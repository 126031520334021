import * as React from "react"
import Button from "../../../components/Button/Button"
import Modal from '../../../components/Modal/Modal'
import "./ResetLessonDialogModal.css"

interface Props {
  show: boolean
  onClose: () => void
  onReset: () => void
  onCancel: () => void
}

interface State {
  resetting: boolean
}

export class ResetLessonDialogModal extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      resetting: false
    }
  }

  handleResetRequest = () => {
    this.setState({resetting: true})
    this.props.onReset()
    setTimeout(() => {
      this.setState({resetting: false})
    }, 300);
  }

  render() {
    let {resetting} = this.state

    return (
      <Modal 
        className="reset-lesson-dialog-modal modal" 
        show={this.props.show} 
        onClose={this.props.onClose} 
        width="900px"
        >
        <div className="reset-lesson-dialog-container">
          <div>
            {this.props.children}
          </div>
          <div className="reset-dialog-controls pb-4 mb-3">
            <Button
              disabled={resetting}
              text={"NO, KEEP DATA"}
              textColor="white"
              bgColor="green"
              onClick={this.props.onCancel}
            />
            &nbsp;
            <Button
              disabled={resetting}
              text={"YES, DELETE DATA"}
              textColor="white"
              bgColor="red"
              onClick={this.handleResetRequest}
            />
          </div>
        </div>
      </Modal>
    )
  }
}