import './SearchBar.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import UserAvatar from './components/UserAvatar/UserAvatarContainer';
import { UserInfo } from '../../services/account/account-rest-interface';
import { isFreeTrialUser } from '../../services/utils/bundle-selection';

interface Props {
    avatarColor: string;
    initials: string;
    userInfo: UserInfo;
    onNavbarToggleClick: () => void;
    popPurchaseModal: () => void;
}

interface State {
    value: string;
}


export default class SearchBar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {value: ''};
    }

    public render() {
        const { userInfo, popPurchaseModal } = this.props;
        const isFreeTrial = isFreeTrialUser(userInfo);
        const daysRemaining = userInfo.expirationDateText ? userInfo.expirationDateText : '';
        let dayOrDays;
        let textColor;
        if (userInfo.expirationDateText) {
            const daysLeft = Number(userInfo.expirationDateText);
            dayOrDays = daysLeft === 1 ? 'day' : 'days';
            textColor =  daysLeft <= 3 ? {color: 'red'} : {color: '#0e5682;'};
        }
        return (
            <div className="topbar">
                <div className="topbar__nav-toggler" onClick={this.handleNavbarToggleClick}>
                    <div className="topbar_nav-toggler-button" />
                </div>
                {isFreeTrial &&
                    <p 
                        className="days-remaining-text align-items-lg-center align-items-md-start justify-content-center justify-content-sm-start flex-md-column flex-lg-row"
                        onClick={popPurchaseModal}
                        style={textColor}
                    >
                    You have {daysRemaining} {dayOrDays} remaining.&nbsp;<span className="d-none d-sm-flex flex-row"><span className="underline">Unlock curriculum</span>&nbsp;by upgrading your membership.</span></p>
                }
                {/* <form className="searchbar_container" onSubmit={this.handleSubmit}>
                    <input className="searchbar" type="text" value={this.state.value} onChange={this.handleChange} />
                </form> */}
                
                <div className="topbar--right">
                    {/* Hide these until the lesson store page is built */}
                    {/*<Cart/>*/}
                    {/*<LessonStore />*/}
                    
                    <UserAvatar
                        user={this.props.userInfo}
                        avatar_color={this.props.avatarColor}
                        initials={this.props.initials}
                        isNavBar={true}
                        onColorChange={() => {/**/}}
                    />
                </div>
            </div>
        );
    }

    private handleSubmit(event: any) {
        alert(this.state.value);
        event.preventDefault();
    }

    private handleChange(e: any) {
        this.setState({value: e.target.value});
    }

    @boundMethod
    private handleNavbarToggleClick(e: any) {
        e.stopPropagation();
        e.preventDefault();

        this.props.onNavbarToggleClick();
    }
}
