import * as React from 'react';

import ReportSection from '../ReportSection';
import ReportTableHeader from '../ReportTableHeader';

import './index.css';

import { CKLessonReportSummary } from '../../services/elearn/lesson-types';

export interface Props {
  summaryData: CKLessonReportSummary
}

function formatTableHeader(first: string, second: string, third: string) {
  if( third == '') {
    return (   <div><div>{first}</div><div>{second}</div></div>  )
  } else {
    return (
      <div className="th-tooltip">
          <div>{first}</div>
          <div>{second} </div>
          <span className="th-tooltip-text">{third}</span>
        </div>
    );
  }
}

const headers = [
  formatTableHeader('Lessons', 'Started', 'Indicates the number of lessons started.'),
  formatTableHeader('Lessons', 'Completed', 'Indicates the number of lessons completed.'),
]

const CKLessonSummary: React.SFC<Props> = ({ summaryData }) => {
  const { categoryName } = summaryData;
  let genericCategoryName = "college-knowledge"

  return (
    <ReportSection sectionName={categoryName} defaultImagePath="/assets/images/college-knowledge">
      <div className="lesson-summary-table-wrapper" style={{minHeight: 100}}>
        <table className="lesson-summary-table table-no-min">
        <ReportTableHeader category={genericCategoryName}>
          {headers.map((headerName, index) => <th key={`th-${genericCategoryName}-${index}`}>{headerName}</th>)}
        </ReportTableHeader>
        <tbody>
          <tr key={`tr-${categoryName}`}>
            <td style={{width: "33%"}}>{summaryData.lessonsStartedVideo}</td>
            <td style={{width: "33%"}}>{summaryData.lessonsCompletedVideo}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </ReportSection>
  )
}

export default CKLessonSummary;
