import * as React from 'react';
import './index.css';

import * as _ from 'lodash';

interface Props {
  id: string;
  selected: string;
  options: Array<any>;
  onChange: (e: any) => void;
}

const SelectCheckbox: React.SFC<Props> = ({ id, options, onChange, selected }) => {
  return (
    <select 
      id={id}
      className="new-select select-checkbox form-control"
      value={selected}
      onChange={onChange}
    >
    {options.map(o => 
      <option 
        key={o.value}
        value={o.value}
      >
        {o.display}
      </option>
    )
    }
    </select>
  );
};

export default SelectCheckbox;
