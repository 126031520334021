// === Lesson Data === //

export interface Bundle {
    bundleId: string;
    bundleName: string;
    curriculum: string;
    lessonMetadata: Partial<Lesson>[];
}

export interface Category {
    categoryId: string;
    categoryName: string;
}

export interface Subcategory {
    subcategoryId: string;
    subcategoryName: string;
    orderWithinCategory?: number;
}

export interface Lesson {
    baselineQuestions: Question[];
    postLessonQuestions: Question[];
    category: Category;
    lessonId: string;
    lessonName: string;
    lessonOrder: number;
    subcategory?: Subcategory;
    topics: Topic[];
    curricula?: string[];
}

export interface Topic {
    topicId: string;
    topicName: string;
    videoId: string;
}

/**
 * Information needed to display a question
 */
export interface Question {
    questionId: string;
    questionHtml: string;
    /* Some questions may have qualifying text that is displayed above the answers */
    questionQualifierHtml?: string;
    questionOptions: QuestionOption[];
    questionImageName?: string;
    feedbackVideoId: string;
    topicName: string;
    topicId: string;
    questionNumber?: number | null;
    difficultyLevel: number;
}

/**
 * A single question option for a multiple choice question
 */
export interface QuestionOption {
    answerHtml: string;
    feedbackHtml: string;
    isCorrect: boolean;
    mcLetter?: string;
}

export enum MaterialType {
    BASELINE_QUESTION,
    TOPIC,
    POST_LESSON_QUESTION,
    REPORT,
    STAY_SHARP_QUESTION
}

export interface CurrentLessonContent {
    type: MaterialType;
    id: string;
}

/**
 * Used to determine a user's progress within a lesson
 */
export interface LessonProgress {
    category: Category;
    lessonId: string;
    lessonName: string;
    lessonOrder: number;
    subcategory?: Subcategory;
    curricula?: string[];
    currentLessonContent: CurrentLessonContent;
    numTotalQuestions: number;
    numCompletedQuestions: number;
    aggregateVideoDuration: string;
    actFrequency: string;
    satFrequency: string;
    isOwned: string;
    star: boolean;
}

export interface StaySharpProgress {
    subject: string;
    lessonsSummary: LessonSummary[];
}

export interface StaySharpByTopicID {
    lessonId: string;
    topicId: string;
    shouldPromptForHints: boolean;
    answers: Array<any>;
    staySharpExtraQuestions: StaySharpExtraQuestions;
}

export interface StaySharpExtraQuestions {
    categoryName: string;
    lessonName: string;
    topicName: string;
    topicVideoId: string;
    questionGroup: StaySharpQuestionGroup;
}

export interface StaySharpQuestionGroup {
    questions: Question[];
    questionGroupInfo: {};
}

export interface StaySharpQuestionOptions {
    answerHtml: string;
    feedbackHtml: string;
    isCorrect: boolean;
}

export interface LessonReportSummary {
  categoryName: string;
  lessonsStarted: number;
  lessonsCompleted: number;
  lessonsUpdated: number;

  preLessonPercentAccuracy: number;
  preLessonSatEquivalentScore: number;
  preLessonActEquivalentScore: number;

  postLessonCurrentPercentAccuracy: number;
  postLessonCurrentSatEquivalentScore: number;
  postLessonCurrentActEquivalentScore: number;

  postLessonCompletedPercentAccuracy: number;
  postLessonCompletedSatEquivalentScore: number;
  postLessonCompletedActEquivalentScore: number;

  percentHintsOnIncorrectQuestions: number;
}

export interface EnrichmentLessonReportSummary {
    categoryName: string;
    lessonsStartedVideo: number;
    lessonsCompletedVideo: number;
    lessonsViewedPdf: number;
}

export interface CKLessonReportSummary {
    categoryName: string;
    lessonsStartedVideo: number;
    lessonsCompletedVideo: number;
}

export interface StaySharpReportSummary {
    categoryName: string
    topicsStarted: number
    topicsCompleted: number
    topicsUpdated: number
    topicsCompletedPercentAccuracy: number
    topicsTotalPercentAccuracy: number
    percentHintsOnIncorrectQuestions: number
    nofHintsUsed: number
    nofTotalQuestions: number
    nofCompletedQuestions: number
    nofTotalMistakes: number
}

export function getDefaultLessonReportSummary() : LessonReportSummary {
  return {
    categoryName: '',
    lessonsStarted:  0,
    lessonsCompleted: 0,
    lessonsUpdated: 0,
    preLessonPercentAccuracy: 0,
    preLessonSatEquivalentScore: 0,
    preLessonActEquivalentScore: 0,

    postLessonCurrentPercentAccuracy: 0,
    postLessonCurrentSatEquivalentScore: 0,
    postLessonCurrentActEquivalentScore: 0,

    postLessonCompletedPercentAccuracy: 0,
    postLessonCompletedSatEquivalentScore:  -1,
    postLessonCompletedActEquivalentScore:  0,

    percentHintsOnIncorrectQuestions: 0
  };
};

export function getDefaultEnrichmentLessonReportSummary() : EnrichmentLessonReportSummary {
    return {
      categoryName: '',
      lessonsStartedVideo:  0,
      lessonsCompletedVideo: 0,
      lessonsViewedPdf: 0
    }
}

export function getDefaultCKLessonReportSummary() : CKLessonReportSummary {
    return {
      categoryName: '',
      lessonsStartedVideo:  0,
      lessonsCompletedVideo: 0,
    }
}

export function getDefaultStaySharpReportSummary() : StaySharpReportSummary {
    return {
      categoryName: '',
        topicsStarted: 0,
        topicsCompleted: 0,
        topicsUpdated: 0,
        topicsCompletedPercentAccuracy: 0,
        topicsTotalPercentAccuracy: 0,
        percentHintsOnIncorrectQuestions: 0,
        nofHintsUsed: 0,
        nofTotalQuestions: 0,
        nofCompletedQuestions: 0,
        nofTotalMistakes: 0
    }
}


export interface LessonSummary {
    lessonName: string;
    topicSummaries: TopicSummary[];
}

export interface TopicSummary {
    topicName: string;
    topicId: string;
    completedQuestions: number;
    totalQuestions: number;
    status: string;
    subject: string;
    lessonId: string;
}

export interface BaselineComparison {
    isBaselineCorrect: boolean;
    isPostLessonCorrect: boolean;
    topicId: string;
}

/**
 * Used for the summary displayed at the end of a lesson
 */
export interface LessonReport {
    baselineComparisons: BaselineComparison[];
    numBaselineQuestionsTotal: number;
    numBaselineQuestionsAnswered: number;
    numBaselineQuestionsCorrect: number;
    numPostLessonQuestionsTotal: number;
    numPostLessonQuestionsAnswered: number;
    numPostLessonQuestionsCorrect: number;
    numMistakeBankQuestionsTotal: number;
    manuallyAddedMistakes: number;
}

export interface QuestionGroupInfo {
    questionGroupImages: string[];
    questionGroupOrder: number;
}

export interface QuestionGroup {
    questionGroupInfo: QuestionGroupInfo | null;
    questions: Question[];
}

/**
 * Format of the response returned when requesting multiple choice questions
 */
export interface MCQuestions {
    postLessonQuestionsData: PostLessonQuestionsData;
}

export interface PostLessonQuestionsData {
    questionGroups: QuestionGroup[];
    shouldPromptForHints: boolean;
}

/**
 * Format of the response returned when requesting baseline questions
 */
export interface BaselineQuestions {
    baselineQuestionsData: BaselineQuestionsData;
}

export interface BaselineQuestionsData {
    questionGroups: QuestionGroup[];
}

/**
 * Format of the response returned when requesting topic questions
 */
export interface TopicQuestions {
    questionGroup: QuestionGroup;
    currentQuestionId: string;
    hints: HintList;
}

export interface HintList {
    [questionId: string]: string;
}
// === Page Overviews === //

/**
 * Info needed to fill out the dashboard
 */
export interface DashboardOverview {
    userId: string;
    currentLessonId: string;
    numLessonsUnstarted: number;
    numLessonsStarted: number;
    numLessonsCompleted: number;
}

/**
 * Format of the response returned when requesting mistake bank questions
 */
export interface MistakeBankLessonsOverview {
    topicMistakeBankOverviews: MistakeBankLessons[];
}

export interface MistakeBankLessons {
    curricula: string[];
    categoryName: string;
    lessonId: string;
    lessonName: string;
    topicId: string;
    topicName: string;
    numQuestionsReviewed: number;
    numQuestionsMissed: number;
    topicVideoId: string;
    topicReviewStatus: string;
    numManualMistakeEntries?: number;
}

/**
 * List of lessons a user owns that are displayed on the My Lessons page
 */
export interface UserLessonOverview {
    userLessonOverviews: Partial<LessonProgress>[];
}

export interface Subject {
    subject: string;
    lessons: LessonProgress[];
}

export interface Hints {
}

export interface LessonCategoryList {
    [categoryName: string]: LessonProgress[];
}

export interface SubcategoryGroup {
    subcategory?: Subcategory;
    lessons: LessonProgress[];
}

export interface Feedback {
    userEmailAddress: string;
    subject: string;
    message: string;
    questionId?: string;
    topicId?: string;
    lessonId: string;
    url?: string;
}

export interface LessonInfo {
    category: string
    lessonName: string
    emailAddress: string
    firstName: string
    lastName: string
    totalQuestions: number
    totalCompletedQuestions: number
    questionCompletionProgress: number
    totalBaselineQuestions: number
    totalAnsweredBaselineQuestions: number
    totalCorrectBaselineQuestions: number
    baselineAccuracy: number
    totalCorrectPostLessonQuestions: number
    questionAccuracy: number
    totalRevisitedBaselineQuestions: number
    totalCorrectRevisitedBaselineQuestions: number
    baselineComparison: number
    baselineQChange: number
    totalHintsWrittenOutOfIncorrectQuestions: number
    currentLessonContent: string
    startDate: string
    completedDate: string
    totalTime: string
    period: string
    lessonId: string
    userId: string
    graduationYear: string
    schoolName: string
    lastUpdatedOn: number
}

// === Contants === //

export const CURRICULUM_LIST = [ 'Test Prep', 'HS Math', 'College Prep' ];

export default Category;
