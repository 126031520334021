
import { createPutRequest2, ignoreJsonError } from '../../request/request-utils'


const requestPUT = createPutRequest2(process.env.REACT_APP_ELEARN_URL || '/')

/**
 * Updates the teacher's and his/her students' lesson priority star
 * 
 * @param lessonId 
 * @param starred 
 * @returns 
 */
export function updatePriorityStarOfLesson(lessonId, starred) {
    let data = JSON.stringify({
        lessonId,
        status: starred
    })
    return requestPUT(`/teacher/lesson-priority-star`, data)
        .catch(err => console.error("Error->", err))
}