import './PDFModal.css';

import * as React from 'react';

import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';

interface Props {
    show: boolean;
    onClose: () => void;
    onPrintPDF: () => void;
    onCloseBtn: () => void;
}

const PDFModal: React.SFC<Props> = (props) => {
    return (
        <Modal
            className="pdf-video-modal"
            show={props.show}
            onClose={props.onClose}
        >
        <div className="modal-container">
            <div className="pdf-video-modal--text">
                <p className="header-text"> Print Lesson Worksheet </p>
                <p className="subtext">
                    Before each video tutorial, it's important that you have a way to take notes.
                </p>
                <p className="subtext">
                    You can either print the lesson worksheet (our recommendation), or you can choose
                    to take notes on a separate sheet of paper (also ok if that's better for you).
                </p>
            </div>
            <div className="btn-container">
                <Button
                    text="WRITE OWN NOTES"
                    textColor="white"
                    bgColor="blue"
                    onClick={props.onCloseBtn}
                />
                <Button
                    text="PRINT WORKSHEET"
                    textColor="white"
                    bgColor="green"
                    onClick={props.onPrintPDF}
                />
            </div>
        </div>
        </Modal>
    );
};

PDFModal.displayName = 'PDFModal';

export default PDFModal;
