import * as React from 'react';
import './index.css';

import * as _ from 'lodash';
import LessonSummaryOverview from './LessonSummaryOverview';
import TestOverviewContainer from './TestOverview/container';
import { RouteComponentProps, Link } from 'react-router-dom';
import { PageContent, PageTitle } from '../../components/PageContent/PageContent';
import ScreenSelector from '../../components/ScreenSelector/';
import EnrichmentLessonSummaryOverview from './EnrichmentLessonSummaryOverview';
import IndividualDataOverview from './IndividualDataOverview';
import LiveViewOverview from './LiveViewOverview';
import CKLessonSummaryOverview from './CKLessonSummaryOverview';
import StaySharpSummaryOverview from './StaySharpSummaryOverview';
import { App, NavbarState } from '../../App';
import RosterView from './RosterView';

export interface Props {
}

export interface StoreProps {
}

type ScreenType = 'lesson' | 'practice-tests' | 'enrichment' | 'roster-view' | 'individual-data' | 'live-view' | 'college-knowledge' | 'stay-sharp';

interface State {
  selectedScreen: ScreenType;
}

export type AllProps = Props & StoreProps & RouteComponentProps<any>;

export default class TeacherPortal extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      selectedScreen: 'roster-view',
    };
  }

  componentDidMount() {
    App.collapseNavbar()
  }

  componentWillUnmount() {
    let navbarStateByUser = App.knownNavbarStateByUser()

    if (!navbarStateByUser) {
      // We collapsed this here programmatically so uncollapse it to restore the navbar
      App.collapseNavbar(false)
    }
    else if (navbarStateByUser === NavbarState.OPEN) {
      // This is the state that's selected by user earlier so uncollapse the navbar
      App.collapseNavbar(false)
    }
  }

  public render() {
    const { selectedScreen } = this.state;

    return (
      <PageContent className="teacher-portal">
        <PageTitle title={`Data Reports`}>
        </PageTitle>
        <div className="view-change-buttons-container mb-20">
          <div className="row">
            <div className="col-lg-12 lessons-navigation-buttons">
              <div className="subject-button-wrapper do-not-print">
                <div className="screen-selector-row subject-buttons inline-buttons">            
                  <ScreenSelector 
                    active={selectedScreen === 'roster-view'}
                    displayName="Roster View" 
                    onSwitchComponent={() => 
                      this.setState({ selectedScreen: 'roster-view' })
                    }
                  />
                  <ScreenSelector 
                    active={selectedScreen === 'individual-data'}
                    displayName="Individual View" 
                    onSwitchComponent={() => 
                      this.setState({ selectedScreen: 'individual-data' })
                    }
                  />
                  <ScreenSelector 
                    active={selectedScreen === 'live-view'}
                    displayName="Live View" 
                    onSwitchComponent={() => 
                      this.setState({ selectedScreen: 'live-view' })
                    }
                  />
                  <ScreenSelector 
                    active={selectedScreen === 'practice-tests'}
                    displayName="Practice Tests" 
                    onSwitchComponent={() => 
                      this.setState({ selectedScreen: 'practice-tests' })
                    }
                  />
                  <ScreenSelector 
                    active={selectedScreen === 'lesson'}
                    displayName="Lessons" 
                    onSwitchComponent={() => 
                      this.setState({ selectedScreen: 'lesson' })
                    } 
                  />
                  <ScreenSelector 
                    active={selectedScreen === 'stay-sharp'}
                    displayName="Stay Sharp" 
                    onSwitchComponent={() => 
                      this.setState({ selectedScreen: 'stay-sharp' })
                    } 
                  />
                  <ScreenSelector 
                    active={selectedScreen === 'college-knowledge'}
                    displayName="College Knowledge" 
                    onSwitchComponent={() => 
                      this.setState({ selectedScreen: 'college-knowledge' })
                    }
                  />
                  <ScreenSelector 
                    active={selectedScreen === 'enrichment'}
                    displayName="Enrichment" 
                    onSwitchComponent={() => 
                      this.setState({ selectedScreen: 'enrichment' })
                    }
                  />
                </div>
              </div>
              {selectedScreen === 'practice-tests' && <TestOverviewContainer />}
              {selectedScreen === 'lesson' && <LessonSummaryOverview />}
              {selectedScreen === 'enrichment' && <EnrichmentLessonSummaryOverview />}
              {selectedScreen === 'individual-data' && <IndividualDataOverview />}
              {selectedScreen === 'live-view' && <LiveViewOverview />}
              {selectedScreen === 'college-knowledge' && <CKLessonSummaryOverview />}
              {selectedScreen === 'stay-sharp' && <StaySharpSummaryOverview />}
              {selectedScreen === 'roster-view' && <RosterView />}
            </div>
          </div>
        </div>
      </PageContent>
    );
  }
}
