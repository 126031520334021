import {
    getCurrentLessonContent,
    setCurrentQuestion,
    setCurrentTopic
} from '../../stores/lesson.store';

import { AppState } from '../../store';
import LessonVideo from './LessonVideo';
import { Props } from './LessonVideo';
import { connect } from 'react-redux';
import { setLessonCompleted } from '../../stores/lesson.store';
import { withRouter } from 'react-router-dom';

const mapStateToProps = ({ lesson }: AppState, ownProps: Props) => {
    const currentLessonContent = getCurrentLessonContent(lesson);
    return {
        currentTopicId: currentLessonContent ? currentLessonContent.id : ''
    };
};

const mapDispatchToProps = { 
    setCurrentTopic,
    setCurrentQuestion,
    setLessonCompleted
};

const ConnectedLessonVideo = connect(mapStateToProps, mapDispatchToProps)(LessonVideo);

export default withRouter(ConnectedLessonVideo);
