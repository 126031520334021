import './Current-Lesson.css';


import * as React from 'react';

import { CurrentLessonContent, MaterialType } from '../../../../../services/elearn/lesson-types';

import Button from '../../../../../components/Button/Button';

interface Props {
    img?: string;
    category?: string;
    lesson?: string;
    length?: string;
    onLessonClick: () => void;
    currentLessonContent?: CurrentLessonContent;
    title?:string
}

interface State {

}

export default class CurrentLesson extends React.PureComponent<Props, State> {
    public render() {
        const { category, currentLessonContent, title="Current Lesson" } = this.props;
        let buttonText = 'START LESSON';
        let buttonColor = 'white';
        let textColor = 'dark';

        if(currentLessonContent && currentLessonContent.type === MaterialType.REPORT) {
            buttonText = 'SUMMARY';
            buttonColor = 'white';
            textColor = 'dark';
        } else if(currentLessonContent) {
            buttonText = 'RESUME LESSON';
            buttonColor = 'white';
            textColor = 'dark';
        }

        // Splitting the lesson text
        const lessonText = this.props.lesson ?? "";
        const firstColonIndex = lessonText.indexOf(':');
        let lessonContent;
        if (firstColonIndex !== -1) {
            const beforeColon = lessonText.substring(0, firstColonIndex);
            const afterColon = lessonText.substring(firstColonIndex + 1);
            lessonContent = (
                <>
                    {beforeColon}:
                    <br />
                    {afterColon}
                </>
            );
        } else {
            lessonContent = lessonText;
        }

        return (
            <div className="current-lesson-container">
                <p className="current-lesson-header">{title}</p>
                <div className="current-lesson-item">
                    {/* <div className="current-lesson-subtitle">{category}</div> */}
                    <div className={`lesson-list-card-header mb-3 theme--` + category?.toLowerCase()}>
                        <span className="ribbon ribbon--img">
                            <img className="ribbonIcon" alt="" src={`/assets/images/icons/subjects/ico-subject-` + category?.toLocaleLowerCase() + `.svg`} width="28" height="27" />
                            <svg className="ribbonBG" width="71" height="45" viewBox="0 0 71 45" fill="none">
                                <path className="ribbonFill" d="M0 0L71 0L60 22.5L71 45H0V0Z" fill="currentColor"/>
                            </svg>
                        </span>
                        <span className="current-lesson-subtitle">{category}</span>
                    </div>
                    <h2 className="current-lesson-title">{lessonContent}</h2>
                </div>
                <div className="current-lesson-btn">
                    <Button
                        text={buttonText}
                        textColor={textColor as any}
                        bgColor={buttonColor as any}
                        onClick={this.props.onLessonClick}
                    />
                </div>
            </div>
        );
    }
}
