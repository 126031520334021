import './CollegeKnowledgeDashboard.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { LessonProgress } from '../../../../services/elearn/lesson-types';

import BlogCard from '../../components/BlogCard/BlogCard';
import MindfulCard from '../../components/MindfulCard/MindfulCard';
import PDFCard from '../../components/PDFCard/PDFCard';
import { PageContent } from '../../../../components/PageContent/PageContent';
import { RouteComponentProps } from 'react-router-dom';
import StockCard from '../../components/StockCard/StockCard';
import { UserInfo } from '../../../../services/account/account-rest-interface';
import { getBaseUrl } from '../../../../services/routing/routing-utils';
import { welcomeUser } from '../../../../services/account/account-rest-interface';
import WelcomeModalOne from '../../components/WelcomeModalOne/WelcomeModalOne';
import WelcomeModalTwo from '../../components/WelcomeModalTwo/WelcomeModalTwo';
import WelcomeModalThree from '../../components/WelcomeModalThree/WelcomeModalThree';

import {
    SuperHelpfulTipsArray,
    MoreHelpfulResourcesArray,
    UnderstandingTheProcessArray
} from '../../components/PDFCard/pdf-list';
import { StepByStepModal } from '../../components/StepByStepModal/StepByStepModal';
import LessonStepByStepModal from '../../components/LessonStepByStepModal/LessonStepByStepModal';
import TutorialCard from '../TutorialCard/TutorialCard';

export interface Props {

}

export interface DispatchProps {
  setUserInfo: (info: Partial<UserInfo>) => void;
}

export interface StoreProps {
    userInfo: UserInfo;
    numLessonsUnstarted: number;
    numLessonsCompleted: number;
    numLessonsStarted: number;
    currentLessonId: string;
    currentLesson: LessonProgress | undefined;
}

interface State {
    showFirstModal: boolean;
    showSecondModal: boolean;
    showThirdModal: boolean;
    showStepByStep: boolean;
    showLearnStepModal: boolean;
}

export type AllProps = Props & StoreProps & DispatchProps & RouteComponentProps<any>;


export class CollegeKnowledgeDashboard extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            showFirstModal: this.props.userInfo.postWelcome === 'true' ? false : true,
            showSecondModal: false,
            showThirdModal: false,
            showStepByStep: false,
            showLearnStepModal: false
        };
    }

    public render() {
        const { numLessonsUnstarted, numLessonsCompleted, numLessonsStarted, userInfo } = this.props;
        const name = userInfo.firstName;

        return (
            <PageContent className="home-container">

                {/* Welcome banner */}
                <div className="row">
                    <div className="col-12">
                        {name && <h1 className="page-title">Welcome, {name}</h1>}
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-12">
                        <Progress
                            totalLessons={totalLessons}
                            completedLessons={numLessonsCompleted}
                            lessonId={currentLessonId}
                            onLessonClick={this.onProgressLessonClick}
                            lesson={currentLesson}
                        />
                    </div>
                </div> */}

                {/* Cards */}
                {/* LEFT-MOST COLUMN  */}
                <div className="row">
                    <div className="first-column col-lg-3 col-md-12 px-lg-0">

                        <TutorialCard openModal={this.openStepByStep} />

                        <PDFCard
                            title={'Super Helpful Tips'}
                            pdfs={SuperHelpfulTipsArray}
                        />

                        <StockCard
                            btnText="CONTACT US"
                            bottomText="Have questions or feedback?"
                            topStyle=""
                            bottomStyle="bottom-text-contact"
                            btnLink="http://winwardacademy.com/contact/"
                        />
                    </div>

                    {/* MIDDLE COLUMN */}
                    <div className="col-lg-6 col-md-12">
                        {/* <ReadyToLearn
                            btnText="VIEW MY LESSONS"
                            topText=""
                            bottomText="Are you ready to learn?"
                            topStyle="top-text"
                            bottomStyle="bottom-text"
                            btnClick={this.goToLesson}
                        /> */}

                        <MindfulCard />

                        <PDFCard
                            title={'More Helpful Resources'}
                            pdfs={MoreHelpfulResourcesArray}
                        />
                    </div>

                    {/* RIGHT COLUMN */}
                    <div className="col-lg-3 col-md-12 px-lg-0">
                        <BlogCard />
                        
                        <PDFCard
                            title={'The Process'}
                            pdfs={UnderstandingTheProcessArray}
                        />

                        <StockCard
                            containerClass={'faq-card'}
                            btnText="VIEW ALL"
                            topText="Having trouble?"
                            bottomText="Check FAQs"
                            topStyle="bottom-text-contact"
                            bottomStyle="bottom-text-contact"
                            btnLink="http://winwardacademy.com/faq/"
                        />
                    </div>
                </div>

                <WelcomeModalOne
                    show={this.state.showFirstModal}
                    onClose={this.toggleSecondModal}
                />

                <WelcomeModalTwo
                    show={this.state.showSecondModal}
                    onClose={this.toggleThirdModal}
                />

                <WelcomeModalThree
                    show={this.state.showThirdModal}
                    onClose={this.sendPostWelcomed}
                />

                <StepByStepModal
                  show={this.state.showStepByStep}
                  onClose={this.closeStepByStep}
                  onModalClose={this.cancelStepModal}
                />

                <LessonStepByStepModal
                  show={this.state.showLearnStepModal}
                  onClose={this.closeLearnStepModal}
                />

            </PageContent>
        );
    }

    @boundMethod
    private toggleSecondModal() {
        this.setState({ showFirstModal: false });
        this.setState({ showSecondModal: true});
    }

    @boundMethod
    private toggleThirdModal() {
        this.setState({ showSecondModal: false });
        this.setState({ showThirdModal: true});
    }

    @boundMethod
    private openStepByStep() {
      this.setState({ showStepByStep: true });
    }

    @boundMethod
    private closeStepByStep() {
      this.setState({
        showStepByStep: false,
        showLearnStepModal: true
      });
    }

    @boundMethod
    private cancelStepModal() {
        this.setState({
            showStepByStep: false,
            showLearnStepModal: false
        });
    }

    @boundMethod
    private closeLearnStepModal() {
      this.setState({
        showLearnStepModal: false
      });
    }

    private onProgressLessonClick(lessonId: string) {
        this.props.history.push(`${getBaseUrl()}app/lessons/${lessonId}`);
    }

    @boundMethod
    private sendPostWelcomed() {
        welcomeUser(this.props.userInfo.id).then(this.updateInitialLogIn);
        this.setState({ showFirstModal: false, showSecondModal: false, showThirdModal: false });
    }

    @boundMethod
    private updateInitialLogIn() {
      this.props.userInfo.postWelcome = 'true';
    }
}
