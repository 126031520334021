import React from "react"

export interface ImageProps {
  alt: string
  src: string
  fallbackSrc?: string
  width?: number
  height?: number
}

export interface ImageState {
  useFallbackSrc: boolean
} 

/**
 * This is a simple image component with fallback option.
 * If the src fails to load then it will attempt to use 
 * the fallbackSrc if provided!
 */
export class Image extends React.Component<ImageProps, ImageState> {
  constructor(props) {
      super(props) 
      this.state = {
          useFallbackSrc: false
      }
  }

  handleError = () => {
      let {fallbackSrc} = this.props
      let {useFallbackSrc} = this.state

      if (fallbackSrc && !useFallbackSrc) {
          this.setState({useFallbackSrc: true})
      }
  }

  render() {
      let {alt, src, fallbackSrc, width=120, height=120} = this.props
      let {useFallbackSrc} = this.state
      let imgSrc = useFallbackSrc ? fallbackSrc: src

      return (
          <img src={imgSrc} alt={alt} 
               width={width} height={height} 
               onError={this.handleError} />
      )
  }
}
