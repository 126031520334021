import * as React from 'react';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { isProductionEnv } from './services/utils/env-helper';

import App from './App';
import GoryBook from './components/GoryBook';
import MaiaContainer from './scenes/Maia/MaiaContainer';
import Login from './scenes/Login/index';
import CleverLogin from './scenes/Login/CleverLoginContainer';
import ClassLinkLogin from './scenes/Login/ClassLinkLoginContainer';
import Logout from './scenes/Logout/Logout';
import NotFound from './scenes/NotFound/NotFoundContainer';
import { Provider } from 'react-redux';
import ResetPassword from './scenes/ResetPassword/ResetPassword';
import FreeTrial from './scenes/SignUp/FreeTrial';
import AccessCodePage from './scenes/SignUp/AccessCodePage';
import Bundle from './scenes/SignUp/Bundle';
import SchoolUserVerification from './scenes/SignUp/SchoolUserVerification';
import Verify from './scenes/Verify/VerifyContainer';
import VideoTest from './scenes/VideoTest/VideoTest';
import Welcome from './scenes/Welcome/Welcome';
import FigureDemo from './scenes/FigureDemo/FigureDemo';
import StudyGuideContainer from './scenes/MistakeBank/Hints/StudyGuideContainer';
import store from './store';
import UnderMaintenance from './scenes/UnderMaintenance/UnderMaintenance';
import PracticePrintContainer from './scenes/Practice/PracticePrintContainer';
import StudentProgressSummary from './scenes/Reports/StudentReports/StudentProgressSummary';
import PracticeTestSummary from './scenes/Reports/StudentReports/PracticeTestSummary';
import TeacherStudentProgressSummary from './scenes/Reports/TeacherReports/TeacherStudentProgressSummary';
import { TeacherStudentPracticeTest } from './scenes/Reports/TeacherReports/TeacherStudentPracticeTest';
import { Reteach } from './scenes/Reteach'

interface Props {}

// TODO: Next up get rid of these shadow props...
const Routes: React.SFC<Props> = (props) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const isUnderMaintenance = process.env.REACT_APP_UNDER_MAINTENANCE;

    return (
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    {isUnderMaintenance && 
                    <Route path={`${baseUrl}login`} component={UnderMaintenance} {...props} />
                    }
                    { !isProductionEnv() &&
                    <Route 
                        path="/gory"
                        exact={true}
                        component={GoryBook}
                    />
                    }
                    { !isProductionEnv() &&
                    <Route 
                        path="/gory/:shelfName"
                        component={GoryBook}
                    />
                    }
                    <Route 
                        path="/maia"
                        exact={true}
                        component={MaiaContainer}
                    />
                    <Route 
                        path="/maia/:token"
                        component={MaiaContainer}
                    />
                    <Route 
                        path="/signup/free-trial"
                        component={FreeTrial} exact 
                    />
                    <Route 
                        path="/signup/gameChanger" 
                        render={(props) => <Bundle gameChangerOnly {...props}/>} 
                    />
                    <Route 
                        path="/signup/gameChangerPersonalCoach" 
                        render={(props) => <Bundle gameChangerPersonalCoach {...props} />} 
                    />                    
                    <Route 
                        path="/signup/gameChangerCollegeKnowledge" 
                        render={(props) => <Bundle gameChangerCollegeKnowledge {...props} />} 
                    />
                    <Route 
                        path="/signup/gameChangerStudyPlan" 
                        render={(props) => <Bundle gameChangerStudyPlan {...props} />} 
                    />
                    <Route 
                        path="/signup/code/"
                        component={AccessCodePage} exact 
                    />
                    <Route 
                        path="/signup/code/:useCode"
                        component={AccessCodePage}
                    />
                    <Route 
                        path="/signup/bundle/:id" 
                        render={(props) => <Bundle bundle {...props}/>} 
                    />
                    <Route 
                        path="/signup/padres-pedal" 
                        render={(props) => (
                          <Bundle {...props} charityCode="padres-pedal" />
                        )} 
                    />
                    <Route 
                        path="/verify/student/:token" 
                        component={SchoolUserVerification} exact
                    />

                    <Route path={'/signup/bundle/'} component={Bundle} />
                    <Route path="/print/study-guide/:firstName/:lastName" component={StudyGuideContainer} exact />
                    <Route path="/print/student-progress/:firstName/:lastName" component={StudentProgressSummary} exact />
                    <Route path="/print/teacher/student-progress/:studentEmail" component={TeacherStudentProgressSummary} exact />
                    <Route path="/print/teacher/student-practice-test/:studentEmail/:testType/:testName" component={TeacherStudentPracticeTest} exact />
                    <Route path="/print/practice-tests/summary/:testType/:testName" component={PracticeTestSummary} exact />
                    <Route path={'/practice-tests/print/:testNumber'} component={PracticePrintContainer} exact />
                    <Route path={`${baseUrl}app/view-figures`} component={FigureDemo} />
                    <Route path={`${baseUrl}login`} component={Login} exact {...props} />
                    <Route path={`${baseUrl}login/clever`} component={CleverLogin} exact {...props} />
                    <Route path={`${baseUrl}login/classlink`} component={ClassLinkLogin} exact {...props} />
                    <Route path={`${baseUrl}reset-password`} component={ResetPassword} {...props} />
                    <Route path={`${baseUrl}logout`} component={Logout} exact {...props} />
                    <Route path={`${baseUrl}verify`} component={Verify} {...props} />
                    <Route path={`${baseUrl}welcome`} component={Welcome} {...props} />
                    <Route path={`${baseUrl}app`} component={App} {...props} />
                    <Route path={`/teacher-reteach/:lessonId`} component={Reteach} />
                    <Redirect from="/" to={`${baseUrl}app/dashboard`} />
                    <Route path={`${baseUrl}video-test`} component={VideoTest} {...props} />
                    <Route component={NotFound} />
                </Switch>
            </BrowserRouter>
        </Provider>
    );
};

export default Routes;
