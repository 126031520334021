import React from 'react'
import BrightcoveVideo from '../BrightcoveVideo/BrightcoveVideo'
import Modal from '../Modal/Modal'
import Loading from '../Loading/Loading'
import "./index.css"

export interface IVideo {
    videoName: string
    videoId: string
}

interface Props {
    title: string
    subtitle: string
    videos: IVideo[]
}

interface State {
    showVideoInModal: boolean
    videoId: string
}

export class VideoGrid extends React.Component<Props, State> {
    videoRef = React.createRef<BrightcoveVideo>()

    constructor(props) {
        super(props)
        this.state = {
            showVideoInModal: false,
            videoId: "",
        }
    }

    showVideoInModal = (videoId: string) => {
        this.setState({ showVideoInModal: true, videoId })
    }

    handleOnModalClose = () => {
        this.setState({ showVideoInModal: false })
        this.videoRef.current?.pause()
    }

    render() {
        let { title, subtitle, videos } = this.props
        let selectedVideoId = this.state.videoId
        let desiredWidth = "80%"
        let bigPlayerStyle = {
            width: '100%',
            maxWidth: '100%',
            height: 'auto'
        }

        if (!videos) return <Loading />

        return (
            <div className="video-grid-container">
                <VideoGroupPanel title={subtitle} videos={videos} onVideoClicked={this.showVideoInModal} />

                <Modal
                    show={this.state.showVideoInModal}
                    width={desiredWidth}
                    showXTopRight={true}
                    onClose={() => this.handleOnModalClose()}>

                    <div className="video-in-modal">
                        <BrightcoveVideo
                            ref={this.videoRef}
                            id={`video-in-modal-${selectedVideoId}`}
                            videoId={selectedVideoId}
                            style={bigPlayerStyle}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}


//--- Helpers ----

function VideoCard(props: { video, idx?, onVideoClicked: (videoId: string) => void }) {
    const { video, idx = "" } = props
    const playerStyle = {
        width: '640px',
        maxWidth: '100%',
        height: 'auto'
    }

    const handleVideoStart = () => { }

    const handleVideoEnd = () => { }

    const handleVideoClick = () => {
        let { onVideoClicked } = props
        onVideoClicked(video.videoId)
    }

    return (
        <div className="col-lg-4 col-md-6 col-sm-12">
            <button className="btn-simple video-container w-100 h-100" onClick={() => handleVideoClick()} title={`Play video: ${video.videoName}, opens in a modal window`}>
                {/* OT: Do not allow pointer events for the thumbnail videos! */}
                <div className="video" style={{ pointerEvents: "none" }}>
                    <BrightcoveVideo
                        id={`video-${video.videoId}-${idx}`}
                        videoId={video.videoId}
                        onVideoStart={handleVideoStart}
                        onVideoEnd={handleVideoEnd}
                        style={playerStyle}
                    />
                </div>
                <h2 className="demo-name">
                    {video.videoName}
                </h2>
            </button>
        </div>
    )
}

function VideoGroupPanel(props: { title: string, videos: any[], onVideoClicked: (videoId: string) => void }) {
    const { title, videos, onVideoClicked } = props

    return (
        <div className="video-group-panel-container">
            <div className="video-panel">
                <div className="row align-items-start">
                    {videos.map((video, idx) =>
                        <VideoCard idx={idx} key={video.videoId + "-" + idx} 
                            video={video} onVideoClicked={onVideoClicked} />)}
                </div>
            </div>
        </div>
    )
}

