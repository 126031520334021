export enum Gender {
    NotSet,
    Male,
    Female,
    Other,
    DeclineToState
}

export function getGenderOptions() {
    return ['', 'Male', 'Female', 'Other', 'Decline to State'];
}

export function getGenderText(gender: Gender) {
    switch(gender) {
        case Gender.Male: return 'Male';
        case Gender.Female: return 'Female';
        case Gender.Other: return 'Other';
        case Gender.DeclineToState: return 'Decline to State';
        default: return '';
    }
}

export function getGenderEnum(gender: string) {
    switch(gender.toUpperCase()) {
        case 'MALE': return Gender.Male;
        case 'FEMALE': return Gender.Female;
        case 'OTHER': return Gender.Other;
        case 'DECLINE TO STATE': return Gender.DeclineToState;
        default: return Gender.NotSet;
    }
}
