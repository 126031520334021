import {quotes} from "./quotes.data"

// --- Helpers ---

export interface IQuote {
    category: string
    author: string
    title?: string
    text: string
}

const DEFAULT_QUOTE: IQuote = {
    category: "Perseverance",
    author: "Confucius",
    title: "Chinese Philosopher",
    text: "Our greatest glory is not in never falling, but in rising every time we fall.",
}

export function randomlyChooseFromQuotes(): IQuote {
    let selectedQuote = DEFAULT_QUOTE

    try {
        let len = quotes.length
        let index = Math.floor(len * Math.random())
        let quote = quotes[index]
        selectedQuote = quote ? quote: selectedQuote
    }
    catch (ex) {
        console.error("Error: ", ex)
    }

    return selectedQuote
}
