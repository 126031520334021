import * as React from 'react'
import BrightcoveVideo from '../../../components/BrightcoveVideo/BrightcoveVideo'
import Modal from '../../../components/Modal/Modal'
import { IDemoVideo } from './types'
import { ResourceStore } from '../../../stores/resource.store'
import "./index.css" 

function DemoCard(props: {demo: IDemoVideo, onVideoClicked: (videoId: string) => void}) {
    const { demo } = props
    const playerStyle = {
        width: '640px',
        maxWidth: '100%',
        height: 'auto'
    }
    
    const handleVideoStart = () => { }

    const handleVideoEnd = () => { }

    const handleVideoClick = () => {
        let { onVideoClicked } = props
        onVideoClicked(demo.videoId)
    }

    return (
        <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="demo-video-container" onClick={() => handleVideoClick()}>                
                {/* OT: Do not allow pointer events for the thumbnail videos! */}
                <div className="demo-video" style={{pointerEvents: "none"}}>
                    <BrightcoveVideo 
                        id={`demo-video-${demo.videoId}`}
                        videoId={demo.videoId}
                        onVideoStart={handleVideoStart}
                        onVideoEnd={handleVideoEnd}                        
                        style={playerStyle}      
                    />
                </div>
                <div className="demo-name">
                    {demo.videoName}
                </div>
            </div>
        </div>
    )
}

function DemoGroupPanel(props: { name: string, demos: IDemoVideo[], onVideoClicked: (videoId: string) => void}) {
    const { name, demos, onVideoClicked } = props
    
    return (
        <div className="demo-group-panel-container">
            <div className="demo-group-title">{name}</div>
            <div className="demos-panel">
                <div className="row">
                    {demos.map(d => <DemoCard key={d.videoId} demo={d} onVideoClicked={onVideoClicked}/>)}
                </div>
            </div>
        </div>
    )
}

interface Props { }

interface State {
    showVideoInModal: boolean
    videoId: string,
    categoryMap: {[index:string]: IDemoVideo[]}
}

export class Demos extends React.Component<Props, State> {
    videoRef = React.createRef<BrightcoveVideo>()

    state = {
        showVideoInModal: false,
        videoId: "",
        categoryMap: {}
    }

    constructor(props) {
        super(props)
        this.init()
    }

    async init() {
        let demos = await ResourceStore.loadDemoVideos()
        let categoryMap = {}
        demos.forEach(r => {
            let key = r.category
            let items = categoryMap[key]            
            if (!items) {
                items = []
                categoryMap[key] = items
            }
            items.push(r)
        })
        this.setState({categoryMap})
    }

    showVideoInModal = (videoId: string) => {
        this.setState({showVideoInModal: true, videoId})
    }

    handleOnModalClose = () => {
        this.setState({showVideoInModal: false})
        this.videoRef.current?.pause()
    }

    render() {
        let {categoryMap} = this.state
        let categoryKeys = Object.keys(categoryMap)
        let selectedVideoId = this.state.videoId
        let desiredWidth = "80%"
        let bigPlayerStyle = {
            width: '100%',
            maxWidth: '100%',
            height: 'auto'
        }

        return (
            <div>
                <div>
                    {categoryKeys.map(category => 
                        <DemoGroupPanel 
                            key={category}
                            name={category} 
                            demos={categoryMap[category]} 
                            onVideoClicked={this.showVideoInModal} />
                    )}                    
                </div>

                <Modal 
                    show={this.state.showVideoInModal}
                    width={desiredWidth}
                    showXTopRight={true}
                    onClose={() => this.handleOnModalClose()}>

                    <div className="demo-video-in-modal">
                        <BrightcoveVideo 
                            ref = {this.videoRef}                          
                            id={`demo-video-in-modal-${selectedVideoId}`}
                            videoId={selectedVideoId}
                            style={bigPlayerStyle}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}


// OT: Keep it as a reference!
export class DemosWithIFrame extends React.Component {
    private elmRef = React.createRef<HTMLDivElement>()

    state = {
        elmTop: 0,
        innerHeight: window.innerHeight 
    }

    handleResize = (e) => {
        this.setState({ innerHeight: window.innerHeight })
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize)
        let rect = this.elmRef.current?.getBoundingClientRect()
        if (rect) {
            this.setState({ elmTop: rect.top })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize)
    }

    render() {
        let { elmTop, innerHeight } = this.state
        let margin = 60
        let iframeHeight = innerHeight - elmTop - margin
        let url = "https://www.winwardacademy.com/demos/"

        return <div ref={this.elmRef}>
            <div style={{textAlign: "right"}}><a target="_blank" href={url}>View on Website</a></div>
            <div className="iframe-container">
                <iframe src={url} width="100%" height={`${iframeHeight}`}/>
            </div>
        </div>
    }
}
