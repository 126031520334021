import * as React from 'react';

import { TestDashboard, Props, StoreProps } from './TestDashboard';

import { AppState } from '../../store';
import { UserInfo } from '../../services/account/account-rest-interface';
import { connect } from 'react-redux';

const mapStateToProps = ({ account }: AppState) => ({
    userInfo: account.userInfo as UserInfo,
});

// TODO: This connect pattern is terrible...need to fix across the board
const TestDashboardImpl = connect<StoreProps, {}, Props, AppState>(mapStateToProps, {})(TestDashboard);
export default (props) => {
    return (
        <TestDashboardImpl {...props} />
    );
};
