import * as React from 'react';
import RadialProgressEx from '../../../components/RadialProgress/RadialProgressEx'
import { PracticeTestScoreEx, TestCompositeRange } from '../../../services/elearn/practice-test-types'
import PracticeTestCard from '../../Practice/components/PracticeTestCard/PracticeTestCard'
import TestScoreDials from '../../Practice/components/TestScoreDials/TestScoreDials'
import "./PracticeTestResults.css"

const totalScoreACTorIA = 36
const totalScoreSAT = 1600

interface ITestScores {
  actScores: PracticeTestScoreEx[]
  satScores: PracticeTestScoreEx[]
  iaScores: PracticeTestScoreEx[]
}

interface Props {
  scores: ITestScores
  showTopScores?: boolean
  enableLinksForStudent?: string
  title?: string
  legend?:boolean
  classes?:string
}

interface State { }

export default class PracticeTestResults extends React.Component<Props, State> {
  findTopScores = () => {
    let {scores} = this.props

    // OT: Per Jennifer's request, now we are including diagnostic scores in the top scores!
    let scoresACT = scores?.actScores
    let scoresSAT = scores?.satScores
    let scoresIA = scores?.iaScores 
    let topACT, topSAT, topIA

    if (scoresACT && scoresACT.length > 1) {
      topACT = scoresACT?.reduce((acc, cur) => {
        if (cur.average > acc.average) {
          acc = cur
        }
        return acc
      }, scoresACT[0])
    }

    if (scoresSAT && scoresSAT.length > 1) {
      topSAT = scoresSAT?.reduce((acc, cur) => {
        if (cur.average > acc.average) {
          acc = cur
        }
        return acc
      }, scoresSAT[0])
    }

    if (scoresIA && scoresIA.length > 1) {
      topIA = scoresIA?.reduce((acc, cur) => {
        if (cur.average > acc.average) {
          acc = cur
        }
        return acc
      }, scoresIA[0])
    }

    return {topACT, topSAT, topIA}
  }

  render() {
    let { topACT, topSAT, topIA } = this.findTopScores()
    let testNameACT = 'ACT Highest Score'
    let testNameSAT = 'SAT Highest Score'
    let radialScoreACT = topACT && topACT.average
    let radialScoreSAT = topSAT && topSAT.average
    let {scores, showTopScores=true, enableLinksForStudent, title="All Scores", legend=true, classes} = this.props
    let CRsuffix = 'college ready score'
  
    return (
      <div className={`practice-test-results ` + classes}>
        <div className="student-reports-header">
          {/* <div className="h4 mb-3">Highest Scores</div> */}
        </div>
        <div className="report-content">
          {showTopScores &&
            <div className="row justify-content-center mb-5">
              {topACT != 0 && 
                <div className="report-content-column col-md-6 mb-3 mb-md-0">
                    <div className="h4 mb-4 text-center">ACT Highest Score</div>
                  <div className="row h-100">
                      <div className="col-6 score-dials-lg">
                        <div className="row">
                          <div className="col-12">
                            <div className="text-center">
                              <RadialProgressEx
                                  progress={radialScoreACT / totalScoreACTorIA * 100}
                                  text={radialScoreACT+""}/>
                            </div>
                          </div>
                          <div className="col-12 progress-title">
                            {testNameACT}
                          </div>
                        </div>
                      </div>
                      <div className="col-6 score-dials-sm">
                      <TestScoreDials
                        score={topACT}
                        totalScore={totalScoreACTorIA}
                        testType={"act"}
                        showCircle={false}
                        showButton={false}
                        showTitle={false} />
                      </div>
                    </div>
                  </div>
                }
                {topSAT != 0 && 
                  <div className="report-content-column col-md-6">
                    <div className="h4 mb-4 text-center">SAT Highest Score</div>
                    <div className="row h-100">
                      <div className="col-6 score-dials-lg">
                        <div className="row">
                          <div className="col-12">
                            <div className="text-center">
                            <RadialProgressEx
                                    progress={radialScoreSAT / totalScoreSAT * 100}
                                    text={radialScoreSAT+""} />
                            </div>
                          </div>
                          <div className="col-12 progress-title">
                            {testNameSAT}
                          </div>
                          </div>
                      </div>
                      <div className="col-6 score-dials-sm">
                        <TestScoreDials
                        score={topSAT}
                        totalScore={totalScoreSAT}
                        testType={"sat"}
                        showCircle={false}
                        showButton={false}
                        showTitle={false} />
                      </div>
                    </div>
                  </div>
                }
            </div>
          }
          <div className="page-break">
            <div className="row no-gutters align-items-center practice-test-report-table-header">
              {title && (
                <div className="col-title mr-3 mr-xl-4">
                  <h4>{title}</h4>
                </div>
              )}
              {legend && 
                <>
                  <div className="pl-0 ml-lg-auto mr-3 mr-xl-4 practice-test-help-icons"><img src={iconAboveAvg} alt="" width="18" height="18" className="mr-1 help-icons--icon" /> Above {CRsuffix}</div>
                  <div className="pl-0 mr-3 mr-xl-4 practice-test-help-icons"><img src={iconCloseAvg} alt="" width="18" height="18" className="mr-1 help-icons--icon" /> Close to {CRsuffix}</div>
                  <div className="pl-0 practice-test-help-icons"><img src={iconBelowAvg} alt="" width="18" height="18" className="mr-1 help-icons--icon" /> Below {CRsuffix}</div>
                </>
              }
            </div>
            <PracticeTestResultsTables scores={scores} enableLinksForStudent={enableLinksForStudent}/>
          </div>
        </div>
      </div>
    )
  }
}


//--- Simple Helper Components ---

interface PracticeTestResultsTablesProps {
  scores: ITestScores
  enableLinksForStudent?: string
}

function PracticeTestResultsTables(props: PracticeTestResultsTablesProps) {
  let {actScores, satScores, iaScores} = props.scores
  let {enableLinksForStudent} = props

  actScores = actScores.filter(s => s.status === "started")
  satScores = satScores.filter(s => s.status === "started")
  iaScores  = iaScores.filter(s => s.status === "started")
  let actIaScores = [...actScores, ...iaScores]

  return (
    <div className="tables-pt-scores">
      <ACTPracticeTestResultsTable title="ACT/IA Practice Test Results" scores={actIaScores} enableLinksForStudent={enableLinksForStudent}/>
      <SATPracticeTestResultsTable title="SAT Practice Test Results" scores={satScores} enableLinksForStudent={enableLinksForStudent}/>
    </div>
  )
}

interface PTTableProps {
  title: string
  scores: PracticeTestScoreEx[]
  enableLinksForStudent?: string // Student's email address (if provided)
}

const NA = "N/A"


const imagePath = '/assets/images/icons/v2';
const iconAboveAvg = `${imagePath}/ico-target-above.svg`
const iconCloseAvg = `${imagePath}/ico-target-at.svg`
const iconBelowAvg = `${imagePath}/ico-target-below.svg`

function returnScoresWithIcons(score, below, above) {
  let outputScore
  if(score != 'N/A' && score <= below) {
    // below Avg
    outputScore = '<span class="val-with-icon d-inline-block" style="line-height:26px;min-width:20px;position:relative;top:1px;">' + score + '</span><img alt="" class="ml-2" width="20" height="20" src="' + iconBelowAvg + '" />'
  } else if(score > below && score < above) {
    // Close to Avg
    outputScore = '<span class="val-with-icon d-inline-block" style="line-height:26px;min-width:20px;position:relative;top:1px;">' + score + '</span><img alt="" class="ml-2" width="20" height="20" src="' + iconCloseAvg + '" />'
  } else if(score >= above && score != 'N/A') {
    // Above Avg
    outputScore = '<span class="val-with-icon d-inline-block" style="line-height:26px;min-width:20px;position:relative;top:1px;">' + score + '</span><img alt="" class="ml-2" width="20" height="20" src="' + iconAboveAvg + '" />'
  } else {
    // NA
    outputScore = '<span class="val-with-icon d-inline-block" style="line-height:26px;min-width:20px">' + score + '</span>'
  }

  return outputScore
}

function getReportLink(studentEmail: string="", testDisplayName: string, testName: string) {
  let testType = testDisplayName.includes("SAT") ? "SAT": testDisplayName.includes("ACT") ? "ACT": "IA"
  let reportLink = `/print/teacher/student-practice-test/${studentEmail}/${testType}/${testName}`
  return reportLink
}

// ACT/IA practice test results table
function ACTPracticeTestResultsTable(props: PTTableProps) {
  let {title, scores, enableLinksForStudent} = props

  let renderTableHeader = () => {
    return (
      <thead>
        <tr key={"report-table-header"}>
          <th className="lst-th1 label">Test Name</th>
          <th className="lst-th2 label">Composite</th>
          <th className="lst-th2 label">English</th>
          <th className="lst-th2 label">Math</th>
          <th className="lst-th2 label">Reading</th>
          <th className="lst-th2 label">Science</th>
        </tr>
      </thead>
    )
  }

  let renderTableBody = (scores: PracticeTestScoreEx[]) => {
    return (
      <tbody>
        {scores.map(lesson => renderTableRow(lesson))}
      </tbody>
    )
  }


  let renderTableRow = (score: PracticeTestScoreEx) => {
    // Determine the scores information here
    let infoEnglish = score.englishActionable ? score.english: NA
    let infoMath = score.mathActionable ? score.math: NA
    let infoReading = score.readingActionable ? score.reading: NA
    let infoScience = score.scienceActionable ? score.science: NA
    let outputEng = returnScoresWithIcons(infoEnglish, 15, 18)
    let outputMath = returnScoresWithIcons(infoMath, 19, 22)
    let outputReading = returnScoresWithIcons(infoReading, 19, 22)
    let outputScience = returnScoresWithIcons(infoScience, 19, 22)
    let reportLink = getReportLink(enableLinksForStudent, score.displayName, score.testName) 

    return (
      <tr>
        {enableLinksForStudent ? 
          <td className="lst-td1">
            <a className="link iconLink--left" href={reportLink} target="practice-test-summary-page">
              <img
                className="explanation-button-image"
                src={`/assets/images/icons/v2/ico-external-link.svg`}
                width="18"
                height="18"
                alt=""
              />
              {score.displayName}
            </a>
          </td>
          : 
          <td className="lst-td1">{score.displayName}</td>
        }
        <td className="lst-td2">{score.average}</td>
        <td className="lst-td2" dangerouslySetInnerHTML={{ __html:outputEng }} />
        <td className="lst-td2" dangerouslySetInnerHTML={{ __html:outputMath}} />
        <td className="lst-td2" dangerouslySetInnerHTML={{ __html:outputReading}} />
        <td className="lst-td2" dangerouslySetInnerHTML={{ __html:outputScience}} />
      </tr>
    )
  }

  return (
    <div>
      <div className="practice-test-report-table-title">
        <div className="row no-gutters align-items-center">
          <div className="col-title mr-3 mr-xl-4">{title}</div>
        </div>
      </div>
      <div className="responsive-table-wrapper">
        <table className="report-lesson-summary-table table-fixed-6">
          {renderTableHeader()}
          {renderTableBody(scores)}
        </table>
      </div>
    </div>
  )
}

// SAT practice test results table
function SATPracticeTestResultsTable(props: PTTableProps) {
  let {title, scores, enableLinksForStudent} = props

  let renderTableHeader = () => {
    return (
      <thead>
        <tr key={"report-table-header"}>
          <th className="lst-th1 label">Test Name</th>
          <th className="lst-th2 empty">&nbsp;</th>
          <th className="lst-th2 empty">&nbsp;</th>
          <th className="lst-th1 label">Score</th>
          <th className="lst-th2 label">EBRW</th>
          <th className="lst-th2 label">Math</th>
        </tr>
      </thead>
    )
  }

  let renderTableBody = (scores: PracticeTestScoreEx[]) => {
    return (
      <tbody>
        {scores.map(lesson => renderTableRow(lesson))}
      </tbody>
    )
  }

  let renderTableRow = (score: PracticeTestScoreEx) => {
    // Determine the scores information here
    let totalMathCalc = score.mathCalculatorAnswerCount || 0
    let totalMathNoCalc = score.mathNoCalculatorAnswerCount || 0
    let totalMathAnswers = totalMathCalc + totalMathNoCalc
    let infoEBRW = score.evidenceBasedRW ? score.evidenceBasedRW: NA
    let infoMath = totalMathAnswers > 0 ? score.math: NA
    let outputEBRW = returnScoresWithIcons(infoEBRW, 450, 480)
    let outputMath = returnScoresWithIcons(infoMath, 500, 530)
    let reportLink = getReportLink(enableLinksForStudent, score.displayName, score.testName) 

    return (
      <tr>
        {enableLinksForStudent ? 
          <td className="lst-td1">
            <a className="link iconLink--left" href={reportLink} target="practice-test-summary-page">
              <img
                className="explanation-button-image"
                src={`/assets/images/icons/v2/ico-external-link.svg`}
                width="18"
                height="18"
                alt=""
              />
              {score.displayName}
            </a>
          </td>
          : 
          <td className="lst-td1">{score.displayName}</td>
        }
        <td className="lst-td2 empty">&nbsp;</td>
        <td className="lst-td2 empty">&nbsp;</td>
        {/* <td className="lst-td1">{score.displayName}</td> */}
        <td className="lst-td2">{score.average}</td>
        <td className="lst-td2" dangerouslySetInnerHTML={{ __html:outputEBRW }}/>
        <td className="lst-td2" dangerouslySetInnerHTML={{ __html:outputMath }} />
      </tr>
    )
  }

  return (
    <div>
      <div className="practice-test-report-table-title">
        <div className="row no-gutters align-items-center">
          <div className="col-title mr-3 mr-xl-4">{title}</div>
        </div>
      </div>
      <div className="responsive-table-wrapper">
        <table className="report-lesson-summary-table table-fixed-6 has-empty">
          {renderTableHeader()}
          {renderTableBody(scores)}
        </table>
      </div>
    </div>
  )
}


function PTTableOld(props: PTTableProps) {
  let {title, scores} = props
  
  scores = scores.filter(s => s.status === "started")

  let renderTableHeader = () => {
    return (
      <thead>
        <tr key={"report-table-header"}>
          <th className="lst-th1 label">Test Name</th>
          <th className="lst-th1 label">All Scores</th>
          <th className="lst-th2 label">Average Score</th>
          <th className="lst-th2 label">Percentile</th>
          <th className="lst-th2 label">Score Range</th>
        </tr>
      </thead>
    )
  }

  let renderTableBody = (scores: PracticeTestScoreEx[]) => {
    return (
      <tbody>
        {scores.map(lesson => renderTableRow(lesson))}
      </tbody>
    )
  }

  let getRangeInfo = (isSAT, act: string, sat: string) => {
    let part1 = isSAT ? sat + " SAT": act + " ACT "
    let part2 = isSAT ? act + " ACT": sat + " SAT"
    let rangeInfo = <span>
        <span style={{paddingRight: 5}}>{part1}</span>
        ⬌
        <span style={{paddingLeft: 5}}>{part2}</span>
      </span>

    return rangeInfo
  }

  let renderTableRow = (score: PracticeTestScoreEx) => {
    let {act, sat} = score.satCompositeRange.filter(r => r.active)[0]
    let isSAT = score.displayName.indexOf("SAT") > -1 ? true: false
    let rangeInfo = getRangeInfo(isSAT, act, sat)
    let scoresInfo = ""

    // Determine the scores information here
    scoresInfo += score.englishActionable ? "<b>English:</b> " + score.english + "<br/>": ""
    scoresInfo += score.mathActionable ? "<b>Math:</b> " + score.math + "<br/>": ""
    scoresInfo += score.readingActionable ? "<b>Reading:</b> " + score.reading + "<br/>": ""
    scoresInfo += score.scienceActionable ? "<b>Science:</b> " + score.science + "<br/>": ""
    scoresInfo += score.evidenceBasedRW ? "<b>EBRW:</b> " + score.evidenceBasedRW + "<br/>": ""
    scoresInfo += score.evidenceBasedRW ? "<b>Math:</b> " + score.math + "<br/>": ""

    return (
      <tr>
        <td className="lst-td1">{score.displayName}</td>
        <td className="lst-td1" dangerouslySetInnerHTML={{__html: scoresInfo}} ></td>
        <td className="lst-td2">{score.average}</td>
        <td className="lst-td2">{score.percentile}</td>
        <td className="lst-td2">{rangeInfo}</td>
      </tr>
    )
  }

  return (
    <div className="responsive-table-wrapper">
      <table className="report-lesson-summary-table">
        {renderTableHeader()}
        {renderTableBody(scores)}
      </table>
    </div>
  )
} 

