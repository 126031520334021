import './index.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';

import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import TextBox from '../TextBox/TextBox';

export interface Props {
  text?: string;
  onCancel: () => void;
  onSaveHint: (text: string) => void;
}

interface State {
  hintText: string;
  hintSet: boolean;
}

export default class AddHintForm extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);

      this.state = {
        hintText: props.text || '',
        hintSet: false
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
      this.setState({ hintText: nextProps.text || ''});
    }


    public render() {
      const { text } = this.props;
      const { hintText } = this.state; // new textA
      const hasExistingHint = text && text.length > 0;

      return (
        <div className={'add-hint-form'}>
            <div className="hint--text">
                {!this.state.hintSet && <span>
                <div className="hint-header">
                  <p className="header-text">Write yourself a hint</p>
                  <img
                      className="hint-icon"
                      alt=""
                      src={`/assets/images/mistakeBank/hint-icon-2022.svg`}
                  />
                </div>
                <p className="subtext">
                    You’ll have access to this hint later when 
                    reviewing your personalized study guide in 
                    your Mistake Bank.
                </p>

                <TextBox
                    id="message"
                    labelText=""
                    inputType="email"
                    placeholder="Please enter..."
                    value={this.state.hintText}
                    onChange={(e) => 
                      this.setState({ hintText: e.target.value })
                    }
                    resizeable={false}
                />

                <div className="submit-btns">
                    <Button
                        text="CANCEL"
                        textColor="white"
                        bgColor="blue"
                        onClick={() => this.props.onCancel()}
                    />

                    <Button
                        text={hasExistingHint ? "UPDATE" : "SAVE"}
                        textColor="white"
                        bgColor="green"
                        onClick={() => {
                          if (this.state.hintText.length > 0) {
                            this.props.onSaveHint(this.state.hintText);
                            this.setState({ hintText: '' });
                          }
                          }
                        }
                    />
                </div>

                </span>}

                {this.state.hintSet &&
                    <div>
                        <p className="hint__confirm-text"> Your hint has been added to your Mistake Bank. </p>
                    </div>}
            </div>
          </div>
      );
    }
}
