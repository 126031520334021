export interface ITeacherResource {
    subject: string
    lesson: string
    topic: string
    worksheet: string
    worksheetUrl?: string
    answerKey: string
    answerKeyUrl?: string
}

export interface IDemoVideo {
    categoryOrder: number
    videoOrder: number
    category: string
    videoName: string
    videoId: string
}

export interface IFaq {
    categoryOrder: number
    category: string
    questionOrder: number
    question: string
    answer: string
    images?: string
}

export enum LinkType {
    DOCUMENT,
    PDF,
    PRESENTATION,
    SHEET
}

export interface IResourceMetaData {
    resource: string
    description: string 
    linkType: LinkType
    link: string
}
export interface IEngagementAndCulture extends IResourceMetaData {
    
}

export interface IAssessments extends IResourceMetaData {

}
