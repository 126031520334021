import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../store';
import { EnrichmentLessons } from './EnrichmentLessons';
import { updateUserEnrichment } from '../../stores/enrichment.store';

const mapStateToProps = ({ account, enrichment }: AppState, ownProps: any) => {
    return {
      ...ownProps,
      userInfo: account.userInfo,
      enrichment
    };
};

const mapDispatchToProps = {
    updateUserEnrichment
};

const ConnectedEnrichment = connect(mapStateToProps, mapDispatchToProps)(EnrichmentLessons);

export default function(props: any) {
    return <ConnectedEnrichment {...props} />
}
