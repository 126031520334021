import './StaySharpQuestions.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import {
    LessonProgress,
    StaySharpQuestionGroup,
    Question
} from '../../services/elearn/lesson-types';
import { getBaseUrl } from '../../services/routing/routing-utils';
import { getStaySharpQuestions } from '../../services/elearn/stay-sharp-rest-interface';

import LessonVideo from '../LessonVideo/LessonVideoContainer';
import RewatchVideo from '../RewatchLessonVideo/RewatchLessonVideoContainer';
import Loading from '../../components/Loading/Loading';
import StaySharpMCPage from '../../components/StaySharpMCPage/StaySharpMCPageContainer';
import StaySharpModal from '../StaySharp/components/StaySharpModal/StaySharpModal';
import { UserInfo } from '../../services/account/account-rest-interface';

enum ActiveSection {
    Baseline,
    Video,
    RewatchVideo,
    MultipleChoice,
    Summary
}

interface ComposedProps {
    staySharpQuestionGroups: StaySharpQuestionGroup[];
    staySharpQuestions: Question[];
    shouldShowHintPopup: boolean;
}

interface Routeprops {
    lessonId: string;
    topicId: string;
}

interface StoreProps {
    numLessonsStarted: number;
    numLessonsCompleted: number;
    userProgress: LessonProgress;
    currentLessonId: string;
    userInfo: UserInfo;
}

interface Props {
    history: any;
    openCompleteModal: (topicName: string) => void;
}

interface DispatchProps {
    setLessonInProgress: (lessonInProgress: boolean) => void;
    setNextLessonAfter: (lessonId: string) => Promise<any>;
    setLessonCompleted: (lessonId: string, userInfo: any) => void;
    incrementNumLessonsStarted: () => void;
    setLessonCompleted2: (lessonId: string, topicId: string, userInfo: any) => void;
}

interface State extends ComposedProps {
    showTutorialPopup: boolean;
    activeSection: ActiveSection;
    showBaselineCompletedModal: boolean;
    loaded: boolean;
    staySharpQuestionGroups: any;
    staySharpParent: any;
    openCompleteModal: boolean;
    openModal: boolean;
    lessonName: string;
    topicName: string;
}

type AllProps = Routeprops & StoreProps & DispatchProps & Props;


export default class StaySharpQuestions extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            staySharpQuestionGroups: {},
            loaded: false,
            shouldShowHintPopup: true,
            showTutorialPopup: false,
            activeSection: ActiveSection.MultipleChoice,
            showBaselineCompletedModal: false,
            staySharpQuestions: [],
            staySharpParent: null,
            openCompleteModal: false,
            openModal: false,
            lessonName: '',
            topicName: ''
        };

        // TEMPORARY
        (window as any).setNext = () => {
            this.props.setNextLessonAfter(this.props.currentLessonId);
        };
    }

    public UNSAFE_componentWillMount() {
        const { lessonId, topicId } = this.props;

        this.props.setLessonInProgress(true);

        // Set current lessonId and get lesson questions
        Promise.all([
            getStaySharpQuestions(lessonId, topicId)
        ])
        .then(([staySharpExtraQuestionsData]) => {
            const hasStaySharpQuestions = staySharpExtraQuestionsData &&
            staySharpExtraQuestionsData.staySharpExtraQuestions.questionGroup &&
            staySharpExtraQuestionsData.staySharpExtraQuestions.questionGroup.questions.length > 0;

            // Increment numLessonsStarted when starting a new lesson
            const { userProgress = {} as Partial<LessonProgress>, incrementNumLessonsStarted } = this.props;
            const { currentLessonContent } = userProgress;
            if(!currentLessonContent) {
                incrementNumLessonsStarted();
            }

            let staySharpQuestions: Question[];
            let shouldShow;

            if(hasStaySharpQuestions) {
                staySharpQuestions = staySharpExtraQuestionsData.staySharpExtraQuestions.questionGroup.questions;
                shouldShow = staySharpExtraQuestionsData.shouldPromptForHints;
            } else {
                staySharpQuestions = [];
                shouldShow = true;
            }

            this.setState({
                staySharpQuestions: staySharpExtraQuestionsData.staySharpExtraQuestions.questionGroup.questions,
                loaded: true,
                shouldShowHintPopup: shouldShow,
                staySharpParent: staySharpExtraQuestionsData,
                lessonName: staySharpExtraQuestionsData.staySharpExtraQuestions.lessonName,
                topicName: staySharpExtraQuestionsData.staySharpExtraQuestions.topicName,
            });
        })
        .catch(e => {
            console.log(e);
        });
    }

    public componentWillUnmount() {
        this.props.setLessonInProgress(false);
        window.onbeforeunload = () => {/**/};
    }

    public render() {
        const { activeSection, loaded, staySharpParent, openModal, lessonName, topicName, 
            staySharpQuestions = [] } = this.state;
        const { lessonId, topicId, userInfo } = this.props;
        if(!loaded) { return <Loading />; }

        switch(activeSection) {
            case ActiveSection.Video: {
                return <LessonVideo lessonId={lessonId} onComplete={this.handleVideoCompleted} userInfo={userInfo} />;
            }
            case ActiveSection.RewatchVideo: {
                return <RewatchVideo lessonId={lessonId} isTeacher={false} userInfo={userInfo} />;
            }
            case ActiveSection.MultipleChoice: {
                return (
                    <StaySharpMCPage
                        lessonId={lessonId}
                        topicId={topicId}
                        onComplete={this.openModal}
                        questionGroup={staySharpQuestions}
                        shouldShowHintPopup={this.state.shouldShowHintPopup}
                        handleExitLesson={this.exitStaySharp}
                        staySharpData={staySharpParent}
                        setLessonComplete={this.setLessonCompleted}
                    >

                    <StaySharpModal
                        show={openModal}
                        onClose={this.closeModal}
                        topicName={topicName}
                        backToStaySharp={this.exitStaySharp}
                    />
                    </StaySharpMCPage>
                );
            }
            default: return <div>Invalid page</div>;
        }
    }

    private setLessonCompleted() {
        console.log('Completed Stay Sharp Topic.');
    }

    @boundMethod
    private openModal() {
        this.setState({
            openModal: true
        });
    }

    private closeModal() {
        this.setState({
            openModal: false
        });
        this.props.history.push('/app/stay-sharp');
    }

    @boundMethod
    private exitStaySharp() {
        this.props.history.push(`${getBaseUrl()}app/stay-sharp`);
    }

    private handleVideoCompleted() {
        if(this.state.staySharpQuestions.length === 0) {
            this.goToSummary();
        } else {
            this.goToMultipleChoice();
        }
    }

    private goToMultipleChoice() {
        this.setState({ activeSection: ActiveSection.MultipleChoice });
    }

    private goToSummary() {
        let topicName = this.state.staySharpParent.staySharpExtraQuestions.lessonName;
        this.props.history.push('stay-sharp?completion=' + topicName);
    }
}
