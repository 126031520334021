import { createGenericAuthRequest } from '../request/request-utils'
const baseUrl1 = process.env.REACT_APP_ACCOUNT_URL || '/'
const baseUrl2 = process.env.REACT_APP_ELEARN_URL || '/'
const request1 = createGenericAuthRequest(baseUrl1)
const request2 = createGenericAuthRequest(baseUrl2)

/**
 * Gets the superintendent's discrict reports for all his/her schools
 * @returns report for SI
 */
export async function getSuperintendentReports() {
  let schoolsReports = await request2(`/district/superintendent/schoolreports`)
  let schoolsPerformanceReports = await request2(`/district/superintendent/schoolperformancereports`)
  let initialReport = schoolsReports[0]
  let combinedReport = schoolsReports.reduce((acc, cur, index) => {
    try {
      if (index === 0) return acc
      
      acc.lessons = acc.lessons ? acc.lessons: {}
      acc.lessons.schools = acc.lessons.schools ? acc.lessons.schools: []
      acc.tests = acc.tests ? acc.tests: {}
      acc.tests.schools = acc.tests.schools ? acc.tests.schools: []

      // Combine them all
      acc.lessons.schools = [...acc.lessons.schools, ...cur.lessons.schools]
      acc.tests.schools = [...acc.tests.schools, ...cur.tests.schools]
    }
    catch (ex) {
      // This shouldn't happen but just incase handle the exception here!
      console.log("Exception in getSuperintendentReport: " + ex)
    }
    return acc
  }, initialReport)
  let tonpDetailsReports = await getSuperintendentSchoolsTonPDetailsReports()
  
  return {...combinedReport, performances: schoolsPerformanceReports, tonpDetailsReports}
}

/**
 * Get SI's schools' Time on Platform (TonP) details
 */
export async function getSuperintendentSchoolsTonPDetailsReports() {
  let siData = await request2(`/district/superintendent/schooltonpdetailsreports`)

  return siData
}

/**
 * Get the practice tests insights for students based on their school, test type and graduation years.
 * 
 * @param schoolName 
 * @param testType 
 * @param graduationYears all | 2024,2025,... (comma separated years) 
 */
export async function getStudentsTestInsightsBySchoolTestTypeAndGraduationYears(schoolName: string, testType: string, graduationYears="all") {  
  let data = await request2(`/district/superintendent/tests/insights/${schoolName}/${testType}/${graduationYears}`)

  return data
}

/**
 * Get the TonP info for all students for the school
 * @param schoolName 
 * @returns 
 */
export async function getStudentsTimeOnPlatformInfoBySchool(schoolName: string) {  
  let data = await request2(`/district/superintendent/timeonplatform/insights/${schoolName}`)

  return data
}

/**
 * Get the pracatice test insights details for students based on their school and the test name
 * This information is categorical (section) including fields for English, Math, etc.
 * @param schoolName 
 * @param testName 
 * @returns 
 */
export async function getStudentsTestInsightDetailsBySchoolAndTestNameFromEndPoint(schoolName: string, testName: string) {
  let data = await request2(`/district/superintendent/tests/insights/details/${schoolName}/${testName}`)

  return data
}

