import { getStaySharpWorksheets, getDemoVideos } from "../services/elearn/resource-rest-interface"
import { IDemoVideo, ITeacherResource } from "../scenes/TeacherPortal/TeacherResources/types"

export interface IResourceStoreData {
    staysharpWorksheets: ITeacherResource[],
    demoVideos: IDemoVideo[],
    // OT: Also gather FAQs here...
}

export class ResourceStore {
    static preloaded = false
    static readonly data: IResourceStoreData = {
        staysharpWorksheets: [],
        demoVideos: []
    }

    static preload() {
        if (!ResourceStore.preloaded) {
            ResourceStore.preloaded = true
            ResourceStore.loadStaySharpWorksheets()
            ResourceStore.loadDemoVideos()
        }
    }

    static async loadStaySharpWorksheets() {
        let { staysharpWorksheets } = this.data

        if (!staysharpWorksheets || staysharpWorksheets.length == 0) {
            staysharpWorksheets = await getStaySharpWorksheets()
            this.data.staysharpWorksheets = staysharpWorksheets
        }        

        return staysharpWorksheets
    }

    static async loadDemoVideos() {
        let { demoVideos } = this.data

        if (!demoVideos || demoVideos.length == 0) {
            demoVideos = await getDemoVideos()
            this.data.demoVideos = demoVideos
        }

        return demoVideos
    }
}

