import './LessonViewSwitch.css';

import * as React from 'react';
import { Link } from 'react-router-dom';
import { UserInfo } from '../../../../services/account/account-rest-interface';
import { hasCollegeKnowledge, hasResilienceReminders, hasSchoolSafety } from '../../../../services/account/account-utils';

export enum LessonView {
    Calendar,
    Subject,
    Completed
}

interface Props {
    selectedView: LessonView;
    onViewChange: (view: LessonView) => void;
    openEnglishModal: () => void;
    openMathModal: () => void;
    openReadingModal: () => void;
    openScienceModal: () => void;
    changeFrequency: (testType: string) => void;
    isTeacher: boolean;
    selectedFrequency?: string;
}

interface StoreProps {
  userInfo: UserInfo;
}

interface State {
    selectedFrequency: string;
}

const baseImgUrl = '/assets/images/icons/views/';

// List of views used to generate image buttons
const views: any[] = [
    // {
    //     title: 'View lessons based on your schedule',
    //     type: LessonView.Calendar,
    //     icon: 'calendar.svg',
    //     //disabled: true
    // },
    {
        title: 'View lessons by subject',
        type: LessonView.Subject,
        icon: 'subject.svg'
    },
    {
        title: 'View all completed lessons',
        type: LessonView.Completed,
        icon: 'completed.svg'
    },
];

type AllProps = Props & StoreProps;

export class LessonViewSwitch extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
      super(props);
      this.state = {
        selectedFrequency: this.props.selectedFrequency || 'ACT'
      };
    }

    public render() {
      let { selectedFrequency } = this.state
      let {userInfo} = this.props
      let {isTeacher=false} = userInfo

      return (
        <div className="view-change-buttons-container mb-20">
            <div className="row">
                <div className="col-xl-12 col-lg-12 d-flex lessons-navigation-buttons">
                    <div className="subject-buttons d-flex">
                        <button
                            className={'btn title-button btn-not-bold btn-default white activeSelection arrow_box margin-right-10'}
                            type="button"
                            title={this.props.userInfo.bundleName}
                        >
                            <div>{this.props.userInfo.bundleName}</div>
                        </button>    
                        {hasCollegeKnowledge(this.props.userInfo) && 
                            <Link to="/app/lessons/dashboard/ck"
                                    className={'btn title-button btn-not-bold btn-default white margin-right-10'}
                                    role="button"
                                    title="College Knowledge">
                                    <div>College Knowledge</div>
                            </Link>
                        }
                        <Link to="/app/lessons/dashboard/enrichment"
                            className={'btn title-button btn-not-bold btn-default white margin-right-10'}
                            role="button"
                            title="Enrichment"
                            >
                            <div>Enrichment</div>
                        </Link>
                        {isTeacher && hasResilienceReminders(userInfo) && 
                            <Link to="/app/lessons/resilience-reminders"
                                className={'btn title-button btn-not-bold btn-default white margin-right-10'}
                                role="button"
                                title="Mindset Moments"
                                >
                                <div>Mindset Moments</div>
                            </Link>
                        }
                    </div>
                    <div className="side-buttons">
                        <div className="lesson-view-switch__wrapper frequency-buttons-container">
                            <span className="lesson-view-switch__text">Change frequency:</span>
                            <div className="lesson-view-switch__buttons">
                                <button className="btn-div__inline" title="View lesson frequency on the ACT" onClick={(() => this.updateFrequency('act'))}>
                                    <img
                                        className={selectedFrequency === 'ACT' ? 'full-opacity resize-icons act-icon' : 'resize-icons act-icon'}
                                        src="/assets/images/icons/tests/act_logo.svg"
                                        alt="act logo icon"
                                    />
                                </button>
                                <button className="btn-div__inline" title="View lesson frequency on the SAT" onClick={(() => this.updateFrequency('sat'))}>
                                    <img
                                        className={selectedFrequency === 'SAT' ? 'full-opacity resize-icons sat-icon' : 'resize-icons sat-icon'}
                                        src="/assets/images/icons/tests/sat_logo.svg"
                                        alt="sat logo icon"
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="lesson-view-switch__wrapper">
                            <span className="lesson-view-switch__text">See what's tested:</span>
                            <div className="lesson-view-switch__buttons1">
                                <button className="btn-div__inline" title="View English topic frequency" onClick={(() => this.props.openEnglishModal())}>
                                    <img
                                        className="resize-icons"
                                        src="/assets/images/WWA_English.svg"
                                        alt="English icon"
                                    />
                                </button>
                                <button className="btn-div__inline" title="View Math topic frequency" onClick={(() => this.props.openMathModal())}>
                                    <img
                                        className="resize-icons"
                                        src="/assets/images/WWA_Math.svg"
                                        alt="Math icon"
                                    />
                                </button>
                                <button className="btn-div__inline" title="View Reading topic frequency" onClick={(() => this.props.openReadingModal())}>
                                    <img
                                        className="resize-icons"
                                        src="/assets/images/WWA_Reading.svg"
                                        alt="Reading icon"
                                    />
                                </button>
                                <button className="btn-div__inline" title="View Science topic frequency (ACT only)" onClick={(() => this.props.openScienceModal())}>
                                    <img
                                        className="resize-icons"
                                        src="/assets/images/WWA_Science.svg"
                                        alt="Science icon"
                                    />
                                </button>
                            </div>
                        </div>
                        { !this.props.isTeacher &&
                            <div className="lesson-view-switch nav-sub-items">
                                <div className="lesson-view-switch__wrapper">
                                    <span className="lesson-view-switch__text">Select your view:</span>
                                    <div className="lesson-view-switch__buttons">
                                        {views.map((view, i) => {
                                            const selectedClass = view.type === this.props.selectedView ? 'selected' : '';
                                            const disabledClass = view.disabled ? 'disabled' : '';
                                            return <button className="btn-div__inline" title={view.title} onClick={view.disabled ? undefined : (() => this.props.onViewChange(view.type))}>
                                                    <img
                                                        key={`lesson-view${i}`}
                                                        className={`${selectedClass} ${disabledClass}`}
                                                        src={baseImgUrl + view.icon}
                                                        width="24"
                                                        height="24"
                                                        alt=""
                                                    />
                                            </button>;
                                        })}
                                    </div>
                                </div>
                            </div>
                        } 
                    </div>
                </div>
                {/* <div className="col-xl-3 col-lg-12 small-side-buttons">
                    <div className="lesson-view-switch frequency-buttons-container nav-sub-items">
                        
                    </div>
                    <div className="lesson-view-switch nav-sub-items">
                        
                    </div>
                </div> */}
            </div>
        </div>
      );
    }

    private updateFrequency(testType: string) {
      this.setState({
        selectedFrequency: testType.toUpperCase()
      });
      this.props.changeFrequency(testType);
    }
}

export function getLessonViewEnum(lessonViewStr: string) {
    switch(lessonViewStr.toUpperCase()) {
        case 'CALENDAR': return LessonView.Calendar;
        case 'COMPLETED': return LessonView.Completed;
        default: return LessonView.Subject;
    }
}
