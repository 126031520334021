import './DateInput.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';
import * as moment from 'moment';

// TODO clean this up
interface Props {
    date?: Date;
    onDateChange: (date: moment.Moment) => void;
    onDateValidation: (isValid: boolean, message: string) => void;
    labelText?: string;
    formGroupClass?: string;
    sucess?: boolean;
    invalid?: boolean;
    feedbackText?: string;
    labelClass?: string;
}

interface State {
    date?: moment.Moment;
    month: string;
    day: string;
    year: string;
    feedbackText: string;
}


export default class DateInput extends React.Component<Props, State> {
    private monthInput!: HTMLInputElement | null;
    private dayInput!: HTMLInputElement | null;
    private yearInput!: HTMLInputElement | null;

    constructor(props: Props) {
        super(props);
        const date = moment.default(props.date);
        this.state = {
            month: props.date ? (date.month() + 1).toString() : '',
            day: props.date ? date.date().toString() : '',
            year: props.date ? date.year().toString() : '',
            feedbackText: ''
        };
    }

    public render() {
        const invalidClass = this.props.invalid || this.state.feedbackText ? 'has-danger' : '';
        const successClass = this.props.sucess ? 'has-success' : '';
        const feedbackText = this.state.feedbackText || this.props.feedbackText;
        const labelClass = this.props.labelClass ? this.props.labelClass : 'text-input-label';

        return (
            <div className={`form-group ${invalidClass} ${successClass} ${this.props.formGroupClass}`}>

                {/* Title */}
                {this.props.labelText && <label className={labelClass}>
                    {this.props.labelText}</label>}

                {/* Input form */}
                <div
                    className="date-input__input-container form-control form-control-lg noselect"
                    onClick={this.redirectFormClick}
                >

                    <input
                        className="date-input__month-input"
                        type="text"
                        placeholder="MM"
                        value={this.state.month}
                        onChange={this.handleMonthChange}
                        ref={el => this.monthInput = el}
                    />

                    <span className="date-input__divider">/</span>

                    <input
                        className="date-input__day-input"
                        type="text"
                        placeholder="DD"
                        value={this.state.day}
                        onChange={this.handleDayChange}
                        ref={el => this.dayInput = el}
                    />

                    <span className="date-input__divider">/</span>

                    <input
                        className="date-input__year-input"
                        type="text"
                        placeholder="YYYY"
                        value={this.state.year}
                        onChange={this.handleYearChange}
                        ref={el => this.yearInput = el}
                    />
                </div>

                {/* Error text */}
                {feedbackText && <div className="form-control-feedback theme-feedback">* {feedbackText}</div>}
            </div>
        );
    }

    @boundMethod
    private handleMonthChange(e: React.ChangeEvent<HTMLInputElement>) {
        const month = e.target.value.substring(0, 2);
        if(!this.isValidInput(month)) { return; }

        const date = this.createMomentDate({ month });
        if(month.length === 2 && this.dayInput) {
            this.dayInput.focus();
        }

        this.setState({ month, date });
        this.fireDateChange(date);
    }


    @boundMethod
    private handleDayChange(e: React.ChangeEvent<HTMLInputElement>) {
        const day = e.target.value.substring(0, 2);
        if(!this.isValidInput(day)) { return; }

        const date = this.createMomentDate({ day });
        if(day.length === 2 && this.yearInput) {
            this.yearInput.focus();
        }

        this.setState({ day, date });
        this.fireDateChange(date);
    }

    @boundMethod
    private handleYearChange(e: React.ChangeEvent<HTMLInputElement>) {
        const year = e.target.value.substring(0, 4);
        if(!this.isValidInput(year)) { return; }

        const date = this.createMomentDate({ year });

        this.setState({ year, date });
        this.fireDateChange(date);
    }

    private createMomentDate(date: any) {
        // Use the passed in date's values if they exist
        return moment.default({
            month: parseInt(date.month === '' || date.month || this.state.month, 10) - 1,
            date: parseInt(date.day === '' || date.day || this.state.day, 10),
            year: parseInt(date.year === '' || date.year || this.state.year, 10)
        });
    }

    /**
     * Performs input date validation and fires relevent callbacks.
     * If the user entered a valid date, notifies the parent component of the new date value.
     * @param date New input date
     */
    private fireDateChange(date: moment.Moment) {
        const today = moment.default(new Date());
        const farthestBackYear = moment.default(new Date('1900'));
        const thirteenYearsAgo = moment.default().subtract(13, 'year');

        if(date.isBefore(farthestBackYear)) {
            this.fireInvalidDate('Invalid year');
        } else if(!date.isValid() || date.isAfter(today) && this.state.year.length === 4) {
            // Only show message if all input boxes are filled
            if(this.state.month && this.state.day && this.state.year) {
                this.fireInvalidDate('Invalid date');
            }
        } else if(date.isAfter(thirteenYearsAgo)) {
            this.fireInvalidDate('Must be 13 years old to sign up');
        } else {
            this.props.onDateValidation(false, '');
            this.props.onDateChange(date);
            this.setState({ feedbackText: '' });
        }
    }

    /**
     * Notifies parent component when an invalid date is entered
     * @param feedbackText Error text
     */
    @boundMethod
    private fireInvalidDate(feedbackText: string) {
        this.props.onDateValidation(true, feedbackText);
        this.setState({ feedbackText });
    }

    /**
     * Targets the months input group when the background of the form container is clicked
     */
    @boundMethod
    private redirectFormClick(e: React.MouseEvent<HTMLDivElement>) {
        // Don't focus if the user has clicked in one of the input boxes
        if((e.target as HTMLDivElement).classList.contains('date-input__input-container') && this.monthInput) {
            this.monthInput.focus();
        }
    }

    @boundMethod
    private isValidInput(dateStr: string) {
        return /^\d+$/.test(dateStr) || dateStr === '';
    }
}
