import * as React from 'react';

import { HomeWithoutRouting, Props, StoreProps } from './Home';

import { AppState } from '../../store';
import { UserInfo } from '../../services/account/account-rest-interface';
import { connect } from 'react-redux';
import { getCurrentLesson } from '../../stores/lesson.store';

const mapStateToProps = ({ account, lesson }: AppState): StoreProps => ({
    userInfo: account.userInfo as UserInfo,
    numLessonsUnstarted: lesson.numLessonsUnstarted,
    numLessonsCompleted: lesson.numLessonsCompleted,
    numLessonsStarted: lesson.numLessonsStarted,
    currentLessonId: lesson.currentLessonId,
    currentLesson: getCurrentLesson(lesson),
    mistake: lesson.mistakeBankOverview,
    allLessons: lesson.userLessons.allLessons
});

const ConnectedHome = connect<StoreProps, {}, Props, AppState>(mapStateToProps, {})(HomeWithoutRouting);

export default (props) => {
    return (
        <ConnectedHome {...props} />
    );
};
