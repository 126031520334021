import './FeedbackPopup.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import Button from '../Button/Button';
import CloseIcon from '../CloseIcon/CloseIcon';
import { Feedback } from '../../services/elearn/lesson-types';
import { RouteComponentProps } from 'react-router-dom';
import SelectInput from '../SelectInput/SelectInput';
import TextBox from '../TextBox/TextBox';

export interface Props {
  show: boolean;
  onClose: () => void;
}

interface State {
  fieldState: FieldStateMap;
  emailSent: boolean;
}

export interface StoreProps {
    topicId?: string;
    questionId?: string;
    lessonId: string;
    email?: string;
}

export interface DispatchProps {
    sendUserFeedback: (feedbackInfo: Feedback) => void;
}

export interface FieldState {
    key: string;
    value?: any;
}

interface FieldStateMap {
    [key: string]: FieldState;
}

export const initialFormState: FieldStateMap = {
    about: { key: 'about' },
    message: { key: 'message' },
};

const aboutOptions = ['Other', 'This Question'];

type AllProps = Props & StoreProps & RouteComponentProps<any> & DispatchProps;


export default class FeedbackPopup extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);

        const fieldState: FieldStateMap = Object.keys(initialFormState)
            .map((key, i, arr) => initialFormState[key])
            .map(formItem => ({ isValid: true, feedback: '', value: '', ...formItem }))
            .reduce((acc, formItem: FieldState) => ({ ...acc, [formItem.key]: formItem }), {});

        fieldState.about.value = aboutOptions[1];
        fieldState.message.value = '';

        this.state = {
                        fieldState,
                        emailSent: false
                     };
    }

    public render() {

        const btnStyle = {
                            width: '100%'
                         };
        const { fieldState } = this.state;

        const displayPopup = this.props.show ? '' : 'display-popup';

        return (
                <div className={displayPopup + ' contact-container'}>
                    <span className="close-btn" title="Close" onClick={this.props.onClose}>
                        <CloseIcon />
                    </span>
                    <div className="contact--text">
                        {!this.state.emailSent && <span>
                        <p className="header-text"> Give us feedback! </p>

                        <SelectInput
                                id="about"
                                labelText="About"
                                value={fieldState.about.value}
                                onChange={this.onAboutChange}
                                options={aboutOptions}
                                labelClass="label-text"
                        />

                        <TextBox
                            id="message"
                            labelText="Message"
                            inputType="email"
                            inputClass="text-box"
                            value={fieldState.message.value}
                            onChange={this.onMessageChange}
                            labelClass="label-text"
                            placeholder="Please enter..."
                            resizeable={false}
                        />

                        <Button
                            text="SEND"
                            textColor="white"
                            bgColor="green"
                            onClick={this.onSendEmail}
                            style={btnStyle}
                        />
                        </span>}
                        {this.state.emailSent &&
                        <div>
                           <p className="header-text"> Thank you for your feedback! </p>
                        </div>}
                    </div>
                    <span className="arrow-left" />
                </div>
            );
    }

    private updateFieldValue(fieldName: string, newValue: any, additionalParams?: Partial<FieldState>) {
        const field = {...this.state.fieldState[fieldName], value: newValue,
            ...additionalParams };
        const fieldState = { ...this.state.fieldState, [fieldName]: field };
        this.setState({ fieldState });
    }

     @boundMethod
     private onAboutChange(e: any) {
         this.updateFieldValue('about', e.target.value);
     }

     @boundMethod
     private onMessageChange(e: any) {
         this.updateFieldValue('message', e.target.value);
     }

     @boundMethod
     private onSendEmail() {
        const { fieldState } = this.state;
        const currQId = sessionStorage.getItem('currQId') || '';
        const currTopicId = sessionStorage.getItem('topicId') || '';
        const currLessonId = sessionStorage.getItem('lessonId') || '';

        const { email } = this.props;
        const feedbackInfo: Feedback = {
            userEmailAddress: email || '',
            subject: fieldState.about.value,
            message: fieldState.message.value,
            questionId: currQId,
            topicId: currTopicId,
            lessonId: currLessonId,
            url: window.location.pathname
        };
        // dispatch rest call to send email
        this.props.sendUserFeedback(feedbackInfo);
        // clear user input
        fieldState.about.value = aboutOptions[1];
        fieldState.message.value = '';
        // reset state
        this.setState({ emailSent: true }, () =>
            setTimeout(() => {
                this.setState({ fieldState, emailSent: false });
                this.props.onClose();
            }, 2000));
        return;
     }
}
