import * as React from 'react';
import * as html2pdf from "html2pdf.js";
import { PageContent, PageTitle } from '../../../components/PageContent/PageContent';
import Button from '../../../components/Button/Button';
import Loading from '../../../components/Loading/Loading';
import { sendPageviewInfo } from '../../../WinwardTracker';
// import { getCurrentIAScores, getScores } from '../../../services/practice-test-scoring/practice-test-service';
import { PracticeTestScoreEx } from '../../../services/elearn/practice-test-types';
import DiagnosticResults from '../StudentReports/DiagnosticResults';
import PracticeTestResults from '../StudentReports/PracticeTestResults';
import MistakeBankSummary from '../StudentReports/MistakeBankSummary';
import LessonSummary from '../StudentReports/LessonSummary';
// import { getHints, getAllLessons, getMistakeBankOverview, getStudentLessonInfo } from '../../../services/elearn/lesson-rest-interface';
// import { getAllHints } from '../../../services/practice-test-scoring/test-hint-service';
import { LessonInfo } from '../../../services/elearn/lesson-types';
import { createFileName } from '../../../services/utils/filename';
import { MistakeBankOverview } from '../../../services/elearn/mistake-bank-types';
import { HintsInfo, TestHintsInfo } from '../../../services/elearn/hint-types';
import { getStudentSummary } from '../../../services/elearn/teacher/teacher-student-summary';
import '../StudentReports/StudentProgressSummary.css';

export interface Props { }
interface State {
  firstName?: string
  lastName?: string
  emailAddress?: string

  scoresListACT: PracticeTestScoreEx[]
  scoresListSAT: PracticeTestScoreEx[]
  scoresListIA: PracticeTestScoreEx[]
  lessons: LessonInfo[]
  mistakeBankOverviews: MistakeBankOverview[]
  hints: HintsInfo[]
  testHints: TestHintsInfo[]
}

class TeacherStudentProgressSummary extends React.Component<Props, State> {
    constructor (props: Props) {
        super(props)

        this.state = {
          scoresListACT: [],
          scoresListSAT: [],
          scoresListIA: [],
          lessons: [],
          mistakeBankOverviews: [],
          hints: [],
          testHints: []
        }
    }

    async componentDidMount() {
      let path = window.location.pathname
      let key = "student-progress/"
      let emailStartIndex =  path.indexOf(key) + key.length
      let studentEmail = path.substring(emailStartIndex)
      let data = await getStudentSummary(studentEmail)
      this.setTestResultsACT(data.practiceTestsACT)
      this.setTestResultsIA(data.practiceTestsIA)
      this.setTestResultsSAT(data.practiceTestsSAT)
      this.setMistakeBankResults(data.mistakeBankOverviews)
      this.setLessons(data.studentLessons)
      this.setHints(data.categoryHints)
      this.setTestHints(data.practiceTestHints)

      sendPageviewInfo(path)
    }

    setTestResultsACT = (res: any) => {
      this.setState({
        scoresListACT: res
      })
    }

    setTestResultsSAT = (res: any) => {
      this.setState({
        scoresListSAT: res
      })
    }

    setTestResultsIA = (res: any) => {
      this.setState({
        scoresListIA: res
      })
    }

    setMistakeBankResults = (mistakeBankOverviews: MistakeBankOverview[]) => {
      this.setState({ mistakeBankOverviews })
    } 

    setLessons = (lessons: LessonInfo[]) => {
      if(lessons.length > 0) {
        let { firstName, lastName, emailAddress } = lessons[0]
        this.setState({
          lessons,
          firstName,
          lastName,
          emailAddress,
        })
      } else {
        let firstName = ' '
        this.setState({
          lessons,
          firstName
        })
      }
    }

    // Set hints from lessons & stay sharp
    setHints = (hints: HintsInfo[]) => {
      this.setState({hints})
    }

    // Set hints from tests (i.e. ACT|SAT|IA)
    setTestHints = (testHints: TestHintsInfo[]) => {
      this.setState({testHints})
    }

    handeClickForPdf = () => {
      let { firstName, lastName, emailAddress } = this.state
      let content = document.getElementById("pdf-main-content")
      let filename = createFileName("progress-summary-" + firstName + "-" + lastName, "pdf")
      
      let opt = { 
        filename,
        margin: 0.5,
        html2canvas: { windowWidth:920, letterRendering: true },
        image:       { type: "jpeg", quality: 0.99 },
        jsPDF:       { unit: "in", format: "letter", orientation: "portrait" },
        pagebreak:   { avoid: "hr, tr, img, .row", mode: ["css"], before: [".page-break", "br.page-break"] }
      }

      html2pdf().set(opt).from(content).toPdf().get('pdf').then(pdf => {
        let nofPages = pdf.internal.getNumberOfPages()

        for (let i = 1; i <= nofPages; i++) {
          let x = pdf.internal.pageSize.getWidth() - 1
          let y = pdf.internal.pageSize.getHeight() - 0.1
          let pageNumber = `Page ${i} of ${nofPages}`
          pdf.setPage(i);
          pdf.setFontSize(11);
          pdf.setTextColor(100);
          pdf.text(pageNumber, x, y)
        } 
      }).save()
    }

    public render() {
        let { firstName, lastName, emailAddress } = this.state
        let { scoresListACT, scoresListSAT, scoresListIA } = this.state
        let { lessons, mistakeBankOverviews, hints, testHints } = this.state
        let actDiagnostics = scoresListACT.find(s => s.testName == "Diagnostic")
        let actComposite = actDiagnostics?.satCompositeRange.find(cr => cr.active == true)
        let radialScoreACT = actDiagnostics?.average ? actDiagnostics.average: 0
        var hasACTSAT, hasLessons, hasReportData = true
        
        // show hide logic
        radialScoreACT <= 1 ? hasACTSAT = false : hasACTSAT = true
        lessons.length <= 0 ? hasLessons = false : hasLessons = true
        if(hasACTSAT === false && hasLessons === false) {
          hasReportData = false
        }
        
        return (
          <PageContent className="summary-report-page">
            <div className="progress-summary-container">
              {hasReportData != false ?
                <Controls onClickPDF={this.handeClickForPdf} onClickPrint={() => window.print()}/>
                :
                <div></div>
              }
              {!firstName ? 
                <Loading/>
                :
                <div id="pdf-main-content">                    
                    <ReportHeader />
                  {hasReportData === false ?
                    <div className="text-center no-entries-message">
                      <div className="h3">Get Started</div>
                      <p>Start with a <a href="https://learn.winwardacademy.com/app/practice-tests">diagnostic</a> or complete your first <a href="https://learn.winwardacademy.com/app/lessons">lesson</a> to see your progress.!</p>
                    </div>
                  :
                    <div>
                      <PreparedFor firstName={firstName} lastName={lastName} emailAddress={emailAddress}/>
                        {hasLessons === true ?
                          <>
                            <div>
                              <LessonSummary lessons={lessons}/>
                            </div>
                            <div>
                              <MistakeBankSummary
                                mistakeBankOverviews={mistakeBankOverviews}
                                lessons={lessons}
                                hints={hints}
                                testHints={testHints}/>
                            </div>
                          </>
                        :
                        <div></div>
                      }
                      {hasACTSAT === true ?
                        <div>
                          <DiagnosticResults diagnostics={{actScores: scoresListACT, satScores: scoresListSAT}}/>
                          <PracticeTestResults scores={{actScores: scoresListACT, satScores: scoresListSAT, iaScores: scoresListIA}}/>
                        </div>
                        :
                        <div></div>
                      }
                    </div>
                  }           
                </div>                
              }
            </div>
          </PageContent>
        )
    }
}


//--- Additional Mini Components ---
function ReportHeader(props) {
  return (
    <div className="row progress-summary-report-header">
      <div className="col-12 d-flex align-items-center">
        <img src="/assets/images/winward-academy-logo.png" alt="logo" width="80px"/>
        <div className="page-title">
          <h1>Progress Summary</h1>
        </div>
      </div>
    </div>
  )
}

function PreparedFor({firstName, lastName, emailAddress}) {
  //let preparedFor = firstName ? `Prepared exclusively for ${firstName} ${lastName}`: ""
  let preparedFor = firstName ? `Student name: ${firstName} ${lastName}`: ""
  let email = emailAddress ? `Student email: ${emailAddress}` : ""
  let dateInfo = new Date().toLocaleDateString()

  return (
    <div className="row progress-summary-report-prepared-for">
      <div className="col-md-9 mb-2 mb-md-0">
        { <h5>{preparedFor}</h5> }
        { <h5>{email}</h5> }
      </div>
      <div className="col-md-3 text-md-right mt-auto">
        <h5 className="progress-summary-report-date">{dateInfo}</h5>
      </div>
    </div>
  )
}

interface ControlsProps {
  onClickPrint: () => void
  onClickPDF: () => void
}

function Controls(props: ControlsProps) {
  return (
    <div className="control-area">
      <Button 
        text="SAVE AS PDF"
        bgColor="blue"
        onClick={props.onClickPDF}
        className="do-not-print"
      />
    </div>
  )
}

export default TeacherStudentProgressSummary
