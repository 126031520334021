import React, { Component } from 'react'
import { TestType, getSATSectionDisplayName } from '../../../../services/utils/practice-test-util'
import { ERR_CANNOT_GET_DATA } from '../../../../constants'
import { getPracticeTestSectionDetails, getTestsInsightDetails } from '../../../../services/elearn/teacher/practice-tests-insights'
import HeadingBanner from '../../../../components/HeadingBanner'
import Loading from '../../../../components/Loading/Loading'
import { capitilizeFirstLetter } from '../../../../services/utils/text-util'
import { TestInsightDetailsbySectionView } from './TestInsightDetailsSectionView'

interface Props {
  testName: string
  testType: TestType
  displayName: string
  sectionKey: string
}

interface State {
  details: any // OT: TODO
  loading: boolean
  errorMsg?: string
}


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


// Provides the practice test insight details based on the test name and test category (section)
// * ACT/IA: English, Math, Reading & Science
// * SAT: Reading, Writing & Language, Math (No Calc), Math (Calc)
// * SAT2024: R&W Module 1, R&W Module 2, Math Module 1, Math Module 2
export default class TestInsightDetailsView extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      details: {},
      loading: true,
      errorMsg: undefined
    }
  }

  async componentDidMount() {
    let {testName, testType, sectionKey} = this.props
    this.setState({loading: true, errorMsg: undefined})
    setTimeout(async () => {
      let rawData = await getTestsInsightDetails(testName)
      if (!rawData) {
        this.setState({errorMsg: ERR_CANNOT_GET_DATA})
        return
      }
      let details = await getPracticeTestSectionDetails(sectionKey, testType, rawData)
      this.setState({details, loading: false})
    }, 200)
  }

  render() {
    let {sectionKey, displayName, testType} = this.props
    let {details, errorMsg, loading} = this.state
    let sectionName = sectionKey || ""
    let sectionDisplayName = testType === TestType.SAT ? getSATSectionDisplayName(sectionName): sectionName
    let title = `${displayName}: ${capitalizeFirstLetter(sectionDisplayName)}`

    return (
      <div className="tests-insights-container">
        <HeadingBanner title={title} section={sectionName} />
        <ErrorOrLoadingInfo errorMsg={errorMsg} loading={loading}/>
        {!loading && 
          <TestInsightDetailsbySectionView details={details} sectionName={sectionName}/>
        }
      </div>
    )
  }
}


//--- Helpers ---

const LoadingMessage = () => {
  return (
    <div className="tests-insights-loading-wrapper">
      <Loading />
      <p>
        We are calculating the practice test section insights in real time. Please be patient as this process can take up to 30 seconds.
      </p>
    </div>
  )  
}

const ErrorOrLoadingInfo = ({loading, errorMsg}) => {
  return (
    errorMsg ?
      <div className="tests-insights-error">{errorMsg}</div>
      :
      loading && <LoadingMessage />
  )
}
