import React from 'react'
import { NA, SB_CURRENT_STUDY_BUDDIES, SB_PENDING_INVITES } from "../../../constants"

interface Props {

}

interface State {

}

export default function StudyBuddiesManageView(props) {
    let {buddies, invitedBuddies, onRemoveBuddy} = props
    let showCurrentBuddies = buddies.length > 0
    let showPendingInvites = invitedBuddies.length > 0

    return (
        <>
        <div className="heading-banner-basic text-start text-left mb-3">
            <h2 className="heading-banner-basic-title mb-0">Manage Study Buddies</h2>
        </div>
        <div className="studybuddyContainer container mw-100 mx-auto w-100">
            <div className="row mb-4">
                <div className="col-12">
                    <h2 className="studybuddyContainer--title">Remove Connections</h2>
                    <p className="mb-0">You can remove study buddy connections below if you wish to disconnect your accounts.</p>
                </div>
            </div>
            {showCurrentBuddies &&
                <div className={showPendingInvites ? 'mb-3' : ''}>
                    <div className="studybuddies-group--title">{SB_CURRENT_STUDY_BUDDIES}</div>
                    <div className="studybuddies-row mx-0">
                        {buddies.map(buddy => <ManagedStudyBuddy buddy={buddy} onRemoveBuddy={onRemoveBuddy} />)}
                    </div>
                </div>
            }
            
            {showPendingInvites &&
                <>
                    <div className="studybuddies-group--title">{SB_PENDING_INVITES}</div>
                    <div className="row studybuddies-row mx-0">
                        {invitedBuddies.map(buddy => <ManagedInvitedStudyBuddy buddy={buddy} onRemoveBuddy={onRemoveBuddy} />)}
                    </div>
                </>
            }
        </div>
        </>
    )
}


//--- Helpers ---

function ManagedStudyBuddy(props) {
    let {buddy, onRemoveBuddy} = props
    let {firstName, lastName, emailAddress, profileColor} = buddy

    return (
        <div className="col-12 studybuddy-col">
            <div className="studybuddy mb-3">
                <div className="studybuddy--accent" style={{"backgroundColor": profileColor}}></div>
                <div className="row w-100 mx-0">
                    <div className="studybuddy--2col">
                        <div className="studybuddy--2col inner">
                            <div className="studybuddy--avatar">
                                <div className="avatar__circle" style={{"backgroundColor": profileColor}}><p className="avatar__circle--text">{firstName[0]}{lastName[0]}</p></div>
                            </div>
                            <div>
                                <div className="studybuddy--name">
                                    {firstName} {lastName}
                                </div>
                                <div className="studybuddy--email">
                                    {emailAddress}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-0 text-center ml-auto studybuddy--actions">
                        <button className="btn btn--text-white btn--bg-purple mb-0" onClick={e => onRemoveBuddy(emailAddress)}>REMOVE BUDDY</button>
                    </div>
                </div>
            </div>
        </div>
    )
} 

function ManagedInvitedStudyBuddy(props) {
    let {buddy, onRemoveBuddy} = props
    let {firstName, lastName, emailAddress, profileColor} = buddy

    return (
        <div className="col-12 studybuddy-col">
            <div className="studybuddy">
                <div className="studybuddy--accent" style={{"backgroundColor": profileColor}}></div>
                <div className="row w-100 mx-0">
                    <div className="studybuddy--2col">
                        <div className="studybuddy--2col inner">
                            <div className="studybuddy--avatar">
                                <div className="avatar__circle" style={{"backgroundColor": profileColor}}><p className="avatar__circle--text">{firstName[0]}{lastName[0]}</p></div>
                            </div>
                            <div>
                                <div className="studybuddy--name">
                                    {firstName} {lastName}
                                </div>
                                <div className="studybuddy--email">
                                    {emailAddress}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-0 text-center ml-auto studybuddy--actions">
                        <button className="btn btn--text-white btn--bg-purple mb-0" onClick={e => onRemoveBuddy(emailAddress)}>REVOKE INVITE</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

