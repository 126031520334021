import { getCKData, updateCK, CollegeKnowledge } from '../services/ck/ck-rest-interface';

const GET_CK = 'ck/GET_CK';
const SET_CK = 'ck/SET_CK';
const UPDATE_LOCAL_CK = 'ck/UPDATE_LOCAL_CK';

export interface CKState {
    ck: Partial<CollegeKnowledge>;
}

const defaultState = {
    ck: []
};

export function getCollegeKnowledge() {
    return dispatch => {
        return getCKData()
            .then((ck = []) => dispatch(setCollegeKnowledge(ck as any)));
    };
}

export function setCollegeKnowledge(ck: CollegeKnowledge[]) {
    return {
        type: SET_CK,
        ck
    };
}

export function updateUserCK(id: string, status: string) {
    // return dispatch => {
    //     return updateCK(id, status).then(getCollegeKnowledge);
    // };
    return(dispatch) => {
        return updateCK(id, status)
            .then(() => dispatch(updateLocalCK(id, status)));
    };
}

function updateLocalCK(id: string, status: string) {
    return {
        type: UPDATE_LOCAL_CK,
        id: id,
        status: status
    };
}

export default function(state: any = defaultState, action: any) {
    switch(action.type) {
        case GET_CK: {
            return {...state };
        }
        case SET_CK: {
            const  ck  = [...action.ck ];
            return { ...state, ck };
        }
        case UPDATE_LOCAL_CK: {
            let clonedArray = [ ...state.ck ];
            let idx = clonedArray.findIndex(obj => {
                return action.id === obj.lessonId;
            });
            if (idx > -1) {
                clonedArray[idx].lessonId = action.id;
                clonedArray[idx].status = action.status;
            }
            
            return { ...state, clonedArray };
        }
        default:
            return state;
    }
}
