import { createGenericAuthRequest } from '../request/request-utils'
const baseUrl1 = process.env.REACT_APP_ACCOUNT_URL || '/'
const baseUrl2 = process.env.REACT_APP_ELEARN_URL || '/'
const request1 = createGenericAuthRequest(baseUrl1)
const request2 = createGenericAuthRequest(baseUrl2)

export interface LiveViewStatus {
    navigation?: string
    activity?: string
}

export enum Context {
    LESSON = "lesson",
    STAYSHARP = "staysharp",
    MISTAKEBANK = "mistakebank",
    TEST = "test",
    TEST_ACT = "testACT",
    TEST_SAT = "testSAT",
    TEST_IA = "testIA"
}

export enum Resource {
    BASELINE = "baseline",
    VIDEO = "video",
    QUESTION = "question",
    ANSWER = "answer",
    START = "start",
    END = "end",
    EXPLANATION_VIDEO = "video",
    EXPLANATION_INFOGRAPHIC = "explanation",
    HINT = "hint",
    ANALYSIS = "analysis"
}

/**
 * Extended user activity included TonP (Time on Platform) data
 */
export interface UserActivityEx extends LiveViewStatus {
    context?: Context
    contextId?: string
    resource?: Resource
    resourceId?: string
    answered?: number
    ended?: number
    answeredAtFirst?: boolean
    answeredCorrectly?: boolean
    attempts?: number
    timedout?: boolean
    category?: string
    timeZone?: string
}

export enum CurrentStatus {
    LIVE = "live",
    IDLE = "idle",
    AWAY = "away",
    NEVER = "never"
}
export interface LiveViewStatusFull {
    userId: string
    firstName: string
    lastName: string
    navigation: string
    activity: string
    updatedAt: string
    currentStatus: CurrentStatus
}

/**
 * Updates the live view status for the current user
 * @param status navigation & activity info 
 */
export function updateLiveViewStatus(status:LiveViewStatus) {
    return request1(`/activity`, status, { method: 'POST' })
}

/**
 * Sends the user activity stats for the user.
 * If timeZone available it sets the timeZone in the payload.
 * @param userActivityEx Extended user activity data (may include TonP data)
 * @param allowMultipleEntries allows multiple entries for this activity
 */
export function sendUserActivity(userActivity:UserActivityEx, allowMultipleEntries=false) {
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    
    if (timeZone) userActivity.timeZone = timeZone

    // Add a timestamp to force multiple entries in the backend
    if (allowMultipleEntries) {
        userActivity.resourceId = userActivity.resourceId + `-T${Date.now()}`
    }

    return request1(`/activity`, userActivity, { method: 'POST' })
}

interface IUserActivityOptions {
    allowMultipleEntries?: boolean
    delayInMs?: number // Delay in milliseconds
}

/**
 * Send user activity stats with delay
 * @param userActivity 
 * @param options 
 */
export function sendUserActivityWithDelay(userActivity: UserActivityEx, options: IUserActivityOptions={}) {
    let {allowMultipleEntries=false, delayInMs=2500} = options
    setTimeout(() => {
        sendUserActivity(userActivity, allowMultipleEntries)
    }, delayInMs)
}

/**
 * Get live view information for students
 * @returns 
 */
export function getAllStudentsLiveViewInfo() {
    return request2(`/teacher/students/activities`);
}


