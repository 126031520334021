import { connect } from 'react-redux';
import { PracticePrint } from './PracticePrint';
import { getPracticeTestByName } from '../../stores/practice-tests.store';

const mapStateToProps = (state) => { 
    return {
        practiceTestSummary: state.practiceTestSummary.testData
    };
 };

const mapDispatchToProps = { 
    getPracticeTestByName
};

export default connect(mapStateToProps, mapDispatchToProps)(PracticePrint);

// const PracticePrintContainer = connect<StoreProps, {}, Props>(mapStateToProps, mapDispatchToProps)(PracticePrint);
// export default (props) => {
//     return (
//         <PracticePrintContainer {...props} />
//     );
// };
