import { createGenericAuthRequest } from '../request/request-utils';

const request = createGenericAuthRequest(process.env.REACT_APP_ELEARN_URL || '/');

export interface CollegeKnowledge {
    categoryId: string;
    categoryName: string;
    duration: string;
    lessonId: string;
    lessonName: string;
    masterOrder: string;
    playOrder: string;
    status: string;
    subCategoryId: string;
    subCategoryName: string;
    videoId: string;
}

/**
 * Updates the data for a particular user
 * @param ckId is the marker for the college knowledge lesson
 * @param status is the status of the video, can be rewatch, 
 */
export function updateCK(ckId: string, status: string) {
    return request(`/ck/users/${ckId}`, {status}, { method: 'PUT' });
}

/**
 * Get all of the college knowledge data associated with a user
 */
export function getCKData() {
    return request(`/ck`);
}
