import React, { Component } from 'react'
import DataGrid from '../../../../components/DataGrid'
import { Column } from '../../../../components/DataGrid/types/types'
import { ITestInsightACT, ITestInsightScore } from '../../../../services/elearn/teacher/practice-tests-insights'
import { alphanumericCompare } from '../../../../services/utils/sort-util'
import { ITimeOnPlatform, getPracticeTestTimeSummaryByStudent } from '../../TimeOnPlatform/top-lesson-processor'
import { getHumanTime } from '../../../../services/utils/date-util'
import { NA } from '../../../../constants'
import { isNAConditionFoundInACTScores, isNAForGivenACTScore } from '../../../../rules/business'

let sortMethod = alphanumericCompare
let minSortVal = Number.NEGATIVE_INFINITY
let maxSortVal = Number.POSITIVE_INFINITY
let sortable = true
let columns: Column[] = [
  {
    title: 'FIRST NAME',
    dataProperty: 'studentNameFirst',
    customTooltip: 'Indicates the first name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'LAST NAME',
    dataProperty: 'studentNameLast',
    customTooltip: 'Indicates the last name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'COMPOSITE<br/>SCORE',
    dataProperty: 'scoreComposite',
    customTooltip: 'Indicates the composite score',
    sortable,
    sortMethod
  }, 
  {
    title: 'ENGLISH<br/>SCORE',
    dataProperty: 'scoreEnglish',
    customTooltip: 'Indicates the English score and college readiness',
    sortable,
    sortMethod
  }, 
  {
    title: 'MATH<br/>SCORE',
    dataProperty: 'scoreMath',
    customTooltip: 'Indicates the Math score and college readiness',
    sortable,
    sortMethod
  }, 
  {
    title: 'READING<br/>SCORE',
    dataProperty: 'scoreReading',
    customTooltip: 'Indicates the Reading score and college readiness',
    sortable,
    sortMethod
  },
  {
    title: 'SCIENCE<br/>SCORE',
    dataProperty: 'scoreScience',
    customTooltip: 'Indicates the Science score and college readiness',
    sortable,
    sortMethod
  },
  {
    title: 'REVIEW<br/>TIME',
    dataProperty: 'reviewTime',
    customTooltip: 'Indicates the total review time',
    sortable,
    sortMethod
  },
  {
    title: 'HINTS &<br/>MISTAKES',
    dataProperty: 'hintsAndMistakes',
    customTooltip: 'Displays the number of missed questions for which students wrote hints and the total number of missed questions',
    sortable,
    sortMethod
  }, 
]

interface Props {
  insights: ITestInsightACT[]
  timeOnPlatformInfo: ITimeOnPlatform[]
}

interface State { }

/**
 * Practice test insights for ACT & IA (they have the same data structure)
 */
export default class TestInsightsForACT extends Component<Props, State> {
  constructor(props) {
    super(props)
  }

  getScoreInfo = (score: ITestInsightScore) => {
    let {value, collegeReady} = score
    let isNA = isNAForGivenACTScore(score)
    let crClassName = collegeReady ? "college-ready-true": "college-ready-false"
    return () => (
      !isNA ?
        <>
          <div>{value}</div>
          <div className={crClassName}>
            <div className="th-tooltip">
              {collegeReady ?
                <div>
                  Yes
                </div>
                :
                <div>
                  No
                </div>
              }
              <span className="th-tooltip-text">{collegeReady ? "Score meets threshold for college readiness" : "Score does not meet threshold for college readiness"}</span>
            </div>
          </div>
        </>
        :
        NA
    )
  }

  getScoreComporator(score: ITestInsightScore) {
    let isNA = isNAForGivenACTScore(score)
    return isNA ? -1: score.value
  }

  getCompositeScoreInfo = (scores, composite) => {
    let isNA = isNAConditionFoundInACTScores(scores)
    return () => !isNA ? <> {composite} </> : NA
  }

  getCompositeComparator = (scores, composite) => {
    let isNA = isNAConditionFoundInACTScores(scores)
    return isNA ? -1: composite
  }

  getTimeSummaryInfo = (timeSummary) => {
    let {timeOnReviewTotal, timeOnMistakes, timeOnHints, timeOnAnalysis} = timeSummary
    let totalReviewTime = getHumanTime(timeOnReviewTotal, false, true).text
    // The following will be used in a tooltip when hovered over the total
    let mistakesTime = getHumanTime(timeOnMistakes, false, true).text
    let hintsTime = getHumanTime(timeOnHints, false, true).text
    let analysisTime = getHumanTime(timeOnAnalysis, false, true).text

    return () => (
      <>
        {totalReviewTime}
      </>
    )
  }

  // OT: Not used at this time!
  // getStatsMistakeInfo(stats) {
  //   let {nofTotalQuestionsAnswered, nofTotalQuestionsMissed} = stats
  //   let isNA = nofTotalQuestionsAnswered === 0 // If this can cause divide by zero error then NA
  //   let percentageOfMistakesInfo = `${Math.round(100*nofTotalQuestionsMissed / nofTotalQuestionsAnswered)}%`

  //   return () => (
  //     <>
  //       {!isNA ? percentageOfMistakesInfo: NA}
  //     </>
  //   )
  // }

  getStatsHintsAndMistakesInfo(stats) {
    let {nofTotalQuestionsMissed, nofTotalHintsWritten} = stats
    let info = `${nofTotalHintsWritten} / ${nofTotalQuestionsMissed}`

    return () => (
      <>
        {info}
      </>
    )
  }

  createContent = (insight: ITestInsightACT, timeOnPlatformInfo, index: number) => {
    let id = `test-insight-${index}`
    let {firstName, lastName, scores, composite, testName, emailAddress, stats} = insight
    let {nofTotalQuestionsAnswered, nofTotalQuestionsMissed, nofTotalHintsWritten} = stats
    let scoresACT = scores.act
    let {english, math, reading, science} = scoresACT
    let timeSummary = getPracticeTestTimeSummaryByStudent(timeOnPlatformInfo, testName, emailAddress )
    let data = {
      studentNameFirst: {
        content: firstName,
        comparator: firstName
      },
      studentNameLast: {
        content: lastName,
        comparator: lastName,
      },
      scoreComposite: {
        content: this.getCompositeScoreInfo(scoresACT, composite),
        comparator: this.getCompositeComparator(scoresACT, composite)
      },
      scoreEnglish: {
        content: this.getScoreInfo(english),
        comparator: this.getScoreComporator(english)
      },
      scoreMath: {
        content: this.getScoreInfo(math),
        comparator: this.getScoreComporator(math)
      },
      scoreReading: {
        content: this.getScoreInfo(reading),
        comparator: this.getScoreComporator(reading)
      },
      scoreScience: {
        content: this.getScoreInfo(science),
        comparator: this.getScoreComporator(science)
      },
      reviewTime: {
        content: this.getTimeSummaryInfo(timeSummary),
        comparator: timeSummary.timeOnReviewTotal
      },
      hintsAndMistakes: {
        content: this.getStatsHintsAndMistakesInfo(stats),
        comparator: nofTotalHintsWritten,
        subComparator: nofTotalQuestionsMissed
      }
    }    

    return {
      id,
      data,
    }
  }

  getCardBodyContent = ((insights: ITestInsightACT[], timeOnPlatformInfo) => {    
    let data = insights.map((insight, index) => this.createContent(insight, timeOnPlatformInfo, index))
    let averageMetaData = {
      columns: [
        {name: "scoreComposite", cb: (average) => `Average Composite Score: ${Math.round(average)}`},
        {name: "scoreEnglish", cb: (average) => `Average English Score: ${Math.round(average)}`},
        {name: "scoreMath", cb: (average) => `Average Math Score: ${Math.round(average)}`},
        {name: "scoreReading", cb: (average) => `Average Reading Score: ${Math.round(average)}`},
        {name: "scoreScience", cb: (average) => `Average Science Score: ${Math.round(average)}`},
        {name: "reviewTime", cb: (average) => `Average Review Time: ${getHumanTime(average, false, true).text}`},
        {name: "hintsAndMistakes", cb2: (average1, average2) => `Average Hints & Mistakes: ${Math.round(average1)} / ${Math.round(average2)}`}
      ]
    }
    
    return (
      <DataGrid
        columns={columns}
        data={data}
        averageMetaData={averageMetaData}
        emptyText="No test insight info found for students."
      />
    )
  })
  
  render() {
    let {insights, timeOnPlatformInfo} = this.props
    let details = this.getCardBodyContent(insights, timeOnPlatformInfo)
    return (
      <div className="tests-insights-details">
        {details}
      </div>
    )
  }
}
