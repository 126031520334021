import { PDF } from './pdf-types';

export const SuperHelpfulTipsArray: Array<PDF> = [
    {
        title: '8 General Tips',
        filename: '8UltimateTips.pdf',
    },
    {
        title: '10 Tips for the ACT',
        filename: '10TipsforACT.pdf'
    },
    {
        title: '10 Tips for the SAT',
        filename: '10TipsforSAT.pdf'
    },
    {
        title: 'Before, During, & After',
        filename: 'TipsforBeforeDuringAfter.pdf'
    },
];

export const MoreHelpfulResourcesArray: Array<PDF> = [
    {
        title: 'About Winward Academy',
        filename: 'AboutUs.pdf',
    },
    {
        title: 'Disproving ACT & SAT Myths',
        filename: 'DisprovingACT&SATMyths.pdf'
    },
    {
        title: 'What Does the Test Score Mean?',
        filename: 'MeaningofACTandSATScores.pdf'
    },
    {
        title: 'List of Helpful Resources',
        filename: 'ListOfHelpfulResources.pdf'
    },
];

export const UnderstandingTheProcessArray: Array<PDF> = [
    {
        title: 'ACT vs SAT',
        filename: 'ACTvsSAT.pdf',
    },
    {
        title: 'Score Choice & Superscoring',
        filename: 'ScoreChoice&Superscoring.pdf'
    },
    {
        title: 'SAT Subject Tests',
        filename: 'SATSubjectTests.pdf'
    },
    {
        title: 'AP Exams',
        filename: 'APExams.pdf'
    },
];
