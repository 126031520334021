import React, { Component, FunctionComponent } from 'react'
import { TotalClassProgress } from './top-lesson-processor'
import CategoricalSummary from './CategoricalSummary'
import ClassProgressChart from './ClassProgressChart'
import "./TotalClassProgressView.css"

interface TotalClassProgressViewProps {
  totalClassProgress: TotalClassProgress
  classSummariesByCategoryMap: any // OT: FIX TYPE
  selectedTestKind: string
}

interface TotalClassProgressViewState {}

export default class TotalClassProgressView extends Component<TotalClassProgressViewProps, TotalClassProgressViewState> {
  render() {
    const {totalClassProgress, selectedTestKind,classSummariesByCategoryMap} = this.props

    return (
      <div className="row no-gutters total-class-progress">
        <div className="col-12 tcp-xl-col">
          <ClassProgressChart totalClassProgress={totalClassProgress}/>
        </div>
        <div className="col-12 tcp-xl-col">
          <ClassCategoricalProgressView
            classSummariesByCategoryMap={classSummariesByCategoryMap}
            selectedTestKind={selectedTestKind}
          />
        </div>
      </div>
    )
  }
}

interface ClassCategoricalProgressViewProps {
  selectedTestKind: string
  classSummariesByCategoryMap: any
}

const ClassCategoricalProgressView = (props: ClassCategoricalProgressViewProps) => {
  let {classSummariesByCategoryMap, selectedTestKind} = props
  let dataEnglish = classSummariesByCategoryMap["English"]
  let dataMath =  classSummariesByCategoryMap["Math"]
  let dataReading = classSummariesByCategoryMap["Reading"]
  let dataScience = classSummariesByCategoryMap["Science"]

  return (
    <div className="categorical-progress-container">
      <div className="row no-gutters" style={{minHeight: "50%"}}>
        <div className="col-12 col-lg-6 pr-lg-2 mb-2"><CategoricalSummary category={"English"} data={dataEnglish} selectedTestKind={selectedTestKind}/></div>
        <div className="col-12 col-lg-6 mb-2"><CategoricalSummary category={"Math"} data={dataMath} selectedTestKind={selectedTestKind}/></div>
      </div>
      <div className="row no-gutters" style={{minHeight: "50%"}}>
        <div className="col-12 col-lg-6 pr-lg-2 mb-2"><CategoricalSummary category={"Reading"} data={dataReading} selectedTestKind={selectedTestKind}/></div>
        <div className="col-12 col-lg-6 mb-2"><CategoricalSummary category={"Science"} data={dataScience} selectedTestKind={selectedTestKind}/></div>
      </div>
    </div>
  )
}
