import './ExplanationButton.css';

import * as React from 'react';

export interface Props {
    onClick: () => void;
    titleAttr?: string;
}

const ExplanationButton: React.SFC<Props> = ({ titleAttr, onClick }) => {
    let titleText = titleAttr ? `View the explanation video for ${titleAttr}` : "View the explanation";
    titleText += ', opens in a modal window';
    return (
        <button title={titleText} className="practice-explanation-button" onClick={onClick}>
            <img
              className="explanation-button-image"
              src={`/assets/images/icons/v2/ico-play.svg`}
              width="20"
              height="20"
              alt=""
            />
        </button>
    );
};

ExplanationButton.defaultProps = { };
ExplanationButton.displayName = 'AnalysisButton';

export default ExplanationButton;
