import './MCAnswerPicker.css';

import React, { createRef }  from 'react';
import { boundMethod } from 'autobind-decorator';
import * as _ from 'lodash';


import Button from '../../../Button/Button';
import MCAnswerFeedback from './components/MCAnswerFeedback/MCAnswerFeedback';
import MCAnswerHint from './components/MCAnswerHint/MCAnswerHint';
import MCAnswerMistakeBank from './components/MCAnswerMistakeBank/MCAnswerMistakeBank';
import MCAnswerRadioButton from './components/MCAnswerRadioButton/MCAnswerRadioButton';

import MathJaxText from '../../../MathJaxText/MathJaxText';
import { Question } from '../../../../services/elearn/lesson-types';
import HelperStudyBuddies from "../../../../scenes/MistakeBank/StudyBuddies/HelperStudyBuddies";


interface Props {
    question: Question;
    /* Whether or not this question is a baseline question */
    isBaseline?: boolean;
    /* Called once a user has finished this question */
    onNextQuestion: () => void;
    /* Called the first time a user submits their answer for this question */
    onAnswer: (isCorrect: boolean, answerIndex: number) => void;
    /* Called when the user clicks on the Teach Me button */
    onShowTeachMeVideo: () => void;

    /* If this is the last question, we display "Finish" instead of "Next" on the button */
    isLastQuestion?: boolean;
    /* Set to true if the answer has answered this question in the past
        (e.g. they are looking at an old question) */
    userHasAnswered?: boolean;
    showButtons?: boolean;
    disabled?: boolean;
    onFocus?: () => void;
    isMistakeBank: boolean;
    addHint?: (hint: string, display: boolean) => void;
    shouldShowHintPopup?: boolean;
    useMathFont: boolean;
    mathVisible: boolean;
    lastInPassageBlock?: boolean;
    isTeacher?: boolean;
    scrollHere?: boolean;
    addToMB?: (add: boolean) => void;
    canNext: boolean;
    scroller?: any;
}

interface State {
    /**
     * Differs from props.userHasAnswered in that state.hasAnswered is set to true if the user
     * has answered while this question is the CURRENT question
     */
    hasAnswered: boolean;
    /* User's currently selected option */
    selectedOption?: number;
    /* Whether or not to display the answer to the user */
    showAnswer: boolean;
    /* determines is users first try */
    notFirstTry: boolean;
    /* reshow Next button */
    disableBtn: boolean;

    addMistakeBank: boolean;
    askForHint: boolean;
    isTeacher: boolean;
    inProgress: boolean;
    defaultTimeout: boolean;
    disableSubmit: boolean;
}

type AllProps = Props;


class MCAnswerPicker extends React.Component<AllProps, State> {
    public static defaultProps: Partial<Props> = {
        showButtons: true,
        lastInPassageBlock: false
    };

    private answerPickerRef = createRef<HTMLDivElement>();

    constructor(props: AllProps) {
        super(props);

        const showAnswer = !props.isBaseline && props.userHasAnswered || false;
        const selectedOption = showAnswer ?
            props.question.questionOptions.findIndex(option => option.isCorrect) : undefined;
        let scroller: any;
        this.state = {
            hasAnswered: false,
            showAnswer: !props.isBaseline && props.userHasAnswered || false,
            notFirstTry: false,
            selectedOption,
            disableBtn: !props.isTeacher,
            addMistakeBank: false,
            askForHint: false,
            isTeacher: props.isTeacher !== undefined && props.isTeacher === true,
            inProgress: false,
            defaultTimeout: true,
            disableSubmit: false
        };
    }

    public componentDidMount() {
        if(this.props.showButtons) {
            // TODO: find a way around having to use setTimeout here
            if (!this.props.isTeacher) {
                setTimeout(() => {
                  const { current } = this.answerPickerRef;
                  if ( current && current.children ) {
                      const { scroller } = this.props;
                      if (scroller) {
                          scroller.to(this.answerPickerRef.current);
                      } 
                  }
                }, 1);
            }
            setTimeout(() => this.setState({ defaultTimeout: false }), 1000);
        }
    }

    public onNewQuestion(nextProps: AllProps) {
        return this.props.question.questionId !== nextProps.question.questionId;
    }

    // public getDerivedStateOfProps(nextProps: AllProps) {
    public UNSAFE_componentWillReceiveProps(nextProps: AllProps) {
        if (this.props.isTeacher && nextProps.scrollHere) {
            const { scroller } = this.props;
            const { current } = this.answerPickerRef;
            if (current) {
              if (scroller) {
                setTimeout(() => scroller.to(current as HTMLDivElement), 100);
              }
            }
        }
        if(this.onNewQuestion(nextProps)) {
            // Show correct answer if 
            //  (1) this is not a baseline question 
            //  and (2) userHasAnswered
            const showAnswer = !this.state.isTeacher && !nextProps.isBaseline && !!nextProps.userHasAnswered;
            const selectedOption = showAnswer ?
                nextProps.question.questionOptions.findIndex(option => option.isCorrect) : undefined;

            // Scroll to this question in the passage list if we're switching to this question
            if(!this.props.showButtons && nextProps.showButtons) {
                setTimeout(() => {
                    // console.log('whaaa?????? TO IMPLEMENT scrollIntoView');
                    /* this.props.scrollIntoView().then(() => */
                    /* this.setState({ selectedOption, showAnswer, hasAnswered: false, */
                    /*     notFirstTry: false, disableBtn: true, inProgress: false, */
                    /*     defaultTimeout: true, disableSubmit: false })); */
                }, 0);
            } else {
                this.setState({ 
                    selectedOption, 
                    showAnswer, 
                    hasAnswered: false, 
                    notFirstTry: false, 
                    disableBtn: true, 
                    inProgress: false,
                    defaultTimeout: true,
                    disableSubmit: false
                }, () => {
                    setTimeout(() => this.setState({ defaultTimeout: false }), 1000);
                });
            }
        } else if(!this.props.showButtons && nextProps.showButtons) {
            const { scroller } = this.props;
            const { current } = this.answerPickerRef;
            if (current) {
              if (scroller) {
                setTimeout(() => scroller.to(current as HTMLDivElement), 100);
              }
            }
        }
    }

    public render() {
        const { question, showButtons, disabled, userHasAnswered, 
            useMathFont, mathVisible, lastInPassageBlock 
        } = this.props;
        const { showAnswer, selectedOption, hasAnswered, defaultTimeout } = this.state;
        const currentOption = this.getCurrentOption();
        const isCorrect = currentOption && currentOption.isCorrect;
        const { questionQualifierHtml, questionOptions } = question;
        const showNextButton = !!currentOption && currentOption.isCorrect && showAnswer
            && this.props.shouldShowHintPopup && this.state.notFirstTry
            && this.state.disableBtn && !this.props.isMistakeBank;
        
        return (
            <div 
                className={`mc-answer-picker has-buddies ${disabled ? 'mc-answer-picker--disabled' : ''}`}
                ref={this.answerPickerRef}
                id={question.questionId}
            >
                <div className="mc-answer-picker__answer-container">
                    {questionQualifierHtml && useMathFont &&
                        <MathJaxText
                            id={`mc-qualifier-text-${question.questionId}`}
                            className="mc-answer-picker__qualifier-text noselect"
                            text={questionQualifierHtml}
                            visible={mathVisible}
                            useMathFont={useMathFont}
                        /> 
                    }
                    {questionQualifierHtml && !useMathFont &&
                        <span
                            id={`mc-qualifier-text-${question.questionId}`}
                            className="mc-answer-picker__qualifier-text noselect"
                            dangerouslySetInnerHTML={{
                              __html: '<span>' + questionQualifierHtml + '</span>'
                            }}
                        />
                    }

                    {questionOptions.map((option, i) => (
                        <MCAnswerRadioButton
                            id={`${question.questionId}.${i}`}
                            key={`${question.questionId}.${i}`}
                            option={option}
                            onAnswerSelection={(() => this.handleAnswerSelection(i))}
                            selected={selectedOption === i}
                            showAnswer={showAnswer}
                            useMathFont={useMathFont}
                            disabled={this.state.isTeacher ? 
                                false : 
                                (disabled || (showAnswer && isCorrect))
                            }
                            visible={mathVisible}
                        />
                        )
                    )
                    }
                </div>

                {showButtons && <div className="mc-answer-picker__submit-btns">
                    <Button
                        text={this.props.isBaseline ? 'NO CLUE, SKIP' : 'TEACH ME'}
                        bgColor="blue"
                        onClick={this.handleTeachMeClick}
                        titleAttr={!this.state.isTeacher && (!this.props.isBaseline && !hasAnswered) ? ' ' : ''}
                        disabled={(!this.state.isTeacher && (!this.props.isBaseline && !hasAnswered) || (this.props.isBaseline && defaultTimeout))}
                    />
                    <Button
                        text={this.getSecondButtonText()}
                        bgColor="green"
                        onClick={() => this.handleSubmitAnswer(this.getSecondButtonText())}
                        titleAttr={typeof selectedOption === 'undefined' || (showAnswer && !isCorrect) || showNextButton || !this.enableButton() || this.state.disableSubmit ? ' ' : ''}
                        disabled={typeof selectedOption === 'undefined' || (showAnswer && !isCorrect) || 
                            showNextButton || !this.enableButton() || this.state.disableSubmit}
                    />
                </div>}

                {showButtons && 
                    <div className="pt-4">
                        <HelperStudyBuddies question={question} />
                    </div>
                }

                {showAnswer && currentOption && showButtons && (!userHasAnswered || hasAnswered) &&
                    <div className="mc-answer-picker__feedback">
                        <MCAnswerFeedback
                            feedback={currentOption.feedbackHtml}
                            isCorrect={currentOption.isCorrect}
                            useMathFont={useMathFont}
                        />

                        {!this.state.isTeacher && 
                          currentOption.isCorrect && 
                          !this.state.notFirstTry &&
                          !this.props.isMistakeBank &&
                          <MCAnswerMistakeBank
                              addToMistakeBank={this.addMistakeBank}
                          />
                        }

                        {currentOption.isCorrect && this.state.notFirstTry && !this.props.isMistakeBank &&
                          this.props.shouldShowHintPopup && <MCAnswerHint
                              onNextQuestion={this.props.onNextQuestion}
                              addHintToMistakeBank={this.setHint}
                              updateAskForHint={this.updateAskForHint}
                          />
                        }
                    </div>}
                {lastInPassageBlock && 
                    <div>
                    </div>
                }
            </div>
        );
    }

    private enableButton() {
        const currentOption = this.getCurrentOption();
        const buttonText = this.getSecondButtonText();
        if (currentOption && buttonText === 'CHECK ANSWER') {
            return true;
        }
        if (currentOption && buttonText === 'SUBMIT ANSWER') {
            return true;
        }
        if (currentOption && currentOption.isCorrect && buttonText === 'NEXT' && this.props.canNext) {
            return true;
        }
        if (buttonText === 'FINISH' && this.props.canNext) {
            return true;
        }
        return false;
    }

    @boundMethod
    private addMistakeBank(add: boolean) {
        const { addToMB } = this.props;
        if (addToMB) {
            addToMB(add);
        }
        this.setState({
            addMistakeBank: add
        });
    }

    private getSecondButtonText() {
        const currentOption = this.getCurrentOption();
        if(this.props.isBaseline) {
            return 'SUBMIT ANSWER';
        }
        if(this.state.showAnswer && currentOption && currentOption.isCorrect) {
            return this.props.isLastQuestion ? 'FINISH' : 'NEXT';
        }
        return 'CHECK ANSWER';
    }

    private handleAnswerSelection(index: number) {
        const currentOption = this.getCurrentOption();
        const answer = currentOption ? currentOption.answerHtml : 'NOTHING';
        // const answeredIndex = this.props.question.questionOptions.findIndex(option => option.answerHtml === answer);
        if(!currentOption || !(currentOption.isCorrect && this.state.showAnswer)) {
            this.setState({ selectedOption: index, showAnswer: false });
        }

        if(!this.props.showButtons && this.props.onFocus) {
            this.props.onFocus();
            // UM???
            // this.props.scrollIntoView().then(this.props.onFocus);
        }
    }

    @boundMethod
    private handleTeachMeClick() {
        if(this.props.isBaseline) {
            // Skip the question
            this.props.onAnswer(false, this.state.selectedOption || -1);
        } else {
            this.props.onShowTeachMeVideo();
        }
    }

    @boundMethod
    private handleSubmitAnswer(text?: string) {
        this.setState({
            inProgress: true,
            disableSubmit: this.props.isBaseline ? true : false
        }, () => {
            const currentOption = this.getCurrentOption();
            // if(this.state.hasAnswered) {
            if (this.state.selectedOption !== undefined) {
            this.props.onAnswer(currentOption ? currentOption.isCorrect : false, this.state.selectedOption);
            } else {
              console.log('selectedOption is undefined?',
                { MCAPState: this.state }
              );
            }
            // }
    
            if(this.state.addMistakeBank && this.props.addHint) {
              if (this.props.shouldShowHintPopup) {
                this.props.addHint('', this.props.shouldShowHintPopup);
              } else {
                this.props.addHint('', false);
              }
    
              // this.props.addHint('', this.state.askForHint);
              this.addMistakeBank(false);
            }
    
            if(!this.state.showAnswer && !this.props.isBaseline) {
                // User is confirming their answer for MC questions
                this.setState({ showAnswer: true, hasAnswered: true });
    
                if(currentOption && !currentOption.isCorrect) {
                    this.setState({ notFirstTry: true });
                }
            }
            if (text && (text === 'FINISH' || text === 'NEXT')) {
                this.props.onNextQuestion();
            }
        });
        
    }

    private getCurrentOption() {
        const { selectedOption } = this.state;
        return typeof selectedOption !== 'undefined' ? this.props.question.questionOptions[selectedOption] : undefined;
    }

    @boundMethod
    private setHint(message: string, display: boolean) {
        this.setState({ disableBtn: false});
        // add hint to backend
        if(this.props.addHint) {
            this.props.addHint(message, display);
        }
    }

    @boundMethod
    private updateAskForHint(checked: boolean) {
      this.setState({
        askForHint: checked
      });
    }
}

export default MCAnswerPicker; 
