import './MCAnswerFeedback.css';

import * as React from 'react';

import MathJaxText from '../../../../../MathJaxText/MathJaxText';

interface Props {
    feedback: string;
    isCorrect: boolean;
    useMathFont: boolean;
}

interface State {
  visible: boolean;
}

const MathJax = (window as any).MathJax;

class MCAnswerFeedback extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
          visible: props.useMathFont ? false : true
        };
    }

    renderMathJax() {
      MathJax.Hub.Typeset(() => { 
        this.setState({ visible: true });
      }
      );
    }

    componentDidMount() {
        this.renderMathJax();
    }
    render() {
        const { isCorrect, feedback, useMathFont } = this.props;
        const className = `mc-answer-feedback mc-answer-feedback--${isCorrect ? 'correct' : 'incorrect'}`;
        const image = `/assets/images/icons/progress/${isCorrect ?
                'check-green' : 'close-red'}.svg`;

        return (
            <div className={className}>
                <img className="mc-answer-feedback__img" src={image} alt=""  />
                <MathJaxText 
                    id="mc-answer-feedback" 
                    text={feedback} 
                    useMathFont={useMathFont} 
                    visible={this.state.visible}
                />
            </div>
        );
    }
}

// MCAnswerFeedback.displayName = 'MCAnswerFeedback';

export default MCAnswerFeedback;
