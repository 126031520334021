import * as React from 'react';

export interface Props {
  emails: Array<string>
}

const EmailList: React.SFC<Props> = ({ emails }) => {
  return(
    <ul>
      {emails.map(email => (<li key={'li' + email}>{email}</li>))}
    </ul>
  );
};

export default EmailList;
