import React from "react"
import { isValidEmailAddress } from "../../../services/utils/text-util"
import { IInvitationResult } from "./types"
import { SB_WAS_SENT_AN_INVITE } from "../../../constants"

export default function StudyBuddiesInfoCard(props) {
    let { onInvite, onEmailAddressChange, clearEmailAddress, invitationResult} = props

    return (
        <div className="study-buddy-card mistake-bank-info-card">
            <div className="mistake-bank-info-card__mix-em-up d-flex">
                <img
                    src={"/assets/images/icons/sections/study-buddy-icon_circle_white.svg"}
                    alt=""
                    width="176"
                    height="176"
                />
            </div>
            <div>
                <h6 className="info-card-title mb-4">Study Buddies Overview</h6>
                <p className="mb-2">The Study Buddies feature enables you to connect your Winward Academy account with friends with whom you like to study.</p>
                <p className="mb-4">After you sync accounts, you'll be able to see when your study buddies get questions right that you miss. Then you'll be able to reach out to them for guidance on missed questions.</p>
                <div>
                    <EmailEntry 
                        onInvite={onInvite} 
                        onEmailAddressChange={onEmailAddressChange} 
                        clearEmailAddress={clearEmailAddress} 
                        invitationResult={invitationResult} />
                </div>
            </div>
        </div>
    )
}


//---- Helpers ---
interface IEmailEntryProps {
    onInvite: (emailAddress: string) => void
    onEmailAddressChange: (emailAddress: string) => void
    clearEmailAddress: boolean
    invitationResult?: IInvitationResult
}

interface IEmailEntryState {
    emailAddress: string
}

class EmailEntry extends React.Component<IEmailEntryProps, IEmailEntryState> {
    constructor(props) {
        super(props)
        this.state = {
            emailAddress: ""
        }
    }    

    handleInvite = (e) => {
        e.preventDefault()

        let { onInvite } = this.props
        let { emailAddress } = this.state
        
        onInvite(emailAddress)
    }

    handleEntryChange = (e) => {
        let { value } = e.target
        let { onEmailAddressChange } = this.props
        this.setState({ emailAddress: value })
        onEmailAddressChange(value)
    }

    render() {
        let { emailAddress } = this.state
        let { invitationResult } = this.props

        return (
            <div className="">
                <form onSubmit={this.handleInvite}>
                    <div className="studybuddy-form form-item row mx-0 px-0">
                        <div className="mr-sm-2 col-lg-8 px-0 mb-1 mb-sm-2">
                            <input className="form-control tall mb-1" type="text" name="emailAddress" placeholder="friend@yourschool.edu" value={emailAddress} onChange={this.handleEntryChange} />
                            <div className="form-messages">
                                {invitationResult && invitationResult.success &&
                                    <div className="form-message success">
                                        <img src="/assets/images/icons/v2/ico-check-green.svg" width="15" height="15" alt="Success" />
                                        <p className="pl-1 m-0">
                                            <b><i>{emailAddress}</i></b> {SB_WAS_SENT_AN_INVITE}
                                        </p>
                                    </div>
                                }
                                {invitationResult && !invitationResult.success &&
                                    <div className="form-message error">
                                        <img src="/assets/images/icons/v2/ico-not-active-triangle.svg" width="15" height="15" alt="Error" />
                                        <p className="pl-1 m-0">
                                            {invitationResult.message}
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="form-actions">
                            <button className="btn btn--bg-blue" type="submit">INVITE</button>
                        </div>
                        <div className="form-label">
                            <label className="label mb-0" htmlFor="emailAddress"><i>Enter your study buddy's email.</i></label>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
