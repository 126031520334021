import { AppState } from '../../store';
import MistakeWithoutRouting from './MistakeBank';
import { connect } from 'react-redux';
import { getMistakeBankOverview } from '../../stores/lesson.store';
import { withRouter } from 'react-router-dom';

const mapStateToProps = ({ lesson, account }: AppState) => ({
    lessons: lesson.mistakeBankOverview,
    allLessons: lesson.userLessons.allLessons,
    userInfo: account.userInfo
});
// dispatch action to get mistake bank overview
const mapDispatchToProps = { getMistakeBankOverview };

const ConnectedMistake =
    connect(mapStateToProps, mapDispatchToProps)
    (MistakeWithoutRouting);

export default withRouter(ConnectedMistake);
