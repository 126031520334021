import './CollegeKnowledgeLessons.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';
import * as _ from 'lodash';
import { CURRICULUM_LIST } from '../../services/elearn/lesson-types';
import { PageContent } from '../../components/PageContent/PageContent';
import { RouteComponentProps, Link } from 'react-router-dom';
import Tabs, { Tab, CustomHeaderContent } from '../../components/Tabs/Tabs';
import CollapsableCard, { CollapsableCardHeader, CollapsableCardBody } from '../CollapsableCard/CollapsableCard';
import LessonListCardHeader from '../LessonListCardHeader/LessonListCardHeader';
import Loading from '../Loading/Loading';
import { Column } from '../DataGrid/types/types';
import DataGrid from '../DataGrid';
import { RowData } from '../DataGrid/types/types';
import LessonListTitle from '../LessonList/components/LessonListTitle/LessonListTitle';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import BrightcoveVideo from '../BrightcoveVideo/BrightcoveVideo';
import { UserInfo } from '../../services/account/account-rest-interface';
import { CKState } from '../../stores/ck.store';
import { hasLessons, hasResilienceReminders, hasSchoolSafety } from '../../services/account/account-utils';
import { PageTitle } from '../PageContent/PageContent';
import ExpirationDialog from '../../scenes/Home/components/ExpirationDialog';
import { Navigation, updateActivityInfo } from '../../WinwardTracker';

export interface Props extends RouteComponentProps<any> {
}

export interface State {
    ckData: any;
    videoLink: string;
    lessonId: string;
    toggleExplanationVideoPopup: boolean;
    showUnlockModal: boolean;
}

interface StoreProps {
  userInfo: UserInfo;
  ck: CKState;
}

interface DispatchProps {
    updateUserCK: (lessonId: string, status: string) => Promise<any>;
}

declare const videojs: any;
type AllProps = Props & StoreProps & DispatchProps;


export class CollegeKnowledgeLessons extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            ckData: null,
            videoLink: '',
            lessonId: '',
            toggleExplanationVideoPopup: false,
            showUnlockModal: false
        };
    }

    public render() {
        const sortStr = (a, b, c?, d?, reverseSort?) => {
            if (!reverseSort) {
              if(a < b) {
                return 1;
              }
              if(b < a) {
                  return -1;
              }
              if (b === a) {
                if (c && d) {
                  if(c < d) {
                    return 1;
                  }
                  if(d < c) {
                      return -1;
                  }
                }
              }
              return 0;
            } else {
              if(a < b) {
                return 1;
              }
              if(b < a) {
                  return -1;
              }
              if (b === a) {
                if (c && d) {
                  if(c < d) {
                    return -1;
                  }
                  if(d < c) {
                      return 1;
                  }
                }
              }
              return 0;
            }

          };

        const ckColumns: Column[] = [
            {
                title: 'LESSONS',
                dataProperty: 'lesson',
                sortable: false
            },
            {
                title: 'VIDEO',
                dataProperty: 'video',
                sortable: false
            },
            {
                title: 'STATUS',
                dataProperty: 'status',
                sortable: false
            },
        ];

        let keyNames;
        let data;
        const ckData = _.groupBy(this.props.ck.ck, 'categoryName');
        if (ckData) {
            keyNames = Object.keys(ckData);
            data = this.createCKData;
        }

        if (!ckData) {
            return (
                <Loading />
            );
        }

        let {userInfo} = this.props
        let {isTeacher=false} = userInfo

        return (
            <PageContent className="college-knowledge-list view-change-buttons-container">
                <PageTitle title="My Lessons" />
                    <div className="row blue-tabs-container">
                        <div className="col-12 text-left subject-buttons">
                        {hasLessons(this.props.userInfo) && 
                            <Link to="/app/lessons"
                                className={'btn title-button btn-not-bold btn-default white margin-right-10'}
                                type="button"
                                >
                                <div>{this.props.userInfo.bundleName}</div>
                            </Link>
                        }
                        <button
                            className={'btn title-button btn-not-bold btn-default white activeSelection arrow_box margin-right-10 ck-button'}
                            role="button"
                        >
                            <div>College Knowledge</div>
                        </button>
                        {hasSchoolSafety(this.props.userInfo) && 
                            <Link to="/app/school-safety"
                                className={'btn title-button btn-not-bold btn-default white'}
                                role="button"
                                >
                                <div>School Safety</div>
                            </Link>
                        }
                        <Link to="/app/lessons/dashboard/enrichment"
                            className={'btn title-button btn-not-bold btn-default white margin-right-10'}
                            role="button">
                            <div>Enrichment</div>
                        </Link>
                        {isTeacher && hasResilienceReminders(userInfo) && 
                            <Link to="/app/lessons/resilience-reminders"
                                className={'btn title-button btn-not-bold btn-default white'}
                                role="button"
                                title="Mindset Moments"
                                style={{width: 210}}
                                >
                                <div>Mindset Moments</div>
                            </Link>
                        }
                        </div>
                    </div>
                {keyNames.map(function(name: string, index: number) {
                    const numCompleted = ckData[name].filter(function(item: any) {
                        return item.status === 'rewatch';
                    });

                    let newData, subKeyNames;
                    let sortedCK = ckData[name].sort((ck1: any, ck2: any) => {
                      if (ck1 !== undefined && ck2 !== undefined) {
                        return ck1.playOrder - ck2.playOrder;
                      } else {
                        return 0;
                      }
                    });
                    newData = _.groupBy(ckData[name], 'subCategoryName');
                    subKeyNames = Object.keys(newData);

                    sortedCK = sortedCK.map(data);
                    const iconPath = `/assets/images/college-knowledge/${name.replace(/\s/g, '').toLowerCase()}-white.svg`;
                    return (
                        <CollapsableCard key={index}>
                            <CollapsableCardHeader>
                                <LessonListCardHeader
                                    text={name}
                                    color={'#18ab70'}
                                    icon={iconPath}
                                    numQuestionsCompleted={numCompleted.length}
                                    numQuestionsTotal={ckData[name].length}
                                >
                                </LessonListCardHeader>
                            </CollapsableCardHeader>
                            <CollapsableCardBody>
                                {
                                  subKeyNames.map(d => {
                                  for (let i = 0; i < d.length; i++) {
                                    sortedCK = newData[d].map(data);
                                    // It's supposed to be RowData, but...
                                    const anyRowData = sortedCK.map(x => x as any); 
                                    // TODO: Figure out how to provide the sortedCK to the data below...
                                    return (
                                      <div className="subcategory-lesson-list" key={`subcategory-lesson-list-${d}`}>
                                          {d.toUpperCase() !== 'NULL' &&
                                              <h6 className="subcategory-title" key={d}>
                                                  {d.toUpperCase()}
                                              </h6>
                                          }
                                              <DataGrid
                                                  className={'lesson-table tbl-3-col'}
                                                  columns={ckColumns}
                                                  data={anyRowData}
                                                  emptyText="No lessons to display"
                                              />
                                      </div>
                                    );
                                  }
                                  return (
                                    <div key={d.lessonId}></div>
                                    // <div className="subcategory-lesson-list" key={`subcategory-lesson-list-${key}`}>
                                    //     {!!d.subCategoryId &&
                                    //         <h6 className="subcategory-title" key={key}>
                                    //             {key.toUpperCase()}
                                    //         </h6>}
                                    //         <DataGrid
                                    //             columns={ckColumns}
                                    //             data={sortedCK}
                                    //             emptyText="No lessons to display"
                                    //         />
                                    // </div>
                                  );
                                })
                              }
                            </CollapsableCardBody>
                        </CollapsableCard>
                    );
                })}
                <Modal
                    className="ck-video ck-video-modal"
                    show={this.state.toggleExplanationVideoPopup}
                    onClose={this.closeModal}
                    showXTopRight={true}
                >
                    <BrightcoveVideo
                        id="ck-video"
                        videoId={this.state.videoLink}
                    >
                    </BrightcoveVideo>
                </Modal>
                <Modal
                    className="heading-banner-home unlock-modal expired-account-dialog"
                    show={this.state.showUnlockModal}
                    onClose={this.closeUnlockModal}
                    width="auto"
                >
                    <ExpirationDialog {...this.props} unlockAccount closeModal={this.closeUnlockModal} />
                </Modal>
            </PageContent>
        );
    }

    
    @boundMethod
    private createCKData(ck: any, i: number): RowData {
        const studentData = {
            lesson: {
                content: this.createLessonTitle(ck),
                comparator: ck.lessonName,
                subComparator: ck.lessonOrder
            },
            video: {
                content: ck.duration,
                comparator: ck.duration,
                subComparator: ck.lessonOrder
            },
            status: {
                content: this.createStatusButton(ck),
                comparator: this.getStatusButtonData(ck).order,
                subComparator: ck.lessonOrder
            },
            isOwned: {
                content: ck.isOwned,
                comparator: ck.isOwned,
            }
        };

        return {
            data: studentData,
            id: `lesson-data${i}`
        };
    }

    private popUnlockModal = () => {
        this.setState({
            showUnlockModal: true
        });
    }

    private closeUnlockModal = () => {
        this.setState({
            showUnlockModal: false
        });
    }

    private createLessonTitle(ckLesson: any) {
        const lessonStatus = this.getStatusButtonData(ckLesson).text;
        const icon = '/assets/images/icons/v2/' + this.getLessonTitleIcon(ckLesson).img;
        return () => (
            <LessonListTitle
                enabled={true}
                icon={icon}
                title={lessonStatus + ': ' + ckLesson.lessonName}
                lessonName={ckLesson.lessonName}
                onIconClick={() => ckLesson.isOwned === 'false' ?
                this.popUnlockModal() :
                this.openExplanationVideo(ckLesson)}
            />
        );
    }

    private getLessonTitleIcon(ck: any) {
        switch(ck.status) {
            case 'resume': return { img: 'ico-resume.svg', alt: 'In Progress' };
            case 'rewatch': return { img: 'ico-summary.svg', alt: 'Completed' };
            default: return { img: 'ico-start.svg', alt: 'To Start' };
        }
    }

    private createStatusButton(ckLesson: any) {
        const statusButtonData = this.getStatusButtonData(ckLesson);
        return () => (
            <Button
                textColor={'white'}
                bgColor={statusButtonData.color as any}
                text={statusButtonData.text}
                className="lesson-list__btn"
                titleAttr={statusButtonData.text + ': ' + ckLesson.lessonName}
                onClick={() => ckLesson.isOwned === 'false' ? 
                this.popUnlockModal() :
                this.openExplanationVideo(ckLesson)}
            />
        );
    }

    private getStatusButtonData(ck: any) {
        if (ck.isOwned === 'false') {
            switch(ck.status) {
                case 'resume': return { text: 'RESUME', color: 'gray', order: 2 };
                case 'rewatch': return { text: 'REWATCH', color: 'gray', order: 3 };
                default: return { text: 'START', color: 'gray', order: 1 };
            }    
        } else {
            switch(ck.status) {
                case 'resume': return { text: 'RESUME', color: 'green', order: 2 };
                case 'rewatch': return { text: 'REWATCH', color: 'purple', order: 3 };
                default: return { text: 'START', color: 'blue', order: 1 };
            }
        }
    }

    private openExplanationVideo(lesson: any) {
        let self = this;
        let { videoId: link, lessonId, lessonName }  = lesson

        updateActivityInfo(Navigation.Lessons, "College Knowledge: " + lessonName)

        this.setState({
          videoLink: link,
          lessonId: lessonId
        }, function() {
            const player = videojs('ck-video');
            player.catalog.getVideo(Number(link), (error, playerVid) => {
                const response = !!error && JSON.parse(error.response);
                if(response && response[0] && response[0].error_code) {
                    console.error(response);
                    alert(response[0].error_code);
                } else {
                    self.props.updateUserCK(lessonId, 'resume');
                    player.catalog.load(playerVid);
                    self.setState({
                        toggleExplanationVideoPopup: !self.state.toggleExplanationVideoPopup,
                    });
                    player.on('ended', function() {
                        self.props.updateUserCK(self.state.lessonId, 'rewatch');
                    });
                }
            });
        });
    }

    @boundMethod
    private closeModal() {
        const myplayer = videojs('ck-video');
        const { lessonId } = this.state;
        myplayer.pause();
        this.setState({
            toggleExplanationVideoPopup: !this.state.toggleExplanationVideoPopup,
        }, this.componentDidMount);

    }

    private doNothing() {
        return true;
    }
}
