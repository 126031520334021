import './DiagnosticTiming.css';

import * as React from 'react';

import { Link, RouteComponentProps } from 'react-router-dom';

import { PageContent } from '../../../../components/PageContent/PageContent';
import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import BrightcoveVideo from '../../../../components/BrightcoveVideo/BrightcoveVideo';
import Loading from '../../../../components/Loading/Loading';
import DiagnosticTimingRow from './DiagnosticTimingRow/DiagnosticTimingRow';

interface Props {
    testName: string;
}

interface State {
    showConfirmationModal: boolean;
    link: string;
    test: string;
    isSAT: boolean;
    isDiagnostic: boolean;
    videoId: string;
    showLoading: boolean;
    showVideoInModal:boolean;
 }

 type AllProps = Props & RouteComponentProps<any>;

export class DiagnosticTiming extends React.Component<AllProps, State> {

    videoRef = React.createRef<BrightcoveVideo>()

    showVideo = (videoId) => {
        this.setState({showVideoInModal: true, videoId})

        // OT: Loading the video causes flashing, so I first cover it...
        // and after a short time I reveal the video in order to prevent flashing!
        setTimeout(() => {
            this.setState({showLoading: false})
        }, 500)
    }

    handleOnModalClose = () => {
        this.setState({showVideoInModal: false, showLoading: true})
        this.videoRef.current?.pause()
    }

    constructor(props: AllProps) {
        super(props);
        const currentUrl = window.location.href;
        const endingPath = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
        this.state = {
            showConfirmationModal: false,
            link: props.testName === 'diagnostic' ?
            `/app/practice-tests/instructions/timing/${endingPath}` :
            `/app/practice-tests/instructions/timing/${endingPath}`,
            test: window.location.href.substr(window.location.href.lastIndexOf('/') + 1),
            isSAT: false,
            isDiagnostic: (window.location.href.includes('diagnostic')) ? true : false,
            videoId:'',
            showLoading: false,
            showVideoInModal: false
        };
    }

    public render() {
        let currentTestName = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
        const currentUrl = window.location.href;
        const endingPath = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
        const testNumber = endingPath.slice(endingPath.length - 1);
        const imageBasePath = `/assets/images/icons/diagnostic`;
        let buttonText = `READY TO ENTER ANSWERS`;
        let testName = `ACT Test ${testNumber}`;
        let link = `/app/practice-tests/scoring/${endingPath}`;
        let type = 'ACT';
        let isSAT = false;
        let isDiagnostic = false;
        let selectedVideoId = this.state.videoId
        let desiredWidth = "80%"
        let bigPlayerStyle = {
            width: '100%',
            maxWidth: '100%',
            height: 'auto'
        }

        if (currentUrl.indexOf('Diagnostic') >= 0) {
            testName = 'ACT Diagnostic';
            link = `/app/practice-tests/scoring/Diagnostic`;
        }

        // console.log('TEST?', this.state.test);

        // { test } = this.state;
        if( this.state.test !== 'Diagnostic' 
        && !this.state.test.startsWith('ACT')) {
            type = 'SAT';
            testName = `SAT Test ${testNumber}`;
        }
        if(this.state.test === 'DiagnosticSAT') {
            link = `/app/practice-tests/scoring/DiagnosticSAT`;
            testName = 'SAT Diagnostic';
        }
        return (
            <PageContent>
                <div className="diagnostic-instructions-timing">
                    {
                        type === 'ACT' &&
                        <p className="act-logo-holder">
                            <img src="/assets/images/act-logo.png" alt="ACT Logo" />
                        </p>
                    }
                    
                    {
                        type === 'SAT' &&
                        <p className="act-logo-holder">
                            <img src="/assets/images/sat-logo.png" alt="SAT Logo" />
                        </p>
                    }
                    <h2 className="text-center fw-700">Here are the proctoring instructions for the {testName}:</h2>

                    {type === 'ACT' &&
                        <p className="light-gray-background">
                            <ul>
                                <li>Please be sure you're in a <b>quiet space</b> to emulate the real testing conditions</li>
                                <li>Set your timer so you get a <b>5-minute warning</b> in each section</li>
                                <li><b>Don't leave any question blank.</b> Be sure to put a star next to any question on which you guessed.</li>
                                <li>Be sure you've printed the <a href="/assets/images/ACT_Scantron.pdf" target="_blank" className="hyperlink mx-0">scantron</a> to <b>bubble your answers</b> by hand as you go. That part takes time, so you have to practice your bubbling, too!</li>
                                <li>If you're looking to follow along with a proctoring video that times each section, gives 5 minute warnings, and provides tips along the way, then you may do so for <button className="btn-simple hyperlink mx-0" onClick={() => this.showVideo("6268507970001")}>regular time</button> or for <button className="btn-simple hyperlink mx-0" onClick={() => this.showVideo("6268505297001")}>1.5 extended time</button>.</li>
                            </ul>
                        </p>
                    }

                    {type === 'SAT' &&
                    <p className="light-gray-background">
                        <ul>
                            <li>Please be sure you're in a <b>quiet space</b> to emulate the real testing conditions</li>
                            <li>Set your timer so you get a <b>5-minute warning</b> in each section</li>
                            <li><b>Don't leave any question blank.</b> Be sure to put a star next to any question on which you guessed.</li>
                            <li>Be sure you've printed the <a href="/assets/sat/SAT1_Scantron.pdf" target="_blank" className="hyperlink mx-0">scantron</a> to <b>bubble your answers</b> by hand as you go. That part takes time, so you have to practice your bubbling, too!</li>
                            <li>If you're looking to follow along with a proctoring video that times each section, gives 5 minute warnings, and provides tips along the way, then you may do so for <button className="btn-simple hyperlink mx-0" onClick={() => this.showVideo("6268507103001")}>regular time</button> or for <button className="btn-simple hyperlink mx-0" onClick={() => this.showVideo("6268510563001")}>1.5 extended time</button>.</li>
                        </ul>
                    </p>
                    }
                    { type === 'ACT' &&
                    <div className="row no-gutters">
                        <div className="offset-lg-1 col-lg-10 offset-md-0 col-md-12">
                            <div className="row black no-gutters">
                                <div className="col-sm-1 hidden-sm-down"></div>
                                <div className="col-4 col-md-3">
                                    <p className="fw-700 mb-none section-header">Section</p>
                                </div>
                                <div className="col-4">
                                    <p className="fw-700 mb-none section-header">Regular Time</p>
                                </div>
                                <div className="col-4">
                                    <p className="fw-700 mb-none section-header">1.5x <span className="hide-on-mobile">Extended </span>Time</p>
                                </div>
                            </div>
                            
                            <DiagnosticTimingRow 
                                icon={`${imageBasePath}/english-icon.svg`} 
                                title="English" 
                                numMinutes="45" 
                                numExtendedMinutes="70" 
                                color="#9d59cf" 
                            />
                            <DiagnosticTimingRow 
                                icon={`${imageBasePath}/math-icon.svg`} 
                                title="Math "
                                numMinutes="60" 
                                numExtendedMinutes="90" 
                                color="#31b8d4" 
                            />
                            <DiagnosticTimingRow 
                                icon={`${imageBasePath}/break-icon.svg`} 
                                title="Break" 
                                numMinutes="10" 
                                numExtendedMinutes="15" 
                                color="#f2f2f2" 
                                textColor="#2B2D2D"
                            />
                            
                            <div className="row no-gutters">
                                <div className="offset-lg-1 col-lg-10 offset-md-0 col-md-12">
                                    <p className="text-center diagnostic-copy">
                                        This is your only break. Use it wisely to recharge so you're ready to focus on the-back-to-back reading and science sections. Get a snack, use the restroom, wash your face with cold water, do jumping jacks, whatever you need to recharge and stay focused for the second half.
                                    </p>
                                </div>
                            </div>

                            <DiagnosticTimingRow 
                                icon={`${imageBasePath}/reading-icon.svg`} 
                                title="Reading" 
                                numMinutes="35" 
                                numExtendedMinutes="55" 
                                color="#f29000" 
                            />
                            <DiagnosticTimingRow 
                                icon={`${imageBasePath}/science-icon.svg`} 
                                title="Science" 
                                numMinutes="35" 
                                numExtendedMinutes="55" 
                                color="#78b821" 
                            />

                            <div className="row no-gutters">
                                <div className="offset-lg-1 col-lg-10 offset-md-0 col-md-12">
                                    <p className="text-center diagnostic-copy">
                                        If you're taking the ACT without Writing, you'll be done after Science. If you're taking the ACT with Writing, then you would next complete the essay in 40 minutes (60 minutes for 1.5x extended time).
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                { type === 'SAT' &&
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <div className="row black no-gutters">
                                <div className="col-sm-1 hidden-sm-down"></div>
                                <div className="col-4 col-md-5">
                                    <p className="fw-700 mb-none section-header">Section</p>
                                </div>
                                <div className="col-3">
                                    <p className="fw-700 mb-none section-header">Regular Time</p>
                                </div>
                                <div className="col-3">
                                    <p className="fw-700 mb-none section-header">1.5x <span className="hide-on-mobile">Extended </span>Time</p>
                                </div>
                            </div>
                            <DiagnosticTimingRow 
                                icon={`${imageBasePath}/reading-icon.svg`} 
                                title="Reading" 
                                numMinutes="65" 
                                numExtendedMinutes="98" 
                                color="#f29000" 
                                sat 
                            />
                            <DiagnosticTimingRow 
                                icon={`${imageBasePath}/break-icon.svg`} 
                                title="Break" 
                                numMinutes="10" 
                                numExtendedMinutes="5" 
                                color="#f2f2f2" 
                                textColor="#2B2D2D"
                                sat 
                            />
                            <div className="row no-gutters">
                                <div className="offset-lg-1 col-lg-10 offset-md-0 col-md-12">
                                    <p className="text-center diagnostic-copy">
                                    Use your break wisely to recharge. Get a snack, use the restroom, wash your face with cold water, do jumping jacks, whatever you need to stay focused.
                                    </p>
                                </div>
                            </div>
                            <DiagnosticTimingRow 
                                icon={`${imageBasePath}/english-icon.svg`} 
                                title="Writing and Language" 
                                numMinutes="35" 
                                numExtendedMinutes="53" 
                                color="#9d59cf" 
                                sat 
                            />
                            <DiagnosticTimingRow 
                                icon={`${imageBasePath}/math-icon.svg`} 
                                title="Math (No Calculator)" 
                                numMinutes="25" 
                                numExtendedMinutes="38" 
                                color="#31b8d4" 
                                sat 
                            />
                            <DiagnosticTimingRow 
                                icon={`${imageBasePath}/break-icon.svg`} 
                                title="Break" 
                                numMinutes="5" 
                                numExtendedMinutes="5" 
                                color="#f2f2f2" 
                                textColor="#2B2D2D"
                                sat 
                            />
                            <DiagnosticTimingRow 
                                icon={`${imageBasePath}/math-icon.svg`} 
                                title="Math (Calculator)" 
                                numMinutes="55" 
                                numExtendedMinutes="83" 
                                color="#31b8d4" 
                                sat 
                            />
                            <div className="row no-gutters">
                                <div className="offset-lg-1 col-lg-10 offset-md-0 col-md-12">
                                    <p className="text-center diagnostic-copy">
                                    If you're taking the SAT without the Essay, you'll be done after Math. If you're taking the SAT with the Essay, then you would next complete the essay in 50 minutes (75 minutes for 1.5x extended time).                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="row">
                        <div className="offset-lg-1 col-lg-10 offset-md-0 col-md-12 text-center footer-section">
                            <p className="fw-700 black">When you’re done taking the exam, you’re ready to enter your answers. You’ll get immediate feedback on whether your answer is correct (green) or incorrect (red) with a running total of your accuracy and score. Every question is broken down by lesson and topic with a step-by-step explanation video.</p>
                            <Link to={link}>
                                <Button text={buttonText} />
                            </Link> 
                        </div>
                    </div>
                </div>
                <Modal
                        className="heading-banner-tc modal diagnostic-confirmation-modal"
                        show={this.state.showConfirmationModal}
                        onClose={this.closeConfirmationModal}
                    >
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h2 className="cushion-element-tc">Quick Check</h2>
                            </div>
                            <div className="body-tc container-fluid coverBottom-tc text-center">
                                <p>Do you have the exam and scantron printed?</p>
                                <div>
                                    <Button className="btn btn-info no-wrap" text="NO, GO BACK" onClick={this.closeConfirmationModal} />
                                    <Button className="btn continue-btn no-wrap" text="YES, CONTINUE" bgColor="green" onClick={this.closeAndNext} />
                                </div>

                            </div>
                        </div>
                    </Modal>
                    <Modal
                        show={this.state.showVideoInModal}
                        width={desiredWidth}
                        showXTopRight={true}
                        onClose={() => this.handleOnModalClose()}>
                            <div style={{position:"relative"}}>
                                <BrightcoveVideo
                                    ref = {this.videoRef}
                                    id={`video-in-modal-${selectedVideoId}`}
                                    videoId={selectedVideoId}
                                    style={bigPlayerStyle}
                                />
                                {this.state.showLoading &&
                                    <div style={{position:"absolute",
                                        height: "100%",
                                        width: "100%",
                                        top: 0,
                                        zIndex: 1,
                                        backgroundColor: "white"}}>
                                        <div style={{position: "absolute", top: "42%", left: "50%"}}>
                                            <div style={{position: "relative", left: "-50%", top: "-50%"}}>
                                                <Loading />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                    </Modal>
            </PageContent>
        );
    }

    popConfirmationModal = () => {
        if (this.props.testName === 'diagnostic' || this.props.testName === 'DiagnosticSAT') {
          this.setState({
            showConfirmationModal: true
          });
        } else {
          this.props.history.push(`${this.state.link}`);
        }
      }

      closeConfirmationModal = () => {
          this.setState({
              showConfirmationModal: false
          });
      }

      closeAndNext = () => {
          this.setState({
              showConfirmationModal: false
          }, () => {
              const currentUrl = window.location.href;
              const endingPath = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
              if (endingPath.toLowerCase() === 'diagnosticsat') {
                  this.props.history.push('/app/practice-tests/instructions/timing/DiagnosticSAT');
              } else {
                  this.props.history.push(`${this.state.link}`);
              }
          });
      }

      routeTo = (path: string) => {
        let isSAT = path === 'diagnostic' ? false : true;
        let currUrl = window.location.href;
        let isDiagnostic = currUrl.includes('diagnostic') ? true : false;
        this.setState({
            isSAT: isSAT,
            isDiagnostic: isDiagnostic
        }, () => {
            this.props.history.push(path);
        });
    }
}
