import * as React from 'react';

import LessonList from './LessonList';

import { withRouter } from 'react-router-dom';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { setPriority } from '../../stores/lesson.store';

const mapStateToProps = ({ account }: AppState) => ({
    userInfo: account.userInfo,
});

const mapDispatchToProps = { setPriority };

const ConnectedLessonList =  connect<any, any, any, AppState>(mapStateToProps, mapDispatchToProps)(LessonList);

export default withRouter(ConnectedLessonList);
