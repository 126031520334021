import * as React from 'react';

import './TopicMetric.css';

export interface Props {
    correctCount: number;
    total: number;
}

const TopicMetric: React.SFC<Props> = ({ correctCount, total }) => {
    const isNoop = correctCount === 0 && total === 0;
    const percentage = ((correctCount / total) * 100);
    return (
        <td className={`topic-metric ${isNoop ? ' noop' : ''}`}>
            {isNoop ? 'N/A' :
                <div>
                    <span>{correctCount}</span>/<span>{total}</span>
                    <div><b>{Math.round(percentage)}%</b></div>
                </div>
            }
            </td>
        );
};

export default TopicMetric;
