import './AccountInfo.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { UserInfo, setNewPassword } from '../../../../services/account/account-rest-interface';

import Button from '../../../../components/Button/Button';
import DisplayAccountInfo from './components/DisplayAccountInfo/DisplayAccountInfo';
import EditAccountInfo from './components/EditAccountInfo/EditAccountInfoContainer';
import PasswordForm from '../../../../components/PasswordForm/PasswordForm';
import UserAvatar from '../../../../components/SearchBar/components/UserAvatar/UserAvatarContainer';

interface Props {
}

export interface StoreProps {
    userInfo: UserInfo;
}

interface State {
    isEditing: boolean;
    showPasswordForm: boolean;
    isPasswordValid: boolean;
    password: string;
    passwordError: string;
    passwordUpdatedSuccess: boolean;
    passwordChangeAttempt: boolean;
    submit: boolean
}

export interface DispatchProps {
    updateUser: (id: string, info: Partial<UserInfo>) => Promise<UserInfo>;
}

export type AllProps = DispatchProps & Props & StoreProps;


export default class AccountInfo extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);

        this.state = {
            isEditing: false,
            showPasswordForm: false,
            isPasswordValid: false,
            passwordUpdatedSuccess: false,
            password: '',
            passwordError: '',
            passwordChangeAttempt: false,
            submit: false
        };
    }

    public render() {
        const { isEditing, showPasswordForm, submit } = this.state;
        const { userInfo } = this.props;
        const { firstName, lastName, profileColor } = userInfo;
        const intials = firstName ?
                        firstName[0] + lastName[0] : '';

        return (
            <div className="account-info">
                <div className="account-info__header row">
                    {/* Title + Avatar */}
                    <div className="account-info__title col-lg-6 mb-3 mb-lg-0">
                        <p>Account Info</p>
                        <UserAvatar
                            avatar_color={profileColor}
                            initials={intials.toLocaleUpperCase()}
                            isNavBar={false}
                            onColorChange={this.onProfileColorChange}
                            user={this.props.userInfo}
                        />
                    </div>
                    {/* Edit buttons */}
                    <div className="col-lg-6">
                        <div className="account-info__edit-btns justify-content-lg-end ml-lg-auto">
                            {(!isEditing && !showPasswordForm) &&
                                <div>
                                    <Button
                                        text="EDIT"
                                        textColor="white"
                                        bgColor="blue"
                                        onClick={this.toggleEditInfo}
                                    />
                                    <Button
                                        text="CHANGE PASSWORD"
                                        textColor="white"
                                        bgColor="green"
                                        onClick={this.toggleChangePassword}
                                        className="ml-sm-2"
                                    />
                                </div>
                            }

                            {(!showPasswordForm && isEditing) &&
                                <div>
                                    <Button
                                        text="CANCEL"
                                        textColor="white"
                                        bgColor="blue"
                                        onClick={this.cancelEdits}
                                    />
                                    <Button
                                        text="SUBMIT"
                                        textColor="white"
                                        bgColor="green"
                                        onClick={this.handleSubmit}
                                        className="ml-sm-2"
                                    />
                                </div>
                            }
                            
                            {(!isEditing && showPasswordForm) &&
                                <div>
                                    <Button
                                        text="CANCEL"
                                        textColor="white"
                                        bgColor="blue"
                                        onClick={this.cancelEdits}
                                    />
                                    <Button
                                        text="SUBMIT"
                                        textColor="white"
                                        bgColor="green"
                                        onClick={this.toggleChangePassword}
                                        disabled={!this.state.isPasswordValid && this.state.showPasswordForm}
                                        className="ml-sm-2"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {this.state.passwordChangeAttempt && !showPasswordForm &&
                    <div className="row">
                        <div className="col-lg-12 password-feedback-pending">
                            Updating password...
                        </div>
                    </div>
                }
                {this.state.passwordUpdatedSuccess && !showPasswordForm && !this.state.passwordChangeAttempt && 
                    <div className="row">
                        <div className="col-lg-12 password-feedback">
                            Password has been successfully updated!    
                        </div>
                    </div>
                }

                <div className="row">
                    {!showPasswordForm && 
                        <div className="col-lg-6">
                            {isEditing ?
                                <EditAccountInfo info={this.props.userInfo} submit={submit} /> :
                                <DisplayAccountInfo info={this.props.userInfo} />}
                        </div>
                    }

                    <div className="col-lg-6">
                        <form action="" className="form" onSubmit={this.handleChangePassword}>
                        {showPasswordForm && <PasswordForm
                            onValidPassword={this.handleValidPassword}
                            onInvalidPassword={this.handleInvalidPassword}
                            formGroupClass="account-info__password-form col-lg-6"
                        />}
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    @boundMethod
    private toggleEditInfo(e: Event) {
        e.preventDefault();
        this.setState({ 
            isEditing: !this.state.isEditing, 
            passwordChangeAttempt: false, 
            passwordUpdatedSuccess: false
        });
    }

    @boundMethod
    private handleSubmit(e: Event) {
        e.preventDefault()        
        this.setState({submit: true}, () => {
            // OT: Clear submit first!
            this.setState(({submit: false}))
            setTimeout(() => {
                this.setState({ 
                    isEditing: false, 
                    passwordChangeAttempt: false, 
                    passwordUpdatedSuccess: false,
                })
            }, 200)
        })
    }

    @boundMethod
    private toggleChangePassword(e: Event) {
        e.preventDefault();
        this.setState({ 
            showPasswordForm: !this.state.showPasswordForm, 
            passwordChangeAttempt: false, 
            passwordUpdatedSuccess: false }, () => {
            if(!this.state.showPasswordForm) {
                this.handleChangePassword();
            }
        });
    }

    @boundMethod
    private cancelEdits(e: Event) {
        e.preventDefault();
        this.setState({
            showPasswordForm: false,
            isEditing: false
        });
    }

    @boundMethod
    private onProfileColorChange(profileColor: string) {
        this.props.updateUser(this.props.userInfo.id, { profileColor });
    }

    @boundMethod
    private handleValidPassword(password: string) {
        this.setState({ isPasswordValid: true, password });
    }

    @boundMethod
    private handleInvalidPassword() {
        this.setState({ isPasswordValid: false });
    }

    @boundMethod
    private handleChangePassword() {
        if(!this.state.isPasswordValid) {
            return;
        }
        this.setState({
            passwordChangeAttempt: true
        }, () => {
            setNewPassword(this.state.password)
            .then(() => this.setState(
                { 
                    passwordUpdatedSuccess: true, 
                    passwordChangeAttempt: false,
                    showPasswordForm: false, 
                    isPasswordValid: false  
                }))
            .catch(() => this.setState({ 
                isPasswordValid: false, 
                passwordChangeAttempt: false, 
                passwordError: 'Could not update password' 
            }));
        });
    }
}
