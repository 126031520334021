import './CompleteDiagnosticCard.css';

import * as React from 'react';

import Modal from '../../../../components/Modal/Modal';
import { PracticeTestScore } from '../../../../services/elearn/practice-test-types';
import RadialProgress from '../../../../components/RadialProgress/RadialProgress';

export interface Props {
    score: PracticeTestScore | any;
    totalScore: number;
    percentile: string;
    openConversion: () => void;
}

const CompleteDiagnosticCard: React.SFC<Props> = (props) => {
    const { score } = props;
    // const testName = props.score.testName;
    const displayName = score.displayName;
    // const testNumber = props.score.testName[props.score.testName.length - 1];
    const radialScore = score.average;
    const prePercentile = score.percentile.slice(0, -2);
    const postPercentile = score.percentile.slice(-2).toUpperCase();
    let testType = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    let type = 'ACT';
    if(testType.includes('sat')) {
        type = 'SAT';
    }
    let newScore = radialScore + '';
    let margin = 0
    return (
        <div className="practice-test-card-container complete-diagnostic-card">
            <div className="practice-test-card-subcontainer">
                <div className="row">
                    <div className="col-12 col-md-5">
                        <div className="row mb-3 mb-md-0">
                            <div className="practice-test-title">
                                <h3 className="practice-test-title-text">{type} Diagnostic Breakdown</h3>
                            </div>
                            {
                                type === 'ACT' &&
                                <div className="col-6 d-flex flex-column align-items-center spacer-right">
                                <RadialProgress
                                    size={150}
                                    radius={45}
                                    startColor="#05b4b9"
                                    endColor="#05b4b9"
                                    pegOffset={30}
                                    progress={score.average / props.totalScore * 100}
                                    text={radialScore + ''}
                                    textStyle={{fontSize: '33px', marginLeft: margin, color: '#2B2D2D', fontWeight: 200}}
                                    progressLineWidth={10}
                                    backgroundLineWidth={10}
                                />
                                {/* <p className="diagnostic-percentile text-center d-flex justify-content-center align-items-center">
                                    <span className="percentile-text">{score.average}</span>
                                </p> */}
                                <p className="text-center footer-text">Diagnostic<br />Score</p>
                            </div>
                            }
                            {
                                type === 'SAT' &&
                                <div className="col-6 d-flex flex-column align-items-center spacer-right">
                                <RadialProgress
                                    size={150}
                                    radius={45}
                                    startColor="#05b4b9"
                                    endColor="#05b4b9"
                                    pegOffset={30}
                                    progress={score.average / props.totalScore * 100}
                                    text={radialScore + ''}
                                    textStyle={{fontSize: '33px', marginLeft: margin, color: '#2B2D2D', fontWeight: 200}}
                                    progressLineWidth={10}
                                    backgroundLineWidth={10}
                                />
                                {/* <p className="diagnostic-percentile text-center d-flex justify-content-center align-items-center">
                                    <span className="percentile-text">{score.average}</span>
                                </p> */}
                                <p className="text-center footer-text">Diagnostic<br />Score</p>
                            </div>
                            }
                            <div className="col-6 d-flex flex-column align-items-center border-left" style={{marginTop: '8px'}}>
                                <p className="diagnostic-percentile text-center d-flex align-items-center">
                                    <span className="percentile-text">{prePercentile}<sup className="number-suffix">{postPercentile}</sup></span>
                                </p>
                                <p className="text-center footer-text" style={{marginTop: '8px'}}>Diagnostic<br />Percentile</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 col-12 act-sat-conversion-table">
                        <div className="row text-center">
                            <div className="col-5">
                                <img alt="" src="/assets/images/icons/tests/act_logo.svg" className="text-image" />
                            </div>
                            <div className="col-2"></div>
                            <div className="col-5">
                                <img alt="" src="/assets/images/icons/tests/sat_logo.svg" className="text-image" />
                            </div>
                        </div>
                        {score.satCompositeRange.map((obj, i) => {
                            return (
                                <div
                                    key={i}
                                    className={'row text-center act-sat-row ' + (obj.active ? 'active-row' : '')}
                                >
                                    <div className={'col-5 conversion-table-text ' + (obj.active ? 'fw-700' : '')}>{obj.act}</div>
                                    <div className="col-2 arrows-column">
                                        {!obj.active &&
                                            <img alt="not active" src="/assets/images/icons/diagnostic/arrows_grey.svg" width="53" height="15" />
                                        }
                                        {obj.active &&
                                            <img alt="active" src="/assets/images/icons/diagnostic/arrows_white.svg" width="53" height="15" />
                                        }
                                    </div>
                                    <div className={'col-5 conversion-table-text ' + (obj.active ? 'fw-700' : '')}>{obj.sat}</div>
                                </div>
                            );
                        })}
                        <div className="text-center">
                            <a className="hyperlink mx-0 mt-3 d-block" href="#" title="View Full Conversion" onClick={props.openConversion}>View Full Conversion</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompleteDiagnosticCard;
