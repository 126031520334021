import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import Verify from './Verify';
import { parse } from 'query-string';

export default (props: RouteComponentProps<any>) => {
    const search = parse(props.location.search);
    const searchToken = search.token as string;
    return <Verify verificationToken={searchToken || undefined} />;
};
