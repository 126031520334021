import './SchoolSafetyLessons.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';
import * as _ from 'lodash';
import { PageContent } from '../../components/PageContent/PageContent';
import { RouteComponentProps, Link } from 'react-router-dom';
import CollapsableCard, { CollapsableCardHeader, CollapsableCardBody } from '../CollapsableCard/CollapsableCard';
import LessonListCardHeader from '../LessonListCardHeader/LessonListCardHeader';
import Loading from '../Loading/Loading';
import DataGrid from '../DataGrid';
import { Column } from '../DataGrid/types/types';
import { RowData } from '../DataGrid/types/types';
import LessonListTitle from '../LessonList/components/LessonListTitle/LessonListTitle';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import BrightcoveVideo from '../BrightcoveVideo/BrightcoveVideo';
import { UserInfo } from '../../services/account/account-rest-interface';
import { SchoolSafetyState, updateUserSchoolSafety } from '../../stores/school-safety.store';
import { hasLessons, hasCollegeKnowledge } from '../../services/account/account-utils';
import { PageTitle } from '../PageContent/PageContent';

export interface Props extends RouteComponentProps<any> {
}

export interface State {
    schoolSafetyData: any;
    videoLink: string;
    lessonId: string;
    toggleExplanationVideoPopup: boolean;
}

interface StoreProps {
  userInfo: UserInfo;
  schoolSafety: SchoolSafetyState;
}

interface DispatchProps {
    updateUserSchoolSafety: (lessonId: string, status: string) => Promise<any>;
}

declare const videojs: any;
type AllProps = Props & StoreProps & DispatchProps;


export class SchoolSafetyLessons extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            schoolSafetyData: null,
            videoLink: '',
            lessonId: '',
            toggleExplanationVideoPopup: false
        };
    }

    public render() {
        const sortStr = (a, b, c?, d?, reverseSort?) => {
            if (!reverseSort) {
              if(a < b) {
                return 1;
              }
              if(b < a) {
                  return -1;
              }
              if (b === a) {
                if (c && d) {
                  if(c < d) {
                    return 1;
                  }
                  if(d < c) {
                      return -1;
                  }
                }
              }
              return 0;
            } else {
              if(a < b) {
                return 1;
              }
              if(b < a) {
                  return -1;
              }
              if (b === a) {
                if (c && d) {
                  if(c < d) {
                    return -1;
                  }
                  if(d < c) {
                      return 1;
                  }
                }
              }
              return 0;
            }

          };

        const schoolSafetyColumns: Column[] = [
            {
                title: 'LESSONS',
                dataProperty: 'lesson',
                width: '40%',
                sortMethod: sortStr
            },
            {
                title: 'VIDEO',
                dataProperty: 'video',
                sortMethod: sortStr,
                width: '12%'
            },
            {
                title: 'QUESTIONS',
                dataProperty: 'questions',
                sortMethod: sortStr,
                width: '12%'
            },
            {
                title: 'STATUS',
                dataProperty: 'status',
                width: '220px',
                sortMethod: sortStr
            }
        ];

        let keyNames;
        let data;
        const schoolSafetyData = _.groupBy(this.props.schoolSafety.schoolSafety, 'categoryName');

        if (schoolSafetyData) {
            keyNames = Object.keys(schoolSafetyData);
            data = this.createSchoolSafetyData;
        }

        if (!schoolSafetyData) {
            return (
                <Loading />
            );
        }

        return (
            <PageContent className="college-knowledge-list">
                <PageTitle title="My Lessons" />
                <div className="row blue-tabs-container">
                    <div className="col-12 text-left">
                        {hasLessons(this.props.userInfo) &&
                            <Link to="/app/lessons">
                                <button
                                    className={'btn title-button btn-not-bold btn-default white margin-right-10'}
                                    type="button"
                                >
                                    <div>{this.props.userInfo.bundleName}</div>
                                </button>
                            </Link>
                        }
                        {hasCollegeKnowledge(this.props.userInfo) &&
                            <Link to="/app/lessons/dashboard/ck">
                                <button
                                    className={'btn title-button btn-not-bold btn-default white margin-left-10 margin-right-10'}
                                    type="button"
                                >
                                    <div>College Knowledge</div>
                                </button>
                            </Link>
                        }
                        <Link to="/app/school-safety">
                            <button
                                className={'btn title-button btn-not-bold btn-default white margin-right-10'}
                                type="button"
                            >
                                <div>School Safety</div>
                            </button>
                        </Link>
                    </div>
                </div>
                {keyNames.map(function(name: string, index: number) {
                    const numCompleted = schoolSafetyData[name].filter(function(item: any) {
                        return item.status === 'rewatch';
                    });

                    let newData, subKeyNames;
                    // This is pretty broken code...going to just disable 
                    let sortedSchoolSafety = schoolSafetyData[name].sort((schoolSafety1: any, schoolSafety2: any) => {
                      if (schoolSafety1 && schoolSafety2) {
                        return schoolSafety1.playOrder - schoolSafety2.playOrder;
                      } else {
                        return -1;
                      }
                    });
                    newData = _.groupBy(schoolSafetyData[name], 'subCategoryName');
                    subKeyNames = Object.keys(newData);

                    sortedSchoolSafety = sortedSchoolSafety.map(data);
                    const iconPath = `/assets/images/school-safety/lock-blue.svg`;
                    return (
                        <CollapsableCard key={index}>
                            <CollapsableCardHeader>
                                <LessonListCardHeader
                                    text={name}
                                    color={'#0e5682'}
                                    icon={iconPath}
                                    numQuestionsCompleted={numCompleted.length}
                                    numQuestionsTotal={schoolSafetyData[name].length}
                                >
                                </LessonListCardHeader>
                            </CollapsableCardHeader>
                            <CollapsableCardBody>
                                {
                                  subKeyNames.map(d => {
                                  for (let i = 0; i < d.length; i++) {
                                    sortedSchoolSafety = newData[d].map(data);
                                    return (
                                      <div className="subcategory-lesson-list" key={`subcategory-lesson-list-${d}`}>
                                          {d.toUpperCase() !== 'NULL' &&
                                              <h6 className="subcategory-title" key={d}>
                                                  {d.toUpperCase()}
                                              </h6>
                                          }
                                              <DataGrid
                                                  columns={schoolSafetyColumns}
                                                  data={[]}
                                                  emptyText="No lessons to display"
                                              />
                                      </div>
                                    );
                                  }
                                  return (
                                    <div key={d.lessonId}></div>
                                  );
                                })
                              }
                            </CollapsableCardBody>
                        </CollapsableCard>
                    );
                })}
                <Modal
                    className="school-safety-video school-safety-video-modal"
                    show={this.state.toggleExplanationVideoPopup}
                    onClose={this.closeModal}
                    showXTopRight={true}
                >
                    <BrightcoveVideo
                        id="school-safety-video"
                        videoId={this.state.videoLink}
                    >
                    </BrightcoveVideo>
                </Modal>
            </PageContent>
        );
    }

    
    private createSchoolSafetyData(schoolSafety: any, i: number): RowData {
        const studentData = {
            lesson: {
                content: this.createLessonTitle(schoolSafety),
                comparator: schoolSafety.lessonName,
                subComparator: schoolSafety.lessonOrder
            },
            scheduled: {
                content: '', // rawData.scheduled,
                comparator: '' // rawData.scheduled
            },
            video: {
                content: schoolSafety.aggregateVideoDuration,
                comparator: schoolSafety.aggregateVideoDuration,
                subComparator: schoolSafety.lessonOrder
            },
            questions: {
                content: this.formatQuestions(schoolSafety),
                comparator: schoolSafety.numCompletedQuestions || 0
            },
            status: {
                content: this.createStatusButton(schoolSafety),
                comparator: this.getStatusButtonData(schoolSafety).order,
                subComparator: schoolSafety.lessonOrder
            },
            isOwned: {
                content: schoolSafety.isOwned,
                comparator: schoolSafety.isOwned,
            }
        };

        return {
            data: studentData,
            id: `lesson-data${i}`
        };
    }

    private createLessonTitle(lesson: any) {
        const icon = '/assets/images/icons/progress/' + this.getLessonTitleIcon(lesson);
        return () => (
            <LessonListTitle
                enabled={false}
                icon={icon}
                lessonName={lesson.lessonName}
                onIconClick={() => this.doNothing}
            />
        );
    }

    private formatQuestions(schoolSafety: any) {
        if(!schoolSafety.numTotalQuestions) { return 'N/A'; }
        return `${schoolSafety.numCompletedQuestions}/${schoolSafety.numTotalQuestions}`;
    }

    private getLessonTitleIcon(schoolSafety: any) {
        switch(schoolSafety.status) {
            case 'resume': return 'dots-green.svg';
            case 'rewatch': return 'check-purple.svg';
            default: return 'play-blue.svg';
        }
    }

    private createStatusButton(schoolSafety: any) {
        const statusButtonData = this.getStatusButtonData(schoolSafety);
        return () => (
            <Button
                textColor={'white'}
                bgColor={statusButtonData.color as any}
                text={statusButtonData.text}
                className="lesson-list__btn"
                onClick={() => console.log('navigate to school safety lesson')}
            />
        );
    }

    private getStatusButtonData(schoolSafety: any) {
        switch(schoolSafety.status) {
            case 'resume': return { text: 'RESUME', color: 'green', order: 2 };
            case 'rewatch': return { text: 'REWATCH', color: 'purple', order: 3 };
            default: return { text: 'START', color: 'blue', order: 1 };
        }
    }

    private closeModal() {
        const myplayer = videojs('school-safety-video');
        const { lessonId } = this.state;
        myplayer.pause();
        this.setState({
            toggleExplanationVideoPopup: !this.state.toggleExplanationVideoPopup,
        }, this.componentDidMount);

    }

    private doNothing() {
        return true;
    }
}
