import * as React from 'react';

import './GrowthIndicator.css';

interface Props {
    before: any;
    after: any;
}

const yellow = 'rgb(255, 201, 87)';
const red = 'rgb(232, 46, 46)';
const green = 'rgb(117, 193, 150)';

interface IconProps {
  color: string;
}

const Circle: React.SFC<IconProps> = ({ color }) => (
  <svg viewBox="0 0 40 40"> 
    <circle 
        cx="20"
        cy="20"
        r="13"
        fill={color}
    />
 </svg>
);

const GrowthIndicator: React.SFC<Props> = ({ before, after }) => {
    const validTopic = t => t !== undefined && t.total !== 0; 
    const validGrowth = validTopic(before) && validTopic(after);
    const beforePercentage = before.correctCount / before.total;
    const afterPercentage = after.correctCount / after.total;
    const notApplicable = validGrowth ? '' : 'not-applicable';
    
    const hasPerfectScores = beforePercentage === 1 && afterPercentage === 1;

    return (
        <td className={`topic-growth-indicator ${notApplicable}`}>
            {beforePercentage > afterPercentage && 
                <img alt="negative growth" className="" width="20" height="20" src="/assets/images/icons/v2/ico-growth-negative.svg" />
            }
            {beforePercentage < afterPercentage && 
                <img alt="positive growth" className="" width="20" height="20" src="/assets/images/icons/v2/ico-growth-positive.svg" />
            }
            {beforePercentage === afterPercentage && 
                !hasPerfectScores &&
                <img alt="neutral growth" className="" width="20" height="20" src="/assets/images/icons/v2/ico-growth-neutral.svg" />
            }
            {hasPerfectScores && 
                <img alt="positive growth" className="" width="20" height="20" src="/assets/images/icons/v2/ico-growth-positive.svg" />
            }
            {validGrowth ? '' : (<span className="naElem">N/A</span>)}
        </td>
    );
};

export default GrowthIndicator;
