import './SelectInput.css';

import * as React from 'react';

interface Props {

    id: string;
    value: string | number;
    options: string[];
    onChange: (e: any) => void;
    labelText?: string;
    invalid?: boolean;
    feedbackText?: string;
    formGroupClass?: string;
    labelClass?: string;
    inputClass?: string;
    size?: 'sm' | 'lg';
}

const SelectInput: React.SFC<Props> = (props) => {
    const invalidClass = props.invalid ? 'has-danger' : '';
    const formControlClass = props.size ? `form-control-${props.size}` : '';

    const options = props.options.map((option, i) => <option key={option} value={option}>{option}</option>);

    return (
        <div className={`form-group ${invalidClass} ${props.formGroupClass}`}>

            {props.labelText &&
                <label className={`select-input-label ${props.labelClass}`} htmlFor={props.id}>
                    {props.labelText}
                </label>}

            <select
                className={`form-control select-input ${formControlClass} ${props.inputClass}`}
                value={props.value}
                onChange={props.onChange}
                id={props.id}
            >
                {options}
            </select>

            {props.feedbackText && <div className="form-control-feedback theme-feedback">* {props.feedbackText}</div>}
        </div>
    );
};

SelectInput.displayName = 'SelectInput';

export default SelectInput;
