import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import DataGrid from '../../components/DataGrid'
import { Column } from '../../components/DataGrid/types/types'
import Loading from '../../components/Loading/Loading'
import StatusProgressBar from '../../components/StatusProgressBar'
import { getAllLessons } from '../../services/elearn/lesson-rest-interface'
import { getUserLessons } from '../../services/user/user-lesson-rest-interface'
import { EMPTY, NA, NONE, NOT_STARTED } from '../../constants'
import { getStudentTonPInfo } from '../../services/time-on-platform'
import { getTimeOnQuestionsForLessonSummary, getTotalStudyTimeForLesson } from '../TeacherPortal/TimeOnPlatform/top-lesson-processor'
import { getHumanTime } from '../../services/utils/date-util'
import { cleanStatus, determineNAs } from '../../rules/business'
import { alphanumericCompare } from '../../services/utils/sort-util'
import "./PriorityLessonsView.css"

interface Props extends RouteComponentProps<any> {}

interface State {
  starredLessons?: any
  categorizedStarredLessons?: any
  loading: boolean
  errorMsg?: string  
}

let sortMethod = alphanumericCompare
let minSortVal = Number.NEGATIVE_INFINITY
let sortable = true
let columns: Column[] = [
  {
    title: 'CATEGORY',
    dataProperty: 'category',
    customTooltip: 'Indicates the category of the prioritized lesson',
    width: '135px',
    sortable,
    sortMethod
  },
  {
    title: 'LESSON',
    dataProperty: 'lessonName',
    customTooltip: 'Indicates the prioritized lesson',
    width: '26%',
    sortable,
    sortMethod
  },
  {
    title: 'STATUS',
    dataProperty: 'status',
    customTooltip: 'Indicates the status of the lessons',
    width: '160px',
    sortable,
    sortMethod
  },
  {
    title: 'PRE-LESSON<br/>ACCURACY',
    dataProperty: 'accuracyPre',
    customTooltip: 'Indicates the pre-lesson accuracy',
    width: '15%',
    sortable,
    sortMethod
  },

  {
    title: 'POST-LESSON<br/>ACCURACY',
    dataProperty: 'accuracyPost',
    customTooltip: 'Indicates the post-lesson accuracy',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'GROWTH',
    dataProperty: 'growth',
    customTooltip: 'Growth',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'HINTS ON<br/>INCORRECT',
    dataProperty: 'hintsOnIncorrect',
    customTooltip: 'Indicated the hints on incorrect',
    width: '15%',
    sortable,
    sortMethod
  },
  // {
  //   title: 'TIME ON<br/>QUESTIONS',
  //   dataProperty: 'timeOnQuestion',
  //   customTooltip: 'Indicates average and total time answering multiple choice questions',
  //   width: '10%',
  //   sortable,
  //   sortMethod
  // },
  {
    title: 'TOTAL<br/>STUDY TIME',
    dataProperty: 'studyTime',
    customTooltip: 'Indicates the total study time on lesson',
    width: '15%',
    sortable,
    sortMethod
  },
]



export default class PriorityLessonsView extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      starredLessons: undefined,
      categorizedStarredLessons: undefined,
      loading: true
    }
  }

  async componentDidMount() {
    function getStatus(currentLessonContent, questionCompletionProgress, lesson) {
      let status = currentLessonContent
      let {totalCompletedQuestions, totalQuestions} = lesson

      status = cleanStatus(status)

      if (questionCompletionProgress == 100) {
        status = currentLessonContent;
      }
      else {
        if (status.indexOf("multiple choice") !== -1) {
          status = `Question ${totalCompletedQuestions}/${totalQuestions}`
        }
      }

      return status
    }

    try {
      let lessonOverviews = await getUserLessons()
      let allLessons = await getAllLessons()
      let tonp = await getStudentTonPInfo()
      let userLessonsOverviews = lessonOverviews.userLessonOverviews
      let starredLessonOverviews = userLessonsOverviews.filter(uso => uso.star === true)

      // Combine them all
      let starredLessons = starredLessonOverviews.map(overview => {
        let lesson = allLessons.find(lesson => lesson.lessonId === overview.lessonId)
        if (lesson) {
          let {lessonId, lessonName, category, currentLessonContent, questionCompletionProgress} = lesson
          let status = getStatus(currentLessonContent, questionCompletionProgress, lesson)
          let totalStudyTime = getTotalStudyTimeForLesson(tonp, lessonId)
          let timeOnQuestionSummary = getTimeOnQuestionsForLessonSummary(tonp, lessonId)
          let details = {...lesson, totalStudyTime, timeOnQuestionSummary}
          return {
            status,
            lessonId,
            lessonName,
            category,
            overview,
            details,
          }
        }
        else {
          let {lessonId, lessonName, category} = overview
          return {
            status: NOT_STARTED,            
            lessonId,
            lessonName,
            category: category.categoryName,
            overview
          }
        }
      })

      // Sort by category & lessonId
      starredLessons.sort((lesson1, lesson2) => {
        let k1 = `key-${lesson1.category}-${lesson1.lessonId}`
        let k2 = `key-${lesson2.category}-${lesson2.lessonId}`
        return k1.localeCompare(k2)
      })

      let categorizedStarredLessons = starredLessons.reduce((acc, cur) => {
        let categoryId = cur?.overview?.category?.categoryId || 'unknown'
        acc[categoryId] = [...(acc[categoryId] || []), cur]
        return acc
      }, {})

      this.setState({starredLessons: starredLessons, categorizedStarredLessons })
      setTimeout(() => this.setState({loading: false}), 500)
    }
    catch (ex) {
      console.error("Ex: ", ex)
      this.setState({errorMsg: "Error occurred. Please try again."})
    }
  }

  private createContent = (lesson: any, index: number) => {
    const id = `priority-lessons-content-${lesson.lessonId}-${index}`
    const navigateTo = (e, lessonUrl) => {
      e.preventDefault()
      this.props.history.push(lessonUrl)
    }

    const prepStatusInfo = (status, category) => {
      return () => <StatusProgressBar status={status} category={category}/>
    }

    const getSubjectThemeClass= (category) => {
      let colorClass = 'color-' + category.toLowerCase();
      return colorClass;
    }
    const getCategoryHTML = (category) => {
      // let colorClass = 'color-' + category.toLowerCase();
      return () => <span className={`subject-link`} dangerouslySetInnerHTML={{__html: category}}/>
    }
    const getLessonLink = (lessonId, lessonName) => {
      let lessonUrl = `lessons/${lessonId}`
      return () => <a href={lessonUrl} title={`Go to lesson: ` + lessonName} onClick={e => navigateTo(e, lessonUrl)} className="link classLink iconLink--right">{lessonName}</a>
    }
    const getStudyTime = (studyTimeInSeconds) => {
      return () => {
        if (studyTimeInSeconds > 0) {
          let ht = getHumanTime(studyTimeInSeconds, true, true)
          return <span dangerouslySetInnerHTML={{__html: ht.text}}/>
        }
        return <span className="not-applicable">{NA}</span>
      }
    }
    const getTimeOnQuestionInfo = (avgTime: number, totalTime: number) => {
      let avgTimeInfo = avgTime > 0 ? getHumanTime(avgTime, true, true).text: NA
      let totalTimeInfo = totalTime > 0 ? getHumanTime(totalTime, true, true).text: NA
      return () => (
        <div className="time-on-question-info act-or-sat-score-info">
          {avgTimeInfo != NA ?
            <div>
              Avg <span dangerouslySetInnerHTML={{__html: avgTimeInfo}}/>
              <br/>
              Total <span dangerouslySetInnerHTML={{__html: totalTimeInfo}}/>
            </div>
            :
            <div className="not-applicable">{NA}</div>
          }
        </div>
      )
    }

    const getFormattedGrowth = (growth) => {
      let arrowClass = ''
      
      if (growth > 0) {
        arrowClass = 'after--up-arrow'
      }
      else if (growth < 0) {
        arrowClass = 'after--down-arrow'
      }

      return () => (
        <span className={arrowClass}>{growth}%</span>
      )
    }

    let {category, lessonId, lessonName, status, details} = lesson
    let infoAccuracyPre = NA
    let infoAccuracyPost = NA
    let infoGrowth: string|any = NA 
    let infoHintsOnIncorrect = NA 
    let infoTotalStudy: string|any = NA
    let isAccuracyPreNA = true
    let isAccuracyPostNA = true
    let isGrowthNA = true
    let isHintsOnIncorrectNA = true
    let baselineAccuracy = 0
    let calculatedQuestionAccuracy = 0
    let totalCompletedQuestions = 0
    let totalCorrectPostLessonQuestions = 0
    let totalHintsWrittenOutOfIncorrectQuestions = 0
    let growth = 0
    let totalStudyTime = 0

    status = cleanStatus(status)

    if (details) {
      let allNAs = determineNAs(category, details)
      
      totalCorrectPostLessonQuestions = details.totalCorrectPostLessonQuestions
      totalCompletedQuestions = details.totalCompletedQuestions
      calculatedQuestionAccuracy = totalCompletedQuestions > 0 ? Math.round(100*totalCorrectPostLessonQuestions/totalCompletedQuestions): 0
      totalHintsWrittenOutOfIncorrectQuestions = details.totalHintsWrittenOutOfIncorrectQuestions
      totalStudyTime = details.totalStudyTime
      
      isAccuracyPreNA = allNAs.isAccuracyPreNA
      isAccuracyPostNA = allNAs.isAccuracyPostNA
      isGrowthNA = allNAs.isGrowthNA
      isHintsOnIncorrectNA = allNAs.isHintsOnIncorrectNA
      baselineAccuracy = details.baselineAccuracy
      growth = calculatedQuestionAccuracy - baselineAccuracy
      
      // Set infos
      infoAccuracyPre = isAccuracyPreNA ? NA: baselineAccuracy + "%"
      infoAccuracyPost = isAccuracyPostNA ? NA: calculatedQuestionAccuracy + "%"
      infoGrowth = isGrowthNA ? NA: getFormattedGrowth(growth)
      infoHintsOnIncorrect = isHintsOnIncorrectNA ? NA: totalHintsWrittenOutOfIncorrectQuestions + "%"
      infoTotalStudy = getStudyTime(totalStudyTime)
    }

    let data = {
      category: {
        content: getCategoryHTML(category),
        comparator: `${category}-${lessonName}`,
        className: "priority-lesson-category"
      },
      lessonName: {
        content: getLessonLink(lessonId, lessonName),
        comparator: lessonName,
        className: "priority-lesson-name"
      }, 
      status: {
        content: prepStatusInfo(status, category),
        comparator: status === NOT_STARTED || status === NONE ? "Zzz": status, // OT: Zzz is needed for status sorting A->Z | Z->A
      },
      accuracyPre: {
        content: infoAccuracyPre,
        comparator: isAccuracyPreNA ? minSortVal: baselineAccuracy,
      },
      accuracyPost: {
        content: infoAccuracyPost,
        comparator: isAccuracyPostNA ? minSortVal: calculatedQuestionAccuracy,
      },
      growth: {
        content: infoGrowth,
        comparator: isGrowthNA ? minSortVal: growth,
      },
      hintsOnIncorrect: {
        content: infoHintsOnIncorrect,
        comparator: isHintsOnIncorrectNA ? minSortVal: totalHintsWrittenOutOfIncorrectQuestions,
      },
      // timeOnQuestion: {
      //   content: timeOnQuestion,
      //   comparator: timeOnQuestion,
      // },
      studyTime: {
        content: infoTotalStudy,
        comparator: totalStudyTime,
      },     
    }

    let highlightRowClassName = getSubjectThemeClass(category)

    return {
      id,
      data,
      highlightRowClassName
    }
  }

  private getCardBodyContent = (starredLessons: any[]) => {
    let data = starredLessons.map((lesson, index) => this.createContent(lesson, index))

    return (
      <DataGrid
        columns={columns}
        data={data}
        emptyText="No priority lesson found!"
      />
    )
  }
  
  render() {
    let {loading, starredLessons, errorMsg} = this.state
    let nofTotalLessons = starredLessons ? starredLessons.length: 0

    return (
      <div className="priority-lessons-container--wrapper">
        <div className="lesson-breakdown-container">
          <div className="heading-banner-lessons text-center">
            <h2 className="priority-lessons-title mb-0">Prioritized Lessons</h2>
          </div>
          <div className="lesson-breakdown-content priority-lessons-container">

            Total # of lessons: {nofTotalLessons}
            <hr/>

            { errorMsg ?
              <div className="priority-lessons-error">{errorMsg}</div>
              :
              loading ?
                <LoadingMessage />
                :
                <div className="priority-lessons-content">
                  {this.getCardBodyContent(starredLessons)}
                </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

//--- Helpers ---
const LoadingMessage = () => {
  return (
    <div className="priority-lessons-loading-wrapper">
      <Loading />
      <p>
        We are finding your prioritized lessons. Please be patient as this process can take up to 30 seconds.
      </p>
    </div>
  )
}

