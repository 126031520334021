import React, { Component } from 'react'
import {App} from "../../App"
import { UserInfo } from '../../services/account/account-rest-interface'
import { Link } from 'react-router-dom'
import "./index.css"

interface Props {}

interface State {
  userInfo: UserInfo
}

/**
 * WA Sitemap component to show various sitemap links for:
 *  * Students
 *  * Teachers
 *  * Superintendents
 */
export default class Sitemap extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      userInfo: App.getUserInfo(),
    }
  }
  render() {
    let {isTeacher, isSuperintendent} = this.state.userInfo
    let isStudent = !isTeacher && !isSuperintendent

    // User: student | teacher | superintendent
    return (
      <div className="sitemap-container">
        <h1>{WA_SITEMAP.name}</h1>
        <hr/>
        <div className="sitemap">
          { isStudent && <SitemapForStudent routes={WA_SITEMAP.studentRoutes} /> }
          { isTeacher && <SitemapForTeacher routes={WA_SITEMAP.teacherRoutes}/> }
          { isSuperintendent && <SitemapForSI routes={WA_SITEMAP.siRoutes}/> }
        </div>
      </div>
    )
  }
}

//--- Helper Components ---
function SitemapForStudent(props) {
  let {routes} = props

  return (
    <div>
      <h2 className="mb-3">For Students:</h2>
      <ul>
        {
          routes.map(route => {
            let {name, path, external} = route
            return (
              <li>
                {!external ? 
                  <Link title={name} to={path}>{name}</Link>
                  :
                  <a href={path} title={name + `, opens in a new window`} target="_blank" rel="noopener noreferrer">{name}</a>
                }
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

function SitemapForTeacher(props) {
  let {routes} = props

  return (
    <div>
      <h2 className="mb-3">For Teachers:</h2>
      <ul>
        {
          routes.map(route => {
            let {name, path, external} = route
            return (
              <li>
                {!external ? 
                  <Link title={name} to={path}>{name}</Link>
                  :
                  <a href={path} title={name + `, opens in a new window`} target="_blank" rel="noopener noreferrer">{name}</a>
                }
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

function SitemapForSI(props) {
  let {routes} = props

  return (
    <div>
      <h2 className="mb-3">For Superintendents:</h2>
      <ul>
        {
          routes.map(route => {
            let {name, path, external} = route
            return (
              <li>
                {!external ? 
                  <Link to={path} title={name}>{name}</Link>
                  :
                  <a href={path} title={name + `, opens in a new window`} target="_blank" rel="noopener noreferrer">{name}</a>
                }
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

//--- Helper Functions & Structures ---
export interface ISitemapRoute {
  path: string
  name: string
  external?: boolean // By default all links are internal for the app (some might be external)
}

export interface ISitemap {
  name: string
  studentRoutes: ISitemapRoute[]
  teacherRoutes: ISitemapRoute[]
  siRoutes: ISitemapRoute[]
}

const WA_COMMENTS_URL = "https://www.winwardacademy.com/contact/"

const SITEMAP_STUDENT_ROUTES: ISitemapRoute[] = [
  {
    path: "/app",
    name: "Home",
  },
  {
    path: "/app/dashboard",
    name: "Dashboard",
  },
  {
    path: "/app/lessons",
    name: "Lessons",
  },
  {
    path: "/app/mistake-bank",
    name: "Mistake Bank",
  },
  {
    path: "/app/stay-sharp",
    name: "Stay Sharp",
  },
  {
    path: "/app/practice-tests",
    name: "Practice Tests",
  },
  {
    path: "/app/student-resources",
    name: "Resources",
  },
  {
    path: WA_COMMENTS_URL,
    name: "Comments",
    external: true,        
  },
  
]

const SITEMAP_TEACHER_ROUTES: ISitemapRoute[] = [
  {
    path: "/app",
    name: "Home",        
  },
  {
    path: "/app/dashboard",
    name: "Dashboard",        
  },
  {
    path: "/app/teacher-portal",
    name: "Data Reports",        
  },
  {
    path: "/app/lessons",
    name: "Lessons",        
  },  
  {
    path: "/app/practice-tests",
    name: "Practice Tests",        
  },
  {
    path: "/app/teacher-resources",
    name: "Resources",        
  },
  {
    path: WA_COMMENTS_URL,
    name: "Comments",
    external: true,        
  },
]

const SITEMAP_SUPERINTENDENT_ROUTES: ISitemapRoute[] = [
  {
    path: "/app",
    name: "Home",        
  },
  {
    path: "/app/dashboard",
    name: "Dashboard",        
  },
  {
    path: WA_COMMENTS_URL,
    name: "Comments",
    external: true,        
  },
]

export const WA_SITEMAP: ISitemap = {
  name: "Winward Academy Sitemap",
  studentRoutes: SITEMAP_STUDENT_ROUTES,
  teacherRoutes: SITEMAP_TEACHER_ROUTES,
  siRoutes: SITEMAP_SUPERINTENDENT_ROUTES,
}

