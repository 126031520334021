export const quotes = [
  {
    "category": "Engagement",
    "author": "George Lucas",
    "title": "Filmmaker",
    "text": "Your focus determines your reality."
  },
  {
    "category": "Engagement",
    "author": "Arnold Palmer",
    "title": "Professional Golfer",
    "text": "Concentration comes out of a combination of confidence and hunger."
  },
  {
    "category": "Engagement",
    "author": "Mark Twain",
    "title": "Author",
    "text": "The secret to getting ahead is getting started."
  },
  {
    "category": "Engagement",
    "author": "Theodora Roosevelt",
    "title": "Former First Lady of the United States",
    "text": "Believe you can and you're halfway there."
  },
  {
    "category": "Engagement",
    "author": "Oprah Winfrey",
    "title": "Media Mogul and Philanthropist",
    "text": "Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work."
  },
  {
    "category": "Engagement",
    "author": "Zainab Salbi",
    "title": "Author and Women's Rights Activist",
    "text": "Success is not about climbing up; it's about staying up."
  },
  {
    "category": "Engagement",
    "author": "Chinese Proverb",
    "text": "Teachers open the door, but you must enter by yourself"
  },
  {
    "category": "Engagement",
    "author": "Nelson Mandela",
    "title": "Former President of South Africa and Anti-Apartheid Activist",
    "text": "It always seems impossible until it’s done."
  },
  {
    "category": "Engagement",
    "author": "Sheila Tobias",
    "title": "Author",
    "text": "There is a difference between not knowing and not knowing yet."
  },
  {
    "category": "Engagement",
    "author": "Henry Ford",
    "title": "Businessman",
    "text": "Anyone who stops learning is old, whether at twenty or eighty.  Anyone who keeps learning stays young. The greatest thing in life is to keep your mind young."
  },
  {
    "category": "Engagement",
    "author": "Dr. Seuss",
    "title": "Author and Cartoonist",
    "text": "You have brains in your head. You have feet in your shoes. You can steer yourself in any direction you choose."
  },
  {
    "category": "Engagement",
    "author": "Eleanor Roosevelt",
    "title": "Former First Lady of the United States",
    "text": "You must do the thing you think you cannot do"
  },
  {
    "category": "Engagement",
    "author": "Paulo Coelho",
    "title": "Author",
    "text": "Whenever you want to achieve something, keep your eyes open, concentrate and make sure you know exactly what it is you want. No one can hit their target with their eyes closed."
  },
  {
    "category": "Engagement",
    "author": "Roy T. Bennett",
    "title": "Author",
    "text": "Focus on your goals, not your fear. Focus like a laser beam on your goals."
  },
  {
    "category": "Values",
    "author": "Pablo Picasso",
    "title": "Artist",
    "text": "Everything you can imagine is real."
  },
  {
    "category": "Values",
    "author": "Mahatma Gandhi",
    "title": "Lawyer and Activist",
    "text": "Live as if you were to die tomorrow. Learn as if you were to live forever."
  },
  {
    "category": "Values",
    "author": "Oscar Wilde",
    "title": "Author",
    "text": "Be yourself; everyone else is already taken."
  },
  {
    "category": "Values",
    "author": "Winston Churchill",
    "title": "Former British Prime Minister",
    "text": "Success is not final, failure is not fatal: It is the courage to continue that counts."
  },
  {
    "category": "Values",
    "author": "CS Lewis",
    "title": "Author",
    "text": "Integrity is doing the right thing, even when no one is watching."
  },
  {
    "category": "Values",
    "author": "Booker T. Washington",
    "title": "Educator and Civil Rights Activist",
    "text": "If you want to lift yourself up, lift up someone else."
  },
  {
    "category": "Values",
    "author": "Maya Angelou",
    "title": "Poet and Civil Rights Activist",
    "text": "Success is not about the destination; it's about the journey."
  },
  {
    "category": "Values",
    "author": "Mikhail Baryshnikov",
    "title": "Russian-American Dancer",
    "text": "I do not try to dance better than anyone else. I only try to dance better than myself."
  },
  {
    "category": "Values",
    "author": "A.A. Milne",
    "title": "Writer",
    "text": "You are braver than you believe, stronger than you seem and smarter than you think."
  },
  {
    "category": "Values",
    "author": "Abraham Lincoln",
    "title": "Former President of the United States",
    "text": "The best way to predict your future is to create it."
  },
  {
    "category": "Values",
    "author": "Antoine de Saint-Exupéry",
    "title": "Writer",
    "text": "A goal without a plan is just a wish."
  },
  {
    "category": "Values",
    "author": "John Wooden",
    "title": "Basketball coach",
    "text": "Don’t let what you cannot do interfere with what you can do."
  },
  {
    "category": "Values",
    "author": "Zig Ziglar",
    "title": "Author",
    "text": "It's not how far you fall, but how high you bounce that counts."
  },
  {
    "category": "Values",
    "author": "C.S. Lewis",
    "title": "Author",
    "text": "Failures are finger posts on the road to achievement."
  },
  {
    "category": "Values",
    "author": "Martin Luther King",
    "title": "Civil Rights Activist",
    "text": "Intelligence plus character – that is the goal of true education."
  },
  {
    "category": "Education",
    "author": "Nelson Mandela",
    "title": "Former President of South Africa and Anti-Apartheid Activist",
    "text": "Education is the most powerful weapon which you can use to change the world."
  },
  {
    "category": "Education",
    "author": "Dr. Seuss",
    "title": "Author and Cartoonist",
    "text": "The more that you read, the more things you will know. The more that you learn, the more places you'll go."
  },
  {
    "category": "Education",
    "author": "B.B. King",
    "title": "Blues Musician",
    "text": "The beautiful thing about learning is that no one can take it away from you."
  },
  {
    "category": "Education",
    "author": "John Dewey",
    "title": "Philosopher and Educational Reformer",
    "text": "Education is not preparation for life; education is life itself."
  },
  {
    "category": "Education",
    "author": "Plutarch",
    "title": "Ancient Greek Biographer",
    "text": "The mind is not a vessel to be filled, but a fire to be kindled."
  },
  {
    "category": "Education",
    "author": "Malcom X",
    "title": "Civil Rights Activist",
    "text": "Education is the passport to the future, for tomorrow belongs to those who prepare for it today."
  },
  {
    "category": "Education",
    "author": "Anonymous",
    "text": "Today a reader. Tomorrow a leader."
  },
  {
    "category": "Education",
    "author": "Ray LeBlond",
    "title": "Marketing Expert",
    "text": "You learn something every day if you pay attention."
  },
  {
    "category": "Education",
    "author": "Ellen DeGeneres",
    "title": "Comedian",
    "text": "When you take risks you learn that there will be times when you succeed and there will be times when you fail, and both are equally important."
  },
  {
    "category": "Education",
    "author": "Chinese Proverb",
    "text": "Learning is a treasure that will follow its owner everywhere."
  },
  {
    "category": "Education",
    "author": "Marvin Phillips",
    "title": "Basketball Player",
    "text": "The difference between try and triumph is a little umph."
  },
  {
    "category": "Education",
    "author": "Claire Fagin",
    "title": "Nurse",
    "text": "Knowledge will bring you the opportunity to make a difference."
  },
  {
    "category": "Education",
    "author": "Sydney J. Harris",
    "title": "Journalist",
    "text": "The whole purpose of education is to turn mirrors into windows"
  },
  {
    "category": "Education",
    "author": "Benjamin Franklin",
    "title": "Inventor and Founding Father of the United States",
    "text": "An investment in knowledge pays the best interest."
  },
  {
    "category": "Education",
    "author": "Albert Einstein",
    "title": "Theoretical Physicist",
    "text": "Any fool can know. The point is to understand."
  },
  {
    "category": "Education",
    "author": "Brian Herbert",
    "title": "Author",
    "text": "The capacity to learn is a gift; the ability to learn is a skill; the willingness to learn is a choice."
  },
  {
    "category": "Perseverance",
    "author": "Confucius",
    "title": "Chinese Philosopher",
    "text": "It does not matter how slowly you go as long as you do not stop."
  },
  {
    "category": "Perseverance",
    "author": "Winston Churchill",
    "title": "Former British Prime Minister",
    "text": "Success is stumbling from failure to failure with no loss of enthusiasm."
  },
  {
    "category": "Perseverance",
    "author": "Walter Elliot",
    "title": "Scottish Unionist Politician",
    "text": "Perseverance is not a long race; it's many short races one after the other."
  },
  {
    "category": "Perseverance",
    "author": "Thomas Jefferson",
    "title": "Founding Father and Third President of the United States",
    "text": "I find that the harder I work, the more luck I seem to have."
  },
  {
    "category": "Perseverance",
    "author": "Confucius",
    "title": "Chinese Philosopher",
    "text": "Our greatest glory is not in never falling, but in rising every time we fall."
  },
  {
    "category": "Perseverance",
    "author": "Maya Angelou",
    "title": "Author",
    "text": "You may encounter many defeats, but you must not be defeated. In fact, it may be necessary to encounter the defeats, so you can know who you are, what you can rise from, how you can still come out of it."
  },
  {
    "category": "Perseverance",
    "author": "Beverly Sills",
    "title": "Opera Singer",
    "text": "There are no shortcuts to any place worth going."
  },
  {
    "category": "Perseverance",
    "author": "Regina Brett",
    "title": "Author",
    "text": "No matter how you feel, get up, dress up, and show up."
  },
  {
    "category": "Perseverance",
    "author": "Abigail Adams",
    "title": "Former First Lady of the United States",
    "text": "Learning is not attained by chance; it must be sought for with ardor and attended to with diligence."
  },
  {
    "category": "Perseverance",
    "author": "Theodore Roosevelt",
    "title": "Former President of the United States",
    "text": "It is hard to fail, but it is worse never to have tried to succeed."
  },
  {
    "category": "Perseverance",
    "author": "Robert T. Kiyosaki",
    "title": "Businessman and Author",
    "text": "Winners are not afraid of losing. But losers are. Failure is part of the process of success. People who avoid failure also avoid success."
  },
  {
    "category": "Perseverance",
    "author": "Thomas A. Edison",
    "title": "Inventor",
    "text": "Our greatest weakness lies in giving up. The most certain way to succeed is always to try just one more time."
  },
  {
    "category": "Perseverance",
    "author": "Dr. Seuss",
    "title": "Author and Cartoonist",
    "text": "You’re off to great places! Today is your day! Your mountain is waiting, so get on your way!"
  },
  {
    "category": "Perseverance",
    "author": "Eleanor Roosevelt",
    "title": "Former First Lady of the United States",
    "text": "The future belongs to those who believe in the beauty of their dreams."
  },
  {
    "category": "Perseverance",
    "author": "Jim Ryun",
    "title": "Former United States Representative",
    "text": "Motivation is what gets you started. Habit is what keeps you going."
  },
  {
    "category": "Perseverance",
    "author": "Paulo Coelho",
    "title": "Author",
    "text": "The secret of life, though, is to fall seven times and to get up eight times."
  },
  {
    "category": "Perseverance",
    "author": "Theodore Roosevelt",
    "title": "Former President of the United States",
    "text": "Do what you can, with what you have, where you are."
  },
  {
    "category": "Perseverance",
    "author": "Maya Angelou",
    "title": "Author",
    "text": "What you're supposed to do when you don't like a thing is change it. If you can't change it, change the way you think about it. Don't complain."
  },
  {
    "category": "Perseverance",
    "author": "R. Collier",
    "title": "Author",
    "text": "Success is the sum of small efforts, repeated."
  },
  {
    "category": "Gratitude",
    "author": "Melody Beattie",
    "title": "Author",
    "text": "Gratitude makes sense of our past, brings peace for today, and creates a vision for tomorrow."
  },
  {
    "category": "Gratitude",
    "author": "Ralph Waldo Emerson",
    "title": "Philosopher and Essayist",
    "text": "Cultivate the habit of being grateful for every good thing that comes to you, and to give thanks continuously."
  },
  {
    "category": "Gratitude",
    "author": "Dalai Lama",
    "title": "Spiritual Leader",
    "text": "The roots of all goodness lie in the soil of appreciation for goodness."
  },
  {
    "category": "Gratitude",
    "author": "Cicero",
    "title": "Ancient Roman Philosopher",
    "text": "Gratitude is not only the greatest of virtues but the parent of all others."
  },
  {
    "category": "Gratitude",
    "author": "Mary Davis",
    "title": "Activist",
    "text": "The more grateful I am, the more beauty I see."
  },
  {
    "category": "Gratitude",
    "author": "Robert Braathe",
    "title": "Businessman",
    "text": "Gratitude and attitude are not challenges, they are choices."
  },
  {
    "category": "Gratitude",
    "author": "Habeen Akande",
    "title": "Author",
    "text": "I may not be where I want to be but I’m thankful for not being where I used to be."
  },
  {
    "category": "Gratitude",
    "author": "Gertrude Stein",
    "title": "Author",
    "text": "Silent gratitude isn’t much use to anyone."
  },
  {
    "category": "Gratitude",
    "author": "Oprah Winfrey",
    "title": "Media Mogul and Philanthropist",
    "text": "Be thankful for what you have; you’ll end up having more. If you concentrate on what you don’t have, you will never, ever, have enough."
  },
  {
    "category": "Gratitude",
    "author": "Louisa May Alcott",
    "title": "Author",
    "text": "The power of finding beauty in the humblest things makes home happy and life lovely."
  },
  {
    "category": "Gratitude",
    "author": "John Lennon",
    "title": "Musician",
    "text": "You may say I'm a dreamer, but I'm not the only one. I hope someday you'll join us. And the world will live as one."
  },
  {
    "category": "Gratitude",
    "author": "John F. Kennedy",
    "title": "President of the United States",
    "text": "We must find time to stop and thank the people who make a difference in our lives."
  },
  {
    "category": "Gratitude",
    "author": "Arthur C. Clarke",
    "title": "Writer",
    "text": "A hundred failures would not matter, when one single success could change the destiny of the world."
  },
  {
    "category": "Learn from Mistakes",
    "author": "James Joyce",
    "title": "Novelist",
    "text": "Mistakes are the portals of discovery."
  },
  {
    "category": "Learn from Mistakes",
    "author": "George Bernard Shaw",
    "title": "Playwright",
    "text": "Success does not consist in never making mistakes but in never making the same one a second time."
  },
  {
    "category": "Learn from Mistakes",
    "author": "John C. Maxwell",
    "title": "Author and Leadership Expert",
    "text": "The only real mistake is the one from which we learn nothing."
  },
  {
    "category": "Learn from Mistakes",
    "author": "Maxwell Maltz",
    "title": "Plastic Surgeon and Author",
    "text": "You make mistakes. Mistakes don't make you."
  },
  {
    "category": "Learn from Mistakes",
    "author": "Thomas A. Edison",
    "title": "Inventor",
    "text": "I have not failed. I've just found 10,000 ways that won't work."
  },
  {
    "category": "Learn from Mistakes",
    "author": "Alfred Mercier",
    "title": "Poet",
    "text": "What we learn with pleasure, we never forget."
  },
  {
    "category": "Learn from Mistakes",
    "author": "Robert K. Kennedy",
    "title": "Politician",
    "text": "Only those who dare to fail greatly can ever achieve greatly."
  },
  {
    "category": "Learn from Mistakes",
    "author": "Albert Einstein",
    "title": "Theoretical Physicist",
    "text": "A person who never made a mistake never tried anything new."
  },
  {
    "category": "Learn from Mistakes",
    "author": "Thomas Fuller",
    "title": "Writer",
    "text": "All things are difficult before they are easy."
  },
  {
    "category": "Learn from Mistakes",
    "author": "Gordon B. Hinckley",
    "title": "Religious Leader and Author",
    "text": "You have not failed until you have quit trying."
  },
  {
    "category": "Learn from Mistakes",
    "author": "Oprah Winfrey",
    "title": "Media Mogul and Philanthropist",
    "text": "Turn your wounds into wisdom."
  },
  {
    "category": "Learn from Mistakes",
    "author": "Truman Capote",
    "title": "Novelist and Screenwriter",
    "text": "Failure is the condiment that gives success its flavor."
  }
]