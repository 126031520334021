import jp from "jsonpath"
import { ERR_CANNOT_GET_DATA_FROM_SERVER } from '../../../constants'
import { createGenericAuthRequest, ignoreJsonError } from '../../request/request-utils'
import { getCachedValue, setCacheValue } from '../../utils/cache-util'
import { collegeReadyLookupForPracticeTests } from '../../utils/college-ready-util'
import { TestType, getTestTypeBasedOnName } from '../../utils/practice-test-util'
import { isProductionEnv } from "../../utils/env-helper"

const request = createGenericAuthRequest(process.env.REACT_APP_ELEARN_URL || '/')
const devOrQAEnv = !isProductionEnv()

function getTestsInsightsRawData(testType: string) {
  return request<any>(`/teacher/tests/insights/${testType}`)
}

function getTestsInsightDetailsRawData(testName: string) {
  return request<any>(`/teacher/tests/insights/details/${testName}`)
}

export async function getTestsInsights(testType: TestType) {
  let cacheKey = `practice-tests-insights-${testType}`
  let data = getCachedValue(cacheKey)

  if (!data) {
    try {
      data = await getTestsInsightsRawData(testType)
      setCacheValue(cacheKey, data)
    }
    catch (ex) {
      console.error(ERR_CANNOT_GET_DATA_FROM_SERVER, ex)
    }
  }
  return data
}

export async function getTestsInsightDetails(testName: string) {
  let cacheKey = `practice-tests-insight-details-${testName}`
  let data = getCachedValue(cacheKey)

  if (!data || devOrQAEnv) {    
    try {
      data = await getTestsInsightDetailsRawData(testName)
      setCacheValue(cacheKey, data)
    }
    catch (ex) {
      console.error(ERR_CANNOT_GET_DATA_FROM_SERVER, ex)
    }
  }
  return data
}

// Case sensitive section keys for ACT/IA & SAT
enum SectionKeyACT {
  ENGLISH = "english",
  MATH    = "math",
  READING = "reading",
  SCIENCE = "science",  
}

enum SectionKeySAT {
  READING = "reading",
  WRITING_AND_LANGUAGE = "writing & language",
  MATH_NO_CALC = "math (no calculator)",
  MATH_CALC = "math (calculator)"
}

type SectionKey = SectionKeyACT | SectionKeySAT


export async function getPracticeTestSectionDetails(sectionKey: string, testType: TestType, studentTestInsightsDetails) {
  let testTypeDetails: any[] = []
  
  sectionKey = sectionKey.toLowerCase()

  // Let's get the test insights details based on the testType
  for (let studentDetail of studentTestInsightsDetails) {
    let d = testType === TestType.ACT ? studentDetail.detailsACT :  
            testType === TestType.SAT ? studentDetail.detailsSAT : 
            studentDetail.detailsIA 

    testTypeDetails.push(d)
  }

  const getSectionDetails = (rawData, sectionKey: SectionKey) => {
    let sectionAnswerNames = [
      "englishAnswers",
      "mathAnswers",
      "readingAnswers",
      "scienceAnswers",
      "writingAndLanguageAnswers",
      "mathNoCalculatorAnswers",
      "mathCalculatorAnswers"
    ]

    // Add email address to each answer
    rawData.filter(d => d).forEach(d => {
      let {emailAddress, questions, questionMap} = d
      sectionAnswerNames.forEach(name => {
        if (questions && questions[name]) {
          questions[name] = questions[name].map(d => { return {emailAddress, ...d}})
        }
        if (questionMap && questionMap[name]) {
          questionMap[name] = questionMap[name].map(d => { return {emailAddress, ...d}})
        }
      })
    })

    let query = ""

    switch (sectionKey) {
      // ACT/IA section keys
      case SectionKeyACT.ENGLISH:
        query = "$..englishAnswers"
        break
      case SectionKeyACT.MATH:
        query = "$..mathAnswers"
        break
      case SectionKeyACT.READING:
        query = "$..readingAnswers"
        break
      case SectionKeyACT.SCIENCE:
        query = "$..scienceAnswers"
        break

      // SAT section keys
      case SectionKeySAT.WRITING_AND_LANGUAGE:
        query = "$..writingAndLanguageAnswers"
        break
      case SectionKeySAT.MATH_NO_CALC:
        query = "$..mathNoCalculatorAnswers"
        break
      case SectionKeySAT.MATH_CALC:
        query = "$..mathCalculatorAnswers"
        break
      case SectionKeySAT.READING:
        query = "$..readingAnswers"
        break
    }
    
    let detailsJP = jp.query(rawData, query).flat()
    let details = detailsJP.map((item: any) => {
      let {emailAddress, questionNumber, correctAnswer, lessonId, lessonName, topicId, topicName, userSubmittedAnswer} = item
      return {
        emailAddress,     
        questionNumber,
        correctAnswer,
        lessonId,
        lessonName,
        topicId,
        topicName,
        userSubmittedAnswer,
        correct: item.correctAnswer === item.userSubmittedAnswer,
      }
    })
  
    return details
  }

  const preparePivot = (data: any[], index1: string, index2: string, index3: string, index4: string): any => {  
    let pivot = {}
    data.forEach(row => {
      let lessonId = row["lessonId"]
      let lessonName = row["lessonName"]
      let key1 = row[index1]
      let key2 = row[index2]
      let key3 = row[index3]
      let key4 = row[index4]
      let info1 = pivot[key1]
      if (!info1) {
        info1 = {nofAnswers: 0, nofCorrectAnswers: 0, accuracy: 0, overallAccurracy: 0, lessons: {}}
        pivot[key1] = info1
      }
      let info2 = pivot[key1].lessons[key2]
      if (!info2) {
        info2 = {lessonId, lessonName, nofAnswers: 0, nofCorrectAnswers: 0, accuracy: 0, overallAccurracy: 0, emailAddresses: {}}
        pivot[key1].lessons[key2] = info2      
      }
      let info3 = pivot[key1].lessons[key2].emailAddresses[key3]
      if (!info3) {
        info3 = {nofAnswers: 0, nofCorrectAnswers: 0, accuracy: 0, questionNumbers: {}}
        pivot[key1].lessons[key2].emailAddresses[key3] = info3
      }

      let info4 = pivot[key1].lessons[key2].emailAddresses[key3].questionNumbers[key4]
      if (!info4) {
        info4 = {nofAnswers: 0, nofCorrectAnswers: 0, accuracy: 0}
        pivot[key1].lessons[key2].emailAddresses[key3].questionNumbers[key4] = info4
      }
  
      if (row.userSubmittedAnswer && row.userSubmittedAnswer !== "") {
        info1.nofAnswers++
        info2.nofAnswers++
        info3.nofAnswers++
        info4.nofAnswers++
  
        if (row.correct) {
          info1.nofCorrectAnswers++
          info2.nofCorrectAnswers++
          info3.nofCorrectAnswers++        
          info4.nofCorrectAnswers++        
        }
        
        info1.accuracy = 100.0*info1.nofCorrectAnswers/info1.nofAnswers
        info2.accuracy = 100.0*info2.nofCorrectAnswers/info2.nofAnswers
        info3.accuracy = 100.0*info3.nofCorrectAnswers/info3.nofAnswers
        info4.accuracy = 100.0*info4.nofCorrectAnswers/info4.nofAnswers
      }
    })
  
    let topicKeys = Object.keys(pivot)
    topicKeys.forEach(topicKey => {
      let topic = pivot[topicKey]
      let lessons = topic.lessons
      let lessonKeys = Object.keys(lessons)
      let nofTotalTopicQuestions = 0
      lessonKeys.forEach(lessonKey => {
        let lesson = lessons[lessonKey]
        let emailAddresses = lesson.emailAddresses
        let emailAddressKeys = Object.keys(emailAddresses)
        let lessonQuestionNumbers: any[] = []
        let questionsJP = jp.query(lesson, `$..questionNumbers`)
        let questionKeys = Object.keys(questionsJP[0])
        lesson.uniqueLessonQuestions = questionKeys.length
        emailAddressKeys.forEach(emailAddress => {
          let studentAnswers = emailAddresses[emailAddress]
          let questionNumbers = studentAnswers.questionNumbers
          let questionNumberKeys = Object.keys(questionNumbers)
          let nofLessonQuestions = questionNumberKeys.length
          studentAnswers.nofLessonQuestions = nofLessonQuestions
          nofTotalTopicQuestions += nofLessonQuestions
          if (studentAnswers.nofAnswers > 0) {
            let studentQuestionNumbers = questionNumberKeys
              .map((questionNumber) => {
                return {
                  emailAddress,
                  questionNumber,
                  ...studentAnswers.questionNumbers[questionNumber],
                }
              })
            lessonQuestionNumbers = lessonQuestionNumbers.concat(studentQuestionNumbers)
          }
        })
        let correctAnswers = lessonQuestionNumbers.filter((q) => q.nofCorrectAnswers > 0)
        lesson.questionsNumbers = lessonQuestionNumbers
        lesson.overallAccurracy = lessonQuestionNumbers.length > 0 ? 100*correctAnswers.length/lessonQuestionNumbers.length: 0
      })
      let uniqueQuestionsJP = jp.query(lessons, `$..uniqueLessonQuestions`)
      let overallAccuraciesJP = jp.query(lessons, `$..overallAccurracy`)
      topic.nofTopicQuestions = uniqueQuestionsJP.reduce((a: number, b: number) => a + b, 0)
      topic.overallAccurracy = overallAccuraciesJP.length > 0 ? overallAccuraciesJP.reduce((a: number, b: number) => a + b, 0)/overallAccuraciesJP.length: 0
    })

    let allTopics: any[] = []
    topicKeys.forEach(topicKey => {
      let topic = pivot[topicKey]
      allTopics.push({
        topic: topicKey,
        accuracy: topic.accuracy,
        overallAccurracy: topic.overallAccurracy,
        nofTopicQuestions: topic.nofTopicQuestions,
      })
    })
  
    let lowestAccuracies, highestFrequencies
    let maxAccuraciesToCapture = 3
    let maxFrequenciesToCapture = 2

    allTopics.sort((a, b) => a.overallAccurracy - b.overallAccurracy)
    lowestAccuracies = allTopics.slice(0, maxAccuraciesToCapture).map(item => item.topic)
    allTopics.sort((a, b) => b.nofTopicQuestions - a.nofTopicQuestions)
    highestFrequencies = allTopics.slice(0, maxFrequenciesToCapture).map(item => item.topic)
  
    // Finalize the pivot
    pivot = {lowestAccuracies, highestFrequencies, topics: pivot}
  
    return pivot
  }

  let sectionDetails = getSectionDetails(testTypeDetails, sectionKey as SectionKey)
  let pivot

  // We need to do pivot based on the categorical sections (requested by Jennifer, 31 July 2023/Mon)
  // "Reading and Science need to focus more on topics, because our follow-up resources in 
  // those subjects are at the topical level." (from Slack channel)
  // Pivot usage for categorical sections:
  // ACT/IA:
  // * English & Math: Lesson > Topic
  // * Reading & Science: Topic > Lesson
  // SAT:
  // * Reading: Topic > Lesson
  // * Writing & Language, Math (Calc & No-calc): Lesson > Topic
  //
  // Update (9 Sept 2024/Mon):
  // For the SAT, it will go with Lesson > Topic as others
  // Also note that SAT 2024 has the following mapping internally:
  // * Reading -> R&W Module 1
  // * Writing and Language -> R&W Module 2
  // * Math (No Calculator) -> Math Module 1
  // * Math (Calculator) -> Math Module 2
  let isTopicFirstInPivot = (sectionKey) => {
    let readingOrScienceACT = sectionKey === SectionKeyACT.READING || sectionKey === SectionKeyACT.SCIENCE

    return testType !== TestType.SAT && readingOrScienceACT
  }

  if (isTopicFirstInPivot(sectionKey)) {
    pivot = preparePivot(sectionDetails, "topicName", "lessonName", "emailAddress", "questionNumber")
  }
  else {
    pivot = preparePivot(sectionDetails, "lessonName", "topicName",  "emailAddress", "questionNumber")
  }

  return pivot
}

export async function getTestsInsightsByTestName(testName, dataInsights): Promise<ITestInsightACT[]|ITestInsightSAT[]> {
  let actCollegeReadyE    = collegeReadyLookupForPracticeTests.ACT.English
  let actCollegeReadyM    = collegeReadyLookupForPracticeTests.ACT.Math
  let actCollegeReadyR    = collegeReadyLookupForPracticeTests.ACT.Reading
  let actCollegeReadyS    = collegeReadyLookupForPracticeTests.ACT.Science
  let satCollegeReadyEBRW = collegeReadyLookupForPracticeTests.SAT.EBRW
  let satCollegeReadyM    = collegeReadyLookupForPracticeTests.SAT.Math
  let testType = getTestTypeBasedOnName(testName)
  let studentsEmailsMap = dataInsights.reduce((acc, cur) => {
    let {firstName, lastName, emailAddress, graduationYear} = cur
    acc[emailAddress] = {
      firstName,
      lastName,
      graduationYear,
    }
    return acc
  }, {})

  let reports = dataInsights
    .filter(d => d.reports && d.reports.find(d => d.testName === testName))
    .flatMap(d => d.reports)
    .filter(d => d.testName === testName)
    .map(d => {
      let studentInfo = studentsEmailsMap[d.emailAddress]
      let {firstName, lastName, graduationYear} = studentInfo
      let collegeReadyE = d.english >= actCollegeReadyE
      let collegeReadyM = d.math >= actCollegeReadyM
      let collegeReadyR = d.reading >= actCollegeReadyR
      let collegeReadyS = d.science >= actCollegeReadyS
      let collegeReadySATEBRW = d.evidenceBasedRW >= satCollegeReadyEBRW
      let collegeReadySATMath = d.math >= satCollegeReadyM
      let nofTotalQuestionsAnswered = 0
      let nofTotalQuestionsIncorrect = 0
      let nofTotalQuestionsBlank = 0  // i.e. not answered questions by the student
      let nofTotalQuestionsMissed = 0 // i.e. mistakes made by the student in the test (incorrect + blank)
      let nofTotalHintsWritten = 0      
      let foundTest = dataInsights
        .filter(di => di.emailAddress === d.emailAddress)
        .flatMap(di => di.tests)
        .find(test => test.testName === testName)
      let foundHints = dataInsights
        .filter(di => di.emailAddress === d.emailAddress)
        .flatMap(di => di.hints)
        .filter(hint => hint.testName === testName)
      
      if (foundTest && foundTest.testStats) {
        let testStats = foundTest.testStats        
        let keys = Object.keys(testStats)
        keys.forEach(key => {
          let stat = testStats[key]
          nofTotalQuestionsAnswered += stat.answered
          nofTotalQuestionsIncorrect += stat.incorrect
        })

        let nofTotalQuestions = (testType === TestType.ACT || testType === TestType.IA) ? TestsMetadata.nofTotalQuestionsACT: TestsMetadata.nofTotalQuestionsSAT
        nofTotalQuestionsBlank = nofTotalQuestions - nofTotalQuestionsAnswered
        nofTotalQuestionsMissed = nofTotalQuestionsIncorrect + nofTotalQuestionsBlank
      }

      if (foundHints) {
        nofTotalHintsWritten = foundHints.length
      }

      return {
        ...d,
        compositeRange: d.satCompositeRange.find(d => d.active),  
        firstName,
        lastName,
        graduationYear,
        collegeReady: {
          act: {
            english: collegeReadyE,
            math:    collegeReadyM,
            reading: collegeReadyR,
            science: collegeReadyS,
          },
          sat: {
            ebrw: collegeReadySATEBRW,
            math: collegeReadySATMath
          }
        },
        nofTotalQuestionsAnswered,
        nofTotalQuestionsIncorrect,
        nofTotalQuestionsMissed,
        nofTotalHintsWritten,
      }
    })

  let insights = reports.map(report => {
    let {firstName, lastName, graduationYear, emailAddress, testName, average, compositeRange, collegeReady} = report
    let {english, reading, science} = report // ACT scores
    let {evidenceBasedRW: ebrw} = report     // SAT scores
    let {math} = report                      // Math is used both in ACT/IA & SAT
    let {nofTotalQuestionsAnswered, nofTotalQuestionsMissed, nofTotalHintsWritten} = report
    let insight = {
      testName,
      firstName,
      lastName,
      graduationYear,
      emailAddress,
      compositeRange: {act: compositeRange.act, sat: compositeRange.sat},
      composite: average,  // Average is the composite in all      
      scores: {
        act: {
          english: {value: english, collegeReady: collegeReady.act.english}, 
          math:    {value: math,    collegeReady: collegeReady.act.math},
          reading: {value: reading, collegeReady: collegeReady.act.reading},
          science: {value: science, collegeReady: collegeReady.act.science},
        },
        sat: {
          ebrw: {value: ebrw, collegeReady: collegeReady.sat.ebrw},
          math: {value: math, collegeReady: collegeReady.sat.math}
        }
      },
      stats: {
        nofTotalQuestionsAnswered,
        nofTotalQuestionsMissed,
        nofTotalHintsWritten,
      }
    }
    return insight
  })

  return insights
}

export interface ITestInsightScore {
  value: number
  collegeReady: boolean
}

export interface ITestInsightScoreCompositeRange {
  act: string
  sat: string
}

export interface ITestInsight {
  testName: string
  firstName: string
  lastName: string
  emailAddress: string
  graduationYear: string
  composite: number
  compositeRange: ITestInsightScoreCompositeRange
  stats: {
    nofTotalQuestionsAnswered: number
    nofTotalQuestionsMissed: number
    nofTotalHintsWritten: number
  }
}

export interface ITestInsightDetails {
  id: string
  firstName: string
  lastName: string
  emailAddress: string
  graduationYear: string
  detailsACT?: ITestInsightDetailsACT
  detailsIA?:  ITestInsightDetailsACT
  detailsSAT?: ITestInsightDetailsSAT
}

export interface ITestInsightDetailsACT {
  testName: string
  test: string
  exam: string
  form: string
  isActive: boolean
  displayName: string
  questions: {
    englishAnswers: ITestQuestion[],
    mathAnswers: ITestQuestion[]
    readingAnswers: ITestQuestion[]
    scienceAnswers: ITestQuestion[]
  }
}

export interface ITestInsightDetailsSAT {
  testName: string
  test: string
  exam: string
  form: string
  isActive: boolean
  displayName: string
  questionMap: {
    writingAndLanguageAnswers: ITestQuestion[]
    readingAnswers: ITestQuestion[]
    mathNoCalculatorAnswers: ITestQuestion[],
    mathCalculatorAnswers: ITestQuestion[]
  }
}

export interface ITestQuestion {
  lessonId: string
  lessonName: string
  topicId: string
  topicName: string
  videoId: number|string
  questionNumber: number
  correctAnswer: string
  userSubmittedAnswer: string
  mcLetters: string[]
  gridIn?: string
  satExplanation?: string
  star: boolean
  bookmarked: boolean
}


export interface ITestInsightACT extends ITestInsight {
  scores: {
    act : ITestInsightACTScores    
  }
}

export interface ITestInsightSAT extends ITestInsight {
  scores: {
    sat: ITestInsightSATScores
  }
}

export interface ITestInsightACTScores {
  english: ITestInsightScore
  math:    ITestInsightScore
  reading: ITestInsightScore
  science: ITestInsightScore
}


export interface ITestInsightSATScores {
  ebrw: ITestInsightScore
  math: ITestInsightScore
}

export class TestsMetadata {
  public static readonly nofTotalQuestionsACT = 215
  public static readonly nofTotalQuestionsSAT = 154
}
