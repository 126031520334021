import * as React from 'react'
import ReportLoadingMessage from './ReportLoadingMessage'
import SummaryFilters from './SummaryFilters'
import { CurrentStatus, getAllStudentsLiveViewInfo, LiveViewStatusFull } from '../../services/liveview/liveview-service'
import LiveViewTable from './LiveViewTable'
import { isDevelopment } from '../../services/utils/env-helper'
import { TeacherStore, ITeacherStoreData, NotificationType } from "../../stores/teacher.store"
import { GraduationYearDescriptor, StudentData } from './types'
import { NO_STUDENT_INFO_FOUND } from './info'
import { NOT_SPECIFIED } from '../../constants'

export interface Props { }

const POLL_INTERVAL = isDevelopment() ? 15_000: 180_000 // In milliseconds

interface State {
  selectedSchoolName?: string
  selectedGraduationYear?: string
  selectedPeriod?: string
  graduationYears: GraduationYearDescriptor[]
  selectedTimeRange?: string
  searchQuery?:string
  data: StudentData[]
  selectedData: StudentData[]  
  anyCardSelected: boolean
  liveData: LiveViewStatusFull[]
  loaded: boolean
}

export default class LiveViewOverview extends React.Component<Props, State> {
  pollTimerId
  unsubscribe

  constructor(props: Props) {
    super(props)
    this.state = {
      selectedSchoolName: undefined,
      selectedGraduationYear: undefined,
      selectedPeriod: undefined,
      data: [],
      selectedData: [],
      graduationYears: [],
      searchQuery: "",
      anyCardSelected: false,
      liveData: [],
      loaded: false
    }
  }

  public componentDidMount() {
    TeacherStore.loadStudents()
    TeacherStore.loadStudentsActivities()
    this.unsubscribe = TeacherStore.subscribe((d, t) => {
      this.setMainData(d, t)
    })
    this.pollTimerId = setInterval(() => {
      TeacherStore.loadStudentsActivities()  
    }, POLL_INTERVAL)
  }

  componentWillUnmount() {
    clearInterval(this.pollTimerId)
    this.unsubscribe()
  }

  setMainData(data: ITeacherStoreData, t?: NotificationType) {
    if (t !== NotificationType.STUDENTS && 
        t !== NotificationType.ACTIVITIES &&
        t !== NotificationType.GENERIC) {
      return
    }
    let { students, studentsActivities, graduationYears } = data
    this.setState({ 
      data: students, selectedData: students, 
      liveData: studentsActivities,
      graduationYears,
      loaded: true
    }, () => this.applyFilter())
  }

  onGraduationYearSelected(selectedYear: string) {
    this.setState(
      { selectedGraduationYear: selectedYear === 'All' ?  
          undefined : selectedYear
      }, 
      () => this.applyFilter()
    )
  }

  onNewSchoolSelected(selectedSchool: string) {
    this.setState({
        selectedSchoolName: selectedSchool === 'All' ? 
          undefined : selectedSchool 
      }, () => this.applyFilter()
    )
  }

  onNewPeriodSelected(selectedPeriod: string) {
    this.setState({
        selectedPeriod: selectedPeriod === 'All' ? 
          undefined : selectedPeriod 
      }, () => this.applyFilter()
    )
  }

  onTimeRangeSelected(selected: string) { }

  private applyFilter = () => {
    let { data, selectedGraduationYear, selectedSchoolName, selectedPeriod } = this.state
    let selectedData = data.filter(d => {
      let selectStudent = true
      let period = d.period || NOT_SPECIFIED
      if ((selectedGraduationYear && selectedGraduationYear !== d.graduationYear) ||
          (selectedSchoolName && selectedSchoolName !== d.schoolName) ||
          (selectedPeriod && selectedPeriod !== period)) {
        selectStudent = false
      }
      return selectStudent
    })
    this.setState({selectedData})
  }

  handleSearch = (searchQuery) => {
    let { data } = this.state
    
    this.setState({searchQuery})

    searchQuery = searchQuery ? searchQuery.trim().toLowerCase(): ""

    if (!searchQuery) {
      this.setState({selectedData: data})
      return
    }

    let keys = searchQuery.split(/[ *]/).filter(k => k)
    let selectedData = this.state.data.filter(d => {
      let firstName = d.firstName ? d.firstName.toLowerCase() : ""
      let lastName = d.lastName ? d.lastName.toLowerCase() : ""
      let combined = `${firstName} ${lastName}`
      let included = true
      let selectionMap = {}

      keys.forEach(key => selectionMap[key] = combined.includes(key) ? true: false)
      Object.keys(selectionMap).forEach(k => {
        if (!selectionMap[k]) {
          included = false
        }
      })

      return included
    })
    this.setState({ selectedData })
  }

  clearSearch = () => {
    this.setState({ searchQuery: "" })
    this.handleSearch("")
  }

  public render() {
    const { data, selectedData, liveData, loaded } = this.state
    const updatedAt = new Date().toLocaleString()
    const NA = "N/A"
    const augmententedLiveData: LiveViewStatusFull[] = []
    
    selectedData.forEach(d => {
      let item = liveData.find(ld => ld.userId == d.id)
      if (!item) {
        item = { 
          userId: d.id,
          firstName: d.firstName,
          lastName: d.lastName,
          currentStatus: CurrentStatus.NEVER,
          navigation: NA,
          activity: NA,
          updatedAt: NA
        }
      }
      augmententedLiveData.push(item)
    })

    return (
        <div className="lesson-overview">
          <SummaryFilters 
            enableTimeRangeSelection={false}
            enablePeriodSelection={true}
            onNewGraduationYearSelected={(gradYear) => this.onGraduationYearSelected(gradYear)}
            onNewSchoolSelected={(schoolSel) => this.onNewSchoolSelected(schoolSel)}
            onNewPeriodSelected={(period) => this.onNewPeriodSelected(period)}
            onNewTimeRangeSelected={(timeRange) => this.onTimeRangeSelected(timeRange)}
          />
          
          {!loaded && <ReportLoadingMessage />}

          <div className="individual-data-section">
            <h2 className="h3 individual-data-title clear-search">Live Student Activity</h2>

            <div className="search-box">
              <input  
                type="text" 
                placeholder="Student Search" 
                value={this.state.searchQuery}
                onChange={e => this.handleSearch(e.target.value)} /> 
              <button title="Clear search" className="btn-simple clear-btn" onClick={this.clearSearch}>x</button>
            </div>
            <hr/>
          </div>
          { augmententedLiveData.length > 0 ?
            <div>
              <div className="student-data-card">
                <div id="liveViewContainer">
                <LiveViewTable data={augmententedLiveData} />
                </div>
              </div>
              Last update: { updatedAt }
            </div>
            :
            <div>
              {NO_STUDENT_INFO_FOUND}
            </div>
          }
        </div>
    )
  }
}
