import * as React from 'react';
import { groupBy } from 'lodash';
import { 
  SectionCode, 
  sectionCodeToDisplayName 
} from '../../../../../services/section-colors';

import './index.css';
import TestHintLineItem from '../TestHintLineItem';

interface Props {
  title: string;
  testHints: any;
}

// Test Hints grouped by test type (ACT, SAT, etc)
const TestHintGroup: React.SFC<Props> = ({ title, testHints }) => {
  const hintGroups = groupBy(testHints, 'sectionCode');
  const sectionKeys = Object.keys(hintGroups);
  const hasHints = testHints.length > 0;
  if (!hasHints) {
    return (<div></div>);
  }

  return (
    <div className="lesson-hint-section">
      <div className="lesson-hint-section--title" style={{backgroundColor: '#fff4DD'}}>{title}</div>
      <div className="topic-hint-section">
        {sectionKeys.map(key => {
        const hints = hintGroups[key];
        return (
            <div key={`${key}`}>
              <div className="topic-hint-section--title topic-hint-row border-bottom border-bottom--practice">
                {sectionCodeToDisplayName(key as SectionCode)}
              </div>
              <ul className="section-hint-list">
                {hints.map(testHint => {
                  const {testName, sectionCode, questionNumber} = testHint;
                  return (
                    <TestHintLineItem 
                      key={`${testName}-${sectionCode}-${questionNumber}`}
                      testHint={testHint} 
                    />
                  );
                })}
              </ul>
            </div>
          );
        })
        }
      </div>
    </div>
  );
};

export default TestHintGroup;
