import { 
    updateSchoolSafety, 
    SchoolSafety 
} from '../services/school-safety/school-safety-rest-interface';
import { 
    getSSSDashboard, 
    getSSSMistakeBankDashboard 
} from '../services/school-safety/school-safety-rest-interface';

const GET_SCHOOL_SAFETY = 'school-safety/GET_SCHOOL_SAFETY';
const SET_SCHOOL_SAFETY = 'school-safety/SET_SCHOOL_SAFETY';
const SET_SSS_DASHBOARD_OVERVIEW = 'school-safety/SET_SSS_DASHBOARD_OVERVIEW';
const ADD_SSS_MISTAKE_BANK_OVERVIEW = 'school-safety/ADD_SSS_MISTAKE_BANK_OVERVIEW';
const UPDATE_LOCAL_SCHOOL_SAFETY = 'school-safety/UPDATE_LOCAL_SCHOOL_SAFETY';

export interface SchoolSafetyState {
    schoolSafety: Partial<SchoolSafety>;
}

const defaultState = {
    schoolSafety: []
};

export function getSchoolSafetyDashboardOverview() {
    return (dispatch) => {
        return getSSSDashboard()
            .then(overview => dispatch(setDashboardOverview(overview)));
    };
}

export function getSchoolSafetyMistakeBankOverview() {
    return (dispatch) => {
        return getSSSMistakeBankDashboard()
            .then(overview => dispatch(setSSSMistakeBankOverview(overview)));
    };
}

export function setSSSMistakeBankOverview(overview: any) {
    return {
        type: ADD_SSS_MISTAKE_BANK_OVERVIEW,
        overview
    };
}

export function setDashboardOverview(overview: any) {
    return {
        type: SET_SSS_DASHBOARD_OVERVIEW,
        ...overview
    };
}

export function setSchoolSafety(schoolSafety: any) {
    return {
        type: SET_SCHOOL_SAFETY,
        schoolSafety: schoolSafety.sssUserLessonOverviews
    };
}

export function updateUserSchoolSafety(id: string, status: string) {
    return(dispatch) => {
        return updateSchoolSafety(id, status)
            .then(() => dispatch(updateLocalSchoolSafety(id, status)));
    };
}

function updateLocalSchoolSafety(id: string, status: string) {
    return {
        type: UPDATE_LOCAL_SCHOOL_SAFETY,
        id: id,
        status: status
    };
}

export default function(state: any = defaultState, action: any) {
    switch(action.type) {
        case GET_SCHOOL_SAFETY: {
            return {...state };
        }
        case ADD_SSS_MISTAKE_BANK_OVERVIEW: {
            const { overview } = action;
            return {
                ...state,
                mistakeBankOverview: overview
            };
        }
        case SET_SSS_DASHBOARD_OVERVIEW: {
            const { currentLessonId, numLessonsUnstarted, numLessonsStarted, numLessonsCompleted } = action;
            return { ...state, currentLessonId, numLessonsUnstarted, numLessonsStarted, numLessonsCompleted };
        }
        case SET_SCHOOL_SAFETY: {
            const  schoolSafety  = action.schoolSafety;
            return { ...state, schoolSafety: schoolSafety };
        }
        case UPDATE_LOCAL_SCHOOL_SAFETY: {
            let clonedArray = [ ...state.schoolSafety ];
            let idx = clonedArray.findIndex(obj => {
                return action.id === obj.lessonId;
            });
            if (idx > -1) {
                clonedArray[idx].lessonId = action.id;
                clonedArray[idx].status = action.status;
            }
            
            return { ...state, clonedArray };
        }
        default:
            return state;
    }
}
