import './DifficultyLevel.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import Popover from '../../../Popover/Popover';
import RadialProgress from '../../../RadialProgress/RadialProgress';

interface Props {
    difficultyLevel: number;
}

interface State {
    isHovering: boolean;
}


export default class DifficultyLevel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isHovering: false
        };
    }

    public render() {
        const { difficultyLevel } = this.props;
        const { isHovering } = this.state;
        if(difficultyLevel === undefined || difficultyLevel === null) {
            return null;
        }

        return (
            <div className="difficulty-level" data-rel={difficultyLevel} onMouseOver={this.handleMouseOver} onMouseLeave={this.handleMouseLeave}>
                {/* <RadialProgress
                    size={48}
                    radius={21}
                    progress={difficultyLevel / 5 * 100}
                    text={difficultyLevel.toString()}
                    startColor="#FEAA04"
                    endColor="#F74A51"
                    pegOffset={3}
                    progressLineWidth={2}
                    backgroundLineWidth={4}
                    backgroundColor="#E9E8E8"
                    textStyle={{ color: 'unset' }}
                /> */}
                <div className={'difficulty-dial difficulty-dial--' + difficultyLevel}><span className="difficulty-dial--value">{difficultyLevel}</span></div>

                <Popover visible={isHovering} xAlignment="center">
                    <span className="difficulty-level__level-text">Level of difficulty: {difficultyLevel}</span>
                    <span className="difficulty-level__scale-text">Scale: 1 (low) to 5 (high)</span>
                </Popover>
            </div>
        );
    }

    @boundMethod
    private handleMouseOver() {
        this.setState({ isHovering: true });
    }

    @boundMethod
    private handleMouseLeave() {
        this.setState({ isHovering: false });
    }
}
