/**
 * Sort compare function to be used when sorting either numbers or strings.
 * In the case of strings, it is able to use numerics in alphanumeric combinations.
 * Primary score is dominant, secondary score just nudges the overall score slightly.
 * 
 * @param val1 (primary)
 * @param val2 (primary)
 * @param val3 (optional secondary) 
 * @param val4 (optional secondary)
 * @returns sortability value for the sort method
 */
export const alphanumericCompare = (val1: string|number, val2: string|number, val3?: string|number, val4?: string|number): number => {
  let score = 0;
  
  if (typeof val1 === "number" && typeof val2 === "number") {
    score = val1 - val2
  } 
  else if (typeof val1 === "string" && typeof val2 === "string") {
    score = val1.localeCompare(val2, undefined, {numeric: true, sensitivity: 'base'});
  }

  // If the optional secondary parameters provided...
  if (val3 && val4) {
    let adjustmentFactor = 0.001
    let secondaryScore

    if (typeof val3 === "number" && typeof val4 === "number") {
      secondaryScore = val3 - val4
    } 
    else if (typeof val3 === "string" && typeof val4 === "string") {
      secondaryScore = val3.localeCompare(val4, undefined, {numeric: true, sensitivity: 'base'})
    }
    score += adjustmentFactor*secondaryScore
  }

  return score    
}
