import { getCurrentLessonContent, sendUserFeedback } from '../../stores/lesson.store';

import { AppState } from '../../store';
import FeedbackPopup from './FeedbackPopup';
import { MaterialType } from '../../services/elearn/lesson-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


const mapStateToProps = ({ lesson, account }: AppState) => {
    const currentLessonContent = getCurrentLessonContent(lesson);

    if(currentLessonContent) {
        if(currentLessonContent.type === MaterialType.TOPIC) {
            return {
                topicId: currentLessonContent.id,
                lessonId: lesson.currentLessonId,
                email: account.userInfo.emailAddress
            };
        } else {
            return {
                questionId: currentLessonContent.id,
                lessonId: lesson.currentLessonId,
                email: account.userInfo.emailAddress
            };
        }
    }
    return {
        lessonId: lesson.currentLessonId,
        email: account.userInfo.emailAddress
    };
};

const mapDispatchToProps = { sendUserFeedback };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedbackPopup)
);
