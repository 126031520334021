import {getDetailPTScoresByTest, getDetailIAScoresByTest, getOverallPTScore, getOverallIAScore} from "./practice-test-service"
import { getUserLessons } from "../user/user-lesson-rest-interface"

/**
 * This will analyze the practice test and return the results
 * 
 * @param testType // ACT | SAT | IA
 * @param testName // Practice test name
 */
export async function analyzeTest(testType: string, testName: string): Promise<IAnalyzedTestResult> {
  let data = await getData(testType, testName)

  return analyzeTestResult(testType, data.result, data.lessons, data.scores)
}


/**
 * Will get the required data for the ACT/SAT/IA Practice Test Analysis
 * 
 * @param testType 
 * @param testName 
 * @returns 
 */
async function getData(testType: string, testName: string) {
  let result, scores
  let lessons = (await getUserLessons()).userLessonOverviews
  
  testType = testType.toUpperCase()

  if (testType !== "IA") {
    // This will get either ACT or SAT test results
    result = await getDetailPTScoresByTest(testName)
    scores = await getOverallPTScore(testName)
  }
  else {
    // This will get the IA results
    result = await getDetailIAScoresByTest(testName)
    scores = await getOverallIAScore(testName)
  }

  return {
    result,
    scores,
    lessons
  }
}

/**
 * Will determine the test type: ACT/IA | SAT
 * Note that ACT & IA will return as ACT since they share the same data structure
 *  
 * @param testResult
 * @returns practice test type ACT (ACT/IA) | SAT
 */
function getTestType(testResult: IACTTestResult | ISATTestResult): string {
  // OT: I was using the "exam" attribute but in some tests it reports differently!
  // For example Jennifer's "jennifer+gc+student@winwardacademy.com" account it returns 
  // as "Diagnostic" for SAT diagnostic test!
  // Previous approach: testResult.exam !== "SAT" ? "ACT" : "SAT" 
  // New approach: I will use the testName to determine if it is ACT/IA or SAT test
  // Test names are unique and consistently named for the practice tests
 
  if (testResult.testName.includes("SAT")) return "SAT"

  return "ACT"
}

/**
 * Analyze the test result
 * Since ACT & IA has the same data structure we will use the same function 
 * for both test types. SAT on the otherside has its own analyze function.
 * 
 * @param testType ACT/IA | SAT
 * @param testResult 
 * @param lessons 
 * @param testScores 
 * @returns Analyzed test result
 */
export function analyzeTestResult(testType: string, testResult: IACTTestResult | ISATTestResult, lessons: any, testScores: any): IAnalyzedTestResult {
  return testType === "SAT" ? 
          analyzeTestResultForSAT(testResult as ISATTestResult, lessons, testScores)
          :
          analyzeTestResultForACT(testResult as IACTTestResult, lessons, testScores) 
}


//--- Helper Functions & Data Structures ---
// ACT & IA have same data structures!
// SAT has its own data structures

function analyzeTestResultForACT(testResult: IACTTestResult, lessons: any, testScores: any): IAnalyzedTestResult {
  // Let's first populate the subcategory field for the answers in the test
  populateSubcategoriesForAllACTAnswers(testResult, lessons)

  return {
    emailAddress: testResult.emailAddress,
    displayName:  testResult.displayName,
    exam:         testResult.exam,
    testDate:     testResult.createdTime,
    average:      testScores.average,
    percentile:   testScores.percentile,
    categories: {
      english: getCategoricalInfo("ACT", "English", testResult.questions.englishAnswers, testScores),
      math:    getCategoricalInfo("ACT", "Math",    testResult.questions.mathAnswers,    testScores),
      reading: getCategoricalInfo("ACT", "Reading", testResult.questions.readingAnswers, testScores),
      science: getCategoricalInfo("ACT", "Science", testResult.questions.scienceAnswers, testScores),
    }
  }
}

function analyzeTestResultForSAT(testResult: ISATTestResult, lessons: any, testScores: any): IAnalyzedTestResult {
  // Let's first populate the subcategory field for the answers in the test
  populateSubcategoriesForAllSATAnswers(testResult, lessons)

  return {
    emailAddress: testResult.emailAddress,
    displayName:  testResult.displayName,
    exam:         testResult.exam,
    testDate:     testResult.createdTime,
    average:      testScores.average,
    percentile:   testScores.percentile,
    categories: {
      reading:            getCategoricalInfo("SAT", "Reading",            testResult.questions.readingAnswers,            testScores),
      writingAndLanguage: getCategoricalInfo("SAT", "WritingAndLanguage", testResult.questions.writingAndLanguageAnswers, testScores),
      mathNoCalculator:   getCategoricalInfo("SAT", "MathNoCalculator",   testResult.questions.mathNoCalculatorAnswers,   testScores),
      mathCalculator:     getCategoricalInfo("SAT", "MathCalculator",     testResult.questions.mathCalculatorAnswers,     testScores),
    }
  }
}

interface ITestResult {
  userId: string
  emailAddress: string
  testName: string
  displayName: string
  exam: string
  form: string
  isActive: boolean
  createdTime: string
}

export interface IACTTestResult extends ITestResult {
  questions: IACTAnswersForQuestions
}

export interface ISATTestResult extends ITestResult {
  questions: ISATAnswersForQuestions
}

// ACT & IA Test Answers for categorical questions
interface IACTAnswersForQuestions {
  englishAnswers: ITestAnswer[]
  mathAnswers:    ITestAnswer[]
  readingAnswers: ITestAnswer[]
  scienceAnswers: ITestAnswer[]
}

// SAT Test Answers for categorical questions
interface ISATAnswersForQuestions {
  writingAndLanguageAnswers: ITestAnswer[]
  readingAnswers:            ITestAnswer[]
  mathNoCalculatorAnswers:   ITestAnswer[]
  mathCalculatorAnswers:     ITestAnswer[]
}

interface ITestAnswer {
  questionNumber: number
  correctAnswer: string
  lessonId: string
  lessonName: string
  topicId: string
  topicName: string
  videoId: string | number
  mcLetters: string[]
  userSubmittedAnswer: string
  star: boolean
  gridIn: string
  satExplanation?: string // Only used for SAT
  bookmarked: boolean
  subcategory?: string    // Will be populated later
}

// Analyzed test results by categories
export interface IAnalyzedTestResult {
  emailAddress: string
  displayName: string
  exam: string
  testDate: string
  average: number
  percentile: string
  // categories: {[category: string]: ICategoryInfo}
  categories: ICategoricalMap
}

export interface ICategoricalMap {
  [category: string]: ICategoryInfo
}

export interface ITopic {
  topicId: string
  topicName: string
  details?: IScoreDetails
}

export interface ILesson {
  lessonId: string
  lessonName: string
  topics: ITopic[]
  details?: IScoreDetails
}

interface ISubcategoryPerformance {
  subcategory: string
  correct: number  
  total: number
}

export interface IScoreDetails {
  accuracy: number
  correct: number
  missed: number
  blank: number
  nofQuestions: number
  missedOrBlankAnswers: number[]
}

export interface ICategoryInfo {
  name: string
  score: number
  lessons: ILesson[]
  subcategoryPerformances: ISubcategoryPerformance[]
}

interface ISubcategoriesToLessonsMap {
  [category: string]: {[subcategory: string]: string[]}
}

interface ILessonSubcategory {
  lessonName: string
  category: string
  subcategory: string
}


function getTopics(data: ITestAnswer[]): ITopic[] {
  let topicIds = Array.from(new Set(data.map(d => d.topicId)))
  let topics = topicIds.map(topicId => {
    let answers = data.filter(d => d.topicId === topicId)
    let topicName = answers[0].topicName
    let details = getScoreDetails(answers)

    return {
      topicId,
      topicName,
      details
    }
  }).sort((t1, t2) => {
    return t1.topicName.localeCompare(t2.topicName)
  })

  return topics
}

function getScoreDetails(answers: ITestAnswer[]): IScoreDetails {
  let res = answers.reduce((acc, cur) => {
    let { correctAnswer, userSubmittedAnswer, questionNumber} = cur
    let isCorrect = correctAnswer === userSubmittedAnswer
    let isBlank = userSubmittedAnswer === ""
    let isMissed = !isBlank && !isCorrect
    let isMissedOrBlank = isMissed || isBlank

    acc.correct += isCorrect ? 1 : 0
    acc.missed += isMissed ? 1 : 0
    acc.blank += isBlank ? 1 : 0
    acc.nofQuestions++

    if (isMissedOrBlank) acc.missedOrBlankAnswers.push(questionNumber)
    
    return acc
  }, {
    nofQuestions: 0,
    accuracy: -1,
    correct: 0,
    missed: 0,
    blank: 0,
    missedOrBlankAnswers: [] as number[]
  })

  // And now calculate the accuracy (rounded)
  res.accuracy = Math.round(100.0*res.correct / res.nofQuestions)

  return res
}

function getLessons(testAnswers: ITestAnswer[]): ILesson[] {
  // OT: lessonId is not unique so I had to combine with the lessonName!
  let DELIM = "|"
  let lessonIdsAndNames = Array.from(new Set(testAnswers.map(d => `${d.lessonId}${DELIM}${d.lessonName}`))) 
  let lessons = lessonIdsAndNames.map(lessonIdAndName => {
    let pairs = lessonIdAndName.split(DELIM)
    let lessonId = pairs[0]
    let lessonName = pairs[1]
    let filteredAnswers = testAnswers.filter(d => d.lessonId === lessonId && d.lessonName === lessonName)
    let topics = getTopics(filteredAnswers)
    let details = getScoreDetails(filteredAnswers)

    return {
      lessonId,
      lessonName,
      details,
      topics,
    }
  })
  .sort((l1, l2) => {
    return l1.lessonName.localeCompare(l2.lessonName)
  })

  return lessons
}

function getSubcategoryPerformances(answers: ITestAnswer[]): ISubcategoryPerformance[] {
  let subcategories = Array.from(new Set(answers.map(d => d.subcategory))).filter(s => s !== undefined)
  let results: ISubcategoryPerformance[] = []
  
  subcategories.forEach(subcategory => {
    if (subcategory) {
      let filteredAnswers = answers.filter(d => d.subcategory === subcategory)
      let details = getScoreDetails(filteredAnswers)
      let { correct, missed, blank } = details
      let total = correct + missed + blank

      results.push({
        subcategory,
        correct,
        total
      })
    }
  })

  return results
}

function getCategoricalInfo(testType: string, category: string, answers: ITestAnswer[], testScores: any): ICategoryInfo {
  let score = testScores[category.toLowerCase()]

  if (testType !== "SAT") {
    // Same for ACT & IA
    score = testScores[category.toLowerCase()]
  }
  else {
    // In SAT there are only Math and EBRW scores
    score = category.toLowerCase().includes("math") ? testScores.math : testScores.evidenceBasedRW
  }

  return {
    name: category,
    score,
    // maxScore,
    subcategoryPerformances: getSubcategoryPerformances(answers),
    lessons: getLessons(answers)
  }    
}


// OT: Lessons should have subcategories, but in the Lessons table they don't, except the Math!
const SUBCATEGORY_MAP: ISubcategoriesToLessonsMap = {
  ALL: {
    "Conventions of Standard English": ["Punctuation", "Apostrophes"],
    "Production of Writing": ["Usage & Mechanics", "Transitions"],
    "Knowledge of Language": ["Basics But Essentials", "Agreement", "Pronouns", 
                              "Verbs", "Parallelism", "Modifying Phrases", "Figures & Tables",
                              "Adjectives/Adverbs/Idioms", "Adjectives, Adverbs, & Idioms",
                              "Other Tough Ones", 
                              "ACT & SAT Reading Passages Overview"], // OT: ??
    "Prose Fiction":   ["Prose Fiction (Single)", "Single Passages (Prose Fiction)", 
                        "Single Passages", "Single Passages with Figures", "Dual Passages"], // OT: ??
    "Social Science":  ["Social Science (Single)", "Social Science (Dual)", "Single Passages with Figures (Social Science)"],
    "Humanities":      ["Humanities (Single)", "Single Passages (Humanities)"],
    "Natural Science": ["Natural Science (Single)", "Dual Passages (Natural Science)", "Single Passages with Figures (Natural Science)"],
    "Figures & Tables": ["Figures & Tables"],
    "Debating Scientists": ["Debating Scientists"],
    "Experiments": ["Experiments"],
  }
}

function getSubcategory(category: string, lessonName: string, lessons: any) {
  let lesson = lessons.find((lesson:any) => lesson.lessonName === lessonName)
  let subcategory = lesson && lesson.subcategory ? lesson.subcategory.subcategoryName: null

  if (!subcategory) {
    // Subcategory not found! Let's try to get it from the map
    // SAT: WritingAndLanguage -> English
    category = category === "WritingAndLanguage" ? "English" : category
    category = "ALL"
    let subcategoriesMap = SUBCATEGORY_MAP[category]
  
    if (subcategoriesMap) {
      let subcategoryKeys = Object.keys(subcategoriesMap)
      let subcategories = subcategoryKeys.map(subcategoryKey => {
        let subcategoriesForKey = subcategoriesMap[subcategoryKey]
        let foundLesson =  subcategoriesForKey.find(subcategory => subcategory === lessonName)
        if (foundLesson) return subcategoryKey // subcategoryKey is the subcategory name
        return null
      })
      .filter(d => d != null)

      subcategory = subcategories && subcategories[0]
      subcategory = subcategory ?? "??"
    }
  }
  
  return subcategory
}

function getSubcategoriezedACTLessonNames(testResults: IACTTestResult, lessons: any): ILessonSubcategory[] {
  let lessonNamesE = Array.from(new Set(testResults.questions.englishAnswers.map(d => d.lessonName)))
  let lessonNamesM = Array.from(new Set(testResults.questions.mathAnswers.map(d => d.lessonName)))
  let lessonNamesR = Array.from(new Set(testResults.questions.readingAnswers.map(d => d.lessonName)))
  let lessonNamesS = Array.from(new Set(testResults.questions.scienceAnswers.map(d => d.lessonName)))  
  let getSubcategoryLessonNames = (category: string, lessonNames: string[]) => {
    return lessonNames.map(lessonName => {
      return {lessonName, category, subcategory: getSubcategory(category, lessonName, lessons) }})
  }
  let subcategoryLessonsE = getSubcategoryLessonNames("English", lessonNamesE)
  let subcategoryLessonsM = getSubcategoryLessonNames("Math", lessonNamesM)
  let subcategoryLessonsR = getSubcategoryLessonNames("Reading", lessonNamesR)
  let subcategoryLessonsS = getSubcategoryLessonNames("Science", lessonNamesS)

  return [...subcategoryLessonsE, ...subcategoryLessonsM, ...subcategoryLessonsR, ...subcategoryLessonsS]
}

function getSubcategoriezedSATLessonNames(testResults: ISATTestResult, lessons: any): ILessonSubcategory[] {
  let lessonNamesR   = Array.from(new Set(testResults.questions.readingAnswers.map(d => d.lessonName)))
  let lessonNamesWL  = Array.from(new Set(testResults.questions.writingAndLanguageAnswers.map(d => d.lessonName)))    
  let lessonNamesMNC = Array.from(new Set(testResults.questions.mathNoCalculatorAnswers.map(d => d.lessonName)))
  let lessonNamesMC  = Array.from(new Set(testResults.questions.mathCalculatorAnswers.map(d => d.lessonName)))
  let getSubcategoryLessonNames = (category: string, lessonNames: string[]) => {
    return lessonNames.map(lessonName => {
      let subcategory = getSubcategory(category, lessonName, lessons)

      return {lessonName, category, subcategory }})
  }
  let subcategoryLessonsR   = getSubcategoryLessonNames("Reading", lessonNamesR)
  let subcategoryLessonsWL  = getSubcategoryLessonNames("WritingAndLanguage", lessonNamesWL)
  let subcategoryLessonsMNC = getSubcategoryLessonNames("MathNoCalculator", lessonNamesMNC)
  let subcategoryLessonsMC  = getSubcategoryLessonNames("MathCalculator", lessonNamesMC)

  return [...subcategoryLessonsMC, ...subcategoryLessonsMNC, ...subcategoryLessonsR, ...subcategoryLessonsWL]
}

function populateSubcategory(answers: ITestAnswer[], subcategorizedLessons: ILessonSubcategory[]) {
  let finder = (lessonName: string) => {
    let subcategory = subcategorizedLessons.find(d => d.lessonName === lessonName)
    return subcategory ? subcategory.subcategory : null
  }
  answers.forEach(answer => {
    let subcategory = finder(answer.lessonName)
    if (subcategory) answer.subcategory = subcategory
  })
}

function populateSubcategoriesForAllACTAnswers(testResult: IACTTestResult, lessons: any) {
  let subcategorizedLessons = getSubcategoriezedACTLessonNames(testResult, lessons)
  let questions = testResult.questions

  // Populate the subcategories for the ACT/IA answers
  populateSubcategory(questions.englishAnswers, subcategorizedLessons)
  populateSubcategory(questions.mathAnswers, subcategorizedLessons)
  populateSubcategory(questions.readingAnswers, subcategorizedLessons)
  populateSubcategory(questions.scienceAnswers, subcategorizedLessons)

  return testResult // Populated with subcategories
}

function populateSubcategoriesForAllSATAnswers(testResult: ISATTestResult, lessons: any) {
  let subcategorizedLessons = getSubcategoriezedSATLessonNames(testResult, lessons)
  let questions = testResult.questions

  // Populate the subcategories for the SAT answers
  populateSubcategory(questions.mathCalculatorAnswers, subcategorizedLessons)
  populateSubcategory(questions.mathNoCalculatorAnswers, subcategorizedLessons)
  populateSubcategory(questions.readingAnswers, subcategorizedLessons)
  populateSubcategory(questions.writingAndLanguageAnswers, subcategorizedLessons)

  return testResult // Populated with subcategories
}

