import './PDFCard.css';

import * as React from 'react';

import Card from '../../../../components/Card/Card';
import { PDF } from './pdf-types';
import PDFLink from './components/PDFLink/PDFLink';

interface Props {
    title: string;
    pdfs: Array<PDF>;
    containerClass?: string;
}

const PDFCard: React.SFC<Props> = (props: Props) => {

    return (
        <div className={'pdf-container ' + (props.containerClass || '')}>
            <div className="pdf-container-title">
                <Card 
                    title={props.title} 
                    titleColor="blue" 
                    classTitle="tip-container" 
                />          
            </div>
                {props.pdfs.map((key, i) => {
                    return <PDFLink key={i} filename={key.filename} title={key.title} />;
                })
                }
            
            {/* </Card> */}
        </div>
    );
};

PDFCard.displayName = 'PDFCard';

export default PDFCard;
