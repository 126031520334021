import './BundleControls.css';
import collegeKnowledgeData from './CollegeKnowledgeBundleData';
import * as React from 'react';

import { Bundle } from '../../../../services/bundles/bundle-types';
import CollegeKnowledgeCheckbox from './CollegeKnowledgeCheckbox';

interface Props {
  bundleTypes: Bundle[];
  onBundleSelected: any;

  // optional to use black color font
  darkPrint?: boolean;
    preSelectedBundleId?: string;

}

interface State {
    bundleId: string;
    includeCollegeKnowledge: boolean;
    ckBundleAddOn?: string;
    helpIcon: string;
    showDescription: boolean;
    newPrice: number;
}

// Note: All prices are marked in # of cents
export default class BundleControls extends React.PureComponent<Props, State> {
  constructor(props: Props) {
      super(props);

      this.state = {
        showDescription: false,
        bundleId: this.props.preSelectedBundleId || '',
        includeCollegeKnowledge: false,
        helpIcon: this.props.darkPrint ? 'black-question.svg' : 'grey-question.svg',
        newPrice: 0
      };
  }

  componentDidMount() {
      this.props.onBundleSelected(this.state.bundleId, false);
  }
 
  public render() {
    const classIfDark = this.props.darkPrint === true ? ' bundle-dark ' : ' bundle-white ';
    return (
        <div className="bundle-controls row">
          <div className="col-12">
            {this.props.bundleTypes.map(bundle => {
              return (
                <div key={bundle.bundleId} className={classIfDark + 'bundleRadio row'}>
                  <label 
                      className="bundle-radio"
                  >
                    <input
                      type="radio"
                      name="bundleRadio"
                      value={bundle.bundleId}
                      onChange={(e) => { 
                          const selectedBundleId = e.target.value;
                          const newBundleSelected = this.state.bundleId !== selectedBundleId;
                          if (newBundleSelected) {
                              // console.log('new bundle selected', selectedBundleId);
                              this.setState({ 
                                  bundleId: selectedBundleId,
                                  includeCollegeKnowledge: false,
                                  newPrice: 0
                              });
                              this.props.onBundleSelected(
                                  selectedBundleId, false, 0
                              );
                          }

                      }}
                      checked={this.state.bundleId === bundle.bundleId}
                    />
                    <i></i>
                    <span className={classIfDark}>
                        {bundle.bundleName} (${bundle.price})
                    </span>
                    <img
                      alt=""
                      src={`/assets/images/icons/progress/${this.state.helpIcon}`}
                      onClick={e => this.toggleDescription(e, bundle.bundleId)}
                    />
                  </label>
                  {this.state.showDescription && this.state.bundleId === bundle.bundleId &&
                    <div 
                        className={classIfDark + 'row bundle-description-container  col-12'}
                    >
                      <div>
                      <p 
                          className={'bundle-description-text ' + classIfDark}
                          onClick={e => this.toggleDescription(e, bundle.bundleId)}
                      >
                        {bundle.description}
                      </p>
                      </div>
                    </div>
                  }
                  {bundle.bundleId === 'collegeKnowledge' && 
                      this.state.bundleId === bundle.bundleId && 
                      Object.keys(collegeKnowledgeData).map(bundleName => 
                          <CollegeKnowledgeCheckbox
                              key={`ck-checkbox-${bundleName}`}
                              bundleId={bundleName}
                              darkPrint={this.props.darkPrint ? true : false}
                              inputType="radio"
                              onChange={(checked) => {
                                  // console.log('CK +', checked, bundleName);
                                  const newPrice = collegeKnowledgeData[bundleName].price;
                                  this.setState({
                                      bundleId: 'collegeKnowledge',
                                      includeCollegeKnowledge: true,
                                      ckBundleAddOn: bundleName,
                                      newPrice: newPrice
                                  });

                                  this.props.onBundleSelected(
                                      bundleName, true, newPrice
                                  );
                              }}
                          />
                      )
                  }
                  {bundle.bundleId !== 'collegeKnowledge' && 
                      this.state.bundleId === bundle.bundleId && 
                      <CollegeKnowledgeCheckbox 
                          bundleId={bundle.bundleId}
                          darkPrint={this.props.darkPrint}
                          onChange={(checked) => { 
                              //  console.log(bundle.bundleId, '+ CK', checked);
                               let newPrice = collegeKnowledgeData[bundle.bundleId].price;
                               this.setState({ 
                                   includeCollegeKnowledge: checked,
                                   newPrice: checked ? newPrice : 0
                               });
                               this.props.onBundleSelected(
                                   bundle.bundleId, 
                                   checked, 
                                   checked ? newPrice : 0
                               );
                          }}
                      />
                  }
                </div>
              );
            })
            }
            </div>
        </div>
    );
  }

  private toggleDescription(e: any, bundleSelected: string) {
    e.preventDefault();
    // let description = this.state.showDescription;
    if (this.state.showDescription === true && this.state.bundleId === bundleSelected) {
      this.setState({
        showDescription: false
      });
    } else {
      this.setState({
        showDescription: true,
        bundleId: bundleSelected
      });
    }

  }
}
