import * as React from 'react';

interface Props {
  title: string;
}

const Shelf: React.SFC<Props> = ({ title, children }) => {
  return (
    <div className="shelf">
      <h3>{title}</h3>
      {children}
    </div>
  );
};

export default Shelf;
