import { createGenericAuthRequest } from '../request/request-utils';

const request = createGenericAuthRequest(process.env.REACT_APP_ELEARN_URL || '/');

export interface Enrichment {
    categoryId: string;
    categoryName: string;
    duration: string;
    lessonId: string;
    lessonName: string;
    masterOrder: string;
    playOrder: string;
    status: string;
    subCategoryId: string;
    subCategoryName: string;
    videoId: string;
    pdfId: string;
}

/**
 * Updates the data for a particular user
 * @param id is the marker for the enrichment lesson
 * @param status is the status of the video, can be rewatch, 
 */
export function updateEnrichment(id: string, status: string) {
    return request(`/enrichment/users/${id}`, {status}, { method: 'PUT' });
}

/**
 * Get all of the enrichment data associated with a user
 */
export function getEnrichmentData() {
    return request(`/enrichment`);
}
