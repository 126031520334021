import './TeacherDashboard.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { LessonProgress } from '../../../../services/elearn/lesson-types';

import BlogCard from '../../components/BlogCard/BlogCard';
import Loading from '../../../../components/Loading/Loading';
import MindfulCard from '../../components/MindfulCard/MindfulCard';
import PDFCard from '../../components/PDFCard/PDFCard';
import { PageContent } from '../../../../components/PageContent/PageContent';
import { RouteComponentProps } from 'react-router-dom';
import StockCard from '../../components/StockCard/StockCard';
import TutorialCard from '../../components/TutorialCard/TutorialCard';
import { UserInfo } from '../../../../services/account/account-rest-interface';
import { getBaseUrl } from '../../../../services/routing/routing-utils';
import { welcomeUser } from '../../../../services/account/account-rest-interface';
import WelcomeModalOne from '../../components/WelcomeModalOne/WelcomeModalOne';
import WelcomeModalTwo from '../../components/WelcomeModalTwo/WelcomeModalTwo';
import WelcomeModalThree from '../../components/WelcomeModalThree/WelcomeModalThree';

import {
    SuperHelpfulTipsArray,
    MoreHelpfulResourcesArray,
    UnderstandingTheProcessArray
} from '../../components/PDFCard/pdf-list';
import { StepByStepModal } from '../../components/StepByStepModal/StepByStepModal';
import LessonStepByStepModal from '../../components/LessonStepByStepModal/LessonStepByStepModal';
import { getUserLessonsOverview } from '../../../../stores/lesson.store';

export interface Props {

}

export interface DispatchProps {
  setUserInfo: (info: Partial<UserInfo>) => void;
}

export interface StoreProps {
    userInfo: UserInfo;
    currentLesson: LessonProgress | undefined;
}

interface State {
    showFirstModal: boolean;
    showSecondModal: boolean;
    showThirdModal: boolean;
    showStepByStep: boolean;
    showLearnStepModal: boolean;
    isLoaded: boolean;
}

export type AllProps = Props & StoreProps & DispatchProps & RouteComponentProps<any>;


export class TeacherDashboard extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            showFirstModal: this.props.userInfo.postWelcome === 'true' ? false : true,
            showSecondModal: false,
            showThirdModal: false,
            showStepByStep: false,
            showLearnStepModal: false,
            isLoaded: false
        };
    }

    public componentDidMount() {
        Promise.all([
            getUserLessonsOverview(this.props.userInfo)
        ]).then(() => this.setState({ isLoaded: true })).catch(() => Promise.resolve([]));
    }

    public render() {
        const { userInfo, currentLesson } = this.props;
        const { isLoaded } = this.state;
        const name = userInfo.firstName;

        if (!isLoaded) {
            return <Loading />;
        }

        if(typeof(currentLesson) === 'undefined') {
            return <Loading />;
        }
        
        const hideInMobileViewClass =  '';

        return (
            <PageContent className="home-container">

                {/* Welcome banner */}
                <div className="row">
                    <div className="col-12">
                        {name && <h1 className="page-title">Welcome, {name}</h1>}
                    </div>
                </div>

                {/* Cards */}
                <div className="row">
                    <div className="first-column col-lg-3 col-md-12 px-lg-0">
                        <div className={hideInMobileViewClass}>
                            <TutorialCard openModal={this.openStepByStep} />
                        </div>
                        <PDFCard
                            title={'Super Helpful Tips'}
                            pdfs={SuperHelpfulTipsArray}
                            containerClass={hideInMobileViewClass}
                        />
                        <StockCard
                            btnText="CONTACT US"
                            bottomText="Have questions or feedback?"
                            topStyle=""
                            bottomStyle="bottom-text-contact"
                            btnLink="http://winwardacademy.com/contact/"
                            containerClass={hideInMobileViewClass}
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <MindfulCard containerClass={hideInMobileViewClass} />
                        <PDFCard
                            title={'More Helpful Resources'}
                            pdfs={MoreHelpfulResourcesArray}
                            containerClass={hideInMobileViewClass}
                        />
                    </div>

                    <div className="col-lg-3 col-md-12 px-lg-0">
                        <BlogCard containerClass={hideInMobileViewClass} />
                        <PDFCard
                            title={'The Process'}
                            pdfs={UnderstandingTheProcessArray}
                            containerClass={hideInMobileViewClass}
                        />
                        <StockCard
                            containerClass={'faq-card ' + hideInMobileViewClass}
                            btnText="VIEW ALL"
                            topText="Having trouble?"
                            bottomText="Check FAQs"
                            topStyle="bottom-text-contact"
                            bottomStyle="bottom-text-contact"
                            btnLink="/app/teacher-resources#faqs"
                            btnInternal='interal'
                        />
                    </div>
                </div>               

                <WelcomeModalOne
                    show={this.state.showFirstModal}
                    onClose={this.toggleSecondModal}
                />

                <WelcomeModalTwo
                    show={this.state.showSecondModal}
                    onClose={this.toggleThirdModal}
                />

                <WelcomeModalThree
                    show={this.state.showThirdModal}
                    onClose={this.sendPostWelcomed}
                />

                <StepByStepModal
                  show={this.state.showStepByStep}
                  onClose={this.closeStepByStep}
                  onModalClose={this.cancelStepModal}
                />

                <LessonStepByStepModal
                  show={this.state.showLearnStepModal}
                  onClose={this.closeLearnStepModal}
                />

            </PageContent>
        );
    }

    private goToLesson() {
      this.props.history.push(`${getBaseUrl()}app/lessons?view=subject`);
    }

    @boundMethod
    private toggleSecondModal() {
        this.setState({ showFirstModal: false });
        this.setState({ showSecondModal: true});
    }

    @boundMethod
    private toggleThirdModal() {
        this.setState({ showSecondModal: false });
        this.setState({ showThirdModal: true});
    }

    @boundMethod
    private openStepByStep() {
      this.setState({ showStepByStep: true });
    }

    @boundMethod
    private closeStepByStep() {
      this.setState({
        showStepByStep: false,
        showLearnStepModal: true
      });
    }

    @boundMethod
    private cancelStepModal() {
        this.setState({
            showStepByStep: false,
            showLearnStepModal: false
        });
    }

    @boundMethod
    private closeLearnStepModal() {
      this.setState({
        showLearnStepModal: false
      });
    }

    @boundMethod
    private sendPostWelcomed() {
        welcomeUser(this.props.userInfo.id).then(this.updateInitialLogIn);
        this.setState({ showFirstModal: false, showSecondModal: false, showThirdModal: false });
    }

    @boundMethod
    private updateInitialLogIn() {
      this.props.userInfo.postWelcome = 'true';
    }
}
