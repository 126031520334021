import React, { Component } from 'react'
import HeadingBanner from '../../../../components/HeadingBanner'
import Loading from '../../../../components/Loading/Loading'
import { ERR_CANNOT_GET_DATA } from '../../../../constants'
import { getTestsInsights, getTestsInsightsByTestName, ITestInsightACT, ITestInsightSAT } from '../../../../services/elearn/teacher/practice-tests-insights'
import { TestType } from '../../../../services/utils/practice-test-util'
import TestInsightsForACT from './TestInsightsForACT'
import TestInsightsForSAT from './TestInsightsForSAT'
import {ITimeOnPlatform} from "../../TimeOnPlatform/top-lesson-processor"

interface Props {
  testName: string
  testType: TestType
  displayName: string
  timeOnPlatformInfo: ITimeOnPlatform[]
}

interface State {
  insights: ITestInsightACT[] | ITestInsightSAT[]
  loading: boolean
  errorMsg?: string
}

export default class TestInsighstView extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      insights: [],
      loading: true,
      errorMsg: undefined
    }
  }

  async componentDidMount() {
    let {testName, testType} = this.props
    this.setState({loading: true, errorMsg: undefined})
    setTimeout(async () => {
      let rawData = await getTestsInsights(testType)
      if (!rawData) {
        this.setState({errorMsg: ERR_CANNOT_GET_DATA})
        return
      }
      let insights = await getTestsInsightsByTestName(testName, rawData)
      this.setState({insights, loading: false})
    }, 200)
  }

  render() {
    let {displayName, testType, timeOnPlatformInfo} = this.props
    let {insights, loading, errorMsg} = this.state

    return (
      <div className="tests-insights-container">
        <HeadingBanner title={displayName} />
        <ErrorOrLoadingInfo errorMsg={errorMsg} loading={loading}/>
        {!loading &&              
          <div className="tests-insights-content">
            {(testType === TestType.ACT || testType === TestType.IA) ?
              <TestInsightsForACT insights={insights as ITestInsightACT[]} timeOnPlatformInfo={timeOnPlatformInfo}/>
              :
              <TestInsightsForSAT insights={insights as ITestInsightSAT[]} timeOnPlatformInfo={timeOnPlatformInfo}/>
            }
          </div>
        }
      </div>
    )
  }
}


//--- Helpers ---
const LoadingMessage = () => {
  return (
    <div className="tests-insights-loading-wrapper">
      <Loading />
      <p>
        We are calculating the practice test insights in real time. Please be patient as this process can take up to 30 seconds.
      </p>
    </div>
  )  
}

const ErrorOrLoadingInfo = ({loading, errorMsg}) => {
  return (
    errorMsg ?
      <div className="tests-insights-error">{errorMsg}</div>
      :
      loading && <LoadingMessage />
  )
}

