import * as React from "react"
import { RouteComponentProps, Link } from "react-router-dom"
import { PageContent, PageTitle } from "../../../components/PageContent/PageContent"
import ScreenSelector from "../../../components/ScreenSelector"
import { Demos } from "./Demos"
import { FAQs } from "./FAQs"
import { EngagementCulture } from "./EngagementCulture"
import { Assessments } from "./Assessments"
import { StaySharpWorksheets } from "./StaySharpWorksheets"
import { StudentResourcesCoreView } from "../../StudentResources"
import "./index.css"

type ScreenType = "demos" | "staysharp-worksheets" | "tips" | "faqs" | "engagementCulture" | "assessments"
type ViewType   = "teacher" | "student"

export interface Props { }

export interface StoreProps { }

export type AllProps = Props & StoreProps & RouteComponentProps<any>;

interface State {
    selectedView: ViewType
}

let defaultScreen: ScreenType = "demos"
let defaultView: ViewType = "teacher"


export class TeacherResources extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props)
        
        this.state = {
            selectedView: defaultView
        }
    }

    onViewSelected = (view: ViewType) => {
        this.setState({ selectedView: view })
        window.location.hash = ""
    }

    public render() {
        return (
            <PageContent className="teacher-portal">
                <div className="row align-items-center teacher-resources-header"> 
                    <div className="col-md2">
                        <PageTitle title={`Resources`}></PageTitle>
                    </div>
                    <div className="col-md teacher-resources-options">
                        <div className="resource-selection-container">
                            <div className="selection-kind">
                                <input id="option-teacher" name="option-teacher" type="radio" 
                                    checked={this.state.selectedView === "teacher"}
                                    onChange={e => this.onViewSelected("teacher")}/> 
                                &nbsp;
                                <label htmlFor="option-teacher">Teacher</label>

                                <input id="option-student" name="option-student" type="radio" 
                                    checked={this.state.selectedView === "student"} 
                                    onChange={e => this.onViewSelected("student")}/>
                                &nbsp; 
                                <label htmlFor="option-student">Student</label>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.selectedView === "teacher" ?
                    <TeacherResourcesCoreView/>
                    :
                    <StudentResourcesCoreView />
                }
            </PageContent>
        )
    }
}


interface ITeacherResourcesCoreState {
    selectedScreen: ScreenType
}

/**
 * View for the Teacher Resources. It also provides an option to view the Student Resources as well.
 */
export class TeacherResourcesCoreView extends React.Component<Props, ITeacherResourcesCoreState> {
    constructor(props: Props) {
        super(props)
        
        this.state = {
            selectedScreen: defaultScreen,
        }
    }

    componentDidMount() {
        switch (window.location.hash) {
            case "#faqs":
                this.setState({selectedScreen: "faqs"})
                break
            case "#demos":
                this.setState({selectedScreen: "demos"})        
                break
            case "#staysharp-worksheets":
                this.setState({selectedScreen: "staysharp-worksheets"})
                break
            case "#engagementCulture":
                this.setState({selectedScreen: "engagementCulture"})
                break
            case "#assessments":
                this.setState({selectedScreen: "assessments"})
                break
            default:
                this.setState({selectedScreen: defaultScreen})
        }
    }

    public render() {
        const { selectedScreen } = this.state;

        return (
            <div className="teacher-portal">
                <div className="screen-selector-row subject-buttons view-change-buttons-container inline-buttons inline-btns">
                    <ScreenSelector 
                        active={selectedScreen === "demos"}
                        displayName="Demos" 
                        onSwitchComponent={() => {
                            this.setState({ selectedScreen: "demos" })
                            window.location.hash = "demos";
                        }}
                    />
                    <ScreenSelector 
                        active={selectedScreen === "staysharp-worksheets"}
                        displayName="Stay Sharp Worksheets" 
                        onSwitchComponent={() => {
                            this.setState({ selectedScreen: "staysharp-worksheets" })
                            window.location.hash = "staysharp-worksheets";
                        }}
                    />
                    <ScreenSelector 
                        active={selectedScreen === "faqs"}
                        displayName="FAQs" 
                        onSwitchComponent={() => {
                            this.setState({ selectedScreen: "faqs" })
                            window.location.hash = "faqs";
                        }}
                    />
                    <ScreenSelector
                        active={selectedScreen === "engagementCulture"}
                        displayName="Engagement & Culture"
                        onSwitchComponent={() => {
                            this.setState({ selectedScreen: "engagementCulture" })
                            window.location.hash = "engagementCulture";
                        }}
                    />
                    <ScreenSelector
                        active={selectedScreen === "assessments"}
                        displayName="Assessments"
                        onSwitchComponent={() => {
                            this.setState({ selectedScreen: "assessments" })
                            window.location.hash = "assessments";
                        }}
                    />
                </div>

                <div className="teacher-resource-content">
                    {selectedScreen === "demos" && <Demos />}
                    {selectedScreen === "staysharp-worksheets" && <StaySharpWorksheets />}
                    {selectedScreen === "faqs" && <FAQs />}
                    {selectedScreen === "engagementCulture" && <EngagementCulture />}
                    {selectedScreen === "assessments" && <Assessments />}
                </div>
            </div>
        )
    }
}
