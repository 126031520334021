let answer01 = `Rosters are the best option to create both student and teacher accounts when organizations have up-to-date records of all the students' emails and the students can receive emails at those addresses. This process works to create tens or hundreds or thousands of accounts at one time.  Organizations prepare Excel files for teachers and for students in the following formats:
Teacher Account Creation - Prepare an Excel file with 3 columns (in this order, if possible):
- Last name
- First name
- Email
Student Account Creation - Prepare an Excel file with 8 columns (in this order, if possible):
- Student ID (If practice test data from Illuminate or Edulastic are going to be uploaded, then this Student ID data must be the students' Illuminate or Edulastic IDs)
- Last name
- First name
- Middle name (ok to be blank if N/A)
- Date of birth (MM/DD/YYYY)
- Gender
- Graduation year
- Email address (this must be an email that the students can check)
After these files are uploaded by Winward Academy, the teachers and/or students receive an email that their Winward Academy accounts have been created. The subject name of this email is “Winward Academy Email Verification.” If they don't see this email in their inbox, students should check their junk or spam folders. When they locate the email, they verify their account by clicking either the VERIFY EMAIL ADDRESS button or the link. Also, if a student accidentally deletes this email, see Step 3 – Help students verify their accounts for instructions on how to trigger a resending of the verification email.
<img src="/assets/images/resources/faqs/how-are-accounts-created-rosters-1.jpg" alt="how are accounts created rosters image 1" />
After clicking the button or the link, a screen loads on which the user, student or teacher, chooses a profile color and sets a password.
<img src="/assets/images/resources/faqs/how-are-accounts-created-rosters-2.jpg" alt="how are accounts created rosters image 2" />
Students and teachers can login anytime using their email and the password they set. 
<img src="/assets/images/resources/faqs/how-are-accounts-created-rosters-3.jpg" alt="how are accounts created rosters image 3" />`


let answer02 = `Access codes are the best option to create student accounts when organizations do not have an existing database with students' emails. Each school or organization picks a custom code (e.g., TGR Foundation picked access codes TGR1, TGR2, TGR3, etc.). Winward Academy creates those codes in the platform's database and sends a PDF to the person coordinating with students to create accounts. The students each receive a personalized code and follow instructions on a custom signup URL: 
learn.winwardacademy.com/signup/code
On that page, students complete the fields with their information and enter their personal access code given to them to activate their accounts. Since each student gets a personalized code, each code works only one time. An example of the instructions provided to students signing up with access codes follows.

<img src="/assets/images/resources/faqs/how-are-accounts-created-codes-1.jpg" alt="how are accounts created codes" />`

let answer03 = `If you need to change a student's email address used to login to the platform, please send an email to <a href="mailto:support@winwardacademy.com" title="Email support@winwardacademy.com">support@winwardacademy.com</a> with the (1) existing email you want to change and the (2) new email you want it changed to. You'll hear back from us within 24 hours that the email has been changed per your direction.`

let answer04 = `If you have a student leave your school or organization and want to deactivate that student's access, please send an email to <a href="mailto:support@winwardacademy.com" title="Email support@winwardacademy.com">support@winwardacademy.com</a> with the email address for that student. You'll hear back from us within 24 hours that the account has been discontinued.`

let answer05 = `After student accounts are active (once they verify their emails), students' emails can be connected to teachers' emails, so that teachers can access student data in their lesson and practice test reports. 
To create a connection between a student and a teacher, provide an Excel file with 3 columns:
- Teacher email used for Winward Academy account
- Student email used for Winward Academy account
- Period (this field can be left blank if N/A)
Schools have chosen to connect teachers to students in various ways. Some schools want teachers connected to all students, and other schools prefer teachers only review data for their students. The Winward Academy tech team works with each school's data coordinator to ensure the teacher-student mapping is done per each school's specific requests. Note that until a teacher's account is linked to students, any lesson or practice test reports will be blank. If a teacher has a question about his or her reports, please create a support ticket or email <a href="mailto:support@winwardacademy.com" title="Email support@winwardacademy.com">support@winwardacademy.com</a>.`

let answer06 = `Winward Academy's learning platform requires third party integrations including the video player. All student and teacher networking privileges must allow access to the following sites for the platform to run properly: 

<a target="_blank" title="https://www.winwardacademy.com, opens in a new window" href="https://www.winwardacademy.com">https://www.winwardacademy.com</a>
<a title="https://www.learn.winwardacademy.com" href="https://www.learn.winwardacademy.com">https://www.learn.winwardacademy.com</a>
<a target="_blank" title="https://metrics.brightcove.com, opens in a new window" href="https://metrics.brightcove.com">https://metrics.brightcove.com</a>
<a target="_blank" title="https://brightcove.hs.llnwd.net, opens in a new window" href="https://brightcove.hs.llnwd.net">https://brightcove.hs.llnwd.net</a>
<a target="_blank" title="https://players.brightcove.net, opens in a new window" href="https://players.brightcove.net">https://players.brightcove.net</a>
<a target="_blank" title="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=AM_HTMLorMML, opens in a new window" href="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=AM_HTMLorMML">https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.1/MathJax.js?config=AM_HTMLorMML</a>`

let answer06_1 = `Yes, Winward Academy is fully integrated with Clever. The benefits of creating accounts this way is that it replaces the rostering process, connects student and teacher accounts automatically, and updates any roster changes during the year.`

let answer07_1 = `Yes, educators have the ability to reset the data for an individual student's lesson. However, this is a permanent action and the data will not be able to be recovered once this action is completed.

To complete this action, go to the Individual View in the teacher Data Reports section. Scroll down to the student and find the lesson you would like to reset. The button in the far right column offers the option to reset the lesson. Again, this is a permanent action and the data cannot be recovered.`

let answer07 = `Winward Academy sends verification emails and password reset emails from the <a href="mailto:help@winwardacademy.com" title="Email help@winwardacademy.com">help@winwardacademy.com</a> email address. These emails are sent by mandrillapp.com. Please  ensure that all students and teachers will be able to receive emails from both sources.`

let answer08 = `Winward Academy is a web-based application that can be used on any device with a browser and internet connection. Those who choose to access the site through a mobile device have the best experience in landscape mode. `

let answer09 = `Just as it's important for students to understand their experience, it's equally important for teachers to understand their teacher portals. Teachers can access the Demo of Teacher Portal in the Demos tab of the Resources section.

- The Demo of Teacher Portal (6.5 minutes) shows teachers how they can access the lesson handouts, video lessons, and  multiple-choice questions and how they can download data reports for lessons and practice tests. 

- Also available are resources for teachers who might be interested in learning more: Demo of College Knowledge curriculum (2 minutes), Demo of Interim Assessments feature (2 minutes), Curriculum & Partnerships (7 minutes), Students' Growth & Results (4 minutes), and Research-Based Teaching Approach (3 minutes).`

let answer10 = `It's incredibly important that both students and teachers understand the lesson and practice test experiences for students prior to completing a lesson or practice test.

Teachers can access the Demo of Lessons and Demo of Practice Tests in the Demos tab of the Resources section.

- The Demo of Lessons (12.5 minutes) highlights the flow and the key learning features that students experience while answering baseline questions, watching video lessons while taking notes in the lesson worksheets, practicing multiple-choice questions, accessing step-by-step TEACH ME explanations for every practice question, writing problem-solving hints for missed questions, viewing conversions from their lesson accuracy to ACT/SAT scores, reviewing mistakes in the Mistake Bank, generating custom study guides organized by lesson and topic, and accessing more “at-bats” with Stay Sharp multiple choice questions in specific topics. 

- The Demo of Practice Tests (5 minutes) shows students how to self-proctor pencil-and-paper exams while timing themselves and bubbling their scantrons in each section. Then they're shown how to enter their answers in their Winward Academy accounts to view which questions are correct and incorrect, understand their scores, access an analysis that breaks down their strengths and weaknesses, review step-by-step explanations for each question from the real exams, and prioritize lessons based on accuracy. Students also see examples of the three steps they should always take while doing test corrections.

<img src="/assets/images/resources/faqs/access-demos-1.jpg" alt="access demos" />`

let answer11 = `Some teachers structure time in class for students to work through lessons in small groups. Others like to play the video lesson at the front of the class and pause to facilitate discussion among students. Those discussions are always great for retention and engagement. 

Other teachers will have students just work on a lesson for the entire class time. This structure can also be nice for teachers if 80% of their students can focus independently and then the teacher can work with the other 20% in a small group format.

These decisions are usually made based on the skill level, focus, and attention of the students.`

let answer12 = `It's most important that students (1) know their mistakes are saved and that they're expected to go back and review them, and (2) watch the explanations for any missed question to ensure they understand why they missed a question and to receive guidance on writing a hint for that missed question by putting what they learn in to their own words.`

let answer13 = `We have multiple resources we provide for pencil-and-paper practice. Teachers can leverage the Stay Sharp worksheets for focused practice by topic. They can also print sections or parts of sections of real exams for students to work through. We also have lots of extra math worksheets and resources for focused practice on the most frequently tested concepts.`

let answer14 = `We have numerous resources to support teachers from scavenger hunts to kick off demos to demonstrations of the platform. Please email <a href="mailto:support@winwardacademy.com" title="Email support@winwardacademy.com">support@winwardacademy.com</a> to let us know what you're looking for, and we can get right back to you to provide it.`

let answer15 = `We can support with customized study plans to guide you with what to prioritize by grade level. If you want to build your own customized study plan, then we recommend you start with a diagnostic test, review the analysis, and prioritize lessons with low accuracy and high frequency to review.`

let answer16 = `We have pre-recorded trainings in the Demos section of the teacher portal's Resources tab. Alternatively, we're happy to schedule time with your teachers to ensure they feel comfortable with the student experience and teacher/admin portal experience.`

let answer17 = `We record each training. For a specific training, please reach out to <a href="mailto:support@winwardacademy.com" title="Email support@winwardacademy.com">support@winwardacademy.com</a>, and we can get you the link.`

let answer18 = `We can provide access to the slides; however, most prefer to utilize the short recordings of focused segments of the trainings to address specific questions.`

let answer19 = `The panels feature consummate professionals across industries of interest to students. They're leveraged in core classes, advisories, and seminars to promote career readiness.`

let answer20 = `The Breaking Barriers interviews inspire students to reflect about and discuss the social and emotional challenges of getting to and through college. Each interview is with a student who experienced and overcame challenges in the journey to college, so they share important insights for students to consider. Teachers will commonly integrate these short interviews into Do Now activities to then inspire students to discuss and reflect on what's shared. We also have suggested discussion questions for each interview, so please contact us at <a href="mailto:help@winwardacademy.com" title="Email help@winwardacademy.com">help@winwardacademy.com</a> to request those.`

let answer21 = `A key difference between the student portal and the teacher portal is when the user can access the TEACH ME explanation video. In the student portal, students much submit an answer choice before the TEACH ME explanation becomes active. In the teacher portal, teachers can watch a TEACH ME explanation anytime because they're always active.`

let answer22 = `The Demos page in the Resources tab has demos for the lessons and practice tests that support students to learn more about what to expect and how to leverage all the unique learning features of the platform.`

let answer23 = `Each video lesson is developed to intentionally provide enough examples for a struggling student to be able to learn the information. For example, after teaching the equation for slope, one student might only need three examples to learn the equation and another student might need 20 examples. Our lessons have 20 examples to ensure that there is enough support for the students who need the most help.

That being said, the student who felt comfortable after three examples might get really bored doing 17 more. We recommend that if a student starts to feel really confident then he or she pause the video lesson and complete the remaining questions on his or her own. Then the student can check his or her answers and only watch the segments of the video in which the student made a mistake.`

let answer24 = `Every lesson and multiple choice explanation video provides the option for students to adjust the speed of the playback. Being able to access the same lessons – in English – but at a slower pace makes it much easier for non-native English speakers to follow along. They can also rewatch any segment if multiple views would help solidify information.`

let answer25 = `We recommend teachers and mentors use their typical communication channels to assign work. Schools that use Google Classroom will post assignments there. After-school programs that use group chats to communicate with students can use those same channels. We also work with organizations to provide weekly study plans for students, which can be printed for students or shared with them via email.`

let answer26 = `Winward Academy offers Lesson Instructional Guides for every lesson. In these guides, you will find lesson specific teacher tips and context to share with students, as well as pacing guidance and additional opportunities for practice.

We also offer a teachers' resource guide that provides a comprehensive list of the number of practice questions and the topics (with lengths, in minutes) for each video lesson. This resource guide can be shared anytime with a school or teacher.

Request a copy by emailing <a href="mailto:support@winwardacademy.com" title="Email support@winwardacademy.com">support@winwardacademy.com</a>`

let answer27 = `Students with additional learning struggles benefit tremendously from many customizable aspects of the learning platform: they can adjust the speed of playback of a lesson to fit their desired learning pace; they can break up lessons into smaller chunks to have the content feel more digestible; and they can personalize their study guides based on questions they miss to get focused support from their teachers who review those study guides with them.

We always want to do everything we can to support all learners, so if you have ideas for resources we could provide, please email us at <a href="mailto:help@winwardacademy.com" title="Email help@winwardacademy.com">help@winwardacademy.com</a>.`

let answer28 = `Winward Academy lessons are great ways to learn, review, and master subject material taught in class at school. For example, if a student needs extra help with learning the area of a triangle (either because the lesson was confusing or a student missed the lecture) then he or she can absolutely utilize the Winward Academy lessons to make up that content for class and not get behind.`

let answer29 = `As the students receive instruction and practice questions through the platform, teachers will be able to walk around to ensure students are on task, to provide more personalized check-ins, and to support their efforts.`

let answer30 = `Students' accounts are created with access to all content because we never want to restrict access for review. A study plan guides them where to start so it's not overwhelming, but they have access to anything in case they need help with school or want to get ahead or review.`

let answer31 = `Middle school students can absolutely feel successful reviewing and learning content through Winward. However, our math lessons start at Algebra I, so if a student isn't yet ready for algebra, then we recommend he or she focus on English and Reading lessons until ready to bring in Math lessons as well.`

let answer32 = `Families play an incredibly important role in student success. The more families are included in the goals of the program, the better the outcomes for teens, especially as they might struggle to grow and build their skills. We encourage families to be excited with their teens about what they're learning and to ask their teens to teach  concepts along the way. Sometimes parents/guardians even sit with their teens and go through the Mistake Bank questions to support that critical review and repetition.`

let answer33 = `We analyzed over 85 real exams from the past 20+ years and categorized each question by lesson and topic tested. We then analyzed those data to generate pie charts to break down what is tested and how frequently in each section of each exam. These pie charts are incredibly valuable to inform strategies and/or approaches with students to maximize their growth depending on how much time they have to study.`

let answer34 = `To break up content into shorter segments, teachers have multiple options: (1) they can have students complete a lesson over several sittings with each focused on one topic (instead of watching all the topics back to back in one sitting); (2) they can utilize ACT sprints or SAT sprints to break up longer sections into shorter bursts that still develop time management skills; or (3) they can have students complete the multiple choice in smaller chunks with focused review in the Mistake Bank as they go. Teachers can also access Stay Sharp worksheets in the Resources tab, which have ~10 questions on a specific topic. `

let answer35 = `After completing our lessons and practice tests, our students average 4.3 points of growth on the ACT and 190 points of growth on the SAT. It's truly a "you get out what you put in" type of experience. Students who are diligent in class with lessons, write hints, redo mistakes, and take notes for test corrections grow their scores 10+ points on the ACT and 300+ points on the SAT. `

let answer36 = `A TI-83 or TI-84 graphing calculator is recommended as students advance in high school. These calculators are pricey (over $100) so students need to take great care of them. Winward Academy has an entire math lesson devoted to teaching students how to use these calculators to simplify complex math questions.`

let answer37_1 = `Yes, all of our instructional videos offer closed captioning. Students also have the ability to adjust the playback speed of each video to best meet their individual needs.`

let answer37 = `The baseline questions are all of medium to hard level of difficulty. So if students get 4/5 or 5/5 right, then they're prompted to skip the video lesson and go straight to more multiple choice practice questions.`

let answer38 = `Baseline questions - all of medium to hard level of difficulty - occur prior to an English or Math lesson. Those same questions repeat during the multiple choice following the video lesson. They're saved in the Mistake Bank if students miss them during the post-lesson multiple choice.`

let answer39 = `Students can exit a video lesson and then resume anytime.`

let answer40 = `The number of lessons varies per subject. English and Math subjects have more lessons because there is more content students need to review and master for those subjects. Reading and Science subjects have fewer lessons because students don't need to learn content for those sections but rather understand strategies, types of questions, and types of passages, which requires less dedicated instructional time.`

let answer41 = `This platform is not about hitting play and watching videos. It's about engaging actively with the content while actively taking notes and following with a natural progression of material that gradually increases in difficulty. Also note that lessons are listed in order based on how frequently they're tested, which guides how to prioritize content to review.`

let answer42 = `We always recommend students follow along with the printed handouts. However, if they don't have printer access, then they can always take notes on a separate sheet of paper or view the PDF handout on a tablet to take notes. The ability to follow along and take notes is critical to enhance learning.`

let answer43 = `The Intro to Reading lesson focuses on teaching students strategies around the types of questions they'll encounter, so that lesson has snippets of passages. The other Reading lessons (i.e., Single Passages, Dual Passages, and Single Passages with Figures) have full passages for students to practice with varied questions types.`

let answer44 = `The Debating Scientist lesson is designed to expose students to the passage structure and types of questions in that passage. For additional practice, students can do stay sharp worksheets (available in the Resources tab of the teacher portal) or timed practice from past real exams.`

let answer45 = `The multiple-choice questions activate when a student finishes a video lesson. The only way a student can skip a video lesson and go straight to the multiple-choice is if that student gets 4/5 or 5/5 right in the baseline, in which case the system will prompt that student with the option to skip the video lesson and go straight to the multiple-choice questions.`

let answer46 = `Students can certainly feel overwhelmed by the quantity of questions. Having them break up lesson multiple choice into smaller chunks and do detailed review in the mistake bank as they progress can make the entire experience feel more digestible. So instead of answering 40 Pronouns questions all in a row, students can do five at a time and redo missed questions in their Mistake Bank before advancing to the next set of five questions.`

let answer47 = `Students can exit multiple-choice questions and then resume where they left off. In fact, many teachers have students do a set of 10 multiple choice questions while being very diligent about watching the TEACH ME explanations and taking notes for problem-solving hints. Then students can go to the Mistake Bank and redo any mistakes from that first batch of 10 questions before going back to the multiple choice to do the next 10 questions. So if teachers prefer to break up the multiple choice (especially with lessons with a high number of questions) then they can absolutely do that.`

let answer48 = `We know many teachers like to have the class watch the video together from a teacher's device, and then students complete the multiple choice on their own. If a teacher plays the video lesson during class, then students should login and skip the video to complete their multiple choice independently. 

Please have them click to START the lesson and then answer the baseline questions (if applicable). To skip the video lesson and activate the multiple choice questions, students should click the last topic segment of the video lesson and advance the video to the last second. When the video ends, the popup to start the multiple choice will load.`

let answer49 = `Since the multiple choice questions are personalized for each student, there is not a fixed amount of time students spend completing the questions after the video lesson.

Students with lower accuracy will move more slowly because they need to watch more TEACH ME explanations and to write themselves more problem-solving hints. 

Students with higher accuracy will move more quickly. We recommend that teachers tell students that if they finish the multiple choice before their classmates, they should go to the Mistake Bank to spend the extra time redoing prior missed questions, or they can go to Stay Sharp and load extra practice questions on a topic in which they need more review.`

let answer50 = `All 10,000+ of our practice multiple choice questions come from real past exams.`

let answer51 = `Questions are identified on a scale of 1 (easiest) to 5 (hardest) based on past student performance. Questions with high student accuracy are considered "easier," and questions with low student accuracy are considered "harder."`

let answer52 = `If you have a student who wants to reset a lesson and try it again from the beginning, then email <a href="mailto:support@winwardacademy.com" title="Email support@winwardacademy.com">support@winwardacademy.com</a> with the student's email and the name of the lesson to reset. Please allow 24 hours for our support team to make this adjustment. `

let answer53 = `The best advice we've given students is to write themselves a hint as if they're pretending they're teaching the concept to someone else. So whatever they write should still make sense if they read it 3 months later.`

let answer54 = `We have printable PDFs for the multiple choice for the Reading and Science sections. We have a few made for Math. Please reach out to our team at <a href="mailto:support@winwardacademy.com" title="Email support@winwardacademy.com">support@winwardacademy.com</a>, and we can check if we have what you're looking for.`

let answer55 = `Every multiple choice question in the entire platform (over 10,000!) has a filmed explanation that reviews the correct steps to solve and also highlights students' common mistakes and the ways the testing agencies try to be tricky.`

let answer56 = `The Mistake Bank automatically saves questions students miss and also saves questions that students add when they get them right.`

let answer57 = `We keep track of all students' mistakes because it is THAT important for them to go back and review prior missed questions. Students truly learn when they own this step of their learning journey and build this incredibly important life skill.`

let answer58 = `Since the practice ACT, SAT, and Interim Assessment exams are all copyright protected materials, Winward Academy cannot put the questions themselves in the students' Mistake Banks. 

However, we are currently building functionality so that students can write themselves problem-solving hints after reviewing a question from a practice test, and those hints will save in the Personalized Study Guide students can generate in their Mistake Banks. Each hint will also indicate the test, section, and question number from which the hint was generated, so students will easily know how to go back to the practice tests to reference questions they may need to review.`

let answer59 = `All Stay Sharp practice questions are distinct from the Lesson Multiple Choice practice questions. They are all new for students to continue applying the same skills.`

let answer60 = `After reviewing prior missed questions in the Mistake Bank, students should then feel ready for extra practice in specific topics. That's when the extra question bank in Stay Sharp will provide access to additional practice to help students master content.`

let answer61 = `Yes, when students complete additional practice in Stay Sharp, those missed questions, saved questions, and hints will also get organized in the Mistake Bank for further review.`

let answer62 = `For ease of monitoring progress, most teachers prefer to have their students completing the same lessons in class. We work with schools to create custom study plans that prioritize the most high impact lessons (i.e., what's tested the most frequently) to be completed as a cohort. As students complete more timed practice of real exams with detailed corrections notes, students can view their exam analysis and prioritize lessons to complete independently that aren't tested as frequently but are areas of growth the students need.`

let answer63 = `Our team can provide example study plans, or we can work with your organization individually to customize a scope and sequence around time per week, areas of biggest need for your students, and strategies to maximize growth depending on real exam administration dates.`

let answer64 = `We typically put together study plans with monthly mastery goals for grades 8-10 and weekly assignments for grades 11-12. Teachers and mentors are more than welcome to pass on the plan to students who will work independently through assignments, or they can choose to facilitate time for students to work on those assignments each week or each month.`

let answer65 = `Students can view an analysis from a practice test, which breaks down their accuracy by lesson. They can then prioritize lessons in areas in which they need content review.`

let answer66 = `It's important to emphasize that one of the biggest challenges students face during an ACT or SAT exam is having the stamina to stay focused for a 4-hour test with just one 10-minute break in the middle. Students have to understand that the first bubble in the first section is worth the same amount as the last bubble in the last section. That means that building the mental ability to focus for hours straight is critical to a student performing at his or her ability on these exams.

For that reason, we strongly encourage students to get in the habit of sitting down for a minimum of ~1-hour chunks of time as they're doing lessons and completing multiple choice questions. That being said, if a student has 15 free minutes and wants to be productive, then we recommend he or she go to the Mistake Bank and redo questions missed previously or go to Stay Sharp and focus on a specific topic. That's a better use of that time than starting a lesson and then stopping a fraction of the way through.`

let answer67 = `Younger grades tend to spend about 0.5-1 hour/week on the platform to review and reinforce foundational English and Math skills (e.g., apostrophes, exponents, or FOIL). Older grades tend to spend closer to 2-4 hours/week on the platform to make sure they're exposing themselves to the needed content and practicing applying their content knowledge to real past exams.`

let answer68 = `Yes, the platform is designed so students can drive their own learning journeys, working independently or in groups. Lessons are broken down to ensure students are building skills along the way, so students tend to stay on track when they carve out dedicated time for lessons. It's a great opportunity for teens to also develop time management skills.`

let answer69 = `It is not too much repetition to start in middle school. Students need ample opportunities to reinforce concepts and practice with guided repetition. Younger grades focus on monthly mastery goals of foundational skills critical to English and Math. Older grades focus more on specific review for college readiness. `

let answer70 = `11th and 12th graders tend to be the most active users as they prepare for college acceptance exams and/or college applications.`

let answer71 = `By matching teachers, counselors, or administrators to students (by category or by class period), it is easy to track student progress, completion, and mastery. Please email <a href="mailto:support@winwardacademy.com" title="Email support@winwardacademy.com">support@winwardacademy.com</a> for help in that process.`

let answer72 = `The teacher portal has an Individual View tab that breaks down the progress for each student for each lesson. It will show you if the student is answering baseline questions, watching the video lesson, or completing multiple choice questions.`

let answer73 = `The teacher portal has a Live View tab in which you can track in real time what your students are working on.`

let answer74 = `It's important that students are showing genuine effort during the lessons - taking great notes during the video lesson, watching the TEACH ME explanations for missed multiple choice questions, writing great hints for missed questions, reviewing mistakes in the Mistake Bank, and accessing additional practice questions in Stay Sharp. Tracking those behaviors is the best way to know that students are building life-long study habits.`

let answer75 = `Currently, most teachers have students generate their personalized study guides in the Mistake Bank and then save it as a PDF to turn in via Google Classroom (or however you receive student work). That's a way for you to track and monitor the quality of their notes without needing a printer.`

let answer76 = `You can use the Live View to monitor their activity and the reports to track both individual and average progress. `

let answer77 = `All the lesson and practice test reports are available for teachers and administrators to export to Excel for further viewing and sorting of the raw data.`

let answer78 = `Teachers specifically requested that both post-lesson metrics be available. First, they want to view completed accuracy to get a sense of how students have performed on the questions they've answered. Second, they want to view overall accuracy depending on the total number of questions. For example, if a lesson has 40 questions and a student has answered 10 right out of 15 questions answered, then the completed accuracy is 10/15 but the total accuracy is 10/40.`

let answer79 = `It's important for students to know the multiple-choice questions for each lesson come from past real ACT and SAT exams.  The accuracy to score conversion is very different from what they're used to at school. Students can access a score conversion table, shown below, from the summary page of each lesson. Teachers can access the same conversion table in both their Data tab and Lessons tab.

<img src="/assets/images/resources/faqs/accuracy-maps-1.jpg" alt="accuracy maps" />`

let answer80 = `Most teachers grade students based on the quality of their work. For any missed multiple-choice question, students are prompted to write themselves problem solving hints. This process is essential to help students put what they learn into their own words. In the lesson reports, teachers can view what percent of the time students write themselves hints (clearly the goal is as close to 100%). Teachers can also see the hints students are writing themselves: students can login, go to their Mistake Bank, click to generate a personalized study guide, and then either print the study guide or save it as a PDF to email to their teachers. Teachers can then review the hints students are writing themselves to evaluate the quality of their notes and to provide feedback.

After students do corrections notes for timed practice sections, teachers frequently have students put together songs, presentations, or posters to present to the class with the top tips they've learned. It's a great way to make the review process more engaging and interactive, and it provides another grading opportunity for teachers.`

let answer81 = `Students produce completion data and accuracy data, and all are accessible to those monitoring their progress. Most grade based on indications of effort, growth, detailed note taking, and quality hint writing. The Winward Academy team is happy to offer individual guidance on grading based on your organization's unique goals and approach. `

let answer82 = `Please remember that what's most important with these lessons is to reward students' growth and effort, not just a straight accuracy score. The challenge is that all our multiple choice questions come from past real exams, which means earning a 50% accuracy can actually translate to a "college ready" score for many sections. So the percentage accuracy on a Winward lesson has a very different meaning compared to what students are used to in school.`

let answer83 = `Every practice test available through Winward Academy (ACT and SAT) is a real past exam scored with the real conversion used by ACT or CollegeBoard for that administration. Truly, your students couldn't have access to more authentic practice test materials.`

let answer84 = `The ACT has four sections (English, Math, Reading, and Science) scored individually, and an overall composite score is calculated as the average of the four sections.

An SAT also has four sections but is scored differently. The Evidence-Based Reading & Writing (EBRW) score is calculated based on performance on a Reading section and a Writing & Language section. The Math score is calculated based on performance on two math sections - one with and one without a calculator. The overall SAT score is the sum of the EBRW and Math scores.`

let answer85 = `Interestingly, the ACT and CollegeBoard scale every test differently based on the level of difficulty of questions and students' accuracy. So a student who misses the same number of questions in a section could end up with a different score depending on the exam. Every practice test in our platform is scored based on the real score conversion for that exam, so students will have an accurate sense of how they'd perform.`

let answer86 = `We add exams to our platform as they're released publicly by ACT and College Board. We also leverage the most recent exams for additional materials developed for teachers to use in their classrooms.`

let answer87 = `This question must be answered personally for each student. We've seen students grow from an ACT of a 10 to a 20 or a 20 to a 30. What's possible is so personal for each student and is also largely determined by the student's effort and willingness to take the preparation and time seriously. `

let answer88 = `We have additional presentations that are accessible in our YouTube channel. These break down test taking strategies and top tips for both exams.`

let answer89 = `The PSAT is a shorter version of the SAT. It tests the same content with the same question formatting, but it's just shorter. The PSAT is administered through schools in October with January as a makeup date. The scores are not reported to colleges, so it's a great practice opportunity for students. If students score in the top 1% for their state, then they qualify for national merit scholarships.`

let answer90 = `The conversion table values are based on averages of these conversions from real ACT and SAT exams from the past 20 years.`

let answer91 = `Students frequently are told (but often forget) that they shouldn't leave any question blank in a real exam because they don't lose points for wrong answers. However, even though students might be told this over and over, they can still forget and then leave answers blank when they shouldn't. Having a "blank" column is a great reminder for students that the column should have all zeros. If it doesn't, then the students need to be reminded to guess when they run out of time or to guess if they don't know how to answer a question.`

let answer92 = `We provide all the practice tests and proctoring support. Students print the practice exam and scantron to take the test in a pencil-and-paper format (just like the real thing). Then they follow along with a proctoring video that times them, gives 5 minute warnings, and offers tips along the way. Then students log in to Winward Academy to enter their answers to score the exam, view a detailed analysis, and review explanations for all the questions.`

let answer93 = `We provide the materials and proctoring videos that time the sections, give 5 minute warnings, prime when to take breaks, and even offer helpful tips along the way. Students are therefore able to self-proctor practice exams through our platform, or they can take them at school in more structured settings.`

let answer94 = `Many students choose to take just one section or even parts of a section. For example, a student may just want to focus on the hard math problems, so he or she can practice questions #41-60 in multiple ACT exams (instead of having to take the entire section or the entire test).`

let answer95 = `Students can enter their own scores directly into the platform, or the Winward team can upload Illuminate or Edulastic matrix reports provided by the school or organization with a 24-hour turnaround.`

let answer96 = `Winward Academy has dedicated team members to support with deeper dives into data to identify intervention opportunities, inform strategies, and track progress.`

let answer97 = `PDFs for the ACT and SAT exams are available for you to leverage for extra practice.`

let answer98 = `Students can view an analysis from a practice test, which will show them how frequently a lesson is tested and how well they performed on that lesson.`

let answer99 = `Teachers and mentors frequently ask about how to direct their students toward individualized, focused practice on Winward after they take a real exam. The challenge with valuable reflection after the official tests is that the students won't have access to review their missed questions broken down by lesson and topic. So the reflection process tends to be more about the score, the timing, and if there's anything they noticed that they didn't remember. So the practice exams they do through Winward Academy actually facilitate much more processing and reflection because they can understand what they miss and why they miss it.`

let answer100 = `Winward Academy supports school interim assessments for many school networks, including KIPP, Ednovate, Great Oaks Legacy, Cristo Rey, and Uncommon. We can work with your school or organization individually to customize these programs and to identify the best way to leverage these extra practice exams.`

let answer101 = `Either a school network or the Winward team can provide the PDF for the exam. Schools print that exam, so students take the exam in the real testing condition of pencil-and-paper. The students' answers are uploaded for scoring (e.g., to Illuminate or Edulastic). Then the school provides those answer matrix reports to the Winward team, and we get the students' answers uploaded into their accounts for review and analysis.`

let answer102 = `Our platform is built to support either approach. You can either administer a full exam in one sitting, to emulate the real testing conditions and to give students an authentic expectation of the length of a real exam, or you can choose to break up a real exam into its sections and administer a full test over multiple class periods. Most schools and organizations finalize an approach based on timing restrictions with class and/or after school time limitations.`

let answer103 = `For schools that administer Interim Assessments (or practice exams) and then score them in Illuminate, students' answers can be moved from Illuminate into Winward Academy. A school's data coordinator downloads the Matrix Reports for each section from a practice test. For an ACT or IA exam, there will be four matrix reports - one for English, Math, Reading, and Science. For an SAT exam, there will be four matrix reports - one for Reading, Writing and Language, Math No Calculator, and Math Calculator. 
Winward Academy uploads these matrix reports into the platform's backend system so that students can easily see their scores and review their mistakes after taking these practice exams at school. After the matrix reports are uploaded, students can login and see their scores and their populated answers, which are marked green for correct and red for incorrect.
<img src="/assets/images/resources/faqs/illuminate-practice-test-1.jpg" alt="illuminate practice test image 1" />
As with all Winward Academy lessons, students can click the explanation video icon to access step-by-step explanations for each question.
<img src="/assets/images/resources/faqs/illuminate-practice-test-2.jpg" alt="illuminate practice test image 2" />`

let answer104 = `Teachers can also put students' answers on Winward Academy lessons into their Illuminate accounts to track answers and accuracy. From their Winward Academy logins, teachers have the option to download .csv files with their students' answers for each multiple-choice question for each lesson. All Winward Academy lessons have “shells” in the Illuminate ZeLibrary so that teachers can easily upload those .csv files to Illuminate and then track students' answers to each question. 
Here are the steps for the data manager at each school to ensure the Winward Academy shells are available in teachers' Illuminate logins.
 - Login as the user you want to be the author of these shells 
 - Go to the ZeLibrary by clicking on the cogwheel -> install packages (under ZeLibrary heading)
 - Do not change the default library that loads, “Illuminate” 
 - Next to that dropdown is a tag box; type in “Winward” and select the tag “Winward Academy” 777
 - Hit enter
 - When the option “Winward Academy Assessment View” appears, check the box to the left of it and click “install selected”
Within a few minutes, the assessment view & assessments will be available in your “view assessments” list.`

let answer105 = `All Winward Academy lessons are available in the ZeLibrary in Illuminate. Teachers or administrators can locate the lessons by searching in Illuminate using the following conventions: Winward Academy - Lesson Name. For example, Winward Academy - Arithmetic Sequences. Once you locate the lesson shell (i.e., answer key in Illuminate) then you can upload your student data into Illuminate to view the question topical breakdowns and overview pie charts.`

let answer106 = `After you've administered an assessment and scored it through Illuminate, you can email <a href="mailto:support@winwardacademy.com" title="Email support@winwardacademy.com">support@winwardacademy.com</a> with the four matrix reports (one for each section of the test). The Winward team will get those matrix reports uploaded within 24 hours, so your students will then be able to jump into their test corrections.`

let answer107 = `All students creating accounts receive an email to verify their account regardless of whether their accounts are created with rosters, access codes, or free-trial extensions. Students will not be able to log in to their Winward Academy accounts until their emails are verified.
If students are having trouble receiving the verification email, please first suggest that they check their junk/spam folder just in case the verification email from <a href="mailto:help@winwardacademy.com" title="Email help@winwardacademy.com">help@winwardacademy.com</a> ended up there. 
Second, if any students accidentally delete their verification emails from Winward Academy, they'll need to follow these simple steps to resend the verification email. 
 - Go to winwardacademy.com
 - Click on Log In in the upper right corner
 - Click on Forgot Password
 - Enter your email and click SEND INSTRUCTIONS
 - If the email entered isn't yet verified, a red message appears that allows the user to resend the verification email (see image below)
<img src="/assets/images/resources/faqs/verification-email-1.jpg" alt="verification email image 1" />
 - Click that link to resend the verification email
For a demonstration video of this process, go to winwardacademy.com/demos and watch the 2-minute video ""How to Verify an Account When Email is Lost,"" which reviews these steps. You can also locate this demo in the Demos tab of the Resources section.`

let answer108 = `If students forget their passwords, they can reset a password by clicking Forgot Password? on the Log In page under the green LOGIN button. Alternatively, you can provide them with this link to the reset password page.
<img src="/assets/images/resources/faqs/forgot-password-1.jpg" alt="forgot password image 1" />`


const simpleTxt2Html = (txt: string): string => {    
    let lines = txt.trim().split("\n")
    let html = lines.reduce((acc, cur) => {
        acc += `${cur} <br/>` 
        return acc
    }, "")

    return `<div>${html}</div>`
}

export const faqsData = [
    {
        "categoryOrder": 1,
        "category" : "Creating, Changing, or Deleting Accounts",
        "questionOrder" : 1,
        "question": "How are accounts created with rosters?",
        "answer" : simpleTxt2Html(answer01)
    },
    {
        "categoryOrder": 1,
        "category" : "Creating, Changing, or Deleting Accounts",
        "questionOrder" : 2,
        "question": "How are accounts created with access codes?",
        "answer" : simpleTxt2Html(answer02)
    },
    {
        "categoryOrder": 1,
        "category" : "Creating, Changing, or Deleting Accounts",
        "questionOrder" : 3,
        "question": "How do we change an email that was used to create a student's account?",
        "answer" : simpleTxt2Html(answer03)
    },
    {
        "categoryOrder": 1,
        "category" : "Creating, Changing, or Deleting Accounts",
        "questionOrder" : 4,
        "question": "How do we remove students who are no longer using Winward?",
        "answer" : simpleTxt2Html(answer04)
    },
    {
        "categoryOrder": 1,
        "category" : "Creating, Changing, or Deleting Accounts",
        "questionOrder" : 5,
        "question": "What is the process to connect students' data to teachers?",
        "answer" : simpleTxt2Html(answer05)
    },
    {
        "categoryOrder": 1,
        "category" : "Creating, Changing, or Deleting Accounts",
        "questionOrder" : 6,
        "question": "Does Winward Academy integrate with Clever?",
        "answer" : simpleTxt2Html(answer06_1)
    },
    {
        "categoryOrder": 1,
        "category" : "Creating, Changing, or Deleting Accounts",
        "questionOrder" : 7,
        "question": "Is it possible to reset a student's lesson so they can do it over again?",
        "answer" : simpleTxt2Html(answer07_1)
    },
    {
        "categoryOrder": 2,
        "category" : "Implementing IT Requirements",
        "questionOrder": 1,
        "question" : "What whitelisting requirements should schools enable to ensure students can access the e-learning platform?",
        "answer" : simpleTxt2Html(answer06)
    },
    {
        "categoryOrder": 2,
        "category" : "Implementing IT Requirements",
        "questionOrder": 2,
        "question" : "What whitelisting requirements should schools enable to ensure students can receive emails to set and reset passwords?",
        "answer" : simpleTxt2Html(answer07)
    },
    {
        "categoryOrder": 2,
        "category" : "Implementing IT Requirements",
        "questionOrder": 3,
        "question" : "Is this an application on the computer?",
        "answer" : simpleTxt2Html(answer08)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 1,
        "question" : "How can teachers access a demo of their teacher portal?",
        "answer" : simpleTxt2Html(answer09)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 2,
        "question" : "How do students and/or teachers access demos of the students' experience with lessons and practice tests?",
        "answer" : simpleTxt2Html(answer10)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 3,
        "question" : "What are some ways students can collaborate on the learning piece together?",
        "answer" : simpleTxt2Html(answer11)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 4,
        "question" : "What are the first things teachers and students should be looking for?",
        "answer" : simpleTxt2Html(answer12)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 5,
        "question" : "What Winward Academy resources are available to be completed offline?",
        "answer" : simpleTxt2Html(answer13)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 6,
        "question" : "Do you have any short activities to get comfortable with the software?",
        "answer" : simpleTxt2Html(answer14)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 7,
        "question" : "How do I know where to start?",
        "answer" : simpleTxt2Html(answer15)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 8,
        "question" : "How do we onboard new teachers/students?",
        "answer" : simpleTxt2Html(answer16)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 9,
        "question" : "How do I get recordings of trainings for my specific region that I missed?",
        "answer" : simpleTxt2Html(answer17)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 10,
        "question" : " Are the slides used for the training available to us?",
        "answer" : simpleTxt2Html(answer18)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 11,
        "question" : " What is the best way to leverage the career panels available in the Enrichment section of the platform's lessons?",
        "answer" : simpleTxt2Html(answer19)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 12,
        "question" : " How do you suggest we integrate the College Knowledge Breaking Barriers curriculum into our classes or discussions?",
        "answer" : simpleTxt2Html(answer20)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 13,
        "question" : " How can teachers view a TEACH ME explanation video before reviewing questions with students?",
        "answer" : simpleTxt2Html(answer21)
    },
    {
        "categoryOrder" : 3,
        "category" : "Supporting Teachers with Onboarding",
        "questionOrder" : 14,
        "question" : " Do you have student-facing or scholar-facing videos to support student onboarding?",
        "answer" : simpleTxt2Html(answer22)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 1,
        "question" : "What do you recommend for students who are strong in a subject and might get bored doing so many example questions?",
        "answer" : simpleTxt2Html(answer23)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 2,
        "question" : "What do you recommend for students who aren't native English speakers?",
        "answer" : simpleTxt2Html(answer24)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 3,
        "question" : "How do teachers tell students what work is assigned?",
        "answer" : simpleTxt2Html(answer25)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 4,
        "question" : "How do teachers know the lengths of lessons and topical segments? Or the number of practice questions for each lesson?",
        "answer" : simpleTxt2Html(answer26)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 5,
        "question" : "What accommodations are available to students with disabilities?",
        "answer" : simpleTxt2Html(answer27)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 6,
        "question" : "How does Winward Academy help a student who doesn't understand something in class or who misses school?",
        "answer" : simpleTxt2Html(answer28)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 7,
        "question" : "How is Winward Academy best used in the classroom?",
        "answer" : simpleTxt2Html(answer29)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 8,
        "question" : "Do students have access to all content, or can they only view lessons assigned to their grade level?",
        "answer" : simpleTxt2Html(answer30)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 9,
        "question" : "Can middle school students benefit from accessing lessons on Winward Academy? Or are they too young?",
        "answer" : simpleTxt2Html(answer31)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 10,
        "question" : "	What is the role of the family and parents in the process?",
        "answer" : simpleTxt2Html(answer32)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 11,
        "question" : "	Where do the data in the \"see what's tested\" pie charts come from?",
        "answer" : simpleTxt2Html(answer33)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 12,
        "question" : "	Is it possible to have shorter lessons for ACT and SAT prep?",
        "answer" : simpleTxt2Html(answer34)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder": 13,
        "question" : "	What ACT and SAT results have been achieved by students on Winward Academy?",
        "answer" : simpleTxt2Html(answer35)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder" : 14,
        "question" : "Do students need a special calculator?",
        "answer" : simpleTxt2Html(answer36)
    },
    {
        "categoryOrder" : 4,
        "category" : "Customizing & Supporting Student Learning",
        "questionOrder" : 15,
        "question" : "Do the videos offer closed captioning?",
        "answer" : simpleTxt2Html(answer37_1)
    },
    {
        "categoryOrder" : 5,
        "category" : "Completing a Lesson: Baseline Questions",
        "questionOrder" : 	1,
        "question" : "How hard are the baseline questions?",
        "answer" : simpleTxt2Html(answer37)
    },
    {
        "categoryOrder" : 5,
        "category" : "Completing a Lesson: Baseline Questions",
        "questionOrder" : 	2,
        "question" : "Do students see or review the baseline questions again?",
        "answer" : simpleTxt2Html(answer38)
    },
    { 
        "categoryOrder" : 6,
        "category" : "Completing a Lesson: Watching the Video Instruction",
        "questionOrder" : 	1,
        "question" : "Do students have to watch the video lesson in one sitting?",
        "answer" : simpleTxt2Html(answer39)
    },
    { 
        "categoryOrder" : 6,
        "category" : "Completing a Lesson: Watching the Video Instruction",
        "questionOrder" : 	2,
        "question" : "How many lessons are there in total for each subject?",
        "answer" : simpleTxt2Html(answer40)
    },
    { 
        "categoryOrder" : 6,
        "category" : "Completing a Lesson: Watching the Video Instruction",
        "questionOrder" : 	3,
        "question" : "Is it important to watch the lessons in a specific order?",
        "answer" : simpleTxt2Html(answer41)
    },
    { 
        "categoryOrder" : 6,
        "category" : "Completing a Lesson: Watching the Video Instruction",
        "questionOrder" : 	4,
        "question" : "Is the print worksheet available digitally if I do not want to print?",
        "answer" : simpleTxt2Html(answer42)
    },
    { 
        "categoryOrder" : 6,
        "category" : "Completing a Lesson: Watching the Video Instruction",
        "questionOrder" : 	5,
        "question" : "In the reading lessons, is the whole passage included or is it snippets?",
        "answer" : simpleTxt2Html(answer43)
    },
    { 
        "categoryOrder" : 6,
        "category" : "Completing a Lesson: Watching the Video Instruction",
        "questionOrder" : 	6,
        "question" : "Will there be more lessons for Debating Scientist questions?",
        "answer" : simpleTxt2Html(answer44)
    },
    { 
        "categoryOrder" : 7,
        "category" : "Completing a Lesson: Answering Multiple Choice",
        "questionOrder" : 1,
        "question" : "Do students have to finish a video lesson before their multiple-choice questions are accessible?",
        "answer" : simpleTxt2Html(answer45)
    },
    { 
        "categoryOrder" : 7,
        "category" : "Completing a Lesson: Answering Multiple Choice",
        "questionOrder" : 2,
        "question" : "Do students have to complete all multiple choice questions for a lesson in one sitting? Or can they break it up?",
        "answer" : simpleTxt2Html(answer46)
    },
{ 
        "categoryOrder" : 7,
        "category" : "Completing a Lesson: Answering Multiple Choice",
        "questionOrder" : 3,
        "question" : "Do students have to answer all the multiple-choice in one sitting?",
        "answer" : simpleTxt2Html(answer47)
    },
    { 
        "categoryOrder" : 7,
        "category" : "Completing a Lesson: Answering Multiple Choice",
        "questionOrder" : 4,
        "question" : "Are students able to answer the multiple choice questions from a lesson without watching the videos?",
        "answer" : simpleTxt2Html(answer48)
    },
    { 
        "categoryOrder" : 7,
        "category" : "Completing a Lesson: Answering Multiple Choice",
        "questionOrder" : 5,
        "question" : "How long does it take students to finish the multiple choice questions?",
        "answer" : simpleTxt2Html(answer49)
    },
    { 
        "categoryOrder" : 7,
        "category" : "Completing a Lesson: Answering Multiple Choice",
        "questionOrder" : 6,
        "question" : "Where do the multiple choice questions come from?",
        "answer" : simpleTxt2Html(answer50)
    },
    { 
        "categoryOrder" : 7,
        "category" : "Completing a Lesson: Answering Multiple Choice",
        "questionOrder" : 7,
        "question" : "How is the level of difficulty for each question determined?",
        "answer" : simpleTxt2Html(answer51)
    },
    { 
        "categoryOrder" : 7,
        "category" : "Completing a Lesson: Answering Multiple Choice",
        "questionOrder" : 8,
        "question" : "Can a student redo multiple-choice questions if he or she wants to retry?",
        "answer" : simpleTxt2Html(answer52)
    },
    { 
        "categoryOrder" : 7,
        "category" : "Completing a Lesson: Answering Multiple Choice",
        "questionOrder" : 9,
        "question" : "What are examples or best practices for what hints should look like?",
        "answer" : simpleTxt2Html(answer53)
    },
    { 
        "categoryOrder" : 7,
        "category" : "Completing a Lesson: Answering Multiple Choice",
        "questionOrder" : 10,
        "question" : "	Is there a pdf of all practice multiple choice problems for just math?",
        "answer" : simpleTxt2Html(answer54)
    },
    {
    
        "categoryOrder" : 8,
        "category" : "Completing a Lesson: Reviewing in the Mistake Bank",
        "questionOrder" : 1,
        "question" : "Does every question in the Mistake Bank have a filmed step-by-step explanation?",
        "answer" : simpleTxt2Html(answer55)
    },
    {
        
        "categoryOrder" : 8,
        "category" : "Completing a Lesson: Reviewing in the Mistake Bank",
        "questionOrder" : 2,
        "question" : "What questions are in the Mistake Bank?",
        "answer" : simpleTxt2Html(answer56)
    },
    {
        
        "categoryOrder" : 8,
        "category" : "Completing a Lesson: Reviewing in the Mistake Bank",
        "questionOrder" : 3,
        "question" : "Do students really need to go to the Mistake Bank to redo prior missed questions?",
        "answer" : simpleTxt2Html(answer57)
    },
    {
        
        "categoryOrder" : 8,
        "category" : "Completing a Lesson: Reviewing in the Mistake Bank",
        "questionOrder" : 4,
        "question" : "Do questions from practice tests that students get incorrect go to the Mistake Bank?",
        "answer" : simpleTxt2Html(answer58)
    },
    
    {
        
        "categoryOrder" : 9,
        "category" : "Completing a Lesson: Getting Extra Practice in Stay Sharp",
        "questionOrder" : 1,
        "question" : "Are the Stay Sharp questions the same as the lesson multiple choice questions?",
        "answer" : simpleTxt2Html(answer59)
    },
    {
        
        "categoryOrder" : 9,
        "category" : "Completing a Lesson: Getting Extra Practice in Stay Sharp",
        "questionOrder" : 2,
        "question" : "When is the right time to do Stay Sharp practice?",
        "answer" : simpleTxt2Html(answer60)
    },
    {
        
        "categoryOrder" : 9,
        "category" : "Completing a Lesson: Getting Extra Practice in Stay Sharp",
        "questionOrder" : 3,
        "question" : "Do mistakes and hints from Stay Sharp questions also go into the Mistake Bank?",
        "answer" : simpleTxt2Html(answer61)
    },
    {
        "categoryOrder" : 10,
        "category" : "Creating or Following a Study Plan",
        "questionOrder" : 1,
        "question" : "Can the students pick what they want to study or should we pick for them?",
        "answer" : simpleTxt2Html(answer62)
    },
    {
        "categoryOrder" : 10,
        "category" : "Creating or Following a Study Plan",
        "questionOrder" : 2,
        "question" : "Are there specific scope and sequences we can have access to?",
        "answer" : simpleTxt2Html(answer63)
    },
    {
        "categoryOrder" : 10,
        "category" : "Creating or Following a Study Plan",
        "questionOrder" : 3,
        "question" : "Do students have a study guide to self-pace and access lessons?",
        "answer" : simpleTxt2Html(answer64)
    },
    {
        "categoryOrder" : 10,
        "category" : "Creating or Following a Study Plan",
        "questionOrder" : 4,
        "question" : "Can the platform be used to recommend study plans for individual students?",
        "answer" : simpleTxt2Html(answer65)
    },
    {
        "categoryOrder" : 10,
        "category" : "Creating or Following a Study Plan",
        "questionOrder" : 5,
        "question" : "What amount of time should students put into a learning session? 5 or 10 minutes at a time? 50-55 minutes at a time?",
        "answer" : simpleTxt2Html(answer66)
    },
    {
        "categoryOrder" : 10,
        "category" : "Creating or Following a Study Plan",
        "questionOrder" : 6,
        "question" : "How much time should students plan to spend on Winward Academy lessons?",
        "answer" : simpleTxt2Html(answer67)
    },
    {
        "categoryOrder" : 10,
        "category" : "Creating or Following a Study Plan",
        "questionOrder" : 7,
        "question" : "To balance school and activities, can students do lessons on their own time?",
        "answer" : simpleTxt2Html(answer68)
    },
    {
        "categoryOrder" : 10,
        "category" : "Creating or Following a Study Plan",
        "questionOrder" : 8,
        "question" : "Is it too much repetition to start in 8th or 9th grade?",
        "answer" : simpleTxt2Html(answer69)
    },
    {
        "categoryOrder" : 10,
        "category" : "Creating or Following a Study Plan",
        "questionOrder" : 9,
        "question" : "For what grade levels is the work the most intense or frequent?",
        "answer" : simpleTxt2Html(answer70)
    },
    {
        "categoryOrder" : 11,
        "category" : "Navigating the Teacher Portal for Data Tracking",
        "questionOrder" : 1,
        "question" : "Am I able to view progress and data for my students?",
        "answer" : simpleTxt2Html(answer71)
    },
    {
        "categoryOrder" : 11,
        "category" : "Navigating the Teacher Portal for Data Tracking",
        "questionOrder" : 2,
        "question" : "How can teachers monitor students' work like watching the lessons or reviewing multiple choice mistakes?",
        "answer" : simpleTxt2Html(answer72)
    },
    {
        "categoryOrder" : 11,
        "category" : "Navigating the Teacher Portal for Data Tracking",
        "questionOrder" : 3,
        "question" : "How can teachers see what a student is working on?",
        "answer" : simpleTxt2Html(answer73)
    },
    {
        "categoryOrder" : 11,
        "category" : "Navigating the Teacher Portal for Data Tracking",
        "questionOrder" : 4,
        "question" : "How do we best monitor and reflect student effort and engagement over time?",
        "answer" : simpleTxt2Html(answer74)
    },
    {
        "categoryOrder" : 11,
        "category" : "Navigating the Teacher Portal for Data Tracking",
        "questionOrder" : 5,
        "question" : "Is there a way for teachers to monitor the hints that students write without the students printing them out?",
        "answer" : simpleTxt2Html(answer75)
    },
    {
        "categoryOrder" : 11,
        "category" : "Navigating the Teacher Portal for Data Tracking",
        "questionOrder" : 6,
        "question" : "What is the best way to monitor student progress?",
        "answer" : simpleTxt2Html(answer76)
    },
    {
        "categoryOrder" : 11,
        "category" : "Navigating the Teacher Portal for Data Tracking",
        "questionOrder" : 7,
        "question" : "Is there an easy way to view and sort student data in Excel?",
        "answer" : simpleTxt2Html(answer77)
    },
    {
        "categoryOrder" : 11,
        "category" : "Navigating the Teacher Portal for Data Tracking",
        "questionOrder" : 8,
        "question" : "Why do the lesson reports have both completed accuracy and total accuracy? What's the difference?",
        "answer" : simpleTxt2Html(answer78)
    },
    {
        "categoryOrder" : 12,
        "category" : "Identifying Best Practices for Grading",
        "questionOrder" : 1,
        "question" : "My students get 50-60% accuracy on their lessons. How can they understand how that accuracy maps to an ACT/SAT score?",
        "answer" : simpleTxt2Html(answer79)
    },
    {
        "categoryOrder" : 12,
        "category" : "Identifying Best Practices for Grading",
        "questionOrder" : 2,
        "question" : "My students are at very different levels, so I don't want to use percentage accuracy as a grade. What do you recommend?",
        "answer" : simpleTxt2Html(answer80)
    },
    {
        "categoryOrder" : 12,
        "category" : "Identifying Best Practices for Grading",
        "questionOrder" : 3,
        "question" : "How do teachers and mentors typically grade or assess student work and progress?",
        "answer" : simpleTxt2Html(answer81)
    },
    {
        "categoryOrder" : 12,
        "category" : "Identifying Best Practices for Grading",
        "questionOrder" : 4,
        "question" : "Could we use Winward lesson performance data to assign grades?",
        "answer" : simpleTxt2Html(answer82)
    },
    {
        "categoryOrder" : 13,
        "category" : "Understanding Standardized Tests (ACT, SAT, and PSAT)",
        "questionOrder" : 1,
        "question" : "Are the practice tests in Winward Academy real past exams? Or are they made up tests?",
        "answer" : simpleTxt2Html(answer83)
    },
    {
        "categoryOrder" : 13,
        "category" : "Understanding Standardized Tests (ACT, SAT, and PSAT)",
        "questionOrder" : 2,
        "question" : "How are the ACT and SAT scored?",
        "answer" : simpleTxt2Html(answer84)
    },
    {
        "categoryOrder" : 13,
        "category" : "Understanding Standardized Tests (ACT, SAT, and PSAT)",
        "questionOrder" : 3,
        "question" : "Are the score conversions the same for every test?",
        "answer" : simpleTxt2Html(answer85)
    },
    {
        "categoryOrder" : 13,
        "category" : "Understanding Standardized Tests (ACT, SAT, and PSAT)",
        "questionOrder" : 4,
        "question" : "How often do ACT and SAT create new tests? Is Winward up-to-date with the most recent exams?",
        "answer" : simpleTxt2Html(answer86)
    },
    {
        "categoryOrder" : 13,
        "category" : "Understanding Standardized Tests (ACT, SAT, and PSAT)",
        "questionOrder" : 5,
        "question" : "What ACT or SAT score ranges are the best to target and why?",
        "answer" : simpleTxt2Html(answer87)
    },
    {
        "categoryOrder" : 13,
        "category" : "Understanding Standardized Tests (ACT, SAT, and PSAT)",
        "questionOrder" : 6,
        "question" : "Where can I find an overview of the entire SAT/ACT test and test taking strategies?",
        "answer" : simpleTxt2Html(answer88)
    },
    {
        "categoryOrder" : 13,
        "category" : "Understanding Standardized Tests (ACT, SAT, and PSAT)",
        "questionOrder" : 7,
        "question" : "How do students prepare for the PSAT?",
        "answer" : simpleTxt2Html(answer89)
    },
    {
        "categoryOrder" : 13,
        "category" : "Understanding Standardized Tests (ACT, SAT, and PSAT)",
        "questionOrder" : 8,
        "question" : "How are the conversion tables from accuracy to projected ACT or SAT score calculated? Is it from one test? Many tests?",
        "answer" : simpleTxt2Html(answer90)
    },
    {
        "categoryOrder" : 13,
        "category" : "Understanding Standardized Tests (ACT, SAT, and PSAT)",
        "questionOrder" : 9,
        "question" : "Why is there a \"blank\" column in the test analysis?",
        "answer" : simpleTxt2Html(answer91)
    },
    {
        "categoryOrder" : 14,
        "category" : "Taking and Reviewing Practice Tests",
        "questionOrder" : 1,
        "question" : "How do students take practice ACT and SAT tests through Winward Academy?",
        "answer" : simpleTxt2Html(answer92)
    },
    {
        "categoryOrder" : 14,
        "category" : "Taking and Reviewing Practice Tests",
        "questionOrder" : 2,
        "question" : "Can students take a practice test on their own?",
        "answer" : simpleTxt2Html(answer93)
    },
    {
        "categoryOrder" : 14,
        "category" : "Taking and Reviewing Practice Tests",
        "questionOrder" : 3,
        "question" : "Can students take part of an exam instead of the whole thing?",
        "answer" : simpleTxt2Html(answer94)
    },
    {
        "categoryOrder" : 14,
        "category" : "Taking and Reviewing Practice Tests",
        "questionOrder" : 4,
        "question" : "What is the turnaround time for practice exams to be uploaded in the Winward Academy platform?",
        "answer" : simpleTxt2Html(answer95)
    },
    {
        "categoryOrder" : 14,
        "category" : "Taking and Reviewing Practice Tests",
        "questionOrder" : 5,
        "question" : "Is there support available from the Winward Academy team to analyze student lesson and practice test data?",
        "answer" : simpleTxt2Html(answer96)
    },
    {
        "categoryOrder" : 14,
        "category" : "Taking and Reviewing Practice Tests",
        "questionOrder" : 6,
        "question" : "Are supplemental practice problems available to print?",
        "answer" : simpleTxt2Html(answer97)
    },
    {
        "categoryOrder" : 14,
        "category" : "Taking and Reviewing Practice Tests",
        "questionOrder" : 7,
        "question" : "Is the frequency a topic is tested visible when students are doing corrections to a practice test?",
        "answer" : simpleTxt2Html(answer98)
    },
    {
        "categoryOrder" : 14,
        "category" : "Taking and Reviewing Practice Tests",
        "questionOrder" : 8,
        "question" : "What do you suggest for productive reflection after students take a real exam?",
        "answer" : simpleTxt2Html(answer99)
    },
    {
        "categoryOrder" : 15,
        "category" : "Administering Interim Assessments",
        "questionOrder" : 1,
        "question" : "Are all of a school's Interim Assessments in the platform?",
        "answer" : simpleTxt2Html(answer100)
    },
    {
        "categoryOrder" : 15,
        "category" : "Administering Interim Assessments",
        "questionOrder" : 2,
        "question" : "What is the process to administer an interim assessment?",
        "answer" : simpleTxt2Html(answer101)
    },
    {
        "categoryOrder" : 15,
        "category" : "Administering Interim Assessments",
        "questionOrder" : 3,
        "question" : "When students are taking practice tests, is it better to administer in one session or to break it up over multiple days?",
        "answer" : simpleTxt2Html(answer102)
    },
    {
        "categoryOrder" : 16,
        "category" : "Integrating with Illuminate",
        "questionOrder" : 1,
        "question" : "How do school administrators or teachers move practice test data from Illuminate into Winward Academy?",
        "answer" : simpleTxt2Html(answer103)
    },
    {
        "categoryOrder" : 16,
        "category" : "Integrating with Illuminate",
        "questionOrder" : 2,
        "question" : "How do school administrators or teachers move Winward Academy lesson data into Illuminate?",
        "answer" : simpleTxt2Html(answer104)
    },
    {
        "categoryOrder" : 16,
        "category" : "Integrating with Illuminate",
        "questionOrder" : 3,
        "question" : "How do we find the Winward Academy assessments in Illuminate?",
        "answer" : simpleTxt2Html(answer105)
    },
    {
        "categoryOrder" : 16,
        "category" : "Integrating with Illuminate",
        "questionOrder" : 4,
        "question" : "How long does it take for the Winward team to get matrix report data into the students' Winward Academy accounts?",
        "answer" : simpleTxt2Html(answer106)
    },
    {
        "categoryOrder" : 17,
        "category" : "Troubleshooting Login Issues",
        "questionOrder" : 1,
        "question" : "What if a student is having trouble receiving the verification email?",
        "answer" : simpleTxt2Html(answer107)
    },
    {
        "categoryOrder" : 17,
        "category" : "Troubleshooting Login Issues",
        "questionOrder" : 2,
        "question" : "What if students forget their passwords?",
        "answer" : simpleTxt2Html(answer108)
    },
]
