import { UserInfo } from './account-rest-interface';

export function hasLessons(user: UserInfo): boolean {
    if  (user.bundleId !== 'collegeKnowledge' && user.bundleId !== 'SchoolSafetyAndSecurity') {
        return true;
    } else {
        return false;
    }
}

export function hasCollegeKnowledge(user: UserInfo): boolean {
    if (user.bundleId === 'collegeKnowledge' || user.ckPermission) {
        return true;
    } else {
        return false;
    }
}

export function hasSchoolSafety(user: UserInfo): boolean {
    let bundleId = user.bundleId
    let isSSS = bundleId === "sss" || bundleId === "schoolSafetySecurity"

    return isSSS
}

/**
 * Finds out if a user (a teacher) has optional Mindset Moments (Resilience Reminders) lessons
 * 
 * @param userInfo 
 * @returns 
 */
export function hasResilienceReminders(userInfo: UserInfo): boolean {
    let rrFound = userInfo.resilienceReminders && userInfo.resilienceReminders !== "" || false
    return rrFound
}
