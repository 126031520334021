import './ProgressCircle.css';

import * as React from 'react';

interface Props {
    img: string;
    title: string;
    progress: number;
}

export const ProgressCircle = (props: Props) => {

    return (
        <div className="text-center">
            <div className="circle-container">
                <div className="circle-img">
                    <img alt="" src={props.img} />
                    </div>
                <div className="circle-progress">
                    {props.progress}
                </div>
            </div>
            <div className="circle-title">{props.title}</div>
        </div>
    );
};
