// Data for the various videos here (we will add as needed)

export const demos = [
  {
    "videoName": "Welcome to Winward Academy",
    "videoId": "6135199401001"
  },
  // {
  //   "videoName": "Lessons and Multiple Choice Demo",
  //   "videoId": "6135199401001"
  // },
  // {
  //   "videoName": "Mistake Bank Demo",
  //   "videoId": "6135199401001"
  // },
  // {
  //   "videoName": "Stay Sharp Demo",
  //   "videoId": "6135199401001"
  // },
  {
    "videoName": "Practice Tests Demo",
    "videoId": "6135198133001" //"6135199401001" //6135198133001
  }
]

export const webinars = [
  {
    "videoName": "ACT vs New Digital SAT",
    "videoId": "6319660123112"
  },
  {
    "videoName": "Navigating the New Digital SAT",
    "videoId": "6340630729112"
  },
  {
    "videoName": "Dos and Don'ts of College Applications",
    "videoId": "6328985336112"
  },
  {
    "videoName": "Cultivating a Positive Mindset",
    "videoId": "6348395058112"
  },
  {
    "videoName": "Tips to Prepare for College",
    "videoId": "6345198809112"
  },
]

export const mindfulmoments = [
  {
    "videoName": "Stretches for Studying",
    "videoId": "5448833959001"
  },
  {
    "videoName": "Tips when Struggling with Stress",
    "videoId": "5732528354001"
  },
  {
    "videoName": "Managing Anxiety During Exams",
    "videoId": "5732544182001"
  },
  {
    "videoName": "Benefits of Sleep",
    "videoId": "5732528349001"
  },
  {
    "videoName": "Healthy Adolescent Brain Development",
    "videoId": "5448810782001"
  },
  {
    "videoName": "Technology and the Teenage Brain",
    "videoId": "5732560142001"
  },
  {
    "videoName": "Smartphones and Sleep in Adolescence",
    "videoId": "5732528869001"
  },
  {
    "videoName": "Anxiety and the Teenage Brain",
    "videoId": "5448985972001"
  },
  {
    "videoName": "The Basics of Sleep",
    "videoId": "5448811121001"
  },
  {
    "videoName": "Sleep Management Before Exams",
    "videoId": "5732560133001"
  },

  {
    "videoName": "Reviewing vs Relearning: Tips for Better Studying",
    "videoId": "5732528882001"
  },

  {
    "videoName": "Adolescent Brain Development",
    "videoId": "5732515468001"
  },
]
