import React, { Component } from 'react'
import RadialProgressEx from '../../../components/RadialProgress/RadialProgressEx'
import { getHumanTime } from '../../../services/utils/date-util'
import { ClassSummary } from './top-lesson-processor'

interface Props {
  category: string
  data: ClassSummary
  selectedTestKind: string
}

interface State {

}

const colorMap = {
  English: { start: "#9453bf", end: "#5e3468", title: '#9d59cf'},
  Math:    { start: "#88e5f9", end: "#3FC3D1", title: "#3FC3D1"},
  Reading: { start: "#f8cd8b", end: "#F49000", title: "#F49000"},
  Science: { start: "#c3f081", end: "#8AC22C", title: "#8AC22C"}
}

export default class CategoricalSummary extends Component<Props, State> {
  render() {
    let NA = "N/A"
    let {category, data, selectedTestKind} = this.props
    let {nofLessonsStarted, nofLessonsCompleted, nofLessonsContinued, totalTimeOnQuestions, avgTimePerQuestions} = data
    let totalTimeInfo = totalTimeOnQuestions > 0 ? getHumanTime(totalTimeOnQuestions, false, true).text: NA  // Includes baselines, videos & MCs
    let avgTimeInfo = avgTimePerQuestions > 0 ? getHumanTime(avgTimePerQuestions, true, true).text: NA
    let colors = colorMap[category]
    let startColor = colors.start 
    let endColor = colors.end 
    let titleColor = colors.title
    let scoreACT = data.postLessonConversionScoreACT
    let scoreSAT = data.postLessonConversionScoreSAT
    let showACT = selectedTestKind == "ACT" ? true: false
    let score = showACT ? scoreACT: scoreSAT
    let conversionInfo = `${showACT ? "ACT": "SAT"} Score Equivalent`

    return (
      <div className="categorical-summary">
        <div className="categorical-summary-title">{category}</div>
        <div className="row">
          <div className="cat-sum-chart-col p-rel pr-2 col right-border-divider" style={{borderRightColor: startColor}}>
            <div className="row mb-4">
              <div className="col">
                Class Accuracy
              </div>
            </div>
            <div className="row progress-info">
              <div className="col radial-progress">                
                <RadialProgressEx 
                  startColor={startColor}
                  endColor={endColor}
                  progress={data.postLessonAccuracy} 
                  text={data.postLessonAccuracy+"%"}
                  textSytle={{fontSize: "24px", fontWeight: 700}}
                  progressLineWidth={7}
                  backgroundLineWidth={11}
                  />
                <div className="act-sat-info">
                  <b className="statVal">{score}</b>
                  <br/>
                  {conversionInfo}
                </div>
              </div>
            </div>
            <div className="lessons-data">
              <table>
              <tr>
                  <td className="pr-2 statVal--cell">
                    <b className="statVal">{nofLessonsStarted}</b>
                  </td>
                  <td>Started</td>
                </tr>
                <tr>
                  <td className="pr-2 statVal--cell">
                    <b className="statVal">{nofLessonsCompleted}</b>
                  </td>
                  <td>Completed</td>
                </tr>
                <tr>
                  <td className="pr-2 statVal--cell">
                    <b className="statVal">{nofLessonsContinued}</b>
                  </td>
                  <td>Continued</td>
                </tr>
              </table>

            </div>
          </div>
          <div className="p-rel col time-info pr-2">
            {/* <img alt="" className="time-icon" src="/assets/images/icons/timer.svg"/> */}
            <div className="time-icon" data-src="/assets/images/icons/timer.svg">
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 33 41">
                <path style={{fill: titleColor}} d="M29.9,14.6l1.8-1.8c0.3-0.4,0.3-1,0-1.4l-1.3-1.3c-0.4-0.4-0.9-0.4-1.3,0c0,0,0,0,0,0l-1.7,1.6
                  c-2.5-2.2-5.7-3.6-9-4V3.8h2.2c0.5,0,0.9-0.4,1-0.9V1c0-0.5-0.4-1-1-1h-8.3c-0.5,0-1,0.5-1,1v1.9c0,0.5,0.4,0.9,1,0.9h2.2v3.9
                  C5.5,8.8-0.9,17,0.1,26.1s9.2,15.5,18.3,14.4c9-1.1,15.5-9.2,14.4-18.3C32.5,19.5,31.5,16.9,29.9,14.6z M16.5,36.8
                  c-7,0-12.7-5.7-12.7-12.7c0-7,5.7-12.7,12.7-12.7c7,0,12.7,5.7,12.7,12.7c0,0,0,0,0,0C29.2,31.2,23.5,36.8,16.5,36.8z M17.5,28h-1.9
                  c-0.6,0-1-0.4-1-1V16.2c0-0.5,0.5-1,1-1h1.9c0.5,0,0.9,0.4,1,1V27C18.4,27.5,17.9,27.9,17.5,28L17.5,28z"/>
                </svg>              
            </div>

            <div className="total-time-info">
              <div dangerouslySetInnerHTML={{__html: '<span class="statVal">' + totalTimeInfo + '</span>'}}/>
              Total Time <br/>On Lessons
            </div>

            <div className="avg-time-info">
              <div dangerouslySetInnerHTML={{__html: '<span class="statVal">' + avgTimeInfo + '</span>'}}/>
              Avg Time <br/>Per Question
            </div>
          </div>
        </div>
      </div>
    )
  }
}

