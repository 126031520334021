import { ERR_CANNOT_GET_DATA_FROM_SERVER } from '../../constants'
import { createGenericAuthRequest } from '../request/request-utils'
import { getCachedValue, setCacheValue } from '../utils/cache-util'
const baseUrl1 = process.env.REACT_APP_ACCOUNT_URL || '/'
const baseUrl2 = process.env.REACT_APP_ELEARN_URL || '/'
const request1 = createGenericAuthRequest(baseUrl1)
const request2 = createGenericAuthRequest(baseUrl2)

/**
 * Get all time on platform info for the teacher's students.
 * @returns time on platform info
 */
export function getAllStudentsTimeOnPlatformInfo() {
  return request2(`/teacher/timeonplatform/students`);
}

/**
 * Get all lessons for the teacher's students.
 * @returns student lessons info
 */
export function getAllStudentsLessonsInfo() {
  return request2(`/teacher/allstudentlessoninfo`);
}


/**
 * Gets current student's TonP info.
 */
export function getStudentTonPInfo() {
  return request2(`/usermaterial/timeonplatform/student`);
}

/**
 * Get cached TonP data for the student
 * @returns 
 */
export async function getStudentTonPInfoCached() {
  let cacheKey = `student-tonp-info`
  let data = getCachedValue(cacheKey)
  
  if (!data) {
    try {
      data = await getStudentTonPInfo()
      setCacheValue(cacheKey, data)
    }
    catch (ex) {
      console.error(ERR_CANNOT_GET_DATA_FROM_SERVER, ex)
    }
  }

  return data
}