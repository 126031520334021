import './LessonTabs.css';

import * as React from 'react';

import { CustomHeaderContent } from '../../../../components/Tabs/Tabs';
import { LessonView } from '../LessonViewSwitch/LessonViewSwitch';
import LessonViewSwitch from '../LessonViewSwitch/LessonViewSwitchContainer';
import Tabs from '../../../../components/Tabs/Tabs';

interface Props {
    onViewChange: (view: LessonView) => void;
    selectedView: LessonView;
    openEnglishModal: () => void;
    openMathModal: () => void;
    openReadingModal: () => void;
    openScienceModal: () => void;
    changeFrequency: (testType: string) => void;
    isTeacher: boolean;
    selectedFrequency?: string;
    isSSS: boolean;
}

const LessonTabs: React.SFC<Props> = (props) => {
    return (
        <Tabs>
            {props.children}
            <CustomHeaderContent>
                {!props.isSSS &&
                <LessonViewSwitch
                    selectedView={props.selectedView}
                    onViewChange={props.onViewChange}
                    openEnglishModal={props.openEnglishModal}
                    openMathModal={props.openMathModal}
                    openReadingModal={props.openReadingModal}
                    openScienceModal={props.openScienceModal}
                    changeFrequency={props.changeFrequency}
                    isTeacher={props.isTeacher}
                    selectedFrequency={props.selectedFrequency}
                />
                }
            </CustomHeaderContent>
        </Tabs>
    );
};

LessonTabs.displayName = 'LessonTabs';
export default LessonTabs;
