import './Loading.css';

import * as React from 'react';

interface Props {
    className?: string;
}

const Loading: React.SFC<Props> = props => {
    const { className = '' } = props;
    return (
        <div className={`hidenoanim loading ${className}`}>
            <img alt="loading" src={'/assets/images/filament-loading-transparent-bkgd.gif'} />
        </div>
    );
};

Loading.defaultProps = {};
Loading.displayName = 'Loading';

export default Loading;
