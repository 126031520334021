
/**
 * Very simple timer to be used to keep track of various activities.
 * Note that this doesn't have any associated UI for it and should
 * be utilized in simple use cases only.
 */
export class SimpleTimer {
  startedTime: number = Date.now()

  constructor() {
  }

  start() {
    this.startedTime = Date.now()
  }

  reset() {
    this.startedTime = Date.now()
  }

  diffInMilliSeconds() {
    return Date.now() - this.startedTime
  }

  diffInSeconds() {
    return Math.round(this.diffInMilliSeconds()/1000)
  }
}