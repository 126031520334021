import React, { Component } from 'react'
import "./CircularPercentile.css"

interface Props {
  percentile?: string
  footerText?: string
}

interface State {}

export default class CircularPercentile extends Component<Props, State> {
  render() {
    let { percentile, footerText } = this.props    
    let numberInfo =  percentile?.slice(0, -2)
    let ordinalInfo = percentile?.slice(-2)

    return (
      <div className="circular-percentile-container">
        <div className="circular-percentile">
          <div className="circular-percentile-text">
            {numberInfo}<sup className="number-suffix">{ordinalInfo}</sup>
          </div>
        </div>
        <div className="footer-text">{footerText}</div>
      </div>
    )
  }
}
