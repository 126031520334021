import './MindfulCard.css';

import * as React from 'react';

import BrightcoveVideo from '../../../../components/BrightcoveVideo/BrightcoveVideo';
import Button from '../../../../components/Button/Button';
import Card from '../../../../components/Card/Card';

interface Props {
    containerClass?: string;
}

const MindfulCard: React.SFC<Props> = (props: Props) => {

    const btnStyle = {width: '100%'};
    const player = {
            width: '100%',
            maxWidth: '100%',
            height: 'auto'
        };
    return (
        <div className={'dash-mindful-container ' + (props.containerClass || '')}>
            <div className="dash-mindful-container__bg">
                <Card title="Mindful Moments Topical Videos" titleColor="white" link="" classTitle="mindful-header">
                    <div className="title-border"></div>
                    <div className="mindful-container">
                        <div>
                            <p className="mindful-text">Stretches for Studying</p>
                        </div>
                        <div className="video">
                            <BrightcoveVideo id="bc-video-test" videoId="5448833959001" style={player}/>
                        </div>
                        <Button
                            text="VIEW ALL"
                            textColor="purple"
                            bgColor="white"
                            titleAttr="View Mindful Moments on WinwardAcademy.com, opens in a new window"
                            style={btnStyle}
                            onClick={() => window.open('http://winwardacademy.com/mindful-moments/')}
                        />
                    </div>
                </Card>
            </div>
        </div>
    );
};

MindfulCard.displayName = 'MindfulCard';

export default MindfulCard;
