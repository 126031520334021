import React from "react"
import DataGrid from "../../../components/DataGrid"
import { Column } from "../../../components/DataGrid/types/types"
import CollapsableCard, { CollapsableCardBody, CollapsableCardHeader } from "../../../components/CollapsableCard/CollapsableCard"
import { alphanumericCompare } from "../../../services/utils/sort-util"
import { ResilienceReminders } from "."
import { IResilienceReminderGrade, IResilienceReminderWeeklyContent } from "./types"

const sortMethod = alphanumericCompare

const columnsForGrades: Column[] = [
    {
        title: "WEEK",
        dataProperty: "week",
        sortable: true,
        sortMethod,
    },
    {
        title: "LESSON NAME",
        dataProperty: "lesson",
        sortable: true,
        sortMethod,
    },
    {
        title: "CATEGORY",
        dataProperty: "category",
        sortable: true,
        sortMethod,
    },
    {
        title: "LAUNCH",
        dataProperty: "launch",
        sortable: false
    },
]

export default function ResilienceRemindersGradesView(props: {grades: IResilienceReminderGrade[]}) {
    let { grades } = props

    return (
        <>
            {grades.map(gradeInfo => {
                let { grade, weeks } = gradeInfo
                let title = `Grade ${grade}`
                let iconPath = grade

                return (
                    <CollapsableInfoCardForGrades
                        name="rr-grade-section"
                        title={title}
                        collapsed={true}
                        icon={iconPath}
                        columns={columnsForGrades}
                        content={weeks}
                    />
                )
            })
        }
        </>
    )
}


function CollapsableInfoCardForGrades({ name, title, icon, ribbonColor = "darkPurple", headerColor = "#279ed7", collapsed = true, columns, content }) {
    let data = getGradesData(content)

    return (
        <CollapsableCard key={name} initiallyCollapsed={collapsed}>
            <CollapsableCardHeader>
                <div className="lesson-list-card-header">
                    <span className={`ribbon ${ribbonColor}`}>
                        <span className="grade-level">{icon}</span>
                    </span>
                    <h2 className="lesson-list-card-header--text">{title}</h2>
                </div>
            </CollapsableCardHeader>

            <CollapsableCardBody>
                <DataGrid
                    columns={columns}
                    data={data}
                    className="lesson-table grades-table"
                />
            </CollapsableCardBody>
        </CollapsableCard>
    )
}

function getLaunchButtonForWeeklyContent(weeklyContent: IResilienceReminderWeeklyContent) {
    return <ResilienceReminders.WeeklyContentLink weeklyContent={weeklyContent}/>
}

function getGradesData(weeks) {
    let data = weeks.map((w, index) => getData(w, index))
    return data
}

function getData(d, index) {
    let id = `rr-grade-data-${index}`
    let { week, name, category, link } = d
    let data = {
        week: {
            content: week,
            comparator: week,
        },
        lesson: {
            content: name,
            comparator: name,
        },
        category: {
            content: category,
            comparator: category,
        },
        launch: {
            content: getLaunchButtonForWeeklyContent(d),
        },
    }

    return {
        id,
        data
    }
}