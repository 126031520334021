import * as React from 'react';

import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import './SessionTimeoutModal.css';

interface Props {
  show: boolean;
}

interface State {
}

class SessionTimeoutModal extends React.Component<Props, State> {

    public render() {
      return (
                <Modal
                    className="session-modal session-timeout-modal"
                    show={this.props.show}
                    onClose={() => ({})}
                >
                    <div className="row text-center">
                        <div className="col-12">
                            <h3 className="session-timeout-modal__header">Login Session Problem</h3>
                            <p className="session-timeout-modal__info-text">Either your session has expired, 
                            or you are on an unreliable wifi network.
                            </p>
                            <p className="session-timeout-modal__info-text">
                              Please click below to LOGIN again, or press RELOAD to try refreshing the page.
                            </p>
                        </div>
                        <div className="col-12 session-modal-btn-bar">
                            <Button 
                                style={{ width: '40%'}}
                                text="LOGIN" 
                                link={`${process.env.REACT_APP_BASE_URL}login`} 
                                bgColor="blue" 
                            />
                            <Button 
                                text="RELOAD" 
                                style={{ width: '40%'}}
                                onClick={() => {
                                window.location.reload();
                                }
                                } 
                                bgColor="green"
                            />
                        </div>
                    </div>
                </Modal>
        );
    }

}

export default SessionTimeoutModal;
