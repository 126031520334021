import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../store';
import { Props, CollegeKnowledgeLessons } from './CollegeKnowledgeLessons';
import { updateUserCK } from '../../stores/ck.store';

const mapStateToProps = ({ account, ck }: AppState, ownProps: any) => {
    return {
      ...ownProps,
      userInfo: account.userInfo,
      ck: ck
    };
};

const mapDispatchToProps = {
    updateUserCK
};

const ConnectedCK = connect(mapStateToProps, mapDispatchToProps)(CollegeKnowledgeLessons);

export default function(props: any) {
    return <ConnectedCK {...props} />;
}
