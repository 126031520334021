import * as React from 'react';
import './index.css';

interface Props {
  category: string;
}

const ReportTableHeader: React.SFC<Props> = ({ category, children }) => {
  const categoryName = category.replace(' ', '-');
  return (
    <thead className={`report-table-header ${categoryName.toLowerCase()}`}>
      <tr>
        {children}
      </tr>
    </thead>
  );
};

export default ReportTableHeader;
