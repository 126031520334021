import * as React from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setPriority } from '../../../../stores/lesson.store';
import { AppState } from '../../../../store';
import TestCard from './TestCard';

const mapStateToProps = () => ({
});

const mapDispatchToProps = { setPriority };

const ConnectedTestCard =  withRouter(
    // This is terrible, but it was the only way...
    connect<any,any,any,any>(mapStateToProps, mapDispatchToProps)(TestCard)
);

export default (props) => {
    return (
        <ConnectedTestCard {...props} />
    );
};
