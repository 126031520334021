import './SignUp.css';

import * as React from 'react';
import * as _ from 'lodash';
import { boundMethod } from 'autobind-decorator';

import { Gender, getGenderEnum, getGenderOptions, getGenderText } from './constants/sign-up-types';

import DateInput from '../../components/DateInput/DateInput';
import SignUpError from './SignUpError';
import { Moment } from 'moment';
import ProfileColorPicker from './components/ProfileColorPicker/ProfileColorPicker';
import SelectInput from '../../components/SelectInput/SelectInput';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import TextInput from '../../components/TextInput/TextInput';

const requiredFields = [
    'firstName', 'lastName', 'password', 'confirmPassword',
    'emailAddress', 'confirmEmail', 'dob', 'gender', 'profileColor'
];

interface Props {
    // TODO: The correct way to specify this type would be to specify a union type for the signup fields:
    // eg. AccessCodeFields | StandardSignUpFields | etc 
    onChange: (value: any, isValid: boolean) => void;
    shouldValidate?: boolean;
    schoolUser?: boolean;
    name?: any;
    requireAccessCode?: boolean;
    requireHowDidYouHear?: boolean;
    selectedBundle?: any;
    gameChangerOnly?: boolean;
    satGameChanger?: boolean;
    gameChangerPersonalCoach?: boolean;
    gameChangerStudyPlan?: boolean;
    gameChangerCollegeKnowledge?: boolean;
}

export interface SignUpFields {
    firstName: string;
    lastName: string;
    emailAddress: string;
    confirmEmail: string;
    password: string;
    confirmPassword: string;
    dob: Date;
    gender: Gender;
    profileColor: string;
    accessCode?: string;
    howDidYouHear?: string;
    parentFirstName: string;
    parentLastName: string;
    parentEmailAddress: string;
}

interface State {
    firstName: string;
    lastName: string;
    emailAddress: string;
    confirmEmail: string;
    password: string;
    confirmPassword: string;
    profileColor: string;
    gender: Gender;

    accessCode: string;
    howDidYouHear: string;
    dob?: Date;
    dobInvalid: boolean;
    agreedToTermsAndConditions: boolean;
    isCapsLockOn: boolean;
    serverMessage: string;
    showAccessCodeHelp: boolean;
    parentFirstName?: string;
    parentLastName?: string;
    parentEmailAddress?: string;
    confirmParentEmail?: string;

}


export default class SignUpForm extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        const initializeName = this.props.schoolUser && this.props.name !== undefined;
        const initFirst = initializeName ? this.props.name.firstName : '';
        const initLast =  initializeName ? this.props.name.lastName : '';
        this.state = {
            firstName: initFirst,
            lastName: initLast,
            emailAddress: '',
            confirmEmail: '',

            password: '',
            confirmPassword: '',
            gender: Gender.NotSet,
            profileColor: '',
            howDidYouHear: '',

            accessCode: '',
            showAccessCodeHelp: false,
            agreedToTermsAndConditions: false,
            dobInvalid: true,
            isCapsLockOn: false,
            serverMessage: '',
            parentFirstName: '',
            parentLastName: '',
            parentEmailAddress: '',
            confirmParentEmail: ''
        };

    }

    getInvalidStatus(elementId: string) {
        if (this.props.shouldValidate) {
          if (elementId === 'gender') {
              return this.state[elementId] === Gender.NotSet;
          } else if (elementId === 'confirmPassword') {
              return !this.passwordsMatch();
          } else if (elementId === 'confirmEmail') {
              return !this.emailsMatch();
          } else if (elementId === 'confirmParentEmail') {
            return !this.parentEmailsMatch();
          } else if (elementId === 'dob') {
              const dobValue = this.state[elementId];
              return this.state.dobInvalid || !_.isDate(dobValue);
          } else {
              return !_.isUndefined(this.state[elementId]) &&
                  this.state[elementId].length === 0;
          }
        } else {
            return false;
        }
    }

    getFeedback(elementId: string) {
        // TODO: clean this up a bit.
        const fieldValue = this.state[elementId];
        if (elementId === 'dob') {
            const required = this.props.shouldValidate && !_.isDate(fieldValue);
            return required ? 'Required' : '';
        } else if (elementId === 'gender') {
            return this.props.shouldValidate && this.state[elementId] === Gender.NotSet ? 'Required' : '';
        }
        const empty = this.props.shouldValidate && _.isEmpty(fieldValue);

        if (empty && elementId !== 'parentEmailAddress' && elementId !== 'confirmParentEmail') {
            return 'Required';
        } else if (elementId === 'confirmEmail' && this.props.shouldValidate) {
            return !this.emailsMatch() ? 'Emails do not match' : '';
        } else if (elementId === 'confirmParentEmail' && this.props.shouldValidate) {
            return !this.parentEmailsMatch() ? 'Parent emails do not match' : '';
        } else if (elementId === 'confirmPassword' && this.props.shouldValidate) {
            return !this.passwordsMatch() ? 'Passwords do not match' : '';
        } else if (elementId === 'emailAddress' && this.props.shouldValidate) {
            return !this.emailIsValid(fieldValue) ? 'Invalid email' : '';
        } else {
            if (elementId !== 'parentEmailAddress') {
                return '';
            } else {
                return undefined;
            }
        }
    }

    public render() {
        const inputProps = {
            size: 'lg' as 'lg'
        };
        const smallInputProps = {
            ...inputProps,
            formGroupClass: 'col-lg-6'
        };
        const genderOptions = getGenderOptions();

        let imagePath;
        if (this.props.selectedBundle) {
          if (
              this.props.selectedBundle.bundleId &&
              (this.props.selectedBundle.bundleId === 'mathOnly' ||
              this.props.selectedBundle.bundleId === 'collegeKnowledge')
            ) {
              imagePath = `/assets/images/bundle-icons/${this.props.selectedBundle.bundleId}.svg`;
          } else {
              imagePath = `/assets/images/bundle-icons/${this.props.selectedBundle.bundleId}.png`;
          }
        }

        return (
            <div>
                { !this.props.schoolUser &&
                <div className="row">
                    <TextInput
                        id="first-name"
                        labelText="First Name"
                        invalid={this.getInvalidStatus('firstName')}
                        feedbackText={this.getFeedback('firstName')}
                        value={this.state.firstName}
                        onChange={(e) => this.onTextInputChange('firstName', e)}
                        {...smallInputProps}
                    />
                    <TextInput
                        id="last-name"
                        labelText="Last Name"
                        invalid={this.getInvalidStatus('lastName')}
                        feedbackText={this.getFeedback('lastName')}
                        value={this.state.lastName}
                        onChange={(e) => this.onTextInputChange('lastName', e)}
                        {...smallInputProps}
                    />
                </div>
                }

                { !this.props.schoolUser &&
                <div className="row">
                    <DateInput
                        date={this.state.dob}
                        onDateChange={this.onDobChange}
                        onDateValidation={(invalid, message) => {
                            this.setState({ dobInvalid: invalid },
                                () => this.formChange());
                        }}
                        invalid={this.getInvalidStatus('dob')}
                        feedbackText={this.getFeedback('dob')}
                        formGroupClass="col-lg-6 col-sm-12"
                        labelText="Date of birth"
                    />

                    <SelectInput
                        id="gender"
                        labelText="Gender"
                        value={getGenderText(this.state.gender || Gender.NotSet)}
                        invalid={this.getInvalidStatus('gender')}
                        feedbackText={this.getFeedback('gender')}
                        onChange={this.onGenderChange}
                        options={genderOptions}
                        {...smallInputProps}
                    />
                </div>
                }

                <div className="row">
                    <div className="form-group col-12">
                        <label className="text-input-label">Pick a profile color</label>
                        <ProfileColorPicker
                            initials={this.state.firstName &&
                                [this.state.firstName.charAt(0).toUpperCase(),
                                this.state.lastName.charAt(0).toUpperCase()].join('')}
                            onColorChange={this.onProfileColorChange}
                        />
                    </div>
                </div>

                { !this.props.schoolUser &&
                <div>
                    <TextInput
                        id="email"
                        labelText="Enter your email"
                        inputType="email"
                        invalid={this.getInvalidStatus('emailAddress')}
                        feedbackText={this.getFeedback('emailAddress')}
                        value={this.state.emailAddress}
                        onChange={e => this.onTextInputChange('emailAddress', e)}
                        {...inputProps}
                    />
                    <TextInput
                        id="email-confirm"
                        labelText="Re-enter your email"
                        inputType="email"
                        invalid={this.getInvalidStatus('confirmEmail')}
                        feedbackText={this.getFeedback('confirmEmail')}
                        value={this.state.confirmEmail}
                        onChange={e => this.onTextInputChange('confirmEmail', e)}
                        {...inputProps}
                    />
                </div>
                }

                <div className="row password-row">
                    <TextInput
                        id="password"
                        labelText="Password"
                        inputType="password"
                        invalid={this.getInvalidStatus('password')}
                        feedbackText={this.getFeedback('password')}
                        value={this.state.password}
                        onChange={e => this.onTextInputChange('password', e)}
                        onKeyUp={e => this.onPasswordChange(e)}
                        {...smallInputProps}
                    />

                    <TextInput
                        id="password-confirm"
                        labelText="Re-enter password"
                        inputType="password"
                        invalid={this.getInvalidStatus('confirmPassword')}
                        feedbackText={this.getFeedback('confirmPassword')}
                        value={this.state.confirmPassword}
                        onChange={e => this.onTextInputChange('confirmPassword', e)}
                        onKeyUp={e => this.onPasswordChange(e)}
                        {...smallInputProps}
                    />
                </div>

                {this.props.requireAccessCode &&
                <div className="">
                    <TextInput
                        id="access-code"
                        labelText="Access Code"
                        invalid={this.getInvalidStatus('accessCode')}
                        feedbackText={this.getFeedback('accessCode')}
                        value={this.state.accessCode}
                        onChange={e => this.onTextInputChange('accessCode', e)}
                        imageSource="/assets/images/icons/progress/grey-question.svg"
                        onImageClick={() => {
                        //   console.log('TODO: show access code copy');
                          this.setState({ showAccessCodeHelp: !this.state.showAccessCodeHelp });
                        }}
                        showAccessCode={this.state.showAccessCodeHelp}
                        {...inputProps}
                    />
                </div>
                }

                {this.state.isCapsLockOn &&
                  <div className="row">
                    <div className="col-12 text-center">
                      <p className="caps-lock-message">Did you mean for Caps Lock to be on?</p>
                    </div>
                  </div>
                }

                {this.props.requireHowDidYouHear &&
                <div className="">
                    <TextInput
                        id="how-did-you-hear"
                        labelText="How did you hear about us?"
                        invalid={this.getInvalidStatus('howDidYouHear')}
                        feedbackText={this.getFeedback('howDidYouHear')}
                        value={this.state.howDidYouHear}
                        onChange={e => this.onTextInputChange('howDidYouHear', e)}
                        // imageSource="/assets/images/icons/progress/grey-question.svg"
                        {...inputProps}
                    />
                </div>
                }
                 {this.props.requireHowDidYouHear &&
                    <div>
                        <h2 className="text-left margin-bottom-20">Step 2: Parent Information (for students under 18)</h2>
                        <TextInput
                            id="parentFirstName"
                            labelText="Parent's First Name"
                            inputType="text"
                            value={this.state.parentFirstName || ''}
                            onChange={e => this.onTextInputChange('parentFirstName', e)}
                            {...inputProps}
                        />
                        <TextInput
                            id="parentLastName"
                            labelText="Parent's Last Name"
                            inputType="text"
                            value={this.state.parentLastName || ''}
                            onChange={e => this.onTextInputChange('parentLastName', e)}
                            {...inputProps}
                        />
                        <TextInput
                            id="parentEmailAddress"
                            labelText="Parent's Email Address"
                            inputType="text"
                            value={this.state.parentEmailAddress || ''}
                            onChange={e => this.onTextInputChange('parentEmailAddress', e)}
                            {...inputProps}
                        />
                        <TextInput
                            id="confirmParentEmail"
                            labelText="Re-enter Parent's Email Address"
                            inputType="text"
                            invalid={this.getInvalidStatus('confirmParentEmail')}
                            feedbackText={this.getFeedback('confirmParentEmail')}
                            value={this.state.confirmParentEmail || ''}
                            onChange={e => this.onTextInputChange('confirmParentEmail', e)}
                            {...inputProps}
                        />
                    </div>
                }
                {
                  !this.props.requireAccessCode &&
                  !this.props.requireHowDidYouHear &&
                  (this.props.selectedBundle || this.props.gameChangerOnly ||
                    this.props.satGameChanger || 
                    this.props.gameChangerPersonalCoach ||
                    this.props.gameChangerStudyPlan ||
                    this.props.gameChangerCollegeKnowledge) &&
                    <div className="row order-summary-section">
                        <div className="col-12 text-center">
                            {this.props.requireHowDidYouHear &&
                                <h2 className="text-left margin-bottom-20">Step 3: Review Order Summary</h2>
                            }
                            {!this.props.requireHowDidYouHear &&
                                <h2 className="text-left margin-bottom-20">Step 2: Review Order Summary</h2>
                            }
                            <table className="table table-bordered">
                                <tbody>
                                        {this.props.gameChangerOnly && (
                                            <tr>
                                                <td className="d-none d-sm-table-cell image-cell">
                                                    <img
                                                        src={`/assets/images/bundle-icons/gameChanger.png`}
                                                        alt="Game Changer Bundle"
                                                        className="bundle-image"
                                                    />
                                                </td>
                                                <td className="text-left order-summary-cell-content">
                                                    <div className="bundle-text d-flex flex-column">
                                                        <img
                                                            src={`/assets/images/bundle-icons/gameChanger.png`}
                                                            alt="Game Changer for ACT"
                                                            className="bundle-image d-block d-sm-none no-border-right"
                                                        />
                                                        <p className="d-flex bundle-text-copy">Game Changer for ACT</p>
                                                        <p className="d-flex bundle-price">$499.99</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {this.props.gameChangerPersonalCoach && !this.props.gameChangerOnly && (
                                            <tr>
                                                <td className="d-none d-sm-table-cell image-cell">
                                                    <img
                                                        src={`/assets/images/bundle-icons/gameChangerPersonalCoach.png`}
                                                        alt="Game Changer + Personal Coach"
                                                        className="bundle-image"
                                                    />
                                                </td>
                                                <td className="text-left order-summary-cell-content">
                                                    <div className="bundle-text d-flex flex-column">
                                                        <img
                                                            src={`/assets/images/bundle-icons/gameChangerPersonalCoach.png`}
                                                            alt="bundle"
                                                            className="bundle-image d-block d-sm-none no-border-right"
                                                        />
                                                        <p className="d-flex bundle-text-copy">Game Changer + Personal Coach</p>
                                                        <p className="d-flex bundle-price">$2499.99</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {this.props.gameChangerStudyPlan && !this.props.gameChangerOnly && (
                                            <tr>
                                                <td className="d-none d-sm-table-cell image-cell">
                                                    <img
                                                        src={`/assets/images/bundle-icons/gameChangerStudyPlan.png`}
                                                        alt="Game Changer + Study Plan"
                                                        className="bundle-image"
                                                    />
                                                </td>
                                                <td className="text-left order-summary-cell-content">
                                                    <div className="bundle-text d-flex flex-column">
                                                        <img
                                                            src={`/assets/images/bundle-icons/gameChangerStudyPlan.png`}
                                                            alt="bundle"
                                                            className="bundle-image d-block d-sm-none no-border-right"
                                                        />
                                                        <p className="d-flex bundle-text-copy">Game Changer + Study Plan</p>
                                                        <p className="d-flex bundle-price">$1499.99</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {this.props.gameChangerCollegeKnowledge && !this.props.gameChangerOnly && (
                                            <tr>
                                                <td className="d-none d-sm-table-cell image-cell">
                                                    <img
                                                        src={`/assets/images/bundle-icons/gameChangerCollegeKnowledge.svg`}
                                                        alt="bundle"
                                                        className="bundle-image gcck-bundle"
                                                    />
                                                </td>
                                                <td className="text-left order-summary-cell-content">
                                                    <div className="bundle-text d-flex flex-column">
                                                        <img
                                                            src={`/assets/images/bundle-icons/gameChangerCollegeKnowledge.svg`}
                                                            alt="bundle"
                                                            className="bundle-image d-block d-sm-none no-border-right"
                                                        />
                                                        <p className="d-flex bundle-text-copy">Game Changer + College Knowledge</p>
                                                        <p className="d-flex bundle-price">$749.99</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}                                        
                                        {this.props.satGameChanger && (
                                            <tr>
                                                <td className="d-none d-sm-table-cell image-cell">
                                                    <img
                                                        src={`/assets/images/bundle-icons/gameChanger.png`}
                                                        alt="bundle"
                                                        className="bundle-image"
                                                    />
                                                </td>
                                                <td className="text-left order-summary-cell-content">
                                                    <div className="bundle-text d-flex flex-column">
                                                        <img
                                                            src={`/assets/images/bundle-icons/gameChanger.png`}
                                                            alt="bundle"
                                                            className="bundle-image d-block d-sm-none no-border-right"
                                                        />
                                                        <p className="d-flex bundle-text-copy">Game Changer for SAT</p>
                                                        <p className="d-flex bundle-price">$499.99</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {this.props.selectedBundle.bundleId === 'gameChanger' &&
                                        !this.props.satGameChanger &&
                                        !this.props.gameChangerOnly &&
                                        !this.props.gameChangerPersonalCoach &&
                                        !this.props.gameChangerStudyPlan &&
                                        !this.props.gameChangerCollegeKnowledge && (
                                            <tr>
                                                <td className="d-none d-sm-table-cell image-cell">
                                                    <img
                                                        src={imagePath}
                                                        alt="bundle"
                                                        className="bundle-image"
                                                    />
                                                </td>
                                                <td className="text-left order-summary-cell-content">
                                                    <div className="bundle-text d-flex flex-column">
                                                        <img
                                                            src={imagePath}
                                                            alt="bundle"
                                                            className="bundle-image d-block d-sm-none no-border-right"
                                                        />
                                                        <p className="d-flex bundle-text-copy">Game Changer</p>
                                                        <p className="d-flex bundle-price">$499.99</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {(!this.props.gameChangerOnly &&
                                        !this.props.satGameChanger &&
                                        !this.props.gameChangerPersonalCoach &&
                                        !this.props.gameChangerCollegeKnowledge &&
                                        this.props.selectedBundle.bundleId !== 'gameChanger') &&
                                        (
                                            <tr>
                                                <td className="d-none d-sm-table-cell image-cell">
                                                    <img
                                                        src={imagePath}
                                                        alt="bundle"
                                                        className="bundle-image"
                                                    />
                                                </td>
                                                <td className="text-left order-summary-cell-content">
                                                    <div className="bundle-text d-flex flex-column">
                                                        <img
                                                            src={imagePath}
                                                            alt="bundle"
                                                            className="bundle-image d-block d-sm-none no-border-right"
                                                        />
                                                        <p className="bundle-text-copy">{this.props.selectedBundle.bundleName}</p>
                                                        <p className="bundle-price">${this.props.selectedBundle.price}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

                <TermsAndConditions
                    checked={this.state.agreedToTermsAndConditions}
                    onCheck={(e) => this.setState({
                            agreedToTermsAndConditions: e.target.checked
                        }, () => this.formChange())
                    }
                />
                {this.getInvalidStatus('email') &&
                    this.props.shouldValidate &&
                    <SignUpError text="Invalid Email" />
                }

                {/* Remember email confirmation */}
                {false && this.props.shouldValidate &&
                    <SignUpError text="Invalid email confirmation" />
                }

                {!this.state.agreedToTermsAndConditions &&
                    this.props.shouldValidate  &&
                    <SignUpError text="Please agree to the Terms & Conditions and Privacy Policy" />
                }
                {this.state.serverMessage &&
                    <SignUpError text={this.state.serverMessage} />
                }

                {this.props.shouldValidate && !this.formIsValid() &&
                    <SignUpError text="Please scroll up and fix information" />
                }
            </div>
        );
    }


    private emailIsValid(email: string) {
        // regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
        // tslint:disable-next-line:max-line-length
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    @boundMethod
    private emailsMatch() {
        return this.state.emailAddress === this.state.confirmEmail;
    }

    @boundMethod
    private parentEmailsMatch() {
        return this.state.parentEmailAddress === this.state.confirmParentEmail;
    }

    @boundMethod
    private passwordsMatch() {
        return this.state.password === this.state.confirmPassword;
    }

    @boundMethod
    private collectRequiredFields() {
        if (this.props.schoolUser) {
          return _.pick(this.state, [
              'password', 'confirmPassword', 'profileColor'
            ]);
        } else if (this.props.requireAccessCode) {
            const newFields = requiredFields.concat(['accessCode']);
            return _.pick(this.state, newFields);
        } else if (this.props.requireHowDidYouHear) {
            const newFields = requiredFields.concat(['howDidYouHear']);
            return _.pick(this.state, newFields);
        }
        return _.pick(this.state, requiredFields);
    }

    @boundMethod
    private formIsValid() {
        const values = this.collectRequiredFields();
        const required = _
            .keys(values)
            .map(key => {
                const value = values[key];

                let isValid = false;
                if (key === 'gender') {
                    isValid = value > 0;
                } else if (_.isDate(value)) {
                    isValid = true;
                } else {
                    isValid = value.length > 0;
                }
                return { key, value, isValid };
            })
            .reduce((acc, tuple) => {
                return acc && tuple.isValid;
            }, true);

        const validatedEmails = this.props.schoolUser ||
            (this.emailsMatch() && this.emailIsValid(this.state.emailAddress));
        const validatedDate = this.props.schoolUser || !this.state.dobInvalid;
        let validatedParentEmails = true;
        if(this.state.parentEmailAddress) {
            validatedParentEmails = this.parentEmailsMatch() && this.emailIsValid(this.state.parentEmailAddress);
        }
        
        const formIsValid = required &&
            this.passwordsMatch() &&
            validatedEmails && validatedParentEmails &&
            validatedDate;

        // console.log(required, this.emailsMatch(), this.passwordsMatch());
        return formIsValid;
    }

    @boundMethod
    private formChange() {
        const values = this.collectRequiredFields();
        const valid = this.formIsValid() && this.state.agreedToTermsAndConditions;
        const valuesWithParentFields = Object.assign(
            values, {
                parentFirstName: this.state.parentFirstName || '',
                parentLastName: this.state.parentLastName || '',
                parentEmailAddress: this.state.parentEmailAddress || ''
            });

        this.props.onChange(valuesWithParentFields, valid);
    }

    @boundMethod
    private onTextInputChange(key: string, e: any) {
        const newState = { ...this.state };
        newState[key] = e.target.value;
        this.setState(newState, () => this.formChange());
    }

    @boundMethod
    private onDobChange(date: Moment) {
        this.setState({ dob: date.toDate() }, () => this.formChange());
    }

    @boundMethod
    private onGenderChange(e: any) {
        const newState = { ...this.state };
        newState.gender = getGenderEnum(e.target.value);
        this.setState(newState, () => this.formChange());
    }

    @boundMethod
    private onProfileColorChange(color: string) {
        this.setState({ profileColor: color });
    }

    @boundMethod
    private onPasswordChange(e: any) {
        if (e.getModifierState) {
            this.setState({ isCapsLockOn: e.getModifierState('CapsLock') });
        }
    }
}
