
import './ButtonLoading.css';

import * as React from 'react';

interface Props {
    className?: string;
}

const ButtonLoading: React.SFC<Props> = props => {
    return (
        <img alt="loading" className="hidenoanim white-filament-loading" src={'/assets/images/filament-loading-white-transparent.gif'} />
    );
};

ButtonLoading.defaultProps = {};
ButtonLoading.displayName = 'ButtonLoading';

export default ButtonLoading;
