import * as React from 'react';
import { getSATSectionDisplayName } from "../../../../services/utils/practice-test-util";

export interface Props {
    sectionName: string;
    score: number;
    sectionColor: string;
    testType: string
}

const TestSectionScore: React.SFC<Props> = ({ sectionName, score, sectionColor, testType }) => {
    sectionName = testType.toLowerCase() === "sat" ? getSATSectionDisplayName(sectionName) : sectionName

    let scoreInfo = `${sectionName} Score - ${score}`

    return (
        <span className={'subject-header-title ' + sectionColor}>
            {scoreInfo}
        </span>
    )
}

TestSectionScore.defaultProps = { };
TestSectionScore.displayName = 'AnalysisButton';

export default TestSectionScore;
