import './TestCard.css';
import * as React from 'react';

import { Column, RowData } from '../../../DataGrid/types/types';
import DataGrid from '../../../DataGrid/index';

export interface Props {
    backgroundColor: string;
    testData: any;
    subject: string;
}

export interface StoreProps {
}

export interface DispatchProps {
    setPriority: (lessonId: string, status: boolean) => Promise<any>;
}

interface State {
    testData: any;
}

export type AllProps = Props & DispatchProps;

export default class TestCard extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
        testData: this.props.testData
    };
  }

  public render() {
    const sortStr = (a, b, c?, d?, reverseSort?) => {
        if (!reverseSort) {
            if(a < b) {
            return 1;
            }
            if(b < a) {
                return -1;
            }
            if (b === a) {
            if (c && d) {
                if(c < d) {
                return 1;
                }
                if(d < c) {
                    return -1;
                }
            }
            }
            return 0;
        } else {
            if(a < b) {
            return 1;
            }
            if(b < a) {
                return -1;
            }
            if (b === a) {
            if (c && d) {
                if(c < d) {
                return -1;
                }
                if(d < c) {
                    return 1;
                }
            }
            }
            return 0;
        }
    };

    const sortNumStr = (a, b) => {
        a = `${a}`
        b = `${b}`
        var parts = {
            a: a.split('.'),
            b: b.split('.')
        };
        if (parts.a[0] === parts.b[0]) {
            return parseFloat(parts.a[1]) - parseFloat(parts.b[1]);
        }
        return parseFloat(parts.a[0]) > parseFloat(parts.b[0]) ? 1 : -1;
    };

    const testColumns: Column[] = [
        {
            title: 'LESSON',
            dataProperty: 'lessonName',
            width: '24%',
            sortable: true,
            sortMethod: sortStr
        },
        {
            title: 'CORRECT',
            dataProperty: 'correct',
            width: '13%',
            sortable: true,
            sortMethod: sortStr
        },
        {
            title: 'INCORRECT',
            dataProperty: 'incorrect',
            width: '13%',
            sortable: true,
            sortMethod: sortStr
        },
        {
            title: 'BLANK',
            dataProperty: 'blank',
            width: '13%',
            sortable: true,
            sortMethod: sortStr
        },
        {
            title: 'TOTAL',
            dataProperty: 'total',
            width: '13%',
            sortable: true,
            sortMethod: sortStr
        },
        {
            title: 'ACCURACY',
            dataProperty: 'accuracy',
            width: '13%',
            sortable: true,
            sortMethod: sortNumStr
        },
        {
            title: 'PRIORITY',
            dataProperty: 'star',
            width: '13%',
            sortable: true,
            sortMethod: sortStr
        },
    ];

    const data = this.props.testData.map(this.createTestData);
    const thisSubject = this.props.subject.toLowerCase()
    let classModifer = '--'
    thisSubject == 'w&l' ? classModifer += 'english' : classModifer += thisSubject


    const dataGridClass = 'test-table first-table' + classModifer
    return (
            <DataGrid
                columns={testColumns}
                data={data.sort( (a, b) => a.data.total - b.data.total)}
                className={dataGridClass}
            />
        );
    }

    createStar(testData: any) {
        let starType = this.getStarType(testData);
        const icon = '/assets/images/icons/v2/' + starType + '.svg';
        const altText = starType.includes('active') ? 'Prioritized' : 'Not prioritized';

        return () => (
            <button className="btn-div__inline btn-has-hover-img" onClick={(e) => this.flipStar(e, testData || 'false')} title="Toggle lesson priority">
                <img
                    src={icon}
                    className="priority-icon non-hover-icon"
                    alt={altText}
                />
                <img src="/assets/images/icons/v2/ico-star-hover.svg" className="priority-icon hover-icon d-none" alt="" />
            </button>
        );
    }

    getStarType = (testData: any) => {
        if (testData.star === true) {
            return 'ico-star-active';
        } else {
          return 'ico-star';
        }
    }

    flipStar = (e: any, testData: any) => {
        e.preventDefault();
        e.stopPropagation();
        const { lessonId, star } = testData;
        const tests = this.state.testData;
        const status = star === true ? false : true;
        this.props.setPriority(lessonId, status).then(() => {
            for (let i = 0; i < tests.length; i++) {
                let test = tests[i]
                if (!test) continue

                if (lessonId.indexOf('SinglePassages:') >= 0 && tests[i].lessonId.indexOf('SinglePassages:') >= 0) {
                    tests[i].star = status;
                } else if (lessonId.indexOf('DualPassages') >= 0 && tests[i].lessonId.indexOf('DualPassages') >= 0) {
                    tests[i].star = status;
                } else if(lessonId.indexOf('SinglePassagesWithFigures') >= 0 && 
                    tests[i].lessonId.indexOf('SinglePassagesWithFigures') >= 0) {
                    tests[i].star = status;
                }

                if (tests[i].lessonId === lessonId) {
                    tests[i].star = status;
                }
            }

            this.setState({
                testData: tests
            });
        });
    }

    createTestData = (testData: any, i: number): RowData => {
        if (!testData) return { id: "?", data: {}}

        const individualTestData = {
            lessonName: {
                content: testData.lessonName,
                comparator: testData.lessonName
            },
            correct: {
                content: testData.correct,
                comparator: testData.correct
            },
            incorrect: {
                content: testData.incorrect,
                comparator: testData.incorrect,
            },
            blank: {
                content: testData.blank,
                comparator: testData.blank,
            },
            total: {
                content: testData.total,
                comparator: testData.total,
            },
            accuracy: {
                content: testData.accuracy + '%',
                comparator: testData.accuracy,
            },
            star: {
                content: this.createStar(testData),
                comparator: testData.star,
            }
        };

        return {
            data: individualTestData,
            id: `test-data${i}`
        };
    }
}
