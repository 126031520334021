import * as React from 'react';
import { connect } from 'react-redux';

import TestOverview, { Props } from './';

import { AppState } from '../../../store';

const mapStateToProps = ({ account }: AppState) => ({
    teacherEmailAddress: account.userInfo.emailAddress as string,
});

// TODO: This connect pattern is terrible...need to fix across the board
const TestOverviewImpl = connect<Props, {}, Props, AppState>(mapStateToProps, {})(TestOverview);
export default (props) => {
    return (
        <TestOverviewImpl {...props} />
    );
};
