import React from 'react'
import { Link } from 'react-router-dom'
import { IAverageProgress } from '.'
import DataGrid from '../../../components/DataGrid'
import { Column } from '../../../components/DataGrid/types/types'
import { NA } from '../../../constants'
import { CurrentStatus } from '../../../services/liveview/liveview-service'
import { getHumanTime } from '../../../services/utils/date-util'
import { alphanumericCompare } from '../../../services/utils/sort-util'
import { IStudentProgressDetails, ITeacherStoreData } from '../../../stores/teacher.store'
import { ISummaryFilter } from '../SummaryFilters'

interface Props {
  selections: ISummaryFilter
  studentsProgress: IStudentProgressDetails[]
  averages: IAverageProgress
}

interface State {
  currentSortField: string
}

let sortMethod = alphanumericCompare
let minSortVal = Number.NEGATIVE_INFINITY
let maxSortVal = Number.POSITIVE_INFINITY
let sortable = true
let columns: Column[] = [
  {
    title: 'FIRST NAME',
    dataProperty: 'studentNameFirst',
    customTooltip: 'Indicates the first name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'LAST NAME',
    dataProperty: 'studentNameLast',
    customTooltip: 'Indicates the last name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'DAYS SINCE<br/>LAST LOGIN',
    dataProperty: 'daysSinceLastLogin',
    customTooltip: 'Indicates the # of days since last login',
    sortable,
    sortMethod
  },  
  {
    title: 'LESSON QUESTIONS<br/>ANSWERED',
    dataProperty: 'totalQuestionsAnswered',
    customTooltip: 'Indicates the total questions answered',
    sortable,
    sortMethod
  },
  {
    title: 'LESSON<br/>COMPLETION',
    dataProperty: 'lessonCompletion',
    customTooltip: 'Indicates the percentage of lessons started that are completed',
    sortable,
    sortMethod
  },
  {
    title: 'TIME ON<br/>LESSONS',
    dataProperty: 'timeOnLessons',
    customTooltip: 'Indicates the total study time on lessons',
    sortable,
    sortMethod
  },
]

let averageStudentProgress = {
  id: "?",
  firstName: "",
  lastName: "",
  emailAddress: "",
  schoolName: "",
  graduationYear: "",
  period: "",
  profileColor: "",

  navigation: "",
  activity: "",
  currentStatus: CurrentStatus.AWAY,
  updatedAt: "",
  daysSinceLastLogin: -1,

  lessons: [],
  nofLessonsStarted: 0,
  nofLessonsCompleted: 0,
  nofTotalQuestionsAnswered: 0,
  percentLessonCompletion: 0,
  lessonsScores: null,

  tonp: [],
  totalStudyTimeForAllLessons: {avg: 0, total: 0},
  isAverage: true
}

export default class RosterLessonSummary extends React.Component<Props, State> {
  averageUsers = {}

  constructor(props) {
    super(props)
    this.state = {
      currentSortField: "default"
    }
  }

  createContent = (studentProgress: IStudentProgressDetails, index: number) => {
    let id = `roster-lesson-summary-${index}`
    let {firstName, lastName, emailAddress, nofTotalQuestionsAnswered, isAverage } = studentProgress
    let {daysSinceLastLogin, percentLessonCompletion, totalStudyTimeForAllLessons} = studentProgress    
    let {averages} = this.props
    let studyTimeTotal = totalStudyTimeForAllLessons.total
    let ht = getHumanTime(studyTimeTotal, false, true)
    let totalStudyTimeInfo = () => <span dangerouslySetInnerHTML={{__html: ht.text}}/>
    let getAverageInfoBasedOnSortField = (currentSortField: string) => {
      let prefix = "Average"
      let averageInfo = ""
      let averageHT = getHumanTime(averages.averageTotalStudy, false, true)
      switch (currentSortField) {
        case "daysSinceLastLogin":
          averageInfo = `<span class="average--val">` + averages.averageDaysSince + `</span>` + prefix + ` Days Since Last Login`
          break
        case "totalQuestionsAnswered":
          averageInfo = `<span class="average--val">` + averages.averageLessonQuestionsAnswered + `</span>` + prefix + ` Total Questions Answered`
          break
        case "lessonCompletion":
          averageInfo = `<span class="average--val">` + averages.averageLessonCompletion + `%</span>` + prefix + ` Lessons Completion`
          break
        case "timeOnLessons":
          averageInfo = `<span class="average--val">` + averageHT.text + `</span>` + prefix + ` Time on Lessons`
          break      
      }
      return averageInfo ? averageInfo : ""
    }
    let {currentSortField} = this.state
    let averageInfo = getAverageInfoBasedOnSortField(currentSortField)
    let getFirstNameOrAverageDetails = () => {
      let reportLink = `/print/teacher/student-progress/${emailAddress}`
      return () =>
        <>
          { !isAverage ?           
            <>
              <Link className="roster-lesson-summary-iconlink" to={reportLink} target="progress-summary-page" title={`Open Full Progress Summary for: ${firstName} ${lastName}, opens in a new window`}>
              <img
                  className="explanation-button-image"
                  src={`/assets/images/icons/v2/ico-external-link.svg`}
                  width="18"
                  height="18"
                  alt=""
                />
              </Link>
              {firstName}
            </>
            :
            <>
              <span dangerouslySetInnerHTML={{__html: averageInfo}}/>
            </>
          }
        </> 
    }      
    let data = {
      studentNameFirst: {
        // We are overloading this with first name or average details (based on the sort field)
        content: getFirstNameOrAverageDetails(),
        comparator: firstName,
      }, 
      studentNameLast: {
        content: lastName,
        comparator: lastName,
      },
      daysSinceLastLogin: {
        content: daysSinceLastLogin !== -1 ? daysSinceLastLogin: NA,
        comparator: daysSinceLastLogin !== -1 ? daysSinceLastLogin: minSortVal,
      },    
      totalQuestionsAnswered: {
        content: nofTotalQuestionsAnswered > 0 ? nofTotalQuestionsAnswered: NA,
        comparator: nofTotalQuestionsAnswered > 0 ? nofTotalQuestionsAnswered: minSortVal,
      },
      lessonCompletion: {
        content: percentLessonCompletion !== -1 ? `${percentLessonCompletion}%`: NA,
        comparator: percentLessonCompletion !== -1 ? percentLessonCompletion: minSortVal,
      },
      timeOnLessons: {
        content: studyTimeTotal > 0 ? totalStudyTimeInfo: NA,
        comparator: studyTimeTotal > 0 ? studyTimeTotal: minSortVal,
      },
    }
  
    // Let's highlight the average row here (please note if there is no averageInfo then collapse the row!)
    let highlightRowClassName  = ""
    if (isAverage) {
      if (averageInfo !== "") {
        highlightRowClassName = "row-highlight"
      }
      else {
        highlightRowClassName = "row-highlight-collapsed"
      }
    }

    return {
      id,
      data,
      highlightRowClassName,     
    }
  }
  
  onSortAllowed = (sortFieldName: string) => {
    let {currentSortField} = this.state
    if (sortFieldName !== currentSortField) {
      setTimeout(() => {
        this.setState({currentSortField: sortFieldName})
      }, 10)
    }
    return true
  }

  getCardBodyContentForLessonSummary = (studentsProgress: IStudentProgressDetails[]) => {
    let {averages} = this.props
    let extendedStudentsProgress = [...studentsProgress]
    
    averageStudentProgress.daysSinceLastLogin = averages.averageDaysSince
    averageStudentProgress.percentLessonCompletion = averages.averageLessonCompletion
    averageStudentProgress.nofTotalQuestionsAnswered = averages.averageLessonQuestionsAnswered
    averageStudentProgress.totalStudyTimeForAllLessons = { avg: averages.averageTotalStudy, total: averages.averageTotalStudy}
    
    // Add average row data only if there are more than 1 entry
    if (studentsProgress.length > 1) {
      extendedStudentsProgress.push(averageStudentProgress as any)
    }

    let data = extendedStudentsProgress.map((progress, index) => this.createContent(progress, index))
  
    return (
      <DataGrid
        columns={columns}
        data={data}
        onSortAllowed={this.onSortAllowed}
        emptyText="No lessons summary found for students."
      />
    )
  }

  public render() {
    let {studentsProgress} = this.props
    if (!studentsProgress) return null

    let lessonSummaryDetails = this.getCardBodyContentForLessonSummary(studentsProgress)

    return (
      <div className="roster-view-lesson-summary-container">
        <div className="individual-data-section">
        <h2 className="h3">Lesson Summary</h2>
        </div> 
        <hr/>

        <div className="roster-view-content">
          {lessonSummaryDetails}
        </div>
      </div>
    )
  }
}
