import React, { Component } from 'react'
import RadialProgress from '../../../components/RadialProgress/RadialProgress'
import { TestKind } from '../SuperintendentFilters'
import "./index.css"

interface SchoolSummaryProps {
  allData: any
  selectedSchool: any
  summary: any
  testScoreKind: TestKind
  onClose: () => void
}

interface SchoolSummaryState {

}

/**
 * Will show the school summary regardless of the selections (per Jennifer)
 * PS: Original desing was showing the data in respect to the selections!
 */
export default class SchoolSummary extends Component<SchoolSummaryProps, SchoolSummaryState> {
  render() {
    let {selectedSchool, onClose, allData, testScoreKind} = this.props
    let schoolName = selectedSchool.name
    let data = testScoreKind === TestKind.ACT ? allData.ACT: allData.SAT
    let school =  data.schools.find(s => s.name === schoolName)
    let {accuracies} = school
    let catE = accuracies.English
    let catM = accuracies.Math
    let catR = accuracies.Reading
    let catS = accuracies.Science
    let valE = Math.round(catE.postLessonAccuracy)
    let valM = Math.round(catM.postLessonAccuracy)
    let valR = Math.round(catR.postLessonAccuracy)
    let valS = Math.round(catS.postLessonAccuracy)
    let perE = valE + "%"
    let perM = valM + "%"
    let perR = valR + "%"
    let perS = valS + "%"
    let equivelentScoreE = catE.equivalentScore
    let equivelentScoreM = catM.equivalentScore
    let equivelentScoreR = catR.equivalentScore
    let equivelentScoreS = catS.equivalentScore
    let collegeReadyE = `${Math.round(catE.collegeReady)}% College Ready`
    let collegeReadyM = `${Math.round(catM.collegeReady)}% College Ready`
    let collegeReadyR = `${Math.round(catR.collegeReady)}% College Ready`
    let collegeReadyS = `${Math.round(catS.collegeReady)}% College Ready`
    let nofTeachers = school.rawdata.teachers.length
    let graduationYearsInfo = school.rawdata.graduationYears.filter(info => info.year.toLowerCase() !== "all")
    let nofStudents = graduationYearsInfo.reduce((acc, cur) => acc + cur.nofStudents, 0)

    return (
      <div className="container school-summary">
        <div className="row school-header mb-2">
          <div className="col header-school-name">{school.name}</div>  
          <div>
            <button className="modal-close remove---close-btn" tabIndex={0} onClick={onClose} >
              <img
                className="modal-icon"
                src={`/assets/images/icons/v2/ico-modal-close-white.svg`}
                width="24"
                height="24"
                alt=""
              />
            </button>
          </div>
        </div>
        <h2 className="title-school-details-info"><b>Lesson Accuracy</b></h2>
        <div className="row text-center">
          <div className="col-sm-6 col-lg-3 mb-3">
            <div className={`col-12 category-info` + (valE <= 0 ? ` no-results` : ` has-results`)}>
              <h5>ENGLISH</h5>
              {valE > 0 ?
                <>
                <div className="radial-wrapper">
                  <RadialProgress
                      size={110}
                      radius={47}
                      startColor="#9353bf"
                      endColor="#6e3d81"
                      pegOffset={8}
                      progress={valE}
                      text={perE}
                      textStyle={{fontSize: '24px', marginLeft: '0', color: '#2B2D2D', fontWeight: 700}}
                      progressLineWidth={14}
                      backgroundLineWidth={14}
                  />
                </div>
                <div className="stat-key-footer interior">
                  <p><b>{equivelentScoreE}</b></p>
                  <p className="small">{collegeReadyE}</p>
                </div>
              </>
                :
                <div className="na-wrapper">N/A</div>
              }
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-3">
            <div className={`col-12 category-info` + (valM <= 0 ? ` no-results` : ` has-results`)}>
              <h5>MATH</h5>
              {valM > 0 ?
                <>
                  <div className="radial-wrapper">
                    <RadialProgress
                        size={110}
                        radius={47}
                        startColor="#87e5f8"
                        endColor="#4cc8e1"
                        pegOffset={8}
                        progress={valM}
                        text={perM}
                        textStyle={{fontSize: '24px', marginLeft: '0', color: '#2B2D2D', fontWeight: 700}}
                        progressLineWidth={14}
                        backgroundLineWidth={14}
                    />
                  </div>
                  <div className="stat-key-footer interior">
                    <p><b>{equivelentScoreM}</b></p>
                    <p className="small">{collegeReadyM}</p>
                  </div>
                </>
                :
                <div className="na-wrapper">N/A</div>
              }
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-3">
            <div className={`col-12 category-info` + (valR <= 0 ? ` no-results` : ` has-results`)}>
              <h5>READING</h5>
              {valR > 0 ?
                <>
                  <div className="radial-wrapper">
                    <RadialProgress
                        size={110}
                        radius={47}
                        startColor="#f8cd8b"
                        endColor="#eda33f"
                        pegOffset={8}
                        progress={valR}
                        text={perR}
                        textStyle={{fontSize: '24px', marginLeft: '0', color: '#2B2D2D', fontWeight: 700}}
                        progressLineWidth={14}
                        backgroundLineWidth={14}
                    />
                  </div>
                  <div className="stat-key-footer interior">
                    <p><b>{equivelentScoreR}</b></p>
                    <p className="small">{collegeReadyR}</p>
                  </div>
                </>
                :
                <div className="na-wrapper">N/A</div>
              }
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-3">
            <div className={`col-12 category-info` + (valS <= 0 ? ` no-results` : ` has-results`)}>
              <h5>SCIENCE</h5>
              {valS > 0 ?
                <>
                  <div className="radial-wrapper">
                    <RadialProgress
                          size={110}
                          radius={47}
                          startColor="#c2f080"
                          endColor="#93cd3f"
                          pegOffset={8}
                          progress={valS}
                          text={perS}
                          textStyle={{fontSize: '24px', marginLeft: '0', color: '#2B2D2D', fontWeight: 700}}
                          progressLineWidth={14}
                          backgroundLineWidth={14}
                      />
                  </div>
                  <div className="stat-key-footer interior">
                    <p><b>{equivelentScoreS}</b></p>
                    <p className="small">{collegeReadyS}</p>
                  </div>
                </>
                :
                <div className="na-wrapper">N/A</div>
              }
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 mb-3">
            <h2 className="title-school-details-info"><b>School User Accounts</b></h2>
            <div className="stripe-rows">
              <div className="row">
                <div className="col">Teachers</div>
                <div className="col text-right">{nofTeachers}</div>
              </div>
              <div className="row">
                <div className="col">Students</div>
                <div className="col text-right">{nofStudents}</div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 mb-3">
            <h2 className="title-school-details-info"><b>Graduating Class User Accounts</b></h2>
            <div className="stripe-rows">              
              {graduationYearsInfo && graduationYearsInfo.map(info => {
                let display = info.nofStudents > 0
                if (!display) return ""
                return(
                  <div className="row">
                    <div className="col">Class of {info.year}</div>
                    <div className="col text-right">{info.nofStudents}</div>
                  </div>
                )
              })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
