import './Checkbox.css';

import * as React from 'react';

interface Props {
    size?: string;
    checked: boolean;
    onCheck: (e: any) => void;
    labelText?: string | JSX.Element;
    checkboxClass?: string;
}

const Checkbox: React.SFC<Props> = (props) => {
    return (
        <label className="custom-control custom-checkbox winward-checkbox">
            <input type="checkbox" className="custom-control-input" checked={props.checked} onChange={props.onCheck} />
            <span className={`custom-control-indicator ${props.checkboxClass || ''}`} />
            {props.labelText && <span className="custom-control-description">{props.labelText}</span>}
        </label>
    );
};

Checkbox.displayName = 'Checkbox';
Checkbox.defaultProps = {
    size: '16px'
};

export default Checkbox;
