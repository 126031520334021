import * as React from "react"
import "./index.css"

export function CollapsableButton(props: {collapsed: boolean}) {
    let {collapsed} = props
    return (
        <div className="collapsable-btn-component">
            <div className="collapsable-btn-container">
                <div className={`collapsable-btn ${collapsed ? "collapsed": ""}`} />
            </div> 
        </div>
    )
}