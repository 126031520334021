import * as React from 'react';

import './PracticeTestComponentSelector.css';

export interface Props {
    onSwitchComponent: () => void;
    displayName: string;
    active: boolean;
}

const PracticeTestComponentSelector: React.SFC<Props> = (props: Props) => {
    return (
        <button
            className={'btn subject-btn btn-not-bold btn-default practice-test-component-selector ' +
            (props.active ? 'activeSelection arrow_box' : '')}
            type="button"
            onClick={() => props.onSwitchComponent()}
        >
            {props.displayName}
        </button>
    );
};

export default PracticeTestComponentSelector;
