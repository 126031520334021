
import React, { Component } from "react"
import Button from "../../../../components/Button/Button"
import Modal from "../../../../components/Modal/Modal"
import "./MistakeBankDialog.css"

interface Props {
  onButtonLeft: () => void
  onButtonRight: () => void
  show: boolean
}

interface State {}

export default class MistakeBankDialog extends Component<Props, State> {
  render() {
    let {show, onButtonLeft, onButtonRight} = this.props

    return (
      <Modal
        className="mistake-bank-modal"
        show={show}
        width="860px"
      >
        <div className="modal-container">
          <div className="stay-sharp-modal--text">
            <p className="header-text">Well Done!</p>
            <p className="subtext">
              You have mastered this topic. For more practice, continue below to review the questions again.
            </p>
          </div>
          <div className="btn-container">
              <Button
                  text="RETURN TO MISTAKE BANK"
                  textColor="white"
                  bgColor="blue"
                  onClick={onButtonLeft}                
              />
              <Button
                  text="REVIEW QUESTIONS AGAIN"
                  textColor="white"
                  bgColor="green"
                  onClick={onButtonRight}
              />
          </div>
        </div>
      </Modal>
    )
  }
}
