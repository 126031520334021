import './BrightcoveVideo.css';

import * as React from 'react';

interface Props {
    id: string;
    videoId: string;
    style?: React.CSSProperties;
    onVideoStart?: () => void;
    // Natural ending is when playback reaches logical end of video
    onVideoEnd?: (endedNaturally: boolean) => void;
    onVideoPause?: () => void
}

interface State {

}

declare const bc: any;
declare const videojs: any;

export default class BrightcoveVideo extends React.PureComponent<Props, State> {
    activePlayer

    public componentDidMount() {
        this.initializeVideo();
    }

    public componentDidUpdate(prevProps: Props) {
        if(prevProps.videoId !== this.props.videoId) {
            this.updateVideo();
        }
    }

    public render() {

        return (
            <div className="winward-video" style={this.props.style}>
                <video
                    id={this.props.id}
                    data-video-id={this.props.videoId}
                    data-account={process.env.REACT_APP_BRIGHTCOVE_ACCOUNT}
                    data-player={process.env.REACT_APP_BRIGHTCOVE_PLAYER}
                    data-embed="default"
                    className="video-js"
                    controls
                />
            </div>
        );
    }

    public componentWillUnmount() {
        try {
            const myplayer = videojs(this.props.id);
            myplayer.dispose();
        }
        catch (ex) {
            // Catch silently
        }
    }

    private initializeVideo() {
        const video = document.getElementById(this.props.id);

        if (!video || (/(?:^|\s)vjs-tech(?:\s|$)/).test(video.className)) {
            console.log('Video already initialized');
            return;
        }

        // Initialize video
        try {
            bc(video);
        } catch(e) {
            // console.log(e);
        }

        if(!this.props.videoId) {
            return;
        }

        const player = videojs(this.props.id);

        player.ready(() => {
            this.initiallyHideClosedCaptions(player)

            // player.catalog.getVideo(this.props.videoId, (error, playerVid) => {
            //     const response = !!error && JSON.parse(error.response);
            //     if(response && response[0] && response[0].error_code) {
            //         console.log(response);
            //     } else {
            //         player.catalog.load(playerVid);
            //     }
            // });

            // Find highest quality source
            // player.one('loadstart', function(evt: any) {
            //     const mp4Ara = player.mediainfo.sources
            //         .filter(source => source.container === 'MP4' && source.hasOwnProperty('src'));
            //     mp4Ara.sort((a: any, b: any) => b.size - a.size);

            //     // Use highest quality source
            //     player.src(mp4Ara[0].src);
            // });

            player.on('play', () => {
                if(this.props.onVideoStart) {
                    this.props.onVideoStart();
                }
            });

            player.on('ended', () => {
                // Playback reaches true logical end of video 
                if(this.props.onVideoEnd) {
                    this.props.onVideoEnd(true);
                }
            });

            player.on("pause", () => {
                if(this.props.onVideoPause) {
                    this.props.onVideoPause()
                }
            })
        });
    }

    /**
     * Initially hide the closed-captions of the video!
     * User can show/hide them anytime during the playback
     * @param player 
     */
    private initiallyHideClosedCaptions(player) {
        setTimeout(() => {
            try {
                let textTracks = player.textTracks()

                for (let i=0;i < textTracks.length; i++) {
                    let track = textTracks[i]
                    if (track) {
                        track.mode = "disabled"
                    }
                }
            } catch (ex) {
                // Catch silently
            }
        }, 1000)
    }

    private updateVideo() {
        const player = videojs(this.props.id);
        this.activePlayer = player
        player.catalog.getVideo(this.props.videoId, (error, playerVid) => {
            const response = !!error && JSON.parse(error.response);
            if(response && response[0] && response[0].error_code) {
                console.error(response);
                alert(response[0].error_code);
            } else {
                player.catalog.load(playerVid);
            }
        });
    }

    pause() {
        if (this.activePlayer) {
            this.activePlayer.pause()
        }
    }

    play() {
        if (this.activePlayer) {
            this.activePlayer.play()
        }
    }
}
