import './BundleCardControls.css';
import * as React from 'react';
import { Bundle } from '../../../../services/bundles/bundle-types';
import BundleCard from './components/BundleCard';

interface Props {
    bundleTypes: any[];
    onBundleSelected: any;
    darkPrint?: boolean;
    preSelectedBundleId?: string;
}

interface State {
    bundleId: string;
    includeCollegeKnowledge: boolean;
    bundles: any[];
}

// Note: All prices are marked in # of cents
export default class BundleCardControls extends React.PureComponent<Props, State> {
  constructor(props: Props) {
      super(props);

      this.state = {
        bundleId: this.props.preSelectedBundleId || '',
        includeCollegeKnowledge: false,
        bundles: props.bundleTypes
      };
  }

  componentDidMount() {
      this.props.onBundleSelected(this.state.bundleId, false);
  }
 
  public render() {
    const self = this;
    const { bundles } = this.state;
    return (
        <div className="bundle-card-controls">
            {bundles.map(bundle => {
                return (
                    <BundleCard 
                        onBundleSelected={this.props.onBundleSelected} 
                        bundle={bundle} 
                        preSelectedBundleId={this.props.preSelectedBundleId} 
                        key={bundle.number}
                    />
                );
            })
            }
        </div>
    );
  }
}
