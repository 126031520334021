import jp from "jsonpath"
import { getStudentsTestInsightDetailsBySchoolAndTestNameFromEndPoint, getStudentsTestInsightsBySchoolTestTypeAndGraduationYears, getStudentsTimeOnPlatformInfoBySchool, getSuperintendentReports } from "../services/superintendent"
import { TestKind } from "../scenes/SuperintendentDashboard/SuperintendentFilters"
import { convertToACTandSAT } from "../services/utils/act-sat-util"
import { collegeReadyLookup } from "../services/utils/college-ready-util"
import { avg, sum, weightedMean } from "../services/utils/math-util"
import { CATEGORY_ENGLISH, CATEGORY_MATH, CATEGORY_READING, CATEGORY_SCIENCE } from "../constants"
import { ACTIVE_TESTS, getTestTypeBasedOnName } from "../services/utils/practice-test-util"
import { getCachedValue, setCacheValue } from "../services/utils/cache-util"
import { ITestInsight, ITestInsightACT, ITestInsightDetails, ITestInsightSAT, getTestsInsightsByTestName } from "../services/elearn/teacher/practice-tests-insights"
import { SITimeFrame } from "../services/utils/date-util"

export interface SuperintendentData {
  lessons: LessonsSummary
  tests: TestsSummary
  performances: any
  tonpDetailsReports: ITonPDetailsReport[]
}

export interface LessonsSummary {
  snapdate: string
  schools: School[]
}

export interface TestsSummary {
  snapdate: string
  schools: TestSummarySchool[]
}

export interface School {
  name: string
  teachers: Teacher[]
  graduationYears: GraduationYear[]
}

export interface TestSummarySchool {
  name: string
  scores: Scores
}

export interface Scores {
  ACT?: Tests
  IA?:  Tests
  SAT?: Tests
}

export interface Tests {
  [key: string]: Test
}


export interface GraduationYearForTests {
  year: string
  details: Details
}

export interface Test {
  testDisplayName: string
  details: Details
  graduationYears: GraduationYearForTests[]
}

export interface Details {
  nofStudents: number
  composite?: SimpleTestScore
  english?: ExtendedTestScore
  math?: ExtendedTestScore
  reading?: ExtendedTestScore
  science?: ExtendedTestScore
  ebrw?: ExtendedTestScore
}

export interface SimpleTestScore {
  nofStudents: number
  avg: number
}

export interface ExtendedTestScore extends SimpleTestScore {
  nofStudentsReady: number
  collegeReady: number
}

export interface Teacher {
  name: string
  email: string
}

export interface GraduationYear {
  year: string
  nofStudents: number
  lastDay: TimelyData
  lastWeek: TimelyData
  lastMonth: TimelyData
  allTime: TimelyData
  currentAcademicYear: TimelyData
}

export interface TimelyData {
  lessons?: Lessons
  tests?: Tests // To be developed soon! (like ACT/SAT PracticeTests etc)
}

export interface Lessons {
  progress: Progress
  categories: Categories
}

export interface Progress {
  nofLessonsStarted: number
  nofLessonsCompleted: number
  nofLessonsContinued: number
  totalQuestionsAnswered: number
  totalTimeOnQuestions: number
  totalStudyTime: number
  dailyAnsweredQuestions: DailyAnsweredQuestions[]
}

// export interface DailyAnsweredQuestions {
//   [date: string]: number
// }
export interface DailyAnsweredQuestions {
  date: string
  n: number
}

export interface Categories {
  English?: Category
  Math?: Category
  Reading?: Category
  Science?: Category
}

export interface Category {
  nofLessonsStarted: number
  nofLessonsCompleted: number
  nofLessonsContinued: number
  totalTimeOnAnswered: number
  totalTimeOnQuestions: number
  avgTimePerQuestions: number
  nofQuestionsAnswered: number
  hintsOnIncorrects: number
  preLessonAccuracy: number
  postLessonAccuracy: number
  lessonGrowth: number,
  preLessonConversionScoreACT: string,
  preLessonConversionScoreSAT: string,
  postLessonConversionScoreACT: string,
  postLessonConversionScoreSAT: string,
  nofStudentsParticipated: number
  nofStudentsCollegeReadyACT: number
  nofStudentsCollegeReadySAT: number
  collegeReadyACT: number
  collegeReadySAT: number
  lessonsContinued: number
  studyTime: {
    baselines: number
    videos: number
    questions: number
    total: number
  }
}

export class SuperintendentStore {
  static async loadDashboardData(): Promise<SuperintendentData> {
    let reports = await getSuperintendentReports()
    return reports
  }
}

export function filterTests(testsSummary: TestsSummary, schoolNames: string[], graduationYears: string[]): TestSummarySchool[] {
  let filteredData: TestSummarySchool[] = testsSummary.schools.filter(school => schoolNames.includes(school.name))

  function getValues(data: any, sectionName: string, key: string) {
    return jp.query(data, `$..${sectionName}.${key}`).filter(val => val > 0)
  }

  function getValuesForACTandIASections(data: any, key: string) {
    let valuesC = getValues(data, "composite", key)
    let valuesE = getValues(data, "english", key)
    let valuesM = getValues(data, "math", key)
    let valuesR = getValues(data, "reading", key)
    let valuesS = getValues(data, "science", key)
    return { valuesC, valuesE, valuesM, valuesR, valuesS }
  }

  function getValuesForSATSections(data: any, key: string) {
    let valuesC = getValues(data, "composite", key)
    let valuesM = getValues(data, "math", key)
    let valuesEBRW = getValues(data, "ebrw", key)
    return { valuesC, valuesM, valuesEBRW }
  }

  // We need to roll up the following for ACT & IA:
  // - nofStudents
  // - composite, english, math, reading, writing
  // -- nofStudents, nofStudentsReady, collegeReady, avg
  function calculateDetailsForACTandIA(graduationYears: GraduationYearForTests[]) {
    let allDetails = graduationYears.map(gy => gy.details)

    let valsForNofStudents = getValuesForACTandIASections(allDetails, "nofStudents")
    let nofStudents = allDetails.reduce((acc, cur) => acc + cur.nofStudents, 0)
    let nofStudentsC = sum(valsForNofStudents.valuesC)
    let nofStudentsE = sum(valsForNofStudents.valuesE)
    let nofStudentsM = sum(valsForNofStudents.valuesM)
    let nofStudentsR = sum(valsForNofStudents.valuesR)
    let nofStudentsS = sum(valsForNofStudents.valuesS)

    let valsForAvg = getValuesForACTandIASections(allDetails, "avg")
    let avgC = avg(valsForAvg.valuesC)
    let avgE = avg(valsForAvg.valuesE)
    let avgM = avg(valsForAvg.valuesM)
    let avgR = avg(valsForAvg.valuesR)
    let avgS = avg(valsForAvg.valuesS)

    let valsForNofStudentsReady = getValuesForACTandIASections(allDetails, "nofStudentsReady")
    let nofStudentsReadyE = sum(valsForNofStudentsReady.valuesE)
    let nofStudentsReadyM = sum(valsForNofStudentsReady.valuesM)
    let nofStudentsReadyR = sum(valsForNofStudentsReady.valuesR)
    let nofStudentsReadyS = sum(valsForNofStudentsReady.valuesS)

    let valsForCollegeReady = getValuesForACTandIASections(allDetails, "collegeReady")
    let collegeReadyE = avg(valsForCollegeReady.valuesE)
    let collegeReadyM = avg(valsForCollegeReady.valuesM)
    let collegeReadyR = avg(valsForCollegeReady.valuesR)
    let collegeReadyS = avg(valsForCollegeReady.valuesS)

    let details = {
      nofStudents,
      composite: {
        nofStudents: nofStudentsC,
        avg: avgC
      },
      english: {
        nofStudents: nofStudentsE,
        nofStudentsReady: nofStudentsReadyE,
        collegeReady: collegeReadyE,
        avg: avgE
      },
      math: {
        nofStudents: nofStudentsM,
        nofStudentsReady: nofStudentsReadyM,
        collegeReady: collegeReadyM,
        avg: avgM
      },
      reading: {
        nofStudents: nofStudentsR,
        nofStudentsReady: nofStudentsReadyR,
        collegeReady: collegeReadyR,
        avg: avgR
      },
      science: {
        nofStudents: nofStudentsS,
        nofStudentsReady: nofStudentsReadyS,
        collegeReady: collegeReadyS,
        avg: avgS
      }
    }

    return details
  }

  // We need to roll up the following for SAT:
  // - nofStudents
  // - composite, math, ebrw
  // -- nofStudents, nofStudentsReady, collegeReady, avg
  function calculateDetailsForSAT(graduationYears: GraduationYearForTests[]) {
    let allDetails = graduationYears.map(gy => gy.details)

    let valsForNofStudents = getValuesForSATSections(allDetails, "nofStudents")
    let nofStudents = allDetails.reduce((acc, cur) => acc + cur.nofStudents, 0)
    let nofStudentsC = sum(valsForNofStudents.valuesC)
    let nofStudentsM = sum(valsForNofStudents.valuesM)
    let nofStudentsEBRW = sum(valsForNofStudents.valuesEBRW)

    let valsForAvg = getValuesForSATSections(allDetails, "avg")
    let avgC = avg(valsForAvg.valuesC)
    let avgM = avg(valsForAvg.valuesM)
    let avgEBRW = avg(valsForAvg.valuesEBRW)

    let valsForNofStudentsReady = getValuesForSATSections(allDetails, "nofStudentsReady")
    let nofStudentsReadyM = sum(valsForNofStudentsReady.valuesM)
    let nofStudentsReadyEBRW = sum(valsForNofStudentsReady.valuesEBRW)

    let valsForCollegeReady = getValuesForSATSections(allDetails, "collegeReady")
    let collegeReadyM = avg(valsForCollegeReady.valuesM)
    let collegeReadyEBRW = avg(valsForCollegeReady.valuesEBRW)

    let details = {
      nofStudents,
      composite: {
        avg: avgC,
        nofStudents: nofStudentsC,
      },
      math: {
        avg: avgM,
        nofStudents: nofStudentsM,
        nofStudentsReady: nofStudentsReadyM,
        collegeReady: collegeReadyM,
      },
      ebrw: {
        avg: avgEBRW,
        nofStudents: nofStudentsEBRW,
        nofStudentsReady: nofStudentsReadyEBRW,
        collegeReady: collegeReadyEBRW,
      }
    }

    return details
  }

  let filteredSchoolSummaries = filteredData.reduce((acc: TestSummarySchool[], schoolTestSummary: TestSummarySchool) => {
    let school = JSON.parse(JSON.stringify(schoolTestSummary)) // OT: Deep copy needed! (didn't use structuredClone as it is new)
    let testsACT: Tests|undefined = school.scores.ACT 
    let testsIA : Tests|undefined = school.scores.IA   
    let testsSAT: Tests|undefined = school.scores.SAT 
    let testNamesACT = testsACT && ACTIVE_TESTS.ACT
    let testNamesIA = testsIA && ACTIVE_TESTS.IA
    let testNamesSAT = testsSAT && ACTIVE_TESTS.SAT
    let schoolTestsACT = testsACT
    let schoolTestsIA = testsIA
    let schoolTestsSAT = testsSAT
    let newSchool = {
      name: school.name,
      scores: {
        ACT: testNamesACT && testNamesACT.reduce((acc: Tests, testName: string) => {  
                let test = schoolTestsACT && schoolTestsACT[testName]
                if (test) {
                  test.graduationYears = test.graduationYears.filter(gradYear => graduationYears.includes(gradYear.year))
                  test.details = calculateDetailsForACTandIA(test.graduationYears)
                  acc[testName] = test
                }
                return acc
              }, {}),

        IA: testNamesIA && testNamesIA.reduce((acc: Tests, testName: string) => {  
              let test = schoolTestsIA && schoolTestsIA[testName]
              if (test) {
                test.graduationYears = test.graduationYears.filter(gradYear => graduationYears.includes(gradYear.year))
                test.details = calculateDetailsForACTandIA(test.graduationYears)
                acc[testName] = test
              }
              return acc
            }, {}),

        SAT: testNamesSAT && testNamesSAT.reduce((acc: Tests, testName: string) => {  
              let test = schoolTestsSAT && schoolTestsSAT[testName]
              if (test) {
                test.graduationYears = test.graduationYears.filter(gradYear => graduationYears.includes(gradYear.year))
                test.details = calculateDetailsForSAT(test.graduationYears)
                acc[testName] = test
              }
              return acc
            }, {}),
      }        
    }
    acc.push(newSchool)

    return acc
  }, [])

  return filteredSchoolSummaries
}

export function filterLessons(lessons: LessonsSummary,  schoolNames: string[], graduationYears: string[], timeFrame=SITimeFrame.ALL_TIME, testKind=TestKind.ACT) {
  function getSchoolAccuracyInfoByCategory(schoolSummaries, category: string, testKind: TestKind) {
    let accuracies = jp.query(schoolSummaries, `$..accuracies.${category}.postLessonAccuracy`).filter(val => val > 0)
    let len = accuracies.length
    let accuracy = avg(accuracies, true)
    let conversion = convertToACTandSAT(category, accuracy, accuracy)
    let testEquivalent = testKind === TestKind.ACT ? conversion.postLessonConversionScoreACT: conversion.postLessonConversionScoreSAT
    let equivalentScore = `${testEquivalent} ${testKind}`
    let collegeReadyItems = jp.query(schoolSummaries, `$..accuracies.${category}.collegeReady`)
    let collegeReadyItemsLen = collegeReadyItems.length
    let collegeReady =  collegeReadyItemsLen > 0 ? Math.round(collegeReadyItems.reduce((acc, cur) => acc+=cur, 0)/collegeReadyItemsLen): 0
    let result = {
      category,
      accuracy,
      equivalentScore,
      collegeReady
    }
    return result
  }

  function getCategoricalAverages(data: GraduationYear[], category: string, timeFrame: SITimeFrame, testKind: TestKind) {
    let categoriesInfo = jp.query(data, `$..${timeFrame}.lessons.categories.${category}`) as Category[]
    let preLessonAccuracies = categoriesInfo
    let postLessonAccuracies = categoriesInfo
      .filter(d => d.postLessonAccuracy > 0)
      .map(d => {
        return {
          nofLessonsContinued: d.nofLessonsContinued, 
          postLessonAccuracy: d.postLessonAccuracy
        }
      })

    let avgPre = weightedMean(preLessonAccuracies.map(d => {
      return { weight: d.nofLessonsContinued, value: d.preLessonAccuracy}
    }))
    let avgPost = weightedMean(postLessonAccuracies.map(d => {
      return { weight: d.nofLessonsContinued, value: d.postLessonAccuracy}
    }))

    let conversion = convertToACTandSAT(category, avgPre, avgPost)
    let testEquivalent = testKind === TestKind.ACT ? conversion.postLessonConversionScoreACT: conversion.postLessonConversionScoreSAT
    let equivalentScore = `${testEquivalent} ${testKind}`
    let pathExpressionCollegeReadyACT = `$..${timeFrame}.lessons.categories.${category}.collegeReadyACT`
    let pathExpressionCollegeReadySAT = `$..${timeFrame}.lessons.categories.${category}.collegeReadySAT`
    let collegeReadyACTItems = jp.query(data, pathExpressionCollegeReadyACT).filter(v => v > -1)
    let collegeReadySATItems = jp.query(data, pathExpressionCollegeReadySAT).filter(v => v > -1)
    let crACTLen = collegeReadyACTItems.length
    let crSATLen = collegeReadySATItems.length
    let crACTSum = collegeReadyACTItems.reduce((acc, cur) => acc += cur, 0)
    let crSATSum = collegeReadySATItems.reduce((acc, cur) => acc += cur, 0)
    let collegeReadyACT = crACTLen > 0 ? Math.round(crACTSum/crACTLen): 0
    let collegeReadySAT = crSATLen > 0 ? Math.round(crSATSum/crSATLen): 0
    let collegeReady = testKind === TestKind.ACT ? collegeReadyACT: collegeReadySAT

    return {
      pre: avgPre,
      post: avgPost,
      growth: avgPost - avgPre,
      equivalentScore,
      collegeReady
    }
  }

  let filteredSchools =  schoolNames.flatMap(ss => lessons?.schools.filter(s => s.name === ss || ss === "all"))
  let schoolsSummaries = filteredSchools.map(school => {
    let lessonsStarted = 0
    let lessonsCompleted = 0
    let lessonsContinued = 0
    let questionsAnswered = 0
    let totalTimeOnQuestions = 0
    let totalStudyTime = 0
    let filteredGraduationYears = graduationYears
                                    .flatMap(sgy => school?.graduationYears.filter(gy => gy.year === sgy))
                                    .filter(d => d !== undefined) as GraduationYear[]
    let averagesE = getCategoricalAverages(filteredGraduationYears, "English", timeFrame, testKind)
    let averagesM = getCategoricalAverages(filteredGraduationYears, "Math", timeFrame, testKind)
    let averagesR = getCategoricalAverages(filteredGraduationYears, "Reading", timeFrame, testKind)
    let averagesS = getCategoricalAverages(filteredGraduationYears, "Science", timeFrame, testKind)
    
    filteredGraduationYears.forEach(filteredGrauationYear => {
      let timelyData: TimelyData = filteredGrauationYear[timeFrame]
      lessonsStarted += timelyData?.lessons?.progress.nofLessonsStarted || 0
      lessonsCompleted += timelyData?.lessons?.progress.nofLessonsCompleted || 0
      lessonsContinued += timelyData?.lessons?.progress.nofLessonsContinued || 0
      questionsAnswered += timelyData.lessons?.progress.totalQuestionsAnswered || 0
      totalTimeOnQuestions += timelyData.lessons?.progress.totalTimeOnQuestions || 0
      totalStudyTime += timelyData.lessons?.progress.totalStudyTime || 0
    })

    return {
      name: school?.name,
      rawdata: school,
      lessonsStarted,
      lessonsCompleted,
      lessonsContinued,
      questionsAnswered,
      totalTimeOnQuestions,
      totalStudyTime,
      accuracies: {
        English: {
          preLessonAccurracy: averagesE.pre,
          postLessonAccuracy: averagesE.post,
          lessonGrowth: averagesE.growth,
          equivalentScore: averagesE.equivalentScore, 
          collegeReady: averagesE.collegeReady
        },
        Math: {
          preLessonAccurracy: averagesM.pre,
          postLessonAccuracy: averagesM.post,
          lessonGrowth: averagesM.growth,
          equivalentScore: averagesM.equivalentScore, 
          collegeReady: averagesM.collegeReady
        },
        Reading: {
          preLessonAccurracy: averagesR.pre,
          postLessonAccuracy: averagesR.post,
          lessonGrowth: averagesR.growth,
          equivalentScore: averagesR.equivalentScore, 
          collegeReady: averagesR.collegeReady
        },
        Science: {
          preLessonAccurracy: averagesS.pre,
          postLessonAccuracy: averagesS.post,
          lessonGrowth: averagesS.growth,
          equivalentScore: averagesS.equivalentScore, 
          collegeReady: averagesS.collegeReady
        },
      }
    }
  })

  let accuracyE = getSchoolAccuracyInfoByCategory(schoolsSummaries, "English", testKind)
  let accuracyM = getSchoolAccuracyInfoByCategory(schoolsSummaries, "Math", testKind)
  let accuracyR = getSchoolAccuracyInfoByCategory(schoolsSummaries, "Reading", testKind)
  let accuracyS = getSchoolAccuracyInfoByCategory(schoolsSummaries, "Science", testKind)

  let summary = {
    lessonsStarted: 0,
    lessonsCompleted: 0,
    lessonsContinued: 0,
    questionsAnswered: 0,
    totalTimeOnQuestions: 0,
    totalStudyTime: 0,
    categories: {
      English: {...accuracyE},
      Math:    {...accuracyM},
      Reading: {...accuracyR},
      Science: {...accuracyS},
    }
  }

  schoolsSummaries.reduce((acc, cur) => {      
    acc.lessonsStarted += cur.lessonsStarted
    acc.lessonsCompleted += cur.lessonsCompleted
    acc.lessonsContinued += cur.lessonsContinued
    acc.questionsAnswered += cur.questionsAnswered
    acc.totalTimeOnQuestions += cur.totalTimeOnQuestions
    acc.totalStudyTime += cur.totalStudyTime

    return acc
  }, summary)

  let overallSummary = {summary, schools: schoolsSummaries}

  return overallSummary
}


//--- Lessons performances related 

interface IOptions {
  schoolNames?: string[],
  lessonIds?: string[],
  graduationYears?: string[],
  timeFrame?: SITimeFrame
}

export interface IStats {
  total: number
  size: number
  avg: number
}

export interface IStatsTonP {
  timeOnQuestions: IStats
  timeOnTotalStudy: number
}

export interface IStatsCollegeReady {
  size: number
  sizeACT: number
  sizeSAT: number
  avgACT: number
  avgSAT: number
}

export interface ILessonStats {
  lessonId: string
  lessonName: string
  category: string
  nofStudents: number
  statsPreAccuracy: IStats
  statsPostAccuracy: IStats
  statsHintsOnIncorrect: IStats
  statsTonP: IStatsTonP
  statsCollegeReady: IStatsCollegeReady
}

export interface ISchoolLessonStats extends ILessonStats {  
  schoolName: string
}

export interface IGroupedLesson {
  lessonId: string
  lessonName: string
  category: string
  stats: ILessonStats,
  schoolsStats: ISchoolLessonStats[]
}

export interface IGroupedLessonsMap {
  [lessonId: string]: IGroupedLesson
}

export interface ICategoricalLessonsPerformances {
  English: IGroupedLessonsMap
  Math:    IGroupedLessonsMap
  Reading: IGroupedLessonsMap
  Science: IGroupedLessonsMap
}

// From schools to lessons by using graduation year, timeFrame
function getAllLessonsStatsFromAllSchools(allSchoolsData, options: IOptions={}): ISchoolLessonStats[] {
  let {schoolNames, lessonIds, graduationYears, timeFrame="allTime"} = options
  let allLessonsForAllSchools: ISchoolLessonStats[] = []
  let lessonProcessedMap = {}

  for (let schoolData of allSchoolsData) {
    let {schoolName, lessonsPerformances} = schoolData
    
    // Process school?
    if (schoolNames && !schoolNames.includes(schoolName)) continue

    for (let lpGraduationYear of lessonsPerformances.graduationYears) {
      let lessonsMap = lpGraduationYear[timeFrame]
      let {year} = lpGraduationYear
      
      // Process the data for the timeFrame?
      if (!lessonsMap) continue

      // Process graduation year?
      if (year === "all" || (graduationYears && !graduationYears.includes(year))) continue
      // if (graduationYears && !graduationYears.includes(year)) continue

      let selectedLessonIds = Object.keys(lessonsMap)
      for (let lessonId of selectedLessonIds) {
        let key = `${schoolName}-${year}-${lessonId}`
        let lesson = lessonsMap[lessonId]

        // Process the lesson?
        if (lessonProcessedMap[key] || (lessonIds && !lessonIds.includes(lessonId))) continue
        
        lessonProcessedMap[key] = true
        allLessonsForAllSchools.push(lesson)
      }
    }
  }

  // Sort by lessonId
  allLessonsForAllSchools.sort((l1, l2) => l1.lessonId.localeCompare(l2.lessonId))
  
  return allLessonsForAllSchools
}

function groupDataByLessonsAndSchools(rawData): IGroupedLessonsMap {
  let data = deepCopy(rawData) // // OT: Deep copy needed!
  let avg = ({total, size}) => size ? total / size : -1
  let updateStats = (currentStats, newStats) => {
    let {nofStudents, statsPreAccuracy, statsPostAccuracy, statsTonP, statsHintsOnIncorrect, statsCollegeReady} = newStats

    // # of students
    currentStats.nofStudents += nofStudents

    // Pre accuracy
    currentStats.statsPreAccuracy.size += statsPreAccuracy.size
    currentStats.statsPreAccuracy.total += statsPreAccuracy.total
    currentStats.statsPreAccuracy.avg = avg(currentStats.statsPreAccuracy)

    // Post accuracy
    currentStats.statsPostAccuracy.size += statsPostAccuracy.size
    currentStats.statsPostAccuracy.total += statsPostAccuracy.total
    currentStats.statsPostAccuracy.avg = avg(currentStats.statsPostAccuracy)

    // Time on questions
    currentStats.statsTonP.timeOnQuestions.size += statsTonP.timeOnQuestions.size
    currentStats.statsTonP.timeOnQuestions.total += statsTonP.timeOnQuestions.total
    currentStats.statsTonP.timeOnTotalStudy += statsTonP.timeOnTotalStudy
    currentStats.statsTonP.timeOnQuestions.avg = avg(currentStats.statsTonP.timeOnQuestions)

    // Hints on incorrect
    currentStats.statsHintsOnIncorrect.size += statsHintsOnIncorrect.size
    currentStats.statsHintsOnIncorrect.total += statsHintsOnIncorrect.total
    currentStats.statsHintsOnIncorrect.avg = avg(currentStats.statsHintsOnIncorrect)

    // College ready
    currentStats.statsCollegeReady.size += statsCollegeReady.size
    currentStats.statsCollegeReady.sizeACT += statsCollegeReady.sizeACT
    currentStats.statsCollegeReady.sizeSAT += statsCollegeReady.sizeSAT
    currentStats.statsCollegeReady.avgACT = currentStats.statsCollegeReady.size > 0 ? 100.0*currentStats.statsCollegeReady.sizeACT/currentStats.statsCollegeReady.size: -1
    currentStats.statsCollegeReady.avgSAT = currentStats.statsCollegeReady.size > 0 ? 100.0*currentStats.statsCollegeReady.sizeSAT/currentStats.statsCollegeReady.size: -1
  }
  let groupedLessonsMap: IGroupedLessonsMap = data.reduce((acc, cur) => {
    let {lessonId, lessonName, category, schoolName} = cur
    
    if (!acc[lessonId]) {
      acc[lessonId] = {
        lessonId,
        lessonName,
        category,
        stats: {
          nofStudents: 0,
          statsPreAccuracy: {size: 0, total: 0, avg: -1},
          statsPostAccuracy: {size: 0, total: 0, avg: -1},
          statsTonP: {timeOnQuestions: {size: 0, total: 0, avg: -1}, timeOnTotalStudy: 0},
          statsHintsOnIncorrect: {size: 0, total: 0, avg: -1},
          statsCollegeReady: {size: 0, sizeACT: 0, sizeSAT: 0, avgACT: -1, avgSAT: -1}        
        }, 
        schoolsStats: [] // Stats for all schools that have this lesson
      }
    }

    // Update lesson level stats
    updateStats(acc[lessonId].stats, cur)
    
    let existingSchoolStats = acc[lessonId].schoolsStats.find(stats => stats.schoolName === schoolName)

    if (existingSchoolStats) {
      // Update school level stats
      updateStats(existingSchoolStats, cur)
    } 
    else {
      acc[lessonId].schoolsStats.push(cur)
    }
    
    return acc
  }, {})  

  return groupedLessonsMap
}

/**
 * Gets the categorial lessons performance stats
 * @param data 
 * @param options 
 * @returns 
 */
export function getCategorialLessonsPerformances(rawData, options: IOptions={}): ICategoricalLessonsPerformances {
  let data = deepCopy(rawData) // OT: Deep copy needed!
  let allLessonsByLessons = getAllLessonsStatsFromAllSchools(data, options)
  let lessonsE = allLessonsByLessons.filter(d => d.category === CATEGORY_ENGLISH)
  let lessonsM = allLessonsByLessons.filter(d => d.category === CATEGORY_MATH)
  let lessonsR = allLessonsByLessons.filter(d => d.category === CATEGORY_READING)
  let lessonsS = allLessonsByLessons.filter(d => d.category === CATEGORY_SCIENCE)
  let groupedLessonsE = groupDataByLessonsAndSchools(lessonsE)
  let groupedLessonsM = groupDataByLessonsAndSchools(lessonsM)
  let groupedLessonsR = groupDataByLessonsAndSchools(lessonsR)
  let groupedLessonsS = groupDataByLessonsAndSchools(lessonsS)
  let categoricalLessonsStats = {
    English: groupedLessonsE,
    Math:    groupedLessonsM,
    Reading: groupedLessonsR,
    Science: groupedLessonsS,
  }

  return categoricalLessonsStats
}

function deepCopy(data) {
  return  JSON.parse(JSON.stringify(data))
}

//--- TonP Details ---

export interface ITonPDetailsReport {
  schoolName: string
  tonpDetails:{
    graduationYears: ITonPDetailsForGraduationYear[]
  }
}

interface ITonPDetailsForGraduationYear {
  year: string
  nofStudents: number
  allTime: ITonPDetailsTimeFrame
  lastDay: ITonPDetailsTimeFrame
  lastWeek: ITonPDetailsTimeFrame
  lastMonth: ITonPDetailsTimeFrame  
  currentAcademicYear: ITonPDetailsTimeFrame
}

interface ITonPDetailsTimeFrame {
  timeStudied: {
    summary: ITimeStudiedSummary,
    days: ITimeStudiedDay[]
  }
}

interface ITimeStudiedSummary {
  lessons: number
  tests: number
  mistakebanks: number
  staysharps: number
}

interface ITimeStudiedDay {
  date: string
  lessons: number
  tests: number
  mistakebanks: number
  staysharps: number
}

interface ITonPDetails {
  timeStudied: {
    summary: ITimeStudiedSummary,
    days: ITimeStudiedDay[]
  }
}

interface IAllTonPDetails {
  summary: ITimeStudiedSummary
  days:    ITimeStudiedDay[]
  schools: ITimeStudiedSchool[]
}

interface ITimeStudiedSchool {
  schoolName: string
  summary: ITimeStudiedSummary
  graduationYears: ITonPDetailsForGraduationYearSummary[]
}

interface ITonPDetailsForGraduationYearSummary {
  year: string
  summary: ITimeStudiedSummary
}


/**
 * Get TonP details: Top level summary, day by day summaries, and school by school summaries
 * 
 * @param tonpDetailsReports 
 * @param graduationYears 
 * @param schoolNames 
 * @param timeFrame 
 * @returns all tonp related details
 */
export function getAllTonpDetails(tonpDetailsReports: ITonPDetailsReport[], graduationYears: string[], schoolNames: string[], timeFrame): IAllTonPDetails {
  let filteredTonPDetails = getFilteredTonPDetails(tonpDetailsReports, graduationYears, schoolNames, timeFrame)
  let allTonPDetails = {
    summary: getTonPDetailsSummary(filteredTonPDetails),
    days:    getTonPDetailsByDays(filteredTonPDetails),
    schools: getTonPDetailsForSchools(tonpDetailsReports, graduationYears, schoolNames, timeFrame),
  }
  return allTonPDetails
}

function getFilteredTonPDetails(tonpDetailsReports: ITonPDetailsReport[], graduationYears: string[], schoolNames: string[], timeFrame): ITonPDetails[] {
  let timeFrameKey = timeFrame

  // Filter by school names, graduation years and time frame
  let filteredBySchoolsTonPDetails = tonpDetailsReports.filter(d => schoolNames.includes(d.schoolName))
  let queryGY = `$..tonpDetails.graduationYears`
  let allGraduationYears = jp.query(filteredBySchoolsTonPDetails, queryGY).flat()
  let filteredGraduationYears = allGraduationYears.filter(gy => graduationYears.includes(gy.year))
  let timeFrameQuery = `$..${timeFrameKey}`
  let filteredTonpDetails = jp.query(filteredGraduationYears, timeFrameQuery)
  return filteredTonpDetails
}

function getTonPDetailsByDays(tonpDetails: ITonPDetails[]): ITimeStudiedDay[] {
  let queryDays = `$..days`
  let daysJP = jp.query(tonpDetails, queryDays).flat() as ITimeStudiedDay[]

  // The daysJP may have multiple of the same days, now we will combine them together!
  let daysMap = daysJP.reduce((acc, cur) => {
    const currentDate = cur.date
    if (!acc[currentDate]) {
      acc[currentDate] = { date: currentDate, lessons: 0, tests: 0, mistakebanks: 0,  staysharps: 0}
    }
    acc[currentDate].lessons += cur.lessons
    acc[currentDate].tests += cur.tests
    acc[currentDate].mistakebanks += cur.mistakebanks
    acc[currentDate].staysharps += cur.staysharps
    return acc
  }, {})

  let tonpDetailsByDays = Object.values(daysMap) as ITimeStudiedDay[]
  tonpDetailsByDays.sort((d1, d2) => d1.date.localeCompare(d2.date))

  return tonpDetailsByDays
}

function getTonPDetailsSummary(tonpDetails: ITonPDetails[]): ITimeStudiedSummary {
  let initialSummary = {
    lessons: 0,
    tests: 0,
    mistakebanks: 0,
    staysharps: 0
  }
  let timeStudiedSummaryQuery = `$..timeStudied.summary`
  let timeStudiedSummaryJP = jp.query(tonpDetails, timeStudiedSummaryQuery)
  let timeStudiedSummary = timeStudiedSummaryJP.reduce((acc, cur) => {
    acc.lessons += cur.lessons
    acc.tests += cur.tests
    acc.mistakebanks += cur.mistakebanks
    acc.staysharps += cur.staysharps
    return acc
  }, initialSummary)

  return timeStudiedSummary
}

function getTonPDetailsForSchools(tonpDetailsReports: ITonPDetailsReport[], graduationYears: string[], schoolNames: string[], timeFrame) {
  let tonpDetailsForSchoolsMap = {}
  schoolNames.forEach(schoolName => {
    graduationYears.forEach(graduationYear => {
      let filteredTonPDetails = getFilteredTonPDetails(tonpDetailsReports, [graduationYear], [schoolName], timeFrame)    
      let summary = getTonPDetailsSummary(filteredTonPDetails)      
      tonpDetailsForSchoolsMap[schoolName] = tonpDetailsForSchoolsMap[schoolName] || {}
      tonpDetailsForSchoolsMap[schoolName][graduationYear] = summary
    })
  })

  let schools = [] as any[]
  schoolNames.forEach(schoolName => {
    // Let's find out the top level summary for each school
    let initialSummary = {lessons: 0, tests: 0, mistakebanks: 0, staysharps: 0}
    let schoolSummary = graduationYears.reduce((acc, cur) => {
      let currentSummary = tonpDetailsForSchoolsMap[schoolName][cur]
      acc.lessons += currentSummary.lessons
      acc.tests += currentSummary.tests
      acc.mistakebanks += currentSummary.mistakebanks
      acc.staysharps += currentSummary.staysharps      
      return acc
    }, initialSummary)
    let graduationYearsTonPDetails = [] as any[]
    graduationYears.forEach(graduationYear => {
      graduationYearsTonPDetails.push({
        year: graduationYear,
        summary: tonpDetailsForSchoolsMap[schoolName][graduationYear]
      })
    })
    schools.push({
      schoolName,
      summary: schoolSummary,
      graduationYears: graduationYearsTonPDetails
    })
  })

  return schools
}

/**
 * Gets the students practice test insights based on provided school and test name
 * The results will be cached and in the next request will be served from there.
 * 
 * @param schoolName 
 * @param testName 
 * @returns PT insights
 */
export async function getStudentsTestInsightsBySchoolAndTestName(schoolName: string, testName: string): Promise<ITestInsight[]|ITestInsightACT[]|ITestInsightSAT[]> {
  let testType = getTestTypeBasedOnName(testName)
  let cacheKey = `si-test-${schoolName}-${testType}`
  let data = getCachedValue(cacheKey)

  if (!data) {
    // Get data from service and set it in the cache!
    data = await getStudentsTestInsightsBySchoolTestTypeAndGraduationYears(schoolName, testType)
    setCacheValue(cacheKey, data)
  }

  // This step is needed to get the insights based on the test name
  let insights = await getTestsInsightsByTestName(testName, data)

  return insights
}

/**
 * Gets the school's students TonP info
 * 
 * @param schoolName 
 * @returns students' TonP info
 */
export async function getStudentsTimeOnPlatformInfo(schoolName: string): Promise<any[]> {
  let cacheKey = `si-tonp-${schoolName}`
  let data = getCachedValue(cacheKey)

  if (!data) {
    // Get data from service and set it in the cache!
    data = await getStudentsTimeOnPlatformInfoBySchool(schoolName)
    setCacheValue(cacheKey, data)
  }

  return data
}

/**
 * Gets the school's students practice test insight details
 * @param schoolName 
 * @param testName 
 * @returns students' PT insight details
 */
export async function getStudentsTestInsightDetailsBySchoolAndTestName(schoolName: string, testName: string): Promise<ITestInsightDetails[]> {
  let cacheKey = `si-test-insight-details-${schoolName}-${testName}`
  let data = getCachedValue(cacheKey)

  if (!data) {
    // Get data from service and set it in the cache!
    data = await getStudentsTestInsightDetailsBySchoolAndTestNameFromEndPoint(schoolName, testName)
    setCacheValue(cacheKey, data)
  }
  
  return data
}
