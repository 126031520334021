export const resources = [
    {
        "name": "Intro to Mindset Moments",
        "showPopupLink": false,
        "lessons": [
            {
                "name": "Tips for Engagement",
                "videoId": "6353551983112",
                "length": "6:00"
            },
            {
                "name": "Listening Skills",
                "videoId": "6353552770112",
                "length": "4:20"
            },
            {
                "name": "Importance of Resilience",
                "videoId": "6353551678112",
                "length": "5:30"
            }
        ]
    },
    {
        "name": "Eight Categories of Resilience Model",
        "showPopupLink": true,
        "lessons": [
            {
                "name": "What are Beliefs?",
                "videoId": "6353551875112",
                "length": "4:15"
            },
            {
                "name": "What are Interpersonal Skills?",
                "videoId": "6353553173112",
                "length": "3:35"
            },
            {
                "name": "What are Mind-Body Skills?",
                "videoId": "6353551877112",
                "length": "3:29"
            },
            {
                "name": "What are Needs and Values?",
                "videoId": "6353552973112",
                "length": "3:31"
            },

            {
                "name": "What are Strengths?",
                "videoId": "6353552974112",
                "length": "4:58"
            },

            {
                "name": "What is Emotional Intelligence?",
                "videoId": "6353552379112",
                "length": "5:13"
            },

            {
                "name": "What is Spirit?",
                "videoId": "6354222348112",
                "length": "4:27"
            },
            {
                "name": "What is Inner Wisdom?",
                "videoId": "6353552268112",
                "length": "4:30"
            },
        ]
    }
]

