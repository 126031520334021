export const rawGrades = [
    {
      "grade": 5,
      "week": 1,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade5week1-bartbounce.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life.\n \nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves.\n \nThe other good news is you are already resilient! You have probably picked up quite a few resilience skills already in life. For example, if you…\n\n* Can name your personal strengths\n* Have good-feeling relationships\n* Reach out for support when you need it\n* Take care of yourself–physically, mentally, emotionally and spiritually\n* Learn from your losses\n* Use your breath to calm down and focus\n* Trust your gut\n\nIf you do any of these things, you are already resilient.\n \nThese Resilience Reminders™ will add more skills to your list and remind you of helpful things you already know.",
      "questions": "How do you define resilience? What words do you use?\n \nHow do you demonstrate resilience? Which skills do you use?\n \nWhat disappointment, challenge or failure are you most proud of yourself for overcoming, and what skills did you use to feel better?\n\nWhat is one way you take care of yourself–physically, mentally, emotionally or spiritually?\n\nDo you agree that it demonstrates resilience to reach out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 2,
      "name": "Own Your SuperPowers",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade5week2-spongebobsuperpowers.gif",
      "summary": "Resilient people know that the more they use their strengths, the more empowered and happier they are, so they own their superpowers and make the most of them.",
      "readAloud": "Life is full of things that are hard. Math is hard for some people. Speaking in public is hard for others. Singing, dancing, focusing on boring homework, shooting hoops, telling jokes and making friends—all of these things are hard for some people. But they are not hard for everyone. And the people who find these things difficult have plenty of other things they are good at.\n  \nEveryone has a different set of personal strengths. And when you own your strengths and use them often, you feel good about yourself.\n \nThink about it: When you spend time doing things that come easily to you, that you are good at, and that you enjoy doing, you naturally feel good about yourself. Using your strengths builds confidence and self-esteem.\n  \nUsing your strengths is also a good way to forget about your troubles for a while. If you can get lost in the act of doing something you enjoy like drawing or playing basketball or singing or playing an instrument, you can shift your mood from not-so-great to better.\n  \nStrengths don’t have to be something you can make a career out of, although they can be. Maybe you are a good listener or a good dancer or a good baker or you are good at making your friends laugh. If it makes you feel good about yourself, consider it a strength. Also, consider it important and look for ways to use it more often.",
      "questions": "What is one of your strengths?\n \nWhat would your friends, family members, teachers, coaches, or mentors say is one of your strengths?\n \nWhat strengths do you admire in others?\n \nWhat strengths would you like to build in yourself?\n \nIf you could make a career out of one of your strengths what would it be?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 5,
      "week": 3,
      "name": "Believe in Your Ability to Learn",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade5week3-homerbelieve.gif",
      "summary": "Resilient people know that mastering something takes practice. They don’t expect themselves to be perfect right from the start. Instead, they believe that with hard work they can learn whatever they put their minds to.",
      "readAloud": "Life is about learning. As babies, we have to learn to walk and talk and feed ourselves. Then we move on to more difficult things like reading, writing, solving math problems and playing sports.\n \nWhen these early accomplishments come easily to people, they are sometimes told that they are “smart.” But telling a kid they are smart can actually make them feel insecure.* Here\u0027s why:\n \nOften, when someone believes they are smart, they think everything should come easily to them. Then, when faced with something more difficult, they think something is wrong with them that it isn\u0027t coming easily. They are not used to working hard, asking for help, and persisting until they figure it out, so they get discouraged, worry that maybe they are not actually smart, and give up.\n \nOn the other hand, resilient people know that mastering something requires practice. They also know that if they work hard enough, they can figure it out. Instead of saying, “I’m not good at that,” they say, “I haven’t learned that yet,\" or “I’m working on that.”\n \nSo, believe in your ability to learn and there will be nothing you cannot accomplish.\n\n*(Also, if someone tells you that you are not smart, tell them they are wrong, or tell them that you just haven\u0027t learned that yet.)",
      "questions": "What is something you are proud of yourself for learning because it took a lot of work to get good at it?\n\nHow do you keep yourself from getting discouraged when you are having trouble learning something new? \n\nIf you want to learn something new or improve something, what can you do to help yourself?\n\nCan you name a time when you asked someone to help you learn or improve something?\n\nCan you understand why telling someone they are smart could actually make them feel insecure?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 4,
      "name": "Get Help When You Need It",
      "category": "Needs/Values",
      "subCategory": "Interpersonal",
      "image": "grade5week4-bartgethelp.gif",
      "summary": "Resilient people know that asking for help when they need it is a strength not a weakness so they don’t hesitate to reach out for support when faced with a challenging situation.",
      "readAloud": "Many people think that asking for help is a sign of weakness. They think they should be able to handle their problems on their own. They don’t want to seem needy, so they suffer in silence pretending they are fine when they are actually not okay and don’t know what to do.\n \nBut everyone needs help sometimes. Disappointments, setbacks, failures, and obstacles that feel overwhelming are a part of life. No one gets to skate through life without facing hardship. And asking for help is one of the most courageous, smart things you can do.\n \nYes, it feels vulnerable to ask for help, but most people are happy to support someone in need. It feels good to help someone. It gives meaning to our lives to have a positive impact in other people’s lives.\n \nSo the next time you feel upset and alone, reach out and allow someone to support you. You will both feel better if you do.",
      "questions": "Many people have a hard time asking for help. Where do you think people learn that they should handle their problems on their own?\n \nCan you describe a time when you helped someone when they needed it, and how did it feel to help them?\n \nCan you describe a time when you reached out for support when you needed it, and how did it feel to allow someone to help you?\n \nCan you think of a time when you did not ask for help when you needed it but now wish you had?\n \nCan you think of anything your school could do to support students in being more supportive of each other, or do you think that up to individual students?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 5,
      "name": "Trust Your Gut",
      "category": "Inner Wisdom",
      "subCategory": "Spirit",
      "image": "grade5week5-chewytrust.gif",
      "summary": "Resilient people know they can feel in their bodies whether something is good or bad for them, so they trust their guts.",
      "readAloud": "We live in a world where many people believe thinking is a more valuable skill than feeling. After all, we spend decades in school learning how to think but very little, if any, time paying attention to our feelings.\n\nBut tuning into our gut feelings can be a superpower if you start paying attention.\n\nWe can feel in our bodies whether a decision is good for us or not good for us. We can also feel in our bodies whether a person is open, trustworthy, and has our best interest at heart, or if they do not.\n\nWhether you call this a gut feeling, intuition, inner voice, or higher power, we all have a source of wisdom inside of us that will guide us to make good decisions if we listen to it.\n \nOn the other hand, our thinking minds can get hijacked by negative thoughts and other people’s agendas, and this can cause us to ignore the warning bells going off in our bodies and make bad decisions. For example:\n\nWhen our mind thinks negative thoughts like, “I’m not good enough. I shouldn’t even try,” our Inner Wisdom says, “Believe in yourself. You can do this.”\n\nOr, when we’re trying to convince ourselves to go along with something we know is wrong, we tell ourselves things like, “I won’t get caught. It’s only once. It’ll probably be fine,” but our gut says: “Danger. Something doesn’t feel right here. Don’t do this.”\n\nOur Inner Wisdom protects us, reminds us of our best qualities, and pushes us to go for our dreams.\n\nSo the next time you have a decision to make, do a gut check and trust your Inner Wisdom.",
      "questions": "Can you describe a time when listening to a gut feeling protected you from making a mistake or putting yourself in danger? \n\nCan you describe a time when following your gut caused something really great to happen in your life? \n\nSome people call it a gut feeling. Others call it intuition, their inner voice, or what they know in their heart. Still others think it is the voice of God. What words do you use to describe the way you just know whether something is good or bad for you?\n \nPeople call it a gut feeling because they feel it in their gut. Where do you feel it when you just have a feeling you should or shouldn\u0027t do something?\n \nWhat situations do you think tuning in to your gut feelings would be helpful in? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 6,
      "name": "Find the Good in Negative Emotions",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade5week6-insideoutemotions.gif",
      "summary": "Resilient people know that negative emotions are not bad. In fact, negative emotions can be good if we use them to take care of ourselves.",
      "readAloud": "Negative emotions feel bad, but that doesn’t mean they are bad. Unfortunately, many people are taught that their negative emotions are wrong. Boys shouldn’t cry. Girls shouldn’t be angry. People shouldn’t be so dramatic or emotional.\n\nMany adults seem to believe it’s not appropriate to be angry when someone is rude to you, sad when you lose something, or afraid when something is challenging or unknown.\n \nBut emotions—negative and positive emotions—are natural responses that can help you make decisions and take care of yourself, if you use them correctly. If you get curious about what your negative emotions have to tell you, instead of ignoring them or pretending like you don’t feel them, you can figure out what you need to feel better and take an action step to take care of yourself.  \n \nAnger is often telling us we deserve better. Sadness is often reminding us that something or someone is important to us. Fear is always trying to protect us.\n \nEmotions are natural and appropriate. Sometimes the way we respond to our emotions is not. So, when you feel a negative emotion, ask yourself what you need to feel better and take an action step to take care of yourself. That is finding the good in negative emotions.",
      "questions": "Have you ever been told to keep certain emotions to yourself? If so, how did that make you feel? \n\nHave you ever noticed that emotions get bigger if you stuff them down and then explode in an overreaction at a later time? Why do you think that is? \n\nCan you describe a time when you listened to what a negative emotion was telling you and took care of yourself?\n\nIt can be really hard not to just react and do or say something you’ll regret when you feel really angry or upset. How do you get control of yourself and calm down when you are really upset? Some people count to 10. Others take a deep breath. What do you do?\n \nDo you believe it’s natural to feel negative emotions sometimes, or do you think you should be able to avoid feeling negative emotions?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Inside Out © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 7,
      "name": "Use Your Breath to Calm Down and Focus",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade5week7-breathe.gif",
      "summary": "Resilient people know that their breath is their best tool for calming down, collecting their thoughts, and focusing when they\u0027re nervous, upset, or need to perform.",
      "readAloud": "Many people do not realize that their greatest tool for calming down when they are nervous or upset about something is always with them... It\u0027s their breath.\n \nUsing your breath to calm down and focus is a super power. And it’s super easy to do.\n \nTake a slow, deep breath right now and focus on the SOUND of the air going in and out of your nose…\n \nTake another slow, deep breath and this time focus on the FEEL of your belly expanding as the air goes in and then contracting as the air goes out…\n \nTake another slow, deep breath and this time, after you fill your belly all the way up, IMAGINE ALL OF YOUR MUSCLES RELAXING as you slowly let the air back out again.\n \nYou just meditated! It’s as easy as that.\n \nFocusing on your breath is an easy way to get your mind to pay attention to what\u0027s going on in your body, instead of on whatever has you nervous or upset. And when you focus on your breath, you naturally calm down.\n \nEven if you only have time for two or three deep breaths, you’ll get the benefit of feeling a little bit more calm and focused, which helps you feel better if you\u0027re upset and perform better if you’re nervous. It also helps you collect your thoughts if you have an important decision to make or during an argument.",
      "questions": "Can you describe a time you took a few deep breaths to calm down when you were NERVOUS or UPSET about something and it worked?\n \nCan you describe a time you used your breath to calm down and PERFORM better in a sport or another performance activity?\n\nCan you describe a time you used your breath to calm down in the middle of an ARGUMENT? \n\nCan you describe a time you used your breath to calm down and collect your thoughts when you had an important DECISION to make?\n\nHave you ever done any other kind of meditation? If so, what was it like and how did it make you feel?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company."
    },
    {
      "grade": 5,
      "week": 8,
      "name": "Take Care of Yourself",
      "category": "Needs/Values",
      "subCategory": "Spirit",
      "image": "grade5week8-poohtakecare.gif",
      "summary": "Resilient people take care of themselves - physically, mentally, emotionally, and spiritually - especially when they are going through a difficult time, when they are feeling stressed, or when they want to perform well.",
      "readAloud": "By the time people get to be adults they often have a million reasons why they don’t take better care of themselves—they’re too busy, there’s not enough time, there are too many other people they have to take care of—so their self-care gets put on the back burner.\n\nAnd when you are a kid, you have to rely on others to take care of you. Even if you want to be thoughtful about your self-care, many aspects of that—from the food you eat, to the activities you engage in, to the people you are surrounded by—are in large part controlled by your parents.\n\nHowever, taking responsibility for taking care of yourself is something best done early in life, so you can build lifelong habits that support your mental, physical, emotional, and spiritual wellbeing.\n \nWhen people think about self-care, they often think about nurturing their bodies with healthy food, exercise and plenty of water and sleep. It’s also important, though, to take care of your mind and spirit.\n \nThere are lots of things you can do to support your mental and emotional health, including surrounding yourself with good-feeling people, having people you can talk to when you are upset, not keeping your thoughts and emotions bottled up, making time for people and activities you enjoy, not being too critical of yourself, and seeking out things that make you laugh.\n \nAnd spiritual health can be supported by engaging in calming activities like meditation and journaling that help you soothe yourself and collect your thoughts as well as anything that nourishes your spirit by giving your life meaning and a sense of purpose.\n \nBottom line: take responsibility for taking care of your own mind, body and spirit, and you will show up as the best version of yourself every day.",
      "questions": "How do you take care of your body?\n\nHow do you take care of your mind?\n\nHow do you take care of yourself emotionally when you are feeling upset about something?\n\nWhy do you think it helps people feel better emotionally to talk about their problems with a supportive person instead of keeping it bottled up inside of them?\n\nHow do you take care of your spirit?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Winnie the Pooh © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 9,
      "name": "Live Up to Your Potential (Not Someone Else\u0027s)",
      "category": "Strengths",
      "subCategory": "Values",
      "image": "grade5week9-homerliveup.gif",
      "summary": "Resilient people know they have something unique and valuable to offer the world, and they pursue their personal strengths, interests and values to live up to their potential.",
      "readAloud": "Everyone has the potential for greatness.\n\nThe problem is, people often spend more time comparing themselves to others and trying to be something they’re not than they do developing the things they are naturally good at and that they are actually interested in.\n \nInstead of deciding for themselves what kind of greatness they want to be, they spend their time trying to measure up to someone else’s standards of greatness.\n \nHowever, when you realize that the things that make you unique, that you enjoy, that you are good at, and that you genuinely care about are the very things that will propel you to your own, special kind of greatness, you can stop looking to others for validation and start living up to your own potential.\n\nHere’s a shortcut: STRENGTHS, INTERESTS, VALUES. Know what yours are, spend as much time as possible pursuing them, and you will feel and be your best. You will also live up to your potential, not someone else\u0027s. ",
      "questions": "What are your personal STRENGTHS? (What are you good at? What comes easy to you?)\n \nWhat are your personal INTERESTS? (What do you look forward to doing? What do you want to do more often?) \n\nWhat are your personal VALUES? (What kind of person do you want to be? What kind of life do you want to have?) \n\nHow do you define GREATNESS for yourself? (What will you have to do to achieve your kind of greatness?) \n\nWhat are some of the reasons you think people don’t live up to their potential?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 10,
      "name": "Don\u0027t Let Failure Define You, Use it to Grow",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade5week10-yodafailure.gif",
      "summary": "Resilient people know that failing is a part of life, so they learn from their failures and turn them into stepping stones to success. ",
      "readAloud": "Failing is a part of life—at least for those who are willing to get in the game, push themselves to try new things, and learn from their losses.\n \nIt can be embarrassing to fail. It can make you mad and sad, and it can make you want to give up. But successful people know that failure is a part of the learning process, so they shake off their failures and take the lessons from them so they’ll do better next time.\n\nAlbert Einstein failed the entrance exam to university the first time and almost failed out of university once he did get in, but that didn’t stop him from pursuing his passion for math and physics and eventually winning the Nobel Prize.\n\nOprah Winfrey was fired from her first television job and told she was “unfit for tv,” but that didn’t stop her from becoming one of the most successful television stars ever.\n\nThomas Edison failed over 10,000 times before he created a light bulb he could sell, but he said, “I have not failed 10,000 times…  I have succeeded in proving that those 10,000 ways will not work. When I have eliminated the ways that will not work, I will find the way that will work.”\n\nFailure is only failure if you fail to learn from it and stop trying. So, the next time you fail, ask yourself what you have learned.",
      "questions": "Can you describe a time when you failed at something and learned from it?\n\nCan you think of something positive in your life that could not have happened if you hadn’t failed at something that led up to it?\n\nIs there someone you admire who failed at something and then went on to succeed?\n\nWhat does it mean about you if you fail?\n\nWhat is the biggest risk you ever took, and did you succeed or fail? \n\nIf you failed, what story do you tell about that failure?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 11,
      "name": "Choose Your Friends Wisely",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade5week11-minionschoose.gif",
      "summary": "Resilient people know that the people you hang out with shape your life, so they choose friends who inspire them, lift them up, and bring out the best in them.",
      "readAloud": "Our friendships set the tone for our lives. They shape how we think and feel about ourselves and the world. If we hang out with people who push themselves to be their best and reach for their dreams, we will likely do the same. If we hang out with people who find excuses to waste their potential and wallow in negativity, we will likely do that. \n \nHere’s a formula for choosing your “FAVIRite” people: (We know it’s not spelled correctly, but it’s close enough to help you remember it.)\n\n* Feelings – Find people who feel good to be around. You can feel in your body, in your gut, whether      someone feels kind and supportive or if they feel untrustworthy and unsafe.\n\n* Actions – Notice how you act around different people and choose friends with whom you behave in ways that make you feel good about yourself.\n\n* Values – Find people who share your values. Think about what matters to you and what kind of person you want to be and surround yourself with people who care about the same things.\n\n* Interests – Find people who share your interests. This will give you endless opportunities to have fun and make memories together.\n\n* Roles – Appreciate the unique role each friend has in your life. Some friends make us laugh. Some are good listeners. Some lift us up when we’re feeling down. \n\nWhen we choose our friends wisely and appreciate the specific ways each one adds value to our lives we maximize the gift of friendship.",
      "questions": "Do you have some friends who FEEL good to be with, who are easy to be around, and with whom you can just be yourself and others who make you feel more guarded or on edge? \n\nCan you describe ways in which you ACT differently with some friends than you do with others?\n \nWhat VALUES do you look for in your friends? (kindness, being a good athlete, doing well in school, having a sense of humor)\n\nWhat INTERESTS do you share with your friends? Do you enjoy different interests with different friends or do all your friends share the same interests?\n \nCan you describe how different friends play different ROLES in your life? Do some make you laugh while others inspire you to be a better athlete? Do some encourage you to work hard in school while others make you feel better when you are upset? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Minions © Universal City Studios LLC"
    },
    {
      "grade": 5,
      "week": 12,
      "name": "Practice Gratitude",
      "category": "Emotional Intelligence",
      "subCategory": "Interpersonal Skills",
      "image": "grade5week12-pandagratitude.gif",
      "summary": "Resilient people know that gratitude improves not only your mental health but also your physical health, so they make it a practice to be grateful.",
      "readAloud": "It makes sense that gratitude is good for your mental health, but studies show that gratitude improves our physical health too! Gratitude also improves relationships.\n\nThanksgiving is all about gratitude. But we can forget to look for things to be grateful for during the rest of the year. \n\nIt’s easy to get so caught up in the stresses of daily life that we focus on what’s going wrong in our lives instead of looking for what’s good. \n  \nThere are always things to be grateful for in our lives. We just have to make an effort to notice them.\n \nThis is why some people practice gratitude the same way they practice a sport. They set aside time every day or every week to think about what they are grateful for. Some people do it every morning. Others do it when they go to bed. Others do it when they attend spiritual services.\n\nHowever you do it, the more you practice gratitude, the better you will feel - and the healthier you will be. ",
      "questions": "Who is a person you are grateful for?\n\nWhat is an experience you are grateful for?\n\nWhat is a quality about yourself you are grateful for?\n\nCan you think of anything you are grateful for that came from something that was not so great?\n\nHow can you remember to practice gratitude more often in your daily life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Kung Fu Panda © DREAMWORKS ANIMATION L.L.C."
    },
    {
      "grade": 5,
      "week": 13,
      "name": "Listen to Messages From Your Body",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade5week13-piglisten.gif",
      "summary": "Resilient people know their bodies have important messages about safety and self-care, so they tune into and take care of their bodies.",
      "readAloud": "Safety and self-care are two ways our bodies can help us be resilient.\n\nAs human beings, we can sense danger in our bodies even before our minds are aware of it. We can also feel whether people feel safe and trustworthy or whether they feel threatening and dangerous. \n\nKids are often made to ignore those feelings and forced to hang out with people and in situations that don’t feel good to them, but the messages are there if they tune into them. Our bodies are always trying to keep us safe. \n\nOur bodies are also trying to keep us healthy. They will tell us how to take care of ourselves, if we listen. And, because our minds and bodies are connected, if we take care of our bodies, we will also feel better mentally and emotionally.\n \nChildren typically don’t have much control over what goes into their bodies, whether the food they eat is healthy or not, how much exercise they get, how much sleep they get, how much stress they feel. But they can listen to what their bodies need and take as much responsibility as possible for taking care of themselves.\n\nChildren who are not taught to pay attention to their bodies grow into adults who don’t take care of themselves, who ignore messages from their bodies, and who do not feel their best—physically, mentally or emotionally. But kids who learn to listen to their bodies have a super power that will serve them for their whole lives.",
      "questions": "When you are stressed, nervous, or upset about something, where do you feel that in your body?\n \nCan you describe a time when you could feel in your body whether someone felt like a good, safe, friendly, trustworthy person or not? \n\nWhat do you do to take care of your body? \n\nWhat is one thing you could do to take better care of yourself? \n\nLet\u0027s ask our bodies what they need right now...\nClose your eyes, if you\u0027re comfortable doing that, take a deep breath, and inside your own head, ask your body what it needs right now...\n(Give them a few seconds to tune in.)\nNow, who wants to tell us what their body said?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Sing © Universal City Studios LLC"
    },
    {
      "grade": 5,
      "week": 14,
      "name": "Know Your \"Mood Shifters\" AND Know When to Use Them",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade5week14-rollingpanda.gif",
      "summary": "Resilient people know how to shift themselves into a better mood, but they only use “mood shifters” when they are ready to feel better.",
      "readAloud": "Everyone experiences bad moods. Often, it’s because something frustrating or upsetting happens. But sometimes it feels like we just get cranky and we don’t know why.\n\nIt’s helpful to have a list in your head of things you can do to get yourself out of a bad mood. Things like: going for a walk, watching funny videos, listening to your favorite music, shooting hoops, and talking to a good friend can lift your spirits.\n\nIt’s also important not to try to get out of a bad mood until you are ready.\n \nHave you ever noticed how when someone tells you to \"Cheer Up!\" when you are not ready to feel better, it just makes you feel worse?\n\nThat\u0027s because negative emotions have important messages for us, and if we ignore them or try to push them away before we understand what they’re saying, they just get bigger. For example:\n\n* If you’re angry because someone was mean to you, you want to make sure you think about how you can take care of yourself in that relationship before you shift into a better mood.\n* If you’re upset at yourself for not doing well on a test, you want to figure out how you can study better next time before you distract yourself.\n\nIf you’re not sure why you’re feeling cranky, ask yourself: \"Why am I upset, and what do I need to feel better?\" This helps you use your emotions to take care of yourself, which is what they’re designed for.\n \nAfter that, you will be ready to feel better, and you can use whatever works for you to shift into a better mood.",
      "questions": "Why do you think it is annoying when someone tries to make you feel better when you are not ready to feel better? \n\nCan you describe a time when you tried to ignore a negative emotion or pretend like it wasn\u0027t important and it just got bigger? \n\nCan you describe a time when you were upset about something and you took as much time as you needed to figure out exactly why you were upset and what you needed to feel better? \n\nCan you name three \"mood shifters\" - things that always make you feel better when you are ready to?\n\nUsing one of your personal strengths can be a good \"mood shifter.\" For example, if you\u0027re good at drawing, singing or shooting hoops, it can lift your spirits to do those things. What is one of your personal strengths that you can use as a \"mood shifter?\"",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company."
    },
    {
      "grade": 5,
      "week": 15,
      "name": "Have Beliefs That Comfort You, Guide You, and Give Your Life Meaning",
      "category": "Spirit",
      "subCategory": "Beliefs",
      "image": "grade5week15-poohbeliefs.gif",
      "summary": "Resilient people know that our beliefs shape our lives, so they have beliefs that comfort them, guide them, and give their lives meaning.",
      "readAloud": "This time of year reminds us that spiritual beliefs are a powerful source of comfort and guidance for many people. Some beliefs give meaning to our lives. Some beliefs inspire us to be kind to each other. Some beliefs comfort us around our fears, especially around our fear of death. And many beliefs connect us to supportive communities and nurture a sense of belonging.\n \nRegardless of what you believe, choosing to live your life in alignment with beliefs that give you purpose, connect you to the most loving parts of yourself, and nurture optimism for your future is a choice worth making.\n \nMany people don’t realize that they can choose what to believe. They think that because they already believe something, it must be true. But people believe many different things, some of which are contradictory. Yet somehow, people have evidence for contradictory beliefs. \n\nSo, if you realize you have a belief that does not make you feel very good, look for evidence of something better. If you look hard enough, you will probably find it.\n \nWhatever you believe, we hope it brings you peace, joy and love this holiday season. ",
      "questions": "Do you have a belief that inspires you to be the best person you can be?\n\nDo you have a belief that motivates you to be kind to others?\n\nDo you have a belief that comforts you around any of your fears?\n\nDo you have a belief that gives your life meaning or a sense of purpose?\n\nDo you have a belief that makes you feel optimistic about your future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Winnie the Pooh © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 16,
      "name": "Set Achievable Goals (Around Strengths and Values)",
      "category": "Strengths",
      "subCategory": "Values",
      "image": "grade5week16-snoopygoals.gif",
      "summary": "Resilient people believe goals should inspire them and make them feel good about themselves, so they set achievable goals around their strengths and values.",
      "readAloud": "New Year’s Resolutions often start out as inspiring but end up making us feel disappointed in ourselves if we fail to achieve them. Here are some tips for setting goals that will make you feel good about yourself:\n\nSet goals that are small enough that you can actually get them done. If you set goals that are too big, you’ll give up, feel bad about yourself, and be less likely to set future goals for yourself. On the other hand, if you set goals you can actually achieve, you will build positive momentum that allows you to achieve goal after goal after goal.\n\nSet goals around your strengths. The more time you spend using your strengths, the better you feel about yourself. And, if you set goals around things you actually enjoy doing and that come naturally to you, you are more likely to achieve your goals, which also makes you feel good about yourself. It’s a double-whammy of feeling good about yourself! \n\nSet goals around your values. Doing things that demonstrate your personal values also makes you feel good about yourself. If you value being a good athlete, set goals around your fitness. If you value being a good student, set goals around your academics. If you value being kind, set goals around making people feel good.\n\nFind people to support you in achieving your goals. Having people to support you and hold you accountable will make you much more likely to achieve your goals.",
      "questions": "What is one goal you have for yourself right now, and does it feel achievable? \n\nCan you think of a goal to set around one of your strengths or values? \n\nCan you think of someone who could support you in achieving one of your goals? \n\nWhat is a goal you successfully achieved in the past, and what factors contributed to you achieving them? \n\nIf you think about goals you did not achieve in the past, what factors prevented you from achieving them? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Snoopy © Peanuts Worldwide"
    },
    {
      "grade": 5,
      "week": 17,
      "name": "Use Your Imagination to Take a Stress Break",
      "category": "Mind-Body Skills",
      "subCategory": "Inner Wisdom",
      "image": "grade5week17-lkstressbreak.gif",
      "summary": "Resilient people know that giving themselves a mental picture of a safe, relaxing place can help them calm down and regroup when they are stressed, so they use their imaginations to take stress breaks.",
      "readAloud": "Close your eyes, if you’re comfortable doing that, and picture in your mind the most relaxing, calm, good-feeling place you can think of. \n\nIt can be a place you’ve actually been to, a place you’ve seen or read about, or a place you make up in your imagination. You could put yourself on a tropical island, on top of a mountain you’ve never been to, or even on a cloud floating gently across the sky. \n\nJust picture a place in your mind that feels safe and quiet and calm and that makes you feel comfortable and relaxed. \n\nNow imagine yourself sitting or lying in this safe place, relaxed and happy to be there, and take five slow, deep breaths as you enjoy being in this place.\n\n(Mentors, give time for 5 deep breaths.) \n\nNow before you leave this place, look around one more time and take note of what you see. And remember, you can come back anytime you need a stress break just by putting yourself there in your imagination and taking a few deep breaths.\n\nNow, take one more deep, relaxing breath and come back to the real world. \n\nYou just meditated. You can use your imagination and a few calming breaths anytime you need to destress, recenter, tune into your Inner Wisdom, or make a decision. ",
      "questions": "Were you able to relax with this meditation? If so, what was the place you pictured?\n\nWhat other kinds of meditation have you done, and how do they compare to this kind where you picture a safe place in your imagination? \n\nWhy do you think picturing something in your imagination helps you relax?\n \nWhat is another way you take a break when you are stressed?\n\nWhy do you think relaxing this way helps you tune into your Inner Wisdom, gut feeling, intuition or whatever you call it? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Lion King © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 18,
      "name": "Know That Thoughts Cause Emotions",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade5week18-playmobil.gif",
      "summary": "Resilient people know that thoughts cause emotions, so they identify their thoughts and then try to find slightly more positive ones to feel better.",
      "readAloud": "One of the greatest concepts of resilience is this: It’s not the situation that causes you to suffer. It’s your thoughts about the situation that cause you to suffer.\n\nFor example, if you miss your final free throw shot and cause your team to lose the game, you probably think you’re upset because you missed the shot, but the real cause of your suffering is the thought-track running through your head: “I always choke under pressure. My teammates hate me now. My coach will never play me again.”\n\nOn the other hand, it is possible for someone to miss the shot and have a more positive thought-track running through their head: “I let my nerves get to me. It happens to everyone. I’ll make it next time.”\n\nThoughts, not situations, cause emotions.\n\nThis is great news, because we cannot always control situations, but we can almost always choose a slightly better-feeling way to think about them.\n\nJust like you can train your body to be physically fit, you can train your mind to focus on thoughts that feel better. \n\nRemember, though, that negative emotions are not \"bad,\" and we can\u0027t expect ourselves to think positive thoughts all the time. It\u0027s a good skill to practice, but give yourself permission to stay in a bad mood sometimes too. You get to decide when you\u0027re ready to feel better, and when you are... choose a slightly better-feeling thought.",
      "questions": "Can you think of a time when you made yourself feel better by choosing to think about the situation more positively?\n \nIs there one particular thought that always makes you feel better - something like, \"It will work out,\" or \"I can figure this out\"? \n\nThink back to the last time you were upset about something. What was the thought going through your head, and can you think of a slightly better-feeling one now? \n\nDo you agree that we can’t think positive thoughts all the time and it’s okay to stay in a bad mood sometimes?\n\nWhat emotion are you feeling now, and what is the thought causing it?\nThe best way to answer this question is to complete this sentence: “I feel (name your emotion), because…”\nWhatever follows \"because\" is the thought causing your emotion.",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Playmobil: The Movie © On Animation Studios"
    },
    {
      "grade": 5,
      "week": 19,
      "name": "Listen for Understanding, Not Winning",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade5week19-arnoldlisten.gif",
      "summary": "Resilient people listen in a way that allows people to feel heard and understood, even when they disagree.",
      "readAloud": "A lot of times, people don’t really listen. They wait for their chance to talk. \n\nInstead of focusing on what the other person is saying, they concentrate on what they want to say while waiting until it’s their turn to speak. \n\nBut this doesn’t feel good to people, especially during a disagreement.\n \nIt is possible to listen to understand someone without “losing” the argument. \n\nHow do you do it? You focus on getting information instead of on giving information. \n\nYou listen with the goal of being able to repeat back to them what you heard them say. You say something like, “Here’s what I heard you say. Is this right?” \n\nThen, you give them a chance to explain further if you’re missing something.\n \nAfter that, you can make your own points, but at least you will understand what they are saying. \n\nWhen you listen to understand, you don’t lose. You learn. You gain not only information but also a stronger connection to another human being because you respected them enough to actually listen to what they had to say.  ",
      "questions": "How do you show someone that you are really listening to them? \n\nCan you describe a time when you disagreed with someone but listened in a way that made them feel heard and understood even though you disagreed? How did you do that?\n\nCan you describe a time when someone disagreed with you but still made you feel heard and understood? How did they do that?\n\nCan you describe a time when you disagreed with someone but listened to what they had to say and changed your mind? \n\nCan you describe a time when you disagreed with someone but they listened to you and changed their mind? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc."
    },
    {
      "grade": 5,
      "week": 20,
      "name": "Find Evidence that Supports your Desires",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade5week20-arnoldevidence.gif",
      "summary": "Resilient people know they must believe something is possible for it to come true, so they actively look for evidence that supports their desires.",
      "readAloud": "If you want something, but believe it is impossible, you will not even try to make it happen. But often, the beliefs that hold us back are not necessarily true. Someone told us something was true, we believed them, and now we have a lot of evidence that it is true.\n \nBut here’s the thing: We think we believe things because they are true, but it’s actually the opposite. They are true because we believe them.\n \nLet’s say that again. We think we believe things because they are true, but they are true because we believe them.\n \nWhen we believe something, we only see evidence that supports it. We do not see evidence that contradicts it.\n \nFor example, if you believe people are not trustworthy, you will look for evidence that you are right, and you will overlook evidence that you are wrong. If you believe you are not smart enough or talented enough to succeed at something, your list of weaknesses will be much, much longer than your list of strengths.\n \nThis is just how the human mind works—it is wired to look for the negative in order to protect us from danger.\n \nThe good news is, if we look for evidence that supports our desires we can almost always find it. Just remember, your mind is in the habit of looking for the negative. If you can break that habit, you will open up a whole new world of possibilities for yourself.",
      "questions": "Have you ever changed your mind and replaced a negative belief about yourself with a more positive one? If so, how did that work out for you?\n\nWhat is something you really want for yourself, and what is one reason why it just might be possible?\n\nCan you think of a belief that holds you back, and then think of one piece of evidence that something better might be true?\n\nCan you think of an example of how a belief only allows you to see evidence that it is true and makes you overlook evidence that it is not true?\n\nCan you describe a time when you believed something positive about yourself when others didn’t and it came true?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc."
    },
    {
      "grade": 5,
      "week": 21,
      "name": "Use Your Body to Feel Into Decisions",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade5week21-spongebobdecisions.gif",
      "summary": "Resilient people know they can feel in their bodies when something feels like a good decision or a bad decision, so they feel into their decisions.",
      "readAloud": "When you are a kid, lots of people have opinions about who you should be, how you should behave, and what you should do with your life. With so many people telling you what to do, it can be hard to know how to make decisions for yourself.\n\nThe good news is we all have a powerful decision-making tool with us all the time: our bodies. Our bodies will tell us if a person feels safe, if an activity will be good for us, and if a decision is what we really want to do, or if we are trying to please someone else.\n\nIf no one tells you how important your gut feelings are, you might not pay attention to them. But if you feel into your body, the information is there.\n\nOur thinking minds can get sidetracked by insecurities, limiting beliefs (like “I’m not good enough”) and other people’s desires for us. But our Inner Wisdom will speak to us through our bodies if we tune in and listen.\n\nSo the next time you have a decision to make, listen to what your body has to tell you.",
      "questions": "How does it feel in your body when you make a decision you are excited about?\n\nHow do you make decisions? Do you listen to your body? Do you think it through? Do you talk to other people? Do you do all of the above?\n\nMany people call the feeling in your body your “gut feeling.” What do you call it? \n\nCan you describe a time when you could feel in your body whether something was a good decision or not?\n\nDo you have any decisions to make in your life now, and if so, what is your body telling you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 5,
      "week": 22,
      "name": "Use Your Words Wisely",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade5week22-hansolowisewords.gif",
      "summary": "Resilient people know that words have power, so they use their words wisely.",
      "readAloud": "Words have power. \n\nWords have the power to make us feel good or bad about ourselves.\n\nWords have the power to make others feel disrespected or supported by us.\n\nWords have the power to make us feel anxious or hopeful about the future. \n\nIn other words, the words we hear and the words we use literally shape our lives.\n \nWhile we cannot always control the words we hear, we can control the words we use. \n\nWhen we decide to use our words for good, our lives move in a positive direction. \n\nWhen we talk about ourselves with kindness and understanding, we feel better.\n\nWhen we talk about others with love and respect, they feel better, and we feel better too.\n\nWhen we talk about our futures with optimism, we feel better. \n\nSo, use your words wisely, and notice how much better you feel.",
      "questions": "What is something positive you can say about yourself? \n\nCan you give an example of how you used words to make someone else feel better?\n \nTeachers and coaches have to choose their words wisely so they inspire you to try harder instead of making you feel like you are not good enough. Can you give an example of something a teacher or coach said that inspired?\n \nDo you have anyone in your life who is way more negative than positive, and if so, how does it feel to be around them? \n\nCan you give an example of something you say that makes you feel optimistic about your future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 23,
      "name": "Practice Box Breathing When You’re Feeling Anxious",
      "category": "Mind-Body Skills",
      "subCategory": "Emotional Intelligence",
      "image": "grade5week23-boxbreathe.gif",
      "summary": "Resilient people know that everyone has moments of anxiety, so they have different ways of using their breath to calm down when they need to. Box Breathing is one of those ways.",
      "readAloud": "People talk a lot about anxiety these days, and it is true that more young people are seeking help for high levels of anxiety than ever before. Seeking professional help when your anxiety feels overwhelming is a courageous thing to do, and mental health professionals have many tools and techniques for helping people cope with anxiety and other intense emotions.\n\nThat being said, some anxiety is a part of life. Everyone has moments of feeling anxious and even overwhelmed, so it’s important to have tools for calming down so you can think straight and figure out how to take care of yourself.\n\nThat’s the thing about anxiety–it makes it difficult to think constructively. Whenever we feel intense negative emotions, it becomes difficult to think well. This is because when we feel threatened the “fight or flight” response narrows our thinking to just a few options: “Fight!” and “Run!” being two of the big ones.\n\nHowever, most of the daily situations that trigger anxiety do not require fighting or running. They do require thinking. So, we need to be able to calm down in order to think about what we need (to feel better) and what we can do to take care of ourselves.\n\nBox breathing can help. Let’s try it now.\n\nFirst, choose a number that you will count to as you breathe in and out and hold your breath between each inhale and exhale. We’ll use the number 4 right now, and we’ll practice Box Breathing for four rounds.\n\n1.\tBreathe IN for the count of four.\n2.\tHold for the count of four.\n3.\tBreathe OUT for the count of four.\n4.\tHold for the count of four.\n\n(Mentors, do that four times.)\n\nSo, that’s Box Breathing. Next time you feel anxious, give it a try and notice how it settles you down, stops a negative thought spiral, and makes you better able to find solutions to whatever is upsetting you.",
      "questions": "What was your experience with Box Breathing like, and do you think it could be helpful for you?\n\nHave you ever used Box Breathing or any other kind of breathing technique to calm down, and do you find it helpful?\n\nHave you ever noticed that you can’t think very well when you’re upset about something?\n\nWhat do you think is the difference between the anxiety that is a part of daily life and anxiety that requires professional help? (If you have a level of anxiety you are unsure about, ask an adult–the school counselor, an advisor, or a teacher.)\n\nWho knows how to find access to mental health resources? (There is a link to mental health resources at the bottom of each Resilience Reminders™lesson.)",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company."
    },
    {
      "grade": 5,
      "week": 24,
      "name": "Do What You Need To Do, Even When It’s Not That Fun",
      "category": "Needs/Values",
      "subCategory": "",
      "image": "grade5week24-spongebobnotfun.gif",
      "summary": "Resilient people set goals and do what they need to do to achieve them, even when it’s not that fun.",
      "readAloud": "Few people enjoy working out so hard they can’t catch their breath.\n\nNot many people think homework is fun.\n\nMost kids would choose junk food over vegetables, if given the choice.\n \nBut most accomplishments require hard work, tough choices, and doing things that aren’t that fun in the moment. \n\nSuccessful students do their homework, even when they’d rather hang out with friends.\n\nWinning athletes push their bodies, even when they’d rather sleep in.\n\nHealthy people make healthy food choices, even when they’d rather eat junk. \n\nWhen we make hard choices that move us toward our goals we feel good about ourselves. And we look good to others—our teachers, coaches, mentors and friends think highly of us and want to help us achieve our goals. \n\nSo, set goals, figure out what you need to do to achieve them, and do it… even when it isn’t fun. ",
      "questions": "What is one thing you do that’s not that fun, but you do it because it moves you toward a goal? \n\nDo you have any academic goals, and if so, what is one? \n\nDo you have any athletic or health goals, and if so, what is one?\n \nWhat is one thing you could do to move yourself toward one of your goals, even if it’s not that fun?  \n\nCan you think of a way to make something that\u0027s not that fun more enjoyable?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 5,
      "week": 25,
      "name": "Play to Your Strengths and Strengthen Your Weaknesses",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade5week25-spongebobstrengths.gif",
      "summary": "Resilient people spend as much time as possible using their strengths. They also acknowledge their weaknesses and take actions to either improve them or lessen their impact.",
      "readAloud": "The more time you spend using your strengths, the happier and more empowered you will feel. That being said, we all have to do things that are out of our comfort zones sometimes. That’s just the way life is.\n\nIt can feel embarrassing to admit that you are not as strong as you would like to be in something. But no one is great at everything. And acknowledging your weaknesses allows you to get help to either improve them or find some other way to lessen the negative impact they have in your life.\n\nSome weaknesses can be improved with training and practice. Others can be offset by working with someone who complements your skills. Successful people surround themselves with people whose strengths make up for their weaknesses.\n\nReminding yourself that everyone has strengths and weaknesses will make you feel better about your own shortcomings. So will reminding yourself what your strengths are when you are acknowledging a weakness. For example, maybe you are not strong in math but you are great at science. Or you are not very good at drawing but you are great at basketball. Or you are not great at speaking in public but you are great at making friends.\n\nBeing honest with yourself (and others) about your strengths and weaknesses is far more beneficial than pretending to be good at something you are not, and it allows you to play to your strengths and shore up your weaknesses.",
      "questions": "What is one of your strengths and what is one of your weaknesses? \n\nCan you describe a time when you took action to improve a weakness? \n\nCan you describe a way that your weaknesses are offset by working with people whose strengths complement your weaknesses? (Maybe you are on a team with people who have different strengths than you do.) \n\nWhat is an example of how you “play to your strengths” (which means spending time using your own strengths)? \n\nWhat is one weakness you would like to improve or offset?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 5,
      "week": 26,
      "name": "Flip the Script on Failure and Talk About What You Learned",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade5week26-southparkflip.gif",
      "summary": "Resilient people don’t beat themselves up when they fail. They look for lessons and talk about what they learned.",
      "readAloud": "Most people don’t realize how much the words they say affect how they feel and how they act. But consider how the following statements make you feel:\n\n“We lost. I played terribly. I’m sure the coach thinks I’m horrible and will never play me again. I’m no good at this sport. I don’t even know why I keep playing.” \n\nThis obviously makes you feel defeated. \n\nNow consider this: \n\n“We lost. I didn’t play my best. I see now that my blocking could be better. I’m going to work extra hard on that this week, so I’m better in the next game.” \n\nThis obviously feels better. It gives you—and everyone else who hears it—hope, and lets them know you are committed to getting better. \n\nSuccessful people know that failure is only failure if you fail to learn from it, and by learning the lessons in failures they transform them into stepping stones to success.\n\nMichael Jordan, maybe the greatest basketball player of all time, was cut from his high school basketball team! What did he learn? That he wanted to keep playing and that failure is a step on the journey to success.\n\n“I have missed more than 9000 shots in my career. I have lost almost 300 games. On 26 occasions I have been entrusted to take the game winning shot, and I missed. I have failed over and over and over again in my life. And that is why I succeed.” ",
      "questions": "Can you describe a time when you failed at something and learned from it? \n\nCan you think of something positive in your life that could not have happened if you hadn’t failed at something first? \n\nHow do you talk about your failures? Do you beat yourself up or talk about what you learned? \n\nWhat do you think it means about you if you fail? \n\nWhat is something you could say to yourself next time you fail?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. South Park © Comedy Partners"
    },
    {
      "grade": 5,
      "week": 27,
      "name": "Help Yourself by Helping Others",
      "category": "Interpersonal Skills",
      "subCategory": "Strengths",
      "image": "grade5week27-uphelpyourself.gif",
      "summary": "Resilient people know that they get just as much from supporting others as the people they support do, so they help themselves by helping others.",
      "readAloud": "Resilient people reach out for help when they need it because they know that everyone needs support sometimes. They also actively look for opportunities to give support. In fact, being of service to others is a value that goes hand in hand with resilience, and here’s why:\n \n* Supporting others builds and strengthens your support system, which makes you more resilient.\n* Being of service to others gives meaning and purpose to your life and makes you feel good about yourself, which makes you more resilient.\n* Helping others can be a positive distraction from your own challenges and give you perspective on your own problems, which makes you more resilient.\n \nIt feels good to support others. It helps you meet new people and deepen the relationships you already have. Each of us has a unique constellation of strengths and resources we could share with others, and doing so will benefit us just as much as it helps someone else. So, look for ways to be of service to others and experience the positive impact in your own life.",
      "questions": "Can you describe a time when you supported someone and how it felt to help them?\n\nWhat are some of the unique strengths and resources you can share with others?\n\nAre you more comfortable giving or receiving support, and why?\n\nCan you describe a time when supporting someone else gave you a new perspective on your own challenges?\n\nCan you think of any ways you might be of service to your school or community?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. a.\tUp © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 28,
      "name": "Be Quiet (Sometimes)",
      "category": "Mind-Body Skills",
      "subCategory": "Inner Wisdom",
      "image": "grade5week28-monstersincbequiet.gif",
      "summary": "Resilient people know there is a source of peace and wisdom inside of us all, and the way to get in touch with it is to be quiet.",
      "readAloud": "How often do you ever just sit and do nothing? Really nothing—no screens, no music, no conversation.\n \nIf you are like most people, you are so busy racing through each day trying to get everything done, you barely have enough time to sleep—much less to “waste” precious time doing nothing.\n \nBut taking time to be quiet is doing something—something very important. It\u0027s allowing our minds and bodies to calm AND tuning in to the guidance available from our own Inner Wisdom and from whatever spiritual sources of wisdom we believe in.\n\nWhat could be more important than that?\n\nIn fact, let\u0027s take 2 minutes right now, and try it together: \n\n1. First, close your eyes, if that feels good. You don’t have to.\n\n2. Now take three slow, deep breaths and focus on relaxing your body while you breathe.(Mentors, allow time for 3 breaths.)\n\n3. Take three more slow, deep breaths and notice how it feels in your mind and in your body to be quiet and breathe and relax. (Mentors, allow time for 3 breaths.)\n\n4. Now, keep breathing and relaxing and think of a challenge you have in your life or a decision you need to make, and ask yourself, “What do I need to know about this?”\n\n5. Then, listen inside for a few breaths and see if an answer comes to you. (Mentors, allow time for 3 breaths.)\n\n6. Finally, take 3 more deep, relaxing breaths, and when you are ready, come back to the room—knowing you can always come back to this peaceful place with just a few breaths.",
      "questions": "How did it feel to do that? What was going on in your mind and body? \n\nDid you get an answer to your question? You don’t have to share the answer, just share whether or not you got one. \n\nDo you ever sit and do nothing, or do you get out a screen or do something else to entertain yourself? \n\nDo you ever do any meditation? If so, what do you do? \n\nIf you are a spiritual person, how do you tune in to guidance from whatever higher power you believe in?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Monsters Inc. © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 29,
      "name": "Take Responsibility for How You Show up in the World",
      "category": "Needs/Values",
      "subCategory": "Strengths",
      "image": "grade5week29-incrediblesresponsibility.gif",
      "summary": "Resilient people know that many things are out of their control,\nbut how they show up each day—what kind of person they are\nand choices they make—is completely up to them.",
      "readAloud": "It is not always easy to be our best selves. When we are angry at someone we can do things we later regret. When we are frustrated about something we can show sides of ourselves we are not proud of. And sometimes certain people can push us to do things we know are not right.\n \nIt is not always easy to be our best selves, but resilient people know that it matters. Why does it matter? Because it builds self-esteem.\n \nIt feels good to set high standards for yourself and live up to those standards—even when no one is watching. It feels good to be proud of yourself. It is empowering and inspiring and satisfying. And it is something that is always available to you, even when much about your life is out of your control.\n \nOne thing that helps you show up as the best version of yourself is to surround yourself with people who share your values and bring out the best in you. Another thing that helps is to know what your personal strengths are and use them as often as possible.\n \nIt is not always easy to be your best self and it will not prevent you from experiencing disappointment, but it will empower you to be the hero in your own life.",
      "questions": "When you think of being your best self, what is the single, most important character trait you try to live by?\n\nIf you asked your friends, family members, coaches, teachers or mentors what the best thing about you is, what would they say?\n\nCan you think of a time when it was difficult to be your best self but you did it anyway?\n\nCan you think of something you could do to start being your best self a little more often?\n\nWhy do you think using your personal strengths helps you show up as the best version of yourself?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. The Incredibles © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 30,
      "name": "Have Practices and Rituals That Nourish Your Spirit",
      "category": "Spirit",
      "subCategory": "Mind-Body Skills",
      "image": "grade5week30-bartpraying.gif",
      "summary": "Resilient people take care of themselves by doing things that add meaning to their lives and connect them to something greater than themselves.",
      "readAloud": "It’s easy to get so wrapped up in our own daily struggles that we forget to look up. We forget to notice the awesomeness right in front of us—of nature, of space, of the human mind and body.\n\nBut connecting ourselves to these awe-inspiring things can nourish our spirits and give meaning to our lives. And finding ways to soothe our souls in a world that feels chaotic and out of control is really important. \n\nGoing to a religious service on Sunday is a weekly ritual that comforts and connects many people—to God and to a supportive community. Saying prayers at bedtime is a daily practice that nourishes the spirit. \n\nBut rituals and practices do not have to be religious. Practices like meditation and keeping a gratitude journal, and rituals like family dinners and spending time in nature can feed our souls, give meaning to our lives, and connect us to something greater than ourselves. \n\nSo, make time for uplifting rituals and practices and daily life will feel a lot more inspiring and meaningful.",
      "questions": "Some rituals are yearly, like celebrating religious holidays and birthdays. What rituals do you do every year and how do they make you feel?\n\nMany people have weekly rituals like going to religious services or having dinner with loved ones. What rituals do you do weekly and how do they make you feel?\n\nSome people have daily practices that calm and comfort them like meditating, journaling, or saying prayers. What practices do you try to do every day and how do they make you feel?\n\nWhat other rituals and practices give meaning to your life?\n\nWhat other rituals and practices connect you to something greater than yourself?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 31,
      "name": "Trust Your Human Radar System",
      "category": "Interpersonal Skills",
      "subCategory": "Inner Wisdom",
      "image": "grade5week31-aladdinradar.gif",
      "summary": "Resilient people pay attention to how people make them feel. They trust their guts about people and only hang out with people who make them feel good.",
      "readAloud": "One of the things adults do to kids without realizing it’s not good for them is insist that they “be nice” to everyone. In the name of politeness, kids are told to obey adults, even adults who don’t feel good to them.\nIn the name of kindness, kids are told to play with everyone, even kids who don’t feel good to them. In the name of fairness, kids are told to share with everyone, even people who don’t feel good to them.\n\nSince kids don’t have much control over who they spend time with, they learn to disregard how people make them feel. And while we want to raise kids who are polite, kind and fair, ignoring how people make us feel is tuning out one of our most important sources of information. \n\nResilient people “feel people out.” They know they can feel in their bodies when someone feels safe, open and trustworthy. And they can feel in their bodies when someone feels closed, untrustworthy, or even dangerous. It’s kind of like a “human radar system” they can trust when meeting new people, choosing friends and seeking adult mentors. \n\nWhether you call it a gut feeling, intuition, instinct or Inner Wisdom, our bodies have lots of information for us if we listen to them. So, tune in to how people make you feel, and spend time with people who lift you up and inspire you to be the best version of yourself.",
      "questions": "What does it feel like in your body when you meet someone who feels like an open, trustworthy, safe person? \n\nWhat does it feel like in your body when you meet someone who feels like a closed, untrustworthy, or even dangerous person? \n\nObserving people is part of feeling people out. What differences can you observe between good-feeling and bad-feeling people (such as body language, tone of voice, eye contact)? \n\nWhat words do you use for the way you can “feel people out?” (Some use gut feeling, intuition, instinct or Inner Wisdom)\n\nWhat can you do to keep yourself safe when you are NOT SURE if someone is a good, safe person? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Aladdin © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 32,
      "name": "Shift Your Mood by Shifting Your Focus",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade5week32-liloshift.gif",
      "summary": "Resilient people know that what you focus on determines how you feel, so when they want to feel better, they shift their mood by shifting their focus.",
      "readAloud": "What you focus on determines how you feel.\n\nIf you’re focusing on a great song, a funny video, a person you love, an activity you enjoy, or a positive thought, you will feel good. \n\nOften, however, we get so caught up in the rush of daily life that we give our attention to whatever is in front of us and allow whatever that is to set our mood. We don’t like math, so we’re in a bad mood. Someone is rude to us, so we’re in a bad mood. We mess up in basketball, so we’re in a bad mood. \n\nBut when we remember that we can choose to focus on activities and thoughts that feel better, we can take our power back. \n\nWe can’t always focus on different activities. If we’re in math class, we can’t start blasting a favorite song. We can, however, choose to focus on thoughts that feel better than others. Instead of thinking, “I hate this. I’m no good at this. It’s no use. I’ll never get better.” You could think, “I really don’t like this, but it’s my last class of the day, and then I get to hang out with my friends.” \n\nThe thoughts we think are often the most powerful causes of our moods. With a little practice we can notice the thoughts that are causing us to feel bad and find a slightly better way of thinking about the situation.\n \nSo when you’re feeling down and want to feel better, shift your focus to an activity or a thought that feels better. \n\nRemember, though, negative emotions have important messages for us about what we need to feel better—about ourselves and our lives. So don’t ignore negative emotions or shift your focus away from them until you have identified what you need and how you can take care of yourself.",
      "questions": "How do you get yourself out of a bad mood when you want to? \n\nWhat is a song that always makes you feel good? \n\nCan you describe a time when you noticed you were focusing on negative thoughts and you found a better-feeling way to think about the situation? \n\nHave you ever noticed that sometimes you just want to stay in your bad mood, and when someone tries to cheer you up, it just makes you feel worse? Do you agree that it’s okay to stay in a bad mood sometimes?\n\nWhy do you think it’s important not to ignore negative emotions?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Lilo and Stitch © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 33,
      "name": "Prioritize Play",
      "category": "Needs/Values",
      "subCategory": "Beliefs",
      "image": "grade5week33-bartprioritize.gif",
      "summary": "Resilient people know it’s important to balance work with restful and joyful activities, so they prioritize play in their lives.",
      "readAloud": "Life is busy and the list of things we have to get done in a given day can feel overwhelming. We have to do our homework. We have to go to football practice. We have to practice our instrument for 20 minutes. We have to do our community service hours. There never seems to be enough time in the day, so we stay up later and get less sleep.\n\nHowever, if we burn the candle at both ends, we will end up feeling exhausted, unmotivated and overwhelmed. We need down time–time to rest and recharge. And research now shows that young people need unstructured play time–time to have fun with their friends without expectations for achievement.\n\nBottom line: we need balance in our lives. Not only do we need to balance work and play, but we also need to balance having fun and getting enough rest, socializing and down time, screen time and human interaction, doing and being, and being distracted and being present. \n\nSo, how do we make our lives feel more balanced when we cannot add more time to the week? We have to shift our priorities. If you believe balance is important, start prioritizing play.",
      "questions": "On a scale of 0-10 how balanced does your life feel?\n\nDo you agree that we need balance to feel our best? Why or why not?\n\nWhat is one thing you could do to add more balance to your life?\n\nDo you agree with the list of things we need to balance: having fun and getting enough rest, socializing and down time, screen time and human interaction, doing and being, and being distracted and being present?\n\nIs the way you spend your time a good representation of your priorities, or do you spend time on things that are not your own priorities?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 5,
      "week": 34,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade5week34-nemo.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. This year we have talked about: \n\n* Owning your unique set of personal strengths\n* Believing in your ability to learn new things and improve\n* Getting help when you need it\n* Using your breath to calm down and focus\n* Accepting negative emotions and using them to take care of yourself\n* Not letting failure define you\n* Choosing your friends wisely\n* Listening for understanding, not willing\n* Being quiet (sometimes)\n* Helping yourself by helping others\n* Taking responsibility for how you show up in the world\n\nThese are just a few of the resilience skills we have covered this year. You were already resilient, and now you have even more skills to choose from when you come to a bump in the road. ",
      "questions": "Can you describe a way you have been resilient this year?\n\nAre there any skills from this Resilience Reminders™ program that you learned or tried that worked for you?\n\nWhat words do you use to define resilience? \n\nDo you think talking about these types of things is helpful for students and families? Why or why not?\n\nDo you believe in reaching out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Finding Nemo © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 1,
      "name": "Know the Ways You are Resilient ",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade6week1-bartbounce.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life.\n \nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves.\n \nThe other good news is you are already resilient! You have probably picked up quite a few resilience skills already in life. For example, if you…\n\n* Can name your personal strengths\n* Have good-feeling relationships\n* Reach out for support when you need it\n* Take care of yourself–physically, mentally, emotionally and spiritually\n* Learn from your losses\n* Use your breath to calm down and focus\n* Trust your gut\n\nIf you do any of these things, you are already resilient. \n\nThese Resilience Reminders™ will add more skills to your list and remind you of helpful things you already know.",
      "questions": "How do you define resilience? What words do you use? \n\nHow do you demonstrate resilience? Which skills do you use? \n\nWhat disappointment, challenge or failure are you most proud of yourself for overcoming, and what skills did you use to feel better?\n\nWhat is one way you take care of yourself–physically, mentally, emotionally or spiritually?\n\nDo you agree that it demonstrates resilience to reach out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 2,
      "name": "Be Coachable. Welcome Feedback.",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade6week2-feedbackanime.gif",
      "summary": "Resilient people know that becoming good at something is a process of learning, trying, and sometimes failing. And, one of the best ways to improve is to get feedback from others.",
      "readAloud": "It can be hard to hear feedback. It can feel embarrassing when a coach, teacher, parent or friend points out how you could do something better.\n \nMany people take it as criticism and get upset when others try to give them feedback. They take it to mean they have done something wrong or are not as good as they should be. Some people think feedback means they have failed.\n \nBut if you shut down when someone is trying to help you, you miss valuable information that could improve your performance… and your life. Feedback helps you in sports, in academics, in relationships, and in your career.\n \nWhen you change the story in your mind from one where feedback means you are not good enough to one where it means you are learning and improving, you put yourself on the fast track to success.\n \nSuccessful people welcome feedback. They want to know what others think of how they are doing and what they could do better.\n \nSo the next time someone offers to give you feedback, resist the urge to take it as criticism and open to the possibility that it will help you become an even better version of yourself. ",
      "questions": "What goes through your head when someone tries to give you feedback? Do you take it as criticism and feel bad about yourself or welcome the idea of learning something? \n\nAre there some people who make it easier for you to hear their feedback and others who make feedback feel bad? Why is it easier to hear from some people? \n\nWhat areas of your life are you best at hearing feedback in, and what areas are more difficult for you? (sports, academics, relationships, family, other?)  \n\nHow do you think feedback can help you in relationships? \n\nAre you good at giving feedback yourself? What can you do to make it so people take your feedback constructively and not as criticism?  ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. "
    },
    {
      "grade": 6,
      "week": 3,
      "name": "Use Your Strengths to Get Out of a Bad Mood",
      "category": "Strengths",
      "subCategory": "Emotional Intelligence",
      "image": "grade6week3-cutehappyfeet.gif",
      "summary": "Resilient people know it feels good to do something you’re good at, so they use their strengths to get themselves out of a bad mood.",
      "readAloud": "Everyone has a bad day sometimes. And sometimes it just feels like you wake up on the wrong side of the bed for no reason and can’t get out of a bad mood.\n \nOne thing to know about bad moods is that you can’t get out of them until you’re ready. Sometimes you just need to wallow in a bad mood for a bit, and that’s ok. We have to give our bad-feeling emotions enough space to get their message across. We have to listen to what they are telling us, so we can know how to take care of ourselves.\n \nWe have to ask ourselves, “What am I upset about? And what do I need to feel better?”\n \nWhen we do that, we might realize that we’re angry because someone was rude to us, and we need to tell that person they hurt our feelings, or we need to tell someone else about what happened, or we need to not spend time with that person until they apologize. Once we are clear about why we’re upset and what we need to feel better, we can do something to get in a better mood.\n \nOne way to feel better is to do something we are good at, because that will automatically feel good. If you’re good at painting, it will feel good to paint. If you’re good at playing the piano, it will shift you into a better mood to play a favorite song. If you’re good at singing, dancing, drawing, shooting hoops, or cooking, doing those activities will make you feel better.\n \nSo the next time you are ready to shift out of a bad mood, spend some time using one of your strengths and feel how good it feels to be good at something.",
      "questions": "Have you ever noticed that sometimes you want to stay in your bad mood? Why do you think that is?\n\nWhat do you do when you are ready to feel better? What works for you?\n\nWhat is one of your strengths that you could use to feel better because you enjoy doing it?\n\nWhat do you consider your top three strengths?\n\nIs there anything you’re really good at that you don’t enjoy that much so it wouldn’t help you get in a better mood?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Happy Feet © Warner Bros. Entertainment, Inc."
    },
    {
      "grade": 6,
      "week": 4,
      "name": "Tell Empowering Stories About Yourself",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade6week4-Imaprettyamazingperson.gif",
      "summary": "Resilient people know that the stories they tell about themselves shape their lives, so they tell the most empowering stories they can about themselves, their lives, and their futures.",
      "readAloud": "The stories we tell about ourselves drive our behavior, influence how others respond to us, and either empower us to achieve our dreams or place unnecessary limits on our potential.\n \nImagine that you tell the story, “I’m not good at school.”\n \nThis belief would make you feel insecure and anxious in the classroom, which would likely make you reluctant to participate. This would cause your teachers to judge you poorly and give you lower grades, which would confirm your belief that you are not good at school. All this would probably make you less likely to try very hard at your schoolwork, which would continue the cycle.\n \nBut imagine if you told this story: “It sometimes takes me longer, but I always figure it out.”\n \nThis belief would make you feel hopeful and determined in the classroom and would likely cause you to engage with your teachers until the concepts clicked for you. Not only would you get higher grades but your teachers would also see you as diligent and hard-working. All this would confirm your belief that if you work hard enough you eventually figure it out.\n \nWords have power.\nYou get to decide how to tell your life story, so choose your words wisely.",
      "questions": "What is one really positive thing you can say about yourself? \n\nWhat is something positive you can say about your future? \n\nCan you think of a positive way to talk about a mistake or failure you had in the past? \n\nWhat is something positive you can say about how you treat other people? \n\nDo you have a negative story about yourself? If so, can you think of a slightly better story to tell instead?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc."
    },
    {
      "grade": 6,
      "week": 5,
      "name": "Take Control of Your Emotions by Accepting Them First",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade6week5-insideoutemos.gif",
      "summary": "Resilient people know that the first step to controlling their emotions is accepting that all emotions are real and important.",
      "readAloud": "Negative emotions like anger, fear and sadness don’t feel good. They don’t feel good to the person feeling them, and they don’t feel good to people around that person. Because of that, people try to ignore negative emotions, pretend they are not important, and stuff them down.\n \nBut ignoring emotions just makes them get bigger. And pretending emotions are not important just makes you feel worse—but in secret, which makes you feel alone.\n \nThe way to feel better is to acknowledge the emotion and get honest with yourself about what it is telling you. How do you do this?\n \nSay an “Emotion Sentence:” I feel (name the emotion) because… (and finish the sentence.)\n \nFor example, if you feel angry because someone was mean to you, it’s important to acknowledge that. Then, you can figure out what to do about it.\n \nBut if you pretend it’s not a big deal, you miss an opportunity to take care of yourself, and you set yourself up to continue getting hurt.\n \nSo the next time you feel upset about something, take control of your emotions by saying how you feel.",
      "questions": "Have you ever noticed that it feels good when other people accept your emotions—even the bad-feeling ones? Why do you think it feels good?\n\nHow do you or could you let someone know that you accept their emotions?\n\nHave you ever noticed that it feels bad when other people don’t accept your emotions? Why do you think that feels bad?\n\nAre there some emotions you do not accept in yourself? (Some people don’t think they should feel angry. Some people don’t think they should show sadness or fear.)\n\nWill you say an “emotion sentence” for an emotion you felt recently, and then say how it felt to share that out loud?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Inside Out ©”The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 6,
      "name": "Don’t Just Think, Check Your Gut Feelings Too",
      "category": "Inner Wisdom",
      "subCategory": "Spirit",
      "image": "grade6week6-newstarwarsbadfeeling.gif",
      "summary": "Resilient people know their thoughts can be misleading, so they check in with their gut feelings too.",
      "readAloud": "Sometimes we think things that are not true: “I’m not good enough.”\n\nMany times we think things that hold us back: “I shouldn’t even try.”\n\nOften we don’t think things through: “I’m sure it’ll be fine. Just do it.”\n\nOur thoughts do not always serve us, but the good news is, our gut feelings will lead us in the right direction—if we learn to trust them.\n\nWe can feel in our bodies whether something feels good, safe and inspiring, and we can feel in our bodies when something feels sketchy, questionable, or fear-based.\n\nWhether we listen to them or not, our gut feelings are there.\n\nSo the next time you have a decision to make, take a moment to check in with your gut feelings. Close your eyes, take a deep, relaxing breath, and ask yourself, “What does my Inner Wisdom have to say about this? Which is the best decision for me?”\n\nIf it still isn’t clear, imagine making one decision and notice how that feels. Then imagine making the other decision, and notice how that feels.\n\nThere is a part of us that knows what we truly desire and what is best for us, and it never gets misled by negative thoughts or limiting beliefs. So, tune in!",
      "questions": "\nSome people call it a gut feeling. Others call it instinct, intuition, Inner Wisdom or their higher self. What words do you use to describe the way you can feel in your body whether something is good or bad for you? \n\nCan you describe a time that you listened to your gut feelings when making a decision? \n\nCan you describe a time when you didn’t listen to your gut feelings—you knew they were there, but you chose to ignore them? \n\nHow can you tell the difference between something you are thinking and something your Inner Wisdom is trying to tell you? \n\nCan you describe a time when your gut feelings protected you from danger?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 7,
      "name": "Ask For What You Need",
      "category": "Interpersonal Skills",
      "subCategory": "Needs",
      "image": "grade6week7-donkeyhugagain.gif",
      "summary": "Resilient people know that people can’t read their minds, so they speak up for themselves and ask for what they need.",
      "readAloud": "It would be great if other people could somehow know exactly what we need at any given time and give it to us. But people can’t read our minds, so they are often left guessing at what we need or thinking we don’t need anything at all. \n\n* If you don’t tell your teacher you need help, they won’t know how to help you.\n* If you don’t tell your coach you need support, they won’t know how to support you.\n* If you don’t tell your friends you need encouragement, they won’t even know that you need encouragement.\n\nWhen people tell each other what they need, relationships feel better. \n\nAnd if you find yourself in a relationship with someone who will not give you what you need, you can look for ways to take care of yourself. \n\nLearning to speak up for yourself and ask for what you need is one of the most empowering things you can do for yourself.",
      "questions": "Can you give an example of when you asked for something you needed from someone? \n\nAre you comfortable speaking up for yourself and asking for what you need? In what situations are you more and less comfortable asking for what you need? \n\nCan you think of something you need from someone that would make your relationship feel better? \n\nHow does it feel to you when someone asks you for something they need? \n\nWhat can you do if you ask someone for something you need and they won’t or can’t give it to you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Shrek ©” The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 8,
      "name": "Be Mindful of Your Body",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade6week8-patrickeatingnotmindfully.gif",
      "summary": "Resilient people know that their minds and bodies are connected, so they are mindful of how they treat their bodies.",
      "readAloud": "Our minds and bodies are connected.\n\nBut with life as busy and chaotic as it is, it\u0027s easy to overlook how the way we treat our bodies affects us mentally and emotionally. But if you pay attention, it is obvious.\n\nWhen our bodies are down with an illness, we feel mentally and emotionally down as well. When we finish a good workout, we feel empowered and clear-minded.\n\nWhen we don’t get enough sleep, we feel foggy and irritable. When we are well-rested and have eaten a healthy meal, we feel alert and optimistic.\n\nDon\u0027t take our word for it. Try it for yourself. \n\nCompare how you feel after eating junk food with how you feel after eating something healthy. Notice how you feel after being a couch potato and how you feel after getting some exercise. See how different you feel after staying up really late versus getting a full night\u0027s sleep.\n\nBe mindful of your body and start feeling your best - physically, mentally and emotionally.",
      "questions": "Can you describe a time that you noticed how what was going on with your body affected how you felt mentally and emotionally? \n\nHow does exercise make you feel mentally and emotionally? \n\nHow does sleep affect how you feel mentally and emotionally? \n\nWhat foods make you feel the best physically, mentally and emotionally? \n\nWhat foods make you feel the worst physically, mentally and emotionally?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 6,
      "week": 9,
      "name": "Don\u0027t Judge the Moment. Stay Hopeful.",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade6week9-homersimpsonhappysad.gif",
      "summary": "Resilient people know that events that seem bad in the moment can pave the way for good things in the future, so instead of judging the moment, they stay hopeful.",
      "readAloud": "The Parable of the Chinese Farmer\n\nOnce upon a time there was a Chinese farmer whose horse ran away. That evening, his neighbors came and said, “How unlucky for you! This is most unfortunate!” The farmer replied, “Maybe, yes. Maybe, no.”\n\nThe next day the horse came back bringing seven horses with it. In the evening, the neighbors came back and said, “Isn’t that lucky! What a great turn of events!” The farmer said again, “Maybe, yes. Maybe, no.” \n\nThe following day his son tried to ride one of the horses, but was thrown off and broke his leg. The neighbors said, “Oh dear, that’s too bad!” The farmer responded, “Maybe, yes. Maybe, no.”\n\nThe next day military officers came to draft people into the army, and they rejected his son because he had a broken leg. Again, the neighbors said, “Isn’t that wonderful!” And the farmer said, “Maybe, yes. Maybe, no.”\n-------------------------------------------------------\n\nIf you look back over your life, you will be able to identify things you consider “good” that could not have happened without something you consider “bad” happening before. Realizing this can help you choose to stay hopeful even when things in the moment are not feeling great.",
      "questions": "Can you think of something “good” in your life that could not have happened if a “bad” thing hadn’t happened before it? \n\nCan you think of something you first thought was \"bad\" but later you realized it was actually \"good\"? \n\nHow about the opposite? Can you think of something you first thought was \"good\" but turned out to be not so good? \n\nWhat can you do to turn something that seems “bad” into something “good”? \n\nWhy do you think not judging the moment helps you be resilient? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 10,
      "name": "Have Healthy Self-Care Habits",
      "category": "Needs/Values",
      "subCategory": "",
      "image": "grade6week10-homersimpsoneating.gif",
      "summary": "Resilient people know how important it is to take care of themselves, especially when they are feeling stressed, so they have healthy self-care habits.",
      "readAloud": "When life gets hard and you feel overwhelmed, it’s important to know what actually works to help you feel better. If you don’t have a list of healthy things you can do to feel better, you can end up making unhealthy choices that make you feel worse.\n\nMaybe you eat junk food, for example, which might feel good in the moment, but ends up giving you a stomach ache.\n\nOr maybe you withdraw from your friends and family, which might save you the embarrassment of having an uncomfortable conversation, but ultimately leaves you feeling alone and unsupported.\n\nOr maybe you stay up too late playing video games or scrolling your social feeds, which distracts you from you problems in the moment, but leaves you feeling exhausted and unmotivated the next day.\n\nThe first step to creating healthy habits is noticing what your habits are now and asking yourself if they really make you feel better in the long run.\n\nSome habits are just healthier than others. Eating healthy food, getting exercise, getting enough sleep, and getting support from people who care about you when you are stressed—these are things we all can do to take care of ourselves.\n\nAnd maybe you have other healthy habits to add to your list. Maybe you like to meditate or pray or journal or sit quietly in nature or gaze at the stars or get a hug from someone who loves you.\n\nAnything that makes you feel good without a negative consequence can go on your Healthy Self-Care Habits list. What’s important is that you have your own list, and you make a habit of doing them.",
      "questions": "What is one Healthy Self-Care Habit you do regularly, whether you are stressed or not?\n\nWhat is a healthy way you take care of yourself when you are stressed or upset? \n\nWhat is one not-so-great habit you might want to improve? \n\nWhat is one not-so-great habit you see other people do that might help in the moment but doesn’t help in the long run? \n\nWhat is one healthy habit you see other people do that you might want to try?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 11,
      "name": "Listen, Instead of Defending Yourself",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade6week11-defensivesouthparklooped.gif",
      "summary": "Resilient people know that they are not always aware of how their actions impact others, so they listen instead of defending themselves.",
      "readAloud": "When someone is upset with us it is natural to want to defend ourselves. It feels terrible to be accused of doing something wrong, especially when we don’t think we did anything wrong. \n\nBut we are not always aware of how our actions impact others. We may not intend to hurt anyone, but they can still be hurt. For example…\n\n* You think you’re joking around, but your teammate feels like you’re picking on him.\n* Your best friend overhears you making plans with someone else and is upset that you don’t tell her about it.\n\nIt is also true that sometimes someone needs something from us, and if we get defensive, we might miss the opportunity to improve our relationship. For example…\n\n* If you get defensive when your coach asks you to work harder in practice, you miss the opportunity to become the leader he needs you to be.\n* If you get defensive when your mom asks you to keep your room tidy, you miss the opportunity to make her feel respected and cared for by you.\n\nIt’s easy to be so stuck on our own story that we cannot understand the other person’s perspective. But if our response is always to defend ourselves, we miss opportunities to learn about how we come across to others and to deepen our relationships.\n\nSo pay attention to when you get defensive, take a breath when you feel yourself doing it, and open to the possibility that you might be either misunderstanding what the other person is needing from you or being misunderstood by them. \n\nIf you listen first, you will be better able to find a win-win solution to whatever is upsetting them.",
      "questions": "Can you describe a time when someone was upset with you for something you didn’t intend and how you resolved that?\n\nWould you say you’re pretty good at listening and not being defensive or not? Why do you think that is?\n\nAre there certain people you feel more defensive with, and why is that? (your big brother, your parents, your teachers)\n\nAre there certain issues that make you feel more defensive than others?\n\nWhat can someone do to make you feel less defensive when they are upset with you or asking you for something they need?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. South Park © Comedy Partners"
    },
    {
      "grade": 6,
      "week": 12,
      "name": "Practice Gratitude",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade6week12-pandagratitude.gif",
      "summary": "Resilient people know that gratitude improves not only your mental health but also your physical health, so they make it a practice to be grateful.",
      "readAloud": "It makes sense that gratitude is good for your mental health, but studies show that gratitude improves our physical health too! Gratitude also improves relationships.\n\nThanksgiving is all about gratitude. But we can forget to look for things to be grateful for during the rest of the year. \n\nIt’s easy to get so caught up in the stresses of daily life that we focus on what’s going wrong in our lives instead of looking for what’s good.   \n\nThere are always things to be grateful for in our lives. We just have to make an effort to notice them. \n\nThis is why some people practice gratitude the same way they practice a sport. They set aside time every day or every week to think about what they are grateful for. Some people do it every morning. Others do it when they go to bed. Others do it when they attend spiritual services.\n\nHowever you do it, the more you practice gratitude, the better you will feel - and the healthier you will be.",
      "questions": "Who is a person you are grateful for?\n\nWhat is an experience you are grateful for?\n\nWhat is a quality about yourself you are grateful for?\n\nCan you think of anything you are grateful for that came from something that was not so great?\n\nHow can you remember to practice gratitude more often in your daily life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Kung Fu Panda © DREAMWORKS ANIMATION L.L.C."
    },
    {
      "grade": 6,
      "week": 13,
      "name": "Captain Your Own Support Team",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade6week13-minionsumbrella.gif",
      "summary": "Resilient people know that everyone needs support, and they are choosy about who they pick to be on their support “team.”",
      "readAloud": "We cannot choose everyone in our lives. We get who we get with our parents, siblings, teachers, coaches and bosses. We can, however, seek out friends and mentors to support us. \n\nJust as captains of dodge ball teams pick the best players to be on their teams, we can pick the best people to support us in different parts of our lives.\n\nIf we look around, we can find people willing to help us with athletics, academics, artistic activities and with social and emotional support.\n\nOften, people don’t realize how many people are willing to help, but resilient people know how good it feels to support others, so they seek support for themselves. \n\nFinding people who see and bring out the best in us gives us a huge advantage in being successful and happy.",
      "questions": "Can you describe a time when you reached out for support from someone? ”\tWhat qualities do you look for in a friend or mentor, and how can you bring more people like that into your life? \n\nCan you describe a time when you supported someone else, and how did it feel? \n\nDid you know that many successful adults have mentors who support them in their jobs? How do you think their mentors help them? \n\nSometimes the people who are supposed to support us are not actually supportive—like people we thought were friends are no longer friendly. What can you do when that happens?\n\nWhat qualities do you look for in a friend or mentor, and how can you bring more people like that into your life? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Minions © Universal City Studios LLC"
    },
    {
      "grade": 6,
      "week": 14,
      "name": "Listen to Yourself (Your Wise Self)",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade6week14-birdsdoyouhearthat.gif",
      "summary": "Resilient people know there is a voice inside of them that knows what is best for them, so they tune in and listen to their wise self.",
      "readAloud": "Inside each of us, whether we know it or not, is a wise self. Some people call it trusting their gut. Others call it intuition, Inner Wisdom, or their higher self. Whatever you call it, we all have a voice inside of us that will help us make good decisions for ourselves, if we listen to it.\n\nThe problem is, we forget to listen to it. \n\nWe spend so much time racing from activity to activity we can’t hear ourselves think.  And when we do have down time, we distract ourselves with things like scrolling our social feeds, watching mindless videos, or playing video games to the point that there’s no space for the voice of our Inner Wisdom to get through.\n\nBut it doesn’t take much to hear our wise self. All we have to do is take away distractions, focus our attention on our inner world, breathe and listen inside.\n\nLet’s do it now: \n\n1.\tTake a deep breath. \n2.\tClose your eyes, if you’re comfortable doing so.\n3.\tFocus on the sound of your breath as you relax and breathe.\n4.\tAsk your wise self if it has anything to tell you.\n5.\tTake three more breaths as you listen inside.\n6.\tWhen you’re ready, open your eyes and focus on the outside world again.\n\nThat is how you tune into your Inner Wisdom whenever you have a decision to make or need to know how to take care of yourself. Just focus on your inner world, ask a question, and listen while you relax and breathe. \n\nThis mind-body skill of connecting your mind and body by focusing on your breath is a quick way to tune into your Inner Wisdom.",
      "questions": "How was it to do that short exercise? Did you get an answer you’d like to share?\n\nDo you make it a habit to tune into your wise self and trust your gut?\n\nWhat words do you use for trusting your gut or tuning into your Inner Wisdom?\n\nCan you give an example of a time that listening to your wise self helped you?\n\nCan you give an example of a time when you wished you had listened to your wise self?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Angry Birds © Rovio Entertainment Corporation"
    },
    {
      "grade": 6,
      "week": 15,
      "name": "Have Beliefs That Comfort You, Guide You, and Give Your Life Meaning",
      "category": "Spirit",
      "subCategory": "Beliefs",
      "image": "grade6week15-poohbeliefs.gif",
      "summary": "Resilient people know that our beliefs shape our lives, so they have beliefs that comfort them, guide them, and give their lives meaning.",
      "readAloud": "This time of year reminds us that spiritual beliefs are a powerful source of comfort and guidance for many people. Some beliefs give meaning to our lives. Some beliefs inspire us to be kind to each other. Some beliefs comfort us around our fears, especially around our fear of death. And many beliefs connect us to supportive communities and nurture a sense of belonging.\n \nRegardless of what you believe, choosing to live your life in alignment with beliefs that give you purpose, connect you to the most loving parts of yourself, and nurture optimism for your future is a choice worth making.\n \nMany people don’t realize that they can choose what to believe. They think that because they already believe something, it must be true. But people believe many different things, some of which are contradictory. Yet somehow, people have evidence for contradictory beliefs. \n\nSo, if you realize you have a belief that does not make you feel very good, look for evidence of something better. If you look hard enough, you will probably find it.\n \nWhatever you believe, we hope it brings you peace, joy and love this holiday season. ",
      "questions": "Do you have a belief that inspires you to be the best person you can be?\n\nDo you have a belief that motivates you to be kind to others?\n\nDo you have a belief that comforts you around any of your fears?\n\nDo you have a belief that gives your life meaning or a sense of purpose?\n\nDo you have a belief that makes you feel optimistic about your future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Winnie the Pooh © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 16,
      "name": "Set Achievable Goals (Around Strengths and Values)",
      "category": "Strengths",
      "subCategory": "Values",
      "image": "grade6week16-snoopygoals.gif",
      "summary": "Resilient people believe goals should inspire them and make them feel good about themselves, so they set achievable goals around their strengths and values.",
      "readAloud": "New Year’s Resolutions often start out as inspiring but end up making us feel disappointed in ourselves if we fail to achieve them. Here are some tips for setting goals that will make you feel good about yourself:\n\nSet goals that are small enough that you can actually get them done. If you set goals that are too big, you’ll give up, feel bad about yourself, and be less likely to set future goals for yourself. On the other hand, if you set goals you can actually achieve, you will build positive momentum that allows you to achieve goal after goal after goal.\n\nSet goals around your strengths. The more time you spend using your strengths, the better you feel about yourself. And, if you set goals around things you actually enjoy doing and that come naturally to you, you are more likely to achieve your goals, which also makes you feel good about yourself. It’s a double-whammy of feeling good about yourself! \n\nSet goals around your values. Doing things that demonstrate your personal values also makes you feel good about yourself. If you value being a good athlete, set goals around your fitness. If you value being a good student, set goals around your academics. If you value being kind, set goals around making people feel good.\n\nFind people to support you in achieving your goals. Having people to support you and hold you accountable will make you much more likely to achieve your goals.",
      "questions": "What is one goal you have for yourself right now, and does it feel achievable? \n\nCan you think of a goal to set around one of your strengths or values? \n\nCan you think of someone who could support you in achieving one of your goals? \n\nWhat is a goal you successfully achieved in the past, and what factors contributed to you achieving them? \n\nIf you think about goals you did not achieve in the past, what factors prevented you from achieving them? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Snoopy © Peanuts Worldwide"
    },
    {
      "grade": 6,
      "week": 17,
      "name": "Have a Vision for Your Future",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade6week17-yodafuture.gif",
      "summary": "Resilient people have hopes and dreams for themselves, and they identify the steps required to make those dreams come true",
      "readAloud": "Our education system doesn’t always do the best job of helping kids connect the dots between the dreams they have for themselves and the things they need to accomplish in order to make those dreams happen.\n \nFew kids enjoy doing homework and studying for tests, but the ones who want to be scientists, astronauts, or doctors study hard because they know they have to get good grades in order to have a chance of making their dreams come true.\n \nFew kids enjoy getting up before the sunrise and running, lifting, or playing a sport until their muscles give out, but the ones who want to be competitive athletes push themselves to their limits because they know their chances of success depend on it.\n \nEven if you don’t know exactly what you want to be when you grow up, when you identify what you want for yourself in the future you find reasons to be your best self today.\n \nIf you want to have a nice lifestyle, you probably want to make the most of your education to get a good job.\n \nIf you want to have lots of friends and loved ones, you probably want to do your part to have good relationships with people.\n \nIt’s okay if you don’t have a clear vision for your future yet, but thinking about it will keep you motivated to be your best self on the path to wherever it is you want to go.\n",
      "questions": "Do you have a vision for your future? If so, what is it and what steps are required to get there?\n\nDo you have a career you might be interested in? If so, can you name one step on the path to that career?\n\nWhat is the most important thing you can do right now to give yourself the best opportunities to have the future of your dreams?\n\nWhere do you work hard in life—in academics, athletics, hobbies, relationships—and what motivates you to work hard?\n\nWhat do you think happens to people when they don’t have a vision for their future?\n",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page ",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 18,
      "name": "Resist the Urge to Compare Yourself to Others",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade6week18-justlikeyouiwannabejustlikeyou.gif",
      "summary": "Resilient people know that everyone’s personality and life journey is unique, so they resist the urge to compare themselves to others.",
      "readAloud": "We live in a world where it’s hard to avoid seeing how fabulous everyone else’s life is. Our social feeds are full of shiny, happy people having the most fun and living their best lives. It’s easy to feel like you are the only person whose life is falling short of your expectations. \n\nBut comparing ourselves to others is a trap that almost always leads to feeling bad. It might feel good at first, but if you constantly judge yourself by how well you stack up to others, you will lose connection to the very traits that make you… you. You will forget about the strengths that make you feel good about yourself and the values that give your life meaning and a sense of purpose.\n\nOnly you know what you genuinely enjoy doing, what your natural talents are, what you don’t mind practicing in order to improve, what you care about, what kind of person you want to be, and what you want your future to look like. \n\nYou get to design your life around your hopes and dreams. And true happiness will come when you appreciate the unique gifts you have to offer the world and live your life authentically.\n\nSo, the next time you start to compare yourself to someone else, ask yourself instead if you are living in alignment with your own strengths, values and interests. And give yourself permission to do you. (And remember, just because someone’s life looks great in pictures, doesn’t mean they don’t have their own challenges.)",
      "questions": "Do you agree that comparing yourself to others usually ends up making you feel bad? Why or why not?\n\nDo you agree that true happiness comes when we appreciate the unique gifts we have to offer the world and live our lives authentically? Why or why not?\n\nWhat is one of your personal strengths? (something you enjoy doing and that you are good at)\n\nWhat is one of your personal interests? (something you enjoy doing even in your free time)\n\nWhat gives your life meaning? (something that makes you feel good about your life)",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Big Hero 6 © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 19,
      "name": "Harness the Power of the Mind-Body Connection",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade6week19-homerrelaxing.gif",
      "summary": "Resilient people know their minds have the power to make their bodies feel better, and their bodies have the power to make their minds feel better.",
      "readAloud": "We all know that our minds and bodies are connected. When our minds are worried, our bodies get tummy aches. When our minds are angry, our bodies get tense muscles. When our minds are sad, our bodies produce tears.\n\nWe might not realize that we can use our minds to make our bodies feel better, and use our bodies to make our minds feel better.\n\nWhen we notice that our mind is upset about something, we can do something physical like taking some deep breaths, or going for a walk, or shooting some hoops and we will feel better mentally.\n\nAnd when we notice we feel stress in our bodies, we can imagine in our minds relaxing our bodies and we will feel better physically.\n\nLet\u0027s do it right now:\n\nTake three slow, deep breaths and imagine the muscles in your body relaxing and calming down a little bit.\n\n(Take 3 breaths.)\n\nWhen your mind relaxes, your body relaxes too–and vice versa–because they are connected.\n\nSo pay attention to what\u0027s going on in your mind and body and use the mind-body connection to feel better mentally and physically.   ",
      "questions": "How does anger show up in your body? What about excitement or happiness? \n\nCan you describe a time when you were upset about something and you felt better after doing something physical? \n\nHow about the opposite: Can you describe a time when you made yourself feel better physically by focusing your mind on something that felt better? \n\nHow does your mental state affect you in sports? Do you notice a difference in how your attitude affects how well you play? \n\nFocusing your mind on the sound and feel of your breath is the quickest way to connect your mind to your body. Can you describe a time when you used your breath to relax mentally and physically?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 20,
      "name": "Know the Four Ways to Feel Better",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade6week20-angrybirdsdeepbreath.gif",
      "summary": "Resilient people know that their thoughts, emotions, bodies and behavior are interconnected and that they can feel better by doing something positive for any of the four.",
      "readAloud": "Our minds and bodies are connected. In fact, our minds, bodies, emotions, and behavior are connected. \n\nWhen we are mentally stressed, we feel negative emotions like anxiety, we have physical symptoms like tense muscles or headaches, and our behavior shows how stressed we are—we snap at people, for example. \n\nIt works the other way too. When our bodies are down with an illness, we feel emotionally down, the thought track in our heads is down too, and our behavior shows that we feel bad—we withdraw from people, for example. \n\nWhat’s going on in our minds matches how we feel emotionally, how we feel physically, and how we behave.\n\nThe good news is, if we can feel better in one of the areas, we’ll feel better in all of them. For example, if we get some exercise, our body will obviously feel better, our mood (emotions) will lift also, we will stop being so short-tempered (behavior), and the thought track in our head will be a little more positive. \n\nSo, the next time you want to feel better, think of something you can do to feel better mentally, emotionally, physically or behaviorally:\n\n* Think a more positive thought by looking at the situation a little differently.\n* Do something that lifts your emotions—like listen to a great song, hang out with a person who makes you laugh, or read something that inspires you.\n* Do something to feel better physically—like shoot some hoops or go for a walk.\n* Or behave in a way that feels good—like do something kind for someone else.",
      "questions": "For many people, doing something physical like going for a run or getting a hug from someone who loves you is a good way to feel better. Is there something physical that works for you when you want to get out of a bad mood?\n\nGreat songs, funny videos, and kind friends can lift your emotions. What else do you do to feel better emotionally—something that is not physical?\n\nOften, the hardest thing to change first is your thoughts, so don\u0027t even try to think positively until you\u0027ve done something to improve your mood. Which of the four (your thoughts, emotions, body or behavior) is hardest for you to shift? \n\nWhich of the four (your thoughts, emotions, body or behavior) is easiest for you to change? \n\nHave you ever noticed that if someone tries to cheer you up before you’re ready, it just makes you feel worse? What do you do when that happens?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Angry Birds © Rovio Entertainment Corporation"
    },
    {
      "grade": 6,
      "week": 21,
      "name": "Don’t Have to be Right",
      "category": "Beliefs",
      "subCategory": "Interpersonal Skills",
      "image": "grade6week21-evenwhemI\u0027mwrong.gif",
      "summary": "Resilient people know that they don’t know everything and they’re not always right, so they take in new information and are open to changing their minds.",
      "readAloud": "No one is always right. There is never just one way to look at a situation. People who are unwilling to consider other people’s points of view are not fun to talk to. They are not even fun to be around.\n \nLife is a process of learning and growing, taking in new information, and adapting to changing situations. Being able to think through things and be flexible allows you to find creative solutions and have better-feeling relationships.\n \nWe have all experienced how bad it feels to have an argument with someone who just has to be right and doesn’t even try to understand our side of things. And we’ve all experienced how great it feels when someone really listens to what we have to say and allows us to change their mind a little.\n \nThis is how we grow as individuals and how we strengthen our relationships.\n \nBeing willing to change your mind is a sign of intelligence and curiosity and empathy. So the next time you have the opportunity to defend your point of view, do it. But also, allow the other person to do the same and do your best to understand what they are saying. It doesn’t have to be a “win/lose” situation.",
      "questions": "Can you describe a time when you listened to someone else’s perspective and changed your mind about something?\n\nCan you describe a time when you changed your mind in an argument and actually apologized for something?\n\nPolitics, religion, and certain lifestyle choices are topics people can be inflexible about. What other topics do people have a hard time changing their minds about or even talking about?\n\nDo you agree that it is a good thing to be willing to change your mind? Why or why not?\n\nWhat can someone do in a discussion that makes you more likely to hear their perspective?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Copyright unknown."
    },
    {
      "grade": 6,
      "week": 22,
      "name": "Find Your \"Flow\"",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade6week22-shoothoops.gif",
      "summary": "Resilient people give their minds a break by getting lost in activities they enjoy. ",
      "readAloud": "Most of us spend a lot of time forcing our minds to do several things at once—like doing our homework in front of the television while carrying on a conversation. \n\nBut multitasking is stressful, and sometimes our minds need a break. One of the best ways to give our minds a break is to “single-task” something we enjoy.\n\n“Flow” is the mental state in which you are so fully engaged in whatever you are doing you lose all sense of time and place. Some call it being “in the zone.” \n\nAthletes achieve flow when they are so engrossed in their sport they don’t even notice what’s going on around them. Artists feel flow when they get lost in the act of painting. Writers experience flow when the words come pouring out and hours go by unnoticed.\n\nFinding activities that allow you to feel highly engaged and singularly focused will greatly improve your life. These activities allow the world—and your worries—to drop away. They also boost your self-esteem and sense of empowerment, because they allow you to demonstrate a strength and accomplish something you value.\n\nSo, find your flow and give yourself a mental and emotional boost.  ",
      "questions": "Can you describe a time when you experienced “flow”—when you were so engaged in doing an activity that you lost track of time and of your surroundings for a little while? \n\nCan you name a physical activity (like shooting hoops, dancing, or kicking a soccer ball) that allows you to experience “flow” ? \n\nCan you name an artistic activity (such as drawing, playing a musical instrument, or singing) that allows you to experience “flow”? \n\nCan you name a mental activity (like some kind of puzzle or chess) that allows you to experience “flow”? \n\nWhich activities can you do well while multitasking, and which ones do you need to single-task to do well?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 6,
      "week": 23,
      "name": "Trust Your Instincts",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade6week23-somethingfeelsoff.gif",
      "summary": "Resilient people trust themselves to make good decisions by tuning into their instincts or gut feelings. When we pay attention we can feel when something is off.",
      "readAloud": "Everyone has an opinion. Our parents, teachers, coaches, siblings, friends, and people we see on social media and television all have opinions about who we should be and how we should live. If we aren’t careful, we can follow someone else’s advice, which might work for them but isn’t good for us.\n\nIf we are always looking outside of ourselves for guidance, we can get lost.\n\nThe good news is, we have a source of guidance inside of ourselves, and all we have to do is tune into it.\n\nWe can feel in our bodies whether a decision is good for us or not. We can feel in our guts when a person is trustworthy or not. We can feel when something is off.\n\nSuccessful people don’t ignore those inner feelings. They trust themselves, because they learned early on to tune into their own inner guidance and feel into decisions. They ask themselves, “Does this feel right to me, or does it not? Then, they sit with the question and listen inside themselves.\n\nIf you try it a few times, you’ll see for yourself how powerful your inner guidance system can be. It’s always there for you. You just have to tune into it.",
      "questions": "Can you give an example of a time you trusted your instincts?\n\nDo you agree that it’s important to be able to trust yourself to make good decisions instead of looking to other people for guidance? Why, or why not?\n\nHave you ever ignored a “red flag” or gut feeling that you shouldn’t do something, and if so, how did that turn out?\n\nWhy do you think trusting our gut feelings, intuition, Inner Wisdom or whatever you call it works? Where does it get its information?\n\nDoes anyone in your life talk about the importance of trusting your gut? What words do they use?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Trek: Lower Decks © Paramount Global"
    },
    {
      "grade": 6,
      "week": 24,
      "name": "Pay Attention to How You Come Across to Others",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade6week24-grumpy.gif",
      "summary": "Resilient people pay attention to how they come across to others, which helps them show up as their best selves and avoid being misunderstood. It\u0027s okay to be grumpy sometimes, but you might not want people to think you\u0027re always grumpy. ",
      "readAloud": "While it is often good advice to “just be yourself” and not worry about what other people think of you, there is something else to consider. If you are not aware of how you come across to others, you might be misunderstood. \n\nShyness can come across as not being interested. Insecurity can come across as arrogance. Humor can sometimes feel insensitive. Excitement can sometimes feel overwhelming.\n\nObviously, these types of misunderstandings can have a huge impact on your ability to make friends and have close relationships. \n\nThe best way to find out how you come across to others is to ask, but make sure you ask people who genuinely care about you because this kind of feedback can be hard to hear. If that feels too scary, just pay attention to the following when you are interacting with others:\n\n* What is your tone of voice like?\n* What is your body language saying?\n* Do you interrupt people or listen well?\n* Do you tend to be more positive or negative?\n* Do you prefer to joke around or be more serious?\n* Do you use sarcasm to cover up your true feelings? \n\nEven if you don’t end up changing anything about how you present yourself, checking in to confirm how you come across to others gives you valuable information. ",
      "questions": "Have you ever been in a situation where someone got the wrong impression of you? If so, what could you have done differently to prevent that? \n\nHow do you want to come across to others? What words would you use for the impression you want to give? \n\nDo you present a different side of yourself to different people? For example, do you present a different side to your friends than you do to your parents? \n\nAre there people in your life who always make you feel good when you interact with them? If so, what can you learn from them and how they present themselves that could help you? \n\nCan you think of someone you know who doesn\u0027t realize how they come across? If so, what advice would you give them if they wanted it?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Snow White © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 25,
      "name": "Have a Personal Mission Statement",
      "category": "Needs/Values",
      "subCategory": "Strengths",
      "image": "grade6week25-missionaccomplishedhomersimpson.gif",
      "summary": "Resilient people know who they are, what makes them feel good about themselves, and what gives their lives meaning. These ideas can be summed up in a personal mission statement that gives them purpose and direction.",
      "readAloud": "Starting as young children, we each get to decide who we want to be, how we want to show up in the world, and what we want to do with our lives. These decisions seem small at first, like, will I play basketball or football? Will I sing or paint? Will I be kind to others or gossipy?\n\nAs we get older, the decisions have more consequences. Will I push myself to be the best student, athlete, artist I can be or will I downplay my talents? Will I reach for opportunities that interest me or will I stay in my comfort zone? Will I have the courage to be vulnerable or will I hide my true self? \n\nThinking about what matters to you, what gives your life meaning, and what sets you apart from others keeps you grounded when life throws you a curveball or disappointment. Even when life as you know it has drastically changed, you can find creative ways to fulfill your personal mission. \n\nAnswering the questions below will help you create your personal mission statement.",
      "questions": "What words do you hope people use when they describe the kind of person you are? \n\nWhat is one special talent or interest you have? \n\nIf you could bring positive change to the world in some way, what would it be? \n\nWhat is one goal you have for yourself? \n\nWhat is the first thing that comes to mind when you think of what your personal mission statement could be? It doesn’t have to be grand like curing cancer or becoming president or landing on Mars, although it certainly can be. Your personal mission could be to bring laughter to people’s lives, or to be the best athlete you can be, or to try new things and not let failure slow you down. So here’s the question again: What is the first thing that comes to mind when you think of what your personal mission statement could be? (Don’t overthink it. Just say whatever pops into your head.)",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 26,
      "name": "Notice What You Enjoy Doing and Do More of It",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade6week26-thisisfun.gif",
      "summary": "Resilient people know that everyone has a unique set of strengths and interests and making time for them builds self-esteem and adds balance to their lives.",
      "readAloud": "Many of the things we have to do as kids are not exactly enjoyable. We have to do our homework. We have to practice our instrument. We have to study for the SAT. And while these things are important if we want to create opportunities for ourselves in the future, it’s also important to balance out our schedules with things we actually enjoy.\n\nIf we spend all of our time checking someone else’s boxes or trying to make our college applications look good, we can turn into adults who have forgotten what they enjoy, what comes naturally to them, and what they are genuinely interested in.\n\nBut nurturing our own interests and strengths is important, not only because all work and no play is not good for anyone’s mental health, but also because it builds self-esteem. \n\nKnowing and using our personal strengths builds self-esteem.\n\nThink about it–we feel good about ourselves when we do things we’re good at. And, we get good at things when we are interested in them and want to spend time doing them.\n\nEach of us is unique in wonderful ways, and we are our best when we express our special combination of strengths and interests.\n\nSo make time for things you do just for you–because they’re interesting to you, fun for you, and they make you feel engaged, alive and uniquely you. In other words, notice what you enjoy doing and do more of it!",
      "questions": "What is one of your personal strengths?\n\nWhat is one of your personal interests?\n\nDoes it make sense to you that owning and using your personal strengths builds self-esteem? Why or why not?\n\nDoes it make sense to you how interests can turn into strengths? Why or why not?\n\nDo you agree that it’s important to balance out hard work with things you do for fun and because they’re interesting to you? And do you have a good enough balance of those things in your life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Tangled © Disney Enterprises, Inc."
    },
    {
      "grade": 6,
      "week": 27,
      "name": "Be Open to Learning and Changing Your Mind",
      "category": "Beliefs",
      "subCategory": "Interpersonal Skills",
      "image": "grade6week27-homerchangemind.gif",
      "summary": "Resilient people know they do not know everything, so they stay open to learning and changing their minds.",
      "readAloud": "When you are a kid, it’s pretty clear that there are many things you do not yet know. Parents and teachers find new things every day to show you and teach you.\n\nBut as people grow into adults, they can stop being curious and instead become rigid in their thinking and set in their ways. They stop seeing different perspectives, which limits their opportunities and relationships.\n\nBeing able to change course and adapt to new situations is key to success.\n\nWhen you remember that you don’t know what you don’t know, you open your life to new possibilities. When you remember that life is a process of learning and growing, you try new things and take the pressure off of yourself to have to be right about everything.\n\nAnd when you give yourself permission to change your mind, you relax about your decisions, knowing you can avoid regret by making a new decision whenever you want to. ",
      "questions": "Can you describe a time when you changed your mind about something, and what made you change it? \n\nCan you describe a time when you listened to another person’s perspective and changed your mind in an argument? \n\nCan you give an example of how you are curious and/or open-minded? \n\nWhy do you think adults sometimes stop being willing to change their minds? \n\nHave you found any helpful tips for presenting your argument when you want to change someone\u0027s mind?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 28,
      "name": "Focus on What’s in Your Control",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade6week28-simpsonsundercontrol.gif",
      "summary": "Resilient people don\u0027t waste time focusing on things they cannot control. When faced with an obstacle, they look for something within their control to move forward and feel better.",
      "readAloud": "When you are upset about something, it’s easy to think something outside of you needs to change in order for you to feel better. Your parents need to be less strict. Your friends need to be nicer. Your coach needs to play you more.\n\nBut other people’s behavior is out of your control. You can ask them to be different, but their behavior is out of your control. So are many other aspects of your life.\n\nOn the other hand, you can always find an action step within your control that will allow you to feel better—even if you cannot change the other person\u0027s behavior or the situation. This action step might not fix the situation, but it will put you back in control of your life, which will feel empowering and get the ball moving in the right direction.\n\nSo, when faced with a disappointment or obstacle, ask yourself this question:\n\n“What is an action step I can take—something within my control—to feel better?”\n\nLet’s say, for example, your coach isn’t playing you as much as you’d like. Focusing on how mad you are will feel bad.\n\nBut if you identify an action step—like asking your coach what you need to do to get more playing time, or putting in extra practice on your own, or reading about how the athletes you admire got so good—you will feel more in control of your own life and you will be moving toward your goal of playing more.  \n\nThere is always something you can do to take care of yourself and feel better, so get in the habit of focusing on what you can control when life is feeling challenging",
      "questions": "Can you describe a time when life didn\u0027t go your way, but instead of getting discouraged you took an action step to move forward?\n\nCan you describe a time when a person would not do what you wanted, but you took an action step to take care of yourself anyway?\n\nWhat is something you can always do to feel better even when you cannot control a frustrating situation?\n\nWhat do you say to yourself when life doesn\u0027t go your way and you don\u0027t want to give up on something you care about? \n\nIs there anything you are upset about right now that you could decide either to stay angry about or take an action step to feel better about? What could you do to make yourself feel better?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 29,
      "name": "Feel People Out",
      "category": "Inner Wisdom",
      "subCategory": "Interpersonal Skills",
      "image": "grade6week29-madedecisiontotrustyou.gif",
      "summary": "Resilient people pay attention to how people make them feel and choose to spend time with good-feeling people.",
      "readAloud": "We can feel in our bodies when someone feels open, kind and trustworthy, and we can feel in our bodies when someone feels edgy, closed off, unkind and untrustworthy. \n\nThe problem is when you’re young you are forced to spend time with people who just happen to be in your class or on your team or in your neighborhood. And you don’t have much choice about the adults in your life either.\n\nThat being said, you can make the most of whatever social group you find yourself in if you get good at “feeling people out.”\n\nIf you tune into how people feel and trust your gut when meeting new people, you will be able to choose friends you can trust to have your best interest at heart. You can also find trustworthy adult mentors to support and guide you. \n\nSo, feel people out and spend time with people who lift you up and inspire you to be the best version of yourself.",
      "questions": "What does it feel like in your body when you meet someone who feels like an open, trustworthy, safe person? \n\nWhat does it feel like in your body when you meet someone who feels like a closed, untrustworthy, or even dangerous person? \n\nObserving people is part of feeling people out. What differences can you observe between good-feeling and bad-feeling people (such as body language, tone of voice, eye contact)? \n\nWhat can you do if you are NOT SURE if someone is a good, safe person? \n\nWhat can you do to keep yourself safe when you meet someone who does not feel good to you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Tangled © Disney Enterprises, Inc."
    },
    {
      "grade": 6,
      "week": 30,
      "name": "Admit When You\u0027re Wrong and Make Amends",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade6week30-iwaswronganime.gif",
      "summary": "Resilient people know that no one is perfect. They also know that taking ownership of their mistakes and making amends when they need to is a sign of strength.",
      "readAloud": "Some people think that admitting when you are wrong is weak. They think being right and winning every argument is the way to seem strong.\n\nBut no one is perfect. Everyone makes mistakes, and everyone is wrong sometimes. We all do things we regret, and having the courage to admit when we mess up and take an action step to make things right is actually a sign of strength.\n\nThink back to the last time someone genuinely apologized to you for something they did. You probably like and respect them more for it, not less.\n\nTaking ownership of our mistakes and making amends, which means making it right with the person we wronged, improves our relationships. It makes our bonds stronger. On the other hand, people who never learn this skill miss out on deep, good-feeling relationships.\n\nSo, be honest with yourself, admit when you are wrong and make amends, and take your relationships to the next level.  ",
      "questions": "Can you describe a time when you admitted you were wrong about something and tried to make it right with the other person? \n\nAre you good at apologizing? If so, why? If not, why not? \n\nHave you ever noticed how you can tell when someone is not genuine in their apology? How can you tell they don’t really mean it? \n\nDo you have someone in your life who is good at admitting when they are wrong and apologizing? If so, how does it make you feel when they do that? \n\nDo you have someone in your life who never apologizes? If so, how does that make you feel?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 6,
      "week": 31,
      "name": "Seek Peace",
      "category": "Spirit",
      "subCategory": "Mind-Body Skills",
      "image": "grade6week31-peacefulnaturerain.gif",
      "summary": "Resilient people know they must balance the frenzy of daily life with moments of peace to calm their minds and nurture their spirits.",
      "readAloud": "Life is stressful. Teachers, coaches, counselors, parents and peers all demand certain things from you. No matter how hard you work there’s always more you could do. And you’re constantly being tested and judged and compared to others. It’s exhausting. And it can feel overwhelming if you don’t take care of yourself.\n\nBut if you make time to pause in the frenzy and seek a feeling of peace, you can calm your mind, relax your body, and lift your spirits.\n\nOur nervous systems are not meant to be on high alert all day. We are meant to have down time–time to unwind and just be without the pressure to work or learn or perform. But the demands of our over-scheduled lives make time to “do nothing” hard to come by. And many people fill what down time they do have with social media, television, video games and other activities that are not actually peaceful or inspiring.\n\nThe good news is we have a powerful source of peace with us all the time. All we have to do is close our eyes and tune into the sound and feel of our breath. It’s always with us, and its natural rhythm is soothing and calming.\n\nAnother source of peace that’s always with us is nature. Even if you live in a city, there are plants, trees, clouds, stars in the night sky, animals to pet and birds to watch.\n\nSo, seek peace–even for a few moments–and you will feel more centered, clear-minded, inspired, and empowered.",
      "questions": "Do you ever use your breath to calm down and feel better (which is a mind-body skill)? If so, when was the last time you did it?\n\nWhen do you feel most peaceful and calm in any given day?\n\nCan you describe a time when something in nature made you feel peaceful?\n\nDo you make it a point to have peaceful downtime in your daily life, or is it hard to find the time?\n\nCan you think of anything your school or community could do to make it easier for people to find moments of peace?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 6,
      "week": 32,
      "name": "Make Sure Your Beliefs Feel Good and Inspire You",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade6week32-haroldlookgood.gif",
      "summary": "Resilient people know that our beliefs shape our lives, so they are careful about what they choose to believe.",
      "readAloud": "We are bombarded by other people’s beliefs every day–beliefs about beauty, and happiness, and money, and success, and love. But our beliefs shape our lives, so it’s important to choose beliefs that feel good and inspire us to be our best.\n\nIf you think people are out to get you, you will be guarded and on the lookout for the next person who is rude to you, and you won’t even notice the nice people. But if you think, “There are some mean people out there, but there are plenty of nice ones too,” you will notice when people are kind instead of focusing only on negative interactions.\n\nIf you think, “I’m bad at taking tests,” you will feel defeated even before you take the test, which might cause you not to study that hard… which could cause you not to do that well. But if you think, “There must be a way to get more comfortable taking tests,” you could ask your teacher or school counselor for support, which could lead you to learn new test-taking strategies that really help.\n\nThe weird thing is, though, people are often unaware of the beliefs that are shaping their lives. We hear them say, “I’m not that smart,” “Studying is for nerds,” or “I could never get a job like that.” But they don’t realize they are limiting themselves.\n\nThe truth is we can choose to have better-feeling beliefs… about life, about other people, and about ourselves. And, if we look for evidence of these better-feeling beliefs, we will find it. We can find evidence for almost anything, so we might as well look for evidence that inspires us.\n\nSo, pay attention to the things you tell yourself and the things you say out loud. And when you come across a negative belief, find a better-feeling way to talk about that situation. And notice the power a shift like that brings.",
      "questions": "Can you give an example of a belief that is prominent in our culture that you agree with? (This could be about beauty, happiness, money, success, or love.)\n\nCan you give an example of a belief that is prominent in our culture that you do not agree with?\n\nHave you ever noticed how someone’s belief is shaping their life but they don’t realize it? Why do you think we are often unaware of our own limiting beliefs?\n\nCan you give an example of a negative belief you decided to change and how you found evidence of a better-feeling belief?\n\nDo you agree that we can find evidence for almost any belief?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc"
    },
    {
      "grade": 6,
      "week": 33,
      "name": "Channel Your Anger Into Positive Action",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade6week33-insideoutanger.gif",
      "summary": "Resilient people know that anger can be destructive or empowering, so they pause before reacting and then channel their anger into positive action.",
      "readAloud": "Anger can be one of the most destructive forces in the universe.\n\nIf we turn our anger outward and lash out at others, it can destroy relationships. If we turn our anger inward and beat ourselves up, it can destroy our self-esteem.\n\nIf we stuff our anger down and hold it in, it can destroy our sense of wellbeing and leave us feeling edgy and short-tempered.\n\nBut, anger is a natural human emotion—an appropriate emotional response we when we feel mistreated or witness an injustice.\n\nIt’s okay to be angry. It’s what you do with your anger that makes the difference between allowing anger to be destructive or empowering.\n\nLearning to channel your anger into positive action is a key resilience skill.\n\nThe next time you feel angry, take a pause. Remember, you cannot think when you are flooded with emotion. Then, when you have calmed down enough to be able to think constructively, identify what you need and what’s in your control, and take action to take care of yourself or address the situation in a way that feels positive.",
      "questions": "Adults tell kids all the time NOT to be angry, or they tell them to “get over it.” Do you think it’s okay to be angry sometimes? \n\nWhat do you usually do with anger? Do you let it out or hold it in, and does that feel destructive or empowering to you? \n\nHave you ever noticed that you can’t think straight when you are really angry? \n\nWhat can you do to pause when you are angry and calm down so you can think straight? (Some people count to ten. Some take a few deep breaths. Others walk away for a bit.) \n\nCan you describe a time when you channeled your anger into a positive action step to either take care of yourself or address the situation in a constructive way?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Inside Out ©”The Walt Disney Company"
    },
    {
      "grade": 6,
      "week": 34,
      "name": "Know the Ways You are Resilient ",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade6week34-nemo.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. This year we have talked about: \n\n* Getting good at hearing feedback\n* Talking about yourself in an empowering way\n* Trusting your gut\n* Having healthy self-care habits\n* Not having to be right\n* Being open to learning and changing your mind\n* Feeling people out\n* Admitting when you’re wrong and making amends\n* Finding your “flow”\n* Seeking peace\n* Asking for what you need\n\nThese are just a few of the resilience skills we have covered this year. You were already resilient, and now you have even more skills to choose from when you come to a bump in the road. ",
      "questions": "Can you describe a way you have been resilient this year?\n\nAre there any skills from this Resilience Reminders™ program that you learned or tried that worked for you?\n\nWhat words do you use to define resilience? \n\nDo you think talking about these types of things is helpful for students and families? Why or why not?\n\nDo you believe in reaching out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Finding Nemo ©”The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 1,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade7week1-bartbounce.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. \n\nThe other good news is you are already resilient! You have probably picked up quite a few resilience skills already in life. For example, if you…\n\n* Can name your personal strengths\n* Have good-feeling relationships\n* Reach out for support when you need it\n* Take care of yourself–physically, mentally, emotionally and spiritually\n* Learn from your losses\n* Use your breath to calm down and focus\n* Trust your gut\n\nIf you do any of these things, you are already resilient. \n\nThese Resilience Reminders™ will add more skills to your list and remind you of helpful things you already know.",
      "questions": "How do you define resilience? What words do you use? \n\nHow do you demonstrate resilience? Which skills do you use? \n\nWhat disappointment, challenge or failure are you most proud of yourself for overcoming, and what skills did you use to feel better?\n\nWhat is one way you take care of yourself–physically, mentally, emotionally or spiritually?\n\nDo you agree that it demonstrates resilience to reach out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 2,
      "name": "Be Choosy About What You Put In-to Your Body and Mind",
      "category": "Needs/Values",
      "subCategory": "",
      "image": "grade7week2-can-i-come-in-kyle-broflovski.gif",
      "summary": "Resilient people value being physically and mentally healthy, so they are choosy about what they put into their bodies and minds.",
      "readAloud": "What we put into our bodies matters. If we only put in junk food and sugary drinks, our bodies will not feel well or work well. Even young children know this. Healthy bodies require healthful food and plenty of water. (They also need regular exercise and plenty of sleep, but right now we’re talking about what we put into our bodies.) If we want healthy bodies, we have to put in healthful foods and drinks.\n\nThe same is true of our minds. If we want healthy minds, we have to feed our minds with healthful “food” too. If we feed our minds a diet of negative thoughts and bad-feeling emotions, our mental health will suffer. \n\nThe problem is we live in a world where it can be difficult to get away from the constant stream of negative chatter. Our televisions, radios, and social media feeds send out a never-ending flow of information that can make us feel angry, scared, lonely, confused and overwhelmed, if we aren’t choosy about what we tune into.\n\nBut if you pay attention to how you feel when you are watching something or listening to someone, you can start being choosy about what you let in. \n\nIf you want to feel empowered, inspired, optimistic, and like you matter, then make sure you seek out people who make you feel good, conversations that inspire you, and media content that makes you believe you can dream big and have a positive impact.",
      "questions": "What is something healthful that you put into your body?\n\nWhat is something healthful that you put into your mind?\n\nIs there anything you put into your body that is not super healthful and that you might like to stop putting in or limit how much you put in?\n\nAnd what about your mind–is there anything you put into your mind that doesn’t make you feel good and that you might want to stop letting in or limit how much you let in? (This could be shows, videos, social content, or hanging out with people who bring you down instead of who lift you up.)\n\nDo you ever take time to not put anything into your mind… to just sit quietly and be present? What could be the benefit of doing that?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. South Park © Comedy Partners"
    },
    {
      "grade": 7,
      "week": 3,
      "name": "Don\u0027t \"Catastrophize\"",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade7week3-lisacatastrophize.gif",
      "summary": "Resilient people know that words have power, so they use words that inspire them to find solutions instead of cause them to give up.",
      "readAloud": "“Catastrophizing” is exaggerating how bad something is to make it seem like a catastrophe—like an absolute failure, like a hopeless situation, like the end of the world.\n\n\"Coach John is the worst coach ever. He hates me. He never says anything helpful. He always makes me feel bad.”\n\n“Ms. Johnson is the meanest teacher in the whole world. She is never nice to me. She always picks on me.”\n\n“This is the hardest class ever. I don’t understand anything the teacher is saying. I will never be able to do this.”\n\nYou can feel the power of these words in your body. They make you feel hopeless.\n\nWords like \u0027never\u0027 and \u0027always\u0027 cause us to shut down and give up.\n\nThey also make the people who might help us feel like we are a lost cause.\n\nSo, stop yourself from exaggerating the negative and try to use words that inspire you to problem solve and take care of yourself.",
      "questions": "Sometimes it is easier to recognize “catastrophizing” in others than in ourselves. Do you know anyone who “catastrophizes?” If so, how does it make you feel? \n\nWhy do you think words like \u0027always\u0027 and \u0027never\u0027 make us feel hopeless when used to describe negative things?\n\nCan you describe a time when you “catastrophized?” If so…How did people respond to you? What could you have said differently? \n\nCan you describe a time when you stopped yourself from “catastrophizing” and used words that inspired you to find a solution?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 4,
      "name": "Practice Tolerating Discomfort",
      "category": "Strengths",
      "subCategory": "Beliefs",
      "image": "grade7week4-icanhandlethis.gif",
      "summary": "Resilient people know that discomfort happens and their attitude about it matters, so they expect some discomfort and practice positive self-talk.",
      "readAloud": "No one likes to be uncomfortable. In fact, our brains are wired to avoid things that are unpleasant. \n\nThat being said, it is impossible to avoid all discomfort. Unexpected annoyances, negative emotions, and uncomfortable experiences are a part of daily life. So, instead of trying to avoid all discomfort, it’s helpful to practice tolerating it. \n\nHow do you do that?\n\nFirst, expect to experience some discomfort as a natural part of daily life. Research shows that the very act of expecting discomfort and “normalizing” it (which just means believing it’s “normal” to expect some discomfort in daily life) makes discomfort less uncomfortable when it happens. \n\nExpecting some discomfort also makes us more able to switch into solution-mode instead of getting stuck focusing on how uncomfortable we feel.\n\nSecond, practice positive self-talk. \n\nFor example, when you notice you feel sick to your stomach about taking a test, instead of focusing on how anxious you feel, you can focus on how well you prepared. \n\n* Your self-talk might sound like this, “It’s normal to feel anxious before a big test, but I’ve got this. I studied hard. I know the material. I can do this.”\n\nOr, if you have to do something out of your comfort zone like speak in public or perform in front of an audience or have a difficult conversation with someone, instead of focusing on how nervous or scared you feel, you could focus on how good it will feel to have given your all.\n\n* Your self-talk might sound like this, “It’s normal to be terrified to speak in public, but it’s a great skill to learn and I’ll be proud of myself for facing this fear.”\n\nThe things we say to ourselves matter. So expect some discomfort and practice using self-talk to motivate and inspire yourself.",
      "questions": "Would you say you’re good at tolerating discomfort or not so good at it? And what could you do to be better?\n\nIs your self-talk generally motivating and positive or critical and negative?\n\nHave you ever stopped yourself in the middle of freaking out about something and calmed yourself down? How did you do it?\n\nHave you ever noticed how the person you’re with can make things better or worse What can they do that make the situation better? What can they do to make it worse?\n\nCan you give an example of how expecting something to be uncomfortable makes it not feel as bad as when you don’t have those expectations?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Hercules © Disney Enterprises, Inc."
    },
    {
      "grade": 7,
      "week": 5,
      "name": "Know Your WHY",
      "category": "Needs/Values",
      "subCategory": "",
      "image": "grade7week5-whyareyoudoingthat.gif",
      "summary": "Resilient people know why they do what they do. This motivates them to do the things that aren’t that fun and keeps them on track to achieve their goals.",
      "readAloud": "Let’s face it. We all have to do things that aren’t that fun in order to succeed in life and achieve our goals. If you want to get good at playing an instrument or singing, you have to practice, practice, practice. If you want to have a healthy body, you have to eat well and exercise. If you want to have lots of career opportunities, you have to study hard and do well in school. \n\nEven the most glamorous careers require some things that aren’t that fun: Professional athletes have to work out to exhaustion and eat well almost every day. Doctors have to show up for emergencies in the middle of the night. Successful business people have to get up early, work long hours, and attend boring meetings when they’d rather be relaxing and hanging with friends.\n\nBut successful people know what matters to them and why they do things, and this motivates them to push through the bad stuff. Resilient people are the same. When faced with a challenging, annoying or boring activity, they remind themselves of the purpose of the task, and get to work.\n\nAnd let’s not forget about parents: Parents take care of their kids when they’re sick, drive them all over creation, cook for them, clean for them, pay for them, and support them through thick and thin… even when their kids are crabby and rude. Why? Because they love them and value them and care about being good parents.\n\nThat’s the power of WHY–it gives meaning and direction to our lives and inspires us to show up as our best selves in the hard moments.",
      "questions": "What is something you don’t particularly enjoy doing, but you do it anyway, and why do you do it?\n\nWhat is one goal you have for yourself that requires doing some things that aren’t that fun?\n\nWhat is something you are proud of yourself for doing?\n\nWhat is a career you might be interested in, and what is a not-so-fun requirement of that career?\n\nWhat is a character trait that is important to you that requires you to make hard choices sometimes? (Being honest, disciplined, loyal, not gossiping, a good friend)",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Family Guy © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 6,
      "name": "Have Friends Who Match Your Different Parts",
      "category": "Interpersonal Skills",
      "subCategory": "Strengths",
      "image": "grade7week6-patrickspongebobdance.gif",
      "summary": "Resilient people seek out friends who match the different parts of their personalities.",
      "readAloud": "We all have different parts of our personalities. We have sporty parts, creative parts, and academic parts. We have silly parts, serious parts, nerdy parts, and parts that know how to be cool.\n\nWe also all have parts that contradict each other. For example, maybe you have a part that wants to do well in school and another part that hates doing homework.\n\nOr maybe you have a part that doesn’t want to disappoint your parents and another part that wants to rebel against their authority.\n \nAn important part of growing up is to explore our different parts and decide which ones we want to build our lives around.\n \nHowever, if you only have friends who match your sporty side, you’ll have difficulty exploring your creative side. If you only have friends who match your rebellious side, you’ll have difficulty exploring the part of you that wants to succeed academically.\n \nAcknowledging all of your different parts–your strengths and even the weaknesses you’d like to improve–will allow you to seek out a diverse group of friends who can help you try new things and learn and grow. ",
      "questions": "What are some of your parts? (sporty, creative, academic, funny, shy, kind) \n\nDo you have any parts that seem to contradict each other? (like a part that really likes people and a part that enjoys being alone) \n\nWhat part of yourself do most of your friends match? \n\nDo you have different friends for different parts? \n\nDo you have a part you’d like to explore but that you don’t have any friends who match?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 7,
      "week": 7,
      "name": "Use Your Breath to Calm Down and Focus",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade7week7-breathe.gif",
      "summary": "Resilient people know that their breath is their best tool for calming down, collecting their thoughts, and focusing when they\u0027re nervous, upset, or need to perform.",
      "readAloud": "Many people do not realize that their greatest tool for calming down when they are nervous or upset about something is always with them... It\u0027s their breath.\n \nUsing your breath to calm down and focus is a super power. And it’s super easy to do.\n \nTake a slow, deep breath right now and focus on the SOUND of the air going in and out of your nose…\n \nTake another slow, deep breath and this time focus on the FEEL of your belly expanding as the air goes in and then contracting as the air goes out…\n \nTake another slow, deep breath and this time, after you fill your belly all the way up, IMAGINE ALL OF YOUR MUSCLES RELAXING as you slowly let the air back out again.\n \nYou just meditated! It’s as easy as that.\n \nFocusing on your breath is an easy way to get your mind to pay attention to what\u0027s going on in your body, instead of on whatever has you nervous or upset. And when you focus on your breath, you naturally calm down.\n \nEven if you only have time for two or three deep breaths, you’ll get the benefit of feeling a little bit more calm and focused, which helps you feel better if you\u0027re upset and perform better if you’re nervous. It also helps you collect your thoughts if you have an important decision to make or during an argument.",
      "questions": "Can you describe a time you took a few deep breaths to calm down when you were NERVOUS or UPSET about something and it worked? \n\nCan you describe a time you used your breath to calm down and PERFORM better in a sport or another performance activity?\n\nCan you describe a time you used your breath to calm down in the middle of an ARGUMENT? \n\nCan you describe a time you used your breath to calm down and collect your thoughts when you had an important DECISION to make?\n\nHave you ever done any other kind of meditation? If so, what was it like and how did it make you feel?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. "
    },
    {
      "grade": 7,
      "week": 8,
      "name": "Allow Negative Emotions to Tell You What You Need",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade7week8-alladinwhatdoyouneed.gif",
      "summary": "Resilient people use negative emotions to take care of themselves. When they notice they are upset, they identify what they need and take an action step.",
      "readAloud": "Disappointments, setbacks, challenges, failure, and even grief are a part of life. No one, no matter how good their life looks from the outside, can avoid emotionally difficult times.\n \nResilient people accept the fact that life gets hard sometimes. They also accept that negative emotions are not “bad.” They feel bad, but negative emotions are natural responses to certain experiences. In fact, negative emotions can be used for good, if we use them to take care of ourselves. \n\nHow do you use negative emotions to take care of yourself? You allow them to tell you what you need to feel better.\n \nWhen you notice you are upset, ask yourself, \"What do I need to feel better?\" \n\nThen, take an action step to take care of yourself.\n\nFor example, if you’re sad and you need a hug, you can reach out to a supportive friend or loved one. \n \nEven when you cannot change the situation, there will be action steps you can take to take care of yourself.",
      "questions": "Last time you were ANGRY, what did you need, and what was an action step you took or could have taken to feel better?\n\nLast time you were SAD, what did you need, and what was an action step you took or could have taken to feel better?\n\nLast time you were OVERWHELMED, what did you need, and what was an action step you took or could have taken to feel better?\n\nLast time you were WORRIED, what did you need, and what was an action step you took or could have taken to feel better?\n\nLast time you were FRUSTRATED, what did you need, and what was an action step you took or could have taken to feel better?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Monsters Inc. © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 9,
      "name": "Talk About Your Mental Health",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade7week9-idontwannatalkaboutit.gif",
      "summary": "Resilient people know that talking about their mental health is good for their mental health, so they have the courage to seek support when they feel anxious, overwhelmed or sad. ",
      "readAloud": "Here’s the thing: Everyone–seriously–everyone has times in life when they feel anxious, overwhelmed, sad, lost, insecure, unsure of themselves or unable to shake themselves out of a dark place. \n\nWhen this happens, we need to seek help. \n\nIf you had a physical symptom, you would likely seek help. You would tell your parents about your illness or injury and they would tell a professional, so you could get the treatment you need.\n\nThe same is true of mental health. Sometimes our symptoms require professional help. And even if they are not that intense, talking about what’s going on is helpful. That’s what therapy is–talking about what’s troubling you, so you can find solutions and start to feel better.\n\nEven mental health professionals seek help from others when they have mental health challenges, because talking and being supported by others is healing. We humans are social beings. It feels good to connect, especially when we are feeling vulnerable.\n\nSo, the next time you are feeling upset about something or just out of sorts, have the courage to talk about it with someone who feels trustworthy and supportive–whether that is someone you already know or someone you can access through a variety of mental health resources available through your school counselor.",
      "questions": "Can you describe a time when you talked with someone about how you were feeling and it helped you feel better?\n\nDo you think it’s true that everyone has times in life when they have overwhelming emotions and don’t know how to help themselves?\n\nDo you think many students your age feel comfortable talking about mental health issues? Why or why not?\n\nWhy do you think talking is helpful when you are upset about something?\n\nDo you support the idea of students in your school going to the school counselor for mental health resources, if they need them?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Lion King © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 10,
      "name": "Make Sure Your Expectations Bring Out the Best in You",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade7week10-homernotperfect.gif",
      "summary": "Resilient people know that the expectations we have for ourselves shape our lives, so they make sure their expectations bring out the best in them.",
      "readAloud": "The expectations we have for ourselves have real power. They can inspire us to reach for our dreams or discourage us from living up to our potential.\n\n* If we expect perfection, we will always be disappointed in ourselves.\n* If we think things should be easy, we will not get in the habit of working hard.\n* If we believe we should be able to succeed without seeking feedback, we will miss opportunities to learn, grow and have mentors.\n* And if we focus on avoiding failure, we will not take risks that could bring great rewards.\n\nOn the other hand, if we expect there to be twists and turns, disappointments and triumphs, failures and successes, we will be open to what life is offering and more able to go with the flow.\n\nWe want to be like Goldilocks when setting expectations–not setting them so low that we fail to push ourselves out of our comfort zones nor setting them so high that we judge ourselves too harshly. Somewhere in the middle is where we can expect ourselves to mess up sometimes but believe we can recover.\n\nSo, set expectations that inspire you, and you will enjoy more of the journey. ",
      "questions": "Why do you think so many people struggle with perfectionism today, and what advice would you give someone about not having to be perfect?\n\nWhat is something that did not come easy to you but you worked hard to learn or improve?\n\nAre you good at hearing feedback or do you take it as criticism?\n\nCan you describe a time you failed at something but learned from it?\n\nWould you say your expectations for yourself are too low, too high, or just right, and why?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Boy and the Heron © Studio Ghibli"
    },
    {
      "grade": 7,
      "week": 11,
      "name": "Feel Into Your Decisions",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade7week11-somethingdoesntfeelright.gif",
      "summary": "Resilient people know they can feel in their bodies when something feels like a good decision or a bad decision, so they feel into their decisions.",
      "readAloud": "When you are a kid, lots of people have opinions about who you should be, how you should behave, and what you should do with your life. With so many people telling you what to do, it can be hard to know how to make decisions for yourself.\n\nThe good news is we all have a powerful decision-making tool with us all the time: our bodies. Our bodies will tell us if a person feels safe, if an activity will be good for us, and if a decision is what we really want to do, or if we are trying to please someone else.\n\nIf no one tells you how important your gut feelings are, you might not pay attention to them. But if you feel into your body, the information is there.\n\nSo the next time you have a decision to make, listen to what your body has to tell you. In fact, let’s try it right now: \n\n* Think of a decision you need to make. \n* Now, close your eyes, if you’re comfortable doing that, and imagine yourself deciding one way. \n\n* We’ll take about 10 seconds now for you to see how it feels to imagine yourself deciding to do a certain thing.\n\n* Now, keep your eyes closed and imagine making a different decision.\n\n* We’ll take another 10 seconds for you to see how it feels to imagine yourself deciding to do a different thing.\n\n* Now open your eyes and take a few more relaxing breaths while you tune into what your body has to tell you about which decision feels better to you.\n\nOur thinking minds can get sidetracked by insecurities, limiting beliefs (like “I’m not good enough”) and other people’s desires for us. But our Inner Wisdom will speak to us through our bodies if we tune in and listen.",
      "questions": "How does it feel in your body when you make a decision you are excited about?\n\nHow do you make decisions? Do you listen to your body? Do you think it through? Do you talk to other people? Do you do all of the above?\n\nMany people call the feeling in your body your “gut feeling.” What do you call it?\n\nCan you describe a time when you could feel in your body whether something was a good decision or not?\n\nHow was it to do that decision-making exercise, and did you learn anything about yourself?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 12,
      "name": "Practice Gratitude",
      "category": "Emotional Intelligence",
      "subCategory": "Interpersonal Skills",
      "image": "grade7week12-pandagratitude.gif",
      "summary": "Resilient people know that gratitude improves not only your mental health but also your physical health, so they make it a practice to be grateful.",
      "readAloud": "It makes sense that gratitude is good for your mental health, but studies show that gratitude improves our physical health too! Gratitude also improves relationships.\n\nThanksgiving is all about gratitude. But we can forget to look for things to be grateful for during the rest of the year. \n\nIt’s easy to get so caught up in the stresses of daily life that we focus on what’s going wrong in our lives instead of looking for what’s good.   \n\nThere are always things to be grateful for in our lives. We just have to make an effort to notice them. \n\nThis is why some people practice gratitude the same way they practice a sport. They set aside time every day or every week to think about what they are grateful for. Some people do it every morning. Others do it when they go to bed. Others do it when they attend spiritual services.\n\nHowever you do it, the more you practice gratitude, the better you will feel - and the healthier you will be. ",
      "questions": "Who is a person you are grateful for?\n\nWhat is an experience you are grateful for?\n\nWhat is a quality about yourself you are grateful for?\n\nCan you think of anything you are grateful for that came from something that was not so great?\n\nHow can you remember to practice gratitude more often in your daily life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Kung Fu Panda © DREAMWORKS ANIMATION L.L.C."
    },
    {
      "grade": 7,
      "week": 13,
      "name": "Play to Your Strengths and Strengthen Your Weaknesses",
      "category": "Strengths",
      "subCategory": "Beliefs",
      "image": "grade7week13-spongebobstrengths.gif",
      "summary": "Resilient people spend as much time as possible using their strengths. They also acknowledge their weaknesses and take actions to either improve them or lessen their impact.",
      "readAloud": "The more time you spend using your strengths, the happier and more empowered you will feel. That being said, we all have to do things that are out of our comfort zones sometimes. That’s just the way life is.\n\nIt can feel embarrassing to admit that you are not as strong as you would like to be in something. But no one is great at everything. And acknowledging your weaknesses allows you to get help to either improve them or find some other way to lessen the negative impact they have in your life.\n\nSome weaknesses can be improved with training and practice. Others can be offset by working with someone who complements your skills. Successful people surround themselves with people whose strengths make up for their weaknesses.\n\nReminding yourself that everyone has strengths and weaknesses will make you feel better about your own shortcomings. So will reminding yourself what your strengths are when you are acknowledging a weakness. For example, maybe you are not strong in math but you are great at science. Or you are not very good at drawing but you are great at basketball. Or you are not great at speaking in public but you are great at making friends.\n\nBeing honest with yourself (and others) about your strengths and weaknesses is far more beneficial than pretending to be good at something you are not, and it allows you to play to your strengths and shore up your weaknesses.",
      "questions": "What is one of your strengths and what is one of your weaknesses? \n\nCan you describe a time when you took action to improve a weakness? \n\nCan you describe a way that your weaknesses are offset by working with people whose strengths complement your weaknesses? (Maybe you are on a team with people who have different strengths than you do.) \n\nWhat is an example of how you “play to your strengths” (which means spending time using your own strengths)? \n\nWhat is one weakness you would like to improve or offset?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 7,
      "week": 14,
      "name": "Have Heroes and Mentors",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade7week14-simpsonhero.gif",
      "summary": "Resilient people know having people who inspire and support them is key to their success, so they are constantly looking for heroes and mentors.",
      "readAloud": "Our beliefs shape our lives. If we believe it is possible for someone like us to be a… professional athlete, famous chef, successful doctor, or even an astronaut or President of the United States, we might try to achieve that for ourselves. \n\nIf we do not believe it is possible, we will not even try. \n\nWhat makes us believe something is possible? Seeing others succeed. \n\nThat’s where heroes come in. Having heroes helps us believe in our own dreams. It also helps us understand what it takes to achieve big things, as we can learn about our heroes’ journeys to success. Usually, this means reading about them or watching movies or videos about them, but not actually talking to them, because heroes are often famous or might not even still be alive.\n\nThat’s where mentors come in. Mentors are people we can actually talk to about the things we are trying to achieve. Mentors are willing to share their knowledge and experience and support us in achieving our goals. Why? Every mentor has their own reasons, but you might be surprised how many people are willing to help when a passionate young person has the courage to ask. \n\nSo be on the lookout for people who inspire you to dream big, and then find people who will support you in achieving those dreams.",
      "questions": "Who is one of your heroes, and what do you admire about them?\n\nDo you want to be someone’s hero one day, and if so, what will people admire about you?\n\nWhy do you think having a mentor is helpful for people?\n\nIs there anything you are trying to achieve that it would be helpful to have a mentor for?\n\nWould you be willing to be a mentor one day, and if so, why?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company.  The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 15,
      "name": "Have Beliefs That Comfort You, Guide You, and Give Your Life Meaning",
      "category": "Spirit",
      "subCategory": "Beliefs",
      "image": "grade7week15-poohbeliefs.gif",
      "summary": "Resilient people know that our beliefs shape our lives, so they have beliefs that comfort them, guide them, and give their lives meaning.",
      "readAloud": "This time of year reminds us that spiritual beliefs are a powerful source of comfort and guidance for many people. Some beliefs give meaning to our lives. Some beliefs inspire us to be kind to each other. Some beliefs comfort us around our fears, especially around our fear of death. And many beliefs connect us to supportive communities and nurture a sense of belonging.\n \nRegardless of what you believe, choosing to live your life in alignment with beliefs that give you purpose, connect you to the most loving parts of yourself, and nurture optimism for your future is a choice worth making.\n \nMany people don’t realize that they can choose what to believe. They think that because they already believe something, it must be true. But people believe many different things, some of which are contradictory. Yet somehow, people have evidence for contradictory beliefs. \n\nSo, if you realize you have a belief that does not make you feel very good, look for evidence of something better. If you look hard enough, you will probably find it.\n \nWhatever you believe, we hope it brings you peace, joy and love this holiday season. ",
      "questions": "Do you have a belief that inspires you to be the best person you can be?\n\nDo you have a belief that motivates you to be kind to others?\n\nDo you have a belief that comforts you around any of your fears?\n\nDo you have a belief that gives your life meaning or a sense of purpose?\n\nDo you have a belief that makes you feel optimistic about your future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Winnie the Pooh © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 16,
      "name": "Set Achievable Goals (Around Strengths and Values)",
      "category": "Strengths",
      "subCategory": "Values",
      "image": "grade7week16-snoopygoals.gif",
      "summary": "Resilient people believe goals should inspire them and make them feel good about themselves, so they set achievable goals around their strengths and values.",
      "readAloud": "New Year’s Resolutions often start out as inspiring but end up making us feel disappointed in ourselves if we fail to achieve them. Here are some tips for setting goals that will make you feel good about yourself:\n\nSet goals that are small enough that you can actually get them done. If you set goals that are too big, you’ll give up, feel bad about yourself, and be less likely to set future goals for yourself. On the other hand, if you set goals you can actually achieve, you will build positive momentum that allows you to achieve goal after goal after goal.\n\nSet goals around your strengths. The more time you spend using your strengths, the better you feel about yourself. And, if you set goals around things you actually enjoy doing and that come naturally to you, you are more likely to achieve your goals, which also makes you feel good about yourself. It’s a double-whammy of feeling good about yourself! \n\nSet goals around your values. Doing things that demonstrate your personal values also makes you feel good about yourself. If you value being a good athlete, set goals around your fitness. If you value being a good student, set goals around your academics. If you value being kind, set goals around making people feel good.\n\nFind people to support you in achieving your goals. Having people to support you and hold you accountable will make you much more likely to achieve your goals.",
      "questions": "What is one goal you have for yourself right now, and does it feel achievable? \n\nCan you think of a goal to set around one of your strengths or values? \n\nCan you think of someone who could support you in achieving one of your goals? \n\nWhat is a goal you successfully achieved in the past, and what factors contributed to you achieving them? \n\nIf you think about goals you did not achieve in the past, what factors prevented you from achieving them? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Snoopy © Peanuts Worldwide"
    },
    {
      "grade": 7,
      "week": 17,
      "name": "Have Good Feeling Talk Habits",
      "category": "Interpersonal Skills",
      "subCategory": "Emotional Intelligence",
      "image": "grade7week17-heyarnoldlistentoyourself.gif",
      "summary": "Resilient people know that words have power so they get in the habit of being more positive than negative in conversations.",
      "readAloud": "At any given moment, there are lots of things to complain about. Whether it’s something in your personal life or something in the news, there are always reasons to be upset. And, expressing negative emotions is not necessarily a bad thing. In fact, if you are upset about something, talking about it and getting support is a great way to take care of yourself.\n \nOn the other hand, there’s a difference between getting support and just venting or being negative. Often people don’t realize how negative they sound, but it doesn’t feel good to be around people who are always complaining or criticizing.\n \nAnd the truth is we have a choice about what we talk about—even when life is not going our way, we get to choose the words that come out of our mouths. And speaking words that focus on the more positive side of things will make you feel better. It will also make others feel better.\n \nAgain, negative emotions are not bad. However, you can make yourself and those around you feel better if you choose to inject some positivity into your conversations sometimes too. Some researchers say the ideal praise-to-criticism ratio is 5 to 1. Meaning, for every negative comment you make, you need to share five positive comments as well.\n \nWhatever you decide is the right ratio for you, becoming aware of your talk habits will give you a powerful tool for feeling better.",
      "questions": "What word would you use to describe how you show up in conversations: positive, negative, supportive, critical, or any other word that comes to mind?\n\nWhat is your praise-to-criticism ratio, and what would you like it to be?\n\nHow do you want people to feel around you?\n\nIn what ways do you already seek out positive conversations or add positivity to conversations?\n\nCan you think of any negative talk habits you could do less of, like gossiping about, criticizing, or blaming others?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page ",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc."
    },
    {
      "grade": 7,
      "week": 18,
      "name": "Visualize Success",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade7week18-imaginationflying.gif",
      "summary": "Resilient people believe in the power of their imagination, so they spend time visualizing success.",
      "readAloud": "Every successful athlete imagined winning the championship game. Every successful musician imagined playing sold out stadiums. Every successful astronaut imagined blasting off into space. \n\nVisualizing success is a way to convince yourself that something is actually possible. And when you believe something is possible, you take steps to make it happen. \n\nNot only that, research shows that practicing things in your mind, actually makes you better in real life. In one study, people who imagined shooting basketball shots for 30 days improved almost as much as people who actually practiced with real basketballs! \n\nOur minds are powerful tools. \n\nSo, put away your screens for a little while and spend some time visualizing yourself achieving one of your dreams. \n\nIn fact, let’s try it right now:\n\n* Close your eyes, if you’re comfortable doing that.\n* Take a deep breath and blow it all the way out.\n* Now picture yourself practicing something you really want to get better at. I’ll give you 10 seconds to imagine that.\n* Now take another deep breath and picture yourself achieving one of your goals and celebrating your success. I’ll give you another 10 seconds to imagine that.\n* Now take a final deep, relaxing breath, and come back to the room.",
      "questions": "How was that visualization for you? What did you see? How did it feel? \n\nPracticing things in your mind is also called mental rehearsal, and lots of athletes do it. Why do you think it is helpful? Have you ever done it? \n\nImagining yourself achieving one of your dreams is also called daydreaming. Why do you think that is helpful? What do you daydream about? \n\nVisualizing success raises your expectations for yourself. Why do you think having high expectations for yourself helps you achieve things but having low expectations for yourself prevents you from achieving things? \n\nIt\u0027s not always easy for people to start visualizing success for themselves. What do you think could help someone who has a hard time with it? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Peter Pan © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 19,
      "name": "Own Your Super Powers",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade7week19-spongebobsuperpowers.gif",
      "summary": "Resilient people know that the more they use their strengths, the more empowered and happier they are, so they own their superpowers and make the most of them.",
      "readAloud": "Life is full of things that are hard. Math is hard for some people. Speaking in public is hard for others. Singing, dancing, focusing on boring homework, shooting hoops, telling jokes and making friends—all of these things are hard for some people. But they are not hard for everyone. And the people who find these things difficult have plenty of other things they are good at.  \n\nEveryone has a different set of personal strengths. And when you own your strengths and use them often, you feel good \nabout yourself. \n\nThink about it: When you spend time doing things that come easily to you, that you are good at, and that you enjoy doing, you naturally feel good about yourself. Using your strengths builds confidence and self-esteem.  \n\nUsing your strengths is also a good way to forget about your troubles for a while. If you can get lost in the act of doing something you enjoy like drawing or playing basketball or singing or playing an instrument, you can shift your mood from not-so-great to better.  \n\nStrengths don’t have to be something you can make a career out of, although they can be. Maybe you are a good listener or a good dancer or a good baker or you are good at making your friends laugh. If it makes you feel good about yourself, consider it a strength. Also, consider it important and look for ways to use it more often.",
      "questions": "What is one of your strengths? \n\nWhat would your friends, family members, teachers, coaches, or mentors say is one of your strengths? \n\nWhat strengths do you admire in others? \n\nWhat strengths would you like to build in yourself? \n\nIf you could make a career out of one of your strengths what would it be?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 7,
      "week": 20,
      "name": "Flip the Script on Failure and Talk About What You Learned",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade7week20-southparkflip.gif",
      "summary": "Resilient people don’t beat themselves up when they fail. They look for lessons and talk about what they learned.",
      "readAloud": "Most people don’t realize how much the words they say affect how they feel and how they act. But consider how the following statements make you feel:\n\n“We lost. I played terribly. I’m sure the coach thinks I’m horrible and will never play me again. I’m no good at this sport. I don’t even know why I keep playing.” \n\nThis obviously makes you feel defeated. \n\nNow consider this: \n\n“We lost. I didn’t play my best. I see now that my blocking could be better. I’m going to work extra hard on that this week, so I’m better in the next game.” \n\nThis obviously feels better. It gives you—and everyone else who hears it—hope, and lets them know you are committed to getting better. \n\nSuccessful people know that failure is only failure if you fail to learn from it, and by learning the lessons in failures they transform them into stepping stones to success.\n\nMichael Jordan, maybe the greatest basketball player of all time, was cut from his high school basketball team! What did he learn? That he wanted to keep playing and that failure is a step on the journey to success.\n\n“I have missed more than 9000 shots in my career. I have lost almost 300 games. On 26 occasions I have been entrusted to take the game winning shot, and I missed. I have failed over and over and over again in my life. And that is why I succeed.” ",
      "questions": "Can you describe a time when you failed at something and learned from it? \n\nCan you think of something positive in your life that could not have happened if you hadn’t failed at something first? \n\nHow do you talk about your failures? Do you beat yourself up or talk about what you learned? \n\nWhat do you think it means about you if you fail? \n\nWhat is something you could say to yourself next time you fail?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. South Park © Comedy Partners"
    },
    {
      "grade": 7,
      "week": 21,
      "name": "Know Your Patterns",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade7week21-spongebobpattern.gif",
      "summary": "Resilient people pay attention to how their own minds work. They notice their patterns of behavior and create habits that empower them.",
      "readAloud": "Many people don’t realize they can choose how to respond to things that upset them. When they feel bad, they act out without realizing they could respond differently. And when they do this over and over, they get stuck in disempowered patterns of behavior instead of taking control and creating new, more empowered patterns of behavior.  \n\nThe thing that sets humans apart from other animals is that we can observe our own minds. We can think about how we think, feel and behave. And we can identify patterns of how we respond to things that make us uncomfortable and choose different patterns if we want to.\n\nInstead of shutting down when we don\u0027t know how to do something, we can ask for help.\n\nInstead of lashing out when we feel angry, we can take a deep breath and ask for what we need.\n\nInstead of blaming others when we make mistakes, we can look for lessons and try again.\n\nSo, start paying attention to your patterns, and when you notice one that doesn\u0027t feel so great, create a new pattern that allows you to be the best version of yourself.",
      "questions": "What is your pattern when you have to learn something new? Do you give yourself a pep talk and bring a positive attitude, or do you worry and get discouraged quickly? \n\nWhat is your pattern when you are stressed or challenged? Do you slow down and take care of yourself, or do you get angry and lash out at people? \n\nWhat is your pattern when you feel sad or overwhelmed? Do you reach out for support, or do you withdraw and keep it to yourself? \n\nWhat is your pattern when someone gives you feedback? Do you appreciate the opportunity to improve, or do you get defensive and stop listening? \n\nCan you think of a pattern you would like to change or one you have already changed, and how would/did that make you feel?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 7,
      "week": 22,
      "name": "Have the Courage to be Real",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade7week22-spongebobdontfeellikeit.gif",
      "summary": "Resilient people know that we build connections with people when we are open and real, so they have the courage to be vulnerable.",
      "readAloud": "When life gets crazy, scary or confusing it really helps to have people you can be honest with. After all, how can you get support for what you’re dealing with if you don’t open up about what’s really going on for you mentally and emotionally?\n \nSometimes people think they should hide their true feelings, especially if those feelings make them seem insecure, not tough enough, or uncool in some way. They worry that people will judge them if they open up and be vulnerable. Some believe it’s weak to need emotional support.\n \nBut the truth is, deep connections are not built when people pretend to be perfect. They are formed when people have the courage to share their insecurities and imperfections, because everyone has them and we can all relate to them.\n \nYou can’t know this until you try it, which is why it takes a lot of courage. And some people are not safe to be vulnerable with. But if you pay attention to how people make you feel, and seek out people who feel non-judgmental and trustworthy, you can surround yourself with relationships that lift you up when life gets you down. And you can do the same for others.",
      "questions": "Do you agree that you connect with people who are open and real rather than people who don’t share anything personal?\n\nCan you think of a reason it is good to have people you can be vulnerable with?\n\nHow can you tell if someone is trustworthy, non-judgmental and safe to be vulnerable with?\n\nWhat do you do to let people know that you are not judgmental and that they can open up to you?\n\nIt is difficult to be with someone when they are having a strong negative emotion like sadness, anger, or fear. Think back to a time you felt that way and tell us what you needed from someone. How could they have helped you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 7,
      "week": 23,
      "name": "Trust Your Gut",
      "category": "Inner Wisdom",
      "subCategory": "Spirit",
      "image": "grade7week23-chewytrust.gif",
      "summary": "Resilient people know they can feel in their bodies whether something is good or bad for them, so they trust their guts.",
      "readAloud": "We live in a world where many people believe thinking is a more valuable skill than feeling. After all, we spend decades in school learning how to think but very little, if any, time paying attention to our feelings.\n\nBut tuning into our gut feelings can be a superpower if you start paying attention.\n\nWe can feel in our bodies whether a decision is good for us or not good for us. We can also feel in our bodies whether a person is open, trustworthy, and has our best interest at heart, or if they do not.\n\nWhether you call this a gut feeling, intuition, inner voice, or higher power, we all have a source of wisdom inside of us that will guide us to make good decisions if we listen to it. \n\nOn the other hand, our thinking minds can get hijacked by negative thoughts and other people’s agendas, and this can cause us to ignore the warning bells going off in our bodies and make bad decisions. For example:\n\nWhen our mind thinks negative thoughts like, “I’m not good enough. I shouldn’t even try,” our Inner Wisdom says, “Believe in yourself. You can do this.”\n\nOr, when we’re trying to convince ourselves to go along with something we know is wrong, we tell ourselves things like, “I won’t get caught. It’s only once. It’ll probably be fine,” but our gut says: “Danger. Something doesn’t feel right here. Don’t do this.”\n\nOur Inner Wisdom protects us, reminds us of our best qualities, and pushes us to go for our dreams.\n\nSo the next time you have a decision to make, do a gut check and trust your Inner Wisdom.",
      "questions": "Can you describe a time when listening to a gut feeling protected you from making a mistake or putting yourself in danger? \n\nCan you describe a time when following your gut caused something really great to happen in your life? \n\nSome people call it a gut feeling. Others call it intuition, their inner voice, or what they know in their heart. Still others think it is the voice of God. What words do you use to describe the way you just know whether something is good or bad for you? \n\nPeople call it a gut feeling because they feel it in their gut. Where do you feel it when you just have a feeling you should or shouldn\u0027t do something? \n\nWhat situations do you think tuning in to your gut feelings would be helpful in? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 24,
      "name": "Don’t Expect Yourself to be Happy All the Time",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade7week24-imnothappy.gif",
      "summary": "Resilient people know that disappointments, losses, failures, challenges and boredom are a part of life, so they don’t expect themselves to be happy all the time.",
      "readAloud": "If we aren’t careful, we can start to believe that life is supposed to be happy all the time. If we are not feeling happy every moment of the day, something is wrong with us. We’re failing at life. We’re not doing it right.\n\nCelebrities, sports stars, social media influencers, and even our peers–if you trust their social media posts–are living these glamorous lives and having these grand adventures. \n\nBut the irony is, expecting ourselves to be happy all the time sets us up for heart-breaking disappointment.\n\nIt feeds our insecurities and makes it more and more difficult to experience actual happiness.\n\nThe good news is, if we expect the daily annoyances, stressors, uncertainties, and bumps in the emotional road, we develop a mindset that is more adaptable, less self-critical, and more able to savor the good moments when they do arise.\n\nThe thing about happiness is it’s fleeting. We feel it for a few seconds, and then life takes over again. We can choose to try to savor the happy feeling, but then we remember our math test, or the fact that someone was rude to us, or how mad we are that our coach won’t play us more.\n\nTrue happiness comes when our lives have meaning and a sense of purpose, when we are working toward goals that matter to us, and when we feel connected to loved ones no matter what emotions we are feeling.\n\nSo stop expecting life to be happy all the time, and start being proud of yourself for pushing through the hard times, learning from your losses, and finding meaning in the setbacks.",
      "questions": "Do you agree that it can feel like we’re doing something wrong if we are not happy all the time?\n\nDo you agree that a certain level of stress and anxiety are a normal part of daily life?\n\nDo you understand why expecting ourselves to be happy all the time causes disappointment?\n\nDo you agree that happiness is fleeting? \n\nWhat do you think true happiness comes from?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page ",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Rocko’s Modern Life © Viacom International, Inc."
    },
    {
      "grade": 7,
      "week": 25,
      "name": "Stand Up For Yourself AND Know When to Agree to Disagree",
      "category": "Needs/Values",
      "subCategory": "Interpersonal Skills",
      "image": "grade7week25-respectthat.gif",
      "summary": "Resilient people stand up for their own values. They also make room for other people’s values and know how to agree to disagree. ",
      "readAloud": "It is hard to listen to people we disagree with when we really care about an issue. When we know they are wrong, we want to make them stop talking and convince them we are right. And when they won’t agree, our anger can boil over until we say or do something we regret. \n\nAnd here’s the thing: It’s good to know what you stand for and stand up for it. In fact, it’s one of the building blocks of self-esteem—to live your values. \n\nIf you value kindness, being kind will make you feel good about yourself. If you value giving your all in your favorite sport, working hard in practice and games will make you respect yourself. If you value standing up for others, standing up for others will boost your self-esteem. \n\nSo know what you stand for and stand up for it. \n\nAND, when you come up against differing opinions, knowing how to agree to disagree will help you be your best self and not say or do things you will later regret. \n\nIf you can listen respectfully, state your case respectfully, and walk away before you betray one of your own values, you will feel better about yourself and make the world a better place.",
      "questions": "What do you stand for? Name one or two values that are really important to you.\n\nCan you describe a time when you stood up for one of your values? \n\nDo you think it’s important to make room for other people’s values even when you disagree with them? Why or why not? \n\nCan you describe a time when you agreed to disagree and it felt good to both of you? \n\nCan you think of a circumstance when you would not agree to disagree, and if so, what would you do instead?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Avatar © Nickelodeon (Paramount Global)"
    },
    {
      "grade": 7,
      "week": 26,
      "name": "Understand the Power of the Pause",
      "category": "Emotional Intelligence",
      "subCategory": "Interpersonal Skills",
      "image": "grade7week26-timeout.gif",
      "summary": "Resilient people know they cannot think well when they are flooded with emotion, so when they are upset, they press pause and calm down before they problem-solve. ",
      "readAloud": "An interesting thing happens when we feel really angry, overwhelmed, or afraid—our bodies shift into “fight or flight” mode and we lose our ability to think constructively. Our instincts kick in and a whole bunch of physical things happen to help us run away or fight off the danger, but our minds get singularly focused on one thing: “Run! Win! Help!”\n \nThis is great, if you are actually in danger, but not so great if you are trying to communicate well, perform well, or think creatively.\n \nHere’s the thing: Our thoughts and emotions always match. \n\n* If we are feeling extreme emotions like panic, we will think extreme thoughts like, “I can’t handle this!”\n* If we are feeling negative emotions like discouragement, we will think negative thoughts like, “This will never work.”\n* If we are feeling positive emotions like optimism, we will think positive thoughts like, “We got this!”\n \nThe good news is, if we can take a pause and shift our emotions to a slightly more positive–or at least less intensely negative–place, we will have access to more constructive thoughts too.\n \nWhen we feel better, we can think more creatively. That’s just how it is.\n \nRemember, though–emotions, even intense ones, are not “bad” or “wrong” in and of themselves. They are a natural part of the human experience. It’s how we respond to our emotions that can cause problems for us.\n \nSo, getting in the habit of taking a pause when we are flooded with emotions, helps us take control of how we respond to our emotions by allowing our minds to calm down and think better. This is especially helpful to prevent a disagreement from turning into an argument.",
      "questions": "Can you describe a time when you couldn’t think well because you were flooded with emotion, but when you calmed down, you could think better?\n\nHave you ever noticed that arguments rarely get solved until people take a pause and calm down a little bit? Why do you think that is?\n\nHave you ever noticed that your thoughts and emotions always match? Can you think of a time when that wasn’t true for you?\n\nCan you describe a time when you took a pause to calm down when you were flooded with emotion?\n\nWhat works for you when you need to take a pause and want to feel better? (For some people it’s taking deep breaths, counting to 10, going for a walk, or listening to music.)\n",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 7,
      "week": 27,
      "name": "Remember the Iceberg When Trying to Resolve Conflict",
      "category": "Interpersonal Skills",
      "subCategory": "Needs/Values",
      "image": "grade7week27-avatar.gif",
      "summary": "Resilient people talk about what they need when trying to resolve conflict instead of talking about what the other person is doing wrong.",
      "readAloud": "When you are mad a someone it’s natural to want to tell them all the things they are doing wrong. “You are so rude!” “I can’t believe you would do this to me!” “You’re a terrible person!”\n \nBut telling someone how bad and wrong they are—even if it’s true—doesn’t usually resolve the conflict. It makes the other person start telling you how bad and wrong you are. So now you’re both yelling about all the horrible things you’ve done to each other.\n \nA way to change the conversation into one that is constructive is to picture an iceberg. What is above the surface of the water—the part of the iceberg we can see—are all of the things the other person is doing wrong.\n \nWhat’s below the surface—the part we can’t see—are what we NEED from the other person in order to feel better.\n \nIf we can talk about what we NEED (what\u0027s below the surface) instead of what they are doing wrong (what\u0027s above the surface) we will have a much better chance of resolving the conflict.\n \nSo, instead of saying to your friend, “You are a horrible person!”, you could say, “I need to be able to trust you not to tell my secrets.”\n \nInstead of saying to your parent, “You are such a nag!”, you could say, “I need you to let me handle this on my own.”\n \nInstead of saying to your sibling, “You are a complete jerk!”, you could say, “I need my privacy to be respected.”\n \nRemember the iceberg when trying to resolve conflict and you will have a constructive conversation.",
      "questions": "When you think of the things that make you mad, is there a common trigger? If so, what do you need in these situations?\n\nCan you think of a person you have frequent arguments with—a sibling, parent or teammate—and identify what you need from them that would make the relationship feel better?\n\nCan you describe a recent argument and how it would have gone differently if you can stated what you need?\n\nStating what you need instead of telling the person what they’re doing wrong is one strategy for resolving conflict. Can you think of another one?\n\nDo you think the iceberg is a helpful image for resolving conflict, why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Avatar © Nickelodeon (Paramount Global)"
    },
    {
      "grade": 7,
      "week": 28,
      "name": "Practice Being, Not Doing",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade7week28-daffywaiting.gif",
      "summary": "Resilient people know that being fully present in each moment is a super power, so they practice being present instead of always doing something.",
      "readAloud": "Most people today, when faced with a few minutes of having nothing to do or having to wait in a line, check their phones. Instead of sitting quietly and being present, they distract themselves with checking their messages or scrolling their feeds. This is true for adults and kids. \n\nAnd while there’s nothing wrong with distracting yourself when you’re bored or uncomfortable, it is also important to be comfortable with simply being present. Why? Because when we’re present–to the people around us and to ourselves–we have better relationships–with the people around us and with ourselves.\n\nDistraction can be a useful tool. It can help us shift our mood from a negative thought track to a better-feeling emotion, for example. But if we aren’t careful, we can find ourselves in a constant state of distraction, which doesn’t allow us to connect well with others or even with ourselves.\n\nThis can cause us not to know why we are feeling down or what we could do to feel better. And it could cause us not to understand why our social life is not feeling great or what we could do to improve it.\n\nBut if we get good at being, we have access to much more information that can help us connect and respond to the world and ourselves.\n\nLet’s try it right now:\n\n* Close your eyes, if you’re comfortable doing that, or leave them open, if that feels better.\n* And just notice whatever you notice about being here in this present moment.\n\n* Notice what you hear.\n* Notice what\u0027s going on in your body.\n* Notice what’s going on in your mind.\n\n* Let’s take about 30 seconds to just sit quietly together and see how it feels to be present.\n",
      "questions": "How was it to sit quietly and do nothing but be present? What did you notice?\n\nDo you agree that being present with people improves relationships? Why or why not?\n\nDo you agree that we shouldn’t distract ourselves from all discomfort because that prevents us from really taking care of ourselves. Why or why not?\n\nCan you give an example of when using distraction is a helpful tool?\n\nCan you give an example of when being distracted could have a negative effect?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Looney Tunes © Warner Brothers"
    },
    {
      "grade": 7,
      "week": 29,
      "name": "Choose Your Own Labels and Make Sure They Feel Good",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade7week29-homersimpsonsmart.gif",
      "summary": "Resilient people know the words people use to describe them have power, so they don’t accept labels that bring them down. They choose labels that feel good.",
      "readAloud": "People often judge other people and define them with labels.\n\nShe’s smart. He’s mean. They’re lazy.\n\nBut labels never tell the whole story.\n\nWe all have lots of different sides to ourselves, and labels can prevent people from seeing them. Labels can also limit the way we think about ourselves.\n\nIf you become known as the crazy one, for example, you might forget that you can also be level-headed and calm. Recognizing different sides of yourself will give you more options for how you show up in different circumstances.\n\nSo choose your own labels and make sure they feel good to you.",
      "questions": "Do you have any labels that other people put on you that don’t feel so good? If so, what other sides of you do those labels prevent people from seeing? \n\nWhat can you do when someone gives you a label that doesn\u0027t feel good? \n\nHas anyone ever given you a label that inspired you to be better in some way? \n\nIf you could give yourself a new label that made you feel good about yourself, what would it be? \n\nCan you think of a label that would make someone you care about feel good and remind them about a positive quality?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 30,
      "name": "Seek Inspiration",
      "category": "Spirit",
      "subCategory": "",
      "image": "grade7week30-seekinspiration.gif",
      "summary": "Resilient people know that what they focus on determines how they feel, so they seek inspiration to feel and be their best.",
      "readAloud": "Lately it feels like all anyone can talk about is bad news. Everywhere we turn scary headlines shout for our attention. There’s so much to be worried about, and if you watch the news, so little to be hopeful about.\n\nBut the world is full of wonderful things too. We just have to make a point to notice them.\n\nFlowers bloom. Birds sing. The stars in the night sky take our breath away… if we notice them.\n\nAnd nature is not the only thing that offers daily inspiration. Strangers are kind. Families are loving. Friends are supportive. Maybe not always, but goodness is there… if we notice it.\n\nIn this world where so many loud and seemingly urgent things compete for our attention, we have to be choosy about what we focus on. It’s not easy to pull our eyes away from the latest gossip or celebrity meltdown, but if we seek inspiration, we will find it.\n\nIf we choose to focus on things that inspire us, we can believe in a better world, be part of the solution, and be our best selves.",
      "questions": "Do you agree that most of the news is negative, and why do you think that is?\n\nDoes more bad news than you would like seep into your daily life, and if so, how do you hear about it? (social media, television, people talking about it)\n\nAre you able to avoid bad news, and if so, how do you do that?\n\nAre you good at noticing inspiring things, and if so, why is that?\n\nWhere do you look for inspiration?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. "
    },
    {
      "grade": 7,
      "week": 31,
      "name": "Know how to Forgive and be Forgivable",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade7week31-bartforgiveme.gif",
      "summary": "Resilient people know that even in the best relationships people get their feelings hurt, so they know how to forgive and how to be forgivable. ",
      "readAloud": "Everyone gets their feelings hurt. Sometimes it’s because people are mean or disrespectful. Other times the person didn’t mean to hurt your feelings and doesn’t understand why you are upset. \n\nYou’ve probably had it happen to you too where someone said you hurt their feelings but you didn’t mean to. \n\nWe all make mistakes, and we all deserve second chances. That’s why it’s important to know how to forgive someone AND how to be forgivable. \n\nThe most important thing to do when you are forgiving someone is to identify what you need going forward in order to feel good about the relationship, and tell them. If you need kindness, honesty, or respect, tell them. If they are not willing to give you that, you might decide not to spend time with them anymore. But, you can still forgive them, because we forgive people for ourselves—so we don’t have to carry around negative emotions. \n\nThe most important thing to do to be forgivable is to understand what the person needs from you and make an effort to give it to them. If they need you to be trustworthy, for example—to not talk behind their back or share their secrets—then be trustworthy. And mean it when you say you’re sorry. ",
      "questions": "Do you agree that one of the main reasons we forgive people is so WE can feel better and not have to carry around negative emotions?\n\nWhen someone upsets you, what do they need to do for you to forgive them? \n\nWhen you upset someone, what do you do to get them to forgive you? \n\nHave you ever had your feelings hurt and the person said they didn’t mean to? What did you do to repair the relationship? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 32,
      "name": "Get Help When You Need It",
      "category": "Needs/Values",
      "subCategory": "Interpersonal Skills",
      "image": "grade7week32-bartgethelp.gif",
      "summary": "Resilient people know that asking for help when they need it is a strength not a weakness so they don’t hesitate to reach out for support when faced with a challenging situation.",
      "readAloud": "Many people think that asking for help is a sign of weakness. They think they should be able to handle their problems on their own. They don’t want to seem needy, so they suffer in silence pretending they are fine when they are actually not okay and don’t know what to do.\n \nBut everyone needs help sometimes. Disappointments, setbacks, failures, and obstacles that feel overwhelming are a part of life. No one gets to skate through life without facing hardship. And asking for help is one of the most courageous, smart things you can do.\n \nYes, it feels vulnerable to ask for help, but most people are happy to support someone in need. It feels good to help someone. It gives meaning to our lives to have a positive impact in other people’s lives.\n \nSo the next time you feel upset and alone, reach out and allow someone to support you. You will both feel better if you do.",
      "questions": "Many people have a hard time asking for help. Where do you think people learn that they should handle their problems on their own? \n\nCan you describe a time when you helped someone when they needed it, and how did it feel to help them? \n\nCan you describe a time when you reached out for support when you needed it, and how did it feel to allow someone to help you? \n\nCan you think of a time when you did not ask for help when you needed it but now wish you had? \n\nCan you think of anything your school could do to support students in being more supportive of each other, or do you think that up to individual students?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 33,
      "name": "Prioritize Play",
      "category": "Needs/Values",
      "subCategory": "Beliefs",
      "image": "grade7week33-bartprioritize.gif",
      "summary": "Resilient people know it’s important to balance work with restful and joyful activities, so they prioritize play in their lives.",
      "readAloud": "Life is busy and the list of things we have to get done in a given day can feel overwhelming. We have to do our homework. We have to go to football practice. We have to practice our instrument for 20 minutes. We have to do our community service hours. There never seems to be enough time in the day, so we stay up later and get less sleep.\n\nHowever, if we burn the candle at both ends, we will end up feeling exhausted, unmotivated and overwhelmed. We need down time–time to rest and recharge. And research now shows that young people need unstructured play time–time to have fun with their friends without expectations for achievement.\n\nBottom line: we need balance in our lives. Not only do we need to balance work and play, but we also need to balance having fun and getting enough rest, socializing and down time, screen time and human interaction, doing and being, and being distracted and being present. \n\nSo, how do we make our lives feel more balanced when we cannot add more time to the week? We have to shift our priorities. If you believe balance is important, start prioritizing play.",
      "questions": "On a scale of 0-10 how balanced does your life feel?\n\nDo you agree that we need balance to feel our best? Why or why not?\n\nWhat is one thing you could do to add more balance to your life?\n\nDo you agree with the list of things we need to balance: having fun and getting enough rest, socializing and down time, screen time and human interaction, doing and being, and being distracted and being present?\n\nIs the way you spend your time a good representation of your priorities, or do you spend time on things that are not your own priorities?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 7,
      "week": 34,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade7week34-nemo.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. This year we have talked about: \n\n* Practicing tolerating discomfort\n* Feeling into your decisions\n* Talking about your mental health\n* Making sure your expectations bring out the best in you\n* Having heroes and mentors\n* Having good-feeling talk habits\n* Visualizing success\n* Trusting your gut\n* Not expecting yourself to be happy all the time\n* Practicing being, not doing\n* Knowing how to forgive and be forgivable\n\nThese are just a few of the resilience skills we have covered this year. You were already resilient, and now you have even more skills to choose from when you come to a bump in the road. ",
      "questions": "Can you describe a way you have been resilient this year?\n\nAre there any skills from this Resilience Reminders™ program that you learned or tried that worked for you?\n\nWhat words do you use to define resilience? \n\nDo you think talking about these types of things is helpful for students and families? Why or why not?\n\nDo you believe in reaching out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company.Finding Nemo © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 1,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade8week1-bartbounce.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. \nThe other good news is you are already resilient! You have probably picked up quite a few resilience skills already in life. For example, if you…\n\n* Can name your personal strengths\n* Have good-feeling relationships\n* Reach out for support when you need it\n* Take care of yourself–physically, mentally, emotionally and spiritually\n* Learn from your losses\n* Use your breath to calm down and focus\n* Trust your gut\n\nIf you do any of these things, you are already resilient. \n\nThese Resilience Reminders™ will add more skills to your list and remind you of helpful things you already know.",
      "questions": " ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 2,
      "name": "Use Negative Emotions to Take Care of Yourself",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade8week2-selfcare.gif",
      "summary": "Resilient people know that negative emotions provide opportunities to take care of themselves, so instead of ignoring them, they listen to what they need to feel better.",
      "readAloud": "Most people will do anything to avoid feeling bad. This includes stuffing negative emotions away and trying desperately to ignore them. But the thing about negative emotions is they get bigger when we pretend they don’t exist. And then they burst through in moments when we overreact or feel overwhelmed.\n\nNegative emotions are natural responses to the inevitable disappointments, setbacks, challenges, and failures that everyone–seriously everyone–must face. And if we stop pushing against them, we can actually learn alot from our negative emotions and use them to take care of ourselves. \n\nHow do you use negative emotions to take care of yourself? You allow them to tell you what you need to feel better.\n\nAnger is often telling us we need to be treated better. Sadness is often reminding us of something we will miss and need more of in our lives. Fear is usually pointing out something we need in order to feel safe and secure.\n\nSo, the next time you notice you are upset, ask yourself, \"What do I need to feel better?\" \n\nThen, take an action step to take care of yourself.\n\nEven when you cannot change the situation, there will be action steps you can take to take care of yourself.",
      "questions": "Can you describe a time when you used negative emotions to take care of yourself?\n\nHave you ever tried to stuff down a negative emotion only to have it burst through later in a moment when you overreacted or felt overwhelmed?\n\nMany adults don’t like to deal with negative emotions from kids. Have you ever been told to keep certain emotions to yourself? If so, how did that make you feel? \n\nWould you say you are pretty good at listening to your negative emotions and understanding what you need to feel better, or do you tend to stuff your emotions down and try to ignore them, and where did you learn to do that?\n\nAnger can be a difficult emotion to manage. How do you take care of yourself when you’re feeling really angry?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. My Little Pony © Hasbro"
    },
    {
      "grade": 8,
      "week": 3,
      "name": "Know How to Agree to Disagree",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade8week3-agreetodisagree.gif",
      "summary": "Resilient people listen in a way that allows people to feel heard and understood, even when they disagree.",
      "readAloud": "A lot of times, people don’t really listen. They wait for their chance to talk. \n\nInstead of focusing on what the other person is saying, they concentrate on what they want to say while waiting until it’s their turn to speak. \n\nBut this doesn’t feel good to people, especially during a disagreement. \n\nIt is possible to listen to understand someone without “losing” the argument. \n\nHow do you do it? You focus on getting information instead of on giving information. \n\nYou listen with the goal of being able to repeat back to them what you heard them say. You say something like, “Here’s what I heard you say. Is this right?” \n\nThen, you give them a chance to explain further if you’re missing something. \n\nAfter that, you can make your own points, but at least you will understand what they are saying. \n\nWhen you listen to understand, you don’t lose. You learn. You gain not only information but also a stronger connection to another human being because you respected them enough to actually listen to what they had to say.  ",
      "questions": "How do you show someone that you are really listening to them? \n\nCan you describe a time when you disagreed with someone but listened in a way that made them feel heard and understood even though you disagreed? How did you do that?\n\nCan you describe a time when someone disagreed with you but still made you feel heard and understood? How did they do that?\n\nCan you describe a time when you disagreed with someone but listened to what they had to say and changed your mind? \n\nCan you describe a time when you disagreed with someone but they listened to you and changed their mind? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 4,
      "name": "Have a Personal Mission Statement ",
      "category": "Needs/Values",
      "subCategory": "Strengths",
      "image": "grade8week4-missionaccomplishedhomersimpson.gif",
      "summary": "Resilient people know who they are, what makes them feel good about themselves, and what gives their lives meaning. These ideas can be summed up in a personal mission statement that gives them purpose and direction",
      "readAloud": "Starting as young children, we each get to decide who we want to be, how we want to show up in the world, and what we want to do with our lives. These decisions seem small at first, like, will I play basketball or football? Will I sing or paint? Will I be kind to others or gossipy?\n\nAs we get older, the decisions have more consequences. Will I push myself to be the best student, athlete, artist I can be or will I downplay my talents? Will I reach for opportunities that interest me or will I stay in my comfort zone? Will I have the courage to be vulnerable or will I hide my true self? \n\nThinking about what matters to you, what gives your life meaning, and what sets you apart from others keeps you grounded when life throws you a curveball or disappointment. Even when life as you know it has drastically changed, you can find creative ways to fulfill your personal mission. \n\nAnswering the questions below will help you create your personal mission statement. Revisiting your personal mission statement every so often will keep you on track and allow you to capture how you’re learning about yourself, growing, and changing in your perspective and goals.",
      "questions": "What words do you hope people use when they describe the kind of person you are? \n\nWhat is one special talent or interest you have? \n\nIf you could bring positive change to your family, peer groups, school, community or even the world in some way, what would it be? \n\nWhat is one goal you have for yourself? \n\nWhat is the first thing that comes to mind when you think of what your personal mission statement could be? It doesn’t have to be grand like curing cancer or becoming president or landing on Mars, although it certainly can be. Your personal mission could be to bring laughter to people’s lives, or to be the best athlete you can be, or to try new things and not let failure slow you down. So here’s the question again: What is the first thing that comes to mind when you think of what your personal mission statement could be? (Don’t overthink it. Just say whatever pops into your head.)",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 5,
      "name": "Ask For What You Need",
      "category": "Needs/Values",
      "subCategory": "Interpersonal Skills",
      "image": "grade8week5-donkeyhugagain.gif",
      "summary": "Resilient people know that people can’t read their minds, so they speak up for themselves and ask for what they need.",
      "readAloud": "It would be great if other people could somehow know exactly what we need at any given time and give it to us. But people can’t read our minds, so they are often left guessing at what we need or thinking we don’t need anything at all. \n\n* If you don’t tell your teacher you need help, they won’t know how to help you.\n* If you don’t tell your coach you need support, they won’t know how to support you.\n* If you don’t tell your friends you need encouragement, they won’t even know that you need encouragement.\n\nWhen people tell each other what they need, relationships feel better. \n\nAnd if you find yourself in a relationship with someone who will not give you what you need, you can look for ways to take care of yourself. \n\nLearning to speak up for yourself and ask for what you need is one of the most empowering things you can do for yourself.",
      "questions": "Can you give an example of when you asked for something you needed from someone? \n\nAre you comfortable speaking up for yourself and asking for what you need? In what situations are you more and less comfortable asking for what you need? \n\nCan you think of something you need from someone that would make your relationship feel better? \n\nHow does it feel to you when someone asks you for something they need? \n\nWhat can you do if you ask someone for something you need and they won’t or can’t give it to you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Shrek © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 6,
      "name": "Listen to Messages From Your Body",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade8week6-piggydance.gif",
      "summary": "Resilient people know their bodies have important messages about safety and self-care, so they tune into and take care of their bodies.",
      "readAloud": "Safety and self-care are two ways our bodies can help us be resilient.\n\nAs human beings, we can sense danger in our bodies even before our minds are aware of it. We can also feel whether people feel safe and trustworthy or whether they feel threatening and dangerous. \n\nKids are often made to ignore those feelings and forced to hang out with people and in situations that don’t feel good to them, but the messages are there if they tune into them. Our bodies are always trying to keep us safe. \n\nOur bodies are also trying to keep us healthy. They will tell us how to take care of ourselves, if we listen. And, because our minds and bodies are connected, if we take care of our bodies, we will also feel better mentally and emotionally. \n\nChildren typically don’t have much control over what goes into their bodies, whether the food they eat is healthy or not, how much exercise they get, how much sleep they get, how much stress they feel. But they can listen to what their bodies need and take as much responsibility as possible for taking care of themselves.\n\nChildren who are not taught to pay attention to their bodies grow into adults who don’t take care of themselves, who ignore messages from their bodies, and who do not feel their best—physically, mentally or emotionally. But kids who learn to listen to the Inner Wisdom their bodies have to share have a super power that will serve them for their whole lives.",
      "questions": "When you are stressed, nervous, or upset about something, where do you feel that in your body? \n\nCan you describe a time when you could feel in your body whether someone felt like a good, safe, friendly, trustworthy person or not? \n\nWhat do you do to take care of your body? \n\nWhat is one thing you could do to take better care of yourself? \n\nLet\u0027s ask our bodies what they need right now...\nClose your eyes, if you\u0027re comfortable doing that, take a deep breath, and inside your own head, ask your body what it needs right now...\n(Give them a few seconds to tune in.)\nNow, who wants to tell us what their body said?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Sing © Universal City Studios LLC"
    },
    {
      "grade": 8,
      "week": 7,
      "name": "Use Your Imagination to Take a Stress Break",
      "category": "Mind-Body Skills",
      "subCategory": "Inner Wisdom",
      "image": "grade8week7-lionkingrelax.gif",
      "summary": "Resilient people know that giving themselves a mental picture of a safe, relaxing place can help them calm down and regroup when they are stressed, so they use their imaginations to take stress breaks.",
      "readAloud": "Close your eyes, if you’re comfortable doing that, and picture in your mind the most relaxing, calm, good-feeling place you can think of. \n\nIt can be a place you’ve actually been to, a place you’ve seen or read about, or a place you make up in your imagination. \n\nYou could put yourself on a tropical island, on top of a mountain you’ve never been to, or even on a cloud floating gently across the sky. \n\nJust picture a place in your mind that feels safe and quiet and calm and that makes you feel comfortable and relaxed. \n\nNow imagine yourself sitting or lying in this safe place, relaxed and happy to be there, and take five slow, deep breaths as you enjoy being in this place.\n\n(Mentors, give time for 5 deep breaths.) \n\nNow before you leave this place, ask yourself if there’s anything you need to know right now that would help you take care of yourself… and see if your Inner Wisdom has anything to say.\n\n(Mentors, give another breath or two.) \n\nOkay, look around one more time and take note of what you see. And remember, you can come back anytime you need a stress break just by putting yourself there in your imagination and taking a few deep breaths.\n\nNow, take one more deep, relaxing breath and come back to the room. \n\nYou just meditated. You can use your imagination and a few calming breaths anytime you need to destress, recenter, tune into your Inner Wisdom, or make a decision. ",
      "questions": "Were you able to relax with this meditation? If so, what was the place you pictured?\n\nWhat other kinds of meditation have you done, and how do they compare to this kind where you picture a safe place in your imagination? \n\nWhy do you think picturing something in your imagination helps you relax? \n\nWhat is another way you take a break when you are stressed?\n\nWhy do you think relaxing this way helps you tune into your Inner Wisdom, gut feeling, intuition or whatever you call it? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Lion King  ©  The Walk Disney Company"
    },
    {
      "grade": 8,
      "week": 8,
      "name": "Welcome Feedback ",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade8week8-feedbackanime.gif",
      "summary": "Resilient people know that becoming good at something is a process of learning, trying, and sometimes failing. And, one of the best ways to improve is to get feedback from others. Do you welcome feedback or gete defensive?",
      "readAloud": "It can be hard to hear feedback. It can feel embarrassing when a coach, teacher, parent or friend points out how you could do something better.\n \nMany people take it as criticism and get upset when others try to give them feedback. They take it to mean they have done something wrong or are not as good as they should be. Some people think feedback means they have failed.\n \nBut if you shut down when someone is trying to help you, you miss valuable information that could improve your performance… and your life. Feedback helps you in sports, in academics, in relationships, and in your career.\n \nWhen you change the story in your mind from one where feedback means you are not good enough to one where it means you are learning and improving, you put yourself on the fast track to success.\n \nSuccessful people welcome feedback. They want to know what others think of how they are doing and what they could do better.\n \nSo the next time someone offers to give you feedback, resist the urge to take it as criticism and open to the possibility that it will help you become an even better version of yourself. ",
      "questions": "What goes through your head when someone tries to give you feedback? Do you take it as criticism and feel bad about yourself or welcome the idea of learning something? \n\nAre there some people who make it easier for you to hear their feedback and others who make feedback feel bad? Why is it easier to hear from some people? \n\nWhat areas of your life are you best at hearing feedback in, and what areas are more difficult for you? (sports, academics, relationships, family, other?)  \n\nHow do you think feedback can help you in relationships? \n\nAre you good at giving feedback yourself? What can you do to make it so people take your feedback constructively and not as criticism?  ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. "
    },
    {
      "grade": 8,
      "week": 9,
      "name": "Don\u0027t Let Failure Define You, Use it to Grow",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade8week9-muchtolearnyoda.gif",
      "summary": "Resilient people know that failing is a part of life, so they learn from their failures and turn them into stepping stones to success. ",
      "readAloud": "Failing is a part of life—at least for those who are willing to get in the game, push themselves to try new things, and learn from their losses. \n\nIt can be embarrassing to fail. It can make you mad and sad, and it can make you want to give up. But successful people know that failure is a part of the learning process, so they shake off their failures and take the lessons from them so they’ll do better next time.\n\nAlbert Einstein failed the entrance exam to university the first time and almost failed out of university once he did get in, but that didn’t stop him from pursuing his passion for math and physics and eventually winning the Nobel Prize.\n\nOprah Winfrey was fired from her first television job and told she was “unfit for tv,” but that didn’t stop her from becoming one of the most successful television stars ever.\n\nThomas Edison failed over 10,000 times before he created a light bulb he could sell, but he said, “I have not failed 10,000 times…  I have succeeded in proving that those 10,000 ways will not work. When I have eliminated the ways that will not work, I will find the way that will work.”\n\nFailure is only failure if you fail to learn from it and stop trying. So, the next time you fail, ask yourself what you have learned.",
      "questions": "Can you describe a time when you failed at something and learned from it?\n\nCan you think of something positive in your life that could not have happened if you hadn’t failed at something that led up to it?\n\nIs there someone you admire who failed at something and then went on to succeed?\n\nWhat does it mean about you if you fail?\n\nWhat is the biggest risk you ever took, and did you succeed or fail? If you failed, what story do you tell about that failure?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 10,
      "name": "Check in With Trusted Advisors When Making Decisions",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade8week10-giphy.gif",
      "summary": "Resilient people know that they don’t know what they don’t know, so they check in with trusted advisors when making decisions.",
      "readAloud": "Being a good decision maker is one of the most important skills you can develop.\n\nThe problem is when you’re a kid you are surrounded by people who have opinions about your life–both adults and peers–but not everyone has your best interest at heart. \n\nMany of them don’t even know what your personal values and goals are, so how could they possibly know what’s best for you?\n\nOn top of all that, when you are a kid there are a lot of things you just don’t know… yet. This is not an insult. It just means you haven’t learned or experienced certain things yet, so you might not have all the information you need to make a good decision.\n\nThe truth is… we don’t know what we don’t know. \n\nThe greatest leaders understand this, so they surround themselves with trusted advisors. They also stay open to new information and are willing to change their minds if new information leads to that.\n\nThe best way to become a good decision maker is to have at least one person you can talk to about your values and goals and whose opinion you trust. Then, you can check in with them when you have a decision to make, and they can help you make sure the decision aligns with your values and goals, point out what you don’t know, and give you any additional information you need. ",
      "questions": "Do you have a trusted advisor (or more than one) in your life? (Someone you can talk to about anything and whose opinion you trust?)\n\nHow would you go about finding a trusted advisor? Where would you look? Who might you ask?\n\nCan you name one goal you have for yourself?\n\nCan you name one of your values–something that matters to you and that helps you make decisions? (It could be something like doing well in school, being a good athlete, being a kind person, or challenging yourself.)\n\nCan you describe a time when you didn’t know what you didn’t know but someone gave you information that helped you make a good decision?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 8,
      "week": 11,
      "name": "Know That Thoughts and Emotions Match",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade8week11-sadnessinsideoutimouttapizza.gif",
      "summary": "Resilient people know their thoughts and emotions always match, so they don’t make important decisions when they’re overwhelmed with negative emotions.",
      "readAloud": "Our thoughts and emotions always match. If we are overwhelmed with negative emotions, we will have overwhelmingly negative thoughts. You simply can’t think positive thoughts when you’re feeling negative emotions. \n\nIn fact, when you are flooded with negative emotions, your thoughts tend to spiral in the negative direction making everything you think about seem worse.\n\nThe best thing to do to stop the spiral is to stop thinking. Distract yourself with something that will make you feel better emotionally, like going for a walk, listening to a great song, or hanging with a caring friend.\n\nDistraction gets a bad rap, but it’s actually an important self-care tool when it’s used to help you shift to a better-feeling emotion so you can then have access to better-feeling thoughts. In other words, you don’t want to distract yourself from all negative emotions. But you do want to be able to distract yourself from a negative thought spiral until you can feel good enough to think constructively about the situation.\n\nEven when we cannot change a situation, we can always do something to feel a little better, which will allow us to think better-feeling thoughts and find solutions. That’s why, knowing what works for you when you want to shift out of a negative emotion is an important resilience skill. ",
      "questions": "What is something that works for you when you want to shift out of a negative emotion?\n\nFor many people, doing something physical like going for a run or getting a hug from someone who loves you is a good way to feel better. Is there something physical that works for you when you want to get out of a bad mood?\n\nDoing something you’re good at, like drawing, singing, or shooting hoops, is another good way to shift your emotions. Do you have a strength you can use to feel better?\n\nDoing something kind for others is another way to feel better. Have you ever experienced that?\n\nHow can you tell if you’re using distraction too much?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Inside Out © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 12,
      "name": "Practice Gratitude",
      "category": "Emotional Intelligence",
      "subCategory": " Interpersonal Skills",
      "image": "grade8week12-pandagratitude.gif",
      "summary": "Resilient people know that gratitude improves not only your mental health but also your physical health, so they make it a practice to be grateful.",
      "readAloud": "It makes sense that gratitude is good for your mental health, but studies show that gratitude improves our physical health too! Gratitude also improves relationships.\n\nThanksgiving is all about gratitude. But we can forget to look for things to be grateful for during the rest of the year. \n\nIt’s easy to get so caught up in the stresses of daily life that we focus on what’s going wrong in our lives instead of looking for what’s good.   \n\nThere are always things to be grateful for in our lives. We just have to make an effort to notice them. \n\nThis is why some people practice gratitude the same way they practice a sport. They set aside time every day or every week to think about what they are grateful for. Some people do it every morning. Others do it when they go to bed. Others do it when they attend spiritual services.\n\nHowever you do it, the more you practice gratitude, the better you will feel - and the healthier you will be.",
      "questions": "Who is a person you are grateful for?\n\nWhat is an experience you are grateful for?\n\nWhat is a quality about yourself you are grateful for?\n\nCan you think of anything you are grateful for that came from something that was not so great?\n\nHow can you remember to practice gratitude more often in your daily life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Kung Fu Panda © DREAMWORKS ANIMATION L.L.C."
    },
    {
      "grade": 8,
      "week": 13,
      "name": "Choose Your Friends Wisely #2",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade8week13-minionsfriends.gif",
      "summary": "Resilient people know that the people you hang out with shape your life, so they choose friends who inspire them, lift them up, and bring out the best in them.",
      "readAloud": "Our friendships set the tone for our lives. They shape how we think and feel about ourselves and the world. If we hang out with people who push themselves to be their best and reach for their dreams, we will likely do the same. If we hang out with people who find excuses to waste their potential and wallow in negativity, we will likely do that.  \n\nHere’s a formula for choosing your “FAVIRite” people: (We know it’s not spelled correctly, but it’s close enough to help you remember it.)\n\n* Feelings – Find people who feel good to be around. You can feel in your body, in your gut, whether someone feels kind and supportive or if they feel untrustworthy and unsafe.\n* Actions – Notice how you act around different people and choose friends with whom you behave in ways that make you feel good about yourself.\n* Values – Find people who share your values. Think about what matters to you and what kind of person you want to be and surround yourself with people who care about the same things.\n* Interests – Find people who share your interests. This will give you endless opportunities to have fun and make memories together.\n* Roles – Appreciate the unique role each friend has in your life. Some friends make us laugh. Some are good listeners. Some lift us up when we’re feeling down. \n\nWhen we choose our friends wisely and appreciate the specific ways each one adds value to our lives we maximize the gift of friendship.",
      "questions": "Do you have some friends who FEEL good to be with, who are easy to be around, and with whom you can just be yourself and others who make you feel more guarded or on edge? \n\nCan you describe ways in which you ACT differently with some friends than you do with others? \n\nWhat VALUES do you look for in your friends? (kindness, being a good athlete, doing well in school, having a sense of humor)\n\nWhat INTERESTS do you share with your friends? Do you enjoy different interests with different friends or do all your friends share the same interests? \n\nCan you describe how different friends play different ROLES in your life? Do some make you laugh while others inspire you to be a better athlete? Do some encourage you to work hard in school while others make you feel better when you are upset? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Minions © Universal City Studios LLC"
    },
    {
      "grade": 8,
      "week": 14,
      "name": "Live Up to Your Potential (Not Someone Else\u0027s)",
      "category": "Strengths",
      "subCategory": "Needs/Values",
      "image": "grade8week14-homeryou\u0027rebetterthanthat.gif",
      "summary": "Resilient people know they have something unique and valuable to offer the world, and they pursue their personal strengths, interests and values to live up to their potential.",
      "readAloud": "Everyone has the potential for greatness.\n\nThe problem is, people often spend more time comparing themselves to others and trying to be something they’re not than they do developing the things they are naturally good at and that they are actually interested in. \n\nInstead of deciding for themselves what kind of greatness they want to be, they spend their time trying to measure up to someone else’s standards of greatness. \n\nHowever, when you realize that the things that make you unique, that you enjoy, that you are good at, and that you genuinely care about are the very things that will propel you to your own, special kind of greatness, you can stop looking to others for validation and start living up to your own potential.\n\nHere’s a shortcut: STRENGTHS, INTERESTS, VALUES. Know what yours are, spend as much time as possible pursuing them, and you will feel and be your best. You will also live up to your potential, not someone else\u0027s. ",
      "questions": "What are your personal STRENGTHS? (What are you good at? What comes easy to you?) \n\nWhat are your personal INTERESTS? (What do you look forward to doing? What do you want to do more often?) \n\nWhat are your personal VALUES? (What kind of person do you want to be? What kind of life do you want to have?) \n\nHow do you define GREATNESS for yourself? (What will you have to do to achieve your kind of greatness?) \n\nWhat are some of the reasons you think people don’t live up to their potential?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 15,
      "name": "Have Beliefs That Comfort You, Guide You, and Give Your Life Meaning",
      "category": "Spirit",
      "subCategory": "Beliefs",
      "image": "grade8week15-poohbeliefs.gif",
      "summary": "Resilient people know that our beliefs shape our lives, so they have beliefs that comfort them, guide them, and give their lives meaning.",
      "readAloud": "This time of year reminds us that spiritual beliefs are a powerful source of comfort and guidance for many people. Some beliefs give meaning to our lives. Some beliefs inspire us to be kind to each other. Some beliefs comfort us around our fears, especially around our fear of death. And many beliefs connect us to supportive communities and nurture a sense of belonging.\n \nRegardless of what you believe, choosing to live your life in alignment with beliefs that give you purpose, connect you to the most loving parts of yourself, and nurture optimism for your future is a choice worth making.\n \nMany people don’t realize that they can choose what to believe. They think that because they already believe something, it must be true. But people believe many different things, some of which are contradictory. Yet somehow, people have evidence for contradictory beliefs. \n\nSo, if you realize you have a belief that does not make you feel very good, look for evidence of something better. If you look hard enough, you will probably find it.\n \nWhatever you believe, we hope it brings you peace, joy and love this holiday season. ",
      "questions": "Do you have a belief that inspires you to be the best person you can be?\n\nDo you have a belief that motivates you to be kind to others?\n\nDo you have a belief that comforts you around any of your fears?\n\nDo you have a belief that gives your life meaning or a sense of purpose?\n\nDo you have a belief that makes you feel optimistic about your future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Winnie the Pooh © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 16,
      "name": "Set Achievable Goals (Around Strengths and Values)",
      "category": "Strengths",
      "subCategory": "Needs/Values",
      "image": "grade8week16-snoopygoals.gif",
      "summary": "Resilient people believe goals should inspire them and make them feel good about themselves, so they set achievable goals around their strengths and values.",
      "readAloud": "New Year’s Resolutions often start out as inspiring but end up making us feel disappointed in ourselves if we fail to achieve them. Here are some tips for setting goals that will make you feel good about yourself:\n\nSet goals that are small enough that you can actually get them done. If you set goals that are too big, you’ll give up, feel bad about yourself, and be less likely to set future goals for yourself. On the other hand, if you set goals you can actually achieve, you will build positive momentum that allows you to achieve goal after goal after goal.\n\nSet goals around your strengths. The more time you spend using your strengths, the better you feel about yourself. And, if you set goals around things you actually enjoy doing and that come naturally to you, you are more likely to achieve your goals, which also makes you feel good about yourself. It’s a double-whammy of feeling good about yourself! \n\nSet goals around your values. Doing things that demonstrate your personal values also makes you feel good about yourself. If you value being a good athlete, set goals around your fitness. If you value being a good student, set goals around your academics. If you value being kind, set goals around making people feel good.\n\nFind people to support you in achieving your goals. Having people to support you and hold you accountable will make you much more likely to achieve your goals.",
      "questions": "What is one goal you have for yourself right now, and does it feel achievable? \n\nCan you think of a goal to set around one of your strengths or values? \n\nCan you think of someone who could support you in achieving one of your goals? \n\nWhat is a goal you successfully achieved in the past, and what factors contributed to you achieving them? \n\nIf you think about goals you did not achieve in the past, what factors prevented you from achieving them? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Snoopy © Peanuts Worldwide"
    },
    {
      "grade": 8,
      "week": 17,
      "name": "Use Your Strengths to Get Out of a Bad Mood",
      "category": "Strengths",
      "subCategory": "Emotional Intelligence",
      "image": "grade8week17-cutehappyfeet.gif",
      "summary": "Resilient people know it feels good to do something you’re good at, so they use their strengths to get themselves out of a bad mood.",
      "readAloud": "Everyone has a bad day sometimes. And sometimes it just feels like you wake up on the wrong side of the bed for no reason and can’t get out of a bad mood.\n \nOne thing to know about bad moods is that you can’t get out of them until you’re ready. Sometimes you just need to wallow in a bad mood for a bit, and that’s ok. We have to give our bad-feeling emotions enough space to get their message across. We have to listen to what they are telling us, so we can know how to take care of ourselves.\n \nWe have to ask ourselves, “What am I upset about? And what do I need to feel better?”\n \nWhen we do that, we might realize that we’re angry because someone was rude to us, and we need to tell that person they hurt our feelings, or we need to tell someone else about what happened, or we need to not spend time with that person until they apologize. Once we are clear about why we’re upset and what we need to feel better, we can do something to get in a better mood.\n \nOne way to feel better is to do something we are good at, because that will automatically feel good. If you’re good at painting, it will feel good to paint. If you’re good at playing the piano, it will shift you into a better mood to play a favorite song. If you’re good at singing, dancing, drawing, shooting hoops, or cooking, doing those activities will make you feel better.\n \nSo the next time you are ready to shift out of a bad mood, spend some time using one of your strengths and feel how good it feels to be good at something.",
      "questions": "Have you ever noticed that sometimes you want to stay in your bad mood? Why do you think that is?\n\nWhat do you do when you are ready to feel better? What works for you?\n\nWhat is one of your strengths that you could use to feel better because you enjoy doing it?\n\nWhat do you consider your top three strengths?\n\nIs there anything you’re really good at that you don’t enjoy that much so it wouldn’t help you get in a better mood?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page ",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Happy Feet © Warner Bros. Entertainment, Inc."
    },
    {
      "grade": 8,
      "week": 18,
      "name": "Use Your Words Wisely",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade8week18-starwarswhatareyoutalkingabout.gif",
      "summary": "Resilient people know that words have power, so they use their words wisely.",
      "readAloud": "Words have power. \n\nWords have the power to make us feel good or bad about ourselves.\n\nWords have the power to make others feel disrespected or supported by us.\n\nWords have the power to make us feel anxious or hopeful about the future. \n\nIn other words, the words we hear and the words we use literally shape our lives. \n\nWhile we cannot always control the words we hear, we can control the words we use. \n\nWhen we decide to use our words for good, our lives move in a positive direction. \n\nWhen we talk about ourselves with kindness and understanding, we feel better.\n\nWhen we talk about others with love and respect, they feel better, and we feel better too.\n\nWhen we talk about our futures with optimism, we feel better. \n\nSo, use your words wisely, and notice how much better you feel.",
      "questions": "What is something positive you can say about yourself? \n\nCan you give an example of how you used words to make someone else feel better? \n\nTeachers and coaches have to choose their words wisely so they inspire you to try harder instead of making you feel like you are not good enough. Can you give an example of something a teacher or coach said that inspired? \n\nDo you have anyone in your life who is way more negative than positive, and if so, how does it feel to be around them? \n\nCan you give an example of something you say that makes you feel optimistic about your future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 19,
      "name": "Use Your Body to Feel Into Decisions",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade8week19-areyoufeelingit.gif",
      "summary": "Resilient people know they can feel in their bodies when something feels like a good decision or a bad decision, so they feel into their decisions.",
      "readAloud": "When you are a kid, lots of people have opinions about who you should be, how you should behave, and what you should do with your life. With so many people telling you what to do, it can be hard to know how to make decisions for yourself.\n\nThe good news is we all have a powerful decision-making tool with us all the time: our bodies. Our bodies will tell us if a person feels safe, if an activity will be good for us, and if a decision is what we really want to do, or if we are trying to please someone else.\n\nIf no one tells you how important your gut feelings are, you might not pay attention to them. But if you feel into your body, the information is there.\n\nOur thinking minds can get sidetracked by insecurities, limiting beliefs (like “I’m not good enough”) and other people’s desires for us. But our Inner Wisdom will speak to us through our bodies if we tune in and listen.\n\nSo the next time you have a decision to make, listen to what your body has to tell you.",
      "questions": "How does it feel in your body when you make a decision you are excited about?\n\nHow do you make decisions? Do you listen to your body? Do you think it through? Do you talk to other people? Do you do all of the above?\n\nMany people call the feeling in your body your “gut feeling.” What do you call it?\n\nCan you describe a time when you could feel in your body whether something was a good decision or not?\n\nDo you have any decisions to make in your life now, and if so, what is your body telling you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 8,
      "week": 20,
      "name": "Practice Box Breathing When You’re Feeling Anxious",
      "category": "Mind-Body Skills",
      "subCategory": "Emotional Intelligence",
      "image": "grade8week20-boxbreathe.gif",
      "summary": "Resilient people know that everyone has moments of anxiety, so they have different ways of using their breath to calm down when they need to. Box Breathing is one of those ways.",
      "readAloud": "People talk a lot about anxiety these days, and it is true that more young people are seeking help for high levels of anxiety than ever before. Seeking professional help when your anxiety feels overwhelming is a courageous thing to do, and mental health professionals have many tools and techniques for helping people cope with anxiety and other intense emotions.\n\nThat being said, some anxiety is a part of life. Everyone has moments of feeling anxious and even overwhelmed, so it’s important to have tools for calming down so you can think straight and figure out how to take care of yourself.\n\nThat’s the thing about anxiety–it makes it difficult to think constructively. Whenever we feel intense negative emotions, it becomes difficult to think well. This is because when we feel threatened the “fight or flight” response narrows our thinking to just a few options: “Fight!” and “Run!” being two of the big ones.\n\nHowever, most of the daily situations that trigger anxiety do not require fighting or running. They do require thinking. So, we need to be able to calm down in order to think about what we need (to feel better) and what we can do to take care of ourselves.\n\nBox breathing, which is a mind-body skill (focusing on your breath to relax your mind and body), can help. Let’s try it now.\n\nFirst, choose a number that you will count to as you breathe in and out and hold your breath between each inhale and exhale. We’ll use the number 4 right now, and we’ll practice Box Breathing for four rounds.\n\n1.\tBreathe IN for the count of four.\n2.\tHold for the count of four.\n3.\tBreathe OUT for the count of four.\n4.\tHold for the count of four.\n\n(Mentors, do that four times.)\n\nSo, that’s Box Breathing. Next time you feel anxious, give it a try and notice how it settles you down, stops a negative thought spiral, and makes you better able to find solutions to whatever is upsetting you.",
      "questions": "What was your experience with Box Breathing like, and do you think it could be helpful for you?\n\nHave you ever used Box Breathing or any other kind of breathing technique to calm down, and do you find it helpful?\n\nHave you ever noticed that you can’t think very well when you’re upset about something?\n\nWhat do you think is the difference between the anxiety that is a part of daily life and anxiety that requires professional help? (If you have a level of anxiety you are unsure about, ask an adult–the school counselor, an advisor, or a teacher.)\n\nWho knows how to find access to mental health resources? (There is a link to mental health resources at the bottom of each Resilience Reminders™lesson.)",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 8,
      "week": 21,
      "name": "Stop Trying to Control Everything and Be Open to What Life is Offering You",
      "category": "Spirit",
      "subCategory": "Beliefs",
      "image": "grade8week21-bartsurprise.gif",
      "summary": "Resilient people know that much of life is out of their control, so they stay open to what life is offering and expect pleasant surprises.",
      "readAloud": "One of the most frustrating aspects of life for many people is that so much of it is out of our control. We want to believe that if we just work hard enough, do the right things, be a good person, and try, try, try, we will get what we want. \n\nBut life doesn’t always work that way. In fact, sometimes it can feel like life is totally out of control no matter how hard we’re trying to manage things.\n\nIt’s natural to feel disappointed when we don’t get what we want.\n\nBut, if we remind ourselves that sometimes our lives take unexpected turns that end up being for the better, we can open to the possibility that not getting everything we think we want might actually be okay. \n\nIn fact, many of the best things in life just happen to us without us making them happen. \n* A new family moves in next door, and the daughter becomes your best friend.\n* A teacher recommends you for a scholarship you didn’t even know about, and that allows you to go to the college of your dreams.\n* You get cut from the soccer team, so you decide to run track, and the team goes on to win the state championship.\n\nWe all have examples of life taking us places we didn’t choose but that turned out to be full of blessings.\n\nSo, the next time you feel disappointed that life isn’t going as you planned, ask yourself: “What if this is actually better?”\n\nAnd notice how this powerful question opens you up to being pleasantly surprised.",
      "questions": "Can you think of a time you were pleasantly surprised?\n\nCan you think of something great that happened in your life that you didn’t make happen - it just happened?\n\nCan you think of a time when you didn’t get what you thought you wanted but you ended up being glad it turned out that way?\n\nDo you consider yourself open and flexible, or do you really like to control things?\n\nThink of the best things about your life… how many of them to did YOU make happen?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 22,
      "name": "Choose Love",
      "category": "Emotional Intelligence",
      "subCategory": "Interpersonal Skills",
      "image": "grade8week22-heartpump.gif",
      "summary": "Resilient people know that love is the best-feeling emotion and most positive force in the universe, so they choose love as often as they can.",
      "readAloud": "It is not always easy to see the best in people or forgive their wrongdoings. But holding grudges feels bad, and giving love feels good. So give love, to others and to yourself.\n\nAnd when you are unsure of what to do, choose love. ",
      "questions": "Can you describe a time when you decided to choose love instead of staying angry at someone? How did that work out? \n\nCan you describe a time when you decided to harbor a grudge against someone? How did that work out? \n\nCan you describe a time when someone surprised you by forgiving you for something when you thought they would stay angry? How did that make you feel? \n\nCan you describe a time when you started to hold a grudge against yourself, but then instead of staying angry at yourself, you decided to choose love for yourself? \n\nDo you agree that love is the most powerful force in the universe? If so, why? If not, what is?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 8,
      "week": 23,
      "name": "Take Care of Yourself",
      "category": "Needs/Values",
      "subCategory": "Spirit",
      "image": "grade8week23-poohexercise.gif",
      "summary": "Resilient people take care of themselves - physically, mentally, emotionally, and spiritually - especially when they are going through a difficult time, when they are feeling stressed, or when they want to perform well.",
      "readAloud": "By the time people get to be adults they often have a million reasons why they don’t take better care of themselves—they’re too busy, there’s not enough time, there are too many other people they have to take care of—so their self-care gets put on the back burner.\n\nAnd when you are a kid, you have to rely on others to take care of you. Even if you want to be thoughtful about your self-care, many aspects of that—from the food you eat, to the activities you engage in, to the people you are surrounded by—are in large part controlled by your parents.\n\nHowever, taking responsibility for taking care of yourself is something best done early in life, so you can build lifelong habits that support your mental, physical, emotional, and spiritual wellbeing.\n \nWhen people think about self-care, they often think about nurturing their bodies with healthy food, exercise and plenty of water and sleep. It’s also important, though, to take care of your mind and spirit.\n \nThere are lots of things you can do to support your mental and emotional health, including surrounding yourself with good-feeling people, having people you can talk to when you are upset, not keeping your thoughts and emotions bottled up, making time for people and activities you enjoy, not being too critical of yourself, and seeking out things that make you laugh.\n \nAnd spiritual health can be supported by engaging in calming activities like meditation and journaling that help you soothe yourself and collect your thoughts as well as anything that nourishes your spirit by giving your life meaning and a sense of purpose.\n \nBottom line: take responsibility for taking care of your own mind, body and spirit, and you will show up as the best version of yourself every day.",
      "questions": "How do you take care of your body?\n\nHow do you take care of your mind?\n\nHow do you take care of yourself emotionally when you are feeling upset about something?\n\nWhy do you think it helps people feel better emotionally to talk about their problems with a supportive person instead of keeping it bottled up inside of them?\n\nHow do you take care of your spirit?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Winnie the Pooh © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 24,
      "name": "Have a Vision for Your Future",
      "category": "Beliefs",
      "subCategory": "Strengths",
      "image": "grade8week24-yodafuture.gif",
      "summary": "Resilient people have hopes and dreams for themselves, and they identify the steps required to make those dreams come true. ",
      "readAloud": "Our education system doesn’t always do the best job of helping kids connect the dots between the dreams they have for themselves and the things they need to accomplish in order to make those dreams happen.\n \nFew kids enjoy doing homework and studying for tests, but the ones who want to be scientists, astronauts, or doctors study hard because they know they have to get good grades in order to have a chance of making their dreams come true.\n \nFew kids enjoy getting up before the sunrise and running, lifting, or playing a sport until their muscles give out, but the ones who want to be competitive athletes push themselves to their limits because they know their chances of success depend on it.\n \nEven if you don’t know exactly what you want to be when you grow up, when you identify what you want for yourself in the future you find reasons to be your best self today.\n \nIf you want to have a nice lifestyle, you probably want to make the most of your education to get a good job.\n \nIf you want to have lots of friends and loved ones, you probably want to do your part to have good relationships with people.\n \nIt’s okay if you don’t have a clear vision for your future yet, but thinking about it will keep you motivated to be your best self on the path to wherever it is you want to go.",
      "questions": "How far into the future does your vision for yourself go? Can you name one goal you have for yourself and one step on the path to achieving it?\n\nWhat is one of your personal strengths that you might want to consider when thinking about your future?\n\nWhat is the most important thing you can do right now to give yourself the best opportunities to have the future of your dreams?\n\nWhere do you work hard in life—in academics, athletics, hobbies, relationships—and what motivates you to work hard?\n\nWhat do you think happens to people when they don’t have a vision for their future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page ",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 25,
      "name": "Know That Thoughts Cause Emotions",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade8week25-Icandothis.gif",
      "summary": "Resilient people know that thoughts cause emotions, so they identify their thoughts and then try to find slightly more positive ones to feel better.",
      "readAloud": "One of the greatest concepts of resilience is this: It’s not the situation that causes you to suffer. It’s your thoughts about the situation that cause you to suffer.\n\nFor example, if you miss your final free throw shot and cause your team to lose the game, you probably think you’re upset because you missed the shot, but the real cause of your suffering is the thought-track running through your head: “I always choke under pressure. My teammates hate me now. My coach will never play me again.”\n\nOn the other hand, it is possible for someone to miss the shot and have a more positive thought-track running through their head: “I let my nerves get to me. It happens to everyone. I’ll make it next time.”\n\nThoughts, not situations, cause emotions.\n\nThis is great news, because we cannot always control situations, but we can almost always choose a slightly better-feeling way to think about them.\n\nJust like you can train your body to be physically fit, you can train your mind to focus on thoughts that feel better. \n\nRemember, though, that negative emotions are not \"bad,\" and we can\u0027t expect ourselves to think positive thoughts all the time. It\u0027s a good skill to practice, but give yourself permission to stay in a bad mood sometimes too. You get to decide when you\u0027re ready to feel better, and when you are... choose a slightly better-feeling thought.",
      "questions": "Can you think of a time when you made yourself feel better by choosing to think about the situation more positively? \n\nIs there one particular thought that always makes you feel better - something like, \"It will work out,\" or \"I can figure this out\"? \n\nThink back to the last time you were upset about something. What was the thought going through your head, and can you think of a slightly better-feeling one now? \n\nDo you agree that we can’t think positive thoughts all the time and it’s okay to stay in a bad mood sometimes?\n\nWhat emotion are you feeling now, and what is the thought causing it? The best way to answer this question is to complete this sentence: “I feel (name your emotion), because…” Whatever follows \"because\" is the thought causing your emotion.",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Playmobil: The Movie © On Animation Studios"
    },
    {
      "grade": 8,
      "week": 26,
      "name": "Have Practices and Rituals That Nourish Your Spirit",
      "category": "Spirit",
      "subCategory": "Mind-Body Skills",
      "image": "grade8week26-patrickmeditating.gif",
      "summary": "Resilient people take care of themselves by doing things that add meaning to their lives and connect them to something greater than themselves.",
      "readAloud": "It’s easy to get so wrapped up in our own daily struggles that we forget to look up. We forget to notice the awesomeness right in front of us—of nature, of space, of the human mind and body.\n\nBut connecting ourselves to these awe-inspiring things can nourish our spirits and give meaning to our lives. And finding ways to soothe our souls in a world that feels chaotic and out of control is really important. \n\nGoing to a religious service on Sunday is a weekly ritual that comforts and connects many people—to God and to a supportive community. Saying prayers at bedtime is a daily practice that nourishes the spirit. \n\nBut rituals and practices do not have to be religious. Practices like meditation and keeping a gratitude journal, and rituals like family dinners and spending time in nature can feed our souls, give meaning to our lives, and connect us to something greater than ourselves. \n\nSo, make time for uplifting rituals and practices and daily life will feel a lot more inspiring and meaningful.",
      "questions": "Some rituals are yearly, like celebrating religious holidays and birthdays. What rituals do you do every year and how do they make you feel?\n\nMany people have weekly rituals like going to religious services or having dinner with loved ones. What rituals do you do weekly and how do they make you feel?\n\nSome people have daily practices that calm and comfort them like meditating, journaling, or saying prayers. What practices do you try to do every day and how do they make you feel?\n\nWhat other rituals and practices give meaning to your life?\n\nWhat other rituals and practices connect you to something greater than yourself?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 8,
      "week": 27,
      "name": "Don\u0027t Judge the Moment. Stay Hopeful.",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade8week27-homersimpsonhappysad.gif",
      "summary": "Resilient people know that events that seem bad in the moment can pave the way for good things in the future, so instead of judging the moment, they stay hopeful.",
      "readAloud": "The Parable of the Chinese Farmer\n\nOnce upon a time there was a Chinese farmer whose horse ran away. That evening, his neighbors came and said, “How unlucky for you! This is most unfortunate!” The farmer replied, “Maybe, yes. Maybe, no.”\n\nThe next day the horse came back bringing seven horses with it. In the evening, the neighbors came back and said, “Isn’t that lucky! What a great turn of events!” The farmer said again, “Maybe, yes. Maybe, no.” \n\nThe following day his son tried to ride one of the horses, but was thrown off and broke his leg. The neighbors said, “Oh dear, that’s too bad!” The farmer responded, “Maybe, yes. Maybe, no.”\n\nThe next day military officers came to draft people into the army, and they rejected his son because he had a broken leg. Again, the neighbors said, “Isn’t that wonderful!” And the farmer said, “Maybe, yes. Maybe, no.”\n-------------------------------------------------------\nIf you look back over your life, you will be able to identify things you consider “good” that could not have happened without something you consider “bad” happening before. Realizing this can help you choose to stay hopeful even when things in the moment are not feeling great.",
      "questions": "Can you think of something “good” in your life that could not have happened if a “bad” thing hadn’t happened before it? \n\nCan you think of something you first thought was \"bad\" but later you realized it was actually \"good\"? \n\nHow about the opposite? Can you think of something you first thought was \"good\" but turned out to be not so good? \n\nWhat can you do to turn something that seems “bad” into something “good”? \n\nWhy do you think not judging the moment helps you be resilient? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 28,
      "name": "Have a Changeable Mind",
      "category": "Beliefs",
      "subCategory": "Interpersonal Skills",
      "image": "grade8week28-evenwhenimwrong.gif",
      "summary": "Resilient people know that they don’t know everything and they’re not always right, so they take in new information and are open to changing their minds.",
      "readAloud": "No one is always right. There is never just one way to look at a situation. People who are unwilling to consider other people’s points of view are not fun to talk to. They are not even fun to be around.\n \nLife is a process of learning and growing, taking in new information, and adapting to changing situations. Being able to think through things and be flexible allows you to find creative solutions and have better-feeling relationships.\n \nWe have all experienced how bad it feels to have an argument with someone who just has to be right and doesn’t even try to understand our side of things. And we’ve all experienced how great it feels when someone really listens to what we have to say and allows us to change their mind a little.\n \nThis is how we grow as individuals and how we strengthen our relationships.\n \nBeing willing to change your mind is a sign of intelligence and curiosity and empathy. So the next time you have the opportunity to defend your point of view, do it. But also, allow the other person to do the same and do your best to understand what they are saying. It doesn’t have to be a “win/lose” situation.",
      "questions": "Can you describe a time when you listened to someone else’s perspective and changed your mind about something?\n\nCan you describe a time when you changed your mind in an argument and actually apologized for something?\n\nPolitics, religion, and certain lifestyle choices are topics people can be inflexible about. What other topics do people have a hard time changing their minds about or even talking about?\n\nDo you agree that it is a good thing to be willing to change your mind? Why or why not?\n\nWhat can someone do in a discussion that makes you more likely to hear their perspective?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Meet The Robinsons © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 29,
      "name": "Trust Your Instincts",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade8week29-somethingfeelsoff.gif",
      "summary": "Resilient people trust themselves to make good decisions by tuning into their instincts or gut feelings. When we pay attention we can feel when something is off.",
      "readAloud": "Everyone has an opinion. Our parents, teachers, coaches, siblings, friends, and people we see on social media and television all have opinions about who we should be and how we should live. If we aren’t careful, we can follow someone else’s advice, which might work for them but isn’t good for us.\n\nIf we are always looking outside of ourselves for guidance, we can get lost.\n\nThe good news is, we have a source of guidance inside of ourselves, and all we have to do is tune into it.\n\nWe can feel in our bodies whether a decision is good for us or not. We can feel in our guts when a person is trustworthy or not. We can feel when something is off.\n\nSuccessful people don’t ignore those inner feelings. They trust themselves, because they learned early on to tune into their own inner guidance and feel into decisions. They ask themselves, “Does this feel right to me, or does it not? Then, they sit with the question and listen inside themselves.\n\nIf you try it a few times, you’ll see for yourself how powerful your inner guidance system can be. It’s always there for you. You just have to tune into it.",
      "questions": "Can you give an example of a time you trusted your instincts?\n\nDo you agree that it’s important to be able to trust yourself to make good decisions instead of looking to other people for guidance? Why, or why not?\n\nHave you ever ignored a “red flag” or gut feeling that you shouldn’t do something, and if so, how did that turn out?\n\nWhy do you think trusting our gut feelings, intuition, Inner Wisdom or whatever you call it works? Where does it get its information?\n\nDoes anyone in your life talk about the importance of trusting your gut? What words do they use?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Trek: Lower Decks © Paramount Global "
    },
    {
      "grade": 8,
      "week": 30,
      "name": "Be Quiet (Sometimes)",
      "category": "Mind-Body Skills",
      "subCategory": "Inner Wisdom",
      "image": "grade8week30-monstersincshhhh.gif",
      "summary": "Resilient people know there is a source of peace and wisdom inside of us all, and the way to get in touch with it is to be quiet.",
      "readAloud": "How often do you ever just sit and do nothing? Really nothing—no screens, no music, no conversation. \n\nIf you are like most people, you are so busy racing through each day trying to get everything done, you barely have enough time to sleep—much less to “waste” precious time doing nothing. \n\nBut taking time to be quiet is doing something—something very important. It\u0027s allowing our minds and bodies to calm AND tuning in to the guidance available from our own Inner Wisdom and from whatever spiritual sources of wisdom we believe in.\n\nWhat could be more important than that?\n\nIn fact, let\u0027s take 2 minutes right now, and try it together: \n\n1. First, close your eyes, if that feels good. You don’t have to.\n\n2. Now take three slow, deep breaths and focus on relaxing your body while you breathe. (Mentors, allow time for 3 breaths.)\n\n3. Take three more slow, deep breaths and notice how it feels in your mind and in your body to be quiet and breathe and relax. (Mentors, allow time for 3 breaths.)\n\n4. Now, keep breathing and relaxing and think of a challenge you have in your life or a decision you need to make, and ask yourself, “What do I need to know about this?”\n\n5. Then, listen inside for a few breaths and see if an answer comes to you. (Mentors, allow time for 3 breaths.)\n\n6. Finally, take 3 more deep, relaxing breaths, and when you are ready, come back to the room—knowing you can always come back to this peaceful place with just a few breaths.",
      "questions": "How did it feel to do that? What was going on in your mind and body? \n\nDid you get an answer to your question? You don’t have to share the answer, just share whether or not you got one. \n\nDo you ever sit and do nothing, or do you get out a screen or do something else to entertain yourself? \n\nDo you ever do any meditation? If so, what do you do? \n\nIf you are a spiritual person, how do you tune in to guidance from whatever higher power you believe in?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Monsters Inc. © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 31,
      "name": "Pay Attention to How You Come Across to Others",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade8week31-grumpy.gif",
      "summary": "Resilient people pay attention to how they come across to others, which helps them show up as their best selves and avoid being misunderstood. It’s okay to be grumpy sometimes, but you might not want people to think you\u0027re always grumpy. ",
      "readAloud": "While it is often good advice to “just be yourself” and not worry about what other people think of you, there is something else to consider. If you are not aware of how you come across to others, you might be misunderstood. \n\nShyness can come across as not being interested. Insecurity can come across as arrogance. Humor can sometimes feel insensitive. Excitement can sometimes feel overwhelming.\n\nObviously, these types of misunderstandings can have a huge impact on your ability to make friends and have close relationships. \n\nThe best way to find out how you come across to others is to ask, but make sure you ask people who genuinely care about you because this kind of feedback can be hard to hear. If that feels too scary, just pay attention to the following when you are interacting with others:\n\n* What is your tone of voice like?\n* What is your body language saying?\n* Do you interrupt people or listen well?\n* Do you tend to be more positive or negative?\n* Do you prefer to joke around or be more serious?\n* Do you use sarcasm to cover up your true feelings? \n\nEven if you don’t end up changing anything about how you present yourself, checking in to confirm how you come across to others gives you valuable information. ",
      "questions": "Have you ever been in a situation where someone got the wrong impression of you? If so, what could you have done differently to prevent that? \n\nHow do you want to come across to others? What words would you use for the impression you want to give? \n\nDo you present a different side of yourself to different people? For example, do you present a different side to your friends than you do to your parents? \n\nAre there people in your life who always make you feel good when you interact with them? If so, what can you learn from them and how they present themselves that could help you? \n\nCan you think of someone you know who doesn\u0027t realize how they come across? If so, what advice would you give them if they wanted it?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Snow White © The Walt Disney Company"
    },
    {
      "grade": 8,
      "week": 32,
      "name": "Make Sure Your Schedule Reflects Your Values",
      "category": "Needs/Values",
      "subCategory": "Strengths",
      "image": "grade8week32-cookiemonsterbusy.gif",
      "summary": "Resilient people know that we feel good about ourselves when we spend time doing things we care about.",
      "readAloud": "One of the hard things about being a kid is that there are all these adults with ideas about what you should spend your time doing. Your parents want you to study more. Your coaches want you to work out more. Your school counselors want you to do more community service.\n \nIt’s easy to spend all your time doing things that matter to others and no time on things that matter to you.\n \nBut this doesn’t feel good. And, it can make you unmotivated and resentful.\n \nSo, it’s important to get clear about what you care about and spend some of your time pursuing your own interests and values.\n\nIt helps to ask yourself who you are as a person and what you are trying to achieve—right now and in the future. \n\nThen, you can make sure the way you are spending your time lines up with your values. You can also spend more time using your strengths.\n\nThis will make you feel good about yourself and make sure you are working towards your own dreams… not someone else’s.",
      "questions": "Name three adjectives that describe who are as a person. (This could be athletic, funny, kind, hard-working, a good friend, an animal lover.)\n\nName three interests you have. (This could be that you like to cook, read, paint, draw, sing, build things, or volunteer at the local animal shelter.)\n\nWhat are you trying to achieve in your life right now? (This could be making good grades, getting into college, making a sports team, being in the school play.)\n\nWhat are you trying to achieve in your future? (What do you want to be when you grow up and what kind of life do you want to have?)\n\nIs there anything you value that you wish you could spend more time doing now?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Sesame Street © Sesame Workshop"
    },
    {
      "grade": 8,
      "week": 33,
      "name": "Notice What You Enjoy Doing and Do More of It",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade8week33-thisisfun.gif",
      "summary": "Resilient people know that everyone has a unique set of strengths and interests and making time for them builds self-esteem and adds balance to their lives.",
      "readAloud": "Many of the things we have to do as kids are not exactly enjoyable. We have to do our homework. We have to practice our instrument. We have to study for the SAT. And while these things are important if we want to create opportunities for ourselves in the future, it’s also important to balance out our schedules with things we actually enjoy.\n\nIf we spend all of our time checking someone else’s boxes or trying to make our college applications look good, we can turn into adults who have forgotten what they enjoy, what comes naturally to them, and what they are genuinely interested in.\n\nBut nurturing our own interests and strengths is important, not only because all work and no play is not good for anyone’s mental health, but also because it builds self-esteem. \n\nKnowing and using our personal strengths builds self-esteem.\n\nThink about it–we feel good about ourselves when we do things we’re good at. And, we get good at things when we are interested in them and want to spend time doing them.\n\nEach of us is unique in wonderful ways, and we are our best when we express our special combination of strengths and interests.\n\nSo make time for things you do just for you–because they’re interesting to you, fun for you, and they make you feel engaged, alive and uniquely you. In other words, notice what you enjoy doing and do more of it!",
      "questions": "What is one of your personal strengths?\n\nWhat is one of your personal interests?\n\nDoes it make sense to you that owning and using your personal strengths builds self-esteem? Why or why not?\n\nDoes it make sense to you how interests can turn into strengths? Why or why not?\n\nDo you agree that it’s important to balance out hard work with things you do for fun and because they’re interesting to you? And do you have a good enough balance of those things in your life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Tangled © Disney Enterprises, Inc."
    },
    {
      "grade": 8,
      "week": 34,
      "name": "Know the Ways You Are Resilient END 8th",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade8week34-nemo.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. This year we have talked about: \n\n* Using negative emotions to take care of yourself\n* Knowing how to agree to disagree\n* Having a personal mission statement\n* Welcoming feedback\n* Setting achievable goals around your strengths and values\n* Having a vision for your future\n* Having a changeable mind\n* Trusting your instincts\n* Paying attention to how you come across to others\n* Making sure your schedule reflects your values\n* Checking in with trusted advisors when making decisions\n\nThese are just a few of the resilience skills we have covered this year. You were already resilient, and now you have even more skills to choose from when you come to a bump in the road. ",
      "questions": "Can you describe a way you have been resilient this year?\n\nAre there any skills from this Resilience Reminders™ program that you learned or tried that worked for you?\n\nWhat words do you use to define resilience? \n\nDo you think talking about these types of things is helpful for students and families? Why or why not?\n\nDo you believe in reaching out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Finding Nemo © Disney Enterprises, Inc."
    },
    {
      "grade": 9,
      "week": 1,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade9week1-bartbounce copy.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. \n\nThe other good news is you are already resilient! You have probably picked up quite a few resilience skills already in life. For example, if you…\n\n* Can name your personal strengths\n* Have good-feeling relationships\n* Reach out for support when you need it\n* Take care of yourself–physically, mentally, emotionally and spiritually\n* Learn from your losses\n* Use your breath to calm down and focus\n* Trust your gut\n\nIf you do any of these things, you are already resilient. \n\nThese Resilience Reminders™ will add more skills to your list and remind you of helpful things you already know.",
      "questions": "How do you define resilience? What words do you use? \n\nHow do you demonstrate resilience? Which skills do you use? \n\nWhat disappointment, challenge or failure are you most proud of yourself for overcoming, and what skills did you use to feel better?\n\nWhat is one way you take care of yourself–physically, mentally, emotionally or spiritually?\n\nDo you agree that it demonstrates resilience to reach out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 2,
      "name": "Find Your \"Flow\"",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade9week2-shoothoops.gif",
      "summary": "Resilient people give their minds a break by getting lost in activities they enjoy. ",
      "readAloud": "Most of us spend a lot of time forcing our minds to do several things at once—like doing our homework in front of the television while carrying on a conversation. \n\nBut multitasking is stressful, and sometimes our minds need a break. One of the best ways to give our minds a break is to “single-task” something we enjoy.\n\n“Flow” is the mental state in which you are so fully engaged in whatever you are doing you lose all sense of time and place. Some call it being “in the zone.” \n\nAthletes achieve flow when they are so engrossed in their sport they don’t even notice what’s going on around them. Artists feel flow when they get lost in the act of painting. Writers experience flow when the words come pouring out and hours go by unnoticed.\n\nFinding activities that allow you to feel highly engaged and singularly focused will greatly improve your life. These activities allow the world—and your worries—to drop away. They also boost your self-esteem and sense of empowerment, because they allow you to demonstrate a strength and accomplish something you value.\n\nSo, find your flow and give yourself a mental and emotional boost.  ",
      "questions": "Can you describe a time when you experienced “flow”—when you were so engaged in doing an activity that you lost track of time and of your surroundings for a little while? \n\nCan you name a physical activity (like shooting hoops, dancing, or kicking a soccer ball) that allows you to experience “flow” ? \n\nCan you name an artistic activity (such as drawing, playing a musical instrument, or singing) that allows you to experience “flow”? \n\nCan you name a mental activity (like some kind of puzzle or chess) that allows you to experience “flow”? \n\nWhich activities can you do well while multitasking, and which ones do you need to single-task to do well?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 9,
      "week": 3,
      "name": "Find the Good in Negative Emotions",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade9week3-insideoutemotions.gif",
      "summary": "Resilient people know that negative emotions are not bad. In fact, negative emotions can be good if we use them to take care of ourselves.",
      "readAloud": "Negative emotions feel bad, but that doesn’t mean they are bad. Unfortunately, many people are taught that their negative emotions are wrong. Boys shouldn’t cry. Girls shouldn’t be angry. People shouldn’t be so dramatic or emotional.\nMany adults seem to believe it’s not appropriate to be angry when someone is rude to you, sad when you lose something, or afraid when something is challenging or unknown.\n \nBut emotions—negative and positive emotions—are natural responses that can help you make decisions and take care of yourself, if you use them correctly. If you get curious about what your negative emotions have to tell you, instead of ignoring them or pretending like you don’t feel them, you can figure out what you need to feel better and take an action step to take care of yourself.  \n \nAnger is often telling us we deserve better. Sadness is often reminding us that something or someone is important to us. Fear is always trying to protect us.\n \nEmotions are natural and appropriate. Sometimes the way we respond to our emotions is not. So, when you feel a negative emotion, ask yourself what you need to feel better and take an action step to take care of yourself. That is finding the good in negative emotions.",
      "questions": "Have you ever been told to keep certain emotions to yourself? If so, how did that make you feel? \n\nHave you ever noticed that emotions get bigger if you stuff them down and then explode in an overreaction at a later time? Why do you think that is? \n\nCan you describe a time when you listened to what a negative emotion was telling you and took care of yourself?\n\nIt can be really hard not to just react and do or say something you’ll regret when you feel really angry or upset. How do you get control of yourself and calm down when you are really upset? Some people count to 10. Others take a deep breath. What do you do? \n\nDo you believe it’s natural to feel negative emotions sometimes, or do you think you should be able to avoid feeling negative emotions?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Inside Out ©”The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 4,
      "name": "Make Sure Your Expectations Bring Out the Best in You",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade9week4-homernotperfect.gif",
      "summary": "Resilient people know that the expectations we have for ourselves shape our lives, so they make sure their expectations bring out the best in them.",
      "readAloud": "The expectations we have for ourselves have real power. They can inspire us to reach for our dreams or discourage us from living up to our potential.\n\n* If we expect perfection, we will always be disappointed in ourselves.\n* If we think things should be easy, we will not get in the habit of working hard.\n* If we believe we should be able to succeed without seeking feedback, we will miss opportunities to learn, grow and have mentors.\n* And if we focus on avoiding failure, we will not take risks that could bring great rewards.\n\nOn the other hand, if we expect there to be twists and turns, disappointments and triumphs, failures and successes, we will be open to what life is offering and more able to go with the flow.\n\nWe want to be like Goldilocks when setting expectations–not setting them so low that we fail to push ourselves out of our comfort zones nor setting them so high that we judge ourselves too harshly. Somewhere in the middle is where we can expect ourselves to mess up sometimes but believe we can recover.\n\nSo, set expectations that inspire you, and you will enjoy more of the journey. ",
      "questions": "Why do you think so many people struggle with perfectionism today, and what advice would you give someone about not having to be perfect?\n\nWhat is something that did not come easy to you but you worked hard to learn or improve?\n\nAre you good at hearing feedback or do you take it as criticism?\n\nCan you describe a time you failed at something but learned from it?\n\nWould you say your expectations for yourself are too low, too high, or just right, and why?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 5,
      "name": "Talk About Your Mental Health",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade9week5-idontwannatalkaboutit.gif",
      "summary": "Resilient people know that talking about their mental health is good for their mental health, so they have the courage to seek support when they feel anxious, overwhelmed or sad. ",
      "readAloud": "Here’s the thing: Everyone–seriously–everyone has times in life when they feel anxious, overwhelmed, sad, lost, insecure, unsure of themselves or unable to shake themselves out of a dark place. \n\nWhen this happens, we need to seek help. \n\nIf you had a physical symptom, you would likely seek help. You would tell your parents about your illness or injury and they would tell a professional, so you could get the treatment you need.\n\nThe same is true of mental health. Sometimes our symptoms require professional help. And even if they are not that intense, talking about what’s going on is helpful. That’s what therapy is–talking about what’s troubling you, so you can find solutions and start to feel better.\n\nEven mental health professionals seek help from others when they have mental health challenges, because talking and being supported by others is healing. We humans are social beings. It feels good to connect, especially when we are feeling vulnerable.\n\nSo, the next time you are feeling upset about something or just out of sorts, have the courage to talk about it with someone who feels trustworthy and supportive–whether that is someone you already know or someone you can access through a variety of mental health resources available through your school counselor.",
      "questions": "Can you describe a time when you talked with someone about how you were feeling and it helped you feel better?\n\nDo you think it’s true that everyone has times in life when they have overwhelming emotions and don’t know how to help themselves?\n\nDo you think many students your age feel comfortable talking about mental health issues? Why or why not?\n\nWhy do you think talking is helpful when you are upset about something?\n\nDo you support the idea of students in your school going to the school counselor for mental health resources, if they need them?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Lion King  ©  The Walk Disney Company"
    },
    {
      "grade": 9,
      "week": 6,
      "name": "Visualize Success",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade9week6-imaginationflying.gif",
      "summary": "Resilient people believe in the power of their imagination, so they spend time visualizing success.",
      "readAloud": "Every successful athlete imagined winning the championship game. Every successful musician imagined playing sold out stadiums. Every successful astronaut imagined blasting off into space. \n\nVisualizing success is a way to convince yourself that something is actually possible. And when you believe something is possible, you take steps to make it happen. \n\nNot only that, research shows that practicing things in your mind, actually makes you better in real life. In one study, people who imagined shooting basketball shots for 30 days improved almost as much as people who actually practiced with real basketballs! \n\nOur minds are powerful tools. \n\nSo, put away your screens for a little while and spend some time visualizing yourself achieving one of your dreams. \nIn fact, let’s try it right now:\n\n* Close your eyes, if you’re comfortable doing that.\n* Take a deep breath and blow it all the way out.\n* Now picture yourself practicing something you really want to get better at. I’ll give you 10 seconds to imagine that.\n* Now take another deep breath and picture yourself achieving one of your goals and celebrating your success. I’ll give you another 10 seconds to imagine that.\n* Now take a final deep, relaxing breath, and come back to the room.",
      "questions": "How was that visualization for you? What did you see? How did it feel? \n\nPracticing things in your mind is also called mental rehearsal, and lots of athletes do it. Why do you think it is helpful? Have you ever done it? \n\nImagining yourself achieving one of your dreams is also called daydreaming. Why do you think that is helpful? What do you daydream about? \n\nVisualizing success raises your expectations for yourself. Why do you think having high expectations for yourself helps you achieve things but having low expectations for yourself prevents you from achieving things? \n\nIt\u0027s not always easy for people to start visualizing success for themselves. What do you think could help someone who has a hard time with it? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Peter Pan © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 7,
      "name": "Don’t Just Think, Check Your Gut Feelings Too",
      "category": "Inner Wisdom",
      "subCategory": "Spirit",
      "image": "grade9week7-newstarwarsbadfeeling.gif",
      "summary": "Resilient people know their thoughts can be misleading, so they check in with their gut feelings too.",
      "readAloud": "Sometimes we think things that are not true: “I’m not good enough.”\n\nMany times we think things that hold us back: “I shouldn’t even try.”\n\nOften we don’t think things through: “I’m sure it’ll be fine. Just do it.”\n\nOur thoughts do not always serve us, but the good news is, our gut feelings will lead us in the right direction—if we learn to trust them.\n\nWe can feel in our bodies whether something feels good, safe and inspiring, and we can feel in our bodies when something feels sketchy, questionable, or fear-based.\n\nWhether we listen to them or not, our gut feelings are there.\n\nSo the next time you have a decision to make, take a moment to check in with your gut feelings. Close your eyes, take a deep, relaxing breath, and ask yourself, “What does my Inner Wisdom have to say about this? Which is the best decision for me?”\n\nIf it still isn’t clear, imagine making one decision and notice how that feels. Then imagine making the other decision, and notice how that feels.\n\nThere is a part of us that knows what we truly desire and what is best for us, and it never gets misled by negative thoughts or limiting beliefs. So, tune in!",
      "questions": "Some people call it a gut feeling. Others call it instinct, intuition, Inner Wisdom or their higher self. What words do you use to describe the way you can feel in your body whether something is good or bad for you? \n\nCan you describe a time that you listened to your gut feelings when making a decision? \n\nCan you describe a time when you didn’t listen to your gut feelings—you knew they were there, but you chose to ignore them? \n\nHow can you tell the difference between something you are thinking and something your Inner Wisdom is trying to tell you? \n\nCan you describe a time when your gut feelings protected you from danger?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 8,
      "name": "Be Choosy About What You Put In-to Your Body and Mind",
      "category": "Needs/Values",
      "subCategory": "",
      "image": "grade9week8-whyareyoudoingthat.gif",
      "summary": "Resilient people value being physically and mentally healthy, so they are choosy about what they put into their bodies and minds.",
      "readAloud": "What we put into our bodies matters. If we only put in junk food and sugary drinks, our bodies will not feel well or work well. Even young children know this. Healthy bodies require healthful food and plenty of water. (They also need regular exercise and plenty of sleep, but right now we’re talking about what we put into our bodies.) If we want healthy bodies, we have to put in healthful foods and drinks.\n\nThe same is true of our minds. If we want healthy minds, we have to feed our minds with healthful “food” too. If we feed our minds a diet of negative thoughts and bad-feeling emotions, our mental health will suffer. \n\nThe problem is we live in a world where it can be difficult to get away from the constant stream of negative chatter. Our televisions, radios, and social media feeds send out a never-ending flow of information that can make us feel angry, scared, lonely, confused and overwhelmed, if we aren’t choosy about what we tune into.\n\nBut if you pay attention to how you feel when you are watching something or listening to someone, you can start being choosy about what you let in. \n\nIf you want to feel empowered, inspired, optimistic, and like you matter, then make sure you seek out people who make you feel good, conversations that inspire you, and media content that makes you believe you can dream big and have a positive impact.",
      "questions": "What is something healthful that you put into your body?\n\nWhat is something healthful that you put into your mind?\n\nIs there anything you put into your body that is not super healthful and that you might like to stop putting in or limit how much you put in?\n\nAnd what about your mind–is there anything you put into your mind that doesn’t make you feel good and that you might want to stop letting in or limit how much you let in? (This could be shows, videos, social content, or hanging out with people who bring you down instead of who lift you up.)\n\nDo you ever take time to not put anything into your mind… to just sit quietly and be present? What could be the benefit of doing that?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Family Guy © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 9,
      "name": "Have Good Feeling Talk Habits",
      "category": "Interpersonal Skills",
      "subCategory": "Emotional Intelligence",
      "image": "grade9week9-heyarnoldlistentoyourself.gif",
      "summary": "Resilient people know that words have power so they get in the habit of being more positive than negative in conversations.",
      "readAloud": "At any given moment, there are lots of things to complain about. Whether it’s something in your personal life or something in the news, there are always reasons to be upset. And, expressing negative emotions is not necessarily a bad thing. In fact, if you are upset about something, talking about it and getting support is a great way to take care of yourself.\n \nOn the other hand, there’s a difference between getting support and just venting or being negative. Often people don’t realize how negative they sound, but it doesn’t feel good to be around people who are always complaining or criticizing.\n \nAnd the truth is we have a choice about what we talk about—even when life is not going our way, we get to choose the words that come out of our mouths. And speaking words that focus on the more positive side of things will make you feel better. It will also make others feel better.\n \nAgain, negative emotions are not bad. However, you can make yourself and those around you feel better if you choose to inject some positivity into your conversations sometimes too. Some researchers say the ideal praise-to-criticism ratio is 5 to 1. Meaning, for every negative comment you make, you need to share five positive comments as well.\n \nWhatever you decide is the right ratio for you, becoming aware of your talk habits will give you a powerful tool for feeling better.",
      "questions": "What word would you use to describe how you show up in conversations: positive, negative, supportive, critical, or any other word that comes to mind?\n\nWhat is your praise-to-criticism ratio, and what would you like it to be?\n\nHow do you want people to feel around you?\n\nIn what ways do you already seek out positive conversations or add positivity to conversations?\n\nCan you think of any negative talk habits you could do less of, like gossiping about, criticizing, or blaming others?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "\\"
    },
    {
      "grade": 9,
      "week": 10,
      "name": "Know the Four Ways to Feel Better",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade9week10-angrybirdsdeepbreath.gif",
      "summary": "Resilient people know that their thoughts, emotions, bodies and behavior are interconnected and that they can feel better by doing something positive for any of the four.",
      "readAloud": "Our minds and bodies are connected. In fact, our minds, bodies, emotions, and behavior are connected. \n\nWhen we are mentally stressed, we feel negative emotions like anxiety, we have physical symptoms like tense muscles or headaches, and our behavior shows how stressed we are—we snap at people, for example. \n\nIt works the other way too. When our bodies are down with an illness, we feel emotionally down, the thought track in our heads is down too, and our behavior shows that we feel bad—we withdraw from people, for example. \n\nWhat’s going on in our minds matches how we feel emotionally, how we feel physically, and how we behave.\n\nThe good news is, if we can feel better in one of the areas, we’ll feel better in all of them. For example, if we get some exercise, our body will obviously feel better, our mood (emotions) will lift also, we will stop being so short-tempered (behavior), and the thought track in our head will be a little more positive. \n\nSo, the next time you want to feel better, think of something you can do to feel better mentally, emotionally, physically or behaviorally:\n\n* Think a more positive thought by looking at the situation a little differently.\n* Do something that lifts your emotions—like listen to a great song, hang out with a person who makes you laugh, or read something that inspires you.\n* Do something to feel better physically—like shoot some hoops or go for a walk.\n* Or behave in a way that feels good—like do something kind for someone else.",
      "questions": "For many people, doing something physical like going for a run or getting a hug from someone who loves you is a good way to feel better. Is there something physical that works for you when you want to get out of a bad mood?\n\nGreat songs, funny videos, and kind friends can lift your emotions. What else do you do to feel better emotionally—something that is not physical?\n\nOften, the hardest thing to change first is your thoughts, so don\u0027t even try to think positively until you\u0027ve done something to improve your mood. Which of the four (your thoughts, emotions, body or behavior) is hardest for you to shift? \n\nWhich of the four (your thoughts, emotions, body or behavior) is easiest for you to change? \n\nHave you ever noticed that if someone tries to cheer you up before you’re ready, it just makes you feel worse? What do you do when that happens?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Angry Birds © Rovio Entertainment Corporation"
    },
    {
      "grade": 9,
      "week": 11,
      "name": "Be Open to Learning and Changing Your Mind",
      "category": "Beliefs",
      "subCategory": "Interpersonal Skills",
      "image": "grade9week11-homerchangemind.gif",
      "summary": "Resilient people know they do not know everything, so they stay open to learning and changing their minds.",
      "readAloud": "When you are a kid, it’s pretty clear that there are many things you do not yet know. Parents and teachers find new things every day to show you and teach you.\n\nBut as people grow into adults, they can stop being curious and instead become rigid in their thinking and set in their ways. They stop seeing different perspectives, which limits their opportunities and relationships.\n\nBeing able to change course and adapt to new situations is key to success.\n\nWhen you remember that you don’t know what you don’t know, you open your life to new possibilities. When you remember that life is a process of learning and growing, you try new things and take the pressure off of yourself to have to be right about everything.\n\nAnd when you give yourself permission to change your mind, you relax about your decisions, knowing you can avoid regret by making a new decision whenever you want to. ",
      "questions": "Can you describe a time when you changed your mind about something, and what made you change it? \n\nCan you describe a time when you listened to another person’s perspective and changed your mind in an argument? \n\nCan you give an example of how you are curious and/or open-minded? \n\nWhy do you think adults sometimes stop being willing to change their minds? \n\nHave you found any helpful tips for presenting your argument when you want to change someone\u0027s mind?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 12,
      "name": "Practice Gratitude",
      "category": "Emotional Intelligence",
      "subCategory": "Interpersonal Skills",
      "image": "grade9week12-pandagratitude.gif",
      "summary": "Resilient people know that gratitude improves not only your mental health but also your physical health, so they make it a practice to be grateful.",
      "readAloud": "It makes sense that gratitude is good for your mental health, but studies show that gratitude improves our physical health too! Gratitude also improves relationships.\n\nThanksgiving is all about gratitude. But we can forget to look for things to be grateful for during the rest of the year. \nIt’s easy to get so caught up in the stresses of daily life that we focus on what’s going wrong in our lives instead of looking for what’s good.   \n\nThere are always things to be grateful for in our lives. We just have to make an effort to notice them. \n\nThis is why some people practice gratitude the same way they practice a sport. They set aside time every day or every week to think about what they are grateful for. Some people do it every morning. Others do it when they go to bed. Others do it when they attend spiritual services.\n\nHowever you do it, the more you practice gratitude, the better you will feel - and the healthier you will be. ",
      "questions": "Who is a person you are grateful for?\n\nWhat is an experience you are grateful for?\n\nWhat is a quality about yourself you are grateful for?\n\nCan you think of anything you are grateful for that came from something that was not so great?\n\nHow can you remember to practice gratitude more often in your daily life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Kung Fu Panda © DREAMWORKS ANIMATION L.L.C."
    },
    {
      "grade": 9,
      "week": 13,
      "name": "Be Coachable. Welcome Feedback.",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade9week13-feedbackanime.gif",
      "summary": "Resilient people know that becoming good at something is a process of learning, trying, and sometimes failing. And, one of the best ways to improve is to get feedback from others.",
      "readAloud": "It can be hard to hear feedback. It can feel embarrassing when a coach, teacher, parent or friend points out how you could do something better.\n \nMany people take it as criticism and get upset when others try to give them feedback. They take it to mean they have done something wrong or are not as good as they should be. Some people think feedback means they have failed.\n \nBut if you shut down when someone is trying to help you, you miss valuable information that could improve your performance… and your life. Feedback helps you in sports, in academics, in relationships, and in your career.\n \nWhen you change the story in your mind from one where feedback means you are not good enough to one where it means you are learning and improving, you put yourself on the fast track to success.\n \nSuccessful people welcome feedback. They want to know what others think of how they are doing and what they could do better.\n \nSo the next time someone offers to give you feedback, resist the urge to take it as criticism and open to the possibility that it will help you become an even better version of yourself. ",
      "questions": "What goes through your head when someone tries to give you feedback? Do you take it as criticism and feel bad about yourself or welcome the idea of learning something? \n\nAre there some people who make it easier for you to hear their feedback and others who make feedback feel bad? Why is it easier to hear from some people? \n\nWhat areas of your life are you best at hearing feedback in, and what areas are more difficult for you? (sports, academics, relationships, family, other?)  \n\nHow do you think feedback can help you in relationships? \n\nAre you good at giving feedback yourself? What can you do to make it so people take your feedback constructively and not as criticism?  ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 9,
      "week": 14,
      "name": "Feel People Out",
      "category": "Inner Wisdom",
      "subCategory": "Interpersonal Skills",
      "image": "grade9week14-madedecisiontotrustyou.gif",
      "summary": "Resilient people pay attention to how people make them feel and choose to spend time with good-feeling people.",
      "readAloud": "We can feel in our bodies when someone feels open, kind and trustworthy, and we can feel in our bodies when someone feels edgy, closed off, unkind and untrustworthy. \n\nThe problem is when you’re young you are forced to spend time with people who just happen to be in your class or on your team or in your neighborhood. And you don’t have much choice about the adults in your life either.\n\nThat being said, you can make the most of whatever social group you find yourself in if you get good at “feeling people out.”\n\nIf you tune into how people feel and trust your gut when meeting new people, you will be able to choose friends you can trust to have your best interest at heart. You can also find trustworthy adult mentors to support and guide you. \n\nSo, feel people out and spend time with people who lift you up and inspire you to be the best version of yourself.",
      "questions": "What does it feel like in your body when you meet someone who feels like an open, trustworthy, safe person? \n\nWhat does it feel like in your body when you meet someone who feels like a closed, untrustworthy, or even dangerous person? \n\nObserving people is part of feeling people out. What differences can you observe between good-feeling and bad-feeling people (such as body language, tone of voice, eye contact)? \n\nWhat can you do if you are NOT SURE if someone is a good, safe person? \n\nWhat can you do to keep yourself safe when you meet someone who does not feel good to you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Tangled © Disney Enterprises, Inc."
    },
    {
      "grade": 9,
      "week": 15,
      "name": "Have Beliefs That Comfort You, Guide You, and Give Your Life Meaning",
      "category": "Spirit",
      "subCategory": "Beliefs",
      "image": "grade9week15-poohbeliefs.gif",
      "summary": "Resilient people know that our beliefs shape our lives, so they have beliefs that comfort them, guide them, and give their lives meaning.",
      "readAloud": "This time of year reminds us that spiritual beliefs are a powerful source of comfort and guidance for many people. Some beliefs give meaning to our lives. Some beliefs inspire us to be kind to each other. Some beliefs comfort us around our fears, especially around our fear of death. And many beliefs connect us to supportive communities and nurture a sense of belonging.\n \nRegardless of what you believe, choosing to live your life in alignment with beliefs that give you purpose, connect you to the most loving parts of yourself, and nurture optimism for your future is a choice worth making.\n \nMany people don’t realize that they can choose what to believe. They think that because they already believe something, it must be true. But people believe many different things, some of which are contradictory. Yet somehow, people have evidence for contradictory beliefs. \n\nSo, if you realize you have a belief that does not make you feel very good, look for evidence of something better. If you look hard enough, you will probably find it.\n \nWhatever you believe, we hope it brings you peace, joy and love this holiday season. ",
      "questions": "Do you have a belief that inspires you to be the best person you can be?\n\nDo you have a belief that motivates you to be kind to others?\n\nDo you have a belief that comforts you around any of your fears?\n\nDo you have a belief that gives your life meaning or a sense of purpose?\n\nDo you have a belief that makes you feel optimistic about your future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Winnie the Pooh © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 16,
      "name": "Set Achievable Goals (Around Strengths and Values)",
      "category": "Strengths",
      "subCategory": "Needs/Values",
      "image": "grade9week16-snoopygoals.gif",
      "summary": "Resilient people believe goals should inspire them and make them feel good about themselves, so they set achievable goals around their strengths and values.",
      "readAloud": "New Year’s Resolutions often start out as inspiring but end up making us feel disappointed in ourselves if we fail to achieve them. Here are some tips for setting goals that will make you feel good about yourself:\n\nSet goals that are small enough that you can actually get them done. If you set goals that are too big, you’ll give up, feel bad about yourself, and be less likely to set future goals for yourself. On the other hand, if you set goals you can actually achieve, you will build positive momentum that allows you to achieve goal after goal after goal.\n\nSet goals around your strengths. The more time you spend using your strengths, the better you feel about yourself. And, if you set goals around things you actually enjoy doing and that come naturally to you, you are more likely to achieve your goals, which also makes you feel good about yourself. It’s a double-whammy of feeling good about yourself! \n\nSet goals around your values. Doing things that demonstrate your personal values also makes you feel good about yourself. If you value being a good athlete, set goals around your fitness. If you value being a good student, set goals around your academics. If you value being kind, set goals around making people feel good.\n\nFind people to support you in achieving your goals. Having people to support you and hold you accountable will make you much more likely to achieve your goals.",
      "questions": "What is one goal you have for yourself right now, and does it feel achievable? \n\nCan you think of a goal to set around one of your strengths or values? \n\nCan you think of someone who could support you in achieving one of your goals? \n\nWhat is a goal you successfully achieved in the past, and what factors contributed to you achieving them? \n\nIf you think about goals you did not achieve in the past, what factors prevented you from achieving them? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Snoopy © Peanuts Worldwide"
    },
    {
      "grade": 9,
      "week": 17,
      "name": "Tell Empowering Stories About Yourself",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade9week17-Imaprettyamazingperson.gif",
      "summary": "Resilient people know that the stories they tell about themselves shape their lives, so they tell the most empowering stories they can about themselves, their lives, and their futures.",
      "readAloud": "The stories we tell about ourselves drive our behavior, influence how others respond to us, and either empower us to achieve our dreams or place unnecessary limits on our potential.\n \nImagine that you tell the story, “I’m not good at school.”\n \nThis belief would make you feel insecure and anxious in the classroom, which would likely make you reluctant to participate. This would cause your teachers to judge you poorly and give you lower grades, which would confirm your belief that you are not good at school. All this would probably make you less likely to try very hard at your schoolwork, which would continue the cycle.\n \nBut imagine if you told this story: “It sometimes takes me longer, but I always figure it out.”\n \nThis belief would make you feel hopeful and determined in the classroom and would likely cause you to engage with your teachers until the concepts clicked for you. Not only would you get higher grades but your teachers would also see you as diligent and hard-working. All this would confirm your belief that if you work hard enough you eventually figure it out.\n \nWords have power.\n\nYou get to decide how to tell your life story, so choose your words wisely.",
      "questions": "What is one really positive thing you can say about yourself? \n\nWhat is something positive you can say about your future? \n\nCan you think of a positive way to talk about a mistake or failure you had in the past? \n\nWhat is something positive you can say about how you treat other people? \n\nDo you have a negative story about yourself? If so, can you think of a slightly better story to tell instead? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page ",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc."
    },
    {
      "grade": 9,
      "week": 18,
      "name": "Don’t Expect Yourself to be Happy All the Time",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade9week18-imnothappy.gif",
      "summary": "Resilient people know that disappointments, losses, failures, challenges and boredom are a part of life, so they don’t expect themselves to be happy all the time.",
      "readAloud": "If we aren’t careful, we can start to believe that life is supposed to be happy all the time. If we are not feeling happy every moment of the day, something is wrong with us. We’re failing at life. We’re not doing it right.\n\nCelebrities, sports stars, social media influencers, and even our peers–if you trust their social media posts–are living these glamorous lives and having these grand adventures. \n\nBut the irony is, expecting ourselves to be happy all the time sets us up for heart-breaking disappointment.\n\nIt feeds our insecurities and makes it more and more difficult to experience actual happiness.\n\nThe good news is, if we expect the daily annoyances, stressors, uncertainties, and bumps in the emotional road, we develop a mindset that is more adaptable, less self-critical, and more able to savor the good moments when they do arise.\n\nThe thing about happiness is it’s fleeting. We feel it for a few seconds, and then life takes over again. We can choose to try to savor the happy feeling, but then we remember our math test, or the fact that someone was rude to us, or how mad we are that our coach won’t play us more.\n\nTrue happiness comes when our lives have meaning and a sense of purpose, when we are working toward goals that matter to us, and when we feel connected to loved ones no matter what emotions we are feeling.\n\nSo stop expecting life to be happy all the time, and start being proud of yourself for pushing through the hard times, learning from your losses, and finding meaning in the setbacks.",
      "questions": "Do you agree that it can feel like we’re doing something wrong if we are not happy all the time?\n\nDo you agree that a certain level of stress and anxiety are a normal part of daily life?\n\nDo you understand why expecting ourselves to be happy all the time causes disappointment?\n\nDo you agree that happiness is fleeting? \n\nWhat do you think true happiness comes from?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Rocko’s Modern Life © Viacom International, Inc."
    },
    {
      "grade": 9,
      "week": 19,
      "name": "Have the Courage to be Real",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade9week19-spongebobdontfeellikeit.gif",
      "summary": "Resilient people know that we build connections with people when we are open and real, so they have the courage to be vulnerable.",
      "readAloud": "When life gets crazy, scary or confusing it really helps to have people you can be honest with. After all, how can you get support for what you’re dealing with if you don’t open up about what’s really going on for you mentally and emotionally?\n \nSometimes people think they should hide their true feelings, especially if those feelings make them seem insecure, not tough enough, or uncool in some way. They worry that people will judge them if they open up and be vulnerable. Some believe it’s weak to need emotional support.\n \nBut the truth is, deep connections are not built when people pretend to be perfect. They are formed when people have the courage to share their insecurities and imperfections, because everyone has them and we can all relate to them.\n \nYou can’t know this until you try it, which is why it takes a lot of courage. And some people are not safe to be vulnerable with. But if you pay attention to how people make you feel, and seek out people who feel non-judgmental and trustworthy, you can surround yourself with relationships that lift you up when life gets you down. And you can do the same for others.",
      "questions": "Do you agree that you connect with people who are open and real rather than people who don’t share anything personal?\n\nCan you think of a reason it is good to have people you can be vulnerable with?\n\nHow can you tell if someone is trustworthy, non-judgmental and safe to be vulnerable with?\n\nWhat do you do to let people know that you are not judgmental and that they can open up to you?\n\nIt is difficult to be with someone when they are having a strong negative emotion like sadness, anger, or fear. Think back to a time you felt that way and tell us what you needed from someone. How could they have helped you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 9,
      "week": 20,
      "name": "Be Mindful of Your Body",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade9week20-patrickeatingnotmindfully.gif",
      "summary": "Resilient people know that their minds and bodies are connected, so they are mindful of how they treat their bodies.",
      "readAloud": "Our minds and bodies are connected.\n\nBut with life as busy and chaotic as it is, it\u0027s easy to overlook how the way we treat our bodies affects us mentally and emotionally. But if you pay attention, it is obvious.\n\nWhen our bodies are down with an illness, we feel mentally and emotionally down as well. When we finish a good workout, we feel empowered and clear-minded.\n\nWhen we don’t get enough sleep, we feel foggy and irritable. When we are well-rested and have eaten a healthy meal, we feel alert and optimistic.\n\nDon\u0027t take our word for it. Try it for yourself. \n\nCompare how you feel after eating junk food with how you feel after eating something healthy. Notice how you feel after being a couch potato and how you feel after getting some exercise. See how different you feel after staying up really late versus getting a full night\u0027s sleep.\n\nBe mindful of your body and start feeling your best - physically, mentally and emotionally.",
      "questions": "Can you describe a time that you noticed how what was going on with your body affected how you felt mentally and emotionally? \n\nHow does exercise make you feel mentally and emotionally? \n\nHow does sleep affect how you feel mentally and emotionally? \n\nWhat foods make you feel the best physically, mentally and emotionally? \n\nWhat foods make you feel the worst physically, mentally and emotionally?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 9,
      "week": 21,
      "name": "Practice Tolerating Discomfort",
      "category": "Strengths",
      "subCategory": "Beliefs",
      "image": "grade9week21-icanhandlethis.gif",
      "summary": "Resilient people know that discomfort happens and their attitude about it matters, so they expect some discomfort and practice positive self-talk.",
      "readAloud": "No one likes to be uncomfortable. In fact, our brains are wired to avoid things that are unpleasant. \n\nThat being said, it is impossible to avoid all discomfort. Unexpected annoyances, negative emotions, and uncomfortable experiences are a part of daily life. So, instead of trying to avoid all discomfort, it’s helpful to practice tolerating it. \n\nHow do you do that?\n\nFirst, expect to experience some discomfort as a natural part of daily life. Research shows that the very act of expecting discomfort and “normalizing” it (which just means believing it’s “normal” to expect some discomfort in daily life) makes discomfort less uncomfortable when it happens. \n\nExpecting some discomfort also makes us more able to switch into solution-mode instead of getting stuck focusing on how uncomfortable we feel.\n\nSecond, practice positive self-talk. \n\nFor example, when you notice you feel sick to your stomach about taking a test, instead of focusing on how anxious you feel, you can focus on how well you prepared. \n\n”\tYour self-talk might sound like this, “It’s normal to feel anxious before a big test, but I’ve got this. I studied hard. I know the material. I can do this.”\n\nOr, if you have to do something out of your comfort zone like speak in public or perform in front of an audience or have a difficult conversation with someone, instead of focusing on how nervous or scared you feel, you could focus on how good it will feel to have given your all.\n\n”\tYour self-talk might sound like this, “It’s normal to be terrified to speak in public, but it’s a great skill to learn and I’ll be proud of myself for facing this fear.”\n\nThe things we say to ourselves matter. So expect some discomfort and practice using self-talk to motivate and inspire yourself.",
      "questions": "Would you say you’re good at tolerating discomfort or not so good at it? And what could you do to be better?\n\nIs your self-talk generally motivating and positive or critical and negative?\n\nHave you ever stopped yourself in the middle of freaking out about something and calmed yourself down? How did you do it?\n\nHave you ever noticed how the person you’re with can make things better or worse? What can they do that make the situation better? What can they do to make it worse?\n\nCan you give an example of how expecting something to be uncomfortable makes it not feel as bad as when you don’t have those expectations?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Hercules © Disney Enterprises, Inc."
    },
    {
      "grade": 9,
      "week": 22,
      "name": "Take Responsibility for How You Show up in the World",
      "category": "Needs/Values",
      "subCategory": "Strengths",
      "image": "grade9week22-whoareyouinvincibles.gif",
      "summary": "Resilient people know that many things are out of their control,\nbut how they show up each day—what kind of person they are\nand choices they make—is completely up to them.",
      "readAloud": "It is not always easy to be our best selves. When we are angry at someone we can do things we later regret. When we are frustrated about something we can show sides of ourselves we are not proud of. And sometimes certain people can push us to do things we know are not right.\n \nIt is not always easy to be our best selves, but resilient people know that it matters. Why does it matter? Because it builds self-esteem.\n \nIt feels good to set high standards for yourself and live up to those standards—even when no one is watching. It feels good to be proud of yourself. It is empowering and inspiring and satisfying. And it is something that is always available to you, even when much about your life is out of your control.\n \nOne thing that helps you show up as the best version of yourself is to surround yourself with people who share your values and bring out the best in you. Another thing that helps is to know what your personal strengths are and use them as often as possible.\n \nIt is not always easy to be your best self and it will not prevent you from experiencing disappointment, but it will empower you to be the hero in your own life.",
      "questions": "When you think of being your best self, what is the single, most important character trait you try to live by?\n\nIf you asked your friends, family members, coaches, teachers or mentors what the best thing about you is, what would they say?\n\nCan you think of a time when it was difficult to be your best self but you did it anyway?\n\nCan you think of something you could do to start being your best self a little more often?\n\nWhy do you think using your personal strengths helps you show up as the best version of yourself?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Incredibles © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 23,
      "name": "Listen, Instead of Defending Yourself",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade9week23-defensivesouthparklooped.gif",
      "summary": "Resilient people know that they are not always aware of how their actions impact others, so they listen instead of defending themselves.",
      "readAloud": "When someone is upset with us it is natural to want to defend ourselves. It feels terrible to be accused of doing something wrong, especially when we don’t think we did anything wrong. \n\nBut we are not always aware of how our actions impact others. We may not intend to hurt anyone, but they can still be hurt. For example…\n\n* You think you’re joking around, but your teammate feels like you’re picking on him.\n* Your best friend overhears you making plans with someone else and is upset that you don’t tell her about it.\n\nIt is also true that sometimes someone needs something from us, and if we get defensive, we might miss the opportunity to improve our relationship. For example…\n\n* If you get defensive when your coach asks you to work harder in practice, you miss the opportunity to become the leader he needs you to be.\n* If you get defensive when your mom asks you to keep your room tidy, you miss the opportunity to make her feel respected and cared for by you.\n\nIt’s easy to be so stuck on our own story that we cannot understand the other person’s perspective. But if our response is always to defend ourselves, we miss opportunities to learn about how we come across to others and to deepen our relationships.\n\nSo pay attention to when you get defensive, take a breath when you feel yourself doing it, and open to the possibility that you might be either misunderstanding what the other person is needing from you or being misunderstood by them. \n\nIf you listen first, you will be better able to find a win-win solution to whatever is upsetting them.",
      "questions": "Can you describe a time when someone was upset with you for something you didn’t intend and how you resolved that?\n\nWould you say you’re pretty good at listening and not being defensive or not? Why do you think that is?\n\nAre there certain people you feel more defensive with, and why is that? (your big brother, your parents, your teachers)\n\nAre there certain issues that make you feel more defensive than others?\n\nWhat can someone do to make you feel less defensive when they are upset with you or asking you for something they need?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. South Park © Comedy Partners"
    },
    {
      "grade": 9,
      "week": 24,
      "name": "Use Your Breath to Calm Down and Focus",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade9week24-breathe copy.gif",
      "summary": "Resilient people know that their breath is their best tool for calming down, collecting their thoughts, and focusing when they\u0027re nervous, upset, or need to perform.",
      "readAloud": "Many people do not realize that their greatest tool for calming down when they are nervous or upset about something is always with them... It\u0027s their breath.\n \nUsing your breath to calm down and focus is a super power. And it’s super easy to do.\n \nTake a slow, deep breath right now and focus on the SOUND of the air going in and out of your nose…\n \nTake another slow, deep breath and this time focus on the FEEL of your belly expanding as the air goes in and then contracting as the air goes out…\n \nTake another slow, deep breath and this time, after you fill your belly all the way up, IMAGINE ALL OF YOUR MUSCLES RELAXING as you slowly let the air back out again.\n \nYou just meditated! It’s as easy as that.\n \nFocusing on your breath is an easy way to get your mind to pay attention to what\u0027s going on in your body, instead of on whatever has you nervous or upset. And when you focus on your breath, you naturally calm down.\n \nEven if you only have time for two or three deep breaths, you’ll get the benefit of feeling a little bit more calm and focused, which helps you feel better if you\u0027re upset and perform better if you’re nervous. It also helps you collect your thoughts if you have an important decision to make or during an argument.",
      "questions": "Can you describe a time you took a few deep breaths to calm down when you were NERVOUS or UPSET about something and it worked? \n\nCan you describe a time you used your breath to calm down and PERFORM better in a sport or another performance activity?\n\nCan you describe a time you used your breath to calm down in the middle of an ARGUMENT? \n\nCan you describe a time you used your breath to calm down and collect your thoughts when you had an important DECISION to make?\n\nHave you ever done any other kind of meditation? If so, what was it like and how did it make you feel?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page ",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 9,
      "week": 25,
      "name": "Listen to Yourself (Your Wise Self)",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade9week25-birdsdoyouhearthat.gif",
      "summary": "Resilient people know there is a voice inside of them that knows what is best for them, so they tune in and listen to their wise self.",
      "readAloud": "Inside each of us, whether we know it or not, is a wise self. Some people call it trusting their gut. Others call it intuition, Inner Wisdom, or their higher self. Whatever you call it, we all have a voice inside of us that will help us make good decisions for ourselves, if we listen to it.\n\nThe problem is, we forget to listen to it. \n\nWe spend so much time racing from activity to activity we can’t hear ourselves think.  And when we do have down time, we distract ourselves with things like scrolling our social feeds, watching mindless videos, or playing video games to the point that there’s no space for the voice of our Inner Wisdom to get through.\n\nBut it doesn’t take much to hear our wise self. All we have to do is take away distractions, focus our attention on our inner world, breathe and listen inside.\n\nLet’s do it now: \n\n1.\tTake a deep breath. \n2.\tClose your eyes, if you’re comfortable doing so.\n3.\tFocus on the sound of your breath as you relax and breathe.\n4.\tAsk your wise self if it has anything to tell you.\n5.\tTake three more breaths as you listen inside.\n6.\tWhen you’re ready, open your eyes and focus on the outside world again.\n\nThat is how you tune into your Inner Wisdom whenever you have a decision to make or need to know how to take care of yourself. Just focus on your inner world, ask a question, and listen while you relax and breathe. \n\nThis mind-body skill of connecting your mind and body by focusing on your breath is a quick way to tune into your Inner Wisdom.",
      "questions": "How was it to do that short exercise? Did you get an answer you’d like to share?\n\nDo you make it a habit to tune into your wise self and trust your gut?\n\nWhat words do you use for trusting your gut or tuning into your Inner Wisdom?\n\nCan you give an example of a time that listening to your wise self helped you?\n\nCan you give an example of a time when you wished you had listened to your wise self?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Angry Birds © Rovio Entertainment Corporation"
    },
    {
      "grade": 9,
      "week": 26,
      "name": "Resist the Urge to Compare Yourself to Others",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade9week26-justlikeyouiwannabejustlikeyou.gif",
      "summary": "Resilient people know that everyone’s personality and life journey is unique, so they resist the urge to compare themselves to others.",
      "readAloud": "We live in a world where it’s hard to avoid seeing how fabulous everyone else’s life is. Our social feeds are full of shiny, happy people having the most fun and living their best lives. It’s easy to feel like you are the only person whose life is falling short of your expectations. \n\nBut comparing ourselves to others is a trap that almost always leads to feeling bad. It might feel good at first, but if you constantly judge yourself by how well you stack up to others, you will lose connection to the very traits that make you… you. You will forget about the strengths that make you feel good about yourself and the values that give your life meaning and a sense of purpose.\n\nOnly you know what you genuinely enjoy doing, what your natural talents are, what you don’t mind practicing in order to improve, what you care about, what kind of person you want to be, and what you want your future to look like. \n\nYou get to design your life around your hopes and dreams. And true happiness will come when you appreciate the unique gifts you have to offer the world and live your life authentically.\n\nSo, the next time you start to compare yourself to someone else, ask yourself instead if you are living in alignment with your own strengths, values and interests. And give yourself permission to do you. (And remember, just because someone’s life looks great in pictures, doesn’t mean they don’t have their own challenges.)",
      "questions": "Do you agree that comparing yourself to others usually ends up making you feel bad? Why or why not?\n\nDo you agree that true happiness comes when we appreciate the unique gifts we have to offer the world and live our lives authentically? Why or why not?\n\nWhat is one of your personal strengths? (something you enjoy doing and that you are good at)\n\nWhat is one of your personal interests? (something you enjoy doing even in your free time)\n\nWhat gives your life meaning? (something that makes you feel good about your life)",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Big Hero 6 © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 27,
      "name": "Channel Your Anger Into Positive Action",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade9week27-insideoutanger.gif",
      "summary": "Resilient people know that anger can be destructive or empowering, so they pause before reacting and then channel their anger into positive action.",
      "readAloud": "Anger can be one of the most destructive forces in the universe.\n\nIf we turn our anger outward and lash out at others, it can destroy relationships. If we turn our anger inward and beat ourselves up, it can destroy our self-esteem.\n\nIf we stuff our anger down and hold it in, it can destroy our sense of wellbeing and leave us feeling edgy and short-tempered.\n\nBut, anger is a natural human emotion—an appropriate emotional response we when we feel mistreated or witness an injustice.\n\nIt’s okay to be angry. It’s what you do with your anger that makes the difference between allowing anger to be destructive or empowering.\n\nLearning to channel your anger into positive action is a key resilience skill.\n\nThe next time you feel angry, take a pause. Remember, you cannot think when you are flooded with emotion. Then, when you have calmed down enough to be able to think constructively, identify what you need and what’s in your control, and take action to take care of yourself or address the situation in a way that feels positive.",
      "questions": "Adults tell kids all the time NOT to be angry, or they tell them to “get over it.” Do you think it’s okay to be angry sometimes? \n\nWhat do you usually do with anger? Do you let it out or hold it in, and does that feel destructive or empowering to you? \n\nHave you ever noticed that you can’t think straight when you are really angry? \n\nWhat can you do to pause when you are angry and calm down so you can think straight? (Some people count to ten. Some take a few deep breaths. Others walk away for a bit.) \n\nCan you describe a time when you channeled your anger into a positive action step to either take care of yourself or address the situation in a constructive way?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Inside Out ©”The Walt Disney Company."
    },
    {
      "grade": 9,
      "week": 28,
      "name": "Believe in Your Ability to Learn",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade9week28-homerkeeptrying.gif",
      "summary": "Resilient people know that mastering something takes practice. They don’t expect themselves to be perfect right from the start. Instead, they believe that with hard work they can learn whatever they put their minds to.",
      "readAloud": "Life is about learning. As babies, we have to learn to walk and talk and feed ourselves. Then we move on to more difficult things like reading, writing, solving math problems and playing sports.\n \nWhen these early accomplishments come easily to people, they are sometimes told that they are “smart.” But telling a kid they are smart can actually make them feel insecure.* Here\u0027s why:\n \nOften, when someone believes they are smart, they think everything should come easily to them. Then, when faced with something more difficult, they think something is wrong with them that it isn\u0027t coming easily. They are not used to working hard, asking for help, and persisting until they figure it out, so they get discouraged, worry that maybe they are not actually smart, and give up.\n \nOn the other hand, resilient people know that mastering something requires practice. They also know that if they work hard enough, they can figure it out. Instead of saying, “I’m not good at that,” they say, “I haven’t learned that yet,\" or “I’m working on that.”\n \nSo, believe in your ability to learn and there will be nothing you cannot accomplish.\n\n*(Also, if someone tells you that you are not smart, tell them they are wrong, or tell them that you just haven\u0027t learned that yet.)",
      "questions": "What is something you are proud of yourself for learning because it took a lot of work to get good at it?\n\nHow do you keep yourself from getting discouraged when you are having trouble learning something new? \n\nIf you want to learn something new or improve something, what can you do to help yourself?\n\nCan you name a time when you asked someone to help you learn or improve something?\n\nCan you understand why telling someone they are smart could actually make them feel insecure?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 29,
      "name": "Shift Your Mood by Shifting Your Focus",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade9week29-dancingdisneymakesyousmile.gif",
      "summary": "Resilient people know that what you focus on determines how you feel, so when they want to feel better, they shift their mood by shifting their focus.",
      "readAloud": "What you focus on determines how you feel.\n\nIf you’re focusing on a great song, a funny video, a person you love, an activity you enjoy, or a positive thought, you will feel good. \n\nOften, however, we get so caught up in the rush of daily life that we give our attention to whatever is in front of us and allow whatever that is to set our mood. We don’t like math, so we’re in a bad mood. Someone is rude to us, so we’re in a bad mood. We mess up in basketball, so we’re in a bad mood. \n\nBut when we remember that we can choose to focus on activities and thoughts that feel better, we can take our power back. \n\nWe can’t always focus on different activities. If we’re in math class, we can’t start blasting a favorite song. We can, however, choose to focus on thoughts that feel better than others. Instead of thinking, “I hate this. I’m no good at this. It’s no use. I’ll never get better.” You could think, “I really don’t like this, but it’s my last class of the day, and then I get to hang out with my friends.” \n\nThe thoughts we think are often the most powerful causes of our moods. With a little practice we can notice the thoughts that are causing us to feel bad and find a slightly better way of thinking about the situation. \n\nSo when you’re feeling down and want to feel better, shift your focus to an activity or a thought that feels better. \n\nRemember, though, negative emotions have important messages for us about what we need to feel better—about ourselves and our lives. So don’t ignore negative emotions or shift your focus away from them until you have identified what you need and how you can take care of yourself.",
      "questions": "How do you get yourself out of a bad mood when you want to? \n\nWhat is a song that always makes you feel good? \n\nCan you describe a time when you noticed you were focusing on negative thoughts and you found a better-feeling way to think about the situation? \n\nHave you ever noticed that sometimes you just want to stay in your bad mood, and when someone tries to cheer you up, it just makes you feel worse? Do you agree that it’s okay to stay in a bad mood sometimes?\n\nWhy do you think it’s important not to ignore negative emotions?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Lilo and Stitch ©”The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 30,
      "name": "Find Evidence that Supports your Desires",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade9week30-arnoldevidence.gif",
      "summary": "Resilient people know they must believe something is possible for it to come true, so they actively look for evidence that supports their desires.",
      "readAloud": "If you want something, but believe it is impossible, you will not even try to make it happen. But often, the beliefs that hold us back are not necessarily true. Someone told us something was true, we believed them, and now we have a lot of evidence that it is true.\n \nBut here’s the thing: We think we believe things because they are true, but it’s actually the opposite. They are true because we believe them.\n \nLet’s say that again. We think we believe things because they are true, but they are true because we believe them.\n \nWhen we believe something, we only see evidence that supports it. We do not see evidence that contradicts it.\n \nFor example, if you believe people are not trustworthy, you will look for evidence that you are right, and you will overlook evidence that you are wrong. If you believe you are not smart enough or talented enough to succeed at something, your list of weaknesses will be much, much longer than your list of strengths.\n \nThis is just how the human mind works—it is wired to look for the negative in order to protect us from danger.\n \nThe good news is, if we look for evidence that supports our desires we can almost always find it. Just remember, your mind is in the habit of looking for the negative. If you can break that habit, you will open up a whole new world of possibilities for yourself.",
      "questions": "Have you ever changed your mind and replaced a negative belief about yourself with a more positive one? If so, how did that work out for you?\n\nWhat is something you really want for yourself, and what is one reason why it just might be possible?\n\nCan you think of a belief that holds you back, and then think of one piece of evidence that something better might be true?\n\nCan you think of an example of how a belief only allows you to see evidence that it is true and makes you overlook evidence that it is not true?\n\nCan you describe a time when you believed something positive about yourself when others didn’t and it came true?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc."
    },
    {
      "grade": 9,
      "week": 31,
      "name": "Know how to Forgive and be Forgivable",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade9week31-bartforgiveme.gif",
      "summary": "Resilient people know that even in the best relationships people get their feelings hurt, so they know how to forgive and how to be forgivable. ",
      "readAloud": "Everyone gets their feelings hurt. Sometimes it’s because people are mean or disrespectful. Other times the person didn’t mean to hurt your feelings and doesn’t understand why you are upset. \n\nYou’ve probably had it happen to you too where someone said you hurt their feelings but you didn’t mean to. \n\nWe all make mistakes, and we all deserve second chances. That’s why it’s important to know how to forgive someone AND how to be forgivable. \n\nThe most important thing to do when you are forgiving someone is to identify what you need going forward in order to feel good about the relationship, and tell them. If you need kindness, honesty, or respect, tell them. If they are not willing to give you that, you might decide not to spend time with them anymore. But, you can still forgive them, because we forgive people for ourselves—so we don’t have to carry around negative emotions. \n\nThe most important thing to do to be forgivable is to understand what the person needs from you and make an effort to give it to them. If they need you to be trustworthy, for example—to not talk behind their back or share their secrets—then be trustworthy. And mean it when you say you’re sorry. ",
      "questions": "Do you agree that one of the main reasons we forgive people is so WE can feel better and not have to carry around negative emotions?\n\nWhen someone upsets you, what do they need to do for you to forgive them? \n\nWhen you upset someone, what do you do to get them to forgive you? \n\nHave you ever had your feelings hurt and the person said they didn’t mean to? What did you do to repair the relationship? \n\nHave you ever had someone say that you hurt their feelings, and you didn’t mean to? What did you do to repair the relationship?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 32,
      "name": "Harness the Power of the Mind-Body Connection",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade9week32-homerrelaxing.gif",
      "summary": "Resilient people know their minds have the power to make their bodies feel better, and their bodies have the power to make their minds feel better.",
      "readAloud": "We all know that our minds and bodies are connected. When our minds are worried, our bodies get tummy aches. When our minds are angry, our bodies get tense muscles. When our minds are sad, our bodies produce tears.\n\nWe might not realize that we can use our minds to make our bodies feel better, and use our bodies to make our minds feel better.\n\nWhen we notice that our mind is upset about something, we can do something physical like taking some deep breaths, or going for a walk, or shooting some hoops and we will feel better mentally.\n\nAnd when we notice we feel stress in our bodies, we can imagine in our minds relaxing our bodies and we will feel better physically.\n\nLet\u0027s do it right now:\n\nTake three slow, deep breaths and imagine the muscles in your body relaxing and calming down a little bit.\n\n(Take 3 breaths.)\n\nWhen your mind relaxes, your body relaxes too–and vice versa–because they are connected.\n\nSo pay attention to what\u0027s going on in your mind and body and use the mind-body connection to feel better mentally and physically.   ",
      "questions": "How does anger show up in your body? What about excitement or happiness? \n\nCan you describe a time when you were upset about something and you felt better after doing something physical? \n\nHow about the opposite: Can you describe a time when you made yourself feel better physically by focusing your mind on something that felt better? \n\nHow does your mental state affect you in sports? Do you notice a difference in how your attitude affects how well you play? \n\nFocusing your mind on the sound and feel of your breath is the quickest way to connect your mind to your body. Can you describe a time when you used your breath to relax mentally and physically?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 9,
      "week": 33,
      "name": "Seek Inspiration",
      "category": "Spirit",
      "subCategory": "",
      "image": "grade9week33-seekinspiration.gif",
      "summary": "Resilient people know that what they focus on determines how they feel, so they seek inspiration to feel and be their best.",
      "readAloud": "Lately it feels like all anyone can talk about is bad news. Everywhere we turn scary headlines shout for our attention. There’s so much to be worried about, and if you watch the news, so little to be hopeful about.\n\nBut the world is full of wonderful things too. We just have to make a point to notice them.\n\nFlowers bloom. Birds sing. The stars in the night sky take our breath away… if we notice them.\n\nAnd nature is not the only thing that offers daily inspiration. Strangers are kind. Families are loving. Friends are supportive. Maybe not always, but goodness is there… if we notice it.\n\nIn this world where so many loud and seemingly urgent things compete for our attention, we have to be choosy about what we focus on. It’s not easy to pull our eyes away from the latest gossip or celebrity meltdown, but if we seek inspiration, we will find it.\n\nIf we choose to focus on things that inspire us, we can believe in a better world, be part of the solution, and be our best selves.",
      "questions": "Do you agree that most of the news is negative, and why do you think that is?\n\nDoes more bad news than you would like seep into your daily life, and if so, how do you hear about it? (social media, television, people talking about it)\n\nAre you able to avoid bad news, and if so, how do you do that?\n\nAre you good at noticing inspiring things, and if so, why is that?\n\nWhere do you look for inspiration?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 9,
      "week": 34,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade9week34-nemo.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. This year we have talked about: \n\n* Finding your “flow”\n* Making sure your expectations bring out the best in you\n* Talking about your mental health\n* Visualizing success\n* Having good-feeling talk habits\n* Being open to learning and changing your mind\n* Being coachable and welcoming feedback\n* Being mindful of your body\n* Practicing tolerating discomfort\n* Listening instead of defending yourself\n* Resisting the urge to compare yourself to others\n\nThese are just a few of the resilience skills we have covered this year. You were already resilient, and now you have even more skills to choose from when you come to a bump in the road.",
      "questions": "Can you describe a way you have been resilient this year?\n\nAre there any skills from this Resilience Reminders™ program that you learned or tried that worked for you?\n\nWhat words do you use to define resilience? \n\nDo you think talking about these types of things is helpful for students and families? Why or why not?\n\nDo you believe in reaching out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Finding Nemo © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 1,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade10week1-bartbounce.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. \n\nThe other good news is you are already resilient! You have probably picked up quite a few resilience skills already in life. For example, if you…\n\n* Can name your personal strengths\n* Have good-feeling relationships\n* Reach out for support when you need it\n* Take care of yourself–physically, mentally, emotionally and spiritually\n* Learn from your losses\n* Use your breath to calm down and focus\n* Trust your gut\n\nIf you do any of these things, you are already resilient. \n\nThese Resilience Reminders™ will add more skills to your list and remind you of helpful things you already know.",
      "questions": "How do you define resilience? What words do you use? \n\nHow do you demonstrate resilience? Which skills do you use? \n\nWhat disappointment, challenge or failure are you most proud of yourself for overcoming, and what skills did you use to feel better?\n\nWhat is one way you take care of yourself–physically, mentally, emotionally or spiritually?\n\nDo you agree that it demonstrates resilience to reach out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 2,
      "name": "Focus on What’s in Your Control",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade10week2-simpsonsundercontrol.gif",
      "summary": "Resilient people don\u0027t waste time focusing on things they cannot control. When faced with an obstacle, they look for something within their control to move forward and feel better.",
      "readAloud": "When you are upset about something, it’s easy to think something outside of you needs to change in order for you to feel better. Your parents need to be less strict. Your friends need to be nicer. Your coach needs to play you more.\n\nBut other people’s behavior is out of your control. You can ask them to be different, but their behavior is out of your control. So are many other aspects of your life.\n\nOn the other hand, you can always find an action step within your control that will allow you to feel better—even if you cannot change the other person\u0027s behavior or the situation. This action step might not fix the situation, but it will put you back in control of your life, which will feel empowering and get the ball moving in the right direction.\n\nSo, when faced with a disappointment or obstacle, ask yourself this question:\n\n“What is an action step I can take—something within my control—to feel better?”\n\nLet’s say, for example, your coach isn’t playing you as much as you’d like. Focusing on how mad you are will feel bad.\n\nBut if you identify an action step—like asking your coach what you need to do to get more playing time, or putting in extra practice on your own, or reading about how the athletes you admire got so good—you will feel more in control of your own life and you will be moving toward your goal of playing more.  \n\nThere is always something you can do to take care of yourself and feel better, so get in the habit of focusing on what you can control when life is feeling challenging",
      "questions": "Can you describe a time when life didn\u0027t go your way, but instead of getting discouraged you took an action step to move forward?\n\nCan you describe a time when a person would not do what you wanted, but you took an action step to take care of yourself anyway?\n\nWhat is something you can always do to feel better even when you cannot control a frustrating situation?\n\nWhat do you say to yourself when life doesn\u0027t go your way and you don\u0027t want to give up on something you care about? \n\nIs there anything you are upset about right now that you could decide either to stay angry about or take an action step to feel better about? What could you do to make yourself feel better?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 3,
      "name": "Listen for Understanding, Not Winning",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade10week3-arnoldlisten.gif",
      "summary": "Resilient people listen in a way that allows people to feel heard and understood, even when they disagree.",
      "readAloud": "A lot of times, people don’t really listen. They wait for their chance to talk. \n\nInstead of focusing on what the other person is saying, they concentrate on what they want to say while waiting until it’s their turn to speak. \n\nBut this doesn’t feel good to people, especially during a disagreement.\n \nIt is possible to listen to understand someone without “losing” the argument. \n\nHow do you do it? You focus on getting information instead of on giving information. \n\nYou listen with the goal of being able to repeat back to them what you heard them say. You say something like, “Here’s what I heard you say. Is this right?” \n\nThen, you give them a chance to explain further if you’re missing something.\n \nAfter that, you can make your own points, but at least you will understand what they are saying. \n\nWhen you listen to understand, you don’t lose. You learn. You gain not only information but also a stronger connection to another human being because you respected them enough to actually listen to what they had to say.  ",
      "questions": "How do you show someone that you are really listening to them? \n\nCan you describe a time when you disagreed with someone but listened in a way that made them feel heard and understood even though you disagreed? How did you do that?\n\nCan you describe a time when someone disagreed with you but still made you feel heard and understood? How did they do that?\n\nCan you describe a time when you disagreed with someone but listened to what they had to say and changed your mind? \n\nCan you describe a time when you disagreed with someone but they listened to you and changed their mind? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc."
    },
    {
      "grade": 10,
      "week": 4,
      "name": "Get Help When You Need It",
      "category": "Needs/Values",
      "subCategory": "Interpersonal Skills",
      "image": "grade10week4-bartgethelp.gif",
      "summary": "Resilient people know that asking for help when they need it is a strength not a weakness so they don’t hesitate to reach out for support when faced with a challenging situation.",
      "readAloud": "Many people think that asking for help is a sign of weakness. They think they should be able to handle their problems on their own. They don’t want to seem needy, so they suffer in silence pretending they are fine when they are actually not okay and don’t know what to do.\n \nBut everyone needs help sometimes. Disappointments, setbacks, failures, and obstacles that feel overwhelming are a part of life. No one gets to skate through life without facing hardship. And asking for help is one of the most courageous, smart things you can do.\n \nYes, it feels vulnerable to ask for help, but most people are happy to support someone in need. It feels good to help someone. It gives meaning to our lives to have a positive impact in other people’s lives.\n \nSo the next time you feel upset and alone, reach out and allow someone to support you. You will both feel better if you do.",
      "questions": "Many people have a hard time asking for help. Where do you think people learn that they should handle their problems on their own? \n\nCan you describe a time when you helped someone when they needed it, and how did it feel to help them? \n\nCan you describe a time when you reached out for support when you needed it, and how did it feel to allow someone to help you? \n\nCan you think of a time when you did not ask for help when you needed it but now wish you had? \n\nCan you think of anything your school could do to support students in being more supportive of each other, or do you think that up to individual students?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 5,
      "name": "Flip the Script on Failure and Talk About What You Learned",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade10week5-southparkflip.gif",
      "summary": "Resilient people don’t beat themselves up when they fail. They look for lessons and talk about what they learned.",
      "readAloud": "Most people don’t realize how much the words they say affect how they feel and how they act. But consider how the following statements make you feel:\n\n“We lost. I played terribly. I’m sure the coach thinks I’m horrible and will never play me again. I’m no good at this sport. I don’t even know why I keep playing.” \n\nThis obviously makes you feel defeated. \n\nNow consider this: \n\n“We lost. I didn’t play my best. I see now that my blocking could be better. I’m going to work extra hard on that this week, so I’m better in the next game.” \n\nThis obviously feels better. It gives you—and everyone else who hears it—hope, and lets them know you are committed to getting better. \n\nSuccessful people know that failure is only failure if you fail to learn from it, and by learning the lessons in failures they transform them into stepping stones to success.\n\nMichael Jordan, maybe the greatest basketball player of all time, was cut from his high school basketball team! What did he learn? That he wanted to keep playing and that failure is a step on the journey to success.\n\n“I have missed more than 9000 shots in my career. I have lost almost 300 games. On 26 occasions I have been entrusted to take the game winning shot, and I missed. I have failed over and over and over again in my life. And that is why I succeed.” ",
      "questions": "Can you describe a time when you failed at something and learned from it? \n\nCan you think of something positive in your life that could not have happened if you hadn’t failed at something first? \n\nHow do you talk about your failures? Do you beat yourself up or talk about what you learned? \n\nWhat do you think it means about you if you fail? \n\nWhat is something you could say to yourself next time you fail?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. South Park © Comedy Partners"
    },
    {
      "grade": 10,
      "week": 6,
      "name": "Check in With Trusted Advisors When Making Decisions",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade10week6-giphy.gif",
      "summary": "Resilient people know that they don’t know what they don’t know, so they check in with trusted advisors when making decisions.",
      "readAloud": "Being a good decision maker is one of the most important skills you can develop.\n\nThe problem is when you’re a kid you are surrounded by people who have opinions about your life–both adults and peers–but not everyone has your best interest at heart. \n\nMany of them don’t even know what your personal values and goals are, so how could they possibly know what’s best for you?\n\nOn top of all that, when you are a kid there are a lot of things you just don’t know… yet. This is not an insult. It just means you haven’t learned or experienced certain things yet, so you might not have all the information you need to make a good decision.\n\nThe truth is… we don’t know what we don’t know. \n\nThe greatest leaders understand this, so they surround themselves with trusted advisors. They also stay open to new information and are willing to change their minds if new information leads to that.\n\nThe best way to become a good decision maker is to have at least one person you can talk to about your values and goals and whose opinion you trust. Then, you can check in with them when you have a decision to make, and they can help you make sure the decision aligns with your values and goals, point out what you don’t know, and give you any additional information you need. ",
      "questions": "Do you have a trusted advisor (or more than one) in your life? (Someone you can talk to about anything and whose opinion you trust?)\n\nHow would you go about finding a trusted advisor? Where would you look? Who might you ask?\n\nCan you name one goal you have for yourself?\n\nCan you name one of your values–something that matters to you and that helps you make decisions? (It could be something like doing well in school, being a good athlete, being a kind person, or challenging yourself.)\n\nCan you describe a time when you didn’t know what you didn’t know but someone gave you information that helped you make a good decision?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 10,
      "week": 7,
      "name": "Listen to Messages From Your Body",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade10week7-piggydance.gif",
      "summary": "Resilient people know their bodies have important messages about safety and self-care, so they tune into and take care of their bodies.",
      "readAloud": "Safety and self-care are two ways our bodies can help us be resilient.\n\nAs human beings, we can sense danger in our bodies even before our minds are aware of it. We can also feel whether people feel safe and trustworthy or whether they feel threatening and dangerous. \n\nKids are often made to ignore those feelings and forced to hang out with people and in situations that don’t feel good to them, but the messages are there if they tune into them. Our bodies are always trying to keep us safe. \n\nOur bodies are also trying to keep us healthy. They will tell us how to take care of ourselves, if we listen. And, because our minds and bodies are connected, if we take care of our bodies, we will also feel better mentally and emotionally. \n\nChildren typically don’t have much control over what goes into their bodies, whether the food they eat is healthy or not, how much exercise they get, how much sleep they get, how much stress they feel. But they can listen to what their bodies need and take as much responsibility as possible for taking care of themselves.\n\nChildren who are not taught to pay attention to their bodies grow into adults who don’t take care of themselves, who ignore messages from their bodies, and who do not feel their best—physically, mentally or emotionally. But kids who learn to listen to the Inner Wisdom their bodies have to share have a super power that will serve them for their whole lives.",
      "questions": "When you are stressed, nervous, or upset about something, where do you feel that in your body? \n\nCan you describe a time when you could feel in your body whether someone felt like a good, safe, friendly, trustworthy person or not? \n\nWhat do you do to take care of your body? \n\nWhat is one thing you could do to take better care of yourself? \n\nLet\u0027s ask our bodies what they need right now...\nClose your eyes, if you\u0027re comfortable doing that, take a deep breath, and inside your own head, ask your body what it needs right now...\n(Give them a few seconds to tune in.)\nNow, who wants to tell us what their body said?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Sing © Universal City Studios LLC"
    },
    {
      "grade": 10,
      "week": 8,
      "name": "Allow Negative Emotions to Tell You What You Need",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade10week8-alladinwhatdoyouneed.gif",
      "summary": "Resilient people use negative emotions to take care of themselves. When they notice they are upset, they identify what they need and take an action step.",
      "readAloud": "Disappointments, setbacks, challenges, failure, and even grief are a part of life. No one, no matter how good their life looks from the outside, can avoid emotionally difficult times.\n \nResilient people accept the fact that life gets hard sometimes. They also accept that negative emotions are not “bad.” They feel bad, but negative emotions are natural responses to certain experiences. In fact, negative emotions can be used for good, if we use them to take care of ourselves. \n\nHow do you use negative emotions to take care of yourself? You allow them to tell you what you need to feel better.\n \nWhen you notice you are upset, ask yourself, \"What do I need to feel better?\" \n\nThen, take an action step to take care of yourself.\n\nFor example, if you’re sad and you need a hug, you can reach out to a supportive friend or loved one. \n \nEven when you cannot change the situation, there will be action steps you can take to take care of yourself.",
      "questions": "Last time you were ANGRY, what did you need, and what was an action step you took or could have taken to feel better?\n\nLast time you were SAD, what did you need, and what was an action step you took or could have taken to feel better?\n\nLast time you were OVERWHELMED, what did you need, and what was an action step you took or could have taken to feel better?\n\nLast time you were WORRIED, what did you need, and what was an action step you took or could have taken to feel better?\n\nLast time you were FRUSTRATED, what did you need, and what was an action step you took or could have taken to feel better?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Aladdin © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 9,
      "name": "Trust Your Gut",
      "category": "Inner Wisdom",
      "subCategory": "Spirit",
      "image": "grade10week9-chewytrust.gif",
      "summary": "Resilient people know they can feel in their bodies whether something is good or bad for them, so they trust their guts.",
      "readAloud": "We live in a world where many people believe thinking is a more valuable skill than feeling. After all, we spend decades in school learning how to think but very little, if any, time paying attention to our feelings.\n\nBut tuning into our gut feelings can be a superpower if you start paying attention.\n\nWe can feel in our bodies whether a decision is good for us or not good for us. We can also feel in our bodies whether a person is open, trustworthy, and has our best interest at heart, or if they do not.\n\nWhether you call this a gut feeling, intuition, inner voice, or higher power, we all have a source of wisdom inside of us that will guide us to make good decisions if we listen to it. \n\nOn the other hand, our thinking minds can get hijacked by negative thoughts and other people’s agendas, and this can cause us to ignore the warning bells going off in our bodies and make bad decisions. For example:\n\nWhen our mind thinks negative thoughts like, “I’m not good enough. I shouldn’t even try,” our Inner Wisdom says, “Believe in yourself. You can do this.”\n\nOr, when we’re trying to convince ourselves to go along with something we know is wrong, we tell ourselves things like, “I won’t get caught. It’s only once. It’ll probably be fine,” but our gut says: “Danger. Something doesn’t feel right here. Don’t do this.”\n\nOur Inner Wisdom protects us, reminds us of our best qualities, and pushes us to go for our dreams.\n\nSo the next time you have a decision to make, do a gut check and trust your Inner Wisdom.",
      "questions": "Can you describe a time when listening to a gut feeling protected you from making a mistake or putting yourself in danger? \n\nCan you describe a time when following your gut caused something really great to happen in your life? \n\nSome people call it a gut feeling. Others call it intuition, their inner voice, or what they know in their heart. Still others think it is the voice of God. What words do you use to describe the way you just know whether something is good or bad for you? \n\nPeople call it a gut feeling because they feel it in their gut. Where do you feel it when you just have a feeling you should or shouldn\u0027t do something? \n\nWhat situations do you think tuning in to your gut feelings would be helpful in? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 10,
      "name": "Have Healthy Self-Care Habits",
      "category": "Needs/Values",
      "subCategory": "",
      "image": "grade10week10-homersimpsoneating.gif",
      "summary": "Resilient people know how important it is to take care of themselves, especially when they are feeling stressed, so they have healthy self-care habits.",
      "readAloud": "When life gets hard and you feel overwhelmed, it’s important to know what actually works to help you feel better. If you don’t have a list of healthy things you can do to feel better, you can end up making unhealthy choices that make you feel worse.\n\nMaybe you eat junk food, for example, which might feel good in the moment, but ends up giving you a stomach ache.\n\nOr maybe you withdraw from your friends and family, which might save you the embarrassment of having an uncomfortable conversation, but ultimately leaves you feeling alone and unsupported.\n\nOr maybe you stay up too late playing video games or scrolling your social feeds, which distracts you from you problems in the moment, but leaves you feeling exhausted and unmotivated the next day.\n\nThe first step to creating healthy habits is noticing what your habits are now and asking yourself if they really make you feel better in the long run.\n\nSome habits are just healthier than others. Eating healthy food, getting exercise, getting enough sleep, and getting support from people who care about you when you are stressed—these are things we all can do to take care of ourselves.\n\nAnd maybe you have other healthy habits to add to your list. Maybe you like to meditate or pray or journal or sit quietly in nature or gaze at the stars or get a hug from someone who loves you.\n\nAnything that makes you feel good without a negative consequence can go on your Healthy Self-Care Habits list. What’s important is that you have your own list, and you make a habit of doing them.",
      "questions": "What is one Healthy Self-Care Habit you do regularly, whether you are stressed or not?\n\nWhat is a healthy way you take care of yourself when you are stressed or upset? \n\nWhat is one not-so-great habit you might want to improve? \n\nWhat is one not-so-great habit you see other people do that might help in the moment but doesn’t help in the long run? \n\nWhat is one healthy habit you see other people do that you might want to try?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 11,
      "name": "Play to Your Strengths and Strengthen Your Weaknesses",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade10week11-spongebobstrengths.gif",
      "summary": "Resilient people spend as much time as possible using their strengths. They also acknowledge their weaknesses and take actions to either improve them or lessen their impact.",
      "readAloud": "The more time you spend using your strengths, the happier and more empowered you will feel. That being said, we all have to do things that are out of our comfort zones sometimes. That’s just the way life is.\n\nIt can feel embarrassing to admit that you are not as strong as you would like to be in something. But no one is great at everything. And acknowledging your weaknesses allows you to get help to either improve them or find some other way to lessen the negative impact they have in your life.\n\nSome weaknesses can be improved with training and practice. Others can be offset by working with someone who complements your skills. Successful people surround themselves with people whose strengths make up for their weaknesses.\n\nReminding yourself that everyone has strengths and weaknesses will make you feel better about your own shortcomings. So will reminding yourself what your strengths are when you are acknowledging a weakness. For example, maybe you are not strong in math but you are great at science. Or you are not very good at drawing but you are great at basketball. Or you are not great at speaking in public but you are great at making friends.\n\nBeing honest with yourself (and others) about your strengths and weaknesses is far more beneficial than pretending to be good at something you are not, and it allows you to play to your strengths and shore up your weaknesses.",
      "questions": "What is one of your strengths and what is one of your weaknesses? \n\nCan you describe a time when you took action to improve a weakness? \n\nCan you describe a way that your weaknesses are offset by working with people whose strengths complement your weaknesses? (Maybe you are on a team with people who have different strengths than you do.) \n\nWhat is an example of how you “play to your strengths” (which means spending time using your own strengths)? \n\nWhat is one weakness you would like to improve or offset?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 10,
      "week": 12,
      "name": "Practice Gratitude",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade10week12-pandagratitude.gif",
      "summary": "Resilient people know that gratitude improves not only your mental health but also your physical health, so they make it a practice to be grateful.",
      "readAloud": "It makes sense that gratitude is good for your mental health, but studies show that gratitude improves our physical health too! Gratitude also improves relationships.\n\nThanksgiving is all about gratitude. But we can forget to look for things to be grateful for during the rest of the year. \n\nIt’s easy to get so caught up in the stresses of daily life that we focus on what’s going wrong in our lives instead of looking for what’s good.   \n\nThere are always things to be grateful for in our lives. We just have to make an effort to notice them. \n\nThis is why some people practice gratitude the same way they practice a sport. They set aside time every day or every week to think about what they are grateful for. Some people do it every morning. Others do it when they go to bed. Others do it when they attend spiritual services.\n\nHowever you do it, the more you practice gratitude, the better you will feel - and the healthier you will be.",
      "questions": "Who is a person you are grateful for?\n\nWhat is an experience you are grateful for?\n\nWhat is a quality about yourself you are grateful for?\n\nCan you think of anything you are grateful for that came from something that was not so great?\n\nHow can you remember to practice gratitude more often in your daily life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Kung Fu Panda © DREAMWORKS ANIMATION L.L.C."
    },
    {
      "grade": 10,
      "week": 13,
      "name": "Stop Trying to Control Everything and Be Open to What Life is Offering You",
      "category": "Spirit",
      "subCategory": "Beliefs",
      "image": "grade10week13-bartsurprise.gif",
      "summary": "Resilient people know that much of life is out of their control, so they stay open to what life is offering and expect pleasant surprises.",
      "readAloud": "One of the most frustrating aspects of life for many people is that so much of it is out of our control. We want to believe that if we just work hard enough, do the right things, be a good person, and try, try, try, we will get what we want. \n\nBut life doesn’t always work that way. In fact, sometimes it can feel like life is totally out of control no matter how hard we’re trying to manage things.\n\nIt’s natural to feel disappointed when we don’t get what we want.\n\nBut, if we remind ourselves that sometimes our lives take unexpected turns that end up being for the better, we can open to the possibility that not getting everything we think we want might actually be okay. \n\nIn fact, many of the best things in life just happen to us without us making them happen. \n* A new family moves in next door, and the daughter becomes your best friend.\n* A teacher recommends you for a scholarship you didn’t even know about, and that allows you to go to the college of your dreams.\n* You get cut from the soccer team, so you decide to run track, and the team goes on to win the state championship.\n\nWe all have examples of life taking us places we didn’t choose but that turned out to be full of blessings.\n\nSo, the next time you feel disappointed that life isn’t going as you planned, ask yourself: “What if this is actually better?”\n\nAnd notice how this powerful question opens you up to being pleasantly surprised.",
      "questions": "Can you think of a time you were pleasantly surprised?\n\nCan you think of something great that happened in your life that you didn’t make happen - it just happened?\n\nCan you think of a time when you didn’t get what you thought you wanted but you ended up being glad it turned out that way?\n\nDo you consider yourself open and flexible, or do you really like to control things?\n\nThink of the best things about your life… how many of them to did YOU make happen?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 14,
      "name": "Know Your \"Mood Shifters\" AND Know When to Use Them",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade10week14-rollingpanda.gif",
      "summary": "Resilient people know how to shift themselves into a better mood, but they only use “mood shifters” when they are ready to feel better.",
      "readAloud": "Everyone experiences bad moods. Often, it’s because something frustrating or upsetting happens. But sometimes it feels like we just get cranky and we don’t know why.\n\nIt’s helpful to have a list in your head of things you can do to get yourself out of a bad mood. Things like: going for a walk, watching funny videos, listening to your favorite music, shooting hoops, and talking to a good friend can lift your spirits.\n\nIt’s also important not to try to get out of a bad mood until you are ready.\n \nHave you ever noticed how when someone tells you to \"Cheer Up!\" when you are not ready to feel better, it just makes you feel worse?\n\nThat\u0027s because negative emotions have important messages for us, and if we ignore them or try to push them away before we understand what they’re saying, they just get bigger. For example:\n\n* If you’re angry because someone was mean to you, you want to make sure you think about how you can take care of yourself in that relationship before you shift into a better mood.\n* If you’re upset at yourself for not doing well on a test, you want to figure out how you can study better next time before you distract yourself.\n\nIf you’re not sure why you’re feeling cranky, ask yourself: \"Why am I upset, and what do I need to feel better?\" This helps you use your emotions to take care of yourself, which is what they’re designed for.\n \nAfter that, you will be ready to feel better, and you can use whatever works for you to shift into a better mood.",
      "questions": "Why do you think it is annoying when someone tries to make you feel better when you are not ready to feel better? \n\nCan you describe a time when you tried to ignore a negative emotion or pretend like it wasn\u0027t important and it just got bigger? \n\nCan you describe a time when you were upset about something and you took as much time as you needed to figure out exactly why you were upset and what you needed to feel better? \n\nCan you name three \"mood shifters\" - things that always make you feel better when you are ready to?\n\nUsing one of your personal strengths can be a good \"mood shifter.\" For example, if you\u0027re good at drawing, singing or shooting hoops, it can lift your spirits to do those things. What is one of your personal strengths that you can use as a \"mood shifter?\"",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company."
    },
    {
      "grade": 10,
      "week": 15,
      "name": "Have Beliefs That Comfort You, Guide You, and Give Your Life Meaning",
      "category": "Spirit",
      "subCategory": "Beliefs",
      "image": "grade10week15-poohbeliefs.gif",
      "summary": "Resilient people know that our beliefs shape our lives, so they have beliefs that comfort them, guide them, and give their lives meaning.",
      "readAloud": "This time of year reminds us that spiritual beliefs are a powerful source of comfort and guidance for many people. Some beliefs give meaning to our lives. Some beliefs inspire us to be kind to each other. Some beliefs comfort us around our fears, especially around our fear of death. And many beliefs connect us to supportive communities and nurture a sense of belonging.\n \nRegardless of what you believe, choosing to live your life in alignment with beliefs that give you purpose, connect you to the most loving parts of yourself, and nurture optimism for your future is a choice worth making.\n \nMany people don’t realize that they can choose what to believe. They think that because they already believe something, it must be true. But people believe many different things, some of which are contradictory. Yet somehow, people have evidence for contradictory beliefs. \n\nSo, if you realize you have a belief that does not make you feel very good, look for evidence of something better. If you look hard enough, you will probably find it.\n \nWhatever you believe, we hope it brings you peace, joy and love this holiday season. ",
      "questions": "Do you have a belief that inspires you to be the best person you can be?\n\nDo you have a belief that motivates you to be kind to others?\n\nDo you have a belief that comforts you around any of your fears?\n\nDo you have a belief that gives your life meaning or a sense of purpose?\n\nDo you have a belief that makes you feel optimistic about your future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Winnie the Pooh © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 16,
      "name": "Set Achievable Goals (Around Strengths and Values - New Years)",
      "category": "Strengths",
      "subCategory": "Values",
      "image": "grade10week16-snoopygoals.gif",
      "summary": "Resilient people believe goals should inspire them and make them feel good about themselves, so they set achievable goals around their strengths and values.",
      "readAloud": "New Year’s Resolutions often start out as inspiring but end up making us feel disappointed in ourselves if we fail to achieve them. Here are some tips for setting goals that will make you feel good about yourself:\n\nSet goals that are small enough that you can actually get them done. If you set goals that are too big, you’ll give up, feel bad about yourself, and be less likely to set future goals for yourself. On the other hand, if you set goals you can actually achieve, you will build positive momentum that allows you to achieve goal after goal after goal.\n\nSet goals around your strengths. The more time you spend using your strengths, the better you feel about yourself. And, if you set goals around things you actually enjoy doing and that come naturally to you, you are more likely to achieve your goals, which also makes you feel good about yourself. It’s a double-whammy of feeling good about yourself! \n\nSet goals around your values. Doing things that demonstrate your personal values also makes you feel good about yourself. If you value being a good athlete, set goals around your fitness. If you value being a good student, set goals around your academics. If you value being kind, set goals around making people feel good.\n\nFind people to support you in achieving your goals. Having people to support you and hold you accountable will make you much more likely to achieve your goals.",
      "questions": "What is one goal you have for yourself right now, and does it feel achievable? \n\nCan you think of a goal to set around one of your strengths or values? \n\nCan you think of someone who could support you in achieving one of your goals? \n\nWhat is a goal you successfully achieved in the past, and what factors contributed to you achieving them? \n\nIf you think about goals you did not achieve in the past, what factors prevented you from achieving them? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Snoopy © Peanuts Worldwide"
    },
    {
      "grade": 10,
      "week": 17,
      "name": "Use Your Strengths to Get Out of a Bad Mood",
      "category": "Strengths",
      "subCategory": "Emotional Intelligence",
      "image": "grade10week17-cutehappyfeet.gif",
      "summary": "Resilient people know it feels good to do something you’re good at, so they use their strengths to get themselves out of a bad mood.",
      "readAloud": "Everyone has a bad day sometimes. And sometimes it just feels like you wake up on the wrong side of the bed for no reason and can’t get out of a bad mood.\n \nOne thing to know about bad moods is that you can’t get out of them until you’re ready. Sometimes you just need to wallow in a bad mood for a bit, and that’s ok. We have to give our bad-feeling emotions enough space to get their message across. We have to listen to what they are telling us, so we can know how to take care of ourselves.\n \nWe have to ask ourselves, “What am I upset about? And what do I need to feel better?”\n \nWhen we do that, we might realize that we’re angry because someone was rude to us, and we need to tell that person they hurt our feelings, or we need to tell someone else about what happened, or we need to not spend time with that person until they apologize. Once we are clear about why we’re upset and what we need to feel better, we can do something to get in a better mood.\n \nOne way to feel better is to do something we are good at, because that will automatically feel good. If you’re good at painting, it will feel good to paint. If you’re good at playing the piano, it will shift you into a better mood to play a favorite song. If you’re good at singing, dancing, drawing, shooting hoops, or cooking, doing those activities will make you feel better.\n \nSo the next time you are ready to shift out of a bad mood, spend some time using one of your strengths and feel how good it feels to be good at something.",
      "questions": "Have you ever noticed that sometimes you want to stay in your bad mood? Why do you think that is?\n\nWhat do you do when you are ready to feel better? What works for you?\n\nWhat is one of your strengths that you could use to feel better because you enjoy doing it?\n\nWhat do you consider your top three strengths?\n\nIs there anything you’re really good at that you don’t enjoy that much so it wouldn’t help you get in a better mood?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page ",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Happy Feet © Warner Bros. Entertainment, Inc."
    },
    {
      "grade": 10,
      "week": 18,
      "name": "Remember the Iceberg When Trying to Resolve Conflict",
      "category": "Interpersonal Skills",
      "subCategory": "Needs/Values",
      "image": "grade10week18-avatar.gif",
      "summary": "Resilient people talk about what they need when trying to resolve conflict instead of talking about what the other person is doing wrong.",
      "readAloud": "When you are mad a someone it’s natural to want to tell them all the things they are doing wrong. “You are so rude!” “I can’t believe you would do this to me!” “You’re a terrible person!”\n \nBut telling someone how bad and wrong they are—even if it’s true—doesn’t usually resolve the conflict. It makes the other person start telling you how bad and wrong you are. So now you’re both yelling about all the horrible things you’ve done to each other.\n \nA way to change the conversation into one that is constructive is to picture an iceberg. What is above the surface of the water—the part of the iceberg we can see—are all of the things the other person is doing wrong.\n \nWhat’s below the surface—the part we can’t see—are what we NEED from the other person in order to feel better.\n \nIf we can talk about what we NEED (what\u0027s below the surface) instead of what they are doing wrong (what\u0027s above the surface) we will have a much better chance of resolving the conflict.\n \nSo, instead of saying to your friend, “You are a horrible person!”, you could say, “I need to be able to trust you not to tell my secrets.”\n \nInstead of saying to your parent, “You are such a nag!”, you could say, “I need you to let me handle this on my own.”\n \nInstead of saying to your sibling, “You are a complete jerk!”, you could say, “I need my privacy to be respected.”\n \nRemember the iceberg when trying to resolve conflict and you will have a constructive conversation.",
      "questions": "When you think of the things that make you mad, is there a common trigger? If so, what do you need in these situations?\n\nCan you think of a person you have frequent arguments with—a sibling, parent or teammate—and identify what you need from them that would make the relationship feel better?\n\nCan you describe a recent argument and how it would have gone differently if you can stated what you need?\n\nStating what you need instead of telling the person what they’re doing wrong is one strategy for resolving conflict. Can you think of another one?\n\nDo you think the iceberg is a helpful image for resolving conflict, why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Avatar © Nickelodeon (Paramount Global)"
    },
    {
      "grade": 10,
      "week": 19,
      "name": "Know Your Patterns",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade10week19-spongebobpattern.gif",
      "summary": "Resilient people pay attention to how their own minds work. They notice their patterns of behavior and create habits that empower them.",
      "readAloud": "Many people don’t realize they can choose how to respond to things that upset them. When they feel bad, they act out without realizing they could respond differently. And when they do this over and over, they get stuck in disempowered patterns of behavior instead of taking control and creating new, more empowered patterns of behavior.  \n\nThe thing that sets humans apart from other animals is that we can observe our own minds. We can think about how we think, feel and behave. And we can identify patterns of how we respond to things that make us uncomfortable and choose different patterns if we want to.\n\nInstead of shutting down when we don\u0027t know how to do something, we can ask for help.\n\nInstead of lashing out when we feel angry, we can take a deep breath and ask for what we need.\n\nInstead of blaming others when we make mistakes, we can look for lessons and try again.\n\nSo, start paying attention to your patterns, and when you notice one that doesn\u0027t feel so great, create a new pattern that allows you to be the best version of yourself.",
      "questions": "What is your pattern when you have to learn something new? Do you give yourself a pep talk and bring a positive attitude, or do you worry and get discouraged quickly? \n\nWhat is your pattern when you are stressed or challenged? Do you slow down and take care of yourself, or do you get angry and lash out at people? \n\nWhat is your pattern when you feel sad or overwhelmed? Do you reach out for support, or do you withdraw and keep it to yourself? \n\nWhat is your pattern when someone gives you feedback? Do you appreciate the opportunity to improve, or do you get defensive and stop listening? \n\nCan you think of a pattern you would like to change or one you have already changed, and how would/did that make you feel?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 10,
      "week": 20,
      "name": "Practice Box Breathing When You’re Feeling Anxious",
      "category": "Mind-Body Skills",
      "subCategory": "Emotional Intelligence",
      "image": "grade10week20-boxbreathe.gif",
      "summary": "Resilient people know that everyone has moments of anxiety, so they have different ways of using their breath to calm down when they need to. Box Breathing is one of those ways.",
      "readAloud": "People talk a lot about anxiety these days, and it is true that more young people are seeking help for high levels of anxiety than ever before. Seeking professional help when your anxiety feels overwhelming is a courageous thing to do, and mental health professionals have many tools and techniques for helping people cope with anxiety and other intense emotions.\n\nThat being said, some anxiety is a part of life. Everyone has moments of feeling anxious and even overwhelmed, so it’s important to have tools for calming down so you can think straight and figure out how to take care of yourself.\n\nThat’s the thing about anxiety–it makes it difficult to think constructively. Whenever we feel intense negative emotions, it becomes difficult to think well. This is because when we feel threatened the “fight or flight” response narrows our thinking to just a few options: “Fight!” and “Run!” being two of the big ones.\n\nHowever, most of the daily situations that trigger anxiety do not require fighting or running. They do require thinking. So, we need to be able to calm down in order to think about what we need (to feel better) and what we can do to take care of ourselves.\n\nBox breathing, which is a mind-body skill (focusing on your breath to relax your mind and body), can help. Let’s try it now.\n\nFirst, choose a number that you will count to as you breathe in and out and hold your breath between each inhale and exhale. We’ll use the number 4 right now, and we’ll practice Box Breathing for four rounds.\n\n1.\tBreathe IN for the count of four.\n2.\tHold for the count of four.\n3.\tBreathe OUT for the count of four.\n4.\tHold for the count of four.\n\n(Mentors, do that four times.)\n\nSo, that’s Box Breathing. Next time you feel anxious, give it a try and notice how it settles you down, stops a negative thought spiral, and makes you better able to find solutions to whatever is upsetting you.",
      "questions": "What was your experience with Box Breathing like, and do you think it could be helpful for you?\n\nHave you ever used Box Breathing or any other kind of breathing technique to calm down, and do you find it helpful?\n\nHave you ever noticed that you can’t think very well when you’re upset about something?\n\nWhat do you think is the difference between the anxiety that is a part of daily life and anxiety that requires professional help? (If you have a level of anxiety you are unsure about, ask an adult–the school counselor, an advisor, or a teacher.)\n\nWho knows how to find access to mental health resources? (There is a link to mental health resources at the bottom of each Resilience Reminders™lesson.)",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 10,
      "week": 21,
      "name": "Don’t Have to be Right",
      "category": "Beliefs",
      "subCategory": "Interpersonal Skills",
      "image": "grade10week21-evenwhemI\u0027mwrong.gif",
      "summary": "Resilient people know that they don’t know everything and they’re not always right, so they take in new information and are open to changing their minds.",
      "readAloud": "No one is always right. There is never just one way to look at a situation. People who are unwilling to consider other people’s points of view are not fun to talk to. They are not even fun to be around.\n \nLife is a process of learning and growing, taking in new information, and adapting to changing situations. Being able to think through things and be flexible allows you to find creative solutions and have better-feeling relationships.\n \nWe have all experienced how bad it feels to have an argument with someone who just has to be right and doesn’t even try to understand our side of things. And we’ve all experienced how great it feels when someone really listens to what we have to say and allows us to change their mind a little.\n \nThis is how we grow as individuals and how we strengthen our relationships.\n \nBeing willing to change your mind is a sign of intelligence and curiosity and empathy. So the next time you have the opportunity to defend your point of view, do it. But also, allow the other person to do the same and do your best to understand what they are saying. It doesn’t have to be a “win/lose” situation.",
      "questions": "Can you describe a time when you listened to someone else’s perspective and changed your mind about something?\n\nCan you describe a time when you changed your mind in an argument and actually apologized for something?\n\nPolitics, religion, and certain lifestyle choices are topics people can be inflexible about. What other topics do people have a hard time changing their minds about or even talking about?\n\nDo you agree that it is a good thing to be willing to change your mind? Why or why not?\n\nWhat can someone do in a discussion that makes you more likely to hear their perspective?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Meet the Robinsons © The Walt Disney Company ."
    },
    {
      "grade": 10,
      "week": 22,
      "name": "Have a Vision for Your Future",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade10week22-yodafuture.gif",
      "summary": "Resilient people have hopes and dreams for themselves, and they identify the steps required to make those dreams come true",
      "readAloud": "Our education system doesn’t always do the best job of helping kids connect the dots between the dreams they have for themselves and the things they need to accomplish in order to make those dreams happen.\n \nFew kids enjoy doing homework and studying for tests, but the ones who want to be scientists, astronauts, or doctors study hard because they know they have to get good grades in order to have a chance of making their dreams come true.\n \nFew kids enjoy getting up before the sunrise and running, lifting, or playing a sport until their muscles give out, but the ones who want to be competitive athletes push themselves to their limits because they know their chances of success depend on it.\n \nEven if you don’t know exactly what you want to be when you grow up, when you identify what you want for yourself in the future you find reasons to be your best self today.\n \nIf you want to have a nice lifestyle, you probably want to make the most of your education to get a good job.\n \nIf you want to have lots of friends and loved ones, you probably want to do your part to have good relationships with people.\n \nIt’s okay if you don’t have a clear vision for your future yet, but thinking about it will keep you motivated to be your best self on the path to wherever it is you want to go.\n",
      "questions": "Do you have a vision for your future? If so, what is it and what steps are required to get there?\n\nDo you have a career you might be interested in? If so, can you name one step on the path to that career?\n\nWhat is the most important thing you can do right now to give yourself the best opportunities to have the future of your dreams?\n\nWhere do you work hard in life—in academics, athletics, hobbies, relationships—and what motivates you to work hard?\n\nWhat do you think happens to people when they don’t have a vision for their future?\n",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page ",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 23,
      "name": "Welcome Feedback",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade10week23-homercanthear.gif",
      "summary": "Resilient people know that becoming good at something is a process of learning, trying, and sometimes failing. And, one of the best ways to improve is to get feedback from others.",
      "readAloud": "It can be hard to hear feedback. It can feel embarrassing when a coach, teacher, parent or friend points out how you could do something better.\n \nMany people take it as criticism and get upset when others try to give them feedback. They take it to mean they have done something wrong or are not as good as they should be. Some people think feedback means they have failed.\n \nBut if you shut down when someone is trying to help you, you miss valuable information that could improve your performance… and your life. Feedback helps you in sports, in academics, in relationships, and in your career.\n \nWhen you change the story in your mind from one where feedback means you are not good enough to one where it means you are learning and improving, you put yourself on the fast track to success.\n \nSuccessful people welcome feedback. They want to know what others think of how they are doing and what they could do better.\n \nSo the next time someone offers to give you feedback, resist the urge to take it as criticism and open to the possibility that it will help you become an even better version of yourself. ",
      "questions": "What goes through your head when someone tries to give you feedback? Do you take it as criticism and feel bad about yourself or welcome the idea of learning something? \n\nAre there some people who make it easier for you to hear their feedback and others who make feedback feel bad? Why is it easier to hear from some people? \n\nWhat areas of your life are you best at hearing feedback in, and what areas are more difficult for you? (sports, academics, relationships, family, other?)  \n\nHow do you think feedback can help you in relationships? \n\nAre you good at giving feedback yourself? What can you do to make it so people take your feedback constructively and not as criticism?  ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 24,
      "name": "Use Your Body to Feel Into Decisions",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade10week24-areyoufeelingit.gif",
      "summary": "Resilient people know they can feel in their bodies when something feels like a good decision or a bad decision, so they feel into their decisions.",
      "readAloud": "When you are a kid, lots of people have opinions about who you should be, how you should behave, and what you should do with your life. With so many people telling you what to do, it can be hard to know how to make decisions for yourself.\n\nThe good news is we all have a powerful decision-making tool with us all the time: our bodies. Our bodies will tell us if a person feels safe, if an activity will be good for us, and if a decision is what we really want to do, or if we are trying to please someone else.\n\nIf no one tells you how important your gut feelings are, you might not pay attention to them. But if you feel into your body, the information is there.\n\nOur thinking minds can get sidetracked by insecurities, limiting beliefs (like “I’m not good enough”) and other people’s desires for us. But our Inner Wisdom will speak to us through our bodies if we tune in and listen.\n\nSo the next time you have a decision to make, listen to what your body has to tell you.",
      "questions": "How does it feel in your body when you make a decision you are excited about?\n\nHow do you make decisions? Do you listen to your body? Do you think it through? Do you talk to other people? Do you do all of the above?\n\nMany people call the feeling in your body your “gut feeling.” What do you call it?\n\nCan you describe a time when you could feel in your body whether something was a good decision or not?\n\nDo you have any decisions to make in your life now, and if so, what is your body telling you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 10,
      "week": 25,
      "name": "Prioritize Play",
      "category": "Needs/Values",
      "subCategory": "Beliefs",
      "image": "grade10week25-bartprioritize.gif",
      "summary": "Resilient people know it’s important to balance work with restful and joyful activities, so they prioritize play in their lives.",
      "readAloud": "Life is busy and the list of things we have to get done in a given day can feel overwhelming. We have to do our homework. We have to go to football practice. We have to practice our instrument for 20 minutes. We have to do our community service hours. There never seems to be enough time in the day, so we stay up later and get less sleep.\n\nHowever, if we burn the candle at both ends, we will end up feeling exhausted, unmotivated and overwhelmed. We need down time–time to rest and recharge. And research now shows that young people need unstructured play time–time to have fun with their friends without expectations for achievement.\n\nBottom line: we need balance in our lives. Not only do we need to balance work and play, but we also need to balance having fun and getting enough rest, socializing and down time, screen time and human interaction, doing and being, and being distracted and being present. \n\nSo, how do we make our lives feel more balanced when we cannot add more time to the week? We have to shift our priorities. If you believe balance is important, start prioritizing play.",
      "questions": "On a scale of 0-10 how balanced does your life feel?\n\nDo you agree that we need balance to feel our best? Why or why not?\n\nWhat is one thing you could do to add more balance to your life?\n\nDo you agree with the list of things we need to balance: having fun and getting enough rest, socializing and down time, screen time and human interaction, doing and being, and being distracted and being present?\n\nIs the way you spend your time a good representation of your priorities, or do you spend time on things that are not your own priorities?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 26,
      "name": "Understand the Power of the Pause",
      "category": "Emotional Intelligence",
      "subCategory": "Interpersonal Skills",
      "image": "grade10week26-timeout.gif",
      "summary": "Resilient people know they cannot think well when they are flooded with emotion, so when they are upset, they press pause and calm down before they problem-solve. ",
      "readAloud": "An interesting thing happens when we feel really angry, overwhelmed, or afraid—our bodies shift into “fight or flight” mode and we lose our ability to think constructively. Our instincts kick in and a whole bunch of physical things happen to help us run away or fight off the danger, but our minds get singularly focused on one thing: “Run! Win! Help!”\n \nThis is great, if you are actually in danger, but not so great if you are trying to communicate well, perform well, or think creatively.\n \nHere’s the thing: Our thoughts and emotions always match. \n\n* If we are feeling extreme emotions like panic, we will think extreme thoughts like, “I can’t handle this!”\n* If we are feeling negative emotions like discouragement, we will think negative thoughts like, “This will never work.”\n* If we are feeling positive emotions like optimism, we will think positive thoughts like, “We got this!”\n \nThe good news is, if we can take a pause and shift our emotions to a slightly more positive–or at least less intensely negative–place, we will have access to more constructive thoughts too.\n \nWhen we feel better, we can think more creatively. That’s just how it is.\n \nRemember, though–emotions, even intense ones, are not “bad” or “wrong” in and of themselves. They are a natural part of the human experience. It’s how we respond to our emotions that can cause problems for us.\n \nSo, getting in the habit of taking a pause when we are flooded with emotions, helps us take control of how we respond to our emotions by allowing our minds to calm down and think better. This is especially helpful to prevent a disagreement from turning into an argument.",
      "questions": "Can you describe a time when you couldn’t think well because you were flooded with emotion, but when you calmed down, you could think better?\n\nHave you ever noticed that arguments rarely get solved until people take a pause and calm down a little bit? Why do you think that is?\n\nHave you ever noticed that your thoughts and emotions always match? Can you think of a time when that wasn’t true for you?\n\nCan you describe a time when you took a pause to calm down when you were flooded with emotion?\n\nWhat works for you when you need to take a pause and want to feel better? (For some people it’s taking deep breaths, counting to 10, going for a walk, or listening to music.)\n",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 10,
      "week": 27,
      "name": "Make Sure Your Schedule Reflects Your Values",
      "category": "Needs/Values",
      "subCategory": "Strengths",
      "image": "grade10week27-cookiemonsterbusy.gif",
      "summary": "Resilient people know that we feel good about ourselves when we spend time doing things we care about.",
      "readAloud": "One of the hard things about being a kid is that there are all these adults with ideas about what you should spend your time doing. Your parents want you to study more. Your coaches want you to work out more. Your school counselors want you to do more community service.\n \nIt’s easy to spend all your time doing things that matter to others and no time on things that matter to you.\n \nBut this doesn’t feel good. And, it can make you unmotivated and resentful.\n \nSo, it’s important to get clear about what you care about and spend some of your time pursuing your own interests and values.\n\nIt helps to ask yourself who you are as a person and what you are trying to achieve—right now and in the future. \n\nThen, you can make sure the way you are spending your time lines up with your values. You can also spend more time using your strengths.\n\nThis will make you feel good about yourself and make sure you are working towards your own dreams… not someone else’s.",
      "questions": "Name three adjectives that describe who are as a person. (This could be athletic, funny, kind, hard-working, a good friend, an animal lover.)\n\nName three interests you have. (This could be that you like to cook, read, paint, draw, sing, build things, or volunteer at the local animal shelter.)\n\nWhat are you trying to achieve in your life right now? (This could be making good grades, getting into college, making a sports team, being in the school play.)\n\nWhat are you trying to achieve in your future? (What do you want to be when you grow up and what kind of life do you want to have?)\n\nIs there anything you value that you wish you could spend more time doing now?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Sesame Street © Sesame Workshop"
    },
    {
      "grade": 10,
      "week": 28,
      "name": "Make Sure Your Beliefs Feel Good and Inspire You",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade10week28-haroldlookgood.gif",
      "summary": "Resilient people know that our beliefs shape our lives, so they are careful about what they choose to believe.",
      "readAloud": "We are bombarded by other people’s beliefs every day–beliefs about beauty, and happiness, and money, and success, and love. But our beliefs shape our lives, so it’s important to choose beliefs that feel good and inspire us to be our best.\n\nIf you think people are out to get you, you will be guarded and on the lookout for the next person who is rude to you, and you won’t even notice the nice people. But if you think, “There are some mean people out there, but there are plenty of nice ones too,” you will notice when people are kind instead of focusing only on negative interactions.\n\nIf you think, “I’m bad at taking tests,” you will feel defeated even before you take the test, which might cause you not to study that hard… which could cause you not to do that well. But if you think, “There must be a way to get more comfortable taking tests,” you could ask your teacher or school counselor for support, which could lead you to learn new test-taking strategies that really help.\n\nThe weird thing is, though, people are often unaware of the beliefs that are shaping their lives. We hear them say, “I’m not that smart,” “Studying is for nerds,” or “I could never get a job like that.” But they don’t realize they are limiting themselves.\n\nThe truth is we can choose to have better-feeling beliefs… about life, about other people, and about ourselves. And, if we look for evidence of these better-feeling beliefs, we will find it. We can find evidence for almost anything, so we might as well look for evidence that inspires us.\n\nSo, pay attention to the things you tell yourself and the things you say out loud. And when you come across a negative belief, find a better-feeling way to talk about that situation. And notice the power a shift like that brings.",
      "questions": "Can you give an example of a belief that is prominent in our culture that you agree with? (This could be about beauty, happiness, money, success, or love.)\n\nCan you give an example of a belief that is prominent in our culture that you do not agree with?\n\nHave you ever noticed how someone’s belief is shaping their life but they don’t realize it? Why do you think we are often unaware of our own limiting beliefs?\n\nCan you give an example of a negative belief you decided to change and how you found evidence of a better-feeling belief?\n\nDo you agree that we can find evidence for almost any belief?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc"
    },
    {
      "grade": 10,
      "week": 29,
      "name": "Use Your Imagination to Take a Stress Break",
      "category": "Mind-Body Skills",
      "subCategory": "Inner Wisdom",
      "image": "grade10week29-lionkingrelax.gif",
      "summary": "Resilient people know that giving themselves a mental picture of a safe, relaxing place can help them calm down and regroup when they are stressed, so they use their imaginations to take stress breaks.",
      "readAloud": "Close your eyes, if you’re comfortable doing that, and picture in your mind the most relaxing, calm, good-feeling place you can think of. \n\nIt can be a place you’ve actually been to, a place you’ve seen or read about, or a place you make up in your imagination. You could put yourself on a tropical island, on top of a mountain you’ve never been to, or even on a cloud floating gently across the sky. \n\nJust picture a place in your mind that feels safe and quiet and calm and that makes you feel comfortable and relaxed. \n\nNow imagine yourself sitting or lying in this safe place, relaxed and happy to be there, and take five slow, deep breaths as you enjoy being in this place.\n\n(Mentors, give time for 5 deep breaths.) \n\nNow before you leave this place, look around one more time and take note of what you see. And remember, you can come back anytime you need a stress break just by putting yourself there in your imagination and taking a few deep breaths.\n\nNow, take one more deep, relaxing breath and come back to the real world. \n\nYou just meditated. You can use your imagination and a few calming breaths anytime you need to destress, recenter, tune into your Inner Wisdom, or make a decision. ",
      "questions": "Were you able to relax with this meditation? If so, what was the place you pictured?\n\nWhat other kinds of meditation have you done, and how do they compare to this kind where you picture a safe place in your imagination? \n\nWhy do you think picturing something in your imagination helps you relax?\n \nWhat is another way you take a break when you are stressed?\n\nWhy do you think relaxing this way helps you tune into your Inner Wisdom, gut feeling, intuition or whatever you call it? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Lion King © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 30,
      "name": "Help Yourself by Helping Others",
      "category": "Interpersonal Skills",
      "subCategory": "Strengths",
      "image": "grade10week30-uphelpyourself.gif",
      "summary": "Resilient people know that they get just as much from supporting others as the people they support do, so they help themselves by helping others.",
      "readAloud": "Resilient people reach out for help when they need it because they know that everyone needs support sometimes. They also actively look for opportunities to give support. In fact, being of service to others is a value that goes hand in hand with resilience, and here’s why:\n \n* Supporting others builds and strengthens your support system, which makes you more resilient.\n* Being of service to others gives meaning and purpose to your life and makes you feel good about yourself, which makes you more resilient.\n* Helping others can be a positive distraction from your own challenges and give you perspective on your own problems, which makes you more resilient.\n \nIt feels good to support others. It helps you meet new people and deepen the relationships you already have. Each of us has a unique constellation of strengths and resources we could share with others, and doing so will benefit us just as much as it helps someone else. So, look for ways to be of service to others and experience the positive impact in your own life.",
      "questions": "Can you describe a time when you supported someone and how it felt to help them?\n\nWhat are some of the unique strengths and resources you can share with others?\n\nAre you more comfortable giving or receiving support, and why?\n\nCan you describe a time when supporting someone else gave you a new perspective on your own challenges?\n\nCan you think of any ways you might be of service to your school or community?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. a. Up © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 31,
      "name": "Practice Being, Not Doing",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade10week31-daffywaiting.gif",
      "summary": "Resilient people know that being fully present in each moment is a super power, so they practice being present instead of always doing something.",
      "readAloud": "Most people today, when faced with a few minutes of having nothing to do or having to wait in a line, check their phones. Instead of sitting quietly and being present, they distract themselves with checking their messages or scrolling their feeds. This is true for adults and kids. \n\nAnd while there’s nothing wrong with distracting yourself when you’re bored or uncomfortable, it is also important to be comfortable with simply being present. Why? Because when we’re present–to the people around us and to ourselves–we have better relationships–with the people around us and with ourselves.\n\nDistraction can be a useful tool. It can help us shift our mood from a negative thought track to a better-feeling emotion, for example. But if we aren’t careful, we can find ourselves in a constant state of distraction, which doesn’t allow us to connect well with others or even with ourselves.\n\nThis can cause us not to know why we are feeling down or what we could do to feel better. And it could cause us not to understand why our social life is not feeling great or what we could do to improve it.\n\nBut if we get good at being, we have access to much more information that can help us connect and respond to the world and ourselves.\n\nLet’s try it right now:\n\n* Close your eyes, if you’re comfortable doing that, or leave them open, if that feels better.\n* And just notice whatever you notice about being here in this present moment.\n\n* Notice what you hear.\n* Notice what\u0027s going on in your body.\n* Notice what’s going on in your mind.\n\n* Let’s take about 30 seconds to just sit quietly together and see how it feels to be present.\n\nQuestions:",
      "questions": "How was it to sit quietly and do nothing but be present? What did you notice?\n\nDo you agree that being present with people improves relationships? Why or why not?\n\nDo you agree that we shouldn’t distract ourselves from all discomfort because that prevents us from really taking care of ourselves. Why or why not?\n\nCan you give an example of when using distraction is a helpful tool?\n\nCan you give an example of when being distracted could have a negative effect?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Looney Tunes © Warner Brothers"
    },
    {
      "grade": 10,
      "week": 32,
      "name": "Take Control of Your Emotions by Accepting Them First",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade10week32-insideoutemos.gif",
      "summary": "Resilient people know that the first step to controlling their emotions is accepting that all emotions are real and important.",
      "readAloud": "Negative emotions like anger, fear and sadness don’t feel good. They don’t feel good to the person feeling them, and they don’t feel good to people around that person. Because of that, people try to ignore negative emotions, pretend they are not important, and stuff them down.\n \nBut ignoring emotions just makes them get bigger. And pretending emotions are not important just makes you feel worse—but in secret, which makes you feel alone.\n \nThe way to feel better is to acknowledge the emotion and get honest with yourself about what it is telling you. How do you do this?\n \nSay an “Emotion Sentence:” I feel (name the emotion) because… (and finish the sentence.)\n \nFor example, if you feel angry because someone was mean to you, it’s important to acknowledge that. Then, you can figure out what to do about it.\n \nBut if you pretend it’s not a big deal, you miss an opportunity to take care of yourself, and you set yourself up to continue getting hurt.\n \nSo the next time you feel upset about something, take control of your emotions by saying how you feel.",
      "questions": "Have you ever noticed that it feels good when other people accept your emotions—even the bad-feeling ones? Why do you think it feels good?\n\nHow do you or could you let someone know that you accept their emotions?\n\nHave you ever noticed that it feels bad when other people don’t accept your emotions? Why do you think that feels bad?\n\nAre there some emotions you do not accept in yourself? (Some people don’t think they should feel angry. Some people don’t think they should show sadness or fear.)\n\nWill you say an “emotion sentence” for an emotion you felt recently, and then say how it felt to share that out loud?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Inside Out ©”The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 33,
      "name": "Choose Your Own Labels and Make Sure They Feel Good",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade10week33-homersimpsonsmart.gif",
      "summary": "Resilient people know the words people use to describe them have power, so they don’t accept labels that bring them down. They choose labels that feel good.",
      "readAloud": "People often judge other people and define them with labels.\n\nShe’s smart. He’s mean. They’re lazy.\n\nBut labels never tell the whole story.\n\nWe all have lots of different sides to ourselves, and labels can prevent people from seeing them. Labels can also limit the way we think about ourselves.\n\nIf you become known as the crazy one, for example, you might forget that you can also be level-headed and calm. Recognizing different sides of yourself will give you more options for how you show up in different circumstances.\n\nSo choose your own labels and make sure they feel good to you.",
      "questions": "Do you have any labels that other people put on you that don’t feel so good? If so, what other sides of you do those labels prevent people from seeing? \n\nWhat can you do when someone gives you a label that doesn\u0027t feel good? \n\nHas anyone ever given you a label that inspired you to be better in some way? \n\nIf you could give yourself a new label that made you feel good about yourself, what would it be? \n\nCan you think of a label that would make someone you care about feel good and remind them about a positive quality?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 10,
      "week": 34,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade10week34-nemo.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. This year we have talked about: \n\n* Focusing on what’s in your control\n* Getting help when you need it\n* Not having to be right\n* Checking in with trusted advisors when making decisions\n* Trusting your gut\n* Having healthy self-care habits\n* Having a personal mission statement\n* Making sure your beliefs feel good and inspire you\n* Using negative emotions to take care of yourself\n* Setting achievable goals around your strengths and values\n* Using your imagination to take a stress break\n\nThese are just a few of the resilience skills we have covered this year. You were already resilient, and now you have even more skills to choose from when you come to a bump in the road. ",
      "questions": "Can you describe a way you have been resilient this year?\n\nAre there any skills from this Resilience Reminders™ program that you learned or tried that worked for you?\n\nWhat words do you use to define resilience? \n\nDo you think talking about these types of things is helpful for students and families? Why or why not?\n\nDo you believe in reaching out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Finding Nemo © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 1,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade11week1-bartbounce.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. \n\nThe other good news is you are already resilient! You have probably picked up quite a few resilience skills already in life. For example, if you…\n\n* Can name your personal strengths\n* Have good-feeling relationships\n* Reach out for support when you need it\n* Take care of yourself–physically, mentally, emotionally and spiritually\n* Learn from your losses\n* Use your breath to calm down and focus\n* Trust your gut\n\nIf you do any of these things, you are already resilient. \n\nThese Resilience Reminders™ will add more skills to your list and remind you of helpful things you already know.",
      "questions": "How do you define resilience? What words do you use? \n\nHow do you demonstrate resilience? Which skills do you use? \n\nWhat disappointment, challenge or failure are you most proud of yourself for overcoming, and what skills did you use to feel better?\n\nWhat is one way you take care of yourself–physically, mentally, emotionally or spiritually?\n\nDo you agree that it demonstrates resilience to reach out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 2,
      "name": "Shift Your Mood by Shifting Your Focus",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade11week2-dancingdisneymakesyousmile.gif",
      "summary": "Resilient people know that what you focus on determines how you feel, so when they want to feel better, they shift their mood by shifting their focus.",
      "readAloud": "What you focus on determines how you feel.\n\nIf you’re focusing on a great song, a funny video, a person you love, an activity you enjoy, or a positive thought, you will feel good. \n\nOften, however, we get so caught up in the rush of daily life that we give our attention to whatever is in front of us and allow whatever that is to set our mood. We don’t like math, so we’re in a bad mood. Someone is rude to us, so we’re in a bad mood. We mess up in basketball, so we’re in a bad mood. \n\nBut when we remember that we can choose to focus on activities and thoughts that feel better, we can take our power back. \n\nWe can’t always focus on different activities. If we’re in math class, we can’t start blasting a favorite song. We can, however, choose to focus on thoughts that feel better than others. Instead of thinking, “I hate this. I’m no good at this. It’s no use. I’ll never get better.” You could think, “I really don’t like this, but it’s my last class of the day, and then I get to hang out with my friends.” \n\nThe thoughts we think are often the most powerful causes of our moods. With a little practice we can notice the thoughts that are causing us to feel bad and find a slightly better way of thinking about the situation. \n\nSo when you’re feeling down and want to feel better, shift your focus to an activity or a thought that feels better. \n\nRemember, though, negative emotions have important messages for us about what we need to feel better—about ourselves and our lives. So don’t ignore negative emotions or shift your focus away from them until you have identified what you need and how you can take care of yourself.",
      "questions": "How do you get yourself out of a bad mood when you want to? \n\nWhat is a song that always makes you feel good? \n\nCan you describe a time when you noticed you were focusing on negative thoughts and you found a better-feeling way to think about the situation? \n\nHave you ever noticed that sometimes you just want to stay in your bad mood, and when someone tries to cheer you up, it just makes you feel worse? Do you agree that it’s okay to stay in a bad mood sometimes?\n\nWhy do you think it’s important not to ignore negative emotions?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Lilo and Stitch ©”The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 3,
      "name": "Live Up to Your Potential (Not Someone Else\u0027s)",
      "category": "Strengths",
      "subCategory": "Needs/Values",
      "image": "grade11week3-homeryou\u0027rebetterthanthat.gif",
      "summary": "Resilient people know they have something unique and valuable to offer the world, and they pursue their personal strengths, interests and values to live up to their potential.",
      "readAloud": "Everyone has the potential for greatness.\n\nThe problem is, people often spend more time comparing themselves to others and trying to be something they’re not than they do developing the things they are naturally good at and that they are actually interested in. \n\nInstead of deciding for themselves what kind of greatness they want to be, they spend their time trying to measure up to someone else’s standards of greatness. \n\nHowever, when you realize that the things that make you unique, that you enjoy, that you are good at, and that you genuinely care about are the very things that will propel you to your own, special kind of greatness, you can stop looking to others for validation and start living up to your own potential.\n\nHere’s a shortcut: STRENGTHS, INTERESTS, VALUES. Know what yours are, spend as much time as possible pursuing them, and you will feel and be your best. You will also live up to your potential, not someone else\u0027s. ",
      "questions": "What are your personal STRENGTHS? (What are you good at? What comes easy to you?) \n\nWhat are your personal INTERESTS? (What do you look forward to doing? What do you want to do more often?) \n\nWhat are your personal VALUES? (What kind of person do you want to be? What kind of life do you want to have?) \n\nHow do you define GREATNESS for yourself? (What will you have to do to achieve your kind of greatness?) \n\nWhat are some of the reasons you think people don’t live up to their potential?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 4,
      "name": "Talk About Your Mental Health",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade11week4-idontwannatalkaboutit.gif",
      "summary": "Resilient people know that talking about their mental health is good for their mental health, so they have the courage to seek support when they feel anxious, overwhelmed or sad. ",
      "readAloud": "Here’s the thing: Everyone–seriously–everyone has times in life when they feel anxious, overwhelmed, sad, lost, insecure, unsure of themselves or unable to shake themselves out of a dark place. \n\nWhen this happens, we need to seek help. \n\nIf you had a physical symptom, you would likely seek help. You would tell your parents about your illness or injury and they would tell a professional, so you could get the treatment you need.\n\nThe same is true of mental health. Sometimes our symptoms require professional help. And even if they are not that intense, talking about what’s going on is helpful. That’s what therapy is–talking about what’s troubling you, so you can find solutions and start to feel better.\n\nEven mental health professionals seek help from others when they have mental health challenges, because talking and being supported by others is healing. We humans are social beings. It feels good to connect, especially when we are feeling vulnerable.\n\nSo, the next time you are feeling upset about something or just out of sorts, have the courage to talk about it with someone who feels trustworthy and supportive–whether that is someone you already know or someone you can access through a variety of mental health resources available through your school counselor.",
      "questions": "Can you describe a time when you talked with someone about how you were feeling and it helped you feel better?\n\nDo you think it’s true that everyone has times in life when they have overwhelming emotions and don’t know how to help themselves?\n\nDo you think many students your age feel comfortable talking about mental health issues? Why or why not?\n\nWhy do you think talking is helpful when you are upset about something?\n\nDo you support the idea of students in your school going to the school counselor for mental health resources, if they need them?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Lion King © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 5,
      "name": "Be Mindful of Your Body",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade11week5-patrickeatingnotmindfully.gif",
      "summary": "Resilient people know that their minds and bodies are connected, so they are mindful of how they treat their bodies.",
      "readAloud": "Our minds and bodies are connected.\n\nBut with life as busy and chaotic as it is, it\u0027s easy to overlook how the way we treat our bodies affects us mentally and emotionally. But if you pay attention, it is obvious.\n\nWhen our bodies are down with an illness, we feel mentally and emotionally down as well. When we finish a good workout, we feel empowered and clear-minded.\n\nWhen we don’t get enough sleep, we feel foggy and irritable. When we are well-rested and have eaten a healthy meal, we feel alert and optimistic.\n\nDon\u0027t take our word for it. Try it for yourself. \n\nCompare how you feel after eating junk food with how you feel after eating something healthy. Notice how you feel after being a couch potato and how you feel after getting some exercise. See how different you feel after staying up really late versus getting a full night\u0027s sleep.\n\nBe mindful of your body and start feeling your best - physically, mentally and emotionally.",
      "questions": "Can you describe a time that you noticed how what was going on with your body affected how you felt mentally and emotionally? \n\nHow does exercise make you feel mentally and emotionally? \n\nHow does sleep affect how you feel mentally and emotionally? \n\nWhat foods make you feel the best physically, mentally and emotionally? \n\nWhat foods make you feel the worst physically, mentally and emotionally?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 11,
      "week": 6,
      "name": "Ask For What You Need",
      "category": "Interpersonal Skills",
      "subCategory": "Needs",
      "image": "grade11week6-donkeyhugagain.gif",
      "summary": "Resilient people know that people can’t read their minds, so they speak up for themselves and ask for what they need.",
      "readAloud": "It would be great if other people could somehow know exactly what we need at any given time and give it to us. But people can’t read our minds, so they are often left guessing at what we need or thinking we don’t need anything at all. \n\n* If you don’t tell your teacher you need help, they won’t know how to help you.\n* If you don’t tell your coach you need support, they won’t know how to support you.\n* If you don’t tell your friends you need encouragement, they won’t even know that you need encouragement.\n\nWhen people tell each other what they need, relationships feel better. \n\nAnd if you find yourself in a relationship with someone who will not give you what you need, you can look for ways to take care of yourself. \n\nLearning to speak up for yourself and ask for what you need is one of the most empowering things you can do for yourself.",
      "questions": "Can you give an example of when you asked for something you needed from someone? \n\nAre you comfortable speaking up for yourself and asking for what you need? In what situations are you more and less comfortable asking for what you need? \n\nCan you think of something you need from someone that would make your relationship feel better? \n\nHow does it feel to you when someone asks you for something they need? \n\nWhat can you do if you ask someone for something you need and they won’t or can’t give it to you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Shrek © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 7,
      "name": "Don\u0027t Judge the Moment. Stay Hopeful.",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade11week7-homersimpsonhappysad.gif",
      "summary": "Resilient people know that events that seem bad in the moment can pave the way for good things in the future, so instead of judging the moment, they stay hopeful.",
      "readAloud": "The Parable of the Chinese Farmer\n\nOnce upon a time there was a Chinese farmer whose horse ran away. That evening, his neighbors came and said, “How unlucky for you! This is most unfortunate!” The farmer replied, “Maybe, yes. Maybe, no.”\n\nThe next day the horse came back bringing seven horses with it. In the evening, the neighbors came back and said, “Isn’t that lucky! What a great turn of events!” The farmer said again, “Maybe, yes. Maybe, no.” \n\nThe following day his son tried to ride one of the horses, but was thrown off and broke his leg. The neighbors said, “Oh dear, that’s too bad!” The farmer responded, “Maybe, yes. Maybe, no.”\n\nThe next day military officers came to draft people into the army, and they rejected his son because he had a broken leg. Again, the neighbors said, “Isn’t that wonderful!” And the farmer said, “Maybe, yes. Maybe, no.”\n-------------------------------------------------------\n\nIf you look back over your life, you will be able to identify things you consider “good” that could not have happened without something you consider “bad” happening before. Realizing this can help you choose to stay hopeful even when things in the moment are not feeling great.",
      "questions": "Can you think of something “good” in your life that could not have happened if a “bad” thing hadn’t happened before it? \n\nCan you think of something you first thought was \"bad\" but later you realized it was actually \"good\"? \n\nHow about the opposite? Can you think of something you first thought was \"good\" but turned out to be not so good? \n\nWhat can you do to turn something that seems “bad” into something “good”? \n\nWhy do you think not judging the moment helps you be resilient? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 8,
      "name": "Feel Into Your Decisions",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade11week8-somethingdoesntfeelright.gif",
      "summary": "Resilient people know they can feel in their bodies when something feels like a good decision or a bad decision, so they feel into their decisions.",
      "readAloud": "When you are a kid, lots of people have opinions about who you should be, how you should behave, and what you should do with your life. With so many people telling you what to do, it can be hard to know how to make decisions for yourself.\n\nThe good news is we all have a powerful decision-making tool with us all the time: our bodies. Our bodies will tell us if a person feels safe, if an activity will be good for us, and if a decision is what we really want to do, or if we are trying to please someone else.\n\nIf no one tells you how important your gut feelings are, you might not pay attention to them. But if you feel into your body, the information is there.\n\nSo the next time you have a decision to make, listen to what your body has to tell you. In fact, let’s try it right now: \n\n* Think of a decision you need to make. \n* Now, close your eyes, if you’re comfortable doing that, and imagine yourself deciding one way. \n\n* We’ll take about 10 seconds now for you to see how it feels to imagine yourself deciding to do a certain thing.\n\n* Now, keep your eyes closed and imagine making a different decision.\n\n* We’ll take another 10 seconds for you to see how it feels to imagine yourself deciding to do a different thing.\n\n* Now open your eyes and take a few more relaxing breaths while you tune into what your body has to tell you about which decision feels better to you.\n\nOur thinking minds can get sidetracked by insecurities, limiting beliefs (like “I’m not good enough”) and other people’s desires for us. But our Inner Wisdom will speak to us through our bodies if we tune in and listen.",
      "questions": "How does it feel in your body when you make a decision you are excited about?\n\nHow do you make decisions? Do you listen to your body? Do you think it through? Do you talk to other people? Do you do all of the above?\n\nMany people call the feeling in your body your “gut feeling.” What do you call it?\n\nCan you describe a time when you could feel in your body whether something was a good decision or not?\n\nHow was it to do that decision-making exercise, and did you learn anything about yourself?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 9,
      "name": "Seek Peace",
      "category": "Spirit",
      "subCategory": "Mind-Body Skills",
      "image": "grade11week9-peacefulnaturerain.gif",
      "summary": "Resilient people know they must balance the frenzy of daily life with moments of peace to calm their minds and nurture their spirits.",
      "readAloud": "Life is stressful. Teachers, coaches, counselors, parents and peers all demand certain things from you. No matter how hard you work there’s always more you could do. And you’re constantly being tested and judged and compared to others. It’s exhausting. And it can feel overwhelming if you don’t take care of yourself.\n\nBut if you make time to pause in the frenzy and seek a feeling of peace, you can calm your mind, relax your body, and lift your spirits.\n\nOur nervous systems are not meant to be on high alert all day. We are meant to have down time–time to unwind and just be without the pressure to work or learn or perform. But the demands of our over-scheduled lives make time to “do nothing” hard to come by. And many people fill what down time they do have with social media, television, video games and other activities that are not actually peaceful or inspiring.\n\nThe good news is we have a powerful source of peace with us all the time. All we have to do is close our eyes and tune into the sound and feel of our breath. It’s always with us, and its natural rhythm is soothing and calming.\n\nAnother source of peace that’s always with us is nature. Even if you live in a city, there are plants, trees, clouds, stars in the night sky, animals to pet and birds to watch.\n\nSo, seek peace–even for a few moments–and you will feel more centered, clear-minded, inspired, and empowered.",
      "questions": "Do you ever use your breath to calm down and feel better (which is a mind-body skill)? If so, when was the last time you did it?\n\nWhen do you feel most peaceful and calm in any given day?\n\nCan you describe a time when something in nature made you feel peaceful?\n\nDo you make it a point to have peaceful downtime in your daily life, or is it hard to find the time?\n\nCan you think of anything your school or community could do to make it easier for people to find moments of peace?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 11,
      "week": 10,
      "name": "Use Your Words Wisely",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade11week10-starwarswhatareyoutalkingabout.gif",
      "summary": "Resilient people know that words have power, so they use their words wisely.",
      "readAloud": "Words have power. \n\nWords have the power to make us feel good or bad about ourselves.\n\nWords have the power to make others feel disrespected or supported by us.\n\nWords have the power to make us feel anxious or hopeful about the future. \n\nIn other words, the words we hear and the words we use literally shape our lives. \n\nWhile we cannot always control the words we hear, we can control the words we use. \n\nWhen we decide to use our words for good, our lives move in a positive direction. \n\nWhen we talk about ourselves with kindness and understanding, we feel better.\n\nWhen we talk about others with love and respect, they feel better, and we feel better too.\n\nWhen we talk about our futures with optimism, we feel better. \n\nSo, use your words wisely, and notice how much better you feel.",
      "questions": "What is something positive you can say about yourself? \n\nCan you give an example of how you used words to make someone else feel better? \n\nTeachers and coaches have to choose their words wisely so they inspire you to try harder instead of making you feel like you are not good enough. Can you give an example of something a teacher or coach said that inspired? \n\nDo you have anyone in your life who is way more negative than positive, and if so, how does it feel to be around them? \n\nCan you give an example of something you say that makes you feel optimistic about your future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 11,
      "name": "Admit When You\u0027re Wrong and Make Amends",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade11week11-iwaswronganime.gif",
      "summary": "Resilient people know that no one is perfect. They also know that taking ownership of their mistakes and making amends when they need to is a sign of strength.",
      "readAloud": "Some people think that admitting when you are wrong is weak. They think being right and winning every argument is the way to seem strong.\n\nBut no one is perfect. Everyone makes mistakes, and everyone is wrong sometimes. We all do things we regret, and having the courage to admit when we mess up and take an action step to make things right is actually a sign of strength.\n\nThink back to the last time someone genuinely apologized to you for something they did. You probably like and respect them more for it, not less.\n\nTaking ownership of our mistakes and making amends, which means making it right with the person we wronged, improves our relationships. It makes our bonds stronger. On the other hand, people who never learn this skill miss out on deep, good-feeling relationships.\n\nSo, be honest with yourself, admit when you are wrong and make amends, and take your relationships to the next level.  ",
      "questions": "Can you describe a time when you admitted you were wrong about something and tried to make it right with the other person? \n\nAre you good at apologizing? If so, why? If not, why not? \n\nHave you ever noticed how you can tell when someone is not genuine in their apology? How can you tell they don’t really mean it? \n\nDo you have someone in your life who is good at admitting when they are wrong and apologizing? If so, how does it make you feel when they do that? \n\nDo you have someone in your life who never apologizes? If so, how does that make you feel?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. "
    },
    {
      "grade": 11,
      "week": 12,
      "name": "Practice Gratitude",
      "category": "Emotional Intelligence",
      "subCategory": "Interpersonal Skills",
      "image": "grade11week12-pandagratitude.gif",
      "summary": "Resilient people know that gratitude improves not only your mental health but also your physical health, so they make it a practice to be grateful.",
      "readAloud": "It makes sense that gratitude is good for your mental health, but studies show that gratitude improves our physical health too! Gratitude also improves relationships.\n\nThanksgiving is all about gratitude. But we can forget to look for things to be grateful for during the rest of the year. \n\nIt’s easy to get so caught up in the stresses of daily life that we focus on what’s going wrong in our lives instead of looking for what’s good.   \n\nThere are always things to be grateful for in our lives. We just have to make an effort to notice them. \n\nThis is why some people practice gratitude the same way they practice a sport. They set aside time every day or every week to think about what they are grateful for. Some people do it every morning. Others do it when they go to bed. Others do it when they attend spiritual services.\n\nHowever you do it, the more you practice gratitude, the better you will feel - and the healthier you will be.",
      "questions": "Who is a person you are grateful for?\n\nWhat is an experience you are grateful for?\n\nWhat is a quality about yourself you are grateful for?\n\nCan you think of anything you are grateful for that came from something that was not so great?\n\nHow can you remember to practice gratitude more often in your daily life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Kung Fu Panda © DREAMWORKS ANIMATION L.L.C."
    },
    {
      "grade": 11,
      "week": 13,
      "name": "Practice Tolerating Discomfort",
      "category": "Strengths",
      "subCategory": "Beliefs",
      "image": "grade11week13-icanhandlethis.gif",
      "summary": "Resilient people know that discomfort happens and their attitude about it matters, so they expect some discomfort and practice positive self-talk.",
      "readAloud": "No one likes to be uncomfortable. In fact, our brains are wired to avoid things that are unpleasant. \n\nThat being said, it is impossible to avoid all discomfort. Unexpected annoyances, negative emotions, and uncomfortable experiences are a part of daily life. So, instead of trying to avoid all discomfort, it’s helpful to practice tolerating it. \n\nHow do you do that?\n\nFirst, expect to experience some discomfort as a natural part of daily life. Research shows that the very act of expecting discomfort and “normalizing” it (which just means believing it’s “normal” to expect some discomfort in daily life) makes discomfort less uncomfortable when it happens. \n\nExpecting some discomfort also makes us more able to switch into solution-mode instead of getting stuck focusing on how uncomfortable we feel.\n\nSecond, practice positive self-talk. \n\nFor example, when you notice you feel sick to your stomach about taking a test, instead of focusing on how anxious you feel, you can focus on how well you prepared. \n\n” Your self-talk might sound like this, “It’s normal to feel anxious before a big test, but I’ve got this. I studied hard. I know the material. I can do this.”\n\nOr, if you have to do something out of your comfort zone like speak in public or perform in front of an audience or have a difficult conversation with someone, instead of focusing on how nervous or scared you feel, you could focus on how good it will feel to have given your all.\n\n” Your self-talk might sound like this, “It’s normal to be terrified to speak in public, but it’s a great skill to learn and I’ll be proud of myself for facing this fear.”\n\nThe things we say to ourselves matter. So expect some discomfort and practice using self-talk to motivate and inspire yourself.",
      "questions": "Would you say you’re good at tolerating discomfort or not so good at it? And what could you do to be better?\n\nIs your self-talk generally motivating and positive or critical and negative?\n\nHave you ever stopped yourself in the middle of freaking out about something and calmed yourself down? How did you do it?\n\nHave you ever noticed how the person you’re with can make things better or worse? What can they do that make the situation better? What can they do to make it worse?\n\nCan you give an example of how expecting something to be uncomfortable makes it not feel as bad as when you don’t have those expectations?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Hercules © Disney Enterprises, Inc."
    },
    {
      "grade": 11,
      "week": 14,
      "name": "Do What You Need To Do, Even When It’s Not That Fun",
      "category": "Needs/Values",
      "subCategory": "",
      "image": "grade11week14-spongebobnotfun.gif",
      "summary": "Resilient people set goals and do what they need to do to achieve them, even when it’s not that fun.",
      "readAloud": "Few people enjoy working out so hard they can’t catch their breath.\n\nNot many people think homework is fun.\n\nMost kids would choose junk food over vegetables, if given the choice.\n \nBut most accomplishments require hard work, tough choices, and doing things that aren’t that fun in the moment. \n\nSuccessful students do their homework, even when they’d rather hang out with friends.\n\nWinning athletes push their bodies, even when they’d rather sleep in.\n\nHealthy people make healthy food choices, even when they’d rather eat junk. \n\nWhen we make hard choices that move us toward our goals we feel good about ourselves. And we look good to others—our teachers, coaches, mentors and friends think highly of us and want to help us achieve our goals. \n\nSo, set goals, figure out what you need to do to achieve them, and do it… even when it isn’t fun. ",
      "questions": "What is one thing you do that’s not that fun, but you do it because it moves you toward a goal? \n\nDo you have any academic goals, and if so, what is one? \n\nDo you have any athletic or health goals, and if so, what is one?\n \nWhat is one thing you could do to move yourself toward one of your goals, even if it’s not that fun?  \n\nCan you think of a way to make something that\u0027s not that fun more enjoyable?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 11,
      "week": 15,
      "name": "Have Beliefs That Comfort You, Guide You, and Give Your Life Meaning",
      "category": "Spirit",
      "subCategory": "Beliefs",
      "image": "grade11week15-poohbeliefs.gif",
      "summary": "Resilient people know that our beliefs shape our lives, so they have beliefs that comfort them, guide them, and give their lives meaning.",
      "readAloud": "This time of year reminds us that spiritual beliefs are a powerful source of comfort and guidance for many people. Some beliefs give meaning to our lives. Some beliefs inspire us to be kind to each other. Some beliefs comfort us around our fears, especially around our fear of death. And many beliefs connect us to supportive communities and nurture a sense of belonging.\n \nRegardless of what you believe, choosing to live your life in alignment with beliefs that give you purpose, connect you to the most loving parts of yourself, and nurture optimism for your future is a choice worth making.\n \nMany people don’t realize that they can choose what to believe. They think that because they already believe something, it must be true. But people believe many different things, some of which are contradictory. Yet somehow, people have evidence for contradictory beliefs. \n\nSo, if you realize you have a belief that does not make you feel very good, look for evidence of something better. If you look hard enough, you will probably find it.\n \nWhatever you believe, we hope it brings you peace, joy and love this holiday season. ",
      "questions": "Do you have a belief that inspires you to be the best person you can be?\n\nDo you have a belief that motivates you to be kind to others?\n\nDo you have a belief that comforts you around any of your fears?\n\nDo you have a belief that gives your life meaning or a sense of purpose?\n\nDo you have a belief that makes you feel optimistic about your future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Winnie the Pooh © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 16,
      "name": "Set Achievable Goals (Around Strengths and Values)",
      "category": "Strengths",
      "subCategory": "Values",
      "image": "grade11week16-snoopygoals.gif",
      "summary": "Resilient people believe goals should inspire them and make them feel good about themselves, so they set achievable goals around their strengths and values.",
      "readAloud": "New Year’s Resolutions often start out as inspiring but end up making us feel disappointed in ourselves if we fail to achieve them. Here are some tips for setting goals that will make you feel good about yourself:\n\nSet goals that are small enough that you can actually get them done. If you set goals that are too big, you’ll give up, feel bad about yourself, and be less likely to set future goals for yourself. On the other hand, if you set goals you can actually achieve, you will build positive momentum that allows you to achieve goal after goal after goal.\n\nSet goals around your strengths. The more time you spend using your strengths, the better you feel about yourself. And, if you set goals around things you actually enjoy doing and that come naturally to you, you are more likely to achieve your goals, which also makes you feel good about yourself. It’s a double-whammy of feeling good about yourself! \n\nSet goals around your values. Doing things that demonstrate your personal values also makes you feel good about yourself. If you value being a good athlete, set goals around your fitness. If you value being a good student, set goals around your academics. If you value being kind, set goals around making people feel good.\n\nFind people to support you in achieving your goals. Having people to support you and hold you accountable will make you much more likely to achieve your goals.",
      "questions": "What is one goal you have for yourself right now, and does it feel achievable? \n\nCan you think of a goal to set around one of your strengths or values? \n\nCan you think of someone who could support you in achieving one of your goals? \n\nWhat is a goal you successfully achieved in the past, and what factors contributed to you achieving them? \n\nIf you think about goals you did not achieve in the past, what factors prevented you from achieving them? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Snoopy © Peanuts Worldwide"
    },
    {
      "grade": 11,
      "week": 17,
      "name": "Know That Thoughts Cause Emotions",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade11week17-Icandothis.gif",
      "summary": "Resilient people know that thoughts cause emotions, so they identify their thoughts and then try to find slightly more positive ones to feel better.",
      "readAloud": "One of the greatest concepts of resilience is this: It’s not the situation that causes you to suffer. It’s your thoughts about the situation that cause you to suffer.\n\nFor example, if you miss your final free throw shot and cause your team to lose the game, you probably think you’re upset because you missed the shot, but the real cause of your suffering is the thought-track running through your head: “I always choke under pressure. My teammates hate me now. My coach will never play me again.”\n\nOn the other hand, it is possible for someone to miss the shot and have a more positive thought-track running through their head: “I let my nerves get to me. It happens to everyone. I’ll make it next time.”\n\nThoughts, not situations, cause emotions.\n\nThis is great news, because we cannot always control situations, but we can almost always choose a slightly better-feeling way to think about them.\n\nJust like you can train your body to be physically fit, you can train your mind to focus on thoughts that feel better. \n\nRemember, though, that negative emotions are not \"bad,\" and we can\u0027t expect ourselves to think positive thoughts all the time. It\u0027s a good skill to practice, but give yourself permission to stay in a bad mood sometimes too. You get to decide when you\u0027re ready to feel better, and when you are... choose a slightly better-feeling thought.",
      "questions": "Can you think of a time when you made yourself feel better by choosing to think about the situation more positively? \n\nIs there one particular thought that always makes you feel better - something like, \"It will work out,\" or \"I can figure this out\"? \n\nThink back to the last time you were upset about something. What was the thought going through your head, and can you think of a slightly better-feeling one now? \n\nDo you agree that we can’t think positive thoughts all the time and it’s okay to stay in a bad mood sometimes?\n\nWhat emotion are you feeling now, and what is the thought causing it? The best way to answer this question is to complete this sentence: “I feel (name your emotion), because…” Whatever follows \"because\" is the thought causing your emotion.",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Playmobil: The Movie © On Animation Studios"
    },
    {
      "grade": 11,
      "week": 18,
      "name": "Listen, Instead of Defending Yourself",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade11week18-defensivesouthparklooped.gif",
      "summary": "Resilient people know that they are not always aware of how their actions impact others, so they listen instead of defending themselves.",
      "readAloud": "When someone is upset with us it is natural to want to defend ourselves. It feels terrible to be accused of doing something wrong, especially when we don’t think we did anything wrong. \n\nBut we are not always aware of how our actions impact others. We may not intend to hurt anyone, but they can still be hurt. For example…\n\n* You think you’re joking around, but your teammate feels like you’re picking on him.\n* Your best friend overhears you making plans with someone else and is upset that you don’t tell her about it.\n\nIt is also true that sometimes someone needs something from us, and if we get defensive, we might miss the opportunity to improve our relationship. For example…\n\n* If you get defensive when your coach asks you to work harder in practice, you miss the opportunity to become the leader he needs you to be.\n* If you get defensive when your mom asks you to keep your room tidy, you miss the opportunity to make her feel respected and cared for by you.\n\nIt’s easy to be so stuck on our own story that we cannot understand the other person’s perspective. But if our response is always to defend ourselves, we miss opportunities to learn about how we come across to others and to deepen our relationships.\n\nSo pay attention to when you get defensive, take a breath when you feel yourself doing it, and open to the possibility that you might be either misunderstanding what the other person is needing from you or being misunderstood by them. \n\nIf you listen first, you will be better able to find a win-win solution to whatever is upsetting them.",
      "questions": "Can you describe a time when someone was upset with you for something you didn’t intend and how you resolved that?\n\nWould you say you’re pretty good at listening and not being defensive or not? Why do you think that is?\n\nAre there certain people you feel more defensive with, and why is that? (your big brother, your parents, your teachers)\n\nAre there certain issues that make you feel more defensive than others?\n\nWhat can someone do to make you feel less defensive when they are upset with you or asking you for something they need?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. South Park © Comedy Partners"
    },
    {
      "grade": 11,
      "week": 19,
      "name": "Use Your Breath to Calm Down and Focus",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade11week19-breathe.gif",
      "summary": "Resilient people know that their breath is their best tool for calming down, collecting their thoughts, and focusing when they\u0027re nervous, upset, or need to perform.",
      "readAloud": "Many people do not realize that their greatest tool for calming down when they are nervous or upset about something is always with them... It\u0027s their breath.\n \nUsing your breath to calm down and focus is a super power. And it’s super easy to do.\n \nTake a slow, deep breath right now and focus on the SOUND of the air going in and out of your nose…\n \nTake another slow, deep breath and this time focus on the FEEL of your belly expanding as the air goes in and then contracting as the air goes out…\n \nTake another slow, deep breath and this time, after you fill your belly all the way up, IMAGINE ALL OF YOUR MUSCLES RELAXING as you slowly let the air back out again.\n \nYou just meditated! It’s as easy as that.\n \nFocusing on your breath is an easy way to get your mind to pay attention to what\u0027s going on in your body, instead of on whatever has you nervous or upset. And when you focus on your breath, you naturally calm down.\n \nEven if you only have time for two or three deep breaths, you’ll get the benefit of feeling a little bit more calm and focused, which helps you feel better if you\u0027re upset and perform better if you’re nervous. It also helps you collect your thoughts if you have an important decision to make or during an argument.",
      "questions": "Can you describe a time you took a few deep breaths to calm down when you were NERVOUS or UPSET about something and it worked? \n\nCan you describe a time you used your breath to calm down and PERFORM better in a sport or another performance activity?\n\nCan you describe a time you used your breath to calm down in the middle of an ARGUMENT? \n\nCan you describe a time you used your breath to calm down and collect your thoughts when you had an important DECISION to make?\n\nHave you ever done any other kind of meditation? If so, what was it like and how did it make you feel?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page ",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 11,
      "week": 20,
      "name": "Resist the Urge to Compare Yourself to Others",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade11week20-justlikeyouiwannabejustlikeyou.gif",
      "summary": "Resilient people know that everyone’s personality and life journey is unique, so they resist the urge to compare themselves to others.",
      "readAloud": "We live in a world where it’s hard to avoid seeing how fabulous everyone else’s life is. Our social feeds are full of shiny, happy people having the most fun and living their best lives. It’s easy to feel like you are the only person whose life is falling short of your expectations. \n\nBut comparing ourselves to others is a trap that almost always leads to feeling bad. It might feel good at first, but if you constantly judge yourself by how well you stack up to others, you will lose connection to the very traits that make you… you. You will forget about the strengths that make you feel good about yourself and the values that give your life meaning and a sense of purpose.\n\nOnly you know what you genuinely enjoy doing, what your natural talents are, what you don’t mind practicing in order to improve, what you care about, what kind of person you want to be, and what you want your future to look like. \n\nYou get to design your life around your hopes and dreams. And true happiness will come when you appreciate the unique gifts you have to offer the world and live your life authentically.\n\nSo, the next time you start to compare yourself to someone else, ask yourself instead if you are living in alignment with your own strengths, values and interests. And give yourself permission to do you. (And remember, just because someone’s life looks great in pictures, doesn’t mean they don’t have their own challenges.)",
      "questions": "Do you agree that comparing yourself to others usually ends up making you feel bad? Why or why not?\n\nDo you agree that true happiness comes when we appreciate the unique gifts we have to offer the world and live our lives authentically? Why or why not?\n\nWhat is one of your personal strengths? (something you enjoy doing and that you are good at)\n\nWhat is one of your personal interests? (something you enjoy doing even in your free time)\n\nWhat gives your life meaning? (something that makes you feel good about your life)",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Big Hero 6 © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 21,
      "name": "Stand Up For Yourself AND Know When to Agree to Disagree",
      "category": "Needs/Values",
      "subCategory": "Interpersonal Skills",
      "image": "grade11week21-respectthat.gif",
      "summary": "Resilient people stand up for their own values. They also make room for other people’s values and know how to agree to disagree. ",
      "readAloud": "It is hard to listen to people we disagree with when we really care about an issue. When we know they are wrong, we want to make them stop talking and convince them we are right. And when they won’t agree, our anger can boil over until we say or do something we regret. \n\nAnd here’s the thing: It’s good to know what you stand for and stand up for it. In fact, it’s one of the building blocks of self-esteem—to live your values. \n\nIf you value kindness, being kind will make you feel good about yourself. If you value giving your all in your favorite sport, working hard in practice and games will make you respect yourself. If you value standing up for others, standing up for others will boost your self-esteem. \n\nSo know what you stand for and stand up for it. \n\nAND, when you come up against differing opinions, knowing how to agree to disagree will help you be your best self and not say or do things you will later regret. \n\nIf you can listen respectfully, state your case respectfully, and walk away before you betray one of your own values, you will feel better about yourself and make the world a better place.",
      "questions": "What do you stand for? Name one or two values that are really important to you.\n\nCan you describe a time when you stood up for one of your values? \n\nDo you think it’s important to make room for other people’s values even when you disagree with them? Why or why not? \n\nCan you describe a time when you agreed to disagree and it felt good to both of you? \n\nCan you think of a circumstance when you would not agree to disagree, and if so, what would you do instead?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Avatar © Nickelodeon (Paramount Global)"
    },
    {
      "grade": 11,
      "week": 22,
      "name": "Feel People Out",
      "category": "Inner Wisdom",
      "subCategory": "Interpersonal Skills",
      "image": "grade11week22-madedecisiontotrustyou.gif",
      "summary": "Resilient people pay attention to how people make them feel and choose to spend time with good-feeling people.",
      "readAloud": "We can feel in our bodies when someone feels open, kind and trustworthy, and we can feel in our bodies when someone feels edgy, closed off, unkind and untrustworthy. \n\nThe problem is when you’re young you are forced to spend time with people who just happen to be in your class or on your team or in your neighborhood. And you don’t have much choice about the adults in your life either.\n\nThat being said, you can make the most of whatever social group you find yourself in if you get good at “feeling people out.”\n\nIf you tune into how people feel and trust your gut when meeting new people, you will be able to choose friends you can trust to have your best interest at heart. You can also find trustworthy adult mentors to support and guide you. \n\nSo, feel people out and spend time with people who lift you up and inspire you to be the best version of yourself.",
      "questions": "What does it feel like in your body when you meet someone who feels like an open, trustworthy, safe person? \n\nWhat does it feel like in your body when you meet someone who feels like a closed, untrustworthy, or even dangerous person? \n\nObserving people is part of feeling people out. What differences can you observe between good-feeling and bad-feeling people (such as body language, tone of voice, eye contact)? \n\nWhat can you do if you are NOT SURE if someone is a good, safe person? \n\nWhat can you do to keep yourself safe when you meet someone who does not feel good to you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Tangled © Disney Enterprises, Inc."
    },
    {
      "grade": 11,
      "week": 23,
      "name": "Take Care of Yourself",
      "category": "Needs/Values",
      "subCategory": "Spirit",
      "image": "grade11week23-poohexercise.gif",
      "summary": "Resilient people take care of themselves - physically, mentally, emotionally, and spiritually - especially when they are going through a difficult time, when they are feeling stressed, or when they want to perform well.",
      "readAloud": "By the time people get to be adults they often have a million reasons why they don’t take better care of themselves—they’re too busy, there’s not enough time, there are too many other people they have to take care of—so their self-care gets put on the back burner.\n\nAnd when you are a kid, you have to rely on others to take care of you. Even if you want to be thoughtful about your self-care, many aspects of that—from the food you eat, to the activities you engage in, to the people you are surrounded by—are in large part controlled by your parents.\n\nHowever, taking responsibility for taking care of yourself is something best done early in life, so you can build lifelong habits that support your mental, physical, emotional, and spiritual wellbeing.\n \nWhen people think about self-care, they often think about nurturing their bodies with healthy food, exercise and plenty of water and sleep. It’s also important, though, to take care of your mind and spirit.\n \nThere are lots of things you can do to support your mental and emotional health, including surrounding yourself with good-feeling people, having people you can talk to when you are upset, not keeping your thoughts and emotions bottled up, making time for people and activities you enjoy, not being too critical of yourself, and seeking out things that make you laugh.\n \nAnd spiritual health can be supported by engaging in calming activities like meditation and journaling that help you soothe yourself and collect your thoughts as well as anything that nourishes your spirit by giving your life meaning and a sense of purpose.\n \nBottom line: take responsibility for taking care of your own mind, body and spirit, and you will show up as the best version of yourself every day.",
      "questions": "How do you take care of your body?\n\nHow do you take care of your mind?\n\nHow do you take care of yourself emotionally when you are feeling upset about something?\n\nWhy do you think it helps people feel better emotionally to talk about their problems with a supportive person instead of keeping it bottled up inside of them?\n\nHow do you take care of your spirit?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Winnie the Pooh © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 24,
      "name": "Know That Thoughts and Emotions Match",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade11week24-sadnessinsideoutimouttapizza.gif",
      "summary": "Resilient people know their thoughts and emotions always match, so they don’t make important decisions when they’re overwhelmed with negative emotions.",
      "readAloud": "Our thoughts and emotions always match. If we are overwhelmed with negative emotions, we will have overwhelmingly negative thoughts. You simply can’t think positive thoughts when you’re feeling negative emotions. \n\nIn fact, when you are flooded with negative emotions, your thoughts tend to spiral in the negative direction making everything you think about seem worse.\n\nThe best thing to do to stop the spiral is to stop thinking. Distract yourself with something that will make you feel better emotionally, like going for a walk, listening to a great song, or hanging with a caring friend.\n\nDistraction gets a bad rap, but it’s actually an important self-care tool when it’s used to help you shift to a better-feeling emotion so you can then have access to better-feeling thoughts. In other words, you don’t want to distract yourself from all negative emotions. But you do want to be able to distract yourself from a negative thought spiral until you can feel good enough to think constructively about the situation.\n\nEven when we cannot change a situation, we can always do something to feel a little better, which will allow us to think better-feeling thoughts and find solutions. That’s why, knowing what works for you when you want to shift out of a negative emotion is an important resilience skill. ",
      "questions": "What is something that works for you when you want to shift out of a negative emotion?\n\nFor many people, doing something physical like going for a run or getting a hug from someone who loves you is a good way to feel better. Is there something physical that works for you when you want to get out of a bad mood?\n\nDoing something you’re good at, like drawing, singing, or shooting hoops, is another good way to shift your emotions. Do you have a strength you can use to feel better?\n\nDoing something kind for others is another way to feel better. Have you ever experienced that?\n\nHow can you tell if you’re using distraction too much?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Inside Out ©”The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 25,
      "name": "Pay Attention to How You Come Across to Others",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade11week25-grumpy.gif",
      "summary": "Resilient people pay attention to how they come across to others, which helps them show up as their best selves and avoid being misunderstood. It\u0027s okay to be grumpy sometimes, but you might not want people to think you\u0027re always grumpy. ",
      "readAloud": "While it is often good advice to “just be yourself” and not worry about what other people think of you, there is something else to consider. If you are not aware of how you come across to others, you might be misunderstood. \n\nShyness can come across as not being interested. Insecurity can come across as arrogance. Humor can sometimes feel insensitive. Excitement can sometimes feel overwhelming.\n\nObviously, these types of misunderstandings can have a huge impact on your ability to make friends and have close relationships. \n\nThe best way to find out how you come across to others is to ask, but make sure you ask people who genuinely care about you because this kind of feedback can be hard to hear. If that feels too scary, just pay attention to the following when you are interacting with others:\n\n* What is your tone of voice like?\n* What is your body language saying?\n* Do you interrupt people or listen well?\n* Do you tend to be more positive or negative?\n* Do you prefer to joke around or be more serious?\n* Do you use sarcasm to cover up your true feelings? \n\nEven if you don’t end up changing anything about how you present yourself, checking in to confirm how you come across to others gives you valuable information. ",
      "questions": "Have you ever been in a situation where someone got the wrong impression of you? If so, what could you have done differently to prevent that? \n\nHow do you want to come across to others? What words would you use for the impression you want to give? \n\nDo you present a different side of yourself to different people? For example, do you present a different side to your friends than you do to your parents? \n\nAre there people in your life who always make you feel good when you interact with them? If so, what can you learn from them and how they present themselves that could help you? \n\nCan you think of someone you know who doesn\u0027t realize how they come across? If so, what advice would you give them if they wanted it?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Snow White © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 26,
      "name": "Have Practices and Rituals That Nourish Your Spirit",
      "category": "Spirit",
      "subCategory": "Mind-Body Skills",
      "image": "grade11week26-patrickmeditating.gif",
      "summary": "Resilient people take care of themselves by doing things that add meaning to their lives and connect them to something greater than themselves.",
      "readAloud": "It’s easy to get so wrapped up in our own daily struggles that we forget to look up. We forget to notice the awesomeness right in front of us—of nature, of space, of the human mind and body.\n\nBut connecting ourselves to these awe-inspiring things can nourish our spirits and give meaning to our lives. And finding ways to soothe our souls in a world that feels chaotic and out of control is really important. \n\nGoing to a religious service on Sunday is a weekly ritual that comforts and connects many people—to God and to a supportive community. Saying prayers at bedtime is a daily practice that nourishes the spirit. \n\nBut rituals and practices do not have to be religious. Practices like meditation and keeping a gratitude journal, and rituals like family dinners and spending time in nature can feed our souls, give meaning to our lives, and connect us to something greater than ourselves. \n\nSo, make time for uplifting rituals and practices and daily life will feel a lot more inspiring and meaningful.",
      "questions": "Some rituals are yearly, like celebrating religious holidays and birthdays. What rituals do you do every year and how do they make you feel?\n\nMany people have weekly rituals like going to religious services or having dinner with loved ones. What rituals do you do weekly and how do they make you feel?\n\nSome people have daily practices that calm and comfort them like meditating, journaling, or saying prayers. What practices do you try to do every day and how do they make you feel?\n\nWhat other rituals and practices give meaning to your life?\n\nWhat other rituals and practices connect you to something greater than yourself?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 11,
      "week": 27,
      "name": "Notice What You Enjoy Doing and Do More of It",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade11week27-thisisfun.gif",
      "summary": "Resilient people know that everyone has a unique set of strengths and interests and making time for them builds self-esteem and adds balance to their lives.",
      "readAloud": "Many of the things we have to do as kids are not exactly enjoyable. We have to do our homework. We have to practice our instrument. We have to study for the SAT. And while these things are important if we want to create opportunities for ourselves in the future, it’s also important to balance out our schedules with things we actually enjoy.\n\nIf we spend all of our time checking someone else’s boxes or trying to make our college applications look good, we can turn into adults who have forgotten what they enjoy, what comes naturally to them, and what they are genuinely interested in.\n\nBut nurturing our own interests and strengths is important, not only because all work and no play is not good for anyone’s mental health, but also because it builds self-esteem. \n\nKnowing and using our personal strengths builds self-esteem.\n\nThink about it–we feel good about ourselves when we do things we’re good at. And, we get good at things when we are interested in them and want to spend time doing them.\n\nEach of us is unique in wonderful ways, and we are our best when we express our special combination of strengths and interests.\n\nSo make time for things you do just for you–because they’re interesting to you, fun for you, and they make you feel engaged, alive and uniquely you. In other words, notice what you enjoy doing and do more of it!",
      "questions": "What is one of your personal strengths?\n\nWhat is one of your personal interests?\n\nDoes it make sense to you that owning and using your personal strengths builds self-esteem? Why or why not?\n\nDoes it make sense to you how interests can turn into strengths? Why or why not?\n\nDo you agree that it’s important to balance out hard work with things you do for fun and because they’re interesting to you? And do you have a good enough balance of those things in your life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Tangled © Disney Enterprises, Inc."
    },
    {
      "grade": 11,
      "week": 28,
      "name": "Use Negative Emotions to Take Care of Yourself",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade11week28-selfcare.gif",
      "summary": "Resilient people know that negative emotions provide opportunities to take care of themselves, so instead of ignoring them, they listen to what they need to feel better.",
      "readAloud": "Most people will do anything to avoid feeling bad. This includes stuffing negative emotions away and trying desperately to ignore them. But the thing about negative emotions is they get bigger when we pretend they don’t exist. And then they burst through in moments when we overreact or feel overwhelmed.\n\nNegative emotions are natural responses to the inevitable disappointments, setbacks, challenges, and failures that everyone–seriously everyone–must face. And if we stop pushing against them, we can actually learn alot from our negative emotions and use them to take care of ourselves. \n\nHow do you use negative emotions to take care of yourself? You allow them to tell you what you need to feel better.\n\nAnger is often telling us we need to be treated better. Sadness is often reminding us of something we will miss and need more of in our lives. Fear is usually pointing out something we need in order to feel safe and secure.\n\nSo, the next time you notice you are upset, ask yourself, \"What do I need to feel better?\" \n\nThen, take an action step to take care of yourself.\n\nEven when you cannot change the situation, there will be action steps you can take to take care of yourself.",
      "questions": "Can you describe a time when you used negative emotions to take care of yourself?\n\nHave you ever tried to stuff down a negative emotion only to have it burst through later in a moment when you overreacted or felt overwhelmed?\n\nMany adults don’t like to deal with negative emotions from kids. Have you ever been told to keep certain emotions to yourself? If so, how did that make you feel? \n\nWould you say you are pretty good at listening to your negative emotions and understanding what you need to feel better, or do you tend to stuff your emotions down and try to ignore them, and where did you learn to do that?\n\nAnger can be a difficult emotion to manage. How do you take care of yourself when you’re feeling really angry?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. My Little Pony © Hasbro"
    },
    {
      "grade": 11,
      "week": 29,
      "name": "Don\u0027t \"Catastrophize\"",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade11week29-lisacatastrophize.gif",
      "summary": "Resilient people know that words have power, so they use words that inspire them to find solutions instead of cause them to give up.",
      "readAloud": "“Catastrophizing” is exaggerating how bad something is to make it seem like a catastrophe—like an absolute failure, like a hopeless situation, like the end of the world.\n\n\"Coach John is the worst coach ever. He hates me. He never says anything helpful. He always makes me feel bad.”\n\n“Ms. Johnson is the meanest teacher in the whole world. She is never nice to me. She always picks on me.”\n\n“This is the hardest class ever. I don’t understand anything the teacher is saying. I will never be able to do this.”\n\nYou can feel the power of these words in your body. They make you feel hopeless.\n\nWords like \u0027never\u0027 and \u0027always\u0027 cause us to shut down and give up.\n\nThey also make the people who might help us feel like we are a lost cause.\n\nSo, stop yourself from exaggerating the negative and try to use words that inspire you to problem solve and take care of yourself.",
      "questions": "Sometimes it is easier to recognize “catastrophizing” in others than in ourselves. Do you know anyone who “catastrophizes?” If so, how does it make you feel? \n\nWhy do you think words like \u0027always\u0027 and \u0027never\u0027 make us feel hopeless when used to describe negative things?\n\nCan you describe a time when you “catastrophized?” If so…How did people respond to you? What could you have said differently? \n\nCan you describe a time when you stopped yourself from “catastrophizing” and used words that inspired you to find a solution?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 30,
      "name": "Have a Changeable Mind",
      "category": "Beliefs",
      "subCategory": "Interpersonal Skills",
      "image": "grade11week30-evenwhemI\u0027mwrong.gif",
      "summary": "Resilient people know that they don’t know everything and they’re not always right, so they take in new information and are open to changing their minds.",
      "readAloud": "No one is always right. There is never just one way to look at a situation. People who are unwilling to consider other people’s points of view are not fun to talk to. They are not even fun to be around.\n \nLife is a process of learning and growing, taking in new information, and adapting to changing situations. Being able to think through things and be flexible allows you to find creative solutions and have better-feeling relationships.\n \nWe have all experienced how bad it feels to have an argument with someone who just has to be right and doesn’t even try to understand our side of things. And we’ve all experienced how great it feels when someone really listens to what we have to say and allows us to change their mind a little.\n \nThis is how we grow as individuals and how we strengthen our relationships.\n \nBeing willing to change your mind is a sign of intelligence and curiosity and empathy. So the next time you have the opportunity to defend your point of view, do it. But also, allow the other person to do the same and do your best to understand what they are saying. It doesn’t have to be a “win/lose” situation.",
      "questions": "Can you describe a time when you listened to someone else’s perspective and changed your mind about something?\n\nCan you describe a time when you changed your mind in an argument and actually apologized for something?\n\nPolitics, religion, and certain lifestyle choices are topics people can be inflexible about. What other topics do people have a hard time changing their minds about or even talking about?\n\nDo you agree that it is a good thing to be willing to change your mind? Why or why not?\n\nWhat can someone do in a discussion that makes you more likely to hear their perspective?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Meet The Robinsons © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 31,
      "name": "Be Quiet (Sometimes)",
      "category": "Mind-Body Skills",
      "subCategory": "Inner Wisdom",
      "image": "grade11week31-monstersincshhhh.gif",
      "summary": "Resilient people know there is a source of peace and wisdom inside of us all, and the way to get in touch with it is to be quiet.",
      "readAloud": "How often do you ever just sit and do nothing? Really nothing—no screens, no music, no conversation. \n\nIf you are like most people, you are so busy racing through each day trying to get everything done, you barely have enough time to sleep—much less to “waste” precious time doing nothing. \n\nBut taking time to be quiet is doing something—something very important. It\u0027s allowing our minds and bodies to calm AND tuning in to the guidance available from our own Inner Wisdom and from whatever spiritual sources of wisdom we believe in.\n\nWhat could be more important than that?\n\nIn fact, let\u0027s take 2 minutes right now, and try it together: \n\n1. First, close your eyes, if that feels good. You don’t have to.\n\n2. Now take three slow, deep breaths and focus on relaxing your body while you breathe. (Mentors, allow time for 3 breaths.)\n\n3. Take three more slow, deep breaths and notice how it feels in your mind and in your body to be quiet and breathe and relax. (Mentors, allow time for 3 breaths.)\n\n4. Now, keep breathing and relaxing and think of a challenge you have in your life or a decision you need to make, and ask yourself, “What do I need to know about this?”\n\n5. Then, listen inside for a few breaths and see if an answer comes to you. (Mentors, allow time for 3 breaths.)\n\n6. Finally, take 3 more deep, relaxing breaths, and when you are ready, come back to the room—knowing you can always come back to this peaceful place with just a few breaths.",
      "questions": "How did it feel to do that? What was going on in your mind and body? \n\nDid you get an answer to your question? You don’t have to share the answer, just share whether or not you got one. \n\nDo you ever sit and do nothing, or do you get out a screen or do something else to entertain yourself? \n\nDo you ever do any meditation? If so, what do you do? \n\nIf you are a spiritual person, how do you tune in to guidance from whatever higher power you believe in?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Monsters Inc. © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 32,
      "name": "Make Sure Your Expectations Bring Out the Best in You",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade11week32-homernotperfect.gif",
      "summary": "Resilient people know that the expectations we have for ourselves shape our lives, so they make sure their expectations bring out the best in them.",
      "readAloud": "The expectations we have for ourselves have real power. They can inspire us to reach for our dreams or discourage us from living up to our potential.\n\n* If we expect perfection, we will always be disappointed in ourselves.\n* If we think things should be easy, we will not get in the habit of working hard.\n* If we believe we should be able to succeed without seeking feedback, we will miss opportunities to learn, grow and have mentors.\n* And if we focus on avoiding failure, we will not take risks that could bring great rewards.\n\nOn the other hand, if we expect there to be twists and turns, disappointments and triumphs, failures and successes, we will be open to what life is offering and more able to go with the flow.\n\nWe want to be like Goldilocks when setting expectations–not setting them so low that we fail to push ourselves out of our comfort zones nor setting them so high that we judge ourselves too harshly. Somewhere in the middle is where we can expect ourselves to mess up sometimes but believe we can recover.\n\nSo, set expectations that inspire you, and you will enjoy more of the journey. ",
      "questions": "Why do you think so many people struggle with perfectionism today, and what advice would you give someone about not having to be perfect?\n\nWhat is something that did not come easy to you but you worked hard to learn or improve?\n\nAre you good at hearing feedback or do you take it as criticism?\n\nCan you describe a time you failed at something but learned from it?\n\nWould you say your expectations for yourself are too low, too high, or just right, and why?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 33,
      "name": "Have Heroes and Mentors",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade11week33-simpsonhero.gif",
      "summary": "Resilient people know having people who inspire and support them is key to their success, so they are constantly looking for heroes and mentors.",
      "readAloud": "Our beliefs shape our lives. If we believe it is possible for someone like us to be a… professional athlete, famous chef, successful doctor, or even an astronaut or President of the United States, we might try to achieve that for ourselves. \n\nIf we do not believe it is possible, we will not even try. \n\nWhat makes us believe something is possible? Seeing others succeed. \n\nThat’s where heroes come in. Having heroes helps us believe in our own dreams. It also helps us understand what it takes to achieve big things, as we can learn about our heroes’ journeys to success. Usually, this means reading about them or watching movies or videos about them, but not actually talking to them, because heroes are often famous or might not even still be alive.\n\nThat’s where mentors come in. Mentors are people we can actually talk to about the things we are trying to achieve. Mentors are willing to share their knowledge and experience and support us in achieving our goals. Why? Every mentor has their own reasons, but you might be surprised how many people are willing to help when a passionate young person has the courage to ask. \n\nSo be on the lookout for people who inspire you to dream big, and then find people who will support you in achieving those dreams.",
      "questions": "Who is one of your heroes, and what do you admire about them?\n\nDo you want to be someone’s hero one day, and if so, what will people admire about you?\n\nWhy do you think having a mentor is helpful for people?\n\nIs there anything you are trying to achieve that it would be helpful to have a mentor for?\n\nWould you be willing to be a mentor one day, and if so, why?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company.  The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 11,
      "week": 34,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade11week34-nemo.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. This year we have talked about: \n\n* Shift your mood by shifting your focus\n* Talk about your mental health\n* Be mindful of your body\n* Ask for what you need\n* Feel into your decisions\n* Use your words wisely\n* Practice tolerating discomfort\n* Listen, instead of defending yourself\n* Pay attention to how you come across to others\n* Have practices and rituals that nourish your spirit\n* Feel people out\n\nThese are just a few of the resilience skills we have covered this year. You were already resilient, and now you have even more skills to choose from when you come to a bump in the road.",
      "questions": "Can you describe a way you have been resilient this year?\n\nAre there any skills from this Resilience Reminders™ program that you learned or tried that worked for you?\n\nWhat words do you use to define resilience? \n\nDo you think talking about these types of things is helpful for students and families? Why or why not?\n\nDo you believe in reaching out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Finding Nemo © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 1,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade12week1-bartbounce.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. \n\nThe other good news is you are already resilient! You have probably picked up quite a few resilience skills already in life. For example, if you…\n\n* Can name your personal strengths\n* Have good-feeling relationships\n* Reach out for support when you need it\n* Take care of yourself–physically, mentally, emotionally and spiritually\n* Learn from your losses\n* Use your breath to calm down and focus\n* Trust your gut\n\nIf you do any of these things, you are already resilient. \n\nThese Resilience Reminders™ will add more skills to your list and remind you of helpful things you already know.",
      "questions": "How do you define resilience? What words do you use? \n\nHow do you demonstrate resilience? Which skills do you use? \n\nWhat disappointment, challenge or failure are you most proud of yourself for overcoming, and what skills did you use to feel better?\n\nWhat is one way you take care of yourself–physically, mentally, emotionally or spiritually?\n\nDo you agree that it demonstrates resilience to reach out for support when you need it? Why or why not? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 2,
      "name": "Have Good Feeling Talk Habits",
      "category": "Interpersonal Skills",
      "subCategory": "Emotional Intelligence",
      "image": "grade12week2-heyarnoldlistentoyourself.gif",
      "summary": "Resilient people know that words have power so they get in the habit of being more positive than negative in conversations.",
      "readAloud": "At any given moment, there are lots of things to complain about. Whether it’s something in your personal life or something in the news, there are always reasons to be upset. And, expressing negative emotions is not necessarily a bad thing. In fact, if you are upset about something, talking about it and getting support is a great way to take care of yourself.\n \nOn the other hand, there’s a difference between getting support and just venting or being negative. Often people don’t realize how negative they sound, but it doesn’t feel good to be around people who are always complaining or criticizing.\n \nAnd the truth is we have a choice about what we talk about—even when life is not going our way, we get to choose the words that come out of our mouths. And speaking words that focus on the more positive side of things will make you feel better. It will also make others feel better.\n \nAgain, negative emotions are not bad. However, you can make yourself and those around you feel better if you choose to inject some positivity into your conversations sometimes too. Some researchers say the ideal praise-to-criticism ratio is 5 to 1. Meaning, for every negative comment you make, you need to share five positive comments as well.\n \nWhatever you decide is the right ratio for you, becoming aware of your talk habits will give you a powerful tool for feeling better.",
      "questions": "What word would you use to describe how you show up in conversations: positive, negative, supportive, critical, or any other word that comes to mind?\n\nWhat is your praise-to-criticism ratio, and what would you like it to be?\n\nHow do you want people to feel around you?\n\nIn what ways do you already seek out positive conversations or add positivity to conversations?\n\nCan you think of any negative talk habits you could do less of, like gossiping about, criticizing, or blaming others?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc."
    },
    {
      "grade": 12,
      "week": 3,
      "name": "Visualize Success",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade12week3-imaginationflying.gif",
      "summary": "Resilient people believe in the power of their imagination, so they spend time visualizing success.",
      "readAloud": "Every successful athlete imagined winning the championship game. Every successful musician imagined playing sold out stadiums. Every successful astronaut imagined blasting off into space. \n\nVisualizing success is a way to convince yourself that something is actually possible. And when you believe something is possible, you take steps to make it happen. \n\nNot only that, research shows that practicing things in your mind, actually makes you better in real life. In one study, people who imagined shooting basketball shots for 30 days improved almost as much as people who actually practiced with real basketballs! \n\nOur minds are powerful tools. \n\nSo, put away your screens for a little while and spend some time visualizing yourself achieving one of your dreams. \n\nIn fact, let’s try it right now:\n\n* Close your eyes, if you’re comfortable doing that.\n* Take a deep breath and blow it all the way out.\n* Now picture yourself practicing something you really want to get better at. I’ll give you 10 seconds to imagine that.\n* Now take another deep breath and picture yourself achieving one of your goals and celebrating your success. I’ll give you another 10 seconds to imagine that.\n* Now take a final deep, relaxing breath, and come back to the room.",
      "questions": "How was that visualization for you? What did you see? How did it feel? \n\nPracticing things in your mind is also called mental rehearsal, and lots of athletes do it. Why do you think it is helpful? Have you ever done it? \n\nImagining yourself achieving one of your dreams is also called daydreaming. Why do you think that is helpful? What do you daydream about? \n\nVisualizing success raises your expectations for yourself. Why do you think having high expectations for yourself helps you achieve things but having low expectations for yourself prevents you from achieving things? \n\nIt\u0027s not always easy for people to start visualizing success for themselves. What do you think could help someone who has a hard time with it? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Peter Pan © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 4,
      "name": "Focus on What’s in Your Control",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade12week4-simpsonsundercontrol.gif",
      "summary": "Resilient people don\u0027t waste time focusing on things they cannot control. When faced with an obstacle, they look for something within their control to move forward and feel better.",
      "readAloud": "When you are upset about something, it’s easy to think something outside of you needs to change in order for you to feel better. Your parents need to be less strict. Your friends need to be nicer. Your coach needs to play you more.\n\nBut other people’s behavior is out of your control. You can ask them to be different, but their behavior is out of your control. So are many other aspects of your life.\n\nOn the other hand, you can always find an action step within your control that will allow you to feel better—even if you cannot change the other person\u0027s behavior or the situation. This action step might not fix the situation, but it will put you back in control of your life, which will feel empowering and get the ball moving in the right direction.\n\nSo, when faced with a disappointment or obstacle, ask yourself this question:\n\n“What is an action step I can take—something within my control—to feel better?”\n\nLet’s say, for example, your coach isn’t playing you as much as you’d like. Focusing on how mad you are will feel bad.\n\nBut if you identify an action step—like asking your coach what you need to do to get more playing time, or putting in extra practice on your own, or reading about how the athletes you admire got so good—you will feel more in control of your own life and you will be moving toward your goal of playing more.  \n\nThere is always something you can do to take care of yourself and feel better, so get in the habit of focusing on what you can control when life is feeling challenging",
      "questions": "Can you describe a time when life didn\u0027t go your way, but instead of getting discouraged you took an action step to move forward?\n\nCan you describe a time when a person would not do what you wanted, but you took an action step to take care of yourself anyway?\n\nWhat is something you can always do to feel better even when you cannot control a frustrating situation?\n\nWhat do you say to yourself when life doesn\u0027t go your way and you don\u0027t want to give up on something you care about? \n\nIs there anything you are upset about right now that you could decide either to stay angry about or take an action step to feel better about? What could you do to make yourself feel better?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 5,
      "name": "Don’t Just Think, Check Your Gut Feelings Too",
      "category": "Inner Wisdom",
      "subCategory": "Spirit",
      "image": "grade12week5-newstarwarsbadfeeling.gif",
      "summary": "Resilient people know their thoughts can be misleading, so they check in with their gut feelings too.",
      "readAloud": "Sometimes we think things that are not true: “I’m not good enough.”\n\nMany times we think things that hold us back: “I shouldn’t even try.”\n\nOften we don’t think things through: “I’m sure it’ll be fine. Just do it.”\n\nOur thoughts do not always serve us, but the good news is, our gut feelings will lead us in the right direction—if we learn to trust them.\n\nWe can feel in our bodies whether something feels good, safe and inspiring, and we can feel in our bodies when something feels sketchy, questionable, or fear-based.\n\nWhether we listen to them or not, our gut feelings are there.\n\nSo the next time you have a decision to make, take a moment to check in with your gut feelings. Close your eyes, take a deep, relaxing breath, and ask yourself, “What does my Inner Wisdom have to say about this? Which is the best decision for me?”\n\nIf it still isn’t clear, imagine making one decision and notice how that feels. Then imagine making the other decision, and notice how that feels.\n\nThere is a part of us that knows what we truly desire and what is best for us, and it never gets misled by negative thoughts or limiting beliefs. So, tune in!",
      "questions": "\nSome people call it a gut feeling. Others call it instinct, intuition, Inner Wisdom or their higher self. What words do you use to describe the way you can feel in your body whether something is good or bad for you? \n\nCan you describe a time that you listened to your gut feelings when making a decision? \n\nCan you describe a time when you didn’t listen to your gut feelings—you knew they were there, but you chose to ignore them? \n\nHow can you tell the difference between something you are thinking and something your Inner Wisdom is trying to tell you? \n\nCan you describe a time when your gut feelings protected you from danger?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 6,
      "name": "Know Your WHY",
      "category": "Needs/Values",
      "subCategory": "",
      "image": "grade12week6-whyareyoudoingthat.gif",
      "summary": "Resilient people know why they do what they do. This motivates them to do the things that aren’t that fun and keeps them on track to achieve their goals.",
      "readAloud": "Let’s face it. We all have to do things that aren’t that fun in order to succeed in life and achieve our goals. If you want to get good at playing an instrument or singing, you have to practice, practice, practice. If you want to have a healthy body, you have to eat well and exercise. If you want to have lots of career opportunities, you have to study hard and do well in school. \n\nEven the most glamorous careers require some things that aren’t that fun: Professional athletes have to work out to exhaustion and eat well almost every day. Doctors have to show up for emergencies in the middle of the night. Successful business people have to get up early, work long hours, and attend boring meetings when they’d rather be relaxing and hanging with friends.\n\nBut successful people know what matters to them and why they do things, and this motivates them to push through the bad stuff. Resilient people are the same. When faced with a challenging, annoying or boring activity, they remind themselves of the purpose of the task, and get to work.\n\nAnd let’s not forget about parents: Parents take care of their kids when they’re sick, drive them all over creation, cook for them, clean for them, pay for them, and support them through thick and thin… even when their kids are crabby and rude. Why? Because they love them and value them and care about being good parents.\n\nThat’s the power of WHY–it gives meaning and direction to our lives and inspires us to show up as our best selves in the hard moments.",
      "questions": "What is something you don’t particularly enjoy doing, but you do it anyway, and why do you do it?\n\nWhat is one goal you have for yourself that requires doing some things that aren’t that fun?\n\nWhat is something you are proud of yourself for doing?\n\nWhat is a career you might be interested in, and what is a not-so-fun requirement of that career?\n\nWhat is a character trait that is important to you that requires you to make hard choices sometimes? (Being honest, disciplined, loyal, not gossiping, a good friend)",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Family Guy © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 7,
      "name": "Harness the Power of the Mind-Body Connection",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade12week7-homerrelaxing.gif",
      "summary": "Resilient people know their minds have the power to make their bodies feel better, and their bodies have the power to make their minds feel better.",
      "readAloud": "We all know that our minds and bodies are connected. When our minds are worried, our bodies get tummy aches. When our minds are angry, our bodies get tense muscles. When our minds are sad, our bodies produce tears.\n\nWe might not realize that we can use our minds to make our bodies feel better, and use our bodies to make our minds feel better.\n\nWhen we notice that our mind is upset about something, we can do something physical like taking some deep breaths, or going for a walk, or shooting some hoops and we will feel better mentally.\n\nAnd when we notice we feel stress in our bodies, we can imagine in our minds relaxing our bodies and we will feel better physically.\n\nLet\u0027s do it right now:\n\nTake three slow, deep breaths and imagine the muscles in your body relaxing and calming down a little bit.\n\n(Take 3 breaths.)\n\nWhen your mind relaxes, your body relaxes too–and vice versa–because they are connected.\n\nSo pay attention to what\u0027s going on in your mind and body and use the mind-body connection to feel better mentally and physically.   ",
      "questions": "How does anger show up in your body? What about excitement or happiness? \n\nCan you describe a time when you were upset about something and you felt better after doing something physical? \n\nHow about the opposite: Can you describe a time when you made yourself feel better physically by focusing your mind on something that felt better? \n\nHow does your mental state affect you in sports? Do you notice a difference in how your attitude affects how well you play? \n\nFocusing your mind on the sound and feel of your breath is the quickest way to connect your mind to your body. Can you describe a time when you used your breath to relax mentally and physically?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 8,
      "name": "Find Evidence that Supports your Desires",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade12week8-arnoldevidence.gif",
      "summary": "Resilient people know they must believe something is possible for it to come true, so they actively look for evidence that supports their desires.",
      "readAloud": "If you want something, but believe it is impossible, you will not even try to make it happen. But often, the beliefs that hold us back are not necessarily true. Someone told us something was true, we believed them, and now we have a lot of evidence that it is true.\n \nBut here’s the thing: We think we believe things because they are true, but it’s actually the opposite. They are true because we believe them.\n \nLet’s say that again. We think we believe things because they are true, but they are true because we believe them.\n \nWhen we believe something, we only see evidence that supports it. We do not see evidence that contradicts it.\n \nFor example, if you believe people are not trustworthy, you will look for evidence that you are right, and you will overlook evidence that you are wrong. If you believe you are not smart enough or talented enough to succeed at something, your list of weaknesses will be much, much longer than your list of strengths.\n \nThis is just how the human mind works—it is wired to look for the negative in order to protect us from danger.\n \nThe good news is, if we look for evidence that supports our desires we can almost always find it. Just remember, your mind is in the habit of looking for the negative. If you can break that habit, you will open up a whole new world of possibilities for yourself.",
      "questions": "Have you ever changed your mind and replaced a negative belief about yourself with a more positive one? If so, how did that work out for you?\n\nWhat is something you really want for yourself, and what is one reason why it just might be possible?\n\nCan you think of a belief that holds you back, and then think of one piece of evidence that something better might be true?\n\nCan you think of an example of how a belief only allows you to see evidence that it is true and makes you overlook evidence that it is not true?\n\nCan you describe a time when you believed something positive about yourself when others didn’t and it came true?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc."
    },
    {
      "grade": 12,
      "week": 9,
      "name": "Find Your \"Flow\"",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade12week9-shoothoops.gif",
      "summary": "Resilient people give their minds a break by getting lost in activities they enjoy. ",
      "readAloud": "Most of us spend a lot of time forcing our minds to do several things at once—like doing our homework in front of the television while carrying on a conversation. \n\nBut multitasking is stressful, and sometimes our minds need a break. One of the best ways to give our minds a break is to “single-task” something we enjoy.\n\n“Flow” is the mental state in which you are so fully engaged in whatever you are doing you lose all sense of time and place. Some call it being “in the zone.” \n\nAthletes achieve flow when they are so engrossed in their sport they don’t even notice what’s going on around them. Artists feel flow when they get lost in the act of painting. Writers experience flow when the words come pouring out and hours go by unnoticed.\n\nFinding activities that allow you to feel highly engaged and singularly focused will greatly improve your life. These activities allow the world—and your worries—to drop away. They also boost your self-esteem and sense of empowerment, because they allow you to demonstrate a strength and accomplish something you value.\n\nSo, find your flow and give yourself a mental and emotional boost.  ",
      "questions": "Can you describe a time when you experienced “flow”—when you were so engaged in doing an activity that you lost track of time and of your surroundings for a little while? \n\nCan you name a physical activity (like shooting hoops, dancing, or kicking a soccer ball) that allows you to experience “flow” ? \n\nCan you name an artistic activity (such as drawing, playing a musical instrument, or singing) that allows you to experience “flow”? \n\nCan you name a mental activity (like some kind of puzzle or chess) that allows you to experience “flow”? \n\nWhich activities can you do well while multitasking, and which ones do you need to single-task to do well?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 12,
      "week": 10,
      "name": "Have the Courage to be Real",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade12week10-spongebobdontfeellikeit.gif",
      "summary": "Resilient people know that we build connections with people when we are open and real, so they have the courage to be vulnerable.",
      "readAloud": "When life gets crazy, scary or confusing it really helps to have people you can be honest with. After all, how can you get support for what you’re dealing with if you don’t open up about what’s really going on for you mentally and emotionally?\n \nSometimes people think they should hide their true feelings, especially if those feelings make them seem insecure, not tough enough, or uncool in some way. They worry that people will judge them if they open up and be vulnerable. Some believe it’s weak to need emotional support.\n \nBut the truth is, deep connections are not built when people pretend to be perfect. They are formed when people have the courage to share their insecurities and imperfections, because everyone has them and we can all relate to them.\n \nYou can’t know this until you try it, which is why it takes a lot of courage. And some people are not safe to be vulnerable with. But if you pay attention to how people make you feel, and seek out people who feel non-judgmental and trustworthy, you can surround yourself with relationships that lift you up when life gets you down. And you can do the same for others.",
      "questions": "Do you agree that you connect with people who are open and real rather than people who don’t share anything personal?\n\nCan you think of a reason it is good to have people you can be vulnerable with?\n\nHow can you tell if someone is trustworthy, non-judgmental and safe to be vulnerable with?\n\nWhat do you do to let people know that you are not judgmental and that they can open up to you?\n\nIt is difficult to be with someone when they are having a strong negative emotion like sadness, anger, or fear. Think back to a time you felt that way and tell us what you needed from someone. How could they have helped you?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 12,
      "week": 11,
      "name": "Stop Trying to Control Everything and Be Open to What Life is Offering You",
      "category": "Spirit",
      "subCategory": "Beliefs",
      "image": "grade12week11-bartsurprise.gif",
      "summary": "Resilient people know that much of life is out of their control, so they stay open to what life is offering and expect pleasant surprises.",
      "readAloud": "One of the most frustrating aspects of life for many people is that so much of it is out of our control. We want to believe that if we just work hard enough, do the right things, be a good person, and try, try, try, we will get what we want. \n\nBut life doesn’t always work that way. In fact, sometimes it can feel like life is totally out of control no matter how hard we’re trying to manage things.\n\nIt’s natural to feel disappointed when we don’t get what we want.\n\nBut, if we remind ourselves that sometimes our lives take unexpected turns that end up being for the better, we can open to the possibility that not getting everything we think we want might actually be okay. \n\nIn fact, many of the best things in life just happen to us without us making them happen. \n* A new family moves in next door, and the daughter becomes your best friend.\n* A teacher recommends you for a scholarship you didn’t even know about, and that allows you to go to the college of your dreams.\n* You get cut from the soccer team, so you decide to run track, and the team goes on to win the state championship.\n\nWe all have examples of life taking us places we didn’t choose but that turned out to be full of blessings.\n\nSo, the next time you feel disappointed that life isn’t going as you planned, ask yourself: “What if this is actually better?”\n\nAnd notice how this powerful question opens you up to being pleasantly surprised.",
      "questions": "Can you think of a time you were pleasantly surprised?\n\nCan you think of something great that happened in your life that you didn’t make happen - it just happened?\n\nCan you think of a time when you didn’t get what you thought you wanted but you ended up being glad it turned out that way?\n\nDo you consider yourself open and flexible, or do you really like to control things?\n\nThink of the best things about your life… how many of them to did YOU make happen?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 12,
      "name": "Practice Gratitude",
      "category": "Emotional Intelligence",
      "subCategory": "Interpersonal Skills",
      "image": "grade12week12-pandagratitude.gif",
      "summary": "Resilient people know that gratitude improves not only your mental health but also your physical health, so they make it a practice to be grateful.",
      "readAloud": "It makes sense that gratitude is good for your mental health, but studies show that gratitude improves our physical health too! Gratitude also improves relationships.\n\nThanksgiving is all about gratitude. But we can forget to look for things to be grateful for during the rest of the year. \n\nIt’s easy to get so caught up in the stresses of daily life that we focus on what’s going wrong in our lives instead of looking for what’s good. \n  \nThere are always things to be grateful for in our lives. We just have to make an effort to notice them.\n \nThis is why some people practice gratitude the same way they practice a sport. They set aside time every day or every week to think about what they are grateful for. Some people do it every morning. Others do it when they go to bed. Others do it when they attend spiritual services.\n\nHowever you do it, the more you practice gratitude, the better you will feel - and the healthier you will be. ",
      "questions": "Who is a person you are grateful for?\n\nWhat is an experience you are grateful for?\n\nWhat is a quality about yourself you are grateful for?\n\nCan you think of anything you are grateful for that came from something that was not so great?\n\nHow can you remember to practice gratitude more often in your daily life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shred or reproduced without the express written consent of the company. Kung Fu Panda © DREAMWORKS ANIMATION L.L.C."
    },
    {
      "grade": 12,
      "week": 13,
      "name": "Don\u0027t Let Failure Define You, Use it to Grow",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade12week13-muchtolearnyoda.gif",
      "summary": "Resilient people know that failing is a part of life, so they learn from their failures and turn them into stepping stones to success. ",
      "readAloud": "Failing is a part of life—at least for those who are willing to get in the game, push themselves to try new things, and learn from their losses. \n\nIt can be embarrassing to fail. It can make you mad and sad, and it can make you want to give up. But successful people know that failure is a part of the learning process, so they shake off their failures and take the lessons from them so they’ll do better next time.\n\nAlbert Einstein failed the entrance exam to university the first time and almost failed out of university once he did get in, but that didn’t stop him from pursuing his passion for math and physics and eventually winning the Nobel Prize.\n\nOprah Winfrey was fired from her first television job and told she was “unfit for tv,” but that didn’t stop her from becoming one of the most successful television stars ever.\n\nThomas Edison failed over 10,000 times before he created a light bulb he could sell, but he said, “I have not failed 10,000 times…  I have succeeded in proving that those 10,000 ways will not work. When I have eliminated the ways that will not work, I will find the way that will work.”\n\nFailure is only failure if you fail to learn from it and stop trying. So, the next time you fail, ask yourself what you have learned.",
      "questions": "Can you describe a time when you failed at something and learned from it?\n\nCan you think of something positive in your life that could not have happened if you hadn’t failed at something that led up to it?\n\nIs there someone you admire who failed at something and then went on to succeed?\n\nWhat does it mean about you if you fail?\n\nWhat is the biggest risk you ever took, and did you succeed or fail? If you failed, what story do you tell about that failure?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Wars © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 14,
      "name": "Choose Love",
      "category": "Emotional Intelligence",
      "subCategory": "Interpersonal Skills",
      "image": "grade12week14-heartpump.gif",
      "summary": "Resilient people know that love is the best-feeling emotion and most positive force in the universe, so they choose love as often as they can.",
      "readAloud": "It is not always easy to see the best in people or forgive their wrongdoings. But holding grudges feels bad, and giving love feels good. So give love, to others and to yourself.\n\nAnd when you are unsure of what to do, choose love. ",
      "questions": "Can you describe a time when you decided to choose love instead of staying angry at someone? How did that work out? \n\nCan you describe a time when you decided to harbor a grudge against someone? How did that work out? \n\nCan you describe a time when someone surprised you by forgiving you for something when you thought they would stay angry? How did that make you feel? \n\nCan you describe a time when you started to hold a grudge against yourself, but then instead of staying angry at yourself, you decided to choose love for yourself? \n\nDo you agree that love is the most powerful force in the universe? If so, why? If not, what is?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 12,
      "week": 15,
      "name": "Have Beliefs That Comfort You, Guide You, and Give Your Life Meaning",
      "category": "Spirit",
      "subCategory": "Beliefs",
      "image": "grade12week15-poohbeliefs.gif",
      "summary": "Resilient people know that our beliefs shape our lives, so they have beliefs that comfort them, guide them, and give their lives meaning.",
      "readAloud": "This time of year reminds us that spiritual beliefs are a powerful source of comfort and guidance for many people. Some beliefs give meaning to our lives. Some beliefs inspire us to be kind to each other. Some beliefs comfort us around our fears, especially around our fear of death. And many beliefs connect us to supportive communities and nurture a sense of belonging.\n \nRegardless of what you believe, choosing to live your life in alignment with beliefs that give you purpose, connect you to the most loving parts of yourself, and nurture optimism for your future is a choice worth making.\n \nMany people don’t realize that they can choose what to believe. They think that because they already believe something, it must be true. But people believe many different things, some of which are contradictory. Yet somehow, people have evidence for contradictory beliefs. \n\nSo, if you realize you have a belief that does not make you feel very good, look for evidence of something better. If you look hard enough, you will probably find it.\n \nWhatever you believe, we hope it brings you peace, joy and love this holiday season. ",
      "questions": "Do you have a belief that inspires you to be the best person you can be?\n\nDo you have a belief that motivates you to be kind to others?\n\nDo you have a belief that comforts you around any of your fears?\n\nDo you have a belief that gives your life meaning or a sense of purpose?\n\nDo you have a belief that makes you feel optimistic about your future?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Winnie the Pooh © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 16,
      "name": "Set Achievable Goals (Around Strengths and Values)",
      "category": "Strengths",
      "subCategory": "Needs/Values",
      "image": "grade12week16-snoopygoals.gif",
      "summary": "Resilient people believe goals should inspire them and make them feel good about themselves, so they set achievable goals around their strengths and values.",
      "readAloud": "New Year’s Resolutions often start out as inspiring but end up making us feel disappointed in ourselves if we fail to achieve them. Here are some tips for setting goals that will make you feel good about yourself:\n\nSet goals that are small enough that you can actually get them done. If you set goals that are too big, you’ll give up, feel bad about yourself, and be less likely to set future goals for yourself. On the other hand, if you set goals you can actually achieve, you will build positive momentum that allows you to achieve goal after goal after goal.\n\nSet goals around your strengths. The more time you spend using your strengths, the better you feel about yourself. And, if you set goals around things you actually enjoy doing and that come naturally to you, you are more likely to achieve your goals, which also makes you feel good about yourself. It’s a double-whammy of feeling good about yourself! \n\nSet goals around your values. Doing things that demonstrate your personal values also makes you feel good about yourself. If you value being a good athlete, set goals around your fitness. If you value being a good student, set goals around your academics. If you value being kind, set goals around making people feel good.\n\nFind people to support you in achieving your goals. Having people to support you and hold you accountable will make you much more likely to achieve your goals.",
      "questions": "What is one goal you have for yourself right now, and does it feel achievable? \n\nCan you think of a goal to set around one of your strengths or values? \n\nCan you think of someone who could support you in achieving one of your goals? \n\nWhat is a goal you successfully achieved in the past, and what factors contributed to you achieving them? \n\nIf you think about goals you did not achieve in the past, what factors prevented you from achieving them? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Snoopy © Peanuts Worldwide"
    },
    {
      "grade": 12,
      "week": 17,
      "name": "Be Coachable. Welcome Feedback.",
      "category": "Interpersonal Skills",
      "subCategory": "Beliefs",
      "image": "grade12week17-feedbackanime.gif",
      "summary": "Resilient people know that becoming good at something is a process of learning, trying, and sometimes failing. And, one of the best ways to improve is to get feedback from others.",
      "readAloud": "It can be hard to hear feedback. It can feel embarrassing when a coach, teacher, parent or friend points out how you could do something better.\n \nMany people take it as criticism and get upset when others try to give them feedback. They take it to mean they have done something wrong or are not as good as they should be. Some people think feedback means they have failed.\n \nBut if you shut down when someone is trying to help you, you miss valuable information that could improve your performance… and your life. Feedback helps you in sports, in academics, in relationships, and in your career.\n \nWhen you change the story in your mind from one where feedback means you are not good enough to one where it means you are learning and improving, you put yourself on the fast track to success.\n \nSuccessful people welcome feedback. They want to know what others think of how they are doing and what they could do better.\n \nSo the next time someone offers to give you feedback, resist the urge to take it as criticism and open to the possibility that it will help you become an even better version of yourself. ",
      "questions": "What goes through your head when someone tries to give you feedback? Do you take it as criticism and feel bad about yourself or welcome the idea of learning something? \n\nAre there some people who make it easier for you to hear their feedback and others who make feedback feel bad? Why is it easier to hear from some people? \n\nWhat areas of your life are you best at hearing feedback in, and what areas are more difficult for you? (sports, academics, relationships, family, other?)  \n\nHow do you think feedback can help you in relationships? \n\nAre you good at giving feedback yourself? What can you do to make it so people take your feedback constructively and not as criticism?  ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company."
    },
    {
      "grade": 12,
      "week": 18,
      "name": "Don’t Expect Yourself to be Happy All the Time",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade12week18-I\u0027mnothappy.gif",
      "summary": "Resilient people know that disappointments, losses, failures, challenges and boredom are a part of life, so they don’t expect themselves to be happy all the time.",
      "readAloud": "If we aren’t careful, we can start to believe that life is supposed to be happy all the time. If we are not feeling happy every moment of the day, something is wrong with us. We’re failing at life. We’re not doing it right.\n\nCelebrities, sports stars, social media influencers, and even our peers–if you trust their social media posts–are living these glamorous lives and having these grand adventures. \n\nBut the irony is, expecting ourselves to be happy all the time sets us up for heart-breaking disappointment.\n\nIt feeds our insecurities and makes it more and more difficult to experience actual happiness.\n\nThe good news is, if we expect the daily annoyances, stressors, uncertainties, and bumps in the emotional road, we develop a mindset that is more adaptable, less self-critical, and more able to savor the good moments when they do arise.\n\nThe thing about happiness is it’s fleeting. We feel it for a few seconds, and then life takes over again. We can choose to try to savor the happy feeling, but then we remember our math test, or the fact that someone was rude to us, or how mad we are that our coach won’t play us more.\n\nTrue happiness comes when our lives have meaning and a sense of purpose, when we are working toward goals that matter to us, and when we feel connected to loved ones no matter what emotions we are feeling.\n\nSo stop expecting life to be happy all the time, and start being proud of yourself for pushing through the hard times, learning from your losses, and finding meaning in the setbacks.",
      "questions": "Do you agree that it can feel like we’re doing something wrong if we are not happy all the time?\n\nDo you agree that a certain level of stress and anxiety are a normal part of daily life?\n\nDo you understand why expecting ourselves to be happy all the time causes disappointment?\n\nDo you agree that happiness is fleeting? \n\nWhat do you think true happiness comes from?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Rocko’s Modern Life © Viacom International, Inc."
    },
    {
      "grade": 12,
      "week": 19,
      "name": "Trust Your Instincts",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade12week19-somethingfeelsoff.gif",
      "summary": "Resilient people trust themselves to make good decisions by tuning into their instincts or gut feelings. When we pay attention we can feel when something is off.",
      "readAloud": "Everyone has an opinion. Our parents, teachers, coaches, siblings, friends, and people we see on social media and television all have opinions about who we should be and how we should live. If we aren’t careful, we can follow someone else’s advice, which might work for them but isn’t good for us.\n\nIf we are always looking outside of ourselves for guidance, we can get lost.\n\nThe good news is, we have a source of guidance inside of ourselves, and all we have to do is tune into it.\n\nWe can feel in our bodies whether a decision is good for us or not. We can feel in our guts when a person is trustworthy or not. We can feel when something is off.\n\nSuccessful people don’t ignore those inner feelings. They trust themselves, because they learned early on to tune into their own inner guidance and feel into decisions. They ask themselves, “Does this feel right to me, or does it not? Then, they sit with the question and listen inside themselves.\n\nIf you try it a few times, you’ll see for yourself how powerful your inner guidance system can be. It’s always there for you. You just have to tune into it.",
      "questions": "Can you give an example of a time you trusted your instincts?\n\nDo you agree that it’s important to be able to trust yourself to make good decisions instead of looking to other people for guidance? Why, or why not?\n\nHave you ever ignored a “red flag” or gut feeling that you shouldn’t do something, and if so, how did that turn out?\n\nWhy do you think trusting our gut feelings, intuition, Inner Wisdom or whatever you call it works? Where does it get its information?\n\nDoes anyone in your life talk about the importance of trusting your gut? What words do they use?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Star Trek: Lower Decks © Paramount Global"
    },
    {
      "grade": 12,
      "week": 20,
      "name": "Own Your SuperPowers",
      "category": "Strengths",
      "subCategory": "",
      "image": "grade12week20-spongebobsuperpowers.gif",
      "summary": "Resilient people know that the more they use their strengths, the more empowered and happier they are, so they own their superpowers and make the most of them.",
      "readAloud": "Life is full of things that are hard. Math is hard for some people. Speaking in public is hard for others. Singing, dancing, focusing on boring homework, shooting hoops, telling jokes and making friends—all of these things are hard for some people. But they are not hard for everyone. And the people who find these things difficult have plenty of other things they are good at.  \n\nEveryone has a different set of personal strengths. And when you own your strengths and use them often, you feel good about yourself. \n\nThink about it: When you spend time doing things that come easily to you, that you are good at, and that you enjoy doing, you naturally feel good about yourself. Using your strengths builds confidence and self-esteem.  \n\nUsing your strengths is also a good way to forget about your troubles for a while. If you can get lost in the act of doing something you enjoy like drawing or playing basketball or singing or playing an instrument, you can shift your mood from not-so-great to better.  \n\nStrengths don’t have to be something you can make a career out of, although they can be. Maybe you are a good listener or a good dancer or a good baker or you are good at making your friends laugh. If it makes you feel good about yourself, consider it a strength. Also, consider it important and look for ways to use it more often.",
      "questions": "As basic as it seems, we all go through times when we have trouble naming our strengths out loud in front of others, but it’s a powerful skill. What is one of your personal strengths? \n\nWhat would your friends, family members, teachers, coaches, or mentors say is one of your strengths? \n\nIf you could make a career out of one of your strengths what would it be?\n\nDo you have a strength you can use to get out of a bad mood?\n\nDo you have any strengths that you do not enjoy using?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. SpongeBob © Viacom International, Inc."
    },
    {
      "grade": 12,
      "week": 21,
      "name": "Be Choosy About What You Put In-to Your Body and Mind",
      "category": "Needs/Values",
      "subCategory": "",
      "image": "grade12week21-canicomeinkylebroflovski.gif",
      "summary": "Resilient people value being physically and mentally healthy, so they are choosy about what they put into their bodies and minds.",
      "readAloud": "What we put into our bodies matters. If we only put in junk food and sugary drinks, our bodies will not feel well or work well. Even young children know this. Healthy bodies require healthful food and plenty of water. (They also need regular exercise and plenty of sleep, but right now we’re talking about what we put into our bodies.) If we want healthy bodies, we have to put in healthful foods and drinks.\n\nThe same is true of our minds. If we want healthy minds, we have to feed our minds with healthful “food” too. If we feed our minds a diet of negative thoughts and bad-feeling emotions, our mental health will suffer. \n\nThe problem is we live in a world where it can be difficult to get away from the constant stream of negative chatter. Our televisions, radios, and social media feeds send out a never-ending flow of information that can make us feel angry, scared, lonely, confused and overwhelmed, if we aren’t choosy about what we tune into.\n\nBut if you pay attention to how you feel when you are watching something or listening to someone, you can start being choosy about what you let in. \n\nIf you want to feel empowered, inspired, optimistic, and like you matter, then make sure you seek out people who make you feel good, conversations that inspire you, and media content that makes you believe you can dream big and have a positive impact.",
      "questions": "What is something healthful that you put into your body?\n\nWhat is something healthful that you put into your mind?\n\nIs there anything you put into your body that is not super healthful and that you might like to stop putting in or limit how much you put in?\n\nAnd what about your mind–is there anything you put into your mind that doesn’t make you feel good and that you might want to stop letting in or limit how much you let in? (This could be shows, videos, social content, or hanging out with people who bring you down instead of who lift you up.)\n\nDo you ever take time to not put anything into your mind… to just sit quietly and be present? What could be the benefit of doing that?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. South Park © Comedy Partners"
    },
    {
      "grade": 12,
      "week": 22,
      "name": "Be Open to Learning and Changing Your Mind",
      "category": "Beliefs",
      "subCategory": "Interpersonal Skills",
      "image": "grade12week22-homerchangemind.gif",
      "summary": "Resilient people know they do not know everything, so they stay open to learning and changing their minds.",
      "readAloud": "When you are a kid, it’s pretty clear that there are many things you do not yet know. Parents and teachers find new things every day to show you and teach you.\n\nBut as people grow into adults, they can stop being curious and instead become rigid in their thinking and set in their ways. They stop seeing different perspectives, which limits their opportunities and relationships.\n\nBeing able to change course and adapt to new situations is key to success.\n\nWhen you remember that you don’t know what you don’t know, you open your life to new possibilities. When you remember that life is a process of learning and growing, you try new things and take the pressure off of yourself to have to be right about everything.\n\nAnd when you give yourself permission to change your mind, you relax about your decisions, knowing you can avoid regret by making a new decision whenever you want to. ",
      "questions": "Can you describe a time when you changed your mind about something, and what made you change it? \n\nCan you describe a time when you listened to another person’s perspective and changed your mind in an argument? \n\nCan you give an example of how you are curious and/or open-minded? \n\nWhy do you think adults sometimes stop being willing to change their minds? \n\nHave you found any helpful tips for presenting your argument when you want to change someone\u0027s mind?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 23,
      "name": "Channel Your Anger Into Positive Action",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade12week23-insideoutanger.gif",
      "summary": "Resilient people know that anger can be destructive or empowering, so they pause before reacting and then channel their anger into positive action.",
      "readAloud": "Anger can be one of the most destructive forces in the universe.\n\nIf we turn our anger outward and lash out at others, it can destroy relationships. If we turn our anger inward and beat ourselves up, it can destroy our self-esteem.\n\nIf we stuff our anger down and hold it in, it can destroy our sense of wellbeing and leave us feeling edgy and short-tempered.\n\nBut, anger is a natural human emotion—an appropriate emotional response we when we feel mistreated or witness an injustice.\n\nIt’s okay to be angry. It’s what you do with your anger that makes the difference between allowing anger to be destructive or empowering.\n\nLearning to channel your anger into positive action is a key resilience skill.\n\nThe next time you feel angry, take a pause. Remember, you cannot think when you are flooded with emotion. Then, when you have calmed down enough to be able to think constructively, identify what you need and what’s in your control, and take action to take care of yourself or address the situation in a way that feels positive.",
      "questions": "Adults tell kids all the time NOT to be angry, or they tell them to “get over it.” Do you think it’s okay to be angry sometimes? \n\nWhat do you usually do with anger? Do you let it out or hold it in, and does that feel destructive or empowering to you? \n\nHave you ever noticed that you can’t think straight when you are really angry? \n\nWhat can you do to pause when you are angry and calm down so you can think straight? (Some people count to ten. Some take a few deep breaths. Others walk away for a bit.) \n\nCan you describe a time when you channeled your anger into a positive action step to either take care of yourself or address the situation in a constructive way?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Inside Out ©”The Walt Disney Company."
    },
    {
      "grade": 12,
      "week": 24,
      "name": "Know How to Agree to Disagree",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade12week24-agreetodisagree.gif",
      "summary": "Resilient people listen in a way that allows people to feel heard and understood, even when they disagree.",
      "readAloud": "A lot of times, people don’t really listen. They wait for their chance to talk. \n\nInstead of focusing on what the other person is saying, they concentrate on what they want to say while waiting until it’s their turn to speak. \n\nBut this doesn’t feel good to people, especially during a disagreement. \n\nIt is possible to listen to understand someone without “losing” the argument. \n\nHow do you do it? You focus on getting information instead of on giving information. \n\nYou listen with the goal of being able to repeat back to them what you heard them say. You say something like, “Here’s what I heard you say. Is this right?” \n\nThen, you give them a chance to explain further if you’re missing something. \n\nAfter that, you can make your own points, but at least you will understand what they are saying. \n\nWhen you listen to understand, you don’t lose. You learn. You gain not only information but also a stronger connection to another human being because you respected them enough to actually listen to what they had to say.  ",
      "questions": "How do you show someone that you are really listening to them? \n\nCan you describe a time when you disagreed with someone but listened in a way that made them feel heard and understood even though you disagreed? How did you do that?\n\nCan you describe a time when someone disagreed with you but still made you feel heard and understood? How did they do that?\n\nCan you describe a time when you disagreed with someone but listened to what they had to say and changed your mind? \n\nCan you describe a time when you disagreed with someone but they listened to you and changed their mind? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Simpsons © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 25,
      "name": "Seek Inspiration",
      "category": "Spirit",
      "subCategory": "",
      "image": "grade12week25-seekinspiration.gif",
      "summary": "Resilient people know that what they focus on determines how they feel, so they seek inspiration to feel and be their best.",
      "readAloud": "Lately it feels like all anyone can talk about is bad news. Everywhere we turn scary headlines shout for our attention. There’s so much to be worried about, and if you watch the news, so little to be hopeful about.\n\nBut the world is full of wonderful things too. We just have to make a point to notice them.\n\nFlowers bloom. Birds sing. The stars in the night sky take our breath away… if we notice them.\n\nAnd nature is not the only thing that offers daily inspiration. Strangers are kind. Families are loving. Friends are supportive. Maybe not always, but goodness is there… if we notice it.\n\nIn this world where so many loud and seemingly urgent things compete for our attention, we have to be choosy about what we focus on. It’s not easy to pull our eyes away from the latest gossip or celebrity meltdown, but if we seek inspiration, we will find it.\n\nIf we choose to focus on things that inspire us, we can believe in a better world, be part of the solution, and be our best selves.",
      "questions": "Do you agree that most of the news is negative, and why do you think that is?\n\nDoes more bad news than you would like seep into your daily life, and if so, how do you hear about it? (social media, television, people talking about it)\n\nAre you able to avoid bad news, and if so, how do you do that?\n\nAre you good at noticing inspiring things, and if so, why is that?\n\nWhere do you look for inspiration?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. "
    },
    {
      "grade": 12,
      "week": 26,
      "name": "Have a Personal Mission Statement",
      "category": "Needs/Values",
      "subCategory": "Strengths",
      "image": "grade12week26-missionfromabove.gif",
      "summary": "Resilient people know who they are, what makes them feel good about themselves, and what gives their lives meaning. These ideas can be summed up in a personal mission statement that gives them purpose and direction.",
      "readAloud": "Starting as young children, we each get to decide who we want to be, how we want to show up in the world, and what we want to do with our lives. These decisions seem small at first, like, will I play basketball or football? Will I sing or paint? Will I be kind to others or gossipy?\n\nAs we get older, the decisions have more consequences. Will I push myself to be the best student, athlete, artist I can be or will I downplay my talents? Will I reach for opportunities that interest me or will I stay in my comfort zone? Will I have the courage to be vulnerable or will I hide my true self? \n\nThinking about what matters to you, what gives your life meaning, and what sets you apart from others keeps you grounded when life throws you a curveball or disappointment. Even when life as you know it has drastically changed, you can find creative ways to fulfill your personal mission. \n\nAnswering the questions below will help you create your personal mission statement. Revisiting your personal mission statement every so often will keep you on track and allow you to capture how you’re learning about yourself, growing, and changing in your perspective and goals.",
      "questions": "What words do you hope people use when they describe the kind of person you are? \n\nWhat is one special talent or interest you have? \n\nIf you could bring positive change to your family, peer groups, school, community or even the world in some way, what would it be? \n\nWhat is one goal you have for yourself? \n\nWhat is the first thing that comes to mind when you think of what your personal mission statement could be? It doesn’t have to be grand like curing cancer or becoming president or landing on Mars, although it certainly can be. Your personal mission could be to bring laughter to people’s lives, or to be the best athlete you can be, or to try new things and not let failure slow you down. So here’s the question again: What is the first thing that comes to mind when you think of what your personal mission statement could be? (Don’t overthink it. Just say whatever pops into your head.)",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Boss Baby © Dreamworks Animation LLC"
    },
    {
      "grade": 12,
      "week": 27,
      "name": "Practice Being, Not Doing",
      "category": "Mind-Body Skills",
      "subCategory": "",
      "image": "grade12week27-daffywaiting.gif",
      "summary": "Resilient people know that being fully present in each moment is a super power, so they practice being present instead of always doing something.",
      "readAloud": "Most people today, when faced with a few minutes of having nothing to do or having to wait in a line, check their phones. Instead of sitting quietly and being present, they distract themselves with checking their messages or scrolling their feeds. This is true for adults and kids. \n\nAnd while there’s nothing wrong with distracting yourself when you’re bored or uncomfortable, it is also important to be comfortable with simply being present. Why? Because when we’re present–to the people around us and to ourselves–we have better relationships–with the people around us and with ourselves.\n\nDistraction can be a useful tool. It can help us shift our mood from a negative thought track to a better-feeling emotion, for example. But if we aren’t careful, we can find ourselves in a constant state of distraction, which doesn’t allow us to connect well with others or even with ourselves.\n\nThis can cause us not to know why we are feeling down or what we could do to feel better. And it could cause us not to understand why our social life is not feeling great or what we could do to improve it.\n\nBut if we get good at being, we have access to much more information that can help us connect and respond to the world and ourselves.\n\nLet’s try it right now:\n\n* Close your eyes, if you’re comfortable doing that, or leave them open, if that feels better.\n* And just notice whatever you notice about being here in this present moment.\n\n* Notice what you hear.\n* Notice what\u0027s going on in your body.\n* Notice what’s going on in your mind.\n\n* Let’s take about 30 seconds to just sit quietly together and see how it feels to be present.\n",
      "questions": "How was it to sit quietly and do nothing but be present? What did you notice?\n\nDo you agree that being present with people improves relationships? Why or why not?\n\nDo you agree that we shouldn’t distract ourselves from all discomfort because that prevents us from really taking care of ourselves. Why or why not?\n\nCan you give an example of when using distraction is a helpful tool?\n\nCan you give an example of when being distracted could have a negative effect?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Looney Tunes © Warner Brothers"
    },
    {
      "grade": 12,
      "week": 28,
      "name": "Captain Your Own Support Team ",
      "category": "Interpersonal Skills",
      "subCategory": "",
      "image": "grade12week28-minionsumbrella.gif",
      "summary": "Resilient people know that everyone needs support, and they are choosy about who they pick to be on their support “team.”",
      "readAloud": "We cannot choose everyone in our lives. We get who we get with our parents, siblings, teachers, coaches and bosses. We can, however, seek out friends and mentors to support us. \n\nJust as team captains pick the best players to be on their dodgeball teams, we can pick the best people to support us in different parts of our lives.\n\nIf we look around, we can find people willing to help us with athletics, academics, artistic activities and with social and emotional support.\n\nOften, young people don’t realize how many adults are willing to help. However, the older people get, the more opportunities they have to experience how good it feels to receive support and to give support. This makes them happy to support others.\n\nIn addition to seeking support from mentors, it’s important to surround yourself with people who have your best interests at heart. Resilient people know that the people they surround themselves with shape their lives, so they are choosy about who they spend time with. \n\nIf your friendships don’t actually feel that supportive, it might be worth expanding your circle. Finding people who see and bring out the best in you gives you a huge advantage in being successful and happy.",
      "questions": "Would you say you are good at reaching out for support when you need it? Why or why not?\n\nDo you agree that it feels good to support other people? \n\nDid you know that many successful adults have mentors who support them in their jobs? What types of things do you think their mentors help them with? \n\nHave you ever had a mentor, or are you interested in having one?\n\nWhat qualities do you look for in your friends, and how do you or how would you expand your circle or friends, if you wanted to? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Minions © Universal City Studios LLCons"
    },
    {
      "grade": 12,
      "week": 29,
      "name": "Find the Good in Negative Emotions ",
      "category": "Emotional Intelligence",
      "subCategory": "Beliefs",
      "image": "grade12week29-insideoutemotions.gif",
      "summary": "Resilient people know that negative emotions are not bad. In fact, negative emotions can be good if we use them to take care of ourselves.",
      "readAloud": "Negative emotions feel bad, but that doesn’t mean they are bad. Unfortunately, many people are taught that their negative emotions are wrong. Boys shouldn’t cry. Girls shouldn’t be angry. People shouldn’t be so dramatic or emotional.\nMany adults seem to believe it’s not appropriate to be angry when someone is rude to you, sad when you lose something, or afraid when something is challenging or unknown.\n \nBut emotions—negative and positive emotions—are natural responses that can help you make decisions and take care of yourself, if you use them correctly. If you get curious about what your negative emotions have to tell you, instead of ignoring them or pretending like you don’t feel them, you can figure out what you need to feel better and take an action step to take care of yourself.  \n \nAnger is often telling us we deserve better. Sadness is often reminding us that something or someone is important to us. Fear is always trying to protect us.\n \nEmotions are natural and appropriate. Sometimes the way we respond to our emotions is not. So, when you feel a negative emotion, ask yourself what you need to feel better and take an action step to take care of yourself. That is finding the good in negative emotions.",
      "questions": "Can you describe a time when you listened to what a negative emotion was telling you and took care of yourself?\n\nAre there certain emotions you think are “bad?” If so, how do you deal with them?\n\nHave you ever been told to keep certain emotions to yourself? If so, how did that make you feel? \n\nHave you ever noticed that emotions get bigger if you stuff them down and then explode in an overreaction at a later time? Why do you think that is? \n\nHave you ever experienced how talking about your negative emotions actually makes you feel better?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Inside Out ©”The Walt Disney Company."
    },
    {
      "grade": 12,
      "week": 30,
      "name": "Take Responsibility for How You Show up in the World ",
      "category": "Needs/Values",
      "subCategory": "Strengths",
      "image": "grade12week30-incrediblesresponsibility.gif",
      "summary": "Resilient people know that many things are out of their control,\nbut how they show up each day—what kind of person they are\nand choices they make—is completely up to them.",
      "readAloud": "It is not always easy to be our best selves. When we are angry at someone we can do things we later regret. When we are frustrated about something we can show sides of ourselves we are not proud of. And sometimes certain people can push us to do things we know are not right.\n \nIt is not always easy to be our best selves, but resilient people know that it matters. Why does it matter? Because it builds self-esteem.\n \nIt feels good to set high standards for yourself and live up to those standards—even when no one is watching. It feels good to be proud of yourself. It is empowering and inspiring and satisfying. And it is something that is always available to you, even when much about your life is out of your control.\n \nOne thing that helps you show up as the best version of yourself is to surround yourself with people who share your values and bring out the best in you. Another thing that helps is to know what your personal strengths are and use them as often as possible.\n \nIt is not always easy to be your best self and it will not prevent you from experiencing disappointment, but it will empower you to be the hero in your own life.",
      "questions": "When you think of being your best self, what is the single, most important character trait you try to live by?\n\nIf you asked your friends, family members, coaches, teachers or mentors what the best thing about you is, what would they say?\n\nAs you transition into the next phase of your life, what is one value you will try to live by?\n\nAs you transition into the next phase of your life, what is one personal strength you will make sure to utilize?\n\nWhat is one piece of advice you would give yourself or someone else your age about how to be your best self as you embark on a new phase of life?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. The Incredibles © The Walt Disney Company"
    },
    {
      "grade": 12,
      "week": 31,
      "name": "Tell Empowering Stories About Yourself",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade12week31-Imaprettyamazingperson.gif",
      "summary": "Resilient people know that the stories they tell about themselves shape their lives, so they tell the most empowering stories they can about themselves, their lives, and their futures.",
      "readAloud": "The stories we tell about ourselves drive our behavior, influence how others respond to us, and either empower us to achieve our dreams or place unnecessary limits on our potential.\n \nImagine that you tell the story, “I’m not good at school.”\n \nThis belief would make you feel insecure and anxious in the classroom, which would likely make you reluctant to participate. This would cause your teachers to judge you poorly and give you lower grades, which would confirm your belief that you are not good at school. All this would probably make you less likely to try very hard at your schoolwork, which would continue the cycle.\n \nBut imagine if you told this story: “It sometimes takes me longer, but I always figure it out.”\n \nThis belief would make you feel hopeful and determined in the classroom and would likely cause you to engage with your teachers until the concepts clicked for you. Not only would you get higher grades but your teachers would also see you as diligent and hard-working. All this would confirm your belief that if you work hard enough you eventually figure it out.\n \nWords have power.\n\nYou get to decide how to tell your life story, so choose your words wisely.",
      "questions": "What is one really positive thing you can say about yourself? \n\nWhat is something positive you can say about your future? \n\nCan you think of a positive way to talk about a mistake or failure you had in the past? \n\nWhat is something positive you can say about how you treat other people? \n\nDo you have a negative story about yourself? If so, can you think of a slightly better story to tell instead? ",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page ",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Hey Arnold © Viacom International, Inc."
    },
    {
      "grade": 12,
      "week": 32,
      "name": "Listen to Yourself (Your Wise Self)",
      "category": "Inner Wisdom",
      "subCategory": "Mind-Body Skills",
      "image": "grade12week32-birdsdoyouhearthat.gif",
      "summary": "Resilient people know there is a voice inside of them that knows what is best for them, so they tune in and listen to their wise self.",
      "readAloud": "Inside each of us, whether we know it or not, is a wise self. Some people call it trusting their gut. Others call it intuition, Inner Wisdom, or their higher self. Whatever you call it, we all have a voice inside of us that will help us make good decisions for ourselves, if we listen to it.\n\nThe problem is, we forget to listen to it. \n\nWe spend so much time racing from activity to activity we can’t hear ourselves think.  And when we do have down time, we distract ourselves with things like scrolling our social feeds, watching mindless videos, or playing video games to the point that there’s no space for the voice of our Inner Wisdom to get through.\n\nBut it doesn’t take much to hear our wise self. All we have to do is take away distractions, focus our attention on our inner world, breathe and listen inside.\n\nLet’s do it now: \n\n1. Take a deep breath. \n2. Close your eyes, if you’re comfortable doing so.\n3. Focus on the sound of your breath as you relax and breathe.\n4. Ask your wise self if it has anything to tell you.\n5. Take three more breaths as you listen inside.\n6. When you’re ready, open your eyes and focus on the outside world again.\n\nThat is how you tune into your Inner Wisdom whenever you have a decision to make or need to know how to take care of yourself. Just focus on your inner world, ask a question, and listen while you relax and breathe. \n\nThis mind-body skill of connecting your mind and body by focusing on your breath is a quick way to tune into your Inner Wisdom.",
      "questions": "How was it to do that short exercise? Did you get an answer you’d like to share?\n\nDo you make it a habit to tune into your wise self and trust your gut?\n\nWhat words do you use for trusting your gut or tuning into your Inner Wisdom?\n\nCan you give an example of a time that listening to your wise self helped you?\n\nCan you give an example of a time when you wished you had listened to your wise self?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Angry Birds © Rovio Entertainment Corporation"
    },
    {
      "grade": 12,
      "week": 33,
      "name": "Know the Four Ways to Feel Better",
      "category": "Emotional Intelligence",
      "subCategory": "",
      "image": "grade12week33-angrybirdsdeepbreath.gif",
      "summary": "Resilient people know that their thoughts, emotions, bodies and behavior are interconnected and that they can feel better by doing something positive for any of the four.",
      "readAloud": "Our minds and bodies are connected. In fact, our minds, bodies, emotions, and behavior are connected. \n\nWhen we are mentally stressed, we feel negative emotions like anxiety, we have physical symptoms like tense muscles or headaches, and our behavior shows how stressed we are—we snap at people, for example. \n\nIt works the other way too. When our bodies are down with an illness, we feel emotionally down, the thought track in our heads is down too, and our behavior shows that we feel bad—we withdraw from people, for example. \n\nWhat’s going on in our minds matches how we feel emotionally, how we feel physically, and how we behave.\n\nThe good news is, if we can feel better in one of the areas, we’ll feel better in all of them. For example, if we get some exercise, our body will obviously feel better, our mood (emotions) will lift also, we will stop being so short-tempered (behavior), and the thought track in our head will be a little more positive. \n\nSo, the next time you want to feel better, think of something you can do to feel better mentally, emotionally, physically or behaviorally:\n\n* Think a more positive thought by looking at the situation a little differently.\n* Do something that lifts your emotions—like listen to a great song, hang out with a person who makes you laugh, or read something that inspires you.\n* Do something to feel better physically—like shoot some hoops or go for a walk.\n* Or behave in a way that feels good—like do something kind for someone else.",
      "questions": "For many people, doing something physical like going for a run or getting a hug from someone who loves you is a good way to feel better. Is there something physical that works for you when you want to get out of a bad mood?\n\nGreat songs, funny videos, and kind friends can lift your emotions. What else do you do to feel better emotionally—something that is not physical?\n\nOften, the hardest thing to change first is your thoughts, so don\u0027t even try to think positively until you\u0027ve done something to improve your mood. Which of the four (your thoughts, emotions, body or behavior) is hardest for you to shift? \n\nWhich of the four (your thoughts, emotions, body or behavior) is easiest for you to change? \n\nHave you ever noticed that if someone tries to cheer you up before you’re ready, it just makes you feel worse? What do you do when that happens?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Angry Birds © Rovio Entertainment Corporation"
    },
    {
      "grade": 12,
      "week": 34,
      "name": "Know the Ways You Are Resilient",
      "category": "Beliefs",
      "subCategory": "",
      "image": "grade12week34-nemo.gif",
      "summary": "Resilient people know lots of different resilience skills, and when life is challenging, they choose whichever skill will help them most.",
      "readAloud": "Resilience is the ability to bounce back from the inevitable disappointments, setbacks, challenges and failures that everyone–literally everyone–must face. No matter how good people’s lives look on the outside, no one escapes from hard times in life. \n\nThe good news is there are skills we can learn that help us not just make it through those hard times but actually use those experiences to become stronger, happier, better versions of ourselves. This year we have talked about: \n\n* Have good-feeling talk habits\n* Visualize success\n* Harness the power of the mind-body connection\n* Don’t let failure define you, use it to grow\n* Trust your instincts\n* Be open to learning and changing your mind\n* Seek inspiration\n* Have a personal mission statement\n* Practice being, not doing\n* Be coachable, welcome feedback\n* Stop trying to control everything and be open to what life is showing you\n\nThese are just a few of the resilience skills we have covered this year. You were already resilient, and now you have even more skills to choose from when you come to a bump in the road. ",
      "questions": "Can you describe a way you have been resilient this year?\n\nAre there any skills from this Resilience Reminders™ program that you learned or tried that worked for you?\n\nWhat words do you use to define resilience? \n\nDo you think talking about these types of things is helpful for students and families? Why or why not?\n\nDo you believe in reaching out for support when you need it? Why or why not?",
      "linkToResources": "https://www.macdermottmethod.com/resilience-reminders-example-school-resource-page",
      "copyright": "© The MacDermott Method, Inc. This content may not be shared or reproduced without the express written consent of the company. Finding Nemo © The Walt Disney Company "
    }
  ]