export interface SubjectIcon {
    title: string;
    icon: string;
    color: string;
    cssColor: string;
    cssTitle: string;
}

const SUBJECT_ICON_LIST: SubjectIcon[] = [
    {
        title: 'English',
        icon: '/assets/images/icons/subjects/ico-subject-english',
        color: '#A256D7',
        cssColor: 'purple',
        cssTitle: 'english'
    },
    {
        title: 'Math',
        icon: '/assets/images/icons/subjects/ico-subject-math',
        color: '#25B8D6',
        cssColor: 'blue',
        cssTitle: 'math'
    },
    {
        title: 'Reading',
        icon: '/assets/images/icons/subjects/ico-subject-reading',
        color: '#F49100',
        cssColor: 'orange',
        cssTitle: 'reading'
    },
    {
        title: 'Science',
        icon: '/assets/images/icons/subjects/ico-subject-science',
        color: '#8AC22D',
        cssColor: 'green',
        cssTitle: 'science'
    },
    {
        title: 'Writing',
        icon: '/assets/images/icons/subjects/ico-subject-writing',
        color: '#C13AA1',
        cssColor: 'magenta',
        cssTitle: 'writing'
    },
    {
        title: 'School Safety & Security',
        icon: '/assets/images/school-safety/lock-blue',
        color: 'rgb(14, 86, 130)',
        cssColor: 'blue',
        cssTitle: 'math'
    }
];

/**
 * Get the list of subject icons and their image paths
 * @export
 * @param {string} [size] Size of the icon (leave blank for 1x) (e.g. "@2x" or "@0,5x")
 * @returns
 */
export function getSubjectIconList(size?: string): SubjectIcon[] {
    return SUBJECT_ICON_LIST.map(subjectIcon => ({...subjectIcon, icon: subjectIcon.icon + `.svg` }));
}

/**
 * It returns the subject related info, such as icon and color, not just icon itself!
 * 
 * @param subjectTitle 
 * @returns 
 */
export function getSubjectIcon(subjectTitle: string): SubjectIcon {
    const subjectIconList = getSubjectIconList('@0,5x');
    let subjectIcon

    subjectIconList.forEach(si => { 
        if (si.title === subjectTitle) {
            subjectIcon = si
        }
    })

    return subjectIcon
}