import * as React from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import {
  CollegeKnowledgeDashboard,
  Props,
  StoreProps
} from './CollegeKnowledgeDashboard';

import { AppState } from '../../../../store';
import { UserInfo } from '../../../../services/account/account-rest-interface';
import { getCurrentLesson } from '../../../../stores/lesson.store';

const mapStateToProps = ({ account, lesson }: AppState): StoreProps => ({
  userInfo: account.userInfo as UserInfo,
  numLessonsUnstarted: lesson.numLessonsUnstarted,
  numLessonsCompleted: lesson.numLessonsCompleted,
  numLessonsStarted: lesson.numLessonsStarted,
  currentLessonId: lesson.currentLessonId,
  currentLesson: getCurrentLesson(lesson)
});

const ConnectedCollegeKnowledgeDashboard = withRouter(connect<StoreProps, {}, Props, AppState>(
  mapStateToProps,
  {}
)(CollegeKnowledgeDashboard));

export default props => {
  return <ConnectedCollegeKnowledgeDashboard {...props} />;
};
