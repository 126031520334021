import './TabContent.css';

import * as React from 'react';

interface Props {
    hidden: boolean;
}

const TabContent: React.SFC<Props> = (props: Props) => {
    const className = props.hidden ? 'tab-content--hidden' : '';
    return (
        <div className={`tab-content ${className}`}>
            {(props as any).children}
        </div>
    );
};

TabContent.displayName = 'TabContent';

export default TabContent;
