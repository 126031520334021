import './BlogCard.css';

import * as React from 'react';

import Button from '../../../../components/Button/Button';
import Card from '../../../../components/Card/Card';

interface Props {
    containerClass?: string;
}

const BlogCard: React.SFC<Props> = (props: Props) => {

    const btnStyle = {width: '100%'};

    return (
        <div className={'dash-blog-container ' + (props.containerClass || '')}>
            <div className="dash-blog-container__bg">
                <Card title="" titleColor="blue" link="" classTitle="">
                    <div className="blog-container">
                        <p className="blog-text">See what <br className="d-none d-xl-block" />we're up to</p>
                        <Button 
                            text="READ OUR BLOG" 
                            textColor="purple" 
                            bgColor="white" 
                            style={btnStyle} 
                            onClick={clickBlogButton} 
                        />
                    </div> 
                </Card>
            </div>
        </div>
    );

    function clickBlogButton() {
        var win = window.open('http://winwardacademy.com/blog', '_blank');
        if (win) {
            win.focus();
        }
    }
};

BlogCard.displayName = 'BlogCard';

export default BlogCard;
