import * as React from 'react';

import SummaryAccuracyCell from '../SummaryAccuracyCell';
import NotApplicableIndicator from '../../../../components/NotApplicableIndicator';
import { TestType } from '../../../../services/utils/practice-test-util';

export interface Props {
  section: any;
}

const ACTOverallCell: React.SFC<Props> = ({ section }) => {
  const zeroStudents = section.studentCount === 0;
  return (
    <div>
      <div>
        {zeroStudents &&
        <div className="th-tooltip">
          <div className="not-applicable">N/A&nbsp;</div>
          <span className="th-tooltip-text">No composite can be calculated without completion of the test by at least one student</span>
        </div>
          // <NotApplicableIndicator 
          //   text={'No composite can be calculated without completion of the test by at least one student'}
          // />
        }
        {zeroStudents && 
          <span className="not-applicable">
            <br/>
            n = 0
          </span>
        }

        {!zeroStudents &&
        <SummaryAccuracyCell 
          testType={TestType.ACT}
          averageScore={section.score}
          accuracy={section.accuracy}
          studentCount={section.studentCount}
        />
        }
      </div>
    </div>
  );
};

export default ACTOverallCell;
