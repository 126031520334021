import * as React from 'react';

import NotApplicableIndicator from '../../../../components/NotApplicableIndicator';

import './index.css';

export interface Props {
  section: any;
}

const OverallAccuracyCell: React.SFC<Props> = ({ section }) => {
  const validEbrw = section.satEbrwStudentCount > 0;
  const validMath = section.satMathStudentCount > 0;

  return (
    <div>
      <div className="overall-sat-values">
        {validEbrw ? 
          <div>
            EBRW - {section.satEbrwScore}
          </div> : 
          <div className="th-tooltip th-tooltip">
          <div>EBRW - N/A</div>
          <span className="th-tooltip-text">An EBRW score cannot be calculated until a student completes both the Reading and the Writing & Language sections.</span>
        </div>
        }
      </div>
      <div>
        n = {section.satEbrwStudentCount}
      </div>

      <div className="overall-sat-values">
        {validMath ? 
        <div>Math - {section.satMathScore}</div> : 
        <div className="th-tooltip th-tooltip">
          <div>Math - N/A</div>
          <span className="th-tooltip-text">A Math score cannot be calculated until a student completes the Math sections.</span>
        </div>
        }
      </div>
      <div>
        n = {section.satMathStudentCount}
      </div>
    </div>
  );
};

export default OverallAccuracyCell;
