import { isTestEnv } from './env-helper';
const TEST_KEY = 'pk_test_lNj3ocOdWRUX0VQgS6K9Bh94';
const PROD_KEY = 'pk_live_gq2tMwn53onnERYk7WE3Br5G';

export function getStripeClientKey() {
  const hostname = window.location.host;
  console.log('get host', hostname);
  if (isTestEnv()) {
    console.log('in qa env');
    return  TEST_KEY;
  } else if (hostname === 'localhost:3000') {
    console.log('in development');
    return TEST_KEY;
  } else if ( hostname === 'learn.winwardacademy.com'
  || hostname === 'production-docker.winwardacademy.com') {
    // TODO: Use/test isProductionEnv when we do a modification to signup
    console.log('in production env');
    return PROD_KEY;
  }
}

export default getStripeClientKey;
