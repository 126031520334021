export function isProductionEnv() {
  const hostname = window.location.host;
  return hostname === 'learn.winwardacademy.com';
}

export function isTestEnv() {
  const hostname = window.location.host;
  return hostname === 'qa-learn.winwardacademy.com';
}

export function isDevelopment() {
  return !isTestEnv() && !isProductionEnv();
}
