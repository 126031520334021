import React from 'react';

import ClassLinkLogin from './ClassLinkLogin';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loginClassLinkUser } from '../../stores/account.store';

const mapStateToProps = ({ account }: AppState) => ({
    requestingLogin: account.requestingLogin
});

const mapDispatchToProps = { loginClassLinkUser };

const ConnectedLogin = connect<any, any, any, AppState>(mapStateToProps, mapDispatchToProps)(ClassLinkLogin);

export default withRouter(ConnectedLogin);
