import * as React from 'react';

import ProgressBar from '../../../../../components/ProgressBar/ProgressBar';
import RadialProgressEx from '../../../../../components/RadialProgress/RadialProgressEx'

export interface Props { 
    subject: string;
    score: number;
    totalScore: number;
    barColor: string;
    className?: any;
}

const TestScoreDialsRow: React.SFC<Props> = (props) => {
    let startColor, endColor
    let displayClass

    props.className ? displayClass = props.className : displayClass = ''

    switch (props.barColor) {
        case 'purple':
            startColor = '#9d59cf'
            endColor = '#9d59cf'
        break;
        case 'green':
            startColor = '#88c02b'
            endColor = '#88c02b'
        break;
        case 'orange':
            startColor = '#f5a000'
            endColor = '#f5a000'
        break;
        case 'blue':
            startColor = '#37c3db'
            endColor = '#37c3db'
        break;
        default:
            startColor = '#37c3db'
            endColor = '#37c3db'
        break;
    } 

    return (
        <div className={'practice-test-score-row ' + displayClass}>
            <div className="col-dial">
            {/* <ProgressBar
                current={props.score}
                total={props.totalScore}
                barCurrentColor={props.barColor}
                barTotalColor="gray"
                size="small"
            /> */}
            <RadialProgressEx 
                progress={(props.score ==  0 ? 0 : props.score / props.totalScore * 100)}
                text={props.score+""}
                size={66}
                radius={26}
                pegOffset={8}
                startColor={startColor}
                endColor={endColor}
                textSytle={{fontSize: '22px', color: '#2B2D2D', fontWeight: 500, top: '21.5px'}}
                progressLineWidth={7}
                backgroundLineWidth={7}
                customClass="radial-small" />
            </div>
            <div className="col-label">
                <span className="practice-test-subject">{props.subject}</span>
            </div>
        </div>
    );
};

export default TestScoreDialsRow;
