import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { AppState } from '../../store';
import { Props, LessonsWithoutRouting } from './Lessons';
import { setUserInfo } from '../../stores/account.store';

const mapStateToProps = ({ account }: AppState, ownProps: any) => {
    return {
      ...ownProps,
      lessonDashView: account.userInfo.lessonDashView,
      isTeacher: account.userInfo.isTeacher,
      userInfo: account.userInfo
    };
};

const mapDispatchToProps = { setUserInfo };

const ConnectedLessons = connect(mapStateToProps, mapDispatchToProps)(LessonsWithoutRouting);

export default function(props: any) {
    return <ConnectedLessons {...props} />;
}
