import React, { Component } from 'react'
import RadialProgress from './RadialProgress'
import "./RadialProgress.css"

export interface Props {
  progress: number
  size?: number
  radius?: number
  startColor?: string
  endColor?: string
  text?: string
  textSytle?: React.CSSProperties
  pegOffset?: number
  progressLineWidth?: number
  backgroundLineWidth?: number
  footerText?: string
  customClass?:string
}

interface State {}

export default class RadialProgressEx extends Component<Props, State> {
  render() {
    const {
      size=150, 
      radius=45,
      startColor="#05b4b9",
      endColor="#05b4b9",
      progress,
      text,
      textSytle={fontSize: '33px', color: '#2B2D2D', fontWeight: 500},
      pegOffset=30,
      progressLineWidth=11,
      backgroundLineWidth=11,
      footerText="",
      customClass=""
    } = this.props

    return (
      <div className={`radial-progress-container ` + customClass}>
        <RadialProgress
            progress={progress}
            size={size}
            radius={radius}
            startColor={startColor}
            endColor={endColor}
            pegOffset={pegOffset}
            text={""}
            textStyle={textSytle}
            progressLineWidth={progressLineWidth}
            backgroundLineWidth={backgroundLineWidth}
        />
        <div className="radial-text-container">
          <div className="radial-progress-text" 
                style={{ ...textSytle }}>
            {text}
          </div>
          <p className="text-center footer-text" style={{marginTop: '8px'}}>{footerText}</p>
        </div>
      </div>
    )
  }
}
