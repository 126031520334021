import React, { Component } from 'react'
import DataGrid from '../../../../components/DataGrid'
import { Column } from '../../../../components/DataGrid/types/types'
import { ITestInsight, ITestInsightSAT, ITestInsightScore } from '../../../../services/elearn/teacher/practice-tests-insights'
import { alphanumericCompare } from '../../../../services/utils/sort-util'
import { ITimeOnPlatform, getPracticeTestTimeSummaryByStudent } from '../../TimeOnPlatform/top-lesson-processor'
import { getHumanTime } from '../../../../services/utils/date-util'
import { NA } from '../../../../constants'
import { isNAConditionFoundInSATScores, isNAForGivenSATScore } from '../../../../rules/business'


let sortMethod = alphanumericCompare
let minSortVal = Number.NEGATIVE_INFINITY
let maxSortVal = Number.POSITIVE_INFINITY
let sortable = true
let columns: Column[] = [
  {
    title: 'FIRST NAME',
    dataProperty: 'studentNameFirst',
    customTooltip: 'Indicates the first name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'LAST NAME',
    dataProperty: 'studentNameLast',
    customTooltip: 'Indicates the last name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'TOTAL<br/>SCORE',
    dataProperty: 'scoreTotal',
    customTooltip: 'Indicates the total score',
    sortable,
    sortMethod
  }, 
  {
    title: 'EBRW',
    dataProperty: 'scoreEBRW',
    customTooltip: 'Indicates the EBRW score and college readiness',
    sortable,
    sortMethod
  }, 
  {
    title: 'MATH',
    dataProperty: 'scoreMath',
    customTooltip: 'Indicates the Math score and college readiness',
    sortable,
    sortMethod
  },
  {
    title: 'REVIEW<br/>TIME',
    dataProperty: 'reviewTime',
    customTooltip: 'Indicates the total review time',
    sortable,
    sortMethod
  },
  {
    title: 'HINTS &<br/>MISTAKES',
    dataProperty: 'hintsAndMistakes',
    customTooltip: 'Displays the number of missed questions for which students wrote hints and the total number of missed questions',
    sortable,
    sortMethod
  },
]

interface Props {
  insights: ITestInsightSAT[]
  timeOnPlatformInfo: ITimeOnPlatform[]
}

interface State { }

/**
 * Practice test insights for SAT
 */
export default class TestInsightsForSAT extends Component<Props, State> {
  getScoreInfo = (score: ITestInsightScore) => {
    let {value, collegeReady} = score
    let crClassName = collegeReady ? "college-ready-true": "college-ready-false"
    let isNA = isNAForGivenSATScore(score)

    return () => (
      !isNA ?
        <>
          <div>{value}</div>
          <div className={crClassName}>
            <div className="th-tooltip">
              {collegeReady ?
              <div>
                Yes&nbsp;
              </div>
              :
              <div>
                No
              </div>
              }
              <span className="th-tooltip-text">{collegeReady ? "Score meets threshold for college readiness" : "Score does not meet threshold for college readiness"}</span>
            </div>
          </div>
        </>
        :
        NA

    )
  }

  getTimeSummaryInfo = (timeSummary) => {
    let {timeOnReviewTotal, timeOnMistakes, timeOnHints, timeOnAnalysis} = timeSummary
    let totalReviewTime = getHumanTime(timeOnReviewTotal, false, true).text
    // The following will be used in a tooltip when hovered over the total
    let mistakesTime = getHumanTime(timeOnMistakes, false, true).text
    let hintsTime = getHumanTime(timeOnHints, false, true).text
    let analysisTime = getHumanTime(timeOnAnalysis, false, true).text

    return () => (
      <>
        {totalReviewTime}
      </>
    )
  }

  getStatsHintsAndMistakesInfo(stats) {
    let {nofTotalQuestionsMissed, nofTotalHintsWritten} = stats
    let info = `${nofTotalHintsWritten} / ${nofTotalQuestionsMissed}`

    return () => (
      <>
        {info}
      </>
    )
  }

  getCompositeScoreInfo = (scores, composite) => {
    let isNA = isNAConditionFoundInSATScores(scores)
    return () => !isNA ? <>{composite}</> : NA
  }

  createContent = (insight: ITestInsightSAT, timeOnPlatformInfo, index: number) => {
    let id = `test-insight-${index}`
    let {firstName, lastName, scores, composite, testName, emailAddress, stats} = insight
    let {nofTotalQuestionsAnswered, nofTotalQuestionsMissed, nofTotalHintsWritten} = stats    
    let scoresSAT = scores.sat
    let {ebrw, math} = scoresSAT
    let timeSummary = getPracticeTestTimeSummaryByStudent(timeOnPlatformInfo, testName, emailAddress )
    let data = {
      studentNameFirst: {
        content: firstName,
        comparator: firstName
      },
      studentNameLast: {
        content: lastName,
        comparator: lastName,
      },
      scoreTotal: {
        content: this.getCompositeScoreInfo(scoresSAT, composite),
        comparator: composite
      },
      scoreEBRW: {
        content: this.getScoreInfo(ebrw),
        comparator: ebrw.value
      },
      scoreMath: {
        content: this.getScoreInfo(math),
        comparator: math.value
      },
      reviewTime: {
        content: this.getTimeSummaryInfo(timeSummary),
        comparator: timeSummary.timeOnReviewTotal
      },
      hintsAndMistakes: {
        content: this.getStatsHintsAndMistakesInfo(stats),
        comparator: nofTotalHintsWritten,
        subComparator: nofTotalQuestionsMissed
      }     
    }    

    return {
      id,
      data,
    }
  }

  getCardBodyContent = (insights: ITestInsightSAT[], timeOnPlatformInfo) => {    
    let data = insights.map((insight, index) => this.createContent(insight, timeOnPlatformInfo, index))
    let averageMetaData = {
      columns: [
        {name: "scoreTotal", cb: (average) => `Average Total Score: ${Math.round(average)}`},
        {name: "scoreEBRW", cb: (average) => `Average EBRW Score: ${Math.round(average)}`},
        {name: "scoreMath", cb: (average) => `Average Math Score: ${Math.round(average)}`},
        {name: "reviewTime", cb: (average) => `Average Review Time: ${getHumanTime(average, false, true).text}`},
        {name: "hintsAndMistakes", cb2: (average1, average2) => `Average Hints & Mistakes: ${Math.round(average1)} / ${Math.round(average2)}`}
      ]
    }

    return (
      <DataGrid
        columns={columns}
        data={data}
        averageMetaData={averageMetaData}
        emptyText="No test insight info found for students."
      />
    )
  }
  
  render() {
    let {insights, timeOnPlatformInfo} = this.props
    let details = this.getCardBodyContent(insights, timeOnPlatformInfo)
    return (
      <div className="tests-insights-details sat">
        {details}
      </div>
    )
  }
}
