
import * as React from 'react';
import './index.css';

interface Props {
  text: string;
}

const EnvLabel: React.SFC<Props> = (props: Props) => {
  return (<div className="env-label">{props.text}</div>);
};

export default EnvLabel;
