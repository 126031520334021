import React, { Component } from 'react'
import { BarChart, Bar, LabelList, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { dateDiff, getLastNDays, getRelativeWeekInfo } from '../../../services/utils/date-util'
import { TotalClassProgress } from './top-lesson-processor'
import "./ClassProgressChart.css"

interface ClassProgressChartProps {
  totalClassProgress: TotalClassProgress
}

interface ClassProgressChartState {}

export default class ClassProgressChart extends Component<ClassProgressChartProps, ClassProgressChartState> {

  render() {
    let {totalClassProgress} = this.props
    let totalLessonsStarted = totalClassProgress.nofLessonsStarted
    let totalLessonsCompleted = totalClassProgress.nofLessonsCompleted
    let totalQuestionsAnswered = totalClassProgress.totalQuestionsAnswered
    let totalWorkingTime = totalClassProgress.totalStudyTime.html
    let timeRelatedDataAvailable = totalQuestionsAnswered > 0 ? true: false
    let chartData = totalClassProgress.dailyAnsweredQuestions.map(data => {
      return {
        date: data.date,
        Total: data.n,
      }
    })

    let now = new Date()
    let startDate = chartData.length > 0 ? chartData[0].date: undefined
    let diff = startDate ? dateDiff(startDate, now): 30

    // Adjust number of day diff here
    diff = diff <= 8 ? 7: diff <= 31 ? 30: diff

    let dateMap = chartData.reduce((acc, cur) => {
      acc[cur.date] = cur.Total
      return acc
    }, {}) 
    
    // Combine daily answered questions with recent days 
    // This will fill the missing date entries with 0
    let recentDays = getLastNDays(now, diff)
    let combinedData = recentDays.map(date => {
      let val = dateMap[date] | 0
      return { date, Total: val }
    })

    // Use the combined data as the new chart data
    chartData = combinedData

    return (
      <div>
        <div className="lesson-progress-container">
          <div className="lesson-progress-title">Total Class Progress</div>
          {timeRelatedDataAvailable?
            <>
              <ClassProgressSummary
                totalLessonsStarted={totalLessonsStarted}
                totalLessonsCompleted={totalLessonsCompleted}
                totalWorkingTime={totalWorkingTime}
                totalQuestionsAnswered={totalQuestionsAnswered}
                />

              <DailyProgressChart chartData={chartData}/>
            </>
            :
            <div className="no-time-on-platform-data">
              <div className="no-time-on-platform-title">Check back soon.</div>
              <div className="no-time-on-platform-message">
                Your class progress will show once your students 
                start taking lessons or practice tests.
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

//--- Helper Components ---

const ClassProgressSummary = (props: any) => {
  let {totalLessonsStarted, totalLessonsCompleted, totalWorkingTime, totalQuestionsAnswered} = props

  return (
    <div className="lesson-progress-info">
      <div className="row lesson-progress-stat-info no-gutters">
        <div className="col"><b>{totalLessonsStarted}</b></div>
        <div className="col"><b>{totalLessonsCompleted}</b></div>
        <div className="col-4 colTotalTime" style={{}} dangerouslySetInnerHTML={{__html: totalWorkingTime}}/>
        <div className="col"><b>{totalQuestionsAnswered}</b></div>
      </div>
      <div className="row lesson-progress-labels no-gutters">
        <div className="col">Lessons<br/>Started</div>
        <div className="col">Lessons<br/>Completed</div>
        <div className="col-4 colTotalTime">Total Time<br/>Working</div>
        <div className="col">Questions<br/>Answered</div>
      </div>
    </div>
  )
}

const CustomTooltip = (props: any) => {
  const {payload, value, active, label} = props; 
  let splitDate = props.label;
  let monthName, fullDisplayDate
  if(splitDate) {
      let dateStr = splitDate.split("-").join(",");
      let dateArr = dateStr.split(',');
      let newDateEnd = dateArr[2] + ', ' + dateArr[0];
      let monthNum = dateArr[1];
      switch(monthNum) {
        case '01':
          monthName = 'Jan'
        break;
        case '02':
          monthName = 'Feb'
        break;
        case '03':
          monthName = 'Mar'
        break;
        case '04':
          monthName = 'Apr'
        break;
        case '05':
          monthName = 'May'
        break;
        case '06':
          monthName = 'Jun'
        break;
        case '07':
          monthName = 'Jul'
        break;
        case '08':
          monthName = 'Aug'
        break;
        case '09':
          monthName = 'Sept'
        break;
        case '10':
          monthName = 'Oct'
        break;
        case '11':
          monthName = 'Nov'
        break;
        case '12':
          monthName = 'Dec'
        break;        
      };
      fullDisplayDate = monthName + ' ' + newDateEnd;
  } else {
    fullDisplayDate = '';
  }

  if (active) { 
		return (
			<div className="wa-theme-tooltip">  
				<p className="h5 mb-1 wa-theme-tooltip--value"><b>{payload[0].value}</b></p>
        <p className="label tooltip--date mb-1">{fullDisplayDate}</p>
			</div>
		);
	}

	return null;
};

const DailyProgressChart = (props: any) => {
  let {chartData} = props
  let startColor = "#69d891"
  let midColor = '#22bead'
  let endColor = "#279fd6"
  let relativeWeekStart = getRelativeWeekInfo(chartData[0].date)
  let relativeWeekEnd = "Today"

  return (
    <div className="lesson-progress-chart-container">
      <div className="lesson-progress-chart-title">DAILY ANSWERED QUESTIONS</div>
      <ResponsiveContainer width="100%" height={380} >
        <BarChart width={600} height={600} data={chartData}>
          <defs>
            <linearGradient
              id="colorUv"
              x1="0"
              y1="0"
              x2="0"
              y2="100%"
              gradientUnits="userSpaceOnUse"
              >
              <stop offset="0" stopColor={startColor} />
              <stop offset=".5" stopColor={midColor} />
              <stop offset="1" stopColor={endColor} />
            </linearGradient>
          </defs>
          <Bar dataKey="Total" fill="url(#colorUv)" />
          <XAxis 
            dataKey="date"
            tick={false}
            tickLine={false}
          />
          <YAxis />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{fill: 'transparent'}}
          />
        </BarChart>
      </ResponsiveContainer>
      <div className="chart-footer">
        <div className="chart-footer-info-left">{relativeWeekStart}</div>
        <div className="chart-footer-info-right">{relativeWeekEnd}</div>
      </div>
    </div>
  )
}