import './LessonComplete.css';

import * as React from 'react';

import {
    LessonProgress,
    LessonReport,
    MistakeBankLessons,
    Question,
    QuestionGroup
} from '../../services/elearn/lesson-types';

import { getLessonSummary } from '../../services/user/user-lesson-rest-interface';
import { getBaselineQuestions, getMistakeBankOverviewNoSS } from '../../services/elearn/lesson-rest-interface';

import Loading from '../../components/Loading/Loading';
import Button from '../../components/Button/Button';
import { Card } from './components/Card/Card';
import { Column } from '../../components/DataGrid/types/types';
import DataGrid from '../../components/DataGrid/DataGrid';
import RadialProgress from '../../components/RadialProgress/RadialProgress';
import { RouteComponentProps } from 'react-router';
import { RowData } from '../../components/DataGrid/types/types';
import { UserInfo } from '../../services/account/account-rest-interface';
import ConversionModal from '../../components/ConversionModal/ConversionModal';
import { boundMethod } from 'autobind-decorator';
import { hasSchoolSafety } from '../../services/account/account-utils';
import { updateActivityInfoWithLastKnownNavigation } from '../../WinwardTracker';

export interface Props {
    lessonId: string;
}
export interface RouteProps {
    goToLessons: () => void;
    goToMistakeBank: () => void;
}

interface StoreProps {
    allLessons: LessonProgress[];
    userInfo: UserInfo;
}
interface DispatchProps {
    setLessonCompleted: (lessonId: string, userInfo: any) => void;
}
interface State {
    selectedContinueButton: number;
    showConversionModal: boolean;

    lessonSummary?: LessonReport;
    baselineQuestionsGroups: QuestionGroup[];
    mistakeBankLessons: MistakeBankLessons[];
    display: boolean;
}

type AllProps = Props & RouteProps & StoreProps & DispatchProps & RouteComponentProps<any>;
export class LessonComplete extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            selectedContinueButton: 0,
            showConversionModal: false,
            lessonSummary: {} as LessonReport,
            baselineQuestionsGroups: [],
            mistakeBankLessons: [],
            display: false
        };
    }

    public componentDidMount() {
        const { lessonId, allLessons } = this.props;
        const lesson = allLessons.find(l => l.lessonId === lessonId);
        this.loadLessonSummaryData();
        if(lesson && lesson.numCompletedQuestions !== lesson.numTotalQuestions) {
            this.props.history.push(`${process.env.REACT_APP_BASE_URL}app/lessons/${lesson.lessonId}`);
        }
    }

    loadLessonSummaryData() {
        const { lessonId, userInfo } = this.props;

        Promise.all([
            getLessonSummary(lessonId, userInfo),
            getBaselineQuestions(lessonId, userInfo),
            getMistakeBankOverviewNoSS(userInfo)
        ]).then(([ lessonSummary, baselineQuestionData, mistakeBankLessons ]) => {
            const questionGroups = baselineQuestionData ? baselineQuestionData.questionGroups : [];
            this.setState({ lessonSummary, baselineQuestionsGroups: questionGroups, mistakeBankLessons, display: true });
        });

    }

    public render() {
        // Baseline Questions
        const { lessonSummary, mistakeBankLessons, display } = this.state;

        if (lessonSummary === undefined || !display) {
            return (<Loading />);
        }
        const {
            baselineComparisons,
            numPostLessonQuestionsCorrect: correct,
            numPostLessonQuestionsTotal: total
        } = lessonSummary;
        const baselineBefore = lessonSummary.numBaselineQuestionsCorrect;
        const baselineAfter = baselineComparisons !== undefined ? baselineComparisons.filter(c => c.isPostLessonCorrect).length : 0;
        const baselineTotal = lessonSummary.numBaselineQuestionsTotal;

        const improvement = baselineAfter - baselineBefore > 0;

        const allMissedTopics = mistakeBankLessons.filter(l => l.lessonId === this.props.lessonId);

        // MC Questions
        const missedByTopic = {} as any;
        // const topicList = {} as any;
        (allMissedTopics as any[]).forEach((mc, i) => {
            missedByTopic[mc.topicName] = {
              name: mc.topicName,
              numQuestionsMissed: mc.numQuestionsMissed,
              numManualMistakeEntries: mc.numManualMistakeEntries
            };
        });

        const questionCols: Column[] = [
            {title: 'Q', dataProperty: 'question', width: '15%', sortable: false},
            {title: 'TOPIC', dataProperty: 'topic', width: '55%', sortable: false},
            {title: 'BEFORE', dataProperty: 'before', width: '15%', sortable: false},
            {title: 'AFTER', dataProperty: 'after', width: '15%', sortable: false}
        ];

        const { baselineQuestionsGroups } = this.state;
        const baselineQuestions = baselineQuestionsGroups.reduce((acc, questionGroup) =>
            acc.concat(questionGroup.questions), [] as Question[]);
        const questionRows: RowData[] = baselineQuestions.map((q, i) => {
            const isBaselineCorrect = i < baselineComparisons.length ? baselineComparisons[i].isBaselineCorrect : false;
            const isPostLessonCorrect = i < baselineComparisons.length ? baselineComparisons[i].isPostLessonCorrect :
                false;
            const row: RowData = {
                id: q.questionId,
                data: {
                    question: {content: i + 1, comparator: ''},
                    topic: {content: q.topicName, comparator: ''},
                    before: {content: this.getImage(isBaselineCorrect), comparator: ''},
                    after: {content: this.getImage(isPostLessonCorrect), comparator: ''},
                }
            };

            return row;
        });

        const hasPostLessonQuestions = total > 0;
        const hasBaselineQuestions = questionRows.length > 0;

        const mistakeCols: Column[] = [
            {title: 'TOPIC', dataProperty: 'topic', width: '60%', sortable: false},
            {title: '#MISSED', dataProperty: 'missed', width: '20%', sortable: false},
            {title: '#ADDED', dataProperty: 'added', width: '20%', sortable: false}
        ];

        let manuallyAddedMistakeBank = 0;
        let automaticAddedMistakeBank = 0;

        const mistakeRows: RowData[] = Object.keys(missedByTopic).map((topic, i) => {
            const row: RowData = {
                id: `${i}`,
                data: {
                    topic: {content: missedByTopic[topic].name, comparator: ''},
                    missed: {
                      content: missedByTopic[topic].numQuestionsMissed,
                      comparator: ''
                    },
                    added: {
                      content: missedByTopic[topic].numManualMistakeEntries,
                      comparator: ''
                    }
                }
            };
            automaticAddedMistakeBank += missedByTopic[topic].numQuestionsMissed;
            manuallyAddedMistakeBank += missedByTopic[topic].numManualMistakeEntries;

            return row;
        });

         
        mistakeRows.push({
            id: "mistake-bank-totals",
            isTotalRow: true,
            totalRowClassName: "table-row",
            data: {
                topic: { content: "TOTAL", comparator: ""},
                missed: { content: automaticAddedMistakeBank, comparator: automaticAddedMistakeBank},
                added: { content: manuallyAddedMistakeBank, comparator: manuallyAddedMistakeBank},
            }
        })

        const numBaselineMissed = (baselineTotal - baselineAfter) > 0 ? true : false;
        const numPostMissed = (total - correct) > 0 ? true : false;
        const lesson = this.props.allLessons.find(l => l.lessonId === this.props.lessonId);
        const lid = this.props.lessonId;
        const history = this.props.history;
        function returnToVideo() {
            history.push(`/app/lessons/${lid}/rewatchVideo`);
        }

        const sixtyWidthStyle = {
          width: '60%'
        };

        const twentyWidthStyle = {
          width: '20%',
          textAlign: 'center'
        } as const; // Hmm, interesting hack for '...not assignable to CSSProperties' error

        const negativeBaseline = (baselineAfter - baselineBefore) < 0;
        const baselineDifferential = baselineAfter - baselineBefore;
        let activity = `Completed ${lesson?.category.categoryName}: ${lesson?.lessonName}`
   
        const accuracyPercentage = Math.floor((correct / total) * 100);

        updateActivityInfoWithLastKnownNavigation(activity)

        return (
            <div className="page-content__inner">

                <div className="lesson-complete student-dashboard">
                    {/* This allows us to change the width of the page in case we don't want full width */}
                    <div className="lesson-complete-width-control">
                        <div className="row dashboard-section align-items-end">
                            <div className={hasBaselineQuestions || hasPostLessonQuestions ? `col-6` : `col-12 text-center`}>
                                <div className="page-title mb-0">
                                    <h1>{`Great job ${this.props.userInfo.firstName}!`}</h1>
                                </div>
                                <p className="page-title--subtitle mb-0">
                                    {lesson ? `${lesson.lessonName} lesson completed!` : 'Lesson completed!'}
                                </p>
                            </div>
                            {(hasBaselineQuestions || hasPostLessonQuestions) &&
                                <div className="col-md-6 text-right">
                                <Button
                                        bgColor="green"
                                        textColor="white"
                                        className="summary-buttons"
                                        text="RETURN TO MY LESSONS"
                                        onClick={this.props.goToLessons}
                                    />
                                </div>
                            }
                        </div>

                        <div className="dashboard-section">
                            <div className={hasBaselineQuestions || hasPostLessonQuestions ? `section-lesson-metrics dashboard-block wide` : `section-lesson-metrics `}>
                                {/* SECTION 1: Block title + Icon */}
                                {(hasBaselineQuestions || hasPostLessonQuestions) &&
                                    <div className="row">
                                        <div className="col-12">
                                            <h2 className="student-dashboard-generic-title">
                                                <img alt="" src="/assets/images/icons/v2/ico-metrics.svg" width="42" height="29" className="mr-2" />
                                                Lesson Summary
                                            </h2>
                                        </div>
                                    </div>
                                }
                                {/* SECTION 2: Charts 2-col */}
                                {(hasBaselineQuestions || hasPostLessonQuestions) &&
                                    <div className="row">
                                        {/* TEMPREF to remove after QA: <Card colClass="mb-2" cssClass="m-0 p-0" col={hasBaselineQuestions ? { lg: 12, md: 12 } : {lg: 6, md: 6}}> */}
                                        <Card colClass="mb-2" cssClass="m-0 p-0" col={{lg: 12, md: 12 }}>
                                            <div className="row mx-0">
                                                {hasBaselineQuestions && !negativeBaseline &&
                                                    <div className="col-md-6 px-1 mb-2 mb-md-0">
                                                        <div className="statistics-container h-100 purple">
                                                            <div className="dashboard-block-title">
                                                                <img className="mr-2" src="/assets/images/icons/v2/ico-pulse.svg" width="37" height="37" alt="" />
                                                                &nbsp;Improvement From Baseline
                                                            </div>
                                                            <div className="row align-items-center">
                                                                <div className="col col--dial mb-3">
                                                                    <div className="radial-wrapper" style={{width: '250px', margin: '0 auto'}}>
                                                                        <RadialProgress
                                                                            size={250}
                                                                            radius={105}
                                                                            startColor="#ffffff"
                                                                            endColor="#ffffff"
                                                                            backgroundColor="#FFFFFF"
                                                                            pegOffset={45}
                                                                            progress={Math.floor((baselineAfter - baselineBefore) * 100)}
                                                                            text={(improvement ? '+' : '') + (baselineAfter - baselineBefore)}
                                                                            cssClass="chart-not-100"
                                                                            textStyle={{ fontSize: '94px' }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col col--content">
                                                                    <div className="">
                                                                        <div className="mx-auto">
                                                                            <p>
                                                                                You got <span className="bold italic">{baselineAfter - baselineBefore} more
                                                                                {(baselineDifferential > 1 || baselineDifferential === 0) ?
                                                                                ' questions ' : ' question '}
                                                                                right out of the {baselineTotal} </span>
                                                                                that repeat before and after the lesson.&nbsp;
                                                                                {numBaselineMissed && <span>
                                                                                The
                                                                                <span className="bold">
                                                                                    &nbsp;{baselineTotal - baselineAfter}&nbsp;
                                                                                </span>
                                                                                {(baselineTotal - baselineAfter > 1) ||
                                                                                (baselineTotal - baselineAfter === 0)
                                                                                ? 'questions ' : 'question '}
                                                                                <p className="bold italic">you still missed
                                                                                {(baselineTotal - baselineAfter > 1) ||
                                                                                (baselineTotal - baselineAfter === 0)
                                                                                ? ' have ' : ' has '}
                                                                                been added to your Mistake Bank.</p>
                                                                                </span>}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {hasPostLessonQuestions &&
                                                    <div className={(hasBaselineQuestions && !negativeBaseline) ? 'px-1 col-md-6' : 'px-1 col-lg-12'}>
                                                        <div className="statistics-container h-100 blue">
                                                            <div className="dashboard-block-title">
                                                                <img className="mr-2" src="/assets/images/icons/v2/ico-question-blue.svg" width="37" height="37" alt="" />
                                                                &nbsp;Overall Question Accuracy
                                                            </div>
                                                            <div className="row align-items-center">
                                                                <div className="col col--dial mb-3">
                                                                    <div className="radial-wrapper" style={{width: '250px', margin: '0 auto'}}>
                                                                        <RadialProgress
                                                                            size={250}
                                                                            radius={105}
                                                                            startColor="#50C3F1"
                                                                            endColor="#63DDAA"
                                                                            backgroundColor="#FFFFFF"
                                                                            pegOffset={10}
                                                                            progress={accuracyPercentage}
                                                                            text={accuracyPercentage + '%'}
                                                                            textStyle={{ color: '#2b2d2d', fontSize: accuracyPercentage >= 100 ? '60px' : '94px' }}
                                                                            cssClass={accuracyPercentage === 0 ? "chart-100" : "chart-not-100"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col col--content">
                                                                    <div className="">
                                                                        <p>
                                                                            You answered
                                                                            <span className="bold"> {correct}/{total} questions correctly</span> in the multiple choice.</p>
                                                                            {numPostMissed &&
                                                                            <p className="bold italic">
                                                                                The {total - correct} you missed ha{((total - correct > 1) || (total - correct === 0)) ? 've ' : 's '}
                                                                                been added to your Mistake Bank.
                                                                            </p>
                                                                            }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Card>
                                    </div>
                                }

                                {/* SECTION 3: 3-col Buttons */}
                                <div className="row m-0">
                                {(hasBaselineQuestions || hasPostLessonQuestions) &&
                                    <Card colClass="px-1" cssClass="lesson-complete-card--outline light mt-0 mb-2" col={hasBaselineQuestions ? { lg: 12, md: 12 } : {lg: 6, md: 6}}>
                                        <>
                                            {hasPostLessonQuestions &&
                                                <div className="statistics-container text-center">
                                                    <div>
                                                        <h2 className="box-title mb-1">Looking for more ways to improve?</h2>
                                                        <p className="mb-4">Review missed questions until you're feeling 100%.</p>
                                                        {hasSchoolSafety(this.props.userInfo) &&
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <p className="buttonLabel"><b><i>Want to review concepts from the lesson?</i></b></p>
                                                                    <Button
                                                                        bgColor="green"
                                                                        textColor="white"
                                                                        className="summary-buttons"
                                                                        text="Rewatch Lesson Video"
                                                                        onClick={returnToVideo}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                            {!hasSchoolSafety(this.props.userInfo) &&
                                                                <div className="row justify-content-center">
                                                                    <div className="col-md-6 ctaCard--col">
                                                                        <div className="ctaCard">
                                                                        <p className="buttonLabel mb-2"><b><i>Want to dive into your missed questions?</i></b></p>
                                                                            <Button
                                                                                bgColor="blue"
                                                                                textColor="white"
                                                                                className="summary-buttons linkable"
                                                                                text="VISIT MISTAKE BANK"
                                                                                link="/app/mistake-bank/"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 ctaCard--col">
                                                                        <div className="ctaCard">
                                                                            <p className="buttonLabel mb-2"><b><i>Want to review concepts from the lesson?</i></b></p>
                                                                            <Button
                                                                                bgColor="green"
                                                                                textColor="white"
                                                                                className="summary-buttons linkable"
                                                                                text="REWATCH LESSON VIDEO"
                                                                                onClick={returnToVideo}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 ctaCard--col">
                                                                        <div className="ctaCard">
                                                                            <p className="buttonLabel mb-2"><b><i>Interested in how your accuracy converts to ACT/SAT scores?</i></b></p>
                                                                            <Button
                                                                                bgColor="purple"
                                                                                textColor="white"
                                                                                className="summary-buttons linkable"
                                                                                text="CONVERT ACCURACY"
                                                                                onClick={this.openConversionModal}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    </Card>
                                }
                                </div>

                                {/* SECTION 4: 2-col tables */ }
                                {(hasBaselineQuestions || hasPostLessonQuestions) &&
                                <div className="row mx-0">
                                    {!hasBaselineQuestions &&
                                        <Card colClass="px-1" cssClass="lesson-complete-card--outline mt-0 mb-2" col={{ lg: 6, md: 6 }}>
                                            <div className="col-lg-12">
                                                <div className="mistake-bank-card">
                                                    <div className="lesson-card-header">
                                                        <div>
                                                            <span className="lesson-card-header-text">
                                                                <span className="lesson-card-mistake-bank-text">Mistake Bank</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <DataGrid
                                                        columns={mistakeCols}
                                                        data={mistakeRows}
                                                    />
                                                </div>
                                            </div>
                                        </Card>
                                    }
                                    {hasBaselineQuestions &&
                                        <Card colClass="px-1" cssClass="baseline-table lesson-complete-card--outline mt-0 mb-2" col={{xl: 6, lg: 12, md: 12}}>
                                            <div className="lesson-card-header">
                                                <span className="lesson-card-header-text">Baseline Questions</span>
                                            </div>
                                            <DataGrid
                                                columns={questionCols}
                                                data={questionRows}
                                            />
                                        </Card>}
                                    {(hasPostLessonQuestions && hasBaselineQuestions) &&
                                    <Card colClass="px-1" cssClass="mistakeBank-table lesson-complete-card--outline mt-0 mb-2" col={{xl: 6, lg: 12, md: 12}}>
                                        <div className="mistake-bank-card">
                                            <div className="lesson-card-header">
                                                <div>
                                                    <span className="lesson-card-header-text">
                                                        <span className="lesson-card-mistake-bank-text">Mistake Bank</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <DataGrid
                                                columns={mistakeCols}
                                                data={mistakeRows}
                                            />
                                        </div>
                                    </Card>}
                                </div>
                                }
                            </div>
                        </div>

                        { this.state.display &&
                            <>
                                {!hasPostLessonQuestions &&
                                    <div className="row justify-content-lg-center justify-content-md-center text-right lesson-complete-buttons">
                                        <div className="pt-3 col-12 col-lg-auto text-center">
                                            <Button
                                                bgColor="blue"
                                                textColor="white"
                                                className="summary-buttons"
                                                text="RETURN TO MY LESSONS"
                                                onClick={this.props.goToLessons}
                                            />

                                            <span>
                                                <p id="noPostLessonQuestions">
                                                    Or, you can also&nbsp;
                                                    <a href="#" id="rewatchVideo" title="Rewatch the Video Lesson" onClick={returnToVideo}>
                                                    rewatch the video lesson
                                                    </a>
                                                    <br />
                                                    if you need to go back to consolidate your learning.
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>

                <ConversionModal show={this.state.showConversionModal} onClose={this.closeConversionModal} />
            </div>
        );
    }

    private getImage(pass: boolean) {
        if(pass) {
            return () => (
                <div className="before-after-image">
                    <img alt="" src="/assets/images/icons/v2/ico-check-green.svg"/>
                </div>
            );
        } else {
            return () => (
                <div className="before-after-image">
                    <img alt="" src="/assets/images/icons/v2/ico-x-red.svg"/>
                </div>
            );
        }
    }

    
    @boundMethod
    private openConversionModal() {
        this.setState({
            showConversionModal: true
        });
    }

    
    @boundMethod
    private closeConversionModal() {
        this.setState({
            showConversionModal: false
        });
    }

}
