import { createGenericAuthRequest, ignoreJsonError } from '../../request/request-utils'

const request = createGenericAuthRequest(process.env.REACT_APP_ELEARN_URL || '/')

interface IStudentPracticeTestProgress {
  studentLessons: any
  studentLessonsOverview: any
  practiceTestScoresSAT: any
  practiceTestScoresACT: any
  practiceTestScoresIA: any
  practiceTestWithUserAnswers: any
}

export function getStudentPracticeTestSummary(studentEmail: string, testType: string, testName: string): Promise<IStudentPracticeTestProgress> {
  return request<any>(`/reports/teacher/practice-test-student-progress/${studentEmail}/${testType}/${testName}`)
}
