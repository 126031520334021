import './TableRow.css';

import * as React from 'react';

import { DragSource, DropTarget } from 'react-dnd';

import { CellContents } from '../../types/types';
import DragHandle from './components/DragHandle/DragHandle';
import TableCell from './components/TableCell/TableCell';
import { findDOMNode } from 'react-dom';

interface Props {
    index: number;
    data: CellContents[];
    dragReorder?: boolean;
    onRowDrag?: (oldIndex: number, newIndex: number) => void;
    summaryRowClassName?: string
    averageRowClassName?: string
    highlightRowClassName?: string
    totalRowClassName?: string
    isHighlighted?: boolean;
    colSpan?: number;
}

interface DragProps {
    connectDragSource?: any;
    connectDropTarget?: any;
    connectDragPreview?: any;
    isDragging?: boolean;
}

const sourceConnector = {
  beginDrag(props: Props) {
    return {
      id: props.index,
      index: props.index,
    };
  }
};

export default class TableRow extends React.Component<Props & DragProps, {}> {
    constructor(props: Props & DragProps) {
        super(props);
    }

    public render() {
        const { connectDragSource, connectDropTarget, connectDragPreview, isHighlighted, colSpan, isDragging, data, highlightRowClassName = "" } = this.props;
        const style = isDragging ? { opacity: 0 } : {};
        const className = `table-row ${highlightRowClassName}`;
        return (
            <tr className={className} style={style}>
                {
                    data.map((cell, i) => (
                        <TableCell
                            width={cell.width}
                            content={cell.content}
                            key={`table-cell${i}`}
                            isHighlighted={isHighlighted}  // Pass isHighlighted prop
                            colSpan={isHighlighted && i === 0 ? colSpan : undefined}  // Only pass colSpan to the first cell if isHighlighted is true
                        />
                    ))
                }
                {this.props.dragReorder &&
                    connectDragSource(
                        <div>
                            <TableCell
                                width={'auto'}
                                content={() => <DragHandle />}
                                isHighlighted={isHighlighted}  // Pass isHighlighted prop
                            />
                        </div>
                    )
                }
            </tr>
        );
    }
}


export class TableSummaryRow extends React.Component<Props & DragProps, {}> {
    constructor(props: Props & DragProps) {
        super(props);
    }

    public render() {
        const { connectDragSource, connectDropTarget, connectDragPreview, isDragging, data, summaryRowClassName } = this.props;
        const style = isDragging ? { opacity: 0 } : {};
        const className = summaryRowClassName ? `table-summary-row ${summaryRowClassName}`: "table-summary-row"

        return (
            <tr className={className} style={style}>
                {
                    data.map((cell, i, arr) => {
                        return <TableCell width={cell.width} content={cell.content} key={`table-cell${i}`} />;
                    })
                }
                {this.props.dragReorder &&
                    connectDragSource(<div><TableCell width={'auto'} content={() => <DragHandle />} /></div>)}
            </tr>
        );
    }
}

export class TableAverageRow extends React.Component<Props & DragProps, {}> {
    constructor(props: Props & DragProps) {
        super(props);
    }

    public render() {
        const { data, averageRowClassName } = this.props;
        const className = averageRowClassName ? `table-row table-average-row ${averageRowClassName}` : "table-row table-average-row";
        // Only one cell has the average info!
        let averageData = data.find(d => d.content && d.content !== "");
        let averageInfo = averageData?.content || "";
        const colSpan = data.length;
        return (
            <tr className={className}>
                <TableCell
                    style={{ textAlign: "center" }}
                    width={"100%"}
                    content={averageInfo}
                    key={`table-cell-average`}
                    colSpan={colSpan}
                />
            </tr>
        );
    }
}


export class TableTotalRow extends React.Component<Props & DragProps, {}> {
    constructor(props: Props & DragProps) {
        super(props)
    }

    public render() {
        const { connectDragSource, totalRowClassName, isDragging, data } = this.props;
        const style = isDragging ? { opacity: 0 } : {};
        const className = totalRowClassName ? `table-total-row ${totalRowClassName}`: "table-total-row"

        return (
            <tr className={className} style={style}>
                {
                    data.map((cell, i, arr) => {
                        return <TableCell width={cell.width} content={cell.content} key={`table-cell${i}`} />;
                    })
                }
                {this.props.dragReorder &&
                    connectDragSource(<div><TableCell width={'auto'} content={() => <DragHandle />} /></div>)}
            </tr>
        );
    }
}


