import React, { Component } from "react"
import { connect } from "react-redux"
import { AppState } from "../../../store"
import { ResilienceReminders } from "."

const mapStateToProps = ({ account }: AppState, ownProps: any) => {
    return {
        ...ownProps,
        userInfo: account.userInfo,
    }
}

const mapDispatchToProps = { }

const ConnectedItem = connect(mapStateToProps, mapDispatchToProps)(ResilienceReminders)

export default function (props: any) {
    return <ConnectedItem {...props} />
}
