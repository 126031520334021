import React, { createRef } from 'react';
import { boundMethod } from 'autobind-decorator';
import BlueGeometricBackground from '../../components/BlueGeometricBackground/BlueGeometricBackground';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import { RouteComponentProps } from 'react-router-dom';
import { UserInfo } from '../../services/account/account-rest-interface';
import './Login.css';
import CleverLoginButton from './components/CleverLoginButton';

export interface StoreProps {
    requestingLogin: boolean;
}

export interface DispatchProps {
    loginUser: (username: string, password: string) => Promise<UserInfo>;
    loginCleverUser: (code: string, host: string) => Promise<UserInfo>;
}

interface State {
    hasError: boolean
    errorText: string
    showModal: boolean
    showCleverButton: boolean
}

export type AllProps = StoreProps & DispatchProps & RouteComponentProps<any>;

export class CleverLoginWithoutRouting extends React.Component<AllProps, State> {
    private emailRef = createRef<HTMLInputElement>();

    constructor(props: AllProps) {
        super(props);
        this.state = {
            hasError: false,
            errorText: '',
            showModal: this.detectBrowser() === 0 ? true : false,
            showCleverButton: true
        };
    }

    public componentDidMount() {
        let {host, search, protocol} = window.location
        let searchParams = new URLSearchParams(search)
        let code = searchParams.get("code")
        let url = `${protocol}//${host}`
        
        if (code) {
            // Login with Clever (SSO OAUTH)
            this.setState({showCleverButton: false})
            this.cleverLogin(code, url)
        }
    }

    public render() {
        let buttonColor = 'blue';
        let textColor = 'white';
        let widthBtn = 'login-modal-btn';
        let {showCleverButton, hasError, errorText} = this.state

        return (
            <BlueGeometricBackground className="login-container">
                <div className="row login__header">
                    <div className="col-12">
                        <a href="https://www.winwardacademy.com">
                            <img 
                                alt="Winward Academy Logo"
                                src={'/assets/images/winward-academy-logo-white.svg'} 
                                className="wa-logo"
                            />
                        </a>
                    </div>
                    { showCleverButton && !hasError ?
                        <>
                            <div className="col-12">
                                <h1>Please Log In with Clever</h1>
                            </div>
                            <div className="col-12">
                                <CleverLoginButton/>
                            </div>
                        </>
                        : 
                        !hasError ?
                            <div className="col-12">
                                <h1>Clever Login...</h1>
                            </div>
                            :
                            <div className="col-12">
                                <h1>{errorText}</h1>
                                <div className="col-12">
                                    <CleverLoginButton/>
                                </div>
                            </div>
                    }
                </div>

                <Modal className="heading-banner-login modal" show={this.state.showModal} onClose={this.toggleModal}>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <h1 className="cushion-element-login">Welcome to Winward Academy!</h1>
                        </div>
                        <div className="body-login coverBottom-login">
                            <div className="cushion-heading-login">
                                <h3 className="dark-blue-text-login text-center">We’re thrilled you’re here!</h3>
                                <h3 className="dark-blue-text-login text-center">
                                    Please note that this site only works in up-to-date browsers.
                                    Come back with a recent version of Chrome, Safari, Edge, or Firefox.</h3>
                            </div>
                            <Button
                                text="I UNDERSTAND"
                                textColor={textColor as any}
                                bgColor={buttonColor as any}
                                className={widthBtn as any}
                                onClick={this.toggleModal}
                            />
                        </div>
                    </div>
                </Modal>
            </BlueGeometricBackground>
        )
    }

    private toggleModal() {
        this.setState({ showModal: !this.state.showModal });
        window.location.href = 'https://winwardacademy.com';
    }

    private cleverLogin(cleverCode: string, host: string) {        
        this.props.loginCleverUser(cleverCode, host)
            .then(this.onLoginSuccess)
            .catch(this.onLoginError)
    }

    @boundMethod
    private onLoginSuccess(userInfo: UserInfo) {
        this.setState({
            hasError: false,
            errorText: '',
        }, this.routeToHome);
    }

    @boundMethod
    private onLoginError(e: any) {
        if (e.body && e.status === 401) {
            e.text().then(output =>  {
                this.setState({
                    hasError: true,
                    errorText: output 
                })
            })
        }
        else {
            this.setState({ 
                hasError: true, 
                errorText: "Login problem with Clever! Please try again."
            })
        }
    }

    private routeToHome() {
        const baseUrl = process.env.REACT_APP_BASE_URL
        this.props.history.push(baseUrl + 'app')
    }

    private detectBrowser() {
        const ua = navigator.userAgent;
        let browser = '';
        let version = 0;

        if (/Firefox[\/\s](\d+\.\d+)/.test(ua)) {
            browser = 'firefox';
            version = Number(RegExp.$1);
        } else if (ua.lastIndexOf('Chrome/') > 0) {
            version = Number(ua.substr(ua.lastIndexOf('Chrome/') + 7, 5));
            browser = 'chrome';
        } else if (ua.lastIndexOf('Safari/') > 0) {
            version = Number(ua.substr(ua.lastIndexOf('Safari/') + 7, 5));
            browser = 'safari';
        } else {
            if(ua.lastIndexOf('MSIE ') > 0) {
                version = 10;
                browser = 'ie';
            } else if(ua.lastIndexOf('Trident/') > 0) {
                version = 11;
                browser = 'ie';
            } else if(ua.lastIndexOf('Edge/') > 0) {
                version = 12;
                browser = 'edge';
            } else if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(ua)) {
                browser = 'mobile';
            }
        }

        switch(browser) {
            case 'firefox':
                if( version < parseInt(process.env.REACT_APP_MIN_FIREFOX_VERSION || '0') ) {
                    return 0;
                }
                return 1;
            case 'chrome':
                if( version < parseInt(process.env.REACT_APP_MIN_CHROME_VERSION || '0')) {
                    return 0;
                }
                return 1;
            case 'safari':
                if( version < parseInt(process.env.REACT_APP_MIN_SAFARI_VERSION || '0')) {
                    return 0;
                }
                return 1;
            case 'ie':
                return 0;
            case 'mobile':
                return 1;
            case 'edge':
                return 1;
            default:
                return 0;
        }
    }
}

export default CleverLoginWithoutRouting;
