import React, { createRef } from 'react';
import { boundMethod } from 'autobind-decorator';
import BlueGeometricBackground from '../../components/BlueGeometricBackground/BlueGeometricBackground';
import { RouteComponentProps } from 'react-router-dom';
import { UserInfo } from '../../services/account/account-rest-interface';
import './Login.css';
import ClassLinkLoginButton from './components/ClassLinkLoginButton';

export interface StoreProps {
    requestingLogin: boolean;
}

export interface DispatchProps {
    loginUser: (username: string, password: string) => Promise<UserInfo>;
    loginClassLinkUser: (code: string, host: string) => Promise<UserInfo>;
}

interface State {
    hasError: boolean
    errorText: string
    showModal: boolean
    showClassLinkButton: boolean
}

export type AllProps = StoreProps & DispatchProps & RouteComponentProps<any>;

export class ClassLinkLoginWithoutRouting extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            hasError: false,
            errorText: '',
            showModal: false,
            showClassLinkButton: true
        };
    }

    public componentDidMount() {
        let {host, search, protocol} = window.location
        let searchParams = new URLSearchParams(search)
        let code = searchParams.get("code")
        let url = `${protocol}//${host}`
        
        if (code) {
            // Login with ClassLink (SSO OAUTH)
            this.setState({showClassLinkButton: false})
            this.classLinkLogin(code, url)
        }
    }

    public render() {
        let {showClassLinkButton, hasError, errorText} = this.state

        return (
            <BlueGeometricBackground className="login-container">
                <div className="row login__header">
                    <div className="col-12">
                        <a href="https://www.winwardacademy.com">
                            <img 
                                alt="Winward Academy Logo"
                                src={'/assets/images/winward-academy-logo-white.svg'} 
                                className="wa-logo"
                            />
                        </a>
                    </div>
                    { showClassLinkButton && !hasError ?
                        <>
                            <div className="col-12">
                                <h1>Please Log In with ClassLink</h1>
                            </div>
                            <div className="col-12">
                                <ClassLinkLoginButton/>
                            </div>
                        </>
                        : 
                        !hasError ?
                            <div className="col-12">
                                <h1>ClassLink Login...</h1>
                            </div>
                            :
                            <div className="col-12">
                                <h1>{errorText}</h1>
                                <div className="col-12">
                                    <ClassLinkLoginButton/>
                                </div>
                            </div>
                    }
                </div>
            </BlueGeometricBackground>
        )
    }

    private toggleModal() {
        this.setState({ showModal: !this.state.showModal });
        window.location.href = 'https://winwardacademy.com';
    }

    private classLinkLogin(code: string, host: string) {        
        this.props.loginClassLinkUser(code, host)
            .then(this.onLoginSuccess)
            .catch(this.onLoginError)
    }

    @boundMethod
    private onLoginSuccess(userInfo: UserInfo) {
        this.setState({
            hasError: false,
            errorText: '',
        }, this.routeToHome);
    }

    @boundMethod
    private onLoginError(e: any) {
        if (e.body && e.status === 401) {
            e.text().then(output =>  {
                this.setState({
                    hasError: true,
                    errorText: output 
                })
            })
        }
        else {
            this.setState({ 
                hasError: true, 
                errorText: "Login problem with ClassLink! Please try again."
            })
        }
    }

    private routeToHome() {
        const baseUrl = process.env.REACT_APP_BASE_URL
        this.props.history.push(baseUrl + 'app')
    }
}

export default ClassLinkLoginWithoutRouting;
