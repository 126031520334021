import './PracticePrint.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';
import * as _ from 'lodash';

import { Link, RouteComponentProps } from 'react-router-dom';
import { PageContent, PageTitle } from '../../components/PageContent/PageContent';
import { PrintPracticeTable } from './components/PrintPracticeTable/PrintPracticeTable';

export interface Props {
    testData: any;
}

export interface StoreProps {
    practiceTestSummary: any;
}

export interface DispatchProps {
    getPracticeTestByName: (testName: string) => Promise<any>;
}

export interface RouteProps extends RouteComponentProps<any> {}

interface State {
    testData: any;
    summaryData: any;
}

export type AllProps = Props & RouteProps & DispatchProps & StoreProps;


export class PracticePrint extends React.Component<AllProps, State> {
    constructor (props: AllProps) {
        super(props);
        this.state = {
            testData: null,
            summaryData: null
        };
    }

    componentDidMount() {
        if (_.isEmpty(this.props.testData)) {
            this.props.getPracticeTestByName(this.props.match.params.testNumber);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (this.state.testData === null && nextProps.practiceTestSummary) {
            this.setState({
                testData: nextProps.practiceTestSummary.testData.practiceTests,
                summaryData: nextProps.practiceTestSummary.testData.scores
            }, () => {
                setTimeout(() => window.print(), 250);
            });
        }
    }

    public render() {
        const { testData, summaryData } = this.state;
        const testNumber = this.props.match.params.testNumber;
        let reviewUrl = '/app/practice-tests/scoring/' + testNumber;
        if (testNumber === 'Diagnostic') {
            reviewUrl = '/app/diagnostic/scoring/Diagnostic';
        }
        if (testData && summaryData) {
            return (
                <PageContent className="study-guide-container practice-test-dashboard">
                    <PageTitle title="Print Practice Tests" classes="do-not-print">
                            <div className="navigation-button-container">
                                <button 
                                    onClick={() => window.print()}
                                    className="title-navigation-buttons btn btn--bg-blue do-not-print custom-nav-button"
                                >
                                    PRINT
                                </button>
                                <Link 
                                    to={reviewUrl} 
                                    className="do-not-print"
                                >
                                    <button className="title-navigation-buttons btn btn--bg-green">
                                        BACK TO REVIEW
                                    </button>
                                </Link>
                            </div>
                    </PageTitle>

                    <div className="row summary-data-container">
                        <div className="col-lg-12">
                            <h4>Score Summary - {testNumber}</h4>
                            <h5>Prepared exclusively for {summaryData.emailAddress}</h5>
                            <div className="row">
                                <div className="col-12">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Subject</th>
                                                <th>Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>English</td>
                                                <td>{summaryData.english}</td>
                                            </tr>
                                            <tr>
                                                <td>Math</td>
                                                <td>{summaryData.math}</td>
                                            </tr>
                                            <tr>
                                                <td>Reading</td>
                                                <td>{summaryData.reading}</td>
                                            </tr>
                                            <tr>
                                                <td>Science</td>
                                                <td>{summaryData.science}</td>
                                            </tr>
                                            <tr>
                                                <td>Composite</td>
                                                <td>{summaryData.average}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <PrintPracticeTable data={testData.englishAnswers} subject="English" />
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12">
                            <PrintPracticeTable data={testData.mathAnswers} subject="Math" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <PrintPracticeTable data={testData.readingAnswers} subject="Reading" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <PrintPracticeTable data={testData.scienceAnswers} subject="Science" />
                        </div>
                    </div>    
                </PageContent>
            );
        } else {
            return (
                <div></div>
            );
        }     
    }
}
