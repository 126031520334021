import { getSessionStorageByID } from '../utils/session-storage-utility';
export const createMaiaAuthRequest = (apiUrl: string) => {
    const postRequest = <T>(endpointUrl, username, password, maiaSecret) => {
        const fullUrl = apiUrl + endpointUrl;
        const headers = {
            'Authorization': 'Basic ' + btoa(`${username}:${password}`),
            'Content-Type': 'application/x-www-form-urlencoded',
        };

        return fetch(fullUrl, {
            credentials: 'omit',
            method: 'POST',
            mode: 'cors',
            headers: headers,
        })
        .then(response => {
            if(!response.ok) {
                return Promise.reject(response) as Promise<T>;
            } else {
                return response.json() as Promise<T>;
            }
        });
    };
    return postRequest;
};

export const createAuthPostRequest = (apiUrl: string) => {
    const postRequest = <T>(endpointUrl, username, password) => {

        const fullUrl = apiUrl + endpointUrl;
        const headers = {
            'Authorization': 'Basic ' + btoa(`${username}:${password}`),
            'Content-Type': 'application/x-www-form-urlencoded',
        };

        return fetch(fullUrl, {
            credentials: 'omit',
            method: 'POST',
            mode: 'cors',
            headers: headers
        })
        .then(response => {
            if(!response.ok) {
                return Promise.reject(response) as Promise<T>;
            } else {
                return response.json() as Promise<T>;
            }
        });
    };
    return postRequest;
};

export const createCleverPostRequest = (apiUrl: string) => {
    const postRequest = <T>(endpointUrl, code: string, host: string) => {
        const fullUrl = apiUrl + endpointUrl;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        const urlencoded = new URLSearchParams();
        urlencoded.append("code", code);
        urlencoded.append("host", host);

        return fetch(fullUrl, {
            credentials: 'omit',
            method: 'POST',
            mode: 'cors',
            headers: headers,
            body: urlencoded
        })
        .then(response => {
            if(!response.ok) {
                return Promise.reject(response) as Promise<T>;
            } else {
                return response.json() as Promise<T>;
            }
        });
    };
    return postRequest;
};

export const createGenericAuthRequest = (apiUrl: string) => {
    const request = <T>(endpointUrl: string, args: object = {}, options: RequestInit = {}) => {
        const fullUrl = apiUrl + endpointUrl;
        const winToken = getSessionStorageByID('x-win-token') || '';
        const userID = getSessionStorageByID('x-win-user') || '';
        return fetch(fullUrl, {
            credentials: 'include',
            method: 'GET',
            mode: 'cors',
            headers: { 
                'Content-Type': 'application/json',
                'x-win-token': winToken,
                'x-win-user': userID
             },
            // No body for GET requests
            body: options && options.method && options.method !== 'GET' ? JSON.stringify(args) : undefined,
            ...options
        })
        .then(response => {
            // if(response.ok || response.headers.get('Content-Length')) {
            if(response.ok) {
                try {
                    // const json = response.json();
                    // return json as Promise<T>;
                    return response.text()
                        .then(text => {
                            return text ? JSON.parse(text) : {};
                        });
                } catch(e) {
                // return Promise.resolve(response) as Promise<T>;
                return Promise.resolve(response) as any;
                }
            }

            return Promise.reject(response) as Promise<T>;
        });
    };

    return request;
};

export const createGenericAuthPostRequest = (apiUrl: string) => {
    const request = <T>(endpointUrl: string, args: object = {}, options: RequestInit = {}) => {
        const fullUrl = apiUrl + endpointUrl
        const winToken = getSessionStorageByID('x-win-token') || ''
        const userID = getSessionStorageByID('x-win-user') || ''
        const method = options.method || 'POST'
        const requestOptions = {
            credentials: 'include' as RequestCredentials,
            method: method,
            mode: 'cors' as RequestMode,
            headers: { 
                'Content-Type': 'application/json',
                'x-win-token': winToken,
                'x-win-user': userID
             },
            // No body for GET requests
            //body: options && options.method && options.method !== 'GET' ? JSON.stringify(args) : undefined,
            body: options && options.method && options.method === 'GET' ? undefined: JSON.stringify(args),
            ...options
        }

        return fetch(fullUrl, requestOptions)
            .then(response => {
                if(response.ok) {
                    try {
                        return response.text()
                            .then(text => {
                                return text ? JSON.parse(text) : {}
                            });
                    } 
                    catch(e) {
                        return Promise.resolve(response) as any
                    }
                }

                return Promise.reject(response) as Promise<T>
            })
    }

    return request
}

export const createFilePostAuthRequest = (apiUrl: string) => {
  const request = <T>(
    endpointUrl: string, 
    formData: any
  ) => {
    const fullUrl = apiUrl + endpointUrl;
    const winToken = getSessionStorageByID('x-win-token') || '';
    const userID = getSessionStorageByID('x-win-user') || '';
    return fetch(fullUrl, {
      credentials: 'include',
      method: 'POST',
      headers: { 
        'x-win-token': winToken,
        'x-win-user': userID,
        // UGH, you have to leave Content-Type blank when using fetch to post a file object. 
        // NOT INTUITIVE!
        //'Content-Type': 'multipart/form-data',
        'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
      },
      body: formData
    })
    .then(response => {
        if(response.ok) {
            try {
                // const json = response.json();
                // return json as Promise<T>;
                return response.text()
                    .then(text => {
                        return text ? JSON.parse(text) : {};
                    });
            } catch(e) {
                return Promise.resolve(response) as any;
            }
        }

        return Promise.reject(response) as Promise<T>;
    });
    };

    return request;
};


export const createParamString = (params: {}, toUpperCase?: boolean) => {
    let str = '?';
    Object.keys(params).forEach((key, index) => {
        const param = params[key];
        if(param === undefined || param === null) { return; }
        if(toUpperCase) { key = key.toUpperCase(); }
        str += key + '=' + param + '&';
    });
    return encodeURI(str.slice(0, str.length - 1));
};

// TODO change the functions that create the fetch requests to not return the json by default
// to get rid of the need for this function
export const ignoreJsonError = (response) => {
    if(response.message === 'Unexpected end of JSON input') {
        const hostname = window.location.host;
        if (hostname !== 'learn.winwardacademy.com') {
            console.log('WA_CLIENT_ERROR', response);
            window.alert('WA_CLIENT_ERROR:' + response);
        }
        return Promise.resolve({});
    }
    return Promise.reject(response);
};

export const createRequestForPDFResource = (apiUrl: string) => {
    const extractFileName = (headers) => {
        let cd:string = headers.get('Content-Disposition')
        let key = "filename="
        let fileName = "default.pdf"
        
        if (!cd) return fileName

        let index = cd.indexOf(key) 
        if (index != -1) {
            fileName = cd.substring(index + key.length)
        }

        return fileName
    }
    const request = <T>(endpointUrl: string, args: object = {}, options: RequestInit = {}) => {
        const fullUrl = apiUrl + endpointUrl
        const winToken = getSessionStorageByID('x-win-token') || ''
        const userID = getSessionStorageByID('x-win-user') || ''
        return fetch(fullUrl, {
            credentials: 'include',
            method: 'GET',
            mode: 'cors',
            headers: { 
                'Content-Type': 'application/pdf',
                'x-win-token': winToken,
                'x-win-user': userID
            }
        })
        .then(response => {
            if(response.ok) {                                
                try {
                    return response.blob()
                        .then(blob => {
                            let fileName = extractFileName(response.headers)
                            let url = window.URL.createObjectURL(blob)
                            let elmA = document.createElement("a")
                            elmA.href = url
                            elmA.download = fileName
                            document.body.appendChild(elmA) 
                            elmA.click()
                            elmA.remove()
                        })
                } 
                catch(e) {
                    return Promise.resolve(response) as any
                }
            }

            return Promise.reject(response) as Promise<T>
        })
    }

    return request;
}

export const createRequestForPDFResource2 = (apiUrl: string) => {
    const request = <T>(endpointUrl: string, args: object = {}, options: RequestInit = {}) => {
        const fullUrl = apiUrl + endpointUrl
        const winToken = getSessionStorageByID('x-win-token') || ''
        const userID = getSessionStorageByID('x-win-user') || ''
        return fetch(fullUrl, {
            credentials: 'include',
            method: 'GET',
            mode: 'cors',
            headers: { 
                'x-win-token': winToken,
                'x-win-user': userID
            }
        })
        .then(response => {
            if(response.ok) {                                
                return response.text()
                        .then(text => {
                            return text ? JSON.parse(text) : {};
                        })
            }

            return Promise.reject(response) as Promise<T>
        })
    }

    return request;
}

export const createPutRequest = (apiUrl: string) => {
    const putRequest = <T>(endpointUrl: string, data?: string) => {
        const fullUrl = apiUrl + endpointUrl
        const winToken = getSessionStorageByID('x-win-token') || ''
        const userID = getSessionStorageByID('x-win-user') || ''
        const headers = {
            'x-win-token': winToken,
            'x-win-user': userID,
            'Accept': 'application/json, application/xml, text/plain, text/html, *.*'
        }        

        return fetch(fullUrl, {
            method: 'PUT',
            mode: 'cors',
            headers: headers,
            body: data
        })
        .then(response => {
            if(!response.ok) {
                return Promise.reject(response) as Promise<T>
            } else {
                return response.json() as Promise<T>
            }
        })
    }

    return putRequest
}

export const createPutRequest2 = (apiUrl: string) => {
    const putRequest = <T>(endpointUrl: string, data?: string) => {
        const fullUrl = apiUrl + endpointUrl
        const winToken = getSessionStorageByID('x-win-token') || ''
        const userID = getSessionStorageByID('x-win-user') || ''
        const headers = {
            'x-win-token': winToken,
            'x-win-user': userID,
            'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
            "Content-Type": "application/json"
        }        

        return fetch(fullUrl, {
            method: 'PUT',
            mode: 'cors',
            headers: headers,
            body: data
        })
        .then(response => {
            if(!response.ok) {
                return Promise.reject(response) as Promise<T>
            } else {
                return response.json() as Promise<T>
            }
        })
    }

    return putRequest
}


