import React from "react"
import "./index.css"

interface MiniCaretProps {
  up: boolean
  onClick?: () => void
}

export const MiniCaret: React.FunctionComponent<MiniCaretProps> = ({up, onClick}) => {
  let innerClassName = `${up? "up": "down"}`
  let imgFile = `${up? "ico-accordion-arrow-up.svg": "ico-accordion-arrow-up.svg"}`
  return (
    <div className='mini-caret' onClick={onClick}>
      <div className={innerClassName}>
      <img
        className="modal-icon "
        src={`/assets/images/icons/v2/` + imgFile}
        width="16"
        height="9"
        alt=""
      />
      </div>    
    </div>
  )
}