import * as React from 'react';

import { PracticeTestScoreSheet, Props, StoreProps } from './PracticeTestScoreSheet';

import { AppState } from '../../store';
import { UserInfo } from '../../services/account/account-rest-interface';
import { connect } from 'react-redux';
import { setPriority } from '../../stores/lesson.store';

const mapStateToProps = ({ account }: AppState) => ({
    userInfo: account.userInfo as UserInfo,
});

const mapDispatchToProps = { setPriority };

const PracticeScoring = connect<StoreProps, {}, Props, AppState>(mapStateToProps, mapDispatchToProps)(PracticeTestScoreSheet);
export default (props) => {
    return (
        <PracticeScoring {...props} />
    );
};
