import * as React from 'react';
import './index.css';
import { UserInfo } from '../../services/account/account-rest-interface';

import { RouteComponentProps } from 'react-router-dom';

import { PageContent, PageTitle } from '../../components/PageContent/PageContent';
import { 
    setSessionStorageItem, 
    getSessionStorageByID, 
    deleteSessionStorageByID 
} from '../../services/utils/session-storage-utility';

import Loading from '../../components/Loading/Loading';

export interface Props extends RouteComponentProps<any> {
    loginMaia: (username: string, maiaToken: string) => Promise<UserInfo>;
}

export interface StoreProps {
}

interface State {
    jwtToken: string;
    displayMessage: string;
    errorMessage?: string;
}

export type AllProps = Props & StoreProps & RouteComponentProps<any>;

export class Maia extends React.Component<Props, State> {
    constructor (props: Props) {
        super(props);
        this.state = {
            jwtToken: props.match.params.token,
            displayMessage: `
                Please be patient while we connect you to Winward Academy.
            `,
            errorMessage: undefined
        };
    }

    public componentDidMount() {
        const { jwtToken } = this.state;
        if (jwtToken === undefined) {
            this.setState({ errorMessage: 'JWT was undefined.' })
            return;
        }
        setTimeout(() => {
            this.authenticateToWinwardAcademy(jwtToken); 
        }, 1000);
    }

    public render() {
        const { displayMessage, errorMessage } = this.state;
        return (
            <PageContent className="practice-test-dashboard">
                <PageTitle title="Winward Academy" />
            <div className="authentication-display">
                <div>
                        <Loading />
                </div>
                <div className="authentication-explanation">
                    <p>{displayMessage}</p>
                    <p style={{ 
                        color: 'red', fontWeight: 'bold'
                    }}>{errorMessage}</p>
                </div>
            </div>
            { false &&
                    <button onClick={() => this.authenticateToWinwardAcademy(this.state.jwtToken)}>
                        Login
                    </button>
            }
            </PageContent>
        );
    }

    private authenticateToWinwardAcademy(jwtToken) {
        deleteSessionStorageByID('x-win-token');
        deleteSessionStorageByID('x-win-user');
        this.props
            .loginMaia('', jwtToken )
            .then(result => {
                if (result[1] && result[1].userInfo) {
                    const emailAddress = result[1].userInfo.emailAddress;
                    // console.log('SUCCESS auth with JWT', jwtToken)
                    // console.log({
                    //     token: getSessionStorageByID('x-win-token'),
                    //     user: getSessionStorageByID('x-win-user')
                    // });
                    setSessionStorageItem('x-win-user', emailAddress); 
                    // console.log({
                    //     token: getSessionStorageByID('x-win-token'),
                    //     user: getSessionStorageByID('x-win-user')
                    // });
                    this.props.history.push('/app');
                } else {
                    // console.log('Not good. No userInfo was received');
                    this.setState({ errorMessage: 'No user information was found.'});
                }
            })
            .catch(err => {
                console.log('Problem on Maia login', err);
                const specificError = err.statusText ? err.statusText : 'No specific err?';

                this.setState({
                    errorMessage:
                    `Problem authenticating this user with Winward Academy [${specificError}]`
                });

            });
    }

}

export default Maia;
