// A simple utility to create a date-based filename.
export function createFileName(prefix: string, extension="txt") {
  const fill = (num, filler="0") => num < 10 ? filler + num: "" + num
  let now = new Date()
  let year = now.getFullYear()
  let month = now.getMonth() + 1
  let day = now.getDate()
  let hour = now.getHours()
  let min = now.getMinutes()
  let sec = now.getSeconds()
  let monthStr = fill(month)
  let dayStr = fill(day)
  let hourStr = fill(hour)
  let minStr = fill(min)
  let secStr = fill(sec)
  let fileName
  if(extension == 'pdf') {
    fileName = `${prefix}-${year}-${monthStr}-${dayStr}.${extension}`
  } else {
    fileName = `${prefix}-${year}${monthStr}${dayStr}-${hourStr}${minStr}${secStr}.${extension}`

  }

  return fileName
}