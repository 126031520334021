import './IASummaryCard.css';
import '../../../../../node_modules/react-dropdown/style.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { getOverallIAScore, getScores } from '../../../../services/practice-test-scoring/practice-test-service';

import Dropdown from 'react-dropdown';
import PracticeTestScoreRow from '../../../Practice/components/PracticeTestCard/PracticeTestScoreRow/PracticeTestScoreRow';
import RadialProgress from '../../../../components/RadialProgress/RadialProgress';
import RewatchLessonVideoContainer from '../../../RewatchLessonVideo/RewatchLessonVideoContainer';

interface Props {
  testType: string;
}

interface State {
    testData: any;
    allTests: any;
    testNames: any;
    displayNames: any;
}


export default class IASummaryCard extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            testData: {},
            allTests: [],
            testNames: [],
            displayNames: [],
        };
    }

    componentDidMount() {
      const self = this;
      let testType = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
      if (this.props.testType === 'practice-tests') {
          if(testType.includes('sat')) {
            getScores('sat').then(populateCard);
          } else {
            getScores().then(populateCard);
          }
        
      } else {
        getOverallIAScore('').then(populateCard);
      }

      function populateCard(res: any) {
        self.setState({
          allTests: res,
          testData: res[0],
          testNames: res.map((a) => a.testName),
          displayNames: res.map((b) => b.displayName)
      });
      }
    }

    render() {
        let totalScore = 36;
        const { testNames } = this.state;
        const { displayNames } = this.state;
        if (!Object.keys(this.state.testData).length) {
            return null;
        }
        let testType = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
        if(!testType.includes('sat')) {
            return (
                <div className="statistics-container ia-summary-card graph-container">
                    <div>
                        <h3>Test Summary</h3>
                    </div>
                    <div className="header-content">
                        <h3 className="section-title">
                            <Dropdown options={displayNames} onChange={this.updateSummary} value={this.state.testData.displayName} />
                        </h3>
                    </div>
                    <div style={{width: '100%', margin: '0 auto'}}>
                        <RadialProgress
                            size={200}
                            radius={64}
                            startColor="#6A5CC7"
                            endColor="#50c3f1"
                            pegOffset={35}
                            progress={this.state.testData.average / 36 * 100}
                            text={this.state.testData.average + ''}
                            textStyle={{fontSize: '50px', color: '#2B2D2D', fontWeight: 500}}
                        />
                    </div>

                    <div className={'practice-test-scores-container'}>
                        {/* English Score Row */}
                        <PracticeTestScoreRow
                            subject="English"
                            score={this.state.testData.english}
                            totalScore={totalScore}
                            barColor="purple"
                            showIcon={true}
                        />

                        {/* Math Score Row */}
                        <PracticeTestScoreRow
                            subject="Math"
                            score={this.state.testData.math}
                            totalScore={totalScore}
                            barColor="blue"
                            showIcon={true}
                        />

                        {/* Reading Score Row */}
                        <PracticeTestScoreRow
                            subject="Reading"
                            score={this.state.testData.reading}
                            totalScore={totalScore}
                            barColor="orange"
                            showIcon={true}
                        />

                        {/* Science Score Row */}
                        <PracticeTestScoreRow
                            subject="Science"
                            score={this.state.testData.science}
                            totalScore={totalScore}
                            barColor="green"
                            showIcon={true}
                        />
                    </div>
                </div>
            );
        } else {
            totalScore = 1600;
            return (
                <div className="statistics-container ia-summary-card">
                    <div className="header-content">
                        <h3 className="section-title">
                            <Dropdown options={displayNames} onChange={this.updateSummary} value={this.state.testData.displayName} />
                        </h3>
                    </div>
                    <div style={{width: '100%', margin: '0 auto'}}>
                        <RadialProgress
                            size={200}
                            radius={64}
                            startColor="#05b4b9"
                            endColor="#05b4b9"
                            pegOffset={35}
                            progress={this.state.testData.average / 1600 * 100}
                            text={this.state.testData.average + ''}
                            textStyle={{fontSize: '50px', color: 'black', fontWeight: 200}}
                            progressLineWidth={15}
                            backgroundLineWidth={15}
                        />
                    </div>

                    <div className={'practice-test-scores-container'}>
                        <PracticeTestScoreRow
                            subject="R&W"
                            score={this.state.testData.evidenceBasedRW}
                            totalScore={totalScore}
                            barColor="purple"
                            showIcon={true}
                        />

                        {/* Math Score Row */}
                        <PracticeTestScoreRow
                            subject="Math"
                            score={this.state.testData.math}
                            totalScore={totalScore}
                            barColor="blue"
                            showIcon={true}
                        />
                    </div>
                </div>
            );          
        }
    }

    @boundMethod
    private updateSummary(newVal: any) {
        const allTests = this.state.allTests;

        for (let i = 0; i < allTests.length; i++) {
            if (allTests[i].displayName === newVal.value) {
                this.setState({
                    testData: allTests[i]
                });
            }
        }
    }
}
