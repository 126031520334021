import {
    BaselineQuestions,
    Lesson,
    MCQuestions
} from './lesson-types';
import {
    LessonProgress,
    MistakeBankLessonsOverview,
    Question
} from './lesson-types';
import { createGenericAuthRequest, createGenericAuthPostRequest, ignoreJsonError } from '../request/request-utils';
import { stringify } from 'query-string';

import { TopicQuestions } from './lesson-types';
import { hasSchoolSafety } from '../account/account-utils';
import { ReportKind } from '../../scenes/TeacherPortal/TestOverview';

const request = createGenericAuthRequest(process.env.REACT_APP_ELEARN_URL || '/');
const requestPost = createGenericAuthPostRequest(process.env.REACT_APP_ELEARN_URL || '/');

/**
 * Get a lesson's data by its id
 */
export function getLesson(lessonId: string, userInfo: any): Promise<Lesson> {
    if (hasSchoolSafety(userInfo)) {
        return request<Lesson>(`/sss/lessons/${lessonId}`)
        .catch(e => {
            console.error(`Could not get lesson info for lesson id: ${lessonId}`);
            return Promise.reject(null);
        });
    } else {
        return request<Lesson>(`/lessons/${lessonId}`)
        .catch(e => {
            console.error(`Could not get lesson info for lesson id: ${lessonId}`);
            return Promise.reject(null);
        });
    }
}

export function getHints() {
    return request<any>('/reports/hints');
}

export function getBaselineQuestions(lessonId: string, userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        return request<BaselineQuestions>(`/sss/lessons/${lessonId}/questions/baseline`)
        .then(response => response.baselineQuestionsData);
    } else {
        return request<BaselineQuestions>(`/usermaterial/lessons/${lessonId}/questions/baseline`)
        .then(response => response.baselineQuestionsData);
    }
}

export function getMCQuestions(lessonId: string, userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        return request<MCQuestions>(`/sss/lessons/${lessonId}/questions/postlesson`)
        .then(response => response.postLessonQuestionsData);
    } else {
        return request<MCQuestions>(`/usermaterial/lessons/${lessonId}/questions/postlesson`)
        .then(response => response.postLessonQuestionsData);
    }

}

const lessonsQuestionsMap = {}
interface IQuestionsResult {
    questions: Question[],
    ok: boolean
    status: string
}
/**
 * Get all Multiple Choice (MC) questions for a lesson.
 * It will cache the questions for another call.
 * @param lessonId 
 * @returns 
 */
// export async function getMCQuestionsForRegularLessons(lessonId: string): Promise<QuestionsResult> {
//     let mcQuestions = [] as Question[] 
//     let ok = true
//     try {
//         let questions = lessonsQuestionsMap[lessonId]
        
//         if (questions) return questions

//         let res = await request<MCQuestions>(`/usermaterial/lessons/${lessonId}/questions/postlesson`).then(response => response.postLessonQuestionsData)
//         let questionGroups = res.questionGroups || []

//         questions = questionGroups.reduce((acc, cur) => acc.concat(cur.questions), [] as Question[])
//         lessonsQuestionsMap[lessonId] = questions

//         return questions as Question[]      
//     }
//     catch (ex) {
//         console.error("Exception:", ex)        
//     }

//     return {
//         questions: mcQuestions,
//         ok:false,
//         status: "OK"
//     }
// }

export async function getMCQuestionsForRegularLessons(lessonId: string): Promise<IQuestionsResult> {
    let questions = lessonsQuestionsMap[lessonId] 
    let ok = true
    let status = "ok"

    try {
        if (!questions) {
            let res = await request<MCQuestions>(`/usermaterial/lessons/${lessonId}/questions/postlesson`).then(response => response.postLessonQuestionsData)
            let questionGroups = res.questionGroups || []

            questions = questionGroups.reduce((acc, cur) => acc.concat(cur.questions), [] as Question[])
            lessonsQuestionsMap[lessonId] = questions
        }
    }
    catch (ex) {
        let msg = `Exception: ${ex}`
        console.error(msg)
        questions = []
        ok = false
        status = msg      
    }

    return {questions, ok, status}
}

export function getMistakeBankOverviewNoSS(userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        return request<MistakeBankLessonsOverview>(`/sss/mistakebank/overview`)
        .then(response => response.topicMistakeBankOverviews);
    } else {
        return request<MistakeBankLessonsOverview>(`/mistakebank/overview`)
        .then(response => response.topicMistakeBankOverviews);
    }
}

export function getMistakeBankOverview() {
    return request<MistakeBankLessonsOverview>(`/mistakebank/overview?includeSS=true`)
        .then(response => response.topicMistakeBankOverviews);
}

export function addQuestionToMistakeBank(lesson: LessonProgress, question: Question,
                                         hint: string, userInfo: any, displayPopup?: boolean) {
    let args;

    args = {
                categoryName: lesson.category.categoryName,
                lessonName: lesson.lessonName,
                topicName: question.topicName || '',
                hint: hint,
                shouldPromptForHints: displayPopup
            };
    if(hasSchoolSafety(userInfo)) {
        return request(
            `/sss/mistakebank/lessons/${lesson.lessonId}/topics/${question.topicId}/questions/missed/${question.questionId}`,
             args, { method: 'PUT' })
            .catch(ignoreJsonError);
    } else {
        return request(
            `/mistakebank/lessons/${lesson.lessonId}/topics/${question.topicId}/questions/missed/${question.questionId}`,
             args, { method: 'PUT' })
            .catch(ignoreJsonError);
    }
}

export function getTopicQuestions(lessonId: string, topicId: string, userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        return request<TopicQuestions>(`/sss/mistakebank/lessons/${lessonId}/topics/${topicId}/questions/missed?includeSS=true`)
        .then(response => response);
    } else {
        return request<TopicQuestions>(`/mistakebank/lessons/${lessonId}/topics/${topicId}/questions/missed?includeSS=true`)
        .then(response => response);
    }
}

export function setTopicReviewStatus(lessonId: string, topicId: string, status: string) {
    const args = {
        topicReviewStatus: status
    };
    return request(
        `/mistakebank/lessons/${lessonId}/topics/${topicId}/reviewstatus`,
         args, { method: 'PUT' })
        .catch(ignoreJsonError);
}

export function setTopicQuestionAsReviewed(lessonId: string, topicId: string, questionId: string, answerLetter: string) {
    return request(
        `/mistakebank/lessons/${lessonId}/topics/${topicId}/questions/reviewed/${questionId}/${answerLetter}`, {}, { method: 'PUT' })
        .catch(ignoreJsonError);
}

export function setTopicQuestionAsReviewedSS(lessonId: string, topicId: string, questionId: string) {
    return request(
        // tslint:disable-next-line:max-line-length
        `/mistakebank/lessons/${lessonId}/topics/${topicId}/questions/reviewed/${questionId}?includeSS=true`, {}, { method: 'PUT' })
        .catch(ignoreJsonError);
}

export function setTopicCurrentQuestion(lessonId: string, topicId: string, questionId: string) {
    return request(
        `/mistakebank/lessons/${lessonId}/topics/${topicId}/questions/current/${questionId}`, {}, { method: 'PUT' })
        .catch(ignoreJsonError);
}

/**
 * Send students' lesson report
 * @returns 
 */
export function sendStudentReport() {
    return request(
        `/reports/teacher`
    ).catch(ignoreJsonError);
}

/**
 * Send students' TonP insights report
 * @returns 
 */
export function sendTonPInsightsReport() {
    return request(
        `/reports/timeonplatform/students-insights`, {}, {method: "POST"}
    ).catch(ignoreJsonError);
}

export function sendStudentEnrichmentReport() {
    return request(
        `/reports/teacher/enrichment`
    ).catch(ignoreJsonError);
}

export function sendStudentCKReport() {
    return request(
        `/reports/teacher/ck`
    ).catch(ignoreJsonError);
}

export function sendStudentStaySharpReport() {
    return request(
        `/reports/teacher/staysharp`
    ).catch(ignoreJsonError);
}

export function sendStudentSummary(reportKind=ReportKind.ALL) {
  let queryParam = "reportKind=";
  switch (reportKind) {
    case ReportKind.ACT:
      queryParam += "ACT";
      break;
    case ReportKind.SAT:
      queryParam += "SAT";
      break;
    default:
      queryParam += "ALL"
  }
  let path = `/reports/tests/email?${queryParam}`;
  return request(path).catch(ignoreJsonError);
}

export function getTestSummaries(
  schoolName?: string,
  graduationYear?: string,
  timeRange?: string
) {
  // console.log(schoolName, graduationYear, timeRange);
  const querifiedFilters = stringify({ schoolName, graduationYear, timeRange });

  if (querifiedFilters) {
    return request(`/lesson-summary/test-summaries?${querifiedFilters}`)
        .catch(ignoreJsonError);
  } else {
    return request(`/lesson-summary/test-summaries`)
        .catch(ignoreJsonError);
  }
}

export function getLessonSummary(
  schoolName?: string,
  graduationYear?: string,
  timeRange?: string
) {
  // console.log(schoolName, graduationYear, timeRange);
  const querifiedFilters = stringify({ schoolName, graduationYear, timeRange });

  if (querifiedFilters) {
    return request(`/lesson-summary?${querifiedFilters}`)
        .catch(ignoreJsonError);
  } else {
    return request(`/lesson-summary`)
        .catch(ignoreJsonError);
  }
}

export function getEnrichmentLessonSummary(
    schoolName?: string,
    graduationYear?: string,
    timeRange?: string
 ) {
    const querifiedFilters = stringify({ schoolName, graduationYear, timeRange });

    if (querifiedFilters) {
      return request(`/enrichment/summary?${querifiedFilters}`)
          .catch(ignoreJsonError);
    } else {
      return request(`/enrichment/summary`)
          .catch(ignoreJsonError);
    }
 }

 export function getCKLessonSummary(
    schoolName?: string,
    graduationYear?: string,
    timeRange?: string
 ) {
    const querifiedFilters = stringify({ schoolName, graduationYear, timeRange });

    if (querifiedFilters) {
      return request(`/ck/summary?${querifiedFilters}`)
          .catch(ignoreJsonError);
    } else {
      return request(`/ck/summary`)
          .catch(ignoreJsonError);
    }
} 

export function getStaySharpSummary(
    schoolName?: string,
    graduationYear?: string,
    timeRange?: string
 ) {
    const querifiedFilters = stringify({ schoolName, graduationYear, timeRange });

    if (querifiedFilters) {
      return request(`/staysharp/summary?${querifiedFilters}`)
          .catch(ignoreJsonError);
    } else {
      return request(`/staysharp/summary`)
          .catch(ignoreJsonError);
    }
} 

export function getAllStudentIndividualData(
    schoolName?: string,
    graduationYear?: string,
    timeRange?: string) {
    const querifiedFilters = stringify({ schoolName, graduationYear, timeRange });
    const path = "/teacher/students"

    if (querifiedFilters) {
      return request(`${path}?${querifiedFilters}`)
          .catch(ignoreJsonError);
    } else {
      return request(path)
          .catch(ignoreJsonError);
    }
}

export function getStudentsLessonInfos(emails: string[]) {
    const path = "/teacher/studentslessoninfos"
    const queryParams = stringify({emails: emails.join(",")})
    const pathWithQueryParams = `${path}?${queryParams}`
    return request(pathWithQueryParams).catch(ignoreJsonError)
}

export function getStudentLessonInfo(email: string) {
    const path = "/teacher/studentlessoninfo"
    const queryParams = stringify({email})
    const pathWithQueryParams = `${path}?${queryParams}`
    // console.log("pathWithQueryParams", pathWithQueryParams)
    return request(pathWithQueryParams).catch(ignoreJsonError)
}

export function getSchoolsByTeacher() {
  return request('/lesson-summary/schools')
    .catch(ignoreJsonError);
}

export function getGraduationYearsByTeacher() {
  return request('/lesson-summary/graduation-years')
    .catch(ignoreJsonError);
}

// Gets all the lessons for the student
export function getAllLessons() {
    return request("/reports/student/lessons-info")
        .catch(ignoreJsonError)
}

