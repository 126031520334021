import jp from "jsonpath"
import { convertToACTandSAT } from "../../../services/utils/act-sat-util"
import { collegeReadyLookup } from "../../../services/utils/college-ready-util"
import { getHumanTime, HumanTime, isWithinDateRange, isBeforeTonPEpochDate } from "../../../services/utils/date-util"
import { avg, sum } from "../../../services/utils/math-util"

export interface LessonData {
  section: string
  lessonId: string
  startedDate: string
  completedDate: string
  lastUpdatedTimestamp: number
  lessonName: string
  status: string
  preLessonAccuracy: number
  postLessonAccuracy: number
  lessonGrowth: number
  hintsOnIncorrect: number
  completedQuestionAccuracy: number
  questionCompletionProgress: number
  totalQuestionAccuracy: number
  totalQuestions: number
  totalCorrectAnswers: number
  totalCompletedBaselineQuestions: number
  totalCorrectBaselineAnswers: number
  infoPreLessonAccuracy: string
  infoCompletedQuestionAccuracy: string
  infoLessonGrowth: string
  infoHintsOnIncorrect: string
  infoTotalQuestionAccuracy: string

  // Later calculated
  totalTimeOnAnswered?: number
  totalTimeOnEnded?: number
  avgTimePerQuestion?: number
  preLessonConversionScoreACT?: string
  preLessonConversionScoreSAT?: string
  postLessonConversionScoreACT?: string
  postLessonConversionScoreSAT?: string
}

export interface LessonSummary {
  userId: string
  emailAddress: string
  lessons: LessonData[]
}

export interface TimeOnPlafromProgress {
  answered?: number
  ended?: number
  timedout?: boolean
  answeredAtFirst?: boolean
  attempts?: number
}

export interface TimeOnPlatformLessonStats {
  nofBaselinesAnswered?: number
  nofVideosWatched?: number
  nofQuestionsAnswered?: number
  timeStudied?: number
  timeVideosWatched?: number
}

export interface ITimeOnPlatformLessonItem {
  lessonId: string
  category: string
  stats?: {
    nofBaselinesAnswered?: number
    nofVideosWatched?: number
    nofQuestionsAnswered?: number
    timeStudied?: number
    timeVideosWatched?: number
    startedAt?: string
    updatedAt?: string
    completedAt?: string
  }
  baselines?: {
    items?: {
      [name: string]: TimeOnPlafromProgress
    }
  }
  videos?: {
    items?: {
      [name: string]: TimeOnPlafromProgress
    }
  }
  questions?: {
    items?: {
      [name: string]: TimeOnPlafromProgress
    }
  }
}

export interface ITimeOnPlatformLessons {
  stats?: {
    English?: TimeOnPlatformLessonStats
    Math?:    TimeOnPlatformLessonStats
    Reading?: TimeOnPlatformLessonStats
    Science?: TimeOnPlatformLessonStats
  }
  updatedLessons?: {
    English?: string[]
    Math?:    string[]
    Reading?: string[]
    Science?: string[]
  } 
  completedLessons?: {
    English?: string[]
    Math?:    string[]
    Reading?: string[]
    Science?: string[]
  } 
  items?: {
    [name: string]: ITimeOnPlatformLessonItem
  }
}

export interface ITimeOnPlatform {
  date: string
  email: string
  data: {
    lessons: ITimeOnPlatformLessons
    tests: any      // TODO: ITimeOnPlatformTests
    staysharps: any // TODO: ITimeOnPlatformStaySharps
    // OT: Later to be implemented
    // mistakes?   // For mistake bank
    // staysharps? // For stay sharp, etc.
  }
}

export interface ClassSummary {
  nofLessonsStarted: number
  nofLessonsCompleted: number
  nofLessonsContinued: number
  totalTimeOnAnswered: number
  totalTimeOnQuestions: number // i.e. total time on ended
  avgTimePerQuestions: number,
  nofQuestionsAnswered: number,
  nofPreLessonQuestionsAnswered: number,
  nofPostLessonQuestionsAnswered: number
  preLessonAccuracy: number,
  postLessonAccuracy: number,
  preLessonConversionScoreACT: string,
  preLessonConversionScoreSAT: string
  postLessonConversionScoreACT: string,
  postLessonConversionScoreSAT: string
}

export interface LessonSummaryByCategory {
  lessonId: string
  lessonName: string
  nofStudentsStarted : number
  nofStudentsCompleted: number
  nofStudentsContinued: number
  nofStudentsCollegeReadyACT: number
  nofStudentsCollegeReadySAT: number
  accuracyPreLesson: number,
  accuracyPostLesson: number,
  lessonGrowth: number,
  hintsOnIncorrect: number,
  avgTimePerQuestion: number,
  totalTimeOnAnswered: number,
  totalTimeOnEnded: number,
  preLessonConversionScoreACT: string,
  preLessonConversionScoreSAT: string
  postLessonConversionScoreACT: string,
  postLessonConversionScoreSAT: string
  keepOnTopDuringSort?: boolean
}

export interface LessonSummaryByCategoryMap {
  [lessonId: string]: LessonSummaryByCategory
}

export interface TotalClassProgress {
  nofLessonsStarted: number
  nofLessonsCompleted: number
  nofLessonsContinued: number
  totalQuestionsAnswered: number
  totalStudyTime: HumanTime
  dailyAnsweredQuestions: DailyAnsweredQuestion[]
}

export interface DailyAnsweredQuestion {
  date: string
  n: number
}


export const filterStudentLessonData = (studentLessonData: LessonSummary[], dateStart: Date|string, dateEnd: Date|string) => {
  let filteredData = jp.query(studentLessonData, `$..lessons.*`)
                        .filter((lesson: LessonData) => isWithinDateRange(lesson.lastUpdatedTimestamp, dateStart, dateEnd))

  return filteredData                                      
}

export class TimeOnPlatformLessonProcessor {
  private lessonSummaryData: LessonSummary[] = []
  private timeOnPlatformData: ITimeOnPlatform[] = []

  constructor() { }

  load(lessonSummaryData: LessonSummary[], timeOnPlatformData: ITimeOnPlatform[]) {
    this.lessonSummaryData = lessonSummaryData
    this.timeOnPlatformData = timeOnPlatformData
  }

  getLessonsByStudents(dateStart: Date|string, dateEnd: Date|string) {
    let {lessonSummaryData} = this
    let studentEmails = jp.query(lessonSummaryData, `$..emailAddress`).sort()
    let studentDataMap: any = {}

    studentEmails.forEach(email => {
      studentDataMap[email] = this.getLessonsDetailsByStudent(dateStart, dateEnd, email)
    })
    return studentDataMap
  }

  getLessonsDetailsByStudent(dateStart: Date|string, dateEnd: Date|string, studentEmail: string) {
    let {lessonSummaryData, timeOnPlatformData} = this
    let filteredStudentLessonDataByEmail = jp.query(lessonSummaryData, `$..[?(@.emailAddress=="${studentEmail}")]`)
    let filteredStudentLessonData = filterStudentLessonData(filteredStudentLessonDataByEmail, dateStart, dateEnd)
    let filteredTimeOnPlatformData = jp.query(timeOnPlatformData, `$..[?(@.email=="${studentEmail}")]`)
                                              .filter(lesson => isWithinDateRange(lesson.date, dateStart, dateEnd))
    let lessonIds = jp.query(filteredStudentLessonData, `$..lessonId`).sort()
    let lessonMap = {} as any
    let categoryMap = {} as any

    lessonIds =  Array.from(new Set(lessonIds)) 
    lessonIds.forEach(lessonId => {
      let lessonData = jp.query(filteredStudentLessonData, `$..[?(@.lessonId=="${lessonId}")]`)[0]
      let top4LessonData = jp.query(filteredTimeOnPlatformData, `$..[?(@.lessonId=="${lessonId}")]`)
      let questionsAnswered = jp.query(top4LessonData, `$..items..[?(@.timedout!=true)]..answered`)
      let questionsEnded = jp.query(top4LessonData, `$..items..[?(@.timedout!=true)]..ended`)
      let totalTimeOnEnded = questionsEnded.reduce((acc, cur) => acc += cur, 0)
      let totalTimeOnAnswered = questionsAnswered.reduce((acc, cur) => acc += cur, 0)
      let avgTimePerQuestion = Math.round(questionsAnswered.length > 0 ? totalTimeOnAnswered/questionsAnswered.length: -1) // -1 -> N/A
      let category = lessonData.section
      let preLessonAccuracy = lessonData.preLessonAccuracy
      let postLessonAccuracy = lessonData.postLessonAccuracy
      let scores = convertToACTandSAT(category, preLessonAccuracy, postLessonAccuracy)
      let preLessonConversionScoreACT = scores && scores.preLessonConversionScoreACT
      let preLessonConversionScoreSAT = scores && scores.preLessonConversionScoreSAT
      let postLessonConversionScoreACT = scores && scores.postLessonConversionScoreACT
      let postLessonConversionScoreSAT = scores && scores.postLessonConversionScoreSAT
  
      lessonMap[lessonId] = {
        ...lessonData, 
        totalTimeOnAnswered, 
        totalTimeOnEnded, 
        avgTimePerQuestion, 
        preLessonConversionScoreACT, 
        preLessonConversionScoreSAT,
        postLessonConversionScoreACT,
        postLessonConversionScoreSAT
      }
    })
  
    let lessons = Object.keys(lessonMap).map(k => lessonMap[k])
    lessons.forEach(lesson => {
      let category = lesson.section
      let list = categoryMap[category]
      if (!list) {
        list = []
        categoryMap[category] = list
      }
      list.push(lesson)
    })
  
    return categoryMap
  }

  getTotalClassProgress(dateStart: Date | string, dateEnd: Date | string): TotalClassProgress {
    let {lessonSummaryData, timeOnPlatformData} = this
    let filteredStudentLessonData = filterStudentLessonData(lessonSummaryData, dateStart, dateEnd)
    let filteredData = timeOnPlatformData.filter(lesson => isWithinDateRange(lesson.date, dateStart, dateEnd))
    let lessons = jp.query(filteredData, `$..data.lessons.items`)
    let started = jp.query(filteredStudentLessonData, `$..startedDate`).filter(date => isWithinDateRange(date, dateStart, dateEnd))
    let completed = jp.query(filteredStudentLessonData, `$..[?(@.completedDate != "No completed date")].completedDate`)
    let nofLessonsStarted= started.length
    let nofLessonsCompleted = completed.length
    let nofLessonsContinued = filteredStudentLessonData.length
  
    // Total time working
    let timeForBaselinesEnded = jp.query(lessons, `$..baselines.items[?(@.timedout!=true)]..ended`)
    let timeForvideosEnded = jp.query(lessons, `$..videos.items[?(@.timedout!=true)]..ended`)
    let timeForQuestionsEnded = jp.query(lessons, `$..questions.items[?(@.timedout!=true)]..ended`)
    let totalStudyTimeBaselines = timeForBaselinesEnded.reduce((acc, cur) => acc += cur, 0)
    let totalStudyTimeVideos = timeForvideosEnded.reduce((acc, cur) => acc += cur, 0)
    let totalStudyTimeQuestionEndeds = timeForQuestionsEnded.reduce((acc, cur) => acc += cur, 0)
    let totalStudyTime = totalStudyTimeBaselines + totalStudyTimeVideos +  totalStudyTimeQuestionEndeds
    
    // Questions answered
    let nofPostLessonQuestionsAnswered = filteredStudentLessonData.reduce((acc, cur) => acc += cur.totalCompletedQuestions, 0)
    let totalQuestionsAnswered = nofPostLessonQuestionsAnswered
    
    // If possible then use the TonP data!
    // if (!isBeforeTonPEpochDate(dateStart)) {
    //   totalQuestionsAnswered = timeForQuestionsEnded.length > 0 ? timeForQuestionsEnded.length: totalQuestionsAnswered
    // }
    totalQuestionsAnswered = timeForQuestionsEnded.length > 0 ? timeForQuestionsEnded.length: totalQuestionsAnswered


    // All required info for the top part the chart
    let dates = jp.query(filteredData, `$..date`)
    dates = Array.from(new Set(dates))
    dates.sort()
  
    let dailyAnsweredQuestions: DailyAnsweredQuestion[] = []
    dates.forEach(date => {
      let d = jp.query(filteredData, `$..[?(@.date=="${date}")]`)
      let qs = jp.query(d, `$..data.lessons..questions.items[?(@.timedout!=true)]..answered`)
      dailyAnsweredQuestions.push({date, n: qs.length})
    })
    let progress = {
      nofLessonsStarted,
      nofLessonsCompleted,
      nofLessonsContinued,
      totalQuestionsAnswered,
      totalStudyTime: getHumanTime(totalStudyTime),
      dailyAnsweredQuestions
    }

    return progress
  }

  getClassSummaryByCategory(dateStart: Date|string, dateEnd: Date|string, category: string): ClassSummary {
    let {lessonSummaryData, timeOnPlatformData} = this
    let filteredStudentLessonDataByDate = filterStudentLessonData(lessonSummaryData, dateStart, dateEnd)
    let filteredStudentLessonData = jp.query(filteredStudentLessonDataByDate, `$..[?(@.section=="${category}")]`)
    let lessonDataStarted = jp.query(filteredStudentLessonData, `$..startedDate`).filter(date => isWithinDateRange(date, dateStart, dateEnd))
    let lessonDataCompleted = jp.query(filteredStudentLessonData, `$..[?(@.completedDate != "No completed date")].completedDate`)
    let nofLessonsStarted= lessonDataStarted.length
    let nofLessonsCompleted = lessonDataCompleted.length
    let nofLessonsContinued = filteredStudentLessonData.length
    let filteredTonPData = timeOnPlatformData.filter(lesson => isWithinDateRange(lesson.date, dateStart, dateEnd))
    let lessons = jp.query(filteredTonPData, `$..lessons.items`)
    let categoryLessons = jp.query(lessons, `$..[?(@.category=="${category}")]`)
    let questionsAnswered = jp.query(categoryLessons, `$..items..[?(@.timedout!=true)]..answered`)
    let nofQuestionsAnswered = questionsAnswered.length
    let questionsEnded = jp.query(categoryLessons, `$..items..[?(@.timedout!=true)]..ended`)
    let totalTimeOnAnswered = questionsAnswered.reduce((acc, cur) => acc += cur, 0)
    let totalTimeOnQuestions = questionsEnded.reduce((acc, cur) => acc += cur, 0) // i.e. total time on ended
    let avgTimePerQuestions = Math.round(questionsAnswered.length > 0 ? totalTimeOnAnswered/questionsAnswered.length: -1) // -1 -> N/A
    let preLessonQuestionAccuracies = filteredStudentLessonData
          .filter(lesson => lesson.totalCompletedBaselineQuestions > 0)
          .map(lesson => Math.round(100*lesson.totalCorrectBaselineAnswers/lesson.totalCompletedBaselineQuestions))
    let postLessonQuestionAccuracies = filteredStudentLessonData
          .filter(lesson => lesson.totalCompletedQuestions > 0)
          .map(lesson => Math.round(100*lesson.totalCorrectAnswers/lesson.totalCompletedQuestions))
    let nofPreLessonQuestionsAnswered = filteredStudentLessonData.reduce((acc, cur) => acc += cur.totalCompletedBaselineQuestions, 0)
    let nofPostLessonQuestionsAnswered = filteredStudentLessonData.reduce((acc, cur) => acc += cur.totalCompletedQuestions, 0)
    let preLessonTotal = preLessonQuestionAccuracies.reduce((acc, cur) => acc += cur, 0)
    let postLessonTotal = postLessonQuestionAccuracies.reduce((acc, cur) => acc += cur, 0)
    let preLessonLen = preLessonQuestionAccuracies.length
    let postLessonLen = postLessonQuestionAccuracies.length
    let preLessonAccuracy = preLessonLen > 0 ? Math.round(preLessonTotal/preLessonLen):0
    let postLessonAccuracy = postLessonLen > 0 ? Math.round(postLessonTotal/postLessonLen): 0
    let scores = convertToACTandSAT(category, preLessonAccuracy, postLessonAccuracy)
    let preLessonConversionScoreACT = scores.preLessonConversionScoreACT
    let preLessonConversionScoreSAT = scores.preLessonConversionScoreSAT
    let postLessonConversionScoreACT = scores.postLessonConversionScoreACT
    let postLessonConversionScoreSAT = scores.postLessonConversionScoreSAT
    let hintsOnIncorrectsInfo = jp.query(filteredStudentLessonData, `$..[?(@.totalCompletedQuestions > 0)]..hintsOnIncorrect`)
    let hintsOnIncorrectsSum = hintsOnIncorrectsInfo.reduce((acc, cur) => acc += cur, 0)
    let hintsOnIncorrects = hintsOnIncorrectsInfo.length > 0 ? Math.round(hintsOnIncorrectsSum/hintsOnIncorrectsInfo.length): 0
    let lessonGrowth = postLessonAccuracy - preLessonAccuracy
    let thresholdACT = collegeReadyLookup(category, "ACT")
    let thresholdSAT = collegeReadyLookup(category, "SAT")
    let collegeReadyDataACT = jp.query(filteredStudentLessonData, `$..[?(@.postLessonAccuracy >= ${thresholdACT})]`)
    let collegeReadyDataSAT = jp.query(filteredStudentLessonData, `$..[?(@.postLessonAccuracy >= ${thresholdSAT})]`)
    let nofStudentsCollegeReadyACT = collegeReadyDataACT.length
    let nofStudentsCollegeReadySAT = collegeReadyDataSAT.length
    let nofStudentsContinued = filteredStudentLessonData.length
    let collegeReadyACT = nofStudentsContinued != 0 ? Math.round(100*nofStudentsCollegeReadyACT / nofStudentsContinued): -1
    let collegeReadySAT = nofStudentsContinued != 0 ? Math.round(100*nofStudentsCollegeReadySAT / nofStudentsContinued): -1
    let categoricalData = {
      nofLessonsStarted,
      nofLessonsCompleted,
      nofLessonsContinued,
      totalTimeOnAnswered,
      totalTimeOnQuestions, 
      avgTimePerQuestions,
      nofQuestionsAnswered,           // This is derived fron TonP data
      nofPreLessonQuestionsAnswered,  // Actual values from lessons - pre
      nofPostLessonQuestionsAnswered, // Actual values from lessons - post
      hintsOnIncorrects,
      preLessonAccuracy,
      postLessonAccuracy,
      lessonGrowth,
      preLessonConversionScoreACT,
      preLessonConversionScoreSAT,
      postLessonConversionScoreACT,
      postLessonConversionScoreSAT,
      nofStudentsCollegeReadyACT,
      nofStudentsCollegeReadySAT,
      collegeReadyACT,
      collegeReadySAT
    }

    return categoricalData
  }

  getLessonSummaryByCategory(dateStart: Date|string, dateEnd: Date|string, category: string): LessonSummaryByCategoryMap {
    let {lessonSummaryData, timeOnPlatformData} = this
    let filteredStudentLessonData = jp.query(lessonSummaryData, `$..[?(@.section=="${category}")]`)
                                      .filter(lesson => isWithinDateRange(lesson.lastUpdatedTimestamp, dateStart, dateEnd))
    let filteredTimeOnPlatformData = timeOnPlatformData.filter(lesson => isWithinDateRange(lesson.date, dateStart, dateEnd))
    let lessonIds = jp.query(filteredStudentLessonData, `$..lessonId`).sort()
    let thresholdACT = collegeReadyLookup(category, "ACT")
    let thresholdSAT = collegeReadyLookup(category, "SAT")
    let lessonMap: {[key: string]: LessonSummaryByCategory} = {}
    
    lessonIds = Array.from(new Set(lessonIds))
    lessonIds.forEach(lessonId => {
      let data = jp.query(filteredStudentLessonData, `$..[?(@.lessonId=="${lessonId}")]`)
      let started = jp.query(data, `$..startedDate`).filter(date => isWithinDateRange(date, dateStart, dateEnd))
      let completed = jp.query(data, `$..[?(@.completedDate != "No completed date")].completedDate`)
      let nofStudentsStarted = started.length
      let nofStudentsCompleted = completed.length
      let nofStudentsContinued = data.length
      let collegeReadyDataACT = jp.query(data, `$..[?(@.postLessonAccuracy >= ${thresholdACT})]`)
      let collegeReadyDataSAT = jp.query(data, `$..[?(@.postLessonAccuracy >= ${thresholdSAT})]`)
      let nofStudentsCollegeReadyACT = collegeReadyDataACT.length
      let nofStudentsCollegeReadySAT = collegeReadyDataSAT.length
      let preLessonAccuracies = jp.query(data, `$..preLessonAccuracy`)
      let postLessonAccuracies = jp.query(data, `$..[?(@.totalCompletedQuestions>0)]..postLessonAccuracy`)
      let nofStudentsStartedMCs = postLessonAccuracies.length
      let accuracyPreLesson = preLessonAccuracies.length > 0 ? Math.round(preLessonAccuracies.reduce((acc, cur) => acc += cur, 0)/preLessonAccuracies.length): 0
      let accuracyPostLesson = nofStudentsStartedMCs > 0 ? Math.round(postLessonAccuracies.reduce((acc, cur) => acc += cur, 0)/nofStudentsStartedMCs): -1
      let lessonGrowth = nofStudentsStartedMCs > 0 ? accuracyPostLesson - accuracyPreLesson: -1
      let hintsOnIncorrects = jp.query(data, `$..[?(@.totalCompletedQuestions > 0)]..hintsOnIncorrect`)
      let hintsOnIncorrect = nofStudentsStartedMCs > 0 ? (hintsOnIncorrects.length > 0 ? Math.round(hintsOnIncorrects.reduce((acc, cur) => acc += cur, 0)/hintsOnIncorrects.length): 0): -1
      let top4LessonData = jp.query(filteredTimeOnPlatformData, `$..[?(@.lessonId=="${lessonId}")]`)
      let questionsAnswered = jp.query(top4LessonData, `$..items..[?(@.timedout!=true)]..answered`)
      let questionsEnded = jp.query(top4LessonData, `$..items..[?(@.timedout!=true)]..ended`)
      let totalTimeOnAnswered = questionsAnswered.reduce((acc, cur) => acc += cur, 0)
      let totalTimeOnEnded = questionsEnded.reduce((acc, cur) => acc += cur, 0)
      let avgTimePerQuestion = Math.round(questionsAnswered.length > 0 ? totalTimeOnAnswered/questionsAnswered.length: -1) // -1 -> N/A
      let scores = convertToACTandSAT(category, accuracyPreLesson, accuracyPostLesson)
      let preLessonConversionScoreACT = scores && scores.preLessonConversionScoreACT
      let preLessonConversionScoreSAT = scores && scores.preLessonConversionScoreSAT
      let postLessonConversionScoreACT = scores && scores.postLessonConversionScoreACT
      let postLessonConversionScoreSAT = scores && scores.postLessonConversionScoreSAT
      let lessonName = data && data.length > 0 && data[0].lessonName || "Unknown"

      lessonMap[lessonId] = {
        lessonId,
        lessonName,
        nofStudentsStarted,
        nofStudentsCompleted,
        nofStudentsContinued,
        nofStudentsCollegeReadyACT,
        nofStudentsCollegeReadySAT,
        accuracyPreLesson,
        accuracyPostLesson,
        lessonGrowth,
        hintsOnIncorrect,
        avgTimePerQuestion,
        totalTimeOnAnswered,
        totalTimeOnEnded,
        preLessonConversionScoreACT,
        preLessonConversionScoreSAT,
        postLessonConversionScoreACT,
        postLessonConversionScoreSAT,
      }
    })
  
    return lessonMap
  }  
}

//--- Useful Functions ---

/**
 * Gets the total study time for the lesson from the provided TonP info.
 * 
 * Note: 
 * I have to use the bracket notation in the queryStr in order to handle special chars in 
 * the lessonIds. For example: f(x)&CompositeFunctions, Graphing&SolvingLines, 
 * Triangles:PythagoreanTheorem, etc.
 * For some reason, initial developers used those as lessonIds and sometimes causing issues!
 * 
 * @param tonpInfo 
 * @param lessonId 
 * @returns total study time for lessonId
 */
export function getTotalStudyTimeForLesson(tonpInfo, lessonId: string) {
  try {
    let queryStr = `$..lessons.items["${lessonId}"].stats.timeStudied`
    let resJP = jp.query(tonpInfo, queryStr)
    return sum(resJP)
  }
  catch (ex) {
    console.error("Error in getTotalStudyTimeForLesson()", ex)
    return -1
  }
}

/**
 * Gets the total study time for all lessons from the provided TonP info (avg & total time)
 * 
 * @param tonpInfo 
 * @param dateStart 
 * @param dateEnd 
 * @returns {avg, total} study time for all lessons
 */
export function getTotalStudyTimeForLessons(tonpInfo, dateStart: Date|string, dateEnd: Date|string) {
  try {
    let dS = new Date(dateStart)
    let dE = new Date(dateEnd)
    let startDate = dS.toISOString().substring(0, 10)
    let endDate = dE.toISOString().substring(0, 10)
    let queryForTimeframedTonP = `$..[?(@.date >= "${startDate}" && @.date <= "${endDate}")]`
    let selectedTonPJP = jp.query(tonpInfo, queryForTimeframedTonP)
    let queryStr = `$..data.lessons..ended`
    let resJP = jp.query(selectedTonPJP, queryStr)
    return {
      avg:   avg(resJP, true),
      total: sum(resJP)
    }
  }
  catch (ex) {
    console.error("Error in getTotalStudyTimeForLessons()", ex)
    return {
      avg: -1,
      total: -1
    }
  }
}

/**
 * Gets the total number of questions answered in the lessons using TonP info
 * 
 * @param tonpInfo 
 * @param dateStart 
 * @param dateEnd 
 * @returns 
 */
export function getNofTotalQuestionsAnsweredForLessons(tonpInfo, dateStart: Date|string, dateEnd: Date|string) {
  try {
    let dS = new Date(dateStart)
    let dE = new Date(dateEnd)
    let startDate = dS.toISOString().substring(0, 10)
    let endDate = dE.toISOString().substring(0, 10)
    let queryForTimeframedTonP = `$..[?(@.date >= "${startDate}" && @.date <= "${endDate}")]`
    let selectedTonPJP = jp.query(tonpInfo, queryForTimeframedTonP)
    let queryStr = `$..data.lessons..questions..ended`
    let resJP = jp.query(selectedTonPJP, queryStr)
    return resJP.length
  }
  catch (ex) {
    console.error("Error in getNofTotalQuestionsAnsweredForLessons()", ex)
    return -1
  }
}

/**
 * Gets the time on questions info for the lesson (avg & total time)
 * 
 * @param tonpInfo 
 * @param lessonId 
 * @returns {avg, total} time on question
 */
export function getTimeOnQuestionsForLessonSummary(tonpInfo, lessonId: string) {
  try {
    let queryStr = `$..lessons.items["${lessonId}"].questions..answered`
    let resJP = jp.query(tonpInfo, queryStr)
    return {
      avg:   avg(resJP),
      total: sum(resJP)
    }
  }
  catch (ex) {
    console.error("Error in getTimeOnQuestionsForLessonSummary()", ex)
    return {
      avg: -1,
      total: -1
    }
  }
}

export interface IPracticeTestTimeSummary {
  timeOnReviewTotal: number
  timeOnMistakes: number
  timeOnHints: number
  timeOnAnalysis: number
}

/**
 * Gets the Practice Test time summary for all students
 * 
 * @param tonpInfo 
 * @param testName 
 * @returns practice test TonP summary for all students
 */
export function getPracticeTestTimeSummaryForAllStudents(tonpInfo:ITimeOnPlatform[], testName: string): IPracticeTestTimeSummary {
  let allTestItemsJP = jp.query(tonpInfo, "$..tests.items")
  let testStatsJP = jp.query(allTestItemsJP, `$..[?(@.testName=="${testName}")]`)
  let mistakesVideosJP = jp.query(testStatsJP, "$..timeVideosWatched")
  let mistakesExplanationsJP = jp.query(testStatsJP, "$..timeExplanationsRead")
  let hintsJP = jp.query(testStatsJP, "$..timeHintsWritten")
  let analysisJP = jp.query(testStatsJP, "$..timeAnalysisViewed")
  let timeOnMistakes = sum(mistakesVideosJP) + sum(mistakesExplanationsJP)
  let timeOnHints = sum(hintsJP)
  let timeOnAnalysis = sum(analysisJP)
  let timeOnReviewTotal = timeOnMistakes + timeOnHints + timeOnAnalysis
  let practiceTestTimeSummary = {timeOnReviewTotal, timeOnMistakes, timeOnHints, timeOnAnalysis}

  return practiceTestTimeSummary 
}

/**
 * Gets the Practice Test time summary for a students
 * 
 * @param tonpInfo 
 * @param testName 
 * @param studentEmail 
 * @returns practice test TonP summary a student
 */
export function getPracticeTestTimeSummaryByStudent(tonpInfo: ITimeOnPlatform[], testName: string, studentEmail: string): IPracticeTestTimeSummary {
  let allTestsByStudentJP = jp.query(tonpInfo, `$..[?(@.email=="${studentEmail}")]`)
  let allTestItemsJP = jp.query(allTestsByStudentJP, "$..tests.items")
  let testStatsJP = jp.query(allTestItemsJP, `$..[?(@.testName=="${testName}")]`)
  let mistakesVideosJP = jp.query(testStatsJP, "$..timeVideosWatched")
  let mistakesExplanationsJP = jp.query(testStatsJP, "$..timeExplanationsRead")
  let hintsJP = jp.query(testStatsJP, "$..timeHintsWritten")
  let analysisJP = jp.query(testStatsJP, "$..timeAnalysisViewed")
  let timeOnMistakes = sum(mistakesVideosJP) + sum(mistakesExplanationsJP)
  let timeOnHints = sum(hintsJP)
  let timeOnAnalysis = sum(analysisJP)
  let timeOnReviewTotal = timeOnMistakes + timeOnHints + timeOnAnalysis
  let practiceTestTimeSummary = {timeOnReviewTotal, timeOnMistakes, timeOnHints, timeOnAnalysis}
    
  return practiceTestTimeSummary
}


export interface IStaySharpTimeSummary {
  timeOnReviewTotal: number
  timeOnHints: number
  timeOnVideos: number
  timeOnQuestions: number
  averageTimeOnAnsweredQuestions: number
  averageTimeOnEndedQuestions: number
}

export function getStaySharpTimeSummaryForAllStudents(tonpInfo:ITimeOnPlatform[], category: string): IStaySharpTimeSummary {
  let allLessonsJP = jp.query(tonpInfo, "$..staysharps.lessons")
  let allCategoryLessonsJP = jp.query(allLessonsJP, `$..[?(@.category=="${category}")]`)
  let allVideosJP = jp.query(allCategoryLessonsJP, `$..videos..ended`)
  let allHintsJP = jp.query(allCategoryLessonsJP, `$..hints..ended`)
  let allQuestionsJP = jp.query(allCategoryLessonsJP, `$..questions`)
  let allQuestionsAnsweredJP = jp.query(allQuestionsJP, `$..answered`)
  let allQuestionsEndedJP = jp.query(allQuestionsJP, `$..ended`)
  let averageTimeOnAnsweredQuestions = avg(allQuestionsAnsweredJP)
  let averageTimeOnEndedQuestions = avg(allQuestionsEndedJP)
  let timeOnVideos = sum(allVideosJP)
  let timeOnHints = sum(allHintsJP)
  let timeOnQuestions = sum(allQuestionsEndedJP)
  let timeOnReviewTotal = timeOnQuestions // This corresponds to the total time on questions for Stay Sharp
  let timeSummary = {timeOnReviewTotal, timeOnQuestions, timeOnVideos, timeOnHints, averageTimeOnAnsweredQuestions, averageTimeOnEndedQuestions}

  return timeSummary 
}

export interface IMistakeBankTimeSummary {
  timeOnReviewTotal: number
  timeOnHints: number
  timeOnVideos: number
  timeOnQuestions: number
  averageTimeOnAnsweredQuestions: number
  averageTimeOnEndedQuestions: number
}

/**
 * Get mistakebank time summary for the given student
 * 
 * @param tonpInfo 
 * @param studentEmail 
 * @param dateStart 
 * @param dateEnd 
 * @returns 
 */
export function getMistakeBankTimeSummaryByStudent(tonpInfo: ITimeOnPlatform[], studentEmail: string, dateStart: Date|string, dateEnd: Date|string): IMistakeBankTimeSummary {
  let dS = new Date(dateStart)
  let dE = new Date(dateEnd)
  let startDate = dS.toISOString().substring(0, 10)
  let endDate = dE.toISOString().substring(0, 10)
  let queryForTimeframedTonP = `$..[?(@.date >= "${startDate}" && @.date <= "${endDate}")]`
  let selectedTonPJP = jp.query(tonpInfo, queryForTimeframedTonP)
  let allInfoByStudentJP = jp.query(selectedTonPJP, `$..[?(@.email=="${studentEmail}")]`)
  let allLessonsJP = jp.query(allInfoByStudentJP, "$..mistakebanks.lessons")
  let allVideosJP = jp.query(allLessonsJP, `$..videos..ended`)
  let allHintsJP = jp.query(allLessonsJP, `$..hints..ended`)
  let allQuestionsJP = jp.query(allLessonsJP, `$..questions`)
  let allQuestionsAnsweredJP = jp.query(allQuestionsJP, `$..answered`)
  let allQuestionsEndedJP = jp.query(allQuestionsJP, `$..ended`)
  let averageTimeOnAnsweredQuestions = avg(allQuestionsAnsweredJP)
  let averageTimeOnEndedQuestions = avg(allQuestionsEndedJP)
  let timeOnVideos = sum(allVideosJP)
  let timeOnHints = sum(allHintsJP)
  let timeOnQuestions = sum(allQuestionsEndedJP)
  let timeOnReviewTotal = timeOnQuestions // This corresponds to the total time on questions
  let timeSummary = {timeOnReviewTotal, timeOnQuestions, timeOnVideos, timeOnHints, averageTimeOnAnsweredQuestions, averageTimeOnEndedQuestions}

  return timeSummary
}

/**
 * Get mistakebank stats for the given student
 * 
 * @param allMBStats
 * @param tonpInfo 
 * @param studentEmail 
 * @param dateStart 
 * @param dateEnd 
 * @returns 
 */
export function getStudentStatsForMistakeBank(allMBStats, tonpInfo: ITimeOnPlatform[], studentEmail: string, dateStart: Date|string, dateEnd: Date|string) {
  let dS = new Date(dateStart)
  let dE = new Date(dateEnd)
  let startDate = dS.toISOString().substring(0, 10)
  let endDate = dE.toISOString().substring(0, 10)
  let queryForTimeframedTonP = `$..[?(@.date >= "${startDate}" && @.date <= "${endDate}")]`
  let selectedTonPJP = jp.query(tonpInfo, queryForTimeframedTonP)
  let allInfoByStudentJP = jp.query(selectedTonPJP, `$..[?(@.email=="${studentEmail}")]`)
  let allMBJP = jp.query(allInfoByStudentJP, "$..mistakebanks.stats")
  let reviewedQuestionsJP = jp.query(allMBJP, `$..nofQuestionsAnswered`)
  let nofReviewedQuestions = sum(reviewedQuestionsJP)
  allMBStats.nofReviewedQuestions = nofReviewedQuestions

  return allMBStats
}
