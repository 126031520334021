import * as React from 'react';

import './index.css';

export interface Props {
    onSwitchComponent: () => void;
    displayName: string;
    active: boolean;
    classes?: string;
}

const ScreenSelector: React.SFC<Props> = (props: Props) => {
    return (
        <button
            // style={{ border: 0 }}
            className={'btn title-button btn-not-bold btn-default white margin-right-10 ' + props.classes +
            (props.active ? ' activeSelection arrow_box' : '')}
            type="button"
            onClick={() => props.onSwitchComponent()}
        >
            {props.displayName}
        </button>
    );
};

export default ScreenSelector;
