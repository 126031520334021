import * as React from 'react';

import NotFound from './NotFound';
import { RouteComponentProps } from 'react-router-dom';

export default (props: RouteComponentProps<any>) => {
    return (
        <NotFound {...props} />
    );
};
