import * as React from 'react';
import Modal from './Modal/Modal';

import './PaymentProcessingModal.css';

const PaymentProcessingModal = ({ showModal }) => {
  return (
      <Modal
          show={showModal}
          onClose={() => console.log('noop on close')}
      >
          <div className="signup-progress-modal">
              <div>
                  <div className="">
                      Thank you!
                  </div>
                  <div className="hidenoanim">
                      <img 
                          alt="Winward Academy Logo"
                          src="/assets/images/winward-logo-animated.gif" 
                      />
                  </div>
                  <div>
                      Please wait while we create your account.
                  </div>
              </div>
          </div>
      </Modal>
  );
};

export default PaymentProcessingModal;
