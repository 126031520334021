import './GeneralConversionModal.css';

import * as React from 'react';
import Modal from '../../../../components/Modal/Modal';
import { ACTSATConversionData } from '../../../../services/diagnostic/ACTSATConversionData';

interface Props {
    onClose: () => void;
    show: boolean;
}

const GeneralConversionModal: React.SFC<Props> = (props) => {
    const data = ACTSATConversionData;
    return (
      <Modal 
        className="conversion-modal modal" 
        show={props.show} 
        onClose={props.onClose} 
        width="900px"
      >
      <div className="row text-center">
          <div className="conversion-modal-header col-lg-12">
            <h2 className="conversion-modal-title mb-0">Score Conversion</h2>
            <h3 className="conversion-modal-subtitle">Please reference the table to better understand how ACT and SAT scores compare.</h3>
            <button type="button" className="conversion-modal-close modal-close" title="Close Modal" aria-label="Close" onClick={props.onClose}>
                <img
                    className="modal-icon"
                    src={`/assets/images/icons/v2/ico-modal-close-white.svg`}
                    width="24"
                    height="24"
                    alt=""
                />
            </button>
          </div>
          <div className="conversion-table-container container-fluid coverBottom-tc">
            <div className="">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-center no-border">Percentile</th>
                            <th className="text-center act-header">ACT Score</th>
                            <th className="text-center sat-header">SAT Score Range</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((obj, i) => {
                            return (
                                <tr key={i}>
                                    <td className="grey">{obj.percentile}</td>
                                    <td>{obj.act}</td>
                                    <td>{obj.sat}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
          </div>
      </div>
      </Modal>
    );
};

GeneralConversionModal.displayName = 'GeneralConversionModal';

export default GeneralConversionModal;
