import * as React from 'react';
import './index.css';
import ScreenSelector from '../../../components/ScreenSelector/'

export interface Props {
  onSelected: (selectedScreen: string) => void
}

type ScreenType = "lessons" | "practicetests" | "tonpdetails"

interface State {
  selectedScreen: ScreenType
}

export default class ViewChangeButtons extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedScreen: "tonpdetails"
    }
  }

  handleScreenChange = (selectedScreen: ScreenType) => {
    let {onSelected} = this.props
    onSelected(selectedScreen)
    this.setState({ selectedScreen })
  }


  public render() {
    const { selectedScreen } = this.state;
    const {onSelected} = this.props

    return (
      <div className="view-change-buttons-container mb-20">
          <div className="row">
            <div className="col-lg-12 lessons-navigation-buttons tab-container__btn-container">
              <div className="subject-button-wrapper do-not-print">
                <div className="screen-selector-row subject-buttons inline-buttons">            
                  <ScreenSelector 
                    active={selectedScreen === "tonpdetails"}
                    classes="btn--nopadding-top"
                    displayName="Time on Platform"
                    onSwitchComponent={() => this.handleScreenChange("tonpdetails")}
                  />
                  <ScreenSelector 
                    active={selectedScreen === "lessons"}
                    classes="btn--nopadding-top"
                    displayName="Lessons"
                    onSwitchComponent={() => this.handleScreenChange("lessons")}
                  />
                  <ScreenSelector 
                    active={selectedScreen === "practicetests"}
                    classes="btn--nopadding-top"
                    displayName="Practice Tests" 
                    onSwitchComponent={() => this.handleScreenChange("practicetests")}
                  />
                </div>
              </div>
              {/* {selectedScreen === 'practice-tests' && <TestOverviewContainer />} */}
            </div>
          </div>
        </div>
    );
  }
}
