import './TableEmptyText.css';

import * as React from 'react';

interface Props {
    text: string;
}

const TableEmptyText: React.SFC<Props> = (props) => {
    return (
        <div className="table-empty-text">
            <p>{props.text}</p>
        </div>
    );
};

TableEmptyText.displayName = 'TableEmptyText';

export default TableEmptyText;
