import * as React from 'react'
import "./index.css"

interface Props {
    icon?: string
    text: string
    color: string
    nofResources?: number
    barColor?: string
}

const ResourceListCardHeader: React.FunctionComponent<Props> = (props: Props) => {

    return (
        <div className="row">
            <div className={`lesson-list-card-header col-12 ${props.text ? props.text.toLowerCase() : ''}`}>
                <span className={`ribbon ${props.text ? props.text.toLowerCase() : ''}`}>
                    <img alt="" src={props.icon} />
                </span>
                <span>{props.text}</span>
            </div>

            <div className="col-6 resource-list-card-header">
                <span>
                    {/* Number of resources: {props.nofResources} */}
                </span>
            </div>
        </div>
    )
}

// ResourceListCardHeader.displayName = "ResourceListCardHeader"

export default ResourceListCardHeader