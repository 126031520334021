import './LessonListTitle.css';
import * as React from 'react';

interface Props {
    icon?: string;
    alt?: string;
    title?: string;
    lessonName: string;
    onIconClick?: () => void;
    enabled: boolean;
}

const LessonListTitle: React.SFC<Props> = (props) => {
    const shouldRenderButton = props.onIconClick && props.enabled;
    return (
        <div className="lesson-list__title">
            {shouldRenderButton ? (
                <button
                    className="btn-simple mr-2"
                    title={props.title ? props.title : ''}
                    onClick={props.onIconClick}
                >
                    <img
                        className="enabled"
                        src={props.icon}
                        alt={props.alt ? props.alt : ''}
                        width="20"
                        height="20"
                    />
                </button>
            ) : (
                <img
                    className="disabled"
                    src={props.icon}
                    alt={props.alt ? props.alt : ''}
                    width="20"
                    height="20"
                />
            )}
            <span>{props.lessonName}</span>
        </div>
    );
};

LessonListTitle.displayName = 'LessonListTitle';

export default LessonListTitle;
