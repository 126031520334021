
export const NULL_GRADUATION_YEAR = '99999';

export interface GraduationYearDescriptor {
    graduationYear: string;
    studentCount: number;
}

export interface StudentData {
  id: string
  studentId: string
  firstName: string
  lastName: string
  emailAddress: string
  profileColor: string
  graduationYear: string
  schoolName: string
  showDetails: boolean
  detailsData: StudentDataDetails[]
  detailsObtainedAt: number
  period?: string
}
  
export interface StudentDataDetails {
  section?: string
  lessonName?: string
  status?: string
  preLessonAccuracy?: string
  postLessonAccuracy?: string
  lessonGrowth?: string
  hintsOnIncorrect?: string
  completedQuestionAccuracy?: string
  totalQuestionAccuracy?: string,
  totalBaselineQuestions: number
  totalQuestions: number,
  infoPreLessonAccuracy: string,
  infoCompletedQuestionAccuracy: string,
  infoLessonGrowth: string,
  infoHintsOnIncorrect: string,
  infoTotalQuestionAccuracy: string
}

export interface IReteachQuestion {
  questionId: string
  selected: boolean
}
  