import './VideoModal.css';

import * as React from 'react';

import BrightcoveVideo from '../BrightcoveVideo/BrightcoveVideo';
import { pauseEvent } from '../../services/event/event-utils';
import Modal from '../Modal/Modal';

interface Props {
    videoId: string;
    onClose: () => void;
    show?: boolean;
}

interface State {

}

export default class VideoModal extends React.PureComponent<Props, State> {
    public render() {
        const { videoId, onClose, show } = this.props;

        return (
            <Modal onClose={onClose} show={show} showXTopRight className="teach-me-video-modal" width="80%">
                <div onClick={pauseEvent}>
                    <BrightcoveVideo id="video-modal-bc" videoId={videoId} />
                </div>                
            </Modal>
        );
    }
}
