import { resources } from "./rr-resources.data"
import { rawGrades } from "./rr-grades.data"
import { UserInfo } from "../../../services/account/account-rest-interface"
import { hasResilienceReminders } from "../../../services/account/account-utils"
import { SpecialWeek, getWeeksSinceStartDate } from "../../../services/utils/date-util"
import { IResilienceReminderGrade } from "./types"

const SPECIAL_WEEKS_TO_SKIP: SpecialWeek[] = ["Thanksgiving", "Christmas", "NewYear"]

interface IResilienceRemindersMetaData {
    grades: string
    dateStart: string
    expiresInDays: number
    dateUpdatedISO: string
}

export interface IRawGrade {
    grade: number
    week: number
    name: string
    category: string
    subCategory: string
    image: string
    summary: string
    readAloud: string
    questions: string
    linkToResources: string
    copyright: string
}

function flatToHierarchical(rawGrades: IRawGrade[]): IResilienceReminderGrade[] {
    let gradesMap = {}
    rawGrades.forEach((gradeInfo) => {
        let gradeKey = gradeInfo.grade
        let gradeMap = gradesMap[gradeKey] || {}
        gradeMap.weeks = gradeMap.weeks || []
        gradeMap.weeks.push({
            ...gradeInfo
        })
        gradesMap[gradeKey] = gradeMap
    })
    let hierarchicalGrades = Object.keys(gradesMap).map((gradeKey) => {
        return {
            grade: Number(gradeKey),
            weeks: gradesMap[gradeKey].weeks
        }
    })

    return hierarchicalGrades
}


function getAssignedGrades(rawGrades: any[], rrMetaData: IResilienceRemindersMetaData): IRawGrade[] {
    let today = new Date()
    let dateStart = new Date(rrMetaData.dateStart)
    let weeks = getWeeksSinceStartDate(dateStart, SPECIAL_WEEKS_TO_SKIP)

    if (isNaN(dateStart.getTime())) {
        return []
    }
    
    if (today < dateStart) {
        console.error("Mindset Moments (Resilience Reminders) not started yet!")
        return []
    }

    let dateEnd = new Date(dateStart)
    
    dateEnd.setDate(dateEnd.getDate() + rrMetaData.expiresInDays)

    if (today > dateEnd) {
        console.error("Mindset Moments (Resilience Reminders) Expired!")
        return []
    }

    let teacherGrades = rrMetaData.grades.split(",").map((grade) => Number(grade))
    let selectedGrades = rawGrades.filter((grade) => teacherGrades.includes(grade.grade))
    let selectedGradesWithWeeks = selectedGrades
        .map(grade => {
            let foundWeek = weeks.find(w => w.weekNo === grade.week)
            return {
                foundWeek,
                ...grade,
            }
        })
        .filter(g => g.foundWeek)

    return selectedGradesWithWeeks
}


/**
 * Gets the Mindset Moments (Resilience Reminders) information for a user (teacher)
 * @param userInfo 
 * @returns 
 */
export function getResilienceRemindersInfo(userInfo: UserInfo) {
    let defautlResilienceRemindersInfo = {hasResilienceReminders: false, resources: [], grades: []}
    let hasRR = hasResilienceReminders(userInfo)     

    if (hasRR) {
        try {
            let rrMetaJson = userInfo.resilienceReminders || ""
            let rrMetaData = JSON.parse(rrMetaJson)
            let assignedGrades = getAssignedGrades(rawGrades, rrMetaData)
            let grades = flatToHierarchical(assignedGrades)
            let resilienceRemindersInfo = { hasResilienceReminders: hasRR, resources, grades }
            return resilienceRemindersInfo
        }
        catch (ex) {
            console.error("Error in getResilienceRemindersInfo(): ", ex)
        }
    }

    return defautlResilienceRemindersInfo
}
