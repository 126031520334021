import './MathJaxText.css';
import * as React from 'react';
import * as _ from 'lodash';
import katex from 'katex';

import { sanitizeHtml } from '../../services/question/question-utils';

interface Props {
    id: string;
    text: string;
    useMathFont?: boolean;
    className?: string;
    style?: React.CSSProperties;
    visible: boolean;
}

interface State {
    visible: boolean;
}

const MathJax = (window as any).MathJax;

export default class MathJaxText extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
          visible: props.visible 
        };
    }

    public shouldComponentUpdate(nextProps: Props) {
        if (nextProps.id === 'question-text' && !nextProps.useMathFont) {
            return true;
        } else {
          return !(nextProps.visible === this.props.visible &&
              nextProps.id === this.props.id);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: any) {
        const { text, id, useMathFont, visible } = nextProps;
        this.setState({ visible: nextProps.visible });
    }

    public render() {
        const ua = navigator.userAgent;
        const isSafari = ua.includes('Safari'); 

        const { text, id, visible, className = '', style, useMathFont } = this.props;
        const sanitizedHtml = sanitizeHtml(text);
        const newJsx = this.htmlToJsx(sanitizedHtml);
        const hiddenClass = this.state.visible ? '' : 'mathjax-text--hidden';
        const mathFontClass = useMathFont ? 'mathjax-text--math ' : '';

        // NOTE: Unfortunately, spans are getting nested, and Safari don't play dat.
        return (
            <span
                id={`${id}-math-jax`}
                className={`${mathFontClass} ${className} ${hiddenClass}` +
                  ' mathjax-element'}
                style={style}
            >
              {isSafari ?
                ( <div> {newJsx} </div>) : 
                newJsx
              }
            </span>
        );
    }

    private htmlToJsx(htmlString: string) {
        //  For testing the double (math and HTML) formatting issues
        // .replace('</span>', '<em>what</em> other stuff <b>BOLD ME</b> `x^y`')
        const temporaryDom = new DOMParser()
            .parseFromString(htmlString, 'text/html')
            .getElementsByTagName('body');
        // NOTE: This logic handles for <em>'s, <b>'s, <span class={underline|blank}>'s
        // The first outer span is the wrapper, and all others 
        // are are custom child elements
        const relativeChildNodes = temporaryDom[0].childNodes[0].childNodes;
        const newJsx = _.map(relativeChildNodes, (el: any) => {
            // TODO: Make sure this didn't break stuff? Turns out data is not a property of Node, so this check should be useless

            // TODO: Replace this with KaTeX implementation!!!

            if (el.data) {
                return (<span key={el.data}>{el.data}</span>);
            } else {
                if (el.localName === 'em') {
                    return (<em key={el.innerHTML}>{el.innerHTML}</em>);
                } else if (el.localName === 'b') {
                    return (<b key={el.innerHTML}>{el.innerHTML}</ b>);
                } else if (el.localName === 'span') {
                    if (el.className === 'blank') {
                        return (
                          <span key={el.innerHTML}>
                            <span className="blank"></span>
                            {el.innerHTML}
                          </span>
                        );
                    } else if (el.className === 'underline') {
                        return (
                          <span 
                              key={el.innerHTML} 
                              className="underline"
                          >
                            {el.innerHTML}
                          </span>
                        );
                    }
                } else {
                    return el;
                }
            }
            return el;
        });
        return newJsx;
    }

}
