import './BaselineCompleteModal.css';

import * as React from 'react';

import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';

interface Props {
    show: boolean;
    onClose: () => void;
    onSkip: () => void;
    onWatch: () => void;
}

const BaselineCompleteModal: React.SFC<Props> = (props) => {
    return (
        <Modal className="navbar-link__modal baseline-complete-modal" show={props.show} onClose={props.onClose}>

            <div className="baseline-complete-modal__text-container">
                <h2>You Rock!</h2>
                <p className="baseline-complete-modal__p1">You handled those baseline questions like a pro.</p>
                <p className="baseline-complete-modal__p2">
                    Since you seem to have a strong grasp of the subject matter, you can skip the video
                    lesson and go straight to the multiple choice questions, or you can choose to still watch
                    the video lesson. Remember you can always rewatch the video lesson from your
                    Mistake Bank if you end up needing to go back to review anything.
                </p>
            </div>

            <div className="baseline-complete-modal__btn-container">
                <Button text="SKIP VIDEO LESSON" bgColor="blue" onClick={props.onSkip} />
                <Button text="WATCH VIDEO LESSON" bgColor="green" onClick={props.onWatch} />
            </div>
        </Modal>
    );
};

BaselineCompleteModal.defaultProps = {};
BaselineCompleteModal.displayName = 'BaselineCompleteModal';

export default BaselineCompleteModal;
