import * as React from 'react';
import './index.css';
import SummaryAccuracyCell from '../SummaryAccuracyCell';
import SATOverallCell from '../SATOverallCell';
import ACTOverallCell from '../ACTOverallCell';
import NotApplicableIndicator from '../../../../components/NotApplicableIndicator';
import { postPTStudentSummary } from '../../../../services/practice-test-scoring/practice-test-service';
import Modal from '../../../../components/Modal/Modal';
import { getSATSectionDisplayName, getTestTypeBasedOnName, TestType } from '../../../../services/utils/practice-test-util';
import TestInsightsView from './TestInsightsView';
import TestInsightDetailsView from './TestInsightDetailsView';
import { ITeacherStoreData, NotificationType, TeacherStore } from '../../../../stores/teacher.store';
import { getPracticeTestTimeSummaryForAllStudents, ITimeOnPlatform } from '../../TimeOnPlatform/top-lesson-processor';
import { debounce } from '../../../../services/utils/debounce';
import { getHumanTime } from '../../../../services/utils/date-util';

export interface Props {
  testType: TestType
  data: Array<any>;
  teacherEmailAddress: string;
  onReportSent: () => void;
  timeOnPlatformInfo: ITimeOnPlatform[]
}

interface State {
  showEmailNotificationModal: boolean;
  showInsightsModal: boolean
  showInsightDetailsModal: boolean
  testName: string
  displayName: string
  sectionKey: string  
}

export default class SummaryTable extends React.Component<Props, State> { 
  unsubscribe
  loadedTonP = false

  constructor(props: Props) {
    super(props);
    this.state = {
      showEmailNotificationModal: false,
      showInsightsModal: false,
      showInsightDetailsModal: false,
      testName: "",
      displayName: "",
      sectionKey: "",
    };
  }

  onEmailSummaryClick = (testName: string, teacherEmailAddress: string) => {
    this.props.onReportSent();
    postPTStudentSummary(testName, teacherEmailAddress)
      .then(results => {
        // NO-OP
      })
      .catch(console.log);
  }

  showInsights = (row: any) => {
    let {testName, displayName} = row
    this.setState({showInsightsModal: true, testName, displayName})
  }

  closeInsights = () => {
    this.setState({showInsightsModal: false})
  }

  showInsightDetails = (testName, displayName, sectionKey) => {
    this.setState({showInsightDetailsModal: true, testName, displayName, sectionKey })
  }

  closeInsightDetails = () => {
    this.setState({showInsightDetailsModal: false})
  }

  render() {
    const { testType, data, teacherEmailAddress, timeOnPlatformInfo } = this.props;
    const isActOrIa = testType === TestType.ACT || testType === TestType.IA;

    // TODO: Set an order for IA tests (same as in dashboard?)
    const testOrder = isActOrIa ? 
      ['Diagnostic', 'ACT1', 'ACT2', 'ACT3', 'ACT4', 'ACT5', 'ACT6', 'ACT7'] : 
      ['DiagnosticSAT', 'SAT1', 'SAT2', 'SAT3', 'SAT4', 'SAT5', 'SAT6', 'SAT7'];

    data.sort((a, b) => 
      testOrder.indexOf(a.testName) - testOrder.indexOf(b.testName)
    );
    const testSectionDisplayNames = isActOrIa ?
      ['Composite', 'English', 'Math', 'Reading', 'Science'] : 
      ['Score', 'R&W Module 1', 'R&W Module 2', 'Math Module 1', 'Math Module 2']
    const testSectionKeys = isActOrIa ?
      ['Composite', 'English', 'Math', 'Reading', 'Science'] : 
      ['Score', 'Reading', 'Writing & Language', 'Math (No Calculator)', 'Math (Calculator)'];
    const preferredOrder = testSectionKeys.map(s => s.toLowerCase());
    const preferredTestSectionSort = (a, b) => preferredOrder.indexOf(a) - preferredOrder.indexOf(b);
    let {showInsightsModal, showInsightDetailsModal, testName, displayName, sectionKey} = this.state

    return (
      <>
        <table className="practice-test-summary">
          <thead className={isActOrIa ? 'act-header' : 'sat-header'}>
            <tr>
              <th>
                Test Name
              </th>
              {testSectionDisplayNames.map(name => <th key={`th-${testType}-${name}`}>{name}</th>)}
              <th>
                <div>REVIEW TIME</div>
              </th>
              <th>
                <div className="th-tooltip">
                  <div>EMAIL REPORT</div>
                  <span className="th-tooltip-text">Email a report with the raw data organized by test section</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
          {data.map(row => {
            let timeSummary = getPracticeTestTimeSummaryForAllStudents(timeOnPlatformInfo, row.testName)
            let {timeOnReviewTotal, timeOnMistakes, timeOnHints, timeOnAnalysis} = timeSummary
            let totalReviewTime = getHumanTime(timeOnReviewTotal, false, true).text
            // The following will be used in a tooltip when hovered over the total
            let mistakesTime = getHumanTime(timeOnMistakes, false, true).text
            let hintsTime = getHumanTime(timeOnHints, false, true).text
            let analysisTime = getHumanTime(timeOnAnalysis, false, true).text
            return (
              <tr key={`row-${row.testName}`}>
                <td>
                  <div>
                    <div>{row.displayName}</div>
                    <a
                      style={{cursor: "pointer"}}
                      className="hyperlink hyperlink--small iconLink--left img-rel m-0"
                      title={`View the student report for: ${row.displayName}, opens in a modal window`}
                      href="#"
                      onClick={e => this.showInsights(row)}>
                        <img
                            src="/assets/images/icons/v2/ico-modal.svg"
                            width="12"
                            height="12"
                            alt=""
                        />
                      Student Report
                    </a>
                  </div>
                </td>
                {Object
                  .keys(row.averageScores)
                  .sort(preferredTestSectionSort)
                  .map(objKey => {
                    const { testName, averageScores, displayName } = row;
                    const section = averageScores[objKey];
                    const isComposite = objKey === 'composite';
                    const zeroStudents = section.studentCount === 0;
                    const isSatOverall = objKey === 'score' && testType === TestType.SAT;
                    const isIndividualTestSection = !isComposite && !isSatOverall;
                    const reportTitle = isActOrIa ? objKey : getSATSectionDisplayName(objKey);
                    return (
                      <td key={`${testName}-${objKey}`}>
                        {isSatOverall && 
                          <SATOverallCell section={section} />
                        }

                        {isComposite && 
                          <ACTOverallCell section={section} />
                        }

                        {(isIndividualTestSection && zeroStudents) &&
                          <div className="th-tooltip">
                            <div className="not-applicable">N/A&nbsp;</div>
                            <span className="th-tooltip-text">No students took this section</span>
                          </div>
                        }
                        {(isIndividualTestSection && !zeroStudents) &&
                          <>
                            <SummaryAccuracyCell
                              testType={testType}
                              averageScore={section.score}
                              accuracy={section.accuracy}
                              studentCount={section.studentCount}
                            />
                            <a
                              className="hyperlink hyperlink--small iconLink--left img-rel m-0 text-capitalize"
                              href="#"
                              onClick={e => this.showInsightDetails(testName, displayName, objKey)}
                              title={`View the ${reportTitle} report for test: ${displayName}, opens in a modal window`}>
                              <img
                                src="/assets/images/icons/v2/ico-modal.svg"
                                width="12"
                                height="12"
                                alt=""
                              />
                              {reportTitle} Report
                            </a>
                          </>
                        }
                      </td>
                    );
                  })
                }
                <td>                  
                  {totalReviewTime}
                </td>
                <td>
                  <button
                    style={{cursor: "pointer"}}
                    className="btn-simple hyperlink hyperlinkEmail"
                    title={`Email the ${row.displayName} Summary report`}
                    onClick={e => this.onEmailSummaryClick(row.testName, teacherEmailAddress)}>
                    <img src="/assets/images/icons/v2/ico-email.svg" width="20" height="16" alt="Email envelope" />
                  </button>
                </td>
              </tr>
            )})
          }
          </tbody>
        </table>
        
        <Modal show={showInsightsModal} className="tests-insights-container--modal" onClose={this.closeInsights} width="90%">
          <div className="tests-insights-container--modal-header">
            <button type="button" className="modal-close" title="Close Modal" aria-label="Close" onClick={this.closeInsights}>
              <img
                className="modal-icon"
                src={`/assets/images/icons/v2/ico-modal-close-white.svg`}
                width="24"
                height="24"
                alt=""
              />
            </button>
          </div>
          { showInsightsModal && <TestInsightsView testName={testName} testType={testType} displayName={displayName} timeOnPlatformInfo={timeOnPlatformInfo}/> }
        </Modal>

        <Modal show={showInsightDetailsModal} className="tests-insights-container--modal" onClose={this.closeInsightDetails} width="90%">
          <div className="tests-insights-container--modal-header">
            <button type="button" className="modal-close" title="Close Modal" aria-label="Close" onClick={this.closeInsightDetails}>
            <img
                className="modal-icon"
                src={`/assets/images/icons/v2/ico-modal-close.svg`}
                width="24"
                height="24"
                alt=""
              />
            </button>
          </div>
          { showInsightDetailsModal && <TestInsightDetailsView testName={testName} testType={testType} displayName={displayName} sectionKey={sectionKey}/> }
        </Modal>
      </>
    );
  }
}
