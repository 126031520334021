import * as React from 'react';

import EditAccountInfo from './EditAccountInfo';
import { connect } from 'react-redux';
import { updateUser } from '../../../../../../stores/account.store';

const mapDispatchToProps = { updateUser };

const ConnectedEditAccountInfo = connect(null, mapDispatchToProps)(EditAccountInfo);

export default (props) => {
    return (
        <ConnectedEditAccountInfo {...props} />
    );
};
