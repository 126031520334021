//--- Business Rules Engine ---

import { EMPTY, CATEGORY_ENGLISH, CATEGORY_MATH, CATEGORY_READING, CATEGORY_SCIENCE, CATEGORY_WRITING, ACT_CATEGORY_MIN_SCORE_VALUE, SAT_CATEGORY_MIN_SCORE_VALUE, NOT_STARTED, NONE } from "../constants"
import { ITestInsightACTScores, ITestInsightSATScores, ITestInsightScore } from "../services/elearn/teacher/practice-tests-insights"

export interface IRule {
  evaluate: (context) => void
}

export const rule = (condition, action) => ({
  evaluate: (context) => {
    if (condition(context)) {
      action(context)
    }
  }
})

export const rulesEngine = (rules: IRule[]) => ({
  evaluate: (context) => {
    rules.forEach(rule => rule.evaluate(context))
  }
})

// Example:
// export const ruleForAgeOK = rule(ctx => ctx.age > 18, ctx => console.log(`OT: ${ctx.name} is OK`))
// let engine = rulesEngine([ruleForAgeOK])
// engine.evaluate({name: "Some One", age: 21})


export type CategoryType = 
  typeof CATEGORY_ENGLISH | typeof CATEGORY_MATH | 
  typeof CATEGORY_READING | typeof CATEGORY_SCIENCE | typeof CATEGORY_WRITING

/**
 * Determines the NA cases for the specific lesson
 * 
 * @param category
 * @param lessonDetails
 * @returns NAs
 */
export const determineNAs = (category: CategoryType, lessonDetails) => {
  let {totalBaselineQuestions, totalCompletedQuestions} = lessonDetails
  let isCategoryRS = category === CATEGORY_READING || category === CATEGORY_SCIENCE
  let isCategoryW = category === CATEGORY_WRITING
  let isInPostQuestions = totalCompletedQuestions > 0 
  let isAccuracyPreNA = totalBaselineQuestions === 0 || isCategoryRS || isCategoryW
  let isAccuracyPostNA = !isInPostQuestions || isCategoryW
  let isGrowthNA = !(isInPostQuestions && !(isCategoryRS || isCategoryW))
  let isHintsOnIncorrectNA = isAccuracyPostNA
  let isCollegeReadyNA = isAccuracyPostNA

  return {
    isAccuracyPreNA,
    isAccuracyPostNA,
    isGrowthNA,
    isHintsOnIncorrectNA,
    isCollegeReadyNA
  }
}

/**
 * Determines the NA cases
 * 
 * @param category
 * @param totalBaselineQuestions
 * @param totalCompletedQuestions
 * @returns NAs
 */
 export const determineNAs2 = (category: CategoryType, totalBaselineQuestions: number, totalCompletedQuestions: number) => {
  return determineNAs(category, {totalBaselineQuestions, totalCompletedQuestions})
}


/**
 * Cleans and gets the status from lesson entry
 * 
 * @param lesson 
 * @returns status info
 */
export const getStatus = (lesson) => {
  let {status, questionCompletionProgress, totalCompletedQuestions, totalQuestions} = lesson
  
  if (status) {
    status = cleanStatus(status)

    if (questionCompletionProgress > 0) {
      if (status.indexOf("multiple choice") !== -1) {
        status = `Question ${totalCompletedQuestions}/${totalQuestions}`
      }
    }

    return status
  }

  return EMPTY
}

/**
 * Cleans the status information
 * 
 * @param status 
 * @returns 
 */
export const cleanStatus = (status: string) => {
  status = status.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
  status = status.replace('::', ' ')
  status = status.replace(' : ', ': ')
  status = status.replace(/([A-Za-z0-9])(&)([A-Z])/g, '$1 $2 $3')
  status = status.toLowerCase() === NONE ? NOT_STARTED: status
  
  // Required for old entries!
  if (status.indexOf("Intro") !== -1) status = "Video: Intro"
  if (status.indexOf("On video:") !== -1) status = "V" + status.substring(4)  
  
  let statusLC = status.toLowerCase()

  if (statusLC.indexOf("video:") !== -1) {
    let regex = /(\d+)\/(\d+)/
    let found = statusLC.match(regex)
    let n1 = 1, n2 = 1
    if (found) {
      n1 = Number(found[1])
      n2 = Number(found[2])      
      status = `Video ${n1}/${n2}`
    }
  }

  return status
}


/**
 * Find if N/A condition found for a given ACT/IA score
 * 
 * @param score 
 * @returns true if found NA condition
 */
export const isNAForGivenACTScore = (score: ITestInsightScore) => {
  let {value} = score
  if (value <= ACT_CATEGORY_MIN_SCORE_VALUE) return true
  return false
}

/**
 * Find if N/A condition found for a given SAT score
 * 
 * @param score 
 * @returns true if found NA condition
 */
export const isNAForGivenSATScore = (score: ITestInsightScore) => {
  let {value} = score
  if (value <= SAT_CATEGORY_MIN_SCORE_VALUE) return true
  return false
}

/**
 * Find if N/A condition found in anyone of the categorical (sectional) ACT/IA scores
 * 
 * @param scores ACT or IA scores
 * @returns true if found NA condition(s)
 */
export const isNAConditionFoundInACTScores = (scores: ITestInsightACTScores) => {
  let isNADetected = false
  let {english, math, reading, science} = scores
  let isNAE = isNAForGivenACTScore(english)
  let isNAM = isNAForGivenACTScore(math)
  let isNAR = isNAForGivenACTScore(reading)
  let isNAS = isNAForGivenACTScore(science)

  // If anyone of them is NA then make sure to set NA detected value to be true
  if (isNAE || isNAM || isNAR || isNAS) {
    isNADetected = true
  }

  return isNADetected
}

/**
 * Find if N/A condition found in anyone of the categorical (sectional) SAT scores
 * 
 * @param scores SAT scores
 * @returns true if found NA condition(s)
 */
export const isNAConditionFoundInSATScores = (scores: ITestInsightSATScores) => {
  let isNADetected = false
  let {ebrw, math} = scores
  let isNAE = isNAForGivenSATScore(ebrw)
  let isNAM = isNAForGivenSATScore(math)

  // If anyone of them is NA then make sure to set NA detected value to be true
  if (isNAE || isNAM) {
    isNADetected = true
  }

  return isNADetected
}
