import './MenuIcon.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import ColorPalette from '../../../../../../../ColorPalette/ColorPalette';

interface Props {
    onColorChange: (color: string) => void;
    initColor: string;
}

interface State {
    selectedColor: string;
}

export default class MenuIcon extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedColor: 'white'
        };
    }

    public render() {
        return (
            <div className="menu-icon-container">
                <ColorPalette onColorChange={this.onColorChange} initColor={this.props.initColor}/>
            </div>
        );
    }
    
    @boundMethod
    private onColorChange(color: string) {
        this.setState({ selectedColor: color }, () => this.props.onColorChange(color));
    }
}
