import './SubjectView.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { CollapsableCardBody, CollapsableCardHeader } from '../../../../components/CollapsableCard/CollapsableCard';
import { LessonProgress, MaterialType } from '../../../../services/elearn/lesson-types';

import MathCheatsheetLink from '../../../../components/MathCheatsheetLink/MathCheatsheetLink';
import CollapsableCard from '../../../../components/CollapsableCard/CollapsableCard';
import LessonList from '../../../../components/LessonList/LessonListContainer';
import LessonListCardHeader from '../../../../components/LessonListCardHeader/LessonListCardHeader';
import NoLessons from './components/NoLessons/NoLessons';
import { getSubjectIconList } from '../../../../services/icon/icon-service';
import { groupLessonsBySubcategory } from '../../../../services/array/array-utils';

export interface SubjectViewProps {
    curriculum: string;
    mistakeBank: boolean;
    isTeacher: boolean;
    frequency: string;
    popPurchaseModal: () => void;
}

interface StoreProps {
    userId: string;
    subjects: {
        [categoryName: string]: LessonProgress[];
    };
    userInfo: any;
}

interface State {

}

type AllProps = SubjectViewProps & StoreProps;

const subjectIcons = getSubjectIconList('@0,5x');


export default class SubjectView extends React.PureComponent<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
    }
    public render() {
        if(!this.props.subjects) {
            return <NoLessons />;
        }

        const cards = subjectIcons.map(this.createCard);
        if(cards.length === 0 || cards.every(card => !card)) {
            return <NoLessons />;
        }

        return (
            <div className="subject-view">                
                {cards}
            </div>
        );
    }

    @boundMethod
    private createCard({ title, icon, color}: any) {

        // Don't show subject cards if the user doesn't have lessons in that subject
        const subject = this.props.subjects[title];
        if(!subject) {
            return;
        }

        const allLessons = subject.filter(lesson =>
            lesson.curricula ? lesson.curricula.some(curriculum => this.props.curriculum === curriculum) : true);
        const numLessons = allLessons.length;
        const numCompletedLessons = allLessons.filter(lesson => lesson.currentLessonContent &&
            lesson.currentLessonContent.type === MaterialType.REPORT).length;

        if(numLessons === 0) {
            return;
        }

        const lessonGroups = groupLessonsBySubcategory(allLessons);
        const initiallyCollapsed = (this.props.userInfo.bundleId === 'sss') ? false : true;

        return (
            <CollapsableCard key={title} initiallyCollapsed={initiallyCollapsed}>

                <CollapsableCardHeader>
                    <LessonListCardHeader
                        text={title}
                        icon={icon}
                        color={color}
                        numQuestionsCompleted={numCompletedLessons}
                        numQuestionsTotal={numLessons}
                    />
                </CollapsableCardHeader>

                <CollapsableCardBody>
                    {title === 'Math' && 
                      <MathCheatsheetLink />
                    }
                    {
                        lessonGroups.map(d => {
                            const lessons = d.lessons.sort((l1, l2) => l1.lessonOrder - l2.lessonOrder);
                            const key = d.subcategory ? d.subcategory.subcategoryName : 'all';
                            return (
                                <div className={`subcategory-lesson-list lesson-table--` + title.toLowerCase()} key={`subcategory-lesson-list-${key}`}>
                                    <div className={title === 'Math' ? 'subcategory-math' : ''}>
                                    {!!d.subcategory &&
                                        <h6 className="subcategory-title" key={key}>
                                            {key.toUpperCase()}
                                        </h6>}
                                    </div>
                                    <LessonList
                                        lessons={lessons}
                                        isTeacher={this.props.isTeacher}
                                        frequency={this.props.frequency}
                                        popPurchaseModal={this.props.popPurchaseModal}
                                    />
                                </div>
                            );
                        })
                    }
                </CollapsableCardBody>
            </CollapsableCard>
        );
    }
}
