import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';

export default class NotFound extends React.PureComponent<RouteComponentProps<any>, {}> {
    constructor(props: any) {
        super(props);
    }

    public render() {
        return (
            <div className="page-content">
                Page not found
            </div>
        );
    }
}
