import './PracticeTestScoreSheet.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';
import { TestAnswer } from '../../services/elearn/practice-test-types';
import { createTestHint, getHint } from '../../services/practice-test-scoring/test-hint-service';
import PracticeTestComponentSelector from './components/PracticeTestComponentSelector';
import AddHintForm from '../../components/AddHintForm';
import BackToTestsButton from './components/BackToTestsButton/BackToTestsButton';
import AnalysisButton from './components/AnalysisButton/AnalysisButton';
import TestSectionScore from './components/TestSectionScore/TestSectionScore';
import ScoreSheetRow from './components/ScoreSheetRow';
import { TestSection, getSectionCode } from '../../services/section-colors';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  getDetailPTScoresByTest,
  getOverallPTScore,
  updatePTTest,
  resetPracticeTestAnswer,
  resetIATestAnswer,

  getDetailIAScoresByTest,
  getOverallIAScore,
  updateIATest
} from '../../services/practice-test-scoring/practice-test-service';
import BrightcoveVideo  from '../../components/BrightcoveVideo/BrightcoveVideo';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import { PageContent } from '../../components/PageContent/PageContent';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import ProgressBarLabel from './components/ProgressBarLabel/ProgressBarLabel';
import { UserInfo } from '../../services/account/account-rest-interface';
import { setPriority } from '../../stores/lesson.store';
import { updateActivityInfoWithLastKnownNavigation } from '../../WinwardTracker';
import { SAT2024, getCategoryName, isInterimAssessment, isSatTest } from '../../services/utils/practice-test-util';
import { App } from '../../App';
import { SimpleTimer } from '../../services/utils/simple-timer';
import { Context, Resource, UserActivityEx, sendUserActivity } from '../../services/liveview/liveview-service';
import { getExplanationURL } from "../../services/utils/resource-util";

export interface Props {
    diagnostic?: boolean;
}

export interface StoreProps {
  userInfo: UserInfo;
}

export interface DispatchProps {
  setPriority: (lessonId: string, status: boolean) => Promise<any>;
}

interface State {
  questionList: Array<any>;
  scoresList: Array<any>;
  section: string;
  testName: string;
  displayName: string;
  totalCorrect: number;
  testScore: number;
  toggleExplanationVideoPopup: boolean;
  showAddHintDialog: boolean;
  hintLocator?: any;
  videoLink: string;
  satImage: string;
  englishAnswers: Array<any>;
  mathAnswers: Array<any>;
  readingAnswers: Array<any>;
  scienceAnswers: Array<any>;
  writingAndLanguageAnswers: Array<any>;
  mathNoCalculatorAnswers: Array<any>;
  mathCalculatorAnswers: Array<any>;
  sortedBy: string;
  reversedSort: boolean;
  isLoaded: boolean;
  gridIn: string;
  disable: boolean;
  isTeacher: boolean;
  shouldRestoreNavbar: boolean;
  interactedQuestion?: any;
}

export type AllProps = Props & StoreProps & DispatchProps & RouteComponentProps<any>;
declare const videojs: any;


export class PracticeTestScoreSheet extends React.Component<AllProps, State> {
  simpleTimer = new SimpleTimer()

  constructor(props: AllProps) {
    super(props);
    this.state = {
      questionList: [],
      scoresList: [],
      section: '',
      testName: props.match.params.practiceId,
      totalCorrect: 0,
      testScore: -1,
      showAddHintDialog: false,
      toggleExplanationVideoPopup: false,
      videoLink: '',
      satImage: '',
      englishAnswers: [],
      mathAnswers: [],
      readingAnswers: [],
      scienceAnswers: [],
      writingAndLanguageAnswers: [],
      mathNoCalculatorAnswers: [],
      mathCalculatorAnswers: [],
      sortedBy: 'questionNumber',
      reversedSort: false,
      displayName: '',
      isLoaded: false,
      isTeacher: props.userInfo.isTeacher,
      gridIn: '',
      disable: false,
      shouldRestoreNavbar: false
    };
  }

    public renderScoreSheetHeader(sortedBy: string, reversedSort: boolean, isTeacher: boolean) {
        return (
            <thead>
                <tr>
                    <th onClick={() => this.sortData('questionNumber')}
                        className={(reversedSort && sortedBy === 'questionNumber')
                        ? 'sortable sorted' : 'sortable'}>
                            <span className="p-rel d-inline-block">
                                # <span className="header-cell__sort"/>
                            </span>
                    </th>
                    <th title="PRIORITIZE QUESTION"onClick={() => this.sortData('bookmark')}
                        className={(reversedSort && sortedBy === 'bookmark')
                        ? 'stacked-title sortable sorted text-left' : 'stacked-title sortable'}>
                            <div className="th-tooltip">
                                <div>
                                    <span className="p-rel text-left">FLAG <span className='header-cell__sort col-bookmark'></span></span>
                                </div>
                                <span className="th-tooltip-text">Bookmark this question to review again</span>
                            </div>
                        </th>
                    <th
                      onClick={() => this.sortData('accuracy')}
                      className={`lesson-name lesson-answer text-center ` + ((reversedSort && sortedBy === 'accuracy') ? 'sortable sorted' : 'sortable')}>
                        <span className="p-rel d-inline-block">YOUR ANSWER<span className="header-cell__sort"></span></span>
                    </th>
                    {(this.state.section === 'Math (Calculator)' 
                        || this.state.section === 'Math (No Calculator)'
                    ) &&
                    <th
                        onClick={() => this.sortData('gridIn')}
                        className={`col-gridin ` + ((reversedSort && sortedBy === 'gridIn') ? 'sortable sorted' : 'sortable')}
                    >
                        <span className="p-rel d-inline-block">GRID IN <span className="header-cell__sort"></span></span>
                    </th>
                    }
                    <th
                        onClick={() => this.sortData('lessonName')}
                        className={(reversedSort && sortedBy === 'lessonName') ? 
                            ' lesson-name sortable sorted' : 'lesson-name sortable'
                        }
                    >
                        <span className="p-rel d-inline-block">LESSON <span className="header-cell__sort"></span></span>
                    </th>
                    <th
                        onClick={() => this.sortData('topicName')}
                        className={(reversedSort && sortedBy === 'topicName') ? 
                            'topic-name sortable sorted' : 'topic-name sortable'
                        }
                    >
                        <span className="p-rel">TOPIC <span className="header-cell__sort"></span></span>
                    </th>
                    <th className="text-center col-view-explanation">LEARN</th>
                    {!isTeacher &&
                    <th className="text-center col-add-hint add-hint-header">HINT</th>
                    }
                    {!isTeacher &&
                    <th
                      onClick={() => this.sortData('star', 'lessonName')}
                      className={(reversedSort && sortedBy === 'star')
                      ? 'star-lesson-header sortable sorted'
                      : 'star-lesson-header sortable'}
                    >
                        <div className="th-tooltip">
                            <div>
                                <span className="p-rel text-left">PRIORITY <span className="header-cell__sort"></span></span>
                            </div>
                            <span className="th-tooltip-text">Star a lesson to indicate all questions that test similar content</span>
                        </div>
                    </th>
                    }
                </tr>
            </thead>
        );
    }

  public render() {
      const self = this;
      const { section, isTeacher, sortedBy, reversedSort, isLoaded, testName, questionList } = this.state;
      let testTitle = this.state.testName;
      if (testTitle === 'DiagnosticSAT') {
          testTitle = 'SAT Diagnostic';
      }
      if (!isLoaded) {
          return <Loading />;
      }

      let testType = 'act';
      if (this.isInterimAssessment()) {
        testType = 'ia';
      } else if (this.isAct()) {
        testType = 'act';
      } else {
        testType = 'sat';
      }
      const baseUri = '/app/practice-tests?testType=' + testType;
      let sectionName = `${section}`      
      let pageTitle = `${(testTitle === 'SAT Diagnostic' && testTitle) ? testTitle: ""} 
                       ${(testTitle !== 'SAT Diagnostic' && this.state.displayName) ? this.state.displayName : ""}`
      let activity = `${pageTitle} ${sectionName}`
      
      let InstructionsLink = `/app/practice-tests/instructions/${testName}`;
      let scoreColor = this.getScoreColor(testType, section)
      
      updateActivityInfoWithLastKnownNavigation(activity)

      return (
        <div className="practice-scoring-parent-div">
            <PageContent className="practice-scoring-container">
            <div className="score-sheet-top-nav-bar">
                <h1 className="page-title">
                    {testTitle === 'SAT Diagnostic' && testTitle} 
                    {testTitle !== 'SAT Diagnostic' && this.state.displayName}
                </h1>
                {testType != 'ia' &&
                    <Link to={InstructionsLink} className="btn btn--text-white btn--bg-blue mr-sm-2">
                        INSTRUCTIONS
                    </Link>
                }
                {!isTeacher && 
                    <AnalysisButton className="mr-sm-2" btnColor="green" testName={this.state.testName} />
                }
                <BackToTestsButton 
                  btnColor={!isTeacher ? "purple" : "green"}
                  targetUri={baseUri} 
                  testType={testType}
                />
            </div>
                {(!this.isAct() && !this.isInterimAssessment()) &&
                    <div className="new-button-bar">
                        <PracticeTestComponentSelector
                            displayName={SAT2024.DISPLAYNAME_RW_MODULE1}
                            onSwitchComponent={() => this.updateSection(SAT2024.SECTIONNAME_RW_MODULE1)}
                            active={(section === SAT2024.SECTIONNAME_RW_MODULE1)}
                        />
                        <PracticeTestComponentSelector
                            displayName={SAT2024.DISPLAYNAME_RW_MODULE2}
                            onSwitchComponent={() => this.updateSection(SAT2024.SECTIONNAME_RW_MODULE2)}
                            active={(section === SAT2024.SECTIONNAME_RW_MODULE2)}
                        />
                        <PracticeTestComponentSelector
                            displayName={SAT2024.DISPLAYNAME_MATH_MODULE1}
                            onSwitchComponent={() => this.updateSection(SAT2024.SECTIONNAME_MATH_MODULE1)}
                            active={(section === SAT2024.SECTIONNAME_MATH_MODULE1)}
                        />
                        <PracticeTestComponentSelector
                            displayName={SAT2024.DISPLAYNAME_MATH_MODULE2}
                            onSwitchComponent={() => this.updateSection(SAT2024.SECTIONNAME_MATH_MODULE2)}
                            active={(section === SAT2024.SECTIONNAME_MATH_MODULE2)}
                        />
                    </div>
                }
                {(this.isAct() || this.isInterimAssessment()) &&
                    <div className="new-button-bar">
                            {['English', 'Math', 'Reading', 'Science'].map(nSection => 
                                <PracticeTestComponentSelector
                                    key={`test-component-button-${nSection}`}
                                    displayName={nSection}
                                    onSwitchComponent={() => this.updateSection(nSection)}
                                    active={(section === nSection)}
                                />)
                            }
                    </div>
                }

            <div className={`practice-scoring-content table-subj-header-alt ` + scoreColor + `--tables`}>
                <div className="subject-header-container">
                {   this.state.testScore > -1 ?
                    <TestSectionScore 
                        sectionColor={scoreColor}
                        score={this.state.testScore}
                        sectionName={sectionName}
                        testType={testType}
                    />
                    : <div>NO SCORE {this.state.testScore}</div>
                }
                <span
                  className={'subject-header-title-progress-bar '}
                >
                    <ProgressBarLabel 
                        totalAccurate={this.state.totalCorrect}
                        questionCount={this.state.questionList.length}
                    />
                    <ProgressBar
                        current={this.state.totalCorrect}
                        total={this.state.questionList.length}
                        size="large"
                        barCurrentColor={this.getProgressBarColors(section).barCurrentColor}
                        barTotalColor={'gray'}
                    />

                </span>
            </div>
            <div className="fixedHeaderTable">
                <table className="practice-scoring-table">
                    {this.renderScoreSheetHeader(sortedBy, reversedSort, isTeacher)}
                    <tbody>
                    {this.state.questionList.map((questionData: any) => {
                        return (
                        <ScoreSheetRow
                            gridInRequired={
                                section === 'Math (Calculator)' ||
                                section === 'Math (No Calculator)'
                            }
                            key={`score-row-${questionData.questionNumber}`}
                            isTeacher={isTeacher}
                            questionNumber={
                                questionData.questionNumber as number
                            }
                            diagnosticType={testType}
                            correctAnswer={questionData.correctAnswer}
                            existingAnswer={questionData.userSubmittedAnswer}
                            topicName={questionData.topicName.toString()}
                            lessonName={questionData.lessonName.toString()}
                            onAnswerSelect={this.onAnswerSelected}
                            mcLetters={questionData.mcLetters}
                            isPriority={questionData.star === true}
                            isBookmarked={questionData.bookmarked === true}
                            isBookmarkDisabled={questionData.userSubmittedAnswer === ""}
                            gridIn={(section === 'Math (Calculator)' ||
                                section === 'Math (No Calculator)') ? questionData.gridIn : undefined
                            }
                            onPriorityUpdated={() => { this.onToggleStar(questionData)}}
                            onBookmarkUpdated={() => this.onToggleBookmark(questionData)}
                            disabled={this.state.disable}
                            onExplanationButtonClicked={() => this.openExplanation(questionData)}
                            onAddHintRequested={() => this.loadSaveHintDialog(questionData)}
                        />);
                    }
                    )
                    }
                    </tbody>
                </table>
              </div>
            </div>

            <Modal
              className="modal"
              show={this.state.showAddHintDialog}
              onClose={() => this.closeHintDialog()}
              showXTopRight={true}
              width="476px"
            >
              <AddHintForm 
                text={this.state.hintLocator ? this.state.hintLocator.existingHint : ""}
                onCancel={() => this.closeHintDialog()}
                onSaveHint={(text) => this.saveHint(text)} />
            </Modal>
          {this.isAct() || this.isInterimAssessment() ? (
                <Modal
                  className="explanation-video modal"
                  show={this.state.toggleExplanationVideoPopup}
                  onClose={this.closeModal}
                  showXTopRight={true}
                  width="976px"
                >
                  <button type="button" className="close" onClick={this.closeModal}>&times;</button>
                  <BrightcoveVideo
                    id="explanation-video"
                    videoId={this.state.videoLink}
                  >
                  </BrightcoveVideo>
                </Modal>
          ) : (
              <Modal
                className="sat-explanation modal"
                show={this.state.toggleExplanationVideoPopup}
                onClose={this.closeModal}
                showXTopRight={true}
                width="1290px"
              >
                <img
                  alt=""
                  src={this.state.satImage}
                />
              </Modal>
          )}
        </PageContent>
      </div>
      );
  }

    public componentDidMount() {
        if (!App.isNavbarCollapsed()) {            
            App.collapseNavbar()       
            this.setState({shouldRestoreNavbar: true})     
        }

        const actOrIa = this.isInterimAssessment() || this.isAct();
        const { testName } = this.state;
        const initialSection = this.isAct() || this.isInterimAssessment() ? 
            'English' : 'Reading';
        this.refreshTestDetails(initialSection);
            //     // TODO IA vs ACT color dealie does this matter?
            //     // const sectionColor = actOrIa ? 'englishPurple' : 'readingOrange';
    }

    componentWillUnmount() {        
        if (this.state.shouldRestoreNavbar) {
            if (App.isNavbarCollapsed()) {
                App.collapseNavbar(false)
            }
        }
    }

    @boundMethod
    closeHintDialog() { 
      this.setState({
        showAddHintDialog: false,
        hintLocator: undefined
      });
    }

    loadSaveHintDialog(questionData) {
      const {questionNumber} = questionData
      const { section, testName } = this.state;
      const sectionCode = getSectionCode(section as TestSection);
      getHint(testName, sectionCode, questionNumber)
        .then((result) => {
          this.simpleTimer.start()
          this.setState({
            showAddHintDialog: true,            
            hintLocator: {
              questionNumber: questionNumber,
              existingHint: result,
              questionData
            }
          });
        })
        .catch(console.log);
    }

    saveHint(text: string) {
      const { testName, section, hintLocator } = this.state;
      const sectionCode = getSectionCode(section as TestSection);
      if (hintLocator) {
        createTestHint(testName, sectionCode, hintLocator.questionNumber, text)
          .then(this.closeHintDialog)
          .catch(console.log);

        this.sendStatsForHintEntry()
      } else {
        console.log('Something went wrong? trying to save hint: ', text);
      }
    }

    refreshTestDetails(sectionName: string) {
        const { testName } = this.state;
        const getDetailScoresByTest = this.isInterimAssessment() ?
            getDetailIAScoresByTest(testName) :
            getDetailPTScoresByTest(testName);

        Promise.all([
            this.refreshScore(sectionName),
            getDetailScoresByTest
        ]).then((results) => {
            const scoreData = results[0] as any;
            const response = results[1] as any;

            let subjectAnswersKey = this.lookupSection(sectionName);
            const questions = response.questions[subjectAnswersKey];
            const correctCount = this.calculateTotalCorrect(questions);

            this.setState({
                displayName: scoreData.displayName,
                testScore: scoreData.testScore,
                section: scoreData.section,

                englishAnswers: response.questions.englishAnswers,
                mathAnswers: response.questions.mathAnswers,
                readingAnswers: response.questions.readingAnswers,
                scienceAnswers: response.questions.scienceAnswers,
                mathNoCalculatorAnswers: response.questions.mathNoCalculatorAnswers,
                mathCalculatorAnswers: response.questions.mathCalculatorAnswers,
                writingAndLanguageAnswers: response.questions.writingAndLanguageAnswers,
                questionList: questions,
                totalCorrect: correctCount,
                isLoaded: true
            }
                // () => resolve()
            );
        }).catch(console.log);
    }

    onToggleStar = (testInfo: any) => {
        const { lessonId, star } = testInfo;
        const { questionList } = this.state;

        this.props.setPriority(lessonId, !star).then(() => {
            const updatedQuestions = questionList.map(question => {
                if (question.lessonId === lessonId) {
                    question.star = !star;
                    return question;
                } else {
                    return question;
                }
            });
            this.setState({ questionList: updatedQuestions });
        });
    }

    onToggleBookmark = (questionData) => {
        const { bookmarked, questionNumber, userSubmittedAnswer } = questionData
        const { questionList } = this.state
        const lookupQuestion = questionList.find(q => q.questionNumber === questionNumber)
        const { correctAnswer } = lookupQuestion

        // OT: Don't allow toggling if there is no answer selected for this question!
        if (userSubmittedAnswer == "") return
        
        const testAnswer: TestAnswer = {
            mcLetter: userSubmittedAnswer,
            isCorrect: (correctAnswer === userSubmittedAnswer) ? true : false,                
            bookmarked: !bookmarked
        }

        
        this.updateAnswer(questionNumber, testAnswer)
        lookupQuestion.bookmarked = testAnswer.bookmarked
        this.setState({questionList})
    }

    // TODO: Extrapolate this to a general utility function
    // The score headers are the same color as the progress bar
    private getProgressBarColors(section: string) {
        switch(section) {
            case SAT2024.SECTIONNAME_RW_MODULE1:
            case SAT2024.SECTIONNAME_RW_MODULE2:
            case 'Writing and Language': 
            case 'English':
                return {
                    barCurrentColor: 'lightpurple',
                };
            case 'Reading':
                return {
                    barCurrentColor: 'orange',
                };
            case 'Science':
                return {
                    barCurrentColor: 'green'
                };
            case SAT2024.SECTIONNAME_MATH_MODULE1:
            case SAT2024.SECTIONNAME_MATH_MODULE2:
            case 'Math':
            case 'Math (No Calculator)':
            case 'Math (Calculator)':
                return {
                    barCurrentColor: 'blue',
                };
            default: {
                return {
                    barCurrentColor: undefined,
                };
            }
        }
    }

    @boundMethod
    private closeModal() {
      if (this.isAct() || this.isInterimAssessment()) {
        const myplayer = videojs('explanation-video');
        myplayer.pause();
        this.setState({
          toggleExplanationVideoPopup: !this.state.toggleExplanationVideoPopup,
        });
      } else {
        this.setState({
          toggleExplanationVideoPopup: !this.state.toggleExplanationVideoPopup,
        });
      }
      this.sendStatsForExplanation()
    }

    private lookupSection(section: string) {
        if (!section || section ===  '') {
            // console.log('Section was EMPTY!');
        }

        // this is unfortunate...
        const sectionMap = {} as any;

        sectionMap.English = 'englishAnswers';
        sectionMap.Math = 'mathAnswers';
        sectionMap.Reading = 'readingAnswers';
        sectionMap.Science = 'scienceAnswers';
        sectionMap['Math (Calculator)'] = 'mathCalculatorAnswers';
        sectionMap['Math (No Calculator)'] = 'mathNoCalculatorAnswers';
        sectionMap['Writing and Language'] = 'writingAndLanguageAnswers';
        return sectionMap[section];
    }

    // TODO: Replace this with new function in practice-test-util
    private isAct() {
        const { testName } = this.state;
        return testName.startsWith('ACT') || 
            testName === 'Diagnostic';
    }

    private isInterimAssessment() {
        const { testName } = this.state
        return isInterimAssessment(testName)
    }
    
    private updateAnswers(questionNumber: number, letterAnswer: string) {
        const { testName, section } = this.state;
        let questions = this.state.questionList;
        const updatedQuestions = questions.map(question => 
            question.questionNumber === questionNumber ?
                { ...question, userSubmittedAnswer: letterAnswer } :
                { ...question }
        );
        const total = this.calculateTotalCorrect(updatedQuestions);
        const newState = { 
            questionList: updatedQuestions,
            totalCorrect: total,
            disable: false
        };
        this.setState(newState);
    }

    private resetAnswer(questionNumber: number, testAnswer: TestAnswer) {
        const { testName, section } = this.state;
        let sectionGroup = this.lookupSection(section);
        let questions = this.state[sectionGroup];

        const resetAnswerPromise = this.isInterimAssessment() ? 
            resetIATestAnswer(
                testName, 
                section.toLowerCase(), 
                questionNumber, 
                testAnswer
            ) :
            resetPracticeTestAnswer(
                testName, 
                section.toLowerCase(), 
                questionNumber, 
                testAnswer
            );
        resetAnswerPromise.then(response => {
            this.updateAnswers(questionNumber, '');
            const index = questions.findIndex(
                q => q.questionNumber === questionNumber
            );
            if (index >= 0) {
                this.updateCurrentSectionScore(response);
            } else {
                throw new Error(
                    `This should not happen: index:${index} 
                    question#${questionNumber}`
                );
            }
        }).catch(console.log);
    }

    // ...ugh, how to type this response
    private updateCurrentSectionScore(response: any) {
        const currentSection = this.getScoreAttributeName(this.state.section);
        this.setState({ testScore: response[currentSection] });
    }

    private updateAnswer(questionNumber: number, testAnswer: TestAnswer) {
        const { testName, section } = this.state;
        let sectionGroup = this.lookupSection(section);
        let questions = this.state[sectionGroup];
        const updatePromise = this.isInterimAssessment() ? 
            updateIATest : updatePTTest;
        updatePromise(testName, section.toLowerCase(), questionNumber, testAnswer)
            .then(response => {
                const index = questions.findIndex(
                    q => q.questionNumber === questionNumber
                );
                this.updateAnswers(questionNumber, testAnswer.mcLetter);

                if (index >= 0) {
                    this.updateCurrentSectionScore(response);
                } else {
                    console.log(
                        'BIG Problem during update: Did not find question/answer: ' 
                        + questionNumber
                    );
                }
            });
    }

    @boundMethod
    private onAnswerSelected(questionNumber: number, letter: string) {
        const lookupQuestion = this.state.questionList.find(
            q => q.questionNumber === questionNumber
        );
        const { userSubmittedAnswer, correctAnswer } = lookupQuestion;                        
        this.setState({ disable: true, interactedQuestion: lookupQuestion, 
        }, () => {
            const shouldResetAnswer = userSubmittedAnswer === letter;
            const testAnswer: TestAnswer = {
                mcLetter: letter,
                isCorrect: (correctAnswer === letter) ? true : false,                
                bookmarked: lookupQuestion.bookmarked
            };

            if (shouldResetAnswer) {
                this.resetAnswer(questionNumber, testAnswer);
            } else {
                this.updateAnswer(questionNumber, testAnswer);
            }            
            this.sendStatsForQuestion()
        });        
    }

    private calculateTotalCorrect(answers: Array<any>) {
        return answers
            .reduce((acc, val) =>
                val.userSubmittedAnswer === val.correctAnswer ? acc + 1 : acc,
                0
            );
    }

    private getScoreAttributeName(section: string) {
        if (this.isAct() || this.isInterimAssessment()) {
            switch(section) {
                case 'English': {
                    return 'english';
                }
                case 'Math': {
                    return 'math';
                }
                case 'Reading': {
                    return 'reading';
                }
                case 'Science': {
                    return 'science';
                }
                default: {
                    // console.log(
                    //     'BAD should not happen getting score attr name: ' + section
                    // );
                    return 'English';
                }
            }
        } else {
            // SAT is weird
            switch(section) {
                case SAT2024.SECTIONNAME_MATH_MODULE1:
                case SAT2024.SECTIONNAME_MATH_MODULE2:
                case 'Math (Calculator)':
                case 'Math (No Calculator)': {
                    return 'math';
                }

                case SAT2024.SECTIONNAME_RW_MODULE1:
                case SAT2024.SECTIONNAME_RW_MODULE2:
                case 'Writing and Language':
                case 'Reading': {
                    return 'evidenceBasedRW';
                }
                default: {
                    // console.log('BAD should not happen getting score attr name: ' + section);
                    return 'Reading';
                }
            }
        }
    }

    private getScoreColor(testType: string, sectionName: string) {
        let isSAT = testType.toLowerCase() === "sat"

        if (isSAT) {
            switch(sectionName) {
                case SAT2024.SECTIONNAME_RW_MODULE1:
                case SAT2024.SECTIONNAME_RW_MODULE2: {
                    return 'englishPurple'
                }
                case SAT2024.SECTIONNAME_MATH_MODULE1:
                case SAT2024.SECTIONNAME_MATH_MODULE2: {
                    return 'mathBlue'
                }
                default: {
                    return ''
                }
            }
        }

        switch (sectionName) {
            case 'English': {
                return 'englishPurple'
            }
            case 'Reading': {
                return 'readingOrange'
            }
            case 'Science': {
                return 'scienceGreen'
            }
            case 'Math':
            case 'Math (No Calculator)':
            case 'Math (Calculator)': {
                return 'mathBlue'
            }
            default: {
                return ''
            }
        }
    }

    private updateSection(newSection: string) {
        const { testName } = this.state;
        this.refreshTestDetails(newSection);
    }

    private refreshScore(section: string) {
        return new Promise((resolve, reject) => {
            const { testName } = this.state;
            const getOverallScore = this.isInterimAssessment() ?
                getOverallIAScore(testName) :
                getOverallPTScore(testName);

            getOverallScore.then(response => {
                resolve({
                    section: section,
                    displayName: response.displayName,
                    testScore: response[this.getScoreAttributeName(section)],
                });
            });
        });
    }

    private sortData(column: string, secondary?: string) {
        let defaultSort = secondary || 'questionNumber';
        const { sortedBy, reversedSort, questionList } = this.state;
        const currentSort = sortedBy;
        const reverseSort = column !== currentSort ? false : !reversedSort;
        let sortedData;
        if (column === 'accuracy') {
            let correctAnswers = questionList.filter(obj => {
              return obj.userSubmittedAnswer === obj.correctAnswer;
            });
            let incorrectAnswers = questionList.filter(obj => {
              return (obj.userSubmittedAnswer !== '') && (obj.userSubmittedAnswer !== obj.correctAnswer);
            });
            let incompleteAnswers = questionList.filter(obj => {
              return obj.userSubmittedAnswer === '';
            });

            sortedData = !reverseSort ? 
                correctAnswers.concat(incorrectAnswers).concat(incompleteAnswers) :
                incorrectAnswers.concat(correctAnswers).concat(incompleteAnswers);
            this.setState({
                questionList: sortedData,
                sortedBy: column,
                reversedSort: reverseSort
            });
        } 
        else if (column === "lessonName") {
            // Special sorting case for the lessonName:
            //   Consider lessonName (primary), topicName (secondary) & questionNumber (tertiary) 
            //   as combined keys in the lexicographical sort!
            sortedData = questionList.sort((a, b) => {
                let lnA = a["lessonName"] || ""
                let lnB = b["lessonName"] || ""
                let tnA = a["topicName"] || ""
                let tnB = b["topicName"] || ""
                let qnA = a["questionNumber"] || 0
                let qnB = b["questionNumber"] || 0
                let qnsA = qnA < 10 ? `0${qnA}`: `${qnA}`
                let qnsB = qnB < 10 ? `0${qnB}`: `${qnB}`
                let k1 = `${lnA}-${tnA}-${qnsA}`
                let k2 = `${lnB}-${tnB}-${qnsB}`

                if (k1 < k2) return -1
                if (k1 > k2) return 1

                return 0
            })
            this.setState({
                questionList: reverseSort ? sortedData.reverse() : sortedData,
                sortedBy: column,
                reversedSort: reverseSort
            })
        }
        else if (column === "bookmark") {
            sortedData = questionList.sort((a, b) => {
                let bmA = a["bookmarked"] ? "A": "Z"
                let bmB = b["bookmarked"] ? "A": "Z"
                let qnA = a["questionNumber"] || 0
                let qnB = b["questionNumber"] || 0
                let qnsA = qnA < 10 ? `0${qnA}`: `${qnA}`
                let qnsB = qnB < 10 ? `0${qnB}`: `${qnB}`
                let k1 = `${bmA}-${qnsA}`
                let k2 = `${bmB}-${qnsB}`

                if (k1 < k2) return -1
                if (k1 > k2) return 1

                return 0
            })
            this.setState({
                questionList: reverseSort ? sortedData.reverse() : sortedData,
                sortedBy: column,
                reversedSort: reverseSort
            })
        }
        else {
            sortedData = questionList.sort((a, b): any => {
                return this.sortStr(a, b, column, defaultSort, reverseSort);
            });
            this.setState({
                questionList: reverseSort ? sortedData.reverse() : sortedData,
                sortedBy: column,
                reversedSort: reverseSort
            });
        }
    }

    private sortStr(a: any, b: any, column: string, defaultVal: string, reverseSort: boolean) {
      if(a[column] < b[column]) {
          return -1;
      }
      if(b[column] < a[column]) {
          return 1;
      }
      if (!reverseSort) {
        return a[column] - b[column] || a[defaultVal] - b[defaultVal];
      } else {
        return a[column] - b[column] || b[defaultVal] - a[defaultVal];
      }
    }

    // Use the QA static resources for local development
    // This requires the resources to be deployed in QA
    private getStaticAssetsBaseUrl() {
        let href = window.location.href;
        let url = "https://learn.winwardacademy.com";

        if (href.indexOf("localhost") !== -1 || href.indexOf("qa-learn") !== -1) {
            url = "https://qa-learn.winwardacademy.com";
        }

        return url;
    }

    @boundMethod
    private openExplanation(question) {
      this.simpleTimer.start()

      if (this.isAct() || this.isInterimAssessment()) { 
        let self = this;
        let link = question.videoId

        this.setState({
          videoLink: link,
          interactedQuestion: question
        }, function() {
          const player = videojs('explanation-video');
          player.catalog.getVideo(link, (error, playerVid) => {
              const response = !!error && JSON.parse(error.response);
              if(response && response[0] && response[0].error_code) {
                  console.error(response);
                  alert(response[0].error_code);
              } else {
                  player.catalog.load(playerVid);
                  self.setState({
                    toggleExplanationVideoPopup: !self.state.toggleExplanationVideoPopup,
                  });
              }
          });
        });
      } 
      else {
        let self = this
        let link = question.satExplanation
        let baseUrl = this.getStaticAssetsBaseUrl()
        let fullUrl = getExplanationURL(link, baseUrl)
        self.setState({
          toggleExplanationVideoPopup: !self.state.toggleExplanationVideoPopup,
          interactedQuestion: question,
          satImage: fullUrl,
        })
      }
    }


    /**
     * Users interect with different possibilities in this part, such as 
     * selecting an explanation (either video or infographic (text, image, etc.))
     * 
     * @param resource 
     * @returns 
     */
    private getInteractedLessonAndQuestionInfo(resource: Resource) {
      let {hintLocator, interactedQuestion} = this.state
      let question = resource === Resource.HINT ? hintLocator.questionData: interactedQuestion
      let {questionNumber, lessonId} = question

      return {
        lessonId,
        questionNumber,
      }
    }

    /**
     * In the Practice tests we need to allow multiple entires of the some of the resource
     * because it is not sequential, a user can jump from any place in the PT view,
     * hence the sendUseractivity has a boolean flag is used.
     * @param resource
     * @param allowMultipleEntries
     */
    private sendStatsBasedOnResource = (resource: Resource, allowMultipleEntries: boolean) => {
      let {testName, section} = this.state
      let {lessonId, questionNumber} = this.getInteractedLessonAndQuestionInfo(resource)
      let seconds = this.simpleTimer.diffInSeconds()
      let ended = seconds
      let isSAT = isSatTest(testName)
      let isIA = isInterimAssessment(testName)
      let context = isSAT ? Context.TEST_SAT: isIA ? Context.TEST_IA: Context.TEST_ACT
      let contextId = testName
      let category = getCategoryName(section, isSAT)
      let resourceId = `${lessonId}-Q${questionNumber}`
      let userActivityEx: UserActivityEx = {
          context, contextId, 
          resource, resourceId, 
          category,
          ended
      }
      sendUserActivity(userActivityEx, allowMultipleEntries)
      this.simpleTimer.reset()
    }

    /**
     * Sends the stats for the Practice Test activity for adding/updating a hint entry.
     * 
     */
    private sendStatsForHintEntry = () => {
      this.sendStatsBasedOnResource(Resource.HINT, true)
    }

    /**
     * Sends the stats for the Practice Test activity for explanation (either infographic or video).
     */
    private sendStatsForExplanation = () => {
      let {testName} = this.state
      let satTest = isSatTest(testName)
      let resource = satTest ? Resource.EXPLANATION_INFOGRAPHIC: Resource.EXPLANATION_VIDEO        
      this.sendStatsBasedOnResource(resource, true)
    }

    /**
     * Sends the stats for the Practice Test activity for a question.
     * For the questions, only one entry allowed (i.e. student's first answer)
     */
    private sendStatsForQuestion = () => {
      this.sendStatsBasedOnResource(Resource.QUESTION, false)
    }
    
}
