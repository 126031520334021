import './TutorialCard.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';

import Button from '../../../../components/Button/Button';
import Card from '../../../../components/Card/Card';
import Modal from '../../../../components/Modal/Modal';

interface Props {
  openModal: () => void;
}

interface State {
    showModal: boolean;
}

export default class TutorialCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showModal: false
        };
    }

    public render() {
        const btnStyle = {width: '100%'};
        return (
            <div className="dash-tutorial-container">
                <div className="dash-tutorial-container__bg">
                    <Card title="" titleColor="blue" link="" classTitle="">
                        <div className="tutorial-container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h2 className="tutorial-text">Not sure where <br className="d-none d-xl-block" />to start?</h2>
                                    <h3 className="tutorial-text">Review a <br className="d-md-none d-xl-block" />step-by-step guide</h3>
                                </div>
                            </div>
                            <Button
                              text="REVIEW STEPS"
                              textColor="default"
                              bgColor="white"
                              style={btnStyle}
                              onClick={this.props.openModal}
                            />
                        </div>
                    </Card>
                </div>
            </div>
        );
    }

    
    private toggleModal() {
        this.setState({ showModal: !this.state.showModal });
    }

}
