let mainCache = {}

export function getCachedValue(key: string) {
  return mainCache[key]
}

export function setCacheValue(key: string, value: any) {
  mainCache[key] = value;
}

export function clearCache() {
  mainCache = {}
}

export function showCache() {
  console.log("Debugging Cache:", JSON.stringify(mainCache, null, 2))
}