import * as React from 'react';
import './index.css';
import NewSelectList from '../NewSelectList';
import { TeacherStore, ITeacherStoreData, IPeriod } from '../../../stores/teacher.store';
import { GraduationYearDescriptor, NULL_GRADUATION_YEAR } from '../types';


export interface ISummaryFilter {
  selectedSchoolName?: string
  selectedGraduationYear?: string
  selectedTimeRange?: string
  selectedPeriod?: string
  selectedTestKind?: string
}

export interface Props {
  enableTimeRangeSelection?: boolean;
  enablePeriodSelection?: boolean;
  enableTestKindSelection?: boolean
  onNewSchoolSelected: (school: string) => void;
  onNewGraduationYearSelected: (gradYear: string) => void;
  onNewTimeRangeSelected: (timeRangeType: string) => void;
  onNewPeriodSelected?: (period: string) => void;
  onNewTestKindSelected?: (testKind: string) => void;
}

interface State {
  selectedSchoolName?: string
  selectedGraduationYear?: string
  schools: any[]
  graduationYears: GraduationYearDescriptor[]
  showEmailNotificationModal: boolean
  selectedTimeRange?: string
  selectedPeriod?: string
  selectedTestKind?: string  
  periods: IPeriod[]
}

export default class SummaryFilters extends React.Component<Props, State> {
  unsubscribe

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSchoolName: undefined,
      schools: [],
      graduationYears: [],
      showEmailNotificationModal: false,
      periods: [],
      selectedPeriod: undefined,
      selectedTestKind: "ACT"
    };
  }

  public componentDidMount() {
    TeacherStore.loadGraduationYears()
    TeacherStore.loadSchools()
    this.unsubscribe = TeacherStore.subscribe(d => {
      this.setMainData(d)
    })
    this.setMainData(TeacherStore.data)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  setMainData(data: ITeacherStoreData) {
    let { graduationYears, schools, periods } = data
    this.setState({ graduationYears, schools, periods })
  }

  onNewSchoolSelected(e: any) {
    const newSelectedSchool = e.target.value;
    const { selectedSchoolName } = this.state;
    if (selectedSchoolName !== e.target.value) {
      const finalNewSchool = newSelectedSchool === 'All' ? 
        undefined : newSelectedSchool;
      this.setState({
          selectedSchoolName: finalNewSchool
        }, () => this.props.onNewSchoolSelected(finalNewSchool)
      );
    }
  }

  onNewTimeRangeSelected(newlySelected: string) {
    const { selectedTimeRange } = this.state;
    // TODO: create a type/interface for newlySelected 
    //    (eg all-time | last-week | etc)
    if (selectedTimeRange !== newlySelected) {
      this.setState({
        selectedTimeRange: newlySelected 
        }, () => this.props.onNewTimeRangeSelected(newlySelected)
      );
    }

  }

  onNewGraduationYearSelected(newlySelected: string) {
    let newGraduationYear = newlySelected === 'None provided' ? 
      NULL_GRADUATION_YEAR : newlySelected;
    const { selectedGraduationYear } = this.state;
    if (selectedGraduationYear !== newlySelected) {
      this.setState({ selectedGraduationYear: newGraduationYear }, 
      () => this.props.onNewGraduationYearSelected(newGraduationYear)
      );
    }
  }

  onNewPeriodSelected(newlySelected: string) {
    const { selectedPeriod } = this.state;
    
    if (selectedPeriod !== newlySelected) {
      this.setState({
        selectedPeriod: newlySelected 
        }, () => this.props.onNewPeriodSelected && this.props.onNewPeriodSelected(newlySelected)
      );
    }
  }

  onNewTestKindSelected(newlySelected: string) {    
    this.setState({
      selectedTestKind: newlySelected 
      }, () => this.props.onNewTestKindSelected && this.props.onNewTestKindSelected(newlySelected)
    )
  }

  renderPluralString(studentCount, message: string) {
    if(studentCount !== 1){
      message += 's';
    }
    return studentCount + ' ' + message;
  }

  public render() {
    const { enableTimeRangeSelection, enablePeriodSelection, enableTestKindSelection } = this.props;
    let {schools, graduationYears, periods} = this.state

    return (
      <div className="summary-filters theme-box">
        <h6>VIEW DATA FOR:</h6>
        <div>
          <div className="lesson-summary-select form-group">
            <label className="label" htmlFor="graduation-year-filter">Graduation <span className="lineBreak">Year</span></label>
            <NewSelectList 
              id="graduation-year-filter" 
              selected={this.state.selectedGraduationYear || 'All'}
              onChange={e => this.onNewGraduationYearSelected(e.target.value)}
              options={graduationYears.map(thing => (
                  {
                  display: `${thing.graduationYear} (${this.renderPluralString(thing.studentCount, 'student')})`,
                  value: thing.graduationYear === 'None provided' ? 
                    NULL_GRADUATION_YEAR : thing.graduationYear
                  } 
                  ))
              }
            />
          </div>
          <div className="summary-select">
            <label className="label" htmlFor="school-filter">School / <span className="lineBreak">Organization</span></label>
            <NewSelectList 
              id="school-filter" 
              selected={this.state.selectedSchoolName || 'All'}
              options={schools.map(item => ({ 
                display: `${item.schoolName} (${this.renderPluralString(item.studentCount, 'student')})`, 
                value: item.schoolName
              }))}
              onChange={e => this.onNewSchoolSelected(e)}
            />
          </div>

          {enablePeriodSelection &&
            <div className="summary-select">
              <label className="label" htmlFor="period-filter">Period / <span className="lineBreak">Group</span></label>
              <NewSelectList 
                id="period-filter" 
                selected={this.state.selectedPeriod || 'all'}
                options={periods.map(item => ({ 
                  display: `${item.period} (${this.renderPluralString(item.studentCount, 'student')})`, 
                  value: item.period
                }))}
                onChange={e => this.onNewPeriodSelected(e.target.value)}
              />
            </div>
          }
          
          {enableTimeRangeSelection &&
            <div className="summary-select">
              <label className="label" htmlFor="time-filter">Time <span className="lineBreak">Frame</span></label>
              <NewSelectList
                id="time-filter"
                selected={this.state.selectedTimeRange || 'all-time'}
                onChange={e => this.onNewTimeRangeSelected(e.target.value)}
                options={[
                  { value: 'all-time', display: 'All Time' },
                  { value: 'last-week', display: 'Last Week' },
                  { value: 'last-month', display: 'Last Month' },
                ]}
              />
            </div>
          }

          {enableTestKindSelection &&
          <div className="summary-select">
            <div className="test-kind-selection-container">
              <label className="label" htmlFor="test-kind-filter">Test Score</label>
              <div className="test-kind">
                <input id="test-act" name="test-kind" type="radio" 
                  value="ACT"
                  checked={this.state.selectedTestKind === "ACT"}
                  onChange={e => this.onNewTestKindSelected("ACT")}/> 
                  &nbsp;
                  <label htmlFor="test-act">ACT</label>
                
                <input id="test-sat" name="test-kind" type="radio" 
                  value="SAT"
                  checked={this.state.selectedTestKind === "SAT"} 
                  onChange={e => this.onNewTestKindSelected("SAT")}/>
                  &nbsp; 
                  <label htmlFor="test-sat">SAT</label>
              </div>
            </div>
          </div>
          }
          <div></div>
        </div>
      </div>
    );
  }
}
