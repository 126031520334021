import React, { Component } from 'react'
import { getHumanTime } from "../../../services/utils/date-util"
import CollapsableCard, { CollapsableCardBody, CollapsableCardHeader } from '../../../components/CollapsableCard/CollapsableCard'
import { TonPGraduationYearsView } from './TonPGraduationYearsView'

interface Props {
  school
}

interface State {
  isDetailsVisible: boolean
}

export class TonPSchoolView extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isDetailsVisible: false
    }
  }

  toggleDetails = () => {
    this.setState({isDetailsVisible: !this.state.isDetailsVisible})
  }

  getCardBodyContent = () => {
    let {school} = this.props
    let {summary, graduationYears} = school
    let {lessons, tests, mistakebanks, staysharps} = summary
    let total = lessons + tests + mistakebanks + staysharps

    return (
      <div className="si-tonp-details-school-graduationyears-container">
        <TonPGraduationYearsView graduationYears={graduationYears}/>
      </div>
    )
  }

  render() {
    let {school} = this.props
    let {schoolName} = school

    return (
      <div className="si-tonp-details-school-view">
        <CollapsableCard key={schoolName}>
          <CollapsableCardHeader>
            {schoolName}
          </CollapsableCardHeader>
          <CollapsableCardBody> 
            {this.getCardBodyContent()}
          </CollapsableCardBody>
        </CollapsableCard>
      </div>
    )
  }
}

