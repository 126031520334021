import * as React from 'react';
import HintSubjectHeader from './HintSubjectHeaderInline';
import './SubjectHints.css';
import * as _ from 'lodash';

// Get the summary icons suitable for PDF export (svg graphics are not processed well!)
function getSummaryStyle(section: string, element: string) {
    let resUrl = "/assets/images/icons/reports/"
    let resColor = '#000000'
    switch (section) {
        case "English":
        resUrl += "icon-subject-english.svg"
        resColor = '#FBF7FF'
        break
        case "Math":
        resUrl += "icon-subject-math.svg"
        resColor = '#EFFDFF'
        break
        case
        "Reading":
        resUrl += "icon-subject-reading.svg"
        resColor = '#FFF8EF'
        break
        case "Science":
        resUrl += "icon-subject-science.svg"
        resColor = '#F9FFEF'
        break
        case "School Safety & Security":
          resUrl += 'lock-white.svg'
          resColor = '#b7cbd8'
        break
        default: // This shouldn't happen!
        resUrl += "black_x_mark.png"
    }
    if(element == 'color') {
        return resColor
    } else {
        return resUrl
    }
}

const SubjectHints = ({ subjectSummary }) => ( 
    <div className="subject-hint-section">
        <hr/>
        <HintSubjectHeader name={subjectSummary.category} />
        { subjectSummary.lessonSummaries.length === 0 ? 
            <div className="topic-hint-section">
              <div className="lesson-hint-section--title" style={{backgroundColor: '#f1f3f2'}}>
                <span>No hints saved yet</span>
              </div>
            </div>
          :
          <></>
        }
        {subjectSummary.lessonSummaries.map(lesson => 
          <div 
            key={'lesson-' + lesson.lessonName}
            className="lesson-hint-section"
          >
            <div className={`lesson-hint-section--title border-bottom border-bottom--` + subjectSummary.category.toLowerCase()} style={{backgroundColor: getSummaryStyle(subjectSummary.category, 'color')}}>
                {lesson.lessonName}
            </div>
          {lesson.topicSummaries.map(
              topic => (
                <div className="topic-hint-section">
                  <div
                      key={'topic-' + topic.topicName}
                      className="topic-hint-section--title topic-hint-row"
                  >
                  <span>{topic.topicName}</span>
                  </div>
                  <ul className="section-hint-list">
                    {
                      topic.hints.map(hint =>
                          <li className="topic-hint-section--content topic-hint-row" key={'li-' + hint.questionId}>{hint.hint}</li>
                      )
                    }
                  </ul>
                </div>
                )
              )
          }
          </div>
        )
        }
    </div>
);

export default SubjectHints;
