import './ReadyToLearn.css';

import * as React from 'react';

import Button from '../../../../components/Button/Button';
import Card from '../../../../components/Card/Card';

interface Props {
    topText?: string;
    bottomText: string;
    btnText: string;
    topStyle?: string;
    bottomStyle?: string;
    btnClick: (e: any) => void;
}

const ReadyToLearn: React.SFC<Props> = (props: Props) => {
    const topText = props.topText ? props.topText : '';
    const topStyle = props.topStyle ? props.topStyle : '';
    const bottomText = props.bottomText ? props.bottomText : '';
    const bottomStyle = props.bottomStyle ? props.bottomStyle : '';

    return (
        <div className="ready_to_learn_stock__wrapper">
            <Card title="" titleColor="white" link="" classTitle="">
                <div className="ready_to_learn_stock-container">
                    <div>
                        {topText && <p className={'ready_to_learn_first-text-container ' + topStyle}>{topText}</p>}
                        <p className={'ready_to_learn_second-text-container ' + bottomStyle}>{bottomText}</p>
                    </div>
                    <div className="card-divider"></div>
                    <Button
                        text={props.btnText}
                        textColor="white"
                        bgColor="blue"
                        onClick={props.btnClick}
                    />
                </div>
            </Card>
        </div>
    );
};

ReadyToLearn.displayName = 'ReadyToLearn';

export default ReadyToLearn;
