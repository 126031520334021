import {
    getPracticeTestSummary
} from '../services/practice-test-scoring/practice-test-service';


export interface PracticeTestState {
}

const defaultState: Partial<PracticeTestState> = {};

export const LOAD_PRACTICE_TEST_BY_NAME = 'practiceTests/LOAD_PRACTICE_TEST_BY_NAME';
export const LOAD_PRACTICE_TEST_BY_NAME_SUCCESS = 'practiceTests/LOAD_PRACTICE_TEST_BY_NAME_SUCCESS';

// DISPATCH
export function getPracticeTestByName(testName: string) {
    return (dispatch) => {
        return getPracticeTestSummary(testName)
            .then((response) => {
                // console.log(response);
                dispatch(loadPracticeTestSucceeded(response));
            });
    };
}

// ACTION
function loadPracticeTestSucceeded(testData: any) {
    return {
        type: LOAD_PRACTICE_TEST_BY_NAME_SUCCESS,
        payload: { testData }
    };
}

// REDUCER
export default function(state: Partial<PracticeTestState> = defaultState, action: any) {
    switch(action.type) {
        case LOAD_PRACTICE_TEST_BY_NAME_SUCCESS: {
            return { ...state, testData: action.payload };
        }
        default: {
            return { ...state };
        }
    }
}
