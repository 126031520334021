import * as React from 'react'
import jp from "jsonpath"
import { MistakeBankOverview } from '../../../services/elearn/mistake-bank-types'
import { LessonInfo } from '../../../services/elearn/lesson-types'
import { HintsInfo, TestHintsInfo } from '../../../services/elearn/hint-types'
import ProgressBar from '../../../components/ProgressBar/ProgressBar';

import "./MistakeBankSummary.css"

interface Props {
  mistakeBankOverviews: MistakeBankOverview[]
  lessons: LessonInfo[]
  hints: HintsInfo[]
  testHints: TestHintsInfo[]
}

interface State {}
export default class MistakeBankSummary extends React.Component<Props, State> {

  getTopicsMasteredInfo = (mbos: MistakeBankOverview[]) => {
    let topicMasteredKey = "TOPIC MASTERED"
    let nofTopics = mbos.length
    let nofTopicsMastered = mbos.reduce((acc: number, cur: MistakeBankOverview) => {
      if (cur.topicReviewStatus == topicMasteredKey) {
        acc++
      }
      return acc
    }, 0)

    return (
      <>
        {nofTopicsMastered}/{nofTopics}
      </>
    )
  }

  getNofQuestionsMissed = (mbos: MistakeBankOverview[]) => {
    let sum = 0
    return sum
  }
  
  getNofQuestionsAdded = (mbos: MistakeBankOverview[]) => {
    let sum = mbos.reduce((acc, cur) => {
      acc += cur.numManualMistakeEntries
      return acc
    }, 0)
    return sum
  }

  getNofQuestionsReviwed = (mbos: MistakeBankOverview[]) => {
    let sum = mbos.reduce((acc, cur) => {
      acc += cur.numQuestionsReviewed
      return acc
    }, 0)
    return sum
  }
  
  // Note that there are two sources for hints: 
  //  * Lessons (includeing Stay Sharp) 
  //  * Tests (ACT|SAT|IA)
  getNofHintsAdded = (hintsInfo: HintsInfo[], testHintsInfo: TestHintsInfo[]) => {
    // Using JsonPath to extract corresponing hints...
    let hints = jp.query(hintsInfo, `$..hint`)
    let testHints = jp.query(testHintsInfo, `$..text`)
    let nofHints = hints && hints.length | 0
    let nofTestHints = testHints && testHints.length | 0
    let totalNumberOfHints = nofHints + nofTestHints

    return totalNumberOfHints
  }

  getNofHintsOnIncorrect(lessons: LessonInfo[]) {
    let sum = lessons.reduce((acc, cur) => {
      let nofMistakes = cur.totalCompletedQuestions - cur.totalCorrectPostLessonQuestions
      let hintsPercentage = cur.totalHintsWrittenOutOfIncorrectQuestions
      let nofHintsUsed = Math.round((nofMistakes*hintsPercentage/100.0))
      acc += nofHintsUsed
      return acc
    }, 0)
    return sum
  }

  getNofQuestionsSaved(mbos: MistakeBankOverview[]) {
    let sum = mbos.reduce((acc, cur) => {
      acc += cur.numManualMistakeEntries
      return acc
    }, 0)
    return sum
  }

  getNofTotalQuestionsInMistakeBank(mbos: MistakeBankOverview[]) {
    let sum = mbos.reduce((acc, cur) => {
      acc += cur.numQuestionsMissed + cur.numManualMistakeEntries
      return acc
    }, 0)
    return sum
  }

  render() {
    let { mistakeBankOverviews,lessons, hints, testHints } = this.props
    let mbosMap = { 
      English: { mbos: []},
      Math: { mbos: []},
      Reading: { mbos:[] },
      Science: { mbos: [] }
    }

    let prepMap = () => {
      mistakeBankOverviews.forEach((mbo: MistakeBankOverview) => {
        let key = mbo.categoryName
        let item = mbosMap[key]
        if (item) {
          item.mbos.push(mbo)
        }
      })
    }

    prepMap()

    let nofTotalQuestions = this.getNofTotalQuestionsInMistakeBank(mistakeBankOverviews)
    let nofHintsAdded = this.getNofHintsAdded(hints, testHints)
    let nofQuestionsReviewed = this.getNofQuestionsReviwed(mistakeBankOverviews)
    let masteredTopicsEnglish = this.getTopicsMasteredInfo(mbosMap["English"].mbos)
    let masteredTopicsMath = this.getTopicsMasteredInfo(mbosMap["Math"].mbos)
    let masteredTopicsReading = this.getTopicsMasteredInfo(mbosMap["Reading"].mbos)
    let masteredTopicsScience = this.getTopicsMasteredInfo(mbosMap["Science"].mbos)
    const imagePath = '/assets/images/icons/reports';

    return (
      <div className="mistake-bank-summary">
        <div className="student-reports-header">
        <h2><img alt="" width="78" height="78" className="mb-2 mr-2" src={imagePath + '/report-header-icon--mistakeBank.svg'} /> Mistake Bank</h2>
          <hr/>
        </div>
        <div className="report-content" style={{marginBottom: 50}}>
          <div className="row">
            <div className="col-md-5 report-mistake-bank-overview">
              <div className="label">Progress</div>
              <div className="mistakeBank-table-overview report-mistake-bank-table">
                <div className="mb-3"><strong className="statNumber">{nofTotalQuestions}</strong><span className="statText">Total Questions</span></div>
                <div className="mb-3"><strong className="statNumber">{nofQuestionsReviewed}</strong><span className="statText">Reviewed Questions</span></div>
                <div className="mb-2"><strong className="statNumber">{nofHintsAdded}</strong><span className="statText">Hints Added</span></div>
              </div>
            </div>
            <div className="col-md-7 report-mistake-bank-details">
              <div className="label">Topics Mastered</div>
              <div className="report-mistake-bank-table row no-gutters">
                <div className="pr-4">
                  <div className="mb-3 statText">
                    <span className="statText--imgWrap">
                      <img alt="" height="27"width="auto" src="/assets/images/icons/english-icon@1,5x.png" />
                    </span>
                    English
                  </div>
                  <div className="mb-3 statText">
                    <span className="statText--imgWrap">
                      <img alt="" height="27"width="auto" src="/assets/images/icons/math-icon@1,5x.png" />
                    </span>
                    Math
                  </div>
                  <div className="mb-3 statText">
                    <span className="statText--imgWrap">
                      <img alt="" height="27"width="auto" src="/assets/images/icons/reading-icon@1,5x.png" />
                    </span>
                    Reading
                  </div>
                  <div className="mb-2 statText">
                    <span className="statText--imgWrap">
                      <img alt="" height="27"width="auto" src="/assets/images/icons/science-icon@1,5x.png" />
                    </span>
                    Science
                  </div>
                </div>
                <div className="pr-4 text-right">
                  <div className="mb-3"><strong className="statNumber">{masteredTopicsEnglish}</strong></div>
                  <div className="mb-3"><strong className="statNumber">{masteredTopicsMath}</strong></div>
                  <div className="mb-3"><strong className="statNumber">{masteredTopicsReading}</strong></div>
                  <div className="mb-2"><strong className="statNumber">{masteredTopicsScience}</strong></div>
                </div>
                <div className="col column-progress-bar">
                  <div className="mb-3 progress-barStat--english" style={{color: "#9d59cf"}}>
                    <ProgressBar
                      current={masteredTopicsEnglish.props.children[0]}
                      total={masteredTopicsEnglish.props.children[2]}
                      size="large"
                      barCurrentColor={'purpleSolid'}
                      barTotalColor={'fadePurple'}
                    />
                  </div>
                  <div className="mb-3 progress-barStat--math" style={{color: "#3FC3D2"}}>
                    <ProgressBar
                      current={masteredTopicsMath.props.children[0]}
                      // current={7}
                      total={masteredTopicsMath.props.children[2]}
                      size="large"
                      barCurrentColor={'blueSolid'}
                      barTotalColor={'fadeBlue'}
                    />
                  </div>
                  <div className="mb-3 progress-barStat--reading" style={{color: "#f29000"}}>
                    <ProgressBar
                      current={masteredTopicsReading.props.children[0]}
                      // current={1}
                      total={masteredTopicsReading.props.children[2]}
                      size="large"
                      barCurrentColor={'orangeSolid'}
                      barTotalColor={'fadeOrange'}
                    />

                  </div>
                  <div className="mb-3 progress-barStat--science" style={{color: "#8ac22d"}}>
                    <ProgressBar
                      current={masteredTopicsScience.props.children[0]}
                      // current={.5}
                      total={masteredTopicsScience.props.children[2]}
                      size="large"
                      barCurrentColor={'greenSolid'}
                      barTotalColor={'fadeGreen'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
