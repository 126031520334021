import './StepByStepModal.css';

import * as React from 'react';
import Modal from '../../../../components/Modal/Modal';
import { boundMethod } from 'autobind-decorator';

interface Props {
    onClose: () => void;
    onModalClose: () => void;
    show: boolean;
}

interface State {
  stepData: Array<any>;
  showStep: number;
}

export class StepByStepModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showStep: 0,
      stepData: [
        {
          stepNumber: '1',
          imageSource: 'chart-icon.png',
          firstLine: 'Complete baseline',
          secondLine: 'questions on the topic',
          buttonText: 'NEXT STEP',
        },
        {
          stepNumber: '2',
          imageSource: 'paper-icon.png',
          firstLine: 'Download & print',
          secondLine: 'the lesson handout',
          buttonText: 'NEXT STEP',
        },
        {
          stepNumber: '3',
          imageSource: 'video-icon.png',
          firstLine: 'Follow along with',
          secondLine: 'the video lesson',
          buttonText: 'NEXT STEP',
        },
        {
          stepNumber: '4',
          imageSource: 'math-page-icon.png',
          firstLine: 'Complete the',
          secondLine: 'multiple choice questions',
          buttonText: 'NEXT STEP',
        },
        {
          stepNumber: '5',
          imageSource: 'pie-chart-icon.png',
          firstLine: 'Receive feedback',
          secondLine: 'on your performance',
          buttonText: 'NEXT STEP',
        },
        {
          stepNumber: '6',
          imageSource: 'check-icon.png',
          firstLine: 'Review mistakes in your',
          secondLine: 'personal Mistake Bank',
          buttonText: 'NEXT STEP',
        },
        {
          stepNumber: '7',
          imageSource: 'report-icon.png',
          firstLine: 'Generate a personalized',
          secondLine: 'study guide from your hints',
          buttonText: 'READY FOR SUMMARY',
        },
      ]
    };
  }

  public render() {
    const {showStep, stepData} = this.state;
    return (
          <Modal onClose={this.onClose} className="step-by-step-modal" show={this.props.show} width={'710px'}>
              <div className="step-by-step-modal-header">
                  <h2>Step-by-Step Lesson Overview</h2>
                  <button type="button" className="modal-close" title="Close Modal" aria-label="Close" onClick={this.onModalClose}>
                    <img
                      alt=""
                      className="modal-icon"
                      src={`/assets/images/icons/v2/ico-modal-close-white.svg`}
                      width="24"
                      height="24"
                    />
                  </button>
              </div>
              <div className="step-by-step-modal-body">
                  <div className="row mx-0">
                      <div className="col-lg-12 margin-bottom">
                        <h6>STEP {showStep + 1}</h6>
                        <img
                          alt=""
                          src={`/assets/images/dashboard/home/${stepData[showStep].imageSource}`}
                        />
                        <h3>{stepData[showStep].firstLine}</h3>
                        <h3>{stepData[showStep].secondLine}</h3>
                      </div>
                  </div>
                  <button
                    className="btn btn--text-blue btn--bg-white"
                    onClick={this.onNext}
                  >{stepData[showStep].buttonText}
                  </button>
              </div>
          </Modal>
    );
  }

  
  private onClose() {
    this.props.onClose();
    this.setState({
      showStep: 0
    });
  }

  @boundMethod
  private onModalClose() {
    this.props.onModalClose();
  }

  
  @boundMethod
  private onNext() {
    let currentNumber = this.state.showStep;
    const stepData = this.state.stepData;
    const nextNumber = ++currentNumber;
    if (nextNumber > stepData.length - 1) {
      this.onClose();
    } else {
      this.setState({
        showStep: nextNumber
      });
    }
  }
}
