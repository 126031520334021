import './TableCell.css';

import * as React from 'react';

import { Content } from '../../../../types/types';

interface Props {
    content: Content;
    width: any;
    style?: React.CSSProperties;
    isHighlighted?: boolean;
    colSpan?: number;
}

const TableCell: React.SFC<Props> = (props) => {
    let style: React.CSSProperties = { ...props.style, width: props.width };
    // if (!props.width) {
    //     style = { flex: '1 1 0' };
    // }
    return (
        <td
            className={`table-cell ${props.isHighlighted ? 'highlight-cell' : ''}`}
            style={style}
            {...(props.colSpan ? { colSpan: props.colSpan } : {})}  // Conditionally add colSpan attribute
        >
            {typeof props.content === 'function' ? props.content() :
                props.content === 'N/A' ?
                    <div className="not-applicable">
                        N/A
                    </div>
                    :
                    <>
                        {props.content}
                    </>
            }
        </td>
    );
};

TableCell.displayName = 'TableCell';
TableCell.defaultProps = {
    style: {}
};

export default TableCell;
