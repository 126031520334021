import * as React from 'react'
import Modal from '../../components/Modal/ModalEmail'
import ConversionModal from '../../components/ConversionModal/ConversionModal'
import SummaryFilters from './SummaryFilters'
import { sendStudentReport } from '../../services/elearn/lesson-rest-interface'
import { TeacherStore, ITeacherStoreData } from '../../stores/teacher.store'
import { GraduationYearDescriptor, StudentData } from './types'
import TimeOnPlatformLessonsView from './TimeOnPlatform/TimeOnPlatformLessonsView'
import { debounce } from '../../services/utils/debounce'
import './LessonSummaryOverview.css'

export interface Props {
}

interface State {
  selectedSchoolName?: string
  selectedGraduationYear?: string
  schools: string[]
  graduationYears: GraduationYearDescriptor[]
  showEmailNotificationModal: boolean
  showScoreConversion: boolean
  selectedTimeRange?: string
  selectedTestKind?: string
  selectedPeriod?: string
  studentsData: StudentData[]
}

export default class LessonSummaryOverview extends React.Component<Props, State> {
  unsubscribe

  constructor(props: Props) {
    super(props)
    this.state = {
      selectedSchoolName: undefined,
      schools: [],
      graduationYears: [],
      showEmailNotificationModal: false,
      showScoreConversion: false,
      selectedTestKind: "ACT",
      studentsData: []
    }
  }

  public componentDidMount() {
    this.unsubscribe = TeacherStore.subscribe(d => {
      this.debouncedSetMainData(d)
    })
    this.setMainData(TeacherStore.data)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  setMainData(data: ITeacherStoreData) {
    this.setState({ 
      studentsData: data.students
    })
  }

  debouncedSetMainData = debounce(d => this.setMainData(d))

  sendLessonReport() {
    sendStudentReport();
    this.setState({
      showEmailNotificationModal: true
    });
  }

  onNewSchoolSelected(selected: string) {
    this.setState({selectedSchoolName: selected === 'All' ? undefined : selected})
  }

  onTimeRangeSelected(selected: string) {
    this.setState({selectedTimeRange: selected })
  }

  onPeriodSelected(selected: string) {
    this.setState({selectedPeriod: selected})
  }

  onTestKindSelected(selected: string) {
    this.setState({selectedTestKind: selected})
  }

  onGraduationYearSelected(selected: string) {
    this.setState({selectedGraduationYear: selected === 'All' ? undefined : selected})
  }

  public render() {
    const { studentsData, selectedSchoolName, selectedGraduationYear, selectedTimeRange, selectedTestKind, selectedPeriod } = this.state
    let selections = {selectedSchoolName, selectedGraduationYear, selectedTimeRange, selectedTestKind, selectedPeriod}

    return (
        <div className="lesson-overview">
          <SummaryFilters 
            enableTimeRangeSelection={true}
            enableTestKindSelection={true}
            enablePeriodSelection={true}
            onNewGraduationYearSelected={(gradYear) => this.onGraduationYearSelected(gradYear)}
            onNewSchoolSelected={(schoolSel) => this.onNewSchoolSelected(schoolSel)}
            onNewTimeRangeSelected={(timeRange) => this.onTimeRangeSelected(timeRange)}
            onNewTestKindSelected={(testKind) =>this.onTestKindSelected(testKind)}
            onNewPeriodSelected={(period) => this.onPeriodSelected(period)}
          />
          <div className="individual-data-section--2col">
            <h2 className="h3 individual-data-title">Lesson Progress & Subject Summary</h2>
            <div className="title-sidebar">
              <a title="View Conversion Table" className="iconLink--left img-rel " href="#" onClick={() => this.setState({ showScoreConversion: true})}>
              <img src="/assets/images/icons/v2/ico-modal.svg" width="11" height="11" alt="" />
                <span className="label def">View Conversion Table</span>
              </a>
              <a title="Email Report" href="#" className="iconLink--left img-rel ml-btn" onClick={() => this.sendLessonReport()}>
              <img src="/assets/images/icons/v2/ico-email.svg" width="14" height="11" alt="" />
                <span className="label def">Email Report</span>
              </a>
            </div>
          </div>
          <hr className="half" />

          <div className="time-data-warning">Please note that all time data on this page were collected starting on 2/22/2022.</div>

          <TimeOnPlatformLessonsView selections={selections} studentsData={studentsData}/> 

          <ConversionModal 
            show={this.state.showScoreConversion}
            onClose={() => this.setState({ showScoreConversion: false})}
          />
          
          <Modal 
            show={this.state.showEmailNotificationModal}
            onClose={() => this.setState({ showEmailNotificationModal: false })}
          >
            <div className="lesson-summary-email-notification-modal">
              <h3>Lesson Report Is On Its Way</h3>
              <p>
              The full lesson report for your students is on its 
              way and will arrive via email in the next 2-3 minutes.
              </p>
            </div>
          </Modal>
        </div>
    )
  }
}
