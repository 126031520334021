import './styles.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as TagManager from 'react-gtm-module';

import Routes from './routes';
import polyfill from './services/polyfill/polyfills';

// import registerServiceWorker from './services/registerServiceWorker';

polyfill();

(window as any).zenscroll = require('zenscroll');

const MathJax = (window as any).MathJax;
MathJax.Hub.Config({
    messageStyle: 'none',
    SVG: { linebreaks: { automatic: true } }
});
MathJax.Hub.setRenderer('SVG');

const tagManagerArgs = {
    gtmId: 'GTM-N554BF4'
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    
    <Routes />,
    document.getElementById('root') as HTMLElement
);

// registerServiceWorker();
