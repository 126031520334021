import { createRequestForPDFResource, createRequestForPDFResource2, ignoreJsonError, createGenericAuthRequest } from '../request/request-utils'

const request1 = createRequestForPDFResource(process.env.REACT_APP_ELEARN_URL || '/')
const request2 = createRequestForPDFResource2(process.env.REACT_APP_ELEARN_URL || '/')
const request3 = createGenericAuthRequest(process.env.REACT_APP_ELEARN_URL || '/')

export function downloadPDF(path) {
    return request1(`/resources/pdf?resourcePath=${path}`)
        .catch(ex => {
            console.log("Error: ", ex)
            alert("Cannot get PDF file: " + path)
        })
} 

// This will open the PDF file in a new tab!
export function openPDF(path) {
    return request2(`/resources/${path}`)
        .then(res => {
            let url = res 
            let elmA = document.createElement("a")
            elmA.href = url
            elmA.target = "_blank"
            document.body.appendChild(elmA) 
            elmA.click()
            elmA.remove()  
        })
        .catch(ex => {
            console.log("Error: ", ex)
            alert("Cannot open PDF file: " + path)
        })
} 

export function downloadAllPDFs() {
    alert("Will download all PDFs, not working yet!")
}


export async function getStaySharpWorksheets() {
    return request3("/resources/staysharpworksheets")
        .then(res => {
            return res
        })
        .catch(ex => {
            console.error("Error in getStaySharpWorksheets: ", ex)            
        })
}

export async function getDemoVideos() {
    return request3("/resources/demovideos")
        .then(res => {
            return res
        })
        .catch(ex => {
            console.error("Error in getDemoVideos: ", ex)            
        })
}