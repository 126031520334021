import './NavbarLink.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';

import Button from '../../components/Button/Button';
import { LocationDescriptor } from 'history';
import Modal from '../Modal/Modal';
import { NavLink } from 'react-router-dom';

interface Props {
    img: string;
    nav: LocationDescriptor;
    title: string;
    collapsed?: boolean;
    comingSoon?: boolean;
    prModal?: boolean;
    frModal?: boolean;
}

interface State {
    showPtModal: boolean;
    showFrModal: boolean;
}

export default class NavbarLink extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showPtModal: false,
            showFrModal: false
        };
    }

    public render() {

        let buttonColor = 'blue';
        let textColor = 'white';
        let widthBtn = 'pt-modal-btn';
        const linkContent = [
            (
                <div className="navbar-link__img-container" key="div">
                    <img className="navbar-link__img" src={this.props.img} alt="" />
                </div>
            ),
            !this.props.collapsed && <span key="span" className="navbar-link__title">{this.props.title}</span>
        ];

        return (
            <div className="navbar-link">

                <Modal className="heading-banner-pt modal" show={this.state.showPtModal} onClose={this.togglePtModal}>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <h2 className="h1 cushion-element-pt">Practice Tests (coming soon!)</h2>
                        </div>
                        <div className="body-pt container-fluid coverBottom-pt">
                            <div className="cushion-heading-pt">
                                <h2 className="dark-blue-text-pt">Here's an overview of the Practice Tests</h2>
                                <h2 className="dark-blue-text-pt">feature currently in development:</h2>
                            </div>
                            <p className="black-text-pt">
                                    We are currently developing a digital scantron that proctors
                                your practice tests, times you, scores the tests, and breaks down what you missed by lesson and
                                by topic. It’s our top priority to launch this feature next, so we’ll notify you the second 
                                it’s ready.
                            </p>
                            <p className="black-text-pt">
                                    Also, great news! Winward Academy received special 
                                permission from ACT to film explanation videos out of its published book of 3 real practice
                                tests. Dr. Winward is busy filming those videos, so they’re ready to go the second this 
                                feature launches.
                            </p>
                            <p className="black-text-pt">
                                    In the interim, we highly recommend you see the 
                                Collegeboard and ACT websites for practice tests. They have instructions for how 
                                to score the exams.
                            </p>
                            <Button
                                text="GOT IT!"
                                textColor={textColor as any}
                                bgColor={buttonColor as any}
                                className={widthBtn as any}
                                onClick={this.togglePtModal}
                            />
                        </div>
                    </div>
                </Modal>
                <Modal className="heading-banner-fr modal" show={this.state.showFrModal} onClose={this.toggleFrModal}>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <h1 className="cushion-element-fr">Friends (coming soon!)</h1>
                        </div>
                        <div className="body-fr container-fluid coverBottom-fr">
                            <div className="cushion-heading-fr">
                                <h2 className="dark-blue-text-fr">Here's an overview of the Friends feature </h2>
                                <h2 className="dark-blue-text-fr">currently in development:</h2>
                            </div>
                            <p className="black-text-fr">
                                A great feature we’re working to finalize will allow you to add friends with whom 
                                you like to study. Then, when you’re reviewing missed questions in your Mistake Bank,
                                 we will let you know which of your friends got the question right. So, if you need a 
                                 little extra support, you’ll know whom to ask!
                            </p>
                            <Button
                                text="GOT IT!"
                                textColor={textColor as any}
                                bgColor={buttonColor as any}
                                className={widthBtn as any}
                                onClick={this.toggleFrModal}
                            />
                        </div>
                    </div>
                </Modal> 
            {this.props.comingSoon ?
                    this.props.title === 'Practice Tests' ? <button onClick={this.togglePtModal}>{linkContent}</button> : <button onClick={this.toggleFrModal}>{linkContent}</button> :
                    // <NavLink to={this.props.nav} title={this.props.collapsed ? this.props.title : ''} activeClassName="selectedLink">{linkContent}</NavLink>
                    <NavLink to={this.props.nav} title={this.props.title} activeClassName="selectedLink">{linkContent}</NavLink>
            }
            </div>
        );
    }

    
    private togglePtModal() {
        this.setState({ showPtModal: !this.state.showPtModal });
    }

    
    private toggleFrModal() {
        this.setState({ showFrModal: !this.state.showFrModal });
    }
}
