import * as React from 'react';

import StaySharpMCPage, { DispatchProps, Props, StoreProps } from './StaySharpMCPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    addQuestionToMistakeBank,
    getCurrentLessonContent,
    /* incrementQuestionsAnswered, */
    /* setSSCurrentQuestion */
} from '../../stores/lesson.store';

import { AppState } from '../../store';
import { connect } from 'react-redux';
import { getCurrentLesson } from '../../stores/lesson.store';

const mapStateToProps = ({ lesson }: AppState) => ({
    lesson: getCurrentLesson(lesson),
    currentLessonId: lesson.currentLessonId,
    currentQuestionId: getCurrentLessonContent(lesson)
});

const mapDispatchToProps = { 
  // AGAIN SEE THE MCPAGE compoenent for the commentary on this
  /* setSSCurrentQuestion, */ 
  /* incrementQuestionsAnswered, */ 
  addQuestionToMistakeBank };

// tslint:disable-next-line:max-line-length
const ConnectedStaySharpMCPage = connect<StoreProps, DispatchProps, Props, AppState>(mapStateToProps, mapDispatchToProps)(StaySharpMCPage);

const StaySharpMCPageContainer = (props: RouteComponentProps<any> & Props) => {
    const handleExitLesson = () => props.history.push(`${process.env.REACT_APP_BASE_URL}app/stay-sharp`);
    return <ConnectedStaySharpMCPage handleExitLesson={handleExitLesson} {...props} />;
};

// tslint:disable-next-line:max-line-length
type FinalProps = Pick<Props, 'lessonId' | 'topicId' | 'staySharpData' | 'onComplete' | 'setLessonComplete' | 'handleExitLesson' | 'questionGroup' | 'shouldShowHintPopup' | 'pdfPrintout'>;
export default withRouter(StaySharpMCPageContainer);
