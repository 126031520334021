import './ContextualTitle.css';

import * as React from 'react';

interface Props {
    title: string;
    preText?: string;
    postText?: string;
    className?: string;
    linkText?: string;
    isTeacher?: boolean;
    showPostText?: boolean;
}

const ContextualTitle: React.SFC<Props> = (props) => {
    const { title, preText, postText, className, linkText = '', showPostText = true, isTeacher = false} = props;
    return (
        <div className={`contextual-title ${className}`}>
            {preText && <h4>{preText}</h4>}
            <h1>{title}</h1>
            {postText && showPostText && <h4>{postText}</h4>}
            {linkText && !isTeacher && 
            <h4><a target="_blank" href={linkText}>Prefer to complete the multiple choice by hand?</a></h4>}
            {linkText && isTeacher && 
            <h4><a target="_blank" href={linkText}>Prefer to print the multiple choice for students?</a></h4>}
        </div>
    );
};

ContextualTitle.defaultProps = {};
ContextualTitle.displayName = 'ContextualTitle';

export default ContextualTitle;
