import * as React from 'react';

import ReportSection from '../ReportSection';
import ReportTableHeader from '../ReportTableHeader';

import './index.css';

import { StaySharpReportSummary } from '../../services/elearn/lesson-types';
import { ITimeOnPlatform, getStaySharpTimeSummaryForAllStudents } from '../../scenes/TeacherPortal/TimeOnPlatform/top-lesson-processor';
import { getHumanTime } from '../../services/utils/date-util';

export interface Props {
  summaryData: StaySharpReportSummary
  timeOnPlatformInfo: ITimeOnPlatform[]
}

function formatTableHeader(first: string, second: string, third: string) {
  if( third == '') {
    return (   <div><div>{first}</div><div>{second}</div></div>  )
  } else {
    return (
      <div className="th-tooltip">
          <div>{first}</div>
          <div>{second} </div>
          <span className="th-tooltip-text">{third}</span>
        </div>
    );
  }
}

const headers = [
  formatTableHeader('Topics', 'Started', 'Indicates the number of topics started.'),
  formatTableHeader('Topics', 'Completed', 'Indicates the number of topics completed.'),
  formatTableHeader('Topics', 'Updated', 'Indicates the number of topics with progress (they were already started and not finished)'),
  formatTableHeader('Completed', 'Accuracy', 'Calculated as the number of correct questions divided by the number of questions answered'),
  formatTableHeader('Total', 'Accuracy', 'Calculated as the number of correct questions divided by the total number of questions in the lesson'),
  formatTableHeader('Hints on', 'Incorrect', 'Reflects what percent of missed questions students write themselves problem solving hints'),
  formatTableHeader('Review', 'Time', 'Reflects the total time students answered Stay Sharp questions'),
]

const StaySharpSummary: React.SFC<Props> = ({ summaryData, timeOnPlatformInfo }) => {
  const { categoryName } = summaryData;
  let genericCategoryName = summaryData.categoryName;

  let staySharpCategoricalSummary = getStaySharpTimeSummaryForAllStudents(timeOnPlatformInfo, categoryName)
  let totalReviewTime = getHumanTime(staySharpCategoricalSummary.timeOnReviewTotal, false, true).text
 
  return (
    <ReportSection sectionName={categoryName}>
      <div className="lesson-summary-table-wrapper">
        <table className="lesson-summary-table">
          <ReportTableHeader category={genericCategoryName}>
            {headers.map((headerName, index) => <th key={`th-${genericCategoryName}-${index}`}>{headerName}</th>)}
          </ReportTableHeader>
          <tbody>
            <tr key={`tr-${categoryName}`}>
              <td style={{width: "14%"}}>{summaryData.topicsStarted}</td>
              <td style={{width: "14%"}}>{summaryData.topicsCompleted}</td>
              <td style={{width: "14%"}}>{summaryData.topicsUpdated}</td>
              <td style={{width: "14%"}}>{summaryData.topicsCompletedPercentAccuracy}%</td>
              <td style={{width: "14%"}}>{summaryData.topicsTotalPercentAccuracy}%</td>
              <td style={{width: "14%"}}>{summaryData.percentHintsOnIncorrectQuestions}%</td>
              <td style={{width: "14%"}}>{totalReviewTime}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ReportSection>
  )
}

export default StaySharpSummary;
