import './HeaderDistrict.css';
import * as React from 'react';
import { Image } from "../../../../components/Image"

interface HeaderDistrictProps {
    className?: string;
    districtName: string
    districtIcon: string
}

interface HeaderDistrictState {
}


const FALLBACK_ICON_URL = "/assets/districts/default-district-logo.png"

export class HeaderDistrict extends React.Component<HeaderDistrictProps, HeaderDistrictState> {
    constructor(props) {
        super(props)
    }

    /**
     * Will render the district header. If the image is not found then we will render the default icon!
     * The images for the districts should be upladed to WA S3 bucket in AWS
     * S3 location: https://s3.console.aws.amazon.com/s3/buckets/wa-learning-public
     * This is mapped to https://pub.winwardacademy.com via DNS setup
     * "districtIcon" is either an absolute URL or image name (e.g. allentown-logo.png)
     * 
     * @returns rendered district header
     */
    render() {
        let s3BucketUrl = "https://pub.winwardacademy.com/assets/districts" // OT: Using WA AWS S3 public bucket
        let {districtIcon, districtName} = this.props 
        let discrictIconUrl = districtIcon.indexOf("http") !== -1 ? districtIcon: `${s3BucketUrl}/${districtIcon}`        
        let fallbackIconUrl = FALLBACK_ICON_URL
        let altInfo = `${districtName} logo`

        return (
            <div className="header--district">            
                <div className="logo">
                    <Image src={discrictIconUrl} fallbackSrc={fallbackIconUrl} alt={altInfo} />
                </div>
                <div className="title">
                    <h1 className="mb-0">{districtName}</h1>
                </div>
            </div>
        )
    }
}

