import './DiagnosticTimingRow.css';

import * as React from 'react';

interface Props {
    icon: string;
    title: string;
    numMinutes: string;
    numExtendedMinutes: string;
    color: string;
    textColor?: string;
    sat?: boolean;
}

const DiagnosticTimingRow: React.SFC<Props> = (props) => {
    return (
        <div className="row no-gutters flex-no-wrap" style={{backgroundColor: props.color, borderRadius: '5px', marginBottom: '15px'}}>
            <div className="col-1 d-flex hidden-sm-down">
                <img alt="" src={props.icon} className="pd-5 d-flex align-items-center justify-content-center" />
            </div>
            <div className={props.sat ? 'col-4 col-md-5' : 'col-4 col-md-3'}>
                <p 
                    className="diagnostic-contents" 
                    style={{color: props.textColor ? props.textColor : `white` }}
                >
                    {props.title}
                </p>
            </div>
            <div className={props.sat ? 'col-4 col-md-3' : 'col-4'}>
                <p 
                    className="diagnostic-contents" 
                    style={{color: props.textColor ? props.textColor : `white` }}
                >
                    {props.numMinutes} Minutes
                </p>
            </div>
            <div className={props.sat ? 'col-4 col-md-3' : 'col-4'}>
                <p 
                    className="diagnostic-contents" 
                    style={{color: props.textColor ? props.textColor : `white` }}
                >
                    {props.numExtendedMinutes} Minutes
                </p>
            </div>
        </div>
    );
};

DiagnosticTimingRow.displayName = 'DiagnosticTimingRow';
export default DiagnosticTimingRow;
