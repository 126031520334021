import * as React from 'react';

const ComingSoon: React.SFC<{}> = (props) => {
    return (
        <div className="lessonview-empty-container">
            <div>
                <h3>Coming Soon fdlkashfsf!</h3>
            </div>
        </div>
    );
};

ComingSoon.displayName = 'ComingSoon';

export default ComingSoon;
