
import * as React from "react"
import { boundMethod } from "autobind-decorator"
import { Question, QuestionGroup } from "../../services/elearn/lesson-types"
import { getLessonProgress, setBaselineAnswer, setMCAnswer } from "../../services/user/user-lesson-rest-interface"
import ContextualTitle from "../ContextualTitle/ContextualTitle"
import Loading from "../Loading/Loading"
import MultipleChoicePassage from "../MultipleChoicePassage/MultipleChoicePassage"
import MultipleChoiceQuestion from "../MultipleChoiceQuestion/MultipleChoiceQuestion"
import { PageContent, PageTitle } from "../PageContent/PageContent"
import QuestionProgressBar from "../../scenes/Lesson/components/QuestionProgressBar/QuestionProgressBar"
import { getLesson } from "../../services/elearn/lesson-rest-interface"
import { updateActivityInfoWithLastKnownNavigation } from "../../WinwardTracker"
import { TimerState } from "../Timer/Timer"
import { UserInfo } from "../../services/account/account-rest-interface"
import "./ReteachMCPage.css"

interface Props {
    lessonId: string
    isBaseline?: boolean
    onComplete: () => void
    handleExitLesson?: () => void
    setLessonComplete?: () => void
    questionGroups: QuestionGroup[]
    isTeacher: boolean
    userInfo: UserInfo
    reteachQuestionNumbers: number[]
}

interface State {
    currentQuestionGroupIndex: number
    currentQuestionIndex: number
    minQuestion: number
    farthestQuestionIndex: number
    firstSubmit: boolean
    questions: Question[]
    isLoaded: boolean
    addToMB: boolean
    canNext: boolean
    showTimeOutDialog: boolean
    lesson: any
}

/**
 * This is a simplified version of MCPage used for reteaching a lesson based on the selected questions
 */
export default class ReteachMCPage extends React.Component<Props, State> {
    constructor(props) {
        super(props)

        let questions = (props.questionGroups || []).reduce((acc, q) => acc.concat(q.questions), [] as Question[])
        let questionIndex = 0
        let currQId = questions[questionIndex].questionId
        
        sessionStorage.setItem("currQId", currQId)

        this.state = {            
            currentQuestionGroupIndex: 0,
            currentQuestionIndex: questionIndex,
            minQuestion: questionIndex,
            farthestQuestionIndex: questionIndex,
            firstSubmit: true,
            questions,
            isLoaded: false,
            addToMB: false,
            canNext: false,
            showTimeOutDialog: false,
            lesson: undefined
        }
    }

    public componentDidMount() {
        let { questionGroups, lessonId, isTeacher, userInfo } = this.props
        let { questions } = this.state

        if(!questionGroups || questionGroups.length === 0) { return }

        if (this.props.isTeacher) {
            this.setState({ currentQuestionIndex: 0 })
        }

        getLesson(lessonId, userInfo).then(lesson => {
            this.setState({lesson})
        })

        getLessonProgress(lessonId, userInfo).then((res) => {
            let currQuestionId, currentQuestionIdx = 0

            if (res.currentLessonContent && res.currentLessonContent.id) {
                currQuestionId = res.currentLessonContent.id
                for (let i = 0; i < questions.length; i++) {
                    if (questions[i].questionId === currQuestionId) {
                        currentQuestionIdx = i
                    }
                }
            }

            let groupIndex = this.getGroupIndexForQuestion(questionGroups, currentQuestionIdx)
            let currQId = questions[currentQuestionIdx].questionId
            sessionStorage.setItem("currQId", currQId)

            this.setState({
                currentQuestionIndex: currentQuestionIdx,
                farthestQuestionIndex: currentQuestionIdx,
                currentQuestionGroupIndex: groupIndex,
                isLoaded: true
            })
        })
    }

    @boundMethod
    public handleAnswerYes() {
        this.setState({showTimeOutDialog: false})
    }

    @boundMethod
    public handleAnswerNo() {
        this.handleExitLesson()
    }

    getGroupQuestionIndex(questionGroups, currentQuestionGroupIndex, currentQuestionIndex) {
        for (let i=0;i < currentQuestionGroupIndex;i++) {
            let group = questionGroups[i]
            currentQuestionIndex -= group.questions.length
        }
        return currentQuestionIndex
    }

    private getReteachTopNavigation() {
        let {reteachQuestionNumbers: questionNumbers, questionGroups } = this.props
        let {lesson} = this.state
        let {lessonName, category} = lesson
        let categoryId = category?.categoryId
        let iconPath = `/assets/images/icons/subjects/ico-subject-${categoryId.replace(/\s/g, '').toLowerCase()}.svg`

        return (
            <div className="reteach-navigation-section">
                <div className="reteach-title">
                <span className={`ribbon light ` + categoryId.toLowerCase()}>
                    <img alt="" src={iconPath} />
                </span>
                Reteach: {lessonName}</div>
                <div className="reteach-subtitle">Questions to Reteach:</div>
                <QuestionProgressBar
                    questionNumbers={questionNumbers}
                    currentQuestionIndex={this.state.currentQuestionIndex}
                    farthestQuestionIndex={this.state.farthestQuestionIndex}
                    onCloseClick={this.handleExitLesson}
                    onQuestionClick={this.goToQuestion}
                    isTeacher={this.props.isTeacher}
                    showExitText={false}
                />
            </div>
        )
    }    

    public render() {
        let { currentQuestionIndex, currentQuestionGroupIndex, farthestQuestionIndex, isLoaded, canNext } = this.state
        let { isBaseline, questionGroups, isTeacher } = this.props
        let { questions, lesson } = this.state
        let currQId = questions[this.state.currentQuestionIndex].questionId
        let topicId = questions[this.state.currentQuestionIndex].topicId
        let pathArray = window.location.pathname.split("/")
        let lessonId = pathArray[pathArray.length - 1]

        sessionStorage.setItem("topicId", topicId)
        sessionStorage.setItem("currQId", currQId)
        sessionStorage.setItem("lessonId", lessonId)

        if(!lesson || !isLoaded) {
            return <Loading />
        }

        if(currentQuestionIndex < 0 || currentQuestionIndex >= questions.length) {
            return <h1>Could not get question</h1>
        }

        let currentQuestionGroup = questionGroups[currentQuestionGroupIndex]
        let currentQuestion = questions[currentQuestionIndex]
        let useMathFont = !!lesson && lesson.category.categoryName === "Math"
        let groupQuestionIndex = this.getGroupQuestionIndex(questionGroups, currentQuestionGroupIndex, currentQuestionIndex)
        let question = currentQuestionGroup.questions[groupQuestionIndex]
        let {topicName} = question
        let title = topicName
        let subTitle = "Reteaching"
        let activity = `${lesson?.category.categoryName}: ${lesson?.lessonName} ${isBaseline ? "- Baseline": ""} (Question ${currentQuestionIndex + 1})`
        
        updateActivityInfoWithLastKnownNavigation(activity)

        return (
            <PageContent preContentComponent={this.getReteachTopNavigation()}>
                <ContextualTitle 
                    title={title} 
                    preText={subTitle} 
                    isTeacher={isTeacher} 
                    className="clear-50 question-title reteach-title"
                />
                {currentQuestionGroup.questionGroupInfo ?
                    <MultipleChoicePassage
                        questionGroup={currentQuestionGroup}
                        currentQuestionGroupIndex={currentQuestionGroupIndex}
                        currentQuestionIndex={currentQuestionIndex}
                        farthestQuestionIndex={farthestQuestionIndex}
                        onAnswer={this.onQuestionAnswered}
                        onNextPassage={this.handleNextPassage}
                        onGoToQuestion={this.goToQuestion}
                        questions={questions}
                        addHint={() => {}}
                        useMathFont={useMathFont}
                        isTeacher={isTeacher}
                        canNext={this.state.canNext}
                        timerState={TimerState.HIDDEN}
                    /> :
                    <MultipleChoiceQuestion
                        question={currentQuestion}
                        onAnswer={this.handleAnswer}
                        onNextQuestion={this.handleNextQuestion}
                        isBaseline={!!isBaseline}
                        userHasAnswered={currentQuestionIndex < farthestQuestionIndex}
                        isLastQuestion={currentQuestionIndex === questions.length - 1}
                        addHint={() => {}}
                        useMathFont={useMathFont}
                        isTeacher={isTeacher}
                        canNext={this.state.canNext}
                        timerState={TimerState.HIDDEN}
                    />
                }
                {this.props.children}
            </PageContent>
        )
    }

    @boundMethod
    private handleAnswer(isCorrect: boolean, answerIndex: number) {
        this.onQuestionAnswered(this.state.currentQuestionIndex, isCorrect, answerIndex)
    }

    @boundMethod
    private onQuestionAnswered(questionIndex: number, isCorrect: boolean, answerIndex: number) {
        let { lessonId, isBaseline, userInfo } = this.props
        let answerLetter = ["A", "B", "C", "D"][answerIndex]
        let currentQuestion = this.state.questions[questionIndex]
        let self = this
        if (isBaseline) {
            if (answerIndex === -1) {
                answerLetter = "no_clue"
            }
            setBaselineAnswer(lessonId, currentQuestion.questionId, isCorrect, answerLetter).then((res) => {
                this.onQuestionComplete(questionIndex)
                this.handleNextQuestion()
            })
        } else {
            if (this.state.currentQuestionIndex === this.state.farthestQuestionIndex) {
                setMCAnswer(lessonId, currentQuestion.questionId, isCorrect, answerLetter, userInfo).then((res) => {
                    if (isCorrect) {
                        this.setState({
                            canNext: true
                        }, () => {
                            this.onQuestionComplete(questionIndex)
                        })
                    }
                })
            }
        }
    }

    /**
     * Go to next question
     */
    @boundMethod
    private handleNextQuestion() {
        this.goToQuestion(this.state.currentQuestionIndex + 1)
    }

    private handleNextPassage() {
    }

    /**
     * Navigate to a particular question by index
     */
    @boundMethod
    private goToQuestion(questionIndex: number) {
        if(questionIndex < 0) {
            return
        }

        if (questionIndex >= this.state.questions.length) {
            this.props.onComplete()
        } else {
            this.setState({
                currentQuestionIndex: questionIndex,
                currentQuestionGroupIndex: this.getGroupIndexForQuestion(this.props.questionGroups, questionIndex),
                firstSubmit: true,
                canNext: false
            })
            sessionStorage.setItem("currQId", this.state.questions[questionIndex].questionId)
        }
    }

    
    /**
     * Only called once a question is "complete". This will go to the next question and set the user"s
     * "currentContent" to the next question"s questionID
    *
    * NOTE:
    * For baseline questions, a question is considered complete once the user answers and hits submit.
    * For MC questions, a question is not considered complete until the user selects the correct answer
    */
    @boundMethod
    private onQuestionComplete(completedQuestionIndex: number) {
        let nextQuestionIndex = completedQuestionIndex + 1

        // If this is not a new question, return
        let oldFarthestQuestion = this.state.farthestQuestionIndex
        if(nextQuestionIndex < oldFarthestQuestion) { return }

        this.setState({
            farthestQuestionIndex: Math.max(this.state.farthestQuestionIndex, nextQuestionIndex)
        })

        if (this.state.farthestQuestionIndex === this.state.questions.length && this.props.setLessonComplete) {
            this.props.setLessonComplete()
        } 
    }

    private getGroupIndexForQuestion(questionGroups: QuestionGroup[], questionIndex: number) {
        let groupIndex = 0
        let numQuestionsPerGroup = questionGroups.map(group => group.questions.length)

        let totalQuestionsSoFar = 0
        for(let i = 0; i < numQuestionsPerGroup.length; i++) {
            totalQuestionsSoFar += numQuestionsPerGroup[i]
            if(totalQuestionsSoFar > questionIndex) {
                groupIndex = i
                break
            }
        }

        return groupIndex
    }

    @boundMethod
    private handleExitLesson() {
        if (this.props.handleExitLesson) {
            this.props.handleExitLesson()
        }
    }
}
