import React, { Component } from "react"
import { App } from "../../../App"
import Popover from '../../../components/Popover/Popover'
import { UserInfo } from "../../../services/account/account-rest-interface"
import { Question } from "../../../services/elearn/lesson-types"
import { IStudyBuddy } from "./types"
import { SB_FRIENDS_WHO_CAN_HELP_WITH_QUESTION } from "../../../constants"
import { getStudyBuddiesForLessonAndQuestion, isSchoolEnabledForStudyBuddies } from "../../../services/study-buddies"

interface Props { 
    question: Question
}

interface State {
    buddies: IStudyBuddy[]
    currentLessonId: string
    enabled: boolean
}


/**
 * This is the view for the study buddies who can help for a specific question.
 */
export default class HelperStudyBuddies extends Component<Props, State> {    
    static instance: HelperStudyBuddies

    constructor(props) {
        super(props)
        this.state = {
            buddies: [],
            currentLessonId: "",
            enabled: false
        }
        HelperStudyBuddies.instance = this
    }

    async componentDidMount() {
        let userInfo = App.getUserInfoOrUndefined()
        
        if (!userInfo) return

        let { schoolName }  = userInfo
        let enabled = await isSchoolEnabledForStudyBuddies(schoolName)
        this.setState({enabled}, () => this.obtainStudyBuddiesInfo())
    }

    async obtainStudyBuddiesInfo() {
        let { enabled, currentLessonId } = this.state

        if (!enabled || currentLessonId === "") return

        let {questionId} = this.props.question

        // Get the study buddies who answered the question correctly!
        let foundStudyBuddies = await getStudyBuddiesForLessonAndQuestion(currentLessonId, questionId)
        let simplifier = (sb) => {
            return {
                emailAddress: sb.emailAddressOfBuddy,
                userId: sb.infoOfBuddy.userId,
                firstName: sb.infoOfBuddy.firstName,
                lastName: sb.infoOfBuddy.lastName,
                profileColor: sb.infoOfBuddy.profileColor,
                schoolName: sb.infoOfBuddy.schoolName
            }
        }
        let buddies = foundStudyBuddies.map(sb => simplifier(sb))
        this.setState({buddies})
    }

    static setCurrentLessonId(lessonId: string, timeout=300) {
        setTimeout(async () => {
            let instance = HelperStudyBuddies.instance
            
            if (instance) {
                HelperStudyBuddies.instance.setState({currentLessonId: lessonId})
                instance.obtainStudyBuddiesInfo()
            }
        }, timeout)
    }    

    render() {
        let { buddies, enabled, currentLessonId } = this.state
        let nofBuddies = buddies.length
        let isVisible = enabled && nofBuddies > 0

        return (
            <div>
                {isVisible &&
                    <div className="studybuddy-widget pt-4">
                        <h5 className="studybuddy-widget--title">Study Buddies</h5>
                        <p className="mb-2">{SB_FRIENDS_WHO_CAN_HELP_WITH_QUESTION}</p>
                        <div className="studybuddy-widget--avatars d-flex flex-wrap">
                            {buddies.map(buddy => <HelperStudyBuddy buddy={buddy}/>)}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

//--- Helpers ---

// Individual study buddy
function HelperStudyBuddy(props) {
    let { buddy } = props
    let { firstName, lastName, profileColor } = buddy
    const stylePopover = {
        minWidth: '300px',
        maxWidth: '350px',
        padding: '0'
    };

    return (
        <div className="studybuddy--tooltip mb-2">
            <div className="studybuddy--avatar mr-2">
                <div className="avatar__circle" style={{backgroundColor: profileColor}}>
                    <p className="avatar__circle--text">{firstName[0]}{lastName[0]}</p>
                </div>
            </div>
            <Popover
                visible={false}
                xAlignment={'right'}
                style={stylePopover} >
                <div className="studybuddy">
                    <div className="studybuddy--accent" style={{"backgroundColor": profileColor}}></div>
                    <div className="studybuddy--avatar">
                        <div className="avatar__circle" style={{"backgroundColor": profileColor}}><p className="avatar__circle--text">{firstName[0]}{lastName[0]}</p></div>
                    </div>
                    <div>
                        <div className="studybuddy--name">
                            {firstName} {lastName}
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    )
}
