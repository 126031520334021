import './Lesson.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';
import { RouteComponentProps } from 'react-router-dom';

import { LessonProgress, MaterialType, QuestionGroup } from '../../services/elearn/lesson-types';
import { getBaselineQuestions, getMCQuestions } from '../../services/elearn/lesson-rest-interface';

import BaselineCompleteModal from
    './components/BaselineQuestions/components/BaselineCompleteModal/BaselineCompleteModal';
import FirstLesson from './components/BaselineQuestions/components/FirstLesson/FirstLesson';
import LessonComplete from '../LessonComplete/LessonCompleteContainer';
import LessonVideo from '../LessonVideo/LessonVideoContainer';
import RewatchVideo from '../RewatchLessonVideo/RewatchLessonVideoContainer';
import Loading from '../../components/Loading/Loading';
import MCPage from '../../components/MCPage/MCPageContainer';
import { getLessonSummary } from '../../services/user/user-lesson-rest-interface';
import { getPrintoutByLessonID } from '../../services/utils/printouts';
import Timer from '../../components/Timer/Timer';

enum ActiveSection {
    Baseline,
    Video,
    RewatchVideo,
    MultipleChoice,
    Summary
}

interface ComposedProps {
    baselineQuestionGroups: QuestionGroup[];
    postLessonQuestionGroups: QuestionGroup[];
    shouldShowHintPopup: boolean;
}

export interface Props {
    lessonId: string;
    userInfo: any;
}

export interface StoreProps {
    numLessonsStarted: number;
    numLessonsCompleted: number;
    userProgress?: LessonProgress;
    currentLessonId: string;
    isTeacher: boolean;
    userInfo: any;
}

export interface DispatchProps {
    setLessonInProgress: (lessonInProgress: boolean) => void;
    /* setNextLessonAfter: (lessonId: string) => Promise<any>; */
    setCurrentLesson: (lessonId: string, userInfo: any) => Promise<any>;
    setLessonCompleted: (lessonId: string, userInfo: any) => void;
    setLessonCompleted2: (lessonId: string, userInfo: any) => void;
    incrementNumLessonsStarted: () => void;
    setCurrentQuestion: (lessonId: string, type: MaterialType, id: string, userInfo: any) => Promise<void>;
}

interface State extends ComposedProps {
    showTutorialPopup: boolean;
    activeSection: ActiveSection;
    showBaselineCompletedModal: boolean;
    loaded: boolean;
}

type AllProps = Props & StoreProps & DispatchProps & RouteComponentProps<any>;


export default class Lesson extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            showTutorialPopup: props.numLessonsStarted === 0 && props.numLessonsCompleted === 0,
            activeSection: ActiveSection.Baseline,
            showBaselineCompletedModal: false,
            baselineQuestionGroups: [],
            postLessonQuestionGroups: [],
            loaded: false,
            shouldShowHintPopup: true
        };

    }

    public UNSAFE_componentWillReceiveProps(nextProps: AllProps) {
      const shouldRewatchVideo = nextProps.location.pathname.endsWith('rewatchVideo');
      if (shouldRewatchVideo) {
          this.setState({
            activeSection: ActiveSection.RewatchVideo
          });
      }
    }

    public UNSAFE_componentWillMount() {
        const { lessonId, isTeacher, userInfo } = this.props;

        this.props.setLessonInProgress(true);

        // Set current lessonId and get lesson questions
        Promise.all([
            !isTeacher ?
                this.props.setCurrentLesson(lessonId, userInfo) :
                Promise.resolve(undefined), // hack, RAK
            getBaselineQuestions(lessonId, userInfo),
            getMCQuestions(lessonId, userInfo)
        ])
        .then(([_, baselineQuestionsData, postLessonQuestionsData]) => {
            const hasBaselineQuestions = baselineQuestionsData && baselineQuestionsData.questionGroups &&
                baselineQuestionsData.questionGroups.length > 0;
            const hasPostLessonQuestions = postLessonQuestionsData && postLessonQuestionsData.questionGroups &&
                postLessonQuestionsData.questionGroups.length > 0;

            // Increment numLessonsStarted when starting a new lesson
            const { userProgress = {} as Partial<LessonProgress>, incrementNumLessonsStarted } = this.props;
            const { currentLessonContent } = userProgress;
            if(!currentLessonContent) {
                incrementNumLessonsStarted();
            }

            let postLessonQuestions: QuestionGroup[];
            let shouldShow;

            if(hasPostLessonQuestions) {
                postLessonQuestions = postLessonQuestionsData.questionGroups;
                shouldShow = postLessonQuestionsData.shouldPromptForHints;
            } else {
                postLessonQuestions = [];
                shouldShow = true;
            }

            // NOTE: This whole function is an unfortunate hack to support teacher view.
            // cWillMount can finish after subsequent cWillReceiveProps functions
            const shouldTeacherWatchVideo = isTeacher && this.state.activeSection === ActiveSection.RewatchVideo;
            const teacherActiveSection = shouldTeacherWatchVideo ? 
                ActiveSection.RewatchVideo : ActiveSection.MultipleChoice;
            this.setState({
                baselineQuestionGroups: hasBaselineQuestions ?
                    this.sortQuestionGroup(baselineQuestionsData.questionGroups) : [],
                postLessonQuestionGroups: this.sortQuestionGroup(postLessonQuestions),
                activeSection: isTeacher ? teacherActiveSection :
                    this.determineActiveSection(hasBaselineQuestions),
                loaded: true,
                shouldShowHintPopup: shouldShow
            });
        })
        .catch(e => {
            console.log(e);
        });
    }

    public componentWillUnmount() {
        this.props.setLessonInProgress(false);
        window.onbeforeunload = () => {/**/};
    }

    public render() {
        const { activeSection, showBaselineCompletedModal, loaded,
            postLessonQuestionGroups = [], baselineQuestionGroups = [] } = this.state;
        const { lessonId, isTeacher, userInfo } = this.props;
        if(!loaded) { return <Loading />; }

        switch(activeSection) {
            case ActiveSection.Baseline: {
                return (
                    <MCPage
                        lessonId={lessonId}
                        isBaseline
                        onComplete={this.handleBaselineCompleted}
                        questionGroups={baselineQuestionGroups}
                        isTeacher={isTeacher}
                        userInfo={userInfo}
                    >
                        <BaselineCompleteModal
                            show={showBaselineCompletedModal}
                            onClose={this.handleBaselineCompleted}
                            onWatch={this.goToVideo}
                            onSkip={this.goToMultipleChoice}
                        />
                        {this.state.showTutorialPopup && <FirstLesson />}
                    </MCPage>
                );
            }
            case ActiveSection.Video: {
                return <LessonVideo lessonId={lessonId} onComplete={this.handleVideoCompleted} userInfo={userInfo} />;
            }
            case ActiveSection.RewatchVideo: {
                return <RewatchVideo lessonId={lessonId} isTeacher={isTeacher} userInfo={userInfo} />;
            }
            case ActiveSection.MultipleChoice: {
                let printout = getPrintoutByLessonID(lessonId);
                return (
                    <MCPage
                        lessonId={lessonId}
                        onComplete={this.handleMCCompletion}
                        setLessonComplete={this.setLessonComplete}
                        questionGroups={postLessonQuestionGroups}
                        shouldShowHintPopup={this.state.shouldShowHintPopup}
                        pdfPrintout={printout}
                        userInfo={userInfo}
                        isTeacher={isTeacher}
                    />
                );
            }
            case ActiveSection.Summary: {
                // return <Redirect to={`${process.env.REACT_APP_BASE_URL}app/lessons/${lessonId}/report`} />;
                return <LessonComplete />;
            }
            default: return <div>Invalid page</div>;
        }
    }

    @boundMethod
    private handleMCCompletion() {
        if (this.props.isTeacher) {
            this.props.history.push('/app/lessons?view=subject');
        } else {
          this.goToSummary();
        }
    }

    @boundMethod
    private handleBaselineCompleted() {

        getLessonSummary(this.props.lessonId, this.props.userInfo)
        .then(lessonSummary => {
            const percentCorrect = lessonSummary.numBaselineQuestionsCorrect / lessonSummary.numBaselineQuestionsTotal;

            this.setState({ showBaselineCompletedModal: (percentCorrect >= 0.8) });

            if(percentCorrect < 0.8) {
                this.goToVideo();
            } 
            else {
                // This is the time baseline completed modal will be shown to the user 
                // with options: They may see the videos or skip them altogether
                // At this point freeze the Timer so that they don't see a running timer behind
                // Of course, the timer will be reset as soon as the modal dialog closes
                Timer.reset(0)   // Reset the timer value
                Timer.freezeUI() // And keep it there
            }
        })
        .catch(e => {
            console.error(e || `Could not retrieve lesson summary for ${this.props.lessonId}`);
            this.goToVideo();
        });
    }

    @boundMethod
    private handleVideoCompleted() {
        if(this.state.postLessonQuestionGroups.length === 0) {
            this.goToSummary();
        } else {
            this.goToMultipleChoice();
        }
    }

    @boundMethod
    private goToVideo() {
        this.setState({ activeSection: ActiveSection.Video });
    }

    @boundMethod
    private goToMultipleChoice() {
        // tslint:disable-next-line: max-line-length
        this.props.setCurrentQuestion(this.props.lessonId, MaterialType.POST_LESSON_QUESTION, '', this.props.userInfo);
        this.setState({ activeSection: ActiveSection.MultipleChoice });

        // Now we can reset the timer as we go to the questions first time
        Timer.reset()
    }

    private goToSummary() {
        this.props.setLessonCompleted(this.props.lessonId, this.props.userInfo);
        this.setState({ activeSection: ActiveSection.Summary });
    }

    private setLessonComplete() {
        this.props.setLessonCompleted2(this.props.lessonId, this.props.userInfo);
    }

    private determineActiveSection(hasBaselineQuestions: boolean) {

        const endingPath = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);

        const { isTeacher, userProgress = {} as Partial<LessonProgress> } = this.props;
        const { currentLessonContent } = userProgress;

        if (isTeacher && endingPath === 'rewatchVideo') {
            return ActiveSection.RewatchVideo;
        }

        if(!currentLessonContent) { // user has not yet started the lesson
            if(!hasBaselineQuestions) {
                return ActiveSection.Video;
            }
            return ActiveSection.Baseline;
        }

        switch(currentLessonContent.type) {
            case MaterialType.POST_LESSON_QUESTION: return ActiveSection.MultipleChoice;
            case MaterialType.TOPIC: return ActiveSection.Video;
            case MaterialType.BASELINE_QUESTION: return ActiveSection.Baseline;
            case MaterialType.REPORT: return ActiveSection.Summary;
            default: return ActiveSection.Baseline;
        }
    }

    private sortQuestionGroup(questionGroup?: QuestionGroup[]) {
        if(!questionGroup) {
            return [];
        }

        return questionGroup.map(group => ({
            ...group,
            questions: group.questions.sort((a, b) =>
                a.questionNumber === null || b.questionNumber === null ||
                a.questionNumber === undefined || b.questionNumber === undefined ? 0 :
                a.questionNumber - b.questionNumber)
            })
        );
    }
}
