import { DispatchProps, Props, RouteProps } from './UserAvatar';

import UserAvatar from './UserAvatar';
import { connect } from 'react-redux';
import { logoutUser } from '../../../../stores/account.store';
import { withRouter } from 'react-router-dom';

const mapDispatchToProps = { logoutUser };

const ConnectedAvatar = connect<{}, DispatchProps, Props & RouteProps>(null, mapDispatchToProps)(UserAvatar);

export default withRouter(ConnectedAvatar);
