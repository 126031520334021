/**
 * Pluralizes the string info based on the n value
 * Examples: 
 *    str: "Student" n: 1 => "Student"
 *    str: "Student" n: 2 => "Students"
 * 
 * @param str 
 * @param n 
 * @returns pluralization of the input string
 */
export const pluralize = (str: string, n: number): string => n === 1 ? str: `${str}s`

/**
 * Returns the number in locale representation and if necessary adds commas for easy readability
 * Examples:
 *  n = 100  -> 100
 *  n = 1234 -> 1,234
 * @param n 
 * @param locale 
 * @returns 
 */
export const localeNumber = (n: number, locale="en-US"): string => new Intl.NumberFormat(locale).format(n)

/**
 * Replaces all occurances of the search term in the string with the replacement value.
 * Example: "Hello World Wide Web" -> "HelloWorldWideWeb"
 * @param str
 * @param search
 * @param replacement
 * @returns 
 */
export const replaceAll = (str: string, search: string, replacement: string) => str.split(search).join(replacement)

/**
 * Sort predicate for strings to be used in a sort algorithm
 * @param a, b, c, d strings to compare
 * @param reverseSort
 * @returns 
 */
export const sortStr = (a: string, b: string, c?: string, d?: string, reverseSort?: boolean) => {
    if (!reverseSort) {
        if(a < b) return 1
        if(a > b) return -1
        if (a === b && c && d) {
            if(c < d) return 1
            if(c > d) return -1
        }
    } 
    else {
        if(a < b) return 1
        if(a > b) return -1
        if (a === b && c && d) {
            if(c < d) return -1
            if(c > d) return 1
        }
    }
    return 0
}

/**
 * Capitilize the first character of a string and returns the result
 * @param str 
 * @returns 
 */
export const capitilizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.substring(1)
}


/**
 * Determines if the provided email address is valid or not
 * @param emailAddress 
 * @returns true if valid
 */
export const isValidEmailAddress = (emailAddress: string) => {
    let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    let isValid = regex.test(emailAddress)
    return isValid
}

