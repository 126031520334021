import { createGenericAuthRequest, ignoreJsonError } from '../request/request-utils';

const baseUrl = process.env.REACT_APP_ELEARN_URL || '/';
const request = createGenericAuthRequest(baseUrl);

const baseURL: string = '/test-hint';

export const getHint = (
  testName: string, 
  section: string,
  questionNumber: number
) => {
  return request(
    `${baseURL}/${testName}/section/${section}/question/${questionNumber}`
  );
};

export const getAllHints = () => {
  return request(
    `${baseURL}/hints`
  );
};

export const createTestHint = (
  testName: string, 
  section: string, 
  questionNumber: number,
  text: string
) => {
  return request(
    `${baseURL}/${testName}/section/${section}/question/${questionNumber}`,
    { text },
    { method: 'POST' }
  );
};
