import React from "react"
import { IResilienceReminderResource } from "./types"
import CollapsableCard, { CollapsableCardBody, CollapsableCardHeader } from "../../../components/CollapsableCard/CollapsableCard"
import { Column } from "../../../components/DataGrid/types/types"
import { alphanumericCompare } from "../../../services/utils/sort-util"
import DataGrid from "../../../components/DataGrid"
import { ResilienceReminders } from "."

const sortMethod = alphanumericCompare

const columnsForResources: Column[] = [
    {
        title: "LESSONS",
        dataProperty: "lesson",
        width: "72%",
        sortable: true,
        sortMethod,
    },
    {
        title: "LENGTH",
        dataProperty: "length",
        width: "220px",
        sortable: true,
        sortMethod,
    },
    {
        title: "LAUNCH",
        dataProperty: "launch",
        width: "110px",
        sortable: false
    },
]


/**
 * Resources view which shows some lesson videos
 * 
 * @param props: title, resources
 * @returns 
 */
export default function ResilienceRemindersResourcesView(props: {title: string, resources: IResilienceReminderResource[]}) {
    let { title, resources } = props
    let iconPath = `/assets/images/navbar/resources-icon-white.svg`

    return (
        <CollapsableInfoCardForResources
            name="rr-resilience-resource-section"
            title={title}
            collapsed={false}
            icon={iconPath}
            columns={columnsForResources}
            content={resources}
        />
    )
}


function CollapsableInfoCardForResources({ name, title, icon, ribbonColor = "darkPurple", headerColor = "#279ed7", collapsed = false, columns, content }) {
    function handleMethodLinkClick(e) {
        e.preventDefault()
        ResilienceReminders.openMacDermottMethodModal()
    }

    return (
        <CollapsableCard key={name} initiallyCollapsed={collapsed}>
            <CollapsableCardHeader>
                <div className="lesson-list-card-header">
                    <span className={`ribbon ${ribbonColor}`}><img src={icon} alt="" /></span>
                    <h2 className="lesson-list-card-header--text">{title}</h2>
                </div>
            </CollapsableCardHeader>

            <CollapsableCardBody>
                {content.map((r, index) => {
                    let { name, showPopupLink } = r
                    let data = getResourceData(r, index)
                    return (
                        <>
                            <div className="subcategory-title--wrapper">
                                <h6 className="subcategory-title">{name}</h6>
                                { showPopupLink &&
                                    <button title="View The MacDermott Method Model, opens a modal window" className="btn btn-simple rr-macdermott-method-model-link" onClick={handleMethodLinkClick}>
                                        <img
                                            className="modal-icon mr-1"
                                            src={`/assets/images/icons/v2/ico-modal.svg`}
                                            width="12"
                                            height="12"
                                            alt=""
                                        /> <span>View The MacDermott Method Model</span>
                                    </button>
                                }
                            </div>
                            <DataGrid
                                columns={columns}
                                data={data}
                            />
                        </>
                    )
                })
                }
            </CollapsableCardBody>
        </CollapsableCard>
    )
}

function getLaunchButtonForVideo(name: string, videoId: string) {
    return <ResilienceReminders.ResourceVideoLink name={name} videoId={videoId}/>
}

function getResourceData(d, index) {
    let { lessons } = d
    let dd = lessons.map((l, index) => getData(l, index))
    return dd
}

function getData(d, index) {
    let id = `rr-data-${index}`
    let { name, videoId, length } = d
    let data = {
        lesson: {
            content: name,
            comparator: name,
        },
        length: {
            content: length,
            comparator: length,
        },
        launch: {
            content: getLaunchButtonForVideo(name, videoId),
        },
    }

    return {
        id,
        data
    }
}