import './BackToTestsButton.css';

import * as React from 'react';
import { Link } from 'react-router-dom';
import { getTestTypeDisplayName } from '../../../../services/utils/practice-test-util';

export interface Props {
    testType: string;
    targetUri: string;
    btnColor?: string;
}

const BackToTestsButton: React.SFC<Props> = ({ testType, targetUri, btnColor = 'blue'}) => {
    // FYI: this logic is duplicated probably in Growth Analytics screen...might 
    // want to consolidate if more stuff needs to be done with this

    const testDisplayName = getTestTypeDisplayName(testType);

    return (
        <Link
            to={targetUri}
            className={`back-to-tests-btn title-navigation-buttons btn btn--text-white btn--bg-` + btnColor}
            title={`RETURN TO ` + testDisplayName}
        >
            RETURN TO {testDisplayName}
        </Link>
    );
};

BackToTestsButton.defaultProps = {
    targetUri: undefined
};
BackToTestsButton.displayName = 'BackToTestsButton';

export default BackToTestsButton;
