import React from "react"
import GlobalStats from "../GlobalStats"
import SchoolsStats from "../SchoolsStats"
import SuperintendentFilters, { FilterSelections, SelectableItem, TestKind } from "../SuperintendentFilters"
import { filterLessons, getCategorialLessonsPerformances, LessonsSummary } from "../../../stores/superintendent-store"
import LessonsPerformanceStats from "../LessonsPerformanceStats"
import { SITimeFrame } from "../../../services/utils/date-util"

export interface LessonsViewProps {  
  summary: LessonsSummary
  lessonsPerformances: any // OT: TODO
  filterSelections: FilterSelections
  onSchoolsChanged: (schoolNames: SelectableItem[]) => void
  onGraduationYearsChanged: (graduationYears: SelectableItem[]) => void
  onTimeFrameChanged: (timeFrame: SITimeFrame) => void
  onTestScoreKindChanged: (testScoreKind: TestKind) => void
}

interface IFullLessons {
  ACT: any
  SAT: any
}

let fullLessons: IFullLessons

export const LessonsView: React.FunctionComponent<LessonsViewProps> = (props) => {
  let {summary, filterSelections, lessonsPerformances} = props
  let {onSchoolsChanged, onGraduationYearsChanged, onTimeFrameChanged, onTestScoreKindChanged} = props
  let {schools, graduationYears, timeFrame, testScoreKind}  = filterSelections
  let selectedSchoolNames = schools.filter(s => s.selected).map(s => s.name)  
  let selectedGraduationYears = graduationYears.filter(gy => gy.selected).map(gy => gy.name)
  let filteredLessons = filterLessons(summary, selectedSchoolNames, selectedGraduationYears, timeFrame, testScoreKind)
  let filteredPerformances = getCategorialLessonsPerformances(lessonsPerformances, {schoolNames: selectedSchoolNames, graduationYears: selectedGraduationYears, timeFrame: timeFrame})

  if (!fullLessons) {
    let allGraduationYears = graduationYears.map(gy => gy.name)
    let allSchoolNames = schools.map(s => s.name)  
    let actLessons = filterLessons(summary, allSchoolNames, allGraduationYears, SITimeFrame.ALL_TIME, TestKind.ACT)
    let satLessons = filterLessons(summary, allSchoolNames, allGraduationYears, SITimeFrame.ALL_TIME, TestKind.SAT)

    // This is the full lessons information (including both ACT & SAT conversions)
    // which will be captured here only once to be used later...
    fullLessons = {
      ACT: actLessons, 
      SAT: satLessons
    }
  }

  return (
    <div className="si-lessons">
      <SuperintendentFilters
        filterSelections={filterSelections}
        showTimeFrameSelection={true}
        onSchoolsChanged={onSchoolsChanged}
        onGraduationYearsChanged={onGraduationYearsChanged}
        onTimeFrameChanged={onTimeFrameChanged}
        onTestScoreKindChanged={onTestScoreKindChanged}
        enableTestScoreKindSelection={true}
      />
      <GlobalStats  data={filteredLessons} filterSelections={filterSelections}/>
      <SchoolsStats data={filteredLessons} filterSelections={filterSelections} allData={fullLessons} />
      <LessonsPerformanceStats data={filteredPerformances} filterSelections={filterSelections} allData={fullLessons} />
    </div>
  )
}
