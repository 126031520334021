import * as React from 'react';
import { connect } from 'react-redux';

import StudyGuide from './StudyGuide';
import { getStudyGuideHints } from '../../../stores/hints.store';

const mapStateToProps = (state) => { 
   return {
       hints: state.hints.subjects
   };
};

const mapDispatchToProps = { 
  getStudyGuideHints
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyGuide);
