// Data for the ACT & SAT Tips (we will add as needed)

export const tips = [
  {
    resource: "Top 10 Tips for the ACT",
    description: "This resource guide gives the top 10 tips for ACT English, Math, Reading, and Science.",
    link: "https://learn.winwardacademy.com/assets/home-pdfs/10TipsforACT.pdf"
  },
  {
    resource: "Top 10 Tips for the SAT",
    description: "This resource guide gives the top 10 tips for SAT Math, Reading, and Writing.",
    link: "https://learn.winwardacademy.com/assets/home-pdfs/10TipsforSAT.pdf"
  },
  {
    resource: "8 Tips for Test Taking",
    description: "This resource guide provides 8 tips for test-taking success.",
    link: "https://learn.winwardacademy.com/assets/home-pdfs/8UltimateTips.pdf"
  },
  {
    resource: "Test Taking Tips for Before, During, and After",
    description: "This resource guide breaks down tips to follow before an exam, during an exam, and after an exam.",
    link: "https://learn.winwardacademy.com/assets/home-pdfs/TipsforBeforeDuringAfter.pdf"
  },
  {
    resource: "Score Choice and Superscoring",
    description: "This resource guide details what score choice and superscoring are and what role they play in college admissions.",
    link: "https://learn.winwardacademy.com/assets/home-pdfs/ScoreChoice&Superscoring.pdf"
  },
  {
    resource: "Meaning of ACT and SAT Scores",
    description: "This resource guide breaks down what ACT and SAT scores actually reflect about test takers.	",
    link: "https://learn.winwardacademy.com/assets/home-pdfs/MeaningofACTandSATScores.pdf"
  },
  {
    resource: "ACT and SAT Myths",
    description: "This resource guide reviews the top misconceptions about the ACT and SAT exams.",
    link: "https://learn.winwardacademy.com/assets/home-pdfs/DisprovingACT&SATMyths.pdf"
  },
  {
    resource: "List of Helpful Resources",
    description: "This resource guide provides phone numbers and emails for the organizations you'll contact for ACT and SAT registration.",
    link: "https://learn.winwardacademy.com/assets/home-pdfs/ListOfHelpfulResources.pdf"
  },
  {
    resource: "ACT Study Schedule",
    description: "This resource guide provides a general study schedule for students to prioritize high impact lessons for the ACT.",
    link: "https://learn.winwardacademy.com/assets/images/dashboard/practice/ACT.Study.Schedule.pdf"
  },
  {
    resource: "SAT Study Schedule",
    description: "This resource guide provides a general study schedule for students to prioritize high impact lessons for the SAT.",
    link: "https://learn.winwardacademy.com/assets/images/dashboard/practice/SAT.Study.Schedule.pdf"
  },
  {
    resource: "Math Formula Sheet",
    description: "This PDF has all math formulas needed for the ACT and SAT",
    link: "https://learn.winwardacademy.com/assets/handouts/Math_Cheat_Sheet.pdf"
  }
]