import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import DiagnosticMaterials from './components/DiagnosticMaterials/DiagnosticMaterialsContainer';

interface Props {

}

interface State {

}

export class PracticeInstructions extends React.Component<Props, State> {
    public render() {
        const currentUrl = window.location.href;
        const endingPath = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
        if (endingPath.toLowerCase() === 'diagnostic') {
            return <DiagnosticMaterials testName="diagnostic" />;
        } else {
            return <DiagnosticMaterials testName={endingPath} />;
        }
    }
}

interface RouteProps extends RouteComponentProps<any>, Props { }
export default (props: RouteProps) => {
    return (
        <PracticeInstructions />
    );
};
