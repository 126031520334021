import * as React from 'react';

import { AllProps, Props } from './MistakeBankQuestionPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    addQuestionToMistakeBank,
    setTopicQuestionAsReviewed,
    setTopicQuestionAsReviewedInSS
} from '../../../../../../stores/lesson.store';

import { AppState } from '../../../../../../store';
import MistakeBankQuestionPage from './MistakeBankQuestionPage';
import { connect } from 'react-redux';

const mapStateToProps = ({ lesson, account }: AppState) => ({
    allLessons: lesson.userLessons.allLessons,
    userInfo: account.userInfo
});

const mapDispatchToProps = { setTopicQuestionAsReviewed, setTopicQuestionAsReviewedInSS, addQuestionToMistakeBank };

const ConnectedMistakeBankQuestionPage = connect<any, any, any, AppState>(mapStateToProps, mapDispatchToProps)(MistakeBankQuestionPage);

const MistakeBankQuestionPageContainer = (props: any) => {
    const handleExitLesson = () => props.history.push(`${process.env.REACT_APP_BASE_URL}app/mistake-bank`);
    return <ConnectedMistakeBankQuestionPage handleExitLesson={handleExitLesson} {...props} />;
};

// tslint:disable-next-line:max-line-length
type FinalProps = Pick<Props, 'topicId' | 'lessonId' | 'onComplete' | 'category' | 'questionGroups' | 'ssQuestionGroups' | 'startingQuestionId' | 'hint'>;
export default withRouter(MistakeBankQuestionPageContainer);
