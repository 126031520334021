import './MistakeBank.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { CURRICULUM_LIST, LessonProgress, MistakeBankLessons, MistakeBankLessonsOverview }
    from '../../services/elearn/lesson-types';
import { PageContent, PageTitle } from '../../components/PageContent/PageContent';

import ComingSoon from '../../components/ComingSoon/ComingSoon';
import Loading from '../../components/Loading/Loading';
import MissedQuestions from './components/MissedQuestions/MissedQuestionsContainer';
import MistakeBankInfoCard from './components/MistakeBankInfoCard/MistakeBankInfoCard';
import { RouteComponentProps } from 'react-router-dom';
import { Tab } from '../../components/Tabs/Tabs';
import Tabs from '../../components/Tabs/Tabs';
import { Link } from "react-router-dom"
import ScreenSelector from "../../components/ScreenSelector"
import { UserInfo } from "../../services/account/account-rest-interface"
import StudyBuddiesView from "./StudyBuddies"
import { LiveViewStatus, updateLiveViewStatus } from "../../services/liveview/liveview-service"
import { isSchoolEnabledForStudyBuddies } from "../../services/study-buddies"

export interface Props {

}

export interface StoreProps {
    lessons: MistakeBankLessons[]
    allLessons: LessonProgress[]
    userInfo: UserInfo
}

export interface DispatchProps {
    /* Increments the total number of questions answered for this lesson (MC questions only) */
    getMistakeBankOverview: (userInfo: UserInfo) => Promise<MistakeBankLessonsOverview>
}

interface State {

}

export type AllProps = Props & StoreProps & DispatchProps & RouteComponentProps<any>

export default class MistakeWithoutRouting extends React.Component<AllProps, State> {
    constructor(props) {
        super(props)
    }

    render() {
        let link = `/print/study-guide/${this.props.userInfo.firstName}/${this.props.userInfo.lastName}`

        return (
            <PageContent className="lessons-container">
                <PageTitle title={`Mistake Bank`}>
                    <div className="navigation-button-container">
                        <Link
                            to={link}
                            target="_blank"
                            onClick={(event) => {
                                event.preventDefault()
                                window.open(link)
                            }}
                            className="title-navigation-buttons btn btn--bg-blue"
                            >
                            VIEW STUDY GUIDE
                        </Link>
                    </div>
                </PageTitle>
                <MistakeBankSubviews {...this.props} />
            </PageContent>
        )
    }
}


//--- Helpers ---

type MistakeBankViewType = typeof SUBVIEW_MISTAKEBANK  | typeof SUBVIEW_STUDYBUDDIES

const SUBVIEW_MISTAKEBANK   = "main"
const SUBVIEW_STUDYBUDDIES  = "studybuddies"
const DEFAULT_VIEW: MistakeBankViewType = SUBVIEW_MISTAKEBANK

interface ISubviewState {
    selectedView: MistakeBankViewType
    studyBuddiesEnabled: boolean
}

class MistakeBankSubviews extends React.Component<AllProps, ISubviewState> {
    constructor(props: AllProps) {
        super(props)

        this.state = {
            selectedView: DEFAULT_VIEW,
            studyBuddiesEnabled: false
        }
    }

    async componentDidMount() {
        switch (window.location.hash) {
            case `#${SUBVIEW_MISTAKEBANK}`:
                this.setState({ selectedView: SUBVIEW_MISTAKEBANK })
                break
            case `#${SUBVIEW_STUDYBUDDIES}`:
                this.setState({ selectedView: SUBVIEW_STUDYBUDDIES })
                break
            default:
                this.setState({ selectedView: DEFAULT_VIEW })
        }

        // Let's find out if the study buddies enabled for the user's school
        let { userInfo } = this.props
        let { schoolName } = userInfo
        let studyBuddiesEnabled = await isSchoolEnabledForStudyBuddies(schoolName)
        this.setState({studyBuddiesEnabled})
    }

    render() {
        let {selectedView, studyBuddiesEnabled} = this.state

        return (
            <div className="">
                <ScreenSelector
                    active={selectedView === SUBVIEW_MISTAKEBANK}
                    displayName="Mistake Bank"
                    onSwitchComponent={() => {
                        this.setState({ selectedView: SUBVIEW_MISTAKEBANK })
                        window.location.hash = SUBVIEW_MISTAKEBANK
                    }}
                />
                {studyBuddiesEnabled &&
                    <ScreenSelector
                        active={selectedView === SUBVIEW_STUDYBUDDIES}
                        displayName="Study Buddies"
                        onSwitchComponent={() => {
                            this.setState({ selectedView: SUBVIEW_STUDYBUDDIES })
                            window.location.hash = SUBVIEW_STUDYBUDDIES
                        }}
                    />
                }

                <div className="mistakebank-subview-container">
                    {selectedView === SUBVIEW_MISTAKEBANK  && <MistakeBankView {...this.props} />}
                    {selectedView === SUBVIEW_STUDYBUDDIES && <StudyBuddiesView {...this.props} />}
                </div>
            </div>
        )
    }
}

interface IMistakeBankState {
    renderComponent: boolean
    overview: any
}

class MistakeBankView extends React.Component<AllProps, IMistakeBankState> {
    constructor(props: AllProps) {
        super(props)
        this.state = {
            renderComponent: false,
            overview: []
        }
    }

    public componentDidMount() {
        this.props.getMistakeBankOverview(this.props.userInfo).then(overview => {
            this.setState({renderComponent: true, overview})
        })

        let status: LiveViewStatus = { navigation: "Mistake Bank", activity: "Main Page"}
        updateLiveViewStatus(status)
    }
    
    createLessonTab = (title: string, index: number) => {
        let isTestPrep = title === 'Test Prep'
        let missedLessons = [] as MistakeBankLessons[]

        if (this.props.lessons ) {
            missedLessons = this.state.overview.overview
        }

        return (
            <Tab text={title} key={title}>
                {!isTestPrep && <ComingSoon />}
                {isTestPrep &&
                    <MissedQuestions
                        curriculum={title}
                        mistakeBank={true}
                        lessons={missedLessons}
                    />
                }
            </Tab>
        )
    }

    render() {
        if (!this.state.renderComponent) {
            return (
                <Loading/>
            );
        }

        let tabs = CURRICULUM_LIST.map(this.createLessonTab)

        return (
            <div className="">
                <MistakeBankInfoCard userInfo={this.props.userInfo} />
                <Tabs>
                    {tabs}
                </Tabs>
            </div>
        )
    }
}
