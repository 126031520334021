import './Progress.css';

import * as React from 'react';

import Card from '../../../../components/Card/Card';
import CurrentLesson from './Current-Lesson/Current-Lesson';
import { LessonProgress } from '../../../../services/elearn/lesson-types';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import { getSubjectIconList } from '../../../../services/icon/icon-service';

interface Props {
    totalLessons: number;
    completedLessons: number;
    lessonId: string;
    lesson: LessonProgress;
    onLessonClick: (lessonId: string) => void;
}

export const Progress: React.SFC<Props> = (props: Props) => {
    const bgStyle = {
        backgroundImage: `url('/assets/images/dashboard/bg-blue-green.png')`
    };

    const subjectIcons = getSubjectIconList('@0,5x');
    const subjectIcon = props.lesson && props.lesson.category ?
        subjectIcons.filter(icon => icon.title === props.lesson.category.categoryName)[0] :
        { icon: '' };

    const minutes = props.lesson ? props.lesson.aggregateVideoDuration
                    .substring(0, props.lesson.aggregateVideoDuration.indexOf(':')) : '0';
    const seconds = props.lesson ? props.lesson.aggregateVideoDuration
    .substring(props.lesson.aggregateVideoDuration.indexOf(':') + 1) : '0';
    let timeText = minutes + ' minutes';
    if (minutes === '0') {
        timeText = seconds + ' seconds';
    }
    return (
        <Card title="My Progress" titleColor="white" classTitle="progress-banner" style={bgStyle}>
            <div className="progress-banner__progress-bar-container">
                <span>Lesson {props.totalLessons}</span>
                <ProgressBar barCurrentColor='darkPurple' total={props.totalLessons} current={props.completedLessons} popover={true}/>
            </div>

            {props.lessonId &&
                <CurrentLesson
                    category={props.lesson.category.categoryName}
                    lesson={props.lesson.lessonName}
                    img={subjectIcon.icon}
                    length={timeText}
                    onLessonClick={() => props.onLessonClick(props.lessonId)}
                    currentLessonContent={props.lesson.currentLessonContent}
                />}
        </Card>
    );
};

Progress.displayName = 'Progress';
