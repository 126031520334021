import React from "react"
import { getHumanTime } from "../../../services/utils/date-util"

export const TonPGraduationYearsView = (props) => {
  let {graduationYears} = props

  return (
    <table>
      <thead>
        <tr>
          <th>GRADUATION YEAR</th>
          <th>TOTAL</th>
          <th>LESSONS</th>
          <th>TESTS</th>
          <th>MISTAKE BANK</th>
          <th>STAY SHARP</th>
        </tr>
      </thead>
      <tbody className="si-tonp-details-school-graduationyears">
        {graduationYears.map(gy => {
          let {year, summary} = gy
          let {lessons, tests, mistakebanks, staysharps} = summary
          let total = lessons + tests + mistakebanks + staysharps
          let lessonsHT = getHumanTime(lessons, false, true).text
          let testsHT = getHumanTime(tests, false, true).text
          let mistakebanksHT = getHumanTime(mistakebanks, false, true).text
          let staysharpsHT = getHumanTime(staysharps, false, true).text
          let totalHT = getHumanTime(total, false, true).text

          return(
            <tr key={`${year}`} >
              <td>Class of {year}</td>
              <td className="cell-composite" dangerouslySetInnerHTML={{__html: totalHT}} />
              <td dangerouslySetInnerHTML={{__html: lessonsHT}} />
              <td dangerouslySetInnerHTML={{__html: testsHT}} />
              <td dangerouslySetInnerHTML={{__html: mistakebanksHT}} />
              <td dangerouslySetInnerHTML={{__html: staysharpsHT}} />
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
