import * as React from 'react';
import * as autobind from 'autobind-decorator';

import MathFigure from '../../components/MathFigure/MathFigure';

import BlueGeometricBackground from '../../components/BlueGeometricBackground/BlueGeometricBackground';
import Button from '../../components/Button/Button';
import * as figureFilenames from '../../list.js'; 

interface Props {

}

interface State {
  idx: number;
}



export default class FigureDemo extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        // console.log(figureFilenames);
        this.state = {
          idx: 0
        };
    }

    public render() {
        return (
            <BlueGeometricBackground className="">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <h3>{figureFilenames[this.state.idx]}</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 figure-btn-wrapper">
                      <Button
                          text={'Previous'}
                          onClick={() => this.setState({ idx: this.state.idx - 1})}
                          bgColor="green"
                      />
                      <Button
                          text={'Next'}
                          onClick={() => this.setState({ idx: this.state.idx + 1})}
                          bgColor="green"
                      />
                    </div>
                  </div>
                  <div className="row">
                      <MathFigure 
                          fileName={figureFilenames[this.state.idx]}
                      />
                  </div>
                </div>
            </BlueGeometricBackground>
        );
    }
}
