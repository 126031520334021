import jp from "jsonpath"
import { LessonProgress, MaterialType, MistakeBankLessons } from "../../../../services/elearn/lesson-types"
import { getMaterialType } from "../../../../services/user/user-lesson-rest-interface"
import { getHumanTime } from "../../../../services/utils/date-util"
import { IAchievementMilestones, IStudentDashboardInfo, IStudentProgressInfo, ITopicMasteryInfo, LessonStatus } from "./types"
import { UserInfo } from "../../../../services/account/account-rest-interface"
import { IPracticeTestScores } from "../../../../services/elearn/practice-test-types"

//--- Achievement Threshold Values ---
const MILESTONE_THRESHOLDS_QUESTIONS_ANSWERED = [10, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1_000, 1_250, 1_500, 1_750, 2_000, 2_250, 2_500, 2_750, 3_000, 3_500, 4_000, 4_500, 5_000, 5_500, 6_000, 6_500, 7_000, 7_500, 8_000, 8_500, 9_000, 9_500, 10_000]
const MILESTONE_THRESHOLDS_HOURLY_STUDY_TIME  = [1, 2, 5, 10, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 175, 200, 250]
const MILESTONE_THRESHOLDS_LESSONS_COMPLETED  = [1, 3, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 79]
const MILESTONE_THRESHOLDS_TOPICS_MASTERED    = [1, 5, 10, 25, 50, 75, 100]

/**
 * Gets the student's dashboard related information including the summary of the progress
 * 
 * @param userInfo 
 * @param currentLesson 
 * @param studentLessons 
 * @param studentMistakes 
 * @param studentTestScores 
 * @param studentTonP 
 * @returns 
 */
export function getStudentDashboardInfo(userInfo: UserInfo, currentLesson: LessonProgress, studentLessons: LessonProgress[], studentMistakes: MistakeBankLessons[], studentTestScores: IPracticeTestScores, studentTonP): IStudentDashboardInfo {
    let studentProgressInfo = getStudentProgressInfo(studentLessons, studentTonP)
    let topicsMasteryInfo = getTopicsMasteryInfo(studentMistakes)
    let milestones = getAchievementMilestones(studentProgressInfo, topicsMasteryInfo)
    let studentDashbordInfo = {
        userInfo,
        currentLesson,
        studentLessons,
        studentMistakes,
        studentTestScores,
        studentTonP,
        summary: {
            milestones,
            lessons: studentProgressInfo.lessons,
            questions: studentProgressInfo.questions,
            studyTime: studentProgressInfo.studyTime,
            topics: {
                mastered: topicsMasteryInfo.mastered,
                review: topicsMasteryInfo.review,
                revisit: topicsMasteryInfo.revisit,
            },
        },
    }

    return studentDashbordInfo
}

/**
 * Determines the lessons status
 * @param lesson 
 * @returns 
 */
function determineLessonStatus(lesson: LessonProgress) {
    let type = lesson.currentLessonContent && lesson.currentLessonContent.type
    let lessonType = getMaterialType(type)

    if (lesson.currentLessonContent && lessonType === MaterialType.REPORT) {
        return LessonStatus.COMPLETED;
    }
    if (lesson.currentLessonContent && lessonType === MaterialType.POST_LESSON_QUESTION) {
        return LessonStatus.STARTED;
    }
    if (lesson.currentLessonContent && lesson.currentLessonContent.id) {
        return LessonStatus.STARTED;
    }
    return LessonStatus.NOT_STARTED;
}

/**
 * Finds the completed lessons
 * @param lessons 
 * @returns 
 */
function findLessonsCompleted(lessons: LessonProgress[]) {
    return lessons.filter(lesson => determineLessonStatus(lesson) === LessonStatus.COMPLETED)
}

/**
 * Finds the lessons by category (i.e. English, Math, Reading, Science)
 * @param lessons 
 * @param category 
 * @returns 
 */
function findLessonsByCategory(lessons: LessonProgress[], category: string): LessonProgress[] {
    let jpQuery = `$..[?(@.category.categoryId == "${category}")]`
    let categoricalLessons = jp.query(lessons, jpQuery)
    return categoricalLessons
}

/**
 * Finds the completed lessons for the given category
 * @param lessons 
 * @param category 
 * @returns 
 */
function findLessonsCompletedForCategory(lessons: LessonProgress[], category: string): LessonProgress[] {
    let selectedLessons = findLessonsByCategory(lessons, category)
    return findLessonsCompleted(selectedLessons)
}

/**
 * Finds the in progress lessons
 * @param lessons 
 * @returns 
 */
function findLessonsInProgress(lessons: LessonProgress[]) {
    return lessons.filter(lesson => determineLessonStatus(lesson) === LessonStatus.STARTED)
}

/**
 * Finds the study time from TonP
 * @param tonp 
 * @returns 
 */
function getStudyTimeInSeconds(tonp) {
    let timeStudiedJP = jp.query(tonp, "$..lessons.stats..timeStudied")
    let questionsAnswered = timeStudiedJP.reduce((acc, cur) => acc + cur, 0)
    return questionsAnswered
}

/**
 * Gets the student progress info
 * @param lessons 
 * @param tonp 
 * @returns 
 */
function getStudentProgressInfo(lessons: LessonProgress[], tonp): IStudentProgressInfo {
    let lessonsCompletedAll = findLessonsCompleted(lessons)
    let lessonsE = findLessonsByCategory(lessons, "English")
    let lessonsCompletedE = findLessonsCompleted(lessonsE)
    let lessonsM = findLessonsByCategory(lessons, "Math")
    let lessonsCompletedM = findLessonsCompleted(lessonsM)
    let lessonsR = findLessonsByCategory(lessons, "Reading")
    let lessonsCompletedR = findLessonsCompleted(lessonsR)
    let lessonsS = findLessonsByCategory(lessons, "Science")
    let lessonsCompletedS = findLessonsCompleted(lessonsS)
    let lessonsW = findLessonsByCategory(lessons, "Writing")
    let lessonsCompletedW = findLessonsCompleted(lessonsW)
    let lessonsInProgress = findLessonsInProgress(lessons)
    let lessonsLeftToStart = lessons.length - lessonsCompletedAll.length - lessonsInProgress.length
    let questionsTotal = getTotalQuestions(lessons)
    let questionsAnswered = getQuestionsAnswered(lessons)
    let studyTimeInSeconds = getStudyTimeInSeconds(tonp)    
    let result = {
        lessons: {
            total: lessons.length,
            completed: lessonsCompletedAll.length,
            inProgress: lessonsInProgress.length,
            leftToStart: lessonsLeftToStart,
            english: {
                total: lessonsE.length,
                completed: lessonsCompletedE.length
            },
            math: {
                total: lessonsM.length,
                completed: lessonsCompletedM.length
            },
            reading: {
                total: lessonsR.length,
                completed: lessonsCompletedR.length
            },
            science: {
                total: lessonsS.length,
                completed: lessonsCompletedS.length
            },
            writing: {
                total: lessonsW.length,
                completed: lessonsCompletedW.length
            }
        },
        questions: {
            total: questionsTotal,
            answered: questionsAnswered
        },
        studyTime: {
            seconds: studyTimeInSeconds,
            humanTime: getHumanTime(studyTimeInSeconds, false, true)
        }
    }

    return result
}

/**
 * Gets total number of questions
 * @param lessons 
 * @returns 
 */
function getTotalQuestions(lessons: LessonProgress[]): number {
    let total = lessons.reduce((acc, cur) => acc + cur.numTotalQuestions, 0)
    return total
}

/**
 * Gets the number of answered questions
 * @param lessons 
 * @returns 
 */
function getQuestionsAnswered(lessons: LessonProgress[]): number {
    let total = lessons.reduce((acc, cur) => acc + cur.numCompletedQuestions, 0)
    return total
}

/**
 * Gets the topic mastery summary info
 * @param mistakeBankOverviews 
 * @returns 
 */
function getTopicsMasteryInfo(mistakeBankOverviews: MistakeBankLessons[]): ITopicMasteryInfo {
    let masteredTopics = mistakeBankOverviews.filter(mbo => mbo.topicReviewStatus === "TOPIC MASTERED")
    let reviewTopics = mistakeBankOverviews.filter(mbo => mbo.topicReviewStatus === "REVIEW")
    let revisitTopics = mistakeBankOverviews.filter(mbo => mbo.topicReviewStatus === "REVIEW AGAIN")
    let result = {
        mastered: masteredTopics.length,
        review: reviewTopics.length,
        revisit: revisitTopics.length,
    }
    return result
}

/**
 * Gets the achievement milestones for the student based on the progress and topic mastery
 * @param studentProgressInfo 
 * @param topicsMasteryInfo 
 * @returns achievement milestones info
 */
export function getAchievementMilestones(studentProgressInfo: IStudentProgressInfo, topicsMasteryInfo: ITopicMasteryInfo): IAchievementMilestones {
    let questionsAnswered = studentProgressInfo.questions.answered
    let studyTimeInHours = studentProgressInfo.studyTime.humanTime.hours
    let lessonsCompleted = studentProgressInfo.lessons.completed
    let topicsMastered = topicsMasteryInfo.mastered
    let milestoneQuestionsAnswered = [...MILESTONE_THRESHOLDS_QUESTIONS_ANSWERED].sort((n1, n2) => n2 - n1).find(n => questionsAnswered >= n) || -1
    let milestoneStudyTime = [...MILESTONE_THRESHOLDS_HOURLY_STUDY_TIME].sort((n1, n2) => n2 - n1).find(n => studyTimeInHours >= n) || -1
    let milestoneLessonsCompleted = [...MILESTONE_THRESHOLDS_LESSONS_COMPLETED].sort((n1, n2) => n2 - n1).find(n => lessonsCompleted >= n) || -1
    let milestoneTopicsMastered = [...MILESTONE_THRESHOLDS_TOPICS_MASTERED].sort((n1, n2) => n2 - n1).find(n => topicsMastered >= n) || -1
    let milestoneQuestionsAnsweredNext = getNextMilestoneForQuestionsAnswered(milestoneQuestionsAnswered)
    let milestoneStudyTimeNext = getNextMilestoneForStudyTime(milestoneStudyTime)
    let milestoneLessonsCompletedNext = getNextMilestoneForLessonsCompleted(milestoneLessonsCompleted)
    let milestoneTopicsMasteredNext = getNextMilestoneForTopicsMastered(milestoneTopicsMastered)
    let isCompletedAllLessons = studentProgressInfo.lessons.completed === studentProgressInfo.lessons.total
    let isCompletedLessonsEnglish = studentProgressInfo.lessons.english.completed === studentProgressInfo.lessons.english.total
    let isCompletedLessonsMath = studentProgressInfo.lessons.math.completed === studentProgressInfo.lessons.math.total
    let isCompletedLessonsReading = studentProgressInfo.lessons.reading.completed === studentProgressInfo.lessons.reading.total
    let isCompletedLessonsScience = studentProgressInfo.lessons.science.completed === studentProgressInfo.lessons.science.total
    let isCompletedLessonsWriting = studentProgressInfo.lessons.writing.completed === studentProgressInfo.lessons.writing.total
    let result = {
        awardLevels: {
            questionsAnswered: milestoneQuestionsAnswered,
            questionsAnsweredNext: milestoneQuestionsAnsweredNext,
            studyTime: milestoneStudyTime,
            studyTimeNext: milestoneStudyTimeNext,
            lessonsCompleted: milestoneLessonsCompleted,
            lessonsCompletedNext: milestoneLessonsCompletedNext,
            topicsMastered: milestoneTopicsMastered,
            topicsMasteredNext: milestoneTopicsMasteredNext,
        },
        completedLessons: {
            all: isCompletedAllLessons,
            english: isCompletedLessonsEnglish,
            math: isCompletedLessonsMath,
            reading: isCompletedLessonsReading,
            science: isCompletedLessonsScience,
            writing: isCompletedLessonsWriting,
        }
    }

    return result
}

/**
 * Gets the next milestone from the milestones array
 * If the current milestone is already the last one then it returns as -1 (sentinel value)
 * It means there is no more milestone available in the area
 * 
 * PS: Assumption is the milestones are monotonically increasing
 * 
 * @param milestones 
 * @param currentMilestone 
 * @returns the next milestone (if the current the last one then it returns -1)
 */
export function getNextMilestone(milestones: number[], currentMilestone: number) {
    let index = milestones.findIndex(n => n === currentMilestone)
    let nextIndex = index + 1
    let nextMilestone = milestones[nextIndex] || -1
    return nextMilestone
}

/**
 * Returns the next milestone for questions answered
 * 
 * @param currentMilestone 
 */
export function getNextMilestoneForQuestionsAnswered(currentMilestone) {
    return getNextMilestone(MILESTONE_THRESHOLDS_QUESTIONS_ANSWERED, currentMilestone)
}

/**
 * Returns the next milestone for study time
 * 
 * @param currentMilestone 
 * @returns 
 */
export function getNextMilestoneForStudyTime(currentMilestone) {
    return getNextMilestone(MILESTONE_THRESHOLDS_HOURLY_STUDY_TIME, currentMilestone)
}

/**
 * Returns the next milestone for lessons completed
 * 
 * @param currentMilestone 
 * @returns 
 */
export function getNextMilestoneForLessonsCompleted(currentMilestone) {
    return getNextMilestone(MILESTONE_THRESHOLDS_LESSONS_COMPLETED, currentMilestone)
}

/**
 * Returns the next milestone for topics mastered
 * 
 * @param currentMilestone 
 * @returns 
 */
export function getNextMilestoneForTopicsMastered(currentMilestone) {
    return getNextMilestone(MILESTONE_THRESHOLDS_TOPICS_MASTERED, currentMilestone)
}

