import './VideoDots.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';

interface Props {
    video: string;
    active: boolean;
    onClick: (video: string) => any;
    onHover: (topic: string) => any;
    lesson: string;
}

interface State {

}


export default class VideoDots extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    public render() {

        return (
            <li
                className={this.props.active ? 'active-video' : ''}
                onClick={() => this.props.onClick(this.props.video)}
                onMouseOver={() => this.props.onHover(this.props.lesson)}
            >
                <span className="popover--dot"> {this.props.lesson}</span>
                <button />
            </li>
        );
    }
}
