import React from 'react'
import "./index.css"

interface HeadingBannerProps {
  title: string;
  section?: string;
}

const HeadingBanner = ({title, section}: HeadingBannerProps) => {
  let colorClass = ''
  if (section) {
    colorClass = 'theme-heading theme-heading'
    if (section.includes('english')) {
      colorClass += '--english'
    } else if (section.includes('math')) {
      colorClass += '--math'
    } else if (section.includes('reading')) {
      colorClass += '--reading'
    } else if (section.includes('science')) {
      colorClass += '--science'
    } else if (section.includes('writing')) {
      colorClass += '--writing'
    }
  }
  return (
    <div className={`heading-banner-basic ${colorClass}`}>
      <h2 className="heading-banner-basic-title mb-0">{title}</h2>
    </div>
  )
}

export default HeadingBanner