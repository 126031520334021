import React, { Component } from 'react'
import { TeacherStore, ITeacherStoreData } from '../../../stores/teacher.store'
import { StudentLessonsSummary } from './StudentLessonsSummary'
import { LessonData, TimeOnPlatformLessonProcessor } from './top-lesson-processor'
import { StudentData } from '../types'
import { Selections, StudentProfileWithLessons } from './types'
import ReportLoadingMessage from '../ReportLoadingMessage'
import { debounce } from '../../../services/utils/debounce'
import { getCachedValue, setCacheValue } from '../../../services/utils/cache-util'
import { NO_STUDENT_INFO_FOUND } from '../info'
import { ResetLessonDialogModal } from './ResetLessonDialogModal'
import PracticeReportsModal from './PracticeReportsModal'
import { resetStudentLesson } from '../../../services/elearn/teacher/teacher-student-lesson-reset'
import { NOT_SPECIFIED } from '../../../constants'
import './TimeOnPlatformIndividualView.css'

interface Props { 
  selections: Selections
  studentsData: StudentData[]
}

interface State {
  studentLessonsInfo: any[]
  timeOnPlatformInfo: any[]
  loaded: boolean
  studentsProfilesWithLessons: any[]
  showResetDialogModal: boolean
  lessonDataToReset?: LessonData
  lessonDataResetForStudentEmail?: string
  lessonDataResetForStudentFullName?: string
  forceRecalculate: boolean
  showPracticeReportsModal: boolean
  selectedStudentEmailAddress?: string
}

export default class TimeOnPlatformIndividualView extends Component<Props, State> {
  timeOnPlatformLessonProcessor = new TimeOnPlatformLessonProcessor()
  unsubscribe
  
  constructor(props: Props) {
    super(props)
    this.state = {
      timeOnPlatformInfo: [],
      studentLessonsInfo: [],
      loaded: false,
      studentsProfilesWithLessons: [],
      showResetDialogModal: false,
      forceRecalculate: false,
      showPracticeReportsModal: false
    }    
  }

  async componentDidMount() {
    TeacherStore.loadTimeOnPlaformInfoForAllStudents()
    TeacherStore.loadLessonInfoForAllStudents()
    this.unsubscribe = TeacherStore.subscribe(d => {
      this.debouncedSetMainData(d)
    })

    let cachedData = this.getStudentsProfilesWithLessonsFromCache()
    if (cachedData) {
      this.setMainData(TeacherStore.data)
    }
    else {
      this.debouncedSetMainData(TeacherStore.data)
    }
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  componentDidUpdate(prevProps, prevState) {
    let propsStr = JSON.stringify(this.props)
    let prevPropsStr = JSON.stringify(prevProps)
    let calculationNeeded = false

    if (propsStr != prevPropsStr) {
      calculationNeeded = true
    }
    else {
      let stateStr = JSON.stringify(this.state)
      let prevSteteStr = JSON.stringify(prevState)

      if (stateStr != prevSteteStr) {
        calculationNeeded = true
      }
    }

    if (calculationNeeded) {
      let {studentsData, selections} = this.props
      let {studentLessonsInfo, timeOnPlatformInfo, forceRecalculate} = this.state

      if (studentLessonsInfo.length > 0) {
        let studentsProfilesWithLessons = this.getStudentsProfilesWithLessonsFromCache()

        if (!studentsProfilesWithLessons || forceRecalculate) {
          // We don't have the info in the cache yet, therefore calculate it
          studentsProfilesWithLessons = this.getStudentsProfilesWithLessons(studentsData, studentLessonsInfo, timeOnPlatformInfo, selections)

          // If we have students profiles with lessons and time on platform info save it to the cache!
          if (timeOnPlatformInfo.length > 0 && studentsProfilesWithLessons.length > 0) {
            this.saveStudentsProfilesWithLessonsToCache(studentsProfilesWithLessons)
          }          
        }

        this.setState({ studentsProfilesWithLessons, loaded: true, forceRecalculate: false })
      }
    }
  }
  
  setMainData = (data: ITeacherStoreData) => {
    let { studentLessonsInfo, timeOnPlatformInfo } = data

    this.setState({ 
      timeOnPlatformInfo,
      studentLessonsInfo,
    })   
  }

  debouncedSetMainData = debounce(d => this.setMainData(d))

  private saveStudentsProfilesWithLessonsToCache(studentsProfilesWithLessons) {
    let key = this.makeCacheKey()
    setCacheValue(key, studentsProfilesWithLessons)
  }

  private getStudentsProfilesWithLessonsFromCache() {
    let key = this.makeCacheKey()
    return getCachedValue(key)
  }

  private makeCacheKey() {
    let uniqueEntityName = "tonp-individual-view-students-profiles-with-lessons"
    let { selections } = this.props
    let {selectedSchoolName, selectedGraduationYear, selectedPeriod, selectedTimeRange, searchQuery} = selections
    let emptyKey = "-"
    let schoolKey = selectedSchoolName || emptyKey
    let gradYearKey = selectedGraduationYear || emptyKey
    let periodKey = selectedPeriod || emptyKey
    let selectedTimeRangeKey = selectedTimeRange || "all-time"
    let searchQueryKey = searchQuery || emptyKey
    let cacheKey = `${uniqueEntityName}-${schoolKey}${gradYearKey}${periodKey}${selectedTimeRangeKey}-${searchQueryKey}`
    return cacheKey
  }

  private getStudentsProfilesWithLessons(studentsProfiles: StudentData[], studentsLessons, studentsTimeOnPlatform, selections: Selections) {
    let {selectedSchoolName, selectedGraduationYear, selectedPeriod, selectedTimeRange, selectedTestKind="ACT"} = selections
    let dateStart = new Date()
    let dateEnd = new Date()
    let nofDaysToGoBack = 3650 // Sufficient days to go back for an early start
    
    if (selectedTimeRange) {
      switch (selectedTimeRange) {
        case "all-time":
          break
        case "last-week":
          nofDaysToGoBack = 7
          break
        case "last-month":
          nofDaysToGoBack = 30
          break
      }
    }
    dateStart.setDate(dateStart.getDate() - nofDaysToGoBack)
    let selectedData = studentsProfiles.filter(d => {
      let selectStudent = true
      let period = d.period || NOT_SPECIFIED
      if ((selectedGraduationYear && selectedGraduationYear !== d.graduationYear) ||
          (selectedSchoolName && selectedSchoolName !== d.schoolName) ||
          (selectedPeriod && selectedPeriod !== period)) {
        selectStudent = false
      }
      return selectStudent
    })
    
    let filteredStudentsLessonsInfo: any[] = []
    let filteredStudentsTimeOnPlatformInfo: any[] = []
    
    selectedData.forEach(student => {
      let email = student.emailAddress
      let userId = student.id

      studentsLessons.forEach(sl => {
        if (sl.userId == userId) {
          filteredStudentsLessonsInfo.push(sl)
        }
      })
      studentsTimeOnPlatform.forEach(st => {
        if (st.email == email) {          
          filteredStudentsTimeOnPlatformInfo.push(st)
        }
      })
    })

    this.timeOnPlatformLessonProcessor.load(filteredStudentsLessonsInfo, filteredStudentsTimeOnPlatformInfo)
    let studentsInfo = this.timeOnPlatformLessonProcessor.getLessonsByStudents(dateStart, dateEnd)

    function combineProfilesWithLessons(email: string, studentsProfiles, studentsInfo): StudentProfileWithLessons {
      let lessons = studentsInfo[email]
      let studentProfile = studentsProfiles.find(s => s.emailAddress.toLowerCase() == email.toLowerCase())
      let studentFullInfo = {...studentProfile, lessons}
      return studentFullInfo
    }

    let studentEmails = Object.keys(studentsInfo)
    let studentsFullInfo = studentEmails.map(email => combineProfilesWithLessons(email, studentsProfiles, studentsInfo))

    // Sort the students by their names
    studentsFullInfo.sort((s1, s2) => {
      let name1 = `${s1.lastName.toLowerCase()} ${s1.firstName.toLowerCase()}`
      let name2 = `${s2.lastName.toLowerCase()} ${s2.firstName.toLowerCase()}`
      return name1 > name2 ? 1: name1 < name2 ? -1: 0
    })

    return studentsFullInfo
  }

  resetSelectedLesson = () => {
    let {lessonDataToReset, lessonDataResetForStudentEmail, studentLessonsInfo} = this.state
    let selectedStudentLessonsInfo = studentLessonsInfo.filter(s => s.emailAddress === lessonDataResetForStudentEmail)[0]

    if (lessonDataResetForStudentEmail && selectedStudentLessonsInfo) {
      let lesson = selectedStudentLessonsInfo.lessons.filter(lesson => lesson.lessonId === lessonDataToReset?.lessonId)[0]
      
      if (lesson) {
        let studentEmail = lessonDataResetForStudentEmail
        let {lessonName, lessonId} = lesson

        // We need to remotely reset the lesson via backend
        // if successful then we also need to remove the lesson from student's list in the UI
        resetStudentLesson(studentEmail, lessonId)
          .then(d => {
            let remainingLessons = selectedStudentLessonsInfo.lessons.filter(lesson => lesson.lessonId !== lessonDataToReset?.lessonId)
            selectedStudentLessonsInfo.lessons = remainingLessons
            
            // Reset the state to reflect changes and close the modal dialog
            this.setState({
              showResetDialogModal: false,
              lessonDataToReset: undefined,
              lessonDataResetForStudentEmail: undefined,
              lessonDataResetForStudentFullName: undefined,
              studentLessonsInfo,
              forceRecalculate: true
            })
          })
          .catch(() => {
            let errInfo = `Cannot reset lesson "${lessonName}" for student ${lessonDataResetForStudentEmail}`
            alert(errInfo)
            this.setState({
              showResetDialogModal: false,
              lessonDataToReset: undefined,
              lessonDataResetForStudentEmail: undefined,
              lessonDataResetForStudentFullName: undefined,
            })
          })
      }
    }
    else {
      // This shouldn't happen!      
      let errInfo = `Student info cannot be found!`
      alert(errInfo)
    }
  }

  handleCancelOrClose = () => {
    this.setState({showResetDialogModal: false})
  }

  handleResetLessonRequest = (studentEmail: string, studentFullName: string, lessonData: LessonData) => {
    this.setState({
      lessonDataToReset: lessonData,
      lessonDataResetForStudentEmail: studentEmail,
      lessonDataResetForStudentFullName: studentFullName,
      showResetDialogModal: true
    })
  }

  render() {
    let {selections} = this.props
    let {selectedTestKind="ACT"} = selections
    let {lessonDataToReset, lessonDataResetForStudentEmail, lessonDataResetForStudentFullName} = this.state
    let {studentsProfilesWithLessons, loaded, showPracticeReportsModal, selectedStudentEmailAddress} = this.state

    return (
      <div>
        {!loaded && <ReportLoadingMessage />}
        {loaded && studentsProfilesWithLessons.length == 0 ?
          <div>{NO_STUDENT_INFO_FOUND}</div>
          :
          <>            
            {studentsProfilesWithLessons.map((studentProfileWithLessons,i) => 
              <StudentLessonsSummary key={`i-${i}`}
                  studentInfo={studentProfileWithLessons}
                  selectedTestKind={selectedTestKind}
                  onShowPracticeReportsModal={(selectedStudentEmailAddress) => this.setState({ selectedStudentEmailAddress, showPracticeReportsModal: true})}
                  onResetLessonRequest={this.handleResetLessonRequest}/>
              )
            }

            <ResetLessonDialogModal 
              show={this.state.showResetDialogModal}
              onReset={this.resetSelectedLesson}
              onCancel={this.handleCancelOrClose}
              onClose={this.handleCancelOrClose}>
              <div className="reset-warning-container">
                <div className="warning-section1 text-center">
                  <h2 className="mb-4 py-3" style={{ fontWeight : 800}}>ALERT: THIS ACTION CANNOT BE UNDONE.</h2>
                  <h5 className="mb-4">You are about to permanently delete and reset <br className="d-none d-md-inline-block"/><a className="student-info-link" href={`mailto:` + lessonDataResetForStudentEmail} title={lessonDataResetForStudentEmail}><span className="student-info">{lessonDataResetForStudentFullName}'s</span></a> data history for the lesson <span className="lesson-name">{lessonDataToReset?.lessonName}</span>.</h5>
                  <h5 className="mb-4">Are you sure you want to reset this lesson?</h5>
                </div>
              </div>
            </ResetLessonDialogModal>
          

            {showPracticeReportsModal && <PracticeReportsModal
              show={showPracticeReportsModal}
              studentEmail={selectedStudentEmailAddress}
              onClose={() => this.setState({ showPracticeReportsModal: false})}/>
            }
          </>
        }
      </div>
    )
  }
}
