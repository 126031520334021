import './MCAnswerMistakeBank.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import Checkbox from '../../../../../Checkbox/Checkbox';

export interface Props {
  /* Called once a user has finished this question */
  // onNextQuestion: () => void;
  // addHintToMistakeBank: (hint: string, display: boolean) => void;
  addToMistakeBank: (add: boolean) => void;
}

interface State {
  hintSet: boolean;
  checked: boolean;
}

export interface FieldState {
    key: string;
    value?: any;
}

type AllProps = Props;


export default class MCAnswerMistakeBank extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            hintSet: false,
            checked: false
        };
    }

    public render() {
        const { checked } = this.state;
        return (
          <div className={' hint-container'}>
              <div className="hint--text">
                  {!this.state.hintSet && <span>
                  <div className="hint-header row">
                      <div className="col-lg-12">
                          <p className="header-text full-width"> Want to save the question? </p>
                      </div>
                  </div>
                  <Checkbox
                      checked={checked}
                      onCheck={this.onCheck}
                      labelText="Add this question to my Mistake Bank"
                      checkboxClass="mc-answer-hint__checkbox"
                  />
                  </span>}
              </div>
          </div>
        );
    }

     @boundMethod
     private onCheck() {
         this.setState({ checked: !this.state.checked }, () => {
            this.props.addToMistakeBank(this.state.checked);
         });
     }
}
