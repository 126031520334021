import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../../../store';
import { LessonViewSwitch } from './LessonViewSwitch';

const mapStateToProps = ({ account }: AppState, ownProps: any) => {
    return {
      ...ownProps,
      userInfo: account.userInfo
    };
};

const ConnectedLessonViewSwitch = connect(mapStateToProps, {})(LessonViewSwitch);

export default function(props: any) {
    return <ConnectedLessonViewSwitch {...props} />;
}
