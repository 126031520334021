import React from 'react'
import "./index.css"

interface Props {
  searchQuery: string
  placeholder?: string
  handleSearch: (query: string) => void
  clearSearch: () => void
}

export default function SimpleSearchBox({searchQuery, handleSearch, clearSearch, placeholder="Search here..."}: Props) {
  return (
    <div className="simple-search-box">
      <input 
        className="input-search"
        type="text" 
        placeholder={placeholder} 
        value={searchQuery}
        onChange={e => handleSearch(e.target.value)} /> 
      <button title="Clear search" className="btn-simple simple-search-clear-btn" onClick={clearSearch}>x</button>
    </div>
  )
}
