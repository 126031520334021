import * as React from "react"
import { IFaq } from "./types"
import { CollapsableButton } from "../../../components/CollapsableButton"
import { faqsData } from "./faqs.data"

let faqs: IFaq[] = faqsData

interface FaqCardProps {
    faq: IFaq
}
interface FaqCardState {
    showDetails: boolean
}
class FaqCard extends React.Component<FaqCardProps, FaqCardState> {
    state = {
        showDetails: false
    }

    toggleDetails = () => {
        let { showDetails } = this.state
        this.setState({showDetails: !showDetails})
    }

    render() {
        let { faq } = this.props
        let { showDetails } = this.state
        let { question, answer } = faq

        return (
            <div className="faq-card">
                <div className="faq-header">
                    <button className="btn-div" onClick={this.toggleDetails}>
                        <CollapsableButton collapsed={showDetails} />
                        <div className={`faq-question ${!showDetails ? "partial": ""}`}>
                            {question}
                        </div>
                    </button>
                </div>

                {showDetails ? 
                    <div className="faq-answer">
                        <div dangerouslySetInnerHTML={{__html: answer}}/>
                    </div>
                    :
                    ""}
            </div>
        )
    }
}

function FaqGroupPanel(props: { title: string, faqs: IFaq[] }) {
    const { title, faqs } = props
    
    return (
        <div key={title} className="faq-group-panel-container">
            <div className="faq-group-title">{title}</div>
            <hr/>
            <div>
                {faqs.map((d, i) => {
                    return (
                        <div key={d.question}>
                            <FaqCard key={d.question} faq={d} />
                            {i != faqs.length-1 ? <div className="faq-separator"/>: ""}
                        </div>
                    )}
                )}
            </div>
        </div>
    )
}


export class FAQs extends React.Component {
    state = {
        categoryMap: {},
        categoryKeys: []
    }

    componentDidMount() {
        let categoryMap = {}
        faqs.forEach(r => {
            let key = r.category
            let items = categoryMap[key]
            if (!items) {
                items = []
                categoryMap[key] = items
            }
            items.push(r)
        })
        let categoryKeys = Object.keys(categoryMap)
        categoryKeys.sort((k1, k2) => k1 > k2 ? 1: k1 < k2 ? -1: 0)

        this.setState({categoryMap, categoryKeys})
    }

    render() {
        let { categoryMap, categoryKeys} = this.state

        return (
            <div>
                <div>
                    {categoryKeys.map(category =>
                        <FaqGroupPanel
                            key={category}
                            title={category}
                            faqs={categoryMap[category]}
                        />
                    )}
                </div>
            </div>
        )
    }
}
