import './MistakeBankQuestionPage.css';

import * as React from 'react';
import * as _ from 'lodash';
import { boundMethod } from 'autobind-decorator';

import { HintList, LessonProgress, Question, QuestionGroup } from '../../../../../../services/elearn/lesson-types';

import ContextualTitle from '../../../../../../components/ContextualTitle/ContextualTitle';
import MultipleChoicePassage from '../../../../../../components/MultipleChoicePassage/MultipleChoicePassage';
import MultipleChoiceQuestion from '../../../../../../components/MultipleChoiceQuestion/MultipleChoiceQuestion';
import { PageContent } from '../../../../../../components/PageContent/PageContent';
import QuestionProgressBarv2 from '../../../../../../components/QuestionProgressBarv2/QuestionProgressBarv2';
import { UserInfo } from '../../../../../../services/account/account-rest-interface';
import { addQuestionToMistakeBank } from '../../../../../../services/elearn/lesson-rest-interface';
import { updateActivityInfoWithLastKnownNavigation } from '../../../../../../WinwardTracker';
import { TimerState } from '../../../../../../components/Timer/Timer';
import { SimpleTimer } from '../../../../../../services/utils/simple-timer';
import { Context, Resource, UserActivityEx, sendUserActivity, sendUserActivityWithDelay } from '../../../../../../services/liveview/liveview-service';
import { TIMEOUT_IN_SECONDS } from '../../../../../../constants';
import HelperStudyBuddies from "../../../../StudyBuddies/HelperStudyBuddies"

export interface Props {
    topicId: string;
    lessonId: string;
    /* Fired once all questions have been answered */
    onComplete: () => void;
    /* Called when the user hits the "Exit Lesson" button */
    handleExitLesson: () => void;

    questionGroups: QuestionGroup;
    ssQuestionGroups: QuestionGroup;
    /* User's current content (question/topic) */
    startingQuestionId: string;
    hint: HintList;
    category: string;
    canNext: boolean;
}

export interface DispatchProps {
    setTopicQuestionAsReviewed: (lessonId: string, topicId: string, questionId: string, answerLetter: string, userInfo: any) => Promise<any>;
    setTopicQuestionAsReviewedInSS: (lessonId: string, topicId: string, questionId: string, answerLetter: string) => Promise<any>;
}

export interface StoreProps {
    // topic?: MistakeBankLessons;
    allLessons: LessonProgress[];
    userInfo: UserInfo;
}

export type AllProps = Props & DispatchProps & StoreProps & DispatchProps;

interface State {
    currentQuestionGroupIndex: number;
    /* The question a user is currently viewing/answering */
    currentQuestionIndex: any;
    /* Furthest back a user can look */
    minQuestion: any;
    /* Furthest question index that a user has gotten to (in case they go back to look at an old question) */
    farthestQuestionIndex: any;
    /* Used to track if the user has already answered this question or not, since we only record the first answer */
    firstSubmit: boolean;
    /* Questions from props.questionGroups, except flattened out into a single array */
    questions: Question[];
    ssQuestions: Question[];
     /* hints for questions */
    hintList: HintList;
    category: string;
    canNext: boolean;
}


export default class MistakeBankQuestionPage extends React.Component<AllProps, State> {
    simpleTimerQuestion = new SimpleTimer()
    simpleTimerHint = new SimpleTimer()
    simpleTimerVideo = new SimpleTimer()
    answered = 0 // Answered the question in seconds
    attempts = 0 // Number of attempts till finding the correct answer for current question
    isCorrectlyAnswered = false // Is current question correctly answered?     
    videoStarted = false
    
    constructor(props: AllProps) {
        super(props);
        
        let questions;
        let ssQs, lessQs;
        if (!_.isEmpty(props.questionGroups) && !_.isEmpty(props.ssQuestionGroups)) {
            lessQs = props.questionGroups.questions.sort((a, b) => (a.questionNumber! - b.questionNumber!)) || [];
            ssQs = props.ssQuestionGroups.questions.sort((a, b) => (a.questionNumber! - b.questionNumber!)) || [];
            questions = lessQs.concat(ssQs);
        } else if (!_.isEmpty(props.questionGroups)) {
            lessQs = props.questionGroups.questions.sort((a, b) => (a.questionNumber! - b.questionNumber!));
            questions = lessQs;
        } else {
            ssQs = props.ssQuestionGroups.questions.sort((a, b) => (a.questionNumber! - b.questionNumber!));
            questions = ssQs;
        }
        
        // const questions = props.questionGroups.questions.sort((a, b) => (a.questionNumber! - b.questionNumber!));
        const { questionIndex, groupIndex } = this.getCurrentQuestionIndex(props, questions);
        
        this.state = {
            currentQuestionGroupIndex: groupIndex,
            currentQuestionIndex: questionIndex,
            minQuestion: questionIndex,
            farthestQuestionIndex: questionIndex,
            firstSubmit: true,
            hintList: this.props.hint,
            questions: lessQs || [],
            ssQuestions: ssQs || [],
            category: '',
            canNext: false
        };

        const currQId = this.state.currentQuestionIndex;
        sessionStorage.setItem('currQId', currQId);
    }

    public componentDidMount() {
        window.addEventListener('beforeunload', this.onWindowUnload);

        const { questionGroups } = this.props;
        // const { questions } = this.state;
        const currQId = this.state.currentQuestionIndex;
        sessionStorage.setItem('currQId', currQId);
        if(!questionGroups) { return; }
    }

    public componentWillUnmount() {
        window.removeEventListener('beforeunload', this.onWindowUnload);
    }

    public render() {
        const { currentQuestionIndex, currentQuestionGroupIndex, farthestQuestionIndex, canNext } = this.state;
        const { questionGroups, category } = this.props;
        const { questions, ssQuestions } = this.state;

        const currQId = this.state.currentQuestionIndex;
        const pathArray = window.location.pathname.split('/');
        const lessonId = pathArray[pathArray.length - 2];
        const topicId = pathArray[pathArray.length - 1];
        sessionStorage.setItem('topicId', topicId);
        sessionStorage.setItem('currQId', currQId);
        sessionStorage.setItem('lessonId', lessonId);
        if(!questionGroups) {
            return null;
        }
        if(currentQuestionIndex < 0 || currentQuestionIndex >= questions.length) {
            return <h1>Could not get question</h1>;
        }

        const currentQuestionGroup = questionGroups;
        const currentQuestion = questions.find(q => q.questionId === currentQuestionIndex) || 
            ssQuestions.find(q => q.questionId === currentQuestionIndex) || questions[0];
        const topicTitle = currentQuestion.topicName;

        let useMathFont = category === 'Math';

        let qIdx, fIdx, isLastQuestion;
        if (questions.length > 0 && ssQuestions.length > 0) {
            let allQuestions = questions.concat(ssQuestions);
            qIdx = allQuestions.findIndex(q => q.questionId === currentQuestionIndex);
            fIdx = allQuestions.findIndex(q => q.questionId === farthestQuestionIndex);
            isLastQuestion = (qIdx + 1) === allQuestions.length;

        } else {
            qIdx = questions.findIndex(q => q.questionId === currentQuestionIndex);
            isLastQuestion =  (qIdx + 1) === questions.length;
            if (qIdx === -1 && ssQuestions) {
                qIdx = ssQuestions.findIndex(q => q.questionId === currentQuestionIndex);
                isLastQuestion =  (qIdx + 1) === ssQuestions.length;
            } else if (qIdx === -1) {
                qIdx = 0;
            }
    
            fIdx = questions.findIndex(q => q.questionId === farthestQuestionIndex);
            if (fIdx === -1 && ssQuestions) {
                fIdx = ssQuestions.findIndex(q => q.questionId === farthestQuestionIndex);
            } else if (fIdx === -1) {
                fIdx = 0;
            }
        }
        
        let currentLesson = this.props.allLessons.find(l => l.lessonId === this.props.lessonId)        
        let activity = `${currentLesson?.category.categoryName}: ${currentLesson?.lessonName} ${topicTitle}`
        
        updateActivityInfoWithLastKnownNavigation(activity)
        HelperStudyBuddies.setCurrentLessonId(lessonId)

        return (
            <PageContent preContentComponent={this.getQuestionProgressBar()}>

                <ContextualTitle title={topicTitle} preText={'Now Learning - Mistake Bank'} />

                {currentQuestionGroup.questionGroupInfo ?
                    <MultipleChoicePassage
                        questions={questions}
                        questionGroup={currentQuestionGroup}
                        currentQuestionGroupIndex={currentQuestionGroupIndex}
                        currentQuestionIndex={qIdx}
                        farthestQuestionIndex={fIdx}
                        onAnswer={this.handlePassageAnswer}
                        onNextPassage={this.handleNextPassage}
                        onGoToQuestion={this.handleNextQuestion}
                        isMistakeBank={true}
                        addHint={this.addHint}
                        hintText={this.state.hintList[currentQuestion.questionId]}
                        useMathFont={useMathFont}
                        isTeacher={false}
                        canNext={this.state.canNext}
                        timerState={TimerState.HIDDEN}
                        onVideoStart={this.onVideoStart}
                    /> :
                    <MultipleChoiceQuestion
                        question={currentQuestion}
                        onAnswer={this.handleAnswer}
                        onNextQuestion={this.handleNextQuestion}
                        isBaseline={false}
                        userHasAnswered={qIdx < fIdx}
                        isMistakeBank={true}
                        addHint={this.addHint}
                        hintText={this.state.hintList[currentQuestion.questionId]}
                        isLastQuestion={isLastQuestion}
                        useMathFont={useMathFont}
                        isTeacher={false}
                        canNext={this.state.canNext}
                        timerState={TimerState.HIDDEN}
                        onVideoStart={this.onVideoStart}
                    />}

                {this.props.children}
            </PageContent>
        );
    }

    @boundMethod
    private addHint(hint: string) {
        this.sendStatsForHint()
        const currentLesson = this.props.allLessons
            .find(l => l.lessonId === this.props.lessonId);

        let ulQuestion = false;
        for (let i = 0; i < this.state.questions.length; i++) {
            if (this.state.questions[i].questionId === this.state.currentQuestionIndex) {
                ulQuestion = true;
            }
        }

        if (!ulQuestion) {
            let idx = -1;
            let ssQuestions = this.state.ssQuestions;
            for (let i = 0; i < ssQuestions.length; i++) {
                if (ssQuestions[i].questionId === this.state.currentQuestionIndex) {
                    idx = i;
                }
            }
            addQuestionToMistakeBank(currentLesson || {} as LessonProgress,
                ssQuestions[idx], hint, this.props.userInfo).then(
                    () => {
                        let tmpHints = this.state.hintList;
                        tmpHints[ssQuestions[idx].questionId] = hint;
                        this.setState({hintList: tmpHints});
                    });
        } else {
            let idx = -1;
            let questions = this.state.questions;
            for (let i = 0; i < questions.length; i++) {
                if (questions[i].questionId === this.state.currentQuestionIndex) {
                    idx = i;
                }
            }
            addQuestionToMistakeBank(currentLesson || {} as LessonProgress,
                questions[idx], hint, this.props.userInfo).then(
                    () => {
                        let tmpHints = this.state.hintList;
                        tmpHints[questions[idx].questionId] = hint;
                        this.setState({hintList: tmpHints});
                    });
        }
    }

    private getQuestionProgressBar() {
        if(!this.state.questions && !this.state.ssQuestions) {
            return;
        }
        return (
            <QuestionProgressBarv2
                questionNumbers={this.state.questions}
                ssQuestionNumbers={this.state.ssQuestions}
                currentQuestionIndex={this.state.currentQuestionIndex}
                farthestQuestionIndex={this.state.farthestQuestionIndex}
                onCloseClick={this.handleExitLesson}
                onQuestionClick={this.goToQuestion}
                isTeacher={false}
                exitText="Exit Mistake Bank"
            />
        );
    }

    @boundMethod
    private handlePassageAnswer(questionIndex: number, isCorrect: boolean, answerIndex: number) {
        this.onQuestionAnswered(this.state.currentQuestionIndex, isCorrect, answerIndex);
    }

    @boundMethod
    private handleAnswer(isCorrect: boolean, answerIndex: number) {
        this.onQuestionAnswered(this.state.currentQuestionIndex, isCorrect, answerIndex);
    }

    private onQuestionAnswered(questionIndex: number, isCorrect: boolean, answerIndex: number) {
        const { lessonId, topicId, userInfo } = this.props;
        const { farthestQuestionIndex } = this.state;
        const currentQuestionId = questionIndex + '';
        let answerLetter = ['A', 'B', 'C', 'D'][answerIndex];
        const currentQuestion = this.state.questions[questionIndex];
        if (questionIndex === farthestQuestionIndex) {
            this.props.setTopicQuestionAsReviewed(lessonId, topicId, currentQuestionId, answerLetter, userInfo);
            this.attempts++
            
            // Capture the time of answering the question (only first time)
            if (this.answered === 0) {
                this.answered = this.simpleTimerQuestion.diffInSeconds()
            }

            if(isCorrect) {
                this.isCorrectlyAnswered = true
                this.setState({
                    canNext: true
                }, () => {
                    this.onQuestionComplete(questionIndex);
                });
                
            }
        } else {
            this.handleNextQuestion();
        }
    }

    /**
     * Go to next question
     */
    @boundMethod
    private handleNextQuestion() {
        const { currentQuestionIndex, questions, ssQuestions } = this.state;
        this.sendStatsForQuestion()

        if (questions.length > 0 && ssQuestions.length > 0) {
            let idx = questions.findIndex(q => q.questionId === currentQuestionIndex);
            if (idx !== -1) {
                if ((idx + 1) === questions.length) {
                    this.goToQuestion(ssQuestions[0].questionId);
                } else {
                    this.goToQuestion(questions[idx + 1].questionId);
                }
            } else {
                let questionIdx = ssQuestions.findIndex(q => q.questionId === currentQuestionIndex);
                if ((questionIdx + 1) === ssQuestions.length) {
                    this.props.onComplete();
                } else {
                    this.goToQuestion(ssQuestions[questionIdx + 1].questionId);    
                }
            }
        } else if (questions.length > 0) {
            let questionIdx = questions.findIndex((q) => {
                return q.questionId === currentQuestionIndex;
            });
    
            if ((questionIdx + 1) === questions.length) {
                this.props.onComplete();
            } else {
                if (questionIdx === -1) {
                    questionIdx = ssQuestions.findIndex((q) => {
                        return q.questionId === currentQuestionIndex;
                    });
                    this.goToQuestion(ssQuestions[questionIdx + 1].questionId);
                } else {
                    this.goToQuestion(questions[questionIdx + 1].questionId);
                }
            }
        } else if (ssQuestions.length > 0) {
            let questionIdx = ssQuestions.findIndex((q) => {
                return q.questionId === currentQuestionIndex;
            });

            if ((questionIdx + 1) === ssQuestions.length) {
                this.props.onComplete();
            } else {
                this.goToQuestion(ssQuestions[questionIdx + 1].questionId);
            }
        } else {
            console.log('Error on progression for mistake bank question page');
        }
    }

    private handleNextPassage() {
        // TODO
    }

    /**
     * Navigate to a particular question by index
     */
    @boundMethod
    private goToQuestion(questionId: string) {
        const { questions, ssQuestions } = this.state;
        // TODO: THIS NEEDS TO CHANGE
        if(questionId === '') {
            return;
        }

        let index = questions.findIndex((q) => {
            return q.questionId === questionId;
        });
        
        if (index > -1) {
            this.setState({
                currentQuestionIndex: questions[index].questionId,
                // farthestQuestionIndex: questions[index].questionId,
                currentQuestionGroupIndex: this.getGroupIndexForQuestion(this.props.questionGroups, index),
                firstSubmit: true,
                canNext: false
            });
            const currQId = this.state.currentQuestionIndex;
            sessionStorage.setItem('currQId', currQId);        }
        if (index === -1) {
            index = ssQuestions.findIndex(q => q.questionId === questionId);
            this.setState({
                currentQuestionIndex: ssQuestions[index].questionId,
                // farthestQuestionIndex: ssQuestions[index].questionId,
                // tslint:disable-next-line:max-line-length
                currentQuestionGroupIndex: this.getGroupIndexForQuestion(this.props.ssQuestionGroups, index),
                firstSubmit: true,
                canNext: false
            });
        }
    }

    /**
     * Only called once a question is "complete". This will go to the next question and set the user's
     * "currentContent" to the next question's questionID
     *
     * NOTE:
     * For baseline questions, a question is considered complete once the user answers and hits submit.
     * For MC questions, a question is not considered complete until the user selects the correct answer
     */
    private onQuestionComplete(completedQuestionIndex: any) {
        const { questions, ssQuestions } = this.state;
        let allQuestions = questions.concat(ssQuestions)
        let farthestQId = completedQuestionIndex;
        let index = allQuestions.findIndex(q => q.questionId === completedQuestionIndex);

        if (index !== -1) {
            if (index < allQuestions.length - 1) {
                farthestQId = allQuestions[index + 1].questionId;
            }
        }

        this.setState({
            farthestQuestionIndex: farthestQId
        });
        const currQId = this.state.currentQuestionIndex;
        sessionStorage.setItem('currQId', currQId);
    }

    private getCurrentQuestionIndex(props: AllProps = this.props, questions: Question[] = this.state.questions) {
        const { questionGroups, ssQuestionGroups } = props;
        if(!props.startingQuestionId) {
            return { questionIndex: 0, groupIndex: 0 };
        }

        const questionIndex = questions.findIndex(q => q.questionId === this.props.startingQuestionId);

        // TODO: FIX THIS - clean up to do search and see if the current question index is in either lesson or ss data
        if (!_.isEmpty(questionGroups)) {
            return {
                groupIndex: this.getGroupIndexForQuestion(questionGroups, questionIndex),
                questionIndex: this.props.startingQuestionId
                // questionIndex: questionIndex
            };    
        } else {
            return {
                groupIndex: this.getGroupIndexForQuestion(ssQuestionGroups, questionIndex),
                questionIndex: this.props.startingQuestionId
                // questionIndex: questionIndex
            };
        }
    }

    private getGroupIndexForQuestion(questionGroups: QuestionGroup, questionIndex: number) {
        let groupIndex = 0;
        const numQuestionsPerGroup = questionGroups.questions.length;

        let totalQuestionsSoFar = 0;
        for(let i = 0; i < numQuestionsPerGroup; i++) {
            totalQuestionsSoFar += numQuestionsPerGroup[i];
            if(totalQuestionsSoFar > questionIndex) {
                groupIndex = i;
                break;
            }
        }

        return groupIndex;
    }

    @boundMethod
    private handleExitLesson() {
        if(!this.state.firstSubmit) {
            // Advance user to next question if they already answered this question
            this.onQuestionComplete(this.state.currentQuestionIndex);
        }
        this.props.handleExitLesson();
    }

    private onWindowUnload() {
        if(!this.state.firstSubmit) {
            // Save user's state
            // If they answered a question incorrectly and are exiting before getting the question correct,
            // we advnace them to the next question since we've already recorded their answer
            this.onQuestionComplete(this.state.currentQuestionIndex);
        }
    }

    private onVideoStart = () => {
        this.videoStarted = true
        this.simpleTimerVideo.start() // Start time for video
    }

    /**
     * Reset the TonP information for the next round!
     */
    private resetTonPStats = () => {
        this.simpleTimerQuestion.reset()
        this.attempts = 0
        this.answered = 0
        this.isCorrectlyAnswered = false
        this.videoStarted = false
    }

    /**
     * Send stats for the question to the backend
     * If the lesson video started then send the stats for it as well
     */
    private sendStatsForQuestion = () => {
        if (this.isCorrectlyAnswered) {
            let seconds = this.simpleTimerQuestion.diffInSeconds()
            let context = Context.MISTAKEBANK
            let contextId = this.props.lessonId
            let resource = Resource.QUESTION
            let resourceId = `q-${this.state.currentQuestionIndex + 1}`
            let answered = this.answered
            let ended = seconds
            let attempts = this.attempts
            let timedout = seconds > TIMEOUT_IN_SECONDS
            let category = this.props.category
            let userActivityEx: UserActivityEx = {
                context, contextId, 
                resource, resourceId, 
                category,
                answered,
                ended,
                attempts,
                timedout
            }

            // Note that there is no explicit video end to send it separately.
            // Also, a user will typically move to the next question
            // So the video stats will be handled here (if started)!
            if (this.videoStarted) {
                this.sendStatsForVideo()
                sendUserActivityWithDelay(userActivityEx)
            }
            else {
                sendUserActivity(userActivityEx)
            }

            this.resetTonPStats()
        }
    }    

    /**
    * Send stats for a hint entry to the backend
    */
    private sendStatsForHint = () => {
        let seconds = this.simpleTimerHint.diffInSeconds()

        if (seconds > 0) {
            let context = Context.MISTAKEBANK
            let contextId = this.props.lessonId
            let resource = Resource.HINT
            let resourceId = `h-${this.state.currentQuestionIndex}`
            let ended = seconds
            let timedout = seconds > TIMEOUT_IN_SECONDS
            let category = this.props.category
            let userActivityEx: UserActivityEx = {
                context, contextId, 
                resource, resourceId, 
                category,
                ended,
                timedout
            }
            sendUserActivity(userActivityEx)
        }
    }

    /**
     * Send stats for watching a video to the backend
     */
    private sendStatsForVideo = () => {
        let seconds = this.simpleTimerVideo.diffInSeconds()        
        if (seconds > 0) {
            let context = Context.MISTAKEBANK
            let contextId = this.props.lessonId
            let resource = Resource.VIDEO
            let resourceId = `v-${this.state.currentQuestionIndex}`
            let ended = seconds
            let timedout = seconds > TIMEOUT_IN_SECONDS
            let category = this.props.category
            let userActivityEx: UserActivityEx = {
                context, contextId, 
                resource, resourceId, 
                category,
                ended,
                timedout
            }
            sendUserActivity(userActivityEx)
        }
    }
}
