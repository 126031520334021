import * as React from 'react';
import './LessonSummaryOverview.css';
import ReportLoadingMessage from './ReportLoadingMessage';
import Modal from '../../components/Modal/ModalEmail';
import { sendStudentEnrichmentReport } from '../../services/elearn/lesson-rest-interface';
import { EnrichmentLessonReportSummary, getDefaultEnrichmentLessonReportSummary } from '../../services/elearn/lesson-types';
import SummaryFilters from './SummaryFilters';
import EnrichmentLessonSummary from '../../components/EnrichmentLessonSummary';
import { TeacherStore, ITeacherStoreData } from '../../stores/teacher.store';
import { GraduationYearDescriptor } from './types';
import { NO_STUDENT_INFO_FOUND } from './info';

export interface Props { }

interface State {
  selectedSchoolName?: string
  selectedGraduationYear?: string
  data: EnrichmentLessonReportSummary[]
  schools: string[]
  graduationYears: GraduationYearDescriptor[]
  showEmailNotificationModal: boolean
  showScoreConversion: boolean
  selectedTimeRange?: string
  loaded: boolean
}

export default class EnrichmentLessonSummaryOverview extends React.Component<Props, State> {
  unsubscribe
  
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSchoolName: undefined,
      data: [],
      schools: [],
      graduationYears: [],
      showEmailNotificationModal: false,
      showScoreConversion: false,
      loaded: false
    };
  }

  public componentDidMount() {
    this.loadEnrichmentLessonSummaries()
    this.unsubscribe = TeacherStore.subscribe(d => {
      this.setMainData(d)
    })
    this.setMainData(TeacherStore.data)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  setMainData(data: ITeacherStoreData) {
    let { enrichmentLessonSummaries } = data
    this.setState({ 
      data: enrichmentLessonSummaries,
      loaded: true
    })
  }

  sendLessonReport() {
    sendStudentEnrichmentReport();
    this.setState({
      showEmailNotificationModal: true
    });
  }

  loadEnrichmentLessonSummaries() {
    const { selectedSchoolName, selectedGraduationYear, selectedTimeRange } = this.state;
    const schoolParam = selectedSchoolName !== undefined ? selectedSchoolName : undefined;
    const gradYearParam = selectedGraduationYear !== undefined ? selectedGraduationYear : undefined;
    TeacherStore.loadEnrichmentLessonSummaries(schoolParam, gradYearParam, selectedTimeRange)
  }

  onNewSchoolSelected(newSelectedSchool: string) {
    this.setState({ data: [] });
    this.setState({
        selectedSchoolName: newSelectedSchool === 'All' ? undefined : newSelectedSchool,
        loaded: false
      }, () => this.loadEnrichmentLessonSummaries()
    );
  }

  onTimeRangeSelected(newlySelected: string) {
    this.setState({ data: [] });
    this.setState({
        selectedTimeRange: newlySelected,
        loaded: false
      }, 
      () => this.loadEnrichmentLessonSummaries()
    );
  }

  onGraduationYearSelected(selectedYear: string) {
    this.setState({ data: [] });
    this.setState({ 
        selectedGraduationYear: selectedYear === 'All' ? undefined : selectedYear,
        loaded: false
      }, 
      () => this.loadEnrichmentLessonSummaries()
    );
  }

  public render() {
    const { data, loaded } = this.state;

    return (
        <div className="lesson-overview">
          <SummaryFilters 
            enableTimeRangeSelection={true}
            onNewGraduationYearSelected={(gradYear) => this.onGraduationYearSelected(gradYear)}
            onNewSchoolSelected={(schoolSel) => this.onNewSchoolSelected(schoolSel)}
            onNewTimeRangeSelected={(timeRange) => this.onTimeRangeSelected(timeRange)}
          />
          <div className="individual-data-section--2col">
            <h2 className="h3 individual-data-title">Enrichment Summary</h2>
            <div className="title-sidebar">
              <a title="Email Report" className="iconLink--left img-rel" href="#" onClick={() => this.sendLessonReport()}>
                <img src="/assets/images/icons/v2/ico-email.svg" width="14" height="11" alt="" />
                <span className="label def">Email Report</span>
              </a>
            </div>
          </div>
          <hr className="half" />
          {/* <p>
            You can 
            <a href="#" onClick={() => this.sendLessonReport()}> email the full enrichment lesson report </a> or view summary information below.
          </p> */}

          {!loaded && <ReportLoadingMessage />}


          {data.map(stuff => {
            return (
              <EnrichmentLessonSummary 
                key={'enrichment-lesson-summary-' + stuff.categoryName}
                summaryData={stuff} 
              />
              );
            })
          }

          {loaded && data.length == 0 && NO_STUDENT_INFO_FOUND}

          <Modal 
            show={this.state.showEmailNotificationModal}
            onClose={() => this.setState({ showEmailNotificationModal: false })}
          >
            <div className="lesson-summary-email-notification-modal">
              <h3>Enrichment Report Is On Its Way</h3>
              <p>
              The full lesson report for your students is on its 
              way and will arrive via email in the next 2-3 minutes.
              </p>
            </div>
          </Modal>
        </div>
    );
  }
}
