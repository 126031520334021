import accountReducer, { AccountState } from './stores/account.store';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import lessonReducer, { LessonState } from './stores/lesson.store';
import hintsReducer from './stores/hints.store';
import practiceTestReducer from './stores/practice-tests.store';
import ckReducer, { CKState } from './stores/ck.store';
import schoolSafetyReducer, { SchoolSafetyState } from './stores/school-safety.store';
import enrichmentReducer,{ EnrichmentState } from './stores/enrichment.store';

const reducers = combineReducers({
    account: accountReducer,
    lesson: lessonReducer,
    hints: hintsReducer,
    practiceTestSummary: practiceTestReducer,
    ck: ckReducer,
    schoolSafety: schoolSafetyReducer,
    enrichment: enrichmentReducer
});

export interface AppState {
    account: AccountState;
    lesson: LessonState;
    ck: CKState;
    schoolSafety: SchoolSafetyState;
    enrichment: EnrichmentState
}

const devTools = (window as any).devToolsExtension ? (window as any).devToolsExtension() : f => f;

const store = createStore(reducers, compose(applyMiddleware(thunk), devTools));

export default store;
