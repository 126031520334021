export function getPrintoutByLessonID(lessonId: string): string {
    let printout = '';
    switch(lessonId) {
        case 'ACT&SATReadingPassagesOverview': {
            printout = '/assets/handouts/ReadingLesson-Overview-Printout.pdf';
            break;
        }
        case 'DualPassages' : {
            printout = '/assets/handouts/ReadingLesson-DualPassages-Printout.pdf';
            break;
        }
        case 'SinglePassages' : {
            printout = '/assets/handouts/ReadingLesson-SinglePassages-Printout.pdf';
            break;
        }
        case 'SinglePassagesWithFigures' : {
            printout = '/assets/handouts/ReadingLesson-SinglePassagesWithFigures-Printout.pdf';
            break;
        }
        case 'DebatingScientists' : {
            printout = '/assets/handouts/Science-DebatingScientists-Printout.pdf';
            break;
        }
        case 'Experiments' : {
            printout = '/assets/handouts/Science-Experiments-Printout.pdf';
            break;
        }
        case 'Figures&Tables' : {
            printout = '/assets/handouts/Science-FiguresTables-Printout.pdf';
            break;
        }
        default : {
            printout = '';
            break;
        }
    }
    
    return printout;
}