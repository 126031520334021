import './DragHandle.css';

import * as React from 'react';

interface Props {
    onDragStart?: () => void;
    onDragEnd?: () => void;
}

const DragHandle: React.SFC<Props> = (props) => {
    return (
        <div className="drag-handle">
            <div className="drag-handle__grabber" onDragStart={props.onDragStart} onDragEnd={props.onDragEnd}>
                <div className="drag-handle__wrapper">
                    <div className="drag-handle__hamburger" />
                </div>
            </div>
        </div>
    );
};

DragHandle.displayName = 'DragHandle';

export default DragHandle;
