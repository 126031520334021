import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';

interface Props extends RouteComponentProps<any> {}

/**
 * Component that scrolls to the top of the window on every route change
 */
class ScrollToTop extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.location !== prevProps.location) {
            // Scroll to the top
            window.scrollTo(0, 0)

            // Also scroll the top level container in the app to the 0, 0 position
            let containerElm = document.querySelector(".winward-academy-container")
            if (containerElm) {
               containerElm.scrollTo(0, 0)
            }
        }
    }

    public render() {
        return null;
    }
}

export default withRouter(ScrollToTop);
