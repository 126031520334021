import './IAHighestScore.css';

import * as React from 'react';
import RadialProgress from '../../../../components/RadialProgress/RadialProgress';

interface Props {
    score: number;
    subject: string;
    test: string;
    startColor: string;
    endColor: string;
    maxPossibleScore: number;
}

const IAHighestScore: React.SFC<Props> = props => {
    const { score, maxPossibleScore } = props;

    if (score === undefined) {
        return null;
    }

    const subjectTitle = props.subject.trim().endsWith("Score") ? props.subject : `${props.subject} Score`;

    return (
        <div className="statistics-container highest-score-card text-center">
            <h4 className="section-title">{subjectTitle}</h4>
            <div style={{width: '150px', margin: '0 auto'}}>
                <RadialProgress
                    size={150}
                    radius={45}
                    startColor={props.startColor}
                    endColor={props.endColor}
                    pegOffset={30}
                    progress={score / maxPossibleScore * 100}
                    text={score + ''}
                    textStyle={{fontSize: '32px', color: '#2b2d2d', fontWeight: 500}}
                    progressLineWidth={13}
                    backgroundLineWidth={13}
                />
                <p className="sub-test">{props.test}</p>
            </div>
        </div>
    );
};

IAHighestScore.defaultProps = {};
IAHighestScore.displayName = 'IAHighestScore';

export default IAHighestScore;
