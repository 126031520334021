import { createGenericAuthRequest, ignoreJsonError } from '../../request/request-utils'

const request = createGenericAuthRequest(process.env.REACT_APP_ELEARN_URL || '/')

export function getStudentSummary(studentEmail: string) {
  return request<any>(`/reports/teacher/student-progress/${studentEmail}`);
}

export function getStudentsMistakeBankStats() {
  return request<any>(`/reports/teacher/students-mistakebank-stats`);
}