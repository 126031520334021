import * as React from 'react';

import './TestSection.css';
import TestAnalysisSectionHeader from '../../../components/TestAnalysisSectionHeader';

// TODO: Delete this and replace with ReportSection the more generic component
const TestSection = ({ children, sectionName }) => {
    return (
        <div className="topic-test-section">
            <TestAnalysisSectionHeader sectionName={sectionName}/>
            {children}
        </div>
    );
};

export default TestSection;
