import React from 'react'
import { Link } from 'react-router-dom'
import { IAveragesLessonMastery } from '.'
import DataGrid from '../../../components/DataGrid'
import { Column } from '../../../components/DataGrid/types/types'
import { NA } from '../../../constants'
import { CurrentStatus } from '../../../services/liveview/liveview-service'
import { alphanumericCompare } from '../../../services/utils/sort-util'
import { IStudentProgressDetails } from '../../../stores/teacher.store'
import NewSelectList from '../NewSelectList'
import { ISummaryFilter } from '../SummaryFilters'

interface Props {
  selections: ISummaryFilter
  studentsProgress: IStudentProgressDetails[]
  averages: IAveragesLessonMastery
}

interface State { 
  masteryLevel: number // Default 70%
  currentSortField: string
}


let sortMethod = alphanumericCompare
let minSortVal = Number.NEGATIVE_INFINITY
let maxSortVal = Number.POSITIVE_INFINITY
let sortable = true
let columns: Column[] = [
  {
    title: 'FIRST NAME',
    dataProperty: 'studentNameFirst',
    customTooltip: 'Indicates the first name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'LAST NAME',
    dataProperty: 'studentNameLast',
    customTooltip: 'Indicates the last name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'ENGLISH<br/>GROWTH',
    dataProperty: 'growthEnglish',
    customTooltip: 'Indicates the change in pre-lesson to post-lesson English accuracy',
    sortable,
    sortMethod
  }, 
  {
    title: 'MATH<br/>GROWTH',
    dataProperty: 'growthMath',
    customTooltip: 'Indicates the change in pre-lesson to post-lesson Math accuracy',
    sortable,
    sortMethod
  }, 
  {
    title: 'ENGLISH<br/>ACCURACY',
    dataProperty: 'accuracyPostEnglish',
    customTooltip: 'Indicates the post-lesson accuracy in English',
    sortable,
    sortMethod
  }, 
  {
    title: 'MATH<br/>ACCURACY',
    dataProperty: 'accuracyPostMath',
    customTooltip: 'Indicates the post-lesson accuracy in Math',
    sortable,
    sortMethod
  },
  {
    title: 'READING<br/>ACCURACY',
    dataProperty: 'accuracyPostReading',
    customTooltip: 'Indicates the post-lesson accuracy in Reading',
    sortable,
    sortMethod
  },
  {
    title: 'SCIENCE<br/>ACCURACY',
    dataProperty: 'accuracyPostScience',
    customTooltip: 'Indicates the post-lesson accuracy in Science',
    sortable,
    sortMethod
  },
]

let averageStudentProgress = {
  id: "?",
  firstName: "",
  lastName: "",
  emailAddress: "",
  schoolName: "",
  graduationYear: "",
  period: "",
  profileColor: "",

  navigation: "",
  activity: "",
  currentStatus: CurrentStatus.AWAY,
  updatedAt: "",
  daysSinceLastLogin: -1,

  lessons: [],
  nofLessonsStarted: 0,
  nofLessonsCompleted: 0,
  nofTotalQuestionsAnswered: 1,
  percentLessonCompletion: 0,
  lessonsScores: {
    english: {
      accuracyPre: -1,
      accuracyPost: -1,
      growth: -1,
      hints: -1,
      totalEnteredHints: -1,
      totalMissedQuestions: -1
    },
    math: {
      accuracyPre: -1,
      accuracyPost: -1,
      growth: -1,
      hints: -1,
      totalEnteredHints: -1,
      totalMissedQuestions: -1
    },
    reading: {
      accuracyPre: -1,
      accuracyPost: -1,
      growth: -1,
      hints: -1,
      totalEnteredHints: -1,
      totalMissedQuestions: -1
    },
    science: {
      accuracyPre: -1,
      accuracyPost: -1,
      growth: -1,
      hints: -1,
      totalEnteredHints: -1,
      totalMissedQuestions: -1
    }
  },

  tonp: [],
  totalStudyTimeForAllLessons: {avg: 0, total: 0},
  isAverage: true
}

export default class RosterLessonMastery extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      masteryLevel: 70,
      currentSortField: "default"
    }
  }

  createContent = (studentProgress: IStudentProgressDetails, index: number) => {
    let id = `roster-lesson-mastery-${index}`
    let {masteryLevel} = this.state
    let {averages} = this.props
    let {firstName, lastName, emailAddress, lessonsScores, nofTotalQuestionsAnswered, isAverage } = studentProgress
    let {english, math, reading, science} = lessonsScores
    let getAverageInfoBasedOnSortField = (currentSortField: string) => {
      let prefix = "Average"
      let averageInfo = ""
      switch (currentSortField) {
        case "growthEnglish":
          averageInfo = `<span class="average--val">${averages.averageGrowthEnglish}%</span> ${prefix} English Growth`
          break
        case "growthMath":
          averageInfo = `<span class="average--val">${averages.averageGrowthMath}%</span> ${prefix} Math Growth`
          break
        case "accuracyPostEnglish":
          averageInfo = `<span class="average--val">${averages.averageAccuracyEnglish}%</span> ${prefix} English Accuracy`
          break
        case "accuracyPostMath":
          averageInfo = `<span class="average--val">${averages.averageAccuracyMath}%</span> ${prefix} Math Accuracy`
          break
        case "accuracyPostReading":
          averageInfo = `<span class="average--val">${averages.averageAccuracyReading}%</span> ${prefix} Reading Accuracy`
          break
        case "accuracyPostScience":
          averageInfo = `<span class="average--val">${averages.averageAccuracyScience}%</span> ${prefix} Science Accuracy`
          break
      }
      return averageInfo ? `${averageInfo}`: ""
    }
    let {currentSortField} = this.state
    let averageInfo = getAverageInfoBasedOnSortField(currentSortField)
    let getFirstNameOrAverageDetails = () => {
      let reportLink = `/print/teacher/student-progress/${emailAddress}`
      return () =>
        <>
          { !isAverage ?           
            <>
              <Link className="roster-lesson-summary-iconlink" to={reportLink} target="progress-summary-page"  title={`Open Full Progress Summary for: ${firstName} ${lastName}, opens in a new window`}>
                <img
                  className="explanation-button-image"
                  src={`/assets/images/icons/v2/ico-external-link.svg`}
                  width="18"
                  height="18"
                  alt=""
                />
              </Link>
              {firstName}
            </>
            :
            <>
              <span dangerouslySetInnerHTML={{__html: averageInfo}}/>
            </>
          }
        </> 
    } 
    let getGrowthInfo = (growth) => {
      let growthInfo = nofTotalQuestionsAnswered > 0 ? `${growth}%`: NA
      let className = '';
      if(growthInfo != NA && !isAverage) {
        className = growth < 0 ? "after--down-arrow" : growth > 0 ? "after--up-arrow": ""
      }
      return () => 
        (growthInfo === NA ?
          <div className="not-applicable">
            {NA}
          </div>
          :
          <>
          <span className={className}>{growthInfo}</span>
          </>
        )
    }
    let getAccuracyInfo = (accuracy) => {
      let accuracyInfo = nofTotalQuestionsAnswered > 0 ? `${accuracy}%`: NA
      let className = nofTotalQuestionsAnswered > 0 ? accuracy >= masteryLevel ? "mastery-highlight-green": "mastery-highlight-red": ""
      return () =>
      (accuracyInfo === NA ?
        <div className="not-applicable">
          {NA}
        </div>
        :
        <>
        <span className={className}>{accuracyInfo}</span>
        </>
      )
    }
    let data = {
      studentNameFirst: {
        content: getFirstNameOrAverageDetails(),
        comparator: firstName,
      },  
      studentNameLast: {
        content: lastName,
        comparator: lastName,
      },
      growthEnglish: {
        content: getGrowthInfo(english.growth),
        comparator: nofTotalQuestionsAnswered > 0 ? english.growth: minSortVal,
      },
      growthMath: {
        content: getGrowthInfo(math.growth),
        comparator: nofTotalQuestionsAnswered > 0 ? math.growth: minSortVal,
      },
      accuracyPostEnglish: {
        content: getAccuracyInfo(english.accuracyPost),
        comparator: nofTotalQuestionsAnswered > 0 ? english.accuracyPost: minSortVal,   
      },
      accuracyPostMath: {
        content: getAccuracyInfo(math.accuracyPost),
        comparator: nofTotalQuestionsAnswered > 0 ? math.accuracyPost: minSortVal,
      },
      accuracyPostReading: {
        content: getAccuracyInfo(reading.accuracyPost),
        comparator: nofTotalQuestionsAnswered > 0 ? reading.accuracyPost: minSortVal,
      },
      accuracyPostScience: {
        content: getAccuracyInfo(science.accuracyPost),
        comparator: nofTotalQuestionsAnswered > 0 ? science.accuracyPost: minSortVal,
      },      
    }

    // Let's highlight the average row here (please note if there is no averageInfo then collapse the row!)
    let highlightRowClassName  = ""
    if (isAverage) {
      if (averageInfo !== "") {
        highlightRowClassName = "row-highlight"
      }
      else {
        highlightRowClassName = "row-highlight-collapsed"
      }
    }
    
    return {
      id,
      data,
      highlightRowClassName,
    }
  }

  onSortAllowed = (sortFieldName: string) => {
    let {currentSortField} = this.state
    if (sortFieldName !== currentSortField) {
      setTimeout(() => {
        this.setState({currentSortField: sortFieldName})
      }, 10)
    }
    return true
  }

  getCardBodyContentForMastery = (studentsProgress: IStudentProgressDetails[]) => {    
    let {averages} = this.props
    
    // Let's set the average accuricies
    averageStudentProgress.lessonsScores.english.accuracyPost = averages.averageAccuracyEnglish
    averageStudentProgress.lessonsScores.math.accuracyPost = averages.averageAccuracyMath
    averageStudentProgress.lessonsScores.reading.accuracyPost = averages.averageAccuracyReading
    averageStudentProgress.lessonsScores.science.accuracyPost = averages.averageAccuracyScience

    // Also, let's set the growth (only for English & Math)
    averageStudentProgress.lessonsScores.english.growth = averages.averageGrowthEnglish
    averageStudentProgress.lessonsScores.math.growth = averages.averageGrowthMath

    let extendedStudentsProgress = [...studentsProgress]

    // Add average row data only if there are more than 1 entry
    if (extendedStudentsProgress.length > 1) {
      extendedStudentsProgress.push(averageStudentProgress as any)
    }
    
    let data = extendedStudentsProgress.map((progress, index) => this.createContent(progress, index))
  
    return (
      <DataGrid
        columns={columns}
        data={data}
        onSortAllowed={this.onSortAllowed}
        emptyText="No lessons mastery info found for students."
      />
    )
  }
  
  onMasteryLevelChanged = (e: React.ChangeEvent<HTMLInputElement>) =>{
    e.preventDefault()
    let value = e.target.value
    let masteryLevel = parseInt(value)
    this.setState({masteryLevel})
  }

  render() {
    let {studentsProgress} = this.props
    let masteryLevels = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
    let masteryLevelOptions = masteryLevels.map(v => {return {value: v+"%", display: v+"%"}})
    let selectedMasteryLevel = `${this.state.masteryLevel}%`
    let masteryDetails = this.getCardBodyContentForMastery(studentsProgress)

    return(
      <div className="roster-view-mastery-container">
        <div className="individual-data-section">
          <h2 className="h3">Lesson Mastery</h2>
          <div className="mastery-level-dropdown-container">
            <label htmlFor="lesson-mastery-dropdown" className="mastery-label">
              Level:
            </label>
            <div className="mastery-level-dropdown">
              <NewSelectList 
                id="lesson-mastery-dropdown"
                selected={selectedMasteryLevel}
                options={masteryLevelOptions}
                onChange={this.onMasteryLevelChanged}
              />
            </div>
          </div>
          <hr/>
        </div> 
        <div className="roster-view-content">
          {masteryDetails}
        </div>
      </div>
    )
  }
}
