import './StaySharpSubjectView.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';
import { RouteComponentProps } from 'react-router-dom';

import { CollapsableCardBody, CollapsableCardHeader } from '../../../../components/CollapsableCard/CollapsableCard';

import CollapsableCard from '../../../../components/CollapsableCard/CollapsableCard';
import MathCheatsheetLink from '../../../../components/MathCheatsheetLink/MathCheatsheetLink';
import StaySharpListCardHeader from '../../../../components/StaySharpListCardHeader/StaySharpListCardHeader';
import { LessonSummary } from '../../../../services/elearn/lesson-types';
import StaySharpLessonTable from './components/StaySharpLessonTable/StaySharpLessonTable';
import { StaySharpProgress } from '../../../../services/elearn/lesson-types';
// import NoLessons from './components/NoLessons/NoLessons';
import { getSubjectIconList } from '../../../../services/icon/icon-service';
import { getDashboard } from '../../../../services/elearn/stay-sharp-rest-interface';
import Loading from '../../../../components/Loading/Loading';
// import { groupLessonsBySubcategory } from '../../../../services/array/array-utils';

export interface StaySharpSubjectViewProps {
    curriculum: string;
    showCompletionModal: (topicName: string) => void;
    subjects: StaySharpProgress[];
}

interface State {
    subjects: StaySharpProgress[];
    hasLoaded: boolean;
}

type AllProps = StaySharpSubjectViewProps & RouteComponentProps<any>;

const subjectIcons = getSubjectIconList('@0,5x');


export default class StaySharpSubjectView extends React.PureComponent<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            subjects: [],
            hasLoaded: false
        };
    }

    public render() {
        const { subjects } = this.props;
        const { props } = this;
        let englishLessons: LessonSummary[] = [];
        let mathLessons: LessonSummary[] = [];

        for (let i = 0; i < subjects.length; i++) {
            if (subjects[i].subject === 'English') {
                englishLessons = subjects[i].lessonsSummary;
            }
            if (subjects[i].subject === 'Math') {
                mathLessons = subjects[i].lessonsSummary;
            }
        }
        
        if (englishLessons.length === 0 && mathLessons.length === 0) {
            return (
                <div className="subject-view"> 
                    <div className="tab-container">
                        <div className="tab-container__btn-container"></div>
                        <div className="tab-container__content-container">
                            <div className="tab-content">
                                <div className="lessonview-empty-container">
                                    <h3>It looks like you haven't completed any lessons yet!</h3>   
                                    <p>Stay Sharp topics will only show once you have completed a lesson for either Math or English.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="subject-view">                        
                    {subjectIcons.map(function(currentValue: any, index: number) {
                        if (currentValue.title === 'English' || currentValue.title === 'Math') {
                            if (currentValue.title === 'English' && englishLessons.length > 0) {
                                return (
                                    <CollapsableCard key={index}>
                                    <CollapsableCardHeader>
                                        <StaySharpListCardHeader 
                                            text={currentValue.title} 
                                            color={currentValue.color} 
                                            icon={currentValue.icon} 
                                        />
                                    </CollapsableCardHeader>
        
                                    <CollapsableCardBody>
                                        <StaySharpLessonTable 
                                            lessonData={englishLessons} 
                                            showCompletionModal={props.showCompletionModal}
                                            {...props} 
                                        />
                                    </CollapsableCardBody>
                                    </CollapsableCard>
                                );
                            }
    
                            if (currentValue.title === 'Math' && mathLessons.length > 0) {
                                return (
                                    <CollapsableCard key={index}>
                                    <CollapsableCardHeader>
                                        <StaySharpListCardHeader 
                                            text={currentValue.title} 
                                            color={currentValue.color} 
                                            icon={currentValue.icon} 
                                        />
                                    </CollapsableCardHeader>
        
                                    <CollapsableCardBody>
                                        <MathCheatsheetLink />
                                        <StaySharpLessonTable 
                                            lessonData={mathLessons} 
                                            showCompletionModal={props.showCompletionModal}
                                            {...props} 
                                        />
                                    </CollapsableCardBody>
                                    </CollapsableCard>
                                );
                            } else {
                                return;
                            }
                        } else {
                        return;   
                        }
                    })
                    }
                </div>
            );
        }
    }
}
