export const ACTSATConversionData = [
    { "act": "36", "sat": "1570-1600", "percentile": "99th" },
    { "act": "35", "sat": "1530-1560", "percentile": "99th" },
    { "act": "34", "sat": "1490-1520", "percentile": "99th" },
    { "act": "33", "sat": "1450-1480", "percentile": "98th" },
    { "act": "32", "sat": "1420-1440", "percentile": "97th" },
    { "act": "31", "sat": "1390-1410", "percentile": "95th" },
    { "act": "30", "sat": "1360-1380", "percentile": "93rd" },
    { "act": "29", "sat": "1330-1350", "percentile": "91st" },
    { "act": "28", "sat": "1300-1320", "percentile": "89th" },
    { "act": "27", "sat": "1260-1290", "percentile": "86th" },
    { "act": "26", "sat": "1230-1250", "percentile": "82nd" },
    { "act": "25", "sat": "1200-1220", "percentile": "78th" },
    { "act": "24", "sat": "1160-1190", "percentile": "74th" },
    { "act": "23", "sat": "1130-1150", "percentile": "69th" },
    { "act": "22", "sat": "1100-1120", "percentile": "63rd" },
    { "act": "21", "sat": "1060-1090", "percentile": "58th" },
    { "act": "20", "sat": "1030-1050", "percentile": "52nd" },
    { "act": "19", "sat": "990-1020", "percentile": "45th" },
    { "act": "18", "sat": "960-980", "percentile": "39th" },
    { "act": "17", "sat": "920-950", "percentile": "32nd" },
    { "act": "16", "sat": "880-910", "percentile": "26th" },
    { "act": "15", "sat": "830-870", "percentile": "20th" },
    { "act": "14", "sat": "780-820", "percentile": "14th" },
    { "act": "13", "sat": "730-770", "percentile": "8th" },
    { "act": "12", "sat": "690-720", "percentile": "4th" },
    { "act": "11", "sat": "650-680", "percentile": "1st" },
    { "act": "10", "sat": "620-640", "percentile": "1st" },
    { "act": "9", "sat": "590-610", "percentile": "1st" },
    { "act": "8", "sat": "590-600", "percentile": "1st" },
    { "act": "7", "sat": "570-580", "percentile": "1st" },
    { "act": "6", "sat": "540-560", "percentile": "1st" },
    { "act": "5", "sat": "510-530", "percentile": "1st" },
    { "act": "4", "sat": "480-500", "percentile": "1st" },
    { "act": "3", "sat": "450-470", "percentile": "1st" },
    { "act": "2", "sat": "420-440", "percentile": "1st" },
    { "act": "1", "sat": "400-410", "percentile": "1st" }
];