import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import PasswordReqPopup from '../../scenes/SignUp/components/PasswordReqPopup/PasswordPopup';
import TextInput from '../TextInput/TextInput';

interface Props {
    onValidPassword: (password: string) => void;
    onInvalidPassword: () => void;
    formGroupClass?: string;
    size?: 'lg' | 'sm';
}

interface State {
    password: string;
    passwordConfirm: string;
    passwordFeedback: string;
    passwordConfirmFeedback: string;
    showPasswordPopup: boolean;
}


export default class PasswordForm extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            password: '',
            passwordConfirm: '',
            passwordFeedback: '',
            passwordConfirmFeedback: '',
            showPasswordPopup: false
        };
    }

    public render() {
        const { formGroupClass, size } = this.props;
        const { password, passwordConfirm, passwordFeedback, passwordConfirmFeedback, showPasswordPopup } = this.state;
        return (
            <div className="row">
                <TextInput
                    id="password-form"
                    labelText="Password"
                    value={password}
                    onChange={this.onPasswordChange}
                    inputType="password"
                    size={size as ('lg' | 'sm')}
                    invalid={!!passwordFeedback}
                    feedbackText={passwordFeedback}
                    // onFocus={this.onPasswordFocus}
                    // onBlur={this.onPasswordBlur}
                    formGroupClass={formGroupClass}
                />

                {/* {showPasswordPopup && <PasswordReqPopup />} */}

                <TextInput
                    id="password-confirm-form"
                    labelText="Re-enter Password"
                    value={passwordConfirm}
                    onChange={this.onPasswordConfirmChange}
                    inputType="password"
                    size={size as ('lg' | 'sm')}
                    invalid={!!passwordConfirmFeedback}
                    feedbackText={passwordConfirmFeedback}
                    formGroupClass={formGroupClass}
                />
            </div>
        );
    }

    @boundMethod
    private onPasswordChange(e: any) {
        const password = e.target.value;
        const { error, isValid } = this.validatePassword(password, this.state.passwordConfirm);
        if(!isValid) { this.props.onInvalidPassword(); }

        this.setState({
            password,
            passwordFeedback: error
        }, this.executeValidPasswordCallback);
    }

    @boundMethod
    private onPasswordConfirmChange(e: any) {
        const passwordConfirm = e.target.value;
        const { error, isValid } = this.validatePassword(passwordConfirm, this.state.password);
        if(!isValid) { this.props.onInvalidPassword(); }

        this.setState({
            passwordConfirm,
            passwordConfirmFeedback: error
        }, this.executeValidPasswordCallback);
    }

    // private onPasswordFocus() {
    //     this.setState({ showPasswordPopup: true });
    // }

    // private onPasswordBlur() {
    //     this.setState({ showPasswordPopup: false });
    // }

    private executeValidPasswordCallback() {
        const { password, passwordConfirm, passwordFeedback, passwordConfirmFeedback } = this.state;
        if(!passwordFeedback && !passwordConfirmFeedback && password && passwordConfirm &&
            password === passwordConfirm) {
            this.props.onValidPassword(password);
        }
    }

    private validatePassword(password: string, passwordToCompare: string) {
        let error = '';
        // if(password.length < 8) {
        //     error = 'Password must be at least 8 characters';
        // } else if(!/[0-9]/.test(password)) {
        //     error = 'Password must contain at least one number';
        // } else if (!/[A-Z]/.test(password)) {
        //     error = 'Password must contain at least one uppercase letter';
        // } else if (!/[a-z]/.test(password)) {
        //     error = 'Password must contain at least one lowercase letter';
        // } else if(!/(?=.*[-+!$@#%&;,.*:/])/.test(password)) {
        //     error = 'Password must contain at least one special character';
        // } else if(passwordToCompare && passwordToCompare !== password) {
        //     error = 'Passwords do not match';
        // }
        if(passwordToCompare && passwordToCompare !== password) {
            error = 'Passwords do not match';
        }

        return {
            error,
            isValid: !error
        };
    }
}
