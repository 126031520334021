import * as React from 'react'
import Loading from '../../../components/Loading/Loading'
import Modal from '../../../components/Modal/Modal'
import { getStudentSummary } from '../../../services/elearn/teacher/teacher-student-summary'
import { sendPageviewInfo } from '../../../WinwardTracker'
import PracticeTestResults from '../../Reports/StudentReports/PracticeTestResults'
import "./PracticeReportsModal.css"

interface Props {
    onClose: () => void
    show: boolean
    studentEmail?: string
}

interface State {
  scoresListACT: any
  scoresListSAT: any
  scoresListIA: any
  loaded: boolean
}

class PracticeReportsModal extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      scoresListSAT: [],
      scoresListACT: [],
      scoresListIA: [],
      loaded: false
    }
  }

  componentDidMount() {
    let path = window.location.pathname
    let {studentEmail} = this.props
    
    if (!studentEmail) return
    
    setTimeout(async () => {
      let data = await getStudentSummary(studentEmail as string)
      let {practiceTestsSAT, practiceTestsACT, practiceTestsIA} = data
      this.setState({
        scoresListSAT: practiceTestsSAT,
        scoresListACT: practiceTestsACT,
        scoresListIA: practiceTestsIA,
        loaded: true
      })
    }, 10)
    
    sendPageviewInfo(path)
  }

  render() {
    let {onClose, show, studentEmail} = this.props
    let {loaded, scoresListACT, scoresListSAT, scoresListIA} = this.state

    return (
      <Modal 
        className="practice-reports-modal modal" 
        show={show} 
        onClose={onClose} 
        width="1600px"
      >
        <div className="practice-reports-modal-container">
          <div className="row">
              <div className="practice-reports-modal-header col-lg-12 text-center">
                <div className="">
                  <h1 className="practice-reports-modal-title">Practice Tests</h1>
                  <h3 className="practice-reports-modal-subtitle">Student: {studentEmail}</h3>
                </div>
                <button type="button" className="modal-close" title="Close Modal" aria-label="Close" onClick={onClose}>
                    <img
                    className="modal-icon"
                    src={`/assets/images/icons/v2/ico-modal-close-white.svg`}
                    width="24"
                    height="24"
                    alt=""
                  />
                </button>
              </div>
              <div className="col-12">
                {!loaded ?
                  <div className="center"><Loading/></div>
                  :
                  <PracticeTestResults
                    showTopScores={false}
                    enableLinksForStudent={studentEmail}
                    scores={{actScores: scoresListACT, satScores: scoresListSAT, iaScores: scoresListIA}}
                  />
                }
              </div>          
          </div>
      </div>
      </Modal>
    )
  }
}

export default PracticeReportsModal