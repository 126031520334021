import './WelcomeModalOne.css';

import * as React from 'react';
import Modal from '../../../../components/Modal/Modal';
import ModalCard from './components/ModalCard/ModalCard';

interface Props { 
    onClose: () => void;
    show: boolean;
}

const WelcomeModalOne: React.SFC<Props> = (props) => {
    return (
            <Modal onClose={props.onClose} className="welcome-one-modal" show={props.show}>
                <div className="welcome-one-modal-header">
                    <h2>Welcome to Our Learning Family!</h2>
                    <h4>As part of our learning family, you'll access the following:</h4>
                </div>
                <div className="welcome-one-modal-body">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12 margin-bottom">
                        <ModalCard image={'purple-girl.svg'}>
                            <p>Individualized teaching <br />and learning programs</p>
                        </ModalCard>
                        </div>
                        <div className="col-lg-4 col-sm-12 margin-bottom">
                        <ModalCard image={'computer-video.svg'}>
                            <p>10,000+ questions with <br />step-by-step explanations</p>
                        </ModalCard>
                        </div>
                        <div className="col-lg-4 col-sm-12 margin-bottom">
                        <ModalCard image={'scantron.svg'}>
                            <p>Exclusive access <br />to real practice tests</p>
                        </ModalCard>
                        </div>
                        <div className="col-lg-4 col-sm-12 margin-bottom">
                        <ModalCard image={'book-stack.svg'}>
                            <p>250+ hours <br />of expert instruction</p>
                        </ModalCard>
                        </div>
                        <div className="col-lg-4 col-sm-12 margin-bottom">
                        <ModalCard image={'hand-voice-blurb.svg'}>
                            <p>Supportive feedback <br />on every question</p>
                        </ModalCard>
                        </div>
                        <div className="col-lg-4 col-sm-12 margin-bottom">
                        <ModalCard image={'trophy.svg'}>
                            <p>Unparalelled <br />lesson quality</p>
                        </ModalCard>
                        </div>
                    </div>
                    <button className="btn btn--text-white btn--bg-blue" onClick={props.onClose}>GOT IT!</button>
                </div>
            </Modal>
    );
};

WelcomeModalOne.displayName = 'WelcomeModalOne';

export default WelcomeModalOne;
