import { TestKind } from "../../scenes/SuperintendentDashboard/SuperintendentFilters"

// Look up tables for ACT & SAT
const lookUpTable4ACT:any = {
  "Accuracy": ["1%", "2%", "3%", "4%", "5%", "6%", "7%", "8%", "9%", "10%", "11%", "12%", "13%", "14%", "15%", "16%", "17%", "18%", "19%", "20%", "21%", "22%", "23%", "24%", "25%", "26%", "27%", "28%", "29%", "30%", "31%", "32%", "33%", "34%", "35%", "36%", "37%", "38%", "39%", "40%", "41%", "42%", "43%", "44%", "45%", "46%", "47%", "48%", "49%", "50%", "51%", "52%", "53%", "54%", "55%", "56%", "57%", "58%", "59%", "60%", "61%", "62%", "63%", "64%", "65%", "66%", "67%", "68%", "69%", "70%", "71%", "72%", "73%", "74%", "75%", "76%", "77%", "78%", "79%", "80%", "81%", "82%", "83%", "84%", "85%", "86%", "87%", "88%", "89%", "90%", "91%", "92%", "93%", "94%", "95%", "96%", "97%", "98%", "99%", "100%"],
  "English":  ["1",	"1-2", "2", 	"2-3", 	"3", 	"3-4", 	"4", 	"4-5", 	"4-5", 	"5", 	"6", 	"6", 	"6", 	"6-7", 	"7", 	"7-8", 	"8", 	"8", 	"9", 	"9", 	"9", 	"9-10", 	"10", 	"10", 	"10", 	"10", 	"10", 	"10-11", 	"11", 	"11", 	"11-12", 	"12", 	"12", 	"12", 	"12-13", 	"13", 	"13", 	"13-14", 	"14", 	"14", 	"14-15", 	"14-15", 	"15", 	"15", 	"15", 	"15-16", 	"16", 	"16", 	"16-17", 	"16-17", 	"17", 	"17-18", 	"17-18", 	"18", 	"18", 	"18-19", 	"18-19", 	"19", 	"19", 	"19-20", 	"20", 	"20", 	"20-21", 	"20-21", 	"21", 	"21", 	"21-22", 	"22", 	"21", 	"21-22", 	"22-23", 	"23", 	"23", 	"23-24", 	"23-24", 	"24", 	"24", 	"24-25", 	"25", 	"25", 	"25-26", 	"25-26", 	"26", 	"27", 	"27-28", 	"28", 	"28-29", 	"29-30", 	"30-31", 	"30-31", 	"31-32", 	"32-33", 	"32-33", 	"33-34", 	"34-35", 	"35", 	"35", 	"35", 	"36", 	"36"], 
  "Math":     ["1", 	"4", 	"6", 	"6-7", 	"7-8", 	"8-9", 	"9", 	"10", 	"10-11", 	"11", 	"11-12", 	"12", 	"12-13", 	"12-13", 	"13", 	"13", 	"13-14", 	"14", 	"14", 	"14", 	"14-15", 	"14-15", 	"15", 	"15", 	"15", 	"15", 	"15-16", 	"15-16", 	"16", 	"16", 	"16-17", 	"16-17", 	"16-17", 	"16-17", 	"17", 	"17", 	"17", 	"17-18", 	"17-18", 	"18", 	"18", 	"18", 	"18-19", 	"18-19", 	"19", 	"19", 	"20", 	"20", 	"20-21", 	"21", 	"21-22", 	"22", 	"22-23", 	"22-23", 	"23", 	"23", 	"23-24", 	"24", 	"24", 	"24-25", 	"24-25", 	"25", 	"25", 	"25-26", 	"25-26", 	"26", 	"26", 	"26", 	"26-27", 	"26-27", 	"27", 	"27", 	"27", 	"27-28", 	"28", 	"28", 	"28", 	"28", 	"28-29", 	"28-29", 	"29", 	"29-30", 	"30", 	"30", 	"30-31", 	"31", 	"31-32", 	"31-32", 	"32", 	"33", 	"33", 	"33-34", 	"34", 	"34", 	"35", 	"35", 	"35", 	"35-36", 	"36", 	"36"], 
  "Reading":  ["1", 	"1-2", 	"1-2", 	"2", 	"2", 	"2-3", 	"4", 	"4-5", 	"4-5", 	"5-6", 	"6", 	"6-7", 	"6-7", 	"7", 	"7", 	"7-8", 	"8", 	"8-9", 	"8-9", 	"9", 	"9-10", 	"10", 	"10-11", 	"11", 	"11", 	"11-12", 	"11-12", 	"12", 	"12", 	"12-13", 	"12-13", 	"12-13", 	"13", 	"12-13", 	"13", 	"13", 	"13-14", 	"14", 	"14-15", 	"14-15", 	"14-15", 	"15", 	"15-16", 	"15-16", 	"16", 	"16", 	"16-17", 	"17", 	"17", 	"17-18", 	"17-18", 	"18", 	"18-19", 	"18-19", 	"18-19", 	"19", 	"19-20", 	"20", 	"20-21", 	"21", 	"21-22", 	"22", 	"22", 	"22-23", 	"23", 	"23", 	"23-24", 	"23-24", 	"23-24", 	"24", 	"24", 	"24-25", 	"25", 	"25", 	"25-26", 	"25-26", 	"26", 	"27", 	"27", 	"27-28", 	"28", 	"28-29", 	"29", 	"29-30", 	"30-31", 	"30-31", 	"31", 	"32", 	"32", 	"32-33", 	"32-33", 	"33", 	"33-34", 	"33-34", 	"34-35", 	"34-35", 	"34-35", 	"35-36", 	"36", 	"36"], 
  "Science":  ["1", 	"1-3", 	"3", 	"3-5", 	"3-5", 	"5", 	"5-6", 	"5-6", 	"6-7", 	"7-8", 	"7-8", 	"8", 	"8-9", 	"8-9", 	"9-10", 	"9-10", 	"10", 	"10-11", 	"10-11", 	"11", 	"11-12", 	"11-12", 	"12-13", 	"12-13", 	"13", 	"13-14", 	"13-14", 	"14", 	"14-15", 	"15", 	"15-16", 	"15-16", 	"16", 	"16", 	"16-17", 	"16-17", 	"16-17", 	"17", 	"17", 	"17-18", 	"17-18", 	"18", 	"18", 	"18", 	"18-19", 	"18-19", 	"18-19", 	"19", 	"19-20", 	"19-20", 	"19-20", 	"20", 	"20-21", 	"20-21", 	"21", 	"21", 	"21-22", 	"22", 	"22", 	"22-23", 	"22-23", 	"22-23", 	"23", 	"23-24", 	"23-24", 	"23-24", 	"24", 	"24", 	"24-25", 	"24-25", 	"24-25", 	"25", 	"25", 	"25-26", 	"26", 	"26", 	"26-27", 	"26-27", 	"26-27", 	"27", 	"27", 	"27-28", 	"38", 	"28", 	"28-29", 	"29", 	"29-30", 	"30-31", 	"30-31", 	"32", 	"32-33", 	"33", 	"33-34", 	"33-34", 	"34", 	"34-35", 	"34-35", 	"35", 	"35-36", 	"36"], 
}

const lookUpTable4SAT = {
  "Accuracy": ["1%", "2%", "3%", "4%", "5%", "6%", "7%", "8%", "9%", "10%", "11%", "12%", "13%", "14%", "15%", "16%", "17%", "18%", "19%", "20%", "21%", "22%", "23%", "24%", "25%", "26%", "27%", "28%", "29%", "30%", "31%", "32%", "33%", "34%", "35%", "36%", "37%", "38%", "39%", "40%", "41%", "42%", "43%", "44%", "45%", "46%", "47%", "48%", "49%", "50%", "51%", "52%", "53%", "54%", "55%", "56%", "57%", "58%", "59%", "60%", "61%", "62%", "63%", "64%", "65%", "66%", "67%", "68%", "69%", "70%", "71%", "72%", "73%", "74%", "75%", "76%", "77%", "78%", "79%", "80%", "81%", "82%", "83%", "84%", "85%", "86%", "87%", "88%", "89%", "90%", "91%", "92%", "93%", "94%", "95%", "96%", "97%", "98%", "99%", "100%"],
  "EBRW":     ["200", "200", "200", "200", "210", "220", "220-230", "230-240", "240-250", "240-250", "260-270", "260-270", "280-290", "280-290", "290-300", "290-300", "300-310", "310", "320", "320-330", "330-340", "340-350", "340-350", "350-360", "360-370", "360-370", "370-380", "380-390", "380-390", "380-400", "390-410", "400-410", "410-420", "410-430", "410-430", "420-440", "420-440", "430-450", "430-450", "450-470", "450-470", "450-470", "460-480", "460-490", "470-490", "470-490", "470-500", "470-510", "480-510", "490-510", "490-520", "500-530", "510-530", "510-530", "520-540", "530-550", "530-550", "530-550", "540-560", "540-560", "550-570", "550-570", "560-580", "560-580", "570-590", "570-590", "580-590", "590-600", "590-610", "590-610", "600-610", "610-620", "610-630", "620-640", "630-640", "630-650", "630-660", "640-660", "650-660", "650-670", "660-680", "660-680", "670-680", "670-690", "670-690", "690-710", "690-710", "700-710", "700-710", "720-730", "720-730", "730-740", "740-750", "740-750", "750-760", "760-770", "770-780", "780-790", "790-800", "800"],
  "Math":     ["200", "200", "210", "210", "230", "230", "250", "270", "270", "280-290", "280-290", "300", "320", "320", "330-340", "330-340", "340-350", "340-350", "360", "360-370", "370", "380-390", "380-390", "390-410", "400-420", "400-420", "420-430", "420-430", "430-450", "430-450", "440-460", "450-470", "450-470", "460-480", "460-480", "470-490", "470-490", "480-500", "490-510", "490-510", "500-520", "500-520", "510-530", "510-540", "510-540", "520-550", "520-550", "530-560", "530-560", "540-570", "550-580", "550-580", "560-590", "560-590", "570-600", "570-600", "570-600", "580-610", "580-610", "590-620", "590-620", "600-630", "610-640", "610-640", "620-650", "620-650", "630-660", "630-660", "640-670", "650-680", "650-680", "650-690", "650-690", "660-700", "670-710", "670-710", "680-710", "680-710", "690-720", "690-720", "690-730", "700-730", "700-730", "710-740", "710-740", "710-750", "710-750", "720-750", "730-760", "730-760", "740-770", "740-770", "750-780", "770-790", "770-790", "780-790", "780-790", "790-800", "790-800", "800"]
} 

export interface ScoreACTandSAT {
  preLessonConversionScoreACT: string
  preLessonConversionScoreSAT: string
  postLessonConversionScoreACT: string
  postLessonConversionScoreSAT: string
}

/**
 * Converts the accuracies (pre & post) to the ACT & SAT scores for the given category
 * 
 * @param category 
 * @param accuracyPreLesson 
 * @param accuracyPostLesson 
 * @returns Pre and post ACT & SAT conversion scores for the category
 */
export const convertToACTandSAT = (category: string, accuracyPreLesson: number, accuracyPostLesson): ScoreACTandSAT => {
  accuracyPreLesson = Math.floor(accuracyPreLesson)
  accuracyPostLesson = Math.floor(accuracyPostLesson)
  
  // Students get the lowest score even if the accuracy is 0 (per Jennifer)
  accuracyPreLesson = Math.max(accuracyPreLesson, 1)
  accuracyPostLesson = Math.max(accuracyPostLesson, 1)

  let index = accuracyPreLesson - 1 // Make it 0 based for look up
  let preLessonConversionScoreACT = lookUpTable4ACT[category] && lookUpTable4ACT[category][index] || "0"
  let preLessonConversionScoreSAT = (category == "Math" ? lookUpTable4SAT["Math"][index]: lookUpTable4SAT["EBRW"][index]) || "0"
  
  index = accuracyPostLesson - 1 // Again, make it 0 based for look up
  let postLessonConversionScoreACT = lookUpTable4ACT[category] && lookUpTable4ACT[category][index] || "0"
  let postLessonConversionScoreSAT = (category == "Math" ? lookUpTable4SAT["Math"][index]: lookUpTable4SAT["EBRW"][index]) || "0"

  return { 
    preLessonConversionScoreACT, 
    preLessonConversionScoreSAT,
    postLessonConversionScoreACT,
    postLessonConversionScoreSAT
  }
}

/**
 * Gets the short pre lessons info for ACT/SAT based on test kind.
 * 
 * @param equivalentScoreInfo 
 * @param testKind 
 * @returns short info
 */
export const shortEquivalentInfoForPreLesson = (equivalentScoreInfo: ScoreACTandSAT, testKind: TestKind): string => {
  let scoreInfo = testKind === TestKind.ACT ? equivalentScoreInfo.preLessonConversionScoreACT: equivalentScoreInfo.preLessonConversionScoreSAT
  return prepareShortInfo(testKind, scoreInfo)
}

/**
 * Gets the short post lessons info for ACT/SAT based on test kind.
 * 
 * @param equivalentScoreInfo 
 * @param testKind 
 * @returns short info
 */
export const shortEquivalentInfoForPostLesson = (equivalentScoreInfo: ScoreACTandSAT, testKind: TestKind): string => {
  let scoreInfo = testKind === TestKind.ACT ? equivalentScoreInfo.postLessonConversionScoreACT: equivalentScoreInfo.postLessonConversionScoreSAT
  return prepareShortInfo(testKind, scoreInfo)
}

/**
 * Convenience function that is just used locally here to prepare and return 
 * the short info about the equivalent score based on the test kind
 * 
 * @param testKind 
 * @param scoreInfo 
 * @returns short info
 */
const prepareShortInfo = ( testKind: TestKind, scoreInfo: string): string => {
  let prefix = testKind === TestKind.ACT ? "ACT" : "SAT"
  return `${prefix} ${scoreInfo}`
}
