import * as React from 'react';

import {
    getCurrentLesson,
    incrementNumLessonsStarted,
    setCurrentLesson,
    setLessonCompleted,
    setLessonInProgress,
    setNextLessonAfter
} from '../../stores/lesson.store';

import { AppState } from '../../store';
import StaySharpQuestions from './StaySharpQuestions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = ({ lesson, account }: AppState) => ({
    numLessonsStarted: lesson.numLessonsStarted,
    numLessonsCompleted: lesson.numLessonsCompleted,
    userProgress: getCurrentLesson(lesson),
    currentLessonId: lesson.currentLessonId,
    userInfo: account.userInfo
});

const mapDispatchToProps = {
    setLessonInProgress,
    setNextLessonAfter,
    setCurrentLesson,
    setLessonCompleted,
    incrementNumLessonsStarted
};

const ConnectedLesson = connect<any, any, any, any>(mapStateToProps, mapDispatchToProps)(StaySharpQuestions);
export default withRouter((props: RouteComponentProps<any>) => (
    <ConnectedLesson lessonId={props.match.params.lessonId} topicId={props.match.params.topicId} {...props} />
));
