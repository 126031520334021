import { CDN_PUB_URL } from "../../constants"

export function getExplanationURL(path: string, baseUrl: string): string {
    let fullUrl = `${baseUrl}${escape(path)}`

    // Check if the path like this $CDN/rest-of-the-path and replace it with CDN URL
    if (path.startsWith("$CDN")) {
        path = path.replace("$CDN", "")
        fullUrl = `${CDN_PUB_URL}${escape(path)}`
    }

    return fullUrl
}
