import * as React from 'react';
import { connect } from 'react-redux';

import {
  TeacherDashboard,
  Props,
  StoreProps
} from './TeacherDashboard';

import { AppState } from '../../../../store';
import { UserInfo } from '../../../../services/account/account-rest-interface';
import { getCurrentLesson } from '../../../../stores/lesson.store';

const mapStateToProps = ({ account, lesson }: AppState): StoreProps => ({
  userInfo: account.userInfo as UserInfo,
  currentLesson: getCurrentLesson(lesson),
});

const ConnectedTeacherDashboard = connect<StoreProps, {}, Props, AppState>(
  mapStateToProps,
  {}
)(TeacherDashboard);

export default props => {
  return <ConnectedTeacherDashboard {...props} />;
};
