import * as React from 'react';

import { LessonComplete, Props, RouteProps } from './LessonComplete';
import { getBaselineQuestions, getMistakeBankOverviewNoSS } from '../../services/elearn/lesson-rest-interface';

import { AppState } from '../../store';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { setLessonCompleted } from '../../stores/lesson.store';
import { withRouter } from 'react-router-dom';

const mapStateToProps = ({ lesson, account }: AppState, ownProps: Props) => ({ 
    allLessons: lesson.userLessons.allLessons, 
    userInfo: account.userInfo 
});

const ConnectedLesson = connect<any, any, any, AppState>(mapStateToProps, { setLessonCompleted })(LessonComplete);
const RoutedLesson = (props: RouteComponentProps<any>) => {
    const goToMistakeBank = () => props.history.push(`${process.env.REACT_APP_BASE_URL}app/mistake-bank`);
    const goToLessons = () => props.history.push(`${process.env.REACT_APP_BASE_URL}app/lessons`);
    return (
        <ConnectedLesson
            lessonId={props.match.params.lessonId as string}
            goToMistakeBank={goToMistakeBank}
            /* returnToVideo={returnToVideo} */
            goToLessons={goToLessons}
            {...props}
        />
    );
};
export default withRouter(RoutedLesson);
