import './MCAnswerHint.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import Button from '../../../../../Button/Button';
import Checkbox from '../../../../../Checkbox/Checkbox';
import TextBox from '../../../../../TextBox/TextBox';

export interface Props {
  /* Called once a user has finished this question */
  onNextQuestion: () => void;
  addHintToMistakeBank: (hint: string, display: boolean) => void;
  updateAskForHint?: (checked: boolean) => void;
}

interface State {
  fieldState: FieldStateMap;
  hintSet: boolean;
  checked: boolean;
}

export interface FieldState {
    key: string;
    value?: any;
}

interface FieldStateMap {
    [key: string]: FieldState;
}

export const initialFormState: FieldStateMap = {
    about: { key: 'about' },
    message: { key: 'message' },
};

const aboutOptions = ['Other', 'This Question'];

type AllProps = Props;


export default class MCAnswerHint extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);

        const fieldState: FieldStateMap = Object.keys(initialFormState)
            .map((key, i, arr) => initialFormState[key])
            .map(formItem => ({ isValid: true, feedback: '', value: '', ...formItem }))
            .reduce((acc, formItem: FieldState) => ({ ...acc, [formItem.key]: formItem }), {});

        fieldState.about.value = aboutOptions[1];
        fieldState.message.value = '';

        this.state = {
            fieldState,
            hintSet: false,
            checked: false
        };
    }

    public render() {

        const { fieldState, checked } = this.state;

        return (
                <div className={' hint-container'}>
                    <div className="hint--text">
                        {!this.state.hintSet && <span>
                        <div className="hint-header">
                            <p className="header-text"> Write yourself a hint </p>
                            <img
                                alt="Add hint"
                                className="hint-icon"
                                src={`/assets/images/mistakeBank/hint-icon-2022.svg`}
                            />
                        </div>
                        {/* <p className="header-text"> Write yourself a hint </p> */}
                        <p className="subtext">
                            You'll have access to this hint later
                            when reviewing  the question in your
                            Mistake Bank. You can add or edit the
                            hint later.
                        </p>

                        <TextBox
                            id="message"
                            labelText=""
                            inputType="email"
                            placeholder="Please enter..."
                            value={fieldState.message.value}
                            onChange={this.onMessageChange}
                            resizeable={false}
                        />

                        <Checkbox
                            checked={checked}
                            onCheck={this.onCheck}
                            labelText="Don't ask again during this lesson"
                            checkboxClass="mc-answer-hint__checkbox"
                        />

                        <div className="submit-btns">
                            <Button
                                text="SKIP"
                                textColor="white"
                                bgColor="blue"
                                onClick={this.props.onNextQuestion}
                            />

                            <Button
                                text="SAVE"
                                textColor="white"
                                bgColor="green"
                                onClick={this.sendHint}
                            />
                        </div>

                        </span>}

                        {this.state.hintSet &&
                            <div>
                                <p className="hint__confirm-text"> Your hint has been added to your Mistake Bank. </p>
                            </div>}
                    </div>
                </div>
            );
    }

    private updateFieldValue(fieldName: string, newValue: any, additionalParams?: Partial<FieldState>) {
        const field = {...this.state.fieldState[fieldName], value: newValue,
            ...additionalParams };
        const fieldState = { ...this.state.fieldState, [fieldName]: field };
        this.setState({ fieldState });
    }

     @boundMethod
     private onMessageChange(e: any) {
         this.updateFieldValue('message', e.target.value);
     }

     @boundMethod
     private sendHint() {
         this.setState({hintSet: true});
         const { fieldState } = this.state;
         this.props.addHintToMistakeBank(fieldState.message.value, !this.state.checked);
         return;
     }

     @boundMethod
     private onCheck() {
         this.setState({ checked: !this.state.checked }, () => {
           if (this.props.updateAskForHint) {
            this.props.updateAskForHint(this.state.checked);
          }
         });
     }
}
