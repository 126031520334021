import * as React from 'react'
import * as html2pdf from "html2pdf.js"
import { RouteComponentProps } from 'react-router-dom'
import { PageContent, PageTitle } from '../../../components/PageContent/PageContent'
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import Button from '../../../components/Button/Button'
import RadialProgressEx from '../../../components/RadialProgress/RadialProgressEx'
import CircularPercentile from '../../../components/CircularPercentile/CircularPercentile'
import Loading from '../../../components/Loading/Loading'
import { sendPageviewInfo } from '../../../WinwardTracker'
import { getAllLessons } from '../../../services/elearn/lesson-rest-interface'
import { LessonInfo } from '../../../services/elearn/lesson-types'
import { createFileName } from '../../../services/utils/filename'
import { analyzeTest, IAnalyzedTestResult, ICategoryInfo, ILesson, IScoreDetails, ITopic } from '../../../services/practice-test-scoring/practice-test-summary'
import { dateToDateStr } from '../../../services/utils/date-util'
import {MAX_SCORE_ACT, MAX_SCORE_SAT, getSATSectionDisplayName, getTestTypeBasedOnName, isInterimAssessment, isSatTest} from "../../../services/utils/practice-test-util"
import { getDetailIAScoresByTest, getDetailPTScoresByTest } from '../../../services/practice-test-scoring/practice-test-service'
import Modal from '../../../components/Modal/Modal'
import BrightcoveVideo from '../../../components/BrightcoveVideo/BrightcoveVideo'
import { ITestQuestion } from '../../../services/elearn/teacher/practice-tests-insights'
import './PracticeTestSummary.css'

declare const videojs // Brightcove video

interface Props extends RouteComponentProps { }
interface State {
  firstName?: string
  lastName?: string
  emailAddress?: string
  lessons: LessonInfo[]
  testType?: string // ACT|IA|SAT
  testDisplayName?: string
  testDate?: string
  maxScore: number
  analyzedPracticeTest?: IAnalyzedTestResult
  testName: string
  answeredTestQuestions: ITestQuestion[]
  showModal: boolean
  videoId: string
}

class PracticeTestSummary extends React.Component<Props, State> {
    static instance

    constructor (props: Props) {
        super(props)

        this.state = {
          lessons: [],
          testType: undefined,
          testDisplayName: undefined,
          maxScore: MAX_SCORE_ACT, // Will be updated based on the testType
          analyzedPracticeTest: undefined,
          testName: "",
          answeredTestQuestions: [],
          showModal: false,
          videoId: ""
        }

        PracticeTestSummary.instance = this
    }

    componentDidMount() {
      let path = window.location.pathname
      let testType = this.props.match.params["testType"]
      let testName = this.props.match.params["testName"]

      getAllLessons().then(this.setLessons)
      analyzeTest(testType, testName).then(data => this.setAnalyzedPracticeTest(testName, testType, data))
      sendPageviewInfo(path)

      let getDetailScoresByTest = isInterimAssessment(testName) ? getDetailIAScoresByTest(testName) : getDetailPTScoresByTest(testName)
      
      getDetailScoresByTest.then(res => {
        // For ACT/IA
        if (!isSatTest(testName)) {
          let {questions} = res
          let {englishAnswers, mathAnswers, readingAnswers, scienceAnswers} = questions
          let answeredTestQuestions = [...englishAnswers, ...mathAnswers, ...readingAnswers, ...scienceAnswers]
          this.setState({answeredTestQuestions})
        }
      })
    }

    setAnalyzedPracticeTest = (testName: string, testType: string, analyzedPracticeTest: IAnalyzedTestResult) => {
      let {displayName, testDate} = analyzedPracticeTest
      let maxScore = testType === "SAT" ? MAX_SCORE_SAT: MAX_SCORE_ACT
      this.setState({testName, testType, testDisplayName: displayName, testDate, maxScore, analyzedPracticeTest})
    }

    setLessons = (lessons: LessonInfo[]) => {
      if(lessons.length > 0) {
        let { firstName, lastName, emailAddress } = lessons[0]
        this.setState({
          lessons,
          firstName,
          lastName,
          emailAddress,
        })
      } else {
        let firstName = ' '
        this.setState({
          lessons,
          firstName
        })
      }
    }

    handeClickForPdf = () => {
      let { firstName, lastName, emailAddress } = this.state
      let content = document.getElementById("pdf-main-content")
      let filename = createFileName("practice-test-summary-" + firstName + "-" + lastName, "pdf")
      
      let opt = { 
        filename,
        margin: 0.5,
        html2canvas: { windowWidth:1024, letterRendering: true },
        image:       { type: "jpeg", quality: 0.99 },
        jsPDF:       { unit: "in", format: "letter", orientation: "portrait" },
        // pagebreak:   { avoid: "tr, td", mode: ["css"], before: [".page-break", "br.page-break"], after: [".page-break-aft"] }
        // pagebreak:   { before: [".page-break", "br.page-break"], after: [".page-break-aft"], avoid: ".rowNoBreak, .report-lesson-summary-title,  img" }
      }

      html2pdf().set(opt).from(content).toPdf().get('pdf').then(pdf => {
        let nofPages = pdf.internal.getNumberOfPages()

        for (let i = 1; i <= nofPages; i++) {
          let x = pdf.internal.pageSize.getWidth() - 1
          let y = pdf.internal.pageSize.getHeight() - 0.1
          let pageNumber = `Page ${i} of ${nofPages}`
          pdf.setPage(i);
          pdf.setFontSize(11);
          pdf.setTextColor(100);
          pdf.text(pageNumber, x, y)
        } 
      }).save()
    }

    private closeModal = () => {
      try {
        let  myplayer = videojs('explanation-video')
        myplayer.pause()        
      }
      catch (err) {
        console.error("Error: ", err)
      }
      finally {
        this.setState({showModal: false})
      }
    }

    static VideoLink = ({videoId, questionNumber}) => {
      function handleVideoClick(e) {
        e.preventDefault()
        PracticeTestSummary.instance.setState({showModal: true, videoId})
      }
      return (
        <a href="" 
          role="button" 
          className="pt-summary-video-hyperlink"
          onClick={(e) => handleVideoClick(e)}>
            {questionNumber}
        </a>
      )
    }

    public render() {
        let { testName, firstName, lastName, emailAddress, answeredTestQuestions, showModal, videoId } = this.state
        let { maxScore, testDisplayName, testDate, analyzedPracticeTest } = this.state

        if (!analyzedPracticeTest) return <Loading/>

        let {average, percentile } = analyzedPracticeTest 
        let displayName = analyzedPracticeTest ? analyzedPracticeTest.displayName: ""
        let categories = analyzedPracticeTest && analyzedPracticeTest.categories 
        let categoryNames = categories && Object.keys(categories)
        let categoryScores = categoryNames.map(name => {
          let category = categories[name]
          return {
            name: category.name,
            score: category.score
          }
        })

        let isSAT = displayName.includes("SAT") ? true : false

        if (isSAT) {          
          let consolidatedSATScore = categoryScores.reduce((acc, cur) => {
            let isMath = cur.name.toLowerCase().includes("math")
            acc.math = isMath ? cur.score: acc.math
            acc.ebrw = !isMath ? cur.score: acc.ebrw
            return acc
          }, {ebrw: 0, math: 0})

          categoryScores = [
            {
              name: 'EBRW',
              score: consolidatedSATScore.ebrw
            },
            {
              name: 'Math',
              score: consolidatedSATScore.math
            }
          ]
        }

        return (
          <>
            <PageContent className="summary-report-page">
              <div className="progress-summary-container wide-width">
                <Controls onClickPDF={this.handeClickForPdf} onClickPrint={() => window.print()}/>

                <div id="pdf-main-content">                    
                  <ReportHeader displayName={displayName} />
                  <div>
                    <div className="row mb-5">
                      <div className="col-md-6">
                        <PreparedFor firstName={firstName} lastName={lastName} emailAddress={emailAddress}
                                  testDisplayName={testDisplayName} testDate={testDate}/>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-6 text-center large-dials-column">
                            <div className="row">
                              <div className="score col-12">
                                <RadialProgressEx
                                  progress={average / maxScore * 100}
                                  text={average+""}
                                  startColor={'#05b4b9'}
                                  endColor={'#05b4b9'} />
                                  <div className="col-12 progress-title">
                                    {testDisplayName}
                                  </div>
                              </div>
                              <div className="percentile col-12">
                                <CircularPercentile
                                  percentile={percentile} />
                                  <div className="col-12 progress-title">
                                  {testDisplayName} Percentile
                                  </div>
                              </div>
                            </div>
                            <br/>
                          </div>
                          <div className="col-6">
                            <div className={`row small-dials-column` + (isSAT ? '--center' : ' justify-content-center')}>
                              {categoryScores.map((cat, index) => {
                                let dialColor = getSummaryStyle(cat.name, 'color')
                                let modClass = 'mb-2 align-content-center'
                                if(isSAT) {
                                  index == 0 ? modClass = 'mt-3' : modClass = 'my-4'
                                }

                                return (
                                  <div key={cat.name} className={modClass + ` small-dials-column--dial text-center text-sm-left col-12 ml-md-3 align-items-center`}>
                                      <div className="">
                                        <RadialProgressEx
                                          progress={cat.score / maxScore * 100}
                                          text={cat.score+""}
                                          startColor={dialColor}
                                          endColor={dialColor}
                                          size={66}
                                          radius={26}
                                          pegOffset={8}
                                          textSytle={{fontSize: '22px', color: '#2B2D2D', fontWeight: 500, top: 'calc(50% - 11px)'}}
                                          progressLineWidth={7}
                                          backgroundLineWidth={7}
                                          customClass="radial-small mx-0" />
                                      </div>
                                      <div className="small-dials-column--text pl-2 pl-sm-3">
                                          <h6>{cat.name}</h6>
                                      </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="" />
                    {categories && categoryNames && categoryNames.map(name => {
                      let category = categories ? categories[name]: {} as ICategoryInfo

                      return (
                        <div key={name} className="mb-5">
                          <Category testName={testName} maxScore={maxScore} category={category} answeredTestQuestions={answeredTestQuestions} />
                        </div>
                      )
                    })}
                  </div>
                </div>             
              </div>
            </PageContent>
          
            {showModal &&
              <Modal
                className="explanation-video modal"
                show={showModal}
                onClose={this.closeModal}
                showXTopRight={true}
                width="976px"
              >
                <button type="button" className="close" onClick={this.closeModal}>&times;</button>
                <BrightcoveVideo id="explanation-video" videoId={videoId} />
              </Modal>
            }
          </>
        )
    }
}

/**
 * Will get summary styles for ACT/IA & SAT sections.
 * Note that ACT/IA and SAT have different sections!
 * However, there are corresponding ACT & SAT sections
 * and whenever possible we will use fall-throughs in 
 * the switch statement.
 * 
 * @param section 
 * @param element 
 * @returns 
 */
function getSummaryStyle(section: string, element: string) {
  let resUrl = "/assets/images/icons/reports/"
  let resColor = '#000000'
  let resColorName

  switch (section) {
    case "English":            // ACT
    case "EBRW":               // SAT 
    case "WritingAndLanguage": // SAT
      resUrl += "icon-subject-english.svg"
      resColor = '#A256D7'
      resColorName = 'purple'
      break

    case "Math":             // ACT
    case "MathCalculator":   // SAT
    case "MathNoCalculator": // SAT
      resUrl += "icon-subject-math.svg"
      resColor = '#25B8D6'
      resColorName = 'blue'
      break

    case
      "Reading": // Both ACT & SAT 
      resUrl += "icon-subject-reading.svg"
      resColor = '#f49100'
      resColorName = 'orange'
      break

    case "Science": // ACT
      resUrl += "icon-subject-science.svg"
      resColor = '#8ac22d'
      resColorName = 'green'
      break

    default: // This shouldn't happen!
      resUrl += "black_x_mark.png"
  }

  if(element == 'color') {
    return resColor
  } 
  else if (element == 'cname') {
    return resColorName
  } 
  else {
    return resUrl
  }
}

//--- Additional Mini Components ---
function ReportHeader(props) {
  let {displayName} = props
  return (
    <div className="row progress-summary-report-header">
      <div className="col-12 d-flex align-items-center">
        <img src="/assets/images/winward-academy-logo.png" alt="logo" width="80px"/>
        <div className="page-title">
          <h1>{displayName} Test Summary</h1>
        </div>
      </div>
    </div>
  )
}

function PreparedFor({firstName, lastName, emailAddress, testDisplayName, testDate}) {
  let preparedFor = firstName ? `Prepared exclusively for ${firstName} ${lastName}`: ""
  let email = emailAddress ? `${emailAddress}` : ""
  let dateInfo = new Date().toLocaleDateString()
  let localDate

  if (testDate) {
    try {
      if (testDate.includes("ET")) {
        testDate = testDate.substring(0, testDate.length-2)
      }
      let date = new Date(testDate)
      localDate = dateToDateStr(date)
    }
    catch {
      // Catch silently!
    }
  }

  return (
    <div className="progress-summary-report-prepared-for">
        <h3 className="mb-4 block-title">{testDisplayName} Results</h3>
        <div className="mb-4 pb-2">
          { <h5>{preparedFor}</h5> }
          { <h6>{email}</h6> }
          <h6 className="progress-summary-report-date">{dateInfo}</h6>
        </div>
        <div className="mb-4 mb-md-0">
          <h6>Test Name: {testDisplayName}</h6>
          {localDate ? <h6>Test Date: {localDate}</h6> : ""}
        </div>
    </div>
  )
}

interface ControlsProps {
  onClickPrint: () => void
  onClickPDF: () => void
}

function Controls(props: ControlsProps) {
  return (
    <div className="control-area">
      <Button 
        text="SAVE AS PDF"
        bgColor="blue"
        onClick={props.onClickPDF}
        className="do-not-print"
      />
    </div>
  )
}

function Category({maxScore, category, answeredTestQuestions, testName}) {
  let {name, score, subcategoryPerformances, lessons} = category as ICategoryInfo
  let lessonsLeft = new Array,
      lessonsRight = new Array,
      rowLessons = new Array,
      rowCount = 0,
      rowHalfway
  let testType = getTestTypeBasedOnName(testName)
  let sectionDisplayName = testType === "SAT" ? getSATSectionDisplayName(name): name

  // first loop count all rows
  // count title row and lesson rows
  lessons.map((lesson, index) => {
    rowCount++
    rowLessons.push(lesson.lessonName)
    for(let r = 0; r < lesson.topics.length; r++) {
      rowCount++
      rowLessons.push(lesson.lessonName)
    }
  })  
  // get halfway calcs/positions
  rowHalfway = Math.round(rowCount / 2)
  let halfwayKey = rowLessons[rowHalfway]
  let pastHalfway = false
  // second loop for display
  lessons.map((lesson, index) => {
    if(pastHalfway) {
      lessonsRight.push(lesson)
    } else {
      lessonsLeft.push(lesson)
      if(lesson.lessonName == halfwayKey) {
        pastHalfway = true
      }
    }
  })
 let dialColor = getSummaryStyle(name, 'color')
 let themeColorName = getSummaryStyle(name, 'cname')

 return (
    <div className="page-break-aft">
      <div className="row mb-4">
        <div className="col-12">
          <span className="report-lesson-summary-title">
            <span className="summaryIconRibbon">
                <img alt="" src={getSummaryStyle(name, 'icon')} height="27" />
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="71px" height="45px" viewBox="0 0 71 45" enable-background="new 0 0 71 45">
                <path fill={dialColor} d="M0,0h71L60,22.5L71,45H0V0z"/>
                </svg>
            </span>
            {sectionDisplayName}
          </span>
        </div>
      </div>
      <table className="summarySectionDialsTable mb-4">
        <thead>
          <tr>
            <th>SCORE</th>
            <th>QUESTION PERFORMANCE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="summarySectionDialsTable--dialCol">
              <div className="summary-section-dials-col--dial">
                <div className="text-center">
                  <div className="summary-section-dials">
                    <RadialProgressEx
                      progress={score / maxScore * 100}
                      text={score+""}
                      startColor={dialColor}
                      endColor={dialColor}
                      customClass="" />
                    </div>
                </div>
              </div>
            </td>
            <td>
              <div className="summary-bar-charts">
                  <table className="w-100">
                    <tbody>
                    {subcategoryPerformances.map((perf, index)=> {
                      return (
                        <tr key={perf.subcategory}>
                          <td className="summary-bar-charts--col-label text-right">{perf.subcategory}</td>
                          <td className="summary-bar-charts--col-value"><span>{perf.correct}</span> / <span>{perf.total}</span></td>
                          <td className="summary-bar-charts--col-graph">
                          <ProgressBar
                                current={perf.correct}
                                total={perf.total}
                                barCurrentColor={themeColorName}
                                barTotalColor={`fade`+ themeColorName.charAt(0).toUpperCase() + themeColorName.slice(1)}
                                size="xlarge"
                            />
                          </td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </table>
                </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div className={`row lesson-w-topics-table--wrap ` + themeColorName}>
        <div className={lessonsRight.length ? 'col-xl-6' : 'col-12'}>
          <LessonsWithTopics testName={testName} lessons={lessons} answeredTestQuestions={answeredTestQuestions} halfway={rowHalfway} />
        </div>
        {lessonsRight.length &&
          <div className="col-xl-6 lessons-second-column">
            <div className="d-none d-xl-block">
              <LessonsWithTopics testName={testName} lessons={lessonsRight} answeredTestQuestions={answeredTestQuestions} />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

interface ILessonsWithTopics {
  testName: string
  lessons: ILesson[]
  answeredTestQuestions: ITestQuestion[]
  halfway?: number
}

function LessonsWithTopics({testName, lessons, answeredTestQuestions, halfway=0}: ILessonsWithTopics ) {
  let totalIndex = 0

  return (
    <div className="lesson-table-wrapper">
      <table className="lesson-w-topics-table">
        <thead>
          <tr>
            <th>LESSON TOPIC</th>
            <th>ACCURACY</th>
            <th>CORRECT</th>
            <th>MISSED</th>
            <th>BLANK</th>
            <th><span className="nowrap">MISSED/BLANK #</span></th>
          </tr>
        </thead>

        <tbody>
          {lessons.map((lesson, index) => {
            let {lessonName, details, topics} = lesson
            let modClass = ''
            if(halfway) {
              totalIndex > halfway ? modClass = 'd-xl-none' : modClass = '';
            }
            totalIndex++;
            return (
              <React.Fragment key={lessonName}>
                <tr className={`tr-lesson ` + modClass}>
                  <td><div className="rowNoBreak">{lessonName}</div></td>
                  <td><div className="rowNoBreak">{details!.accuracy}%</div></td>
                  <td><div className="rowNoBreak">{details!.correct}</div></td>
                  <td><div className="rowNoBreak">{details!.missed}</div></td>
                  <td><div className="rowNoBreak">{details!.blank}</div></td>
                  <td><div className="rowNoBreak"></div></td>
                </tr>

                { topics.map(topic => {
                  let {topicName, details} = topic
                  let isSAT  = isSatTest(testName)
                  let missedOrBlankAnswersSimplifiedInfo = details!.missedOrBlankAnswers.join(", ")
                  let missedOrBlankAnswersInfo = isSAT ? missedOrBlankAnswersSimplifiedInfo : getHyperlinksForACTMissedOrBlankAnswers(answeredTestQuestions,  topic)
                  totalIndex++

                  return (
                    <tr key={topicName} className={`tr-topic ` + modClass}>
                      <td><div className="rowNoBreak">{topicName}</div></td>
                      <td><div className="rowNoBreak">{details!.accuracy}%</div></td>
                      <td><div className="rowNoBreak">{details!.correct}</div></td>
                      <td><div className="rowNoBreak">{details!.missed}</div></td>
                      <td><div className="rowNoBreak">{details!.blank}</div></td>
                      <td><div className="rowNoBreak">{missedOrBlankAnswersInfo}</div></td>
                    </tr>
                  )
                })}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}


export default PracticeTestSummary


//--- Helpers ---

function getHyperlinksForACTMissedOrBlankAnswers(answeredTestQuestions: ITestQuestion[], topic: ITopic) {
  let {topicId, details} = topic
  let {missedOrBlankAnswers} = details as IScoreDetails
  let filteredMissingOrBlankAnswers = answeredTestQuestions.filter(a => a.topicId == topicId && missedOrBlankAnswers.includes(a.questionNumber))
  let nofAnswers = filteredMissingOrBlankAnswers.length
  
  return filteredMissingOrBlankAnswers.map((answer, idx) => {
    let {questionNumber, videoId} = answer

    return (
      <>
        <PracticeTestSummary.VideoLink questionNumber={questionNumber} videoId={videoId} />
        {idx === nofAnswers - 1 ? " ": ", "}
      </>
    )
  })
}

