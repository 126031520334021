import './Home.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';

import {
  LessonProgress,
  MistakeBankLessons
} from '../../services/elearn/lesson-types';

import { Redirect, RouteComponentProps } from 'react-router-dom';
import { UserInfo } from '../../services/account/account-rest-interface';
import TeacherDashboardContainer from './components/TeacherDashboard/TeacherDashboardContainer';
import StudentDashboardContainer from './components/StudentDashboard/StudentDashboardContainer';
import CollegeKnowledgeDashboardContainer from './components/CollegeKnowledgeDashboard/CollegeKnowledgeDashboardContainer';
import { Aux } from '../../hoc/Auxiliary';
import { hasSchoolSafety } from '../../services/account/account-utils';
import SchoolSafetyStudentDashboardContainer from './components/SchoolSafetyStudentDashboard/SchoolSafetyStudentDashboardContainer';
import SchoolSafetyTeacherDashboardContainer from './components/SchoolSafetyTeacherDashboard/SchoolSafetyTeacherDashboardContainer';
import { TeacherStore } from '../../stores/teacher.store';
import { BASE_PATH } from '../../App';


export interface Props {}

export interface DispatchProps {
  setUserInfo: (info: Partial<UserInfo>) => void;
}

export interface StoreProps {
  userInfo: UserInfo;
  numLessonsUnstarted: number;
  numLessonsCompleted: number;
  numLessonsStarted: number;
  currentLessonId: string;
  currentLesson: LessonProgress | undefined;
  mistake: MistakeBankLessons[];
  allLessons: LessonProgress[];
}

interface State {}

export type AllProps = Props &
  StoreProps &
  DispatchProps &
  RouteComponentProps<any>;


export class HomeWithoutRouting extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
  }

  componentDidMount() {
    
  }

  public render() {
    const { userInfo } = this.props;
    const { bundleId, isSuperintendent } = userInfo;
    const isSSS = bundleId === "sss" || bundleId === "schoolSafetySecurity"

    // OT: School safety security (i.e. SSS) starts with lessons (no dashboard)
    if (isSSS) return <Redirect to={`${BASE_PATH}/lessons`}  /> 

    // Likewise, if the user superintendent direct them to the district dashboard
    if (isSuperintendent) return <Redirect to={`${BASE_PATH}/dashboard/superintendent`} /> 

    if (bundleId === 'practiceTestsOnly') {
      return (
        <div>XXX PT ONLY! (This is only an intermediate step?</div>
      );
    }
    if (hasSchoolSafety(userInfo) && userInfo.isTeacher) {
      return (
          <Aux>
              <SchoolSafetyTeacherDashboardContainer />
          </Aux>
      );
    }
    if (hasSchoolSafety(userInfo)) {
      return (
        <Aux>
            <SchoolSafetyStudentDashboardContainer />
        </Aux>
    );
    }
    if (
      this.props.userInfo.bundleId === 'collegeKnowledge'
    ) {
      return (
        <Aux>
          <CollegeKnowledgeDashboardContainer />
        </Aux>
      );
    }
    if (this.props.userInfo.isTeacher) {
      return (
          <Aux>
            <TeacherDashboardContainer />
          </Aux>
      );
    }
    return (
        <Aux>
            <StudentDashboardContainer />
        </Aux>
    );
  }
}
