import './TestDashboard.css';

import * as React from 'react';
import * as _ from 'lodash';

import { Link, RouteComponentProps } from 'react-router-dom';
import { PageContent, PageTitle } from '../../components/PageContent/PageContent';

import CompleteDiagnosticCard from './components/CompleteDiagnosticCard/CompleteDiagnosticCard';
import ExpirationDialog from '../Home/components/ExpirationDialog';
import GeneralConversionModal from './components/GeneralConversionModal/GeneralConversionModal';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import PracticeTestCard from './components/PracticeTestCard/PracticeTestCard';
import PracticeTestComponentSelector from './components/PracticeTestComponentSelector';
import PracticeTestInfoCard from './PracticeTestInfoCard';
import { UserInfo } from '../../services/account/account-rest-interface';
import { getScores, getCurrentIAScores } from '../../services/practice-test-scoring/practice-test-service';
import { App } from '../../App';

export interface Props {
}

export interface StoreProps {
    userInfo: UserInfo;
}

interface State {
    scoresList: Array<any>;
    startedCount: number;
    showUnlockDialog: boolean;
    showConversionModal: boolean;
    testType: string;
}

export type AllProps = Props & StoreProps & RouteComponentProps<any>;

export class TestDashboard extends React.Component<AllProps, State> {
    constructor (props: AllProps) {
        super(props);
        let search = new URLSearchParams(this.props.location.search);

        this.state = {
            scoresList: [],
            startedCount: -1,
            showUnlockDialog: false,
            showConversionModal: false,
            testType: search.get('testType') || 'act'
        };

    }

    allowActAccess() {
        const { bundleId } = this.props.userInfo;
        const bundleName = bundleId ? bundleId.toLowerCase() : undefined;
        return _.includes(['actcrunchtime', 'gamechanger', 'freetrial', 'mathonly', 'practicetestsonly'], bundleName);
    }

    allowSatAccess() {
        const { bundleId } = this.props.userInfo;
        // console.log('bundleid', bundleId);
        const bundleName = bundleId ? bundleId.toLowerCase() : '';
        return _.includes(['satcrunchtime', 'gamechanger', 'freetrial', 'mathonly', 'practicetestsonly'], bundleName);
    }

    UNSAFE_componentWillReceiveProps(nextProps: AllProps) {
        const searchParams = new URLSearchParams(nextProps.location.search);
        this.setState({ 
            testType: searchParams.get('testType') || 'act',
        }, () => this.getTestStatuses());
    }

    switchTo(testType: string) {
        this.setState({ scoresList: [] });
        if (testType === 'ACT') {
          this.props.history.push('/app/practice-tests?testType=act');
        } else if (testType === 'SAT') {
          this.props.history.push('/app/practice-tests?testType=sat');
        } else if (testType === 'IA') {
          this.props.history.push('/app/practice-tests?testType=ia');
        }
    }

    public render() {
        const { scoresList, showConversionModal, testType } = this.state;
        const { isTeacher } = this.props.userInfo;

        const isSAT = testType === 'sat';
        // HACK: Prevent ACT dashboard from showing up if it's SAT only.
        const satOnly = !this.allowActAccess() && this.allowSatAccess();
        if (satOnly && !isSAT) {
            // console.log('yo sat only?');
            this.props.history.push('/app/practice-tests?testType=sat');
        }

        const self = this;
        let diagnosticScores = scoresList.find((obj) => {
            return obj.testName === 'Diagnostic' || obj.testName === 'DiagnosticSAT'; 
        });

        let allScores = this.state.scoresList;

        const diagnosticStarted = diagnosticScores !== undefined && 
          (diagnosticScores.status === 'started' || diagnosticScores.status === 'not started');
        if (testType !== 'ia') {
          if (diagnosticStarted) {
              allScores = scoresList.filter((obj) => {
                  return !obj.displayName.includes('Diagnostic');
              });
          }
        } 

        const hasIaPermission = this.props.userInfo.iaPermission;
        const teacherEmail = this.props.userInfo.emailAddress;

        return (
            <PageContent className="practice-test-dashboard">
                <PageTitle title="Practice Tests">
                        <div className="navigation-button-container">
                            {(this.state.startedCount >= 2 && !isTeacher) &&
                            <Link title="TOPICS OVER TIME" className="title-navigation-buttons btn btn--bg-blue" to={`/app/practice-tests/${testType}/growth-analytics/topics`}>
                                   TOPICS OVER TIME
                            </Link>
                            }
                            {(this.state.startedCount >= 2 && !isTeacher) &&
                            <Link title="GROWTH ANALYTICS" className="title-navigation-buttons btn btn--bg-green" to={`/app/practice-tests/${testType === 'ia' ? 'ia/' : ''}growth-analytics?testType=${testType.toLowerCase()}`}>
                                GROWTH ANALYTICS
                            </Link>
                            }
                        </div>
                </PageTitle>
                <div className="test-dashboard-nav-bar">
                  { this.allowActAccess() &&
                      <PracticeTestComponentSelector 
                        displayName="ACT Dashboard"
                        onSwitchComponent={() => this.switchTo('ACT')}
                        active={testType === 'act'}
                      />
                  }
                  { this.allowSatAccess() &&
                      <PracticeTestComponentSelector 
                        displayName="SAT Dashboard"
                        onSwitchComponent={() => this.switchTo('SAT')}
                        active={testType === 'sat'}
                      />
                  }
                  { hasIaPermission &&
                      <PracticeTestComponentSelector 
                        displayName="Interim Assessments"
                        onSwitchComponent={() => this.switchTo('IA')}
                        active={testType === 'ia'}
                      />
                  }
                </div>

                <PracticeTestInfoCard testType={testType} /> 

                {diagnosticStarted &&
                    <div className="row stats-holder">
                        <div className="col-xl-8 stats-holder-diagnostic">
                            <CompleteDiagnosticCard score={diagnosticScores} totalScore={isSAT ? 1600 : 36} percentile="99th" openConversion={this.popConversionModal} />
                        </div>
                        <div className="col-xl-4">
                            <PracticeTestCard
                            score={diagnosticScores}
                            totalScore={isSAT ? 1600 : 36}
                            showUnlockDialog={self.showUnlockDialog}
                            testType={testType}
                            diagnostic
                            />
                        </div>
                    </div>
                }

                <div className="row stats-holder">

                {(scoresList.length === 0) && 
                  <div style={{ paddingTop: '2rem', margin: 'auto'}}>
                    <Loading />
                  </div>
                  }
                {allScores.map((object: any, i: number) => {
                    return (
                    <div 
                        key={i} 
                        className="act-results-card col-lg-6 col-xl-4"
                    >
                        <PracticeTestCard 
                            score={object} 
                            totalScore={isSAT ? 1600 : 36} 
                            showUnlockDialog={self.showUnlockDialog} 
                            diagnostic={object.testName === 'Diagnostic'}
                            testType={testType}
                        />
                    </div>);
                })}
                </div>

                <GeneralConversionModal show={showConversionModal} onClose={this.closeConversionModal} />

                <Modal
                    className="heading-banner-home unlock-modal expired-account-dialog"
                    show={this.state.showUnlockDialog}
                    onClose={this.closeUnlockDialog}
                    width="auto"
                >
                    <ExpirationDialog {...this.props} unlockAccount closeModal={this.closeUnlockDialog} />
                </Modal>
            </PageContent>
        );
    }

    public componentDidMount() {
        this.getTestStatuses();
    }

    popConversionModal = () => {
        this.setState({
            showConversionModal: true
        });
    }

    closeConversionModal = () => {
        this.setState({
            showConversionModal: false
        });
    }

    private showUnlockDialog = () => {
        this.setState({
            showUnlockDialog: true
        });
    }

    private closeUnlockDialog = () => {
        this.setState({
            showUnlockDialog: false
        });
    }

    private getTestStatuses() {
        let self = this;

        const { testType } = this.state;
        if (testType === 'ia') {
          getCurrentIAScores().then(setTestResults);
        } else if (testType === 'sat') {
          getScores('sat').then(setTestResults);
        } else {
          getScores().then(setTestResults);
        }

        function setTestResults(res: any) {
          let startedCount = 0;
          for (let i = 0; i < res.length; i++) {
            if (res[i].status === 'started') {
              startedCount++;
            }
          }

          self.setState({
              scoresList: res,
              startedCount: startedCount, 
          });
        }
    }
}
