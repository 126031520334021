import * as React from 'react'
import CollapsableCard, { CollapsableCardBody, CollapsableCardHeader } from '../../../components/CollapsableCard/CollapsableCard'
import DataGrid from '../../../components/DataGrid'
import { Column, RowData } from '../../../components/DataGrid/types/types'
import ResourceListCardHeader from '../../../components/ResourceListCardHeader'
import { downloadPDF, downloadAllPDFs, openPDF } from '../../../services/elearn/resource-rest-interface'
import { ITeacherResource } from './types'
import { getSubjectIconList, SubjectIcon } from '../../../services/icon/icon-service'
import { ResourceStore } from '../../../stores/resource.store'

const sortMethod = (str1: string, str2: string) => {
    if (str1 > str2) return 1
    if (str1 < str2) return -1
    
    return 0    
}

const resourceColumns: Column[] = [
    {
        title: 'LESSONS',
        dataProperty: 'lesson',
        width: '30%',
        sortable: true,
        sortMethod: sortMethod
    },
    {
        title: 'TOPICS',
        dataProperty: 'topic',
        width: '40%',
        sortable: true,
        sortMethod: sortMethod
    },
    {
        title: 'WORKSHEET',
        dataProperty: 'worksheet',
        width: '15%',
        sortable: false
    },
    {
        title: 'ANSWER KEY',
        dataProperty: 'key',
        width: '15%',
        sortable: false
    },
]

const subjectIconList = getSubjectIconList('@0,5x');

// OT: It returns the subject related info, such as icon and color, not just icon!
const getSubjectIcon = (subjectTitle: string): SubjectIcon => {
    let subjectIcon

    subjectIconList.forEach(si => { 
        if (si.title === subjectTitle) {
            subjectIcon = si
        }
    })

    return subjectIcon
}

interface Props {}

interface State {
    subjectsMap: {[index:string]: ITeacherResource[]}
}

export class StaySharpWorksheets extends React.Component<Props, State> {
    state = {
        subjectsMap: {}
    }

    constructor(props) {
        super(props)
        this.init()
    }

    async init() {
        let resources = await ResourceStore.loadStaySharpWorksheets()
        let subjectsMap = {}
        resources.forEach(r => {
            let key = r.subject
            let resourceItems = subjectsMap[key]

            if (!resourceItems) {
                resourceItems = []
                subjectsMap[key] = resourceItems
            }
            resourceItems.push(r)
        })
        this.setState({subjectsMap})
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private handlePDFLinkClick = (e, path: string) => {
        e.preventDefault()
        openPDF(path)
    }

    private createContent = (resourceUrl, resourceTitle, resourceType) => {
        let imgSrc = "/assets/images/icons/v2/ico-handout-download.svg"
        // The "a" tag has the CDN URL in the href instead of the local one!
        return () => (
            <a className="btn-simple downloadable" title={`Download the ${resourceTitle} ${resourceType}`} href={resourceUrl} target="_blank" tabIndex={0}>
                <img alt="" width="16" height="20" src={imgSrc}></img>
            </a>
        )
    }

    private createContentOld = (resourceName) => {
        let imgSrc = "/assets/images/icons/v2/ico-handout-download.svg"
        let path = `teacher/${resourceName}`
        let resPath = `resources/${path}`

        // OT: The "a" tag has a displayable URL (for accessiblity purposes) 
        // but we will get the actual content from our CDN!
        return () => (
            <a className="btn-simple downloadable" href={resPath} target="_blank" onClick={e => this.handlePDFLinkClick(e, path)} tabIndex={0}>
                <img alt="" width="16" height="20" src={imgSrc}></img>
            </a>
        )
    }    

    private createTeacherResourceData = (resource: ITeacherResource, index: number): RowData => {
        let id = `teacher-resouce-${resource.lesson}-${index}`
        let topicContent = `${resource.topic}`
        let teacherResourceData = {
            lesson: {
                content: resource.lesson,
                comparator: resource.lesson,
            } , 
            topic: {
                content: topicContent,
                comparator: topicContent,
            },  
            worksheet: {
                content: this.createContent(resource.worksheetUrl, topicContent, 'Worksheet'),
                comparator: resource.worksheet, 
            },
            key: {
                content: this.createContent(resource.answerKeyUrl, topicContent, 'Answer Key'),
                comparator: resource.answerKey,
            }
        }

        return {
            id,
            data: teacherResourceData
        }
    }

    getCardBodyContent = (resourceItems) => {
        let data = resourceItems.map(this.createTeacherResourceData)

        return (
            <DataGrid
                columns={resourceColumns}
                data={data}
                emptyText="No resource to display"                
            />
        )
    }

    render() {
        let { subjectsMap} = this.state        
        let subjectKeys = Object.keys(subjectsMap)
        let defaultColor = "#10a492"

        return (
            <div>
                {/* OT: Download All will be implemented later */}
                {/* <div className="row">
                    { <div className="col-9 sub-title">Stay Sharp Worksheets</div> }
                    { <div className="col-3 download-all-link">
                        <a onClick={e => downloadAllPDFs()}>Download All</a>
                    </div> }
                </div> */}

                {subjectKeys.map(key => {
                    let resourceItems = subjectsMap[key]
                    let iconPath = `/assets/images/icons/subjects/ico-subject-${key.replace(/\s/g, '').toLowerCase()}.svg`
                    let si = getSubjectIcon(key)
                    let color = si ? si.color: defaultColor 

                    return (
                        <CollapsableCard key={key}>
                            <CollapsableCardHeader>
                                <ResourceListCardHeader
                                    text={key}
                                    color={color}
                                    icon={iconPath}
                                    nofResources={resourceItems.length}
                                />
                            </CollapsableCardHeader>
                            <CollapsableCardBody> 
                                <div className={`lesson-table--` + key.replace(/\s/g, '').toLowerCase()}>
                                    {this.getCardBodyContent(resourceItems)}
                                </div>
                            </CollapsableCardBody>
                        </CollapsableCard>
                    )}
                )}
            </div>
        )
    }
}