export function setSessionStorageItem(key: string, value: string) {
    sessionStorage.setItem(key, value);
}

export function getSessionStorageByID(key: string): any {
    if (sessionStorage[key]) {
        return sessionStorage[key];
    } else {
        return null;
    }
}

export function deleteSessionStorageByID(key: string): void {
    if (sessionStorage[key]) {
        delete sessionStorage[key];
    }
}