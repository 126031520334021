import './UnderMaintenance.css';

import * as React from 'react';

interface Props {
    className?: string;
}

const UnderMaintenance: React.SFC<Props> = (props) => {
    return (
        <div className="full-screen">
            <img alt="" src={'/assets/images/Under_construction_icon.png'} />
            <h3>Our apologies. Winward Academy is down for maintenance.</h3>
            <p>Our team works constantly to optimize our e-learning platform and is currently performing a system update.</p>
            <p>We apologize for any inconvenience.</p>
        </div>
    );
};

UnderMaintenance.displayName = 'UnderMaintenance';

export default UnderMaintenance;
