import './QuestionProgressButton.css';

import * as React from 'react';

interface Props {
    direction: 'left' | 'right';
    onClick: () => void;
    enabled?: boolean;
}

const QuestionProgressButton: React.SFC<Props> = (props) => {
    const { enabled, direction, onClick } = props;
    const divClass = `question-progress-btn question-progress-btn--${enabled ? 'enabled' : ''}`;
    const spanClass = `question-progress-btn__arrow question-progress-btn__arrow--${direction}`;

    return (
        <div className={divClass} onClick={enabled ? onClick : undefined}>
            <span className={spanClass} />
        </div>
    );
};

QuestionProgressButton.defaultProps = {};
QuestionProgressButton.displayName = 'QuestionProgressButton';

export default QuestionProgressButton;
