import './MultipleChoiceQuestion.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import BrightcoveVideo from '../BrightcoveVideo/BrightcoveVideo';
import DifficultyLevel from './components/DifficultyLevel/DifficultyLevel';
import MCAnswerPicker from './components/MCAnswerPicker/MCAnswerPicker';
import { Question } from '../../services/elearn/lesson-types';
import QuestionText from './components/QuestionText/QuestionText';
import Timer, { TimerState } from '../Timer/Timer';
const MathJax = (window as any).MathJax;

interface Props {
    question: Question;
    isBaseline?: boolean;
    onAnswer: (isCorrect: boolean, answerIndex: number) => void;
    onNextQuestion: () => void;
    addHint: (hint: string, display: boolean) => void;
    userHasAnswered?: boolean;
    isLastQuestion?: boolean;
    isMistakeBank?: boolean;
    hintText?: string;
    shouldShowHintPopup?: boolean;
    useMathFont: boolean;
    isTeacher: boolean;
    addToMB?: (add: boolean) => void;
    canNext: boolean;
    timerState?: TimerState
    onVideoStart?: () => void
}

interface State {
    showTeachMeVideo: boolean;
    showHintPopover: boolean;
    showRenderedMath: boolean;
}

export default class MultipleChoiceQuestion extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showTeachMeVideo: false,
            showHintPopover: this.props.shouldShowHintPopup ? this.props.shouldShowHintPopup : true,
            showRenderedMath: true
        };
    }

    public componentDidMount() {
        MathJax.Hub.Typeset((args) => {
            this.setState({ 
                showRenderedMath: true,
            });
        });
    }

    public componentDidUpdate(prevProps: Props) {
        const { question, useMathFont } = this.props;
        if (question.questionHtml !== prevProps.question.questionHtml) {

            if (useMathFont) {
                this.setState({
                    showRenderedMath: false,
                    showTeachMeVideo: false 
                }, () => {
                    MathJax.Hub.Typeset((args) => {
                        this.setState({ showRenderedMath: true });
                    });        
                });
            } else {
                this.setState({ 
                    showTeachMeVideo: false 
                });
            }
        }
    }

    public render() {
        const { question, isBaseline, onNextQuestion, onAnswer, 
            userHasAnswered, isLastQuestion, useMathFont, timerState } = this.props;
        const { showRenderedMath } = this.state;
        const { difficultyLevel } = question;
        let visible = timerState && timerState == TimerState.HIDDEN ? false: true
        
        return (
            <div className="multiple-choice-question">
                <div className="multiple-choice-question__timer">
                    <Timer visible={visible} />
                </div>
                <div className="multiple-choice-question__question-text">
                <div className="multiple-choice-question__difficulty">
                    <DifficultyLevel difficultyLevel={difficultyLevel} />
                </div>
                    <QuestionText
                        questionText={question.questionHtml}
                        isMistakeBank={this.props.isMistakeBank ? this.props.isMistakeBank : false}
                        hintText={this.props.hintText ? this.props.hintText : ''}
                        updateHint={this.props.addHint}
                        figureImageName={question.questionImageName}
                        useMathFont={useMathFont}
                        mathVisible={showRenderedMath}
                    />

                    {this.state.showTeachMeVideo &&
                        <div className="multiple-choice-question__teach-me">
                            <div>
                                <BrightcoveVideo 
                                    id="teach-me-video" 
                                    videoId={question.feedbackVideoId}
                                    onVideoStart={this.props.onVideoStart}
                                />
                            </div>
                        </div>}
                </div>

                <div className="multiple-choice-question__mc-answer-picker">

                    <MCAnswerPicker
                        question={question}
                        isBaseline={isBaseline}
                        onAnswer={onAnswer}
                        onNextQuestion={onNextQuestion}
                        onShowTeachMeVideo={this.handleShowTeachMeVideo}
                        userHasAnswered={userHasAnswered}
                        isLastQuestion={isLastQuestion}
                        isMistakeBank={this.props.isMistakeBank ? this.props.isMistakeBank : false}
                        addHint={this.setHint}
                        shouldShowHintPopup={!this.props.isTeacher && this.state.showHintPopover}
                        useMathFont={useMathFont}
                        mathVisible={showRenderedMath}
                        isTeacher={this.props.isTeacher}
                        addToMB={this.addToMB}
                        canNext={this.props.canNext}
                    />
                </div>
            </div>
        );
    }

    
    @boundMethod
    private addToMB(add: boolean) {
        if (this.props.addToMB) {
            this.props.addToMB(add);
        }
    }

    
    @boundMethod
    private handleShowTeachMeVideo() {
        this.setState({ showTeachMeVideo: true });
    }

    
     @boundMethod
     private setHint(message: string, display: boolean) {
        this.setState({showHintPopover: display});
        // add hint to backend
        if(this.props.addHint) {
            this.props.addHint(message, display);
        }
    }
}
