import * as React from 'react'
import CircularPercentile from '../../../components/CircularPercentile/CircularPercentile'
import RadialProgress from '../../../components/RadialProgress/RadialProgress'
import RadialProgressEx from '../../../components/RadialProgress/RadialProgressEx'
import { PracticeTestScoreEx } from '../../../services/elearn/practice-test-types'
import PracticeTestCard from '../../Practice/components/PracticeTestCard/PracticeTestCard'
import TestScoreDials from '../../Practice/components/TestScoreDials/TestScoreDials'

const totalScoreACT = 36
const totalScoreSAT = 1600

interface IDiagnostic {
  actScores: PracticeTestScoreEx[]
  satScores: PracticeTestScoreEx[]
}

interface Props {
  diagnostics: IDiagnostic
}

interface State { }

export default class DiagnosticResults extends React.Component<Props, State> {

  render() {
    let {diagnostics} = this.props    
    let actDiagnostics = diagnostics.actScores.find(s => s.testName == "Diagnostic")
    let satDiagnostics = diagnostics.satScores.find(s => s.testName == "DiagnosticSAT")
    let actComposite = actDiagnostics?.satCompositeRange.find(cr => cr.active == true)
    let satComposite = satDiagnostics?.satCompositeRange.find(cr => cr.active == true)
    let radialScoreACT = actDiagnostics?.average ? actDiagnostics.average: 0
    let radialScoreSAT = satDiagnostics?.average ? satDiagnostics.average: 0
    const imagePath = '/assets/images/icons/reports';

    return (
      
      <div className="diagnostic mb-4 pb-1 page-break">
        <div className="student-reports-header">
          <h2><img alt="" width="78" height="78" className="mb-2 mr-2" src={imagePath + '/report-header-icon--reading.svg'} /> Practice Tests</h2>
          <hr/>
        </div>
        <div className="report-content pt-2">
          {/* <div className="row">
            <div className="col-12">
              <div className="h4 mb-3">Diagnostic Scores</div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              {actDiagnostics &&
                <div className="row">
                  <div className="col-12">
                  <div className="h4 mb-4 text-center">ACT Diagnostic Score</div>
                    <div className="row mb-4">
                      <div className="col score-dials-lg">
                        <div className="score">
                          <RadialProgressEx
                            progress={radialScoreACT / totalScoreACT * 100}
                            text={radialScoreACT+""}
                            startColor={'#05b4b9'}
                            endColor={'#05b4b9'} />
                            <div className="col-12 progress-title">
                              ACT Diagnostic
                            </div>
                        </div>

                        <div className="percentile">
                          <CircularPercentile
                            percentile={actDiagnostics?.percentile} />
                            <div className="col-12 progress-title">
                              ACT Percentile
                            </div>
                        </div>
                      </div>
                      <div className="col score-dials-sm">

                        <TestScoreDials
                            score={actDiagnostics}
                            totalScore={totalScoreACT}
                            testType={"act"}
                            showCircle={false}
                            showButton={false}
                            showTitle={false} />
                      </div>
                    </div>

                    <div className="row act-sat-row text-center mb-1 mx-auto mt-0">
                      <div className="col-5 conversion-table-text"><span className="fw-700">{actComposite?.act}</span> ACT</div>
                      <div className="col-2"><img alt="" src="/assets/images/icons/diagnostic/arrows_grey2x.png" width="37" height="9" /></div>
                      <div className="col-5 conversion-table-text"><span className="fw-700">{actComposite?.sat}</span> SAT</div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="col-md-6">
              {satDiagnostics &&
                <div className="row">
                  <div className="col-12">
                  <div className="h4 mb-4 text-center">SAT Diagnostic Score</div>
                    <div className="row mb-4">
                      <div className="col score-dials-lg">
                        <div className="score">
                          <RadialProgressEx
                            progress={radialScoreSAT / totalScoreSAT * 100}
                            text={radialScoreSAT+""}
                            startColor={'#05b4b9'}
                            endColor={'#05b4b9'} />
                            <div className="col-12 progress-title">
                              SAT Diagnostic
                            </div>
                        </div>

                        <div className="percentile">
                          <CircularPercentile
                            percentile={satDiagnostics?.percentile} />
                            <div className="col-12 progress-title">
                              SAT Percentile
                            </div>
                        </div>
                      </div>
                      <div className="col score-dials-sm">
                        <div className="row">
                          <div className="col-12">
                          <TestScoreDials
                              score={satDiagnostics}
                              totalScore={totalScoreSAT}
                              testType={"sat"}
                              showCircle={false}
                              showButton={false}
                              showTitle={false} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="row act-sat-row text-center mb-1 mx-auto mt-0">
                          <div className="col-5 conversion-table-text"><span className="fw-700">{satComposite?.sat}</span> SAT</div>
                          <div className="col-2"><img alt="" src="/assets/images/icons/diagnostic/arrows_grey2x.png" width="37" height="9" /></div>
                          <div className="col-5 conversion-table-text"><span className="fw-700">{satComposite?.act}</span> ACT</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
