import './HeaderCell.css';

import * as React from 'react';

interface Props {
    title: string;
    sortable?: boolean;
    sorted?: boolean;
    width: any;
    customToolTip?:string;
    onClick: () => void;
}

function stripHTML(html) {
    const cleanedHtml = html.replace(/<br\s*\/?>/gi, ' ');
    const doc = new DOMParser().parseFromString(cleanedHtml, 'text/html');
    return doc.body.textContent || "";
}
// TODO make this use TableCell to reduce code duplication
const HeaderCell: React.SFC<Props> = (props) => {
    const sortedClass = props.sorted ? 'sorted' : '';
    const sortableClass = props.sortable ? 'sortable' : '';
    const plainTitle = stripHTML(props.title);
    // let style: React.CSSProperties = { flexBasis: props.width };
    let style: React.CSSProperties = { width: props.width };
    // if(!props.width) {
    //     style = { flex: '1 1 0' };
    // }

    if (props.customToolTip) {
        return (
            <th className={`header-cell ${sortedClass} ${sortableClass}`} style={style}>
                {props.sortable ? (
                    <button className="btn-simple" tabIndex={0} onClick={props.onClick} title={`Sort by ${plainTitle}`}>
                        <div className="th-tooltip">
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: props.title + (props.sortable ? '<span class="header-cell__sort"></span>' : '') }} />
                            </div>
                            <span className="th-tooltip-text">{props.customToolTip}</span>
                        </div>
                    </button>
                ) : (
                    <div className="th-tooltip">
                        <div dangerouslySetInnerHTML={{ __html: props.title }} />
                        <span className="th-tooltip-text">{props.customToolTip}</span>
                    </div>
                )}
            </th>
        );
    } else {
        return (
            <th className={`header-cell ${sortedClass} ${sortableClass}`} style={style}>
                {props.sortable ? (
                    <button className="btn-simple" tabIndex={0} onClick={props.onClick} title={`Sort by ${plainTitle}`}>
                        <span className="p-rel d-inline-block">
                            <span dangerouslySetInnerHTML={{ __html: props.title }}></span>
                            {props.sortable && <span className="header-cell__sort" />}
                        </span>
                    </button>
                ) : (
                    <span className="p-rel d-inline-block">
                        <span dangerouslySetInnerHTML={{ __html: props.title }}></span>
                    </span>
                )}
            </th>
        );
    }
};

HeaderCell.displayName = 'HeaderCell';

export default HeaderCell;
