import * as React from 'react'
import './LessonSummaryOverview.css'
import ReportLoadingMessage from './ReportLoadingMessage'
import Modal from '../../components/Modal/ModalEmail'
import { sendStudentCKReport, sendStudentStaySharpReport } from '../../services/elearn/lesson-rest-interface'
import { StaySharpReportSummary } from '../../services/elearn/lesson-types'
import SummaryFilters from './SummaryFilters'
import { TeacherStore, ITeacherStoreData } from '../../stores/teacher.store'
import { GraduationYearDescriptor } from './types'
import { NO_STUDENT_INFO_FOUND } from './info'
import StaySharpSummary from '../../components/StaySharpSummary'
import { ITimeOnPlatform } from './TimeOnPlatform/top-lesson-processor'

export interface Props { }

interface State {
  selectedSchoolName?: string
  selectedGraduationYear?: string
  data: StaySharpReportSummary[]
  schools: string[]
  graduationYears: GraduationYearDescriptor[]
  showEmailNotificationModal: boolean
  selectedTimeRange?: string
  loaded: boolean
  timeOnPlatformInfo: ITimeOnPlatform[]
}

export default class StaySharpSummaryOverview extends React.Component<Props, State> {
  unsubscribe
  
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSchoolName: undefined,
      data: [],
      schools: [],
      graduationYears: [],
      showEmailNotificationModal: false,
      loaded: false,
      timeOnPlatformInfo: []
    };
  }

  public componentDidMount() {
    this.loadStaySharpSummaries()
    this.unsubscribe = TeacherStore.subscribe(d => {
      this.setMainData(d)
    })
    this.setMainData(TeacherStore.data)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  setMainData(data: ITeacherStoreData) {
    let { staySharpSummaries, timeOnPlatformInfo } = data
    this.setState({ 
      data: staySharpSummaries,
      timeOnPlatformInfo,
      loaded: true
    })    
  }

  sendLessonReport() {
    sendStudentStaySharpReport();
    this.setState({
      showEmailNotificationModal: true
    });
  }

  loadStaySharpSummaries() {
    const { selectedSchoolName, selectedGraduationYear, selectedTimeRange } = this.state;
    const schoolParam = selectedSchoolName !== undefined ? selectedSchoolName : undefined;
    const gradYearParam = selectedGraduationYear !== undefined ? selectedGraduationYear : undefined;
    TeacherStore.loadStaySharpSummaries(schoolParam, gradYearParam, selectedTimeRange)
  }

  onNewSchoolSelected(newSelectedSchool: string) {
    this.setState({ data: [], loaded: false });
    this.setState({
        selectedSchoolName: newSelectedSchool === 'All' ? 
          undefined : newSelectedSchool 
      }, () => this.loadStaySharpSummaries()
    );
  }

  onTimeRangeSelected(newlySelected: string) {
    this.setState({ data: [], loaded: false });
    this.setState({
      selectedTimeRange: newlySelected }, 
      () => this.loadStaySharpSummaries()
    );
  }

  onGraduationYearSelected(selectedYear: string) {
    this.setState({ data: [], loaded: false });
    this.setState(
      { selectedGraduationYear: selectedYear === 'All' ?  
          undefined : selectedYear
      }, 
      () => this.loadStaySharpSummaries()
    );
  }

  public render() {
    const { data, loaded, timeOnPlatformInfo } = this.state;

    return (
        <div className="lesson-overview">
          <SummaryFilters 
            enableTimeRangeSelection={true}
            onNewGraduationYearSelected={(gradYear) => this.onGraduationYearSelected(gradYear)}
            onNewSchoolSelected={(schoolSel) => this.onNewSchoolSelected(schoolSel)}
            onNewTimeRangeSelected={(timeRange) => this.onTimeRangeSelected(timeRange)}
          />
          <div className="individual-data-section--2col">
            <h2 className="h3 individual-data-title">Stay Sharp Summary</h2>
            <div className="title-sidebar">
              <a title="Email Report" className="iconLink--left img-rel" href="#" onClick={() => this.sendLessonReport()}>
              <img src="/assets/images/icons/v2/ico-email.svg" width="14" height="11" alt="" />
              <span className="label def">Email Report</span>
              </a>
            </div>
          </div>
          <hr className="half" />
          <span className="centerNextTable"></span>
          {/* <p>
            You can <a href="#" onClick={() => this.sendLessonReport()}> 
            email the full stay sharp report 
            </a> or view summary information below.
          </p> */}

          {!loaded && <ReportLoadingMessage />}

          {data.map((d,i) => {
            return (
              <StaySharpSummary 
                key={'staysharp-summary-' + d.categoryName + i}
                summaryData={d}
                timeOnPlatformInfo={timeOnPlatformInfo}
              />
              )
            })
          }
          
          {loaded && data.length == 0 && NO_STUDENT_INFO_FOUND}

          <Modal 
            show={this.state.showEmailNotificationModal}
            onClose={() => this.setState({ showEmailNotificationModal: false })}
          >
            <div className="lesson-summary-email-notification-modal">
              <h3>Stay Sharp Report Is On Its Way</h3>
              <p>
              The full lesson report for your students is on its 
              way and will arrive via email in the next 2-3 minutes.
              </p>
            </div>
          </Modal>
        </div>
    );
  }
}
