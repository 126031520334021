import './Tabs.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import TabButton from './components/TabButton/TabButton';
import TabContent from './components/TabContent/TabContent';

interface TabsProps {

}

interface TabsState {
    selectedTab: number;
}

export default class Tabs extends React.Component<TabsProps, TabsState> {
    constructor(props: TabsProps) {
        super(props);
        this.state = {
            selectedTab: 0
        };
    }

    public render() {
        const children = React.Children.toArray(this.props.children);
        const customContent = children.filter(this.isCustomContent);
        const tabs = children.filter(this.isTab);
        const buttons = tabs.map(this.createTabButton);
        const content = tabs.map(this.addTabHandlers);

        return (
            <div className="tab-container">
                <div className="tab-container__btn-container">
                    {buttons}
                    {customContent}
                </div>
                <div className="tab-container__content-container">
                    {content}
                </div>
            </div>
        );
    }

    
    @boundMethod
    private createTabButton(el: any, index: number) {
        return (
            <TabButton
                text={el.props.text}
                key={el.props.text}
                isActive={this.state.selectedTab === index}
                onClick={e => this.handleTabClick(el, index, e)}
            />
        );
    }

    private isTab(el: any) {
        return Tab.prototype === (el.type as any).prototype;
    }

    private isCustomContent(el: any) {
        return CustomHeaderContent.prototype === (el.type as any).prototype;
    }

    
    @boundMethod
    private addTabHandlers(el: any, index: number) {
        return React.cloneElement(el as React.ReactElement<any>, {
            isActive: this.state.selectedTab === index
        });
    }

    
    private handleTabClick(el: any, index: number, e: any) {
        this.setState({ selectedTab: index });
    }
}

interface TabProps {
    text: string;
    isActive?: boolean;
}

export class Tab extends React.Component<TabProps, {}> {
    public static defaultProps: Partial<TabProps> = {
        isActive: false
    };

    public render() {
        return (
            <TabContent hidden={!this.props.isActive}>
                {this.props.children}
            </TabContent>
        );
    }
}

export const CustomHeaderContent: React.SFC<{}> = (props) => {
    return (
        <div className="tab-header__custom-content">
            {props.children}
        </div>
    );
};

CustomHeaderContent.displayName = 'CustomHeaderContent';
