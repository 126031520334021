import './Modal.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';

import Portal from '../Portal/Portal';

interface Props {
    className?: string;
    show?: boolean;
    onClose: () => void;
    width?: string;
    showXTopRight?: boolean;
}

interface State {

}

// TODO flesh this out so it is customizable
export default class Modal extends React.PureComponent<Props, State> {
    public static defaultProps: Partial<Props> = {
        width: '570px'
    };

    constructor(props: Props) {
        super(props);
    }

    public render() {
        const modalClass = this.props.className || '';
        const showClass = this.props.show ? 'show d-block' : '';
        // TODO set this depending on if we have a title so content can be centered
        const emptyHeader = 'modal--empty-header';

        return (
            <Portal>
                <div>
            <div
                className={`modal fade note ${showClass}`}
                id="modal"
                onClick={this.props.onClose}
                tabIndex={-1}
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="true"
            >
            <button className="closeBtn btn-simple" title="Close modal">
                <img
                    className="modal-icon"
                    src={`/assets/images/icons/v2/ico-modal-close-white.svg`}
                    width="24"
                    height="24"
                    alt=""
                />
            </button>

                  <div className="vertical-align-helper">
                    <div className="modal-dialog vertical-align-center modal-center" role="document">

                        <div
                            className={`modal-content ${modalClass}`}
                            onClick={this.preventClose}
                            style={{ maxWidth: this.props.width}}
                        >

                            {/* Body */}
                            <div className="modal-body">
                                {this.props.children}
                                <div className="text-center">
                                    <button className="btn btn--text-white btn--bg-blue" title="Close Modal" style={{ minWidth : "140px"}} aria-label="close" onClick={this.props.onClose}>CONTINUE</button>
                                </div>
                           </div>

                            {/* Footer */}
                            {/*<div className="modal-footer">

                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
            {this.props.show
                && <div className="modal-backdrop fade show"/>
            }
            </div>
            </Portal>
        );
    }

    
    private preventClose(e: any) {
        e.stopPropagation();
    }
}
