import * as React from 'react';
import { TestType } from '../../../../services/utils/practice-test-util';

export interface Props {
  testType: TestType;
  averageScore?: number;

  // a percentage value
  accuracy?: number;
  studentCount?: number;
}

const SummaryAccuracyCell: React.SFC<Props> = ({ 
  accuracy, averageScore, studentCount, testType 
}) => {
  return (
    <div>
      { testType !== TestType.SAT &&
        <span>
           {averageScore}&nbsp;
        </span>
      }
      <span>
         {accuracy && accuracy > -1 && `(${accuracy}%)`}
      </span>
      <div>
        n = {studentCount} 
      </div>
    </div>
  );
};

export default SummaryAccuracyCell;
