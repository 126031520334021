import './StaySharpListCardHeader.css';

import * as React from 'react';

interface Props {
    icon?: string;
    text: string;
    color: string;
}

const StaySharpListCardHeader: React.SFC<Props> = (props) => {
    return (
        <div className="row">
            {/* Subject icon + title */}
            <div className={`stay-sharp-list-card-header col-6 ${props.text ? props.text.toLowerCase() : ''}`}>
                <span className={`ribbon ${props.text ? props.text.toLowerCase() : ''}`}>
                    <img alt="" src={props.icon} />
                </span>
                <span>{props.text}</span>
            </div>
        </div>
    );
};

StaySharpListCardHeader.displayName = 'StaySharpListCardHeader';

export default StaySharpListCardHeader;

