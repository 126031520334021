import * as React from 'react';
import './BundleCard.css';

interface Props {
    onBundleSelected: (id: string, includeCk: boolean, price: number) => void;
    bundle: any;
    preSelectedBundleId: any;
}

interface State {
    showBack: boolean;
}

export default class BundleCard extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
          showBack: false
        };
    }
    
    public render() {
        const { bundle, onBundleSelected } = this.props;
        const classes = (this.props.bundle.bundleId === this.props.preSelectedBundleId && !this.state.showBack) ? 
            'row bundle-card selected-bundle flip-card' : 'row bundle-card flip-card';
        let style = {
            height: '100px',
            width: '100px',
            marginLeft: '0px'
        };
        if (bundle.bundleId === 'gameChanger+ck') {
            style = {
                height: '100px',
                width: '119px',
                marginLeft: '20px'
            };
        }
        return (
            <div 
                className={classes} 
                onClick={() => this.onBundleClick(bundle.bundleId, bundle.includeCK, bundle.price)}
            >
                <div className="flip-card-inner">
                    {!this.state.showBack &&
                        <div className="flip-card-front">
                            <button className="flip-button" onClick={this.flipCard}>+</button> 
                            <img 
                                src={`/assets/images/bundle-icons/${bundle.image}`} 
                                className="bundle-card-image"
                                alt=""
                                style={style}
                            />
                            <p className="bundle-card-title">{bundle.bundleNameLine1}</p>
                            <p className="bundle-card-title">{bundle.bundleNameLine2}</p>
                            <p className="bundle-card-price">${bundle.shownPrice}</p>
                        </div>
                    }
                    
                    {this.state.showBack && 
                        <div className="flip-card-back">
                            <button className="flip-button" onClick={this.flipCard}>-</button>
                            <p className="bundle-card-title">{bundle.bundleNameLine1}</p>
                            <p className="bundle-card-title">{bundle.bundleNameLine2}</p>
                            {bundle.description.map((description, idx) => {
                                return (
                                    <p key={idx} className="text-left bundle-card-content">{description}</p>
                                );
                            })}
                        </div>
                    }
                </div>
            </div>
        );
    }

    onBundleClick = (id, includeCk, price) => {
        this.props.onBundleSelected(id, includeCk, price);
    }
    
    flipCard = (e) => {
        e.stopPropagation();
        this.setState({
            showBack: !this.state.showBack
        });
    }
}
