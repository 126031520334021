import * as React from 'react';

import ProgressBar from '../../../../../components/ProgressBar/ProgressBar';

export interface Props { 
    subject: string;
    score: number;
    totalScore: number;
    barColor: string;
    className?: any;
    showIcon?: boolean;
}

const PracticeTestScoreRow: React.SFC<Props> = (props) => {
    let iconPath = '';
    let iconWidth = 27;
    let iconHeight = 27;
    const baseIconPath = '/assets/images/icons/subjects/';
    switch (props.subject.toLowerCase()) {
        case 'english':
            iconPath = baseIconPath + 'english-icon.svg';
            break;
        case 'math':
            iconPath = baseIconPath + 'math-icon.svg';
            break;
        case 'science':
            iconPath = baseIconPath + 'science-icon.svg';
            iconWidth = 24;
            break;
        case 'reading':
            iconPath = baseIconPath + 'reading-icon.svg';
            iconWidth = 22;
            break;
        case 'writing':
        case 'ebrw':
        case 'r&w':
            iconPath = baseIconPath + 'writing-icon.svg';
            iconWidth = 24;
            break;
        default:
            iconPath = ''; // No icon if subject doesn't match
            break;
    }

    return (
        <div className={'practice-test-score-row ' + props.className}> 
            {props.showIcon && (
                <span className="practice-test-subject--icon">
                    <img src={iconPath} alt={`${props.subject} icon`} width={iconWidth} height={iconHeight}></img>
                </span>
            )}
            <span className="practice-test-subject">{props.subject}</span>
            <span className="practice-test-score">{props.score}</span>
            <ProgressBar
                current={props.score}
                total={props.totalScore}
                barCurrentColor={props.barColor}
                barTotalColor="gray"
                size="small"
            />
        </div>
    );
};

export default PracticeTestScoreRow;
