import './VerifyFailed.css';

import * as React from 'react';
import Button from '../../../../components/Button/Button';
import { Link } from 'react-router-dom';

interface Props {
    error: string;
}

const VerifyFailed: React.SFC<Props> = (props) => {
    return (
        <div className="verify-email-failed-message">
            <p>{props.error}</p>
            <Link to={'/login'}>
                <Button
                    textColor={'white'}
                    bgColor={'green'}
                    text={'LOGIN'}
                />
            </Link>
        </div>
    );
};

VerifyFailed.displayName = 'VerifyFailed';

export default VerifyFailed;
