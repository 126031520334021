import {
    DashboardOverview,
    LessonProgress,
    LessonReport,
    MaterialType,
    UserLessonOverview
} from '../elearn/lesson-types';
import { createGenericAuthRequest, ignoreJsonError } from '../request/request-utils';

import { Feedback } from '../elearn/lesson-types';
import { hasSchoolSafety } from '../account/account-utils';
import { getCachedValue, setCacheValue } from '../utils/cache-util';
import { ERR_CANNOT_GET_DATA_FROM_SERVER } from '../../constants';

const request = createGenericAuthRequest(process.env.REACT_APP_ELEARN_URL || '/');

// === LESSONS === //

/**
 * Get the overview info needed for the Lessons page
 */
export function getUserLessons() {
    return request<UserLessonOverview>(`/usermaterial/overview/lessons`)
        // Since the server sends over MaterialType enum values as strings, we need to
        // transform them to regular enum values (e.g. 'TOPIC' => MaterialType.TOPIC)
        .then(overview => ({
            userLessonOverviews: overview.userLessonOverviews.map(lesson => ({
                ...lesson,
                currentLessonContent: lesson.currentLessonContent ? {
                    ...lesson.currentLessonContent,
                    type: getMaterialTypeEnum((lesson.currentLessonContent as any).type)
                } : null
            }))
        }));
}

/**
 * Get the overview info needed for the dashboard
 */
export function getDashboardOverview() {
    return request<DashboardOverview>('/usermaterial/overview/dashboard');
}

export async function getDashboardOverviewCached() {
    let cacheKey = `user-dashboard-overview-info`
    let data = getCachedValue(cacheKey)

    if (!data) {
        try {
            data = await getDashboardOverview()
            setCacheValue(cacheKey, data)
        }
        catch (ex) {
            console.error(ERR_CANNOT_GET_DATA_FROM_SERVER, ex)
        }
    }

    return data
}
  

// === USER PROGRESS === //

/**
 * Update the user's current lesson. This will set the lesson to "In Progress"
 */
export function setCurrentLesson(lessonId: string): Promise<{}> {
    return request(`/usermaterial/lessons/current/${lessonId}`, {}, { method: 'PUT' })
        .catch(ignoreJsonError);
}

export function setSSCurrentLesson(lessonId: string, topicId: string): Promise<{}> {
    return request(`/stay-sharp/lessons/current/${lessonId}/${topicId}`, {}, { method: 'PUT' })
        .catch(ignoreJsonError);
}

/**
 * Set the current part of the lesson that a user is on (baseline question, video topic, or post-lesson question)
 * @param lessonId Current lesson's lessonID
 * @param type BASELINE_QUESTION, TOPIC, or POST_LESSON_QUESTION
 * @param id The ID of the current question/topic
 */
export function setCurrentMaterial(lessonId: string, type: MaterialType, id: string) {
    const args = {
        type: getMaterialTypeString(type),
        id
    };
    return request(`/usermaterial/lessons/${lessonId}/content/current`, args, { method: 'PUT' })
        .catch(ignoreJsonError);
}

export function setSSCurrentMaterial(lessonId: string, type: MaterialType, id: string) {
    const args = {
        type: getMaterialTypeString(type),
        id
    };
    return request(`/usermaterial/lessons/${lessonId}/content/current?includeSS=true`, args, { method: 'PUT' })
        .catch(ignoreJsonError);
}

/**
 * Get the lesson summary for when a user has finished a lesson
 */
export function getLessonSummary(lessonId: string, userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        return request<LessonReport>(`/sss/lessons/${lessonId}/report`);

    } else {
        return request<LessonReport>(`/usermaterial/lessons/${lessonId}/report`);
    }
}

/**
 * Get a user's progress for a given lesson
 */
export function getLessonProgress(lessonId: string, userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        return request(`/sss/overview/lessons/${lessonId}`)
        .then(({ currentLessonContent, ...rest }: any) => ({
            ...rest,
            currentLessonContent: currentLessonContent ? {
                // something: (() => console.log(currentLessonContent))(),
                ...currentLessonContent,
                type: getMaterialTypeEnum(currentLessonContent.type)
            } : undefined
        } as LessonProgress));
    } else {
        return request(`/usermaterial/overview/lessons/${lessonId}`)
        .then(({ currentLessonContent, ...rest }: any) => ({
            ...rest,
            currentLessonContent: currentLessonContent ? {
                // something: (() => console.log(currentLessonContent))(),
                ...currentLessonContent,
                type: getMaterialTypeEnum(currentLessonContent.type)
            } : undefined
        } as LessonProgress));
    }
}

// === QUESTIONS === //

export function setBaselineAnswer(lessonId: string, questionId: string, isCorrect: boolean, mcLetter: string) {
    return request(`/usermaterial/lessons/${lessonId}/answers/baseline/${questionId}/`, 
        { isCorrect, mcLetter },
        { method: 'POST' })
    .catch(ignoreJsonError);
}

export function setMCAnswer(lessonId: string, questionId: string, isCorrect: boolean, mcLetter: string, userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        return request(`/sss/lessons/${lessonId}/answers/postlesson/${questionId}/`,
        { isCorrect, mcLetter },
        { method: 'POST' })
        .catch(ignoreJsonError);
    } else {
        return request(`/usermaterial/lessons/${lessonId}/answers/postlesson/${questionId}/`,
        { isCorrect, mcLetter },
        { method: 'POST' })
        .catch(ignoreJsonError);
    }
    
}

// === FEEBACK === //
export function sendFeedback(message: Feedback) {
    const args = {
        userEmailAddress: message.userEmailAddress,
        subject: message.subject,
        message: message.message,
        questionId: sessionStorage.getItem('currQId'),
        topicId: message.topicId,
        lessonId: message.lessonId,
        url: message.url
    };

    return request(`/contactus/feedback`, args,
            { method: 'POST' })
        .catch(ignoreJsonError);
}

export function getMaterialTypeString(type: MaterialType) {
    switch(type) {
        case MaterialType.TOPIC: return 'TOPIC';
        case MaterialType.POST_LESSON_QUESTION: return 'POST_LESSON_QUESTION';
        case MaterialType.REPORT: return 'REPORT';
        default: return 'BASELINE_QUESTION';
    }
}

export function getMaterialTypeEnum(type: string) {
    if(type === 'TOPIC') { return MaterialType.TOPIC; }
    if(type === 'POST_LESSON_QUESTION') { return MaterialType.POST_LESSON_QUESTION; }
    if(type === 'REPORT') { return MaterialType.REPORT; }
    return MaterialType.BASELINE_QUESTION;
}

export function getMaterialType(type: MaterialType) {
    if (type === MaterialType.TOPIC) { return MaterialType.TOPIC; }
    if (type === MaterialType.POST_LESSON_QUESTION) { return MaterialType.POST_LESSON_QUESTION; }
    if (type === MaterialType.REPORT) { return MaterialType.REPORT; }
    return MaterialType.BASELINE_QUESTION;
}

