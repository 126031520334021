import './MistakeBankTopic.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';
import * as _ from 'lodash';

import { HintList, QuestionGroup, Question } from '../../../../services/elearn/lesson-types';

import MistakeBankModal from '../MistakeBankModal/MistakeBankModal';
import MistakeBankQuestionPage from './components/MistakeBankQuestionPage/MistakeBankQuestionPageContainer';
import { RouteComponentProps } from 'react-router';
import { getTopicQuestions } from '../../../../services/elearn/lesson-rest-interface';
import Loading from '../../../../components/Loading/Loading';
import { UserInfo } from '../../../../services/account/account-rest-interface';

interface ComposedProps {
    // get this prop from rest call
    topicQuestionGroups: QuestionGroup;
    ssTopicQuestionGroups: QuestionGroup;
    currentQuestionId: string;
    hint: HintList;
    ssHint: HintList;
    category: string;
}

interface DispatchProps {
    setTopicReviewStatus: (lessonId: string, topicId: string, status: string) => Promise<any>;
    setLessonInProgress: (lessonInProgress: boolean) => void;
    userInfo: UserInfo;
}

interface Routeprops {
    lessonId: string;
    topicId: string;
}

interface State extends ComposedProps {
    loaded: boolean;
    showModal: boolean;
}

type AllProps = Routeprops & DispatchProps & RouteComponentProps<any>;


export default class MistakeBankTopic extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            topicQuestionGroups: {} as QuestionGroup,
            ssTopicQuestionGroups: {} as QuestionGroup,
            currentQuestionId: '',
            hint: {} as HintList,
            ssHint: {} as HintList,
            loaded: false,
            showModal: false,
            category: ''
        };
    }

    public UNSAFE_componentWillMount() {
        const { lessonId, topicId, userInfo } = this.props;
        this.props.setLessonInProgress(true);
        getTopicQuestions(lessonId, topicId, userInfo).then(res => {
            let lessonMB = {} as QuestionGroup;
            let lessonHint = {} as HintList;
            let ssMB = {} as QuestionGroup;
            let ssHint = {} as HintList;
            let totalHints = {} as HintList;
            if (res.lessonTopicMistakes) {
                lessonMB = res.lessonTopicMistakes.questionGroup;
                lessonHint = res.lessonTopicMistakes.hints;
                totalHints = { ...lessonHint };
            }
            if (res.staySharpTopicMistakes) {
                ssMB = res.staySharpTopicMistakes.questionGroup;
                ssHint = res.staySharpTopicMistakes.hints;
                totalHints = { ...lessonHint, ...ssHint };
            }
            this.setState({
                topicQuestionGroups: lessonMB,
                ssTopicQuestionGroups: ssMB,
                currentQuestionId: res.currentQuestionId,
                hint: totalHints,
                ssHint: ssHint,
                category: res.category,
                loaded: true
            });
        });
    }

    public componentWillUnmount() {
        window.onbeforeunload = () => {/**/};
        this.props.setLessonInProgress(false);
    }

    public render() {
        const { loaded, topicQuestionGroups, ssTopicQuestionGroups, category } = this.state;
        const { topicId, lessonId } = this.props;

        if(!loaded) { return <Loading />; }

        let currentQuestionId = '';
        let currentTopic = {} as any;

        if (!_.isEmpty(topicQuestionGroups) && !_.isEmpty(ssTopicQuestionGroups)) {
            currentQuestionId = this.state.currentQuestionId ?
                this.state.currentQuestionId : topicQuestionGroups.questions[0].questionId;
            currentTopic = topicQuestionGroups.questions.find(l => l.questionId === currentQuestionId);
            if (!currentTopic) {
                currentQuestionId = this.state.currentQuestionId ?
                    this.state.currentQuestionId : ssTopicQuestionGroups.questions[0].questionId;
                currentTopic = ssTopicQuestionGroups.questions.find(l => l.questionId === currentQuestionId);
            }
        } else if (!_.isEmpty(topicQuestionGroups)) {
            currentQuestionId = this.state.currentQuestionId ?
            this.state.currentQuestionId : topicQuestionGroups.questions[0].questionId;
            currentTopic = topicQuestionGroups.questions.find(l => l.questionId === currentQuestionId);
        } else {
            currentQuestionId = this.state.currentQuestionId ?
            this.state.currentQuestionId : ssTopicQuestionGroups.questions[0].questionId;
            currentTopic = ssTopicQuestionGroups.questions.find(l => l.questionId === currentQuestionId);
        }

        return (
                <MistakeBankQuestionPage
                    topicId={topicId}
                    lessonId={lessonId}
                    onComplete={this.handleMCCompletion}
                    questionGroups={topicQuestionGroups || []}
                    ssQuestionGroups={ssTopicQuestionGroups || []}
                    startingQuestionId={currentQuestionId}
                    hint={this.state.hint}
                    category={category}
                >

                <MistakeBankModal
                    show={this.state.showModal}
                    onClose={this.closeModal}
                    setReview={this.setReviewAgainStatus}
                    setMastered={this.setTopicMasteredStatus}
                    topicName={currentTopic.topicName}
                />

                </MistakeBankQuestionPage>
                );

    }

    @boundMethod
    private handleMCCompletion() {
        this.setState({ showModal: true });
    }

    @boundMethod
    private closeModal() {
        this.setState({ showModal: false });
    }

    @boundMethod
    private setReviewAgainStatus() {
        this.props.setTopicReviewStatus(this.props.lessonId, this.props.topicId, 'REVIEW AGAIN')
            .then(v => {
                this.props.history.push(`${process.env.REACT_APP_BASE_URL}app/mistake-bank`);
            });
    }

    @boundMethod
    private setTopicMasteredStatus() {
        this.props.setTopicReviewStatus(this.props.lessonId, this.props.topicId, 'TOPIC MASTERED')
            .then(v => {
                this.props.history.push(`${process.env.REACT_APP_BASE_URL}app/mistake-bank`);
            });
    }
}
