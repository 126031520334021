import * as React from 'react';
import { first, get } from 'lodash';

import './TopicRow.css';
import TopicMetric from './TopicMetric';
import GrowthIndicator from './GrowthIndicator';

export interface Props {
    compareLastTwo: boolean;
    topicId: string;
    topicDisplayName: string;
    topicOverTimeList: Array<any>;
}

const TopicRow: React.SFC<Props> = (props: Props) => {
    const { topicOverTimeList, topicId, topicDisplayName } = props;
    let before = first(topicOverTimeList);
    if (props.compareLastTwo) {
        before = topicOverTimeList[topicOverTimeList.length - 2];
    }
    const after = get(topicOverTimeList, topicOverTimeList.length - 1);

    return (
        <tr className="topic-row">
            <td className="topic-name">
                {topicDisplayName}
            </td>
            {props.topicOverTimeList.map(topicOverTime => {
                const { correctCount, total, name } = topicOverTime;
                if (!name) {
                    return (<td key={`td-undef-${topicId}`}>N/A</td>);

                }
                return (
                    <TopicMetric 
                        key={`tm-key-${topicId}-${name}`}
                        correctCount={correctCount}
                        total={total}
                    />
                );
            })
            }
            <GrowthIndicator 
                before={before}
                after={after}
            />
        </tr>
    );
};

export default TopicRow;
