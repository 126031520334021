import * as React from 'react'
import Loading from '../../../components/Loading/Loading'
import './index.css'

interface Props {
  msg?: string
}

const defaultMsg = "We are calculating your data summaries in real time. Please be patient as this process can take up to 30 seconds."

const ReportLoadingMessage = (props: Props) => {
  let {msg=defaultMsg} = props

  return (
    <div className="loading-wrapper">
      <Loading />
      <p>
        {msg}
      </p>
    </div>
  )
}

export default ReportLoadingMessage
