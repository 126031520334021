import * as React from 'react';

import { withRouter } from 'react-router-dom';
import { DiagnosticMaterials } from './DiagnosticMaterials';

const ConnectedDiagnosicMaterials =  withRouter(DiagnosticMaterials);

export default (props) => {
    return (
        <ConnectedDiagnosicMaterials {...props} />
    );
};
