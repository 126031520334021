import { createGenericAuthRequest } from '../request/request-utils';

const baseUrl = process.env.REACT_APP_ELEARN_V2_URL || '/';
const request = createGenericAuthRequest(baseUrl);

const baseURL: string = '/bundles';

export const getBundles = () => {
  return request(baseURL);
};
