import './SignUp.css';

import * as React from 'react';
import * as Script from 'react-load-script';
import * as _ from 'lodash';
import { boundMethod } from 'autobind-decorator';
import { parse } from 'query-string';

import { getUserInfo } from '../../stores/account.store';
import {
    getDashboardOverview,
    getMistakeBankOverview,
    getUserLessonsOverview,
    setCurrentLesson
} from '../../stores/lesson.store';

import { 
    NewAccount, 
    registerNewStudent,
    isUserVerified
} from '../../services/account/account-rest-interface';

import { Gender, getGenderEnum, getGenderOptions, getGenderText } from './constants/sign-up-types';

import BlueGeometricBackground from '../../components/BlueGeometricBackground/BlueGeometricBackground';
import Button from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import SignUpError from './SignUpError';
import SignUpHeader from './SignUpHeader';
import RegistrationContainer from './RegistrationContainer';
import PaymentProcessingModal from '../../components/PaymentProcessingModal';
import { RouteComponentProps } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import { SignUpFields } from './SignUpForm';
import ButtonLoading from '../../components/ButtonLoading/ButtonLoading';
import VerifyFailed from '../Verify/components/VerifyFailed/VerifyFailed';

interface Props {

}

interface RouteProps extends RouteComponentProps<any> {}

interface State {
    isLoading: boolean;
    showProgressModal: boolean;
    serverMessage: string;
    shouldValidate: boolean;
    validationMessage: string;
    newAccountInfo?: SignUpFields;
    formIsValid: boolean;
    firstName: string;
    lastName: string;
    isLoaded: boolean;
    errorFound: boolean;
    message: string;
}


export default class SchoolUserVerification extends React.PureComponent<Props & RouteProps, State> {
    constructor(props: Props & RouteProps) {
        super(props);

        const qsArgs = parse(props.location.search);
        const fName = qsArgs.firstName as string;
        const lName = qsArgs.lastName as string;

        this.state = {
            validationMessage: '',
            isLoading: false,
            showProgressModal: false,
            serverMessage: '',
            shouldValidate: false,
            formIsValid: false,
            firstName: fName,
            lastName: lName,
            isLoaded: false,
            errorFound: false,
            message: ''
        };
    }

    public componentDidMount() {
        isUserVerified(this.props.match.params.token).then((res) => {
            res.text().then((output) => {
                let errorMessage = 'Your account has already been verified.';
                if (output !== 'true') {
                    this.setState({
                        isLoaded: true,
                    });    
                } else {
                    this.setState({
                        isLoaded: true,
                        errorFound: true,
                        message: errorMessage
                    });
                }
            });
        });
    }

    public render() {
        const { isLoading, isLoaded, errorFound, message } = this.state;

        if (!isLoaded) {
            return (
                <BlueGeometricBackground className="sign-up-container">
                    <Loading />
                </BlueGeometricBackground>
            );
        }

        if (errorFound) {
            return (
                <BlueGeometricBackground className="sign-up-container">
                    <VerifyFailed error={message} />
                </BlueGeometricBackground>
            );
        }

        return (
            <BlueGeometricBackground className="sign-up-container">
                {/* TODO: 
                    This should be renamed. It's more of an account setup modal 
                */}
                <PaymentProcessingModal 
                    showModal={this.state.showProgressModal}
                />

                <RegistrationContainer>
                    <SignUpHeader 
                        text={`Welcome ${this.state.firstName} ${this.state.lastName}`} 
                    />
                    <SignUpForm
                        schoolUser={true}
                        name={_.pick(this.state, ['firstName', 'lastName'])}
                        onChange={(newAccountInfo, formIsValid) => { 
                            if (formIsValid) {
                                this.setState({ validationMessage: '' });
                            }

                            this.setState({
                                newAccountInfo,
                                formIsValid,
                            });
                        }}
                        shouldValidate={this.state.shouldValidate}
                    />
                    <SignUpError text={this.state.validationMessage}/>
                    <SignUpError text={this.state.serverMessage}/>

                    <div className="row">
                      <Button
                          text={this.state.isLoading ? 
                              <ButtonLoading className="sign-up__loading" /> : 
                              <span>CREATE ACCOUNT</span>
                          }
                          className="sign-up__create-account"
                          onClick={this.handleCreateAccount}
                          bgColor="green"
                          disabled={isLoading}
                      />
                    </div>
                </RegistrationContainer>
            </BlueGeometricBackground>
        );
    }

    private toggleCreateAccountButton(enableBtn: boolean) {
        this.setState({ isLoading: enableBtn, });
    }

    @boundMethod
    private handleCreateAccount() {
        this.setState({ shouldValidate: true });
        if (!this.state.formIsValid) {
            return;
        }

        this.setState({ serverMessage: '' });
        this.toggleCreateAccountButton(true);
        this.setState({ showProgressModal: true });

        const userRegistration = _.pick(this.state.newAccountInfo, 
            ['gender', 'profileColor', 'dob', 'password']);

        const verificationToken = this.props.match.params.token;
        registerNewStudent(userRegistration, verificationToken)
            .then(response => {
                this.setState({ showProgressModal: false });
                this.props.history.push('/login');
            }).catch(e => { 
                console.log('Server err while registering user', { e });
                e.text().then(output =>  {
                    this.setState({ 
                        isLoading: false,
                        serverMessage: output,
                        showProgressModal: false,
                    });
                });
            });
    }
}
