import './StaySharpModal.css';

import * as React from 'react';

import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';

interface Props {
    show: boolean;
    onClose: () => void;
    backToStaySharp: () => void;
    topicName: string;
}

const StaySharpModal: React.SFC<Props> = (props) => {
    return (
        <Modal
            className="mistake-bank-modal"
            show={props.show}
            onClose={props.onClose}
            width="800px"
        >
        <div className="modal-container">
            <div className="stay-sharp-modal--text">
                <p className="header-text">Outstanding!</p>
                <p className="subtext">
                   You've completed the Stay Sharp questions for {props.topicName}.
                </p>
                <p className="subtext">
                   Remember to continue reviewing your work in the Mistake Bank.
                </p>
            </div>
            <div className="btn-container">
                <Button
                    text="BACK TO STAY SHARP"
                    textColor="white"
                    bgColor="green"
                    onClick={props.backToStaySharp}
                />
            </div>
        </div>
        </Modal>
    );
};

StaySharpModal.defaultProps = {};
StaySharpModal.displayName = 'StaySharpModal';

export default StaySharpModal;
