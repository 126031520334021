import * as React from 'react';

import MCPage, { DispatchProps, Props, StoreProps } from './MCPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    addQuestionToMistakeBank,
    getCurrentLessonContent,
    incrementQuestionsAnswered,
    setCurrentQuestion,
    setResume,
    getLessonById,
} from '../../stores/lesson.store';

import { AppState } from '../../store';
import { connect } from 'react-redux';

const mapStateToProps = ({ account, lesson }: AppState, ownProps: any) => {
    const isTeacher = account.userInfo.isTeacher;
    const lessonId = isTeacher ? ownProps.lessonId : lesson.currentLessonId;
    return {
        lesson: getLessonById(lesson, lessonId),
        currentLessonId: lesson.currentLessonId,
        currentQuestionId: getCurrentLessonContent(lesson),
        isTeacher
    };
};

const mapDispatchToProps = { 
    setCurrentQuestion, 
    setResume, 
    incrementQuestionsAnswered, 
    addQuestionToMistakeBank 
};

type FinalProps = Pick<Props, 'lessonId' | 'isBaseline' | 'onComplete' | 'setLessonComplete' | 'questionGroups' | 'shouldShowHintPopup' | 'pdfPrintout' | 'userInfo'>;
const ConnectedMCPage = connect<any, any, any, AppState>(mapStateToProps, mapDispatchToProps)(MCPage);

const MCPageContainer = (props: RouteComponentProps<any> & Props) => {
    const handleExitLesson = () => props.history.push(`${process.env.REACT_APP_BASE_URL}app/lessons`);
    return <ConnectedMCPage handleExitLesson={handleExitLesson} {...props} />;
};

export default withRouter(MCPageContainer);
