import './NoLessons.css';

import * as React from 'react';

import Button from '../../../../components/Button/Button';

interface Props {}

const NoLessons: React.SFC<Props> = (props) => {
    return (
        <div className="lessonview-empty-container">
            <div>
                <h3>It looks like you don't have any lessons yet!</h3>
                <p>Checkout the <a href="#">Lesson Store</a> to purchase lessons now.</p>
                {/*<p>
                    You can also take our <a href="#">quick diagnostic</a> or complete
                    a <a href="#">full practice test</a> to get lesson reccomendations on
                    the areas in which you need the most practice.
                </p>*/}
                <Button text="ADD LESSONS" bgColor="green" textColor="white" />
            </div>
        </div>
    );
};

NoLessons.displayName = 'NoLessons';

export default NoLessons;
