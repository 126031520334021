import './UpdatePassword.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { updatePassword, verifyUpdatePasswordToken } from '../../../../services/account/account-rest-interface';

import BlueGeometricBackground from '../../../../components/BlueGeometricBackground/BlueGeometricBackground';
import Button from '../../../../components/Button/Button';
import Loading from '../../../../components/Loading/Loading';
import PasswordForm from '../../../../components/PasswordForm/PasswordForm';
import { Redirect } from 'react-router-dom';
import ButtonLoading from '../../../../components/ButtonLoading/ButtonLoading';

enum VerificationStatus {
    NOT_YET_VERIFIED,
    FAILED,
    VERIFIED
}

interface Props {
    token: string;
    onPasswordUpdateComplete: () => void;
}

interface State {
    isRequestingPasswordChange: boolean;
    isComplete: boolean;
    status: VerificationStatus;
    password: string;
    isPasswordValid: boolean;
    formErrorText: string;
}


export default class UpdatePassword extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            status: VerificationStatus.NOT_YET_VERIFIED,
            password: '',
            isPasswordValid: false,
            isRequestingPasswordChange: false,
            isComplete: false,
            formErrorText: ''
        };
    }

    public componentDidMount() {
        verifyUpdatePasswordToken(this.props.token)
            .then(() => this.setState({ status: VerificationStatus.VERIFIED }))
            .catch(() => this.setState({ status: VerificationStatus.FAILED }));
    }

    public render() {
        const { status, isPasswordValid, isRequestingPasswordChange, formErrorText } = this.state;

        return (
            <BlueGeometricBackground className="update-password">

                {status === VerificationStatus.NOT_YET_VERIFIED &&
                    <ButtonLoading className="update-password__loading" />}

                {status === VerificationStatus.FAILED &&
                    <Redirect to={`${process.env.REACT_APP_BASE_URL}login`} />}

                {status === VerificationStatus.VERIFIED &&
                    <div className="row">
                        <div className="col-12 update-password__form">
                            <h1>Reset Your Password</h1>
                        </div>
                        <div className="offset-md-4 col-md-4">
                            <form action="" className="form" onSubmit={this.updatePassword}>
                                <PasswordForm
                                    onValidPassword={this.onValidPassword}
                                    onInvalidPassword={this.onInvalidPassword}
                                    formGroupClass="col-lg-12"
                                    size="lg"
                                />

                                <Button
                                    text={isRequestingPasswordChange ?
                                        <ButtonLoading className="update-password__loading" /> : 'CHANGE PASSWORD'}
                                    disabled={!isPasswordValid || isRequestingPasswordChange}
                                    textColor="white"
                                    bgColor="green"
                                    className="col-lg-12"
                                    onClick={this.updatePassword}
                                />

                                {formErrorText &&
                                    <div className="form-group has-danger text-center update-password__error-text">
                                        <small className="form-control-feedback">{formErrorText}</small>
                                    </div>}

                            </form>
                        </div>
                    </div>}
            </BlueGeometricBackground>
        );
    }

    @boundMethod
    private onValidPassword(password: string) {
        this.setState({ password, isPasswordValid: true });
    }

    @boundMethod
    private onInvalidPassword() {
        this.setState({ isPasswordValid: false });
    }

    @boundMethod
    private updatePassword(e: any) {
        if(e) { e.preventDefault(); }
        if(!this.state.isPasswordValid) {
            return;
        }

        updatePassword(this.state.password, this.props.token)
            .then(this.props.onPasswordUpdateComplete)
            .catch(() => this.setState({
                isRequestingPasswordChange: false,
                formErrorText: 'Could not update password'
            }));
    }
}
