import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import { CurrentStatus, LiveViewStatusFull } from '../../services/liveview/liveview-service'
import './LiveViewTable.css'
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

interface Props { data: LiveViewStatusFull[] }
interface State { sortBy: string|undefined, sortAsc: boolean, allowTooltip: boolean }

const caretUp   = "&#9650;"
const caretDown = "&#9660;"

export default class LiveViewTable extends Component<Props, State> {
    state = {
        sortBy: "lastname",
        sortAsc: true,
        allowTooltip: false
    }

    constructor(props) {
        super(props)        
    }

    componentDidMount() {
        let allowTooltip = true
        let nofItems = this.props.data.length

        if (nofItems <= 150) {
            allowTooltip = true
        }
        this.setState({allowTooltip})
    }

    renderStatus(currentStatus: CurrentStatus) {
        let modifierClass = `status-indicator ${currentStatus}`

        if (this.state.allowTooltip) {
            let info = currentStatus.toString()
            info = info === "never" ? "No activity": info
            info = info[0].toUpperCase() + info.substr(1)
            let icon = 'liveView/ico-not-active.svg'
            let iwidth = "20"
            let iheight = "19"
            if(currentStatus == 'live') {
                icon = 'liveView/ico-active.svg'
                iwidth = "16"
                iheight = "20"
            } else if (currentStatus == 'away') {
                icon = 'ico-away.svg'
            }
            return <span className={currentStatus + `--parent statusParent`}>
                    <img
                        className={modifierClass}
                        src={`/assets/images/icons/v2/` + icon}
                        width={iwidth}
                        height={iheight}
                        alt=""
                    />
                    {info}
                </span>
        }

        return <span className={modifierClass}>&nbsp;</span>
    }

    renderSortOrder(fieldName) {
        let { sortBy, sortAsc } = this.state
        let caret = <span dangerouslySetInnerHTML={{__html: `${sortAsc? caretUp: caretDown }`}}/>
        return sortBy === fieldName ? 
            "sorted"
            // <span className={`sort-btn ${sortAsc?"asc": "dec"}`}>
            //     {/* { caret } */}
            // </span>
            : 
            ""
    }

    handleSort = (fieldName: string) => {
        let { sortBy, sortAsc } = this.state
        if (sortBy === fieldName) {
            sortAsc = !sortAsc
        }
        this.setState({ sortBy: fieldName, sortAsc })
    }

    sortData() {
        let { sortBy, sortAsc } = this.state
        let { data } = this.props
        let selectComparable = (d: LiveViewStatusFull) => {
            switch (sortBy) {
                case "firstname": return d.firstName + d.lastName
                case "lastname" : return d.lastName + d.firstName
                case "navigation": return d.navigation + d.lastName + d.firstName
                case "activity": return d.activity  + d.lastName + d.firstName
                case "status": return d.currentStatus  + d.lastName + d.firstName
                case "updated": return d.updatedAt  + d.lastName + d.firstName
                default: return d.lastName + d.firstName
            }
        }

        data.sort((d1, d2) => {
            let comp1 = selectComparable(d1).toLowerCase()
            let comp2 = selectComparable(d2).toLowerCase()
            if (comp1 < comp2) return sortAsc ? -1: 1
            if (comp1 > comp2) return sortAsc ? 1: -1
            return 0
        })
    }

    showPopup = (e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>, data: LiveViewStatusFull) => {
        // let {clientX, clientY} = e
        // console.log("x, y", clientX, clientY, data.currentStatus)
    }

    renderUpdatedAt = (d: LiveViewStatusFull) => {
        const NA = "N/A"
        if (d.updatedAt === NA) {
            const dateHTML = `<span class="not-applicable">N/A</span>`
            return dateHTML;
        } else {
            const date = new Date(d.updatedAt);
            const dateHTML = `
                <span class="date--date">${date.toLocaleDateString()}</span>
                <br/>
                <span class="date--time">${date.toLocaleTimeString()}</span>
            `;
            return dateHTML;
        }
    }

    render() {
        this.sortData()
        let { data } = this.props

        return (
            <div className="fixedHeaderTable">
                 {/* <div style={{padding: 5, textAlign: "right"}}>
                    Legend:&nbsp; 
                    {this.renderStatus(CurrentStatus.LIVE)} Live &nbsp;
                    {this.renderStatus(CurrentStatus.IDLE)} Idle &nbsp;            
                    {this.renderStatus(CurrentStatus.AWAY)} Away &nbsp;
                    {this.renderStatus(CurrentStatus.NEVER)} No activity 
                </div> */}
                <table className="live-view-table lesson-table">
                    <thead className="control-zone lesson-table-header">
                        <tr>
                            <th className={`header-cell th1 label sortable ${this.renderSortOrder('status')}`}>
                                <button className="btn-simple" onClick={e => this.handleSort("status")} tabIndex={0}>
                                <span className="d-inline-block p-rel"><span>STATUS</span> <span className="header-cell__sort"/></span>
                                </button>
                            </th>
                            <th className={`header-cell th2 label sortable ${this.renderSortOrder('firstname')}`}>
                                <button className="btn-simple" onClick={e => this.handleSort("firstname")} tabIndex={0}>
                                <span className="d-inline-block p-rel"><span>FIRST NAME</span> <span className="header-cell__sort"/></span>
                                </button>
                            </th>
                            <th className={`header-cell th3 label sortable ${this.renderSortOrder('lastname')}`}>
                                <button className="btn-simple" onClick={e => this.handleSort("lastname")} tabIndex={0}>
                                <span className="d-inline-block p-rel"><span>LAST NAME</span> <span className="header-cell__sort"/></span>
                                </button>
                            </th>
                            <th className={`header-cell th4 label sortable ${this.renderSortOrder('navigation')}`} >
                                <button className="btn-simple" onClick={e => this.handleSort("navigation")} tabIndex={0}>
                                <span className="d-inline-block p-rel"><span>NAVIGATION</span> <span className="header-cell__sort"/></span>
                                </button>
                            </th>
                            <th className={`header-cell th5 label sortable ${this.renderSortOrder('activity')}`} >
                                <button className="btn-simple" onClick={e => this.handleSort("activity")} tabIndex={0}>
                                <span className="d-inline-block p-rel"><span>ACTIVITY</span> <span className="header-cell__sort"/></span>
                                </button>
                            </th>
                            <th className={`header-cell th6 label sortable ${this.renderSortOrder('updated')}`} >
                                <button className="btn-simple" onClick={e => this.handleSort("updated")} tabIndex={0}>
                                <span className="d-inline-block p-rel"><span>UPDATED</span> <span className="header-cell__sort"/></span>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>                    
                        {data.map(d => {
                            return(
                                <tr key={d.userId} >
                                    <td className="td1" onMouseEnter={e => this.showPopup(e, d)}>{this.renderStatus(d.currentStatus)}</td>
                                    <td className="td2">{d.firstName}</td>
                                    <td className="td3">{d.lastName}</td>
                                    <td className="td4">
                                        {d.navigation === 'N/A' ?
                                                <span className="not-applicable">N/A</span>
                                                :
                                                d.navigation
                                        }
                                    </td>
                                    <td className="td5">
                                        {d.activity === 'N/A' ?
                                            <span className="not-applicable">N/A</span>
                                            :
                                            d.activity
                                        }
                                    </td>
                                    <td className="td6">
                                        <div dangerouslySetInnerHTML={{ __html: this.renderUpdatedAt(d)}}></div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
               
            </div>
        )
    }
}
