import './MistakeBankCard.css';

import * as React from 'react';

import Card from '../../../../components/Card/Card';
import { ProgressCircle } from '../ProgressCircle/ProgressCircle';

interface Props {
    mastered: number;
    reviewAgain: number;
    review: number;
    onLinkClick?: () => void;
}

const MistakeBankCard: React.SFC<Props> = (props: Props) => {

    return (
        <div className="dash-mb-container">
            <Card
                title="Mistake Bank"
                titleColor="blue"
                link="/app/mistake-bank"
                onLinkClick={props.onLinkClick}
            >
                <div className="row justify-content-center dash-lesson-row pb-3">
                    <div className="px-2">
                        <ProgressCircle
                            title="Mastered"
                            progress={props.mastered}
                            img="/assets/images/icons/v2/vis-ico-topic-mastered-filled.svg"
                        />
                    </div>
                    <div className="px-2">
                        <ProgressCircle
                            title="Review Again"
                            progress={props.reviewAgain}
                            img="/assets/images/icons/v2/vis-ico-review-again-filled.svg"
                        />
                    </div>
                    <div className="px-2">
                        <ProgressCircle
                            title="Review"
                            progress={props.review}
                            img="/assets/images/icons/v2/vis-ico-review-filled.svg"
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

MistakeBankCard.displayName = 'MistakeBankCard';

export default MistakeBankCard;
