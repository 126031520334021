import './TextBox.css';

import * as React from 'react';

interface Props {
    id: string;
    labelText?: string;
    sucess?: boolean;
    invalid?: boolean;
    placeholder?: string;
    feedbackText?: string;
    inputType?: string;
    value: string | number;
    onChange: (e: any) => void;
    onSubmit?: (e: any) => void;
    onFocus?: (e: any) => void;
    onBlur?: (e: any) => void;
    formGroupClass?: string;
    labelClass?: string;
    inputClass?: string;
    size?: 'sm' | 'lg';
    inputRef?: (el: HTMLInputElement) => void;
    resizeable?: boolean;
}

const defaultProps: Partial<Props> = {
    inputType: 'text',
    formGroupClass: '',
    labelClass: 'text-box__label',
    inputClass: '',
    resizeable: true
};

const TextBox: React.SFC<Props> = (props) => {
    const invalidClass = props.invalid ? 'has-danger' : '';
    const successClass = props.sucess ? 'has-success' : '';
    const formControlClass = props.size ? `form-control-${props.size}` : '';
    const resizeClass = props.resizeable ? '' : 'text-box--no-resize';

    const onSubmit = (e: any) => {
        if(props.onSubmit && e.charCode === 13) {
            props.onSubmit(e);
        }
    };

    return (
        <div className={`form-group ${invalidClass} ${successClass} ${props.formGroupClass}`}>

            {props.labelText && <label className={props.labelClass} htmlFor={props.id}>{props.labelText}</label>}

            <textarea
                maxLength={500}
                className={`form-control text-box ${formControlClass} ${props.inputClass} ${resizeClass}`}
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                onKeyPress={onSubmit}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                // ref={props.inputRef}
            />

            {props.feedbackText && <div className="form-control-feedback theme-feedback">* {props.feedbackText}</div>}
        </div>
    );
};

TextBox.displayName = 'TextBox';
TextBox.defaultProps = defaultProps;

export default TextBox;
