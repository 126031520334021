import * as React from 'react';

import ForgotPassword from './scenes/ForgotPassword/ForgotPassword';
import { RouteComponentProps } from 'react-router-dom';
import UpdatePassword from './scenes/UpdatePassword/UpdatePassword';
import { parse } from 'query-string';

interface Props {

}

const ResetPassword: React.SFC<Props & RouteComponentProps<any>> = props => {
    const search = parse(props.location.search);
    const token = search.token as string;
    const goToLogin = () => props.history.push(`${process.env.REACT_APP_BASE_URL}login`);

    if(token) { return <UpdatePassword token={token} onPasswordUpdateComplete={goToLogin} />; }

    return <ForgotPassword />;
};

ResetPassword.defaultProps = {};
ResetPassword.displayName = 'ResetPassword';

export default ResetPassword;
