import React from 'react'
import "./index.css"

interface Props {
    animationDisabled: boolean
    disabled?: boolean
    onChange?: (animationDisabled: boolean) => void
}

enum AnimationAction {
    ENABLE,
    DISABLE
}

interface State {
    localAnimationDisabled: boolean;
}

export default class AnimationInfo extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            localAnimationDisabled: props.animationDisabled,
        };
    }

    componentDidUpdate(prevProps) {
        // Update local state if the prop has changed
        if (prevProps.animationDisabled !== this.props.animationDisabled) {
            this.setState({ localAnimationDisabled: this.props.animationDisabled });
        }
    }

    handleClick = (e, action: AnimationAction) => {
        e.preventDefault();
        const animationDisabled = action === AnimationAction.DISABLE;
        this.setState({ localAnimationDisabled: animationDisabled }, () => {
            this.props.onChange?.(animationDisabled);
        });
    }

    render() {
        let { disabled } = this.props;
        let { localAnimationDisabled } = this.state;

        const toggleSwitch = (
            <>
                <label className="toggleSwitch large">
                    <input type="checkbox" checked={!localAnimationDisabled} disabled={disabled} />
                    <span><span></span><span></span></span>
                    <a></a>
                </label>
                {localAnimationDisabled ? ' Disabled' : ' Enabled'}
            </>
        );

        const toggleButton = (
            <button
                className="btn-simple toggleSwitchWrapper"
                onClick={e => this.handleClick(e, localAnimationDisabled ? AnimationAction.ENABLE : AnimationAction.DISABLE)}
            >
                {toggleSwitch}
            </button>
        );

        const animationInfo = (
            <>
                {toggleButton}
                <div className="infoMsg">Enable or disable sitewide animations</div>
            </>
        );

        const infoMsg = localAnimationDisabled ?
            'Edit your account settings to enable animations' :
            'Edit your account settings to disable animations';

        const infoViewOnly = (
            <>
                <div className="toggleSwitchWrapper">{toggleSwitch}</div>
                <div className="infoMsg">{infoMsg}</div>
            </>
        );

        const outputInfo = disabled ? infoViewOnly : animationInfo;
        const animationInfoClass = `animation-info ${disabled ? "disabled" : ""}`;

        return (
            <div className="col-lg-12 mobile-section">
                <div className="user-info__field">
                    <span className="user-info__field-title">ANIMATIONS {disabled}</span>
                    <div className="animation-info-container">
                        <span className={animationInfoClass}>{outputInfo}</span>
                    </div>                    
                </div>
            </div>
        )
    }
}
