import './VerifySuccess.css';
import { verifyUser } from '../../../../services/account/account-rest-interface';

import * as React from 'react';

import VerifyFailed from '../VerifyFailed/VerifyFailed';
import Button from '../../../../components/Button/Button';
import { NavLink } from 'react-router-dom';
interface Props {
    verificationToken?: string;
}

interface State {
    verified: boolean;
}

export class VerifySuccess extends React.Component<Props,State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            verified: false
        };
    }

    componentDidMount() {
        // console.log('Verifying: ', this.props);
        const { verificationToken } = this.props;
        if (verificationToken !== undefined) {
            verifyUser(verificationToken)
                .then(response => {
                    // console.log('Cool Story...it verified: ', response);
                    this.setState({ verified: true });
                })
                .catch(response => {
                    response.text().then(output =>  {
                        // console.log('Cool Story...it verified: ', output);
                    });
                });
        }
    }

    render() {
        if (this.state.verified) {
            return (
                <div className="verify-success">
                    <h3>Your account has successfully been verified!</h3>

                    <NavLink to="login">
                        <Button bgColor="green" textColor="white" text="LOG IN" />
                    </NavLink>
                </div>
            );

        } else {
          return (<div></div>);
        }
        // <VerifyFailed error={err} />
    }
};

export default VerifySuccess;
