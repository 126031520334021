import React, { Component } from 'react'
import ReactGA from 'react-ga'

import { RouteComponentProps, withRouter } from 'react-router';
import { UserInfo } from './services/account/account-rest-interface'
import { LiveViewStatus, updateLiveViewStatus } from './services/liveview/liveview-service'
import { isProductionEnv, isTestEnv } from './services/utils/env-helper'

interface Props extends RouteComponentProps<any> {
    userInfo: UserInfo
}

export enum Navigation {
    Dashboard = "Dashboard",
    Lessons = "Lessons",
    MistakeBank = "Mistake Bank",
    StaySharp = "Stay Sharp",
    PracticeTests = "Practice Tests",
    Settings = "Settings",

    //--- Teachers & Admins ---
    DataReports = "Data Reports",
    Admin = "Admin",
    Resources = "Resources"
}

const navigationMap = {
    "dashboard": Navigation.Dashboard,
    "lessons": Navigation.Lessons,
    "mistake-bank": Navigation.MistakeBank,
    "stay-sharp": Navigation.StaySharp,
    "practice-tests": Navigation.PracticeTests,
    "teacher-portal": Navigation.DataReports, // OT: teacher-portal -> data reports
    "admin": Navigation.Admin,
    "settings": Navigation.Settings,
    "resources": Navigation.Resources,
    "student-resources": Navigation.Resources
}

const activityMap = {
    "ck": "College Knowledge",
    "enrichment": "Enrichment",
    "default": "Main Page"
}

let lastKnownNavigation: string
let currentUserInfo: UserInfo
let appEnvironment = isProductionEnv() ? "production": isTestEnv() ? "qa": "dev"

export function updateActivityInfo(navigation: string, activity: string) {
    if (!navigation || !currentUserInfo) return
    
    let status: LiveViewStatus = {
        navigation,
        activity
    }
    lastKnownNavigation = navigation
    updateLiveViewStatus(status)

    // Update Google Analytics which also allows additional dimentions and metrics.
    // The ones that I used so far:
    // * dimension1: userType (admin|teacher|student)
    // * dimension2: bundleName (user's current bundle)
    // * dimension3: schoolName
    // * dimension4: appEnvironment (production|qa|dev)
    let isTeacher = currentUserInfo.isTeacher
    let isAdmin = currentUserInfo.emailAddress.indexOf("@winwardacademy.com") != -1 ? true: false
    let userType = isAdmin ? "admin": isTeacher ? "teacher": "student"
    ReactGA.event({
        category: navigation,
        action: activity,
        label: "elearn-activities",
        dimension1: userType,
        dimension2: currentUserInfo.bundleName,
        dimension3: currentUserInfo.schoolName,
        dimension4: appEnvironment
    })    
}

export function updateActivityInfoWithLastKnownNavigation(activity: string) {
    updateActivityInfo(lastKnownNavigation, activity)
}

export function sendPageviewInfo(path) {
    ReactGA.pageview(path)
}

class WinwardTracker extends Component<Props, {}> {
    constructor(props: Props) {
        super(props);         
    }

    public componentDidMount() {
        let { location } = this.props
        let currentPath = `${location.pathname}${location.search}`
        sendPageviewInfo(currentPath)
    }

    public componentDidUpdate(prevProps: Props) {
        let { location, userInfo } = this.props
        let currentPath = `${location.pathname}${location.search}`
        let previousPath = `${prevProps.location.pathname}${prevProps.location.search}`
        
        currentUserInfo = userInfo

        if (currentPath !== previousPath) {
            this.sendActivityInfo()
            // Also send path info to GA
            sendPageviewInfo(currentPath)
        }
    }

    private sendActivityInfo() {        
        let { location } = this.props
        let path = location.pathname
        let segments = path.split("/")
        let navigation = segments[2]
        let activity = segments[segments.length-1]
        
        activity = navigation !== activity ? activity : "default"
        activity = activityMap[activity] ? activityMap[activity]: activity
        navigation = navigationMap[navigation] ? navigationMap[navigation] : navigation
        updateActivityInfo(navigation, activity)
    }

    public render() {
        return ""
    }
}

export default withRouter(WinwardTracker);
