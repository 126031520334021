import './ForgotPassword.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import BlueGeometricBackground from '../../../../components/BlueGeometricBackground/BlueGeometricBackground';
import Button from '../../../../components/Button/Button';
import TextInput from '../../../../components/TextInput/TextInput';
import { requestPasswordReset } from '../../../../services/account/account-rest-interface';
import VerificationError from '../../../Login/components/VerificationError/VerificationError';
import ButtonLoading from '../../../../components/ButtonLoading/ButtonLoading';

interface Props {
}

interface State {
    email: string;
    error: string;
    isLoading: boolean;
    isComplete: boolean;
    accountNotVerified: boolean;
}


export default class ForgotPassword extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            isLoading: false,
            isComplete: false,
            accountNotVerified: false
        };
    }

    public render() {
        const { isLoading, isComplete, email, error, accountNotVerified } = this.state;
        let errorClass
        error == '' ? errorClass = '' : errorClass = 'login__error-text'
        return (
            <BlueGeometricBackground className="forgot-password">
                <div className="row forgot-password__header">
                    <div className="col-12">
                        <h1>Reset Your Password</h1>
                    </div>
                    <div className="col-12">
                        <h3 className="forgot-password__instruction-text">
                        {!isComplete ? `Winward Academy will send reset instructions to the
                            email address associated with your account.` :
                            'An email has been sent to your email address with instructions to reset your password.'}
                        </h3>
                    </div>
                </div>

                <div className="row">
                    <div className="offset-md-4 col-md-4">
                        {!isComplete ? <form className="form" onSubmit={this.requestPasswordReset}>
                            <TextInput
                                id="reset-password-email"
                                labelText="Email address"
                                value={email}
                                onChange={this.handleEmailChange}
                                inputType="email"
                                size="lg"
                                onSubmit={this.requestPasswordReset}
                            />

                            {accountNotVerified ? <div className="text-center"><span className="login__error-text"><VerificationError /></span></div> :
                                <div className="text-center"><span className={errorClass}><p>{error}</p></span></div>
                            }

                            <Button
                                text={isLoading ?
                                    <ButtonLoading className="forgot-password__loading" /> : 'SEND INSTRUCTIONS'}
                                className="login__button"
                                onClick={this.requestPasswordReset}
                                bgColor="green"
                                disabled={isLoading}
                            />
                        </form> :
                        <Button
                            text="RETURN TO LOGIN PAGE"
                            className="login__button"
                            textColor="white"
                            bgColor="green"
                            link="/"
                        />}
                    </div>
                </div>
            </BlueGeometricBackground>
        );
    }

    @boundMethod
    private handleEmailChange(e: any) {
        this.setState({ email: e.target.value, error: '' });
    }

    @boundMethod
    private requestPasswordReset(initEvent) {
        initEvent.preventDefault();
        
        if(!this.state.email) {
            this.setState({ error: 'Email cannot be empty'});
        } else {
            // TODO reset password
            this.setState({ isLoading: true }, () => {
                requestPasswordReset(this.state.email)
                    .then(() => {
                        this.setState({ isLoading: false, isComplete: true });
                    })
                    .catch(e => {
                        if (e.body && e.status === 401) {
                            e.text().then(output =>  {
                                let accountNotVerified = false;
                                if (output === 'The account has not been verified.') {
                                    accountNotVerified = true;
                                }
                                this.setState({ 
                                    isLoading: false, 
                                    error: output,
                                    accountNotVerified: accountNotVerified
                                });
                            }).catch(err => {
                                console.log({ jsonParseErr: err });
                            });
                        } else {
                            this.setState({ error: 'Error: could not reset password' });
                        }
                    });
            });
        }
    }
}
