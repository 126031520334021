import * as _ from 'lodash';

import { 
    createAuthPostRequest, 
    createMaiaAuthRequest, 
    createGenericAuthRequest,
    createCleverPostRequest,
    ignoreJsonError 
} from '../request/request-utils';

import { Gender } from './../../scenes/SignUp/constants/sign-up-types';
import { getGenderText } from '../../scenes/SignUp/constants/sign-up-types';
import { getSessionStorageByID } from '../utils/session-storage-utility';

const baseUrl = process.env.REACT_APP_ACCOUNT_URL || '/';
const noAuthUrl = process.env.REACT_APP_ELEARN_URL || '/';
const maiaAuthRequest = createMaiaAuthRequest(baseUrl);
const authRequest = createAuthPostRequest(baseUrl);
const cleverPostRequest = createCleverPostRequest(baseUrl);
const request = createGenericAuthRequest(baseUrl);
const noAuthRequest = createGenericAuthRequest(noAuthUrl);

export enum UserStatus {
    ACTIVE,
    PENDING,
    DISABLED
}

export interface UserInfo {
    id: string;
    emailAddress: string;
    expirationDate: string;
    firstName: string;
    lastName: string;
    profileColor: string;
    status: UserStatus;
    dateOfBirth: Date;
    gender: any;
    charityCode: string;
    postWelcome: string;
    iaPermission: boolean;
    isTeacher: boolean;
    ckPermission: boolean;
    bundleId?: string;
    bundleName?: string;
    winToken?: string;
    expirationDateText?: string;
    lessonDashView?: string;
    isMaiaStudent: boolean;
    schoolName: string;
    isSuperintendent: boolean
    districtName: string
    districtIcon: string
    disctrictSchoolsList: string[]
    animationDisabled: boolean
    resilienceReminders?: string
}

export interface NewAccount {
    emailAddress: string;
    password: string;
    firstName: string;
    lastName: string;
    profileColor: string;
    birthDate: Date;
    gender: Gender;
    charityCode?: string;
    expirationDate: Date;
    bundleId: string;
    howDidYouHear?: string;
    includeCollegeKnowledge: boolean;
}

export interface StripePayment {
  token: string;
  email: string;
}

export const login = (emailAddress: string, password: string, maiaSecret?: string) => {
    if (maiaSecret) {
        return maiaAuthRequest<UserInfo>('/login?maia_token=' + maiaSecret, emailAddress, password, maiaSecret);
    }
    return authRequest<UserInfo>('/login', emailAddress, password);
};

/**
 * Clever login process
 * 
 * @param cleverCode 
 * @returns 
 */
export const cleverLogin = (cleverCode: string, host: string) => {    
    return cleverPostRequest<UserInfo>("/login/clever", cleverCode, host) 
};

/**
 * ClassLink login process
 * 
 * @param classLinkCode 
 * @returns 
 */
export const classLinkLogin = (classLinkCode: string, host: string) => {    
    return cleverPostRequest<UserInfo>("/login/classlink", classLinkCode, host) 
};

export const activateSubscription = (
    stripePayment: StripePayment,
    bundleId: string,
    includeCollegeKnowledge: boolean,
    schoolSafetyAdditional: boolean,
    includePersonalCoach:boolean,
    includeStudyPlan: boolean
) => {
  const email = encodeURIComponent(stripePayment.email);
  const activateUrl = `/users/status?stripePaymentToken=${stripePayment.token}&stripeEmailAddress=${email}&bundleId=${bundleId}&ck=${includeCollegeKnowledge}&schoolSafety=${schoolSafetyAdditional}&personalCoach=${includePersonalCoach}&studyPlan=${includeStudyPlan}`;
//   return request(
//     activateUrl,
//       { },
//       { method: 'POST' }
//   ).catch(ignoreJsonError);
  const winToken = getSessionStorageByID('x-win-token') || '';
  const userID = getSessionStorageByID('x-win-user') || '';
  return fetch(baseUrl + activateUrl, {
    credentials: 'include',
    method: 'POST',
    mode: 'cors',
    headers: { 
        'Content-Type': 'application/json',
        'x-win-token': winToken,
        'x-win-user': userID
     },
    body: JSON.stringify({})
}).then(response => {
    return response.ok ?
        Promise.resolve(response) :
        onRegistrationError(response);
});

};

// NOTE: It takes a response from a fetch
function onRegistrationError(response: any) {
    if (response.status === 409) {
        return Promise.reject(new Error(
            'There is already an account connected to that email address'
            )
        );
    } else if (response.status === 402) {
        return response.text().then(output => {
            let errMessage = output ? output : 'Payment Required';
            return Promise.reject(new Error(errMessage));
        });
    } else if (response.body) {
        return response.text().then(output => {
            let errMessage = output ? output : 'Unexpected problem';
            return Promise.reject(new Error(errMessage));
        });
    } else {
        console.log('Unexpected Problem:', response);
        return Promise.reject(new Error('Could not create account'));
    }
}

export function registerNewStudent(studentInfo: any, token: string) {
    const { password, profileColor } = studentInfo;
    return fetch(baseUrl + `/users/verify/student/${token}`, {
        credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password, profileColor })
    }).then(response => {
        // TODO: 404 for no ver token found?
        // Talk to Jordan when he gets back
        return response.ok ?
            Promise.resolve(response) :
            Promise.reject(response);
    });

}

export function registerUser(newAccount: NewAccount) {
    const { gender, birthDate, ...rest } = newAccount;
    const info = {
        ...rest,
        dateOfBirth: birthDate.toISOString(),
        gender: getGenderText(gender),
        includeCollegeKnowledge: undefined
    };

    const queryString = `?bundleId=${newAccount.bundleId}&ck=false`;
    return fetch(baseUrl + `/users${queryString}`, {
        credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    }).then(response => {
        return response.ok ?
            Promise.resolve(response) :
            onRegistrationError(response);
    });
}

export function isUserVerified(token: string) {
    return fetch(baseUrl + `/users/verified/${token}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }).then(response => {
        return response.ok ?
            Promise.resolve(response) :
            Promise.reject(response);
    });
}

export function registerUserWithAccessCode(
    newAccount: NewAccount,
    accessCode: string
) {
    const { gender, birthDate, ...rest } = newAccount;
    const info = {
        ...rest,
        dateOfBirth: birthDate.toISOString(),
        gender: getGenderText(gender),
        includeCollegeKnowledge: undefined
    };
    const trimmedCode = encodeURIComponent(accessCode.trim());
    const queryString = `?access_code=${trimmedCode}&bundleId=gameChanger&ck=false`;
    return fetch(baseUrl + `/users${queryString}`, {
        credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    }).then(response => {
        return response.ok ?
            Promise.resolve(response) :
            onRegistrationError(response);
    });
}

export function registerUserWithPaymentCk(
    newAccount: any,
    stripePayment: StripePayment,
) {
    const { gender, birthDate, ...rest } = newAccount;
    const info = {
        ...rest,
        dateOfBirth: birthDate.toISOString(),
        gender: getGenderText(gender),
        includeCollegeKnowledge: undefined,
        includePersonalCoach: undefined,
        includeStudyPlan: undefined
    };

    const bundleClause = `?bundleId=${newAccount.bundleId}`;
    const personalCoachClause = `&personalCoach=${newAccount.includePersonalCoach}`;
    const studyPlanClause = `&studyPlan=${newAccount.includeStudyPlan}`;
    const paymentClause = `&paymentToken=${stripePayment.token}`;
    const receiptEmailClause = `&stripeEmailAddress=${encodeURIComponent(stripePayment.email)}`;
    const collegeKnowledgeClause = `&ck=${newAccount.includeCollegeKnowledge}`;
    const queryString = `${bundleClause}${personalCoachClause}${paymentClause}${receiptEmailClause}${collegeKnowledgeClause}${studyPlanClause}`;

    // console.log({ queryString });
    // console.log({ info });

    return fetch(baseUrl + `/users${queryString}`, {
        credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    }).then(response => {
        return response.ok ?
            Promise.resolve(response) :
            onRegistrationError(response);
    });
}

export function registerUserWithPayment(
    newAccount: NewAccount,
    stripePayment: StripePayment,
) {
    const { gender, birthDate, ...rest } = newAccount;
    const info = {
        ...rest,
        dateOfBirth: birthDate.toISOString(),
        gender: getGenderText(gender),
    };

    const bundleClause = `?bundleId=${newAccount.bundleId}`;
    const paymentClause = `&paymentToken=${stripePayment.token}`;
    const receiptEmailClause = `&stripeEmailAddress=${encodeURIComponent(stripePayment.email)}`;
    const charityCodeClause = newAccount.charityCode === undefined ? 
        '' : `&charityCode=${newAccount.charityCode}`;
    const queryString = 
        `${bundleClause}${paymentClause}${receiptEmailClause}${charityCodeClause}`;

    return fetch(baseUrl + `/users${queryString}`, {
        credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    }).then(response => {
        return response.ok ?
            Promise.resolve(response) :
            onRegistrationError(response);
    });
}

export const createAccount = (
    newAccount: NewAccount,
    accessCode: string,
    stripePayment?: StripePayment
) => {
    // console.log('in createAcct: ', newAccount);
    // const trimmedCode = accessCode ? accessCode.trim() : '';
    const trimmedCode = accessCode.trim();
    const { gender, birthDate, ...rest } = newAccount;
    const info = {
        ...rest,
        dateOfBirth: birthDate.toISOString(),
        gender: getGenderText(gender),
    };

    let queryString = '';
    let addedAccessCode = false;
    let addedPaymentToken = false;

    if (!_.isEmpty(trimmedCode)) {
        queryString = `?access_code=${trimmedCode}`;
        addedAccessCode = true;
    } else if (stripePayment) {
        const paymentClause = `?paymentToken=${stripePayment.token}`;
        const receiptEmailClause = `&stripeEmailAddress=${encodeURIComponent(stripePayment.email)}`;
        queryString = `${paymentClause}${receiptEmailClause}`;
        addedPaymentToken = true;
    }

    if (!_.isEmpty(newAccount.bundleId) && (addedAccessCode || addedPaymentToken)) {
      queryString += `&bundleId=${newAccount.bundleId}`;
    } else if (!addedAccessCode && !addedPaymentToken) {
      queryString = `?bundleId=${newAccount.bundleId}`;
    }

    return fetch(baseUrl + `/users${queryString}`, {
        credentials: 'include',
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(info)
    }).then(response => response.ok ? Promise.resolve(response) : Promise.reject(response));
};

export const getUser = () => {
    return request<UserInfo>(`/users`);
};

export const deleteUser = (userId: string) => {
    return request(`/users`, {}, { method: 'DELETE'});
};

export const updateLessonView = (lessonViewObject: any) => {
    return request(`/users/ldv`, lessonViewObject, { method: 'PUT'});
};

export const updateUserInfo = (userId: string, userInfo: Partial<UserInfo>) => {
    return request(`/users`, userInfo, { method: 'PUT' }).catch(ignoreJsonError);
};

export const verifyUser = (verificationToken: string) => {
    return request(`/users/verify/${verificationToken}`)
        .catch(ignoreJsonError);
};

export const welcomeUser = (userId: string) => {
    return request(`/users/welcome/${userId}`)
        .catch(ignoreJsonError);
};

export const requestPasswordReset = (emailAddress: string) => {
    return request(`/users/password/updaterequest`, { emailAddress }, { method: 'POST' })
        .catch(ignoreJsonError);
};

export const verifyUpdatePasswordToken = (token: string) => {
    return request(`/users/password/updaterequest/verify/${token}`);
};

export const updatePassword = (password: string, verificationToken?: string) => {
    return request(`/users/password`, { password, verificationToken }, { method: 'POST' })
        .catch(ignoreJsonError);
};

export const setNewPassword = (password: string, verificationToken?: string) => {
    return request(`/users/settings/password`, { password, verificationToken }, { method: 'POST' })
        .catch(ignoreJsonError);
};

export const resendVerificationEmail = (emailAddress: string) => {
    return request('/users/verify/emailresend', { emailAddress }, { method: 'POST' });
};

export const getLoginStatus = () => {
    return request('/users/loginstatus');
};

// Begin Practice test stats (page 1 of "practice tests")

export const getPracticeResults = () => {
    return noAuthRequest('/practice-tests/current/scores/');
};

export const updateAnimationPreference = (animationDisabled: boolean) => {
    return request(`/users/preferences/animation`, { animationDisabled }, { method: 'POST' })
        .catch(ignoreJsonError);
};
