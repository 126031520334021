import './LastVideoModal.css';

import * as React from 'react';

import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';

interface Props {
    show: boolean;
    onClose: () => void;
    onGoToLessons: () => void;
    onGoToMultipleChoice: () => void;
    hasQuestions: boolean;
    lessonName: string;
}

const LastVideoModal: React.SFC<Props> = (props) => {
    return (
        <Modal
            className="last-video-modal"
            show={props.show}
            onClose={props.onClose}
            width="1200px"
        >
        <div className="modal-container">
            {props.hasQuestions && <div className="last-video-modal--text">
                <p className="header-text"> You finished the video lesson!</p>
                <p className="subtext">
                    Are you feeling ready to go straight to the multiple choice questions?
                    Or, would you prefer to take a break and finish the lesson later?
                </p>
            </div>}
            {!props.hasQuestions && <div className="last-video-modal--text">
                <p className="header-text"></p>
                <p className="subtext">
                    You finished the {props.lessonName} video lesson, which does not have multiple choice questions
                </p>
            </div>
            }
            <div className="btn-container">
                <Button
                    text="RETURN TO MY LESSONS"
                    textColor="white"
                    bgColor="blue"
                    onClick={props.onGoToLessons}
                />
                {props.hasQuestions && <Button
                    text="START MULTIPLE CHOICE"
                    textColor="white"
                    bgColor="green"
                    onClick={props.onGoToMultipleChoice}
                />
                }
            </div>
        </div>
        </Modal>
    );
};

LastVideoModal.defaultProps = {};
LastVideoModal.displayName = 'LastVideoModal';

export default LastVideoModal;
