import * as React from 'react';

interface Props {
    children: any;
}

const RegistrationContainer: React.SFC<Props> = ({ children }) => {
    return (
        <div className="registration-container">
            <div className="col-12 text-center">
                <div className="form">
                    {children}
                </div>
            </div>
        </div>
    );
};

RegistrationContainer.displayName = 'RegistrationContainer';

export default RegistrationContainer;
