import './BlueGeometricBackground.css';

import * as React from 'react';

import { PageContent } from '../PageContent/PageContent';

interface Props {
    className?: string;
}

const BlueGeometricBackground: React.SFC<Props> = (props) => {
    const backgroundImage = `url("/assets/images/login/bg-geometric-blue-fade.png")`;
    return (
        <PageContent className={`blue-geometric-bg ${props.className}`} style={{ backgroundImage }}>
            <div className="blue-geometric-bg__wrapper">
                {props.children}
            </div>
        </PageContent>
    );
};

BlueGeometricBackground.displayName = 'BlueGeometricBackground';

export default BlueGeometricBackground;
