import React, { Component } from 'react'
import { PageContent } from '../../components/PageContent/PageContent'
import { getMCQuestions } from '../../services/elearn/lesson-rest-interface'
import { IReteachQuestion } from '../TeacherPortal/types'
import { QuestionGroup } from '../../services/elearn/lesson-types'
import { getReteachQuestions } from '../../services/elearn/teacher/reteach'
import { UserInfo } from '../../services/account/account-rest-interface'
import { ERR_RETEACH_NO_QUESTIONS_SELECTED, ERR_RETEACH_TITLE, NA } from '../../constants'
import { getPersistedUserInfo } from '../../persistance/local'
import ReteachMCPage from '../../components/MCPage/ReteachMCPage'
import './index.css';

interface Props {
}

interface State {
    userInfo?: UserInfo
    lessonId: string
    lessonName: string
    questionGroups: QuestionGroup[]
    reteachQuestions: IReteachQuestion[]
}

/**
 * Reteach component for teachers
 */
export class Reteach extends Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            lessonId: "",
            questionGroups: [],
            reteachQuestions: [],
            lessonName: "???",
        }

        let userInfo = getPersistedUserInfo()

        if (!userInfo) return

        setTimeout(async () => {
            let lessonId = this.getLessonId()
            let multipleChoiceQuestions = await getMCQuestions(lessonId, userInfo)
            let reteachQuestions: IReteachQuestion[] = await getReteachQuestions(lessonId)
            this.setState({ userInfo, lessonId, questionGroups: multipleChoiceQuestions.questionGroups, reteachQuestions })
        }, 100)
    }

    /**
     * Gets the lessonId from the pathname of the current location
     * @returns 
     */
    getLessonId() {
        let pathname = window.location.pathname
        let segments, lessonId
        pathname = pathname.replace(/^\/|\/$/g, "") // Remove leading and trailing slashes first!
        segments = pathname.split("/")
        lessonId = segments && segments[1] || ""
        return lessonId
    }

    getReteachQuestionNumbers(questionGroups, reteachQuestions) {
        let selectedReteachQuestions = reteachQuestions.filter(question => question.selected)
        let reteachQuestionNumbers

        questionGroups.forEach(group => {
            let selectedGroupQuestions = group.questions.filter(q => selectedReteachQuestions.some(sq => sq.questionId === q.questionId))
            group.questions = selectedGroupQuestions
        })

        reteachQuestionNumbers = questionGroups.reduce((acc, cur) => {
            cur.questions.forEach(q => acc.push(q.questionNumber))
            return acc
        }, [] as number[])

        return reteachQuestionNumbers
    }

    render() {
        let { userInfo, lessonId, reteachQuestions, questionGroups } = this.state

        if (!userInfo || questionGroups.length === 0) return null

        let reteachQuestionNumbers = this.getReteachQuestionNumbers(questionGroups, reteachQuestions)

        // Check if the user (teacher) has already selected questions to display
        // If no reteach-questions found then show the error message and return!
        if (reteachQuestionNumbers.length === 0) {
            return (
                <PageContent>
                    <div className="errorPage">
                        <div className="title">{ERR_RETEACH_TITLE}</div>
                        <div className="subtitle">{ERR_RETEACH_NO_QUESTIONS_SELECTED}</div>
                    </div>
                </PageContent>
            )
        }

        return (
            <ReteachMCPage
                isTeacher={true}
                userInfo={userInfo}
                lessonId={lessonId}
                reteachQuestionNumbers={reteachQuestionNumbers}
                questionGroups={questionGroups}
                onComplete={() => { }}
                setLessonComplete={() => { }}
            />
        )
    }
}
