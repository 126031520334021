import * as React from 'react';
import { Link } from 'react-router-dom';

export interface Props {
    totalAccurate: number;
    questionCount: number;
}

const ProgressBarLabel: React.SFC<Props> = ({ totalAccurate, questionCount }) => {
    return (
        <span>
            {totalAccurate}/{questionCount} Correct
        </span>
    );
};

ProgressBarLabel.defaultProps = {
    totalAccurate: 0,
    questionCount: 0
};
ProgressBarLabel.displayName = 'ProgressBarLabel';

export default ProgressBarLabel;
