import './TabButton.css';

import * as React from 'react';

interface Props {
    text: string;
    isActive: boolean;
    onClick: (e: any) => void;
}

const TabButton: React.SFC<Props> = (props) => {
    const activeClass = props.isActive ? 'tab-button--active' : '';
    return (
        <div className={`tab-button ${activeClass}`} onClick={props.onClick}>
            <span className="tab-button__text">
                {props.text}
            </span>
        </div>
    );
};

TabButton.displayName = 'TabButton';

export default TabButton;
