import './NoLessons.css';

import * as React from 'react';

interface Props {}

const NoLessons: React.SFC<Props> = (props) => {
    return (
        <div className="lessonview-empty-container">
            <div>
                <h3>It looks like you don't have any mistakes yet!</h3>
                <p>Your mistakes will only show once you have completed a lesson.</p>
            </div>
        </div>
    );
};

NoLessons.displayName = 'NoLessons';

export default NoLessons;
