import './StaySharpLessonTable.css';

import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { LessonSummary } from '../../../../../../services/elearn/lesson-types';
import StaySharpTopicTables from './StaySharpTopicTables/StaySharpTopicTables';

interface Props {
    lessonData: LessonSummary[];
    showCompletionModal: (topicName: string) => void;   
}

type AllProps = Props & RouteComponentProps<any>;

const StaySharpLessonTable: React.SFC<AllProps> = (props) => {
    return (
        <div>
            {props.lessonData.map((currVal, idx) => {
                return (
                    <div key={idx}>
                        <h6 className="stay-sharp-lesson-name subcategory-title">{currVal.lessonName}</h6>
                        <StaySharpTopicTables 
                            topicData={currVal.topicSummaries} 
                            showCompletionModal={props.showCompletionModal} 
                            {...props}
                        />
                    </div>
                );
            })
            }
        </div>
    );
};

StaySharpLessonTable.displayName = 'StaySharpLessonTable';

export default StaySharpLessonTable;
