import './RewatchLessonVideo.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { Lesson, MaterialType, PostLessonQuestionsData, Topic } from '../../services/elearn/lesson-types';
import { getLesson } from '../../services/elearn/lesson-rest-interface';

import Loading from '../../components/Loading/Loading';
import BrightcoveVideo from '../../components/BrightcoveVideo/BrightcoveVideo';
import Button from '../../components/Button/Button';
import ContextualTitle from '../../components/ContextualTitle/ContextualTitle';
import LastVideoModal from './components/RewatchLessonVideoModal/RewatchLastVideoModal';
import { PageContent } from '../../components/PageContent/PageContent';
import { RouteComponentProps } from 'react-router-dom';
import VideoDots from '../LessonVideo/components/VideoDots/VideoDots';
import { getBaseUrl } from '../../services/routing/routing-utils';
import { UserInfo } from '../../services/account/account-rest-interface';
import { hasSchoolSafety } from '../../services/account/account-utils';

export interface Props {
    lessonId: string;
    userInfo: UserInfo;
    isTeacher: boolean;
}

interface StoreProps {
    currentTopicId: string;
    userInfo: UserInfo;
}

interface DispatchProps {
}

interface State {
    lesson?: Lesson;
    selectedTopic: Topic;
    hoveredTopic: string;
    lastVideo: boolean;
}

type AllProps = Props & RouteComponentProps<any> & StoreProps & DispatchProps;


export default class LessonVideo extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);

        this.state = {
            selectedTopic: {} as Topic,
            hoveredTopic: '',
            lastVideo: false,
            lesson: undefined
        };
    }

    public componentDidMount() {
        getLesson(this.props.lessonId, this.props.userInfo)
            .then( lesson => {
                // console.log('WE got it', lesson);
                const selectedTopic = this.findTopicFromLesson(lesson, this.props.currentTopicId);
                this.setState({ 
                    lesson,
                    selectedTopic
                });
            })
            .catch(console.log);
    }

    public render() {

        const playerStyle = {
            width: '960px',
            maxWidth: '100%',
            height: 'auto'
        };

        const { lesson } = this.state;
        if (lesson === undefined) {

            return (<Loading />);
        }

        return (
            <PageContent className="lesson-video">
                <div className="lesson-video__close-container">
                    <button title="Close video" className="lesson-video__close btn-simple" tab-index="0" onClick={this.closeLesson} />
                </div>

                <div className="lesson-video__topic-selector">

                    {lesson.topics &&
                        <ul className={'video-dots--' + lesson.category.categoryName}>
                            {lesson.topics.map(topic =>
                                <VideoDots
                                    video={topic.videoId}
                                    key={topic.topicName}
                                    active={this.state.selectedTopic.videoId === topic.videoId}
                                    onClick={this.selectVideo}
                                    onHover={this.hoverVideo}
                                    lesson={topic.topicName}
                                />
                            )}
                        </ul>}
                </div>

                <div className="lesson-video__video">

                    <div className="lesson-video__video-title">
                        <ContextualTitle
                            title={lesson.lessonName}
                            postText={this.state.selectedTopic.topicName}
                            showPostText={!hasSchoolSafety(this.props.userInfo)}
                        />
                        {!hasSchoolSafety(this.props.userInfo) &&
                            <Button bgColor="green" text="PRINT WORKSHEET" onClick={this.printWorksheet} />
                        }
                        
                    </div>

                    <div>
                        <BrightcoveVideo
                            id="bc-video-test"
                            videoId={this.state.selectedTopic.videoId}
                            onVideoStart={this.handleVideoStart}
                            onVideoEnd={this.handleVideoEnd}
                            style={playerStyle}
                        />
                    </div>

                    {this.state.lastVideo && <LastVideoModal
                        show={this.state.lastVideo}
                        onClose={this.handleModalClose}
                        onGoToLessons={this.closeLesson}
                        onGoToSummary={this.goToSummary}
                        lessonName={lesson.lessonName}
                    />}
                </div>
            </PageContent>
        );
    }

    @boundMethod
    private selectVideo(videoId: string) {
        const newTopic = this.getTopicWithVideo(videoId);
        this.setState({ selectedTopic: newTopic });
    }

    @boundMethod
    private hoverVideo(lesson: string) {
        const newTopic = this.getTopicWithLesson(lesson);
        this.setState({ hoveredTopic: newTopic.topicName });
    }

    @boundMethod
    private closeLesson() {
        this.props.history.push(`${getBaseUrl()}app/lessons`);
    }
    private goToSummary() {
        this.props.history.push(`${getBaseUrl()}app/lessons/${this.props.lessonId}`);
        window.location.reload();
    }

    @boundMethod
    private printWorksheet() {
        const handoutName = this.props.lessonId.replace(':', '_');
        window.open(`/assets/handouts/${handoutName}.pdf`, '_blank');
    }

    private handleVideoStart() {
        // this.props.updateCurrentTopic(this.props.lessonId, this.state.selectedTopic.topicId);
    }

    private handleVideoEnd() {
        // // TODO throttle this and/or add loading bar so the transition isn't so sudden
        const nextTopic = this.getNextTopic();
        if(nextTopic === this.state.selectedTopic) { // if we've finished the last video...
              if (this.props.isTeacher) {
                  this.closeLesson();
              } else {
                  this.setState({ lastVideo: true });
              }
        }
    }

    @boundMethod
    private getNextTopic() {
        const { selectedTopic, lesson } = this.state;
        if (lesson === undefined) {
            return {} as Topic;
        }
        const nextVideoIndex = lesson.topics
            .findIndex(l => l.topicName === selectedTopic.topicName) + 1;
        if(nextVideoIndex >= lesson.topics.length || nextVideoIndex < 0) {
            return selectedTopic;
        }

        return lesson.topics[nextVideoIndex];
    }

    private getTopicWithVideo(videoId: string) {
        const { lesson } = this.state;
        if (lesson === undefined) {
            return {} as Topic;
        }
        const ret = lesson.topics.find(topic => topic.videoId === videoId);
        if(!ret) { return {} as Topic; }
        return ret;
    }

    private getTopicWithLesson(topicName: string) {
        const { lesson } = this.state;
        if (lesson === undefined) {
            return {} as Topic;
        }
        const ret = lesson.topics.find(topic => topic.topicName === topicName);
        if(!ret) { return {} as Topic; }
        return ret;
    }

    private handleModalClose() {
        this.setState({ lastVideo: false });
    }

    private findTopicFromLesson(lesson: Lesson, topicId: string) {
        if (lesson !== undefined) {
            if(!topicId) { return lesson.topics[0]; }

            const ret = lesson.topics.find(topic => topic.topicId === topicId);
            if(!ret) { return lesson.topics[0]; }
            return ret;
        } else {
            return {} as Topic;
        }
    }
}
