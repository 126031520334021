import { App } from "../App"
import { UserInfo } from "../services/account/account-rest-interface"

const USER_OPTIONS = "user-options"
const USER_UI_PREFERENCES = "user-ui-preferences"
const USER_INFO = "user-info"

let optionsJsonStr = localStorage.getItem(USER_OPTIONS)

export function getPersistedUserOptions() {
    try {
        if (!optionsJsonStr) return {}
        return JSON.parse(optionsJsonStr)
    }
    catch {
        return {}
    }
}

export function persistSITestKindSelection(siTestKind) {
    let options = optionsJsonStr ? JSON.parse(optionsJsonStr) : {}
    options.siTestKind = siTestKind
    localStorage.setItem(USER_OPTIONS, JSON.stringify(options))
}

/**
 * Persist user info during the session in the session storage
 */
export function persistUserInfo() {
    let userInfo: UserInfo = App.getUserInfo()
    if (userInfo) {
        sessionStorage.setItem(USER_INFO, JSON.stringify(userInfo))
    }
}

/**
 * Get the user info (if found in the session storage) or else return value will be undefined
 * 
 * @returns user info
 */
export function getPersistedUserInfo(): UserInfo | undefined {
    let userInfoStr = sessionStorage.getItem(USER_INFO)
    let userInfo
    if (userInfoStr) {
        userInfo = userInfoStr && JSON.parse(userInfoStr)
    }
    return userInfo
}

// /**
//  * User UI preferences
//  */
// interface IUserUIPreferences {
//   animationEnabled?: boolean
// }

// export function getPersistedUIPreferences() {
//   try {
//     let uiPreferencesJsonStr = localStorage.getItem(USER_UI_PREFERENCES)
//     if (!uiPreferencesJsonStr) return {}
//     return JSON.parse(uiPreferencesJsonStr)
//   }
//   catch {
//     return {}
//   }
// }

// function persistUIPreferences(preferences: IUserUIPreferences) {
//   localStorage.setItem(USER_UI_PREFERENCES, JSON.stringify(preferences))
// }

// function updateUIPreferences(preferences: IUserUIPreferences) {
//   let currentPrefs = getPersistedUIPreferences()
//   let updatedPrefs = {...currentPrefs, ...preferences}
//   persistUIPreferences(updatedPrefs)
// }

// function enableAnimation() {
//   updateUIPreferences({animationEnabled: true})
// }

// function disableAnimation() {
//   updateUIPreferences({animationEnabled: false})
// }

// function isAnimationEnabled() {
//   let prefs = getPersistedUIPreferences()
//   let {animationEnabled=true} = prefs // default to true 
//   return animationEnabled
// }

// // Animation related user preference controls
// export const animation = {
//   isEnabled: isAnimationEnabled,
//   enable: enableAnimation,
//   disable: disableAnimation
// }
