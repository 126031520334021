import * as React from 'react'
import DataGrid from '../../../components/DataGrid'
import { Column } from '../../../components/DataGrid/types/types'
import { IEngagementAndCulture, LinkType } from './types'
import { engagementAndCultureData } from "./engagement-culture.data"
import { sortStr } from '../../../services/utils/text-util'

interface Props {}

interface State {}

const INFO_EMPTY = "No engagement and culture to display!"
  
const columns: Column[] = [
    {
        title: 'RESOURCE',
        dataProperty: 'resource',
        width: '30%',
        sortMethod: sortStr,
        customTooltip: 'Indicates the name of the resource'
    },
    {
        title: 'DESCRIPTION',
        dataProperty: 'description',
        width: '65%',
        sortMethod: sortStr,
        customTooltip: 'Indicates the description of the resource'
    },
    {
        title: 'LINK',
        dataProperty: 'link',
        sortable: false,
        customTooltip: 'Opens resource in new tab'
    },
]

export class EngagementCulture extends React.Component<Props, State> {
    state = {

    }

    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private createLink = (data: IEngagementAndCulture) => {
        let {resource, link, linkType} = data
        // OT: Jennifer wants one icon for all types
        // let iconPath = "/assets/images/icons/home/"
        // let iconSrc = `${iconPath}${
        //         linkType === LinkType.PDF ? "pdf-icon.svg":
        //         linkType === LinkType.PRESENTATION ? "presentation.svg": 
        //         linkType === LinkType.SHEET ? "sheet.svg": "document.svg"}`

        return () => (
            <div className="d-flex h-100 align-items-center justify-content-center">
                <a
                    href={link}
                    className="download-icon--link vertical-center btn-simple"
                    target="_blank"
                    tabIndex={0}
                    title={`View the resource "${resource}", opens in a new window`}>
                    <img
                        src="/assets/images/icons/v2/ico-external-link.svg"
                        width="18"
                        height="18"
                        alt=""
                    />
                </a>
            </div>
        )
    }

    private createEngagementAndCultureData = (data: IEngagementAndCulture, idx: number) => {
        const ecd = {
            resource: {
                content: data.resource,
                comparator: data.resource,
                subComparator: ""
            },
            description: {
                content: data.description,
                comparator: data.description,
                subComparator: ""
            },
            link: {
                content: this.createLink(data),
                comparator: data.linkType + data.description,
                subComparator: ""
            }
        }
        return {
            data: ecd,
            id: `engagement-culture-${idx}`
        }
    }

    render() {
        const data = engagementAndCultureData.map(this.createEngagementAndCultureData)
      
        return (
            <div>
                <div className="engagement-and-culture-container">
                    <div className="engagement-and-culture-info">
                        <DataGrid
                            columns={columns}
                            className={"engagement-and-culture-table"}
                            data={data}
                            emptyText={INFO_EMPTY}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
 