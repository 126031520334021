import React from 'react';
import { Link } from 'react-router-dom';
import './AnalysisButton.css';

interface Props {
  testName: string;
  className?: string;
  btnColor?: string;
}

const AnalysisButton: React.FC<Props> = (props) => {
  const urlPrecursor = 'practice-tests';
  const classStr = props.btnColor ? props.btnColor : 'purple';

  return (
    <Link
      to={`/app/${urlPrecursor}/reports/${props.testName}`}
      className={`${props.className || ''} analysis-button title-button btn btn--text-white btn--bg-${classStr}`}
    >
      ANALYSIS
    </Link>
  );
};

AnalysisButton.defaultProps = { };
AnalysisButton.displayName = 'AnalysisButton';

export default AnalysisButton;
