import './QuestionNumber.css';

import * as React from 'react';

interface Props {
    num: number;
    disabled?: boolean;
    isSelected?: boolean;
    onClick?: () => void;
}

const QuestionNumber: React.SFC<Props> = (props) => {
    const { disabled, isSelected, onClick, num } = props;
    const className = `
        question-number
        ${isSelected ? 'question-number--selected' : ''}
        ${disabled ? 'question-number--disabled' : ''}
    `;

    return (
        <div className={className} onClick={!disabled && !isSelected ? onClick : undefined}>
            {num}
        </div>
    );
};

QuestionNumber.defaultProps = {};
QuestionNumber.displayName = 'QuestionNumber';

export default QuestionNumber;
