import { UserInfo } from "../services/account/account-rest-interface"

/**
 * User's UI preferences. This information build up using the userInfo.
 * They will be available everywhere through the static methods.
 * 
 * Example usage: Prerefences.isAnimationDisabled() // Make sure to import Preferences first
 * Based on their value, developers can act upon them.
 * 
 */
export class Preferences {
  static userInfo: UserInfo

  /**
   * Set the user's info to extract user's preferences when needed.
   * @param userInfo 
   */
  static setUserInfo(userInfo: UserInfo) {
    this.userInfo = userInfo
  }

  /**
   * Gets the full user info.
   * @returns full user info
   */
  static getUserInfo() {
    return this.userInfo
  }

  /**
   *  Is the animation disabled (animations are enabled by default)?
   * @returns true if disabled
   */
  static isAnimationDisabled() {
    let { animationDisabled=false} = this.userInfo
    return animationDisabled
  }

  /**
   * Gets the user's profile color in hex string (format: #RRGGBB).
   * @returns 
   */
  static getProfileColor() {
    return this.userInfo.profileColor
  }

}
