import React, { Component } from 'react'
import { TeacherStore, ITeacherStoreData } from '../../../stores/teacher.store'
import { GraduationYearDescriptor, StudentData } from '../types'
import LessonByLessonSummary from './LessonByLessonSummary'
import { TimeOnPlatformLessonProcessor, TotalClassProgress } from './top-lesson-processor'
import TotalClassProgressView from './TotalClassProgressView'
import { Selections, StudentProfileWithLessons } from './types'
import { debounce } from '../../../services/utils/debounce'
import ReportLoadingMessage from '../ReportLoadingMessage'
import { getCachedValue, setCacheValue } from '../../../services/utils/cache-util'
import { NOT_SPECIFIED } from '../../../constants'

export interface Props { 
  selections: Selections
  studentsData: StudentData[]
}

interface State {
  selectedSchoolName?: string
  selectedGraduationYear?: string
  studentLessonsInfo: any[]
  selectedTimeRange?: string
  selectedPeriod?: string
  timeOnPlatformInfo: any[]
  schools: string[]
  graduationYears: GraduationYearDescriptor[]
  showEmailNotificationModal: boolean
  loaded: boolean
  selectedTestKind: string
  lessonSummariesByCategoryMap: any
  classSummariesByCategoryMap: any
  totalClassProgress: TotalClassProgress
  nofStudents: number
}

export default class TimeOnPlatformLessonsView extends Component<Props, State> {
  timeOnPlatformLessonProcessor = new TimeOnPlatformLessonProcessor()
  unsubscribe

  constructor(props: Props) {
    super(props)
    this.state = {
      selectedSchoolName: undefined,
      timeOnPlatformInfo: [],
      studentLessonsInfo: [],
      schools: [],
      graduationYears: [],
      showEmailNotificationModal: false,
      loaded: false,
      selectedTestKind: "ACT",
      lessonSummariesByCategoryMap: {},
      classSummariesByCategoryMap: {},
      totalClassProgress: {
        nofLessonsStarted: 0, nofLessonsCompleted: 0, nofLessonsContinued: 0, 
        totalQuestionsAnswered: 0, 
        totalStudyTime: {days: 0, hours: 0, minutes: 0, seconds: 0, html: "", text:""},
        dailyAnsweredQuestions: [], 
      },
      nofStudents: 0
    }    
  }

  async componentDidMount() {
    TeacherStore.loadTimeOnPlaformInfoForAllStudents()
    TeacherStore.loadLessonInfoForAllStudents()
    this.unsubscribe = TeacherStore.subscribe(d => {
      this.debouncedSetMainData(d)
    })

    let cachedData = this.getFilteredResultsFromCache()
    if (cachedData) {
      this.setMainData(TeacherStore.data)
    }
    else {
      this.debouncedSetMainData(TeacherStore.data)
    }
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  componentDidUpdate(prevProps, prevState) {
    let propsStr = JSON.stringify(this.props)
    let prevPropsStr = JSON.stringify(prevProps)
    let calculationNeeded = false

    if (propsStr != prevPropsStr) {
      calculationNeeded = true
    }
    else {
      let stateStr = JSON.stringify(this.state)
      let prevStateStr = JSON.stringify(prevState)

      if (stateStr != prevStateStr) {
        calculationNeeded = true
      }
    }

    if (calculationNeeded) {
      let {studentsData, selections} = this.props
      let {studentLessonsInfo, timeOnPlatformInfo} = this.state

      if (studentLessonsInfo.length > 0) {
        let filteredResults = this.getFilteredResultsFromCache()
        if (!filteredResults) {
          filteredResults = this.applyFilter(studentsData, studentLessonsInfo, timeOnPlatformInfo, selections)

          // If we have  proper info save it to the cache!
          if (timeOnPlatformInfo.length > 0 && studentLessonsInfo.length > 0) {
            this.saveFilteredResultsToCache(filteredResults)
          } 
        }

        let {selectedTestKind, lessonSummariesByCategoryMap, classSummariesByCategoryMap, totalClassProgress, nofStudents} = filteredResults
        this.setState({
          selectedTestKind, lessonSummariesByCategoryMap, 
          classSummariesByCategoryMap, 
          totalClassProgress, nofStudents,
          loaded: true
        })
      }
    }
  }


  setMainData = (data: ITeacherStoreData) => {
    let { studentLessonsInfo, timeOnPlatformInfo } = data
    let { timeOnPlatformLessonProcessor } = this

    this.setState({ 
      timeOnPlatformInfo,
      studentLessonsInfo,
    })    
  }

  debouncedSetMainData = debounce(d => this.setMainData(d))

  applyFilter(studentsProfiles: StudentData[], studentsLessons, studentsTimeOnPlatform, selections: Selections) {
    let {selectedSchoolName, selectedGraduationYear, selectedPeriod, selectedTimeRange, selectedTestKind="ACT"} = selections
    let dateStart = new Date()
    let dateEnd = new Date()
    let nofDaysToGoBack = 3650 // Sufficient days to go back for an early start
    
    if (selectedTimeRange) {
      switch (selectedTimeRange) {
        case "all-time":
          break
        case "last-week":
          nofDaysToGoBack = 7
          break
        case "last-month":
          nofDaysToGoBack = 30
          break
      }
    }
    dateStart.setDate(dateStart.getDate() - nofDaysToGoBack)
    let selectedStudentProfiles = studentsProfiles.filter(studentProfile => {
      let selectStudent = true
      let period = studentProfile.period || NOT_SPECIFIED

      if ((selectedGraduationYear && selectedGraduationYear !== studentProfile.graduationYear) ||
          (selectedSchoolName && selectedSchoolName !== studentProfile.schoolName) ||
          (selectedPeriod && selectedPeriod !== "All" && selectedPeriod !== period)) {
        selectStudent = false
      }
      return selectStudent
    })
    
    let filteredStudentsLessonsInfo: any[] = []
    let filteredStudentsTimeOnPlatformInfo: any[] = []
    
    selectedStudentProfiles.forEach(student => {
      let email = student.emailAddress
      let userId = student.id

      studentsLessons.forEach(sl => {
        if (sl.userId === userId) {
          filteredStudentsLessonsInfo.push(sl)
        }
      })
      studentsTimeOnPlatform.forEach(st => {
        if (st.email === email) {          
          filteredStudentsTimeOnPlatformInfo.push(st)
        }
      })
    })

    this.timeOnPlatformLessonProcessor.load(filteredStudentsLessonsInfo, filteredStudentsTimeOnPlatformInfo)
    let studentsInfo = this.timeOnPlatformLessonProcessor.getLessonsByStudents(dateStart, dateEnd)

    function combineProfilesWithLessons(email: string, studentsProfiles, studentsInfo): StudentProfileWithLessons {
      let lessons = studentsInfo[email]
      let studentProfile = studentsProfiles.find(s => s.emailAddress.toLowerCase() == email.toLowerCase())
      let studentFullInfo = {...studentProfile, lessons}
      return studentFullInfo
    }

    let studentEmails = Object.keys(studentsInfo)
    let studentsFullInfo = studentEmails.map(email => combineProfilesWithLessons(email, studentsProfiles, studentsInfo))

    let {timeOnPlatformLessonProcessor} = this
    let lessonSummaryMapForEnglish = timeOnPlatformLessonProcessor.getLessonSummaryByCategory(dateStart, dateEnd, "English")
    let lessonSummaryMapForMath = timeOnPlatformLessonProcessor.getLessonSummaryByCategory(dateStart, dateEnd, "Math")
    let lessonSummaryMapForReading = timeOnPlatformLessonProcessor.getLessonSummaryByCategory(dateStart, dateEnd, "Reading")
    let lessonSummaryMapForScience = timeOnPlatformLessonProcessor.getLessonSummaryByCategory(dateStart, dateEnd, "Science")
    let lessonSummariesByCategoryMap = {
      English: lessonSummaryMapForEnglish,
      Math:    lessonSummaryMapForMath,
      Reading: lessonSummaryMapForReading,
      Science: lessonSummaryMapForScience
    }
    let classSummaryForEnglish = timeOnPlatformLessonProcessor.getClassSummaryByCategory(dateStart, dateEnd, "English")
    let classSummaryForMath = timeOnPlatformLessonProcessor.getClassSummaryByCategory(dateStart, dateEnd, "Math")
    let classSummaryForReading = timeOnPlatformLessonProcessor.getClassSummaryByCategory(dateStart, dateEnd, "Reading")
    let classSummaryForScience = timeOnPlatformLessonProcessor.getClassSummaryByCategory(dateStart, dateEnd, "Science")
    let classSummariesByCategoryMap = {
      English: classSummaryForEnglish,
      Math:    classSummaryForMath,
      Reading: classSummaryForReading,
      Science: classSummaryForScience
    }
    let totalClassProgress = timeOnPlatformLessonProcessor.getTotalClassProgress(dateStart, dateEnd)
    let nofStudents = studentsFullInfo.length

    return {
      dateStart,
      dateEnd,
      selectedTestKind,
      studentsFullInfo, 
      nofStudents,
      filteredStudentsLessonsInfo, 
      filteredStudentsTimeOnPlatformInfo, 
      totalClassProgress,
      lessonSummariesByCategoryMap, 
      classSummariesByCategoryMap
    } 
  }

  private saveFilteredResultsToCache(filteredResults) {
    let key = this.makeCacheKey()
    setCacheValue(key, filteredResults)
  }

  private getFilteredResultsFromCache() {
    let key = this.makeCacheKey()
    return getCachedValue(key)
  }

  private makeCacheKey() {
    let uniqueEntityName = "tonp-lessons-view-students-filtered-data"
    let { selections } = this.props
    let {selectedSchoolName, selectedGraduationYear, selectedTimeRange, selectedPeriod} = selections
    let emptyKey = "-"
    let schoolKey = selectedSchoolName || emptyKey
    let gradYearKey = selectedGraduationYear || emptyKey
    let selectedTimeRangeKey = selectedTimeRange || "all-time"
    let selectedPeriodKey = selectedPeriod || emptyKey
    let cacheKey = `${uniqueEntityName}-${schoolKey}${gradYearKey}${selectedTimeRangeKey}${selectedPeriodKey}`
    return cacheKey
  }

  render() {
    let {studentsData, selections} = this.props
    let { selectedTestKind="ACT"} = selections
    let {loaded, lessonSummariesByCategoryMap, classSummariesByCategoryMap, totalClassProgress, nofStudents} = this.state

    return (
      <div>
        {!loaded && <ReportLoadingMessage />}
        {loaded && studentsData.length > 0 &&
        <>
          <TotalClassProgressView
            totalClassProgress={totalClassProgress}
            classSummariesByCategoryMap={classSummariesByCategoryMap}
            selectedTestKind = {selectedTestKind}
          />

          <LessonByLessonSummary
            lessonSummariesByCategoryMap={lessonSummariesByCategoryMap}
            selectedTestKind = {selectedTestKind}
            selections = {selections}
            nofStudents={nofStudents}
            classSummariesByCategoryMap={classSummariesByCategoryMap}
          />
        </>
      }
      </div>
    )
  }
}
