import * as React from 'react'
import RadialProgress from '../../../components/RadialProgress/RadialProgress'
import { getHumanTime } from '../../../services/utils/date-util'
import { localeNumber } from '../../../services/utils/text-util'
import { FilterSelections } from '../SuperintendentFilters'
import './index.css'

interface Props {
  // id?: string;
  // selected>: string;
  // options: Array<any>;
  onChange?: (e: any) => void
  data: any
  filterSelections: FilterSelections
}

const GlobalStats: React.FunctionComponent<Props> = ({ data, onChange }) => {
  let {summary} = data
  let {lessonsStarted, lessonsCompleted, questionsAnswered, totalStudyTime, categories} = summary  
  let catE = categories.English
  let catM = categories.Math
  let catR = categories.Reading
  let catS = categories.Science
  let valE = Math.round(catE.accuracy)
  let valM = Math.round(catM.accuracy)
  let valR = Math.round(catR.accuracy)
  let valS = Math.round(catS.accuracy)
  let perE = valE + "%"
  let perM = valM + "%"
  let perR = valR + "%"
  let perS = valS + "%"
  let equivelentScoreE = catE.equivalentScore
  let equivelentScoreM = catM.equivalentScore
  let equivelentScoreR = catR.equivalentScore
  let equivelentScoreS = catS.equivalentScore
  let collegeReadyE = `${Math.round(catE.collegeReady)}% College Ready`
  let collegeReadyM = `${Math.round(catM.collegeReady)}% College Ready`
  let collegeReadyR = `${Math.round(catR.collegeReady)}% College Ready`
  let collegeReadyS = `${Math.round(catS.collegeReady)}% College Ready`
  let totalStudyTimeHours = Math.round(totalStudyTime/3600)
  let localeLessonsStarted = localeNumber(lessonsStarted)
  let localeLessonsCompleted = localeNumber(lessonsCompleted)
  let localeQuestionsAnswered = localeNumber(questionsAnswered)
  let localeTotalStudyTimeHours = localeNumber(totalStudyTimeHours)

  return (
    <div className="row globalStats--wrapper text-center">
      <div className="col globalStats--col-first">
        <div className="row">
          <div className="col">
            <div className="col-inner">
              <p className="c-blue stat--val">{localeLessonsStarted}</p>
              <p className="stat--key">Lessons <br/>Started</p>
            </div>
          </div>
          <div className="col">
            <div className="col-inner">
              <p className="c-blue stat--val">{localeLessonsCompleted}</p>
              <p className="stat--key">Lessons <br/>Completed</p>
            </div>
          </div>
          <div className="col">
            <div className="col-inner">
              <p className="c-blue stat--val">{localeQuestionsAnswered}</p>
              <p className="stat--key">Questions <br/>Answered</p>
            </div>
          </div>
          <div className="col">
            <div className="col-inner">
              <p className="c-blue stat--val">{localeTotalStudyTimeHours}</p>
              <p className="stat--key">Total Hours <br/>Working</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col globalStats--col-last globalStats--dials">
        <div className="row">
          <div className="col">
            <div className="col--inner">
              <p className="stat--key mb-2">ENGLISH</p>
              <div className="radial-wrapper mb-1">
                <RadialProgress
                      size={110}
                      radius={47}
                      startColor="#9353bf"
                      endColor="#6e3d81"
                      pegOffset={8}
                      progress={valE}
                      text={perE}
                      textStyle={{fontSize: '24px', marginLeft: '0', color: '#2B2D2D', fontWeight: 600}}
                      progressLineWidth={14}
                      backgroundLineWidth={14}
                  />
                </div>
              <div className="stat-key-footer">
                <p><b>{equivelentScoreE}</b></p>
                <p className="small">{collegeReadyE}</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="col--inner">
              <p className="stat--key mb-2">MATH</p>
              <div className="radial-wrapper mb-1">
                <RadialProgress
                  size={110}
                  radius={47}
                  startColor="#87e5f8"
                  endColor="#4cc8e1"
                  pegOffset={8}
                  progress={valM}
                  text={perM}
                  textStyle={{fontSize: '24px', marginLeft: '0', color: '#2B2D2D', fontWeight: 600}}
                  progressLineWidth={14}
                  backgroundLineWidth={14}
                />
              </div>
              <div className="stat-key-footer">
                <p><b>{equivelentScoreM}</b></p>
                <p className="small">{collegeReadyM}</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="col--inner">
              <p className="stat--key mb-2">READING</p>
              <div className="radial-wrapper mb-1">
                <RadialProgress
                  size={110}
                  radius={47}
                  startColor="#f8cd8b"
                  endColor="#eda33f"
                  pegOffset={8}
                  progress={valR}
                  text={perR}
                  textStyle={{fontSize: '24px', marginLeft: '0', color: '#2B2D2D', fontWeight: 600}}
                  progressLineWidth={14}
                  backgroundLineWidth={14}
                />
              </div>
              <div className="stat-key-footer">
                <p><b>{equivelentScoreR}</b></p>
                <p className="small">{collegeReadyR}</p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="col--inner">
              <p className="stat--key mb-2">SCIENCE</p>
              <div className="radial-wrapper mb-1">
                <RadialProgress
                  size={110}
                  radius={47}
                  startColor="#c2f080"
                  endColor="#93cd3f"
                  pegOffset={8}
                  progress={valS}
                  text={perS}
                  textStyle={{fontSize: '24px', marginLeft: '0', color: '#2B2D2D', fontWeight: 600}}
                  progressLineWidth={14}
                  backgroundLineWidth={14}
                />
              </div>
              <div className="stat-key-footer">
                <p><b>{equivelentScoreS}</b></p>
                <p className="small">{collegeReadyS}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalStats;
