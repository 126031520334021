import './StaySharpViewSwitch.css';

import * as React from 'react';

export enum StaySharpView {
    Calendar,
    Subject,
    Completed
}

interface Props {
    selectedView: StaySharpView;
    onViewChange: (view: StaySharpView) => void;
    openEnglishModal: () => void;
    openMathModal: () => void;
}

// // List of views used to generate image buttons
// const views: any[] = [
//     // {
//     //     title: 'View lessons based on your schedule',
//     //     type: LessonView.Calendar,
//     //     icon: 'calendar.svg',
//     //     //disabled: true
//     // },
//     {
//         title: 'View lessons by subject',
//         type: StaySharpView.Subject,
//         icon: 'subject.svg'
//     },
//     {
//         title: 'View all completed lessons',
//         type: StaySharpView.Completed,
//         icon: 'completed.svg'
//     },
// ];

const StaySharpViewSwitch: React.SFC<Props> = (props) => {
    return (
        <div className="view-chang-buttons-container">
    
        </div>
    );
};

StaySharpViewSwitch.displayName = 'StaySharpViewSwitch';

export default StaySharpViewSwitch;

export function getStaySharpViewEnum(lessonViewStr: string) {
    switch(lessonViewStr.toUpperCase()) {
        case 'CALENDAR': return StaySharpView.Calendar;
        case 'COMPLETED': return StaySharpView.Completed;
        default: return StaySharpView.Subject;
    }
}
