import React, { Component } from "react"
import Button from "../Button/Button"
import Modal from "../Modal/Modal"


interface Props {
  onAnswerYes: () => void
  onAnswerNo: () => void
  show: boolean
}

interface State {}

export default class TimeoutDialog extends Component<Props, State> {
  render() {
    let {show, onAnswerYes, onAnswerNo} = this.props

    return (
      <Modal
        className="last-video-modal"
        show={show}
        width="1200px"
      >
        <div className="modal-container">
          <div className="last-video-modal--text">
            <p className="header-text">Timer Paused</p>
            <p className="subtext">
              Are you still working on this question?
            </p>
          </div>
          <div className="btn-container">
              <Button
                  text="YES, CONTINUE"
                  textColor="white"
                  bgColor="blue"
                  onClick={onAnswerYes}
              />
              <Button
                  text="NO, EXIT LESSON"
                  textColor="white"
                  bgColor="green"
                  onClick={onAnswerNo}
              />
          </div>
        </div>
      </Modal>
    )
  }
}
