import * as React from 'react';

interface Props {
  testHint: any;
}

const TestHintLineItem: React.SFC<Props> = ({ testHint }) => {
  return (
    <li className="topic-hint-section--content topic-hint-row">
      {testHint.text} ({testHint.testDisplayName} #{testHint.questionNumber})
    </li>
  );
};

export default TestHintLineItem;
