import * as React from 'react'
import jp from "jsonpath"
import ReportLoadingMessage from '../ReportLoadingMessage'
import SummaryFilters, { ISummaryFilter } from '../SummaryFilters'
import { TeacherStore, ITeacherStoreData, NotificationType, IStudentProgressDetails } from '../../../stores/teacher.store'
import { GraduationYearDescriptor, StudentData } from '../types'
import { NO_STUDENT_INFO_FOUND } from '../info'
import { TimeOnPlatformLessonProcessor } from '../TimeOnPlatform/top-lesson-processor'
import { debounce } from '../../../services/utils/debounce'
import RosterLessonSummary from './RosterLessonSummary'
import RosterLessonMastery from './RosterLessonMastery'
import RosterMistakeBank from './RosterMistakeBank'
import './index.css'
import SimpleSearchBox from '../../../components/SimpleSearchBox'
import { avg, sum } from '../../../services/utils/math-util'
import { CurrentStatus } from '../../../services/liveview/liveview-service'
import { TimeFrame } from '../../../services/utils/date-util'
import { NOT_SPECIFIED } from '../../../constants'

const imagePath = '/assets/images/icons/reports';
const imagePrint = `${imagePath}/print-solid-light.svg`
const imageSave = `${imagePath}/file-download-solid-light.svg`

export interface Props { }

export interface IAverageProgress {
  averageDaysSince: number
  averageLessonCompletion: number
  averageLessonQuestionsAnswered: number
  averageTotalStudy: number
}

export interface IAveragesLessonMastery {
  averageGrowthEnglish: number
  averageGrowthMath: number
  averageAccuracyEnglish: number
  averageAccuracyMath: number
  averageAccuracyReading: number
  averageAccuracyScience: number  
}

export interface IAveragesMistakeBank {
  averageTotalMistakeBankQuestions: number
  averageReviewedQuestions: number
  averageHintsOnMissedQuestions: number
  averageReviewTime: number
}

interface State {
  selectedSchoolName?: string
  selectedGraduationYear?: string
  selectedPeriod?: string
  teacherStoreData?: ITeacherStoreData,
  graduationYears: GraduationYearDescriptor[]
  showEmailNotificationModal: boolean
  showScoreConversion: boolean
  selectedTimeRange?: string
  selectedTestKind?: string
  allStudentsProgress?
  selectedAllStudentsProgress?
  loaded: boolean
  searchQuery: string
  averages: IAverageProgress
  averagesForLessonMastery: IAveragesLessonMastery
  averagesForMistakeBank: IAveragesMistakeBank  
}

export default class RosterView extends React.Component<Props, State> {
  unsubscribe
  timeOnPlatformLessonProcessor = new TimeOnPlatformLessonProcessor()
  
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSchoolName: undefined,
      teacherStoreData: undefined,
      graduationYears: [],
      loaded: false,
      showEmailNotificationModal: false,
      showScoreConversion: false,
      selectedTestKind: "ACT",
      searchQuery: "",
      averages: {
        averageDaysSince: -1,
        averageLessonCompletion: -1,
        averageLessonQuestionsAnswered: -1,
        averageTotalStudy: -1,
      },
      averagesForLessonMastery: {
        averageGrowthEnglish: -1,
        averageGrowthMath: -1, 
        averageAccuracyEnglish: -1,
        averageAccuracyMath: -1,
        averageAccuracyReading: -1,
        averageAccuracyScience: -1, 
      },
      averagesForMistakeBank: {
        averageTotalMistakeBankQuestions: -1,
        averageReviewedQuestions: -1,
        averageHintsOnMissedQuestions: -1,
        averageReviewTime: -1,
      }        
    }
  }

  public componentDidMount() {
    this.unsubscribe = TeacherStore.subscribe((d, t) => {
      this.debouncedSetMainData(d, t)
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  debouncedSetMainData = debounce((d, t) => this.setMainData(d, t), 500)

  setMainData(data: ITeacherStoreData, nt: NotificationType) {
    if (nt === NotificationType.GENERIC || nt === NotificationType.ALL_LOADED) {
      let today = new Date()
      let allStudentsProgress = TeacherStore.getAllStudentsProgressDetails(TimeFrame.ALL_TIME)
      let selectedAllStudentsProgress = [...allStudentsProgress]
      let averages = this.findAverageValuesForLessonSummary(selectedAllStudentsProgress)
      let averagesForLessonMastery = this.findAveragesForLessonMastery(selectedAllStudentsProgress)
      let averagesForMistakeBank = this.findAveragesForMistakeBank(selectedAllStudentsProgress)

      this.setState({ 
        teacherStoreData: data, 
        allStudentsProgress,
        averages,
        averagesForLessonMastery,
        averagesForMistakeBank,
        selectedAllStudentsProgress,
        loaded: true 
      })
      this.applyFilter()
    }
  }

  findAverageValuesForLessonSummary = (studentsProgress: IStudentProgressDetails[]) => {
    let participatedStudentsProgress = studentsProgress
      .map(studentProgress => {
        let totalStudyTime = studentProgress.totalStudyTimeForAllLessons.total
        return {
          ...studentProgress,
          totalStudyTime
        }
      })

    let averageTotalStudy = avg(participatedStudentsProgress.filter(s => s.totalStudyTime > 0).map(s => s.totalStudyTime), true)
    let averageDaysSince = avg(participatedStudentsProgress.filter(s => s.daysSinceLastLogin !== -1).map(s => s.daysSinceLastLogin), true)
    let averageLessonQuestionsAnswered = avg(participatedStudentsProgress.filter(s => s.nofTotalQuestionsAnswered > 0).map(s => s.nofTotalQuestionsAnswered), true)
    let averageLessonCompletion = avg(participatedStudentsProgress.filter(s => s.percentLessonCompletion !== -1).map(s => s.percentLessonCompletion), true)

    return {
      averageTotalStudy,
      averageDaysSince,
      averageLessonQuestionsAnswered,
      averageLessonCompletion
    }
  }

  findAveragesForLessonMastery = (studentsProgress: IStudentProgressDetails[]) => {
    let participatedStudentsProgress = studentsProgress
      .filter(studentProgress => studentProgress.nofTotalQuestionsAnswered > 0)
      .map(studentProgress => {
        let totalStudyTime = studentProgress.totalStudyTimeForAllLessons.total
        return {
          ...studentProgress,
          totalStudyTime
        }
      })

    let averageGrowthEnglish   = avg(jp.query(participatedStudentsProgress, `$..lessonsScores.english.growth`), true)
    let averageGrowthMath      = avg(jp.query(participatedStudentsProgress, `$..lessonsScores.math.growth`), true)
    let averageAccuracyEnglish = avg(jp.query(participatedStudentsProgress, `$..lessonsScores.english.accuracyPost`), true)
    let averageAccuracyMath    = avg(jp.query(participatedStudentsProgress, `$..lessonsScores.math.accuracyPost`), true)
    let averageAccuracyReading = avg(jp.query(participatedStudentsProgress, `$..lessonsScores.reading.accuracyPost`), true)
    let averageAccuracyScience = avg(jp.query(participatedStudentsProgress, `$..lessonsScores.science.accuracyPost`), true)

    return {
      averageGrowthEnglish,
      averageGrowthMath, 
      averageAccuracyEnglish,
      averageAccuracyMath,
      averageAccuracyReading,
      averageAccuracyScience,     
    }
  }

  findAveragesForMistakeBank = (studentsProgress: IStudentProgressDetails[]) => {
    let participatedStudentsProgress = studentsProgress
      .filter(studentProgress => {
        let { mistakeBankStats } = studentProgress
        let nofMissedQuestions = mistakeBankStats ? mistakeBankStats.nofMissedQuestions: 0
        let nofManualEntries = mistakeBankStats ? mistakeBankStats.nofManualEntries: 0
        let totalQuestions = nofMissedQuestions + nofManualEntries
        
        return totalQuestions > 0
      })
      .map(studentProgress => {
        let totalStudyTime = studentProgress.totalStudyTimeForAllLessons.total
        return {
          ...studentProgress,
          totalStudyTime
        }
      })

    let totalMissedQuestionsJP = jp.query(participatedStudentsProgress, `$..lessonsScores..totalMissedQuestions`)
    let totalEnteredQuestionsJP = jp.query(participatedStudentsProgress, `$..mistakeBankStats.nofManualEntries`)
    let reviewedQuestionsJP = jp.query(participatedStudentsProgress, `$..mistakeBankStats.nofReviewedQuestions`)
    let hintsAddedJP = jp.query(participatedStudentsProgress, `$..lessonsScores..totalEnteredHints`)
    let totalReviewTimeJP = jp.query(participatedStudentsProgress, `$..mistakeBankTimeSummary.timeOnReviewTotal`)

    let nofTotalMissedQuestions = sum(totalMissedQuestionsJP)
    let nofTotalEnteredQuestions = sum(totalEnteredQuestionsJP)    
    let nofTotalQuestions = nofTotalMissedQuestions + nofTotalEnteredQuestions
    let nofReviewedQuestions = sum(reviewedQuestionsJP)
    let nofHintsAdded = sum(hintsAddedJP)
    let totalReviewTime = sum(totalReviewTimeJP)

    // Averages
    let nofStudents = participatedStudentsProgress.length
    let averageTotalMistakeBankQuestions = nofStudents > 0 ? Math.round(nofTotalQuestions/nofStudents): -1
    let averageReviewedQuestions = nofStudents > 0 ? Math.round(nofReviewedQuestions/nofStudents): -1
    let averageHintsOnMissedQuestions = nofTotalMissedQuestions > 0 ? Math.round(100.0*nofHintsAdded/nofTotalMissedQuestions): -1
    let averageReviewTime = nofStudents > 0 ? Math.round(totalReviewTime/nofStudents): -1

    return {
      averageTotalMistakeBankQuestions,
      averageReviewedQuestions,
      averageHintsOnMissedQuestions,
      averageReviewTime
    }
  }

  onGraduationYearSelected(selectedYear: string) {
    this.setState(
      { selectedGraduationYear: selectedYear === 'All' ?  
          undefined : selectedYear
      }, 
      () => this.applyFilter()
    );
  }

  onNewSchoolSelected(selectedSchool: string) {
    this.setState({
        selectedSchoolName: selectedSchool === 'All' ? 
          undefined : selectedSchool 
      }, () => this.applyFilter()
    );
  }

  onNewPeriodSelected(selectedPeriod: string) {
    this.setState({
      selectedPeriod: selectedPeriod === 'All' ? 
          undefined : selectedPeriod 
      }, () => this.applyFilter()
    );
  }

  onTimeRangeSelected(selected: string) { 
    let allStudentsProgress = TeacherStore.getAllStudentsProgressDetails(selected as TimeFrame)
    let selectedAllStudentsProgress = [...allStudentsProgress]

    this.setState({
      selectedTimeRange: selected,
      allStudentsProgress,
      selectedAllStudentsProgress },
      () => this.applyFilter()
    )
  }

  onTestKindSelected(newlySelected: string) {
    this.setState({
      selectedTestKind: newlySelected }, 
      () => this.applyFilter()
    )
  }

  private applyFilter = () => {
    let selectedAllStudentsProgress = this.getFilteredStudentsProgress()
    let averages = this.findAverageValuesForLessonSummary(selectedAllStudentsProgress)
    let averagesForLessonMastery = this.findAveragesForLessonMastery(selectedAllStudentsProgress)
    let averagesForMistakeBank = this.findAveragesForMistakeBank(selectedAllStudentsProgress)
    this.setState({selectedAllStudentsProgress, searchQuery:"", averages, averagesForLessonMastery, averagesForMistakeBank})
  }

  private getFilteredStudentsProgress = () => {
    let { selectedSchoolName, selectedGraduationYear, selectedPeriod } = this.state
    let {allStudentsProgress} = this.state

    if (!allStudentsProgress) return []

    let selectedAllStudentsProgress = allStudentsProgress.filter(d => {
      let selectStudent = true
      let period = d.period || NOT_SPECIFIED
      if ((selectedGraduationYear && selectedGraduationYear !== d.graduationYear) ||
          (selectedSchoolName && selectedSchoolName !== d.schoolName) ||
          (selectedPeriod && selectedPeriod !== period)) {
        selectStudent = false
      }
      return selectStudent
    })

    return selectedAllStudentsProgress
  }

  private handleSearch = (searchQuery) => {    
    // Apply filters first
    let filteredStudentsProgress = this.getFilteredStudentsProgress() 

    // Now search on the filtered data
    let trimmedQuery = searchQuery ? searchQuery.trim().toLowerCase(): ""
    let keys = trimmedQuery.split(/[ *]/).filter(k => k)
    let searchedStudentsProgress = filteredStudentsProgress.filter(d => {
      let firstName = d.firstName ? d.firstName.toLowerCase() : ""
      let lastName = d.lastName ? d.lastName.toLowerCase() : ""
      let combined = `${firstName} ${lastName}`
      let included = true
      let selectionMap = {}

      keys.forEach(key => selectionMap[key] = combined.includes(key) ? true: false)
      Object.keys(selectionMap).forEach(k => {
        if (!selectionMap[k]) {
          included = false
        }
      })

      return included
    })

    this.setState({searchQuery, selectedAllStudentsProgress: searchedStudentsProgress})
  }

  private clearSearch = () => {
    this.handleSearch("")   
  }

  public render() {
    let { selectedAllStudentsProgress, loaded, searchQuery } = this.state
    let { selectedSchoolName, selectedGraduationYear, selectedTimeRange, selectedPeriod, selectedTestKind } = this.state
    let { averages, averagesForLessonMastery, averagesForMistakeBank } = this.state
    let selections: ISummaryFilter = {
      selectedSchoolName, 
      selectedGraduationYear, 
      selectedTimeRange, 
      selectedPeriod, 
      selectedTestKind
    }
    
    return (
        <div className="roster-view-container">
          <SummaryFilters 
            enableTimeRangeSelection={true}
            enablePeriodSelection={true}
            enableTestKindSelection={false}
            onNewGraduationYearSelected={(gradYear) => this.onGraduationYearSelected(gradYear)}
            onNewSchoolSelected={(schoolSel) => this.onNewSchoolSelected(schoolSel)}
            onNewPeriodSelected={(period) => this.onNewPeriodSelected(period)}
            onNewTimeRangeSelected={(timeRange) => this.onTimeRangeSelected(timeRange)}
            onNewTestKindSelected={(testKind) =>this.onTestKindSelected(testKind)}
          />

          {!loaded && <ReportLoadingMessage />}
          
          {loaded &&
            <div className="roster-view-main-content-container">
              <div className="search-box-container">
                <SimpleSearchBox 
                  searchQuery={searchQuery}
                  placeholder="Student Search"
                  handleSearch={this.handleSearch}
                  clearSearch={this.clearSearch}
                />
              </div>
              
              <RosterLessonSummary selections={selections} studentsProgress={selectedAllStudentsProgress} averages={averages}/>
              <RosterLessonMastery selections={selections} studentsProgress={selectedAllStudentsProgress} averages={averagesForLessonMastery}/>
              <RosterMistakeBank   selections={selections} studentsProgress={selectedAllStudentsProgress} averages={averagesForMistakeBank}/>
            </div>
          }
        </div>
    )
  }
}
