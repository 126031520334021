import './index.css';
import * as React from 'react';


export interface Props {
  questionNumber: number;
  onClicked: (questionNumber: number) => void;
}

const AddHintButton: React.SFC<Props> = ({ 
  questionNumber, onClicked
}) => {
    return (
      <button
        title="Write yourself a hint"
        className="add-hint-button"
        onClick={() => onClicked(questionNumber)}
      >
        <img 
          alt="Add hint" 
          src={`/assets/images/icons/v2/ico-hint.svg`}
          width="20"
          height="20"
        />
      </button>
    );
};

AddHintButton.defaultProps = {
};
AddHintButton.displayName = 'AddHintButton';

export default AddHintButton;
