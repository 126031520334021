import * as React from 'react';
import './index.css';

import { RouteComponentProps } from 'react-router-dom';
import Shelf from './Shelf';
import MCAnswerHint from '../MultipleChoiceQuestion/components/MCAnswerPicker/components/MCAnswerHint/MCAnswerHint';
import AddHintForm from '../AddHintForm';
import ReportTableHeader from '../ReportTableHeader';
import SelectInput from '../SelectInput/SelectInput';
import GrowthIndicator from '../../scenes/TopicsOverTime/components/GrowthIndicator';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import TestAnalysisSectionHeader from '../TestAnalysisSectionHeader';

import NotApplicableIndicator from '../NotApplicableIndicator';
import NavbarLink from '../NavbarLink/NavbarLink';
import NewSelectList from '../../scenes/TeacherPortal/NewSelectList/';

import SummaryAccuracyCell from '../../scenes/TeacherPortal/TestOverview/SummaryAccuracyCell/';
import SummaryTable from '../../scenes/TeacherPortal/TestOverview/SummaryTable/';
import { TestType } from '../../services/utils/practice-test-util';

interface Props extends RouteComponentProps<any> {
}

interface State {
  selectedShelf: string;
}

const shelves = [
  {
    title: 'Add Hint Form (NEW!)',
    component: 
    ( 
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1, padding: '1rem' }}>
          <AddHintForm 
            onSaveHint={(text) => console.log("On save: ", text)}
            onCancel={() => console.log('CANCEL Goes Here')}
          />
        </div>
        <div style={{ flex: 1, padding: '1rem' }}>
          <AddHintForm 
            onCancel={() => console.log('CANCEL Goes Here')}
            onSaveHint={(text) => console.log("On save: ", text)}
            text={"Pre-loaded text hint that was already saved!"}
          />
        </div>
      </div>
    )
  },
  {
    title: 'MCAnswerHint',
    component: 
    ( 
    <div style={{ width: '50%' }}>
      <MCAnswerHint 
        onNextQuestion={console.log}
        addHintToMistakeBank={
          (hint: string, display: boolean) => console.log(hint, display)
        }
        updateAskForHint={(checked: boolean) => console.log(checked)}
      />
    </div>
    )
  },
  {
    title: 'PT Summary Accuracy Cell',
    component: 
    (
      <table style={{ width: '50%', border: 'solid 1px' }}>
        <tbody>
        <tr>
          <td>SAT Example row</td>
          <td>
            <SummaryAccuracyCell 
              averageScore={10}
              testType={TestType.SAT}
              accuracy={0}
              studentCount={0}
            />
          </td>
          <td>
            <SummaryAccuracyCell 
              testType={TestType.SAT}
              accuracy={73}
              studentCount={5}
            />
          </td>
        </tr>
        <tr>
          <td>ACT Example row</td>
          <td>
            <SummaryAccuracyCell 
              averageScore={17}
              testType={TestType.SAT}
              accuracy={67}
              studentCount={2}
            />
          </td>
          <td>
            <SummaryAccuracyCell 
              testType={TestType.ACT}
              averageScore={19}
              accuracy={67}
              studentCount={3}
            />
          </td>
        </tr>
        </tbody>
      </table>
    )
  },
  {
    title: 'PT Summary Table (SAT)',
    component: 
    (
      <div style={{ backgroundColor: '#DDD', padding: '.5rem'}}>
        <p>Four different states for OverallAccuracyCell component [
          <br/> (invalid, invalid)
          <br/> (valid, invalid)
          <br/> (invalid, valid)
          <br/> (valid, valid)
        </p>
        <SummaryTable 
          onReportSent={console.log}
          testType={TestType.SAT}
          teacherEmailAddress="asdfa@asdfs.com"
          timeOnPlatformInfo={[]}
          data={[{
            testName: 'SAT Test 1', 
            averageScores: {
              score: {
                satEbrwScore: 470,
                satEbrwStudentCount: 0,
                satMathScore: 510,
                satMathStudentCount: 0 
              },
              reading: { accuracy: 20, studentCount: 9 },
              'writing & language': { accuracy: 70, studentCount: 9 },
              'math (no calculator)': { accuracy: 0, studentCount: 0 },
              'math (calculator)': { accuracy: 89, studentCount: 9 },
            }
          }]}
        />
        <SummaryTable 
          onReportSent={console.log}
          testType={TestType.SAT}
          teacherEmailAddress="asdfa@asdfs.com"
          timeOnPlatformInfo={[]}
          data={[{
            testName: 'SAT Test 1', 
            averageScores: {
              score: {
                satEbrwScore: 530,
                satEbrwStudentCount: 1,
                satMathScore: 550,
                satMathStudentCount: 0 
              },
              reading: { accuracy: 20, studentCount: 9 },
              'writing & language': { accuracy: 70, studentCount: 9 },
              'math (no calculator)': { accuracy: 0, studentCount: 0 },
              'math (calculator)': { accuracy: 89, studentCount: 9 },
            }
          }]}
        />
        <SummaryTable 
          onReportSent={console.log}
          testType={TestType.SAT}
          teacherEmailAddress="asdfa@asdfs.com"
          timeOnPlatformInfo={[]}
          data={[{
            testName: 'SAT Test 1', 
            averageScores: {
              score: {
                satEbrwScore: 490,
                satEbrwStudentCount: 0,
                satMathScore: 550,
                satMathStudentCount: 1 
              },
              reading: { accuracy: 20, studentCount: 9 },
              'writing & language': { accuracy: 70, studentCount: 9 },
              'math (no calculator)': { accuracy: 0, studentCount: 0 },
              'math (calculator)': { accuracy: 89, studentCount: 9 },
            }
          }]}
        />
        <SummaryTable 
          onReportSent={console.log}
          testType={TestType.SAT}
          teacherEmailAddress="asdfa@asdfs.com"
          timeOnPlatformInfo={[]}
          data={[{
            testName: 'SAT Test 1', 
            averageScores: {
              score: {
                satEbrwScore: 490,
                satEbrwStudentCount: 1,
                satMathScore: 550,
                satMathStudentCount: 8
              },
              reading: { accuracy: 20, studentCount: 9 },
              'writing & language': { accuracy: 70, studentCount: 9 },
              'math (no calculator)': { accuracy: 0, studentCount: 0 },
              'math (calculator)': { accuracy: 89, studentCount: 9 },
            }
          }]}
        />
      </div>
    )
  },
  {
    title: 'PT Summary Table (ACT)',
    component: 
    (
      <div style={{ backgroundColor: '#DDD', padding: '.5rem'}}>
        <h6>Option #1:</h6>
        <SummaryTable 
          onReportSent={console.log}
          teacherEmailAddress="asdfa@asdfs.com"
          testType={TestType.ACT}
          timeOnPlatformInfo={[]}
          data={[
          {
            testName: 'ACT Test 1', 
            averageScores: {
              composite: { score: 18, accuracy: 25, studentCount: 9 },
              english: { score: 18, accuracy: 10, studentCount: 9 },
              math: { score: 0, accuracy: 0, studentCount: 0 },
              reading: { score: 18, accuracy: 10, studentCount: 9 },
              science: { score: 18, accuracy: 10, studentCount: 9 } 
            }
          },
          {
            testName: 'ACT Test 2', 
            averageScores: {
              composite: { score: 18, accuracy: 25, studentCount: 9 },
              english: { score: 18, accuracy: 10, studentCount: 9 },
              math: { score: 18, accuracy: 10, studentCount: 9 },
              reading: { score: 18, accuracy: 10, studentCount: 9 },
              science: { score: 18, accuracy: 10, studentCount: 9 } 
            }
          }
          ]}
        />
        <h6>Option #2:</h6>
        <SummaryTable 
          onReportSent={console.log}
          teacherEmailAddress="asdfasf@asdfas.com"
          testType={TestType.ACT}
          timeOnPlatformInfo={[]}
          data={[
          {
            testName: 'ACT Test 2', 
            averageScores: {
              composite: { score: 18, accuracy: 25, studentCount: 9 },
              english: { score: 18, accuracy: 10, studentCount: 9 },
              math: { score: 18, accuracy: 10, studentCount: 9 },
              reading: { score: 18, accuracy: 10, studentCount: 9 },
              science: { score: 18, accuracy: 10, studentCount: 9 } 
            }
          },
          {
            testName: 'ACT Test 1', 
            averageScores: {
              composite: { score: 18, accuracy: 25, studentCount: 9 },
              english: { score: 18, accuracy: 10, studentCount: 9 },
              math: { score: 18, accuracy: 10, studentCount: 9 },
              reading: { score: 18, accuracy: 10, studentCount: 9 },
              science: { score: 18, accuracy: 10, studentCount: 9 } 
            }
          },
          ]}
        />
      </div>
    )
  },
  {
    title: 'Test Summary - Multiple tables, but IA is empty',
    component: 
    (
      <div style={{ backgroundColor: '#DDD', padding: '.5rem'}}>
        
        <SummaryTable 
          onReportSent={console.log}
          testType={TestType.SAT}
          teacherEmailAddress="asdfa@asdfs.com"
          timeOnPlatformInfo={[]}
          data={[{
            testName: 'SAT Test 1', 
            averageScores: {
              score: {
                satEbrwScore: 490,
                satMathScore: 550
              },
              reading: { accuracy: 20, studentCount: 9 },
              'writing & language': { accuracy: 70, studentCount: 9 },
              'math (no calculator)': { accuracy: 0, studentCount: 0 },
              'math (calculator)': { accuracy: 89, studentCount: 9 },
            }
          }]}
        />
        <SummaryTable 
          onReportSent={console.log}
          testType={TestType.IA}
          teacherEmailAddress="asdfa@asdfs.com"
          timeOnPlatformInfo={[]}
          data={[
          ]}
        />
      </div>
    )
  },
  {
    title: 'NewSelectList',
    component: 
    (
      <NewSelectList
        id="what-id-list"
        onChange={() => console.log('boom')}
        selected="dsp-id-x"
        options={[
          {value: 'dsp-id-x', display: 'Display X'},
          {value: 'dsp-id-y', display: 'Display Y'}
        ]}
      />
    )
  },
  {
    title: 'NavbarLinks',
    component: 
    (
    <div style={{ backgroundColor: '#0C5681' }}>
      <NavbarLink 
        img="/assets/images/navbar/WWA_Dashboard.svg"
        title="Dashboard"
        nav={{
          key: 'blergh',
          pathname: '/nothing!',
          search: '?some=search-string',
          hash: '#howdy',
          state: { }
        }}
      />
      <NavbarLink 
        img="/assets/images/navbar/WWA_Lessons.svg"
        title="Lessons"
        nav={{
          key: 'blergh',
          pathname: '/nothing!',
          search: '?some=search-string',
          hash: '#howdy',
          state: { }
        }}
      />
      <NavbarLink 
        img="/assets/images/navbar/WWA_Data.svg"
        title="Data"
        nav={{
          key: 'blergh',
          pathname: '/nothing!',
          search: '?some=search-string',
          hash: '#howdy',
          state: { }
        }}
      />
      <NavbarLink 
        img="/assets/images/navbar/WWA_Practice-Test.svg"
        title="Practice Tests"
        nav={{
          key: 'blergh',
          pathname: '/nothing!',
          search: '?some=search-string',
          hash: '#howdy',
          state: { }
        }}
      />
    </div>
    )
  },
  {
    title: 'Select Input',
    component: 
    (
    <div>
      <SelectInput 
        id="blergh-select" value="bbb" 
        options={['aaa', 'bbb', 'ccc']}
        onChange={() => console.log('sdfas')}
      />
    </div>
    )
  },
  {
    title: 'Not Applicable',
    component: 
    (
    <div>
      Vanilla indicator
      <div>
        <NotApplicableIndicator />
      </div>
      With custom prepended text
      <NotApplicableIndicator 
        prependedText="EBRW - " 
      />
    </div>
    )
  },
  { 
    title: 'ReportTableHeader',
    component: 
    (
    <div>
      <table style={{ width: '50%' }}>
        <ReportTableHeader category="Math">
          <th>First Col</th><th>2nd Col</th>
        </ReportTableHeader>
      </table>
      <table style={{ width: '50%' }}>
        <ReportTableHeader category="Reading">
          <th>First Col</th><th>2nd Col</th>
        </ReportTableHeader>
      </table>
    </div>
    )
  },
  { 
    title: 'Category Headers',
    component: 
    (
    <div>
      <TestAnalysisSectionHeader sectionName="Math" />
      <TestAnalysisSectionHeader sectionName="English" />
      <TestAnalysisSectionHeader sectionName="Reading" />
      <TestAnalysisSectionHeader sectionName="Science" />
    </div>
    )
  },
  {
    title: 'Green button with click',
    component: 
      (
      <Button 
        bgColor="green"
        text="green - with Click"
        onClick={() => alert('sup g')}
      />
      )
  },
  {
    title: 'Increased performance',
    component: 
      (
      <GrowthIndicator 
        before={{ correctCount: 3, total: 20 }} 
        after={{ correctCount: 9, total: 20 }} 
      />
      )
  },
  {
    title: 'Decreased performance',
    component:
      (
      <GrowthIndicator 
        before={{ correctCount: 10, total: 20 }} 
        after={{ correctCount: 9, total: 20 }} 
      />
      )
  },
  {
    title: 'Modal',
    component:
      (
      <Modal 
        show={true} 
        onClose={console.log}
      >
        Say what
      </Modal>
      )
  },
  {
    title: 'Purple button',
    component: 
      (
      <Button
        bgColor="purple"
        text="Dearly Beloved..."
      />
      )
  }
];

export class GoryBook extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    
    let selectedShelf = this.titleToKey(shelves[0].title);
    if (props.match.params.shelfName) {
      selectedShelf = props.match.params.shelfName;
    }

    this.state = {
      selectedShelf: selectedShelf
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.match.params.shelfName) {
      this.setState({ selectedShelf: nextProps.match.params.shelfName });
    }
  }

  onSelectShelf(title: string) {
    if (this.props.history) {
      this.props.history.push('/gory/' + this.titleToKey(title));
    }
  }

  titleToKey(title: string) {
    return title.replace(/ /g, '-').toLowerCase();
  }

  render() {
    const { selectedShelf } = this.state;
    let selectedShelfData = shelves.find(
      shelfData => this.titleToKey(shelfData.title) === selectedShelf
    );

    if (selectedShelfData === undefined) {
      selectedShelfData = shelves[0];
    }
    
    return (
      <div className="the-gory-window">
        <div className="gory-header">
          <h4>
            Gory Book 
          </h4>
          <h5>
            A gruesome alternative to Storybook
          </h5>
        </div>

        <div className="gory-workspace">
          <div className="gory-side-bar">
          {shelves.map(shelfData =>
              <button 
                className={selectedShelf === this.titleToKey(shelfData.title) ? 'gory-selected-shelf' : ''}
                onClick={() => this.onSelectShelf(shelfData.title)}
                key={'btn-' + shelfData.title}
              >
                {shelfData.title}
              </button>
          )
          }
          </div>
          <div className="gory-canvas">
            <Shelf 
              title={selectedShelfData.title}
            >
              {selectedShelfData.component}
            </Shelf>
          </div>
        </div>

      </div>);
  }
}

export default GoryBook;
