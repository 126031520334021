import './MenuList.css';

import * as React from 'react';
import { hasSchoolSafety } from '../../../../../../../../services/account/account-utils';

interface Props {
    goToSettings: () => void;
    handleLogout: () => void;
    handleLeave: () => void;
    user: any;
}

const MenuList: React.SFC<Props> = (props: Props) => {
    
    const { goToSettings, handleLogout, handleLeave, user } = props;
    if (hasSchoolSafety(user)) {
        return (
            <div>
                <button className="dropdown-item" onClick={goToSettings} title="Account Settings">Account Settings</button>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item" onClick={handleLogout} title="Logout">Logout</button>
            </div>
        );    
    } else {
        return (
            <div>
                <button title="Account settings" className="dropdown-item" onClick={goToSettings}>Account Settings</button>
                {user.isSuperintendent &&
                <a className="dropdown-item" href="https://winwardacademy.com/contact/" target="_blank" title="Contact us">Contact Us</a>
                }
                <div className="dropdown-divider"></div>
                <button title="Logout" className="dropdown-item" onClick={handleLogout} onBlur={handleLeave}>Logout</button>
            </div>
        );
    }
};

MenuList.displayName = 'MenuList';
export default MenuList;
