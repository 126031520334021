/**
 * Debounces the functions for a certain amount of time before running.
 * 
 * @param cb Callback function
 * @param timeout Timeout in milliseconds
 * @returns debounced function returned
 */
export function debounce(cb, timeout=300) {
  let timerId
  return (...args) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => cb(...args), timeout)
  }
}
