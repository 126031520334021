
import { createGenericAuthRequest, createPutRequest2 } from '../../request/request-utils'

const requestGET = createGenericAuthRequest(process.env.REACT_APP_ELEARN_URL || '/')
const requestPUT = createPutRequest2(process.env.REACT_APP_ELEARN_URL || '/')

/**
 * Gets the reteach questions for the lesson
 * @param lessonId 
 * @returns 
 */
export async function getReteachQuestions(lessonId: string) {
    let reteachQuestions = []

    try {
        let result = await requestGET(`/teacher/reteach/${lessonId}/questions`)
        if (result != null) reteachQuestions = result
    }
    catch (err) {
        console.error("Error: ", err)
    }
    
    return reteachQuestions
}

/**
 * Updates the lesson's question's reteach status
 * 
 * @param lessonId 
 * @param starred 
 * @returns 
 */
export function updateQuestionReteachStatus(lessonId: string, questionId: string, selected: boolean) {
    let data = JSON.stringify({
        lessonId,
        questionId,
        selected
    })
    return requestPUT(`/teacher/reteach`, data)
        .catch(err => console.error("Error->", err))
}

