
/**
 * SAT Reading & Writing Domain Lessons
 */
export const SAT_DOMAIN_LESSONS_RW = {
    craftAndStructure: [
        "ACT&SATReadingPassagesOverview",
        "SinglePassages",
        "DualPassages",
    ],
    informationAndIdeas: [
        "SinglePassagesWithFigures",
    ],
    expressionOfIdeas: [
        "Usage&Mechanics",
        "Transitions",
    ],
    standardEnglishConventions: [
        "AdjectivesAdverbs&Idioms",
        "Agreement",
        "Apostrophes",
        "BasicsButEssentials",
        "ModifyingPhrases",
        "OtherToughOnes",
        "Parallelism",
        "Pronouns",
        "Punctuation",
        "Verbs",
    ],

}

/**
 * SAT Math Domain Lessons
 */
export const SAT_DOMAIN_LESSONS_MATH = {
    algebra: [
        "AbsoluteValue&Inequalities",
        "AlgebraStrategies",
        "ArithmeticSequences",
        "CombiningUnits",
        "f(x)&CompositeFunctions",
        "Graphing&SolvingLines",
        "LinearSystemsWordProblems",
        "SolvingMultipleLinearSystems",
    ],
    advancedMath: [
        "CombiningFractions",
        "ExponentRules",
        "Factoring",
        "FOIL",
        "MainGraphs&Transformations",
        "SimplifyingRadicals",
        "SolvingQuadraticEquations",
        "SolvingRadicals&Exponents"
    ],
    geometryAndTrigonometry: [
        "2DShapes:Part1:",
        "2DShapes:Part2:",
        "2DShapes:Part3:",
        "3DShapes:Part1",
        "3DShapes:Part2",
        "Circles:ArcLength&SectorArea",
        "Circles:Circumference&Area",
        "Circles:EquationOfACircle",
        "GeometryFundamentals:Angles&CoordinatePlane",
        "SixTrigFunctions",
        "Triangles:3rdSideRule&SimilarTriangles",
        "Triangles:Basics&Area",
        "Triangles:PythagoreanTheorem",
        "Triangles:SOHCAHTOA",
        "Triangles:SpecialRightTriangles",
        "UnitCircle",
    ],
    problemSolvingAndDataAnalysis:  [
        "AlgebraFundamentals:KeyDefinitions",
        "Distance&AverageSpeed",
        "Distance&MidpointFormulas",
        "Figures&Tables",
        "MeanMedian&Mode",
        "Percents",
        "Probability",
        "Rare&Random",
        "Ratios",
    ],

}

