import './CompletedView.css';

import * as React from 'react';

import { MaterialType } from '../../../../services/elearn/lesson-types';

import LessonList from '../../../../components/LessonList/LessonListContainer';
import NoLessons from '../../../../components/SubjectView/components/NoLessons/NoLessons';

export interface Props {
    curriculum: string;
    frequency: string;
}

interface StoreProps {
    lessons: any;
}

interface State {

}

type AllProps = Props & StoreProps;

export default class CompletedView extends React.Component<AllProps, State> {
    public render() {
        const allLessons = this.props.lessons.allLessons.filter(lesson =>
            lesson.curricula ? lesson.curricula.some(curriculum => this.props.curriculum === curriculum) : true);
        const numLessons = allLessons.length;

        if(numLessons === 0) {
            return <NoLessons />;
        }

        const completedLessons = allLessons
            .filter(lesson => lesson.currentLessonContent && lesson.currentLessonContent.type === MaterialType.REPORT);

        return (
            <div className="completed-view">
                <div className="subcategory-lesson-list">
                    <LessonList lessons={completedLessons} frequency={this.props.frequency} />
                </div>
            </div>
        );
    }
}
