import './DataGrid.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { CellContents, Column, RowData } from './types/types';

import TableEmptyText from './components/TableEmptyText/TableEmptyText';
import TableHeader from './components/TableHeader/TableHeader';
import TableRow, { TableAverageRow, TableSummaryRow, TableTotalRow } from './components/TableRow/TableRow';
import { move } from '../../services/array/array-utils';
import { NA } from '../../constants';

let currentSortedCol = ""
let currentSortedMap = {}

export interface IAverageColumn {
    name: string
    cb?: (avarege: number) => number|string
    cb2?: (avarege1: number, avarege2: number) => number|string
}
export interface IAverageMetaData {
    columns: IAverageColumn[]    
}

interface Props {
    /* Column definitions for the table */
    columns: Column[];
    /* Row data */
    data?: RowData[];
    /* If true, allows rows to be custom sorted by dragging and dropping that row in the table */
    dragReorder?: boolean;
    /* Custom text to use if the data grid has no rows */
    emptyText?: string;
    className?: string;
    sortByAttribute?: string;
    onSortAllowed?: (fieldName: string) => boolean
    averageMetaData?: IAverageMetaData
    specialSort?: boolean
}

interface State {
    sortedCol: string;
    data: RowData[];
    reverseSort: boolean;
}


export default class DataGrid extends React.Component<Props, State> {
    public static defaultProps = {
        data: [],
        emptyText: 'No data to display'
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            sortedCol: "",
            data: props.data || [],
            reverseSort: false
        };
    }

    componentDidMount() {
        if (this.props.sortByAttribute) {
            this.handleSort(this.props.sortByAttribute);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: any) {
      let sortBy = this.state.sortedCol;
      let reverse = this.state.reverseSort;
      let newRow = nextProps.data;
      const hasClassName = nextProps.className !== undefined;
      if (sortBy !== '' && hasClassName && nextProps.className.indexOf('test-table') >= 0) {
        newRow = this.sortDataByCol(sortBy, !reverse, nextProps.data);
      } else if (sortBy !== '' && hasClassName && nextProps.className.indexOf('lesson-dashboard-table') >= 0) {
        newRow = this.sortDataByCol(sortBy, !reverse, nextProps.data);
      } else if (sortBy !== '' && hasClassName && nextProps.className.indexOf('teacher-lesson-table') >= 0) {
        newRow = this.sortDataByCol(sortBy, !reverse, nextProps.data);
      } else if (sortBy !== '') {
        newRow = this.sortDataByCol(sortBy, reverse, nextProps.data);
      }
      this.setState({
        data: newRow,
        sortedCol: sortBy,
      });
    }


    /**
     * Get average row info.
     * Note that there could be 2 average values based on the comparator and optional subComparator values!
     * 
     * @param rows 
     * @param averageMetaData 
     * @returns 
     */
    getAverageRow = (rows: RowData[], averageMetaData: IAverageMetaData) => {
        let {sortedCol} = this.state
        let sum1 = 0
        let sum2 = 0
        let count1 = 0
        let count2 = 0

        rows.forEach(row => {
            let {data} = row
            let sortedColData = data[sortedCol]
            let {comparator, subComparator} = sortedColData
            let v1 = comparator
            let v2 = subComparator
            
            if (typeof v1 === "number" && v1 !== -1) {
                sum1 += v1
                count1++
            }
            if (v2 && typeof v2 === "number" && v2 !== -1) {
                sum2 += v2
                count2++
            }
        })

        let averageRow
        let average1 = sum1/count1
        let average2 = sum2/count2
        let averageInfo: number|string = `Average: ${!isNaN(average1) ? Math.round(average1): NA}`
        let meta = averageMetaData?.columns.find(c => c.name === sortedCol)

        if (meta) {
            let { cb, cb2 } = meta
            if (cb && !isNaN(average1)) {
                averageInfo = cb(average1)
            }
            else if (cb2 && !isNaN(average1) && !isNaN(average2)) {
                averageInfo = cb2(average1, average2)
            }
        }

        averageRow = {id: "average", isAverageRow: true, averageInfo, data: {[sortedCol]: {content: averageInfo, comparator: average1}}}

        return averageRow
    }

    public render() {
        const rowDataOrginal = this.state.data;
        let rowData = [...rowDataOrginal]
        let headerColumns = this.props.columns;
        const containerClass = !this.props.className ? 'lesson-table' : this.props.className;
                
        // Add draggable sort column
        if(this.props.dragReorder) {
            headerColumns = headerColumns.concat({ title: 'ORDER', width: 'auto', dataProperty: '', sortable: false, customTooltip: '' });
        }

        let {averageMetaData} = this.props
        let allowAverage = rowData.length > 1 ? true: false

        if (averageMetaData && allowAverage) {
            // Let's find the average row...
            let {sortedCol, reverseSort} = this.state
            let found = averageMetaData?.columns.find(c => c.name === sortedCol)

            if (found) {
                let averageRow = this.getAverageRow(rowData, averageMetaData) as RowData
                rowData.push(averageRow)
                rowData = this.sortDataByCol(sortedCol, reverseSort, rowData)
            }
        }

        const globalColSpan = headerColumns.length + (this.props.dragReorder ? 1 : 0);

        return (
            <div className="fixedHeaderTable">
                <table className={containerClass}>
                    <TableHeader
                        data={headerColumns}
                        onSort={this.handleSort}
                        sortedCol={this.state.sortedCol}
                        reversed={this.state.reverseSort}
                    />

                    <tbody>

                        {rowData.length === 0 &&
                        <tr>
                            <td colSpan={globalColSpan}>
                                <TableEmptyText text={this.props.emptyText as string} />
                            </td>
                        </tr>}
                        {rowData.map((data, i) => {
                            const isSummaryRow = data.isSummaryRow
                            const isAverageRow = data.isAverageRow
                            const isTotalRow = data.isTotalRow
                            const highlightRowClassName = data.highlightRowClassName
                            const summaryRowClassName = data.summaryRowClassName
                            const averageRowClassName = data.averageRowClassName
                            const totalRowClassName = data.totalRowClassName
                            const cellData: CellContents[] = this.props.columns.map(col => ({
                                ...data.data[col.dataProperty],
                                width: col.width
                            }));

                            const isHighlighted = highlightRowClassName?.includes('row-highlight');
                            // const colSpan = cellData.length + (this.props.dragReorder ? 1 : 0);

                            return isSummaryRow ?
                                <TableSummaryRow
                                    summaryRowClassName={summaryRowClassName}
                                    data={cellData}
                                    index={i}
                                    key={data.id}
                                    dragReorder={this.props.dragReorder}
                                    onRowDrag={this.onRowDrag}
                                />
                                :
                                isTotalRow ?
                                    <TableTotalRow
                                        totalRowClassName={totalRowClassName}
                                        data={cellData}
                                        index={i}
                                        key={data.id}
                                        dragReorder={this.props.dragReorder}
                                        onRowDrag={this.onRowDrag}
                                    />
                                :
                                isAverageRow ?
                                    <TableAverageRow
                                        summaryRowClassName={averageRowClassName}
                                        data={cellData}
                                        index={i}
                                        key={data.id}
                                        dragReorder={this.props.dragReorder}
                                        onRowDrag={this.onRowDrag}
                                    />
                                :
                                <TableRow
                                    data={cellData}
                                    index={i}
                                    key={data.id}
                                    dragReorder={this.props.dragReorder}
                                    onRowDrag={this.onRowDrag}
                                    highlightRowClassName={highlightRowClassName}
                                    isHighlighted={isHighlighted}
                                    colSpan={globalColSpan}
                                />
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    private onRowDrag(oldIndex: number, newIndex: number) {
        const data = move(this.state.data, oldIndex, newIndex);
        this.setState({ data, sortedCol: '' });
    }

    @boundMethod
    private handleSort(dataProperty: string) {
        let {onSortAllowed, specialSort=false} = this.props

        if (onSortAllowed && !onSortAllowed(dataProperty)) return

        const reverseSort = this.state.reverseSort;
        const reverse = this.state.sortedCol === dataProperty ? !reverseSort : false;
        const data = specialSort ? this.sortDataByCol(dataProperty, reverse): this.sortDataByCol(dataProperty, !reverse)
        const sortedCol = dataProperty;

        currentSortedCol = sortedCol
        currentSortedMap[currentSortedCol] = !reverse

        this.setState({ sortedCol, data, reverseSort: reverse });
    }

    private sortDataByCol(sortedCol: string, reverseSort: boolean, data?: any, maintainSort?: boolean) {
        if(!sortedCol || !this.props.data) {
            return this.props.data as any[];
        }

        const colToSort = this.props.columns.filter(col => col.dataProperty === sortedCol)[0];
        const sortMethod = colToSort && colToSort.sortMethod;
        
        if(!sortMethod) {
            return this.props.data as any[];
        }

        data = data ? data: this.props.data
        let sortedData = data.slice().sort((a, b) => {
            if (a.data.keepOnTopDuringSort && a.data.keepOnTopDuringSort.content === true) {
                return reverseSort ? -1: 1
            }

            if (b.data.keepOnTopDuringSort && b.data.keepOnTopDuringSort.content === true) {
                return reverseSort ? -1: 1
            }

            return sortMethod(a.data[sortedCol].comparator, b.data[sortedCol].comparator, a.data[sortedCol].subComparator, b.data[sortedCol].subComparator, reverseSort)
        })

        if (sortedCol === 'frequency' ||
            sortedCol === 'questions' ||
            sortedCol === 'video' ||
            sortedCol === 'correct' ||
            sortedCol === 'incorrect' ||
            sortedCol === 'blank' ||
            sortedCol === 'total' ||
            sortedCol === 'star') {
          return reverseSort ? sortedData : sortedData.reverse();
        }

        return reverseSort ? sortedData.reverse() : sortedData;
    }
}
