import { UserInfo } from "../../../../services/account/account-rest-interface"
import { LessonProgress, MistakeBankLessons } from "../../../../services/elearn/lesson-types"


export interface IStudentDashboardProps {
    info: IStudentDashboardInfo
}

export interface IStudentDashboardInfo {
    userInfo: UserInfo
    currentLesson: LessonProgress
    studentLessons: LessonProgress[]
    studentMistakes: MistakeBankLessons[]
    studentTestScores
    studentTonP
    summary: {
        milestones: IAchievementMilestones
        lessons: IProgressLessons
        questions: IProgressQuestions
        studyTime: IProgressStudyTime
        topics: {
            mastered: number
            review: number
            revisit: number
        }
    }
}

export interface IAchievementMilestones {
    awardLevels: {
        lessonsCompleted: number
        lessonsCompletedNext: number
        questionsAnswered: number
        questionsAnsweredNext: number
        studyTime: number
        studyTimeNext: number
        topicsMastered: number
        topicsMasteredNext: number
    }
    completedLessons: {
        all: boolean
        english: boolean
        math: boolean
        reading: boolean
        science: boolean
        writing: boolean
    }
}

export interface IProgressLessons {
    total: number
    completed: number
    inProgress: number
    leftToStart: number

    // Categorical progress info
    english: {
        total: number
        completed: number
    }
    math: {
        total: number
        completed: number
    }
    reading: {
        total: number
        completed: number
    }
    science: {
        total: number
        completed: number
    }
    writing: {
        total: number
        completed: number
    }
}

export interface IProgressQuestions {
    total: number
    answered: number
}

export interface IProgressStudyTime {
    seconds: number
    humanTime: {
        days: number
        hours: number
        minutes: number
        seconds: number
        html: string
        text: string
    }
}

export interface IStudentProgressInfo {
    lessons: IProgressLessons
    questions: IProgressQuestions
    studyTime: IProgressStudyTime
}

export interface ITopicMasteryInfo {
    mastered: number
    review: number
    revisit: number
}

export enum LessonStatus {
    NOT_STARTED,
    STARTED,
    COMPLETED
}