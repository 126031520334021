import * as React from 'react';

import { AppState } from '../../../../store';
import SubjectView from './SubjectView';
import { connect } from 'react-redux';

const mapStateToProps = ({ lesson, account }: AppState) => ({
    subjects: { ...lesson.userLessons.bySubject },
    isTeacher: account.userInfo.isTeacher,
    userInfo: account.userInfo
});

const ConnectedSubjectView =  connect<any, any, any, AppState>(mapStateToProps, {})(SubjectView);

export default (props) => {
    return (
        <ConnectedSubjectView {...props} />
    );
};
