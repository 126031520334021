import * as React from 'react'
import { isProductionEnv } from './services/utils/env-helper'

let scriptLoaded = false
function loadNewRelicScript() {
  if (scriptLoaded) return
  let prod = isProductionEnv()
  let srcUrl = `/assets/libs/newrelic-${prod ? "prod": "dev"}.js`
  let script = document.createElement("script")
  script.async = true
  script.src = srcUrl  
  script.onerror = (e) => console.error("NewRelic script cannot be loaded!", e)
  document.body.appendChild(script)
  scriptLoaded = true
}

class NewRelic extends React.Component {
  componentDidMount() {
    loadNewRelicScript()
  }
  
  render() {
    return ""
  }
}

export default NewRelic
