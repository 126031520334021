import './BookmarkIcon.css';

import * as React from 'react';

export interface Props {
    isBookmarked?: boolean
    disabled?: boolean
    lessonAndTopic?: string
    onToggle?: () => void
}

const BookmarkIcon: React.SFC<Props> = ({ lessonAndTopic, isBookmarked, disabled, onToggle }) => {
    const bookmarkClass = isBookmarked ? "bookmark-icon isBookmarked" : disabled ? "bookmark-icon disabled": "bookmark-icon"
    const titleText = isBookmarked
    ? `Bookmark active for: ${lessonAndTopic}, click to disable`
    : `Bookmark disabled for: ${lessonAndTopic}, click to enable`;

    return (
        <button title={titleText} onClick={onToggle} className={`btn-simple btn-has-hover-img ` + bookmarkClass}>
            {isBookmarked ?
                <img src="/assets/images/icons/v2/ico-bookmark-active.svg" className="non-hover-icon" width="20" height="20" alt="" />
                :
                <img src="/assets/images/icons/v2/ico-bookmark.svg" className="non-hover-icon" width="20" height="20" alt="" />
            }
            <img src="/assets/images/icons/v2/ico-bookmark-hover.svg" className="priority-icon hover-icon d-none" width="20" height="20" alt="" />
        </button>
    );
};

BookmarkIcon.defaultProps = {};
BookmarkIcon.displayName = 'BookmarkIcon';

export default BookmarkIcon;
