import * as React from 'react';

import { Redirect, Route, RouteProps } from 'react-router-dom';

import { getSessionStorageByID } from '../../services/utils/session-storage-utility';

interface Props extends RouteProps {
    loggingIn?: boolean;
    authenticated?: boolean;
    component?: any;
    render?: () => any;
}

const Authenticated: React.SFC<Props> = ({ loggingIn, authenticated, component, render, ...rest }: Props) => {

    const shouldUseRender = !component && render;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const hasWinToken = getSessionStorageByID('x-win-token');
    const isAuthenticated = !!hasWinToken && authenticated;
    const authenticate = (props) => {
        if(loggingIn) { return <div></div>; }
        if(isAuthenticated && shouldUseRender && render) {
            return render();
        }
        return isAuthenticated ?
            (React.createElement(component, { ...props, loggingIn, authenticated })) :
            (<Redirect to={`${baseUrl}login`} />);
    };

    return (
        <Route {...rest} render={authenticate} />
    );
};

Authenticated.displayName = 'Authenticated';

export default Authenticated;
