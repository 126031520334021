import './QuestionProgressBar.css';

import React, { createRef } from 'react';
import { boundMethod } from 'autobind-decorator';

import CloseIcon from '../../../../components/CloseIcon/CloseIcon';
import QuestionNumber from './components/QuestionNumber/QuestionNumber';
import QuestionProgressButton from './components/QuestionProgressButton/QuestionProgressButton';

interface Props {
    questionNumbers: number[];
    currentQuestionIndex: number;
    farthestQuestionIndex: number;
    onQuestionClick: (questionIndex: number) => void;
    onCloseClick: (e: any) => void;
    exitText?: string;
    disableNavigation?: boolean;
    isTeacher: boolean;
    showExitText?: boolean
}

interface State {
    navWidth: number;
}


export default class QuestionProgressBar extends React.Component<Props, State> {
    public static defaultProps = {
        exitText: 'Exit Lesson'
    };

    private nav = createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);
        this.state = {
            navWidth: 0
        };
    }

    public componentDidMount() {
        const currentNode = this.nav.current;
        this.setState({
            navWidth: currentNode ? currentNode.clientWidth : 0
        });
    }

    // TODO: For more fine-grained control, use navWidth to reason about viewport
    // public componentWillReceiveProps() {
    //     const newNavClientWidth = this.nav ? this.nav.clientWidth : 0
    //     // console.log('clientWidth?', newNavClientWidth);
    //     this.setState({
    //         navWidth: newNavClientWidth
    //     });
    // }

    public render() {
        const { currentQuestionIndex, farthestQuestionIndex, onQuestionClick, 
            disableNavigation, questionNumbers, onCloseClick, exitText, showExitText=true, isTeacher 
        } = this.props;
        let navStyle = { marginLeft: ''};
        const pixelsPerIndexComponent = 48;
        const thresholdIndex = 10;
        let newOffset = pixelsPerIndexComponent * (currentQuestionIndex - thresholdIndex);
        if (currentQuestionIndex > thresholdIndex) { 
          navStyle = { marginLeft: `${-newOffset}px` };
        }

        return (
            <div className="question-progress-bar">
                <QuestionProgressButton
                    direction="left"
                    onClick={this.handlePrev}
                    enabled={isTeacher || currentQuestionIndex > 0 && !disableNavigation}
                />

                <div className="question-progress-bar__nav" style={navStyle} ref={this.nav}>
                    {questionNumbers.map((num, i) => (
                        <QuestionNumber
                            num={num}
                            key={num}
                            isSelected={i === currentQuestionIndex}
                            disabled={!isTeacher && (i > farthestQuestionIndex || disableNavigation)}
                            onClick={() => onQuestionClick(i)}
                        />
                    ))}
                </div>

                <QuestionProgressButton
                    direction="right"
                    onClick={this.handleNext}
                    enabled={isTeacher || (currentQuestionIndex < farthestQuestionIndex && !disableNavigation)}
                />

                {showExitText &&
                    <div className="question-progress-bar__close">                    
                        <button className="btn-simple question-progress-bar__close-btn" title={exitText} onClick={onCloseClick}>
                            <span className="question-progress-bar__close-text">{exitText}</span>
                            <CloseIcon />
                        </button>
                    </div>
                }
            </div>
        );
    }

    @boundMethod
    private handlePrev() {
        this.props.onQuestionClick(this.props.currentQuestionIndex - 1);
    }

    @boundMethod
    private handleNext() {
        this.props.onQuestionClick(this.props.currentQuestionIndex + 1);
    }
}
