import React from 'react'
import { isProductionEnv } from '../../../services/utils/env-helper'

const CLASSLINK_CLIENT_ID_QA   = "c1678241682311008ed519e606e323a5f272f5d805f3" // QA & Local dev have same ID
const CLASSLINK_CLIENT_ID_PROD = "c167824168231188678c6f074af064ad44ae196498e3"

/**
 * This is self-contained Single Sign-On Button for ClassLink logins.
 * When user clicks then he/she will be directed to the ClassLink to login and in
 * return it will be redirected to our own ClasLink login page (<wa-url>/login/classlink)
 * 
 * Note that this is just a button with a redirect URL. Actual handshake 
 * starts in the redirected page with OAuth2 code which is passed to WA 
 * backend, in return it finds the user's information with a call to the 
 * auth server to be later verified againts WA's DB.
 * 
 * @returns ClassLink Button
 */
export default function ClassLinkLoginButton() {
  let prod = isProductionEnv()
  let {host, protocol} = window.location
  let redirectUrl = `${protocol}//${host}/login/classlink`
  let clientId = prod ? CLASSLINK_CLIENT_ID_PROD: CLASSLINK_CLIENT_ID_QA
  let classLinkOauthUrl = "https://launchpad.classlink.com/oauth2/v2/auth"
  let classLinkLoginUrl = `${classLinkOauthUrl}?scope=full,openid&response_type=code&redirect_uri=${redirectUrl}&client_id=${clientId}`

  return (
    <div style={{textAlign:"center"}}>
      <a href={classLinkLoginUrl} title="Log In with ClassLink">
          <img src="/assets/images/loginbtn--classLink.svg" alt="ClassLink Log In Button" width="200" height="auto" />
      </a>
    </div>
  )
}
