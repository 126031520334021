import './DropDown.css';

import React, { createRef } from 'react';

import MenuIcon from './components/MenuIcon/MenuIcon';
import MenuList from './components/MenuList/MenuList';

interface Props {
    show: boolean;
    // TODO add more positions
    position: 'right' | 'bottom-left';
    initColor: string;
    handleMenuHover: () => void;
    handleMenuLeave: () => void;
    goToSettings: () => void;
    handleLogout: () => void;
    onColorChange: (color: string) => void;
    center?: boolean;
    user: any;
}

interface State {
    /** Show or hide the dropdown */
    show: boolean;
    /** Calculated size (either width or height depending on props.position) of the dropdown container */
    containerSize: number;
    offset?: number;
}

const ARROW_SIZE = 14;

class DropDown extends React.PureComponent<Props, State> {
    private container = createRef<HTMLDivElement>();
    private content = createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);

        // Initially show to calculate dimensions
        this.state = {
            show: true,
            containerSize: 0
        };
    }

    public componentDidMount() {
        const currentNode = this.content.current;

        if (currentNode !== null) {
        const offset = this.props.position === 'bottom-left' ?
            currentNode.clientWidth - ARROW_SIZE : // subtract width of the arrow to position in bottom-left
            currentNode.clientHeight / 2.0; // divide by 2 to center dropdown

        // Set the calculated offset, then call setState again with the calculated containerSize
        // NOTE: The containerSize MUST be calculated after the offset is set, otherwise the containerSize
        // will include the size of the child elements, but we only want the size of the container after
        // the child element have the offset applied (negative margin)
        this.setState({ offset }, () =>
            this.setState({
                show: this.props.show,
                containerSize: this.props.position === 'right' ? currentNode.clientHeight / 2.0 : 0
            }));
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if(nextProps.show !== this.state.show) {
            this.setState({ show: nextProps.show });
        }
    }

    public render() {
        const { position, handleMenuHover, handleMenuLeave, onColorChange, initColor,
            goToSettings, handleLogout } = this.props;
        const { show } = this.state;

        return (
            <div
                className={`dropdown ${show ? 'dropdown--show' : ''} dropdown--${position}`}
                ref={this.container}
                style={this.getContainerPosition()}
            >
                <div className="dropdown__wrapper">
                    <div className="dropdown__arrow"></div>
                    <div
                        className="dropdown__content"
                        onMouseOver={handleMenuHover}
                        onMouseLeave={handleMenuLeave}
                        onFocus={handleMenuHover}
                        style={this.getContentPosition()}
                        ref={this.content}
                    >
                        {position === 'bottom-left' ?
                            <MenuList
                                goToSettings={goToSettings}
                                handleLogout={handleLogout}
                                handleLeave={handleMenuLeave}
                                user={this.props.user}
                            /> :
                            <MenuIcon onColorChange={onColorChange} initColor={initColor} />}
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Creates styles to center the dropdown container relative to its parent element
     */
    private getContainerPosition(): React.CSSProperties {
        if(this.state.offset === undefined) {
            return {};
        }

        switch(this.props.position) {
            case 'right': return { top: 0, bottom: 0 }; // center vertically
            default: return { left: 0, right: 0 }; // center horizontally
        }
    }

    /**
     * Creates styles to offset the content div to either center it (position === 'right')
     * or to place it to the left of the parent (position === 'bottom-left')
     */
    private getContentPosition(): React.CSSProperties {
        return {
            [this.getMarginType()]: this.getDropdownOffset()
        };
    }

    /**
     * Calculates how much of a negative margin to give the dropdown container
     * in order to center it relative to its parent element
     */
    private getDropdownOffset() {
        return -((this.state.offset) || 0) + this.state.containerSize;
    }

    /**
     * Get the css style key for the margin side we're offsetting
     */
    private getMarginType() {
        switch(this.props.position) {
            case 'right': return 'marginTop';
            default: return 'marginLeft';
        }
    }
}

export default DropDown;
