import React, { Component } from "react"
import TestCard from "./components/TestCard/TestCardContainer"
import { getDetailPTScoresByTest } from "../../services/practice-test-scoring/practice-test-service"
import { getUserLessons } from "../../services/user/user-lesson-rest-interface"
import { SAT_DOMAIN_LESSONS_MATH, SAT_DOMAIN_LESSONS_RW } from "./SATDomainLessons"

interface Props {
    testName: string
}

interface State {
    testScoreDetailsRawData?: ISATTestScoreDetailsRawData
    reportsByDomains?: ISATReportsByDomainsAndLessons
    lessons?: any
}

export default class SATTestAnalysis extends Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount(): void {
        let {testName} = this.props
        getUserLessons().then(res => {
            let lessons = res.userLessonOverviews
            getDetailPTScoresByTest(testName).then(res => {
                let testScoreDetailsRawData = res
                let reportsByDomains = processRawData(testScoreDetailsRawData, lessons)
                this.setState({ testScoreDetailsRawData, reportsByDomains})
            })
        })
    }

    render() {
        let {reportsByDomains} = this.state
        
        if (!reportsByDomains) return <></>

        let readingAndWriting = reportsByDomains.readingAndWriting
        let math = reportsByDomains.math

        return (
            <>
                <SATReadingAndWritingAnalysisView data={readingAndWriting} />
                <SATAnalysisMathView data={math} />
            </>
        )
    }
}


//--- Helpers ---

interface ISATTestScoreDetailsRawData {
    userId: string
    emailAddress: string
    testName: string
    displayName: string
    isActive: boolean
    exam: string
    test: string
    form: string
    questions: {
        readingAnswers: any
        writingAndLanguageAnswers: any
        mathNoCalculatorAnswers: any
        mathCalculatorAnswers: any

    }
}

interface ISATReportsByDomainsAndLessons {
    readingAndWriting: IAnalysisReadingAndWriting
    math: IAnalysisMath
}

interface IAnalysisReadingAndWriting {
    craftAndStructure: IAnaysisLessonStats
    informationAndIdeas: IAnaysisLessonStats
    expressionOfIdeas: IAnaysisLessonStats
    standardEnglishConventions: IAnaysisLessonStats
}

interface IAnalysisMath {
    algebra: any
    advancedMath: any
    geometryAndTrigonometry: any
    problemSolvingAndDataAnalysis: any
}

interface IAnaysisLessonStats {
    [key:string]: IAnalysisStats
}

interface IAnalysisStats {
    lessonId: string
    lessonName: string
    star: boolean
    correct: number
    incorrect: number
    blank: number
    total: number
    accuracyReal: number
    accuracy: string
    accuracyTextRounded: string
}


function  SATReadingAndWritingAnalysisView(props: {data: IAnalysisReadingAndWriting}) {
    let {data} = props
        
    return (
        <div className="collapsable-card practicetests-domain-analysis">
            <div className="collapsable-card__header ">
                <div className="lesson-list-card-header purple">
                    <span className="ribbon purple">
                    <img alt="" src="/assets/images/icons/subjects/ico-subject-english.svg" width="28" height="27" />
                    </span>
                    <h3 className="mb-0">SAT Reading & Writing</h3>
                </div>
            </div>
            <div className="collapsable-card__body">
                <SubView
                    title="Craft and Stucture"
                    cssClass="lesson-table--english"
                    subviewLessonIds={SAT_DOMAIN_LESSONS_RW.craftAndStructure}
                    subviewData={data.craftAndStructure}
                />

                <SubView
                    title="Information And Ideas"
                    cssClass="lesson-table--english"
                    subviewLessonIds={SAT_DOMAIN_LESSONS_RW.informationAndIdeas}
                    subviewData={data.informationAndIdeas}
                />

                <SubView
                    title="Expression of Ideas"
                    cssClass="lesson-table--english"
                    subviewLessonIds={SAT_DOMAIN_LESSONS_RW.expressionOfIdeas}
                    subviewData={data.expressionOfIdeas}
                />

                <SubView
                    title="Standard English Conventions"
                    cssClass="lesson-table--english"
                    subviewLessonIds={SAT_DOMAIN_LESSONS_RW.standardEnglishConventions}
                    subviewData={data.standardEnglishConventions}
                />
            </div>
        </div>
    )
}

function  SATAnalysisMathView(props: {data: IAnalysisMath}) {
    let {data} = props

    return (
        <div className="collapsable-card practicetests-domain-analysis">
            <div className="collapsable-card__header ">
                <div className="lesson-list-card-header blue">
                    <span className="ribbon blue">
                    <img alt="" src="/assets/images/icons/subjects/ico-subject-math.svg" width="28" height="27" />
                    </span>
                    <h3 className="mb-0">SAT Math</h3>
                </div>
            </div>
            <div className="collapsable-card__body">
                <SubView
                    title="Algebra"
                    subviewLessonIds={SAT_DOMAIN_LESSONS_MATH.algebra}
                    cssClass="lesson-table--math"
                    subviewData={data.algebra}
                />

                <SubView
                    title="Advanced Math"
                    subviewLessonIds={SAT_DOMAIN_LESSONS_MATH.advancedMath}
                    cssClass="lesson-table--math"
                    subviewData={data.advancedMath}
                />

                <SubView
                    title="Geometry & Trigonometry"
                    subviewLessonIds={SAT_DOMAIN_LESSONS_MATH.geometryAndTrigonometry}
                    cssClass="lesson-table--math"
                    subviewData={data.geometryAndTrigonometry}
                />

                <SubView
                    title="Problem-Solving and Data Analysis"
                    subviewLessonIds={SAT_DOMAIN_LESSONS_MATH.problemSolvingAndDataAnalysis}
                    cssClass="lesson-table--math"
                    subviewData={data.problemSolvingAndDataAnalysis}
                />
            </div>
        </div>
    )
}

function SubView(props) {
    let {title, subviewLessonIds, subviewData, cssClass} = props
    let testData = subviewLessonIds.map(lessonId => subviewData[lessonId]).filter(d => !!d)

    return (
        <div className={`subcategory-lesson-list ${cssClass}`}>
            <h5 className="subcategory-lesson-list--title">{title}</h5>
            <TestCard 
                backgroundColor="blue-header"
                testData={testData}
                subject={title}
            />
        </div>
    )
}


function processRawData(rawdata, lessons): ISATReportsByDomainsAndLessons {
    let readingAnswers = rawdata.questions.readingAnswers
    let writingAndLanguageAnswers = rawdata.questions.writingAndLanguageAnswers
    let mathNoCalculatorAnswers = rawdata.questions.mathNoCalculatorAnswers
    let mathCalculatorAnswers = rawdata.questions.mathCalculatorAnswers
    let satReadingAndWritingAnswers = [...readingAnswers, ...writingAndLanguageAnswers]
    let satMathAnswers = [...mathNoCalculatorAnswers, ...mathCalculatorAnswers]
    let readingAndWriting = getSATReadingAndWritingInfo(satReadingAndWritingAnswers, lessons)
    let math = getSATMathInfo(satMathAnswers, lessons)

    return {
        readingAndWriting, 
        math
    }
}

function processDomainAndLessons(domainData, lessonIds, lessons): IAnaysisLessonStats {
    let map = {}
    lessonIds.forEach(lessonId => {
        let lesson = lessons.find(l => l.lessonId === lessonId)
        if (!lesson) return
        let lessonName = lesson.lessonName
        let questions = domainData.filter(q => q.lessonId.includes(lessonId))
        let correct = questions.filter(q => q.userSubmittedAnswer === q.correctAnswer).length
        let blank = questions.filter(q => q.userSubmittedAnswer === "").length
        let total = questions.length
        if (total === 0) return
        let incorrect = total - correct - blank
        let accuracyReal = total > 0 ? correct / total : -1
        let accuracyText = accuracyReal === -1 ? "N/A" : `${(accuracyReal * 100).toFixed(2)}`
        let accuracyTextRounded = accuracyReal === -1 ? "N/A" : `${Math.round(accuracyReal * 100)}`
        let star = lesson.star
        let stats = {
            lessonId,
            lessonName,
            star,
            correct,
            incorrect,
            blank,
            total,
            accuracyReal,
            accuracy: accuracyText,
            accuracyTextRounded,
        }
        map[lessonId] = { ...stats }
    })

    return map
}


//--- Reading & Writing Section ---

function getSATReadingAndWritingInfo(readingAndWritingAnswers, lessons): IAnalysisReadingAndWriting {
    return {
        craftAndStructure: getCraftAndStructure(readingAndWritingAnswers, lessons),
        informationAndIdeas: getInformationAndIdeas(readingAndWritingAnswers, lessons),
        expressionOfIdeas: getExpressionOfIdeas(readingAndWritingAnswers, lessons),
        standardEnglishConventions: getStandardEnglishConventions(readingAndWritingAnswers, lessons),
    }
}

function getCraftAndStructure(readingAndWriting, lessons) {
    let lessonIds = SAT_DOMAIN_LESSONS_RW.craftAndStructure
    return processDomainAndLessons(readingAndWriting, lessonIds,lessons)
}

function getInformationAndIdeas(readingAndWriting, lessons) {
    let lessonIds = SAT_DOMAIN_LESSONS_RW.informationAndIdeas
    return processDomainAndLessons(readingAndWriting, lessonIds, lessons)
}

function getExpressionOfIdeas(readingAndWriting, lessons) {
    let lessonIds = SAT_DOMAIN_LESSONS_RW.expressionOfIdeas
    return processDomainAndLessons(readingAndWriting, lessonIds, lessons)
}

function getStandardEnglishConventions(readingAndWriting, lessons) {
    let lessonIds = SAT_DOMAIN_LESSONS_RW.standardEnglishConventions
    return processDomainAndLessons(readingAndWriting, lessonIds, lessons)
}


//--- Math Section ---

function getSATMathInfo(satMathAnswers, lessons): IAnalysisMath {
    return {
        algebra: getAlgebra(satMathAnswers, lessons),
        advancedMath: getAdvancedMath(satMathAnswers, lessons),
        geometryAndTrigonometry: getGeometryAndTrigonometry(satMathAnswers, lessons),
        problemSolvingAndDataAnalysis: getProblemSolvingAndDataAnalysis(satMathAnswers, lessons),

    }
}

function getAlgebra(satMathAnswers, lessons) {
    let lessonIds = SAT_DOMAIN_LESSONS_MATH.algebra
    let map = processDomainAndLessons(satMathAnswers, lessonIds, lessons)
    return map
}

function getAdvancedMath(satMathAnswers, lessons) {
    let lessonIds = SAT_DOMAIN_LESSONS_MATH.advancedMath
    let map = processDomainAndLessons(satMathAnswers, lessonIds, lessons)
    return map
}

function getGeometryAndTrigonometry(satMathAnswers, lessons) {
    let lessonIds = SAT_DOMAIN_LESSONS_MATH.geometryAndTrigonometry
    let map = processDomainAndLessons(satMathAnswers, lessonIds, lessons)
    return map
}

function getProblemSolvingAndDataAnalysis(satMathAnswers, lessons) {
    let lessonIds = SAT_DOMAIN_LESSONS_MATH.problemSolvingAndDataAnalysis
    let map = processDomainAndLessons(satMathAnswers, lessonIds, lessons)
    return map
}

