import { UserInfo, getUser, login, cleverLogin, updateUserInfo, classLinkLogin } from '../services/account/account-rest-interface';
import { 
    setSessionStorageItem, 
    getSessionStorageByID, 
    deleteSessionStorageByID 
} from '../services/utils/session-storage-utility';
import { Preferences } from '../persistance';

const SET_USER_INFO = 'account/SET_USER_INFO';
const LOGIN_REQUEST = 'account/LOGIN_REQUEST';
const LOGIN_SUCCESS = 'account/LOGIN_SUCCESS';
const LOGIN_FAILURE = 'account/LOGIN_FAILURE';
const LOGOUT_REQUEST = 'account/LOGOUT_REQUEST';

export function setUserInfo(userInfo: Partial<UserInfo>) {
    return {
        type: SET_USER_INFO,
        userInfo
    };
}

export function requestLogin() {
    return {
        type: LOGIN_REQUEST,
        requestingLogin: true,
        isAuthenticated: false
    };
}

export function loginSuccess() {
    return {
        type: LOGIN_SUCCESS,
        requestingLogin: false,
        isAuthenticated: true
    };
}

export function loginError(loginError: string) {
    return {
        type: LOGIN_FAILURE,
        requestingLogin: false,
        isAuthenticated: false,
        loginError
    };
}

export function getUserInfo() {
    return dispatch => {
        return getUser()
            .then(userInfo => dispatch(setUserInfo(userInfo)));
    };
}

export function loginMaia(email: string, maiaJwt: string) {
    console.log('Logging in with Maia JWT', maiaJwt);
    return loginUser(email, '', maiaJwt);
}

export function loginUser(email: string, password: string, maiaJwt?: string) {
    return dispatch => {
        dispatch(requestLogin());

        return login(email, password, maiaJwt)
            .then(userInfo => {
                setSessionStorageItem('x-win-token', userInfo.winToken || '');
                return Promise.all([
                    dispatch(loginSuccess()),
                    dispatch(setUserInfo(userInfo))
                ]);
            })
            .catch(err => {
                dispatch(loginError(err));
                return Promise.reject(err);
            });
    };
}

export function loginCleverUser(cleverCode: string, host: string) {
    return dispatch => {
        dispatch(requestLogin());

        return cleverLogin(cleverCode, host)
            .then(userInfo => {
                let {winToken, emailAddress } = userInfo
                setSessionStorageItem('x-win-token', winToken || '');
                setSessionStorageItem('x-win-user', emailAddress || '');
                return Promise.all([
                    dispatch(loginSuccess()),
                    dispatch(setUserInfo(userInfo))
                ]);
            })
            .catch(err => {
                dispatch(loginError(err));
                return Promise.reject(err);
            });
    };
}

export function loginClassLinkUser(code: string, host: string) {
    return dispatch => {
        dispatch(requestLogin());

        return classLinkLogin(code, host)
            .then(userInfo => {
                let {winToken, emailAddress } = userInfo
                setSessionStorageItem('x-win-token', winToken || '');
                setSessionStorageItem('x-win-user', emailAddress || '');
                return Promise.all([
                    dispatch(loginSuccess()),
                    dispatch(setUserInfo(userInfo))
                ]);
            })
            .catch(err => {
                dispatch(loginError(err));
                return Promise.reject(err);
            });
    };
}

export function updateUser(id: string, info: Partial<UserInfo>) {

    return dispatch => {
        return updateUserInfo(id, info)
            .then(userInfo => dispatch(setUserInfo(info)));
    };
}
export function logoutRequest(username: string) {
    return {
        type: LOGOUT_REQUEST,
        isAuthenticated: false
    };
}

export function logoutUser(username: string) {
    return dispatch => {
        deleteSessionStorageByID('x-win-token');
        deleteSessionStorageByID('x-win-user');
        return dispatch(logoutRequest(username));
    };
}

export interface AccountState {
    requestingLogin: boolean;
    isAuthenticated: boolean;
    loginError: string;
    userInfo: Partial<UserInfo>;
}

const defaultState = {
    isAuthenticated: !!getSessionStorageByID('x-win-token'),
    userInfo: {}
};

export default function(state: Partial<AccountState> = defaultState, action: any) {
    switch(action.type) {
        case LOGIN_REQUEST: {
            const { requestingLogin } = action;
            return {...state, requestingLogin };
        }
        case LOGIN_SUCCESS: {
            const { requestingLogin, isAuthenticated } = action;
            return {...state, requestingLogin, isAuthenticated, loginError: '' };
        }
        case LOGIN_FAILURE: {
            const { requestingLogin, isAuthenticated, loginError } = action;
            return {...state, requestingLogin, isAuthenticated, loginError };
        }
        case LOGOUT_REQUEST: {
            const { isAuthenticated } = action;
            return {...state, isAuthenticated, userInfo: {} };
        }
        case SET_USER_INFO: {
            let userInfo  = {
                ...state.userInfo,
                ...action.userInfo
            };

            // Set the userInfo to extract user's preferences when needed
            Preferences.setUserInfo(userInfo)

            return { ...state, userInfo };
        }
        default:
            return state;
    }
}
