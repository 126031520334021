import './AccountSettings.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';

import { PageContent, PageTitle } from '../../components/PageContent/PageContent';

import AccountInfo from './components/AccountInfo/AccountInfoContainer';

interface Props {
}

interface State {
}


export default class AccountSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    public render() {

        return (
            <PageContent>
                <PageTitle title="Account Settings" />
                <div className="account-settings">
                    <AccountInfo />
                </div>
                {/* <div className="divider"/> */}
            </PageContent>
        );
    }
}
