import './Modal.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';

import Portal from '../Portal/Portal';

interface Props {
    className?: string;
    show?: boolean;
    onClose: () => void;
    width?: string;
    showXTopRight?: boolean;
    showXinside?: boolean;
}

interface State {

}

// Function to toggle keyboard accessibility
function toggleKeyboardAccessibility(selector, disable) {
    const element = document.querySelector(selector);

    if (element) {
        const focusableElements = element.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]'
        );

        focusableElements.forEach((child) => {
            if (disable) {
                if (!child.hasAttribute('data-original-tabindex')) {
                    const originalTabIndex = child.getAttribute('tabindex') !== null ? child.getAttribute('tabindex')! : '0';
                    child.setAttribute('data-original-tabindex', originalTabIndex);
                }
                child.setAttribute('tabindex', '-1');
            } else {
                const originalTabIndex = child.getAttribute('data-original-tabindex');
                if (originalTabIndex !== null) {
                    child.setAttribute('tabindex', originalTabIndex);
                    child.removeAttribute('data-original-tabindex');
                }
            }
        });
    }
}


// TODO flesh this out so it is customizable
export default class Modal extends React.PureComponent<Props, State> {
    private lastFocusedElement: Element | null = null;
    private triggeringElement: HTMLElement | null = null; // Store the button that opened the modal
    // Create a ref for the modal element
    private modalRef = React.createRef<HTMLDivElement>();

    public static defaultProps: Partial<Props> = {
        width: '800px'
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('mousedown', this.handleMouseDown); // Capture mouse clicks
        document.addEventListener('focusin', this.handleFocusIn); // Add a listener for focus changes
        setTimeout(() => {
            this.setFocusToFirstItem();
        }, 0);
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.show && this.props.show) {
            // Store the current focus before modal opens
            toggleKeyboardAccessibility('.winward-academy-container', true); // Disable keyboard access

            this.lastFocusedElement = document.activeElement;
            // Capture the triggering element
            this.triggeringElement = this.lastFocusedElement as HTMLElement;
            // Store the triggering element
            setTimeout(() => {
                this.setFocusToFirstItem();
            }, 0);

        } else if (prevProps.show && !this.props.show) {
            // When the modal is closed, restore focus to the last focused element
            if (this.triggeringElement instanceof HTMLElement) {
                this.triggeringElement.focus();
            }
            toggleKeyboardAccessibility('.winward-academy-container', false);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('mousedown', this.handleMouseDown); // Remove the listener
        document.removeEventListener('focusin', this.handleFocusIn); // Remove the listener
        if (this.triggeringElement instanceof HTMLElement) {
            this.triggeringElement.focus(); // Restore focus to the button that opened the modal
        }
        toggleKeyboardAccessibility('.winward-academy-container', false); // Re-enable keyboard access
    }

    handleMouseDown = (event: MouseEvent) => {
        // Capture the element that was clicked
        this.lastFocusedElement = event.target as Element;
    };

    handleFocusIn = (event: FocusEvent) => {
        // Capture the focused element
        this.lastFocusedElement = event.target as Element;
    };

    setFocusToFirstItem = () => {
        const focusableElements = this.modalRef.current?.querySelectorAll<HTMLElement>('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        if (focusableElements && focusableElements.length > 0) {
            setTimeout(() => focusableElements[0].focus(), 0);
        }
    };

    handleKeyDown = (event: KeyboardEvent) => {
        if (this.props.show && event.key === 'Tab') {
            // Implement focus trapping
            // ... focus trapping logic
        }
    };

    constructor(props: Props) {
        super(props);
    }

    // public componentDidUpdate(prevProps: Props) {
    //     const root = document.getElementById('root');
    //     if(!root) { return; }

    //     if(this.props.show) {
    //         const backdrops = document.getElementsByClassName('modal-backdrop');
    //         if(backdrops.length > 0) {
    //             return;
    //         }
    //         const backdrop = document.createElement('div');
    //         backdrop.classList.add('modal-backdrop', 'fade', 'show');
    //         root.appendChild(backdrop);
    //     } else {
    //         const backdrops = document.getElementsByClassName('modal-backdrop');
    //         for(let i = 0; i < backdrops.length; i++) {
    //             root.removeChild(backdrops[i]);
    //         }
    //     }
    // }

    // public componentWillUnmount() {
    //     const root = document.getElementById('root');
    //     if(!root) { return; }

    //     const backdrops = document.getElementsByClassName('modal-backdrop');
    //     for(let i = 0; i < backdrops.length; i++) {
    //         root.removeChild(backdrops[i]);
    //     }
    // }

    // public componentDidMount() {
    //     const root = document.getElementById('root');
    //     if(!root) { return; }

    //     if(this.props.show) {
    //         const backdrops = document.getElementsByClassName('modal-backdrop');
    //         if(backdrops.length > 0) {
    //             return;
    //         }
    //         const backdrop = document.createElement('div');
    //         backdrop.classList.add('modal-backdrop', 'fade', 'show');
    //         root.appendChild(backdrop);
    //     }
    // }

    public render() {
        const modalClass = this.props.className || '';
        const showClass = this.props.show ? 'show d-block' : '';
        // TODO set this depending on if we have a title so content can be centered
        const emptyHeader = 'modal--empty-header';
        const tabIval = this.props.show ? 0 : -1;
        return (
            <Portal>
                <div>
            <div
                ref={this.modalRef}
                className={`modal fade ${showClass}`}
                id="modal"
                onClick={this.props.onClose}
                role="dialog"
                aria-labelledby="modalLabel"
            >
            {this.props.showXTopRight &&
              <button className="closeBtn btn-simple" title="Close modal" tabIndex={tabIval}>
                <img
                    className="modal-icon"
                    src={`/assets/images/icons/v2/ico-modal-close-white.svg`}
                    width="24"
                    height="24"
                    alt=""
                />
              </button>
            }

                  <div className="vertical-align-helper">
                    <div className="modal-dialog vertical-align-center modal-center" role="document">

                        <div
                            className={`modal-content ${modalClass}`}
                            onClick={this.preventClose}
                            style={{ maxWidth: this.props.width}}
                        >

                            {/* Header */}
                            <div className={`modal-header ${emptyHeader}`}>

                                <button type="button" className="close" title="Close Modal" aria-label="Close" onClick={this.props.onClose}>
                                    {/*<h5 className="modal-title" id="modalLabel">Modal Title</h5>*/}
                                    {this.props.showXinside ?
                                        <img className="modal-icon" src="/assets/images/icons/v2/ico-modal-close-white.svg" width="24" height="24" alt="" />
                                        :
                                        <span aria-hidden="true">&times;</span>
                                    }
                                </button>
                            </div>

                            {/* Body */}
                            <div className="modal-body">
                                {this.props.children}
                            </div>

                            {/* Footer */}
                            {/*<div className="modal-footer">

                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
            {this.props.show
                && <div className="modal-backdrop fade show"/>
            }
            </div>
            </Portal>
        );
    }

    
    private preventClose(e: any) {
        e.stopPropagation();
    }
}
