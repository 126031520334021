import './PTGrowthAnalytics.css';

import * as React from 'react';

import { getTestTypeDisplayName } from '../../services/utils/practice-test-util';
import { Link } from 'react-router-dom';
import { PageContent, PageTitle } from '../../components/PageContent/PageContent';
import BackToTestsButton from './components/BackToTestsButton/BackToTestsButton';
import { getPTHighestScores } from '../../services/practice-test-scoring/practice-test-service';
import { getSATPTHighestScores } from '../../services/practice-test-scoring/practice-test-service';
import IAHighestScore from '../InterimAssessment/components/IAHighestScore/IAHighestScore';
import IASummaryCard from '../InterimAssessment/components/IASummaryCard/IASummaryCard';
import IALineGraph from '../InterimAssessment/components/IALineGraph/IALineGraph';

interface Props {
}

interface State {
    highestComposite: any;
    highestEnglish: any;
    highestMath: any;
    highestReading: any;
    highestScience: any;
    highestEvidenceBasedReadingAndWriting: any;
    lineGraphData: string;
    summaryTestName: string;
}

export class PTGrowthAnalytics extends React.Component<Props, State> {
    constructor (props: Props) {
        super(props);
        this.state = {
            highestComposite: {},
            highestEnglish: {},
            highestMath: {},
            highestReading: {},
            highestScience: {},
            highestEvidenceBasedReadingAndWriting: {},
            lineGraphData: 'compositeCoordinates',
            summaryTestName: 'IA 1'
        };
    }

    componentDidMount() {
        let testType = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
        if(!testType.includes('sat')) {
            getPTHighestScores().then((res) => {
                this.setState({
                    highestComposite: res[0],
                    highestEnglish: res[1],
                    highestMath: res[2],
                    highestReading: res[3],
                    highestScience: res[4],
                });
            });
        } else {
            getSATPTHighestScores().then((res) => {
                this.setState({
                    highestComposite: res[0],
                    highestMath: res[1],
                    highestEvidenceBasedReadingAndWriting: res[2]
                });
            });
        }
    }

    public render() {
        const { lineGraphData } = this.state;
        let testType = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);

        const testAbbrev = testType.split('=')[1].toLowerCase();
        let testTypeDisplayName = getTestTypeDisplayName(testAbbrev);

        if (this.state.highestComposite === []) {
            return null;
        }

        if(!testType.includes('sat')) {
            return (
                <PageContent className="ia-dashboard pt-growth-analytics">
                    <PageTitle title={`${'ACT'} Growth Analytics`}>
                        <div className="navigation-button-container">
                            <BackToTestsButton
                              targetUri="/app/practice-tests"
                              testType={testAbbrev}
                            />
                        </div>
                    </PageTitle>
                    <div className="row mb-3">
                        <div className="col-lg-7 col-xl-8">
                            <div className="white-background graph-container">
                                <div>
                                    <h3>Score Summary</h3>
                                </div>
                                <div className="subject-buttons">
                                        <button
                                            className={lineGraphData === 'compositeCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                            onClick={() => this.updateGraph('compositeCoordinates')}
                                        >Composite
                                        </button>
                                        <button
                                            className={lineGraphData === 'englishCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                            onClick={() => this.updateGraph('englishCoordinates')}
                                        >English
                                        </button>
                                        <button
                                            className={lineGraphData === 'mathCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                            onClick={() => this.updateGraph('mathCoordinates')}
                                        >Math
                                        </button>
                                        <button
                                            className={lineGraphData === 'readingCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                            onClick={() => this.updateGraph('readingCoordinates')}
                                        >Reading
                                        </button>
                                        <button
                                            className={lineGraphData === 'scienceCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                            onClick={() => this.updateGraph('scienceCoordinates')}
                                        >Science
                                        </button>
                                </div>
                                <IALineGraph
                                subject={this.state.lineGraphData}
                                updateSummary={this.updateSummaryCard}
                                testType="practice-tests"
                                />
                            </div>
                        </div>
                        <div className="pl-lg-0 col-lg-5 col-xl-4">
                            <div className="summary-card-container">
                                <div className="test-summary-card">
                                    <IASummaryCard testType="practice-tests" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="white-background highest-scores-container">
                                <div>
                                    <h3>Highest Scores</h3>
                                </div>
                                <div className="row highest-scores-card-container">
                                    <div className="col-6 col-lg mb-3">
                                        <IAHighestScore
                                            score={this.state.highestComposite.score}
                                            subject={this.state.highestComposite.category}
                                            test={this.state.highestComposite.testName}
                                            startColor={'#6A5CC7'}
                                            endColor={'#50c3f1'}
                                            maxPossibleScore={36}
                                        />
                                    </div>
                                    <div className="col-6 col-lg mb-3">
                                        <IAHighestScore
                                            score={this.state.highestEnglish.score}
                                            subject={this.state.highestEnglish.category}
                                            test={this.state.highestEnglish.testName}
                                            startColor={'#9d59cf'}
                                            endColor={'#9d59cf'}
                                            maxPossibleScore={36}
                                        />
                                    </div>
                                    <div className="col-6 col-lg mb-3">
                                        <IAHighestScore
                                            score={this.state.highestMath.score}
                                            subject={this.state.highestMath.category}
                                            test={this.state.highestMath.testName}
                                            startColor={'#37c3db'}
                                            endColor={'#37c3db'}
                                            maxPossibleScore={36}
                                        />
                                    </div>
                                    <div className="col-6 col-lg mb-3">
                                        <IAHighestScore
                                            score={this.state.highestReading.score}
                                            subject={this.state.highestReading.category}
                                            test={this.state.highestReading.testName}
                                            startColor={'#f5a000'}
                                            endColor={'#f5a000'}
                                            maxPossibleScore={36}
                                        />
                                    </div>
                                    <div className="col-6 col-lg mb-3">
                                        <IAHighestScore
                                            score={this.state.highestScience.score}
                                            subject={this.state.highestScience.category}
                                            test={this.state.highestScience.testName}
                                            startColor={'#88c02b'}
                                            endColor={'#88c02b'}
                                            maxPossibleScore={36}
                                        />
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </PageContent>
            );
        } else {
            return (
                <PageContent className="ia-dashboard pt-growth-analytics">
                    <PageTitle title={`${'SAT'} Growth Analytics`}>
                        <div className="navigation-button-container">
                            <BackToTestsButton
                              targetUri="/app/practice-tests?testType=sat"
                              testType={testAbbrev}
                            />
                        </div>
                    </PageTitle>
                    <div className="row mb-3">
                        <div className="col-lg-8">
                            <div className="white-background graph-container">
                                <div>
                                    <h3>Score Summary</h3>
                                </div>
                                <div className="subject-buttons">
                                        <button
                                            className={lineGraphData === 'compositeCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                            onClick={() => this.updateGraph('compositeCoordinates')}
                                        >Composite
                                        </button>
                                        <button
                                            className={lineGraphData === 'evidenceBasedRWCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                            onClick={() => this.updateGraph('evidenceBasedRWCoordinates')}
                                        >R&amp;W
                                        </button>
                                        <button
                                            className={lineGraphData === 'mathCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                            onClick={() => this.updateGraph('mathCoordinates')}
                                        >Math
                                        </button>
                                </div>
                                <IALineGraph
                                subject={this.state.lineGraphData}
                                updateSummary={this.updateSummaryCard}
                                testType="practice-tests"
                                />
                            </div>
                        </div>
                        <div className="pl-lg-0 col-lg-4">
                            <div className="summary-card-container">
                                <div className="test-summary-card">
                                    <IASummaryCard testType="practice-tests" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="white-background highest-scores-container">
                                <div>
                                    <h3>Highest Scores</h3>
                                </div>
                                <div className="row highest-scores-card-container">
                                    <div className="col">
                                        <IAHighestScore
                                            score={this.state.highestComposite.score}
                                            subject={this.state.highestComposite.category}
                                            test={this.state.highestComposite.testName}
                                            startColor={'#6A5CC7'}
                                            endColor={'#50c3f1'}
                                            maxPossibleScore={1600}
                                        />
                                    </div>
                                    <div className="col">
                                        <IAHighestScore
                                            score={this.state.highestEvidenceBasedReadingAndWriting.score}
                                            subject="R&W"
                                            test={this.state.highestEvidenceBasedReadingAndWriting.testName}
                                            startColor={'#9d59cf'}
                                            endColor={'#9d59cf'}
                                            maxPossibleScore={800}
                                        />
                                    </div>
                                    <div className="col">
                                        <IAHighestScore
                                            score={this.state.highestMath.score}
                                            subject={this.state.highestMath.category}
                                            test={this.state.highestMath.testName}
                                            startColor={'#37c3db'}
                                            endColor={'#37c3db'}
                                            maxPossibleScore={800}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageContent>
            );
        }
    }

    private updateGraph(subjectName: string) {
        this.setState({
            lineGraphData: subjectName
        });
    }

    private updateSummaryCard(testName: string) {
        this.setState({
            summaryTestName: testName
        });
    }
}
