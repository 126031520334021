import React, { ReactElement } from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
    container?: HTMLElement;
}

/**
 * Utility class used to render components directly onto the document body
 */
export default class Portal extends React.Component<Props, {}> {
    public static defaultProps = {
        container: document.getElementById('root')
    };

    private content: HTMLElement | undefined;

    public componentDidMount() {
        if(!this.props.container) { return; }

        this.content = document.createElement('div');
        this.props.container.appendChild(this.content);
        this.renderContent();
    }

    public componentDidUpdate() {
        this.renderContent();
    }

    public componentWillUnmount() {
        if (this.content) {
          ReactDOM.unmountComponentAtNode(this.content);
          if(this.props.container && this.content) {
              this.props.container.removeChild(this.content);
          }
        }
    }

    public render() {
        return null;
    }

    private renderContent() {
        if(this.props.children && this.content) {
            const OnlyChildren = React.Children.only(
                this.props.children
            );
            ReactDOM.render(
                OnlyChildren as ReactElement,
                this.content
            );
        }
    }
}
