import './ExpiredAccountDialog.css';

import * as React from 'react';
import * as Script from 'react-load-script';

import BundleCardControls from '../../../SignUp/components/BundleCardControls/BundleCardControls';
import Button from '../../../../components/Button/Button';
import PaymentProcessingModal from '../../../../components/PaymentProcessingModal';
import SignUpError from '../../../SignUp/SignUpError';
import { activateSubscription } from '../../../../services/account/account-rest-interface';
import { getStripeClientKey } from '../../../../services/utils/winward-stripe';
import bundleInfo from '../../../SignUp/components/BundleCardControls/BundleCardInfo';

let buttonColor = 'blue';
let textColor = 'white';
let widthBtn = 'tc-modal-btn';

function handleStripeLoad() {
  // console.log('Stripe loaded');
}

interface Props {
  history: any;
  titleText?: string;
  unlockAccount?: boolean;
  closeModal?: () => void;
  freeTrial?: boolean;
}

interface State {
  bundleArray: any[];
  reactivateBtnEnabled: boolean;
  selectedBundleId: string;
  showPaymentProcessingModal: boolean;
  includeCollegeKnowledge: boolean;
  newPrice: number;
  serverMessage: string;
}

function handleStripeCreate() {
    // console.log('created');
}

function handleStripeError(e: any) {
    console.log('Problem loading Stripe:', e);
}

export default class ExpiredAccountDialog extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
          bundleArray: bundleInfo,
          reactivateBtnEnabled: false,
          selectedBundleId: '',
          showPaymentProcessingModal: false,
          includeCollegeKnowledge: false,
          newPrice: 0,
          serverMessage: ''
        };
    }

    render() {
        let titleText = this.props.freeTrial ? 'Your Trial Has Expired' : 'Your Access Has Expired';
        let content = `We hope you’ve enjoyed exploring our lessons, 
        multiple-choice questions, and practice exams. 
        Please see the flexible pricing options to reactivate your 
        account and continue learning for another year.`;
             
        let buttonText = 'UPGRADE MEMBERSHIP';
        const blurClass = this.state.showPaymentProcessingModal ? 
          'expiration-dialog-blur' : '';
        return (
        <div className={`row text-center ${blurClass}`}>
            <PaymentProcessingModal
                showModal={this.state.showPaymentProcessingModal}
            />
            {this.props.unlockAccount &&
                <div className="col-lg-12 d-flex flex-row justify-content-center align-items-center position-relative">
                    <h1 className="cushion-element-home d-flex">{titleText}</h1>
                    <span className="position-absolute x-modal-close-btn" onClick={this.props.closeModal}>X</span>
                </div>
            }
            {!this.props.unlockAccount &&
                <div className="col-lg-12">
                    <h1 className="cushion-element-home">{titleText}</h1>
                </div>
            }
            
            <div className="body-home container-fluid coverBottom-home">
                <div className="cushion-heading-home">
                    <h2 className="dark-blue-text-home">Don't worry! Your data have been saved.</h2>
                </div>
                
                <p className="expiration-dialog-content black-text-home text-centered upgrade-membership-text">
                    {content}
                </p>
                <p>
                <BundleCardControls
                    bundleTypes={this.state.bundleArray}
                    onBundleSelected={(id, includeCollegeKnowledge, newPrice) => {
                        this.setState({ 
                            reactivateBtnEnabled: (id === '' ? false : true),
                            selectedBundleId: id,
                            includeCollegeKnowledge: includeCollegeKnowledge,
                            newPrice: includeCollegeKnowledge ? newPrice : 0,
                        });
                    }}
                    preSelectedBundleId={this.state.selectedBundleId}
                    darkPrint={false}
                />
                </p>
                <SignUpError text={this.state.serverMessage} /> 
                <Button
                    text={buttonText}
                    textColor={textColor as any}
                    bgColor={buttonColor as any}
                    className={widthBtn as any}
                    onClick={() => this.openStripeDialog(this.props.history)}
                    disabled={!this.state.reactivateBtnEnabled}
                />
                <Script
                    url="https://checkout.stripe.com/checkout.js"
                    onCreate={handleStripeCreate}
                    onError={handleStripeError}
                    onLoad={handleStripeLoad}
                />
            </div>
        </div>
        );
    }

    private openStripeDialog(history: any) {
        this.setState({
            serverMessage: ''
        });
        const selectedBundleId = this.state.selectedBundleId;
        const selectedBundle = this.state.bundleArray.find(
            b => b.bundleId === this.state.selectedBundleId);
        const schoolSafetyAdditional = false;
        const self = this;
        const handler = StripeCheckout.configure({
            key: getStripeClientKey(),
            image: '/assets/images/winward-stripe-logo.png',
            locale: 'auto',
            zipCode: true,
            billingAddress: true,
            token: function(token: any) {
                self.setState({ showPaymentProcessingModal: true });
                activateSubscription({ token: token.id, email: token.email }, 
                    selectedBundle.backEndBundleId, 
                    selectedBundle.includeCK, 
                    schoolSafetyAdditional,
                    selectedBundle.includePersonalCoach,
                    selectedBundle.includeStudyPlan)
                  .then(() => {
                      console.log('Reactivation success');
                      history.push('/');
                  }).catch(e => {
                    console.log('Server err while registering user', { e });
                    self.setState({
                        showPaymentProcessingModal : false,
                        serverMessage: e.message
                    });
                });
            }
        });

        if (selectedBundle !== undefined) {
            const ckSuffix = selectedBundle.bundleName;
            const { newPrice } = this.state;
            let cost = newPrice > 0 ? newPrice : selectedBundle.price;
            handler.open({
                name: 'Winward Academy',
                description: `${selectedBundle.bundleName}`,
                amount: cost, // amount in cents
                closed: function() { console.log('closed stripe modal'); }
            });
        }

    }
}
