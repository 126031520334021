import './StaySharpTabs.css';

import * as React from 'react';

import { CustomHeaderContent } from '../../../../components/Tabs/Tabs';
import { StaySharpView } from '../StaySharpViewSwitch/StaySharpViewSwitch';
import StaySharpViewSwitch from '../StaySharpViewSwitch/StaySharpViewSwitch';
import Tabs from '../../../../components/Tabs/Tabs';

interface Props {
    onViewChange: (view: StaySharpView) => void;
    selectedView: StaySharpView;
    openEnglishModal: () => void;
    openMathModal: () => void;
}

const StaySharpTabs: React.SFC<Props> = (props) => {
    return (
            
        <Tabs>
            {props.children}
            <CustomHeaderContent>
                <StaySharpViewSwitch 
                    selectedView={props.selectedView} 
                    onViewChange={props.onViewChange} 
                    openEnglishModal={props.openEnglishModal} 
                    openMathModal={props.openMathModal} 
                />
            </CustomHeaderContent>
        </Tabs>
    );
};

StaySharpTabs.displayName = 'StaySharpTabs';

export default StaySharpTabs;
