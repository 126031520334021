const collegeKnowledgeData = {
    gameChanger: { 
        message: 'Save 50% off Game Changer when you bundle with College Knowledge ($749.99 for both)',
        price: 74999
    },
    actCrunchTime: {
        message: 'Save 50% off ACT Crunch Time when you bundle with College Knowledge ($624.99 for both)',
        price: 62499
    },
    satCrunchTime: { 
        message: 'Save 50% off SAT Crunch Time when you bundle with College Knowledge ($624.99 for both)',
        price: 62499
    },
    mathOnly: { 
        message: 'Save 50% off Math Essentials when you bundle with College Knowledge ($649.99 for both)',
        price: 64999
    },
};

export default collegeKnowledgeData;
