import * as React from 'react';
import { connect } from 'react-redux';
import { Maia } from './';
import { loginMaia } from '../../stores/account.store';

const mapStateToProps = () => ({
    // requestingLogin: account.requestingLogin
});

const mapDispatchToProps = {
    loginMaia
};

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(Maia);
