import React from 'react'

import { randomlyChooseFromQuotes } from './helpers'
import "./index.css"

export default function InspirationalQuote() {
  let {category, author, title, text} = randomlyChooseFromQuotes()

  let helperString = "";
  let helperColor = "";
  
  switch (category) {
    case 'Engagement':
      helperString = "engage";
      helperColor = '#1AC0D3';
      break;
    case 'Education':
      helperString = "educate";
      helperColor = '#6D5BC6';
      break;
    case 'Gratitude':
      helperString = "gratitude";
      helperColor = '#F7474F';
      break;
    case 'Values':
      helperString = "value";
      helperColor = '#18AB70';
      break;
    case 'Perseverance':
      helperString = "persevere";
      helperColor = '#8E3E9F';
      break;
    case 'Learn from Mistakes':
      helperString = "learn";
      helperColor = '#0E5682';
      break;
    default:
      helperString = "engage";
      helperColor = '#1AC0D3';
      break;
  }

  return (
    <div className={`inspirational-quote-container iqc-image--` + helperString}>
      <div className="row icq-row">
        <div className="col iqc-col--image">
          <div className="iqc-image" title={category}>
            <img alt="" src="/assets/images/icons/v2/inspo/ico-double-quote.svg" width="116" height="75" className="iqc-image--quote" />
            <img alt="" src={`/assets/images/icons/v2/inspo/ico-outline-` + helperString + `.svg`} width="121" height="auto" className="iqc-image--cat" />
          </div>
        </div>
        <div className="iqc-col--content pt-xl-4">
        <div className="px-3 pr-sm-0">
            <div className="iqc-wrapper">
              <blockquote>
                "{text}"
              </blockquote>
              <hr className="dotted" style={{ borderTopColor: helperColor}} />
              <cite>
                {author}
              </cite>
              <div className="cite-title pb-3">{title}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


