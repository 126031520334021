import React from 'react';

import CleverLogin from './CleverLogin';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loginCleverUser } from '../../stores/account.store';

const mapStateToProps = ({ account }: AppState) => ({
    requestingLogin: account.requestingLogin
});

const mapDispatchToProps = { loginCleverUser };

const ConnectedLogin = connect<any, any, any, AppState>(mapStateToProps, mapDispatchToProps)(CleverLogin);

export default withRouter(ConnectedLogin);
