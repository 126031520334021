import React, { createRef } from 'react';

interface Props {
    size: number;
    radius: number;
    progress: number;
    startColor: string;
    endColor: string;
    pegOffset: number; // This is probably fn of the radius
    backgroundColor?: string;
    progressLineWidth?: number;
    backgroundLineWidth?: number;
    text?: string;
    textStyle?: React.CSSProperties;
    cssClass?:string;
}

export default class RadialProgress extends React.Component<Props, {}> {
    public static defaultProps = {
        text: '',
        textStyle: {}
    };

    private canvas = createRef<HTMLCanvasElement>();

    public componentDidMount() {
        this.renderCanvas();
    }

    public componentDidUpdate() {
        this.renderCanvas();
    }

    public render() {
        const processedText = this.props.text?.replace(/%/g, '<span class="percent">%</span>') || '';
        return (
            <div
                className={`noselect ${this.props.cssClass ? `${this.props.cssClass}` : ''}`}
                style={{
                    width: this.props.size + 'px',
                    height: this.props.size + 'px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                }}
            >
                <canvas ref={this.canvas} width={this.props.size} height={this.props.size}/>
                <span
                    style={{
                        position: 'absolute',
                        color: '#2b2d2d',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        margin: 'auto',
                        width: '100%',
                        textAlign: 'center',
                        lineHeight: this.props.size + 'px',
                        ...this.props.textStyle
                    }}
                    dangerouslySetInnerHTML={{ __html: processedText }} />
            </div>
        );
    }

    private renderCanvas() {
        const currentNode = this.canvas.current;
        if(!currentNode) { return; }

        const ctx = currentNode.getContext('2d');

        if(!ctx) { return; }

        ctx.clearRect(0, 0, this.props.size, this.props.size);

        const progress = this.props.progress >= 0 ? Math.min(this.props.progress, 100) : 0;
        const x = this.props.size / 2;
        const y = this.props.size / 2;
        const radius = this.props.radius;
            // 0deg   - 1.5 * Pi,
            // 90deg  - 0   * Pi,
            // 180deg - 0.5 * Pi,
            // 270deg - 1   * Pi

        const angleStart = 1.5 * Math.PI;
        // const angleEnd = 1.499 * Math.PI;
        let arc1End = 0;
        let arc2End = 0;
        if(progress <= 50) {
            arc1End = (progress - 25) / 100 * 360 * Math.PI / 180;
        } else {
            arc1End = 0.5 * Math.PI;
            arc2End = (progress - 25) / 100 * 360 * Math.PI / 180;
        }

        // Create gradient
        const midColor = this.findColor(0.45); // tweak this to get a good blend
        var gradient = ctx.createLinearGradient(0, 0, 0, this.props.size);
        gradient.addColorStop(0,  this.props.startColor);
        gradient.addColorStop(0.7, midColor);
        var gradient2 = ctx.createLinearGradient(0, this.props.size, 0, 0);
        gradient2.addColorStop(0.3, midColor);
        gradient2.addColorStop(1, this.props.endColor);

        // Draw circle
        ctx.beginPath();
        ctx.arc(x, x, radius, 0, 2 * Math.PI, false);
        ctx.lineWidth = this.props.progressLineWidth || 20;
        ctx.strokeStyle = this.props.backgroundColor || 'rgba(128, 128, 128, 0.2)';
        ctx.stroke();

        // Draw arc
        if(progress > 0) {
            ctx.beginPath();
            ctx.arc(x, y, radius, angleStart, arc1End);
            ctx.strokeStyle = gradient;
            ctx.lineWidth = this.props.backgroundLineWidth || 20;
            ctx.lineCap = 'round';
            ctx.stroke();
        }

        if(progress > 50) {
            ctx.beginPath();
            ctx.arc(x, y, radius,  0.5 * Math.PI, arc2End);
            ctx.strokeStyle = gradient2;
            ctx.lineWidth = this.props.backgroundLineWidth || 20;
            ctx.lineCap = 'round';
            ctx.stroke();
        }
        // Draw peg
        // peg removed @ MJ
        // if(progress > 0) {
        //     ctx.beginPath();
        //     ctx.arc(x, this.props.pegOffset, ((this.props.progressLineWidth || 10) / 2) - 1, 2 * Math.PI, 0);
        //     ctx.fillStyle = this.props.endColor;
        //     ctx.fill();
        // }

    }

    private findColor(ratio: number) {
        const c1 = this.props.startColor.replace('#', '');
        const c2 = this.props.endColor.replace('#', '');
        const r = Math.ceil(parseInt(c1.substring(0, 2), 16) * ratio + parseInt(c2.substring(0, 2), 16) * (1 - ratio));
        const g = Math.ceil(parseInt(c1.substring(2, 4), 16) * ratio + parseInt(c2.substring(2, 4), 16) * (1 - ratio));
        const b = Math.ceil(parseInt(c1.substring(4, 6), 16) * ratio + parseInt(c2.substring(4, 6), 16) * (1 - ratio));
        const color = '#' + this.toHex(r) + this.toHex(g) + this.toHex(b);
        return color;
    }

    private toHex(val: number) {
        const hex = val.toString(16);
        return (hex.length === 1) ? '0' + hex : hex; // pad with 0
    }
}
