import './CloseIcon.css';

import * as React from 'react';

interface Props {
    size?: string;
    color?: string;
}

const CloseIcon: React.SFC<Props> = (props) => {
    const styles = {
        width: props.size,
        height: props.size,
        color: props.color
    };

    return (
        <span className="close-icon" style={{...styles}} />
    );
};

CloseIcon.defaultProps = { size: '16px' };
CloseIcon.displayName = 'CloseIcon';

export default CloseIcon;
