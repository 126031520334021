import React, { Component } from "react"
import { PageContent, PageTitle } from "../../../components/PageContent/PageContent"
import { Link, RouteComponentProps } from "react-router-dom"
import { UserInfo } from "../../../services/account/account-rest-interface"
import ResilienceRemindersMarketingView from "./ResilienceRemindersMarketingView"
import Modal from "../../../components/Modal/Modal"
import BrightcoveVideo from "../../../components/BrightcoveVideo/BrightcoveVideo"
import { IResilienceReminderWeeklyContent } from "./types"
import ResilienceRemindersMethodInfoView from "./ResilienceRemindersMethodInfoView"
import ResilienceRemindersResourcesView from "./ResilienceRemindersResourceView"
import ResilienceRemindersGradesView from "./ResilienceRemindersGradesView"
import ResilienceReminderWeeklyContentView from "./ResilienceRemindersWeeklyContentView"
import { hasLessons, hasSchoolSafety, hasCollegeKnowledge, hasResilienceReminders } from "../../../services/account/account-utils"
import { getResilienceRemindersInfo } from "./rr-info"
import "./index.css"

declare const videojs // Brightcove video

interface Props extends RouteComponentProps<any> { }

interface State {
    showModal: boolean
    videoId: string
    weeklyContent?: IResilienceReminderWeeklyContent
    showMacDermottMethodModal: boolean
}

interface StoreProps {
    userInfo: UserInfo
}

type AllProps = Props & StoreProps

export class ResilienceReminders extends Component<AllProps, State> {
    static instance: ResilienceReminders

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            videoId: "",
            showMacDermottMethodModal: false           
        }

        ResilienceReminders.instance = this
    }

    handleVideoClick = (videoId: string) => {
        ResilienceReminders.instance.setState({showModal: true, videoId})
    }

    static ResourceVideoLink = (props: {name: string, videoId: string}) => {
        let {videoId, name} = props

        function handleVideoClick(e) {
            e.preventDefault()
            ResilienceReminders.instance.handleVideoClick(videoId)
        }

        return (
            <button
                className="d-block text-center btn-simple mx-auto"
                onClick={(e) => handleVideoClick(e)}
                title={`Play video: ${name}`}
            >
                <img alt="Play icon" width="20" height="20" src="/assets/images/icons/v2/ico-play.svg" />
            </button>
        )
    }

    static WeeklyContentLink = (props: {weeklyContent: IResilienceReminderWeeklyContent}) => {
        let {weeklyContent} = props

        function handleContentClick(e) {
            e.preventDefault()
            ResilienceReminders.instance.setState({showModal: true, weeklyContent})
        }

        return (
            <button
                className="d-block text-center btn-simple mx-auto"
                onClick={(e) => handleContentClick(e)}
                title={`Play video: ${weeklyContent.name}`}
            >
                <img alt="Play icon" width="20" height="20" src="/assets/images/icons/v2/ico-explanation.svg" />
            </button>
        )
    }
    
    static openMacDermottMethodModal = () => {
        ResilienceReminders.instance.setState({showMacDermottMethodModal: true})
    }

    static closeModal = () => {
        ResilienceReminders.instance.setState({showModal: false, weeklyContent: undefined, videoId: ""})
    }    

    closeMacDermottMethodModal = () => {
        this.setState({showMacDermottMethodModal: false})
    }

    closeVideoModal = () => {
        try {
            let myplayer = videojs('rr-explanation-video')
            myplayer.pause()
        }
        catch (err) {
            console.error("Error: ", err)
        }
        finally {
            this.setState({ showModal: false, weeklyContent: undefined, videoId: "" })
        }
    }

    render() {
        let { userInfo } = this.props
        let resilienceRemindersInfo = getResilienceRemindersInfo(userInfo)

        if (resilienceRemindersInfo === undefined) return

        let { hasResilienceReminders, resources, grades } = resilienceRemindersInfo        
        let { showModal, videoId, weeklyContent, showMacDermottMethodModal } = this.state
        let showXTopRight = !weeklyContent
        let modalWidth = weeklyContent ? "730px" : "976px"
        let urlForMacDermottMethod = "https://pub.winwardacademy.com/resilience-reminders/images/mm-infographic-052324.png"

        return (
            <PageContent className="view-change-buttons-container">
                <PageTitle title="My Lessons" />
                
                <LessonTabs userInfo={userInfo} />

                <div className="resilience-reminders-container">
                    {hasResilienceReminders ?
                        <>
                            <ResilienceRemindersMethodInfoView />
                            <div className="wrap-3-col">
                                <ResilienceRemindersResourcesView title={"Resources for Mentors"} resources={resources} />
                            </div>
                            <div className="wrap-4-col">
                            <ResilienceRemindersGradesView grades={grades} />
                            </div>
                        </>
                        :
                        <ResilienceRemindersMarketingView />
                    }
                </div>

                    <Modal
                        className="explanation-video modal"
                        show={showModal}
                        onClose={this.closeVideoModal}
                        showXTopRight={showXTopRight}
                        width={modalWidth}>
                        {weeklyContent ?
                            <ResilienceReminderWeeklyContentView content={weeklyContent}/>
                            :
                            <BrightcoveVideo id="rr-explanation-video" videoId={videoId} />
                        }
                    </Modal>
                    <Modal
                        className="explanation-macdermott-method"
                        show={showMacDermottMethodModal}
                        onClose={this.closeMacDermottMethodModal}
                        showXTopRight={showXTopRight}
                        width={modalWidth}>
                        <img alt="" src={urlForMacDermottMethod} width="100%" />
                    </Modal>
            </PageContent>
        )
    }
}


//--- Helpers ---

function LessonTabs({ userInfo}) {

    return (
        <div className="row blue-tabs-container">
            <div className="col-12 text-left subject-buttons">
                {hasLessons(userInfo) &&
                    <Link to="/app/lessons"
                        className={"btn title-button btn-not-bold btn-default white margin-right-10"}
                        role="button">
                        <div>{userInfo.bundleName}</div>
                    </Link>
                }
                {hasCollegeKnowledge(userInfo) && 
                    <Link to="/app/lessons/dashboard/ck"
                        className={"btn title-button btn-not-bold btn-default white margin-right-10"}
                        role="button">
                        <div>College Knowledge</div>
                    </Link>
                }
                {hasSchoolSafety(userInfo) &&
                    <Link to="/app/school-safety"
                        className={"btn title-button btn-not-bold btn-default white margin-right-10"}
                        role="button">
                        <div>School Safety</div>
                    </Link>
                }                
                <Link to="/app/lessons/dashboard/enrichment"
                    className={"btn title-button btn-not-bold btn-default white margin-right-10"}
                    role="button">
                    <div>Enrichment</div>
                </Link>
                <button
                    className={"btn title-button btn-not-bold btn-default white activeSelection arrow_box"}
                    type="button">
                    <div>Mindset Moments</div>
                </button>
            </div>
        </div>
    )
}
