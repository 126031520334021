// This is the display name but it's used as if it were 
// a key in some places (eg. the scantron sheet)
export type TestSection = 'Reading' | 
    'Writing and Language' |
    'English' |
    'Reading' |
    'Science' |
    'Math' |
    'Math (No Calculator)' |
    'Math (Calculator)'|
    'Master the Application' |
    'Master the Writing' |
    'Master the Interview' |
    'Master the Resume' |
    'Break Barriers' | 
    'Success Looks Like Me' |
    'Young Women In Bio' |
    'NCAA';

export type SectionCode = 'reading' | 
  'writingAndLanguage' |
  'english' | 
  'reading' | 
  'science' | 
  'math' | 
  'mathNoCalculator' |
  'mathCalculator' |
  'masterTheApplication'|
  'Master the Writing' |
  'Master the Interview' |
  'Master the Resume' |
  'Break Barriers' | 
  'Success Looks Like Me' |
  'Young Women In Bio' |
  'NCAA';

export function getColor(section: TestSection) {
    switch(section) {
        case 'Writing and Language': 
        case 'English':
            return 'english-purple';
        case 'Reading':
            return 'reading-orange';
        case 'Science':
            return 'science-green';
        case 'Math':
        case 'Math (No Calculator)':
        case 'Math (Calculator)':
            return 'math-blue';
        case 'Master the Application':
        case 'Master the Writing':
        case 'Master the Interview':
        case 'Master the Resume':
        case 'Break Barriers':
            return 'ck-green';
        case 'Success Looks Like Me':
        case 'Young Women In Bio':
        case 'NCAA':
            return 'enrichment';
        default: 
            return 'black';
    }
}

export function getIcon(section: TestSection, defaultImagePath?: string) {
    switch (section) {
        case 'Reading':
            return '/assets/images/icons/subjects/ico-subject-reading.svg';
        case 'English': 
        case 'Writing and Language': 
            return '/assets/images/icons/subjects/ico-subject-english.svg';
        case 'Math': 
        case 'Math (No Calculator)': 
        case 'Math (Calculator)': 
            return '/assets/images/icons/subjects/ico-subject-math.svg';
        case 'Science': 
            return '/assets/images/icons/subjects/ico-subject-science.svg';
        default:
            // OT: Otherwise it is from enrichment or CK lessons
            // Threat section as a string
            let str = section as string
            let name = str.toLowerCase().replace(/ /g, "")
            let imgPath = `${defaultImagePath}/${name}-white.svg`

            return imgPath
    }
}

export function sectionCodeToDisplayName(sectionCode: SectionCode) {
    switch (sectionCode) {
        case 'reading':
          return 'Reading';
        case 'english':
            return 'English';
        case 'writingAndLanguage':
            return 'Writing and Language';
        case 'math':
            return 'Math';
        case 'mathNoCalculator':
            return 'Math (No Calculator)';
        case 'mathCalculator':
            return 'Math (Calculator)';
        case 'science':
            return 'Science';
        default:
            // console.log('Hit default, SHAME, SHAME, SHAME');
            return 'BLERGH.svg';
    }
}

export function getSectionCode(section: TestSection) {
    switch (section) {
        case 'Reading':
            return 'reading';
        case 'English':
            return 'english';
        case 'Writing and Language': 
            return 'writingAndLanguage';
        case 'Math':
            return 'math';
        case 'Math (No Calculator)':
            return 'mathNoCalculator';
        case 'Math (Calculator)':
            return 'mathCalculator';
        case 'Science':
            return 'science';
        default:
            // console.log('Hit default, SHAME, SHAME, SHAME');
            return 'BLERGH.svg';
    }
}

export default getColor;
