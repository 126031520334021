import * as React from 'react';

import CompletedView, { Props } from './CompletedView';
import { connect } from 'react-redux';
import { AppState } from '../../../../store';

const mapStateToProps = ({ lesson }: AppState) => ({
    subjects: { ...lesson.userLessons.bySubject },
    lessons: { ...lesson.userLessons }
});

const ConnectedCompletedView =  connect(mapStateToProps, {})(CompletedView);

export default (props) => {
    return (
        <ConnectedCompletedView {...props} />
    );
};
