import './ProfileColorPicker.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import ColorPalette from '../../../../components/ColorPalette/ColorPalette';

interface Props {
    initials: string | undefined;
    onColorChange: (color: string) => void;
}

interface State {
    selectedColor: string;
}

export default class ProfileColorPicker extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedColor: 'white'
        };
    }

    public render() {
        const backgroundColor = this.state.selectedColor;

        return (
            <div className="profile-color-picker">
                <div className="profile-color-picker__initials-container">
                    <div className="profile-color-picker__initials noselect" style={{ backgroundColor }}>
                        {this.props.initials}
                    </div>
                </div>

                <div className="profile-color-picker__divider" />

                <div className="profile-color-picker__colors">
                    <ColorPalette onColorChange={this.onColorChange} />
                </div>
            </div>
        );
    }

    
    @boundMethod
    private onColorChange(color: string) {
        this.setState({ selectedColor: color }, () => this.props.onColorChange(color));
    }
}
