import './PageContent.css';

import * as React from 'react';
import { isProductionEnv, isTestEnv } from '../../services/utils/env-helper';
import EnvLabel from '../EnvLabel';
import Breadcrumb from '../Breadcrumb';
import Copyright from '../Copyright';

interface PageContentProps {
    className?: string;
    style?: React.CSSProperties;
    noPad?: boolean;
    preContentComponent?: JSX.Element;
    showEnvLabel?: boolean
    showBreadcrumb?: boolean
    showCopyright?: boolean
}

export const PageContent: React.SFC<PageContentProps> = (props) => {
    const className = `page-content__inner
        ${props.className}
        ${props.noPad ? 'page-content__inner--no-pad' : ''}
    `;
    
    let {showEnvLabel=true, showBreadcrumb=true, showCopyright=true} = props
    let isLoginPage = window.location.pathname.includes("/login")
    if (isLoginPage) showCopyright = false

    return (
        <div className="page-content">
            {showEnvLabel && !isProductionEnv() &&
                <EnvLabel text={`${isTestEnv() ? 'TEST' : 'DEV'} MODE`}  />
            }
            {props.preContentComponent}
            <div className={className} style={props.style}>
                <Breadcrumb visible={showBreadcrumb} />
                {props.children}
            </div>
            {showCopyright && <Copyright/> }
        </div>
    );
};
PageContent.displayName = 'PageContent';
PageContent.defaultProps = { className: '' };

interface PageTitleProps {
    title: string;
    children?: any;
    classes?: any;
}

export const PageTitle: React.SFC<PageTitleProps> = (props) => {
    return (
        <div className={'page-title ' + props.classes || ''}>
            <h1>{props.title}</h1>
            {props.children}
        </div>
    );
};
PageTitle.displayName = 'PageTitle';
