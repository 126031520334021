import * as React from 'react';

import AccountInfo from './AccountInfo';
import { AppState } from '../../../../store';
import { connect } from 'react-redux';
import { updateUser } from '../../../../stores/account.store';

const mapDispatchToProps = { updateUser };

const mapStateToProps = (state: AppState) => ({
    userInfo: state.account.userInfo
});

const ConnectedAccount = connect<any, any, any, AppState>(mapStateToProps, mapDispatchToProps)(AccountInfo);

export default (props) => {
    return (
        <ConnectedAccount {...props} />
    );
};
