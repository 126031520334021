import './RewatchLastVideoModal.css';

import * as React from 'react';

import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';

interface Props {
    show: boolean;
    onClose: () => void;
    onGoToLessons: () => void;
    onGoToSummary: () => void;
    lessonName: string;
}

const LastVideoModal: React.SFC<Props> = (props) => {
    return (
        <Modal
            className="last-video-modal"
            show={props.show}
            onClose={props.onClose}
            width="1200px"
        >
        <div className="modal-container">
            
          <div className="last-video-modal--text">
                <p className="header-text"></p>
                <p className="subtext">
                We hope you enjoyed rewatching the {props.lessonName} lesson 
                and that the concepts are becoming more clear.
                </p>
            </div>
            
            <div className="btn-container">
                <Button
                    text="RETURN TO MY LESSONS"
                    textColor="white"
                    bgColor="blue"
                    onClick={props.onGoToLessons}
                    style={{width: 260}}
                />

                <Button
                    text="GO TO LESSON SUMMARY"
                    textColor="white"
                    bgColor="green"
                    onClick={props.onGoToSummary}
                    style={{width: 260}}                    
                />
            </div>
        </div>
        </Modal>
    );
};

LastVideoModal.defaultProps = {};
LastVideoModal.displayName = 'LastVideoModal';

export default LastVideoModal;
