import React from 'react';

import Login from './Login';
import { AppState } from '../../store';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loginUser } from '../../stores/account.store';

const mapStateToProps = ({ account }: AppState) => ({
    requestingLogin: account.requestingLogin
});

const mapDispatchToProps = { loginUser };

const ConnectedLogin = connect<any, any, any, AppState>(mapStateToProps, mapDispatchToProps)(Login);

export default withRouter(ConnectedLogin);
// export default (props: RouteComponentProps<any>) => {
//     return (
//         <ConnectedLogin {...props} />
//     );
// };
