import './MistakeBankModal.css';

import * as React from 'react';

import Button from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';

interface Props {
    show: boolean;
    onClose: () => void;
    setReview: () => void;
    setMastered: () => void;
    topicName: string;
}

const MistakeBankModal: React.SFC<Props> = (props) => {
    return (
        <Modal
            className="mistake-bank-modal"
            show={props.show}
            onClose={props.onClose}
            width="800px"
        >
        <div className="modal-container">
            <div className="mistake-modal--text">
                <p className="header-text"> Nice job!</p>
                <p className="subtext">
                    How are you feeling about {props.topicName} now?
                    If you're feeling confident, then we can mark this topic
                    as mastered in your Mistake Bank. Or, if you feel like
                    you're improving but want to try these questions again,
                    we can indicate that you'd like to review them again in
                    your Mistake Bank.
                </p>
                {/* <p className="subtext">
                    Remember, too, that you can always access more practice
                    questions on Super Pythagorean Theorem through the Mix'Em
                    Up practice.
                </p> */}
            </div>
            <div className="btn-container">
                <Button
                    text="REVIEW AGAIN"
                    textColor="white"
                    bgColor="green"
                    onClick={props.setReview}
                />
                <Button
                    text="TOPIC MASTERED"
                    textColor="white"
                    bgColor="purple"
                    onClick={props.setMastered}
                />
            </div>
        </div>
        </Modal>
    );
};

MistakeBankModal.defaultProps = {};
MistakeBankModal.displayName = 'MistakeBankModal';

export default MistakeBankModal;
