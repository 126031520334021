import { CDN_PUB_URL_RESOURCES_ENGAGEMENT_AND_CULTURE } from "../../../constants"
import { replaceAll } from "../../../services/utils/text-util"
import { LinkType } from "./types"

const getLink = (resourceNameOrLink: string) => {
    if (resourceNameOrLink.includes(`docs.google.com`) || resourceNameOrLink.includes(`drive.google.com`)) return resourceNameOrLink
    resourceNameOrLink = replaceAll(resourceNameOrLink, " ", "")
    return `${CDN_PUB_URL_RESOURCES_ENGAGEMENT_AND_CULTURE}/${resourceNameOrLink}`
}

// --- Engagement & Culture Data ---
export const engagementAndCultureData = [
    {
        resource: `Onboarding Platform Demo - Student, Teacher, Parent, and Admin Experience`,
        description: `This Google Slides resource the onboarding session used to highlight the student, teacher, parent, and administrator experience on Winward Academy. Feel free to use this for yourself or to show others how to access the platform.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1INdiyvlQ2W93V8Cyw5A1HEbDuxQ-cye1aoUIonydfBU/view`)
    },
    {
        resource: `“Why” Presentation - Big Picture Overview`,
        description: `This Google Slides resource helps students reflect on the purpose and “why” behind what motivates them in preparing for college and career readiness.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1hs0vQ_vCCK8296XifNDY23LP1NojhHrbSPUWH15bkV8/view`)
    },
    {
        resource: `Introduction for Students - How and Why With WA`,
        description: `This Google Slides resource is for educators to introduce  Winward Academy to students and helps them get familiar with the platform.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1R279RJKkDHUdU4dt8zQdXBqVTttS0djnd9kTLRWdnHI/view`)
    },
    {
        resource: `Scavenger Hunt Activity`,
        description: `This PDF resource is a fun activity to help students learn how to navigate the Winward Academy platform and see what content and resources are available`,
        linkType: LinkType.PDF,
        link: getLink(`WinwardAcademy_Scavenger-Hunt.pdf`)
    },
    {
        resource: `Scavenger Hunt Key`,
        description: `This PDF resource is the answer key for the Scavenger Hunt, which is worth 15 points.`,
        linkType: LinkType.PDF,
        link: getLink(`WinwardAcademy_Scavenger-Hunt-Key.pdf`)
    },
    {
        resource: `How to Use Lesson Instructional Guides`,
        description: `This PDF resource offers detailed guidance on how each part of the Lesson Instructional Guides can be leveraged in planning for instruction.`,
        linkType: LinkType.PDF,
        link: getLink(`HowToUseLessonInstructionalGuides.pdf`)
    },
    {
        resource: `2023-2024 Academic Year Study Plan`,
        description: `This PDF resource provides high impact strategies and a study plan for 8th-12th grade following the 2023-2024 academic year.`,
        linkType: LinkType.PDF,
        link: getLink(`2023-24WinwardAcademyStudyPlan.pdf`)
    },
    {
        resource: `2024 Calendar Year Study Plan`,
        description: `This PDF resource provides high impact strategies and a study plan for 8th-12th grade following the 2024 calendar year.`,
        linkType: LinkType.PDF,
        link: getLink(`WinwardAcademy_2024StudyGuide.pdf`)
    },
    {
        resource: `2023-2024 Scope & Sequence Template`,
        description: `This Google Sheets planning document allows for schools and organizations to map out which lessons they want to teach throughout the school year. The key exam dates for both the ACT and SAT are included to help inform instructional decisions.`,
        linkType: LinkType.SHEET,
        link: getLink(`https://docs.google.com/spreadsheets/d/1_TqVwyPHDgfKFFLaUSY1vAQEvxsZiY21/edit#gid=378218711`)
    },
    {
        resource: `Engagement Toolkit`,
        description: `This PDF resource shares best practices for building trust and relationships with students throughout the course of an academic year.`,
        linkType: LinkType.PDF,
        link: getLink(`Engagement-Toolkit.pdf`)
    },
    {
        resource: `How Winward Academy Supports All Learners`,
        description: `This PDF resource offers best practices for teachers on how they might adjust instruction to better meet the accommodations needed for diverse learners using Winward Academy in their classroom.`,
        linkType: LinkType.PDF,
        link: getLink(`How-Winward-Academy-Supports-All-Learners+2023.pdf`)
    },
    {
        resource: `Hybrid Instructional Guide`,
        description: `This PDF document is a step-by-step guide to help content expert teachers adjust their instruction to a hybrid model that leverages the power of Winward Academy to amplify the teacher's skills in the classroom.`,
        linkType: LinkType.PDF,
        link: getLink(`Winward-Academy_Teacher-Resource_Hybrid-Instruction.pdf`)
    },
    {
        resource: `Positive Self-Talk One-Pager`,
        description: `This PDF is a resource for educators to use in planning ways to build resilience within their students by developing the skill of positive self-talk.`,
        linkType: LinkType.PDF,
        link: getLink(`Winward-Academy_Teacher-Resource_Power-of-Positive-Self-Talk.pdf`)
    },
    {
        resource: `Self-Determination Theory`,
        description: `This PDF is a resource for educators to help them better understand the brain science behind self-determination to increase student motivation.`,
        linkType: LinkType.PDF,
        link: getLink(`Winward-Academy_Teacher-Resource_Self-Determination-Theory.pdf`)
    },
    {
        resource: `Universal Prompts`,
        description: `This PDF explains the high-impact instructional strategy of Universal Prompts, so that educators can efficiently plan and implement these prompts in their classroom.`,
        linkType: LinkType.PDF,
        link: getLink(`Winward-Academy_Teacher-Resource_Universal-Prompts.pdf`)
    },
    {
        resource: `Professional Development Offerings`,
        description: `This PDF provides an overview of the myriad professional development trainings offered by the Winward Academy team.`,
        linkType: LinkType.PDF,
        link: getLink(`Winward-Academy_Admin-Resource_Professional-Development.pdf`)
    },
    {
        resource: `Content Lessons Overview`,
        description: `This PDF provides an overview of the content lessons offered on Winward Academy.`,
        linkType: LinkType.PDF,
        link: getLink(`Winward-Academy_Admin-Resource_Content-Lessons.pdf`)
    }
]
