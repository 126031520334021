import './MistakeLessonListTitle.css';

import * as React from 'react';

interface Props {
    icon?: string;
    alt?: string;
    title?: string;
    lessonName: string;
    onIconClick: () => void;
}

const MistakeLessonListTitle: React.SFC<Props> = (props) => {
    return (
        <div className="lesson-list__title">
            <button className="btn-simple mr-2" tabIndex={0} title={props.title ? props.title : ''} onClick={props.onIconClick}>
                <img
                    src={props.icon}
                    alt={props.alt ? props.alt : ''}
                />
            </button>
            <span>{props.lessonName}</span>
        </div>
    );
};

MistakeLessonListTitle.displayName = 'MistakeLessonListTitle';

export default MistakeLessonListTitle;
