import { CDN_PUB_URL_RESOURCES_ASSESSMENTS } from "../../../constants"
import { replaceAll } from "../../../services/utils/text-util"
import { LinkType } from "./types"

const getLink = (resourceNameOrLink: string) => {
    if (resourceNameOrLink.includes(`docs.google.com`)) return resourceNameOrLink
    resourceNameOrLink = replaceAll(resourceNameOrLink, " ", "")
    return `${CDN_PUB_URL_RESOURCES_ASSESSMENTS}/${resourceNameOrLink}`
}

// --- Assessments Data (Category by Category) ---
const assessmentsForCategory1 = [
    {
        resource: `Steps to Administer a Practice Test`,
        description: `This Google Sheets template project plan helps schools to strategically plan to administer a practice ACT or SAT exam.`,
        linkType: LinkType.SHEET,
        link: getLink(`https://docs.google.com/spreadsheets/d/1Sr8c_E2XxFteOMXvRDY1yjlF7T4myZBgEyq-8idrlGU/view`)
    },
    {
        resource: `Practice Test Logistics Document`,
        description: `This document is a template logistics memo for schools and organizations to modify with their site specific details. The vision is that this is updated and shared with stakeholders in your community in advance of a practice ACT or SAT exam. `,
        linkType: LinkType.DOCUMENT,
        link: getLink(`https://docs.google.com/document/d/11BwH3uQQbKOV-4YlFFuoFJBue7QK1aW0PBRluNUMUSM/view`)
    },
    {
        resource: `Practice Exam Preview for Students`,
        description: `This Google Slides presentation is a template that schools and organizations can add their own details to regarding an upcoming practice exam.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1f_rU_uQbIBrqPmsQOJ2M_gDKFoVbzMjvxRC_JBBK6zo/edit#slide=id.gf7094a58e8_1_69/view`)
    },
    {
        resource: `ACT vs SAT Presentation`,
        description: `This Google Slides presentation helps students, families, and faculty understand the differences between the ACT and SAT exams.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1pcEekOgnlB693IxCUsyE7BVvqoYAn-eH/edit#slide=id.p1/view`)
    },
    {
        resource: `ACT vs SAT Presentación`,
        description: `This Google Slides presentation helps students, families, and faculty understand the differences between the ACT and SAT exams in Spanish.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1rZSm8l_Tlv1vtZ06bRbtbNjDf29lI2LVlPyyVeQuSWA/edit#slide=id.g15f9fefb333_0_1/view`)
    },
    {
        resource: `Math Formula Cheat Sheet`,
        description: `This PDF resource is a cheat sheet of all the math formulas students need to know for the ACT/SAT.`,
        linkType: LinkType.PDF,
        link: getLink(`MathFormulaCheatSheet.pdf`)
    },
]

const assessmentsForCategory2 = [
    {
        resource: `ACT Sprints: Strategies for ACT Subtest Timed Practice`,
        description: `This PDF resource outlines how you might simulate the pacing demands of the ACT in a shortened time period using a past exam. This is helpful if you have a class period to give students an at-bat but there is not enough time for a full length exam.`,
        linkType: LinkType.PDF,
        link: getLink(`Winward-Academy_Educator-Resource_ACT-Sprints.pdf`)
    },
    {
        resource: `ACT Proctoring Instructions`,
        description: `This PDF resource is a concise single page document to support school and partner sites in proctoring a full-length ACT exam.`,
        linkType: LinkType.PDF,
        link: getLink(`ACT_Proctoring_Instructions.pdf`)
    },
    {
        resource: `ACT Proctoring Script`,
        description: `This PDF document is the written script for proctoring an ACT exam and includes timing guidelines.`,
        linkType: LinkType.PDF,
        link: getLink(`ACT_Proctoring_Script.pdf`)
    },
    {
        resource: `ACT Proctoring Instructions Presentation (Regular Time)`,
        description: `This Google Slides resource is a scripted presentation deck that proctors can use as they facilitate an ACT exam.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1riOoiZ5q1vok9vWdMIBvlQ0pc4BJhJ89/edit#slide=id.g1376747a77b_3_0/view`)
    },
    {
        resource: `ACT Proctoring Instructions Presentation (Extended Time)`,
        description: `This Google Slides resource is a scripted presentation deck that proctors can use as they facilitate an ACT exam with extended time pacing guidelines.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1pp6wG1D2iT465gSWJI1oeHI_TKU1XWgU/view`)
    },    
    {
        resource: `ACT Scantron`,
        description: `This PDF is the ACT Scantron form to collect student answers.`,
        linkType: LinkType.PDF,
        link: getLink(`ACT_Scantron.pdf`)
    },
    {
        resource: `ACT Diagnostic `,
        description: `This PDF is the ACT Diagnostic Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`ACT_Diagnostic.pdf`)
    },
    {
        resource: `ACT Test 1`,
        description: `This PDF is the ACT Test 1 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`ACT_Test1.pdf`)
    },
    {
        resource: `ACT Test 2`,
        description: `This PDF is the ACT Test 2 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`ACT_Test2.pdf`)
    },
    {
        resource: `ACT Test 3`,
        description: `This PDF is the ACT Test 3 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`ACT_Test3.pdf`)
    },
    {
        resource: `ACT Test 4`,
        description: `This PDF is the ACT Test 4 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`ACT_Test4.pdf`)
    },
    {
        resource: `ACT Test 5`,
        description: `This PDF is the ACT Test 5 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`ACT_Test5.pdf`)
    },
    {
        resource: `ACT Test 6`,
        description: `This PDF is the ACT Test 6 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`ACT_Test6.pdf`)
    },
    {
        resource: `ACT Test 7`,
        description: `This PDF is the ACT Test 7 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`ACT_Test7.pdf`) // This resource will be provided soon by Jennifer!
    },
]


const assessmentsForCategory3 = [
    {
        resource: `SAT Sprints: Strategies for SAT Subtest Timed Practice`,
        description: `This PDF resource outlines how you might simulate the pacing demands of the SAT in a shortened time period using a past exam. This is helpful if you have a class period to give students an at-bat but there is not enough time for a full length exam.`,
        linkType: LinkType.PDF,
        link: getLink(`Winward-Academy_Educator-Resource_SAT-Sprints.pdf`)
    },

    {
        resource: `SAT Proctoring Instructions`,
        description: `This PDF resource is a concise single page document to support school and partner sites in proctoring a full-length SAT exam.`,
        linkType: LinkType.PDF,
        link: getLink(`SAT_Proctoring_Instructions.pdf`)
    },
    {
        resource: `SAT Proctoring Script`,
        description: `This PDF document is the written script for proctoring an SAT exam and includes timing guidelines.`,
        linkType: LinkType.PDF,
        link: getLink(`SAT_Proctoring_Script.pdf`)
    },
    {
        resource: `SAT Proctoring Instructions Presentation (Regular Time)`,
        description: `This Google Slides resource is a scripted presentation deck that proctors can use as they facilitate an SAT exam.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1GxPPQX0T9AteJB9_WGVcYUFV2J8LIqR7/view`)
    },
    {
        resource: `SAT Proctoring Instructions Presentation (Extended Time)`,
        description: `This Google Slides resource is a scripted presentation deck that proctors can use as they facilitate an SAT exam with extended time pacing guidelines.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1JKU2mTJITYEIT7MKPJaFFceJMA93hue-/view`)
    },    
    {
        resource: `SAT Scantron`,
        description: `This PDF is the SAT Scantron form to collect student answers.`,
        linkType: LinkType.PDF,
        link: getLink(`SATScantron.pdf`)
    },
    {
        resource: `SAT Diagnostic`,
        description: `This PDF is the SAT Diagnostic Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`SATDiagnostic.pdf`)
    },

    {
        resource: `SAT Test 1`,
        description: `This PDF is the SAT Test 1 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`SAT1.pdf`)
    },
    {
        resource: `SAT Test 2`,
        description: `This PDF is the SAT Test 2 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`SAT2.pdf`)
    },
    {
        resource: `SAT Test 3`,
        description: `This PDF is the SAT Test 3 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`SAT3.pdf`)
    },
    {
        resource: `SAT Test 4`,
        description: `This PDF is the SAT Test 4 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`SAT4.pdf`)
    },
    {
        resource: `SAT Test 5`,
        description: `This PDF is the SAT Test 5 Exam.`,
        linkType: LinkType.PDF,
        link: getLink(`SAT5.pdf`)
    },

]

const assessmentsForCategory4 = [
    {
        resource: `How to Review an ACT Practice Test`,
        description: `This Google Slides presentation walks students through the step-by-step process of submitting their scores and reviewing their results for a practice exam.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/17lOGSnJFGDiXxX6L9h0UlSPLBqB5q9rjyl7Wp3KTqBk/edit#slide=id.g2311d1b41c2_0_881`)
    },
    {
        resource: `ACT Interim Assessment Test Corrections`,
        description: `This Google Slides presentation walks students through the step-by-step process of reviewing their interim assessment results and completing test corrections.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1MWHDgYFqwooufDDoS9VUKZyYIm9gCBVes5gvApMVEMg/edit`)
    },
    {
        resource: `ACT Corrections Tracker`,
        description: `This PDF resource walks students through the step-by-step process of reviewing their results from a practice ACT and explains how to do question by question corrections.`,
        linkType: LinkType.PDF,
        link: getLink(`WinwardAcademy_ACTTestCorrections.pdf`)
    },
    {
        resource: `ACT Goal Setting Worksheet`,
        description: `This PDF is a student-facing handout to support the reflection process after students complete a practice ACT exam. This document guides them in setting their desired score, reflecting on their current score, and creating action steps for their own personal study plan.`,
        linkType: LinkType.PDF,
        link: getLink(`Winward-Academy_Student-Worksheet_ACT-Goal-Setting-Worksheet.pdf`)
    },
    {
        resource: `ACT Test Corrections Worksheet`,
        description: `This PDF resource is for students to use as they review a practice exam and make test corrections.`,
        linkType: LinkType.PDF,
        link: getLink(`WinwardAcademy_ACTTestCorrections.pdf`)
    },
    {
        resource: `ACT Topics Over Time Worksheet`,
        description: `This PDF handout for students is used to track their progress over time on practice ACT exams.`,
        linkType: LinkType.PDF,
        link: getLink(`WinwardAcademy_ACT_TopicsOverTime.pdf`)
    },
    {
        resource: `How to Review an SAT Practice Test`,
        description: `This Google Slides presentation walks students through the step-by-step process of submitting their scores and reviewing their results for a practice exam.`,
        linkType: LinkType.PRESENTATION,
        link: getLink(`https://docs.google.com/presentation/d/1XwpbAwqoZ1Ffc63x74DDe3XNRfex6Ei0bA8U4KQsfdY/edit#slide=id.g2b8f6205573_0_3108`)
    },
    {
        resource: `SAT Corrections Tracker`,
        description: `This PDF resource walks students through the step-by-step process of reviewing their results from a practice SAT and explains how to do question by question corrections.`,
        linkType: LinkType.PDF,
        link: getLink(`WinwardAcademy_SATTestCorrections.pdf`)
    },
    {
        resource: `SAT Goal Setting Worksheet`,
        description: `This PDF is a student-facing handout to support the reflection process after students complete a practice SAT exam. This document guides them in setting their desired score, reflecting on their current score, and creating action steps for their own personal study plan.`,
        linkType: LinkType.PDF,
        link: getLink(`Winward-Academy_Student-Worksheet_SAT-Goal-Setting-Worksheet.pdf`)
    },
    {
        resource: `SAT Test Corrections Worksheet`,
        description: `This PDF handout for students is used to track their progress over time on practice SAT exams.`,
        linkType: LinkType.PDF,
        link: getLink(`WinwardAcademy_SATTestCorrections.pdf`)
    },
    {
        resource: `SAT Topics Over Time Worksheet`,
        description: `A printable handout for students to track their progress over time on practice SAT exams.`,
        linkType: LinkType.PDF,
        link: getLink(`WinwardAcademy_SAT_TopicsOverTime.pdf`)
    },
]


// Export all data here...
export const assesmentsData = {
    category1: { 
        name: "Practice Test Resources",
        data: assessmentsForCategory1
    },
    category2: { 
        name: "ACT Exam Resources",
        data: assessmentsForCategory2
    },
    category3: { 
        name: "SAT Exam Resources",
        data: assessmentsForCategory3
    },
    category4: {
        name: "Post-Exam Resources",
        data: assessmentsForCategory4
    }
}

