import './MultipleChoicePassage.css';

import React, { createRef } from 'react';
import { boundMethod } from 'autobind-decorator';

import { Question, QuestionGroup } from '../../services/elearn/lesson-types';
import * as _ from 'lodash';

import DifficultyLevel from '../MultipleChoiceQuestion/components/DifficultyLevel/DifficultyLevel';
import MCAnswerPicker from '../MultipleChoiceQuestion/components/MCAnswerPicker/MCAnswerPicker';
import Popover from '../Popover/Popover';
import TextBox from '../TextBox/TextBox';
import VideoModal from '../VideoModal/VideoModal';
import Timer, { TimerState } from '../Timer/Timer';
const MathJax = (window as any).MathJax;

interface Props {
    questionGroup: QuestionGroup;
    questions: Question[];
    currentQuestionGroupIndex: number;
    currentQuestionIndex: number;
    farthestQuestionIndex: number;
    onAnswer: (questionIndex: number, isCorrect: boolean, answerIndex: number) => void;
    onGoToQuestion: (questionIndex: number) => void;
    onNextPassage: () => void;
    isMistakeBank?: boolean;
    hintText?: string;
    addHint?: (hint: string, display: boolean) => void;
    shouldShowHintPopup?: boolean;
    useMathFont: boolean;
    isTeacher: boolean;
    addToMB?: (add: boolean) => void;
    canNext: boolean;
    timerState?: TimerState
    onVideoStart?: () => void
}

interface State {
    showTeachMeVideo: boolean;
    videoId: string;
    fieldState: FieldStateMap;
    showHint: boolean;
    editMode: boolean;
    showHintPopover: boolean;
    showRenderedMath: boolean;
    scroller?: any;
}

export interface FieldState {
    key: string;
    value?: any;
}

interface FieldStateMap {
    [key: string]: FieldState;
}

export const initialFormState: FieldStateMap = {
    hint: { key: 'hint' }
};


export default class MultipleChoicePassage extends React.Component<Props, State> {
    private timerRef = React.createRef<Timer>()
    
    constructor(props: Props) {
        super(props);

        const fieldState: FieldStateMap = Object.keys(initialFormState)
            .map((key, i, arr) => initialFormState[key])
            .map(formItem => ({ isValid: true, feedback: '', value: '', ...formItem }))
            .reduce((acc, formItem: FieldState) => ({ ...acc, [formItem.key]: formItem }), {});

        fieldState.hint.value = this.props.hintText;

        this.state = {
            showTeachMeVideo: false,
            videoId: '',
            fieldState,
            showHint: false,
            editMode: false,
            showHintPopover: this.props.shouldShowHintPopup ? this.props.shouldShowHintPopup : false,
            showRenderedMath: false
        };
    }

    private scrollPaneRef = createRef<HTMLDivElement>();

    componentDidMount() {
        const topEdgeOffset = 15; 
        const newScroller  = (window as any).zenscroll.createScroller(
          this.scrollPaneRef.current, 500, topEdgeOffset
        );
        this.setState({ scroller: newScroller });

        MathJax.Hub.Typeset((args) => {
            this.setState({ showRenderedMath: true });
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.setState({showHint: false});
        this.state.fieldState.hint.value = nextProps.hintText;
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.questionGroup && prevProps.questionGroup) {
            const newFirstQuestion = _.first(this.props.questionGroup.questions);
            const oldFirstQuestion = _.first(prevProps.questionGroup.questions);
            if (newFirstQuestion !== undefined && oldFirstQuestion !== undefined) {
              const newFirst = newFirstQuestion.questionId;
              const oldFirst = oldFirstQuestion.questionId;
              if (newFirst !== oldFirst) {
                  this.setState({ showRenderedMath: false });
                  MathJax.Hub.Typeset((args) => {
                      this.setState({ showRenderedMath: true });
                  });
              }
            }
        }
    }

    public render() {
        const { questionGroup, onGoToQuestion, useMathFont,
            farthestQuestionIndex, currentQuestionIndex, questions, timerState 
        } = this.props;
        const { questionGroupInfo } = questionGroup;
        if(!questionGroupInfo) { return null; }
        const currentQuestion = questions[currentQuestionIndex];
        const firstQuestion = questionGroup.questions[0];
        const firstQuestionIndex = questions
            .findIndex(q => q.questionId === firstQuestion.questionId);
        const hideHover = (this.state.showHint) ? ' hide' : '';
        const stylePopover = {
            width: '93.5vw',
            maxWidth: '500px'
        };
        const { fieldState } = this.state;
        let visible = timerState && timerState == TimerState.HIDDEN ? false: true

        return (
            <>
                <VideoModal
                    videoId={this.state.videoId}
                    onClose={this.closeTeachMeVideo}
                    show={this.state.showTeachMeVideo} // Pass dynamic 'show' prop
                />
                <div className="multiple-choice-passage">
                    <div className="multiple-choice-passage__timer">
                        <Timer ref={this.timerRef} visible={visible}/>
                    </div>
                    <div className="passage-question pt-0">
                        <div id="passage-column" className="multiple-choice-question__question-text">
                            <div className="multiple-choice-passage__difficulty">
                                <DifficultyLevel difficultyLevel={currentQuestion.difficultyLevel} />
                            </div>
                            <div className="mc-scroll-pane">
                                <div className="multiple-choice-passage__images">
                                    {this.props.isMistakeBank
                                        && <div className={'hint-icon-passages'}>
                                                <img
                                                    alt="Show Hints"
                                                    src="/assets/images/mistakeBank/hint-icon-2022.svg"
                                                    onClick={this.setShowHint}
                                                />
                                            <span className={'popover-passages' + hideHover}> My hint </span>
                                            <div className="hintPopover-passages">
                                                <Popover
                                                        visible={this.state.showHint}
                                                        xAlignment={'right'}
                                                        style={stylePopover}
                                                    >
                                                    {(this.props.hintText && !this.state.editMode) && <span>
                                                    <div className="editContainer">
                                                        <p className="editText" onClick={this.setEditMode}>Edit hint</p>
                                                        <p className="border"></p>
                                                    </div>
                                                    <p className="title">Hint</p>
                                                    <p className="subtext">{fieldState.hint.value}</p>
                                                    </span>
                                                    }

                                                    {(!this.props.hintText || this.state.editMode) && <div className="textBox">
                                                        <p className="saveText" onClick={this.sendNewHint}>Save hint</p>
                                                        <p className="saveBorder"></p>
                                                        <TextBox
                                                            id="message"
                                                            labelText=""
                                                            inputType="email"
                                                            placeholder={'Enter hint...'}
                                                            value={fieldState.hint.value}
                                                            onChange={this.editHint}
                                                            resizeable={false}
                                                        />
                                                    </div>}
                                                </Popover>
                                            </div>
                                        </div>
                                    }

                                    {questionGroupInfo.questionGroupImages.map(image =>
                                        <div key={image}><img alt="" src={`/assets/passages/${image}`} /></div>)}
                                </div>
                            </div>
                        </div>
                        <div id="answer-column" className="multiple-choice-question__mc-answer-picker">
                            <div className="mc-scroll-pane" ref={this.scrollPaneRef}>
                                {questionGroup.questions.map((question, i) => {
                                    const { questionNumber } = question;
                                    const questionIndex = firstQuestionIndex + i;
                                    const lastQuestionInBlock = questionGroup.questions.length === (i + 1);
                                    // For passage questions, we have to set the "questionQualifierHtml"
                                    // as the regular questionHtml
                                    const modifedQuestion: Question = {
                                        ...question,
                                        questionQualifierHtml: `${questionNumber}. ${question.questionHtml}`
                                    };
                                    const pickerDisabled = !this.props.isTeacher &&
                                        questionIndex > farthestQuestionIndex;

                                    return (
                                        <MCAnswerPicker
                                            key={question.questionId}
                                            question={modifedQuestion}
                                            isBaseline={false}
                                            onAnswer={this.onAnswer}
                                            onNextQuestion={() => onGoToQuestion(currentQuestionIndex + 1)}
                                            onShowTeachMeVideo={() => this.showTeachMeVideo(question.feedbackVideoId)}
                                            showButtons={this.props.isTeacher || currentQuestionIndex === questionIndex}
                                            userHasAnswered={questionIndex < farthestQuestionIndex && !this.props.isTeacher}
                                            disabled={pickerDisabled}
                                            onFocus={() => onGoToQuestion(questionIndex)}
                                            isLastQuestion={currentQuestionIndex === questions.length - 1}
                                            isMistakeBank={this.props.isMistakeBank ? this.props.isMistakeBank : false}
                                            addHint={this.setHint}
                                            shouldShowHintPopup={!this.props.isTeacher && this.state.showHintPopover}
                                            useMathFont={useMathFont}
                                            mathVisible={this.state.showRenderedMath}
                                            lastInPassageBlock={lastQuestionInBlock}
                                            scrollHere={questionIndex === currentQuestionIndex}
                                            isTeacher={this.props.isTeacher}
                                            addToMB={this.addToMB}
                                            canNext={this.props.canNext}
                                            scroller={this.state.scroller}
                                        />);
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    @boundMethod
    private addToMB(add: boolean) {
        if (this.props.addToMB) {
            this.props.addToMB(add);
        }
    }

    @boundMethod
    private onAnswer(isCorrect: boolean, answerIndex: number) {
        this.props.onAnswer(this.props.currentQuestionIndex, isCorrect, answerIndex);
    }

    private showTeachMeVideo(videoId: string) {
        this.setState({ showTeachMeVideo: !this.state.showTeachMeVideo, videoId });
        if (this.props.onVideoStart) this.props.onVideoStart()
    }

    @boundMethod
    private closeTeachMeVideo() {
        this.setState({ showTeachMeVideo: false });
    }

    @boundMethod
    private setShowHint() {
        this.setState({ showHint: !this.state.showHint, editMode: false });
    }

    private updateFieldValue(fieldName: string, newValue: any, additionalParams?: Partial<FieldState>) {
        const field = {...this.state.fieldState[fieldName], value: newValue,
            ...additionalParams };
        const fieldState = { ...this.state.fieldState, [fieldName]: field };
        this.setState({ fieldState });
    }

    @boundMethod
    private editHint(e: any) {
        this.updateFieldValue('hint', e.target.value);
    }

    @boundMethod
    private setEditMode() {
        this.setState({editMode: true});
    }

    @boundMethod
    private sendNewHint() {
        const { fieldState } = this.state;

        if(this.props.addHint) {
            // Hopefully, this is okay?
            this.props.addHint(fieldState.hint.value, false);
        }

        this.setState({editMode: false});
    }

    @boundMethod
    private setHint(message: string, display: boolean) {
        this.setState({showHintPopover: display});
        // add hint to backend
        if(this.props.addHint) {
            this.props.addHint(message, display);
        }
    }
}
