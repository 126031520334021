import * as React from 'react';

import TextInput from '../../../../components/TextInput/TextInput';
import EmailList from '../EmailList';
import Button from '../../../../components/Button/Button';
import { createFilePostAuthRequest } from '../../../../services/request/request-utils';

import './index.css';

interface Props {
}

interface State {
  selectedTest?: string;
  selectedSection?: string;
  fileData?: any;
  message: string;
  successEmails: Array<string>;
  missingEmails: Array<string>;
  currentlyProcessing: boolean;
}

export default class EdulasticUpload extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      message: '',
      successEmails: [],
      missingEmails: [],
      currentlyProcessing: false
    };
  }

  uploadAnswers() {
    this.setState({ 
      successEmails: [], 
      missingEmails: [],
      currentlyProcessing: true
    });
    const request = createFilePostAuthRequest(process.env.REACT_APP_ELEARN_URL || '/');
    const { selectedTest, selectedSection } = this.state;
    const postUrl = `/reports/edulastic/answers/${selectedTest}/${selectedSection}`;

    const { fileData } = this.state;
    const formData = new FormData();
    formData.append('file', fileData);
    request(
      postUrl, 
      formData,
    )
      .then(result => {
        if (result.missingIlluminateIds) {
          // ugh this is terrible, it's actually emails on this end
          this.setState({ 
            currentlyProcessing: false,
            missingEmails: result.missingIlluminateIds 
          });
        } else if (result.updateSuccessEmails) {
          this.setState({ 
            currentlyProcessing: false,
            successEmails: result.updateSuccessEmails 
          });
        }
        
      })
      .catch(console.log);
  }

  public render() {
    const { message } = this.state;
    const subjectData = [
      { key: 'reading', name: 'Reading' }, 
      { key: 'science', name: 'Science' }, 
      { key: 'math', name: 'Math' }, 
      { key: 'english', name: 'English' }, 
      { key: 'mathc', name: 'Math (Calc)' }, 
      { key: 'mathnc', name: 'Math (No Calc)' }, 
      { key: 'writing', name: 'Writing' }
    ];

    const actTests = [
      { name: 'ACT 1', key: 'ACT1' },
      { name: 'ACT 2', key: 'ACT2' },
      { name: 'ACT 3', key: 'ACT3' },
      { name: 'ACT 4', key: 'ACT4' },
      { name: 'ACT 5', key: 'ACT5' },
      { name: 'ACT 6', key: 'ACT6' },
      { name: 'ACT 7', key: 'ACT7' },
      { name: 'ACT Diagnostic', key: 'Diagnostic' }
    ];

    const iaTests = [
      { key: 'Pre-Test 2019', name: 'Pre-Test 2019 (EOY ACT 8th)' },
      { key: 'Practice 2', name: 'Practice 2 (BOY ACT 9th)' },
      { key: 'Pre-Test', name: 'Pre-Test 2018 (MOY ACT 9th)' },
      { key: 'Mid-Test', name: 'Mid-Test (EOY ACT 9th)' },
      { key: 'IA 1', name: 'IA 1 (BOY ACT 10th)' },
      { key: 'Practice 1', name: 'Practice 1 (MOY ACT 10th)' },
      { key: 'IA 3', name: 'IA 3 (EOY ACT 10th)' },
      { key: 'IA 2', name: 'IA 2 (BOY ACT 11th)' },
      { key: 'MOY ACT 11th', name: 'MOY ACT 11th' },
    ];

    const satTests = [
      { key: 'SAT1', name: 'SAT 1' },
      { key: 'SAT2', name: 'SAT 2' },
      { key: 'SAT3', name: 'SAT 3' },
      { key: 'SAT4', name: 'SAT 4' },
      { key: 'SAT5', name: 'SAT 5' },
      { key: 'SAT6', name: 'SAT 6' },
      { key: 'SAT7', name: 'SAT 7' },
      { key: 'DiagnosticSAT', name: 'SAT Diagnostic' }
    ];

    return (
      <div className="admin-edulastic">
        <h5>Import Edulastic Answer File</h5>
        <p>Select a CSV file, test name, and test section</p>
        <div className="test-inputs">
          <div>
            <h6>Section</h6>
            {subjectData.map(section => 
              <label key={`label-${section.key}`}>
              <input 
                type="radio" 
                key={`input-${section.key}`}
                name="subjectAnswerUpload" 
                value={section.key}
                onChange={e => 
                  this.setState({ selectedSection: section.key })
                }
              />
                {section.name}
              </label>
            )}
          </div>
          <div>
            <h6>ACT</h6>
            {actTests.map(actTest => 
              <label key={`label-${actTest.key}`}>
                <input 
                  type="radio" 
                  name="testNameAnswerUpload"
                  key={`input-${actTest.key}`}
                  value={actTest.key} 
                  onChange={e => 
                    this.setState({ selectedTest: actTest.key})
                  }
                />
                {actTest.name}
              </label>
              )
            }
          </div>
            <div>
              <h6>Interim Assessment</h6>
              {iaTests.map(test => 
                <label key={`label-${test.key}`}>
                  <input 
                    key={`input-${test.key}`}
                    type="radio" 
                    name="testNameAnswerUpload" 
                    value={test.key}
                    onChange={e => 
                      this.setState({ selectedTest: test.key})
                    }
                  />
                  {test.name}
                </label>
              )}
            </div>
          { false &&
          <div>
            {satTests.map(test => 
            <label key={`label-${test.key}`}>
              <input
                key={`input-${test.key}`}
                type="radio" 
                name="testNameAnswerUpload" 
                value={test.key}
                onChange={e => 
                  this.setState({ selectedTest: test.key})
                }
              />
              {test.name}
            </label>
            )}
          </div>
          }
          <div className="messages">
            <em>Upload Processing Messages:</em>
            <div id="upload-answers-results">
            {this.state.currentlyProcessing && 
            "...Uploading/processing answers now..."
            }
            {message}
            {this.state.successEmails.length > 0 && 
            <div>
              Successfully saved answers for: 
              <EmailList emails={this.state.successEmails} />
            </div>
            }
            {this.state.missingEmails.length > 0 && 
            <div>
              Following emails not found in our system: 
              <EmailList emails={this.state.missingEmails} />
            </div>
            }
            </div>
          </div>
        </div>
        <div className="admin-file-upload">
          <label htmlFor="fileUpload">Choose Edulastic (CSV) file</label>
          <input 
            type="file" 
            name="fileUpload"
            accept=".csv"
            id="answerUpload" 
            onChange={e => {
              const files = e.target.files;
              if (files !== null) {
                const theFileData = files[0];
                this.setState({ fileData: theFileData });
              }

            }}
          />
          <button 
            className="btn btn-outline-success" 
            disabled={this.state.fileData === undefined || 
            this.state.selectedTest === undefined ||
            this.state.selectedSection === undefined
            }
            onClick={() => this.uploadAnswers()}
          >
            Upload
          </button>
        </div>
      </div>
    );
  }
}
