import {
    getHints
} from '../services/elearn/lesson-rest-interface';

export interface HintState {
}

const defaultState: Partial<HintState> = {
    // lessonInProgress: false,
    // userLessons: {
    //     bySubject: {},
    //     allLessons: []
    // }
};

export const LOAD_ANSWER_HINTS = 'lesson/LOAD_ANSWER_HINTS';
export const LOAD_ANSWER_HINTS_SUCCESS = 'lesson/LOAD_ANSWER_HINTS_SUCCESS';

export function getStudyGuideHints() {
    return (dispatch) => {
        return getHints()
            .then((response) => {
                // console.log(response);
                dispatch(loadHintsSucceeded(response));

            });
    };
}

function loadHintsSucceeded(hints) {
    return {
        type: LOAD_ANSWER_HINTS_SUCCESS,
        payload: { hints }
    };
}

export default function(state: Partial<HintState> = defaultState, action: any) {
    switch(action.type) {
        case LOAD_ANSWER_HINTS_SUCCESS: {
            return { ...state, subjects: action.payload.hints };
        }
        default: {
            return { ...state };
        }
    }
}
