import * as React from 'react';
import { withRouter } from 'react-router';

import { StudentDashboard, Props, StoreProps } from './SchoolSafetyStudentDashboard';

import { AppState } from '../../../../store';
import { UserInfo } from '../../../../services/account/account-rest-interface';
import { connect } from 'react-redux';
import { getCurrentLesson } from '../../../../stores/lesson.store';

const mapStateToProps = ({ account, lesson }: AppState): StoreProps => ({
    userInfo: account.userInfo as UserInfo,
    numLessonsUnstarted: lesson.numLessonsUnstarted,
    numLessonsCompleted: lesson.numLessonsCompleted,
    numLessonsStarted: lesson.numLessonsStarted,
    currentLessonId: lesson.currentLessonId,
    currentLesson: getCurrentLesson(lesson),
    mistake: lesson.mistakeBankOverview,
    allLessons: lesson.userLessons.allLessons
});

const ConnectedSchoolSafetyStudentDashboard = 
withRouter(connect<StoreProps, {}, Props, AppState>(mapStateToProps, {})(StudentDashboard));

export default (props) => {
    return (
        <ConnectedSchoolSafetyStudentDashboard {...props} />
    );
};
