import './ProgressBar.css';

import * as React from 'react';

interface Props {
    total: number;
    current: number;
    barCurrentColor?: 'white' | 'green' | 'purple' | 'lightpurple' | 'blue' | 'orange' | 'pink' | 'ck-green' | 'primary-wa-blue' | string;
    barTotalColor?: 'white' | 'green' | 'purple' | 'blue' | 'gray' | string;
    size?: 'large' | 'small' | 'xlarge';
    popover?: boolean;
}

const defaultProps: Partial<Props> = {
    barCurrentColor: 'purple',
    barTotalColor: 'white',
    size: 'large'
};

const ProgressBar: React.SFC<Props> = (props: Props) => {
    const width = ((props.current / props.total) * 100) + '%';

    return (
       <div className={`progress-bar progress-bar--${props.size}`}>
           <div className={`progress-bar__total progress-bar__total--${props.barTotalColor}`} />
            <div
                className={`progress-bar__completed progress-bar__completed--${props.barCurrentColor}`}
                style={{ width }}
            >
               {props.current > 0 && <div className="progress-bar-start_dot" />}
               {props.popover && props.total > 0 && props.current > 0 &&
                <div className="progress-bar-current_dot">
                   <span className="popover">Lesson {props.current}</span>
                   <span className="arrow-down"></span>
               </div>}
            </div>
       </div>
    );
};

ProgressBar.defaultProps = defaultProps;
ProgressBar.displayName = 'ProgressBar';

export default ProgressBar;
