import { createGenericAuthRequest, ignoreJsonError } from '../request/request-utils';
import { TestAnswer } from '../elearn/practice-test-types';
import { getCachedValue, setCacheValue } from '../utils/cache-util';
import { ERR_CANNOT_GET_DATA_FROM_SERVER } from '../../constants';
import { App } from "../../App"

const baseUrl = process.env.REACT_APP_ELEARN_URL || '/';
const request = createGenericAuthRequest(baseUrl);

const baseURL: string = '/practice-tests';
const baseIAURL: string = '/interim-assessments';

export const getScantrons = () => {
  return request(baseURL);
};

// /practice-tests/<test-name>
export const getDetailPTScoresByTest = (testName: string) => {
  return request(`${baseURL}/${testName}`);
};

export const getPracticeTestSummary = (testName: string) => {
  return request(`${baseURL}/printable/${testName}`);
};

export const getOverallPTScore = (testName: string) => {
  return request(baseURL + '/scores/current/' + testName);
};

export const markAccuracy = (accuracyObject: any) => {
  return request(baseURL,
  accuracyObject,
  { method: 'PUT' })
      .catch(ignoreJsonError);
};

export const getAccuracies = () => {
  return request(baseURL + '/current');
};

export const getScoreCalculations = () => {
  return request(baseURL + '/scores');
};

export const getScores = (testType?: string) => {
  if (testType === 'sat') {
    return request(baseURL + '/scores/current?type=sat');
  } else {
    return request(baseURL + '/scores/current?type=act');
  }
};

export const resetPracticeTestAnswer = (testName: string, subject: string, questionNumber: number, testAnswer: TestAnswer) => {
  return request(baseURL + '/' + testName + '/' + subject + '/' + questionNumber + '?includeScore=true&resetAnswer=true',
    testAnswer,
    { method: 'PUT' })
    .catch(ignoreJsonError);
};

export const updatePTTest = (testName: string, subject: string, questionNumber: number, testAnswer: TestAnswer) => {
  return request(baseURL + '/' + testName + '/' + subject + '/' + questionNumber + '?includeScore=true',
    testAnswer,
    { method: 'PUT' })
    .catch(ignoreJsonError);
};

export const getCurrentIAScores = () => {
  return request(baseIAURL + `/scores/current`);
};

export const getDetailIAScores = () => {
  return request(baseIAURL);
};

export const getDetailIAScoresByTest = (testName: string) => {
  return request(`${baseIAURL}/${testName}`);
};

export const resetIATestAnswer = (testName: string, subject: string, questionNumber: number, object: any) => {
  return request(baseIAURL + '/' + testName + '/' + subject + '/' + questionNumber + '?includeScore=true&resetAnswer=true',
    object,
    { method: 'PUT' })
    .catch(ignoreJsonError);
};
export const updateIATest = (testName: string, subject: string, questionNumber: number, object: any) => {
  return request(baseIAURL + '/' + testName + '/' + subject + '/' + questionNumber + '?includeScore=true',
    object,
    { method: 'PUT' })
    .catch(ignoreJsonError);
};

export const getIATestTotalScores = () => {
  return request(baseIAURL);
};

export const getUserIAScores = () => {
  return request(baseIAURL + '/current');
};

export const getOverallIAScore = (testName: string) => {
  return request(baseIAURL + '/scores/current/' + testName);
};

export const getPTHighestScores = () => {
  return request(baseURL + '/growth-analytics');
};

export const getSATPTHighestScores = () => {
  return request(baseURL + '/growth-analytics?type=sat');
};

export const getPTLineGraphData = () => {
  return request(baseURL + '/growth-analytics/line');
};

export const getSATPTLineGraphData = () => {
  return request(baseURL + '/growth-analytics/line?type=sat');
};

export const getHighestScores = () => {
  return request(baseIAURL + '/growth-analytics');
};

export const getLineGraphData = () => {
  return request(baseIAURL + '/growth-analytics/line');
};

export const postPTStudentSummary = (testId: string, teacherEmail: string) => {
  return request(`/reports/tests/statistics/${testId}`,
  {},
  { method: 'POST' })
  .catch(ignoreJsonError);
};

export const getTopicsOverTime = (testType: string) => {
    return request(`/reports/tests/${testType}/topics`);
};

export const getPTAnalysis = (testName: string) => {
  return request('/reports/tests/practiceTests/' + testName);
};

export const getIAAnalysis = (testName: string) => {
  return request('/reports/tests/interimAssessments/' + testName);
};

export const updatePriority = (lessonId: string, status: boolean) => {
  return request('/usermaterial/stars',
  {
    lessonId: lessonId,
    status: status,
  },
  { method: 'PUT' })
  .catch(ignoreJsonError);
};


async function getScoresACT() {
  let scoresACT = []
  try {
    scoresACT = await getScores('act')
  }
  catch (ex) {
    console.error(ERR_CANNOT_GET_DATA_FROM_SERVER, ex)
  }
  return scoresACT
}

async function getScoresSAT() {
  let scoresSAT = []
  try {
    scoresSAT = await getScores('sat')
  }
  catch (ex) {
    console.error(ERR_CANNOT_GET_DATA_FROM_SERVER, ex)
  }
  return scoresSAT
}

async function getScoresIA() {
  let scoresIA = []
  try {
    let userInfo = App.getUserInfo()
    
    // Maia students don't have IA!
    if (userInfo && !userInfo.isMaiaStudent) {
      scoresIA = await getCurrentIAScores()
    }
  }
  catch (ex) {
    console.error(ERR_CANNOT_GET_DATA_FROM_SERVER, ex)
  }
  return scoresIA
}

export async function getAllTestScoresCached() {
  let cacheKey = `all-test-scores-info`
  let data = getCachedValue(cacheKey)

  if (!data) {
    try {
      let scoresACT = await getScoresACT()
      let scoresSAT = await getScoresSAT()
      let scoresIA  = await getScoresIA()
      data = { scoresACT, scoresSAT, scoresIA }
      setCacheValue(cacheKey, data)
    }
    catch (ex) {
      console.error(ERR_CANNOT_GET_DATA_FROM_SERVER, ex)
    }
  }

  return data
}
