import React from "react"
import { ResilienceReminders } from "."
import Button from "../../../components/Button/Button"

/**
 * This is the first element at top showing generic RR info with couple videos
 * 
 * @returns 
 */
export default function ResilienceRemindersMethodInfoView() {
    let videoId1 = "6353527648112"
    let videoId2 = "6353528437112"

    function handleVideoClick(videoId) {
        ResilienceReminders.instance.handleVideoClick(videoId)
    }
    function handleMethodLinkClick(e) {
        e.preventDefault()
        ResilienceReminders.openMacDermottMethodModal()
    }

    return (
        <div className="rr-method-info">
            <div className="row">
                <div className="col-sm-6 col-lg-4 col-xl-3 mb-3 mb-lg-0">
                    <div className="rr-method-info--image px-3 py-5">
                        <img alt="MacDermott Logo" src="/assets/images/logos/macdermott.jpg" width="250" height="62" className="img-fluid" />
                    </div>
                </div>
                <div className="col-12 col-lg">
                    <h2 className="h5">Welcome to Mindset Moments™</h2>
                    <p>
                        Developed from an
                        {" "}
                        <button className="btn btn-simple hyperlink rr-macdermott-method-model-link--text" 
                            title="View The MacDermott Method Model, opens a modal window" onClick={handleMethodLinkClick}>
                            evidence-based resilience model
                        </button> 
                        {" "}
                        this community-wide program builds character, resilience, and relationships one short conversation at a time and promotes 
                        a sense of belonging from the classroom to the dinner table.
                    </p>
                    <div className="pt-3">
                        <p className="mb-0">
                            <b>
                                <i>Explore these short videos to start meaningful, resilience-building conversations with your students.</i>
                            </b>
                        </p>
                        <Button titleAttr="Watch welcome message from Kristin MacDermott, creator of Mindset Moments" text="WELCOME MESSAGE" bgColor="blue" className="wide mt-2 mr-2 mr-xl-3" onClick={() => handleVideoClick(videoId1)}/>
                        {" "}
                        <Button titleAttr="Receive an overview of how to use Mindset Moments Skill of the Week lessons" text="HOW TO USE" bgColor="green" className="wide mt-2" onClick={() => handleVideoClick(videoId2)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
