import React from 'react'
import { Link } from 'react-router-dom'
import { IAveragesMistakeBank } from '.'
import DataGrid from '../../../components/DataGrid'
import { Column } from '../../../components/DataGrid/types/types'
import { NA } from '../../../constants'
import { CurrentStatus } from '../../../services/liveview/liveview-service'
import { alphanumericCompare } from '../../../services/utils/sort-util'
import { IStudentProgressDetails } from '../../../stores/teacher.store'
import { ISummaryFilter } from '../SummaryFilters'
import { getHumanTime } from '../../../services/utils/date-util'

interface Props {
  selections: ISummaryFilter
  studentsProgress: IStudentProgressDetails[]
  averages: IAveragesMistakeBank
}

interface State {
  currentSortField: string
}

let sortMethod = alphanumericCompare
let minSortVal = Number.NEGATIVE_INFINITY
let maxSortVal = Number.POSITIVE_INFINITY
let sortable = true
let columns: Column[] = [
  {
    title: 'FIRST NAME',
    dataProperty: 'studentNameFirst',
    customTooltip: 'Indicates the first name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'LAST NAME',
    dataProperty: 'studentNameLast',
    customTooltip: 'Indicates the last name of the student',
    width: '15%',
    sortable,
    sortMethod
  },
  {
    title: 'TOTAL<br/>QUESTIONS',
    dataProperty: 'totalQuestionsInMistakeBank',
    customTooltip: 'Indicates the total missed and saved questions for all categories',
    sortable,
    sortMethod
  },
  {
    title: 'REVIEWED<br/>QUESTIONS',
    dataProperty: 'questionsReviewed',
    customTooltip: 'Indicates the reviewed questions for all categories',
    sortable,
    sortMethod
  },   
  {
    title: 'HINTS ON<br/>INCORRECT',
    dataProperty: 'hintsOnMissedQuestions',
    customTooltip: 'Indicates the percent of missed questions with hints written',
    sortable,
    sortMethod
  },
  {
    title: 'ENGLISH<br/>TOPIC MASTERY',
    dataProperty: 'masteryEnglish',
    customTooltip: 'Indicates the number of mastered English topics out of the total missed English topics',
    sortable,
    sortMethod
  }, 
  {
    title: 'MATH<br/>TOPIC MASTERY',
    dataProperty: 'masteryMath',
    customTooltip: 'Indicates the number of mastered Math topics out of the total missed Math topics',
    sortable,
    sortMethod
  }, 
  {
    title: 'READING<br/>TOPIC MASTERY',
    dataProperty: 'masteryReading',
    customTooltip: 'Indicates the number of mastered Reading topics out of the total missed Reading topics',
    sortable,
    sortMethod
  }, 
  {
    title: 'SCIENCE<br/>TOPIC MASTERY',
    dataProperty: 'masteryScience',
    customTooltip: 'Indicates the number of mastered Science topics out of the total missed Science topics',
    sortable,
    sortMethod
  },
  {
    title: 'REVIEW<br/>TIME',
    dataProperty: 'reviewTime',
    customTooltip: 'Reflects the total time students answered Mistake Bank questions',
    sortable,
    sortMethod
  }, 
]

export default class RosterMistakeBank extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      currentSortField: "default"
    }
  }

  onSortAllowed = (sortFieldName: string) => {
    let {currentSortField} = this.state
    if (sortFieldName !== currentSortField) {
      setTimeout(() => {
        this.setState({currentSortField: sortFieldName})
      }, 10)
    }
    return true
  }

  createContent = (studentProgressView: IViewModel, index: number) => {
    let id = `roster-lesson-mistakebank-${index}`
    let {firstName, lastName, emailAddress, nofTotalMistakeBankQuestions, nofReviewedQuestions, hintsOnMissedQuestions, categories, isAverage}  = studentProgressView
    let {timeOnReviewTotal} = studentProgressView 
    let nofTopicsdE = categories.english.nofTopics
    let nofTopicsdM = categories.math.nofTopics
    let nofTopicsdR = categories.reading.nofTopics
    let nofTopicsdS = categories.science.nofTopics
    let nofTopicsMasteredE = categories.english.nofTopicsMastered
    let nofTopicsMasteredM = categories.math.nofTopicsMastered
    let nofTopicsMasteredR = categories.reading.nofTopicsMastered
    let nofTopicsMasteredS = categories.science.nofTopicsMastered
    let isNA = nofTotalMistakeBankQuestions === 0 || nofTotalMistakeBankQuestions === -1
    let comparatorE = `${nofTopicsMasteredE} ${nofTopicsdE}`
    let comparatorM = `${nofTopicsMasteredM} ${nofTopicsdM}`
    let comparatorR = `${nofTopicsMasteredR} ${nofTopicsdR}`
    let comparatorS = `${nofTopicsMasteredS} ${nofTopicsdS}`
    let comparatorHoMQ = isAverage ? hintsOnMissedQuestions : nofTotalMistakeBankQuestions > 0 ? hintsOnMissedQuestions : minSortVal
    let totalReviewTime = getHumanTime(timeOnReviewTotal, false, true).text
    let comparatorRT = isNA ? -1: timeOnReviewTotal
    let getAverageInfoBasedOnSortField = (currentSortField: string) => {
      let prefix = "Average"
      let averageInfo = ""

      if (!isNA) {
        switch (currentSortField) {
          case "totalQuestionsInMistakeBank":
            averageInfo = `<span class="average--val">${nofTotalMistakeBankQuestions}</span> ${prefix} Total Questions`
            break
          case "questionsReviewed":
            averageInfo = `<span class="average--val">${nofReviewedQuestions}</span> ${prefix} Reviewed Questions`
            break
          case "hintsOnMissedQuestions":
            averageInfo = `<span class="average--val">${hintsOnMissedQuestions}%</span> ${prefix} Hints on Incorrect`
            break
          case "reviewTime":
            averageInfo = `<span class="average--val">${totalReviewTime}</span> ${prefix} Review Time`
            break        
        }
      }

      return averageInfo ? averageInfo : ""
    }
    let {currentSortField} = this.state
    let averageInfo = getAverageInfoBasedOnSortField(currentSortField)
    let getFirstNameOrAverageDetails = () => {
      let reportLink = `/print/teacher/student-progress/${emailAddress}`
      return () =>
        <>
          { !isAverage ?           
            <>
              <Link className="roster-lesson-summary-iconlink" to={reportLink} target="progress-summary-page"  title={`Open Full Progress Summary for: ${firstName} ${lastName}, opens in a new window`}>
                <img
                  className="explanation-button-image"
                  src={`/assets/images/icons/v2/ico-external-link.svg`}
                  width="18"
                  height="18"
                  alt=""
                />
              </Link>
              {firstName}
            </>
            :
            <>
              <span dangerouslySetInnerHTML={{__html: averageInfo}}/>
            </>
          }
        </> 
    }    
    let getTotalQuestionsInMistakeBank = () => {
      return () => 
        <>
          {nofTotalMistakeBankQuestions}
        </>      
    }
    let getReviewedQuestions = () => {
      return () => 
        <>
          {nofReviewedQuestions}
        </>      
    }
    let getHintsOnMissedQuestions = (hintsOnMissedQuestions) => {
      let hintsInfo = hintsOnMissedQuestions !== -1 ? `${hintsOnMissedQuestions}%`: NA
      return () => 
        <>
          {hintsInfo}
        </>      
    }
    let getLessonMastery = (nofTopicsMastered: number, nofTopics: number) => {
      return () => 
        <>
          <span>{nofTopicsMastered}</span>/<span>{nofTopics}</span>
        </>
    }
    let getReviewTime = () => {
      return () => 
        <>
          <span>{totalReviewTime}</span>
        </>
    }
    let data = {
      studentNameFirst: {
        content: getFirstNameOrAverageDetails(),
        comparator: firstName,
      },  
      studentNameLast: {
        content: lastName,
        comparator: lastName,
      },
      totalQuestionsInMistakeBank: {
        content: isNA ? NA: getTotalQuestionsInMistakeBank(),
        comparator: nofTotalMistakeBankQuestions,
      },
      questionsReviewed: {
        content: isNA ? NA: getReviewedQuestions(),
        comparator: nofReviewedQuestions
      },
      hintsOnMissedQuestions: { // AKA Hints Frequency!
        content: isNA ? NA: getHintsOnMissedQuestions(hintsOnMissedQuestions),
        comparator: comparatorHoMQ,
      },
      masteryEnglish: {
        content: isNA ? NA: getLessonMastery(nofTopicsMasteredE, nofTopicsdE),
        comparator: comparatorE,
      },
      masteryMath: {
        content: isNA ? NA: getLessonMastery(nofTopicsMasteredM, nofTopicsdM),
        comparator: comparatorM,
      },
      masteryReading: {
        content: isNA ? NA: getLessonMastery(nofTopicsMasteredR, nofTopicsdR),
        comparator: comparatorR,
      },
      masteryScience: {
        content: isNA ? NA: getLessonMastery(nofTopicsMasteredS, nofTopicsdS),
        comparator: comparatorS,
      },
      reviewTime: {
        content: isNA ? NA: getReviewTime(),
        comparator: comparatorRT,
      },      
    }

    // Let's highlight the average row here (please note if there is no averageInfo then collapse the row!)
    let highlightRowClassName  = ""
    if (isAverage) {
      if (averageInfo !== "") {
        highlightRowClassName = "row-highlight"
      }
      else {
        highlightRowClassName = "row-highlight-collapsed"
      }
    }
    
    return {
      id,
      data,
      highlightRowClassName,
    }
  }

  prepareViewData = (studentProgress: IStudentProgressDetails): IViewModel => {
    let {firstName, lastName, emailAddress, lessonsScores, mistakeBankStats, mistakeBankTimeSummary} = studentProgress
    let {english, math, reading, science} = lessonsScores
    let allCategories = [english, math, reading, science]
    let totalEnteredHints = allCategories.reduce((acc, cur) => acc += cur.totalEnteredHints, 0)
    let totalMissedQuestions = allCategories.reduce((acc, cur) => acc += cur.totalMissedQuestions, 0)
    let hintsOnMissedQuestions = totalMissedQuestions > 0 ? Math.round(100.0*totalEnteredHints/totalMissedQuestions): -1
    let nofMissedQuestions = mistakeBankStats ? mistakeBankStats.nofMissedQuestions: 0
    let nofManualEntries = mistakeBankStats ? mistakeBankStats.nofManualEntries: 0
    let nofTotalMistakeBankQuestions = nofMissedQuestions + nofManualEntries
    let nofReviewedQuestions = nofTotalMistakeBankQuestions > 0 ? mistakeBankStats ? mistakeBankStats.nofReviewedQuestions: 0: -1
    let nofTopicsMasteredE = mistakeBankStats?.nofTopicsEnglishMastered ?? 0
    let nofTopicsMasteredM = mistakeBankStats?.nofTopicsMathMastered    ?? 0
    let nofTopicsMasteredR = mistakeBankStats?.nofTopicsReadingMastered ?? 0
    let nofTopicsMasteredS = mistakeBankStats?.nofTopicsScienceMastered ?? 0
    let nofTopicsdE = mistakeBankStats?.nofTopicsEnglish ?? 0
    let nofTopicsdM = mistakeBankStats?.nofTopicsMath    ?? 0
    let nofTopicsdR = mistakeBankStats?.nofTopicsReading ?? 0
    let nofTopicsdS = mistakeBankStats?.nofTopicsScience ?? 0
    let timeOnReviewTotal = mistakeBankTimeSummary.timeOnReviewTotal

    let viewModel: IViewModel = {
      firstName,
      lastName,
      emailAddress,
      nofTotalMistakeBankQuestions,
      nofReviewedQuestions,
      hintsOnMissedQuestions,
      categories: {
        english: {nofTopics: nofTopicsdE, nofTopicsMastered: nofTopicsMasteredE},
        math:    {nofTopics: nofTopicsdM, nofTopicsMastered: nofTopicsMasteredM},
        reading: {nofTopics: nofTopicsdR, nofTopicsMastered: nofTopicsMasteredR},
        science: {nofTopics: nofTopicsdS, nofTopicsMastered: nofTopicsMasteredS},
      },
      isAverage: false,
      timeOnReviewTotal
    }

    return viewModel
  }

  getCardBodyContentForMistakeBank = (studentsProgress: IStudentProgressDetails[]) => {
    let {averages} = this.props
    let studentsProgressView = studentsProgress.map(this.prepareViewData)

    // Add average row data only if there are more than 1 entry
    if (averages && studentsProgressView.length > 1) {
      // Let's add the average row (notice that some entries are ignored, i.e. not needed!)
      let averageView: IViewModel = {
        // The following values will be used in the average row!
        isAverage: true,
        nofTotalMistakeBankQuestions: averages.averageTotalMistakeBankQuestions,
        nofReviewedQuestions: averages.averageReviewedQuestions,
        hintsOnMissedQuestions:  averages.averageHintsOnMissedQuestions,

        // Ignore following (they are not shown in the average row)
        firstName: "",
        lastName: "",
        emailAddress: "",
        categories: {
          english: {nofTopics: -1, nofTopicsMastered: -1},
          math:    {nofTopics: -1, nofTopicsMastered: -1},
          reading: {nofTopics: -1, nofTopicsMastered: -1},
          science: {nofTopics: -1, nofTopicsMastered: -1},
        },
        timeOnReviewTotal: averages.averageReviewTime
      }
      studentsProgressView.push(averageView)
    }

    let data = studentsProgressView.map((progress, index) => this.createContent(progress, index))
  
    return (
      <DataGrid
        columns={columns}
        data={data}
        onSortAllowed={this.onSortAllowed}
        emptyText="No mistake bank info found for students."
      />
    )
  }

  render() {
    let {studentsProgress} = this.props
    let details = this.getCardBodyContentForMistakeBank(studentsProgress)

    return (
      <div className="roster-view-mistakebank-container">
        <div className="individual-data-section">
          <h2 className="h3">Mistake Bank</h2>
          <hr/>
        </div> 
        <div className="roster-view-content">
          {details}
        </div>
      </div>
    )
  }
}


//--- Helpers ---

interface IViewModel {
  firstName: string
  lastName: string
  emailAddress: string
  nofTotalMistakeBankQuestions: number
  nofReviewedQuestions: number
  hintsOnMissedQuestions: number
  categories: {
    english: ITopicMastery
    math:    ITopicMastery
    reading: ITopicMastery
    science: ITopicMastery
  }
  isAverage: boolean
  timeOnReviewTotal: number
}

interface ITopicMastery {
  nofTopics: number
  nofTopicsMastered: number
}
