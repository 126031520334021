import './TermsAndConditions.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import Checkbox from '../../../../components/Checkbox/Checkbox';
import Modal from '../../../../components/Modal/Modal';

interface Props {
    checked: boolean;
    onCheck: (e: any) => void;
}

interface State {
    showModal: boolean;
    showPrivacyModal: boolean;
}

export default class TermsAndConditions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showModal: false,
            showPrivacyModal: false
        };
    }

    public render() {
        const labelText = (
            <span className="terms-conditions__text">
            I agree to the <a href="#" onClick={this.toggleModal}>Terms &amp; Conditions</a> and the <a href="#" onClick={this.togglePrivacyModal}>Privacy Policy</a>
            </span>
        );

        return (
            <div className="row">
                <div className="col-12 terms-conditions-container">
                    <Checkbox
                        checked={this.props.checked}
                        onCheck={this.props.onCheck}
                        labelText={labelText}
                    />
                    <Modal className="terms_modal" show={this.state.showModal} onClose={this.toggleModal}>
                        <button type="button" className="close fixed-top-right" onClick={this.toggleModal}>&times;</button>
                        <iframe 
                            className="iframe-container"
                            src="/assets/images/terms/WinwardAcademy_TermsConditions.pdf.html"
                        >
                        </iframe>
                    </Modal>

                    <Modal 
                        className="privacy_modal" 
                        show={this.state.showPrivacyModal} 
                        onClose={this.togglePrivacyModal}
                    >
                        <button type="button" className="close fixed-top-right" onClick={this.togglePrivacyModal}>&times;</button>

                        <iframe 
                            className="iframe-container"
                            src="/assets/images/terms/WinwardAcademy_PrivacyPolicy_5.1.18.pdf.html">
                        </iframe>
                    </Modal>
                </div>
            </div>
        );
    }

    
    @boundMethod
    private toggleModal() {
        this.setState({ showModal: !this.state.showModal });
    }

    
    @boundMethod
    private togglePrivacyModal() {
        this.setState({ showPrivacyModal: !this.state.showPrivacyModal });
    }
}
