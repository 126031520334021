import './FirstLesson.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/Modal/Modal';
import Timer from '../../../../../../components/Timer/Timer';

// import UpgradeBrowser from './components/UpgradeBrowser/UpgradeBrowser';

export interface Props {
}

export interface State {
    showModal: boolean;
    popover: number;
}


export default class FirstLesson extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showModal: true,
            popover: this.detectBrowser()
        };
    }

    componentDidMount() {
        // As soon as this dialog shows up stop the timer right away!
        this.stopTimer()
    }   

    render() {
        let buttonColor = 'blue';
        let textColor = 'white';
        let widthBtn = 'fl-modal-btn';
        return(
            <div>
                <Modal className="heading-banner-fl modal" show={this.state.showModal} onClose={this.toggleModal}>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <h2 className="cushion-element-fl">Welcome to Your First Lesson!</h2>
                        </div>
                        <div className="body-fl container-fluid coverBottom-fl">
                            <div className="cushion-heading-fl">
                                <h2 className="dark-blue-text-fl">Here's a step-by-step guide of what to expect:</h2>
                            </div>
                            <ol className="black-text-fl ">
                                <li>Complete <strong>baseline questions</strong> (do your best or just skip if you have no clue).</li>
                                <li>Watch the <strong>video lesson</strong>
                                    <ul>
                                        <li>Be sure to print the lesson handout to take notes as you follow along.</li>
                                        <li>Feel free to adjust the speed of the lesson to adapt to your desired learning pace.</li>
                                    </ul>
                                </li>
                                <li>Answer the <strong>multiple choice questions</strong>
                                    <ul>
                                        <li>Review feedback on correct and incorrect answers.</li>
                                        <li>Watch a TEACH ME video for an explanation.</li>
                                        <li>Write yourself a hint (i.e., put what you learn into YOUR own words). That hint
                            is saved and you can read what you wrote to yourself when you review your
                            missed questions in your personal Mistake Bank.</li>
                                    </ul>
                                </li>
                                <li>Review your <strong>summary page</strong> to get a sense of how your performance changed
                            from baseline and what you need to review.</li>
                                <li>Redo your missed questions in your <strong>Mistake Bank</strong> and be sure to read the hint
                            you wrote to yourself. When you read what you put into your voice, your memory
                            will consolidate much better on the topic!</li>
                                <li>Repeat and keep learning 😊</li>
                            </ol>
                            <Button
                                text="I AM READY TO LEARN!"
                                textColor={textColor as any}
                                bgColor={buttonColor as any}
                                className={widthBtn as any}
                                onClick={this.toggleModal}
                            />
                        </div>
                    </div>
                </Modal>
            {/* {this.state.popover != 0 ?
                    this.setState({ showModal: true }) :
                    <UpgradeBrowser></UpgradeBrowser>
                    //come back to this
            } */}
            </div>
        );
    }

    /**
     * FirstLesson dialog is shown when the student starts taking his/her very first lesson.
     * When it is showing stop the timer (because it shouldn't start counting yet!)
     */
     @boundMethod
     private stopTimer() {        
         Timer.pause()
         Timer.reset()
     }
 
     /**
      * Starts the timer. This should be called when this dialog box is closed.
      */
     @boundMethod
     private startTimer() {
         Timer.resume()
         Timer.reset()
     }
    
    @boundMethod
    private toggleModal() {
        this.setState({ showModal: !this.state.showModal });        
        this.startTimer()
    }  

    private detectBrowser() {

        const ua = navigator.userAgent;
        let browser = '';
        let version = 0;

        if (/Firefox[\/\s](\d+\.\d+)/.test(ua)) {
            browser = 'firefox';
            version = Number(RegExp.$1);
        } else if (ua.lastIndexOf('Chrome/') > 0) {
            version = Number(ua.substr(ua.lastIndexOf('Chrome/') + 7, 2));
            browser = 'chrome';
        } else if (ua.lastIndexOf('Safari/') > 0) {
            version = Number(ua.substr(ua.lastIndexOf('Safari/') + 7, 2));
            browser = 'safari';
        } else {
            if(ua.lastIndexOf('MSIE ') > 0) {
                version = 10;
                browser = 'ie';
            } else if(ua.lastIndexOf('Trident/') > 0) {
                version = 11;
                browser = 'ie';
            } else if(ua.lastIndexOf('Edge/') > 0) {
                version = 12;
                browser = 'edge';
            } else if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(ua)) {
                browser = 'mobile';
            }
        }

        switch(browser) {
            case 'firefox':
                if( version < parseInt(process.env.REACT_APP_MIN_FIREFOX_VERSION || '0')) {
                    return 0;
                }
                return 1;
            case 'chrome':
                if( version < parseInt(process.env.REACT_APP_MIN_CHROME_VERSION || '0')) {
                    return 0;
                }
                return 1;
            case 'safari':
                if( version < parseInt(process.env.REACT_APP_MIN_SAFARI_VERSION || '0')) {
                    return 0;
                }
                return 1;
            case 'ie':
                return 0;
            case 'mobile':
                return 1;
            case 'edge':
                return 1;
            default:
                return 0;
        }
    }
}
