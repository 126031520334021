import * as React from 'react';
import collegeKnowledgeData from './CollegeKnowledgeBundleData';

interface Props {
    onChange: (checked: boolean) => void;
    bundleId: string;
    inputType?: string; // radio or checkbox
    darkPrint?: boolean;
}

const CollegeKnowledgeCheckbox: React.SFC<Props> = (props: Props) => {
    const typeColor = props.darkPrint === true ? ' bundle-dark ' : ' bundle-white ';
    const isRadio = props.inputType === 'radio';
    return (
        <div className="bundle-ck-box">
            <label className="bundle-cb">
                <input 
                    className="bundle-ck-checkbox"
                    onChange={(e) => {
                        props.onChange(e.target.checked);
                        // props.onChange();
                    }}
                    type={props.inputType === undefined ? 
                        'checkbox' : props.inputType
                    }
                    name={isRadio ? 
                        'ck-radio-inputs' : `${props.bundleId}_add_ck`
                    }
                    value={props.bundleId}
                />
                <i></i>
                <div 
                    className={'ck-checkbox-text ' + typeColor}
                >
                    {collegeKnowledgeData[props.bundleId].message}
                </div>
           </label>
        </div>
    );
};

export default CollegeKnowledgeCheckbox;
