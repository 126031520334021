import React from "react"
import { TonPSchoolView } from "./TonPSchoolView"

export const TonPSchoolsView = (props) => {
  let {summaryHeadline, subHeadline, schools} = props
  
  return (
    <div className="si-tonp-details-schools-view-container">
      <div>
        <h3 className="h3">Schools</h3>
        <hr className="half mb-2"/>
      </div>
      
      <div className="summary-headline mb-1" dangerouslySetInnerHTML={{ __html: summaryHeadline }} />
      <div className="summary-headline--subtext"><i>{subHeadline}</i></div>
      <div className="si-tonp-details-schools-view">
        {schools.map(school => <TonPSchoolView school={school}/> )}
      </div>
    </div>
  )
}