import * as React from 'react';

import {
    getCurrentLesson,
    incrementNumLessonsStarted,
    setCurrentLesson,
    setLessonCompleted,
    setLessonCompleted2,
    setLessonInProgress,
    /* setNextLessonAfter, */
    setCurrentQuestion
} from '../../stores/lesson.store';

import { AppState } from '../../store';
import Lesson, { Props } from './Lesson';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (
  rootState: AppState
  , ownProps: Props) => ({
    numLessonsStarted: rootState.lesson.numLessonsStarted,
    numLessonsCompleted: rootState.lesson.numLessonsCompleted,
    userProgress: getCurrentLesson(rootState.lesson),
    currentLessonId: rootState.lesson.currentLessonId,
    isTeacher: rootState.account.userInfo.isTeacher,
    userInfo: rootState.account.userInfo
});

const mapDispatchToProps = {
    setLessonInProgress,
    /* setNextLessonAfter, */
    setCurrentLesson,
    setLessonCompleted,
    setLessonCompleted2,
    incrementNumLessonsStarted,
    setCurrentQuestion
};

const ConnectedLesson = connect<any,any,any,any>(mapStateToProps, mapDispatchToProps)(Lesson);
export default (props: RouteComponentProps<any>) => (
    <ConnectedLesson lessonId={props.match.params.lessonId} {...props} />
);
