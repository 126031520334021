/**
 * Gets the college ready threshold value based on the category 
 * and the test kind (ACT | SAT)
 * 
 * @param category ACT: [English|Math|Reading|Science] SAT: [EBRW|Math]
 * @param testKind ACT | SAT
 * @returns 
 */
export const collegeReadyLookup = (category: string, testKind: string) => {
  let accuracyMapACT: {[key:string]: number} = {
    English: 53,
    Math: 52,
    Reading: 63,
    Science: 61
  }
  let accuracyMapSAT: {[key: string]: number} = {
    EBRW: 45,
    Math: 48 
  }
  let key = testKind === "ACT" ? category: category === "Math" ? "Math": "EBRW"

  return testKind === "ACT" ? accuracyMapACT[key]: accuracyMapSAT[key]
}

export interface IPracticeTestCollegeReadyLookup {
  ACT: {
    English: number;
    Math: number;
    Reading: number;
    Science: number;
  };
  IA: {
    English: number;
    Math: number;
    Reading: number;
    Science: number;
  };
  SAT: {
    EBRW: number;
    Math: number;
  };
}
/**
 * College ready thresholds for the practice tests
 * From wa-core practiceTest.ts for SI reports
 */
export const collegeReadyLookupForPracticeTests: IPracticeTestCollegeReadyLookup = {
  ACT: {
    English: 18,
    Math: 22,
    Reading: 22,
    Science: 23
  },
  IA: {
    English: 18,
    Math: 22,
    Reading: 22,
    Science: 23
  },
  SAT: {
    EBRW: 480,
    Math: 530
  }
}
