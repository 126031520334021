import './PDFLink.css';

import * as React from 'react';

interface Props {
    filename: string;
    title: string;
    className?: string;
}

const PDFLink: React.SFC<Props> = (props: Props) => {

    return (
        <div className="pdf-row">
            <img 
                alt=""
                className="pdf-icon"
                src="/assets/images/icons/v2/ico-handout-download.svg"
                width="16"
                height="20"
            />
            <a 
                className="pdf-link hyperlink btn-simple"
                href={'/assets/home-pdfs/' + props.filename} 
                title={`${props.title}, opens PDF in a new window`}
                target="_blank"
                tabIndex={0}
            >
                {props.title}
            </a>
        </div>
    );
};

PDFLink.displayName = 'PDFLink';

export default PDFLink;
