import { createGenericAuthRequest } from '../request/request-utils'
import { getCachedValue, setCacheValue } from '../utils/cache-util'
import { IStudyBuddyFull } from "../../scenes/MistakeBank/StudyBuddies/types"
const baseUrl1 = process.env.REACT_APP_ACCOUNT_URL || '/'
const baseUrl2 = process.env.REACT_APP_ELEARN_URL || '/'
const request1 = createGenericAuthRequest(baseUrl1)
const request2 = createGenericAuthRequest(baseUrl2)

export async function getAllStudyBuddies() {
    let res = await request2(`/studybuddies/all`)
    return res
}

export async function addStudyBuddyOld(emailAddressOfBuddy: string) {
    let args = { emailAddressOfBuddy }
    let res = await request2(`/studybuddies/add`, args, {method: "POST"})
    return res
}

export async function addStudyBuddy(emailAddressOfBuddy: string) {
    let args = { emailAddressOfBuddy }
    let res = await request2(`/studybuddies/add`, args, {method: "POST"})
    return res

}

export async function acceptStudyBuddy(emailAddressOfBuddy: string) {
    let args = { emailAddressOfBuddy }
    let res = await request2(`/studybuddies/accept`, args, {method: "POST"})
    return res
}

export async function rejectStudyBuddy(emailAddressOfBuddy: string) {
    let args = { emailAddressOfBuddy }
    let res = await request2(`/studybuddies/reject`, args, {method: "POST"})
    return res
}

export async function deleteStudyBuddy(emailAddressOfBuddy: string) {
    let res = await request2(`/studybuddies/${emailAddressOfBuddy}`, { }, {method: "DELETE"})
    return res
}

export async function getStudyBuddiesForLessonAndQuestion(lessonId: string, questionId: string): Promise<IStudyBuddyFull[]> {
    let url = `/studybuddies/lesson/${lessonId}/question/${questionId}`
    let res = await request2(url)
    return res
}

export async function isSchoolEnabledForStudyBuddies(schoolName: string): Promise<boolean> {
    let url = `/studybuddies/school/${schoolName}`
    let res = await request2(url)
    if (res && res.enabled) return true
    return false
}
