import React from 'react'

const ICON_BASE_PATH = "/assets/images/icons/v2"
const ICON_NAME = "ico-reteach"

export interface Props {
    isSelected: boolean,
    qnum?:number,
    onClick: () => void
}

/**
 * Reteach button
 * Icons used:
 *  + ico-reteach.svg
 *  + ico-reteach-active.svg
 *  + ico-reteach-hover.svg
 * 
 * @param props 
 * @returns 
 */
export function ReteachPlus(props: Props) {
    const { isSelected, onClick, qnum } = props;
    const img = `${ICON_BASE_PATH}/${isSelected ? ICON_NAME + "-active" : ICON_NAME}.svg`;
    const imgHover = `${ICON_BASE_PATH}/${ICON_NAME}-hover.svg`;
    const altText = isSelected
        ? `Reteach enabled for question ${qnum}, click to disable`
        : `Reteach disabled for question ${qnum}, click to enable`;
    const titleText = altText;

    return (
        <button className="btn-div__inline btn-has-hover-img" title={titleText} onClick={onClick}>
            <img src={img} className="non-hover-icon" alt={altText} />
            <img src={imgHover} className="hover-icon d-none" alt="" />
        </button>
    );
}