import './Card.css';

import * as React from 'react';

interface Column {
    xl?: number;
    lg?: number;
    md?: number;
}
interface Props {
    col: Column;
    cssClass?: string;
    colClass?: string;
}

export const Card: React.SFC<Props> = (props: Props) => {
    const definedCol = {lg: 12, md: 12, xl: 12, ...props.col};
    const colSize = `col-xl-${definedCol.xl} col-lg-${definedCol.lg} col-md-${definedCol.md}`;
    let cssCardClass = '';
    let colClass = '';
    if(props.cssClass != undefined) {
        cssCardClass = ' ' + props.cssClass
    }
    if(props.colClass != undefined) {
        colClass = ' ' + props.colClass
    }
    return (
        <div className={colSize + colClass} style={{display: 'flex'}}>
            <div className={`lesson-complete-card` + cssCardClass} style={{flex: 1}}>
                {(props as any).children}
            </div>
        </div>
    );
};

Card.displayName = 'Card';
