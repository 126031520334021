import './LessonVideo.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { Lesson, MaterialType, PostLessonQuestionsData, Topic } from '../../services/elearn/lesson-types';
import { getLesson, getMCQuestions } from '../../services/elearn/lesson-rest-interface';

import BrightcoveVideo from '../../components/BrightcoveVideo/BrightcoveVideo';
import Button from '../../components/Button/Button';
import ContextualTitle from '../../components/ContextualTitle/ContextualTitle';
import LastVideoModal from './components/LastVideoModal/LastVideoModal';
import PDFModal from './components/PDFModal/PDFModal';
import { PageContent } from '../../components/PageContent/PageContent';
import { RouteComponentProps } from 'react-router-dom';
import VideoDots from './components/VideoDots/VideoDots';
import { getBaseUrl } from '../../services/routing/routing-utils';
import { hasSchoolSafety } from '../../services/account/account-utils';
import { updateActivityInfoWithLastKnownNavigation } from '../../WinwardTracker';
import { Context, Resource, sendUserActivity, UserActivityEx } from '../../services/liveview/liveview-service';

export interface Props {
    lessonId: string;
    onComplete: () => void;
    userInfo: any;
}

export interface StoreProps {
    currentTopicId: string;
}

export interface DispatchProps {
    setCurrentTopic: (lessonId: string, topicId: string, userInfo: any) => void;
    setCurrentQuestion: (lessonId: string, type: MaterialType, id: string, userInfo: any) => void;
    setLessonCompleted: (lessonId: string, userInfo: any) => void;
}

interface State {
    selectedTopic?: Topic;
    hoveredTopic: string;
    lastVideo: boolean;
    pdfPopup: boolean;
    lesson?: Lesson;
    postLessonQuestionData?: PostLessonQuestionsData;
}

type AllProps = Props & RouteComponentProps<any> & StoreProps & DispatchProps;

export default class LessonVideo extends React.Component<AllProps, State> {
    private timerId
    private timerCntr = 0
    private playing = false 
    private timedout = false
    private readonly TIMEOUT_IN_SECONDS = 3600 // 60 mins

    constructor(props: AllProps) {
        super(props);
        this.state = {
            selectedTopic: undefined,
            hoveredTopic: '',
            lastVideo: false,
            pdfPopup: false
        };
    }

    public componentDidMount() {
        this.loadPostLessonQuestionData();
        this.timerId = setInterval(() => {
            if (this.playing) {
                this.timerCntr++
                if (this.timerCntr > this.TIMEOUT_IN_SECONDS) {
                    this.timedout = true
                }
            }
        }, 1000)
    }

    public componentWillUnmount() {
        clearInterval(this.timerId)
    }

    public componentDidUpdate(prevProps: AllProps, prevState: State) {
        const { selectedTopic } = this.state;
        const oldTopic = prevState.selectedTopic;
        if (
          oldTopic !== undefined && 
          selectedTopic !== undefined &&
          prevState.selectedTopic !== selectedTopic
        ) {
            this.props.setCurrentTopic(this.props.lessonId, selectedTopic.topicId, this.props.userInfo);
        }
    }

    public render() {

        const playerStyle = {
            width: '960px',
            maxWidth: '100%',
            height: 'auto'
        };

        const { 
            lesson, 
            selectedTopic,
            postLessonQuestionData
        } = this.state;

        let activity = `Video Lesson: ${lesson?.category.categoryName}: ${lesson?.lessonName} - ${selectedTopic?.topicName}`

        updateActivityInfoWithLastKnownNavigation(activity)

        return (
            <PageContent className="lesson-video">
                <div className="lesson-video__close-container">
                    <div className="lesson-video__close" onClick={this.closeLesson} />
                </div>

                <div className="lesson-video__topic-selector">
                    {lesson !== undefined && 
                        lesson.topics && 
                        selectedTopic !== undefined && 
                        <ul className={'video-dots--' + lesson.category.categoryName}>
                            {lesson.topics.map(topic =>
                                <VideoDots
                                    video={topic.videoId}
                                    key={topic.topicName}
                                    active={selectedTopic.videoId === topic.videoId}
                                    onClick={this.selectVideo}
                                    onHover={this.hoverVideo}
                                    lesson={topic.topicName}
                                />
                            )}
                        </ul>}
                </div>

                {lesson !== undefined && 
                    selectedTopic !== undefined &&
                <div className="lesson-video__video">
                    <div className="lesson-video__video-title">
                        <ContextualTitle
                            title={lesson.lessonName}
                            postText={selectedTopic.topicName}
                            showPostText={!hasSchoolSafety(this.props.userInfo)}
                            className="lesson-video-contextual-title"
                        />
                        {!hasSchoolSafety(this.props.userInfo) &&
                            <Button bgColor="green" text="PRINT WORKSHEET" onClick={this.printWorksheet} />
                        }
                    </div>

                    <div>
                        <BrightcoveVideo
                            id="bc-video-test"
                            videoId={selectedTopic.videoId}
                            onVideoStart={this.handleVideoStart}
                            onVideoEnd={this.handleVideoEnd}
                            onVideoPause={this.handleVideoPause}
                            style={playerStyle}
                        />
                    </div>

                    {this.state.pdfPopup && <PDFModal
                        show={this.state.pdfPopup}
                        onClose={this.handlePdfModalClose}
                        onPrintPDF={this.printWorksheet}
                        onCloseBtn={this.handlePdfModalClose}
                    />}

                    {this.state.lastVideo && <LastVideoModal
                        show={this.state.lastVideo}
                        onClose={this.handleModalClose}
                        onGoToLessons={this.closeLesson}
                        onGoToMultipleChoice={this.props.onComplete}
                        hasQuestions={postLessonQuestionData !== undefined && postLessonQuestionData ? true : false}
                        lessonName={lesson.lessonName}
                    />}
                </div>
                }
            </PageContent>
        );
    }

    private loadPostLessonQuestionData() {
        const { lessonId, userInfo } = this.props;
        Promise.all([
            getLesson(lessonId, userInfo),
            getMCQuestions(lessonId, userInfo)
        ]).then(([lesson, postLessonQuestionData]) => {

            if (postLessonQuestionData === null) {
                const selectedTopic = this.findTopicFromLesson(
                    lesson, 
                    this.props.currentTopicId
                );
                const hoveredTopic = selectedTopic.topicName;
                // console.log({ selectedTopic });
                this.setState({
                    selectedTopic: selectedTopic,
                    hoveredTopic: hoveredTopic,
                    lesson: lesson,
                });
                this.props.setCurrentTopic(
                    lessonId, selectedTopic.topicId, userInfo
                );

              
            } else if(postLessonQuestionData && postLessonQuestionData.questionGroups) {
                const sortedPostLessonQuestionData = {
                    ...postLessonQuestionData,
                    questionGroups: postLessonQuestionData.questionGroups.map(group => ({
                        ...group,
                        questions: group.questions
                            .sort((a, b) => a.questionNumber - b.questionNumber)
                    }))
                };

                const selectedTopic = this.findTopicFromLesson(
                    lesson, 
                    this.props.currentTopicId
                );
                const hoveredTopic = selectedTopic.topicName;
                this.setState({ 
                    selectedTopic: selectedTopic,
                    hoveredTopic: hoveredTopic,
                    lesson: lesson,
                    postLessonQuestionData: sortedPostLessonQuestionData 
                });
                this.props.setCurrentTopic(
                    this.props.lessonId, 
                    selectedTopic.topicId, 
                    this.props.userInfo
                );
            }
        }).catch(console.log);
    }

    @boundMethod
    private selectVideo(videoId: string) {
        const newTopic = this.getTopicWithVideo(videoId);
        this.setState({ selectedTopic: newTopic });
    }

    @boundMethod
    private hoverVideo(lesson: string) {
        const newTopic = this.getTopicWithLesson(lesson);
        const topicIsValid = newTopic !== undefined && newTopic.topicName !== undefined;
        this.setState({ hoveredTopic: topicIsValid ? newTopic.topicName : ''});
    }

    @boundMethod
    private closeLesson() {
        const rewatchVideo = window.location.search.substring(1);

        const { postLessonQuestionData, lastVideo } = this.state;
        const { lessonId, userInfo } = this.props;
        if (rewatchVideo) {
            console.log('rewatching a video');
            this.props.setLessonCompleted(lessonId, userInfo);
        } else if (postLessonQuestionData === undefined && lastVideo) {
            console.log('its probably a non-question-having lesson');
            this.props.setLessonCompleted(lessonId, userInfo);
        } 

        this.props.history.push(`${getBaseUrl()}app/lessons`);
    }

    @boundMethod
    private printWorksheet() {
        const handoutName = this.props.lessonId.replace(':', '_');
        window.open(`/assets/handouts/${handoutName}.pdf`, '_blank');
    }

    @boundMethod
    private handleVideoStart() {
        // this.props.updateCurrentTopic(this.props.lessonId, this.state.selectedTopic.topicId);

        this.playing = true
    }

    /**
     * Callback for after video ends (either through manual stop or "naturally")
     * @param endedNaturally === the video playing all the way to the end.
     */
    @boundMethod
    private handleVideoEnd(endedNaturally: boolean) {
        // Video ended, send the stats now!
        this.playing = false
        this.sendStats()
        
        // TODO throttle this and/or add loading bar so the transition isn't so sudden
        const nextTopic = this.getNextTopic();
        if(nextTopic === this.state.selectedTopic) { // if we've finished the last video...
            const { postLessonQuestionData } = this.state;
            // Set the currentContent to the first post-lesson question if there are post-lesson questions
            if(!postLessonQuestionData) {
                console.log({ endedNaturally, postLessonQuestionData, lastVideo: this.state.lastVideo });
                this.setState({ lastVideo: true });
                return;
            }

            const { questionGroups } = postLessonQuestionData || {} as any;
            const firstMCQuestion = questionGroups && questionGroups[0] && questionGroups[0].questions ?
                questionGroups[0].questions[0] : undefined;
            if(firstMCQuestion && endedNaturally) {
                this.props.setCurrentQuestion(
                    this.props.lessonId,
                    MaterialType.POST_LESSON_QUESTION,
                    firstMCQuestion.questionId, this.props.userInfo);
                this.setState({ lastVideo: true });
            } else {
                // Otherwise just finish (this will go to the summary page)
                this.props.onComplete();
            }
        }
        this.setState({ selectedTopic: nextTopic });        
    }

    @boundMethod
    private handleVideoPause() {
        this.playing = false
    }

    @boundMethod
    private resetStats() {
        this.timerCntr = 0
        this.timedout = false
    }

    @boundMethod
    private sendStats() {
        try {
            let {lesson} = this.state 
            if (lesson) {
                let context = Context.LESSON
                let contextId = lesson.lessonId
                let resource = Resource.VIDEO
                let resourceId = this.state.selectedTopic?.videoId
                let category = lesson.category.categoryName
                let timedout = this.timedout
                let ended = this.timerCntr
                let userActivityEx: UserActivityEx = {
                    context, contextId, 
                    resource, resourceId, 
                    category, ended, timedout
                }
    
                sendUserActivity(userActivityEx)
            }
        }
        catch (ex) {
            console.error("Error in LessonVideo sendStats:", ex)
        }
        
        this.resetStats()
    }

    @boundMethod
    private getNextTopic() {
        const { selectedTopic, lesson } = this.state;
        if (lesson !== undefined && selectedTopic !== undefined) {
          const nextVideoIndex = lesson.topics
              .findIndex(l => l.topicName === selectedTopic.topicName) + 1;
          if(nextVideoIndex >= lesson.topics.length || nextVideoIndex < 0) {
              return selectedTopic;
          }

          return lesson.topics[nextVideoIndex];
        }
        // UGH...
        return {} as Topic;
    }

    private getTopicWithVideo(videoId: string) {
        const { lesson } = this.state;
        if (lesson !== undefined) {
            const ret = lesson.topics.find(topic => topic.videoId === videoId);
            if(!ret) { return {} as Topic; }
            return ret;
        } else {
            return {} as Topic;
        }
    }

    private getTopicWithLesson(topicName: string) {
        const { lesson } = this.state;
        if (lesson !== undefined) {
            const ret = lesson.topics.find(topic => topic.topicName === topicName);
            if(!ret) { 
                return {} as Topic; 
            }
            return ret;
        } else {
            return {} as Topic;
        }
    }

    @boundMethod
    private handleModalClose() {
        this.setState({ lastVideo: false });
    }

    private handlePdfModalClose() {
        this.setState({ pdfPopup: false });
    }

    private findTopicFromLesson(lesson: Lesson, topicId: string) {
        if(!topicId) { return lesson.topics[0]; }

        const ret = lesson.topics.find(topic => topic.topicId === topicId);
        if(!ret) { return lesson.topics[0]; }
        return ret;
    }
}
