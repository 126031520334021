import './LessonCard.css';

import * as React from 'react';

import Card from '../../../../components/Card/Card';
import { ProgressCircle } from '../ProgressCircle/ProgressCircle';

interface Props {
    completed: number;
    current: number;
    total: number;
}

const LessonCard: React.SFC<Props> = (props: Props) => {

    return (
        <div className="dash-lesson-container">
            <Card title="My Lessons" titleColor="blue" link="/app/lessons" classTitle="">
                <div className="row justify-content-center dash-lesson-row pb-3">
                    <div className="px-2">
                        <ProgressCircle
                            title="Completed"
                            progress={props.completed}
                            img="/assets/images/icons/v2/vis-ico-summary-filled.svg"
                        />
                    </div>
                    <div className="px-2">
                        <ProgressCircle
                            title="In Progress"
                            progress={props.current}
                            img="/assets/images/icons/v2/vis-ico-resume-filled.svg"
                            />
                    </div>
                    <div className="px-2">
                        <ProgressCircle
                            title="To Start"
                            progress={props.total}
                            img="/assets/images/icons/v2/vis-ico-start-filled.svg"
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
};

LessonCard.displayName = 'LessonCard';

export default LessonCard;
