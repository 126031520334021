import * as React from 'react';

import { AppState } from '../../../../store';
import MissedQuestions from './MissedQuestions';
import { connect } from 'react-redux';

const mapStateToProps = ({ lesson }: AppState) => ({
    subjects: { ...lesson.userLessons.bySubject }
});

const ConnectedMissedQuestions =  connect(mapStateToProps, {})(MissedQuestions);

export default (props) => {
    return (
        <ConnectedMissedQuestions {...props} />
    );
};
