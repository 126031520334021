import * as React from 'react';
import { getStudentLessonInfo, sendTonPInsightsReport } from '../../services/elearn/lesson-rest-interface';
import ReportLoadingMessage from './ReportLoadingMessage';
import Modal from '../../components/Modal/ModalEmail';
import ConversionModal from '../../components/ConversionModal/ConversionModal';
import LessonSummary from '../../components/LessonSummary';
import { sendStudentReport } from '../../services/elearn/lesson-rest-interface';
import { LessonReportSummary, getDefaultLessonReportSummary } from '../../services/elearn/lesson-types';
import SummaryFilters from './SummaryFilters';
import { IndividualDataCard } from './IndividualDataCard';
import { TeacherStore, ITeacherStoreData, NotificationType } from '../../stores/teacher.store';
import { GraduationYearDescriptor, StudentData } from './types';
import './IndividualDataOverview.css';
import { NO_STUDENT_INFO_FOUND } from './info'
import { createFileName } from '../../services/utils/filename';
import { TimeOnPlatformLessonProcessor } from './TimeOnPlatform/top-lesson-processor';
import TimeOnPlatformIndividualView from './TimeOnPlatform/TimeOnPlatformIndividualView';
import { NOT_SPECIFIED } from '../../constants';

const imagePath = '/assets/images/icons/reports';
const imagePrint = `${imagePath}/print-solid-light.svg`
const imageSave = `${imagePath}/file-download-solid-light.svg`

export interface Props { }

interface State {
  selectedSchoolName?: string
  selectedGraduationYear?: string
  selectedPeriod?: string
  schools: string[]
  graduationYears: GraduationYearDescriptor[]
  showEmailNotificationModal: boolean
  showScoreConversion: boolean
  selectedTimeRange?: string
  selectedTestKind?: string
  searchQuery?:string
  data: StudentData[]
  lessonData: LessonReportSummary[]
  selectedData: StudentData[]  
  anyCardSelected: boolean
  loaded: boolean
  reportTitleInModal: string
}

export default class IndividualDataOverview extends React.Component<Props, State> {
  unsubscribe
  timeOnPlatformLessonProcessor = new TimeOnPlatformLessonProcessor()
  
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSchoolName: undefined,
      data: [],
      lessonData: [],
      selectedData: [],
      schools: [],
      graduationYears: [],
      searchQuery: "",
      anyCardSelected: false,
      loaded: false,
      showEmailNotificationModal: false,
      showScoreConversion: false,
      selectedTestKind: "ACT",
      reportTitleInModal:""
    }
  }

  public componentDidMount() {
    TeacherStore.loadStudents()
    this.unsubscribe = TeacherStore.subscribe((d, t) => {
      this.setMainData(d, t)
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  setMainData(data: ITeacherStoreData, t?: NotificationType) {
    if (t !== NotificationType.STUDENTS && t !== NotificationType.GENERIC) {
      return
    }
    let { students } = data
    let nofStudents = students ? students.length: 0
    let initialMax = 500
    let nofInitialShow = nofStudents < initialMax ? nofStudents: initialMax
    let displayData = nofInitialShow > 0 ? students.slice(0, nofInitialShow): []
    this.setState({ data: displayData, selectedData: displayData,  loaded: true })

    if (nofInitialShow < nofStudents) {
      setTimeout(() => {
        this.setState({data: students, selectedData: students})
      }, 300)
    }
  }

  sendLessonReport() {
    sendStudentReport();
    this.setState({
      showEmailNotificationModal: true,
      reportTitleInModal: "Lesson Report Is On Its Way"
    });
  }

  sendTimeReport() {
    sendTonPInsightsReport();
    this.setState({
      showEmailNotificationModal: true,
      reportTitleInModal: "Time on Platform Report Is On Its Way"
    });
  }

  onGraduationYearSelected(selectedYear: string) {
    this.setState(
      { selectedGraduationYear: selectedYear === 'All' ?  
          undefined : selectedYear
      }, 
      () => this.applyFilter()
    );
  }

  onNewSchoolSelected(selectedSchool: string) {
    this.setState({
        selectedSchoolName: selectedSchool === 'All' ? 
          undefined : selectedSchool 
      }, () => this.applyFilter()
    );
  }

  onNewPeriodSelected(selectedPeriod: string) {
    this.setState({
      selectedPeriod: selectedPeriod === 'All' ? 
          undefined : selectedPeriod 
      }, () => this.applyFilter()
    );
  }

  onTimeRangeSelected(selected: string) { }

  onTestKindSelected(newlySelected: string) {
    this.setState({
      selectedTestKind: newlySelected }, 
      () => this.applyFilter()
    )
  }

  private applyFilter = () => {
    let { data, selectedGraduationYear, selectedSchoolName, selectedPeriod } = this.state
    // Clear selections (i.e. make show details -> false)
    data.forEach(d => d.showDetails = false)
    let selectedData = data.filter(d => {
      let selectStudent = true
      let period = d.period || NOT_SPECIFIED
      if ((selectedGraduationYear && selectedGraduationYear !== d.graduationYear) ||
          (selectedSchoolName && selectedSchoolName !== d.schoolName) ||
          (selectedPeriod && selectedPeriod !== period)) {
        selectStudent = false
      }
      return selectStudent
    })
    this.setState({selectedData})
  }

  handleSearch = (searchQuery) => {
    let { data } = this.state
    
    this.setState({searchQuery})

    searchQuery = searchQuery ? searchQuery.trim().toLowerCase(): ""

    if (!searchQuery) {
      this.setState({selectedData: data})
      return
    }

    let keys = searchQuery.split(/[ *]/).filter(k => k)
    let selectedData = this.state.data.filter(d => {
      let firstName = d.firstName ? d.firstName.toLowerCase() : ""
      let lastName = d.lastName ? d.lastName.toLowerCase() : ""
      let combined = `${firstName} ${lastName}`
      let included = true
      let selectionMap = {}

      keys.forEach(key => selectionMap[key] = combined.includes(key) ? true: false)
      Object.keys(selectionMap).forEach(k => {
        if (!selectionMap[k]) {
          included = false
        }
      })

      return included
    })
    this.setState({ selectedData })
  }

  clearSearch = () => {
    this.setState({ searchQuery: "" })
    this.handleSearch("")
  }

  handleToggleDetails = (data: StudentData) => {
    data.showDetails = !data.showDetails
    this.setState({selectedData: this.state.selectedData})

    if (!data.detailsData) {
      setTimeout(() => {
        getStudentLessonInfo(data.emailAddress)
          .then(info => {
            if (info) {
              data.detailsObtainedAt = Date.now()
              data.detailsData = info.lessons.map(report => {
                return {
                  section: report.section,
                  lessonName: report.lessonName,
                  status: report.status,
                  preLessonAccuracy: report.preLessonAccuracy + "%",
                  postLessonAccuracy: report.postLessonAccuracy + "%",
                  lessonGrowth: report.lessonGrowth + "%",
                  hintsOnIncorrect: report.hintsOnIncorrect + "%",
                  overallQuestionAccuracy: report.overallQuestionAccuracy + "%",
                  completedQuestionAccuracy: report.completedQuestionAccuracy + "%",
                  totalQuestionAccuracy: report.totalQuestionAccuracy + "%",
                  totalQuestions: report.totalQuestions,
                  totalBaselineQuestions: report.totalBaselineQuestions,
                  infoPreLessonAccuracy: report.infoPreLessonAccuracy,
                  infoCompletedQuestionAccuracy: report.infoCompletedQuestionAccuracy,
                  infoLessonGrowth: report.infoLessonGrowth,
                  infoHintsOnIncorrect: report.infoHintsOnIncorrect,
                  infoTotalQuestionAccuracy: report.infoTotalQuestionAccuracy
                }
              })
              this.setState({selectedData: this.state.selectedData })
            }
          })
      }, 500)
    }
  }

  printPage = () => {
    window.print()
  }

  countSelected = () => {
    let { data } = this.state
    let cntr = 0
    if (data) {
      data.forEach(d => {
        if (d.showDetails) cntr++
      })
    }
    return cntr
  }

  // Download the selected students' data as .csv export
  downloadSelected = () => {
    let fileName = createFileName("selected-students-info", "csv")
    let elm = document.createElement("a")
    let initialText = "First Name, Last Name, Email, Section, Lesson Name, Status, Pre-Lesson Accuracy, Completed Question Accuracy, Lesson Growth, % Hints on Incorrect Qs, Total Question Accuracy\n"
    let seperator = ", "
    let notApplicaple = "N/A"
    let text  = this.state.selectedData.map(d => {
      let baseInfo = d.firstName + ", " + d.lastName + ", " + d.emailAddress
      let line = ""
      if (d.detailsData && d.showDetails) {
        d.detailsData.forEach(details => {
          line += baseInfo + seperator 
            + details.section + seperator
            + details.lessonName?.replace(/,/g, "") + seperator
            + details.status + seperator
            + details.infoPreLessonAccuracy + seperator
            + details.infoCompletedQuestionAccuracy + seperator
            + details.infoLessonGrowth + seperator
            + details.infoHintsOnIncorrect + seperator
            + details.infoTotalQuestionAccuracy
            + "\n"
        })
      }
      return line
    })
    .reduce((acc, cur) => {
      acc += cur
      return acc
    }, initialText)

    elm.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text))
    elm.setAttribute("download", fileName)
    elm.style.display = "none"
    document.body.appendChild(elm)
    elm.click()
    document.body.removeChild(elm)
  }

  public render() {
    const { data, lessonData, selectedData, loaded } = this.state;
    let cntr = 0
    let info = NO_STUDENT_INFO_FOUND
    const { selectedSchoolName, selectedGraduationYear, selectedTimeRange, selectedPeriod, selectedTestKind, searchQuery } = this.state;
    let selections = {selectedSchoolName, selectedGraduationYear, selectedTimeRange, selectedPeriod, selectedTestKind, searchQuery}

    return (
        <div className="lesson-overview">
          <SummaryFilters 
            enableTimeRangeSelection={false}
            enablePeriodSelection={true}
            enableTestKindSelection={true}
            onNewGraduationYearSelected={(gradYear) => this.onGraduationYearSelected(gradYear)}
            onNewSchoolSelected={(schoolSel) => this.onNewSchoolSelected(schoolSel)}
            onNewPeriodSelected={(period) => this.onNewPeriodSelected(period)}
            onNewTimeRangeSelected={(timeRange) => this.onTimeRangeSelected(timeRange)}
            onNewTestKindSelected={(testKind) =>this.onTestKindSelected(testKind)}
          />

          {!loaded && <ReportLoadingMessage />}
          
          <div className="individual-data-section">
            <div className="individual-data-section-header">
              <h2 className="h3 individual-data-title mb-2 mb-lg-0 d-inline-block" style={{padding:'0 10px 0 0'}}>
                Individual Student Data
              </h2>
              <div className="tools" style={{marginLeft:'0'}}>
              </div>
              <div className="search-box">
                <a title="Email Performance Report" href="#" onClick={() => this.sendLessonReport()} className="emailReport mr-2 iconLink--left img-rel">
                   <img
                    src="/assets/images/icons/v2/ico-email.svg"
                    className="top-0"
                    width="14"
                    height="11"
                    alt="" />
                  <span className="label def">Email Performance Report</span>
                </a>
                <a title="Email Time Report" href="#" onClick={() => this.sendTimeReport()} className="emailReport mr-2 iconLink--left img-rel">
                   <img
                    src="/assets/images/icons/v2/ico-email.svg"
                    className="top-0"
                    width="14"
                    height="11"
                    alt="" />
                  <span className="label def">Email Time Report</span>
                </a>
                <input  
                  type="text" 
                  className="input-search"
                  placeholder="Student Search" 
                  value={this.state.searchQuery}
                  onChange={e => this.handleSearch(e.target.value)} /> 

                <button title="Clear search" className="btn-simple clear-btn" onClick={this.clearSearch}>x</button>
              </div>
            </div>
            <hr/>
            <TimeOnPlatformIndividualView selections={selections} studentsData={selectedData}/>
          </div>
          
          <ConversionModal 
            show={this.state.showScoreConversion}
            onClose={() => this.setState({ showScoreConversion: false})}
          />
          
          <Modal 
            show={this.state.showEmailNotificationModal}
            onClose={() => this.setState({ showEmailNotificationModal: false })}
          >
            <div className="lesson-summary-email-notification-modal">
              <h3>{this.state.reportTitleInModal}</h3>
              <p>
                The full report for your students is on its 
                way and will arrive via email in the next 2-3 minutes.
              </p>
            </div>
          </Modal>
        </div>
    )
  }
}
