import * as React from 'react';

import './index.css';
import TestAnalysisSectionHeader from '../TestAnalysisSectionHeader';

interface Props {
    children: any
    sectionName: string
    defaultImagePath?: string
}

const ReportSection = (props) => {
    let { children, sectionName, defaultImagePath } = props
    return (
        <div className="topic-test-section">
            <TestAnalysisSectionHeader sectionName={sectionName} defaultImagePath={defaultImagePath}/>
            {children}
        </div>
    );
};

export default ReportSection;
