import React from "react"
import { round } from "../../../services/utils/math-util"
import { CATEGORY_ENGLISH, CATEGORY_MATH, CATEGORY_READING, CATEGORY_SCIENCE, EMPTY, NA } from "../../../constants"

export interface TestViewProps { 
  ind: number
  schoolName: string
  testName: string
  data: any
  onShowStudentsTestsInsights: (schoolName: string, testName: string, graduationYear?: string) => void
  onShowStudentsTestsDetailsByCategory: (category: string, schoolName: string, testName: string, graduationYear?: string) => void
}

export interface TestViewState { 
  showDetails: boolean
}

export const GraduationYearsViewACTandIA = (props) => {
  let {graduationYears, ind, schoolName, testName, onShowStudentsTestsInsights, onShowStudentsTestsDetailsByCategory} = props

  return ( 
    <>
      {graduationYears.map(graduationYear => {
        let {year, details} = graduationYear
        let {nofStudents, composite, english, math, reading, science} = details
        let avgC = composite?.avg ?? 0
        let avgE = english?.avg ?? 0
        let avgM = math?.avg ?? 0
        let avgR = reading?.avg ?? 0
        let avgS = science?.avg ?? 0
        let infoAvgC = nofStudents > 0 ? avgC: EMPTY
        let infoAvgE = nofStudents > 0 ? avgE: EMPTY
        let infoAvgM = nofStudents > 0 ? avgM: EMPTY
        let infoAvgR = nofStudents > 0 ? avgR: EMPTY
        let infoAvgS = nofStudents > 0 ? avgS: EMPTY
        let infoCRE = nofStudents > 0 ? <span className="college-ready-info">{english?.collegeReady}% College Ready</span>: EMPTY
        let infoCRM = nofStudents > 0 ? <span className="college-ready-info">{math?.collegeReady}% College Ready</span>: EMPTY
        let infoCRR = nofStudents > 0 ? <span className="college-ready-info">{reading?.collegeReady}% College Ready</span>: EMPTY
        let infoCRS = nofStudents > 0 ? <span className="college-ready-info">{science?.collegeReady}% College Ready</span>: EMPTY
        let nC = composite?.nofStudents
        let nE = english?.nofStudents
        let nM = math?.nofStudents
        let nR = reading?.nofStudents
        let nS = science?.nofStudents
        let infoNC = nofStudents > 0 ? `n=${nC}`: EMPTY
        let infoNE = nofStudents > 0 ? `n=${nE}`: EMPTY
        let infoNM = nofStudents > 0 ? `n=${nM}`: EMPTY
        let infoNR = nofStudents > 0 ? `n=${nR}`: EMPTY
        let infoNS = nofStudents > 0 ? `n=${nS}`: EMPTY

        // Full info for the categories
        let fullInfoC = nofStudents > 0 && nC > 0 ? <span>{infoAvgC}<br/>{infoNC}</span>: NA
        let fullInfoE = nofStudents > 0 && nE > 0 ? 
          <span>
            {infoAvgE}
            <br/>
            {avgE > 0 ? 
              <span className="info-line" title={ english?.collegeReady + `% College Ready`}>
                {infoCRE}
                <br/> 
                {infoNE} 
                <br/> 
                <StudentReportButton titleAttr={testName} text="English Report" onClick={() => onShowStudentsTestsDetailsByCategory(CATEGORY_ENGLISH, schoolName, testName, year)}/>
              </span> 
              : 
              EMPTY 
            }
          </span>
          : 
          NA
        
        let fullInfoM = nofStudents > 0 && nM > 0 ? 
          <span>
            {infoAvgM} 
            <br/>
            {avgM > 0 ? 
              <span className="info-line" title={ english?.collegeReady + `% College Ready`}>
                {infoCRM} 
                <br/> 
                {infoNM} 
                <br/>
                <StudentReportButton titleAttr={testName} text="Math Report" onClick={() => onShowStudentsTestsDetailsByCategory(CATEGORY_MATH, schoolName, testName, year)}/>
              </span> 
              : 
              EMPTY 
            } 
          </span>
          : 
          NA
          
        let fullInfoR = nofStudents > 0 && nR > 0 ? 
          <span>
            {infoAvgR}
            <br/>
            {avgR > 0 ? 
              <span className="info-line" title={ english?.collegeReady + `% College Ready`}>
                {infoCRR} 
                <br/>
                {infoNR}
                <br/>
                <StudentReportButton titleAttr={testName} text="Reading Report" onClick={() => onShowStudentsTestsDetailsByCategory(CATEGORY_READING, schoolName, testName, year)}/>
              </span> 
              : 
              EMPTY 
            }
          </span>
          : 
          NA

        let fullInfoS = nofStudents > 0 && nS > 0 ? 
          <span>
            {infoAvgS}
            <br/>
            {avgS > 0 ? 
              <span className="info-line" title={ english?.collegeReady + `% College Ready`}>
                {infoCRS} 
                <br/> 
                {infoNS}
                <br/>
                <StudentReportButton titleAttr={testName} text="Reading Report" onClick={() => onShowStudentsTestsDetailsByCategory(CATEGORY_SCIENCE, schoolName, testName, year)}/>
              </span> 
              : 
              EMPTY
            }
          </span>
          : 
          NA

        return (
          <tr key={year} className={`test-view-inner ${(ind % 2 == 0) ? 'odd' : 'even'}`}>
            <td className="graduation-year-info">
              Class of {year}
              <br/>
              <StudentReportButton titleAttr={`${schoolName}: Class of ${year}`} onClick={() => onShowStudentsTestsInsights(schoolName, testName, year)}/>
            </td>
            <td className="cell-composite">{fullInfoC}</td>
            <td className="">{fullInfoE}</td>
            <td className="">{fullInfoM}</td>
            <td className="">{fullInfoR}</td>
            <td className="">{fullInfoS}</td>
          </tr>
        )
        })
      }
    </>
  )
}

export const GraduationYearsViewSAT = (props) => {
  let {graduationYears, ind, onShowStudentsTestsInsights, schoolName, testName} = props
  return ( 
    <>
      {graduationYears.map(graduationYear => {
        let {year, details} = graduationYear
        let {nofStudents, math, ebrw} = details
        let nofStudentsEBRW = ebrw?.nofStudents
        let nofStudentsM = math?.nofStudents
        let avgEBRW = round(details.ebrw?.avg)
        let avgM = round(details.math?.avg)
        let score = round(avgM + avgEBRW) // Term "score" is used in the SAT tests!          
        let infoCRE = nofStudents > 0 ? <span className="college-ready-info">{ebrw?.collegeReady}% College Ready</span>: EMPTY
        let infoCRM = nofStudents > 0 ? <span className="college-ready-info">{math?.collegeReady}% College Ready</span>: EMPTY
        let infoScore = nofStudentsEBRW > 0 &&  nofStudentsM > 0 ? <span>{score}<br/>n={nofStudents}</span>: NA
        let infoEBRW = nofStudentsEBRW > 0 ? <span>{avgEBRW}<br/>{infoCRE}<br/>n={nofStudentsEBRW}</span>: NA
        let infoM = nofStudentsM > 0 ? <span>{avgM}<br/>{infoCRM}<br/>n={nofStudentsM}</span>: NA

        return (
          <tr className={`test-view-inner ${(ind % 2 == 0) ? 'odd' : 'even'}`}>
            <td className="graduation-year-info">
              Class of {year}
              <br/>
              <StudentReportButton titleAttr={`${schoolName}: Class of ${year}`} onClick={() => onShowStudentsTestsInsights(schoolName, testName, year)}/>
            </td>
            <td className="cell-composite">{infoScore}</td>
            <td className="">{infoEBRW}</td>
            <td className="">{infoM}</td>
          </tr>
        )
        })
      }
    </>
  )
}


//--- Simple Helper Components ---

export function StudentReportButton(props) {
  let {width=12, height=12, text="Student Report", className, onClick, tabindex, titleAttr} = props

  return (
    <span className={className}>
      <button title={`View ${titleAttr} ${text} Details, opens a modal window`} className="table-report-btn btn-simple" onClick={onClick} tabIndex={tabindex}>
        <img src="/assets/images/icons/v2/ico-modal.svg" 
          width={width} 
          height={height} 
          style={{position: "relative", top: -1, marginRight: 5}}
          alt=""
        />
        {text}
      </button>
    </span>
  )
}
