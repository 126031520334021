import './MCPage.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import {
    CurrentLessonContent,
    LessonProgress,
    MaterialType,
    Question,
    QuestionGroup
} from '../../services/elearn/lesson-types';
import { getLessonProgress, setBaselineAnswer, setMCAnswer } from '../../services/user/user-lesson-rest-interface';

import ContextualTitle from '../ContextualTitle/ContextualTitle';
import Loading from '../Loading/Loading';
import MultipleChoicePassage from '../MultipleChoicePassage/MultipleChoicePassage';
import MultipleChoiceQuestion from '../MultipleChoiceQuestion/MultipleChoiceQuestion';
import { PageContent } from '../PageContent/PageContent';
import QuestionProgressBar from '../../scenes/Lesson/components/QuestionProgressBar/QuestionProgressBar';
import { addQuestionToMistakeBank } from '../../services/elearn/lesson-rest-interface';
import { updateActivityInfoWithLastKnownNavigation } from '../../WinwardTracker';
import { Context, Resource, sendUserActivity, UserActivityEx } from '../../services/liveview/liveview-service';
import Timer from '../Timer/Timer'
import TimeoutDialog from '../TimeoutDialog/TimeoutDialog'

export interface Props {
    /* LessonID for the current lesson */
    lessonId: string;
    /* Whether or not this should display baseline questions or mc questions */
    isBaseline?: boolean;
    /* Fired once all questions have been answered */
    onComplete: () => void;
    /* Called when the user hits the "Exit Lesson" button */
    handleExitLesson?: () => void;

    setLessonComplete?: () => void;

    questionGroups: QuestionGroup[];

    shouldShowHintPopup?: boolean;

    // pdfprintout
    pdfPrintout?: string;

    isTeacher: boolean;
    userInfo: any;
}

export interface DispatchProps {
    /* Set the "currentContent" in the database */
    setCurrentQuestion: (lessonId: string, type: MaterialType, id: string, userInfo: any) => Promise<void>;
    setResume: (lessonId: string, topicId: string) => Promise<void>;
    /* Increments the total number of questions answered for this lesson (MC questions only) */
    incrementQuestionsAnswered: (lessonId: string) => Promise<void>;
    // tslint:disable-next-line:max-line-length
    addQuestionToMistakeBank: (lesson: LessonProgress, question: Question, hint: string, userInfo: any) => void;
}

export interface StoreProps {
    /* User progress for this lesson */
    lesson?: LessonProgress;
    /* User's current content (question/topic) */
    currentQuestionId: CurrentLessonContent | null;
}

export type AllProps = Props & DispatchProps & StoreProps;

interface State {
    currentQuestionGroupIndex: number;
    /* The question a user is currently viewing/answering */
    currentQuestionIndex: number;
    /* Furthest back a user can look */
    minQuestion: number;
    /* Furthest question index that a user has gotten to (in case they go back to look at an old question) */
    farthestQuestionIndex: number;
    /* Used to track if the user has already answered this question or not, since we only record the first answer */
    firstSubmit: boolean;
    /* Questions from props.questionGroups, except flattened out into a single array */
    questions: Question[];
    isLoaded: boolean;
    addToMB: boolean;
    canNext: boolean;
    showTimeOutDialog: boolean
}

export default class MCPage extends React.Component<AllProps, State> {
    private answeredAtFirst = false
    private attempts = 0
    private answered = 0
    private correctlyAnswered = false
    private userActivityEx: UserActivityEx = {}
    private readonly TIMEOUT_IN_SECONDS = 900 // 15 mins
    private readonly INACTIVITY_TIMEOUT_IN_SECONDS = this.TIMEOUT_IN_SECONDS/2 // Half of timeout
    private timerId
    private inactivityCntr = 0

    constructor(props: AllProps) {
        super(props);

        const questions = (props.questionGroups || []).reduce((acc, q) => acc.concat(q.questions), [] as Question[]);
        const { questionIndex } = this.getCurrentQuestionIndex(props, questions);
        const currQId = questions[questionIndex].questionId;
        sessionStorage.setItem('currQId', currQId);
        this.state = {            
            currentQuestionGroupIndex: 0,
            currentQuestionIndex: questionIndex,
            minQuestion: questionIndex,
            farthestQuestionIndex: questionIndex,
            firstSubmit: true,
            questions,
            isLoaded: false,
            addToMB: false,
            canNext: false,
            showTimeOutDialog: false
        };
    }

    public componentDidMount() {
        const { questionGroups, lessonId, isTeacher, userInfo } = this.props;
        const { questions } = this.state;
        if(!questionGroups || questionGroups.length === 0) { return; }

        this.timerId = setInterval(() => {
            this.inactivityCntr++
            if (this.inactivityCntr > this.INACTIVITY_TIMEOUT_IN_SECONDS) {
                this.setState({showTimeOutDialog: true})
                Timer.pause()
            }
        }, 1000)

        // Set the current question in the database to the question the user is actually on
        // This needs to be done especially if the user is transitioning from a video lesson to MC questions
        // since the "currentContent" will be set to a topicID

        if (this.props.isTeacher) {
            this.setState({ currentQuestionIndex: 0 });
        }

        getLessonProgress(lessonId, userInfo).then((res) => {
            let currQuestionId, currentQuestionIdx;
            if (res.currentLessonContent && res.currentLessonContent.id) {
                currQuestionId = res.currentLessonContent.id;
                for (var i = 0; i < questions.length; i++) {
                    if (questions[i].questionId === currQuestionId) {
                        currentQuestionIdx = i;
                    }
                }
            } else {
                currentQuestionIdx = 0;
                this.sendLessonStartedInfo()
            }

            let groupIndex = this.getGroupIndexForQuestion(questionGroups, currentQuestionIdx);
            const currQId = questions[currentQuestionIdx].questionId;
            sessionStorage.setItem('currQId', currQId);
            this.setState({
                currentQuestionIndex: currentQuestionIdx,
                farthestQuestionIndex: currentQuestionIdx,
                currentQuestionGroupIndex: groupIndex,
                isLoaded: true
            });
        });
    }

    public componentWillUnmount() {
        if (this.state.addToMB && this.props.lesson) {
            addQuestionToMistakeBank(this.props.lesson, this.state.questions[this.state.currentQuestionIndex], 
                this.props.userInfo, '');
        }
        clearInterval(this.timerId)
    }

    public UNSAFE_componentWillReceiveProps(nextProps: AllProps) {
        if(nextProps.isBaseline !== this.props.isBaseline ||
            nextProps.lessonId !== this.props.lessonId) {
            const index = 0; // this.getCurrentQuestionIndex(nextProps);
            this.setState({ currentQuestionIndex: index, minQuestion: index, farthestQuestionIndex: index },
                () => {
                    if(this.getCurrentQuestionIndex(nextProps).questionIndex !== index) {
                        this.goToQuestion(0);
                    }
                });
        }
        const questions = (nextProps.questionGroups || [])
            .reduce((acc, q) => acc.concat(q.questions), [] as Question[]);
        this.setState({ questions });
    }

    @boundMethod
    public handleAnswerYes() {
        this.setState({showTimeOutDialog: false})
        this.inactivityCntr = 0
        Timer.resume()
    }

    @boundMethod
    public handleAnswerNo() {
        this.handleExitLesson()
    }

    public render() {
        const { currentQuestionIndex, currentQuestionGroupIndex, 
            farthestQuestionIndex, isLoaded, canNext } = this.state;
        const { isBaseline, lesson, questionGroups, pdfPrintout, isTeacher } = this.props;
        const { questions } = this.state;
        const currQId = questions[this.state.currentQuestionIndex].questionId;
        const topicId = questions[this.state.currentQuestionIndex].topicId;
        const pathArray = window.location.pathname.split('/');
        const lessonId = pathArray[pathArray.length - 1];
        sessionStorage.setItem('topicId', topicId);
        sessionStorage.setItem('currQId', currQId);
        sessionStorage.setItem('lessonId', lessonId);

        if(!lesson || !isLoaded) {
            return <Loading />;
        }

        if(currentQuestionIndex < 0 || currentQuestionIndex >= questions.length) {
            return <h1>Could not get question</h1>;
        }

        const currentQuestionGroup = questionGroups[currentQuestionGroupIndex];
        const currentQuestion = questions[currentQuestionIndex];
        const subTitle = isBaseline ? 'Baseline Questions' : 'Now Learning';
        let useMathFont = !!lesson && lesson.category.categoryName === 'Math';
        let activity = `${lesson?.category.categoryName}: ${lesson?.lessonName} ${isBaseline ? "- Baseline": ""} (Question ${currentQuestionIndex + 1})`
        
        updateActivityInfoWithLastKnownNavigation(activity)
        let {showTimeOutDialog} = this.state

        return (
            <PageContent preContentComponent={this.getQuestionProgressBar()}>
                {this.state.showTimeOutDialog && 
                    <TimeoutDialog 
                        show={showTimeOutDialog} 
                        onAnswerYes={this.handleAnswerYes}
                        onAnswerNo={this.handleAnswerNo}
                        />
                }
                <ContextualTitle 
                    title={lesson.lessonName} 
                    preText={subTitle} 
                    linkText={pdfPrintout} 
                    isTeacher={isTeacher} 
                    className="clear-50 question-title"
                />

                {currentQuestionGroup.questionGroupInfo ?
                    <MultipleChoicePassage
                        questionGroup={currentQuestionGroup}
                        currentQuestionGroupIndex={currentQuestionGroupIndex}
                        currentQuestionIndex={currentQuestionIndex}
                        farthestQuestionIndex={farthestQuestionIndex}
                        onAnswer={this.onQuestionAnswered}
                        onNextPassage={this.handleNextPassage}
                        onGoToQuestion={this.goToQuestion}
                        questions={questions}
                        addHint={this.addHint}
                        shouldShowHintPopup={this.props.shouldShowHintPopup}
                        useMathFont={useMathFont}
                        isTeacher={this.props.isTeacher}
                        addToMB={this.doIAddToMistakeBank}
                        canNext={this.state.canNext}
                    /> :
                    <MultipleChoiceQuestion
                        question={currentQuestion}
                        onAnswer={this.handleAnswer}
                        onNextQuestion={this.handleNextQuestion}
                        isBaseline={!!isBaseline}
                        userHasAnswered={currentQuestionIndex < farthestQuestionIndex}
                        isLastQuestion={currentQuestionIndex === questions.length - 1}
                        addHint={this.addHint}
                        shouldShowHintPopup={this.props.shouldShowHintPopup}
                        useMathFont={useMathFont}
                        isTeacher={this.props.isTeacher}
                        addToMB={this.doIAddToMistakeBank}
                        canNext={this.state.canNext}
                    />}

                {this.props.children}
            </PageContent>
        );
    }

    @boundMethod
    private doIAddToMistakeBank(add: boolean) {
        // console.log('Add to MB Checkbox is: ' + add);
        this.setState({
            addToMB: add
        });
    }

    private getQuestionProgressBar() {
        const questionNumbers = this.state.questions.reduce((acc: number[], question, i) => {
            const { questionNumber } = question;
            // Baseline questions don't have question numbers, so we need to just count as we go
            // if there is no questionNumber for this question
            return acc.concat([ questionNumber !== null && questionNumber !== undefined ? questionNumber :
                (acc[i - 1] + 1 || 1)]);
        }, []);

        return (
            <QuestionProgressBar
                questionNumbers={questionNumbers}
                currentQuestionIndex={this.state.currentQuestionIndex}
                farthestQuestionIndex={this.state.farthestQuestionIndex}
                onCloseClick={this.handleExitLesson}
                onQuestionClick={this.goToQuestion}
                disableNavigation={this.props.isBaseline}
                isTeacher={this.props.isTeacher}
            />
        );
    }

    @boundMethod
    private handleAnswer(isCorrect: boolean, answerIndex: number) {
        this.onQuestionAnswered(this.state.currentQuestionIndex, isCorrect, answerIndex);
    }

    @boundMethod
    private onQuestionAnswered(questionIndex: number, isCorrect: boolean, answerIndex: number) {
        this.inactivityCntr = 0

        // Stats
        this.attempts++
        if (isCorrect) {
            this.correctlyAnswered = true;
            if (this.attempts == 1) {
                this.answeredAtFirst = true
            }            
        }

        // If we didn't get the 'answered' stat yet then get it...
        // Note that this was earlier capturing the time for the correct answer
        // i.e. time to get correct answer
        // After our meeting, Jennifer wants this to be the first attempt time
        if (this.answered == 0) {
            this.answered = Timer.getSeconds()
            // If the student answered the question then freeze the timer 
            // it will still count but don't show the running timer
            // and it wil be unfrozen with next reset
            Timer.freezeUI()
        }

        // If this is a baseline question then send the stats...
        if (this.props.isBaseline) {
            this.sendStats()
        }


        // Only record the user's answer on their first submit
        const { lessonId, isBaseline, userInfo } = this.props;
        let answerLetter = ['A', 'B', 'C', 'D'][answerIndex];
        const currentQuestion = this.state.questions[questionIndex];
        const self = this;
        if (isBaseline) {
            if (answerIndex === -1) {
                answerLetter = 'no_clue';
            }
            setBaselineAnswer(lessonId, currentQuestion.questionId, isCorrect, answerLetter).then((res) => {
                this.onQuestionComplete(questionIndex);
                /* self.props.setResume(lessonId, currentQuestion.questionId); */
                this.handleNextQuestion();
            });
        } else {
            if (this.state.currentQuestionIndex === this.state.farthestQuestionIndex) {
                setMCAnswer(lessonId, currentQuestion.questionId, isCorrect, answerLetter, userInfo).then((res) => {
                    if (isCorrect) {
                        this.setState({
                            canNext: true
                        }, () => {
                            this.onQuestionComplete(questionIndex);
                        });
                    }
                });
            }
        }
        
    }

    /**
     * Go to next question
     */
    @boundMethod
    private handleNextQuestion() {
        this.goToQuestion(this.state.currentQuestionIndex + 1);
    }

    @boundMethod
    private sendStats() {
        try {
            let seconds = Timer.getSeconds()
            let isBaseline = this.props.isBaseline
            let currentQuestion = this.state.questions[this.state.currentQuestionIndex];
            let context = Context.LESSON
            let contextId = this.props.lesson?.lessonId
            let category = this.props.lesson?.category.categoryId
            let resource = isBaseline ? Resource.BASELINE: Resource.QUESTION
            let resourceId = currentQuestion.questionId
            let ended = seconds
            let {answered, answeredAtFirst, attempts, correctlyAnswered} = this
            let timedout = seconds > this.TIMEOUT_IN_SECONDS

            if (attempts == 0) {
                return
            }

            // If this is a baseline question then there is no "answered" concept, just "ended"
            // Also, baselines always move to the next question as soon as they are answered!
            if (isBaseline) {
                answered = 0
            }
            else {
                // For the MC questions "attempts" counted twice in the last step, so fix it here.
                attempts = --attempts < 0 ? 0: attempts
            }

            this.userActivityEx = {...this.userActivityEx, 
                context, contextId, resource, resourceId, category,
                answered, ended, answeredCorrectly: correctlyAnswered, answeredAtFirst, attempts, timedout}

            sendUserActivity(this.userActivityEx)
        }
        catch (ex) {
            console.error("Error in sendStats ", ex)
        }
        
        this.resetStats()
    }

    @boundMethod
    private resetStats() {
        this.answered = 0
        this.attempts = 0
        this.answeredAtFirst = false
        this.correctlyAnswered = false
        this.inactivityCntr = 0
        Timer.reset()
    }

    @boundMethod
    private sendLessonStartedInfo() {
        let context = Context.LESSON
        let contextId = this.props.lesson?.lessonId
        let resource = Resource.START
        let category = this.props.lesson?.category.categoryId
        let userActivityEx = {context, contextId, resource, category}
        sendUserActivity(userActivityEx)
    }

    @boundMethod
    private sendLessonEndedInfo() {
        let isBaseline = this.props.isBaseline
        if (isBaseline) {
            // Baselines are the initial question sets for the lessons.
            // So we should not mark the lesson end here but later on the MCs.
            // Since MCPage is generic it is used for both baselines and MCs 
            // therefore we need to return here!
            return
        }
        let context = Context.LESSON
        let contextId = this.props.lesson?.lessonId
        let resource = Resource.END
        let category = this.props.lesson?.category.categoryId
        let userActivityEx = {context, contextId, resource, category}
        sendUserActivity(userActivityEx)
    }

    private handleNextPassage() {
        // TODO
    }

    /**
     * Navigate to a particular question by index
     */
    @boundMethod
    private goToQuestion(questionIndex: number) {

        if(questionIndex < 0) {
            return;
        }

        let {isBaseline} = this.props
        // In the MCs, the UI always shows the Next button in order to progress to the next
        // So we will send the stats when handling the next question (i.e. here)
        if (!isBaseline) {
            let {farthestQuestionIndex} = this.state
            // In MC questions students may go back to the previous ones by clicking on the question number.
            // So make sure we don't send stats when they navigate to the earlier questions.
            if (questionIndex >= farthestQuestionIndex) {
                this.sendStats()
            }
        }
    
        if (questionIndex >= this.state.questions.length) {
            this.props.onComplete();
            // This lessons is completed so send TonP info
            this.sendLessonEndedInfo()
        } else {
            this.setState({
                currentQuestionIndex: questionIndex,
                currentQuestionGroupIndex: this.getGroupIndexForQuestion(this.props.questionGroups, questionIndex),
                firstSubmit: true,
                canNext: false
            });
            sessionStorage.setItem('currQId', this.state.questions[questionIndex].questionId);
        }
    }

    @boundMethod
    private addHint(hint: string, display: boolean) {
        if(this.props.lesson) {
            addQuestionToMistakeBank(this.props.lesson,
                this.state.questions[this.state.currentQuestionIndex], hint, this.props.userInfo, display);
        }
    }

    /**
     * Only called once a question is "complete". This will go to the next question and set the user's
     * "currentContent" to the next question's questionID
     *
     * NOTE:
     * For baseline questions, a question is considered complete once the user answers and hits submit.
     * For MC questions, a question is not considered complete until the user selects the correct answer
     */
    private onQuestionComplete(completedQuestionIndex: number) {
        const nextQuestionIndex = completedQuestionIndex + 1;

        // If this is not a new question, return
        const oldFarthestQuestion = this.state.farthestQuestionIndex;
        if(nextQuestionIndex < oldFarthestQuestion) { return; }

        this.setState({
            farthestQuestionIndex: Math.max(this.state.farthestQuestionIndex, nextQuestionIndex)
        });

        if (this.state.farthestQuestionIndex === this.state.questions.length && this.props.setLessonComplete) {
            this.props.incrementQuestionsAnswered(this.props.lessonId);
            this.props.setLessonComplete();
        } else {
            if(!this.props.isBaseline) {
                this.props.incrementQuestionsAnswered(this.props.lessonId);
            }
        }
    }

    private getCurrentQuestionIndex(props: AllProps = this.props, questions: Question[] = this.state.questions) {
        const { lesson, questionGroups } = props;
        if(!lesson || !lesson.currentLessonContent || !lesson.currentLessonContent.id ||
                lesson.currentLessonContent.type === MaterialType.TOPIC) {
            return { questionIndex: 0, groupIndex: 0 };
        }

        const questionIndex = questions.findIndex(q => q.questionId === lesson.currentLessonContent.id);

        return {
            groupIndex: this.getGroupIndexForQuestion(questionGroups, questionIndex),
            questionIndex
        };
    }

    private getGroupIndexForQuestion(questionGroups: QuestionGroup[], questionIndex: number) {
        let groupIndex = 0;
        const numQuestionsPerGroup = questionGroups.map(group => group.questions.length);

        let totalQuestionsSoFar = 0;
        for(let i = 0; i < numQuestionsPerGroup.length; i++) {
            totalQuestionsSoFar += numQuestionsPerGroup[i];
            if(totalQuestionsSoFar > questionIndex) {
                groupIndex = i;
                break;
            }
        }

        return groupIndex;
    }

    @boundMethod
    private handleExitLesson() {
        if (this.props.handleExitLesson) {
            this.props.handleExitLesson();
        }

        // Send the stats if the student attempted and at some point answered correctly!
        if (this.attempts > 0 && (this.props.isBaseline || this.correctlyAnswered)) {
            this.sendStats()
        }
    }
}
