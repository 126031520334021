import {
    getCurrentLessonContent,
} from '../../stores/lesson.store';

import { AppState } from '../../store';
import LessonVideo, { Props } from './RewatchLessonVideo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = ({ lesson, account }: AppState) => {
    const currentLessonContent = getCurrentLessonContent(lesson);
    return {
        currentTopicId: currentLessonContent ? currentLessonContent.id : '',
        userInfo: account.userInfo
    };
};

const ConnectedLessonVideo = connect<any, any, any, any>(mapStateToProps, {})(LessonVideo);

export default withRouter(ConnectedLessonVideo);
