import * as React from 'react';

import { setLessonInProgress, setTopicReviewStatus } from '../../../../stores/lesson.store';

import { AppState } from '../../../../store';
import MistakeBankTopic from './MistakeBankTopic';
import { connect } from 'react-redux';

const mapStateToProps = ({ account }: AppState) => ({
    userInfo: account.userInfo
});

const mapDispatchToProps = { setTopicReviewStatus, setLessonInProgress };

const ConnectedMistakeBankTopic = 
    connect<any, any, any, AppState>(mapStateToProps, mapDispatchToProps)(MistakeBankTopic); 

export default (props: any) => (
    <ConnectedMistakeBankTopic lessonId={props.match.params.lessonId} topicId={props.match.params.topicId} {...props} />
);
