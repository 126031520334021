// Misc math/stats related functions

// Get average value from an array
export const avg = (vals: number[], round=false) => {
  let res = vals.length > 0 ? vals.reduce((acc, cur) => acc += cur, 0)/vals.length: 0
  return round ? Math.round(res): res
}

// Get summation from an array
export const sum = (vals: number[]) => vals.reduce((acc, cur) => acc += cur, 0)

// Rounds a given number
export const round = (num) => Math.round(num)

/**
 * Finds the weighted mean (average) and returns the value
 * @param arr array of weights and values
 */
 export const weightedMean = (arr: {weight: number, value: number}[]) => {
  let {sum, totalWeights} = arr.reduce((acc, cur) => {
    acc.sum += cur.weight*cur.value
    acc.totalWeights += cur.weight
    return acc
  }, {sum: 0, totalWeights: 0})

  return totalWeights > 0 ? sum/totalWeights: -1
}
