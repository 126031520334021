import './IALineGraph.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';
import {
  getLineGraphData,
  updateIATest,
  getPTLineGraphData,
  getSATPTLineGraphData
} from '../../../../services/practice-test-scoring/practice-test-service';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    linearGradient,
    Label,
    LabelList,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Area,
    AreaChart
} from 'recharts';
import { start } from 'repl';
import IAXAxisButton from './components/IAXAxisButton/IAXAxisButton';

interface Props {
    subject: string;
    updateSummary: (testName: string) => void;
    testType: string;
}

interface State {
    compositeCoordinates: Array<any>;
    englishCoordinates: Array<any>;
    mathCoordinates: Array<any>;
    readingCoordinates: Array<any>;
    scienceCoordinates: Array<any>;
    evidenceBasedRWCoordinates: Array<any>;
}


export default class IASummaryCard extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            compositeCoordinates: [],
            englishCoordinates: [],
            mathCoordinates: [],
            readingCoordinates: [],
            scienceCoordinates: [],
            evidenceBasedRWCoordinates: []
        };
    }

    componentDidMount() {
      const self = this;
      let testType = window.location.href.substr(window.location.href.lastIndexOf('/') + 1); 
      let interimAssessment = false;
      if(window.location.href.includes('/ia/')) {
          interimAssessment = true;
      }
      if (this.props.testType === 'practice-tests' && !interimAssessment) {
          if(!testType.includes('sat')) {
              getPTLineGraphData().then(populateLineGraph);
          } else {
              getSATPTLineGraphData().then(populateLineGraph);
          }
      } else {
           getLineGraphData().then(populateLineGraph);
      }
      function populateLineGraph(res: any) {
        function adjustAttributes(a: any) {
            a.Score = a.score;
            delete a.score;
            return a;
        }
        res.compositeCoordinates = res.compositeCoordinates.map(adjustAttributes);
        if(!testType.includes('sat')) {
            res.englishCoordinates = res.englishCoordinates.map(adjustAttributes);
            res.mathCoordinates = res.mathCoordinates.map(adjustAttributes);
            res.readingCoordinates = res.readingCoordinates.map(adjustAttributes);
            res.scienceCoordinates = res.scienceCoordinates.map(adjustAttributes);
            self.setState({
                compositeCoordinates: res.compositeCoordinates,
                englishCoordinates: res.englishCoordinates,
                mathCoordinates: res.mathCoordinates,
                readingCoordinates: res.readingCoordinates,
                scienceCoordinates: res.scienceCoordinates
            });
        } else {
            res.mathCoordinates = res.mathCoordinates.map(adjustAttributes);
            res.evidenceBasedRWCoordinates = res.evidenceBasedRWCoordinates.map(adjustAttributes);
            self.setState({
                compositeCoordinates: res.compositeCoordinates,
                mathCoordinates: res.mathCoordinates,
                evidenceBasedRWCoordinates: res.evidenceBasedRWCoordinates
            });
        }
      }
    }

    render() {
        let lineColor, startColor, endColor;
        endColor = '#ffffff';
        const data = this.state[this.props.subject];
        switch(this.props.subject) {
            case 'compositeCoordinates':
                lineColor = '#28D98D';
                startColor = '#28D98D';
                break;
            case 'englishCoordinates':
                lineColor = '#9d59cf';
                startColor = '#9d59cf';
                break;
            case 'mathCoordinates':
                lineColor = '#97EDFE';
                startColor = '#97EDFE';
                break;
            case 'readingCoordinates':
                lineColor = '#fbd79c';
                startColor = '#fbd79c';
                break;
            case 'scienceCoordinates':
                lineColor = '#CFFA92';
                startColor = '#CFFA92';
                break;
            case 'evidenceBasedRWCoordinates':
                lineColor = '#9d59cf';
                startColor = '#9d59cf';
                break;
            default:
        }
        let testType = window.location.href.substr(window.location.href.lastIndexOf('/') + 1); 
        if(testType.includes('sat')) {
            return (
                <div id="line-chart-container">
                    <ResponsiveContainer width="100%" height={350}>
                    <AreaChart data={data} margin={{top: 30, right: 30, left: 0, bottom: 0}}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="30%" stopColor={startColor} stopOpacity={1}/>
                            <stop offset="50%" stopColor={startColor} stopOpacity={0.8}/>
                            <stop offset="80%" stopColor={startColor} stopOpacity={0.6}/>
                            <stop offset="100%" stopColor={endColor} stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="x"
                            allowDecimals={false}
                            padding={{ left: 10 }}
                        />
                        <YAxis
                            type="number"
                            domain={[this.getSATLowerLimit, this.getSATUpperLimit]}
                        />
                        <Tooltip cursor={false} />
                        <Area
                            type="linear"
                            dataKey="Score"
                            stroke={lineColor}
                            fill="url(#colorUv)"
                            dot={{r: 4, strokeWidth: '2px', fill: `${lineColor}`}}
                            activeDot={{r: 8}}
                        >
                        </Area>
                    </AreaChart>
                    </ResponsiveContainer>
                </div>
            );
        } else {
            return (
                <div id="line-chart-container">
                    <ResponsiveContainer width="100%" height={350}>
                    <AreaChart data={data} margin={{top: 30, right: 30, left: 0, bottom: 0}}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="30%" stopColor={startColor} stopOpacity={1}/>
                            <stop offset="50%" stopColor={startColor} stopOpacity={0.8}/>
                            <stop offset="80%" stopColor={startColor} stopOpacity={0.6}/>
                            <stop offset="100%" stopColor={endColor} stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="x"
                            allowDecimals={false}
                            padding={{ left: 10 }}
                        />
                        <YAxis
                            type="number"
                            domain={[this.getLowerLimit, this.getUpperLimit]}
                        />
                        <Tooltip cursor={false} />
                        <Area
                            type="linear"
                            dataKey="Score"
                            stroke={lineColor}
                            fill="url(#colorUv)"
                            dot={{r: 4, strokeWidth: '2px', fill: `${lineColor}`}}
                            activeDot={{r: 8}}
                        >
                        </Area>
                    </AreaChart>
                    </ResponsiveContainer>
                </div>
            );
        }
        
    }

    private getLowerLimit(minimum: number) {
        if ((minimum - 2) <= 0) {
            return 0;
        } else {
            return minimum - 2;
        }
    }

    private getUpperLimit(maximum: number) {
        if ((maximum + 2) >= 36) {
            return 36;
        } else {
            return maximum + 2;
        }
    }

    private getSATLowerLimit(minimum: number) {
        if ((minimum - 40) <= 0) {
            return 400;
        } else {
            return minimum - 40;
        }
    }

    private getSATUpperLimit(maximum: number) {
        if ((maximum + 40) >= 1600) {
            return 1600;
        } else {
            return maximum + 40;
        }
    }
}
