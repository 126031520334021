import { StaySharpProgress, StaySharpByTopicID, Question } from './lesson-types';
import { createGenericAuthRequest, ignoreJsonError } from '../request/request-utils';

const request = createGenericAuthRequest(process.env.REACT_APP_ELEARN_URL || '/');

export function getStaySharpQuestions(lessonId: string, topicId: string): Promise<StaySharpByTopicID> {
    return request<StaySharpByTopicID>(`/stay-sharp/${lessonId}/${topicId}`)
        .catch(e => {
            console.error(`Could not get questions`);
            return Promise.reject(null);
        });
}

export function getDashboard(): Promise<StaySharpProgress[]> {
    return request<StaySharpProgress[]>(`/stay-sharp/dashboard`)
        .catch(e => {
            console.error(`Could not get dashboard`);
            return Promise.reject(null);
        });
}

// tslint:disable-next-line:max-line-length
export function setStaySharpMCAnswer(lessonId: string, topicId: string, questionId: string, isCorrect: boolean, answerLetter: string) {
    return request(`/stay-sharp/${lessonId}/${topicId}/answers/${questionId}/${isCorrect}/${answerLetter}`, {},
            { method: 'POST' });
}

export function addStaySharpToMistakeBank(lesson: any, question: Question, hint: string, displayPopup?: boolean) {
  let args;

  args = {
    categoryName: lesson.staySharpExtraQuestions.categoryName,
    lessonName: lesson.staySharpExtraQuestions.lessonName,
    topicName: question.topicName,
    hint: hint,
    shouldPromptForHints: displayPopup
  };

  return request(
  // tslint:disable-next-line:max-line-length
  `/mistakebank/lessons/${lesson.lessonId}/topics/${question.topicId}/questions/missed/${question.questionId}?includeSS=true`,
  args, { method: 'PUT' })
.catch(ignoreJsonError);
}
