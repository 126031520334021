import './EditAccountInfo.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import DateInput from '../../../../../../components/DateInput/DateInput';
import { Moment } from 'moment';
import TextInput from '../../../../../../components/TextInput/TextInput';
import { UserInfo } from '../../../../../../services/account/account-rest-interface';
import AnimationsInfo from '../AnimationsInfo';

interface Props {
    info: UserInfo;
    submit: boolean
}

interface State {
    fieldState: FieldStateMap;
    dob: Date;
    dobInvalid: boolean;
}

export interface FieldState {
    key: string;
    value?: any;
    isValid?: boolean;
    feedback?: string;
}

interface FieldStateMap {
    [key: string]: FieldState;
}

export const initialFormState: FieldStateMap = {
    firstName: { key: 'firstName' },
    lastName: { key: 'lastName' },
    email: { key: 'email' },
    animationDisabled: { key: 'animationDisabled'}
};

export interface DispatchProps {
    updateUser: (id: string, info: Partial<UserInfo>) => Promise<UserInfo>;
}


export default class EditAccountInfo extends React.Component<DispatchProps & Props, State> {
    constructor(props: DispatchProps & Props) {
        super(props);

        const fieldState: FieldStateMap = Object.keys(initialFormState)
            .map((key, i, arr) => initialFormState[key])
            .map(formItem => ({ isValid: true, feedback: '', value: '', ...formItem }))
            .reduce((acc, formItem: FieldState) => ({ ...acc, [formItem.key]: formItem }), {});

        fieldState.firstName.value = this.props.info.firstName;
        fieldState.lastName.value = this.props.info.lastName;
        fieldState.email.value = this.props.info.emailAddress;
        fieldState.animationDisabled.value = this.props.info.animationDisabled;

        this.state = {fieldState, dob: this.props.info.dateOfBirth, dobInvalid: false};
    }

    componentWillReceiveProps(nextProps: Readonly<DispatchProps & Props>) {
        if (nextProps.submit) {
            this.submitUserAccoutInfo()
        }
    }

    componentWillUnmount() {
        // OT: Originally this was always saving user info no matter what action was taken!
        // That should only save when the user clicks on the Update button in the parent.
        // Therefore following code is commented out, kept here for reference, at some point
        // it might be better to lift the funtionality up to the parent but I kept the original
        // design as is for now!
        // this.handleUpdateAccount();
    }
    
    public render() {
        const { fieldState } = this.state;
        
        return(
            <div className="row edit-user-info-container">
                <div className="col-lg-6 mobile-section">
                    <div className="user-info__field">
                        <span className="user-info__field-title">FIRST NAME</span>
                        <TextInput
                            id="edit-first-name"
                            value={fieldState.firstName.value}
                            onChange={this.onFirstNameChange}
                        />
                    </div>
                </div>
                <div className="col-lg-6 mobile-section mb-1 pt-3 pt-lg-0">
                    <div className="user-info__field">
                        <span className="user-info__field-title">LAST NAME</span>
                        <TextInput
                            id="edit-last-name"
                            value={fieldState.lastName.value}
                            onChange={this.onLastNameChange}
                        />
                    </div>
                </div>
                {/* <div className="col-lg-12 mobile-section">
                    <div className="user-info__field">
                        <span className="user-info__field-title">DATE OF BIRTH</span>
                        <DateInput
                            date={this.state.dob}
                            onDateChange={this.onDOBChange}
                            onDateValidation={this.onInvalidDob}
                            feedbackText={''}
                        />
                    </div>
                </div>*/}
                <div className="col-sm-12 mobile-section mb-1 pt-3">
                    <div className="user-info__field">
                        <span className="user-info__field-title">EMAIL</span>
                        <p>{fieldState.email.value}</p>
                    </div>
                </div>

                {/* <AnimationsInfo animationDisabled={fieldState.animationDisabled.value} info={this.props.info} onChange={this.handleAnimationDisabled}/> */}
                <AnimationsInfo animationDisabled={fieldState.animationDisabled.value} onChange={this.handleAnimationDisabled}/>
            </div>
        );
    }

    private updateFieldValue(fieldName: string, newValue: any, additionalParams?: Partial<FieldState>) {
        const field = {...this.state.fieldState[fieldName], value: newValue,
            ...additionalParams };
        const fieldState = { ...this.state.fieldState, [fieldName]: field };
        this.setState({ fieldState });
    }

    @boundMethod
    private onFirstNameChange(e: any) {
        this.updateFieldValue('firstName', e.target.value);
    }

    @boundMethod
    private onLastNameChange(e: any) {
        this.updateFieldValue('lastName', e.target.value);
    }

    @boundMethod
    private onDOBChange(date: Moment) {
        this.setState({ dob: date.toDate(), dobInvalid: false });
    }

    @boundMethod
    private handleUpdateAccount() {
        const { fieldState } = this.state;

        let fname = fieldState.firstName.value as string;
        let first = fname[0].toUpperCase();
        fname = first + fname.substring(1, fname.length);

        let lname = fieldState.lastName.value as string;
        first = lname[0].toUpperCase();
        lname = first + lname.substring(1, lname.length);

        const userInfo: Partial<UserInfo> = {
            firstName: fname,
            lastName: lname,
            dateOfBirth: this.state.dob as Date,
            emailAddress: fieldState.email.value,
            animationDisabled: fieldState.animationDisabled.value
        };
        if(!this.validateEmail(userInfo.emailAddress)) {
            return;
        }

        this.updateAccount(userInfo);
    }

    private updateAccount(accountInfo: Partial<UserInfo>) {
        this.props.updateUser(this.props.info.id, accountInfo)
            .then()
            .catch();
    }

    private validateEmail(email?: string) {
        // regex from http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
        if(!email) {
            return false;
        }
        // tslint:disable-next-line:max-line-length
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const valid = re.test(email);
        if(!valid) {
            // this.updateFieldValue('email', email, { isValid: false, feedback: 'Email is not valid' });
        }
        return valid;
    }

    @boundMethod
    private onInvalidDob(dobInvalid: boolean, feedback: string) {
        this.setState({ dobInvalid });
    }

    @boundMethod
    private handleAnimationDisabled(animationDisabled) {
        this.updateFieldValue("animationDisabled", animationDisabled)
    }

    @boundMethod
    private submitUserAccoutInfo() {
        this.handleUpdateAccount();
    }
}
