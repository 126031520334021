import './ColorPalette.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import ColorButton from '../ColorButton/ColorButton';

interface Props {
    onColorChange: (color: string) => void;
    initColor?: string;
}

interface State {
    selectedColor: string;
}

const colors = [
    '#194259', '#007496', '#039EDA', '#15B9D7', '#73C396', '#00AD6F', '#78BA00',
    '#272929', '#5E2F60', '#6B58CD', '#9E56D2', '#C235A1', '#FD454A', '#F59200'
];


export default class ColorPalette extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedColor: props.initColor || colors[0]
        };
    }

    public componentDidMount() {
        if(!this.props.initColor) {
            this.props.onColorChange(this.state.selectedColor);
        }
    }

    public render() {
        return (
            <div className="color-palette">
                {
                    colors.map(color =>
                        <div className="color-palette__color" key={color}>
                            <ColorButton 
                                color={color}
                                selected={this.state.selectedColor === color}
                                onClick={this.onColorClick} 
                            />
                        </div>)
                }
            </div>
        );
    }

    @boundMethod
    private onColorClick(color: string) {
        this.setState({ selectedColor: color }, () => this.props.onColorChange(color));
    }
}
