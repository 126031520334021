import './IAGrowthAnalytics.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';

import { PageContent, PageTitle } from '../../components/PageContent/PageContent';

import IAHighestScore from './components/IAHighestScore/IAHighestScore';
import IALineGraph from './components/IALineGraph/IALineGraph';
import IASummaryCard from './components/IASummaryCard/IASummaryCard';
import { Link } from 'react-router-dom';
import { getHighestScores } from '../../services/practice-test-scoring/practice-test-service';
import BackToTestsButton from '../Practice/components/BackToTestsButton/BackToTestsButton';

interface Props {
}

interface State {
    highestComposite: any;
    highestEnglish: any;
    highestMath: any;
    highestReading: any;
    highestScience: any;
    lineGraphData: string;
    summaryTestName: string;
}


export class IAGrowthAnalytics extends React.Component<Props, State> {
    constructor (props: Props) {
        super(props);
        this.state = {
            highestComposite: {},
            highestEnglish: {},
            highestMath: {},
            highestReading: {},
            highestScience: {},
            lineGraphData: 'compositeCoordinates',
            summaryTestName: 'IA 1'
        };
    }

    componentDidMount() {
        getHighestScores().then((res) => {
            this.setState({
                highestComposite: res[0],
                highestEnglish: res[1],
                highestMath: res[2],
                highestReading: res[3],
                highestScience: res[4],
            });
        });
    }

    public render() {
        const { lineGraphData } = this.state;
        if (this.state.highestComposite === []) {
            return null;
        }
        return (
            <PageContent className="ia-dashboard ia-growth-analytics">
                <PageTitle title="IA Growth Analytics">
                    <div className="navigation-button-container">
                        <BackToTestsButton 
                          targetUri="/app/practice-tests?testType=ia"
                          testType={'ia'}
                        />
                    </div>
                </PageTitle>
                <div className="row">
                    <div className="col-lg-8 white-background graph-container">
                        <div>
                            <h3>Score Summary</h3>
                        </div>
                        <div className="subject-buttons">
                                <button
                                    className={lineGraphData === 'compositeCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                    onClick={() => this.updateGraph('compositeCoordinates')}
                                >Composite
                                </button>
                                <button
                                    className={lineGraphData === 'englishCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                    onClick={() => this.updateGraph('englishCoordinates')}
                                >English
                                </button>
                                <button
                                    className={lineGraphData === 'mathCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                    onClick={() => this.updateGraph('mathCoordinates')}
                                >Math
                                </button>
                                <button
                                    className={lineGraphData === 'readingCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                    onClick={() => this.updateGraph('readingCoordinates')}
                                >Reading
                                </button>
                                <button
                                    className={lineGraphData === 'scienceCoordinates' ? 'btn btn-sm btn-primary activeSelection arrow_box' : 'btn btn-sm btn-default'}
                                    onClick={() => this.updateGraph('scienceCoordinates')}
                                >Science
                                </button>
                        </div>
                        <IALineGraph
                          subject={this.state.lineGraphData}
                          updateSummary={this.updateSummaryCard}
                          testType="ia"
                        />
                    </div>
                    <div className="col-lg-4 pl-lg-0">
                        <div className="summary-card-container">
                            <div className="test-summary-card">
                                <IASummaryCard testType="ia" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="white-background highest-scores-container">
                            <div>
                                <h3>Highest Scores</h3>
                            </div>
                            <div className="row highest-scores-card-container">
                                <div className="col">
                                    <IAHighestScore
                                        score={this.state.highestComposite.score}
                                        subject={this.state.highestComposite.category}
                                        test={this.state.highestComposite.displayName}
                                        startColor={'#28D98D'}
                                        endColor={'#269DD3'}
                                        maxPossibleScore={36}
                                    />
                                </div>
                                <div className="col">
                                    <IAHighestScore
                                        score={this.state.highestEnglish.score}
                                        subject={this.state.highestEnglish.category}
                                        test={this.state.highestEnglish.displayName}
                                        startColor={'#9d59cf'}
                                        endColor={'#5E3468'}
                                        maxPossibleScore={36}
                                    />
                                </div>
                                <div className="col">
                                    <IAHighestScore
                                        score={this.state.highestMath.score}
                                        subject={this.state.highestMath.category}
                                        test={this.state.highestMath.displayName}
                                        startColor={'#97EDFE'}
                                        endColor={'#36BDD8'}
                                        maxPossibleScore={36}
                                    />
                                </div>
                                <div className="col">
                                    <IAHighestScore
                                        score={this.state.highestReading.score}
                                        subject={this.state.highestReading.category}
                                        test={this.state.highestReading.displayName}
                                        startColor={'#fbd79c'}
                                        endColor={'#ea9527'}
                                        maxPossibleScore={36}
                                    />
                                </div>
                                <div className="col">
                                    <IAHighestScore
                                        score={this.state.highestScience.score}
                                        subject={this.state.highestScience.category}
                                        test={this.state.highestScience.displayName}
                                        startColor={'#CFFA92'}
                                        endColor={'#7DBC21'}
                                        maxPossibleScore={36}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </PageContent>
        );
    }

    private updateGraph(subjectName: string) {
        this.setState({
            lineGraphData: subjectName
        });
    }

    private updateSummaryCard(testName: string) {
        this.setState({
            summaryTestName: testName
        });
    }
}
