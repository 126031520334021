import jp from "jsonpath"
import { CATEGORY_ENGLISH, CATEGORY_MATH, CATEGORY_READING, CATEGORY_SCIENCE, NA, NOT_SPECIFIED, NO_COMPLETED_DATE, TIMEOUT_IDLE_MINUTES, TIMEOUT_LIVE_MINUTES } from "../constants"
import { getMistakeBankTimeSummaryByStudent, getNofTotalQuestionsAnsweredForLessons, getStudentStatsForMistakeBank, getTotalStudyTimeForLessons, IMistakeBankTimeSummary, ITimeOnPlatform } from "../scenes/TeacherPortal/TimeOnPlatform/top-lesson-processor"
import { GraduationYearDescriptor, NULL_GRADUATION_YEAR, StudentData } from "../scenes/TeacherPortal/types"
import { getAllStudentIndividualData, getCKLessonSummary, getEnrichmentLessonSummary, getGraduationYearsByTeacher, getLessonSummary, getSchoolsByTeacher, getStaySharpSummary, getTestSummaries } from "../services/elearn/lesson-rest-interface"
import { getDefaultCKLessonReportSummary, getDefaultEnrichmentLessonReportSummary, getDefaultLessonReportSummary, getDefaultStaySharpReportSummary } from "../services/elearn/lesson-types"
import { getStudentsMistakeBankStats } from "../services/elearn/teacher/teacher-student-summary"
import { CurrentStatus, getAllStudentsLiveViewInfo, LiveViewStatusFull } from "../services/liveview/liveview-service"
import { getAllStudentsLessonsInfo, getAllStudentsTimeOnPlatformInfo } from "../services/time-on-platform"
import { TimeFrame, dateDiff, getStartDateBasedOnTimeFrame, isWithinDateRange } from "../services/utils/date-util"

export interface IStudent extends StudentData {}

export interface IStudentActivity extends LiveViewStatusFull {}

interface IStudentCountable {
    studentCount: number
}
export interface ISchool extends IStudentCountable {
    schoolName: string
}

export interface IGraduationYear extends IStudentCountable {
    graduationYear: string
}

export interface IPeriod extends IStudentCountable {
    period: string
}

export interface ILessonSummary {
    categoryName: string
    lessonsStarted: number
    lessonsCompleted: number
    lessonsUpdated: number
    preLessonPercentAccuracy: number
    preLessonSatEquivalentScore: number
    preLessonActEquivalentScore: number
    postLessonCurrentPercentAccuracy: number
    postLessonCurrentSatEquivalentScore: number
    postLessonCurrentActEquivalentScore: number
    postLessonCompletedPercentAccuracy: number
    postLessonCompletedSatEquivalentScore: number
    postLessonCompletedActEquivalentScore: number
    percentHintsOnIncorrectQuestions: number
}

export interface IPracticeTestSummary {
    [key: string]: {
        testName: string
        displayName: string
        averageScores: any
    }
}

export interface IEnrichmentLessonSummary {
    categoryName: string
    lessonsStartedVideo: number
    lessonsCompletedVideo: number
    lessonsViewedPdf: number
}
export interface ICKLessonSummary {
    categoryName: string
    lessonsStartedVideo: number
    lessonsCompletedVideo: number
}

export interface IStaySharpSummary {
    categoryName: string
    topicsStarted: number
    topicsCompleted: number
    topicsUpdated: number
    topicsCompletedPercentAccuracy: number
    topicsTotalPercentAccuracy: number
    percentHintsOnIncorrectQuestions: number
    nofHintsUsed: number,
    nofTotalQuestions: number
    nofCompletedQuestions: number
    nofTotalMistakes: number
}

export interface IStudentLessonInfo {
    userId: string
    emailAddress: string
    lessons: ILesson[]
}

interface ILesson {
    section: string
    lessonId: string
    startedDate: string
    completedDate: string
    lastUpdatedTimestamp: number
    lessonName: string
    status: string
    preLessonAccuracy: number
    postLessonAccuracy: number
    lessonGrowth: number
    hintsOnIncorrect: number,
    completedQuestionAccuracy: number
    questionCompletionProgress: number
    totalQuestionAccuracy: number
    totalQuestions: number
    totalCorrectAnswers: number
    totalCompletedQuestions: number
    totalBaselineQuestions: number
    totalCompletedBaselineQuestions: number
    totalCorrectBaselineAnswers: number
    infoPreLessonAccuracy: string
    infoCompletedQuestionAccuracy: string
    infoLessonGrowth: string
    infoHintsOnIncorrect: string
    infoTotalQuestionAccuracy: string
    answers: IAnswer[]
}

interface IAnswer {
    questionId: string
    isCorrect: boolean
    mcLetter: string
}

export interface IStudentMistakeBankStats {
    userId: string
    nofManualEntries: number
    nofMissedQuestions: number
    nofReviewedQuestions: number
    nofTopicsEnglish: number
    nofTopicsEnglishMastered: number
    nofTopicsMath: number
    nofTopicsMathMastered: number
    nofTopicsReading: number
    nofTopicsReadingMastered: number
    nofTopicsScience: number
    nofTopicsScienceMastered: number    
}

export interface ITeacherStoreData {
    students: IStudent[],
    studentsActivities: IStudentActivity[]
    schools: ISchool[],
    graduationYears: IGraduationYear[],
    periods: IPeriod[],    
    lessonSummaries: ILessonSummary[],
    practiceTestsSummaries: IPracticeTestSummary[],
    enrichmentLessonSummaries: IEnrichmentLessonSummary[]
    ckLessonSummaries: ICKLessonSummary[]
    staySharpSummaries: IStaySharpSummary[]
    timeOnPlatformInfo: ITimeOnPlatform[]
    studentLessonsInfo: IStudentLessonInfo[]
    studentsMistakeBankStats: IStudentMistakeBankStats[]
}

export interface ILessonCategoricalScores {
   english: ILessonScores
   math:    ILessonScores
   reading: ILessonScores
   science: ILessonScores
}

export interface ILessonScores {
    accuracyPre: number
    accuracyPost: number
    growth: number
    hints: number
    totalEnteredHints: number
    totalMissedQuestions: number
}

/**
 * Student profile info and his/her progress details
 * including corresponding lessons and TonP info
 */
 export interface IStudentProgressDetails {
    // User details
    id: string
    firstName: string
    lastName: string
    emailAddress: string
    schoolName: string
    graduationYear: string
    period: string
    profileColor: string
    
    // Live Actity & Navigation
    navigation: string           // Page location/navigation   
    activity: string             // Current/last known activity
    currentStatus: CurrentStatus // LIVE | IDLE | AWAY etc.
    updatedAt: string            // Last update of any activity
    daysSinceLastLogin: number   // Days since last login to our system (-1 if NA)

    // Lessons
    lessons: ILesson[]
    nofLessonsStarted: number
    nofLessonsCompleted: number
    nofTotalQuestionsAnswered: number
    percentLessonCompletion: number     //  % of nofLessonsCompleted/nofLessonStarted(-1 if NA)
    lessonsScores: ILessonCategoricalScores

    // Time On Platform 
    tonp: ITimeOnPlatform[]
    totalStudyTimeForAllLessons: { avg: number, total: number }

    isAverage?: boolean

    // MistakeBank
    mistakeBankStats: IStudentMistakeBankStats
    mistakeBankTimeSummary: IMistakeBankTimeSummary
}


export enum NotificationType {
    GENERIC,
    STUDENTS,
    ACTIVITIES,
    SCHOOLS,
    GRADUATION_YEARS,
    LESSON_SUMMARIES,
    PRACTICE_TESTS_SUMMARIES,
    ENRICHMENT_LESSON_SUMMARIES,
    CK_LESSON_SUMMARIES,
    STAYSHARP_SUMMARIES,
    TIME_ON_PLATFORM_INFO,
    STUDENT_LESSONS_INFO,
    STUDENTS_MISTAKEBANK_STATS,
    ALL_LOADED
}

// export const NOT_SPECIFIED = "Not specified"

export type TeacherStoreListener = (data: ITeacherStoreData, type?: NotificationType) => void

let prefetchingData = false

export class TeacherStore {
    static readonly data: ITeacherStoreData = {
        students: [],
        studentsActivities: [],
        schools: [],
        graduationYears: [],
        periods: [],
        lessonSummaries: [],
        practiceTestsSummaries: [],
        enrichmentLessonSummaries: [],
        ckLessonSummaries: [],
        staySharpSummaries: [],
        timeOnPlatformInfo: [],
        studentLessonsInfo: [],
        studentsMistakeBankStats: []
    }
    private static lessonSummariesMap = {}
    private static practiceTestsSummariesMap = {}
    private static enrichmentLessonSummariesMap = {}
    private static ckLessonSummariesMap = {}
    private static staySharpSummariesMap = {}
    private static timeOnPlatformMap = {}
    private static studentLessonsMap = {}
    private static studentsMistakeBankStatsMap = {}
    private static studentsLoadedAt
    private static studentsActivitiesLoadedAt
    private static schoolsLoadedAt
    private static graduationYearsLoadedAt
    private static listeners: TeacherStoreListener[] = []
    
    static async prefetchData() {
        if (prefetchingData) return

        try {
            prefetchingData = true

            // OT: Students data is the common one for many requests! 
            // Load it first so that it can be cached in the backend.
            let students = await this.getStudents() 

            // We got the students data, now let's try to prefetch others...
            await Promise.all([
                this.getSchools(),
                this.getGraduationYears(),
                this.getStudentsActivities(),
                this.getLessonSummaries(),
                this.getPracticeTestsSummaries(),
                this.getEnrichmentLessonSummaries(),
                this.getCKLessonSummaries(),
                this.getStaySharpSummaries(),
                this.getTimeOnPlaformInfoForAllStudents(),
                this.getLessonInfoForAllStudents(),
                this.getStudentsMistakeBankStats(), 
            ])

            
        }
        catch (ex) {
            console.error("Problem during prefetch!", ex)
        }
        finally {
            prefetchingData = false
            this.notify(NotificationType.ALL_LOADED)
        }
    }

    static async loadStudents() {
        if (prefetchingData) {
            return
        }
        return await this.getStudents()
    }

    static async loadSchools() {
        if (prefetchingData) {
            return
        }
        let schools = await this.getSchools()
        return Promise.resolve(schools)
    }

    static async loadGraduationYears() {
        if (prefetchingData) {
            return
        }
        return await this.getGraduationYears()
    }
             
    static async loadStudentsActivities() {
        if (prefetchingData) {
            return
        }
        return await this.getStudentsActivities()
    }

    static async loadLessonSummaries(schoolParam?, gradYearParam?, selectedTimeRange?) {
        if (prefetchingData) {
            return
        }
        return await this.getLessonSummaries(schoolParam, gradYearParam, selectedTimeRange)
    }

    static async loadPracticeTestsSummaries(schoolParam?, gradYearParam?, selectedTimeRange?) {
        if (prefetchingData) {
            return
        }
        return await this.getPracticeTestsSummaries(schoolParam, gradYearParam, selectedTimeRange)
    }

    static async loadEnrichmentLessonSummaries(schoolParam?, gradYearParam?, selectedTimeRange?) {
        if (prefetchingData) {
            return
        }
        return await this.getEnrichmentLessonSummaries(schoolParam, gradYearParam, selectedTimeRange)
    }

    static async loadCKLessonSummaries(schoolParam?, gradYearParam?, selectedTimeRange?) {
        if (prefetchingData) {
            return
        }
        return await this.getCKLessonSummaries(schoolParam, gradYearParam, selectedTimeRange)
    }

    static async loadStaySharpSummaries(schoolParam?, gradYearParam?, selectedTimeRange?) {
        if (prefetchingData) {
            return
        }
        return await this.getStaySharpSummaries(schoolParam, gradYearParam, selectedTimeRange)
    }
    
    static async loadTimeOnPlaformInfoForAllStudents() {
        if (prefetchingData) {
            return
        }
        return await this.getTimeOnPlaformInfoForAllStudents()
    }

    static async loadLessonInfoForAllStudents() {
        if (prefetchingData) {
            return
        }
        return await this.getLessonInfoForAllStudents()
    }
    
    private static async getStudents() {
        let now = Date.now()
        let timeout = 600_000
        let expired = now > this.studentsLoadedAt + timeout
        let { students } = this.data

        return new Promise((resolve, reject) => {
            if (students.length === 0 || expired) {
                getAllStudentIndividualData()
                    .then(results => {
                        let students = results.map(r => {
                            return {
                                id: r.id,
                                firstName: r.firstName || "",
                                lastName: r.lastName || "",
                                emailAddress: r.emailAddress || "",
                                profileColor: r.profileColor,
                                graduationYear: r.graduationYear,
                                schoolName: r.schoolName,
                                showDetails: false,
                                detailsData: undefined,
                                period: r.period
                            }
                        })
                        .sort((s1, s2) => {
                            let key1 = `${s1.lastName.toLowerCase()} ${s1.firstName.toLowerCase()}`
                            let key2 = `${s2.lastName.toLowerCase()} ${s2.firstName.toLowerCase()}`
                            return key1.localeCompare(key2)
                        })

                        // Extract period info from students
                        let periods = [{
                            period: "All",
                            studentCount: students.length
                        }]
                        let periodsMap = {}
                        let notSpecifiedCntr = 0
                        students.forEach(student => {
                            let period = student.period
                            if (period && period !== "") {
                                if (!periodsMap[period]) {
                                    periodsMap[period] = { period, studentCount: 1 }
                                }
                                else {
                                    periodsMap[period].studentCount++
                                }
                            }                          
                            else {
                                notSpecifiedCntr++
                            }                  
                        })
                        if (notSpecifiedCntr > 0) {
                            periods.push({period: NOT_SPECIFIED, studentCount: notSpecifiedCntr})
                        }
                        Object.keys(periodsMap).forEach(k => periods.push(periodsMap[k]))
                        this.data.periods = periods 
                        this.data.students = students 
                        this.studentsLoadedAt = Date.now()
                        this.notify(NotificationType.STUDENTS)
                        resolve(students)
                    })
                    .catch(ex => {
                        console.log("Error in loadStudents", ex)
                        reject()
                    })
            }
            else {                
                resolve(students)
            }
        })
    }

    private static async getSchools() {
        let now = Date.now()
        let timeout = 900_000
        let expired = now > this.schoolsLoadedAt + timeout
        let { schools } = this.data

        return new Promise((resolve, reject) => {
            if (schools.length === 0 || expired) {
                getSchoolsByTeacher().then(results => {
                    const schools = [{ 
                        schoolName: 'All', 
                        studentCount: Object
                            .keys(results)
                            .map(key => results[key].studentCount)
                            .reduce((acc, val) => acc + val, 0) 
                    }]
                    results.forEach(item => {
                        schools.push(item);
                    })
                    this.schoolsLoadedAt = Date.now()
                    this.data.schools = schools
                    this.notify(NotificationType.SCHOOLS)
                    return resolve(schools)
                })
                .catch(ex => {
                    console.log("Error in loadSchools", ex)
                    return reject()
                })
            }
            else {
                resolve(schools)
            }
        })
    }

    private static async getGraduationYears() {
        let now = Date.now()
        let timeout = 900_000
        let expired = now > this.graduationYearsLoadedAt + timeout
        if (this.data.graduationYears.length === 0 || expired) {
            return new Promise((resolve, reject) => {
                getGraduationYearsByTeacher()
                    .then(results => {
                        let years: GraduationYearDescriptor[] = [];
                        years.push({ 
                            graduationYear: 'All', 
                            studentCount: Object
                                .keys(results)
                                .map(key => results[key].studentCount)
                                .reduce((acc, val) => acc + val, 0) 
                        })
                        results.forEach(thing => {
                            years.push(thing.graduationYear === NULL_GRADUATION_YEAR ? 
                            {graduationYear: 'None provided', studentCount: thing.studentCount } : 
                            thing)
                        })
                        years.sort((a: GraduationYearDescriptor, b: GraduationYearDescriptor) => {
                            if (a.graduationYear === 'All') {
                                return -1
                            } 
                            else if (b.graduationYear === "All") {
                                return 1;
                            }
                            else if (a.graduationYear !== b.graduationYear) {
                                return a.graduationYear > b.graduationYear ? 1 : -1
                            }
                            return 0
                        })
                        this.graduationYearsLoadedAt = Date.now()
                        this.data.graduationYears = years
                        this.notify(NotificationType.GRADUATION_YEARS)
                        return resolve(years)
                    })
                    .catch(ex => {
                        console.log("Error in loadGraduationYears", ex)
                        return reject()
                    })
            })
        }
    }

    private static async getStudentsActivities() {
        let now = Date.now()
        let timeout = 10_000
        let expired = now > this.studentsActivitiesLoadedAt + timeout
        let { studentsActivities } = this.data

        return new Promise((resolve, reject) => {
            if (studentsActivities.length === 0 || expired) {
                getAllStudentsLiveViewInfo()
                    .then(activities => {
                        activities = activities.map(d => {
                            let updated = Date.parse(d.updatedAt)
                            let now = Date.now()
                            let deltaSeconds = (now - updated)/1000
                            let deltaMins = deltaSeconds/60 
                            let currentStatus = deltaMins < TIMEOUT_LIVE_MINUTES ? CurrentStatus.LIVE
                                                    : deltaMins < TIMEOUT_IDLE_MINUTES ? CurrentStatus.IDLE: 
                                                    CurrentStatus.AWAY
                                                    
                            return {...d, currentStatus }
                        })
                        this.studentsActivitiesLoadedAt = Date.now()
                        this.data.studentsActivities = activities
                        this.notify(NotificationType.ACTIVITIES)
                        resolve(activities)
                    })
                    .catch(ex => {
                        console.log("Error in loadStudentsActivities", ex)
                        reject()
                    })
            }
            else {
                resolve(studentsActivities)
            }
        })
    }

    private static async getLessonSummaries(schoolParam?, gradYearParam?, selectedTimeRange?) {
        let cacheKey = this.getCacheKey(schoolParam, gradYearParam, selectedTimeRange)
        let lessonSummaries = this.lessonSummariesMap[cacheKey]

        return new Promise((resolve, reject) => {
            if (lessonSummaries) {
                // OT: We have it on the cache, so just use that!
                this.data.lessonSummaries = lessonSummaries
                this.notify(NotificationType.LESSON_SUMMARIES)
                resolve(lessonSummaries)
                return
            }

            getLessonSummary(schoolParam, gradYearParam, selectedTimeRange)
                .then(results => {
                    const categories = [ 'English', 'Math', 'Reading', 'Science' ]
            
                    lessonSummaries = categories.map(category => {
                        const data = results.find(r => category === r.category)
                        const isScience = category === 'Science'
                
                        let summary = getDefaultLessonReportSummary()
                
                        if (data !== undefined) {
                            summary.categoryName = category
                            summary.lessonsStarted = data.lessonsStarted
                            summary.lessonsCompleted = data.lessonsCompleted
                            summary.lessonsUpdated = data.lessonsUpdated
                
                            summary.preLessonPercentAccuracy = data.preLessonPercentAccuracy
                            summary.preLessonSatEquivalentScore = data.preLessonSatEquivalentScore
                            summary.preLessonActEquivalentScore = data.preLessonActEquivalentScore
                
                            summary.postLessonCurrentPercentAccuracy = data.postLessonCurrentPercentAccuracy
                            summary.postLessonCurrentSatEquivalentScore = isScience ? -1 : data.postLessonCurrentSatEquivalentScore
                            summary.postLessonCurrentActEquivalentScore = data.postLessonCurrentActEquivalentScore
                
                            summary.postLessonCompletedPercentAccuracy = data.postLessonCompletedPercentAccuracy
                            summary.postLessonCompletedSatEquivalentScore = 888
                            summary.postLessonCompletedActEquivalentScore = data.postLessonCompletedActEquivalentScore
                
                            summary.percentHintsOnIncorrectQuestions = data.percentHintsOnIncorrectQuestions
                        } 
                
                        if (isScience) {
                            summary.postLessonCompletedSatEquivalentScore = -1 
                        }
                
                        return summary;
                    })
                    this.data.lessonSummaries = lessonSummaries
                    this.lessonSummariesMap[cacheKey] = lessonSummaries
                    this.notify(NotificationType.LESSON_SUMMARIES)
                    resolve(lessonSummaries)
                })
                .catch(ex => {
                    console.log("Error in loadLessonSummaryData", ex)
                    reject()
                })
            })
    }

    private static async getPracticeTestsSummaries(schoolParam?, gradYearParam?, selectedTimeRange?) {
        let cacheKey = this.getCacheKey(schoolParam, gradYearParam, selectedTimeRange)
        let practiceTestsSummaries = this.practiceTestsSummariesMap[cacheKey]
        
        return new Promise((resolve, reject) => {
            if (practiceTestsSummaries) {
                this.data.practiceTestsSummaries = practiceTestsSummaries
                this.notify(NotificationType.PRACTICE_TESTS_SUMMARIES)
                resolve(practiceTestsSummaries)
                return
            }

            getTestSummaries(schoolParam, gradYearParam, selectedTimeRange)
                .then(result => {
                    this.data.practiceTestsSummaries = result
                    this.practiceTestsSummariesMap[cacheKey] = result
                    this.notify(NotificationType.PRACTICE_TESTS_SUMMARIES)
                    resolve(result)
                })
                .catch(ex => {
                    console.log("Error in loadPracticeTestsSummaries", ex)
                    reject()
                })
            })
    }

    static async getEnrichmentLessonSummaries(schoolParam?, gradYearParam?, selectedTimeRange?) {
        let cacheKey = this.getCacheKey(schoolParam, gradYearParam, selectedTimeRange)
        let enrichmentLessonSummaries = this.enrichmentLessonSummariesMap[cacheKey]

        return new Promise((resolve, reject) => {
            if (enrichmentLessonSummaries) {
                this.data.enrichmentLessonSummaries = enrichmentLessonSummaries
                this.notify(NotificationType.ENRICHMENT_LESSON_SUMMARIES)
                resolve(enrichmentLessonSummaries)
                return
            }

            getEnrichmentLessonSummary(schoolParam, gradYearParam, selectedTimeRange)
                .then(results => {
                    let categories = results.map(r => r.categoryId)
                    let result = categories.map(category => {
                        let data = results.find(r => category === r.categoryId)
                        let summary = getDefaultEnrichmentLessonReportSummary()
                        if (data !== undefined) {
                            summary.categoryName = data.categoryName
                            summary.lessonsStartedVideo = data.lessonsStartedVideo
                            summary.lessonsCompletedVideo = data.lessonsCompletedVideo
                            summary.lessonsViewedPdf = data.lessonsViewedPdf
                        } 
                        return summary
                    })
                    this.data.enrichmentLessonSummaries = result
                    this.enrichmentLessonSummariesMap[cacheKey] = result
                    this.notify(NotificationType.ENRICHMENT_LESSON_SUMMARIES)
                    resolve(result)
                })
                .catch(ex => {
                    console.log("Error in loadEnrichmentLessonSummaries", ex)
                    reject()
                })
            })
    }

    static async getCKLessonSummaries(schoolParam?, gradYearParam?, selectedTimeRange?) {
        let cacheKey = this.getCacheKey(schoolParam, gradYearParam, selectedTimeRange)
        let ckLessonSummaries = this.ckLessonSummariesMap[cacheKey]

        return new Promise((resolve, reject) => {
            if (ckLessonSummaries) {
                this.data.ckLessonSummaries = ckLessonSummaries
                this.notify(NotificationType.CK_LESSON_SUMMARIES)
                resolve(ckLessonSummaries)
                return
            }

            getCKLessonSummary(schoolParam, gradYearParam, selectedTimeRange)
                .then(results => {
                    let categories = results.map(r => r.categoryId)
                    let result = categories.map(category => {
                        let data = results.find(r => category === r.categoryId)
                        let summary = getDefaultCKLessonReportSummary()
                        if (data !== undefined) {
                            summary.categoryName = data.categoryName
                            summary.lessonsStartedVideo = data.lessonsStartedVideo
                            summary.lessonsCompletedVideo = data.lessonsCompletedVideo
                        } 
                        return summary
                    })
                    this.data.ckLessonSummaries = result
                    this.ckLessonSummariesMap[cacheKey] = result
                    this.notify(NotificationType.CK_LESSON_SUMMARIES)
                    resolve(result)
                })
                .catch(ex => {
                    console.log("Error in loadCKtLessonSummaries", ex)
                    reject()
                })
            })
    }

    private static async getStaySharpSummaries(schoolParam?, gradYearParam?, selectedTimeRange?) {
        let cacheKey = this.getCacheKey(schoolParam, gradYearParam, selectedTimeRange)
        let staySharpSummaries = this.staySharpSummariesMap[cacheKey]

        return new Promise((resolve, reject) => {
            if (staySharpSummaries) {
                this.data.staySharpSummaries = staySharpSummaries
                this.notify(NotificationType.STAYSHARP_SUMMARIES)
                resolve(staySharpSummaries)
                return
            }

            getStaySharpSummary(schoolParam, gradYearParam, selectedTimeRange)
                .then(results => {
                    let categories = results.map(r => r.categoryName)
                    let result = categories.map(category => {
                        let data = results.find(r => category === r.categoryName)
                        let summary = getDefaultStaySharpReportSummary()
                        if (data !== undefined) {
                            summary.categoryName = data.categoryName
                            summary.topicsStarted = data.topicsStarted
                            summary.topicsCompleted = data.topicsCompleted
                            summary.topicsUpdated = data.topicsUpdated
                            summary.topicsCompletedPercentAccuracy = data.topicsCompletedPercentAccuracy
                            summary.topicsTotalPercentAccuracy = data.topicsTotalPercentAccuracy
                            summary.percentHintsOnIncorrectQuestions = data.percentHintsOnIncorrectQuestions
                            summary.nofHintsUsed = data.nofHintsUsed
                            summary.nofTotalQuestions = data.nofTotalQuestions
                            summary.nofCompletedQuestions = data.nofCompletedQuestions
                            summary.nofTotalMistakes = data.nofTotalMistakes
                        } 
                        return summary
                    })
                    this.data.staySharpSummaries = result
                    this.staySharpSummariesMap[cacheKey] = result
                    this.notify(NotificationType.STAYSHARP_SUMMARIES)
                    resolve(result)
                })
                .catch(ex => {
                    console.log("Error in getStaySharpSummary", ex)
                    reject()
                })
            })
    }

    /**
     * Get all TonP info for all students from cache
     * @returns all lessons for all students
     */
     public static timeOnPlaformInfoForAllStudents() {
        let cacheKey = 'time-on-platform'
        return this.timeOnPlatformMap[cacheKey]
    }

    private static async getTimeOnPlaformInfoForAllStudents() {
        let cacheKey = 'time-on-platform'
        let timeOnPlatformInfo = this.timeOnPlatformMap[cacheKey]

        return new Promise((resolve, reject) => {
            if (timeOnPlatformInfo) {
                this.data.timeOnPlatformInfo = timeOnPlatformInfo
                this.notify(NotificationType.TIME_ON_PLATFORM_INFO)
                resolve(timeOnPlatformInfo)
                return
            }

            getAllStudentsTimeOnPlatformInfo()
                .then(d => {
                    let result = d
                    this.data.timeOnPlatformInfo = result
                    this.timeOnPlatformMap[cacheKey] = result
                    this.notify(NotificationType.TIME_ON_PLATFORM_INFO)
                    resolve(result)
                })
                .catch(ex => {
                    console.log("Error in getTimeOnPlaformInfoForAllStudents")
                    reject()
                })
        })
    }

    /**
     * Get all lessons info for all students from cache
     * @returns all lessons for all students
     */
    public static lessonInfoForAllStudents() {
        let cacheKey = 'all-students-lessons'
        return this.studentLessonsMap[cacheKey]
    }
    
    private static async getLessonInfoForAllStudents() {
        let cacheKey = 'all-students-lessons'
        let studentLessonsInfo = this.studentLessonsMap[cacheKey]

        return new Promise((resolve, reject) => {
            if (studentLessonsInfo) {
                this.data.studentLessonsInfo = studentLessonsInfo
                this.notify(NotificationType.STUDENT_LESSONS_INFO)
                resolve(studentLessonsInfo)
                return
            }

            getAllStudentsLessonsInfo()
                .then(d => {
                    let result = d
                    this.data.studentLessonsInfo = result
                    this.studentLessonsMap[cacheKey] = result
                    this.notify(NotificationType.STUDENT_LESSONS_INFO)
                    resolve(result)
                })
                .catch(ex => {
                    console.log("Error in getLessonInfoForAllStudents")
                    reject()
                })
        })
    }

    private static async getStudentsMistakeBankStats() {
        let cacheKey = 'students-mistakebank-stats'
        let stats = this.studentsMistakeBankStatsMap[cacheKey]

        return new Promise((resolve, reject) => {
            if (stats) {
                this.data.studentsMistakeBankStats = stats
                this.notify(NotificationType.STUDENTS_MISTAKEBANK_STATS)
                resolve(stats)
                return
            }

            getStudentsMistakeBankStats()
                .then(d => {
                    let result = d
                    this.data.studentsMistakeBankStats = result
                    this.studentsMistakeBankStatsMap[cacheKey] = result
                    this.notify(NotificationType.STUDENTS_MISTAKEBANK_STATS)
                    resolve(result)
                })
                .catch(ex => {
                    console.log("Error in getStudentsMistakeBankStats")
                    reject()
                })
        })
    }

    private static getCacheKey(schoolParam, gradYearParam, selectedTimeRange) {
        let emptyKey = "-"
        let schoolKey = schoolParam || emptyKey
        let gradYearKey = gradYearParam || emptyKey
        let selectedTimeRangeKey = selectedTimeRange ? selectedTimeRange.includes("all") ? emptyKey: selectedTimeRange: emptyKey
        let cacheKey = `${schoolKey}${gradYearKey}${selectedTimeRangeKey}`
        return cacheKey
    }

    public static subscribe(listener: TeacherStoreListener) {
        if (listener == null) return
        this.listeners.push(listener)

        listener(this.data, NotificationType.GENERIC)

        // OT: Allow the subsciber also unscribe calling this method!
        return () => this.listeners = this.listeners.filter(l =>  l != null && l != listener)
    }

    private static notify(type: NotificationType, timeout=200) {
        setTimeout(() => {
            this.listeners.forEach(listener => listener && listener(this.data, type))
        }, timeout)
    }

    /**
     * Get all the data gathered in the teacher store.
     * @returns 
     */
    public static getAllData(): ITeacherStoreData {
        return TeacherStore.data
    }

    /**
     * Gets the all students for the teacher
     * @returns 
     */
    public static getAllStudents(): IStudent[] {
        return TeacherStore.data.students
    }
    
    /**
     * Gets all students' progress information, including their lessons, TonP, live view activity, etc.
     * 
     * @param timeFrame 
     * @returns all students' progress
     */
    public static getAllStudentsProgressDetails(timeFrame: TimeFrame): IStudentProgressDetails[] {
        let {students, studentLessonsInfo, studentsActivities, timeOnPlatformInfo, studentsMistakeBankStats} = TeacherStore.data
        let today = new Date()
        let dateStart = getStartDateBasedOnTimeFrame(timeFrame)
        let dateEnd = today

        let allStudentsProgressDetails = studentLessonsInfo.map(lessonInfo => {
            let {userId, emailAddress, lessons} = lessonInfo
            let filteredLessons = lessons.filter(lesson => isWithinDateRange(lesson.lastUpdatedTimestamp, dateStart, dateEnd))
            let student = students.find(s => s.id === userId)
            let studentActivity = studentsActivities.find(a => a.userId === userId)
            let tonp = timeOnPlatformInfo.filter(t => t.email === emailAddress)
            let daysSinceLastLogin = studentActivity ? dateDiff(new Date(studentActivity.updatedAt), today): -1
            let nofLessonsStarted = filteredLessons ? filteredLessons.length: 0
            let nofLessonsCompleted = filteredLessons ? filteredLessons.reduce((acc, cur) => {
                let {completedDate} = cur
                acc += completedDate !== NO_COMPLETED_DATE ? 1: 0
                return acc
            }, 0): 0
            let percentLessonCompletion = nofLessonsStarted > 0 ? Math.round(100.0*nofLessonsCompleted/nofLessonsStarted): -1
            let totalStudyTimeForAllLessons = getTotalStudyTimeForLessons(tonp, dateStart, dateEnd)
            let lessonsScores = getLessonsScores(filteredLessons)
            let mistakeBankTimeSummary = getMistakeBankTimeSummaryByStudent(tonp, emailAddress, dateStart, dateEnd)
            let studentMistakeBankStats = studentsMistakeBankStats.find(d => d.userId === userId)
            let nofTotalQuestionsAnswered = 0
            if (timeFrame === TimeFrame.ALL_TIME) {
                // We may have some earlier lessons that started before TonP data capturing began
                // In this case use the info from the lessons directly
                nofTotalQuestionsAnswered = filteredLessons ? filteredLessons.reduce((acc, cur) => {
                    let {answers} = cur
                    acc += answers.length
                    return acc
                    }, 0): 0
            }
            else {
                // Get the info from TonP data
                nofTotalQuestionsAnswered = getNofTotalQuestionsAnsweredForLessons(tonp, dateStart, dateEnd)
                studentMistakeBankStats = getStudentStatsForMistakeBank(studentMistakeBankStats, tonp, emailAddress, dateStart, dateEnd)
            }
            
            return {
              ...student,         // Student profile
              ...studentActivity, // Live activity info
              daysSinceLastLogin, // Days since last activity
              
              // Lessons
              lessons: filteredLessons,
              nofLessonsStarted,
              nofLessonsCompleted,
              nofTotalQuestionsAnswered,
              percentLessonCompletion,
              lessonsScores,

              // Time on platform
              tonp,  // All TonP info
              totalStudyTimeForAllLessons,

              // MistakeBank
              mistakeBankStats: studentMistakeBankStats,
              mistakeBankTimeSummary,

            } as IStudentProgressDetails
        })

        // Initial sort by lastName & firstName
        allStudentsProgressDetails.sort((d1, d2) => {
            let k1 = `${d1.lastName}-${d1.firstName}`
            let k2 = `${d2.lastName}-${d2.firstName}`
            
            if (k1 > k2) return 1
            if (k1 < k2) return -1

            return 0
        })

        return allStudentsProgressDetails
    }
}

//--- Helper functions ---
function getLessonsScores(lessons: ILesson[]) {
    let lessonsE = lessons.filter(l => l.section === CATEGORY_ENGLISH)
    let lessonsM = lessons.filter(l => l.section === CATEGORY_MATH)
    let lessonsR = lessons.filter(l => l.section === CATEGORY_READING)
    let lessonsS = lessons.filter(l => l.section === CATEGORY_SCIENCE)
    let findScores = (categoryLessons: ILesson[]) => {
        let initial = {
            accuracyPre: 0,
            accuracyPost: 0,
            growth: 0,
            hints: 0,
            totalMissedQuestions: 0,
            totalEnteredHints: 0,
        }

        return categoryLessons.reduce((acc, cur, idx, arr) => {
            let nofWrongQuestions = cur.totalCompletedQuestions - cur.totalCorrectAnswers
            let nofHintsEntered = Math.round(nofWrongQuestions*cur.hintsOnIncorrect/100)
            
            acc.accuracyPre += cur.preLessonAccuracy
            acc.accuracyPost += cur.postLessonAccuracy
            acc.growth += cur.lessonGrowth
            acc.hints += cur.hintsOnIncorrect
            acc.totalMissedQuestions += nofWrongQuestions
            acc.totalEnteredHints += nofHintsEntered

            if (idx === arr.length - 1) {
                acc.accuracyPre = Math.round(acc.accuracyPre/arr.length)
                acc.accuracyPost = Math.round(acc.accuracyPost/arr.length)
                acc.growth = Math.round(acc.growth/arr.length)
                acc.hints = Math.round(acc.hints/arr.length)
            }
            return acc
        }, initial)
    }
    let scoresEnglish = findScores(lessonsE)
    let scoresMath    = findScores(lessonsM)
    let scoresReading = findScores(lessonsR)
    let scoresScience = findScores(lessonsS)

    return {
        english: scoresEnglish,
        math:    scoresMath,
        reading: scoresReading,
        science: scoresScience
    }

}
