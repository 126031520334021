import { LessonProgress, SubcategoryGroup } from '../elearn/lesson-types';

export function mapKeys<T>(obj: object): T[] {
    return Object.keys(obj).map(key => obj[key]);
}

export function move(arr: any[], from: number, to: number) {
    let copy = [...arr];
    while (from < 0) {
        from += arr.length;
    }
    while (to < 0) {
        to += arr.length;
    }
    if (to >= arr.length) {
        let k = to - arr.length;
        while ((k--) + 1) {
            copy.push(undefined);
        }
    }
    copy.splice(to, 0, copy.splice(from, 1)[0]);
    return copy;
}

export function mapRange<T>(range: number) {
    return (f: (value: T, i: number, arr: T[]) => T) => {
        return Array(range).fill(undefined).map(f);
    };
}

export function forRange<T>(range: number) {
    return (f: (i: number) => T) => {
        for(let _i = 0; _i < range; _i++) {
            f(_i);
        }
    };
}

export function groupLessonsBySubcategory(lessons: LessonProgress[]): SubcategoryGroup[] {
    const hasSubcategory = lessons.every(d => !!d.subcategory);
    if(!hasSubcategory) {
        // No subcategory, put all lessons in a single list group
        return [{ lessons }];
    }

    // Create separate lists for all lessons in each subcategory
    const lessonsBySubcategory = lessons.reduce(addLessonToSubcategoryMap,
        new Map<string, SubcategoryGroup>());

    return Array.from(lessonsBySubcategory.values())
        .sort(sortSubcategoryGroup);
}

export function addLessonToSubcategoryMap(subcategoryMap: Map<string, SubcategoryGroup>, lesson: LessonProgress) {
    if(!lesson.subcategory) { return subcategoryMap; }

    const key = lesson.subcategory.subcategoryName;
    if(!subcategoryMap.has(key)) {
        // Create new lesson list for subcategory
        subcategoryMap.set(key, { subcategory: lesson.subcategory, lessons: [] });
    }

    (subcategoryMap.get(key) as SubcategoryGroup).lessons.push(lesson);
    return subcategoryMap;
}

export function sortSubcategoryGroup(group1: SubcategoryGroup, group2: SubcategoryGroup) {
    const order1 = group1.subcategory && group1.subcategory.orderWithinCategory ?
        group1.subcategory.orderWithinCategory : 0;
    const order2 = group2.subcategory && group2.subcategory.orderWithinCategory ?
        group2.subcategory.orderWithinCategory : 0;
    return order1 - order2;
}