import * as React from 'react';
import './index.css';

import {
  getTestSummaries,
  sendStudentSummary,
  getSchoolsByTeacher,
  getGraduationYearsByTeacher
} from '../../../services/elearn/lesson-rest-interface';

import * as _ from 'lodash';
import SummaryTable from './SummaryTable';
import SummaryFilters from '../SummaryFilters/';
import { RouteComponentProps } from 'react-router-dom';
import ReportLoadingMessage from '../ReportLoadingMessage';
import Modal from '../../../components/Modal/ModalEmail';
import SelectInput from '../../../components/SelectInput/SelectInput';
import NewSelectList from '../NewSelectList';
import { TeacherStore, ITeacherStoreData } from '../../../stores/teacher.store';
import { GraduationYearDescriptor, NULL_GRADUATION_YEAR } from '../types';
import { TestType } from '../../../services/utils/practice-test-util';
import { ITimeOnPlatform } from '../TimeOnPlatform/top-lesson-processor';

export interface Props {
  teacherEmailAddress: string;
}

interface State {
  selectedSchoolName?: string
  selectedGraduationYear?: string
  data: any
  schools: string[]
  graduationYears: GraduationYearDescriptor[]
  selectedTimeRange?: string
  showEmailNotificationModal: boolean
  reportKindMsg: string
  timeOnPlatformInfo: ITimeOnPlatform[]
}

export enum ReportKind {
  ACT,
  SAT,
  ALL
}

export default class TestOverview extends React.Component<Props, State> {
  unsubscribe
  
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSchoolName: undefined,
      selectedTimeRange: undefined,
      data: [],
      schools: [],
      graduationYears: [],
      showEmailNotificationModal: false,
      reportKindMsg: "",
      timeOnPlatformInfo: []
    };
  }

  public componentDidMount() {
    const { selectedSchoolName } = this.state;
    this.loadSummaryData()
    this.unsubscribe = TeacherStore.subscribe(d => {
      this.setMainData(d)
    })
    this.setMainData(TeacherStore.data)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  setMainData(data: ITeacherStoreData) {
    let { practiceTestsSummaries, timeOnPlatformInfo } = data
    this.setState({ 
      data: practiceTestsSummaries,
      timeOnPlatformInfo
    })
  }

  loadSummaryData() {
    const { selectedSchoolName, selectedGraduationYear, selectedTimeRange } = this.state;
    const schoolParam = selectedSchoolName !== undefined ? selectedSchoolName : undefined;
    const gradYearParam = selectedGraduationYear !== undefined ? selectedGraduationYear : undefined;
    TeacherStore.loadPracticeTestsSummaries(schoolParam, gradYearParam, selectedTimeRange)
  }

  onNewSchoolSelected(newSelectedSchool: string) {
    this.setState({ data: [] });
    const { selectedSchoolName } = this.state;
    if (selectedSchoolName !== newSelectedSchool) {
      this.setState({
          selectedSchoolName: newSelectedSchool === 'All' ? undefined : newSelectedSchool
        }, () => this.loadSummaryData()
      );
    }
  }

  onTimeRangeSelected(newlySelected: string) {
    const { selectedTimeRange } = this.state;
    // TODO: create a type/interface for newlySelected
    //    (eg all-time | last-week | etc)
    this.setState({ data: [] });
    if (selectedTimeRange !== newlySelected) {
      this.setState({
        selectedTimeRange: newlySelected },
        () => this.loadSummaryData()
      );
    }

  }

  onGraduationYearSelected(newlySelected: string) {
    this.setState({ data: [] });
    let newGraduationYear = newlySelected === 'None provided' ?
      NULL_GRADUATION_YEAR : newlySelected;
    const { selectedGraduationYear } = this.state;
    if (selectedGraduationYear !== newlySelected) {
      this.setState({
          selectedGraduationYear: newGraduationYear === 'All' ? undefined : newGraduationYear
        }, () => this.loadSummaryData()
      );
    }
  }

  openModal() {
    this.setState({ showEmailNotificationModal: true, reportKindMsg: "" });
  }

  emailStudentSummary(reportKind: ReportKind) {
    let reportKindMsg
    switch (reportKind) {
      case ReportKind.ACT:
        reportKindMsg = "ACT/IA";
        break;
      case ReportKind.SAT:
        reportKindMsg = "SAT";
        break;
      default:
        reportKindMsg = "";
    }
    this.setState({ showEmailNotificationModal: true, reportKindMsg });
    sendStudentSummary(reportKind);
  }

  public render() {
    const { data, timeOnPlatformInfo } = this.state;
    const teacherEmail = this.props.teacherEmailAddress;

    return (
        <div className="test-overview">
          <SummaryFilters
            enableTimeRangeSelection={false}
            onNewGraduationYearSelected={(gradYear) => this.onGraduationYearSelected(gradYear)}
            onNewSchoolSelected={(schoolSel) => this.onNewSchoolSelected(schoolSel)}
            onNewTimeRangeSelected={(timeRange) => this.onTimeRangeSelected(timeRange)}
          />
          <div className="individual-data-section--2col">
            <h2 className="h3 individual-data-title">Practice Test Summary</h2>
            <div className="title-sidebar">

              <a title="Email ACT/IA Report" className="iconLink--left img-rel" href="#" onClick={() => this.emailStudentSummary(ReportKind.ACT)}>
                <img src="/assets/images/icons/v2/ico-email.svg" width="14" height="11" alt="" />
                <span className="label def">Email ACT/IA Report</span>
              </a>

              <a title="Email SAT Report" className="iconLink--left img-rel ml-btn" href="#" onClick={() => this.emailStudentSummary(ReportKind.SAT)}>
                <img src="/assets/images/icons/v2/ico-email.svg" width="14" height="11" alt="" />
                <span className="label def">Email SAT Report</span>
              </a>
            </div>
          </div>
          <hr className="half" />

          {data.length === 0 && <ReportLoadingMessage />}

          <div className="demo-group-panel-container">
            <div className="demo-group-title">ACT Book Tests</div>
              <div className="demos-panel">
                <div className="lesson-summary-table-wrapper fixedHeaderTable">
                  {data && data.act &&
                  <SummaryTable
                    teacherEmailAddress={this.props.teacherEmailAddress}
                    data={data.act}
                    timeOnPlatformInfo={timeOnPlatformInfo}
                    testType={TestType.ACT}
                    onReportSent={() => this.openModal()}
                  />
                  }
              </div>
            </div>
          </div>
          <div className="demo-group-panel-container">
            <div className="demo-group-title">Interim Assessments</div>
              <div className="demos-panel">
                <div className="lesson-summary-table-wrapper fixedHeaderTable">
                  { data && data.ia &&
                  <SummaryTable
                    teacherEmailAddress={this.props.teacherEmailAddress}
                    data={data.ia}
                    timeOnPlatformInfo={timeOnPlatformInfo}
                    testType={TestType.IA}
                    onReportSent={() => this.openModal()}
                  />
                  }
                </div>
              </div>
          </div>
          <div className="demo-group-panel-container">
            <div className="demo-group-title">SAT Book Tests</div>
            <div className="demos-panel">
              <div className="lesson-summary-table-wrapper fixedHeaderTable">
                { data && data.sat &&
                <SummaryTable
                  teacherEmailAddress={this.props.teacherEmailAddress}
                  data={data.sat}
                  timeOnPlatformInfo={timeOnPlatformInfo}
                  testType={TestType.SAT}
                  onReportSent={() => this.openModal()}
                />
                }
              </div>
            </div>
          </div>
          <Modal
            show={this.state.showEmailNotificationModal}
            onClose={() => this.setState({ showEmailNotificationModal: false })}
          >
            <div className="test-summary-email-notification-modal">
            <h3>{this.state.reportKindMsg} Report Is On Its Way</h3>
              <p>
              The {this.state.reportKindMsg} report for your students
              is on its way and will arrive via email
              in the next 2-3 minutes.
              </p>
            </div>
          </Modal>

        </div>
    );
  }
}
