import './Popover.css';

import * as React from 'react';

interface Props {
    visible: boolean;
    yAlignment?: 'top' | 'bottom' | 'center';
    xAlignment?: 'left' | 'right' | 'center';
    style?: React.CSSProperties;
}

interface State {

}

export default class Popover extends React.Component<Props, State> {
    public static defaultProps = {
        xAlignment: 'center',
        yAlignment: 'center'
    };

    public render() {
        const { visible, style, xAlignment, yAlignment } = this.props;

        const className =
            (visible ? 'wa-popover--visible ' : ' ') +
            `wa-popover--${xAlignment} ` +
            `wa-popover--${yAlignment}`;

        return (
            <div className={`wa-popover ${className}`} style={style}>
                <div className="wa-popover__content">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
