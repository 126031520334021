import './VerificationError.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';

interface Props {

}

type AllProps = Props & RouteComponentProps<any>;


class VerificationError extends React.Component<AllProps, {}> {
    constructor(props: AllProps) {
        super(props);
    }

    public render() {
        return (
            <div className="verification-error">
                <p className="verification-error__info-text">
                  Unverified account. Please check your email to verify your account, or <button className="verification-error__resend-btn" onClick={this.resendVerificationEmail}>resend the verification email</button>.
                </p>
                {/* <p className="verification-error__resend-text">
                    <span className="verification-error__resend-btn" onClick={this.resendVerificationEmail}>
                        Click here
                    </span>
                    {' '}to resend the verification email
                </p> */}
            </div>
        );
    }

    @boundMethod
    private resendVerificationEmail() {
        this.props.history.push(`${process.env.REACT_APP_BASE_URL}welcome?resendVerificationEmail=true`);
    }
}

export default withRouter(VerificationError);
