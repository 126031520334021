import * as React from 'react';

import { TestSection, getColor, getIcon } from '../../services/section-colors';

import './index.css';

interface Props {
    sectionName: string
    defautlImagePath?: string
}

const TestAnalysisSectionHeader = (props) => {
    let { sectionName, defaultImagePath } = props
    const color = getColor(sectionName);
    return (
        <div className={`tas-header ribbon-header`}> 
            <span className={`ribbon ${color ? color : ''}`}>
                <img alt="" src={getIcon(sectionName, defaultImagePath)} />
            </span>
            <h3 className="tas-header--text">{sectionName}</h3>
        </div>
    );
};

export default TestAnalysisSectionHeader;