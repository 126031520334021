import './QuestionText.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import MathJaxText from '../../../MathJaxText/MathJaxText';
import MathFigure from '../../../MathFigure/MathFigure';
import Popover from '../../../Popover/Popover';
import TextBox from '../../../TextBox/TextBox';

interface Props {
    questionText: string;
    figureImageName?: string;
    isMistakeBank?: boolean;
    hintText: string;
    useMathFont: boolean;
    updateHint: (hint: string, display: boolean) => void;
    mathVisible: boolean;
}

interface State {
    fieldState: FieldStateMap;
    showHint: boolean;
    editMode: boolean;
    visible: boolean;
}

export interface FieldState {
    key: string;
    value?: any;
}

interface FieldStateMap {
    [key: string]: FieldState;
}

export const initialFormState: FieldStateMap = {
    hint: { key: 'hint' }
};


export default class QuestionText extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        const fieldState: FieldStateMap = Object.keys(initialFormState)
            .map((key, i, arr) => initialFormState[key])
            .map(formItem => ({ isValid: true, feedback: '', value: '', ...formItem }))
            .reduce((acc, formItem: FieldState) => ({ ...acc, [formItem.key]: formItem }), {});

        fieldState.hint.value = this.props.hintText;

        this.state = {
            fieldState,
            showHint: false,
            editMode: false,
            visible: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.setState({showHint: false});
        this.state.fieldState.hint.value = nextProps.hintText;
    }

    public render() {
        const { fieldState } = this.state;
        const { questionText, useMathFont, mathVisible } = this.props;
        const hideHover = (this.state.showHint) ? ' hide' : '';
        const style = {
            width: '93.5vw',
            maxWidth: '500px'
        };

        return (
            <div className="question-text noselect">
                { useMathFont ?
                    <MathJaxText 
                      id="question-text" 
                      text={questionText} 
                      useMathFont={useMathFont} 
                      visible={useMathFont ? mathVisible : true} 
                    /> :
                    <span
                        id="question-text"
                        dangerouslySetInnerHTML={{
                          __html: '<span>' + questionText + '</span>'
                        }}
                    />
                }

                {this.props.figureImageName &&
                  <MathFigure fileName={this.props.figureImageName} />
                }

                {this.props.isMistakeBank
                    && <div className={'hint-icon'}>
                        <img
                            alt="Add hint"
                            src="/assets/images/mistakeBank/hint-icon-2022.svg"
                            onClick={this.setShowHint}
                        />
                        <span className={'popover' + hideHover}> My hint </span>
                        <span className={'arrow-down' + hideHover}></span>
                        <div className="hintPopover">
                            <Popover
                                visible={this.state.showHint}
                                xAlignment={'right'}
                                yAlignment={'center'}
                                style={style}
                            >

                            {(this.props.hintText && !this.state.editMode) && <span>
                                <div className="editContainer">
                                    <p title="Edit hint" className="editText" onClick={this.setEditMode}>Edit hint</p>
                                    <p className="border"></p>
                                </div>
                                <p className="title">Hint</p>
                                <p className="subtext">{fieldState.hint.value}</p>
                            </span>
                            }

                            {(!this.props.hintText || this.state.editMode) && <div className="textBox">
                                <p title="Save hint" className="saveText" onClick={this.sendNewHint}>Save hint</p>
                                <p className="saveBorder"></p>
                                <TextBox
                                    id="message"
                                    labelText=""
                                    inputType="email"
                                    placeholder={'Enter hint...'}
                                    value={fieldState.hint.value}
                                    onChange={this.editHint}
                                    resizeable={false}
                                />
                            </div>}
                            </Popover>
                        </div>
                    </div>
                }
            </div>
        );
    }

    @boundMethod
    private setShowHint() {
        this.setState({ showHint: !this.state.showHint, editMode: false });
    }

    @boundMethod
    private setEditMode() {
        this.setState({editMode: true});
    }

    @boundMethod
    private sendNewHint() {
        const { fieldState } = this.state;

        if(this.props.updateHint) {
            // UGH, We'll need to fix this probably with the broken 
            // display popup logic for add hint
            this.props.updateHint(fieldState.hint.value, false);
        }

        this.setState({editMode: false});
    }

    private updateFieldValue(fieldName: string, newValue: any, additionalParams?: Partial<FieldState>) {
        const field = {...this.state.fieldState[fieldName], value: newValue,
            ...additionalParams };
        const fieldState = { ...this.state.fieldState, [fieldName]: field };
        this.setState({ fieldState });
    }

    @boundMethod
    private editHint(e: any) {
        this.updateFieldValue('hint', e.target.value);
    }
}
