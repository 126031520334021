import { createGenericAuthRequest, ignoreJsonError } from '../request/request-utils';
import { MistakeBankLessonsOverview, UserLessonOverview, MaterialType, LessonProgress, Question } from '../elearn/lesson-types';
import { getMaterialTypeEnum, getMaterialTypeString } from '../user/user-lesson-rest-interface';

const request = createGenericAuthRequest(process.env.REACT_APP_ELEARN_URL || '/');
const root = '/sss/';

export interface SchoolSafety {
    categoryId: string;
    categoryName: string;
    duration: string;
    lessonId: string;
    lessonName: string;
    masterOrder: string;
    playOrder: string;
    status: string;
    subCategoryId: string;
    subCategoryName: string;
    videoId: string;
}

/**
 * Updates the data for a particular user
 * @param lessonId is the marker for the college knowledge lesson
 * @param status is the status of the video, can be rewatch, 
 */
export function updateSchoolSafety(lessonId: string, status: string) {
    return request(`/schoolsafety/users/${lessonId}`, {status}, { method: 'PUT' });
}

/**
 * Get all of the college knowledge data associated with a user
 */
export function getSchoolSafetyData() {
    return request(`/schoolsafety`);
}

export function getSSSDashboard() {
    return request(`${root}overview/dashboard`);
}

export function getSSSMistakeBankDashboard() {
    return request(`${root}overview/dashboard/mistakebank`);
}

export function getSSSUserLessons() {
    return request<UserLessonOverview>(`${root}overview/lessons`)
        // Since the server sends over MaterialType enum values as strings, we need to
        // transform them to regular enum values (e.g. 'TOPIC' => MaterialType.TOPIC)
        .then(overview => ({
            userLessonOverviews: overview.sssUserLessonOverviews.map(lesson => ({
                ...lesson,
                currentLessonContent: lesson.currentLessonContent ? {
                    ...lesson.currentLessonContent,
                    type: getMaterialTypeEnum((lesson.currentLessonContent as any).type)
                } : null
            }))
        }));
}

export function getSSSMistakeBankOverview() {
    return request<MistakeBankLessonsOverview>(`/sss/mistakebank/overview?includeSS=true`)
        .then(response => response.topicMistakeBankOverviews);
}

export function setCurrentSSSMaterial(lessonId: string, type: MaterialType, id: string) {
    const args = {
        type: getMaterialTypeString(type),
        id
    };
    return request(`${root}lessons/${lessonId}/content/current`, args, { method: 'PUT' })
        .catch(ignoreJsonError);
}

export function setCurrentSSSLesson(lessonId: string): Promise<{}> {
    return request(`${root}lessons/current/${lessonId}`, {}, { method: 'PUT' })
        .catch(ignoreJsonError);
}

export function addSSSQuestionToMistakeBank(lesson: LessonProgress, question: Question, 
                                            hint: string, displayPopup?: boolean) {
    let args;

    args = {
    categoryName: lesson.category.categoryName,
    lessonName: lesson.lessonName,
    topicName: question.topicName || '',
    hint: hint,
    shouldPromptForHints: displayPopup
    };

    return request(
    `/sss/mistakebank/lessons/${lesson.lessonId}/topics/${question.topicId}/questions/missed/${question.questionId}`,
    args, { method: 'PUT' })
    .catch(ignoreJsonError);
}

export function setSSSTopicQuestionAsReviewed(lessonId: string, topicId: string, questionId: string, answerLetter: string) {
    return request(
        `/sss/mistakebank/lessons/${lessonId}/topics/${topicId}/questions/reviewed/${questionId}/${answerLetter}`, {}, { method: 'PUT' })
        .catch(ignoreJsonError);
}
