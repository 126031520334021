import './ColorButton.css';

import * as React from 'react';

interface Props {
    color: string;
    onClick: (color: string) => any;
    selected?: boolean;
}

const ColorButton: React.SFC<Props> = (props) => {
    const selectedClass = props.selected ? 'color-button--selected' : '';
    return (
        <div className={`color-button ${selectedClass}`} onClick={() => props.onClick(props.color)}>
            <div className="color-button__inner" style={{ backgroundColor: props.color }} />
        </div>
    );
};

ColorButton.displayName = 'ColorButton';

export default ColorButton;
