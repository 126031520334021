import * as React from 'react';

import './index.css';

import * as _ from 'lodash';
import { RouteComponentProps, Link } from 'react-router-dom';
import { getTestTypeDisplayName } from '../../services/utils/practice-test-util';
import { PageContent, PageTitle } from '../../components/PageContent/PageContent';
import Loading from '../../components/Loading/Loading';
import { getTopicsOverTime } from '../../services/practice-test-scoring/practice-test-service';
import TestSection from './components/TestSection';
import LessonTopicGroup from './components/LessonTopicGroup';
import ComparisonSelector from './components/ComparisonSelector';

export interface Props {
}

export interface StoreProps {
}

interface State {
  testSections: any;
  compareLastTwo: boolean; // growth comparison mode
  testType: string;
  testTitle: string;
  stuff?: Array<any>;
}

export type AllProps = Props & StoreProps & RouteComponentProps<any>;

export class TopicsOverTime extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      compareLastTwo: false,
      testSections: [],
      testType: props.match.params.testType,
      testTitle: '',
    };
  }

  public componentDidMount() {
    const { testType } = this.props.match.params;
    getTopicsOverTime(testType)
      .then(result => {
          this.setState({ 
              testSections: result
          });
      })
      .catch(console.log);
  }

  public getLessonGroups(groupedByLesson) {
      // further down, the depths of skullduggery
    return Object
      .keys(groupedByLesson)
      .map(lessonId => {
        const lessonGroup = groupedByLesson[lessonId];
        const topicGroups = _.groupBy(lessonGroup, 'key.topicId');

        const topics = Object
          .keys(topicGroups)
          .map(topicId => {
            const currentTopic = topicGroups[topicId];
            const topicTests = currentTopic.map(item => ({
              name: item.testName,
              testDisplayName: item.testDisplayName,
              correctCount: item.correctCount,
              total: item.total
            }));

            return {
              key: { lessonId: lessonId, topicId: topicId },
              topicDisplayName: currentTopic[0].key.topicName,
              tests: topicTests
            };
          });
        return {
            lessonDisplayName: lessonGroup[0].key.lessonName,
            lessonId,
            topics
          };
      });
  }

  public render() {
    const { testType } = this.props.match.params;
    const { testSections, compareLastTwo } = this.state;

    if (testSections.length === 0) {
      return <Loading />;
    }

    let lessonGroups: any = [];

    const backToTestsLink = '/app/practice-tests?testType=' + testType.toLowerCase();
    let testTypeDisplayName = getTestTypeDisplayName(testType);

    const actSections = ['English', 'Math', 'Reading', 'Science'];
    const satSections = ['Reading', 'Writing and Language', 'Math (No Calculator)', 'Math (Calculator)'];
    let sectionNames = testType.toLowerCase() === 'sat' ?  satSections : actSections;
    const lookupSection = sectionName => testSections.find(section => 
              section.displayName.toLowerCase() === sectionName.toLowerCase());
    let orderedTestSections: Array<any> = [];
    if (testSections.length !== 0) {
      orderedTestSections = sectionNames.map(sectionName =>  
        lookupSection(sectionName)
      );
    }

    return (
      <PageContent className="analysis-container">
        <PageTitle title={`${testType.toUpperCase()} Topics Over Time`}>
          <div className="navigation-button-container">
            <Link to={backToTestsLink}>
                <button className="title-navigation-buttons btn btn--text-white btn--bg-blue">
                    RETURN TO {testTypeDisplayName}
                </button>
            </Link>
          </div>
        </PageTitle>
        <ComparisonSelector 
            onClick={(bLastTwo) => this.setState({ 
                compareLastTwo: bLastTwo })
            }
        />
        {orderedTestSections.map(section => {
            const topics = section.topicsOverTime
            const byLesson = _.groupBy(topics, 'key.lessonId')
            let lessonGroups = this.getLessonGroups(byLesson)
            let lessonThemeClass = 'first-table--' + section.displayName.toLowerCase()
            return (
                <TestSection 
                    key={`test-section-${section.name}`}
                    sectionName={section.displayName}
                >
                    <div className={`lesson-tables-container ` + lessonThemeClass}>
                    {lessonGroups.map(lessonGroup => {
                        return (
                            <LessonTopicGroup 
                                sectionName={section.displayName}
                                compareLastTwo={this.state.compareLastTwo}
                                key={`${lessonGroup.lessonId}-group`}
                                lessonDisplayName={lessonGroup.lessonDisplayName}
                                lessonId={lessonGroup.lessonId}
                                topics={lessonGroup.topics}
                            />);
                    })}
                    </div>
                </TestSection>
            );
          })}
        </PageContent>
      );
  }

  private getIADisplayName(testName) {
    switch(testName) {
      case 'Pre-Test': 
        return 'Pre-Test 2018 (MOY ACT 9th)'
      case 'Practice 1': 
        return 'Practice 1 (MOY ACT 10th)'
      case 'Practice 2': 
      return 'Practice 2 (BOY ACT 9th)'
      case 'Pre-Test 2019': 
        return 'Pre-Test 2019 (EOY ACT 8th)'
      case 'Mid-Test': 
        return 'Mid-Test (EOY ACT 9th)'
      case 'IA 1':
        return 'IA 1 (BOY ACT 10th)'
      case 'IA 2':
        return 'IA 2 (BOY ACT 11th)'
      case 'IA 3':
        return 'IA 3 (EOY ACT 10th)'
      case 'MOY ACT 11th':
          return 'MOY ACT 11th'
          
      default: {
          // console.log('default? shold not haap');
          return testName;
      }
    }
  }
}
