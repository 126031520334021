import React from 'react'
import { Link } from 'react-router-dom'
import "./index.css"

export default function Copyright() {
  let currentYear = new Date().getFullYear()

  return (
    <div className="copyright-container">
      <span className="copyright-left">
        &copy; {currentYear} Winward Academy 
      </span>
      <span className="copyright-right">
          <a href="https://www.winwardacademy.com/privacy/" title="Privacy policy, opens in a new window" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          {" | "}
          <a href="https://www.winwardacademy.com/terms-and-conditions/" title="Terms & Conditions, opens in a new window" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
          {" | "}
          <Link to="/app/sitemap" title="Winward Academy Site Map">Site Map</Link>
      </span>
    </div>
  )
}
