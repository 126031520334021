import * as React from 'react';

interface Props {
    text: string;
}

const defaultProps: Partial<Props> = {
    text: '',
};

const SignUpError: React.SFC<Props> = ({ text }) => {
    return (
        <div
            className="form-group has-danger text-center" 
            style={{ color: 'red', fontSize: 'larger' }}
        >
        {text &&
            <small className="form-control-feedback login__error-text">{text}</small>
        }
        </div>
    );
};

SignUpError.displayName = 'SignUpError';
SignUpError.defaultProps = defaultProps;

export default SignUpError;
