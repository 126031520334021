import './TextInput.css';

import React, { createRef, RefObject } from 'react';

interface Props {
    id: string;
    labelText?: string;
    sucess?: boolean;
    invalid?: boolean;
    feedbackText?: string;
    inputType?: string;
    value: string | number;
    onChange: (e: any) => void;
    onSubmit?: (e: any) => void;
    onFocus?: (e: any) => void;
    onKeyUp?: (e: any) => void;
    onBlur?: (e: any) => void;
    formGroupClass?: string;
    labelClass?: string;
    inputClass?: string;
    size?: 'sm' | 'lg';
    inputRef?: RefObject<HTMLInputElement>;
    imageSource?: string;
    onImageClick?: (e: any) => void;
    showAccessCode?: boolean;
    showCharityCode?: boolean;

}

const defaultProps: Partial<Props> = {
    inputType: 'text',
    formGroupClass: '',
    labelClass: 'text-input-label',
    inputClass: ''
};

const TextInput: React.SFC<Props> = (props) => {
    const invalidClass = props.invalid ? 'has-danger' : '';
    const successClass = props.sucess ? 'has-success' : '';
    const formControlClass = props.size ? `form-control-${props.size}` : '';

    const onSubmit = (e: any) => {
        if(props.onSubmit && e.charCode === 13) {
            props.onSubmit(e);
        }
    };

    return (
        <div className={`form-group ${invalidClass} ${successClass} ${props.formGroupClass}`}>

            {props.labelText && <label className={props.labelClass} htmlFor={props.id}>{props.labelText}</label>}
            {props.imageSource &&
              <img
                className="input-image"
                src={props.imageSource}
                onClick={props.onImageClick}
                alt=""
              />
            }
            {props.id === 'access-code' && props.showAccessCode &&
              <p className="description-field">
                An access code is provided by a sponsoring organization (e.g., a school or after-school program) to allow you to bypass the payment requirement.
              </p>
            }
            {props.id === 'charity-code' && props.showCharityCode &&
              <p className="description-field">
                A charity code can be entered as part of our community give-back programs.
              </p>
            }

            <input
                className={`form-control text-input ${formControlClass} ${props.inputClass}`}
                type={props.inputType}
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                onKeyPress={onSubmit}
                onKeyUp={props.onKeyUp}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                ref={props.inputRef}
                autoComplete="off"
            />

              {(() => {
                if (props.feedbackText && props.id == 'resend-email') {
                  return (
                    <div style={{textAlign: 'center'}}><div className="form-control-feedback login__error-text">{props.feedbackText}</div></div>
                  )
                } else if (props.feedbackText) {
                  return (
                    <div className="form-control-feedback theme-feedback">* {props.feedbackText}</div>
                    )
                }
              })()}
        </div>
    );
};

TextInput.displayName = 'TextInput';
TextInput.defaultProps = defaultProps;

export default TextInput;
