import './MissedQuestions.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import { CollapsableCardBody, CollapsableCardHeader } from '../../../../components/CollapsableCard/CollapsableCard';
import { LessonProgress, MistakeBankLessons } from '../../../../services/elearn/lesson-types';
import MathCheatsheetLink from '../../../../components/MathCheatsheetLink/MathCheatsheetLink';
import CollapsableCard from '../../../../components/CollapsableCard/CollapsableCard';
import LessonListCardHeader from '../../../../components/LessonListCardHeader/LessonListCardHeader';
import MistakeLessonList from './components/MistakeLessonList/MistakeLessonListContainer';
import NoLessons from './components/NoLessons/NoLessons';
import { getSubjectIconList } from '../../../../services/icon/icon-service';

export interface MissedQuestionsProps {
    curriculum: string;
    mistakeBank: boolean;
    lessons: MistakeBankLessons[];
}

interface StoreProps {
    userId: string;
    subjects: {
        [categoryName: string]: LessonProgress[];
    };
}

interface State {

}

type AllProps = MissedQuestionsProps & StoreProps;

const subjectIcons = getSubjectIconList('@0,5x');


export default class MissedQuestions extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
    }

    public render() {
        if(!this.props.subjects) {
            return <NoLessons />;
        }

        let categoryNames = this.props.lessons.reduce((acc: any, lesson: MistakeBankLessons) => {

            const category = lesson.categoryName;

            if(!acc.includes(category)) {
                acc.push(category);
            }

            return acc;

        }, []);

        categoryNames = categoryNames.sort((a, b) => a.localeCompare(b));
        const cards = categoryNames.map(this.createCard);
        if(cards.length === 0 || cards.every(card => !card || !categoryNames)) {
            return <NoLessons />;
        }

        return (
            <div className="subject-view">
                {cards}
            </div>
        );
    }

    @boundMethod
    private createCard(title: string) {

        // Don't show subject cards if the user doesn't have lessons in that subject
        const subject = this.props.lessons.filter(l => l.categoryName === title);
        const index = subjectIcons.findIndex(l => l.title === title);

        const allLessons = subject.filter(lesson =>
            lesson.curricula ? lesson.curricula.some(curriculum => this.props.curriculum === curriculum) : true);
        const numLessons = allLessons.length;
        const numCompletedLessons = allLessons.filter(lesson => lesson.topicReviewStatus === 'TOPIC MASTERED').length;

        if(numLessons === 0) {
            return;
        }

        return (
            <CollapsableCard key={title}>

                <CollapsableCardHeader>
                    <LessonListCardHeader
                        text={title}
                        icon={subjectIcons[index].icon}
                        color={subjectIcons[index].color}
                        numQuestionsCompleted={numCompletedLessons}
                        numQuestionsTotal={numLessons}
                        mistakeBank
                    />
                </CollapsableCardHeader>

                <CollapsableCardBody>
                    {title === 'Math' &&
                      <MathCheatsheetLink />
                    }
                    <MistakeLessonList mistakeLessons={allLessons} />
                </CollapsableCardBody>
            </CollapsableCard>
        );
    }
}
