import * as React from 'react';

import './index.css';

import { PageContent, PageTitle } from '../../components/PageContent/PageContent';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button/Button';
import { createGenericAuthRequest } from '../../services/request/request-utils';
import EdulasticUpload from './components/EdulasticUpload';

interface Props {
}

interface State {
  message: string;
  teacherEmail: string;
  selectedFunction: string;
}

export default class AdminDashboard extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {
        message: '',
        teacherEmail: '',
        selectedFunction: 'reset-student-list'
      };
    }

    resetStudents() {
      const elearnUrl = process.env.REACT_APP_ELEARN_URL;
      const request = createGenericAuthRequest(elearnUrl || '/blergh');
      const { teacherEmail } = this.state;
      this.setState({ 
        message: 'Try resetting students for: ' + teacherEmail 
      });
      // From https://emailregex.com/
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailRegex.test(teacherEmail)) {
          this.setState({ 
            message: 'You must provide a VALID email'
          })
        return;
      }

      var url = `/admin/teacher/${teacherEmail}/students`; 
      // console.log('URL: ', url);
      request(url)
        .then(() => 
          this.setState({ 
            message: 'Successfully reset student list for: ' + teacherEmail 
          })
        )
        .catch(e => {
          if (e.status === 404) {
            this.setState({ 
              message: 'Could not find: ' + teacherEmail 
            })
          } else {
            console.log(e)
          }
        });
    }

    public render() {
      return (
        <PageContent>
          <PageTitle title="Admin" />
          <div className="admin-container">
            <div className="admin-menu">
              <label>Operations: </label>
              <button
                className="btn btn-outline-success" 
                onClick={() => 
                  this.setState({ selectedFunction: 'reset-student-list'})}
              >
                Reset Student List
              </button>
              <button 
                className="btn btn-outline-success" 
                onClick={() => 
                  this.setState({ selectedFunction: 'edulastic-import'})}
              >
                Import Edulastic File
              </button>
            </div>
            <div className="admin-form-area">
              { this.state.selectedFunction === 'reset-student-list' && 
              <div>
              <h5>Reset student list for a given teacher</h5>
              <div className="row">
                <div className="form-group col-lg-4">
                  <label className="">Teacher Email</label>
                  <TextInput 
                    value={this.state.teacherEmail}
                    id="teacher-email"
                    onChange={(e) => this.setState({ teacherEmail: e.target.value })}
                  />
                  <Button
                      text="REMOVE STUDENT ASSOCIATIONS"
                      textColor="white"
                      bgColor="blue"
                      onClick={() => this.resetStudents()}
                  />
                </div>
                <div className="admin-messages col-lg-6">
                  <h6>Messages:</h6>
                  {this.state.message}
                </div>
              </div>
              </div>
              }
              {this.state.selectedFunction === 'edulastic-import' &&
                <EdulasticUpload />
              }
            </div>
          </div>
        </PageContent>
      );
    }
}
