import './StaySharpMCPage.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import {
    CurrentLessonContent,
    LessonProgress,
    Question,
} from '../../services/elearn/lesson-types';

import ContextualTitle from '../ContextualTitle/ContextualTitle';
import Loading from '../Loading/Loading';
import MultipleChoiceQuestion from '../MultipleChoiceQuestion/MultipleChoiceQuestion';
import { PageContent } from '../PageContent/PageContent';
import QuestionProgressBar from '../../scenes/Lesson/components/QuestionProgressBar/QuestionProgressBar';
import { addStaySharpToMistakeBank } from '../../services/elearn/stay-sharp-rest-interface';
import { setStaySharpMCAnswer } from '../../services/elearn/stay-sharp-rest-interface';
import { updateActivityInfoWithLastKnownNavigation } from '../../WinwardTracker';
import { TimerState } from '../Timer/Timer';
import { SimpleTimer } from '../../services/utils/simple-timer';
import { Context, Resource, UserActivityEx, sendUserActivity } from '../../services/liveview/liveview-service';
import { TIMEOUT_IN_SECONDS } from '../../constants';

export interface Props {
    /* LessonID for the current lesson */
    lessonId: string;
    /* TopicID for the current lesson */
    topicId: string;
    /* Fired once all questions have been answered */
    onComplete: () => void;
    /* Called when the user hits the "Exit Lesson" button */
    handleExitLesson: () => void;

    setLessonComplete: () => void;

    questionGroup: Question[];

    shouldShowHintPopup?: boolean;

    // pdfprintout
    pdfPrintout?: string;

    staySharpData: any;
}

export interface DispatchProps {
    addQuestionToMistakeBank: (lesson: LessonProgress, question: Question, hint: string, userInfo: any) => void;
}

export interface StoreProps {
    /* User's current content (question/topic) */
    currentQuestionId: CurrentLessonContent | null;
}

export type AllProps = Props & DispatchProps & StoreProps;

interface State {
    currentQuestionGroupIndex: number;
    /* The question a user is currently viewing/answering */
    currentQuestionIndex: number;
    /* Furthest back a user can look */
    minQuestion: number;
    /* Furthest question index that a user has gotten to (in case they go back to look at an old question) */
    farthestQuestionIndex: number;
    /* Used to track if the user has already answered this question or not, since we only record the first answer */
    firstSubmit: boolean;
    /* Questions from props.questionGroups, except flattened out into a single array */
    questions: Question[];
    isLoaded: boolean;
    addToMB: boolean;
    canNext: boolean;    
}


export default class StaySharpMCPage extends React.Component<AllProps, State> {
    simpleTimerQuestion = new SimpleTimer()
    simpleTimerHint = new SimpleTimer()
    simpleTimerVideo = new SimpleTimer()
    answered = 0 // Answered the question in seconds
    attempts = 0 // Number of attempts till finding the correct answer for current question
    isCorrectlyAnswered = false // Is current question correctly answered?     
    videoStarted = false
    
    constructor(props: AllProps) {
        super(props);

        // tslint:disable-next-line:max-line-length
        const questions = (props.questionGroup || []).reduce((acc, q) => acc.concat(q), [] as Question[]);
        const { questionIndex } = this.getCurrentQuestionIndex(props, questions);

        // TODO
        // const { questionIndex } = {questionIndex: 0};
        this.state = {
            currentQuestionGroupIndex: 0,
            currentQuestionIndex: questionIndex,
            minQuestion: questionIndex,
            farthestQuestionIndex: questionIndex,
            firstSubmit: true,
            questions,
            isLoaded: false,
            addToMB: false,
            canNext: false            
        };
    }

    public componentDidMount() {
        const { questionGroup } = this.props;
        if(!questionGroup || questionGroup.length === 0) { return; }
    }

    public componentWillUnmount() {
        const { questionGroup } = this.props;
        const { addToMB } = this.state;
        if (addToMB && questionGroup) {
            addStaySharpToMistakeBank(this.props.staySharpData,
                this.state.questions[this.state.currentQuestionIndex], '');
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: AllProps) {
        if(nextProps.lessonId !== this.props.lessonId) {
            const index = 0; // this.getCurrentQuestionIndex(nextProps);
            this.setState({ currentQuestionIndex: index, minQuestion: index, farthestQuestionIndex: index },
                () => {
                    if(this.getCurrentQuestionIndex(nextProps).questionIndex !== index) {
                        this.goToQuestion(0);
                    }
                });
        }
        // tslint:disable-next-line:max-line-length
        // const questions = nextProps.questionGroup;
        const questions = (nextProps.questionGroup || []).reduce((acc, q) => acc.concat(q), [] as Question[]);
        this.setState({ questions });
    }

    public render() {
        const { currentQuestionIndex, farthestQuestionIndex, canNext } = this.state;
        // const { currentQuestionIndex, currentQuestionGroupIndex } = this.state;
        const { pdfPrintout } = this.props;
        // const { lesson, questionGroup, pdfPrintout } = this.props;
        const { questions } = this.state;
        // if(!this.props.lessonId) {
        //     return <Loading />;
        // }
        if(currentQuestionIndex < 0 || currentQuestionIndex >= questions.length) {
            return <h1>Could not get question</h1>;
        }

        // const currentQuestionGroup = questionGroup[currentQuestionGroupIndex];
        const currentQuestion = questions[currentQuestionIndex];
        // const currQId = this.state.currentQuestionIndex;
        const pathArray = window.location.pathname.split('/');
        const lessonId = pathArray[pathArray.length - 2];
        const topicId = pathArray[pathArray.length - 1];

        sessionStorage.setItem('topicId', topicId);
        sessionStorage.setItem('currQId', currentQuestion.questionId);
        sessionStorage.setItem('lessonId', lessonId);

        const subTitle = 'Now Sharpening';

        let useMathFont = !!this.props.staySharpData.staySharpExtraQuestions &&
        this.props.staySharpData.staySharpExtraQuestions.categoryName === 'Math';

        let { staySharpData } = this.props        
        let categoryName = staySharpData?.staySharpExtraQuestions?.categoryName
        let topicName = staySharpData?.staySharpExtraQuestions?.topicName
        let activity = `${categoryName}: ${topicName} (Question ${currentQuestionIndex+1})`

        updateActivityInfoWithLastKnownNavigation(activity)

        return (
            <PageContent preContentComponent={this.getQuestionProgressBar()}>

                <ContextualTitle
                  title={topicName}
                  preText={subTitle}
                  linkText={pdfPrintout}
                />

                <MultipleChoiceQuestion
                    question={currentQuestion}
                    onAnswer={this.handleAnswer}
                    onNextQuestion={this.handleNextQuestion}
                    userHasAnswered={currentQuestionIndex < farthestQuestionIndex}
                    isLastQuestion={currentQuestionIndex === questions.length - 1}
                    addHint={this.addHint}
                    shouldShowHintPopup={this.props.shouldShowHintPopup}
                    useMathFont={useMathFont}
                    isTeacher={false}
                    addToMB={this.doIAddToMistakeBank}
                    canNext={this.state.canNext}
                    timerState={TimerState.HIDDEN}
                    onVideoStart={this.onVideoStart}
                />

                {this.props.children}
            </PageContent>
        );
    }

    @boundMethod
    private doIAddToMistakeBank(add: boolean) {
        // console.log('Add to SS MB Checkbox is: ' + add);
        this.setState({
            addToMB: add
        });
    }

    private getQuestionProgressBar() {
        const questionNumbers = this.state.questions.reduce((acc: number[], question, i) => {
            const { questionNumber } = question;
            // Baseline questions don't have question numbers, so we need to just count as we go
            // if there is no questionNumber for this question
            return acc.concat([ questionNumber !== null && questionNumber !== undefined ? questionNumber :
                (acc[i - 1] + 1 || 1)]);
        }, []);

        return (
            <QuestionProgressBar
                questionNumbers={questionNumbers}
                currentQuestionIndex={this.state.currentQuestionIndex}
                farthestQuestionIndex={this.state.farthestQuestionIndex}
                onCloseClick={this.handleExitLesson}
                onQuestionClick={this.goToQuestion}
                isTeacher={false}
            />
        );
    }

    @boundMethod
    private handleAnswer(isCorrect: boolean, answerIndex: number) {
        this.onQuestionAnswered(this.state.currentQuestionIndex, isCorrect, answerIndex);
    }

    private onQuestionAnswered(questionIndex: number, isCorrect: boolean, answerIndex: number) {
        const { lessonId, topicId } = this.props;
        const { questions, currentQuestionIndex, farthestQuestionIndex } = this.state;
        let answerLetter = ['A', 'B', 'C', 'D'][answerIndex];
        const currentQuestion = questions[currentQuestionIndex];

        if (currentQuestionIndex === farthestQuestionIndex) {
            setStaySharpMCAnswer(lessonId, topicId, currentQuestion.questionId, isCorrect, answerLetter).then((res) => {
                this.attempts++

                // Capture the time of answering the question (only first time)
                if (this.answered === 0) {
                    this.answered = this.simpleTimerQuestion.diffInSeconds()
                }

                if (isCorrect) {
                    this.isCorrectlyAnswered = true
                    this.setState({
                        canNext: true
                    }, () => {
                        this.onQuestionComplete(questionIndex);
                    });
                }
            });
        }
    }

    /**
     * Go to next question
     */
    @boundMethod
    private handleNextQuestion() {
        this.sendStatsForQuestion()
        this.goToQuestion(this.state.currentQuestionIndex + 1);
    }

    // private handleNextPassage() {
    //     // TODO
    // }

    /**
     * Navigate to a particular question by index
     */
    @boundMethod
    private goToQuestion(questionIndex: number) {

        if(questionIndex < 0) {
            return;
        }

        if(questionIndex >= this.state.questions.length) {
            this.props.onComplete();
        } else {
            this.setState({
                currentQuestionIndex: questionIndex,
                // currentQuestionGroupIndex: this.getGroupIndexForQuestion(this.props.questionGroup, questionIndex),
                firstSubmit: true,
                canNext: false
            });
        }
    }

    @boundMethod
    private addHint(hint: string, display: boolean) {
        this.sendStatsForHint()
        if(this.props.staySharpData.staySharpExtraQuestions) {
          addStaySharpToMistakeBank(this.props.staySharpData,
            this.state.questions[this.state.currentQuestionIndex], hint, display);
        }
    }

    /**
     * Only called once a question is "complete". This will go to the next question and set the user's
     * "currentContent" to the next question's questionID
     *
     * NOTE:
     * For baseline questions, a question is considered complete once the user answers and hits submit.
     * For MC questions, a question is not considered complete until the user selects the correct answer
     */
    private onQuestionComplete(completedQuestionIndex: number) {
        const nextQuestionIndex = completedQuestionIndex + 1;

        // At this point the notes are shown to the user
        this.simpleTimerHint.start() // Start timer for notes

        // If this is not a new question, return
        const oldFarthestQuestion = this.state.farthestQuestionIndex;
        if(nextQuestionIndex < oldFarthestQuestion) { return; }

        this.setState({
            farthestQuestionIndex: Math.max(this.state.farthestQuestionIndex, nextQuestionIndex)
        });

        if (this.state.farthestQuestionIndex === this.state.questions.length && this.props.setLessonComplete) {
            this.props.setLessonComplete();
        }
    }

    // tslint:disable-next-line:max-line-length
    private getCurrentQuestionIndex(props: AllProps = this.props, questions: Question[] = this.state.questions) {
        const { staySharpData, questionGroup } = props;
        if(!staySharpData.staySharpExtraQuestions || 
            staySharpData.staySharpExtraQuestions.questionGroup.questions.length < 0 ||
            staySharpData.positionInMc.id === '') {
            return { questionIndex: 0, groupIndex: 0 };
        }

        const questionIndex = questions.findIndex(q => q.questionId === staySharpData.positionInMc.id);

        return {
            groupIndex: this.getGroupIndexForQuestion(staySharpData, questionIndex),
            questionIndex
        };
    }

    private getGroupIndexForQuestion(questionGroups: any, questionIndex: number) {
        let groupIndex = 0;
        // tslint:disable-next-line:max-line-length
        // const numQuestionsPerGroup = questionGroups.staySharpExtraQuestions.questionGroup.map(function(group: any) {
        //     return group.questions.length;
        // });

        // let totalQuestionsSoFar = 0;
        // for(let i = 0; i < numQuestionsPerGroup.length; i++) {
        //     totalQuestionsSoFar += numQuestionsPerGroup[i];
        //     if(totalQuestionsSoFar > questionIndex) {
        //         groupIndex = i;
        //         break;
        //     }
        // }

        return groupIndex;
    }

    @boundMethod
    private handleExitLesson() {
        this.sendStatsForQuestion()
        this.props.handleExitLesson();
    }

    private getCategoryName = () => {
        let { staySharpData } = this.props        
        let categoryName = staySharpData?.staySharpExtraQuestions?.categoryName
        return categoryName
    }

    /**
     * Reset the TonP information for the next round!
     */
    private resetTonPStats = () => {
        this.simpleTimerQuestion.reset()
        this.attempts = 0
        this.answered = 0
        this.isCorrectlyAnswered = false
        this.videoStarted = false
    }

    private onVideoStart = () => {
        this.videoStarted = true
        this.simpleTimerVideo.start() // Start for video
    }

    /**
     * Send stats for the question to the backend
     * If the lesson video started then send the stats for it as well
     */
    private sendStatsForQuestion = () => {
        if (this.isCorrectlyAnswered) {
            // Note that there is no explicit video end to send it separately.
            // Also, a user will typically move to the next question
            // So the video stats will be handled here (if started)!
            if (this.videoStarted) {
                this.sendStatsForVideo()
            }

            let seconds = this.simpleTimerQuestion.diffInSeconds()
            let context = Context.STAYSHARP
            let contextId = this.props.lessonId
            let resource = Resource.QUESTION
            let resourceId = `q-${this.state.currentQuestionIndex + 1}`
            let answered = this.answered
            let ended = seconds
            let attempts = this.attempts
            let timedout = answered > TIMEOUT_IN_SECONDS
            let category = this.getCategoryName()
            let userActivityEx: UserActivityEx = {
                context, contextId, 
                resource, resourceId, 
                category,
                attempts,
                answered,
                ended,
                timedout
            }

            // Send the stats for the question
            sendUserActivity(userActivityEx)            
            this.resetTonPStats()
        }
    }

    /**
     * Send stats for a hint entry to the backend
     */
    private sendStatsForHint = () => {
        let seconds = this.simpleTimerHint.diffInSeconds()

        if (seconds > 0) {
            let context = Context.STAYSHARP
            let contextId = this.props.lessonId
            let resource = Resource.HINT
            let resourceId = `h-${this.state.currentQuestionIndex + 1}`
            let ended = seconds
            let timedout = seconds > TIMEOUT_IN_SECONDS
            let category = this.getCategoryName()
            let userActivityEx: UserActivityEx = {
                context, contextId, 
                resource, resourceId, 
                category,
                ended,
                timedout
            }
            sendUserActivity(userActivityEx)
        }
    }

    /**
     * Send stats for watching a video to the backend
     */
    private sendStatsForVideo = () => {
        let seconds = this.simpleTimerVideo.diffInSeconds()        
        if (seconds > 0) {
            let context = Context.STAYSHARP
            let contextId = this.props.lessonId
            let resource = Resource.VIDEO
            let resourceId = `v-${this.state.currentQuestionIndex + 1}`
            let ended = seconds
            let timedout = seconds > TIMEOUT_IN_SECONDS
            let category = this.getCategoryName()
            let userActivityEx: UserActivityEx = {
                context, contextId, 
                resource, resourceId, 
                category,
                ended,
                timedout
            }
            sendUserActivity(userActivityEx)
        }
    }
}
