import { CATEGORY_READING, CATEGORY_SCIENCE } from "../../constants"

/**
 * Will determine if the category is Reading or Science
 * 
 * @param category 
 * @returns 
 */
export function isCategoryReadingOrScience(category: string) {
  return category === CATEGORY_READING || category === CATEGORY_SCIENCE
}
