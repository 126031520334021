import './SignUp.css';

import * as React from 'react';
import * as Script from 'react-load-script';
import * as _ from 'lodash';
import { boundMethod } from 'autobind-decorator';
import PhoneLink from './PhoneLink';

import { 
    NewAccount, 
    registerUserWithAccessCode
} from '../../services/account/account-rest-interface';

import { Gender, getGenderEnum, getGenderOptions, getGenderText } from './constants/sign-up-types';

import BlueGeometricBackground from '../../components/BlueGeometricBackground/BlueGeometricBackground';
import Button from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';
import SignUpError from './SignUpError';
import SignUpHeader from './SignUpHeader';
import RegistrationContainer from './RegistrationContainer';
import PaymentProcessingModal from '../../components/PaymentProcessingModal';
import { Moment } from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import { SignUpFields } from './SignUpForm';
import ButtonLoading from '../../components/ButtonLoading/ButtonLoading';

interface Props {

}

interface RouteProps extends RouteComponentProps<any> {}

interface State {
    isLoading: boolean;
    showProgressModal: boolean;
    serverMessage: string;
    shouldValidate: boolean;
    validationMessage: string;
    newAccountInfo?: SignUpFields;
    formIsValid: boolean;
}


export default class SignUp extends React.PureComponent<Props & RouteProps, State> {
    constructor(props: Props & RouteProps) {
        super(props);

        this.state = {
            validationMessage: '',
            isLoading: false,
            showProgressModal: false,
            serverMessage: '',
            shouldValidate: false,
            formIsValid: false
        };
    }

    public render() {
        const { isLoading } = this.state;
        const useCode = this.props.match.params.useCode;
        let isUSP = false;
        if (useCode && useCode.toLowerCase() === 'usp') {
            isUSP = true;
        }
        return (
            <BlueGeometricBackground className="sign-up-container">
                {/* Actually just the wait for account setup modal */}
                <PaymentProcessingModal 
                    showModal={this.state.showProgressModal}
                />
                <div className="order-help-banner">
                    <div className="order-help-branding justify-content-between align-items-center d-none d-sm-flex">
                        <div className={'charity-first-logo d-none d-sm-flex justify-content-center align-items-center ' + isUSP ? 'usp-wwa-image' : ''}>
                            {isUSP && 
                                <a href="https://www.winwardacademy.com" target="_blank">
                                    <img
                                        alt=""
                                        className="img-fluid order-help-image full-size" 
                                        src={'/assets/images/usp-wwa.png'} 
                                    /> 
                                </a>
                            }
                            {!isUSP &&
                                <a href="https://www.winwardacademy.com" target="_blank">
                                    <img
                                        alt=""
                                        className="img-fluid order-help-image full-size" 
                                        src={'/assets/images/winward-academy-logo-color.svg'} 
                                    /> 
                                </a>
                            }
                        </div>
                        <div className="justify-content-center d-none d-sm-flex align-items-center flex-column order-help-text">
                            <p className="d-flex no-bottom-margin">Need help signing up?</p>
                            <p className="d-flex no-bottom-margin"><a href="mailto:help@winwardacademy.com?subject=Help Signing Up">Email us</a>&nbsp;or call&nbsp;
                                <PhoneLink />
                            </p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center flex-column mobile-order-help-text order-help-text d-sm-none">
                        <p className="d-flex no-bottom-margin">Need help signing up?</p>
                        <p className="d-flex no-bottom-margin"><a href="mailto:help@winwardacademy.com?subject=Help Signing Up">Email us</a>&nbsp;or call&nbsp;
                            <PhoneLink />
                        </p>
                    </div>
                </div>
                {/* Header text, errgh login__header needs refactoring */}
                {/* Make this a style-container for use with Bundle page and others */}
                <RegistrationContainer>
                    <div className="row sign-up-header">
                        <div className="col-12">
                            <h1 className="register-act-prep-title">Welcome to Winward Academy</h1>
                        </div>
                    </div>
                    <SignUpForm
                        onChange={(newAccountInfo, formIsValid) => { 
                            if (formIsValid) {
                                this.setState({ validationMessage: '' });
                            }

                            this.setState({
                                newAccountInfo,
                                formIsValid,
                            });
                        }}
                        shouldValidate={this.state.shouldValidate}
                        requireAccessCode={true}
                    />
                    <SignUpError text={this.state.validationMessage}/>
                    <SignUpError text={this.state.serverMessage}/>

                    <div className="row">
                      <Button
                          text={this.state.isLoading ? 
                              <ButtonLoading className="sign-up__loading" /> : 
                              <span>CREATE ACCOUNT</span>
                          }
                          className="sign-up__create-account"
                          onClick={this.handleCreateAccount}
                          bgColor="green"
                          disabled={isLoading}
                      />
                    </div>
                </RegistrationContainer>
            </BlueGeometricBackground>
        );
    }

    @boundMethod
    private handleCreateAccount() {
        this.setState({ shouldValidate: true });
        if (!this.state.formIsValid) {
            return;
        }

        const info = this.state.newAccountInfo;
        if (info) {
            this.setState({ 
                isLoading: true,
                showProgressModal: true,
                serverMessage: ''
            });

            const accountInfo: NewAccount = {
                firstName: info.firstName,
                lastName: info.lastName,
                emailAddress: info.emailAddress,
                birthDate: info.dob,
                gender: info.gender, 
                profileColor: info.profileColor,
                password: info.password,

                expirationDate: new Date(),
                bundleId: 'freeTrial',

                // TODO: Get rid of this from freeTrial?
                charityCode: '',
                includeCollegeKnowledge: false
            };

            // console.log(info.accessCode ? info.accessCode : 'NO ACCESS CODE');
            if (!info.accessCode) {
                return;
            }
            registerUserWithAccessCode(accountInfo, info.accessCode)
                .then(response => {
                    // console.log('SUCCESS', response);
                    this.setState({ showProgressModal: false });
                    const email = encodeURIComponent(accountInfo.emailAddress);
                    this.props.history.push(`/welcome?email=${email}`);
                }).catch(e => { 
                    console.log('Server err while registering user', { e });
                    this.setState({ 
                        isLoading: false,
                        serverMessage: e.message,
                        showProgressModal: false,
                    });
                });
            }
        }
}
