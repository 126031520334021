import React from "react"
import CollapsableCard, { CollapsableCardBody, CollapsableCardHeader } from '../../../components/CollapsableCard/CollapsableCard'
import { FilterSelections, prepareSelectionsInfo, TestKind } from "../SuperintendentFilters"
import { filterTests, GraduationYearForTests, Test, Tests, TestsSummary, TestSummarySchool } from "../../../stores/superintendent-store"
import { round } from "../../../services/utils/math-util"
import { MiniCaret } from '../../../components/MiniCaret'
import { GraduationYearsViewACTandIA, GraduationYearsViewSAT, StudentReportButton, TestViewProps, TestViewState } from "./shared"
import { getTestNamesInSpecialOrder } from "../../../services/utils/practice-test-util"
import { CATEGORY_ENGLISH, CATEGORY_MATH, CATEGORY_READING, CATEGORY_SCIENCE, EMPTY, NA } from "../../../constants"
import "./index.css"

export interface IViewBySchoolProps {
  summary: TestsSummary
  filterSelections: FilterSelections
  onShowStudentsTestsInsights: (schoolName: string, testName: string, graduationYear?: string) => void
  onShowStudentsTestsDetailsByCategory: (category: string, schoolName: string, testName: string, graduationYear?: string) => void
}

interface IViewBySchoolState {

}

export class ViewBySchool extends React.Component<IViewBySchoolProps, IViewBySchoolState> {
  constructor(props) {
    super(props)
  }

  render() {
    let {summary, filterSelections, onShowStudentsTestsInsights, onShowStudentsTestsDetailsByCategory} = this.props
    let {schools, graduationYears, testKind} = filterSelections
    let selectedSchoolNames = schools.filter(s => s.selected).map(s => s.name)
    let selectedGraduationYears = graduationYears.filter(gy => gy.selected).map(gy => gy.name)
    let filteredTests = filterTests(summary, selectedSchoolNames, selectedGraduationYears)
    let showACTSection = testKind === TestKind.ALL || testKind === TestKind.ACT
    let showIASection  = testKind === TestKind.ALL || testKind === TestKind.IA
    let showSATSection = testKind === TestKind.ALL || testKind === TestKind.SAT
    let subtitle = prepareSelectionsInfo(filterSelections).selectionInfoForReports

    return (
      <div className="si-practice-tests">   
        <div className="all-tests-sections">
          {showACTSection && <TestSummaryViewBySchool schools={filteredTests} testKind={TestKind.ACT} title="ACT Summary" subtitle={subtitle} onShowStudentsTestsInsights={onShowStudentsTestsInsights} onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}/>}
          {showSATSection && <TestSummaryViewBySchool schools={filteredTests} testKind={TestKind.SAT} title="SAT Summary" subtitle={subtitle} onShowStudentsTestsInsights={onShowStudentsTestsInsights} onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}/>}
          {showIASection  && <TestSummaryViewBySchool schools={filteredTests} testKind={TestKind.IA}  title="IA Summary"  subtitle={subtitle} onShowStudentsTestsInsights={onShowStudentsTestsInsights} onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}/>}
        </div>
      </div>
    )
  }
}

class TestViewACTandIA extends React.Component<TestViewProps, TestViewState> {
  constructor(props) {
    super(props)
    this.state = {
      showDetails: false
    }
  }

  toggleDetails = () => {
    this.setState({showDetails: !this.state.showDetails})
  }

  showStudentsTestsInsights = (e: Event, schoolName: string, testName: string) => {
    let {onShowStudentsTestsInsights} = this.props
    if (onShowStudentsTestsInsights) onShowStudentsTestsInsights(schoolName, testName)
  }

  render() {
    let {schoolName, testName, data, ind, onShowStudentsTestsInsights, onShowStudentsTestsDetailsByCategory} = this.props
    let {showDetails} = this.state
    let {testDisplayName, details, graduationYears} = data 
    let nofStudents = details.nofStudents

    // Averages
    let avgC = round(details.composite?.avg)
    let avgE = round(details.english?.avg)
    let avgM = round(details.math?.avg)
    let avgR = round(details.reading?.avg)
    let avgS = round(details.science?.avg)
    let infoAvgC = nofStudents > 0 ? avgC: EMPTY
    let infoAvgE = nofStudents > 0 ? avgE: EMPTY
    let infoAvgM = nofStudents > 0 ? avgM: EMPTY
    let infoAvgR = nofStudents > 0 ? avgR: EMPTY
    let infoAvgS = nofStudents > 0 ? avgS: EMPTY
    let nC = details.composite?.nofStudents
    let nE = details.english?.nofStudents
    let nM = details.math?.nofStudents
    let nR = details.reading?.nofStudents
    let nS = details.science?.nofStudents
    let infoNC = nofStudents > 0 ? `n=${nC}`: EMPTY
    let infoNE = nofStudents > 0 ? `n=${nE}`: EMPTY
    let infoNM = nofStudents > 0 ? `n=${nM}`: EMPTY
    let infoNR = nofStudents > 0 ? `n=${nR}`: EMPTY
    let infoNS = nofStudents > 0 ? `n=${nS}`: EMPTY

    // Full info including buttons for reports
    let fullInfoC = nofStudents > 0 && nC > 0 ? <span>{infoAvgC}<br/>{infoNC}</span>: NA
    let fullInfoE = nofStudents > 0 && nE > 0 ? <span>{infoAvgE}<br/>{infoNE}<br/><StudentReportButton titleAttr={testDisplayName} text={`English Report`} tabindex={nofStudents > 0 ? 0 : -2} onClick={() => onShowStudentsTestsDetailsByCategory(CATEGORY_ENGLISH, schoolName, testName)}/></span>: NA
    let fullInfoM = nofStudents > 0 && nM > 0 ? <span>{infoAvgM}<br/>{infoNM}<br/><StudentReportButton titleAttr={testDisplayName} text={`Math Report`} tabindex={nofStudents > 0 ? 0 : -2} onClick={() => onShowStudentsTestsDetailsByCategory(CATEGORY_MATH, schoolName, testName)}/></span>: NA
    let fullInfoR = nofStudents > 0 && nR > 0 ? <span>{infoAvgR}<br/>{infoNR}<br/><StudentReportButton titleAttr={testDisplayName} text={`Reading Report`} tabindex={nofStudents > 0 ? 0 : -2} onClick={() => onShowStudentsTestsDetailsByCategory(CATEGORY_READING, schoolName, testName)}/></span>: NA
    let fullInfoS = nofStudents > 0 && nS > 0 ? <span>{infoAvgS}<br/>{infoNS}<br/><StudentReportButton titleAttr={testDisplayName} text={`Science Report`} tabindex={nofStudents > 0 ? 0 : -2} onClick={() => onShowStudentsTestsDetailsByCategory(CATEGORY_SCIENCE, schoolName, testName)}/></span>: NA
    
    return (
      <>
        <tr className={`isDropdown test-view ${(nofStudents > 0) ? '' : 'disabled '} ${(ind % 2 == 0) ? 'odd' : 'even'}`} >
          <td className="accordion-cell">
            <button className="btn-simple btn-icon" title={`toggle ${testDisplayName} details`} onClick={this.toggleDetails} tabIndex={nofStudents > 0 ? 0 : -2}><MiniCaret up={!showDetails} /></button>
            <span className="si-practicetest-view-by-school">{testDisplayName}</span>
            <br/>
            <StudentReportButton titleAttr={testDisplayName} className="si-student-test-reports-btn" tabindex={nofStudents > 0 ? 0 : -2} onClick={(e) => this.showStudentsTestsInsights(e, schoolName, testName)}/>
          </td>
          <td className={avgC > 0 ? 'cell-composite' : 'cell-composite disabled'}>{fullInfoC}</td>
          <td className={avgE > 0 ? '' : 'disabled'}>{fullInfoE}</td>
          <td className={avgM > 0 ? '' : 'disabled'}>{fullInfoM}</td>
          <td className={avgR > 0 ? '' : 'disabled'}>{fullInfoR}</td>
          <td className={avgS > 0 ? '' : 'disabled'}>{fullInfoS}</td>
        </tr>
        {showDetails ?
          <GraduationYearsViewACTandIA 
            ind={ind}
            schoolName={schoolName}
            testName={testName}
            graduationYears={graduationYears} 
            onShowStudentsTestsInsights={onShowStudentsTestsInsights}
            onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}
            />
          :
          ""
        }
      </>
    )
  }
}

class  TestViewSAT extends React.Component<TestViewProps, TestViewState> {
  constructor(props) {
    super(props)
    this.state = {
      showDetails: false
    }
  }

  toggleDetails = () => {
    this.setState({showDetails: !this.state.showDetails})
  }

  render() {
    let {schoolName, testName, data, ind, onShowStudentsTestsInsights} = this.props
    let {showDetails} = this.state
    let {testDisplayName, details, graduationYears} = data        
    let nofStudents = details.nofStudents       
    let nofStudentsM = details.math?.nofStudents
    let nofStudentsEBRW = details.ebrw?.nofStudents

    // Averages
    let avgM = round(details.math?.avg)
    let avgEBRW = round(details.ebrw?.avg)
    let score = round(avgM + avgEBRW)
    let infoScore = nofStudents > 0 ? <span>{score}<br/>n={nofStudents}</span>: NA
    let infoEBRW = nofStudents > 0 ? <span>{avgEBRW}<br/>n={nofStudentsEBRW}</span>: NA
    let infoM = nofStudents > 0 ? <span>{avgM}<br/>n={nofStudentsM}</span>: NA

    return (
      <>
        <tr className={`isDropdown test-view ${(nofStudents > 0) ? '' : 'disabled '} ${(ind % 2 == 0) ? 'odd' : 'even'}`}>
          <td className="accordion-cell">
            <button className="btn-simple btn-icon" title={`toggle ${testDisplayName} details`} onClick={this.toggleDetails} tabIndex={nofStudents > 0 ? 0 : -2}><MiniCaret up={!showDetails} /></button>
            <span className="si-practicetest-view-by-school">{testDisplayName}</span>
            <br/>
            <StudentReportButton titleAttr={testName} className="si-student-test-reports-btn" tabindex={nofStudents > 0 ? 0 : -2} onClick={() => onShowStudentsTestsInsights(schoolName, testName)}/>
          </td>
          <td className="cell-composite">{infoScore}</td>
          <td>{infoEBRW}</td>
          <td>{infoM}</td>
        </tr>

        {showDetails ?
          <GraduationYearsViewSAT 
            ind={ind}
            schoolName={schoolName}
            testName={testName}
            graduationYears={graduationYears}
            onShowStudentsTestsInsights={onShowStudentsTestsInsights}
          />
          :
          ""
        }
      </>
    )
  }
}


interface TestSummaryViewProps {
  schools: TestSummarySchool[]
  testKind: TestKind
  title: string
  subtitle: string
  onShowStudentsTestsInsights: (schoolName: string, testName: string, graduationYear?: string) => void
  onShowStudentsTestsDetailsByCategory: (category: string, schoolName: string, testName: string, graduationYear?: string) => void
}

const TestSummaryViewBySchool = (props: TestSummaryViewProps) => {
  let {schools, testKind, title, subtitle, onShowStudentsTestsInsights, onShowStudentsTestsDetailsByCategory} = props

  function cardBodyContent(school: TestSummarySchool, testKind) {
    let {name: schoolName, scores} = school
    let tests: Tests = scores[testKind]
    let testNames = Object.keys(tests)

    testNames = getTestNamesInSpecialOrder(testNames, testKind)
    
    let isACTorIA = testKind === TestKind.ACT || testKind === TestKind.IA

    return (
      <>
        <thead className={isACTorIA ? "act-header-theme" : "sat-header-theme"}>
          <tr>
          {isACTorIA ?
            <>
              <th className="label-header p-0"><span className="border-header first">TEST</span></th>
              <th className="label-header p-0"><span className="border-header">Composite</span></th>
              <th className="label-header p-0"><span className="border-header">English</span></th>
              <th className="label-header p-0"><span className="border-header">Math</span></th>
              <th className="label-header p-0"><span className="border-header">Reading</span></th>
              <th className="label-header p-0"><span className="border-header">Science</span></th>
            </>
            :
            <>
              <th className="label-header p-0"><span className="border-header first">TEST</span></th>
              <th className="label-header p-0"><span className="border-header">Score</span></th>
              <th className="label-header p-0"><span className="border-header">EBRW</span></th>
              <th className="label-header p-0"><span className="border-header">Math</span></th>
            </>
          }
          </tr>
        </thead>
        <tbody>
          {testNames.map((testName, index) => {
            let testData: Test = tests[testName]
            let isACTorIA = testKind === TestKind.ACT || testKind === TestKind.IA
            let key = `${testName}-${index}`

            return (
              isACTorIA ?
                <TestViewACTandIA 
                  key={key}
                  ind={index}
                  schoolName={schoolName}
                  testName={testName} 
                  data={testData}
                  onShowStudentsTestsInsights={onShowStudentsTestsInsights}
                  onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}
                />
                :
                <TestViewSAT 
                  key={key}
                  ind={index} 
                  schoolName={schoolName}
                  testName={testName}
                  data={testData}
                  onShowStudentsTestsInsights={onShowStudentsTestsInsights}
                  onShowStudentsTestsDetailsByCategory={onShowStudentsTestsDetailsByCategory}
                />
            )
          })}
        </tbody>
      </>
    )
  }
  let isACTorIA = testKind === TestKind.ACT || testKind === TestKind.IA
  let tableClassModifier = 'table-5-col'
  if(isACTorIA) {
    tableClassModifier = 'table-7-col'
  }
  if(testKind === TestKind.IA) {
    tableClassModifier += ' ia-table'
  }

  return (
    <div className="mb-4">
      <h2>{title}</h2>
      {subtitle &&
        <p className="summary-headline" dangerouslySetInnerHTML={{ __html: subtitle }} />
      }
      <div className="tests-summary">
          {schools.map((school) => {
            let {name: schoolName} = school
            return (
              <CollapsableCard key={schoolName}>
                <CollapsableCardHeader>
                  <div className="lesson-list-card-header no-icon mb-1">
                    <span>{schoolName}</span>
                  </div>
                </CollapsableCardHeader>
                <CollapsableCardBody>
                <div className="fixedHeaderTable">
                  <table className={tableClassModifier + ` lesson-summary-table test-summary-table`}>
                    {cardBodyContent(school, testKind)}
                  </table>
                </div>
                </CollapsableCardBody>
              </CollapsableCard>
            )
          })}
      </div>
    </div>
  )
}
