import React from "react"
import Modal from "../../../components/Modal/Modal"
import { isValidEmailAddress } from "../../../services/utils/text-util"
import { IInvitationResult, IStudyBuddy } from "./types"
import StudyBuddiesInfoCard from "./StudyBuddiesInfoCard"
import StudyBuddiesManageView from "./StudyBuddiesManageView"
import { acceptStudyBuddy, addStudyBuddy, deleteStudyBuddy, getAllStudyBuddies, rejectStudyBuddy } from "../../../services/study-buddies"
import { SB_CONNECTION_REQUESTS, SB_CURRENT_STUDY_BUDDIES, SB_INVALID_EMAIL_ADDRESS, SB_NO_CONNECTION_REQUESTS, SB_NO_CURRENT_STUDY_BUDDIES, SB_NO_PENDING_INVITES, SB_PENDING_INVITES, SB_STATUS_ACCEPTED, SB_STATUS_PENDING, SB_STATUS_REQUESTING, SB_TOAST_MESSAGE_DURATION_MS, SB_WAS_SENT_AN_INVITE } from "../../../constants"
import { LiveViewStatus, updateLiveViewStatus } from "../../../services/liveview/liveview-service"
import "./index.css"

interface Props {

}

interface State {
    invitationResult?: IInvitationResult
    studyBuddies: IStudyBuddy[]
    studyBuddiesRequests: IStudyBuddy[]
    studyBuddiesInvites: IStudyBuddy[]
    showModal: boolean
}

export default class StudyBuddiesView extends React.Component<Props, State> {
    timerId

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            studyBuddies: [],
            studyBuddiesRequests: [],
            studyBuddiesInvites: [],
        }
    }

    componentDidMount() {
        this.getAllBuddies()
        let status: LiveViewStatus = { navigation: "Mistake Bank", activity: "Study Buddies"}
        updateLiveViewStatus(status)
    }

    async getAllBuddies() {
        let allStudyBuddiesRaw = await getAllStudyBuddies()
        let studyBuddiesRaw = allStudyBuddiesRaw.filter(b => b.status === SB_STATUS_ACCEPTED)
        let studyBuddiesRequestsRaw = allStudyBuddiesRaw.filter(b => b.status === SB_STATUS_REQUESTING)
        let studyBuddiesInvitesRaw = allStudyBuddiesRaw.filter(b => b.status === SB_STATUS_PENDING)
        let simplifier = (sb) => {
            return {
                emailAddress: sb.emailAddressOfBuddy,
                userId: sb.infoOfBuddy.userId,
                firstName: sb.infoOfBuddy.firstName,
                lastName: sb.infoOfBuddy.lastName,
                profileColor: sb.infoOfBuddy.profileColor,
                schoolName: sb.infoOfBuddy.schoolName,
            }
        }
        let simplifiedStudyBuddies = studyBuddiesRaw.map(simplifier)
        let simplifiedStudyBuddiesRequests = studyBuddiesRequestsRaw.map(simplifier)
        let simplifiedStudyBuddiesInvites = studyBuddiesInvitesRaw.map(simplifier)
        this.setState({studyBuddies: simplifiedStudyBuddies, studyBuddiesInvites: simplifiedStudyBuddiesInvites, studyBuddiesRequests: simplifiedStudyBuddiesRequests})
    }

    onInvite = (emailAddress: string) => {
        if (emailAddress === "") return
        let isValid = isValidEmailAddress(emailAddress)
        
        if (isValid) {
            addStudyBuddy(emailAddress)
                .then(res => {
                    this.showToastMessage(true, `${emailAddress} ${SB_WAS_SENT_AN_INVITE}`)
                })
                .catch(async (ex) => {
                    let errorInfo = await ex.text()
                    this.showToastMessage(false, errorInfo)
                })
                .finally(() => {
                    this.getAllBuddies()
                })

        }
        else {
            this.showToastMessage(false, SB_INVALID_EMAIL_ADDRESS )
        }
    }

    /**
     * Show the success of error as a toast message based on the flag
     * @param success 
     * @param message 
     */
    showToastMessage(success: boolean, message: string) {        
        this.setState({invitationResult: { success, message }})
        clearTimeout(this.timerId)
        this.timerId = setTimeout(() => {
            this.setState({invitationResult: undefined})
        }, SB_TOAST_MESSAGE_DURATION_MS)
    }

    onEmailAddressChange = (emailAddress: string) => { } // Not used!

    onAccept = (emailAddress: string) => {
        acceptStudyBuddy(emailAddress)
            .then(res => {
                this.getAllBuddies()
            })
            .catch(async (ex) => {
                let errorInfo = await ex.text()
                this.showToastMessage(false, errorInfo)
            })
    }

    onIgnore = (emailAddress: string) => {
        rejectStudyBuddy(emailAddress)
            .then(res => {
                this.getAllBuddies()
            })
            .catch(async (ex) => {
                let errorInfo = await ex.text()
                this.showToastMessage(false, errorInfo)
            })
    }

    onRemoveBuddy = (emailAddress: string) => {
        deleteStudyBuddy(emailAddress)
            .then(res => {
                this.getAllBuddies()
            })
            .catch(async (ex) => {
                let errorInfo = await ex.text()
                this.showToastMessage(false, errorInfo)
            })
    }

    handleShowModal = () => {
        this.getAllBuddies()
        this.setState({showModal: true})
    }

    closeModal = () => {
        this.setState({ showModal: false })
    }

    render() {
        let { studyBuddies, studyBuddiesRequests, studyBuddiesInvites } = this.state
        let { invitationResult, showModal } = this.state
        let showXTopRight = false
        let showXinside = true
        let modalWidth = "800px"
        let managementDisabled = studyBuddies.length + studyBuddiesInvites.length === 0

        return (
            <div className="">
                <StudyBuddiesInfoCard 
                    onEmailAddressChange={this.onEmailAddressChange} 
                    onInvite={this.onInvite} 
                    invitationResult={invitationResult} />

                <div className="student-data-card studybuddies-container">
                    <div className="studybuddies-container--header mb-2">
                    <h2 className="studybuddies-container-title mb-2 mb-sm-0">Study Buddies</h2>
                    <button className="btn btn--text-white btn--bg-green" onClick={this.handleShowModal} disabled={managementDisabled}>MANAGE BUDDIES</button>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <StudyBuddiesCurrent  buddies={studyBuddies} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 mb-2 mb-xl-0 pr-xl-2">
                            <StudyBuddiesRequests buddies={studyBuddiesRequests} onAccept={this.onAccept} onIgnore={this.onIgnore} />
                        </div>
                        <div className="col-xl-6 mb-2 mb-xl-0 pl-xl-2">
                            <StudyBuddiesInvites  buddies={studyBuddiesInvites} />
                        </div>
                    </div>
                </div>

                <Modal
                    className="sb-add-friends-modal"
                    show={showModal}
                    onClose={this.closeModal}
                    showXTopRight={showXTopRight}
                    showXinside={showXinside}
                    width={modalWidth}>
                    {showModal &&
                        <StudyBuddiesManageView 
                            buddies={studyBuddies}
                            invitedBuddies={studyBuddiesInvites}
                            onRemoveBuddy={this.onRemoveBuddy} />
                    }
                </Modal>
            </div>
        )
    }
}

//--- Helpers ---

function StudyBuddiesCurrent(props) {
    let {buddies} = props

    return (
        <div className="studybuddies-group current">
            <div className="studybuddies-group--title px-1">{SB_CURRENT_STUDY_BUDDIES}</div>
            <div className="studybuddies-group--content">
            <div className="row mx-0">
                    {buddies.length > 0 ?
                        buddies.map(buddy =>
                            <StudyBuddyConnected buddy={buddy} />)
                        :
                        <p><i>{SB_NO_CURRENT_STUDY_BUDDIES}</i></p>
                    }
                </div>
            </div>
        </div>
    )
}

function StudyBuddiesRequests(props) {
    let {buddies, onAccept, onIgnore} = props

    return (
        <div className="studybuddies-group bg-light">
            <div className="studybuddies-group--title px-1">{SB_CONNECTION_REQUESTS}</div>
            <div className="studybuddies-group--content">
                <div className="row studybuddies-row mx-0">
                    {buddies.length > 0 ?
                        buddies.map(buddy =>
                            <div className="col-12 studybuddy-col">
                                <StudyBuddyConnectionRequested
                                    buddy={buddy}
                                    onAccept={onAccept}
                                    onIgnore={onIgnore} />
                            </div>
                        )
                        :
                        <p><i>{SB_NO_CONNECTION_REQUESTS}</i></p>
                    }
                </div>
            </div>
        </div>
    )
}

function StudyBuddiesInvites(props) {
    let {buddies} = props

    return (
        <div className="studybuddies-group bg-light">
            <div className="studybuddies-group--title px-1">{SB_PENDING_INVITES}</div>
            <div className="studybuddies-group--content">
                <div className="row studybuddies-row mx-0">
                    {buddies.length > 0 ?
                        buddies.map(buddy =>
                            <div className="col-12 studybuddy-col">
                                <StudyBuddyPending buddy={buddy} />
                            </div>
                        )
                    :
                        <p><i>{SB_NO_PENDING_INVITES}</i></p>
                    }
                </div>
            </div>
        </div>
    )
}

function StudyBuddyConnected(props) {
    let {buddy} = props
    let {firstName, lastName, emailAddress, profileColor} = buddy

    return (
        <div className="col-lg-6 col-xl-4 mb-2 pl-1 pr-2">
            <div className="studybuddy">
                <div className="studybuddy--accent" style={{"backgroundColor": profileColor}}></div>
                <div className="studybuddy--avatar">
                    <div className="avatar__circle" style={{"backgroundColor": profileColor}}><p className="avatar__circle--text">{firstName[0]}{lastName[0]}</p></div>
                </div>
                <div>
                    <div className="studybuddy--name">
                        {firstName} {lastName}
                    </div>
                    <div className="studybuddy--email">
                        {emailAddress}
                    </div>
                </div>
            </div>
        </div>
    )
}

function StudyBuddyConnectionRequested(props) {
    let {buddy, onAccept, onIgnore} = props
    let {firstName, lastName, emailAddress, profileColor} = buddy

    return (
        <div className="studybuddy">
            <div className="studybuddy--accent" style={{"backgroundColor": profileColor}}></div>
            <div className="row w-100 mx-0">
                <div className="studybuddy--2col">
                    <div className="studybuddy--2col inner">
                        <div className="studybuddy--avatar">
                            <div className="avatar__circle" style={{"backgroundColor": profileColor}}><p className="avatar__circle--text">{firstName[0]}{lastName[0]}</p></div>
                        </div>
                        <div>
                            <div className="studybuddy--name">
                                {firstName} {lastName}
                            </div>
                            <div className="studybuddy--email">
                                {emailAddress}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col px-0 text-center ml-auto studybuddy--actions">
                    <button className="btn btn--text-white btn--bg-green mb-2" onClick={e => onAccept(emailAddress)}>ACCEPT</button>
                    <a href="#" className="hyperlink hyperlink--small iconLink--left img-rel m-0" onClick={e => onIgnore(emailAddress)}>
                    <img alt="" src="/assets/images/icons/v2/ico-x.svg" width="12" height="12"/> Ignore Request</a>
                </div>
            </div>
        </div>
    )
}

function StudyBuddyPending(props) {
    let {buddy} = props
    let {firstName, lastName, emailAddress, profileColor} = buddy

    return (
        <div className="studybuddy disabled">
            <div className="studybuddy--accent" style={{"backgroundColor": profileColor}}></div>
            <div className="studybuddy--avatar">
                <div className="avatar__circle" style={{"backgroundColor": profileColor}}><p className="avatar__circle--text">{firstName[0]}{lastName[0]}</p></div>
            </div>
            <div>
                <div className="studybuddy--name">
                    {firstName} {lastName}
                </div>
                <div className="studybuddy--email">
                    {emailAddress}
                </div>
            </div>
        </div>
    )
}
