import './MistakeBankVideo.css';

import * as React from 'react';
import { boundMethod }  from 'autobind-decorator';

import VideoModal from '../../../../../../../../components/VideoModal/VideoModal';
import { SimpleTimer } from '../../../../../../../../services/utils/simple-timer';
import { Context, Resource, UserActivityEx, sendUserActivity } from '../../../../../../../../services/liveview/liveview-service';
import { TIMEOUT_IN_SECONDS } from '../../../../../../../../constants';

interface Props {
    videoId: string;
    lessonId: string
    categoryName: string
    titleAttr?: string
}

interface State {
    showVideo: boolean;
}


export default class MistakeBankVideo extends React.Component<Props, State> {
    simpleTimerVideo = new SimpleTimer()

    constructor(props: Props) {
        super(props);
        this.state = {
            showVideo: false
        };
    }

    public render() {
        let buttonTitle = 'play in modal window';
        if (this.props.titleAttr) {
            buttonTitle = 'Play lesson video: ' + this.props.titleAttr + ', opens in a modal window';
        }
        return (
            <div className="video-img">
                <button className="btn-simple video-img btn-has-hover-img" title={buttonTitle} onClick={this.setVideoState}>
                    <img
                        alt="Play"
                        className="non-hover-icon"
                        src={'/assets/images/icons/v2/ico-play.svg'}
                    />
                    <img src="/assets/images/icons/v2/ico-play-hover.svg" className="hover-icon d-none" alt="" />
                </button>
                    <VideoModal
                        videoId={this.props.videoId}
                        onClose={this.closeVideo}
                        show={this.state.showVideo}
                    />
            </div>
        );
    }

    @boundMethod
    private setVideoState() {
        this.simpleTimerVideo.start()
        this.setState({showVideo: true});        
    }

    @boundMethod
    private closeVideo() {
        this.setState({showVideo: false});        
        this.sendStatsForVideo()
    }

    /**
    * Send stats for watching a video to the backend
    */
    private sendStatsForVideo = () => {
        let seconds = this.simpleTimerVideo.diffInSeconds()

        if (seconds > 0) {
            let context = Context.MISTAKEBANK
            let contextId = this.props.lessonId
            let resource = Resource.VIDEO
            let resourceId = `mb-rewatch-v-${this.props.videoId}`
            let ended = seconds
            let timedout = seconds > TIMEOUT_IN_SECONDS
            let category = this.props.categoryName
            let userActivityEx: UserActivityEx = {
                context, contextId, 
                resource, resourceId, 
                category,
                ended,
                timedout
            }
            sendUserActivity(userActivityEx)
        }
    }
}
