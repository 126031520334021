import './DisplayAccountInfo.css';

import * as React from 'react';
import * as moment from 'moment';

import { UserInfo } from '../../../../../../services/account/account-rest-interface';
import AnimationsInfo from '../AnimationsInfo';

interface Props {
    info: UserInfo;    
}

const DisplayAccountInfo: React.SFC<Props> = (props: Props) => {

    const date = moment.default(props.info.dateOfBirth).format('MM/DD/YYYY');

    return(
        <div className="row user-info">
            <div className="col-lg-6 mobile-section mb-3">
                <div className="user-info__field">
                    <span className="user-info__field-title">FIRST NAME</span>
                    <div>{props.info.firstName}</div>
                </div>
            </div>
            <div className="col-lg-6 mobile-section mb-3">
                <div className="user-info__field">
                    <span className="user-info__field-title">LAST NAME</span>
                    <div>{props.info.lastName}</div>
                </div>
            </div>
            {/* <div className="col-lg-12 mobile-section">
                <div className="user-info__field">
                    <span className="user-info__field-title">DATE OF BIRTH</span>
                    <p>{date}</p>
                </div>
            </div> */}
            <div className="col-sm-12 mobile-section mb-3">
                <div className="user-info__field">
                    <span className="user-info__field-title">EMAIL</span>
                    <div>{props.info.emailAddress}</div>
                </div>
            </div>
            <AnimationsInfo animationDisabled={props.info.animationDisabled} disabled/>
        </div>
    );
};

DisplayAccountInfo.displayName = 'DisplayAccountInfo';

export default DisplayAccountInfo;
