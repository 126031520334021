import './Verify.css';

import * as React from 'react';

import BlueGeometricBackground from '../../components/BlueGeometricBackground/BlueGeometricBackground';
import VerifySuccess from './components/VerifySuccess/VerifySuccess';

interface Props {
    verificationToken?: string;
}

interface State {

}

export default class Verify extends React.Component<Props, State> {
    public render() {
        return (
            <BlueGeometricBackground className="verify">
                <div className="verify-account">
                    <VerifySuccess verificationToken={this.props.verificationToken} />
                </div>
            </BlueGeometricBackground>
        );
    }
}
