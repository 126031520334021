import React, { Component } from "react"
import {App} from "../../App"
import { UserInfo } from "../../services/account/account-rest-interface"
import { LessonsSummary, School, SuperintendentData, SuperintendentStore, TestsSummary } from '../../stores/superintendent-store'
import { PageContent } from '../../components/PageContent/PageContent'
import { HeaderDistrict} from '../../components/SuperIntendent/components/HeaderDistrict/HeaderDistrict'
import { ChartType, FilterSelections, SelectableItem, TestKind, ViewBy } from './SuperintendentFilters'
import ViewChangeButtons from './ViewChangeButtons'
import { PracticeTestsView } from "./PracticeTestsView"
import { LessonsView } from "./LessonsView"
import Loading from "../../components/Loading/Loading"
import './index.css'
import { getPersistedUserOptions } from "../../persistance/local"
import { SITimeFrame, getNomimalGraduationYears } from "../../services/utils/date-util"
import TonPDetailsView from "./TonPDetails"

const MAX_PROCESSING_TIME_MS = 40_000

interface Props { }

interface State {
  data?: SuperintendentData
  currentScreen: string
  usageSummaries: UsageAndTimeSummary[]
  filterSelections: FilterSelections
  userInfo: UserInfo
  processing: boolean
}

interface UsageAndTimeSummary {
  schoolName: string
  schoolData: School
  lessonsStarted: number
  lessonsCompleted: number
  lessonsContinued: number
  questionsAnswered: number
  timeOnQuestions: number
  totalStudyTime: number
}


let options = getPersistedUserOptions()
let testScoreKind = options.siTestKind ? options.siTestKind === "ACT" ? TestKind.ACT: TestKind.SAT: TestKind.ACT
let nominalGraduationYears = getNomimalGraduationYears()

export default class SuperintendentDashboard extends Component<Props, State> {
  timerId

  constructor(props) {
    super(props)
    this.state = {
      data: undefined,
      usageSummaries: [],
      currentScreen: "tonpdetails",
      filterSelections: {
        graduationYears: [],
        schools: [],
        timeFrame: SITimeFrame.CURRENT_ACADEMIC_YEAR,
        testScoreKind,
        testKind: TestKind.ALL,
        viewBy: ViewBy.School,
        chartType: ChartType.Stacked,
        selectionInfoForLessons: "",
        selectionInfoForReports: "",
        selectionInfoForTonP: "",
      },
      userInfo: App.getUserInfo(),
      processing: true
    }

    this.init() 

    clearTimeout(this.timerId)
    this.timerId = setTimeout(() => {
      this.setState({processing: false})
    }, MAX_PROCESSING_TIME_MS)
  }

  async init() {
    let data = await SuperintendentStore.loadDashboardData()

    if (!data) return

    let {lessons} = data
    let schoolNames = lessons.schools.map(s => s.name)
    let graduationYears = Array.from(new Set(lessons.schools.flatMap(s => s.graduationYears.map(g => g.year))
                              .sort((s1, s2) => {
                                if (s1 < s2) return -1
                                if (s1 > s2) return 1
                                return 0
                              }))).filter(graduationYear => graduationYear !== "all") as string[]
    let {filterSelections} = this.state
    let atLeastOneNomimalGraduationYearSelectable = graduationYears.some(gy => nominalGraduationYears.includes(gy))

    filterSelections.graduationYears = graduationYears.map(name => {
      let selected = !atLeastOneNomimalGraduationYearSelectable || nominalGraduationYears.find(gy => gy === name) ? true: false
      return {name, selected}
    })
    filterSelections.schools = schoolNames.map(name => {return {name, selected: true}})
    this.setState({ data, filterSelections })
  }

  componentWillUnmount() {
    clearTimeout(this.timerId)
  }

  handleViewChange = (screenName: string) => {
    this.setState({currentScreen: screenName})
  }

  handleSchoolsChanged = (selectedSchools: SelectableItem[]) => {
    // Filter selections already has the data, just force refresh!
    let {filterSelections} = this.state
    this.setState({filterSelections})
  }

  handleGraduationYearsChanged  = (selectedGraduationYears: SelectableItem[]) => {
    // Filter selections already has the data, just force refresh!
    let {filterSelections} = this.state
    this.setState({filterSelections})
  }

  handleTimeFrameChanged = (selectedTimeFrame: SITimeFrame) => {
    let {filterSelections} = this.state
    filterSelections.timeFrame = selectedTimeFrame
    this.setState({filterSelections})
  }

  handleChartTypeChanged = (selectedChartType: ChartType) => {
    let {filterSelections} = this.state
    filterSelections.chartType = selectedChartType
    this.setState({filterSelections})
  }

  handleTestKindChanged = (selectedTestKind: TestKind) => {
    let {filterSelections} = this.state
    filterSelections.testKind = selectedTestKind
    this.setState({filterSelections})
  }

  handleViewByChanged = (selectedViewBy: ViewBy) => {
    let {filterSelections} = this.state
    filterSelections.viewBy = selectedViewBy
    this.setState({filterSelections})
  }
  
  handleTestScoreKindChanged = (selectedTestScoreKind: TestKind) => {
    let {filterSelections} = this.state
    filterSelections.testScoreKind = selectedTestScoreKind
    this.setState({filterSelections})
  }

  selectScreenView = (screenName: string, lessonsSummary: LessonsSummary, practiceTestsSummary: TestsSummary, lessonsPerformances, tonpDetailsReports) => {
    const {filterSelections} = this.state
    const map: {[key:string]: any} = {
      tonpdetails:
        <TonPDetailsView
          tonpDetailsReports={tonpDetailsReports}
          filterSelections={filterSelections}
          onSchoolsChanged={this.handleSchoolsChanged}
          onGraduationYearsChanged={this.handleGraduationYearsChanged}
          onTimeFrameChanged={this.handleTimeFrameChanged}
          onChartTypeChanged={this.handleChartTypeChanged}
        />,
      lessons: 
        <LessonsView 
          summary={lessonsSummary}
          lessonsPerformances={lessonsPerformances}
          filterSelections={filterSelections}
          onSchoolsChanged={this.handleSchoolsChanged}
          onGraduationYearsChanged={this.handleGraduationYearsChanged}
          onTimeFrameChanged={this.handleTimeFrameChanged}
          onTestScoreKindChanged={this.handleTestScoreKindChanged}
        />,
      practicetests: 
        <PracticeTestsView
          summary={practiceTestsSummary}
          filterSelections={filterSelections}
          onSchoolsChanged={this.handleSchoolsChanged}
          onGraduationYearsChanged={this.handleGraduationYearsChanged}
          onTestKindChanged={this.handleTestKindChanged}
          onViewByChanged={this.handleViewByChanged}
        />
    }

    return map[screenName]
  }

  render() {    
    let {data, currentScreen, processing} = this.state
    if (!data) return ""
    let lessonsSummaries = data.lessons
    let testsSummaries = data.tests
    let lessonsPerformances = data.performances
    let tonpDetailsReports = data.tonpDetailsReports
    let selectedScreenView = this.selectScreenView(currentScreen, lessonsSummaries, testsSummaries, lessonsPerformances, tonpDetailsReports)
    let {userInfo} = this.state
    let {districtName, districtIcon} = userInfo

    return (
      <div className="w-100">
        <HeaderDistrict districtIcon={districtIcon} districtName={districtName} />
        {data ?
          <PageContent className="siview-wrapper">
            <div className="siview-container">
              <ViewChangeButtons onSelected={this.handleViewChange}/> 
              {selectedScreenView}
            </div>
          </PageContent>
          :
          processing ? <Processing/> : <NoData/>
        }
      </div>
    )
  }
}

function NoData(){
  return (
    <div className="no-report-data">No Report Data Are Available!</div>
  )
}

function Processing(){
  return (
    <div className="processing-data"><Loading/></div>
  )
}

