import React, { Component } from 'react'
import { boundMethod } from 'autobind-decorator';
import { getMilitaryTime } from '../../services/utils/date-util'
import Popover from '../Popover/Popover';

import "./Timer.css"

export enum TimerState {
  VISIBLE,
  HIDDEN
}

interface Props {
  showHourSection?: boolean
  timerStartValue?: number
  visible?: boolean
  isExpanded?: boolean
  isHovering?: boolean
}

interface State {
  seconds: number  
  paused: boolean
  frozen: boolean // UI frozen or not!
  frozenSeconds: number
  isExpanded: boolean
  isHovering: boolean
}

/**
 * Timer counts seconds and display the information as military time like HH:MM:SS
 * By default, only the MM:SS sections are shown.
 */
export default class Timer extends Component<Props, State> {
  timerId
  isExpanded
  delayTimer
  static currentInstance

  constructor(props) {
    super(props)
    let startVal = this.props.timerStartValue || 0
    this.state = {
      seconds: startVal,
      paused: false,
      frozen: false,
      frozenSeconds: startVal,
      isExpanded: false,
      isHovering: false
    }
    Timer.currentInstance = this
  }

  /**
   * Resets the seconds and the makes the ui unfrozen
   */
  static reset(resetValue=0) {
    let instance = Timer.currentInstance
    if (instance) {
      instance.setState({seconds: resetValue, frozen: false})
    }
  }

  static pause() {
    let instance = Timer.currentInstance
    if (instance) {
      instance.setState({paused: true})
    }
  }

  static resume() {
    let instance = Timer.currentInstance
    if (instance) {
      instance.setState({paused: false})
    }
  }

  /**
   * Freezes the UI. The seconds are still counting (unless paused)
   * but the UI doesn't update.
   * There was a new requirement that I had to show a frozen timer after
   * student selects an answer.
   */
  static freezeUI() {
    let instance = Timer.currentInstance
    if (instance) {
      let {seconds} = instance.state
      instance.setState({frozen: true, frozenSeconds: seconds})
    }
  }

  static unfreezeUI() {
    let instance = Timer.currentInstance
    if (instance) {
      instance.setState({frozen: false})
    }
  }

  static getSeconds(): number {
    let instance = Timer.currentInstance
    if (instance) {
      let seconds = instance.state.seconds
      return seconds
    }
    return -1
  }

  componentDidMount() {
    this.timerId = setInterval(() => {
      let {seconds, paused} = this.state
      if (!paused) {
        this.setState({seconds: seconds + 1})
      }
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timerId)
  }

  reset() {
    this.setState({seconds: 0})
  }

   toggleExpanded() {
    let instance = Timer.currentInstance
    if (instance) {
      instance.setState({ isExpanded: !instance.state.isExpanded })
    }
  }

  onTimeout = () => {
    this.setState({ isHovering: true });
  };

  clearTimer = () => {
    this.setState({ isHovering: false });
    clearTimeout(this.delayTimer);
  };

  render() {
    let {showHourSection=false, visible=true} = this.props
    let {seconds, frozenSeconds, frozen, isExpanded, isHovering} = this.state
    let displayedSeconds = frozen ? frozenSeconds: seconds
    let militaryTime = getMilitaryTime(displayedSeconds, showHourSection)
    let info = militaryTime.text

    return (
      <>
        { visible ? 
          <div className="timer-container">
            <button className={`btn-simple timer-parent${isExpanded ? ' expanded' : ''}`} tabIndex={0} title={`${isExpanded ? 'Collapse' : 'Expand'} timer`} onClick={this.toggleExpanded}>
              <div className="timer-parent--inner">
                {/* <img alt="" className="timer-icon" src="/assets/images/icons/timer.svg"/> */}
                <div className="time-icon" data-src="/assets/images/icons/timer.svg" onMouseEnter={this.handleMouseOver} onMouseLeave={this.clearTimer}>
                <svg version="1.1" x="0px" y="0px" viewBox="0 0 33 41">
                  <path style={{fill: '#1395d7'}} d="M29.9,14.6l1.8-1.8c0.3-0.4,0.3-1,0-1.4l-1.3-1.3c-0.4-0.4-0.9-0.4-1.3,0c0,0,0,0,0,0l-1.7,1.6
                    c-2.5-2.2-5.7-3.6-9-4V3.8h2.2c0.5,0,0.9-0.4,1-0.9V1c0-0.5-0.4-1-1-1h-8.3c-0.5,0-1,0.5-1,1v1.9c0,0.5,0.4,0.9,1,0.9h2.2v3.9
                    C5.5,8.8-0.9,17,0.1,26.1s9.2,15.5,18.3,14.4c9-1.1,15.5-9.2,14.4-18.3C32.5,19.5,31.5,16.9,29.9,14.6z M16.5,36.8
                    c-7,0-12.7-5.7-12.7-12.7c0-7,5.7-12.7,12.7-12.7c7,0,12.7,5.7,12.7,12.7c0,0,0,0,0,0C29.2,31.2,23.5,36.8,16.5,36.8z M17.5,28h-1.9
                    c-0.6,0-1-0.4-1-1V16.2c0-0.5,0.5-1,1-1h1.9c0.5,0,0.9,0.4,1,1V27C18.4,27.5,17.9,27.9,17.5,28L17.5,28z"/>
                  </svg>
                </div>
                <span className="timer-info">{info}</span>
              </div>
            </button>
            {/* <Popover visible={isHovering} yAlignment="bottom" xAlignment="right">
                <span className="difficulty-level__scale-text">
                   <span className="whenClosed">Show&nbsp;</span>
                   <span className="whenExpanded">Hide&nbsp;</span>
                    timer
                </span>
            </Popover> */}
          </div>
          :
          <></>
        }
      </>
    )
  }
  @boundMethod
  private handleMouseOver() {
      this.delayTimer = setTimeout(this.onTimeout, 600);
  }
}
