import './TestScoreDials.css';

import * as React from 'react';

import Button from '../../../../components/Button/Button';
import { Link } from 'react-router-dom';
import { PracticeTestScore } from '../../../../services/elearn/practice-test-types';
import TestScoreDialsRow from './TestScoreDialsRow/TestScoreDialsRow';
import RadialProgress from '../../../../components/RadialProgress/RadialProgress';

export interface Props {
    score: PracticeTestScore;
    totalScore: number;
    showUnlockDialog?: () => void;
    diagnostic?: boolean;
    testType: string;
    showButton?: boolean
    showCircle?: boolean
    showTitle?: boolean
}

const TestScoreDials: React.SFC<Props> = (props) => {
    const { testType, showButton=true, showCircle=true, showTitle=true } = props;
    const { testName, displayName, status, isActive } = props.score;

    const testNumber = props.score.testName[props.score.testName.length - 1];
    let sumScores = props.score.math + props.score.reading + props.score.science + props.score.english;
    let radialScore = Math.round(sumScores / 4);
    {/* tslint:disable:max-line-length */}
    let link = `/app/${(status === 'started' ||  status === 'completed') ? 
      `practice-tests/scoring/${testName}` : 
      `practice-tests/instructions/${testName}`}`;
    let skipLink = `practice-tests/scoring/${testName}`;
    if (props.diagnostic) {
      link = `/app/${(status === 'started' || status === 'completed') ? 
        `practice-tests/scoring/${testName}` : 
        `practice-tests/instructions/${testName}`}`;
    }
    if (testType === 'ia') {
      link = `/app/practice-tests/scoring/${testName}`;
    }
    const startedOrCompleted = (props.score.status === 'started' || props.score.status === 'completed');
    const button = createButton(startedOrCompleted);

    function createButton(startedOrCreated: boolean) {
        if  (displayName.toLowerCase().includes('diagnostic')) {
            return (
                <Link to={link}>
                    <Button
                        className="practice-test-button"
                        text={`${startedOrCompleted ? 'REVIEW ' : 'TAKE '} ${displayName.toUpperCase()}`}
                        bgColor="blue"
                    />
                </Link>
            );
        }
        if (isActive) {
            if( testName === 'Diagnostic' || testName === 'DiagnosticSAT') {
                return (
                    <div>
                        <Link to={link}>
                            <Button
                                className="practice-test-button"
                                text={`${startedOrCompleted ? 'REVIEW ' : 'TAKE '} ${displayName.toUpperCase()}`}
                                bgColor="blue"
                            />
                        </Link>
                        {!startedOrCompleted && 
                            <div className="pt-3">
                                <Link to={skipLink} className="hyperlink" title="Skip Instructions and Enter Answers">
                                    Skip Instructions and Enter Answers
                                </Link>
                            </div>
                        }
                    </div>
                );
            } else if (testName.startsWith('ACT')) {
                return (
                    <div>
                        <Link to={link}>
                            <Button
                                className="practice-test-button"
                                text={`${startedOrCompleted ? 'REVIEW ACT TEST' : 'TAKE ACT TEST'} ${testNumber}`}
                                bgColor="blue"
                            />
                        </Link>
                        {!startedOrCompleted && 
                            <div className="pt-3">
                                <Link to={skipLink} className="hyperlink" title="Skip Instructions and Enter Answers">
                                    Skip Instructions and Enter Answers
                                </Link>
                            </div>
                        }
                    </div>
                );
            } else if (testType === 'ia') {
                return (
                    <Link to={link}>
                        <Button
                            className="practice-test-button"
                            text={`${startedOrCompleted ? 
                              `REVIEW ${displayName.toUpperCase()} ANSWERS` :
                              `ENTER ${displayName.toUpperCase()} ANSWERS`}`
                              }
                            bgColor="blue"
                        />
                    </Link>
                );
            } else {
                return (
                    <div>
                        <Link to={link}>
                            <Button
                                className="practice-test-button"
                                text={`${startedOrCompleted ? 'REVIEW SAT TEST' : 'TAKE SAT TEST'} ${testNumber}`}
                                bgColor="blue"
                            />
                        </Link>
                        {!startedOrCompleted && 
                            <div className="pt-3">
                                <Link to={skipLink} className="hyperlink" title="Skip Instructions and Enter Answers">
                                    Skip Instructions and Enter Answers
                                </Link>
                            </div>
                        }
                    </div>
                );
            }

        } else {
            if( testName === 'Diagnostic' || testName === 'DiagnosticSAT') {
                return (
                    <Button
                        className="practice-test-button-locked"
                        text={`${startedOrCompleted ? 'REVIEW ' : 'TAKE '} ${displayName.toUpperCase()}`}
                        bgColor="gray"
                        onClick={props.showUnlockDialog}
                    />
                );
            } else if (testName.startsWith('ACT')) {
                return (
                    <Button
                        className="practice-test-button-locked"
                        text={`${startedOrCompleted ? 'REVIEW ACT TEST' : 'TAKE ACT TEST'} ${testNumber}`}
                        bgColor="gray"
                        onClick={props.showUnlockDialog}
                    />
                );
            } else if (testType === 'sat') {
                return (
                    <Button
                        className="practice-test-button-locked"
                        text={`${startedOrCompleted ? 'REVIEW SAT TEST' : 'TAKE SAT TEST'} ${testNumber}`}
                        bgColor="gray"
                        onClick={props.showUnlockDialog}
                    />
                );
            } else {
              // it is ia so we show no button;
              return '';
            }
        }
    }

    /* testName === 'Diagnostic' || testName.startsWith('ACT')) */ 
    if( testType === 'act' || testType === 'ia' ) {
        let newScore = radialScore + ''
        let margin = 0
        return (
            <div className="practice-test-card-container">
                <div className="practice-test-card-subcontainer">
                    {/* Title */}
                    {showCircle &&
                        <div className="practice-test-title">
                            {showTitle &&
                                <h3 className="practice-test-title-text"> {displayName}</h3>
                            }

                            <div className={`${!startedOrCompleted ? 'faded' : ''}`}>
                                    <RadialProgress
                                        size={150}
                                        radius={45}
                                        startColor="#00d28d"
                                        endColor="#099dd7"
                                        pegOffset={30}
                                        progress={(!startedOrCompleted ? 0 : radialScore / props.totalScore * 100)}
                                        text={(!startedOrCompleted ? '0' : radialScore + '')}
                                        textStyle={{fontSize: '33px', marginLeft: margin, color: '#2B2D2D'}}
                                        progressLineWidth={10}
                                        backgroundLineWidth={10}
                                    />
                            </div>
                        </div>
                    }
                    {!isActive && testType === 'ia' &&
                      <p className="ia-unassigned-message">
                        This test has not yet been activated by your school administrator
                      </p>
                    }

                    {/* tslint:disable:max-line-length */}
                    <div className={'practice-test-scores-container ' + (!startedOrCompleted ? 'faded' : '')}>
                        <TestScoreDialsRow subject="English" className="row row-dials-small" totalScore={props.totalScore} score={(!startedOrCompleted ? 0 : props.score.english)} barColor="purple" />
                        <TestScoreDialsRow subject="Math" className="row row-dials-small" totalScore={props.totalScore} score={(!startedOrCompleted ? 0 : props.score.math)} barColor="blue" />
                        <TestScoreDialsRow subject="Reading" className="row row-dials-small" totalScore={props.totalScore} score={(!startedOrCompleted ? 0 : props.score.reading)} barColor="orange" />
                        <TestScoreDialsRow subject="Science" className="row row-dials-small" totalScore={props.totalScore} score={(!startedOrCompleted ? 0 : props.score.science)} barColor="green" />
                    </div>
                     {/* Button */}
                    { showButton &&
                        <div className="practice-test-button-container">
                           {button}
                        </div>
                    }
                </div>
            </div>
        );
    } else {
        sumScores = props.score.math + props.score.evidenceBasedRW
        radialScore = sumScores
        let newScore = radialScore + ''
        let margin = 0
        let totalScore = 1600
        return (
            <div className="practice-test-card-container">
                <div className="practice-test-card-subcontainer">
                    {/* Title */}
                    <div className="practice-test-title">
                        {showTitle &&
                            <h3 className="practice-test-title-text">{displayName}</h3>
                        }

                        <div className={(!startedOrCompleted ? 'faded' : '')}>
                            {showCircle &&
                                <RadialProgress
                                    size={150}
                                    radius={45}
                                    startColor="#00d28d"
                                    endColor="#099dd7"
                                    pegOffset={30}
                                    progress={(!startedOrCompleted ? 0 : radialScore / totalScore * 100)}
                                    text={(!startedOrCompleted ? '0' : radialScore + '')}
                                    textStyle={{fontSize: '33px', marginLeft: margin, color: '#2B2D2D'}}
                                    progressLineWidth={10}
                                    backgroundLineWidth={10}
                                />
                            }
                        </div>
                    </div>

                    {/* tslint:disable:max-line-length */}
                    <div className={'practice-test-scores-container ' + (!startedOrCompleted ? 'faded' : '')}>
                        <TestScoreDialsRow subject="EBRW" className="row row-dials-small" totalScore={800} score={(!startedOrCompleted ? 0 : props.score.evidenceBasedRW)} barColor="purple" />
                        <TestScoreDialsRow subject="Math" className="row row-dials-small" totalScore={800} score={(!startedOrCompleted ? 0 : props.score.math)} barColor="blue" />
                        {/* Combined Writing and Language with Reading Score Row
                        <TestScoreDialsRow subject="EBRW" score={(!startedOrCompleted ? 0 : props.score.evidenceBasedRW)} totalScore={800} barColor="purple" className="invisible" />
                        <TestScoreDialsRow subject="Math" score={(!startedOrCompleted ? 0 : props.score.math)} totalScore={800} barColor="blue" className="invisible" />
                        */}
                    </div>

                    {/* Button */}
                    { showButton &&
                        <div className="practice-test-button-container">
                            {button}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default TestScoreDials;
