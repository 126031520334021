import * as React from 'react';

import BrightcoveVideo from '../../components/BrightcoveVideo/BrightcoveVideo';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps<any> {

}

interface State {

}

export default class VideoTest extends React.Component<Props, State> {
    public render() {
        const style = {
            width: '100%'
        };

        return (
            <div className="">
                <BrightcoveVideo id="video-test" videoId="5439832357001" style={style} />
            </div>
        );
    }
}
