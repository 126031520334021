import { getLoginStatus } from './account-rest-interface';

export function startHeartbeat(interval: number) {

    return new Promise((resolve, reject) => {
        const intervalId = setInterval(() => {
            getLoginStatus()
                .catch(() => {
                    clearInterval(intervalId);
                    reject();
                });

        }, Math.max(interval, 1000));
    });
}