import { getEnrichmentData, updateEnrichment, Enrichment } from '../services/enrichment/enrichment-rest-interface';

const GET_ENRICHMENT = 'enrichment/GET_ENRICHMENT';
const SET_ENRICHMENT = 'enrichment/SET_ENRICHMENT';
const UPDATE_LOCAL_ENRICHMENT = 'enrichment/UPDATE_LOCAL_ENRICHMENT';

export interface EnrichmentState {
    enrichment: Partial<Enrichment>;
}

const defaultState = {
    enrichment: []
};

export function getEnrichment() {
    return dispatch => {
        return getEnrichmentData()
            .then((enrichment = []) => dispatch(setEnrichment(enrichment as any)));
    };
}

export function setEnrichment(enrichment: Enrichment[]) {
    return {
        type: SET_ENRICHMENT,
        enrichment
    };
}

export function updateUserEnrichment(id: string, status: string) {
    return(dispatch) => {
        return updateEnrichment(id, status)
            .then(() => dispatch(updateLocalEnrichment(id, status)));
    };
}

function updateLocalEnrichment(id: string, status: string) {
    return {
        type: UPDATE_LOCAL_ENRICHMENT,
        id: id,
        status: status
    };
}

export default function(state: any = defaultState, action: any) {
    switch(action.type) {
        case GET_ENRICHMENT: {
            return {...state };
        }
        case SET_ENRICHMENT: {
            const  enrichment  = [...action.enrichment ];
            return { ...state, enrichment };
        }
        case UPDATE_LOCAL_ENRICHMENT: {
            let clonedArray = [ ...state.enrichment ];
            let idx = clonedArray.findIndex(obj => {
                return action.id === obj.lessonId;
            });
            if (idx > -1) {
                clonedArray[idx].lessonId = action.id;
                clonedArray[idx].status = action.status;
            }
            
            return { ...state, clonedArray };
        }
        default:
            return state;
    }
}
