import './index.css';

import * as React from 'react';
import Popover from '../Popover/Popover';

interface Props {
  text?: string;

  // text that gets prepended to 'N/A'
  prependedText?: string;
}

interface State {
  isHovering: boolean;
}

export default class NotApplicableIndicator extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
          isHovering: false
        };
    }

    public render() {
        const { text, prependedText } = this.props;
        const { isHovering } = this.state;
        return (
          <div
              className="not-applicable-parent"
              onMouseOver={() => this.setState({ isHovering: true })}
              onMouseLeave={() => this.setState({ isHovering: false })}
          >
             <div className="th-tooltip tooltip-center">
                <div className="not-applicable">{prependedText} N/A&nbsp;</div>
                <span className="th-tooltip-text">No students took this section</span>
              </div>
            {/* <div>
              {prependedText} N/A
            </div> */}
            {/* <Popover 
              visible={isHovering} 
              yAlignment="bottom"
              xAlignment="left"
              style={{ marginLeft: '3rem' }}
            >
              <div className="the-reveal-text">
                {text !== undefined ? 
                  text : 
                  'Reading and Science do not have baseline questions'
                }
              </div> 
            </Popover> */}
          </div>
        );
    }
}
