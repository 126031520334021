import React from 'react'

export default function ResilienceRemindersMarketingView() {
  let urlForMacDermottMethod = "https://pub.winwardacademy.com/resilience-reminders/images/mm-infographic-052324.png"
  
  return (
    <div>
        <h1>Mindset Moments Marketing</h1>
        <hr/>
        <img alt="" src={urlForMacDermottMethod} width="100%" />
    </div>
  )
}
