import {
    CurrentLessonContent,
    DashboardOverview,
    Feedback,
    LessonProgress,
    MaterialType,
    MistakeBankLessons,
    Question
} from '../services/elearn/lesson-types';
import {
         addQuestionToMistakeBank as addQuestionToMistakeBankRest,
         getMistakeBankOverview as getMistakeBankOverviewRest,
         setTopicQuestionAsReviewed as setTopicQuestionAsReviewedRest,
         setTopicQuestionAsReviewedSS as setTopicQuestionAsReviewedRestSS,
         setTopicReviewStatus as setTopicReviewStatusRest
} from '../services/elearn/lesson-rest-interface';
import {
    getDashboardOverview as getDashboardOverviewRest,
    getUserLessons as getUserLessonsRest,
    sendFeedback,
    setCurrentMaterial,
    setSSCurrentMaterial
} from '../services/user/user-lesson-rest-interface';
import {
    getLessonProgress,
    setCurrentLesson as sendSetCurrentLessonRequest
} from '../services/user/user-lesson-rest-interface';

import { updatePriority as updatePriorityRest } from '../services/practice-test-scoring/practice-test-service';
import {
    getSSSDashboard,
    getSSSMistakeBankOverview,
    getSSSUserLessons,
    setCurrentSSSMaterial,
    setCurrentSSSLesson
} from '../services/school-safety/school-safety-rest-interface';
import { hasSchoolSafety } from '../services/account/account-utils';
import { addSSSQuestionToMistakeBank, setSSSTopicQuestionAsReviewed } from '../services/school-safety/school-safety-rest-interface';
import { userInfo } from 'os';

// ===== ACTION TYPES ===== //

const SET_LESSON_IN_PROGRESS = 'lesson/SET_LESSON_IN_PROGRESS';
const SET_CURRENT_LESSON = 'lesson/SET_CURRENT_LESSON';
const SET_CURRENT_LESSON_CONTENT = 'lesson/SET_CURRENT_LESSON_CONTENT';
// const SET_MC_ANSWER = 'lesson/SET_MC_ANSWER';
// const SET_BASELINE_ANSWER = 'lesson/SET_BASELINE_ANSWER';
const SET_USER_LESSONS = 'lesson/SET_USER_LESSONS';
const SET_DASHBOARD_OVERVIEW = 'lesson/SET_DASHBOARD_OVERVIEW';
const INCREMENT_QUESTIONS_ANSWERED = 'lesson/SET_QUESTION_ANSWERED_COUNT';
const SET_NEXT_LESSON_AFTER = 'lesson/SET_NEXT_LESSON_AFTER';
const SEND_USER_FEEDBACK = 'contactus/SEND_USER_FEEDBACK';
const INCREMENT_NUM_LESSONS_STARTED = 'lesson/INCREMENT_NUM_LESSONS_STARTED';
const SET_PRIORITY = 'lesson/SET_PRIORITY';
const INCREMENT_NUM_LESSONS_COMPLETED = 'lesson/INCREMENT_NUM_LESSONS_COMPLETED';
const SET_MISTAKE_BANK_OVERVIEW = 'mistakebank/SET_MISTAKE_BANK_OVERVIEW';
const ADD_MISTAKE_BANK_OVERVIEW = 'mistakebank/ADD_MISTAKE_BANK_OVERVIEW';
const SET_TOPIC_REVIEW_STATUS = 'mistakebank/SET_TOPIC_REVIEW_STATUS';
const SET_TOPIC_QUESTION_AS_REVIEWED = 'mistakebank/SET_TOPIC_QUESTION_AS_REVIEWED';

// ===== ACTION CREATORS ===== //

export function setLessonInProgress(lessonInProgress: boolean) {
    return {
        type: SET_LESSON_IN_PROGRESS,
        lessonInProgress
    };
}

function _setCurrentLesson(currentLesson: LessonProgress) {
    const currentLessonId = currentLesson.lessonId;
    return {
        type: SET_CURRENT_LESSON,
        currentLessonId,
        currentLesson
    };
}

function _setCurrentQuestion(currentLessonContent: CurrentLessonContent) {
    return {
        type: SET_CURRENT_LESSON_CONTENT,
        currentLessonContent
    };
}

function _setCurrentTopic(currentLessonContent: CurrentLessonContent) {
    return {
        type: SET_CURRENT_LESSON_CONTENT,
        currentLessonContent
    };
}

function _setLessonCompleted(currentLessonContent: CurrentLessonContent) {
    return {
        type: SET_CURRENT_LESSON_CONTENT,
        currentLessonContent
    };
}

function _sendUserFeedback(feedbackInfo: Feedback) {
    return {
        type: SEND_USER_FEEDBACK,
        feedbackInfo
    };
}

export function setDashboardOverview(overview: DashboardOverview) {
    return {
        type: SET_DASHBOARD_OVERVIEW,
        ...overview
    };
}

export function setUserLessons(userLessons: LessonProgress[]) {
    return {
        type: SET_USER_LESSONS,
        userLessons
    };
}

export function incrementQuestionsAnswered(lessonId: string) {
    return {
        type: INCREMENT_QUESTIONS_ANSWERED,
        lessonId
    };
}

export function setNextLessonAfter(lessonId: string) {
    return {
        type: SET_NEXT_LESSON_AFTER,
        lessonId
    };
}

function _addQuestionToMistakeBank(lesson: LessonProgress, question: Question) {
    return {
        type: SET_MISTAKE_BANK_OVERVIEW,
        lesson,
        question
    };
}

export function setMistakeBankOverview(overview: MistakeBankLessons[]) {
    return {
        type: ADD_MISTAKE_BANK_OVERVIEW,
        overview
    };
}

export function _setTopicReviewStatus(topicId: string, status: string) {
    return {
        type: SET_TOPIC_REVIEW_STATUS,
        topicId,
        status
    };
}

export function _setTopicQuestionAsReviewed(topicId: string) {
    return {
        type: SET_TOPIC_QUESTION_AS_REVIEWED,
        topicId
    };
}

export function _setTopicQuestionAsReviewedSS(topicId: string) {
    return {
        type: SET_TOPIC_QUESTION_AS_REVIEWED,
        topicId
    };
}

export function setPriority(lessonId: string, status: boolean) {
    return dispatch => {
        return updatePriorityRest(lessonId, status)
            .then(() =>
             dispatch(_setPriority(lessonId, status)));
    };
}

function _setPriority(lessonId: string, status: boolean) {
    return {
        type: SET_PRIORITY,
        action: {
            lessonId, status
        }
    };
}

export function setCurrentLesson(currentLessonId: string, userInfo: any) {
    const lessonId = currentLessonId;
    if (hasSchoolSafety(userInfo)) {
        return (dispatch) => {
            return setCurrentSSSLesson(lessonId)
                .then(() =>
                 dispatch(getCurrentLessonProgress(currentLessonId, userInfo)));
        };
    } else {
        return (dispatch) => {
            return sendSetCurrentLessonRequest(lessonId)
                .then(() =>
                 dispatch(getCurrentLessonProgress(currentLessonId, userInfo)));
        };
    }

}

export function sendUserFeedback(feedbackInfo: Feedback) {
    return dispatch => {
        return sendFeedback(feedbackInfo)
            .then(() => dispatch(_sendUserFeedback(feedbackInfo)));
    };
}

export function getCurrentLessonProgress(currentLessonId: string, userInfo: any) {
    return dispatch => {
        return getLessonProgress(currentLessonId, userInfo)
            .then(currentLesson => dispatch(_setCurrentLesson(currentLesson)));
    };
}

export function setCurrentQuestion(lessonId: string, type: MaterialType, id: string, userInfo: any) {
    const currentLessonContent = { type, id };
    if (hasSchoolSafety(userInfo)) {
        return dispatch => {
            return setCurrentSSSMaterial(lessonId, type, id)
                .then(() => dispatch(_setCurrentQuestion(currentLessonContent)));
        };
    } else {
        return dispatch => {
            return setCurrentMaterial(lessonId, type, id)
                .then(() => dispatch(_setCurrentQuestion(currentLessonContent)));
        };
    }
}

export function setSSCurrentQuestion(lessonId: string, type: MaterialType, id: string) {
    const currentLessonContent = { type, id };
    return dispatch => {
        return setSSCurrentMaterial(lessonId, type, id)
            .then(() => dispatch(_setCurrentQuestion(currentLessonContent)));
    };
}

export function setResume(lessonId: string, topicId: string) {
    const currentLessonContent = { type: MaterialType.BASELINE_QUESTION, id: topicId };
    return {
        type: SET_CURRENT_LESSON_CONTENT,
        currentLessonContent
    };
}

export function setCurrentTopic(lessonId: string, topicId: string, userInfo: any) {
    const currentLessonContent = { type: MaterialType.TOPIC, id: topicId };
    if (hasSchoolSafety(userInfo)) {
        return dispatch => {
            return setCurrentSSSMaterial(lessonId, MaterialType.TOPIC, topicId)
                .then(() => dispatch(_setCurrentTopic(currentLessonContent)));
        };
    } else {
        return dispatch => {
            return setCurrentMaterial(lessonId, MaterialType.TOPIC, topicId)
                .then(() => dispatch(_setCurrentTopic(currentLessonContent)));
        };
    }

}

export function setLessonCompleted(lessonId: string, userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        const currentLessonContent = { type: MaterialType.REPORT, id: '' };
        return dispatch => {
            return setCurrentSSSMaterial(lessonId, MaterialType.REPORT, '')
                .then(() => dispatch(_setLessonCompleted(currentLessonContent)))
                .then(() => dispatch(_incrementNumLessonsCompleted()))
                .then(() => dispatch(setNextLessonAfter(lessonId)));
        };
    } else {
        const currentLessonContent = { type: MaterialType.REPORT, id: '' };
        return dispatch => {
            return setCurrentMaterial(lessonId, MaterialType.REPORT, '')
                .then(() => dispatch(_setLessonCompleted(currentLessonContent)))
                .then(() => dispatch(_incrementNumLessonsCompleted()))
                .then(() => dispatch(setNextLessonAfter(lessonId)));
        };
    }

}

export function setLessonCompleted2(lessonId: string) {
    const currentLessonContent = { type: MaterialType.REPORT, id: '' };
    return _setLessonCompleted(currentLessonContent);
}

export function getDashboardOverview(userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        return (dispatch) => {
            return getSSSDashboard()
                .then(overview => dispatch(setDashboardOverview(overview)));
        };
    } else {
        return (dispatch) => {
            return getDashboardOverviewRest()
                .then(overview => dispatch(setDashboardOverview(overview)));
        };
    }

}

export function getUserLessonsOverview(userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        return(dispatch) => {
            return getSSSUserLessons()
                .then(({ userLessonOverviews = [] }) => dispatch(setUserLessons(userLessonOverviews as any)));
        };
    } else {
        return(dispatch) => {
            return getUserLessonsRest()
                .then(({ userLessonOverviews = [] }) => dispatch(setUserLessons(userLessonOverviews as any)));
        };
    }
}

export function setTopicReviewStatus(lessonId: string, topicId: string, status: string) {
    return(dispatch) => {
        return setTopicReviewStatusRest(lessonId, topicId, status)
            .then(() => dispatch(_setTopicReviewStatus(topicId, status)));
    };
}

export function setTopicQuestionAsReviewed(lessonId: string, topicId: string,
                                           questionId: string, answerLetter: string, userInfo: any) {
        if (hasSchoolSafety(userInfo)) {
            return(dispatch) => {
                return setSSSTopicQuestionAsReviewed(lessonId, topicId, questionId, answerLetter)
                    .then(() => dispatch(_setTopicQuestionAsReviewed(topicId)));
            };
        } else {
            return(dispatch) => {
                return setTopicQuestionAsReviewedRest(lessonId, topicId, questionId, answerLetter)
                    .then(() => dispatch(_setTopicQuestionAsReviewed(topicId)));
            };
        }
}

export function setTopicQuestionAsReviewedInSS(lessonId: string, topicId: string, questionId: string) {
    return(dispatch) => {
        return setTopicQuestionAsReviewedRestSS(lessonId, topicId, questionId)
            .then(() => dispatch(_setTopicQuestionAsReviewed(topicId)));
    };
}

export function incrementNumLessonsStarted() {
    return {
        type: INCREMENT_NUM_LESSONS_STARTED
    };
}

function _incrementNumLessonsCompleted() {
    return {
        type: INCREMENT_NUM_LESSONS_COMPLETED
    };
}

export function addQuestionToMistakeBank(lesson: LessonProgress, question: Question, hint: string, userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        return(dispatch) => {
            return addSSSQuestionToMistakeBank(lesson, question, hint)
                .then(() => dispatch(_addQuestionToMistakeBank(lesson, question)));
        };
    } else {
        return(dispatch) => {
            return addQuestionToMistakeBankRest(lesson, question, hint, userInfo)
                .then(() => dispatch(_addQuestionToMistakeBank(lesson, question)));
        };
    }
}

export function getMistakeBankOverview(userInfo: any) {
    if (hasSchoolSafety(userInfo)) {
        return (dispatch) => {
            return getSSSMistakeBankOverview()
                .then(overview => dispatch(setMistakeBankOverview(overview)));
        };
    } else {
        return (dispatch) => {
            return getMistakeBankOverviewRest()
                .then(overview => dispatch(setMistakeBankOverview(overview)));
        };
    }

}

// ===== SELECTORS ===== //

export function getLessonById(state: LessonState, lessonId: string) {
    return state.userLessons.allLessons.find(lesson => lesson.lessonId === lessonId);
}

export function getCurrentLesson(state: LessonState) {
    const { currentLessonId } = state;
    return getLessonById(state, currentLessonId);
}

export function getCurrentLessonContent(state: LessonState): CurrentLessonContent | null {
    const currentLesson = getCurrentLesson(state);
    return currentLesson ?
        currentLesson.currentLessonContent :
        { type: MaterialType.BASELINE_QUESTION, id: '' };
}

// ===== REDUCER ===== //

export interface LessonGroup {
    bySubject: {
        [categoryName: string]: LessonProgress[];
    };
    allLessons: LessonProgress[];
}

export interface LessonState {
    currentLessonId: string;
    numLessonsUnstarted: number;
    numLessonsStarted: number;
    numLessonsCompleted: number;
    lessonInProgress: boolean;
    userLessons: LessonGroup;
    mistakeBankOverview: MistakeBankLessons[];
    setPriority: (lessonId: string, status: boolean) => void;
}

const defaultState: Partial<LessonState> = {
    lessonInProgress: false,
    userLessons: {
        bySubject: {},
        allLessons: []
    }
};

export default function(state: Partial<LessonState> = defaultState, action: any) {
    switch(action.type) {
        case SET_DASHBOARD_OVERVIEW: {
            const { currentLessonId, numLessonsUnstarted, numLessonsStarted, numLessonsCompleted } = action;
            return { ...state, currentLessonId, numLessonsUnstarted, numLessonsStarted, numLessonsCompleted };
        }
        case INCREMENT_NUM_LESSONS_STARTED: {
            const { numLessonsStarted = 0, numLessonsUnstarted = 1, userLessons } = state;
            const numTotalLessons = userLessons ? userLessons.allLessons.length : 0;

            return {
                ...state,
                numLessonsStarted: Math.min(numLessonsStarted + 1, numTotalLessons),
                numLessonsUnstarted: Math.max(numLessonsUnstarted - 1, 0)
            };
        }
        case INCREMENT_NUM_LESSONS_COMPLETED: {
            const { numLessonsStarted = 1, numLessonsCompleted = 0, userLessons } = state;
            const numTotalLessons = userLessons ? userLessons.allLessons.length : 0;

            return {
                ...state,
                numLessonsStarted: Math.max(numLessonsStarted - 1, 0),
                numLessonsCompleted: Math.min(numLessonsCompleted + 1, numTotalLessons)
            };
        }
        case SET_LESSON_IN_PROGRESS: {
            const { lessonInProgress } = action;
            return { ...state, lessonInProgress };
        }
        case SET_CURRENT_LESSON: {
            const { currentLessonId } = action;
            return { ...state, currentLessonId };
        }
        case SET_USER_LESSONS: {
            const { userLessons: allLessons } = action;

            const bySubject = allLessons.reduce((acc: any, lesson: LessonProgress) => {
                const category = lesson.category.categoryName;

                if(!acc[category]) {
                    acc[category] = [];
                }

                acc[category].push(lesson);
                return acc;
            }, {});

            const userLessons = { bySubject, allLessons };
            return { ...state, userLessons };
        }
        case SET_CURRENT_LESSON_CONTENT: {
            const { currentLessonContent } = action;
            const { currentLessonId } = state;
            const { allLessons, bySubject } = state.userLessons!;

            const lessonToUpdate = allLessons.find(allLesson => allLesson.lessonId === currentLessonId);
            if(!lessonToUpdate) { return state; }

            const { categoryName } = lessonToUpdate.category;
            const lessonsBySubject = bySubject[categoryName];
            if(!lessonsBySubject) { return state; }

            const updateCurrentLessonContent = newLesson => newLesson.lessonId !== currentLessonId ?
                newLesson :
                { ...newLesson, currentLessonContent: { ...currentLessonContent } };

            return {
                ...state,
                userLessons: {
                    bySubject: {
                        ...bySubject,
                        [categoryName]: lessonsBySubject.map(updateCurrentLessonContent)
                    },
                    allLessons: allLessons.map(updateCurrentLessonContent),
                }
            };
        }
        case SET_PRIORITY: {
            let status = action.action.status;
            let lessonId = action.action.lessonId;
            const { allLessons, bySubject } = state.userLessons!;
            if(!allLessons || allLessons.length === 0 || !bySubject) { return state; }

            let lessonToUpdate;
            if (lessonId.indexOf('SinglePassages') >= 0) {
                if (lessonId.indexOf('SinglePassagesWithFigures') >= 0) {
                    lessonToUpdate = allLessons.find(allLesson => allLesson.lessonId === 'SinglePassagesWithFigures');
                } else {
                    lessonToUpdate = allLessons.find(allLesson => allLesson.lessonId === 'SinglePassages');
                }
            } else if (lessonId.indexOf('DualPassages') >= 0) {
                lessonToUpdate = allLessons.find(allLesson => allLesson.lessonId === 'DualPassages');
            } else {
                lessonToUpdate = allLessons.find(allLesson => allLesson.lessonId === lessonId);
            }
            if(!lessonToUpdate) { return state; }

            const { categoryName } = lessonToUpdate.category;
            const lessonsBySubject = bySubject[categoryName];
            if(!lessonsBySubject) { return state; }

            const updateStar = (lesson: LessonProgress) => lesson.lessonId !== lessonToUpdate.lessonId ? lesson : {
                ...lesson,
                star: status
            };

            const newBySubject = {
                ...bySubject,
                [categoryName]: lessonsBySubject.map(updateStar)
            };
            const newAllLessons = allLessons.map(updateStar);

            return {
                ...state,
                userLessons: {
                    bySubject: newBySubject,
                    allLessons: newAllLessons
                }
            };
        }
        case INCREMENT_QUESTIONS_ANSWERED: {
            const { lessonId } = action;

            const { allLessons, bySubject } = state.userLessons!;
            if(!allLessons || allLessons.length === 0 || !bySubject) { return state; }

            const lessonToUpdate = allLessons.find(allLesson => allLesson.lessonId === lessonId);
            if(!lessonToUpdate) { return state; }

            const { categoryName } = lessonToUpdate.category;
            const lessonsBySubject = bySubject[categoryName];
            if(!lessonsBySubject) { return state; }

            const incrementCurrentLesson = (lesson: LessonProgress) => lesson.lessonId !== lessonId ? lesson : {
                    ...lesson,
                    numCompletedQuestions: Math.min(lesson.numCompletedQuestions + 1, lesson.numTotalQuestions)
                };

            return {
                ...state,
                userLessons: {
                    bySubject: {
                        ...bySubject,
                        [categoryName]: lessonsBySubject.map(incrementCurrentLesson)
                    },
                    allLessons: allLessons.map(incrementCurrentLesson),
                }
            };
        }
        case SET_NEXT_LESSON_AFTER : {

            const { lessonId } = action;
            const max = Number.MAX_SAFE_INTEGER;

            const { allLessons, bySubject } = state.userLessons!;
            if(!allLessons || allLessons.length === 0 || !bySubject) { return state; }

            const sortedLessonsByCat = (Object.keys(bySubject)).
                sort((a, b) => (a.charAt(0)).localeCompare(b.charAt(0)));

            let sortedLessons: any = [];
            sortedLessonsByCat.forEach(lessonCat => {

                sortedLessons.push(bySubject[lessonCat].sort((a, b) => {
                        const order1 = a.subcategory ? a.subcategory.orderWithinCategory || max : max;
                        const order2 = b.subcategory ? b.subcategory.orderWithinCategory || max : max;
                        const result =  order1 - order2;

                        if(result === 0) {
                            return a.lessonOrder - b.lessonOrder;
                        } else {
                            return result;
                        }
                    }));
            });

            const flatLessons: LessonProgress[] = sortedLessons.reduce((acc: any, lesson: LessonProgress[]) => {

                lesson.forEach(element => {
                    acc.push(element);
                });

                return acc;
            }, []);

            // filter out completed lessons unless id  === current id
            const unstartedLessons = flatLessons.filter(lesson =>
                !lesson.currentLessonContent ||
                lesson.currentLessonContent.type !== MaterialType.REPORT ||
                lesson.lessonId === lessonId);

            // get next lesson in list
            const index = unstartedLessons.findIndex(l => l.lessonId === lessonId) + 1;

            // need to handle case where this is last unstarted lesson
            const nextLesson = index < unstartedLessons.length ? 
                unstartedLessons[index].lessonId : unstartedLessons[index - 1].lessonId;

            return {
                ...state,
                currentLessonId: nextLesson
            };
        }
        case SET_MISTAKE_BANK_OVERVIEW: {

            const { lesson, question } = action;

            let mistakeArray: MistakeBankLessons[] = [];

            if(state.mistakeBankOverview) {

                mistakeArray = state.mistakeBankOverview;

                const exists = mistakeArray.find(l => l.topicId === question.topicId);

                if(exists) {
                    const index = mistakeArray.findIndex(l => l.topicId === exists.topicId);
                    exists.numQuestionsMissed = exists.numQuestionsMissed + 1;
                    mistakeArray[index] = exists;
                } else {
                    const mistakeQuestion: MistakeBankLessons = {
                                                        curricula: lesson.curricula ? lesson.curricula : [],
                                                        categoryName: lesson.category.categoryName,
                                                        lessonId: lesson.lessonId,
                                                        lessonName: lesson.lessonName,
                                                        topicId: question.topicId,
                                                        topicName: question.topicName,
                                                        numQuestionsReviewed: 0,
                                                        numQuestionsMissed: 1,
                                                        topicVideoId: question.feedbackVideoId,
                                                        topicReviewStatus: 'REVIEW'
                                                    };
                    mistakeArray.push(mistakeQuestion);
                }
            } else {
                return {
                    ...state
                };
            }

            return {
                ...state,
                mistakeBankOverview: mistakeArray
            };
        }
        case ADD_MISTAKE_BANK_OVERVIEW: {
            const { overview } = action;
            return {
                ...state,
                mistakeBankOverview: overview
            };
        }
        case SET_TOPIC_REVIEW_STATUS: {
            const { topicId, status } = action;

            let mistakeArray: MistakeBankLessons[] = [];

            if(state.mistakeBankOverview) {

                mistakeArray = state.mistakeBankOverview;

                const exists = mistakeArray.find(l => l.topicId === topicId);

                if(exists) {
                    const index = mistakeArray.findIndex(l => l.topicId === exists.topicId);
                    exists.topicReviewStatus = status;
                    mistakeArray[index] = exists;
                }
            } else {
                return {
                    ...state
                };
            }

            return {
                ...state,
                mistakeBankOverview: mistakeArray
            };
        }
        case SET_TOPIC_QUESTION_AS_REVIEWED: {
            const { topicId } = action;

            let mistakeArray: MistakeBankLessons[] = [];

            if(state.mistakeBankOverview) {

                mistakeArray = state.mistakeBankOverview;

                const exists = mistakeArray.find(l => l.topicId === topicId);

                if(exists) {
                    const index = mistakeArray.findIndex(l => l.topicId === exists.topicId);
                    // need to make sure that we don't count question multiple time since user can redo questions
                    if(exists.numQuestionsMissed === exists.numQuestionsReviewed) {
                        return {
                            ...state
                        };
                    }

                    exists.numQuestionsReviewed = exists.numQuestionsReviewed + 1;
                    mistakeArray[index] = exists;
                }
            } else {
                return {
                    ...state
                };
            }

            return {
                ...state,
                mistakeBankOverview: mistakeArray
            };
        }
        default: return state;
    }
}
