import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from '../../store';
import { SchoolSafetyLessons } from './SchoolSafetyLessons';
import { updateUserSchoolSafety } from '../../stores/school-safety.store';

const mapStateToProps = ({ account, schoolSafety }: AppState, ownProps: any) => {
    return {
      ...ownProps,
      userInfo: account.userInfo,
      schoolSafety: schoolSafety
    };
};

const mapDispatchToProps = {
    updateUserSchoolSafety
};

const ConnectedSchoolSafety = connect(mapStateToProps, mapDispatchToProps)(SchoolSafetyLessons);

export default function(props: any) {
    return <ConnectedSchoolSafety {...props} />;
}
