import * as React from 'react';
import * as autobind from 'autobind-decorator';
import * as _ from 'lodash';
import { getAllHints } from  '../../../services/practice-test-scoring/test-hint-service';

import './StudyGuide.css';

import * as html2pdf from "html2pdf.js";
import { createFileName } from '../../../services/utils/filename';


import { isActTest, isInterimAssessment } from '../../../services/utils/practice-test-util';
import { PageContent, PageTitle } from '../../../components/PageContent/PageContent';
import Button from '../../../components/Button/Button';
import Loading from '../../../components/Loading/Loading';

import TestHintGroup from './components/TestHintGroup';
import HintSubjectHeader from './components/HintSubjectHeaderInline';
import SubjectHints from './components/SubjectHints';
import { sendPageviewInfo } from '../../../WinwardTracker';

export interface Props {
    hints: any[];
    match: any;
}

interface TestHints {
  act: Array<any>;
  sat: Array<any>;
  ia: Array<any>;
}

interface State {
    testHints?: TestHints;
    hints?: any[];
    emailAddress?: string;
}

export interface DispatchProps {
    getStudyGuideHints: () => Promise<any>;
}

type AllProps = Props & DispatchProps;


class StudyGuidePage extends React.Component<AllProps, State> {
    constructor (props: AllProps) {
        super(props);

        this.state = {
          testHints: undefined
        };
    }

    componentDidMount() {
        if (_.isEmpty(this.props.hints)) {
            this.props.getStudyGuideHints();
        }

        if (_.isEmpty(this.state.testHints)) {
          getAllHints()
            .then(result => { 
              const testHints =  this.sortTestHints(result);
              this.setState({ testHints: testHints });
            })
            .catch(console.log);
        }
        let path = window.location.pathname
        sendPageviewInfo(path)
    }

    sortTestHints(results: Array<any>) {
      return {
        act: results.filter(hint => isActTest(hint.testName)),
        sat: results.filter(hint => !isActTest(hint.testName) && !isInterimAssessment(hint.testName)),
        ia: results.filter(hint => isInterimAssessment(hint.testName))
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.state.hints === undefined && nextProps.hints) {
            this.setState({
                hints: _.sortBy(nextProps.hints, 'category' ),
                emailAddress: _.first(nextProps.hints).emailAddress
            });
        }
    }

    testTypeToTitle(testType: string) {
      switch(testType) {
        case 'ia': return 'Interim Assessments';
        case 'act': return 'ACT Book Tests';
        case 'sat': return 'SAT Book Tests';
        default: return 'false';
      }
    }

    handeClickForPdf = () => {
      let { firstName, lastName } = this.props.match.params
      let content = document.getElementById("pdf-main-content")
      let filename = createFileName("study-guide-" + firstName + "-" + lastName, "pdf")
      let opt = {
        filename,
        margin: 0.5,
        html2canvas: { scale: 2, letterRendering: true },
        image:       { type: "jpeg", quality: 0.99 },
        jsPDF:       { unit: "in", format: "letter", orientation: "portrait" },
        pagebreak:   { mode: ["css", "avoid-all"], before: [".page-break", "br.page-break"] }
      }

      html2pdf().set(opt).from(content).toPdf().get('pdf').then(pdf => {
        let nofPages = pdf.internal.getNumberOfPages()

        for (let i = 1; i <= nofPages; i++) {
          let x = pdf.internal.pageSize.getWidth() - 1
          let y = pdf.internal.pageSize.getHeight() - 0.1
          let pageNumber = `Page ${i} of ${nofPages}`
          pdf.setPage(i);
          pdf.setFontSize(11);
          pdf.setTextColor(100);
          pdf.text(pageNumber, x, y)
        }
      }).save()
    }

    public render() {
        const { firstName, lastName } = this.props.match.params;
        const { testHints } = this.state;
        const hasStandardHints = !_.isEmpty(this.state.hints);
        const hasTestHints = testHints && (testHints.act.length > 0 || testHints.sat.length > 0 || testHints.ia.length > 0)
        let mapComplete = false
        let dateInfo = new Date().toLocaleDateString()
        return (
            <PageContent className="study-guide-container study-guide-report">
              <div className="study-guide-container-inner">
                <div className="control-area">
                  <div className="">
                    <Button
                        text="SAVE AS PDF"
                        bgColor="blue"
                        onClick={this.handeClickForPdf}
                        className="do-not-print"
                    />
                  </div>
                </div>
                <div id="pdf-main-content">
                  <div className="row progress-summary-report-header">
                    <div className="col-12 d-flex align-items-center study-guide-header">
                      <img src="/assets/images/winward-academy-logo.png" alt="logo" width="80px"/>
                      <PageTitle title="Study Guide" />
                    </div>
                  </div>
                  { this.state.emailAddress &&
                    <div className="row progress-summary-report-prepared-for">
                      <div className="col-md-9 mb-2 mb-md-0">
                          <h5>Prepared exclusively for {firstName} {lastName}</h5>
                          <h5>{this.state.emailAddress}</h5>
                      </div>
                      <div className="col-md-3 text-md-right mt-auto">
                        <h5 className="progress-summary-report-date">{dateInfo}</h5>
                      </div>
                    </div>
                  }
                  <div className="row">
                    {!this.state.hints && <Loading />}
                    <div className="col-12 hint-subject">
                      <div className="subject-hint-list">
                        {this.state.hints && this.state.hints.map((subject, index, array) => {
                            subject
                                .lessonSummaries
                                .sort((a, b) => {
                                    const lowerA = a.lessonName.toLowerCase();
                                    const lowerB = b.lessonName.toLowerCase();
                                    if(lowerA < lowerB) { return -1; }
                                    if(lowerA > lowerB ) { return 1; }
                                    return 0;
                                });
                              if(array.length - 1 === index) mapComplete = true                              
                            return (
                                <div key={`hint-subject-${subject.category}`}>
                                    <SubjectHints subjectSummary={subject}/>
                                </div>
                            );
                         })
                        }
                        {hasTestHints && testHints !== undefined && mapComplete &&
                              <div>
                                <div className="subject-hint-section">
                                  <hr/>
                                  <HintSubjectHeader name="Practice Tests"/>
                                    {Object.keys(testHints).map(testType =>
                                      <TestHintGroup
                                        key={'hint-group-' + testType}
                                        title={this.testTypeToTitle(testType)}
                                        testHints={testHints[testType] || []}
                                      />
                                    )}
                                </div>
                              </div>
                              }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PageContent>
        );
    }
}

export default StudyGuidePage;
