import './SchoolSafetyTeacherDashboard.css';

import * as React from 'react';
import * as autobind from 'autobind-decorator';

import { LessonProgress, MistakeBankLessons } from '../../../../services/elearn/lesson-types';

import BlogCard from '../BlogCard/BlogCard';
import Loading from '../../../../components/Loading/Loading';
import MindfulCard from '../MindfulCard/MindfulCard';
import { PageContent } from '../../../../components/PageContent/PageContent';
import { RouteComponentProps } from 'react-router-dom';
import StockCard from '../StockCard/StockCard';
import { UserInfo } from '../../../../services/account/account-rest-interface';
import { getBaseUrl } from '../../../../services/routing/routing-utils';
import { welcomeUser } from '../../../../services/account/account-rest-interface';
import WelcomeModalThree from '../WelcomeModalThree/WelcomeModalThree';

import { StepByStepModal } from '../StepByStepModal/StepByStepModal';
import LessonStepByStepModal from '../LessonStepByStepModal/LessonStepByStepModal';

export interface Props {

}

export interface DispatchProps {
  setUserInfo: (info: Partial<UserInfo>) => void;
}

export interface StoreProps {
    userInfo: UserInfo;
    numLessonsUnstarted: number;
    numLessonsCompleted: number;
    numLessonsStarted: number;
    currentLessonId: string;
    currentLesson: LessonProgress | undefined;
    mistake: MistakeBankLessons[];
    allLessons: LessonProgress[];
}

interface State {
    showFirstModal: boolean;
    showSecondModal: boolean;
    showThirdModal: boolean;
    showStepByStep: boolean;
    showLearnStepModal: boolean;
    isLoaded: boolean;
}

export type AllProps = Props & StoreProps & DispatchProps & RouteComponentProps<any>;


export class SSSTeacherDashboard extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            showFirstModal: this.props.userInfo.postWelcome === 'true' ? false : true,
            showSecondModal: false,
            showThirdModal: false,
            showStepByStep: false,
            showLearnStepModal: false,
            isLoaded: true
        };
    }

    public render() {
        const { userInfo, currentLesson } = this.props;
        const { isLoaded } = this.state;
        const name = userInfo.firstName;

        // get all completed lesson info in mistake bank
        let missedLessons = [] as MistakeBankLessons[];

        const completeLessons = this.props.allLessons.filter(l => l.numCompletedQuestions === l.numTotalQuestions
                && (l.numTotalQuestions !== 0));
        const completeLessonsIds = completeLessons.reduce((acc: any, lesson: LessonProgress) => {
                acc.push(lesson.lessonId);
                return acc;
            }, []);

        missedLessons = this.props.mistake.filter((l) => {

                if(completeLessonsIds.includes(l.lessonId)) {
                    return true;
                } else {
                    return false;
                }
        });

        if (!isLoaded) {
            return <Loading />;
        }

        if(typeof(currentLesson) === 'undefined') {
            return <Loading />;
        }

        const hideInMobileViewClass = 'hidden-md-down';

        return (
            <PageContent className="home-container">

                {/* Welcome banner */}
                <div className="row">
                    <div className="col-12">
                        {name && <h1 className="page-title">Welcome, {name}</h1>}
                    </div>
                </div>

                {/* Cards */}
                <div className="row">
                    <div className="first-column col-lg-3 col-md-12 px-lg-0">
                        <StockCard
                            btnText="CONTACT US"
                            bottomText="Have questions or feedback?"
                            topStyle=""
                            bottomStyle="bottom-text-contact"
                            btnLink="http://winwardacademy.com/contact/"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <MindfulCard />
                    </div>

                    <div className="col-lg-3 col-md-12 px-lg-0">
                        <BlogCard />
                    </div>
                </div>

                <WelcomeModalThree
                    show={this.state.showFirstModal}
                    onClose={this.sendPostWelcomed}
                />

                <StepByStepModal
                  show={this.state.showStepByStep}
                  onClose={this.closeStepByStep}
                  onModalClose={this.cancelStepModal}
                />

                <LessonStepByStepModal
                  show={this.state.showLearnStepModal}
                  onClose={this.closeLearnStepModal}
                />

            </PageContent>
        );
    }

    private goToLesson() {
      this.props.history.push(`${getBaseUrl()}app/lessons?view=subject`);
    }

    private toggleSecondModal() {
        this.setState({ showFirstModal: false });
        this.setState({ showSecondModal: true});
    }

    private toggleThirdModal() {
        this.setState({ showSecondModal: false });
        this.setState({ showThirdModal: true});
    }

    private openStepByStep() {
      this.setState({ showStepByStep: true });
    }

    private closeStepByStep() {
      this.setState({
        showStepByStep: false,
        showLearnStepModal: true
      });
    }

    private cancelStepModal() {
        this.setState({
            showStepByStep: false,
            showLearnStepModal: false
        });
    }

    private closeLearnStepModal() {
      this.setState({
        showLearnStepModal: false
      });
    }

    private onProgressLessonClick(lessonId: string) {
        this.props.history.push(`${getBaseUrl()}app/lessons/${lessonId}`);
    }

    private sendPostWelcomed() {
        welcomeUser(this.props.userInfo.id).then(this.updateInitialLogIn);
        this.setState({ showFirstModal: false, showSecondModal: false, showThirdModal: false });
    }

    private updateInitialLogIn() {
      this.props.userInfo.postWelcome = 'true';
    }
}
