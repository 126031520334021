import React from 'react'
import { isProductionEnv } from '../../../services/utils/env-helper'

const CLEVER_CLIENT_ID_QA   = "95fc27aaa4421de95bbe" // QA & Local dev has same Clever ID
const CLEVER_CLIENT_ID_PROD = "8569ac32489d9db36471"

/**
 * This is self-contained Single Signon Button for Clever logins.
 * When user clicks then he/she will be directed to the clever to login and in
 * return it will be redirected to our own Clever login page (<wa-url>/login/clever)
 * 
 * Note that this is just a button with a redirect URL. Actual handshake 
 * starts in the redirected page with OAuth2 code which is passed to WA 
 * backend, in return it finds the user's information with a call to the 
 * auth server to be later verified againts WA's DB.
 * 
 * @returns Clever Button
 */
export default function CleverLoginButton() {
  let prod = isProductionEnv()
  let {host, protocol, search} = window.location
  let redirectUrl = `${protocol}//${host}/login/clever`
  let clientId = prod ? CLEVER_CLIENT_ID_PROD: CLEVER_CLIENT_ID_QA
  let cleverOauthUrl = "https://clever.com/oauth/authorize"
  let cleverLoginUrl = `${cleverOauthUrl}?response_type=code&redirect_uri=${redirectUrl}&client_id=${clientId}`

  return (
    <div style={{textAlign:"center"}}>
      <a href={cleverLoginUrl} title="Log In with Clever">
          <img src="/assets/images/loginbtn--clever.svg" alt="Clever Log In Button" width="200" height="auto" />
      </a>
    </div>
  )
}
