import './UserAvatar.css';

import * as React from 'react';
import { boundMethod } from 'autobind-decorator';

import Button from '../../../../components/Button/Button';
import DropDown from './components/DropDown/DropDown';
import Modal from '../../../Modal/Modal';
import { RouteComponentProps } from 'react-router-dom';
import { getBaseUrl } from '../../../../services/routing/routing-utils';

export interface Props {
    avatar_color: string;
    user: any;
    initials: string;
    isNavBar: boolean;
    onColorChange: (color: string) => void;
}

export interface DispatchProps {
    logoutUser: (username: string) => any;
}

export interface RouteProps extends RouteComponentProps<any> {}

interface State {
    toggle: boolean;
    menu_toggle: boolean;
    profileColor: string;
    showComingSoonPopup: boolean;
}

export type AllProps = Props & DispatchProps & RouteProps;


export default class UserAvatar extends React.Component<AllProps, State> {

    constructor(props: AllProps) {
        super(props);
        this.state = {
            toggle: false,
            menu_toggle: false,
            profileColor: this.props.avatar_color,
            showComingSoonPopup: false
        };
    }

    public componentDidMount() {
        const root = document.getElementById('root');
        if(!root) { return; }

        root.addEventListener('click', () => this.handleMenuHover);
    }

    public UNSAFE_componentWillReceiveProps(props: Props) {
        if(props.avatar_color !== this.state.profileColor) {
            this.setState({profileColor: props.avatar_color});
        }
    }

    public render() {

        const { toggle, profileColor, showComingSoonPopup } = this.state;
        const { initials } = this.props;
        let buttonColor = 'blue';
        let textColor = 'white';
        let widthBtn = 'ss-modal-btn';
        let displayColor = profileColor;
        if(profileColor == '#055683') {
            displayColor = '#194259'
        }
        return (
            <div className="avatar">
                <div className="avatar__dropdown">
                        <div style={{ background: displayColor }} className="avatar__circle">
                        <button className="btn-simple" onMouseOver={this.handleHover} onMouseLeave={this.handleLeave} onFocus={this.handleHover} onBlur={this.handleLeave}>
                            <p>
                                {initials}
                            </p>
                            </button>
                        </div>
                    <DropDown
                        show={toggle}
                        handleLogout={this.handleLogout}
                        goToSettings={this.goToSettings}
                        handleMenuHover={this.handleMenuHover}
                        handleMenuLeave={this.handleMenuLeave}
                        position={this.props.isNavBar ? 'bottom-left' : 'right'}
                        onColorChange={this.onProfileColorChange}
                        initColor={this.props.avatar_color}
                        user={this.props.user}
                    />
                </div>
            </div>
        );
    }   

    @boundMethod
    private handleHover(event: any) {
        this.setState({toggle: true});
    }

    @boundMethod
    private handleLeave(event: any) {
        setTimeout(() => { this.setState({toggle: this.state.menu_toggle}); }, 100);
    }

    @boundMethod
    private handleMenuHover(event?: any) {
        this.setState({menu_toggle: true});
    }

    @boundMethod
    private handleMenuLeave(event?: any) {
        this.setState({
            menu_toggle: false,
            toggle: false
        });
    }

    private toggleComingSoonPopup() {
        this.setState({ showComingSoonPopup: !this.state.showComingSoonPopup });
    }

    @boundMethod
    private handleLogout(e?: any) {
        this.props.logoutUser('');
        this.props.history.push('/logout');

    }

    @boundMethod
    private goToSettings() {
        this.props.history.push(`${getBaseUrl()}app/settings`);
        this.handleMenuLeave();
    }

    
    @boundMethod
    private onProfileColorChange(color: string) {
        this.setState({ profileColor: color }, () => this.props.onColorChange(color));
    }
}
