import './Button.css';

import * as React from 'react';

interface Props {
    /* Button text */
    text: string | JSX.Element;
    /* Button text color */
    textColor?: 'white' | 'green' | 'purple' | 'blue' | 'red' | 'default';
    /* Background color of the button */
    bgColor?: 'white' | 'green' | 'purple' | 'blue' | 'gray' | 'red';
    /* Click callback */
    onClick?: (e: any | undefined) => void;
    /* Custom styles */
    style?: React.CSSProperties;
    /* Custom Title Attribute */
    titleAttr?: string;
    /* Custom classname */
    className?: string;
    /* Disable the button (onClick won't be fired while disabled) */
    disabled?: boolean;
    /* If set, will use an <a> tag instead of <button> and links to the specified url */
    link?: string;
}

const defaultProps: Partial<Props> = {
    textColor: 'white',
    bgColor: 'blue',
    onClick: () => {/**/},
    className: ''
};

const Button: React.SFC<Props> = (props: Props) => {
    const onClick = (e: any) => {
        if(!props.disabled && props.onClick) {
            props.onClick(e);
        }
    };
    let formattedPropsText;
    props.titleAttr ? formattedPropsText = props.titleAttr : formattedPropsText = props.text;

    const spreadProps = {
        style: props.style,
        className: `
            btn
            btn--text-${props.textColor}
            btn--bg-${props.bgColor}
            ${props.className}
        `,
        onClick,
        role: props.link ? 'button' : undefined,
        href: props.link,
        title: typeof props.text === 'string' ? formattedPropsText : '',
        disabled: props.disabled
    };

    if(props.link) {
        return <a {...spreadProps}>{props.text}</a>;
    }

    return <button {...spreadProps}>{props.text}</button>;
};

Button.defaultProps = defaultProps;
Button.displayName = 'Button';

export default Button;
