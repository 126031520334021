const bundleInfo = [
    {
        number: 1,
        bundleId: 'gameChangerPersonalCoach',
        backEndBundleId: 'gameChanger',
        image: 'gameChangerPersonalCoach.png',
        bundleName: 'Game Changer + Personal Coach',
        bundleNameLine1: 'Game Changer +',
        bundleNameLine2: 'Personal Coach',
        description: [
            `All content, strategies, practice questions, real past exams, and personalized study plan available in the Game Changer + Study Plan bundle`,
            `Weekly check-ins to monitor progress and track performance`,
            `Additional practice tests, worksheets, and analysis provided to maximize results`,
        ],        
        price: 249999,
        shownPrice: 2499.99,
        includeCK: false,
        includePersonalCoach: true,
        includeStudyPlan: false        
    },
    {
        number: 2,
        bundleId: 'gameChangerStudyPlan',
        backEndBundleId: 'gameChanger',
        image: 'gameChangerStudyPlan.png',
        bundleName: 'Game Changer + Study Plan',
        bundleNameLine1: 'Game Changer +',
        bundleNameLine2: 'Study Plan',
        description: [
            `All content, strategies, practice questions, and real past exams available in the Game Changer bundle`,
            `Personalized study plan developed based on an in-depth analysis of the strengths and weaknesses identified in a diagnostic exam`,
            `Weekly plan is customized to each student’s needs`,
        ],
        price: 149999, 
        shownPrice: 1499.99,
        includeCK: false,
        includePersonalCoach: false,
        includeStudyPlan: true 
    },
    {
        number: 3,
        bundleId: 'gameChanger',
        backEndBundleId: 'gameChanger',
        image: 'gameChanger.png',
        bundleName: 'Game Changer for ACT & SAT',
        bundleNameLine1: 'Game Changer',
        bundleNameLine2: 'for ACT & SAT',
        description: [
            `All content and strategies needed for the ACT, the SAT, or both tests`,
            `7,000+ practice questions from real past exams`,
            `Step-by-step explanations for 8 real ACT tests and 8 real SAT tests`,
            `12 months of access`
        ],
        price: 49999,
        shownPrice: 499.99,
        includeCK: false,
        includePersonalCoach: false,
        includeStudyPlan: false 
    },   
    {
        number: 4,
        bundleId: 'collegeKnowledge',
        backEndBundleId: 'collegeKnowledge',
        image: 'collegeKnowledge.svg',
        bundleName: 'College Knowledge',
        bundleNameLine1: 'College',
        bundleNameLine2: 'Knowledge',
        description: [
            `Best for students preparing for college apps who want to shine in their
            essays, prepare a resume, complete the applications, prepare for interviews,
            and leave a lasting impression.`
        ],
        price: 49999,
        shownPrice: 499.99,
        includeCK: false,
        includePersonalCoach: false,
        includeStudyPlan: false
    },
    {
        number: 5,
        bundleId: 'gameChanger+ck',
        backEndBundleId: 'gameChanger',
        image: 'gameChangerCollegeKnowledge.svg',
        bundleName: 'Game Changer + College Knowledge',
        bundleNameLine1: 'Game Changer +',
        bundleNameLine2: 'College Knowledge',
        description: [
            `Save 50% on Game Changer when you add College Knowledge. 
            It's the ultimate toolkit to master the ACT and/or the SAT and shine in college applications.`
        ],
        strikeThroughPrice: 999.98,
        price: 74999,
        shownPrice: 749.99,
        includeCK: true,
        includePersonalCoach: false,
        includeStudyPlan: false
    }
];

export default bundleInfo;