import * as React from "react"
import { RouteComponentProps, Link } from "react-router-dom"
import { PageContent, PageTitle } from "../../components/PageContent/PageContent"
import { VideoGrid } from "../../components/VideoGrid"
import ScreenSelector from "../../components/ScreenSelector"
import { Tips } from "../../components/Tips"
import { demos, webinars, mindfulmoments } from "./data.videos"
import { tips } from "./data.tips"
import "./index.css"

type ScreenType = "student-demos" | "student-webinars" | "student-mindful-moments" | "student-tips"

export interface Props { }

export interface StoreProps { }

export type AllProps = Props & StoreProps & RouteComponentProps<any>

interface State {
    selectedScreen: ScreenType
}

let defaultScreen: ScreenType = "student-demos"


/**
 * View for the Student Resources. This is the main wrapper for the StudentResourcesCore component.
 */
export class StudentResources extends React.Component<Props, State> {
    constructor(props: AllProps) {
        super(props)

        this.state = {
            selectedScreen: defaultScreen
        }
    }

    public render() {
        return (
            <PageContent className="">
                <PageTitle title={`Resources`}></PageTitle>
                <StudentResourcesCoreView />
            </PageContent>
        )
    }
}

/**
 * Core view for the Student Resources. Currently, videos for Demos, student-webinars, Mindful Moments.
 * There is also ACT & SAT tips section that shows the useful tips with links to the students.
 */
export class StudentResourcesCoreView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = {
            selectedScreen: defaultScreen
        }
    }

    componentDidMount() {
        switch (window.location.hash) {
            case "#student-demos":
                this.setState({selectedScreen: "student-demos"})
                break
            case "#student-webinars":
                this.setState({selectedScreen: "student-webinars"})
                break
            case "#student-mindful-moments":
                this.setState({selectedScreen: "student-mindful-moments"})
                break
            case "#student-tips":
                this.setState({selectedScreen: "student-tips"})
                break
            default:
                this.setState({selectedScreen: defaultScreen})
        }
    }

    public render() {
        const { selectedScreen } = this.state

        return (
            <div className="">
                <div className="screen-selector-row subject-buttons view-change-buttons-container inline-buttons inline-btns">
                    <ScreenSelector
                        active={selectedScreen === "student-demos"}
                        displayName="Demos"
                        onSwitchComponent={() => {
                            this.setState({ selectedScreen: "student-demos" })
                            window.location.hash = "student-demos"
                        }}
                    />                    
                    <ScreenSelector
                        active={selectedScreen === "student-webinars"}
                        displayName="Webinars"
                        onSwitchComponent={() => {
                            this.setState({ selectedScreen: "student-webinars" })
                            window.location.hash = "student-webinars"
                        }}
                    />
                    <ScreenSelector
                        active={selectedScreen === "student-mindful-moments"}
                        displayName="Mindful Moments"
                        onSwitchComponent={() => {
                            this.setState({ selectedScreen: "student-mindful-moments" })
                            window.location.hash = "student-mindful-moments"
                        }}
                    />
                    <ScreenSelector
                        active={selectedScreen === "student-tips"}
                        displayName="ACT & SAT Tips"
                        onSwitchComponent={() => {
                            this.setState({ selectedScreen: "student-tips" })
                            window.location.hash = "student-tips"
                        }}
                    />
                </div>

                <div className="resource-content">
                    {selectedScreen === "student-demos" && <VideoGrid title="Winward Academy Video Demos" subtitle="For Students" videos={demos} />}
                    {selectedScreen === "student-webinars" && <VideoGrid title="Winward Academy Webinars" subtitle="For Students" videos={webinars} />}
                    {selectedScreen === "student-mindful-moments" && <VideoGrid title="Winward Academy Mindful Moments" subtitle="For Students" videos={mindfulmoments} />}
                    {selectedScreen === "student-tips" && <Tips title="Winward Academy ACT & SAT Tips" subtitle="For Students" tips={tips} />}
                </div>
            </div>
        )
    }
}

