import * as React from 'react';
import * as autobind from 'autobind-decorator';

interface Props {

}

interface State {
    showHelpDetail: boolean;
}


export default class WelcomeHelpMessage extends React.PureComponent<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            showHelpDetail: false,
        };
    }

    public render() {
        return (
            <div>
                <p style={{ marginTop: '32px' }}>
                    <a 
                        style={{ 
                            color: 'white', 
                        }}
                        href="javascript:void(0)"
                        onClick={() => this.setState({ showHelpDetail: !this.state.showHelpDetail })}
                    >
                        Having trouble receiving the email?
                    </a>
                </p>
                { this.state.showHelpDetail &&
                <div>
                    <p style={{ fontSize: '16px' }}>
                        If you don't get the confirmation email in the next few 
                        minutes, please first check your junk/spam folder as it's 
                        common to have email delivery issues due to email filters. 
                        If it's still not there, then please resend the confirmation 
                        email.
                    </p>
                    <p style={{ fontSize: '16px' }}>
                        Feel free to get in touch with us if you still don't receive the 
                        confirmation. It's possible the email was entered incorrectly or 
                        that it's being blocked by a school email server. 
                        Please&nbsp;
                        <a 
                            style={{ color: 'white' }}
                            href="mailto:help@winwardacademy.com"
                        >
                            contact us
                        </a>.
                        and we'll help get everything sorted for you. 
                    </p>
                </div>
                }
            </div>
        );
    }
}
