import './SignUp.css';

import * as React from 'react';
import * as Script from 'react-load-script';
import * as _ from 'lodash';
import { boundMethod } from 'autobind-decorator';

import {
    NewAccount,
    registerUser
} from '../../services/account/account-rest-interface';

import { Gender, getGenderEnum, getGenderOptions, getGenderText } from './constants/sign-up-types';

import BlueGeometricBackground from '../../components/BlueGeometricBackground/BlueGeometricBackground';
import Button from '../../components/Button/Button';
import Loading from '../../components/Loading/Loading';
import SignUpError from './SignUpError';
import SignUpHeader from './SignUpHeader';
import RegistrationContainer from './RegistrationContainer';
import PaymentProcessingModal from '../../components/PaymentProcessingModal';
import { Moment } from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import { SignUpFields } from './SignUpForm';
import PhoneLink from './PhoneLink';
import ButtonLoading from '../../components/ButtonLoading/ButtonLoading';

interface Props {

}

interface RouteProps extends RouteComponentProps<any> {}

interface State {
    isLoading: boolean;
    showProgressModal: boolean;
    serverMessage: string;
    shouldValidate: boolean;
    validationMessage: string;
    newAccountInfo?: SignUpFields;
    formIsValid: boolean;
}


export default class SignUp extends React.PureComponent<Props & RouteProps, State> {
    constructor(props: Props & RouteProps) {
        super(props);

        this.state = {
            validationMessage: '',
            isLoading: false,
            showProgressModal: false,
            serverMessage: '',
            shouldValidate: false,
            formIsValid: false
        };
    }

    public render() {
        const { isLoading } = this.state;

        return (
            <BlueGeometricBackground className="sign-up-container">
                {/* Actually just the wait for account setup modal */}
                <PaymentProcessingModal
                    showModal={this.state.showProgressModal}
                />

                <div className="order-help-banner">
                    <div className="order-help-branding justify-content-between align-items-center d-none d-sm-flex">
                        <div className="charity-first-logo d-none d-sm-flex justify-content-center align-items-center">
                            <a href="https://www.winwardacademy.com" target="_blank">
                                <img
                                    alt=""
                                    className="img-fluid order-help-image full-size"
                                    src={'/assets/images/winward-academy-logo-color.svg'}
                                />
                            </a>
                        </div>
                        <div className="justify-content-center d-none d-sm-flex align-items-center flex-column order-help-text">
                            <p className="d-flex no-bottom-margin">Need help signing up?</p>
                            <p className="d-flex no-bottom-margin"><a href="mailto:help@winwardacademy.com?subject=Help Signing Up">Email us</a>&nbsp;or call&nbsp;
                                <PhoneLink />
                            </p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center flex-column mobile-order-help-text order-help-text d-sm-none">
                        <p className="d-flex no-bottom-margin">Need help signing up?</p>
                        <p className="d-flex no-bottom-margin"><a href="mailto:help@winwardacademy.com?subject=Help Signing Up">Email us</a>&nbsp;or call&nbsp;
                            <PhoneLink />
                        </p>
                    </div>
                </div>

                {/* Header text, errgh login__header needs refactoring */}
                {/* Make this a style-container for use with Bundle page and others */}
                <RegistrationContainer>
                    <div className="row sign-up-header">
                        <div className="col-12">
                            <h1 className="register-act-prep-title">Welcome to Your Free Trial with Diagnostic</h1>
                        </div>
                    </div>
                    <div className="row free-trial-description">
                        <div className="col-12">
                            <div className="free-trial-row d-flex">
                                <p className="d-flex" style={{fontSize: '1.2rem'}}>
                                    Sign up to:
                                </p>
                            </div>
                            <div className="free-trial-row d-flex">
                                <p className="d-flex">
                                    <img alt="" src="/assets/images/dashboard/modal-cards/greencheckmark.svg" className="checkmark-image" />
                                    Take a full-length, official ACT® and SAT® exam with step-by-step explanations to all questions
                                </p>
                            </div>
                            <div className="free-trial-row d-flex">
                                <p className="d-flex">
                                    <img alt="" src="/assets/images/dashboard/modal-cards/greencheckmark.svg" className="checkmark-image" />
                                    Receive immediate access to your customized dashboard with strengths and weaknesses broken down by concept
                                </p>
                            </div>
                            <div className="free-trial-row d-flex">
                                <p className="d-flex">
                                    <img alt="" src="/assets/images/dashboard/modal-cards/greencheckmark.svg" className="checkmark-image" />
                                    Experience 14 days of limited access to Winward Academy’s award-winning test prep (free trial includes diagnostic)
                                </p>
                            </div>
                        </div>
                    </div>
                    <h2 className="margin-bottom-20">Step 1: Student Information</h2>
                    <SignUpForm
                        requireHowDidYouHear={true}
                        onChange={(newAccountInfo, formIsValid) => {
                            if (formIsValid) {
                                this.setState({ validationMessage: '' });
                            }

                            this.setState({
                                newAccountInfo,
                                formIsValid,
                            });
                        }}
                        shouldValidate={this.state.shouldValidate}
                    />
                    <SignUpError text={this.state.validationMessage}/>
                    <SignUpError text={this.state.serverMessage}/>

                    <div className="row">
                      <Button
                          text={this.state.isLoading ?
                              <ButtonLoading className="sign-up__loading" /> :
                              <span>CREATE ACCOUNT</span>
                          }
                          className="sign-up__create-account"
                          onClick={this.handleCreateAccount}
                          bgColor="green"
                          disabled={isLoading}
                      />
                    </div>
                </RegistrationContainer>
            </BlueGeometricBackground>
        );
    }

    @boundMethod
    private handleCreateAccount() {
        this.setState({ shouldValidate: true });
        if (!this.state.formIsValid) {
            return;
        }

        const info = this.state.newAccountInfo;
        if (info) {
            this.setState({
                isLoading: true,
                showProgressModal: true,
                serverMessage: ''
            });

            const accountInfo: any = {
                firstName: info.firstName,
                lastName: info.lastName,
                emailAddress: info.emailAddress,
                birthDate: info.dob,
                gender: info.gender,
                profileColor: info.profileColor,
                password: info.password,
                howDidYouHear: info.howDidYouHear,

                expirationDate: new Date(),
                bundleId: 'freeTrial',

                parentFirstName: info.parentFirstName,
                parentLastName: info.parentLastName,
                parentEmailAddress: info.parentEmailAddress,

                // TODO: Get rid of this from freeTrial?
                charityCode: '',
                includeCollegeKnowledge: false
            };

            registerUser(accountInfo)
                .then(response => {
                    // console.log('SUCCESS', response);
                    this.setState({ showProgressModal: false });
                    const email = encodeURIComponent(accountInfo.emailAddress);
                    this.props.history.push(`/welcome?email=${email}`);
                }).catch(e => {
                    console.log('Server err while registering user', { e });
                    this.setState({
                        isLoading: false,
                        serverMessage: e.message,
                        showProgressModal: false,
                    });
                });
            }
        }
}
