import './MathFigure.css';

import * as React from 'react';

const figuresPath = '/assets/figures/';

interface Props {
    fileName: string;
}

interface State {
}

export default class MathFigure extends React.Component<Props, State> {
    public render() {
        return (
            <div className="offset-md-1 col-md-8 col-sm-11 col-xs-11 math-figure-wrapper">
                <img 
                  src={figuresPath + this.props.fileName}
                  alt=""
                />
            </div>
        );
    }
}
