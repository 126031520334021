import * as React from 'react';

import ReportSection from '../ReportSection';
import ReportTableHeader from '../ReportTableHeader';

import './index.css';

import { EnrichmentLessonReportSummary } from '../../services/elearn/lesson-types';

export interface Props {
  summaryData: EnrichmentLessonReportSummary; 
}

function formatTableHeader(first: string, second: string, third: string) {
  if( third == '') {
    return (   <div><div>{first}</div><div>{second}</div></div>  )
  } else {
    return (
      <div className="th-tooltip">
          <div>{first}</div>
          <div>{second} </div>
          <span className="th-tooltip-text">{third}</span>
        </div>
    );
  }
}


const headers = [
  formatTableHeader('Video Lessons', 'Started', 'Indicates the number of video lessons  started.'),
  formatTableHeader('Video Lessons', 'Completed', 'Indicates the number of video lessons watched completely.'),
  formatTableHeader('PDF Lessons', 'Viewed', 'Indicates the number of PDF handouts opened.'),
];

const EnrichmentLessonSummary: React.SFC<Props> = ({ summaryData }) => {
  const { categoryName } = summaryData;
  let genericCategoryName = "enrichment";

  return (
    <ReportSection sectionName={categoryName} defaultImagePath="/assets/images/enrichment">
      <div className="lesson-summary-table-wrapper" style={{minHeight: 100}}>
        <table className="lesson-summary-table">
        <ReportTableHeader category={genericCategoryName}>
          {headers.map((headerName, index) => <th key={`th-${genericCategoryName}-${index}`}>{headerName}</th>)}
        </ReportTableHeader>
        <tbody>
          <tr key={`tr-${categoryName}`}>
            <td style={{width: "33%"}}>{summaryData.lessonsStartedVideo}</td>
            <td style={{width: "33%"}}>{summaryData.lessonsCompletedVideo}</td>
            <td style={{width: "33%"}} >{summaryData.lessonsViewedPdf}</td>
          </tr>
        </tbody>
      </table>
      </div>
    </ReportSection>
  );
};

export default EnrichmentLessonSummary;
